import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { SidebarService } from 'src/app/@fyxt/_services/_layout/sidebar.service';

import { SlidesOutputData, OwlOptions } from 'ngx-owl-carousel-o';
import { Width } from 'ngx-owl-carousel-o/lib/services/carousel.service';
import { ConfigService } from 'src/app/services/config.service';
import { UiService } from 'src/app/services/ui/ui.service';
import { UtilityService } from 'src/app/services/utility.service';
import { BaseService } from 'src/app/services/base.service';
import { ExternalVendorService } from 'src/app/services/external-vendor.service';
import { environment } from 'src/environments/environment';
import { NgIf, NgFor } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonComponent } from '../button/button.component';
import { MaterialModule } from '../../../material/material.module';

@Component({
    selector: 'fyxt-file-detail-view',
    templateUrl: './file-detail-view.component.html',
    styleUrls: ['./file-detail-view.component.scss'],
    standalone: true,
    imports: [ButtonComponent, MatTooltipModule, MatIconModule, NgIf, NgFor,MaterialModule]
})
export class FileDetailViewComponent implements OnInit {
  activeSlides: SlidesOutputData;
  @Input() width: number;
  @Input() id: any;
  @Input() photos: any;

  customOptions: OwlOptions = {
    loop: false,
    margin: 10,
    autoWidth: true,
    center: true,
    autoHeight: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    nav: true,
    navText: ['', ''],
    freeDrag: false
  };


  constructor(public _uiService: UiService, public sidebarService: SidebarService, public _utilService: UtilityService, public _baseService: BaseService, public _Config: ConfigService, public _externalVendorService: ExternalVendorService) { }

  ngOnInit(): void { }

  getPassedData(data: SlidesOutputData): void {
    this.activeSlides = data;
  }

  test(): void { }

  deleteSlider(id: any): void {
    this._Config.isLoader = true;
    let requestURL = environment.baseURL + this._Config.exvDeletePhotos;
    requestURL = requestURL.replace('{photo_id}', id);
    this._baseService.doDelete(requestURL).subscribe((result: any) => {
      this._Config.isLoader = false;
      this._utilService.showSuccess('', 'Photo Deleted Successfully');
      this._externalVendorService.getJobVendorDetail();
    }, (error) => {
      this._Config.isLoader = false;
      this._utilService.showError('', 'Unable to delete the photo!!');
    });
  }

  slickInit(slideEvent: any): void {
    slideEvent.slick.$slider.find('img').first().on('load', () => {
      const height = $('.slickImg-item').height();
      $('.slick-list').height(height);
    });
  }

  /** Track by Index  **/
  trackByIndex(index: number, item: any): number {
    return index;
  }

}
