import {Component, Inject, OnInit} from '@angular/core';
import {DialogService} from "../../../../_services/dialog/dialog.service";
import {UtilityService} from "../../../../../services/utility.service";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import {environment} from "../../../../../../environments/environment";
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { ChipComponent } from '../../../_reusable_ui/_components/chip/chip.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-remove-users',
    templateUrl: './remove-users.component.html',
    styleUrls: ['./remove-users.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, ChipComponent, ButtonComponent, MatDialogModule]
})
export class RemoveUsersComponent implements OnInit {
  selectedCompanyDetails: any;
  selectedUserDetails: any;
  isMultipleRemoveUsers = false;

  constructor(public _dialogService: DialogService,  public _utilService: UtilityService, public dialogRef: MatDialogRef<RemoveUsersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.selectedCompanyDetails = this.data?.data?.selectedCompanyDetails;
    this.selectedUserDetails = this.data?.data?.selectedUserDetails;
    this.isMultipleRemoveUsers = this.data?.data?.multipleRemoveUser;
  }

  ngOnInit(): void {
  }

  removeUsers(): void {
    if (this.isMultipleRemoveUsers) {
      this.removeMultipleUsers();
    } else {
      this.removeSingleUser();
    }
  }

  deletedTag(event: any) {
    // const deleteUserIndex = this.selectedUserDetails.findIndex((user) => {
    //   return user.id == event.id;
    // });
    // this.selectedUserDetails.splice(deleteUserIndex, 1);
    // this.selectedUserDetails = this.selectedUserDetails;
    this.selectedUserDetails = this.selectedUserDetails.filter((user: any) => {
      return user.id != event.id;
    });
  }

  removeMultipleUsers(): void {
    let payload = {
      user_ids: this.selectedUserDetails.map((user: any) => {
        return user.id || [];
      }),
      is_remove: true
    };
    let requestURL = `${environment.fastAPI_Actual_URL}` + `/companies/`+ this.selectedCompanyDetails.id + `/users/bulk-actions/`;
    this._dialogService.doPATCH(requestURL, payload).subscribe(
      {
        next: (response: any) => {
          response.isDelete = true;
          this.dialogRef.close(response);
          this._utilService.showSuccess('', 'Users Removed Successfully.');
        },
        error: (error) => {
          this._utilService.showErrorMessage(error.error);
          // this._utilService.showError('', 'Something went wrong, please try again later');
        },
        complete: () => {
        }
      }
    );

  }

  removeSingleUser(): void {
    let requestURL = environment.fastAPI_Actual_URL + "/companies/" + this.selectedCompanyDetails.id + "/users/" + this.selectedUserDetails.id + "/";
    this._dialogService.doDELETE(requestURL).subscribe(
      {
        next: (response: any) => {
          response.isDelete = true;
          this.dialogRef.close(response);
          this._utilService.showSuccess('', 'User Removed Successfully.');
        },
        error: (error) => {
          this._utilService.showErrorMessage(error.error);
          // this._utilService.showError('', 'Something went wrong, please try again later');
        },
        complete: () => {
        }
      }
    );
  }

   /** Track by Index  **/
   trackByIndex(index: number, item: any): number {
    return index;
  }

}
