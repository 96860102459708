import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { ConfigService } from 'src/app/services/config.service';
import { environment } from '../../../../../environments/environment';
import { KeycloakService } from 'keycloak-angular';
import { NgFor, NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-account-listing',
    templateUrl: './account-listing.component.html',
    styleUrls: ['./account-listing.component.scss'],
    standalone: true,
    imports: [NgFor, NgClass, NgIf]
})
export class AccountListingComponent implements OnInit {

  all_sub_domains: any[] = [];

  constructor(public _baseService: BaseService, public _Config: ConfigService, private keycloakService: KeycloakService) {

    setTimeout(() => {
      this.all_sub_domains.forEach(element => {
        element.isChecked = false;
      });
    }, 1000);

  }


  ngOnInit(): void {
    let requestURL = environment.baseURL + 'users/auth/';
    this._baseService.doGET(requestURL).subscribe((response: any) => {
      this.all_sub_domains = response.domains;
    });
  }



  extractHostname(url) {
    var hostname;

    if (url.indexOf("//") > -1) {
      hostname = url.split('/')[2];
    }
    else {
      hostname = url.split('/')[0];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];

    // return 'www.' + hostname;
    return hostname;
  }


  LaunchToDashboard() {
    const token = this.keycloakService.getKeycloakInstance().token;
    const refreshToken = this.keycloakService.getKeycloakInstance().refreshToken;
    let selectedObj = this.all_sub_domains.find(obj => obj.isChecked == true) || {};
    window.location.href = `${selectedObj.link}${token}/${refreshToken}`;

    this._baseService.removeAuthTokens();

  }


  isSelectedDomain(index: any) {

    this.all_sub_domains.forEach(element => {
      element.isChecked = false;
    });

    this.all_sub_domains[index].isChecked = true;
  }


  isSelectedDomainExist() {
    let indexVal = this.all_sub_domains.findIndex(obj => obj.isChecked == true);

    if (indexVal != -1)
      return true;
    else
      return false;

  }

}
