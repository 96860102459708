import { DatePipe, NgIf } from '@angular/common';
import { Component, ComponentRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { UtilityService } from 'src/app/services/utility.service';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { TimePickerComponent } from '../../../_reusable_ui/_controls/time-picker/time-picker.component';
import { CheckboxComponent } from '../../../_reusable_ui/_controls/checkbox/checkbox.component';
import { DropdownComponent } from '../../../_reusable_ui/_controls/dropdown/dropdown.component';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { DropdownDateSelectComponent } from '../../../_reusable_ui/_controls/dropdown-select/dropdown-date-select/dropdown-date-select.component';

@Component({
    selector: 'app-edit-service-scheduler',
    templateUrl: './edit-service-scheduler.component.html',
    styleUrls: ['./edit-service-scheduler.component.scss'],
    providers: [DatePipe],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, DropdownDateSelectComponent, NgxIntlTelInputModule, TextboxComponent, NgIf, DropdownComponent, CheckboxComponent, TimePickerComponent, MatRadioModule, MatDividerModule, MatDialogModule, ButtonComponent]
})
export class EditServiceSchedulerComponent implements OnInit {

  serviceSchedulerForm!: FormGroup;

  formSubmitted: boolean = true;
  isLoader: boolean = false;
  @Input() Error: boolean = false;
  @Input() currentDate = new Date();

  @Input() scheduleData:any;

  radioValue: any;
  serviceDue_Dropdown: any[] = [];
  plannerId:any;
  plannerStatus: string;

  startTime: string;
  endTime: string;
  calculateHours:any;
  calculateMinute:any;
  selectedTab: any;
  dateIsNotValid:boolean = false

  service_due_msg = "Due on Friday";

  availableToStartTypes:any = [
    { id: 'Day', name: 'Days Before' },
    { id: 'Month', name: 'Day of the month'},
  ];

  repeatType: any = [
    { id: 'Day', name: 'Day/s'},
    { id: 'Week', name: 'Week/s'},
    { id: 'Month', name: 'Month/s'},
    { id: 'Year', name: 'Year/s'}
  ];

  dueWeekList: any = [
    { id: "1", name: 'Monday'},
    { id: "2", name: 'Tuesday'},
    { id: "3", name: 'Wednesday'},
    { id: "4" , name: 'Thursday'},
    { id: "5", name: 'Friday'},
    { id: "6", name: 'Saturday'},
    { id: "7", name: 'Sunday'},
  ];

  tabList = [
    { name: 'Never', active: true, disable: false },
    { name: 'On', active: false, disable: false },
    { name: 'After',  active: false, disable: false },
  ];

  constructor(
    public _dialogService: DialogService,
    public _utilService: UtilityService,
    public dialogRef: MatDialogRef<EditServiceSchedulerComponent>,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.scheduleData = data?.data?.selectedSchedule;
    this.plannerId = data?.data?.id;
    this.plannerStatus = data?.data?.status;
  }

  ngOnInit(): void {
    this.createSchedulerForm()
  }

  createSchedulerForm() {
    this.serviceSchedulerForm = new FormGroup({
      startDate: new FormControl(new Date(), [Validators.required]),
      repeatNumber: new FormControl<number>(1, [Validators.required,Validators.min(1)]),
      repeatType:  new FormControl({ id: 'Week', name: 'Week/s'}, [Validators.required]),
      endsType: new FormControl('Never', [Validators.required]),
      endDate:new FormControl(new Date()),
      endOccurrence:new FormControl<number>(null, [Validators.min(1)]),
      availableToStart: new FormControl<number>(1),
      serviceDue:new FormControl(null),
      serviceDueWeek:new FormControl({ id:"5", name: 'Friday'}),
      customPeriod: new FormControl(true),
      expectedServiceStartTime: new FormControl(''),
      availableToStartType:new FormControl ({ id: 'Day', name: 'Days Before'}, [Validators.required]),
      expectedServiceEndTime: new FormControl(''),
    });

      // set default 29 days dropdown
      for (let index = 1; index < 29; index++) {
        let listObj = {  id: index, name: (index + '' + this._utilService.nth(index)) } ;
        this.serviceDue_Dropdown.push(listObj);
      }
      let last = { name: 'Last', id: 0 }
      this.serviceDue_Dropdown.push(last)
      this.setFormData()
  }

  onClickCustom(event) {
    if(event && this.serviceSchedulerForm.value?.repeatType?.id == 'Month') {
      this.serviceSchedulerForm.get('availableToStart').setValidators([Validators.required]);
      this.serviceSchedulerForm.get('serviceDue').setValidators([Validators.required]);
      this.serviceSchedulerForm.get('availableToStart').updateValueAndValidity();
      this.serviceSchedulerForm.get('serviceDue').updateValueAndValidity();
    } else {
      this.serviceSchedulerForm.get('availableToStart').clearValidators();
      this.serviceSchedulerForm.get('serviceDue').clearValidators();
      this.serviceSchedulerForm.get('availableToStart').updateValueAndValidity();
      this.serviceSchedulerForm.get('serviceDue').updateValueAndValidity();
    }
  }

  convert12Hours(value: string): string {
    if(value){
      return moment(value, 'hh:mm:ss A').format('h:mm A');
    }
  }

  setFormData() {
    this.serviceSchedulerForm.patchValue({
      startDate: this._utilService.setUTCDate(this.scheduleData.start_date),
      repeatNumber: this.scheduleData.repeat_number,
      endsType: this.scheduleData.ends_type,
      endDate: this._utilService.setUTCDate(this.scheduleData.ends_date),
      endOccurrence: this.scheduleData.ends_total_occurrence,
      availableToStart: this.scheduleData.available_to_start,
      serviceDue: this.scheduleData.service_due,
      customPeriod: this.scheduleData.custom_period,
      availableToStartType: (this.scheduleData.available_to_start_type== 'Day') ? { id: 'Day', name: 'Days Before'} :  { id: 'Month', name: 'Day of the month'},
      expectedServiceStartTime:this.convert12Hours(this.scheduleData?.expected_window_from_time) ,
      expectedServiceEndTime:this.convert12Hours(this.scheduleData?.expected_window_to_time),
    });

    if(this.scheduleData.custom_period && this.serviceSchedulerForm.value?.repeatType?.id == 'Month') {
      this.serviceSchedulerForm.get('availableToStart').setValidators([Validators.required]);
      this.serviceSchedulerForm.get('serviceDue').setValidators([Validators.required]);
      this.serviceSchedulerForm.get('availableToStart').updateValueAndValidity();
      this.serviceSchedulerForm.get('serviceDue').updateValueAndValidity();
    } else {
      this.serviceSchedulerForm.get('availableToStart').clearValidators();
      this.serviceSchedulerForm.get('serviceDue').clearValidators();
      this.serviceSchedulerForm.get('availableToStart').updateValueAndValidity();
      this.serviceSchedulerForm.get('serviceDue').updateValueAndValidity();
    }
    
    if(this.scheduleData.repeat_type == 'Day')
      {
        this.serviceSchedulerForm.patchValue({repeatType:{ id: 'Day', name: 'Day/s'}})
        this.service_due_msg = "Daily (Monday - Friday)"
      }
    else if(this.scheduleData.repeat_type == 'Week')
      {
        this.serviceSchedulerForm.patchValue({repeatType:{ id: 'Week', name: 'Week/s'}})
            if((this.scheduleData.expected_days >=1) && (this.scheduleData.expected_days <=7) ){
              this.serviceSchedulerForm.patchValue({serviceDueWeek:this.expectedDaysPatchValue(this.scheduleData.expected_days)})
            }
      }
    else if(this.scheduleData.repeat_type == 'Month')
      {
        this.serviceSchedulerForm.patchValue({repeatType:{ id: 'Month', name: 'Month/s'}})
        this.service_due_msg = "Day of the month"
            if(this.scheduleData.service_due == 0)
            {
              this.serviceSchedulerForm.patchValue({serviceDue:0})
            }
            else if((this.scheduleData.service_due >= 1)&& (this.scheduleData.service_due <= 28))
            {
              this.serviceSchedulerForm.patchValue({serviceDue:this.scheduleData.service_due})
            }
          if(this.scheduleData.available_to_start_type == 'Month' && this.scheduleData.available_to_start == 0)
          {
            this.serviceSchedulerForm.patchValue({availableToStart:0})
          }
          else if(this.scheduleData.available_to_start_type== 'Month' &&(this.scheduleData.available_to_start >= 1)&& (this.scheduleData.available_to_start <= 28))
          {
          this.serviceSchedulerForm.patchValue({availableToStart:this.scheduleData.available_to_start})
          }
      }
    else if(this.scheduleData.repeat_type == 'Year')
      {
      this.serviceSchedulerForm.patchValue({repeatType:{ id: 'Year', name: 'Year/s'}})
      this.service_due_msg = "One year from Start Date"
      }


      if(this.scheduleData.ends_type=='After'){
        this.addValidators(this.serviceSchedulerForm, ['endOccurrence'],true);
        this.serviceSchedulerForm.get('endOccurrence').patchValue(Number(this.scheduleData.ends_total_occurrence));
      }
      else{
        this.removeValidators(this.serviceSchedulerForm, ['endOccurrence']);
        this.serviceSchedulerForm.get('endOccurrence').patchValue(null);
      }

  }

  expectedDaysPatchValue(id:any){
    let obj =this.dueWeekList.find(x => x.id == id);
    return  obj
  }


  restrictPastDate(event: any) {
    this.scheduleData.ends_date = event
    if (this.scheduleData.ends_date instanceof Date) {
      new Date(this.formatDateToMM_DD_YYYY(this.scheduleData.ends_date)).getTime() < new Date(this.formatDateToMM_DD_YYYY(this.currentDate)).getTime() ? this.dateIsNotValid = true : this.dateIsNotValid = false;
    }
  }

  formatDateToMM_DD_YYYY(date: Date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [month, day, year].join('/');
  }


  calculateDuration(event: string, time: string) {
    if (time == 'start') {
      this.startTime = event
    } else if (time == 'end') {
      this.endTime = event
    }

    // Parse the start and end times
    const startHours = parseInt(this.startTime?.split(':')[0]);
    const startMinutes = parseInt(this.startTime?.split(':')[1].split(' ')[0]);
    const startMeridiem = this.startTime?.split(' ')[1];
    const endHours = parseInt(this.endTime?.split(':')[0]);
    const endMinutes = parseInt(this.endTime?.split(':')[1].split(' ')[0]);
    const endMeridiem = this.endTime?.split(' ')[1];

    // Convert to 24-hour format
    const startHours24 = (startMeridiem === 'AM' && startHours === 12) ? 0 : ((startMeridiem === 'PM' && startHours !== 12) ? startHours + 12 : startHours);
    const endHours24 = (endMeridiem === 'AM' && endHours === 12) ? 0 : ((endMeridiem === 'PM' && endHours !== 12) ? endHours + 12 : endHours);

    // Calculate the duration in minutes
    const durationMinutes = (endHours24 * 60 + endMinutes) - (startHours24 * 60 + startMinutes);

    // Convert to hours and minutes
    const durationHours = Math.floor(durationMinutes / 60);
    const durationMinutesRemainder = durationMinutes % 60;


    if (durationHours || durationMinutesRemainder) {
      if (durationHours > 0 || durationMinutesRemainder > 1) {
        this.calculateHours = durationHours;
        this.calculateMinute = durationMinutesRemainder;
        this.Error = false;
      }
      else {
        this.Error = true;
      }
    }


  }

  twentyFourHourFormat(time:any){
    if(time){
     // Parse the start and end times
     const Hours = parseInt(time?.split(':')[0]);
     const Minutes = parseInt(time?.split(':')[1].split(' ')[0]);
     const Meridiem = time?.split(' ')[1];
     // Convert to 24-hour format
     const Hours24 = (Meridiem === 'AM' && Hours === 12) ? 0 : ((Meridiem === 'PM' && Hours !== 12) ? Hours + 12 : Hours);
     const formattedTime: string = `${Hours24.toString().padStart(2, "0")}:${Minutes.toString().padStart(2, "0")}`;
     return  formattedTime;
    }
    return time;
  }

  repeatTypeChange(event:any){
    if(event.id =='Day'){
      this.service_due_msg = "Daily (Monday - Friday)"
      this.serviceSchedulerForm.patchValue({availableToStartType :{ id: 'Day', name: 'Days Before'},serviceDue:null, serviceDueWeek:'',availableToStart:1})
    }else if(event.id =='Week'){
      this.service_due_msg = "";
      this.serviceSchedulerForm.patchValue({availableToStartType :{ id: 'Day', name: 'Days Before'},serviceDue:null, serviceDueWeek:{ id: '5', name: 'Friday'},availableToStart:1})
    }else if(event.id =='Month'){
      this.service_due_msg = "Day of the month";
      this.serviceSchedulerForm.patchValue({serviceDueWeek:'',serviceDue:1 ,availableToStart:1})
    }else if(event.id =='Year'){
      this.service_due_msg = "One year from Start Date";
      this.serviceSchedulerForm.patchValue({availableToStartType :{ id: 'Day', name: 'Days Before'},serviceDue:null, serviceDueWeek:'',availableToStart:1})
    }
  }

  serviceTypeChange(event:any){}

  omitSpecialCharacters(e: any): any {
    try {
      if (/^[a-zA-Z0-9\s]*$/.test(e.key)) {
       if (e.keyCode == 69 || e.keyCode == 101 || e.keyCode == 32) {
            return false;
       } else {
         return true;
       }
      } else {
        e.preventDefault();
        return false;
      }
    } catch (e) {}
 }


  onselectInputValue(inputElement:any){
    inputElement.nativeElement.focus()
    inputElement.nativeElement.select()
  }
  availableToStartTypeChange(event:any){
    if(event.id =='Month'){
      this.serviceSchedulerForm.patchValue({availableToStart: 1 })
    }else if(event.id =='Day'){
      this.serviceSchedulerForm.patchValue({availableToStart:1})
    }
  }

  onSelectTab(selectedTabValue: any) {
    this.selectedTab = selectedTabValue;
  }

  onSubmit(){
    this.formSubmitted = true;
    if(this.serviceSchedulerForm.invalid){
      return true;
    }

    let endOccurrence=Number(this.serviceSchedulerForm.value.endOccurrence);
    let ends_total_occurrence=(endOccurrence>0)?this.convertToInt(endOccurrence):null;
    let serviceDue=this.serviceSchedulerForm.value?.serviceDue?this.serviceSchedulerForm.value?.serviceDue:((this.serviceSchedulerForm.value?.serviceDue==0)?0:null);

    let payload: any = {
      schedule: {
        "id":this.scheduleData?.id,
        // "expected_days":this.serviceSchedulerForm.value.serviceDueWeek?.id ? Number(this.serviceSchedulerForm.value.serviceDueWeek?.id) :null,
        "start_date": this.datePipe.transform(this.serviceSchedulerForm.value.startDate, 'yyyy-MM-dd'),
        "service_due":serviceDue,
        "repeat_number": this.convertToInt(this.serviceSchedulerForm.value.repeatNumber),
        "repeat_type": this.serviceSchedulerForm.value.repeatType?.id ,
        "ends_type": this.serviceSchedulerForm.value.endsType,
        "ends_date": this.serviceSchedulerForm.value.endsType === 'On'?this.datePipe.transform(this.serviceSchedulerForm.value.endDate, 'yyyy-MM-dd'): null,
        "ends_total_occurrence":ends_total_occurrence,
        "expected_flag": false,
        "expected_window_from_time": this.serviceSchedulerForm.value?.expectedServiceStartTime ? this.twentyFourHourFormat(this.serviceSchedulerForm.value.expectedServiceStartTime)+":00" : null,
        "expected_window_to_time": this.serviceSchedulerForm.value.expectedServiceEndTime ? this.twentyFourHourFormat(this.serviceSchedulerForm.value.expectedServiceEndTime)+":00" : null,
        "custom_period": this.serviceSchedulerForm.value.customPeriod,
        "available_to_start": this.serviceSchedulerForm.value?.availableToStart ? (this.convertToInt(this.serviceSchedulerForm.value?.availableToStart)) : 0,
        "available_to_start_type": this.serviceSchedulerForm.value.availableToStartType?.id,
      },
    }
    if(this.serviceSchedulerForm.value.serviceDueWeek && this.serviceSchedulerForm.value.serviceDueWeek?.id){
      payload.schedule.expected_days = Number(this.serviceSchedulerForm.value.serviceDueWeek?.id)
    }
    if(!this.serviceSchedulerForm.value.customPeriod && this.serviceSchedulerForm.value.repeatType?.id == 'Month'){
      payload.schedule.available_to_start = null;
      payload.schedule.available_to_start_type = null;
      payload.schedule.expected_window_from_time = null;
      payload.schedule.expected_window_to_time = null;
      payload.schedule.service_due = null;
    }
    this.patchApiCall(payload)

  }

  convertToInt(Value:any){
    if(typeof(Value) == 'string'){
      return parseInt(Value)
    }
    return Value;
  }

  patchApiCall(payload){
    let requestURL = environment.baseURL + `planner/`+this.plannerId+`/`
    this.isLoader = true;
    this._dialogService.doPATCH(requestURL,payload).subscribe(
      {
        next: (response: any) => {
          this.dialogRef.close(response);
          response.isEdit = true;
          this._utilService.showSnackNotification("success", ` Planner Schedule Updated Successfully `);
        },
        error: (error) => {
          this.isLoader = false;
          this._utilService.showErrorMessage(error);
        },
        complete: () => {
          this.isLoader = false;
        }
      });
  }

  radioChange(event: any) {
    if(event.value=='After'){
      this.addValidators(this.serviceSchedulerForm, ['endOccurrence'],true);
      this.serviceSchedulerForm.get('endOccurrence').patchValue(1);
    }
    else{
      this.removeValidators(this.serviceSchedulerForm, ['endOccurrence']);
      this.serviceSchedulerForm.get('endOccurrence').patchValue(null);
    }
  }


  addValidators(form?: any, control?: any,customMinValidators?:boolean) {
    if(customMinValidators){
      control.forEach((element) => {
        form.controls[element].setValidators([Validators.required,Validators.min(1)]);
        form.controls[element].updateValueAndValidity();
      });
    }
    else{
      control.forEach((element) => {
        form.controls[element].setValidators([Validators.required]);
        form.controls[element].updateValueAndValidity();
      });
    }

  }

  removeValidators(form?: any, control?: any) {
    control.forEach((element) => {
      form.controls[element].clearValidators();
      form.controls[element].updateValueAndValidity();
    });
  }


}
