import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {fromEvent } from 'rxjs';
import { filter, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { MatInputModule } from '@angular/material/input';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'fyxt-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SearchInputComponent)
        }
    ],
    standalone: true,
    imports: [NgClass, MatInputModule, FormsModule, NgxIntlTelInputModule, NgIf]
})
export class SearchInputComponent implements OnInit {
  private readonly destroy$: Subject<null> = new Subject<null>();

  @Input() checked?: boolean;
  @Input() disabled: boolean = false;
  @Output() change = new EventEmitter<boolean>();
  @Input() customClass: string = '';
  @Input() autoWidth: boolean;
  @Input() clear: BehaviorSubject<any>
  @Input() isLoading: boolean = false;
  @Input() isAdvanceFilter: boolean = false;
  
  @Input() inputControl!: any;

  public changed!: (value: string) => void;
  public touched!: () => void;
  onTouch: any = () => { }


  @Input() disable: boolean = false;
  @Input() placeHolder!: string;
  @Output() onSearchChange = new EventEmitter<Event>();

  @ViewChild('input', { static: true }) input: ElementRef;


  constructor() { }



  ngOnInit(): void {
    if (this.clear) {
      this.clear
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.inputControl = '';
        })
    }
  }

  ngAfterViewInit() {
    // server-side search
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(1000),
        distinctUntilChanged(),
        tap((event: KeyboardEvent) => {
          this.onSearch(this.input.nativeElement.value)
        })
      )
      .subscribe();
  }

  /**
 *Search func triggered on input change
 */
  onSearch(event: any) {
    this.onSearchChange.emit(event);
  }

  /**
  *Search func triggered on clicked icon
  */
  search(val: any) {
    if (!val) {
      return;
    }
    this.onSearchChange.emit(val);
  }


  public onChange(event: Event): void {
    const value: string = (<HTMLInputElement>event.target).value;
    this.changed(value);
  }


  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(inputVal: any) {
    this.inputControl = inputVal;
  }

  /**
   * On Change Event
   * @param event
   */
  onchange(event: Event) {
    const value: string = (<HTMLInputElement>event.target).value;
    this.changed(value);

    this.inputControl = event;
    this.change.emit(this.inputControl);

  }


}
