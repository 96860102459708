import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import Inputmask from 'inputmask';
import { MatIconModule } from '@angular/material/icon';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'fyxt-date-input',
    templateUrl: './dropdown-date-select.component.html',
    styleUrls: ['./dropdown-date-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => DropdownDateSelectComponent)
        }
    ],
    standalone: true,
    imports: [NgClass, NgIf, MatFormFieldModule, MatInputModule, MatDatepickerModule, FormsModule, NgxIntlTelInputModule, MatIconModule]
})
export class DropdownDateSelectComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  @Input() input: any;
  @Input() minDate: any;
  @Input() maxDate: any;
  @Input() minLenght: any;
  @Input() maxLenght: any;
  @Input() placeHolder!: string;
  @Input() disable: boolean = false;
  @Input() readonly: boolean = false;
  @Input() label: any;
  @Input() Error: boolean = false;
  @Input() dateInputValidate: boolean = true;
  @Input() dateSelector: boolean = false;
  @Input() isClear: boolean = false;
  @Input() offFocus: boolean = false;
  @Input() showRequiredStar: boolean = false;
  @Output() onValueChange = new EventEmitter<Event>();
  isInvalidDate: boolean=false;
  @Input() showError = true;

  constructor() { }

  ngOnInit(): void {
  }

  @ViewChild('customInput') customInputElementRef: ElementRef;

  onChange = (data: any) => { }
  onTouch = (_: any) => { }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: string) {
    if (value === null) {
      this.input = null;
    } else {
      this.input = new Date(value);
    }

    this.onChange(value);
  }

  /**
  *Func triggered on input change
  */
  onchange(event: any) {
    const isValidDate = !isNaN(Date.parse(event));
    if (!isValidDate) {
      this.isInvalidDate = true;
      this.onChange(null);
      this.onValueChange.emit(null);
    }
    else {
      this.isInvalidDate = false;
      this.onChange(event);
      this.onValueChange.emit(event);
    }

  }


  clearDate(event) {
    event.stopPropagation();
    this.input = null;
    this.onChange(this.input);
    this.onValueChange.emit(this.input);
  }
  ngAfterViewInit(): void {
    if (this.dateInputValidate) {
      Inputmask('datetime', {
        inputFormat: 'mm/dd/yyyy',
        placeholder: 'mm/dd/yyyy',
        alias: 'datetime',
        clearMaskOnLostFocus: false,
        shiftPositions: false,
        isComplete: function (buffer, opts) {
        },
        oncleared: () => {
          this.onValueChange.emit(new Event('dateCleared'));
        },
      }).mask(this.customInputElementRef.nativeElement);
    }

  }
}

