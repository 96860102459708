import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SoftAuthService {
  routeSub: any;
  subDomain: any;
  userID:any;
  public activeJob;
  public activeTenant;
  public contactData;

  constructor(private http: HttpClient) {
    let url = window.location.origin;
    this.subDomain = this.extractDomain(url).split('.')[0];
    // this.subDomain = 'web'
  }


  extractDomain(url: string) {
    let domain;
    if (url.indexOf("://") > -1) {
      domain = url.split('/')[2];
    } else {
      domain = url.split('/')[0];
    }
    domain = domain.split(':')[0];
    return domain;
  }

  getJob(id) {
    return this.http.get(`${environment.softAuth.url}jobs/public/${id}`,
      {
        headers: {
          "x-tenant-id": this.activeTenant,
          "SoftAuthSkipHeader": 'SoftAuthSkipHeader'
        }
      }
    )
  }

  emailSendOtp(body) {
    return this.http.post(`${environment.softAuth.url}auth/otp`, body, {
      headers: {
        "x-tenant-id": this.activeTenant,
        "SoftAuthSkipHeader": 'SoftAuthSkipHeader'
      }
    })
  }

  emailValidateOtp(body) {
    return this.http.post(`${environment.softAuth.url}auth/otp/validate`, body, {
      headers: {
        "x-tenant-id": this.activeTenant,
        "SoftAuthSkipHeader": 'SoftAuthSkipHeader'
      }
    })
  }

  createEntityAccess(body) {
    return this.http.post(`${environment.softAuth.url}entity_accesses`, body, {
      headers: {
        "x-tenant-id": this.subDomain,
      }
    })
  }

  getContactforEntity(job) {
    return this.http.get(`${environment.softAuth.url}entity_accesses/${job}`, {
      headers: {
        "x-tenant-id": this.subDomain,
      }
    })
  }

  removeEntityAccess(user_id) {
    return this.http.delete(`${environment.softAuth.url}entity_accesses/${user_id}`, {
      headers: {
        "x-tenant-id": this.subDomain,
      }
    })
  }

  resendInvite(body) {
    return this.http.post(`${environment.softAuth.url}entity_accesses/resend`, body, {
      headers: {
        "x-tenant-id": this.subDomain,
      }
    })

  }

  getFullJobDetails(id) {
    return this.http.get(`${environment.softAuth.url}jobs/${id}`,
      {
        headers: {
          "x-tenant-id": this.activeTenant,
          "SoftAuthSkipHeader": 'SoftAuthSkipHeader'
        }
      }
    )
  }

  getJobSchedules(id) {
    return this.http.get(`${environment.softAuth.url}jobs/${id}/schedules`,
      {
        headers: {
          "x-tenant-id": this.activeTenant,
          "SoftAuthSkipHeader": 'SoftAuthSkipHeader'
        }
      }
    )
  }


  getJobContacts(id) {
    return this.http.get(`${environment.softAuth.url}jobs/${id}/contacts`,
      {
        headers: {
          "x-tenant-id": this.activeTenant,
          "SoftAuthSkipHeader": 'SoftAuthSkipHeader'
        }
      }
    )

  }


  getschemaBasedData() {
    return this.http.get(`${environment.softAuth.url}entity_accesses/jobs`,
      {
        headers: {
          "x-tenant-id": this.activeTenant,
          "SoftAuthSkipHeader": 'SoftAuthSkipHeader'
        }
      }
    )

  }

  sendSmsOtp(body) {
    return this.http.post(`${environment.softAuth.url}auth/sms/otp`, body, {
      headers: {
        "x-tenant-id": this.activeTenant,
        "SoftAuthSkipHeader": 'SoftAuthSkipHeader'
      }
    })

  }


  verifySmsOtp(body) {
    return this.http.post(`${environment.softAuth.url}auth/sms/otp/validate`, body, {
      headers: {
        "x-tenant-id": this.activeTenant,
        "SoftAuthSkipHeader": 'SoftAuthSkipHeader'
      }
    })

  }

  getJobsCount(){
    let jobCountURL = 'tenants/job-count/?contact_id=' + localStorage.getItem('soft-auth-id');
    return this.http.get(`${environment.baseURL + jobCountURL}`)
  }

  getJobsAssigned(){
    let jobCountURL = 'jobs/?contact_id=' + localStorage.getItem('soft-auth-id');
    return this.http.get(`${environment.baseURL + jobCountURL}`)
  }





}
