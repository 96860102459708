import { NgClass, NgIf, NgFor, NgStyle, SlicePipe } from '@angular/common';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { ChipComponent } from '../../_components';

@Component({
  selector: 'fyxt-dropdown-infinite-scroll',
  templateUrl: './dropdown-infinite-scroll.component.html',
  styleUrls: ['./dropdown-infinite-scroll.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DropdownInfiniteScrollComponent)
    }
  ],
  standalone: true,
  imports: [NgClass, NgIf, NgSelectModule, FormsModule, NgxIntlTelInputModule, NgFor, ChipComponent, MatIconModule, SlicePipe]
})
export class DropdownInfiniteScrollComponent implements OnInit, ControlValueAccessor {

  @Input() label: any;
  @Input() placeHolder: any;
  @Input() items!: any[];
  @Input() selectAll: boolean = false;
  @Input() checkbox: boolean = false;
  @Input() searchable: boolean = false;
  @Input() multi: boolean = false;
  @Input() loading: boolean = false;
  @Input() input: any;
  @Input() Error: any;
  isSelectAll: boolean = false;
  @Input() allTeamsCount: number = 0;

  @Output() onValueChange = new EventEmitter<Event>();
  @Output() onScrollDropdown = new EventEmitter();
  @Output() onSelectAll = new EventEmitter();
  
  @ViewChild('selectComponent') selectComponent!: NgSelectComponent;
  searchValue: string = '';

  constructor() { }

  writeValue(value: string) {
    this.input = value;
    this.onChange(value);
  }

  onChange = (data: any) => {
  }

  onTouch = (_: any) => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngOnInit(): void {
  }

  onSelectAllTeams(isSelected?: boolean) {
   this.onSelectAll.emit(isSelected);
  }

  onchange(event: any) {
    if (this.selectAll) {
      if (this.items.length === this.input.length) {
        this.isSelectAll = true;
      }
      else {
        this.isSelectAll = false;
      }
      this.onChange(this.input);
    }
    else {
      this.onChange(this.input);
    }
    this.onValueChange.emit(event);
  }

  infiniteScroll() {
    this.onScrollDropdown.emit();
  }

  trackByFn(item: any) {
    return item.id;
  }

  getSelectedPropertyCount(){
    let unSelectedItems=this.items.filter(obj=>!this.input.includes(obj.id)) || [];
    let newCount=this.items.length - unSelectedItems?.length;
    return newCount;
  }
}
