import { ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { DropdownPosition, NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChipComponent } from '../../_components/chip/chip.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { NgIf, NgClass, NgFor, SlicePipe } from '@angular/common';

@Component({
    selector: 'fyxt-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => DropdownComponent)
        }
    ],
    standalone: true,
    imports: [NgIf, NgClass, NgSelectModule, FormsModule, NgxIntlTelInputModule, NgFor, ChipComponent, MatTooltipModule, CheckboxComponent, SlicePipe]
})
export class DropdownComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() input: any;
  @Input() items!: any[];
  @Input() bindLabel: string = 'name';
  @Input() bindValue: string = null;
  @Input() placeHolder: any;
  @Input() loading: boolean = false;
  @Input() searchable: boolean = false;
  @Input() selectAll: boolean = false;
  @Input() disable: boolean = false;
  @Input() addTagText: string = 'Add Item';
  @Input() closeOnSelect: boolean = false;
  @Input() clearSearchOnClose: boolean = true;
  @Input() clearSearchOnAdd: boolean = true;
  @Input() clearSearch: boolean = false;
  @Input() addTag: boolean = false;
  @Input() clearable: boolean = false;
  @Input() customClass: string = '';
  @Input() virtualScroll: boolean = false;
  @Input() dropdownPosition: DropdownPosition = 'auto';
  @Input() showNoItemsFound: boolean = true;

  @Input() label: any;
  @Input() Error: any;
  @Input() Image: any;
  @Input() groupKey: any;
  @Input() multi: boolean = false;
  @Input() grouped: boolean = false;
  @Input() checkbox: boolean = false;
  @Input() notify: boolean = false;
  @Input() checkboxWithHTMLValue: boolean = false;
  @Input() profile: boolean = false;
  @Input() units: boolean = false;
  @Input() isCountChipNeeded: boolean = false;
  @Output() onValueChange = new EventEmitter<Event>();
  @Output() onSearchDropdown = new EventEmitter<Event>();
  @Output() clearAll = new EventEmitter<Event>();
  @Output() clear = new EventEmitter<Event>();
  @Output() close = new EventEmitter<Event>();
  @Output() onScrollDropdown = new EventEmitter();
  @Output() onGetRemovableValueChange = new EventEmitter<Event>();

  @Input() isCreateTicket: boolean = false;
  @Input() isCategoryView: boolean = false;
  @Input() isEquipment: boolean = false;
  @Input() isDateDropdown: boolean = false;
  @Input() isPrimaryView: boolean = false;

  @ViewChild('selectComponent') selectComponent!: NgSelectComponent;
  @ViewChild('myIdentifier') myIdentifier: ElementRef;

  @Input() isSelectAll: boolean = false;
  @Input() fullInputData: { id: string, name: string }[] = [];
  disablesSelectAll: boolean = false;
  equipmentImagePlaceholder: string = '../../../../../assets/@fyxt/images/images.png';
  searchValue: string = '';
  indeterminate: boolean = false;
  @Input() isForJobSettings: boolean = false;

  constructor(
    public changeDetector: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes): void {
    if (this.units) {
      this.handleSelectAllInitValue();
    }
    if (changes.clearSearch) {
      if (this.selectComponent) {
        this.selectComponent.searchTerm = '';
      }
      this.searchValue = '';
    }

    // code for indeterminate
    if(this.items?.length>0 && this.selectAll){

      if(this.input?.length>0){
        let selectedItems:any[]=this.items.filter(obj=>this.input.includes(obj.id)) || [];
        if(selectedItems?.length>0 && selectedItems?.length!=this.items.length){
          this.indeterminate = true;
        }
        else{
          this.indeterminate = false;
        }
      }
      else{
        this.indeterminate = false;
      }

    }
    else{
      this.indeterminate = false;
    }

    this.changeDetector.detectChanges();
  }


  //Get dynamic width of the lable
  getName(itemName: any, itemId?: any) {
    let htmlElem: any = document.getElementsByClassName('width_100')[0];
    let charCount = (htmlElem?.offsetWidth / 8) - 3;

    let name = itemName;
    if (!name && itemId ){
      const existInFullData = this.fullInputData.find(obj => obj.id === itemId);
      if (existInFullData && existInFullData.name) {
        name = existInFullData.name;
      }
    }

    if (name?.length>charCount) {
      return name.slice(0,charCount) + '...';
    }
    else {
      return name;
    }
  }

  //Show/Hide dynamic width of the lable Tooltip
  isTooltip(itemName: any) {
    let htmlElem: any = document.getElementsByClassName('width_100')[0];
    let charCount = (htmlElem?.offsetWidth / 8) - 3;

    if (itemName?.length>charCount) {
      return true;
    }
    else {
      return false;
    }
  }

  private handleSelectAllInitValue(): void {
    const { items, selectAll } = this;
    if (!(items && items.length)) {
      this.isSelectAll = false;
      this.disablesSelectAll = true;
      return;
    }

    if (items.every(item => item.selected)) {
      this.isSelectAll = true;
      return;
    }

    this.disablesSelectAll = items.every(item => item.disabled) && selectAll;
    this.isSelectAll = false;
  }

  onChange = (data: any) => {


  }
  onTouch = (_: any) => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: string) {
    this.input = value;
    this.onChange(value);
  }

  /**
   *Func triggered on input change
   */
  onchange(event: any) {
    this.handleSelectedParam(event);
    if (this.selectAll) {
      if (this.detectSelectAllCheckbox()) {
        this.isSelectAll = true;
      } else {
        this.isSelectAll = false;
      }
      this.onChange(this.input);
      this.onValueChange.emit(this.input);
    } else {
      this.onChange(this.input);
      this.onValueChange.emit(event);
      this.searchValue = '';
    }


    // code for indeterminate
    if(this.items?.length>0 && this.selectAll){

      if(this.input?.length>0){
        let selectedItems:any[]=this.items.filter(obj=>this.input.includes(obj.id)) || [];
        if(selectedItems?.length>0 && selectedItems?.length!=this.items.length){
          this.indeterminate = true;
        }
        else{
          this.indeterminate = false;
        }
      }
      else{
        this.indeterminate = false;
      }

    }
    else{
      this.indeterminate = false;
    }
  }

  private detectSelectAllCheckbox(): boolean {
    if (this.units) {
      return this.detectSelectAllCheckboxForItemsWithDisabledParam();
    }

    return this.items.length === this.input.length
  }

  private detectSelectAllCheckboxForItemsWithDisabledParam(): boolean {
    const itemsCouldBeSelected = this.items.filter(item => !item.disabled);
    return itemsCouldBeSelected.length === this.input.length;
  }

  private handleSelectedParam(data: any): void {
    if (!Array.isArray(data)) {
      return;
    }

    if (!data.length) {
      this.items.filter(obj => obj.selected = false);
      return;
    }

    this.items = this.items.map(item => {
      if (!Object.keys(item).includes('selected')) {
        return item;
      }

      const inputItem = data.find(obj => obj.id && obj.id === item.id);
      if (inputItem) {
        return { ...item, selected: true };
      }

      return { ...item, selected: false };
    })

  }


  /**
   * Triggered when select all
   */
  toggleSelectAll(isSelected?: boolean) {
    if (this.isForJobSettings) {
      this.items.filter(obj => obj.selected = obj.disabled ? false : isSelected);
      if (isSelected === true || (!isSelected && this.indeterminate)) {
        this.input = this.items.filter(item => item.selected).map(obj => obj.id);
        this.isSelectAll = false;
      }
      else {
        this.input = [];
      }
    } else {

      this.items.filter(obj => obj.selected = isSelected);

      if (isSelected == true || (!isSelected && this.indeterminate)) {
        this.input = this.items.map(obj => obj.id);
      } else {
        this.input = [];
      }
    }
    // this.isSelectAll=isSelected;

    // this.onChange(this.input);
    // this.onValueChange.emit(this.input);

  }

  /**
   *Func triggered on Selecting all values
   */
  onSelectAll(isSelected: boolean): void {
    if (this.units) {
      this.handleAllSelectionListItemsWithDisabledParam(isSelected);
    } else {
      this.handleAllSelectionByDefault(isSelected);
    }

    this.onChange(this.input);
    this.onValueChange.emit(this.input);
  }

  private handleAllSelectionByDefault(isSelected: boolean): void {
    this.items.filter(obj => obj.selected = isSelected);

    if (isSelected == true) {
      this.input = this.items;
    } else {
      this.input = [];
    }
  }

  private handleAllSelectionListItemsWithDisabledParam(isSelected: boolean): void {
    this.items.filter(obj => obj.selected = obj.disabled ? false : isSelected);

    if (isSelected == true) {
      this.input = this.items.filter(item => item.selected);
    } else {
      this.input = [];
    }
  }


  onRemove(event: any): void {
    if (event?.value?.jobCost_approval_scenario_exist) {
      event.disabled = false;
    }
    this.onGetRemovableValueChange.emit(event.value);
  }

  /**
   *Func triggered on scroll bar go to bottom
   */
  infiniteScroll() {
    this.onScrollDropdown.emit();
  }

  /**
   *Func triggered on input change
   */
  onSearch(event: any) {
    this.onSearchDropdown.emit(event);
    if (!this.clearSearchOnClose)
      this.searchValue = event.term;
  }

  /**
   *Func triggered on input change
   */
  onClear(event?: any) {
    this.clear.emit(event);
  }

  onClose(event?: any) {
    if (!this.clearSearchOnClose)
      this.selectComponent.searchTerm = this.searchValue;
    this.close.emit(event);
  }
}

