import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http'
import { Observable, throwError } from 'rxjs';

import { catchError, map, tap } from 'rxjs/operators'
import { AuthService } from './auth.service';
import checkServerDownURLs from './server_down_common_APIs.json';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService {
  checkServerDownURLsList: any = checkServerDownURLs;

  constructor(
    public injector: Injector,
    private readonly authService: AuthService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone();
    return next.handle(request)
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.ok) {
              /******** check server error  ********/
              let urlParams = new URL(event.url);
              if (this.checkServerDownURLsList.includes(urlParams?.pathname)) {
                let currentPath = window.location.pathname;
                if (currentPath == '/server-error') {
                  this.authService.redirectTo('/');
                }
              }
              // console.log('Success');
            } else {
              // console.log('Error');
            }
          }

          return event;
        }))
      .pipe(tap(() => { },
        (err: Error) => {
          if (err instanceof HttpErrorResponse) {
            if (![401].includes(err.status)) {
              // console.error(err);

              /******** check server error  ********/
              let urlParams = new URL(err.url);
              if (this.checkServerDownURLsList.includes(urlParams?.pathname)) {
                this.authService.redirectTo('/server-error');
              }

              return;
            }

            let accessErrorCode = String(err.status);

            if (err.error === 'User is suspended.') {
              accessErrorCode = '423';
            }


            const loginUrl: string = this.authService.getLoginUrlWithErr(accessErrorCode);
            this.authService.logOut(loginUrl).subscribe();
          }
        }));
  }
}
