import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PhoneNoInputComponent } from '../../../_reusable_ui/_controls/phone-no-input/phone-no-input.component';
import { NgIf } from '@angular/common';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';

@Component({
    selector: 'fyxt-create-company',
    templateUrl: './create-company.component.html',
    styleUrls: ['./create-company.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, TextboxComponent, NgxIntlTelInputModule, NgIf, PhoneNoInputComponent, MatDialogModule, ButtonComponent]
})
export class CreateCompanyComponent implements OnInit {

  constructor(public formbuilder: FormBuilder,) { }

  addCompanyForm!: FormGroup;
  formSubmitted : boolean = false ;

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(){
      this.addCompanyForm = this.formbuilder.group({
        'company_name': ['', Validators.required],
        'phone_no': ['', Validators.required],
        'email': [null, Validators.required],
        'website': [null,],
        'address': [null,],
       });
  }

  onSubmit() {
    this.formSubmitted = true ;
  }

  onCancel() {
    this.formSubmitted = false ;
    this.addCompanyForm.reset();
  }

}
