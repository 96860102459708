import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';
import { DialogService, ModalData } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { AppTableSource } from '../../../models/app-table-model';
import { EditColumn, EditColumnsModalData, EditColumnsComponent } from '../../_popup_views/edit-columns/edit-columns.component';
import { TableComponent } from '../../../_reusable_ui/_components/table/table.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { NgIf } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';

@Component({
    selector: 'fyxt-tab-planer-contacts',
    templateUrl: './tab-planer-contacts.component.html',
    styleUrls: ['./tab-planer-contacts.component.scss'],
    standalone: true,
    imports: [SearchInputComponent, MatDividerModule, NgIf, LoaderSmallComponent, TableComponent]
})
export class TabPlanerContactsComponent implements OnInit {

  private readonly editableColumns$: BehaviorSubject<any[]> = new BehaviorSubject<EditColumn[]>([]);
  @Input() dataSource!: AppTableSource;
  @Input() plannerContactSearchValue!: string;
  @Input() isLoader!: boolean;
  @Input() plannerContactEditColumnView!: [];
  @Output() onSearchValueChange = new EventEmitter<Event>();
  @Output() onSortValueChange = new EventEmitter<Event>();
  @Output() onPaginationValueChange = new EventEmitter<Event>();
  @Output() onEditColumnChange = new EventEmitter();
  @Output() onRowClickValueChange = new EventEmitter();


  constructor(
    public _dialogService: DialogService,
  ) { }

  ngOnInit(): void {
  }

  editColumnPopup() {
    this.editableColumns$.next(this.plannerContactEditColumnView);
    this.editableColumns$
      .pipe(
        take(1),
        switchMap((columns) => {
          let popupData: ModalData<EditColumnsModalData> = {
            title: 'Edit Table Column',
            component: EditColumnsComponent,
            containerClass: ['modal-sm', 'modal_edit_colomn'],
            data: {
              columns,
              onChange: this.editColumnOrder.bind(this)
            }
          };
          return this._dialogService.openModal<EditColumnsModalData>(popupData);
        })
      )
      .subscribe(result => {});
  }

  editColumnOrder(columnLists: EditColumn[]): void {
    this.onEditColumnChange.emit(columnLists);
  }

  searchPlannerContact(value: any): void {
    this.onSearchValueChange.emit(value);
    this.plannerContactSearchValue = value;
  }

  plannerContactSortChange(sortState: any) {
    this.onSortValueChange.emit(sortState);
  }

  onPageChange(paginationEvent: any) {
    this.onPaginationValueChange.emit(paginationEvent);
  }

  onRowClick(event: any): void {
    this.onRowClickValueChange.emit(event);
  }


}
