import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { UtilityService } from 'src/app/services/utility.service';
import { ConfigService } from 'src/app/services/config.service';
import { environment } from 'src/environments/environment';

import { combineLatest, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../../services/Auth/auth.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true
})
export class LoginComponent implements OnInit {
  isJobView: boolean = false;
  selectedJob: string = '';

  constructor(
    public _baseService: BaseService,
    public _utilService: UtilityService,
    public _Config: ConfigService,
    public activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
  ) {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params.job) {
        this.isJobView = true;
        this.selectedJob = params.job;
      }
    });
  }


  ngOnInit(): void {
    this.startAuthFlow();
  }

  startAuthFlow(): void {
    this.authService.isLoggedIn()
      .pipe(
        switchMap((isLoggedIn) => {
          if (!isLoggedIn) {
            return this.authService.logIn().pipe(filter(() => false));
          }

          return this.authService.isDomainAllowed();
        }),
        switchMap((isDomainAllowed) => {
          if (isDomainAllowed) {
            return of(true);
          }

          const loginUrl: string = this.authService.getLoginUrlWithErr('401');
          return this.authService.logOut(loginUrl);
        }),
        filter((isAccessAllowed) => !!isAccessAllowed)
      )
      .subscribe(() => {
        this.setUpData();
      });
  }

  setUpData(): void {
    if (this._baseService.isPublicPage()) {
      return;
    }

    this._Config.isLoader = true;

    let requestURL = environment.baseURL + 'users/auth/';
    let profileRequestURL = environment.baseURL + this._Config.getProfile;
    combineLatest([
      this._baseService.doGET(requestURL),
      this._baseService.doGET(profileRequestURL),
    ])
      .pipe(map(([authResp, profile]) => ({ ...authResp, ...profile })))
      .subscribe((result: any) => {

        this._baseService.removeAuthTokens();

          this._Config.isLoader = false;

          let baseObj: any = result || {};

          baseObj.category = result.category;

          if (result.types.length > 0)
            baseObj.current_Role = result.types[0];

          if (baseObj.current_Role) {
            if (baseObj.current_Role == 'Member')  //if member role act as Tenant
              baseObj.current_Role = 'Tenant';
          }

          localStorage.setItem('userobj', JSON.stringify(baseObj));

          this._baseService.setUserInfo(result);


          if (this.isJobView) {
            this.isViewJob(baseObj);
          }
          else {
            this._baseService.goToDashboard(baseObj);
          }

        },
        (error) => {
          this._Config.isLoader = false;
        }
      );
  }

  isViewJob(result: any) {
    this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.getProfile;
    this._baseService.doGET(requestURL).subscribe(
      (result: any) => {
        this._Config.isLoader = false;
        this._baseService.setUserInfo(result);
        this._baseService.redirectTo('/view-job/' + this.selectedJob);
      },
      (error) => {
        this._Config.isLoader = false;
        this._utilService.showErrorMessage(error);
      }
    );

  }

}
