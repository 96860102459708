import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';
import { ItemList } from '../../../_reusable_ui/_controls/dropdown-select/itemType.class';
import { AddViewComponent } from '../add-view/add-view.component';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { CheckboxComponent } from '../../../_reusable_ui/_controls/checkbox/checkbox.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';
import { DropdownSelectComponent } from '../../../_reusable_ui/_controls/dropdown-select/dropdown-select/dropdown-select.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-dashboard-add-view',
    templateUrl: './dashboard-add-view.component.html',
    styleUrls: ['./dashboard-add-view.component.scss'],
    standalone: true,
    imports: [NgIf, DropdownSelectComponent, TextboxComponent, FormsModule, NgxIntlTelInputModule, CheckboxComponent, MatDialogModule, ButtonComponent]
})
export class DashboardAddViewComponent implements OnInit {

  @ViewChild('closePopup') closePopup: ElementRef;

  currentUserInfo: any = localStorage.getItem('userobj') ? JSON.parse(localStorage.getItem('userobj')): {};

  _viewModule: string


  chipList: ItemList[] = [];

  switchOnOff: boolean = true;

  addViewForm: any = {
    selectedFilters: [],
    viewName: '',
    isPinned: true,
    isShareToOthers: true
  };
  isLoaderForSave: boolean = false;
  isLoaderForUpdate: boolean = false;
  selectedView:any={};

  constructor(
    public _dialogService: DialogService,
    public _utilService: UtilityService,
    public dialogRef: MatDialogRef<AddViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this._viewModule = this._dialogService._viewModule;
    // this.chipList = this.data.data.selectedFilter;
    // this.selectedView=this.data.data.selectedView;
  }

  ngOnInit(): void {
  }

  /**
 * On Change Event
 * @param event
 */
  slideToggleChange(event: any): void {}

  /**
* On Change Event
* @param event
*/
  selectedTags(event: any): void {
    this.addViewForm.selectedFilters = event;
  }


  /**
   * save view Trigger
   */
  saveView() {
    if (!this.addViewForm.viewName) {
      return null
    } else {
      this.isLoaderForSave = true;

      let postBody = {
        // "filters": this.data.data.filterQuery ? this.data.data.filterQuery : {},
        "is_pin": this.addViewForm.isPinned,
        "view_name": this.addViewForm.viewName,
        "is_private": this.addViewForm.isShareToOthers,
      };
      let requestURL = environment.LocalDev_URL_V2  + this._viewModule + "/";

      this._dialogService.doPOST(requestURL, postBody).subscribe(
        {
          next: (response: any) => {
            this.dialogRef.close(response);
            this._utilService.showSuccess('', 'View Created Successfully.');
          },
          error: (error) => {
            this.isLoaderForSave = false;
            this._utilService.showErrorMessage(error);

          },
          complete: () => {
            this.isLoaderForSave = false;
          }
        }
      );
    }
  }

  updateView() {
    if (!this.addViewForm.viewName) {
      return null
    } else {
      this.isLoaderForUpdate = true;
      let postBody = {
    // "filters": this.data.data.filterQuery ? this.data.data.filterQuery : {},
        "is_pin": this.addViewForm.isPinned,
        "view_name": this.addViewForm.viewName,
        "is_private": this.addViewForm.isShareToOthers,
      };
      let requestURL = environment.LocalDev_URL_V2  + this._viewModule + "/";
      this._dialogService.doPATCH(requestURL, postBody).subscribe(
        {
          next: (response: any) => {
            this.dialogRef.close(response);
            this._utilService.showSuccess('', 'View Updated Successfully.');
          },
          error: (error) => {
            this.isLoaderForUpdate = false;
            this._utilService.showErrorMessage(error);

          },
          complete: () => {
            this.isLoaderForUpdate = false;
          }
        }
      );
    }
  }

  triggerClosePopupView() {
    // let el: HTMLElement = this.closePopup.nativeElement as HTMLElement;
    // el.click();
  }

}

