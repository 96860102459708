import { APIBaseService } from './apibase.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import MailEntity from '../models/entities/mail/mail-entity';
import MailManagerEntity from '../models/entities/mail/mail-manager-entity';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InboxActionApiService extends APIBaseService {
  constructor(private http: HttpClient, private configService: ConfigService) {
    super();
  }

  assignManager(request: any) {
    let url = super.prepareUrl(this.configService.assignManager);
    url = super.updateId(url, request.mailId);
    url = super.addQueryParamMailBoxId(url, request.mailBoxId);
    return this.http
      .patch(url, { manager: request.manager })
      .pipe(map((response: MailEntity) => response));
  }

  fetchAssignedManager(mailId: string, mailBoxId: string) {
    let url = super.prepareUrl(this.configService.getAssignedManager);
    url = super.updateId(url, mailId);
    url = super.addQueryParamMailBoxId(url, mailBoxId);
    return this.http
      .get(url)
      .pipe(map((response: MailManagerEntity) => response));
  }

  changeConversationStatus(request: any) {
    let newbody:any = {};
    newbody.status = request.status;
    if(request.is_move){
      newbody.is_move = true
    }
    let url = super.prepareUrl(this.configService.viewMailDetail);
    url = super.updateId(url, request.mailId);
    url = super.addQueryParamMailBoxId(url, request.mailBoxId);
    return this.http
      .patch(url,  newbody )
      .pipe(map((response: MailEntity) => response));
  }

  markConversationAsUnread(mailId: string,mailboxid) {
    let url = super.prepareUrl(this.configService.markConversationAsUnread);
    url = super.updateId(url, mailId);
    url =  url.replace('{MAILID}' ,mailboxid)
    return this.http
      .patch(url, { unread: true })
      .pipe(map((response: MailEntity) => response));
  }

  
}
