import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeaseForm } from '../../models/porperty-manager/leases';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { LeasesStoreService } from 'src/app/@fyxt/_services/leases/leases-store.service';
import { take, takeUntil, tap } from 'rxjs/operators';
import { SidebarService } from 'src/app/@fyxt/_services/_layout/sidebar.service';
import { UtilityService } from '../../../../services/utility.service';
import { ConfigService } from '../../../../services/config.service';
import { AddCompanyComponent } from '../add-company/add-company.component';
import { CompaniesService } from '../../../../Modules/_fyxt_modules/companies/_services/companies.service';
import { LeasesHttpService } from '../../../_services/leases/leases-http.service';
import { PrioritizationOptions } from '../../models/porperty-manager/inspection-scheduler';
import {format} from "date-fns";
import {LEASE_STATUS} from "../../../../Modules/_fyxt_modules/leases/leases.constants";
import { TextboxComponent } from '../../_reusable_ui/_controls/textbox/textbox.component';
import { DropdownComponent } from '../../_reusable_ui/_controls/dropdown/dropdown.component';
import { CheckboxComponent } from '../../_reusable_ui/_controls/checkbox/checkbox.component';
import { DropdownDateSelectComponent } from '../../_reusable_ui/_controls/dropdown-select/dropdown-date-select/dropdown-date-select.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { DropdownSelectComponent } from '../../_reusable_ui/_controls/dropdown-select/dropdown-select/dropdown-select.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../_reusable_ui/_components/button/button.component';
import { NgIf, AsyncPipe } from '@angular/common';


interface CreateLeaseForm extends Record<keyof LeaseForm, FormControl> {
}

const noneTemplate = { id: null, name: 'None' };

@Component({
    selector: 'fyxt-create-lease',
    templateUrl: './create-lease.component.html',
    styleUrls: ['./create-lease.component.scss'],
    standalone: true,
    imports: [NgIf, ButtonComponent, MatIconModule, MatDividerModule, FormsModule, ReactiveFormsModule, DropdownSelectComponent, NgxIntlTelInputModule, MatTooltipModule, DropdownDateSelectComponent, CheckboxComponent, DropdownComponent, TextboxComponent, AsyncPipe]
})
export class CreateLeaseComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<null> = new Subject();
  public createLeaseForm!: FormGroup<CreateLeaseForm>;
  formSubmitted: boolean = false;
  inM2M: boolean = false;
  @Input() updateLease: boolean = false;

  private readonly templateNamesSubject$: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  public readonly templateNames$: Observable<any> =
    this.templateNamesSubject$.asObservable();

  isLoaderSave = false;
  isLoaderCreate = false;

  errorMessageDate = '';
  unitsNames: any[] = [];

  // Company Dropdown
  allCompaniesList: any[] = [];
  allCompaniesListPageNumber: number = 1;
  allCompaniesListTotal: number;
  allCompaniesListSize: number;
  companiesSearchInput: any = '';
  allCompaniesListPageCount: number;

  //Property Dropdown
  allPropertiesList: any[] = [];
  allPropertiesListPageNumber: number = 1;
  allPropertiesListTotal: number;
  allPropertiesListSize: number;
  propertiesSearchInput: any = '';
  allPropertiesListPageCount: number;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly leasesStoreService: LeasesStoreService,
    private readonly dialogService: DialogService,
    public utilService: UtilityService,
    public sidebarService: SidebarService,
    public configService: ConfigService,
    private readonly leasesHttpService: LeasesHttpService,
    private readonly companiesService: CompaniesService,
  ) {
  }

  ngOnInit(): void {
    const data = this.sidebarService.getData();
    this.getAllCompanyTypesDropdowns();
    this.getAllCompanyDropdowns();
    this.getAllPropertyDropdowns();
    this.leasesStoreService
      .loadInitPropertiesData()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
    this.getWithPrioritization();
    this.initializeForm(data);

    this.sidebarService.toggleRightSidebarStateObservable$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!res) {
          this.resetForm();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private resetForm() {
    setTimeout(() => {
      this.formSubmitted = false;
      this.createLeaseForm.reset();
      this.sidebarService.setFormConext('');
      this.initializeForm();
    }, 100);
  }

  private initializeForm(data = null): void {
    if (data) return this.initializeDraftForm(data);
    this.createLeaseForm = this.formBuilder.group({
      id: [null],
      company: [null, Validators.required],
      property: [null, Validators.required],
      units: [null, Validators.required],
      external_code: [null],
      commencement_date: [null],
      expiration_date: [null],
      is_month_to_month: [false],
      inspection_scheduler_id: [null, Validators.required],
      move_in_date: [null],
      move_out_date: [null],
      can_create_job: [false],
      can_approve_job: [false],
    });
    if (this.createLeaseForm.get('inspection_scheduler_id').value == null) {
      this.createLeaseForm.controls.inspection_scheduler_id.setValue(
        noneTemplate,
      );
    }
  }

  private initializeDraftForm(data) {
    this.createLeaseForm = this.formBuilder.group({
      id: [data?.id],
      company: [data?.tenant, Validators.required],
      property: [data?.property?.id && data?.property, Validators.required],
      units: [null, Validators.required],
      external_code: [data?.external_code],
      commencement_date: [data?.commencement_date],
      expiration_date: [data?.expiration_date],
      is_month_to_month: [data?.is_month_to_month],
      inspection_scheduler_id: [
        data?.inspection_scheduler_id,
        Validators.required,
      ],
      move_in_date: [data?.move_in_date],
      move_out_date: [data?.move_out_date],
      can_create_job: [data?.permission?.can_create_job],
      can_approve_job: [data?.permission?.can_approve_job],
    });
    if (data.unitsNames) {
      const units = [];
      this.unitsNames = data.unitsNames;
      if (data.units?.length) {
        const occupied = data.unitsNames
          .filter((v) => v.disabled)
          .map((v) => v.id);
        data.units.forEach((v) => {
          if (!occupied.includes(v.id)) {
            units.push(data.unitsNames.find((u) => u.id === v.id));
          }
        });
        this.createLeaseForm.controls.units.setValue(units);
      }
    }
    this.checkProperty(this.createLeaseForm.controls.property.value?.id);
    if (this.createLeaseForm.get('inspection_scheduler_id').value == null) {
      this.createLeaseForm.controls.inspection_scheduler_id.setValue(
        noneTemplate,
      );
    }
  }

  public companyDropdownSearch(event: any) {
    this.companiesSearchInput = event.term;
    this.allCompaniesListPageNumber = 1;
    this.getAllCompanyDropdowns();
  }

  private getAllCompanyDropdowns(pagination?: boolean) {
    let requestURL =
      `companies/?columns=['id', 'name']&query={'type': ['Tenant']}&page=` +
      this.allCompaniesListPageNumber +
      '&search=' +
      this.companiesSearchInput;
    this.leasesHttpService
      .doGETcc(requestURL)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (pagination) {
            this.allCompaniesList = this.allCompaniesList.concat(
              response.items,
            );
          } else {
            this.allCompaniesList = response.items;
          }
          this.allCompaniesListTotal = response.total;
          this.allCompaniesListSize = response.size;
          this.allCompaniesListPageCount = Math.round(
            this.allCompaniesListTotal / this.allCompaniesListSize,
          );
        },
        error: (error) => {
          this.configService.isLoader = false;
          this.utilService.showErrorMessage(error);
        },
        complete: () => {
          this.configService.isLoader = false;
        },
      });
  }

  public companyDropdownsScroll() {
    if (this.allCompaniesListPageNumber < this.allCompaniesListPageCount) {
      this.allCompaniesListPageNumber++;
      this.getAllCompanyDropdowns(true);
    }
  }

  private getAllPropertyDropdowns(pagination?: boolean) {
    let requestURL =
      `properties/dropdown/?source=pm` +
      '&search=' +
      this.propertiesSearchInput;
    this.leasesHttpService
      .doGETca(requestURL)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.allPropertiesList = response || [];
          this.checkProperty(this.createLeaseForm.controls.property.value?.id);
        },
        error: (error) => {
          this.configService.isLoader = false;
          this.utilService.showError('', 'Error Server Properties');
        },
        complete: () => {
          this.configService.isLoader = false;
        },
      });
  }

  public propertyDropdownSearch(event: any) {
    this.propertiesSearchInput = event.term;
    this.allPropertiesListPageNumber = 1;
    this.getAllPropertyDropdowns();
  }

  public propertyDropdownsScroll() {
    if (this.allPropertiesListPageNumber < this.allPropertiesListPageCount) {
      this.allPropertiesListPageNumber++;
      this.getAllPropertyDropdowns(true);
    }
  }

  private getAllCompanyTypesDropdowns() {
    this.companiesService
      .getAllCompanyTypes()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (responseList: any) => {
          this.companiesService.formDropDownTypes = responseList;
        },
        error: (error: any) => {},
        complete: () => {
        },
      });
  }

  public createNewCompanyPopup() {
    let popupData = {
      title: 'Create New Company',
      component: AddCompanyComponent,
      containerClass: 'modal_manage_views',
      data: {
        companyModal: true,
      },
    };

    this.dialogService
      .openModal(popupData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result) {
          this.allCompaniesListPageNumber = 1;
          this.getAllCompanyDropdowns();
        }
      });
  }

  public onClickMonth(event: boolean) {
    this.inM2M = event;
    if (this.checkDateError()) return;
    const property = this.createLeaseForm.get('property').value;
    if (property) this.onChangeProperty(property);
  }

  public onCreateClick(): void {
    this.formSubmitted = true;
    if (!this.createLeaseForm.controls.property.value)
      this.createLeaseForm.controls.property.setErrors(Validators.required);
    if (!this.createLeaseForm.controls.units.value?.length)
      this.createLeaseForm.controls.units.setErrors(Validators.required);
    if (!this.createLeaseForm.controls.commencement_date.value)
      this.createLeaseForm.controls.commencement_date.setErrors(Validators.required);
    if (this.inM2M && !this.createLeaseForm.controls.expiration_date.value)
      this.createLeaseForm.controls.expiration_date.setErrors(null);
    if (this.errorMessageDate != '') return;
    if (
      this.createLeaseForm.valid &&
      !this.isLoaderCreate &&
      !this.isLoaderSave
    ) {
      this.onCreateLease();
    }
  }

  public onSaveClick(): void {
    this.formSubmitted = true;
    this.createLeaseForm.controls.property.setErrors(null);
    this.createLeaseForm.controls.units.setErrors(null);
    this.createLeaseForm.controls.expiration_date.setErrors(null);
    this.createLeaseForm.controls.commencement_date.setErrors(null);
    if (
      this.createLeaseForm.valid &&
      !this.isLoaderSave &&
      !this.isLoaderCreate
    ) {
      if (this.createLeaseForm.get('id').value) {
        this.onUpdateLease();
      } else {
        this.onSaveLease();
      }
    }
  }

  private onCreateLease() {
    const units = this.createLeaseForm.getRawValue().units.map((v) => v.id);
    const inspection_scheduler_id =
      this.createLeaseForm.getRawValue().inspection_scheduler_id?.id;
    const external_code = this.createLeaseForm.getRawValue().external_code;
    const permission = {
      can_create_job: this.createLeaseForm.getRawValue().can_create_job,
      can_approve_job: this.createLeaseForm.getRawValue().can_approve_job,
    };
    this.isLoaderCreate = true;
    this.leasesStoreService
      .createLease({
        ...this.createLeaseForm.getRawValue(),
        permission,
        units,
        inspection_scheduler_id,
        external_code,
        type: LEASE_STATUS.NOT_STARTED,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          if (this.createLeaseForm.controls.id.value) {
            this.leasesHttpService
              .deleteLease(this.createLeaseForm.controls.id.value)
              .pipe(takeUntil(this.destroy$), take(1))
              .subscribe({
                next: () => {
                  this.isLoaderCreate = false;
                  this.utilService.showSuccess(
                    '',
                    'Lease Created Successfully.',
                  );
                  this.sidebarService.setEvent('Created');
                  this.onCancel();
                },
                error: (error) => {
                  this.isLoaderCreate = false;
                  this.utilService.showError(
                    'Lease Created Error',
                    this.leasesStoreService.parseServiceError(error),
                  );
                },
              });
          } else {
            this.isLoaderCreate = false;
            this.utilService.showSuccess('', 'Lease Created Successfully.');
            this.sidebarService.setEvent('Created');
            this.onCancel();
          }
        },
        error: (error) => {
          this.isLoaderCreate = false;
          this.utilService.showError('Lease Create Error', this.leasesStoreService.parseServiceError(error));
          // this.utilService.showError('Lease Created Error', error.message);
        },
      });
  }

  private onSaveLease(): void {
    const external_code = this.createLeaseForm.getRawValue().external_code;
    const permission = {
      can_create_job: this.createLeaseForm.getRawValue().can_create_job,
      can_approve_job: this.createLeaseForm.getRawValue().can_approve_job,
    };
    const units = (this.createLeaseForm.getRawValue().units || []).map(
      (v) => v.id,
    );
    const inspection_scheduler_id =
      this.createLeaseForm.getRawValue().inspection_scheduler_id?.id;
    this.isLoaderSave = true;
    this.leasesStoreService
      .createLease({
        ...this.createLeaseForm.getRawValue(),
        permission,
        units,
        inspection_scheduler_id,
        external_code,
        type: 'DRAFT',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.isLoaderSave = false;
          this.utilService.showSuccess('', 'Lease Saved Successfully.');
          this.sidebarService.setEvent('Saved');
          this.onCancel();
        },
        error: (error) => {
          this.isLoaderSave = false;
          this.utilService.showError('Lease Save Error', this.leasesStoreService.parseServiceError(error));
        },
      });
  }

  private onUpdateLease(): void {
    const external_code = this.createLeaseForm.getRawValue().external_code;
    const permission = {
      can_create_job: this.createLeaseForm.getRawValue().can_create_job,
      can_approve_job: this.createLeaseForm.getRawValue().can_approve_job,
    };
    const units = (this.createLeaseForm.getRawValue().units || []).map(
      (v) => v.id,
    );
    const inspection_scheduler_id =
      this.createLeaseForm.getRawValue().inspection_scheduler_id?.id;
    this.isLoaderSave = true;
    this.leasesStoreService
      .updateLease({
        ...this.createLeaseForm.getRawValue(),
        id: this.createLeaseForm.getRawValue().id,
        tenant: this.createLeaseForm.getRawValue().company?.id,
        property: this.createLeaseForm.getRawValue().property?.id,
        permission,
        units,
        inspection_scheduler_id,
        external_code,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.isLoaderSave = false;
          this.utilService.showSuccess('', 'Lease Saved Successfully.');
        },
        error: (error) => {
          this.isLoaderSave = false;
          this.utilService.showError('Lease Save Error', this.leasesStoreService.parseServiceError(error));
        },
        complete: () => {
          this.sidebarService.setEvent('Saved');
          this.onCancel();
        },
      });
  }

  public onCancel() {
    if (!this.isLoaderCreate && !this.isLoaderSave) {
      this.sidebarService.resetData();
      this.sidebarService.toggleCustom();
      this.sidebarService.setFormConext('Create Lease');
      this.resetForm();
    }
  }

  public onChangeProperty(e: any): void {
    if (!e?.id) return;
    this.createLeaseForm.controls.units.reset();
    this.unitsNames = [];
    this.leasesStoreService
      .getUnits(
        e.id,
        this.createLeaseForm.get('commencement_date').value,
        this.createLeaseForm.get('expiration_date').value,
        this.createLeaseForm.get('is_month_to_month').value ||
        !this.createLeaseForm.get('expiration_date')?.value,
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        const data = [];
        value.forEach((v) => {
          v.units.forEach((unit) => {
            const disabled = unit.status === 'Occupied';
            data.push({
              ...unit,
              value: `${v.address} - ${unit.name}`,
              address: v.address,
              disabled,
              selected: false,
            });
          });
        });
        this.unitsNames = data;
      });
    this.getWithPrioritization();
  }

  public onChangeCompany(e: any) {
    this.getWithPrioritization();
  }

  public onChangeUnit(e: any) {
    this.getWithPrioritization();
  }

  protected onChangeDate(e: any, type: string) {
    if (type === 'commencement_date')
      this.createLeaseForm.controls.move_in_date.setValue(e);
    if (type === 'expiration_date')
      this.createLeaseForm.controls.move_out_date.setValue(e);
    if (this.checkDateError()) return;
    this.onChangeProperty(this.createLeaseForm.get('property').value);
  }

  private checkDateError() {
    this.errorMessageDate = '';
    if (!this.createLeaseForm.get('expiration_date').value && this.inM2M)
      return false;
    if (
      this.createLeaseForm.get('expiration_date').value &&
      this.createLeaseForm.get('commencement_date').value &&
      new Date(this.createLeaseForm.get('expiration_date').value) < new Date(this.createLeaseForm.get('commencement_date').value)
    ) {
      this.errorMessageDate =
        'The Expiration Date must be more than the Commencement Date';
      return true;
    }
    return false;
  }

  private getWithPrioritization(options: PrioritizationOptions = {}) {
    if (this.createLeaseForm) {
      const { company, property, units } = this.createLeaseForm.getRawValue();
      if (company) options.tenant = company.id;
      if (property) options.property = property.id;
      if (units) options.units = units.map((v) => v.id);
    }

    this.leasesStoreService
      .getWithPrioritization(options)
      .pipe(takeUntil(this.destroy$))
      .pipe(
        tap((v) => {
          const data = [noneTemplate];
          const suggested = v.suggested.map((s) => ({
            id: s.id,
            name: s.name,
            group: 'Suggested Template',
          }));
          const all = v.all.map((s) => ({
            id: s.id,
            name: s.name,
            group: 'All Template',
          }));
          if (suggested?.length) data.push(...suggested);
          if (all?.length) data.push(...all);
          if (this.createLeaseForm && v?.suggested?.length) {
            this.createLeaseForm.controls.inspection_scheduler_id.setValue(
              suggested[0],
            );
          }

          const alreadySetId = this.createLeaseForm.controls['inspection_scheduler_id'].value;

          if (this.createLeaseForm && alreadySetId) {
            const existTemplate = data.find(t => t.id === alreadySetId) || noneTemplate;
            this.createLeaseForm.controls.inspection_scheduler_id.setValue(existTemplate);
          }

          if (this.createLeaseForm && !(alreadySetId) && !v?.suggested?.length) {
            this.createLeaseForm.controls.inspection_scheduler_id.setValue(
              noneTemplate,
            );
          }

          this.templateNamesSubject$.next(data);
        }),
      )
      .subscribe();
  }

  private checkProperty(id) {
    if (!id) return;
    if (!this.allPropertiesList.length) return;
    if (!this.allPropertiesList.some((v) => v.id === id)) {
      this.createLeaseForm.controls.property.setValue(null);
    }
  }
}
