import { Component } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Companies } from '../../../models/porperty-manager/companies';
import { LeasesStoreService } from '../../../../_services/leases/leases-store.service';
import { filter, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { UtilService } from '../../../../../services/v2/util.service';
import { ConfigService } from '../../../../../services/config.service';
import { LeasesHttpService } from '../../../../_services/leases/leases-http.service';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { ListComponent } from '../../../_reusable_ui/_components/list/list.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';


export interface CompanyDetailsForm {
  id: string,
  companyName: string,
}

interface CompanyVm extends Companies {
  active?: boolean
}
@Component({
    selector: 'fyxt-company-details',
    templateUrl: './company-details.component.html',
    styleUrls: ['./company-details.component.scss'],
    standalone: true,
    imports: [NgIf, LoaderSmallComponent, SearchInputComponent, InfiniteScrollModule, NgFor, ListComponent, NgClass, MatDialogModule, ButtonComponent, AsyncPipe]
})
export class CompanyDetailsComponent {
  private readonly destroy$: Subject<null> = new Subject();
  public isLoader: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<CompanyDetailsComponent>,
    private readonly leasesStoreService: LeasesStoreService,
    private readonly leasesHttpService: LeasesHttpService,
    private readonly utilService: UtilService,
    private readonly configService: ConfigService,
  ) { }

  clearSearchInput: BehaviorSubject<any> = new BehaviorSubject(null);
  searchInput$: BehaviorSubject<string> = new BehaviorSubject('');
  selectedCompanySubj$: BehaviorSubject<CompanyDetailsForm> = new BehaviorSubject({id: undefined, companyName: undefined})
  selectedCompany$: Observable<CompanyDetailsForm> = this.selectedCompanySubj$.asObservable();
  currentTenantId = null;

  // filteredListCompanies$: Observable<Companies[]> = combineLatest([
  //   this.leasesStoreService.currentLease$,
  //   this.leasesStoreService.companies$,
  //   this.searchInput$,
  //   this.selectedCompany$
  // ]).pipe(
  //   filter(([currentLease]) => !!currentLease),
  //   map(([currentLease, companies = [], search = '', selectedCompany]) => {
  //     return companies
  //       .filter(company =>
  //         (company?.id !== currentLease?.tenant?.id && (company.name || '').toLowerCase().includes(search.toLowerCase()))
  //       )
  //       .map(company => this.transformCompanyToVm(company, selectedCompany.id === company.id))
  //   }),
  // )
  /**
   * save view Trigger
   */

    // Company Dropdown
  allCompaniesList: any[] = [];
  allCompaniesListPageNumber: number = 1;
  allCompaniesListTotal: number;
  allCompaniesListSize: number;
  companiesSearchInput: any = '';
  allCompaniesListPageCount: number;
  allCompaniesListLoader: boolean = false;

  public ngOnInit(): void {
    this.getAllCompanyDropdowns();
    this.selectedCompany$
      .pipe(takeUntil(this.destroy$))
      .subscribe(v => {
        this.allCompaniesList = this.allCompaniesList.map(c => {
          c.active = c.id === v.id;
          return c;
        });
      });
    this.leasesStoreService.currentLease$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentLease => {
        this.currentTenantId = currentLease?.tenant?.id;
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public handleCompanyChange(event: Event, company: Companies): void {
    this.selectedCompanySubj$.next({id: company.id, companyName: company.name})
  }

  public saveLeaseCompany(): void {
    this.leasesStoreService.currentLease$
      .pipe(
        take(1),
        withLatestFrom(this.selectedCompany$),
        filter(([_, selectedCompany]) => !!selectedCompany.id),
        switchMap(([lease, selectedCompany]) => {
          this.isLoader = true;
          return this.leasesStoreService.updateLease({
            id: lease.id,
            tenant: selectedCompany.id,
          });
      }))
      .subscribe(
        {
          next: () => {
            this.utilService.showSuccess('', 'Lease Company Update Successfully.');
          },
          error: (error) => {
            this.utilService.showError('', 'Lease Company Update Error.');
          },
          complete: () => {
            this.dialogRef.close();
            this.isLoader = false;
          },
        }
      );
  }

  private transformCompanyToVm(company: Companies, isActive: boolean): CompanyVm {
    return {
      ...company,
      active: isActive
    }
  }

  onSearchChange(search: any) {
    this.searchInput$.next(search);
  }


  //
  public companyDropdownSearch(event: any) {
    this.companiesSearchInput = event;
    this.allCompaniesListPageNumber = 1;
    this.getAllCompanyDropdowns();
  }

  private getAllCompanyDropdowns(pagination?: boolean, loader: boolean = true) {
    let requestURL =
      `companies/?columns=['id', 'name']&query={'type': ['Tenant']}&page=` +
      this.allCompaniesListPageNumber +
      '&search=' +
      this.companiesSearchInput;
    this.allCompaniesListLoader = loader;
    this.leasesHttpService
      .doGETcc(requestURL)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (pagination) {
            this.allCompaniesList = this.allCompaniesList.concat(
              response.items,
            );
          } else {
            this.allCompaniesList = response.items;
          }
          this.allCompaniesListTotal = response.total;
          this.allCompaniesListSize = response.size;
          this.allCompaniesListPageCount = Math.ceil(
            this.allCompaniesListTotal / this.allCompaniesListSize,
          );
        },
        error: (error) => {
          this.allCompaniesListLoader = false;
          this.utilService.showErrorMessage(error);
        },
        complete: () => {
          this.allCompaniesListLoader = false;
        },
      });
  }

  public companyDropdownsScroll() {
    if (this.allCompaniesListPageNumber < this.allCompaniesListPageCount) {
      this.allCompaniesListPageNumber++;
      this.getAllCompanyDropdowns(true, false);
    }
  }

  scrollToEnd(e: any) {
    if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight) {
      this.companyDropdownsScroll();
    }
  }

   /** Track by Index  **/
   trackByIndex(index: number, item: any): number {
    return index;
  }
}
