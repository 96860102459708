
import { Injectable } from '@angular/core';
import { ColumnSortType, TableColumnTypes } from 'src/app/@fyxt/_shared/enums/table/table-column-types.enum';
import { AppTableSource } from 'src/app/@fyxt/_shared/models/app-table-model';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TableService {
  resizeColumnElement: any;
  public isColumnResizeUpdate = new BehaviorSubject<boolean>(false);
  public unCheckedTableRows: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  public isTotalTableRowsSelected = new BehaviorSubject<boolean>(false);
  public isAllCurrentPageSelected = new BehaviorSubject<boolean>(false);
  public checkedRowItems: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);


  constructor() { }

  setTableData(module: string, header: any, row: any, length?: number, pageIndex?: number, pageSize?: number, userRole?:any,checkedRows?:any[],unSelectedRows?:any[]) {
    //Dynamic headers
    if (module == "dashboard" || module == "company" || module == "contact" || module == "service-planner" || module == "eqipment" || module == "timeSheet" || module == "property" || module == "Tenant-portal" || module == 'Inbox' || module == "all-jobs" || module == 'jobCostApproval' || module == 'custom-tabs' || module == 'payments' || module == 'teams' || module == 'FILES') {

      header.map((obj) => {
        if (obj.value == "checkbox") {
          (obj.sort = ColumnSortType.none, obj.type = TableColumnTypes.checkbox, obj.className = 'table-checkbox', obj.resizeColumn = false)
        } else if (obj.value == "created_at" || obj.value == "start_date" || obj.value == "end_date" || obj.value == "last_activity" || obj.value == "date_added" || obj.value == "modified_at" || obj.value == "last_activity_at" || obj.inputType === 'date') {
          ((obj?.sort ? obj.sort = obj.sort : obj.sort = ColumnSortType.none), obj.type = TableColumnTypes.date, obj.className = 'table-header', obj.resizeColumn = true)
        }
        else if (obj.value == "stage") {
          ((obj?.sort ? obj.sort = obj.sort : obj.sort = ColumnSortType.none), obj.type = TableColumnTypes.dropdown, obj.className = 'table-header', obj.resizeColumn = false)
        }
        else if (obj.value == "priority" && userRole != 'Engineer' && module == "dashboard") {
          ((obj?.sort ? obj.sort = obj.sort : obj.sort = ColumnSortType.none), obj.type = TableColumnTypes.priority, obj.className = 'table-header', obj.resizeColumn = false)
        } else if (obj.value == "priority" && userRole == 'Engineer' && module == "dashboard") {
          ((obj?.sort ? obj.sort = obj.sort : obj.sort = ColumnSortType.none), obj.type = TableColumnTypes.textWithColor, obj.className = 'table-header', obj.resizeColumn = false)
        } else if (obj.value == "action") {
          ((obj?.sort ? obj.sort = obj.sort : obj.sort = ColumnSortType.none), obj.type = TableColumnTypes.action, obj.className = 'three-dot', obj.resizeColumn = false)
        } else if (obj.value == "status" && userRole =='Engineer' && module == "timeSheet") {
          ((obj?.sort ? obj.sort = obj.sort : obj.sort = ColumnSortType.none), obj.type = TableColumnTypes.textWithColor, obj.className = 'table-header', obj.resizeColumn = true)
        }else if (obj.value == "status" && userRole !='Engineer' && module == "timeSheet") {
          ((obj?.sort ? obj.sort = obj.sort : obj.sort = ColumnSortType.none), obj.type = TableColumnTypes.dropdown, obj.className = 'table-header', obj.resizeColumn = true)
        }
        else if (obj.value == "actions") {
          ((obj?.sort ? obj.sort = obj.sort : obj.sort = ColumnSortType.none), obj.type = TableColumnTypes.actions_columns, obj.className = 'table-header actions_columns', obj.resizeColumn = false)
        }
        else if (obj.value == "linked_jobs" || obj.value == "associated_email") {
          ((obj?.sort ? obj.sort = obj.sort : obj.sort = ColumnSortType.none), obj.type = TableColumnTypes.linkedJobsCount, obj.className = 'table-header', obj.resizeColumn = false)
        }
        else if (obj.value == "number_of_bids") {
          ((obj?.sort ? obj.sort = obj.sort : obj.sort = ColumnSortType.none), obj.type = TableColumnTypes.chipOnlyCount, obj.className = 'table-header', obj.resizeColumn = false)
        }
        else if (obj.value == "target_cost" || obj.value == "invoice_submitted" || obj.value == "invoice_approved") {
          ((obj?.sort ? obj.sort = obj.sort : obj.sort = ColumnSortType.none), obj.type = TableColumnTypes.amount, obj.className = 'table-header', obj.resizeColumn = false)
        }
        else if (obj.value == "managers" || obj.value == "followers" || obj.value == "engineers" || obj.value == "vendors" || obj.value == "approved_vendor" || obj.value == "tenant" || obj.value == "assigned_to" || obj.value == "categories" || obj.value == "manager" || obj.value == "vendor" || obj.value == "tenant") {
          ((obj?.sort ? obj.sort = obj.sort : obj.sort = ColumnSortType.none), obj.type = TableColumnTypes.usersListWithCountDropdown, obj.className = 'table-header min_170px', obj.resizeColumn = true)
        }
        else if (obj.value == "required_signature") {
          ((obj?.sort ? obj.sort = obj.sort : obj.sort = ColumnSortType.none), obj.type = TableColumnTypes.innerHTML, obj.className = 'table-header', obj.resizeColumn = false)
        }
        else if (obj.isEquipment_name) {
          obj.className = 'table-header  equipment_name';
        }
        else if (obj.value == "schedule_date" || obj.value == "schedule_duration" || obj.value == "schedule_starttime") {
          ((obj?.sort ? obj.sort = obj.sort : obj.sort = ColumnSortType.none), (obj?.type ? obj.type = obj.type : obj.type = TableColumnTypes.text), obj.className = 'table-header', obj.resizeColumn = true)
        }
        else {
          ((obj?.sort ? obj.sort = obj.sort : obj.sort = ColumnSortType.none), (obj?.type ? obj.type = obj.type : obj.type = TableColumnTypes.text), obj.className = 'table-header', obj.resizeColumn = true)
        }
        return obj
      }
      )
    }
    else { //static headers
      header
    }


    const source: AppTableSource = {
      headers: header,
      rows: row,
      length: length,
      pageIndex: pageIndex || 0,
      pageSize: pageSize,
      moduleName:module,
      checkedRows:checkedRows,
      unSelectedRows:unSelectedRows
    };
    return source;
  }

  fetchResizeColumnUpdate(): Observable<boolean> {
    return this.isColumnResizeUpdate.asObservable();
  }

}
