import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  AppTableRow,
  AppTableSource
} from 'src/app/@fyxt/_shared/models/app-table-model';
import {
  EditColumnsComponent,
  FileReminderComponent,
  ReplaceFileComponent,
  TabPhotobankPopupComponent,
  TabUploadFilesPopupComponent
} from 'src/app/@fyxt/_shared/_views';
import { forkJoin, of, Subject } from 'rxjs';
import { catchError, debounceTime, tap } from 'rxjs/operators';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { FilesService } from 'src/app/Modules/_fyxt_modules/companies/_services/files.service';
import { Router } from '@angular/router';
import { FileSaverService } from 'ngx-filesaver';
import { MatDialog } from '@angular/material/dialog';
import { FilesSliderComponent } from './files-slider/files-slider.component';
import { ConfigService } from 'src/app/services/config.service';
import { SelectionModel } from '@angular/cdk/collections';
import { FileReminderActionType } from '../../_popup_views/file-reminder/file-reminder.interface';
import { BrowserService } from 'src/app/Modules/_fyxt_modules/files-management/services/browser.service';
import { CreateFolderComponent } from 'src/app/Modules/_fyxt_modules/files-management/components/create-folder/create-folder.component';
import { BaseService } from '../../../../../services/base.service';
import { ToastrService } from 'ngx-toastr';
import { EntityType } from 'src/app/Modules/_fyxt_modules/files-management/file-manager.const';
import { ChipComponent } from '../../../_reusable_ui/_components/chip/chip.component';
import { NoDataFountComponent } from '../../../_reusable_ui/_components/no-data-fount/no-data-fount.component';
import { TableComponent } from '../../../_reusable_ui/_components/table/table.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { BreadcrumbsDirective } from '../../../../../Modules/_fyxt_modules/files-management/directives/breadcrumbs.directive';

@Component({
    selector: 'fyxt-tab-files',
    templateUrl: './tab-files.component.html',
    styleUrls: ['./tab-files.component.scss'],
    standalone: true,
    imports: [BreadcrumbsDirective, NgIf, SearchInputComponent, ButtonComponent, MatMenuModule, MatIconModule, NgClass, LoaderSmallComponent, TableComponent, NoDataFountComponent, NgFor, ChipComponent]
})
export class TabFilesComponent implements OnInit {
  @Input() isTenantPortal: boolean = false;
  @Input() dataSource!: any;
  @Input() entityType: any;
  @Input() entityId: any;
  @Input() filesSearchValue!: string;
  @Input() isLoader!: boolean;
  @Input() entityName: string = '';
  @Input() isReadonly: boolean = false;
  @Input() currentFilePath: string = '/';
  @Output() onSearchValueChange = new EventEmitter<Event>();
  @Output() onSortValueChange = new EventEmitter<Event>();
  @Output() onThreeDotMenuChange = new EventEmitter<Event>();
  @Output() isAllRowSelectValueChange = new EventEmitter();
  @Output() onBulkActionValueChange = new EventEmitter();
  @Output() onRowClickValueChange = new EventEmitter();
  @Output() onPaginationValueChange = new EventEmitter<Event>();
  @Output() onclick = new EventEmitter<any>();
  @Output() onFolderChange = new EventEmitter<any>();
  @Output() newFolderCreated = new EventEmitter<any>();

  selectedRows: any[] = [];
  selectedViewTableHeaders: any[] = [];
  selectedUserDetails: any;
  currentEvent: any;
  searchDebounce = new Subject();

  @ViewChild('deleteDialogTemplate')
  deleteDialogTemplate!: TemplateRef<any>;

  @ViewChild('bulkDeleteDialogTemplate')
  bulkDeleteDialogTemplate!: TemplateRef<any>;

  @ViewChild('deleteFolderTemplate')
  deleteFolderTemplate!: TemplateRef<any>;

  selectionTable = new SelectionModel<any>(true, []);
  clearSelection: boolean;

  displayedFolders: any = [];
  hiddenFolders: any = [];
  filesDisabled: boolean = false;
  @Input() inActiveProperty: boolean = false;

  constructor(
    public _dialogService: DialogService,
    private _fileService: FilesService,
    private router: Router,
    private fileSaverService: FileSaverService,
    public dialog: MatDialog,
    public configService: ConfigService,
    private browser: BrowserService,
    public changeDetector: ChangeDetectorRef,
    public _baseService: BaseService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.searchDebounce
      .pipe(debounceTime(300))
      .subscribe((val: any) => this.onSearchValueChange.emit(val));
      if ((this.entityType === 'PROPERTY' && this._baseService.isPermissionExist('view_property') && !this._baseService.isPermissionExist('add_property')) || this.inActiveProperty) {
        this.filesDisabled = true;
      } else {
        this.filesDisabled = false;
      }
    this.getAllGlobalSettings();
  }


  ngAfterContentChecked(): void {
    //Called after every check of the component's or directive's content.
    //Add 'implements AfterContentChecked' to the class.
    if (this.dataSource?.headers) {
      this.dataSource.headers = this.dataSource.headers.map((obj) => {
        if (obj.name === 'Name') {
          obj.className = 'table-header equipment_name';
        }
        return obj;
      });
    }

    if (this.isTenantPortal || this.isReadonly || this.filesDisabled) {
      if (this.dataSource?.headers) {
        // this.dataSource.headers = this.dataSource.headers.filter(obj => !(obj.value == 'checkbox' || obj.value == 'action'));
        this.dataSource.headers = this.dataSource.headers
          .filter((obj) => !(obj.value == 'checkbox' || obj.value == 'action'))
          .map((obj) => {
            if (obj.name === 'Name') {
              obj.className = 'table-header equipment_name';
            }
            return obj;
          });
      }
    }

    this.changeDetector.detectChanges();

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataSource?.currentValue) {
      if (this.selectedRows.length > 0) {
        this.dataSource.rows.map((filesData: any) => {
          this.selectedRows.map((checkedFilesData: any) => {
            if (filesData.id == checkedFilesData.id) {
              this.selectionTable.selected.push(filesData);
              return (filesData.checked = true);
            }
          });
        });
      }
    }
  }

  searchFiles(value: any): void {
    this.searchDebounce.next(value);
    this.filesSearchValue = value;
  }

  filesSortChange(sortState: any) {
    this.onSortValueChange.emit(sortState);
  }

  filesPageChange(paginationEvent: any) {
    this.onPaginationValueChange.emit(paginationEvent);
  }

  threeDotMenuChange(event: any) {
    this.onThreeDotMenuChange.emit(event);
  }

  isAllSelected(isSelected: any) {
    this.isAllRowSelectValueChange.emit(isSelected);
  }

  bulkActionPopup(item: any): void {
    const payload = {};
    this.onBulkActionValueChange.emit(payload);
  }

  onRowClick(event: any): void {
    this.onRowClickValueChange.emit(event);
  }

  tableRowClicked(event: any) {
    if (event.mimeType == 'application/folder') {
      if (this.currentFilePath == '/') {
        this.currentFilePath = '/' + event.filename;
      } else {
        this.currentFilePath = this.currentFilePath + '/' + event.filename;
      }

      this.onFolderChange.emit(this.currentFilePath);
    } else {
      let imageData = {
        rows: this.dataSource.rows
          .filter((f) => f.mimeType != 'application/folder')
          .map((f) => {
            return { ...f, source: 'DIRECT' };
          })
      };
      this._fileService.sliderData = imageData;
      this._fileService.currentFileClicked = event.key;
      this._fileService.isTenantPortal = this.isTenantPortal;
      //  this.router.navigate([`/pm/companies/${this._fileService.selectedEntityId}/files`]);
      this.filesPopUp(event);
    }
  }

  menuSelectedOption(event: any) {
    this.selectedUserDetails = event;
    if (event.label == 'Remove as a User') {
      this.openRemoveUser();
    } else if (event.label == 'Edit User') {
      this.inviteUserPopup();
    } else if (event.label == 'download') {
      // this.deletefile(event)
    }
  }

  downloadFile(event, type: string) {
    if (type == 'PHOTO_BANK') {
      event.filename = event.filename + '.jpg';
    }
    this.configService.isLoader = true;
    this._fileService.donwloadFile(event.key).subscribe(
      (data) => {
        this.configService.isLoader = false;
        this.fileSaverService.save(data, event.filename);
      },
      (err: any) => {
        this.configService.isLoader = false;
      }
    );
  }

  menuSelected(event) {
    this.currentEvent = event;
    const { label, source, url, filename, mimeType } = event;
    
    switch (label) {
      case 'delete':
        this.handleDelete(event);
        break;
        
      case 'replace':
        this.openReplaceFiles(event);
        break;
        
      case 'download':
        this.handleDownload(source, url, filename, event);
        break;
        
      case 'reminder':
        this.addReminder(event);
        break;
        
      default:
        console.warn('Unhandled event label:', label);
    }
  }
  
  handleDelete(event) {
    if (event.mimeType == 'application/folder') {

      const popupData = {
        title: 'Delete Folder',
        template: this.deleteFolderTemplate,
        confirmText: 'Delete',
        cancelText: 'Cancel',
        buttons: true,
        data: ''
      };
      
      this._dialogService.confirmDialog(popupData).subscribe((result) => {
        if (result) {
          this.deleteFolder(event);
        }
      });

    } else {

      const popupData = {
        title: 'Delete Files',
        template: this.deleteDialogTemplate,
        confirmText: 'Delete',
        cancelText: 'Cancel',
        buttons: true,
        data: ''
      };
      
      this._dialogService.confirmDialog(popupData).subscribe((result) => {
        if (result) {
          this.deleteFileEvent(true);
        }
      });
    }
  }
  
  handleDownload(source, url, filename, event) {
    switch (source) {
      case 'DIRECT':
        this.downloadFile(event, 'DIRECT');
        break;
        
      case 'URL':
        this.downloadFromUrl(url, filename);
        break;
        
      case 'PHOTO_BANK':
        this.downloadFile(event, 'PHOTO_BANK');
        break;
        
      default:
        console.warn('Unhandled download source:', source);
    }
  }
  
  downloadFromUrl(url, filename) {
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = url;
    tempLink.setAttribute('download', filename);
    tempLink.setAttribute('target', '_blank');
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  }

  deleteFileEvent(data) {
    this.dialog.closeAll();
    if (data) {
      this.onclick.emit({ event: 'delete', data: this.currentEvent });
      this.selectedRows = [];
    }
  }

  bulkDeleteFileEvent(data) {
    this.dialog.closeAll();
    if (data) {
      this.onclick.emit({ event: 'bulk delete', data: this.selectedRows });
      this.selectedRows = [];
    }
  }

  openRemoveUser() {
    let popupData = {
      title: 'File Upload',
      component: TabUploadFilesPopupComponent,
      data: {}
    };

    this._dialogService.openModal(popupData).subscribe((result) => {});
  }

  inviteUserPopup() {
    let popupData = {
      title: 'File Upload',
      component: TabUploadFilesPopupComponent
    };

    this._dialogService.openModal(popupData).subscribe((result) => {});
  }

  uploadFilesPopup() {
    this._fileService.selectedEntityType = this.entityType;
    this._fileService.selectedEntityId = this.entityId;

    let popupData = {
      title: 'File Upload',
      component: TabUploadFilesPopupComponent,
      data: {
        entityId: `${this.entityId}`,
        entityType: this.entityType,
        isFileManager: true,
        path: this.currentFilePath
      }
    };

    // public _dialogService: DialogService
    this._dialogService.openModal(popupData).subscribe((result) => {
      this.onclick.emit({ event: 'upload', files: result });
      this.selectedRows = [];
    });
  }

  photoBankPopup() {
    let popupData = {
      title: 'Photobank',
      component: TabPhotobankPopupComponent,
      data: {
        entityId: `${this.entityId}`,
        entityType: this.entityType,
        currentFilePath: this.currentFilePath
      }
    };

    this._dialogService.openModal(popupData).subscribe((result) => {
      if (result?.event == 'photobank updated') {
        this.onclick.emit({ event: 'photobank updated' });
        this.selectedRows = [];
        this.clearSelection = true;
      } else if (result?.event == 'close') {
        let filesList = result.files.map((obj) => obj.data);
        this.onclick.emit({ event: 'upload', files: filesList });
        this.selectedRows = [];
        this.clearSelection = true;
      }
    });
  }

  editColumnPopup() {
    let popupData = {
      title: 'Edit Table Column',
      component: EditColumnsComponent,
      containerClass: ['modal-sm', 'modal_edit_colomn']
    };

    this._dialogService.openModal(popupData).subscribe((result) => {});
  }

  openReplaceFiles(event) {
    let popupData = {
      title: 'Replace',
      component: ReplaceFileComponent,
      containerClass: 'modal-md',
      data: {
        fileData: event,
        entityId: this.entityId,
        entityType: this.entityType
      }
    };

    this._dialogService.openModal(popupData).subscribe((result) => {
      if (result?.event == 'file replaced') {
        this.onclick.emit({ event: 'file replaced' });
        this.selectedRows = [];
      }
    });
  }

  addReminder(event) {
    let popupData = {
      title: 'Add Reminder',
      component: FileReminderComponent,
      containerClass: 'remider_model',
      data: event,
      type: FileReminderActionType.ADD,
      hide_header: true,
      val: {}
    };
    this._dialogService.openModal(popupData).subscribe((result) => {
      if (result.event == 'TABLE') {
        this.onclick.emit({ event: 'reminder added' });
        this.selectedRows = [];
      }
    });
  }

  bulkReminder() {
    let fileIds = [];
    for (let data of this.selectedRows) {
      fileIds.push(data.id);
    }
    let popupData = {
      title: 'Add Reminder',
      component: FileReminderComponent,
      containerClass: 'remider_model',
      data: fileIds,
      type: FileReminderActionType.BULK,
      hide_header: true,
      val: {}
    };
    this._dialogService.openModal(popupData).subscribe((result) => {
      if (result.event == 'TABLE') {
        this.onclick.emit({ event: 'upload' });
        this.clearSelection = true;
        this.selectionTable.clear();
        this.selectedRows = [];
      }
    });
  }

  filesPopUp(event) {
    let isFileDisabled = false;

    if(event?.type == 'INVOICE' && event?.isDisabled) {
      isFileDisabled = true;
    }

    if(this.isReadonly) {
      isFileDisabled = true;
    }


    let popupData = {
      title: 'Add Reminder',
      component: FilesSliderComponent,
      data: ''
    };

    this.dialog.open(FilesSliderComponent, {
      data: { isPublic: isFileDisabled },
      panelClass: 'custom-dialog-container'
    });
    this.dialog.afterAllClosed.subscribe((data) => {
      this.onFolderChange.emit(this.currentFilePath);
      this.selectedRows = [];
    });
  }

  rowOnChecked(event: any) {
    let fileRow: number[];
    const isFileChecked = this.selectedRows.some(
      (checkedFile) => checkedFile.id === event.id
    );
    if (isFileChecked) {
      fileRow = this.selectedRows.filter(
        (checkedFile) => checkedFile.id !== event.id
      );
    } else {
      fileRow = [...this.selectedRows, event];
    }
    this.selectedRows = fileRow;
  }

  bulkDownload() {
    this.configService.isLoader = true;
    let success = 0;
    let errors = 0;
    const reqs = this.selectedRows.map((url) =>
      this._fileService.donwloadFile(url.key).pipe(
        tap((_) => {
          success++;
          this.fileSaverService.save(_, url.filename);
        }),
        catchError((err) => {
          errors++;
          return of(err);
        })
      )
    );
    forkJoin(reqs).subscribe(
      () => {
        this.clearSelection = true;
        this.selectionTable.clear();
        this.selectedRows = [];
      },
      (err) => (this.configService.isLoader = false),
      () => (this.configService.isLoader = false)
    );
  }

  bulkDelete() {
    let popupData = {
      title: 'Delete Files',
      template: this.bulkDeleteDialogTemplate,
      confirmText: 'Delete',
      cancelText: 'Cancel',
      buttons: true
    };
    this._dialogService.confirmDialog(popupData).subscribe((result) => {
      if (result) {
        this.bulkDeleteFileEvent(true);
      }
      this.clearSelection = true;
      this.selectionTable.clear();
    });
  }

  private removeDuplicateBulkActionFiles(list: any): any {
    return list.reduce((unique, entry) => {
      if (!unique.some((obj) => obj.id == entry.id)) {
        unique.push(entry);
      }
      return unique;
    }, []);
  }

  allRowsSelected(event: any) {
    if (event) {
      this.selectedRows = [...this.selectedRows, ...this.dataSource.rows];
      this.selectedRows = this.removeDuplicateBulkActionFiles(
        this.selectedRows
      );
    } else {
      this.selectedRows = this.selectedRows.filter(
        (ar) => !this.dataSource.rows.find((rm: any) => rm.id === ar.id)
      );
      this.dataSource.rows.map((value: any) => {
        return (value.checked = false);
      });
    }
  }

  changePath(url, i) {
    if (i == 0) {
      this.currentFilePath = '/';
    } else {
      this.currentFilePath = this.browser.constructCurrentPathOnFolderClick(
        this.currentFilePath.split('/'),
        i
      );
    }
    this.onFolderChange.emit(this.currentFilePath);
  }

  protected openCreateFolderPopup() {
    let popupData = {
      title: 'File Upload',
      component: CreateFolderComponent
    };
    this._dialogService.openModal(popupData).subscribe((result: any) => {
      if (result) {
        this.createFolder(result.result);
      }
    });
  }

  private createFolder(folderName: string) {
    const payload = {
      name: folderName,
      entityId: `${this.entityId}`,
      entityType: this.entityType,
      path: this.currentFilePath
    };
    this.browser.createNewFolder(payload).subscribe((data) => {
      this.newFolderCreated.emit(true);
    });
  }

  breadCrumbs(event: any) {
    this.displayedFolders = event.folderList;
    this.hiddenFolders = event.moreFolders;
  }

  getAllGlobalSettings() {
    this.browser.getGlobalSettings().subscribe((r) => {
      this.browser.globalJobSettings = r;
    })
  }

  deleteFolder(event) {
    this._fileService
      .deleteFolder(
       this.currentFilePath + event.title,
        this.entityId,
        this.entityType
      )
      .subscribe(
        (data: any) => {
          this.onFolderChange.emit(this.currentFilePath);
          this.toastr.success('Folder Deleted');
        },
        (err) => {
          this.toastr.error(err?.error?.message ?? 'Error deleting folder');
        }
      );
  }
}
