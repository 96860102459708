import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {  
  public subDomain: string = '';

  constructor(private httpClient: HttpClient, private apollo: Apollo) {
    let url = window.location.origin;
    this.subDomain = this.extractDomain(url).split('.')[0];
    this.subDomain == 'localhost' ? this.subDomain = 'web' : this.subDomain;
  }

  extractDomain(url: string) {
    let domain;
    if (url.indexOf('://') > -1) {
      domain = url.split('/')[2];
    } else {
      domain = url.split('/')[0];
    }
    domain = domain.split(':')[0];
    return domain;
  }

  getAllActivites(entityId, entityType, date, createdById, pageNumber) {

    const filter: any = {
      parentEntityId: `${entityId}`,
      parentEntityType: entityType
    };

    if (date && date.from && date.to) {
      filter.startDate = date.from;
      filter.endDate = date.to;
    }

    const sort = {
      activityAt: 'DESC',
      createdAt: 'DESC'
    };

    const paginate = {
      show: 50,
      page: pageNumber
    };

    const body: any = {
      filter: filter,
      paginate: paginate,
      sort: sort
    };

    if (createdById.length > 0) {
      body.filter.createdByIds = createdById;
    }

    return this.httpClient.post(environment.activity.http + 'activities/get', body, {
       headers: { 'x-tenant-id': this.subDomain } 
    });
  }

  getAllActivityUser(entityId, entityType, Date?) {
    return this.apollo
      .use('activity')
      .query({
        query: this.getAllActivityUserQuery(entityId, entityType, Date)
      });
  }

  getAllActivityUserQuery(pEntityId, pEntityType, date?) {
    let date_range = ``;
    if (date.from == '' && date.to == '') {
      date_range = ``;
    } else {
      date_range = `,startDate:"${date.from}",endDate:"${date.to}"`;
    }
    return gql`query{
      activity_users(filter:{parentEntityType:"${pEntityType}",parentEntityId:"${pEntityId}"${date_range}}){
        createdById,
        user{
          first_name,
          last_name
        }

      }
    }`;
  }

  getAcitivtyCount(entityId, entityType) {

    const filter: any = {
      parentEntityId: `${entityId}`,
      parentEntityType: entityType
    };

    const body: any = {
      filter: filter,
      paginate: {},
      sort: {}
    };
    
    return this.httpClient.post(environment.activity.http + 'activities/count', body, {
      headers: { 'x-tenant-id': this.subDomain }})
  }
  
  transformActivityJobs(activity) {
     // Find and remove the object with "show_reason"
     const filteredArray = activity.filter(item => !item.hasOwnProperty('show_reason'));

     // Combine remaining objects into one
     const combinedObject = filteredArray.reduce((acc, item) => {
         return { ...acc, ...item };
     }, {});
 
     return this.reasonPositionChange([combinedObject]);
  }

  reasonPositionChange(array) {
    return array.map(item => {
        if (item.reason) {
            // Destructure the object and place "reason" at the end
            const { reason, ...rest } = item;
            return { ...rest, reason };
        }
        return item;
    });
}

}
