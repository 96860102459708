import { FormControl } from '@angular/forms';


export type T_COI_RISK = 'Low' | 'Medium' | 'High';

export interface ICoiForm {
  name: FormControl;
  company: FormControl;
  properties: FormControl;
  effective_date: FormControl;
  expiration_date: FormControl;
  company_insurer: FormControl;
  categories: FormControl;
  risk_level: FormControl;
  description: FormControl;
}

export interface IUpdateCoiForm {
  name: FormControl;
  company: FormControl;
  properties: FormControl;
  effective_date: FormControl;
  expiration_date: FormControl;
}

export interface ICreateCoi {
  title: string;
  company_id: string;
  company_type: string;
  properties: string[];
  effective_date: string;
  expiration_date: string;
}

export interface IDuplicateCoi {
  coi_id: string;
  title: string;
  company_id: string;
  company_type: string;
  properties: string[];
  effective_date: string;
  expiration_date: string;
}

export interface IUpdateCoi {
  title: string,
  company_id: string,
  company_type: string,
  insurer_company_id: string,
  risk_level: string,
  description: string,
  effective_date: string,
  expiration_date: string,
  property: string[],
  category: string[]
}

export interface IGetCOIsListResponse {
  coi: ICoi[]
  page: number
  size: number
  total: number
}

export interface IGetVendorCOIAvailability {
  has_for_property: boolean
  has_for_category: boolean
}

export interface IVendorCOIInput {
  vendor_id: string
  vendor_name: string
  property_id: string
  category_id: string
  has_coi: boolean
}

export interface IGetCOIsListParams {
  page?: number
  take?: number
  search?: string
  sort?: string
  order?: string
  companies?: string[]
  properties?: string[]
  columns?: Record<string, string>[]
  view_name?: string
}

export interface IGetVendorCOIsListParams {
  page?: number
  take?: number
  sort?: string
  order?: string
  vendor_id: string
  property_id: string
  category_id: string
  has_coi: boolean
}
export enum ExpiringWithinDateTypeLabel {
  DAYS = 'Day/s',
  MONTHS = 'Month/s',
  YEARS = 'Years/s',
}
export enum ExpiringWithinDateTypeValue {
  'Day/s' = 'days',
  'Month/s' = 'months',
  'Years/s' = 'years',
}

export enum ExpirationStatus {
  EXPIRED = 'has_expired',
  NOT_EXPIRED = 'has_not_expired',
}

export enum CoiCompanyType {
  TENANT = 'Tenant',
  VENDOR = 'Vendor',
}

export enum CoiFilter {
  PROPERTIES = 'Properties',
  EXPIRING_WITHIN = 'Expiring Within',
  EXPIRED = 'Expired',
  EXPIRING_ON_OR_AFTER = 'Expiring On or After',
  COMPANY_TYPE = 'Company Type',
  COMPANY = 'Company',
}

export interface ICoiFilters {
  companies?: string[]
  properties?: string[]
  company_type?: string
  expiration?: string
  expiring_on_or_after?: string
  expiring_within_value?: number
  expiring_within_date_type?: ''
}

export interface ICoi {
  id?: string;
  title: string;
  company_id: string;
  company_name: string;
  company_type: string;
  properties: IProperty[];
  effective_date: string;
  expiration_date: string;
  risk_level: T_COI_RISK;
  categories: ICategory[];
  description: string;
  insurer_company_id: string;
  insurer_company: ICompany;
}

export interface IProperty {
  id: string;
  name: string;
}

export interface ICategory {
  id: string;
  name: string;
}

export interface ICompany {
  id: string;
  name: string;
}
