// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datepicker-container {
  height: 15px !important;
}

.dash_between_inputs {
  margin-top: 12px;
  text-align: center;
}

::ng-deep .ng-placeholder {
  color: #192C49 !important;
}

.custom-btn {
  position: relative;
  z-index: 5;
}

.linkedJobFilterHeight {
  overflow: scroll;
  height: 57vh;
}

.h-custom-team {
  height: calc(80vh - 80px);
  overflow: auto;
  margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_reusable_ui/_components/filter/filter.component.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;AACJ;;AAGC;EACI,gBAAA;EACD,kBAAA;AAAJ;;AAGA;EACI,yBAAA;AAAJ;;AAGC;EACG,kBAAA;EACA,UAAA;AAAJ;;AAGA;EACE,gBAAA;EACA,YAAA;AAAF;;AAGA;EACE,yBAAA;EACA,cAAA;EACA,eAAA;AAAF","sourcesContent":[".datepicker-container {\n    height: 15px !important;\n}\n\n\n .dash_between_inputs {\n     margin-top: 12px;\n    text-align: center;\n }\n\n::ng-deep .ng-placeholder {\n    color: #192C49 !important;\n } \n\n .custom-btn {\n    position: relative;\n    z-index: 5;\n }\n\n.linkedJobFilterHeight {\n  overflow: scroll;\n  height: calc(100vh - 43vh);\n}\n\n.h-custom-team {\n  height: calc(80vh - 80px);\n  overflow: auto;\n  margin-right: 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
