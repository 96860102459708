import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import { DatePipe } from '@angular/common';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { ThumbnailComponent } from '../../../_reusable_ui/_components/thumbnail/thumbnail.component';

@Component({
    selector: 'app-delete-comment',
    templateUrl: './delete-comment.component.html',
    styleUrls: ['./delete-comment.component.scss'],
    standalone: true,
    imports: [ThumbnailComponent, MatDialogModule, ButtonComponent, DatePipe]
})
export class DeleteCommentComponent implements OnInit {
  deletedCommentDetails: any;
  image = 'assets/images/users/avatar-2.jpg';


  constructor(public dialogRef: MatDialogRef<DeleteCommentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.deletedCommentDetails = this.data?.data?.selectedComment;
  }

  ngOnInit(): void {
  }

  deleteChecklist(){
    this.dialogRef.close(this.deletedCommentDetails)
  }

}
