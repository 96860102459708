import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from 'src/app/services/base.service';
import { ConfigService } from 'src/app/services/config.service';
import { ChatService } from 'src/app/services/Chat/chat.service';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../utility.service';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { formatDate } from '@angular/common';
import { TicketFlowsService } from 'src/app/services/ticket-flows.service';
import { InboxService } from 'src/app/services/Inbox/inbox.service';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  currentUserInfo: any = localStorage.getItem('userobj')
    ? JSON.parse(localStorage.getItem('userobj'))
    : {};

  constructor(
    public _http: HttpClient,
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    public _Config: ConfigService,
    public _utilService: UtilityService,
    public _baseService: BaseService,
    public _ticketService: TicketFlowsService,
    public _chatService: ChatService,
    public _inboxService: InboxService
  ) { }

  newChatItem: any;
  dashboardCount: any;
  notificationsCount: any;
  inboxCount: any;

  
  

}
