import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  CreateLease,
  IUnitsResult,
  Lease,
  LeaseOptionsItem,
  UpdateLease,
} from 'src/app/@fyxt/_shared/models/porperty-manager/leases';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import {
  IContactsAPIResponse,
  IDocumentsAPIResponse,
  ILeasesAPIResponse,
  LeasesGraphqlService
} from './leases-graphql.service';
import { Property } from '../../_shared/models/porperty-manager/properties';
import { Companies } from '../../_shared/models/porperty-manager/companies';
import { CreateProvision, Provisions } from '../../_shared/models/porperty-manager/provisions';
import {
  ILeaseCategory,
  ILeaseCategoryRule,
  IRuleOptions,
  Rule
} from '../../_shared/models/porperty-manager/maintenance-terms-tab';
import { Contact, ContactOptions, Contacts } from '../../_shared/models/porperty-manager/contacts';
import { DocumentInput, DocumentItem, DocumentOptions } from '../../_shared/models/porperty-manager/documents';
import {
  IInspectionJobData,
  IInspectionJobItem,
  IInspectionJobTableItem,
  INSPECTION_STATUS,
  InspectionSchedulerInput,
  InspectionSchedulerOptions,
  InspectionSchedulerOutput,
  JOB_STATUS,
  LeaseInspectionInput,
  PrioritizationOptions,
  PropertiesForInspectionSchedulerOptions,
} from '../../_shared/models/porperty-manager/inspection-scheduler';
import { T_INSPECTION } from '../../../Modules/_fyxt_modules/leases/pages/lease-inspection/lease-inspection.interface';
import moment from 'moment';
import { LeasesHttpService } from './leases-http.service';
import {
  getFilePreviewUrl,
  getFileUrl,
} from './helpers'
import { BaseService } from '../../../services/base.service';
import { UtilityService } from '../../../services/utility.service';
import {LEASE_STATUS} from "../../../Modules/_fyxt_modules/leases/leases.constants";


export const takeParam = 25;
export const initLeaseParams: LeaseOptionsItem = {
  sort: 'expiration_date',
  order: 'ASC',
  search: '',
  take: takeParam,
  page: 1,
  type: 'CURRENT',
  filters: {
    companies: [],
    properties: [],
    units: [],
    external_code: [],
    from_date: '',
    to_date: '',
  },

};

export const initContactByLeaseParams: ContactOptions = {
  sort: '',
  order: '',
  search: '',
  take: takeParam,
  page: 1,
};

export const initDocumentByLeaseParams: DocumentOptions = {
  sort: '',
  order: '',
  search: '',
  take: takeParam,
  page: 1,
};

export const initInspectionSchedulerParams: InspectionSchedulerOptions = {
  id: null,
  sort: '',
  order: '',
  search: '',
  take: takeParam,
  page: 1,
};

@Injectable({ providedIn: 'root' })
export class LeasesStoreService {
  private readonly leasesSubject$: BehaviorSubject<ILeasesAPIResponse> = new BehaviorSubject<ILeasesAPIResponse>({});
  public readonly leases$: Observable<ILeasesAPIResponse> = this.leasesSubject$.asObservable();

  private readonly propertiesSubject$: BehaviorSubject<Property[]> = new BehaviorSubject<Property[]>([]);
  public readonly properties$: Observable<Property[]> = this.propertiesSubject$.asObservable();

  private readonly propertiesByPmSubject$: BehaviorSubject<Property[]> = new BehaviorSubject<Property[]>([]);
  public readonly propertiesByPm$: Observable<Property[]> = this.propertiesByPmSubject$.asObservable();

  private readonly companiesSubject$: BehaviorSubject<Companies[]> = new BehaviorSubject<Companies[]>([]);
  public readonly companies$: Observable<Companies[]> = this.companiesSubject$.asObservable();

  private readonly contactsSubject$: BehaviorSubject<IContactsAPIResponse> = new BehaviorSubject<IContactsAPIResponse>({});
  public readonly contacts$: Observable<IContactsAPIResponse> = this.contactsSubject$.asObservable();

  private readonly contactsByCompanySubject$: BehaviorSubject<Contacts[]> = new BehaviorSubject<Contacts[]>([]);
  public readonly contactsByCompany$: Observable<Contacts[]> = this.contactsByCompanySubject$.asObservable();

  private readonly primaryContactSubject$: BehaviorSubject<Contact> = new BehaviorSubject<Contact>(null);
  public readonly primaryContact$: Observable<Contact> = this.primaryContactSubject$.asObservable();

  private readonly provisionsSubject$: BehaviorSubject<Provisions[]> = new BehaviorSubject<Provisions[]>([]);
  public readonly provisions$: Observable<Provisions[]> = this.provisionsSubject$.asObservable();

  private readonly documentsByLeaseSubject$: BehaviorSubject<DocumentItem[]> = new BehaviorSubject<DocumentItem[]>([]);
  public readonly documentsByLease$: Observable<DocumentItem[]> = this.documentsByLeaseSubject$.asObservable();

  private readonly documentsSubject$: BehaviorSubject<IDocumentsAPIResponse> = new BehaviorSubject<IDocumentsAPIResponse>({});
  public readonly documents$: Observable<IDocumentsAPIResponse> = this.documentsSubject$.asObservable();

  private readonly categoriesSubject$: BehaviorSubject<ILeaseCategory[]> = new BehaviorSubject<ILeaseCategory[]>([]);
  public readonly categories$: Observable<ILeaseCategory[]> = this.categoriesSubject$.asObservable();

  private readonly rulesSubject$: BehaviorSubject<Rule[]> = new BehaviorSubject<Rule[]>([]);
  public readonly rules$: Observable<Rule[]> = this.rulesSubject$.asObservable();

  private readonly ruleOptionsSubject$: BehaviorSubject<IRuleOptions> = new BehaviorSubject<IRuleOptions>({} as IRuleOptions);
  public readonly ruleOptions$: Observable<IRuleOptions> = this.ruleOptionsSubject$.asObservable();

  private readonly inspectionSchedulersSubject$: BehaviorSubject<any> = new BehaviorSubject<InspectionSchedulerOutput>({} as InspectionSchedulerOutput);
  public readonly inspectionSchedulers$: Observable<InspectionSchedulerOutput> = this.inspectionSchedulersSubject$.asObservable();

  public readonly currentLeaseSubject$: BehaviorSubject<Lease> = new BehaviorSubject<Lease>(null);
  public readonly currentLease$: Observable<Lease> = this.currentLeaseSubject$.asObservable();

  public readonly currentLeaseDocumentSubject$: BehaviorSubject<DocumentItem> = new BehaviorSubject<DocumentItem>(null);
  public readonly currentLeaseDocument$: Observable<DocumentItem> = this.currentLeaseDocumentSubject$.asObservable();
  public currentViewingFile
  public currentFileClicked

  private readonly leaseInspectionsJobsSubject$: BehaviorSubject<IInspectionJobTableItem[]> = new BehaviorSubject<IInspectionJobTableItem[]>([]);
  public readonly leaseInspectionsJobs$: Observable<IInspectionJobTableItem[]> = this.leaseInspectionsJobsSubject$.asObservable();

  public eventLeases = 'open';
  private eventLeases$ = new BehaviorSubject<string>(this.eventLeases);
  public eventLeasesObservable$ = this.eventLeases$.asObservable();
  public queryLeaseParams: LeaseOptionsItem = { ...initLeaseParams };
  public filterLeaseChip: any = [];
  public filterDateRange: any = [];
  public filterLeaseList: any = [];
  public queryContactByLeaseParams: LeaseOptionsItem = initContactByLeaseParams;
  public queryDocumentByLeaseParams: LeaseOptionsItem = initDocumentByLeaseParams;
  public queryInspectionSchedulerParams: InspectionSchedulerOptions = initInspectionSchedulerParams;
  public leaseId = 0;
  public tenantUnits = [];

  constructor(
    private readonly leasesGraphqlService: LeasesGraphqlService,
    private readonly httpClient: LeasesHttpService,
    private readonly baseService: BaseService,
    private readonly _utilService: UtilityService,
  ) {
    this.baseService.currentUserInfo$
      .pipe(filter((r) => !!Object.values(r).length))
      .subscribe((r) => {
        this.tenantUnits = r?.associated_companies?.flatMap(v => v?.units) || [];
      })
  }

  public loadInitialData(): Observable<ILeasesAPIResponse> {
    return this.httpClient.getLeases(this.queryLeaseParams)
      .pipe(tap((data) => {
        const leases = data.leases.map(lease => ({ ...lease, units: lease.units.map(v => v?.name) }));
        this.leasesSubject$.next({ ...data, leases });
      }));
  }

  public loadCompaniesLeases() {
    return this.httpClient.getCompanyLeases(this.queryLeaseParams)
      .pipe(tap((data) => {
        const leases = data.leases.map(lease => ({ ...lease, units: lease.units.map(v => v?.name) }));
        this.leasesSubject$.next({ ...data, leases });
      }));
  }

  public loadPropertyLeases() {
    return this.httpClient.getPropertyLeases(this.queryLeaseParams)
      .pipe(tap((data) => {
        const leases = data.leases.map(lease => ({ ...lease, units: lease.units.map(v => v?.name) }));
        this.leasesSubject$.next({ ...data, leases });
      }));
  }

  public loadInitialTenantData(): Observable<ILeasesAPIResponse> {
    return this.httpClient.getLeases(this.queryLeaseParams)
      .pipe(tap((data) => {
        const leases = data.leases.map(lease => ({ ...lease, units: lease.units.map(v => v?.name) }));
        this.leasesSubject$.next({ ...data, leases });
      }));
  }

  public loadCountTabs(): Observable<any> {
    return this.httpClient.getCount();
  }

  public setQueryLeaseParams(params: LeaseOptionsItem): Observable<ILeasesAPIResponse> {
    const { sort, order, take, page, search, type, filters } = params;
    const { companies, properties, units, from_date, to_date, custom_attributes, external_code } = filters || this.queryLeaseParams.filters;
    this.queryLeaseParams = {
      sort: sort || typeof (sort) === 'string' ? sort : this.queryLeaseParams.sort,
      order: order || typeof (order) === 'string' ? order : this.queryLeaseParams.order,
      search: search || typeof (search) === 'string' ? search : this.queryLeaseParams.search,
      take: take || this.queryLeaseParams.take,
      page: page || this.queryLeaseParams.page,
      type: type || this.queryLeaseParams.type,
      filters: {
        companies: companies || this.queryLeaseParams.filters.companies,
        properties: properties || this.queryLeaseParams.filters.properties,
        units: units || this.queryLeaseParams.filters.units,
        external_code: external_code || this.queryLeaseParams.filters.external_code,
        from_date: (from_date ?? this.getDateIgnoringTimezone(from_date)) || typeof (from_date) === 'string' ? this.getDateIgnoringTimezone(from_date) : this.queryLeaseParams.filters.from_date,
        to_date: (to_date ?? this.getDateIgnoringTimezone(to_date)) || typeof (to_date) === 'string' ? this.getDateIgnoringTimezone(to_date) : this.queryLeaseParams.filters.to_date,
        ...custom_attributes,
      },
    };

    return this.loadInitialData();
  }

  public setQueryCompanyLeaseParams(params: LeaseOptionsItem): Observable<ILeasesAPIResponse> {
    const { sort, order, take, page, search, type, filters } = params;
    const { companies } = filters || this.queryLeaseParams.filters;
    this.queryLeaseParams = {
      sort: sort || typeof (sort) === 'string' ? sort : this.queryLeaseParams.sort,
      order: order || typeof (order) === 'string' ? order : this.queryLeaseParams.order,
      search: search || typeof (search) === 'string' ? search : this.queryLeaseParams.search,
      take: take || this.queryLeaseParams.take,
      page: page || this.queryLeaseParams.page,
      type: type || this.queryLeaseParams.type,
      filters: {
        companies: companies || this.queryLeaseParams.filters.companies,
      },
    };

    return this.loadCompaniesLeases();
  }

  public setQueryPropertyLeaseParams(params: LeaseOptionsItem): Observable<ILeasesAPIResponse> {
    const { sort, order, take, page, search, type, filters } = params;
    const { properties } = filters || this.queryLeaseParams.filters;
    this.queryLeaseParams = {
      sort: sort || typeof (sort) === 'string' ? sort : this.queryLeaseParams.sort,
      order: order || typeof (order) === 'string' ? order : this.queryLeaseParams.order,
      search: search || typeof (search) === 'string' ? search : this.queryLeaseParams.search,
      take: take || this.queryLeaseParams.take,
      page: page || this.queryLeaseParams.page,
      type: type || this.queryLeaseParams.type,
      filters: {
        properties : properties || this.queryLeaseParams.filters.properties,
      },
    };

    return this.loadPropertyLeases();
  }

  public setQueryLeaseTenantParams(params: LeaseOptionsItem): Observable<ILeasesAPIResponse> {
    const { sort, order, take, page, search, type, filters } = params;
    const { companies, properties, units, from_date, to_date } = filters || this.queryLeaseParams.filters;
    this.queryLeaseParams = {
      sort: sort || typeof (sort) === 'string' ? sort : this.queryLeaseParams.sort,
      order: order || typeof (order) === 'string' ? order : this.queryLeaseParams.order,
      search: search || typeof (search) === 'string' ? search : this.queryLeaseParams.search,
      take: take || this.queryLeaseParams.take,
      page: page || this.queryLeaseParams.page,
      type: type || this.queryLeaseParams.type,
      filters: {
        companies: companies || this.queryLeaseParams.filters.companies,
        properties: properties || this.queryLeaseParams.filters.properties,
        units: units?.length ? units : this.tenantUnits,
        from_date: (from_date ?? this.getDateIgnoringTimezone(from_date)) || typeof (from_date) === 'string' ? this.getDateIgnoringTimezone(from_date) : this.queryLeaseParams.filters.from_date,
        to_date: (to_date ?? this.getDateIgnoringTimezone(to_date)) || typeof (to_date) === 'string' ? this.getDateIgnoringTimezone(to_date) : this.queryLeaseParams.filters.to_date,
      },
    };

    return this.loadInitialTenantData();
  }

  public setQueryContactByLeaseParams(params: ContactOptions): Observable<IContactsAPIResponse> {
    const { sort, order, take, page, search } = params;
    this.queryContactByLeaseParams = {
      sort: sort || typeof (sort) === 'string' ? sort : this.queryContactByLeaseParams.sort,
      order: order || typeof (order) === 'string' ? order : this.queryContactByLeaseParams.order,
      search: search || typeof (search) === 'string' ? search : this.queryContactByLeaseParams.search,
      take: take || this.queryContactByLeaseParams.take,
      page: page || this.queryContactByLeaseParams.page,
    };

    return this.loadContactsByLease();
  }

  public setQueryDocumentByLeaseParams(params: DocumentOptions, save = true) {
    const { search, sort, order, page, take } = params;
    this.queryDocumentByLeaseParams = {
      sort: sort || typeof (sort) === 'string' ? sort : this.queryDocumentByLeaseParams.sort,
      order: order || typeof (order) === 'string' ? order : this.queryDocumentByLeaseParams.order,
      search: search || typeof (search) === 'string' ? search : this.queryDocumentByLeaseParams.search,
      take: take || this.queryDocumentByLeaseParams.take,
      page: page || this.queryDocumentByLeaseParams.page,
    };

    return this.loadDocumentsByLease(this.leaseId, save);
  }

  public setQueryInspectionSchedulerParams(params: InspectionSchedulerOptions) {
    const { search, sort, order, page, take, id } = params;
    this.queryInspectionSchedulerParams = {
      sort: sort || typeof (sort) === 'string' ? sort : this.queryInspectionSchedulerParams.sort,
      order: order || typeof (order) === 'string' ? order : this.queryInspectionSchedulerParams.order,
      search: search || typeof (search) === 'string' ? search : this.queryInspectionSchedulerParams.search,
      take: take || this.queryInspectionSchedulerParams.take,
      page: page || this.queryInspectionSchedulerParams.page,
      id: id || null,
    };

    return this.loadInspectionScheduler();
  }

  public loadInitPropertiesData(): Observable<Property[]> {
    return this.leasesGraphqlService.getPropertiesByPm().pipe(
      tap((p) => {
        this.propertiesSubject$.next(p);
      })
    );
  }

  public loadInitPropertiesByPmData(): Observable<Property[]> {
    return this.propertiesByPm$.pipe(
      take(1),
      filter((p) => !p.length),
      switchMap(() => {
        return this.leasesGraphqlService.getPropertiesByPm();
      }),
      tap((p) => {
        this.propertiesByPmSubject$.next(p);
      }),
    );
  }

  public loadInitCompaniesData(): Observable<Companies[]> {
    return this.leasesGraphqlService.getCompanies()
      .pipe(tap((p) => {
          this.companiesSubject$.next(p);
        }),
      );
  }

  public loadInitProvisionsData(lease_id: number, sort = 'id', order = 'desc'): Observable<Provisions[]> {
    return this.httpClient.getProvisions(lease_id, { sort, order })
      .pipe(tap((provisions) => {
        this.provisionsSubject$.next(provisions);
      }));
  }

  public loadInitRulesData(category_id: string, lease_id: number): Observable<Rule[]> {
    return this.leasesGraphqlService.getRules(category_id, lease_id)
      .pipe(tap((rules) => {
        this.rulesSubject$.next(rules);
      }));
  }

  public createRule(rule: Rule): Observable<Rule> {
    return this.leasesGraphqlService.createRule(rule);
  }

  public updateRule(id: number, rule: Rule): Observable<Rule> {
    return this.leasesGraphqlService.updateRule(id, rule)
      .pipe(
        tap(() => this.loadRulesCountData(rule.lease_id)),
      );
  }

  public deleteRule(id: number, lease_id: number): Observable<number> {
    return this.leasesGraphqlService.deleteRule(id)
      .pipe(
        tap(() => this.loadRulesCountData(lease_id)),
      );
  }

  public loadInitRuleOptionsData(): Observable<IRuleOptions> {
    return this.leasesGraphqlService.getRuleOptions()
      .pipe(tap((rules) => {
        this.ruleOptionsSubject$.next(rules);
      }));
  }

  public loadRulesCountData(lease_id: number) {
    this.leasesGraphqlService.getRulesCount(lease_id)
      .pipe(tap((data) => {
        const categories = this.categoriesSubject$.value;
        if (categories && categories.length) {
          if (data.length) {
            this.categoriesSubject$.next(categories.map(item => ({
              ...item,
              ruleCount: data.find(countItem => countItem.category_id === item.id)?.count || 0,
            })));
          } else {
            this.categoriesSubject$.next(categories.map(item => ({
              ...item,
              ruleCount: 0,
            })));
          }

        }
      })).subscribe();
  }


  public loadInitCategoriesData(lease_id: number): Observable<ILeaseCategory[]> {
    return this.leasesGraphqlService.getCategories(lease_id)
      .pipe(
        tap((categories) => {
          this.categoriesSubject$.next(categories);
          this.loadRulesCountData(lease_id);
        }),
      );
  }

  public updateLeaseCategoryConfig(lease_id: number, category_id: string, config: string): Observable<boolean> {
    return this.leasesGraphqlService.updateLeaseCategoryConfig(lease_id, category_id, config)
      .pipe(
        tap(() => {
          const categories = this.categoriesSubject$.value;
          const updatedCategories = categories.map(category => {
            if (category.id === category_id) {
              return { ...category, config, is_modified: true };
            }
            return category;
          });
          this.categoriesSubject$.next(updatedCategories);
        }),
      );
  }

  public loadInitLeaseData(lease_id: number) {
    return this.httpClient.getLease(lease_id)
      // .pipe(shareReplay(1))
      .pipe(tap((lease) => {
        this.leaseId = lease.id;
        this.currentLeaseSubject$.next(lease);
      }));
  }

  public createLease(lease: CreateLease): Observable<Lease[]> {
    if (lease.move_in_date) {
      lease.move_in_date = this.getDateIgnoringTimezone(lease.move_in_date);
    }
    if (lease.move_out_date) {
      lease.move_out_date = this.getDateIgnoringTimezone(lease.move_out_date);
    }
    if (lease.commencement_date) {
      lease.commencement_date = this.getDateIgnoringTimezone(lease.commencement_date);
    }
    if (lease.expiration_date) {
      lease.expiration_date = this.getDateIgnoringTimezone(lease.expiration_date);
    }
    return this.httpClient.createLease(lease);
  }

  public updateLease(createLease: UpdateLease): Observable<Lease> {
    if (createLease.move_in_date) {
      createLease.move_in_date = this.getDateIgnoringTimezone(createLease.move_in_date);
    }
    if (createLease.move_out_date) {
      createLease.move_out_date = this.getDateIgnoringTimezone(createLease.move_out_date);
    }

    return this.httpClient.updateLease(createLease)
      .pipe(tap((lease) => {
        this.currentLeaseSubject$.next(lease);
      }));
  }

  public addProvision(lease_id: number, provision: CreateProvision): Observable<Provisions> {
    provision.lease_id = lease_id;
    return this.httpClient.createProvision(lease_id, provision);
  }

  public createDocumentLease(id: number, document_id: string, title: string): Observable<DocumentItem> {
    return this.leasesGraphqlService.createDocumentLease(id, document_id, title);
  }

  public editProvision(provision: Provisions): Observable<Provisions> {
    return this.httpClient.updateProvision(provision.id, provision);
  }

  public deleteProvision(provisionId: number): Observable<{ id: number }> {
    return this.httpClient.deleteProvision(provisionId);
  }

  public deleteDocument(document_id: string): Observable<string> {
    return this.leasesGraphqlService.deleteDocument(document_id);
  }

  public clear(): void {
    this.leasesSubject$.next({});
  }

  public clearCurrentLease(): void {
    this.leaseId = null;
    this.currentLeaseSubject$.next(null);
  }

  public resetLeaseCurrentLease(lease_type: string) {
    this.currentLeaseSubject$.next({ ...this.currentLeaseSubject$.value, lease_type });
  }

  public getUnits(property_id: string, commencement_date: string = null, expiration_date: string = null, is_month_to_month: boolean = null): Observable<IUnitsResult[]> {
    const cd = commencement_date ? this.getDateIgnoringTimezone(commencement_date) : null;
    const ed = expiration_date ? this.getDateIgnoringTimezone(expiration_date) : null;
    return this.httpClient.getUnits(property_id, { commencement_date: cd, expiration_date: ed, is_month_to_month });
    // return this.leasesGraphqlService.getUnits(property_id, { commencement_date, expiration_date, is_month_to_month });
  }

  public resetEventLeases() {
    this.eventLeases = 'open';
    this.eventLeases$.next(this.eventLeases);
  }

  public setEventLeases(eventName: string) {
    this.eventLeases = eventName;
    this.eventLeases$.next(this.eventLeases);
  }

  public loadContactByCompany(company_id: string) {
    return this.leasesGraphqlService.getContactsByCompany(company_id)
      .pipe(tap((contacts) => {
        this.contactsByCompanySubject$.next(contacts);
      }));
  }

  public loadContactsByLease() {
    return this.leasesGraphqlService.getContactsByLease({
      lease_id: this.leaseId,
      options: this.queryContactByLeaseParams,
    })
      .pipe(tap((contacts) => {
        this.contactsSubject$.next(contacts);
      }));
  }

  public loadPrimaryContactById(id: string) {
    return this.httpClient.getContactById(id)
      .pipe(tap((contact) => {
        this.primaryContactSubject$.next(contact);
      }));
  }

  public loadDocumentsByLease(leaseId: number, save = true): Observable<IDocumentsAPIResponse> {
    return this.leasesGraphqlService.getDocumentsByLeaseId(leaseId, this.queryDocumentByLeaseParams)
      .pipe(tap((document) => {
        if (save) {
          this.documentsSubject$.next({ ...document, items: document.items.map((docItem) => ({
              ...docItem,
              document: {
                ...docItem.document,
                previewUrl: getFilePreviewUrl(docItem.document),
                url: getFileUrl(docItem.document),
              },
            }))});
        }
      }));
  }

  public editDocument(document_id: string, input: DocumentInput) {
    return this.leasesGraphqlService.updateDocument(document_id, input);
  }

  public bulkDocuments(ids: string[], action: string) {
    return this.leasesGraphqlService.bulkDocuments(ids, action);
  }

  public updateContacts(lease_id: number, assignedContactsIds: string[], unassignedContactsIds: string[]) {
    return this.httpClient.updateContacts(lease_id, assignedContactsIds, unassignedContactsIds)
        .pipe(tap((r: { assigned_contacts: string[], unassigned_contacts: string[] }) => {
          this.contacts$
              .pipe(take(1))
              .subscribe(contacts => {
                const copyContacts = { ...contacts };

                (r?.assigned_contacts || []).forEach((assignedId: string) => {
                  if (!copyContacts.ids.includes(assignedId)) {
                    copyContacts.ids.push(assignedId);
                    this.contactsSubject$.next(copyContacts);
                  }
                });
                (r?.unassigned_contacts || []).forEach((unassignedId: string) => {
                  if (copyContacts.ids.includes(unassignedId)) {
                    const index = copyContacts.ids.indexOf(unassignedId);
                    if (index !== -1) {
                      copyContacts.ids.splice(index, 1);
                    }
                    this.contactsSubject$.next(copyContacts);
                  }
                })

              })
        }));
  }
  public assignContact(lease_id: number, contact_id: string) {
    return this.httpClient.assignContact(lease_id, contact_id)
      .pipe(tap(r => {
            this.contacts$
              .pipe(take(1))
              .subscribe(contacts => {
              const copyContacts = { ...contacts };
              if (!copyContacts.ids.includes(r?.contact_id)) {
                copyContacts.ids.push(r?.contact_id);
                this.contactsSubject$.next(copyContacts);
              }
            })
          }));
    // return this.leasesGraphqlService.assignContact(lease_id, contact_id)
    //   .pipe(tap(r => {
    //     this.contacts$
    //       .pipe(take(1))
    //       .subscribe(contacts => {
    //       const copyContacts = { ...contacts };
    //       if (!copyContacts.ids.includes(r?.contact_id)) {
    //         copyContacts.ids.push(r?.contact_id);
    //         this.contactsSubject$.next(copyContacts);
    //       }
    //     })
    //   }));
  }

  public removeContact(lease_id: number, contact_id: string) {
    return this.httpClient.removeContact(lease_id, contact_id)
      .pipe(tap(r => {
        this.contacts$
          .pipe(take(1))
          .subscribe(contacts => {
          const copyContacts = { ...contacts };
          if (copyContacts.ids.includes(r?.contact_id)) {
            const index = copyContacts.ids.indexOf(r?.contact_id);
            if (index !== -1) {
              copyContacts.ids.splice(index, 1);
            }
            // copyContacts.ids = copyContacts.ids.filter(v => v != r?.contact_id);
            this.contactsSubject$.next(copyContacts);
          }
        })
      }));
  }

  public removeBulkContacts(lease_id: number, ids: string[]) {
    return this.httpClient.removeBulkContacts(lease_id, ids);
  }

  public bulkLeases(ids: number[], action: string) {
    return this.httpClient.leaseBulkAction(ids, action);
  }

  public loadInspectionScheduler() {
    return this.httpClient.getAllTemplates(this.queryInspectionSchedulerParams)
      .pipe(tap((result) => {
        this.inspectionSchedulersSubject$.next(result);
      }));
  }

  public createInspectionScheduler(template: InspectionSchedulerInput) {
    return this.httpClient.createInspectionScheduler(template);
  }

  public updateInspectionScheduler(id: number, template: InspectionSchedulerInput) {
    return this.httpClient.updateInspectionScheduler(id, template);
  }

  public updateInspectionLease(lease_id: number, template: LeaseInspectionInput) {
    if (template.move_in_date) {
      template.move_in_date = this.getDateIgnoringTimezone(template.move_in_date);
    }
    if (template.move_out_date) {
      template.move_out_date = this.getDateIgnoringTimezone(template.move_out_date);
    }
    return this.httpClient.updateInspectionLease(lease_id, template);
  }

  public loadInspectionSchedulerById(id: number) {
    return this.httpClient.getTemplateById(id);
  }

  public loadInspectionByLease(lease_id: number) {
    return this.httpClient.getInspectionByLease(lease_id);
  }

  public loadPropertiesForInspectionSchedulerById(id: number, options: PropertiesForInspectionSchedulerOptions) {
    return this.leasesGraphqlService.getPropertiesForInspectionSchedulerId(id, options);
  }

  public bulkInspectionScheduler(ids: number[], action: string) {
    return this.httpClient.bulkInspectionScheduler(ids, action);
  }

  public getLeaseInspectionJobsViaREST(lease_id: number) {
    return this.httpClient.getLeaseInspectionJobs(lease_id)
      .pipe(
        tap((jobs) => {
          let res = [];
          if (jobs && jobs.length) {
            res = jobs.map(j => {
              const res = {
                ...j,
              };

              if (res.status !== JOB_STATUS.COMPLETED) {
                if (moment().diff(res.target_date, 'days') < 0) {
                  res.status = INSPECTION_STATUS.PENDING;
                } else {
                  res.status = INSPECTION_STATUS.INCOMPLETE;
                }
              }

              const signatureStatuses = res.signature;
              res.signature_status = signatureStatuses;
              res.signature = {};
              Object.keys(signatureStatuses).map(item => res.signature[`${item}`] = true);
              res.required_signature = this._utilService.getSignatureTextColor(res);

              return res;
            })
          }
          this.leaseInspectionsJobsSubject$.next(res);
        })
      )
  }

  public getLeaseInspectionJobs(lease_id: number): Observable<IInspectionJobData[]> {
    return this.leasesGraphqlService.getLeaseInspectionJobs(lease_id)
      .pipe(
        tap((jobs) => {
          let res = [];
          if (jobs && jobs.length) {
            res = jobs.map(j => {
              const { job } = j;
              const res = {
                id: job.id,
                inspection_type: job.category.name,
                target_date: job.target_date,
                status: job.stage.name,
                property: job.property.name,
                unit: job.unit.name,
              };

              if (res.status !== JOB_STATUS.COMPLETED) {
                if (moment().diff(res.target_date, 'days') < 0) {
                  res.status = INSPECTION_STATUS.PENDING;
                } else {
                  res.status = INSPECTION_STATUS.INCOMPLETE;
                }
              }

              return res;
            })
          }
          this.leaseInspectionsJobsSubject$.next(res);
        })
      );
  }

  public triggerInspection(lease_id: number, date: string, inspection_type: T_INSPECTION, checklist_id: string, need_subtract_date: boolean): Observable<IInspectionJobItem[]> {
    return this.leasesGraphqlService.triggerInspection(lease_id, this.getDateIgnoringTimezone(date), inspection_type, checklist_id, need_subtract_date)
      .pipe(
        tap((newJobs) => {
          const mapped = newJobs.map(nj => {
            const res = {
              id: nj.id,
              inspection_type: nj.category.name,
              target_date: nj.target_date,
              status: nj.status,
              property: nj.property.name,
              unit: nj.unit.name,
            }

            if (res.status !== JOB_STATUS.COMPLETED) {
              if (moment().diff(res.target_date, 'days') < 0) {
                res.status = INSPECTION_STATUS.PENDING;
              } else {
                res.status = INSPECTION_STATUS.INCOMPLETE;
              }
            }

            return res;
          })
          this.leaseInspectionsJobsSubject$.next(mapped.concat(this.leaseInspectionsJobsSubject$.value));
        })
      );
  }

  public triggerInspectionREST(lease_id: number, date: string, inspection_type: T_INSPECTION, checklist_id: string, need_subtract_date: boolean): Observable<any> {
    const data = {
      lease_id,
      date: this.getDateIgnoringTimezone(date).split('T')[0],
      inspection_type,
      checklist_id,
      need_subtract_date
    }

    return this.httpClient.triggerInspection(data)
      .pipe(
        tap((jobs) => {
          let res = [];
          if (jobs && jobs.length) {
            res = jobs.map(j => {
              const res = {
                ...j,
              };

              if (res.status !== JOB_STATUS.COMPLETED) {
                if (moment().diff(res.target_date, 'days') < 0) {
                  res.status = INSPECTION_STATUS.PENDING;
                } else {
                  res.status = INSPECTION_STATUS.INCOMPLETE;
                }
              }

              const signatureStatuses = res.signature;
              res.signature_status = signatureStatuses;
              res.signature = {};
              Object.keys(signatureStatuses).map(item => res.signature[`${item}`] = true);
              res.required_signature = this._utilService.getSignatureTextColor(res);

              return res;
            })
          }
          this.leaseInspectionsJobsSubject$.next(res);
        })
      );
  }

  public endLease(lease_id: number) {
    return this.httpClient.endLease(lease_id)
      .pipe(tap(() => {
        this.currentLeaseSubject$.next({ ...this.currentLeaseSubject$.value, type: 'ENDED'});
      }));
  }

  public clearJobsList() {
    this.leaseInspectionsJobsSubject$.next([]);
  }

  public triggerInspectionList() {
    this.leaseInspectionsJobsSubject$.next([...this.leaseInspectionsJobsSubject$.value]);
  }

  public sortInspectionJobs(field: string, direction: 'asc' | 'desc') {
    const list = this.leaseInspectionsJobsSubject$.value;
    if (list && list.length) {
      this.leaseInspectionsJobsSubject$.next([...list.sort((a, b) => {
        const field1 = a[field];
        const field2 = b[field];

        if (direction === 'desc') {

          if (field1 > field2) {
            return 1;
          }

          if (field1 < field2) {
            return -1;
          }

        } else {

          if (field1 > field2) {
            return -1;
          }

          if (field1 < field2) {
            return 1;
          }

        }

        return 0
      })])
    }
  }

  public getWithPrioritization(options: PrioritizationOptions) {
    return this.httpClient.getTemplatesWithPriority(options);
  }


  /**
   * Get lease Info by Lease Id
   * @param lease_id
   * @returns
   */
  public getCategoriesRules(lease_id: number): Observable<Rule[]> {
    return this.leasesGraphqlService.getRulesByCategory(lease_id);
  }

  /**
   * Get Rule Info for Job by lease ID and Cat
   * @param category_id
   * @param lease_id
   * @returns
   */
    public getJobRules(category_id: string, lease_id: number): Observable<Rule[]> {
      return this.leasesGraphqlService.getRules(category_id, lease_id);
    }

  public getLeaseCategoryRules(lease_id: number, category_id: string): Observable<ILeaseCategoryRule> {
    return this.httpClient.getLeaseCategoryRules(lease_id, category_id);
  }


  public getDateIgnoringTimezone(date: number | string | Date): string {
    if (typeof date === 'string' && date.includes('00:00:00')) return date;

    if (!date) return '';
    return new Date(moment.utc(new Date(date)).add('minutes', moment().utcOffset()).unix() * 1000).toISOString()
  }

  public setUTCDate(dateVal: any) {
    if (!dateVal) return null;

    let dateArr = dateVal.split('-');

    let date = dateArr[2]
    if (date.includes('T')) {
      date = date.split('T')[0];
    }

    let d = new Date();
    // d.setDate(date);
    // d.setMonth(dateArr[1] - 1);
    d.setFullYear(dateArr[0], dateArr[1] - 1, date);

    return d;
  }

  public onSortingDocuments(sort: string, order: string ) {
      this.documents$.pipe(
        take(1),
        tap(v => {
        const items = v.items.sort((a, b) => {
          const sortA = sort === 'title' ? a : a.document;
          const sortB = sort === 'title' ? b : b.document;
          if (order === 'asc') {
            return sortA[sort].localeCompare(sortB[sort]);
          }
          if (order === 'desc') {
            return sortB[sort].localeCompare(sortA[sort]);
          }
          return 0;
        })
        const documents = {
          ...v,
          items,
        }
        this.documentsSubject$.next(documents)
      })).subscribe()
  }

  public parseServiceError(e: any): string {
      const { error, message } = e;
      if (!error) {
        return message;
      }

      const { errors } = error;
      if (!(errors && errors.length)) {
        return message;
      }

      return errors.reduce((acc, curr) => {
        const { message: errMsg } = curr;
        if (!message) {
          return acc;
        }

        return `${acc} ${errMsg}`;

      }, '')
  }

  updateImportantContact(param: { id: any; contact_primary: any }) {
    return this.httpClient.updateImportantContact(param.id, param?.contact_primary)
      .pipe(tap(lease => this.currentLeaseSubject$.next(lease)));
  }

  initColumns() {
    return this.httpClient.getColumns();
  }
  updateColumns(is_draft, params) {
    return this.httpClient.updateColumns(is_draft, params);
  }

  getAllViews(): Observable<any[]> {
      return this.httpClient.getAllViews();
  }

  getViewColumns(view_id: string) {
    return this.httpClient.getViewColumns(view_id);
  }

  exportSelectedTableData(columns: any[], view_name: string, file_type: string) {
    return this.httpClient.exportSelectedTableData({...this.queryLeaseParams, columns, file_type, view_name} as any);
  }

  getLeaseExternalCodesFilter(params: { page: number, search: string }) {
      return this.httpClient.getLeaseExternalCodeFilter(this.queryLeaseParams.type as string, params);
  }

  updateLeaseStatus(lease_id: number, status: LEASE_STATUS) {
      return this.httpClient.updateLeaseStatus(lease_id, status);
  }
}
