import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { TextEditorComponent } from '../../../_reusable_ui/_controls/text-editor/text-editor.component';

@Component({
    selector: 'app-add-comment',
    templateUrl: './add-comment.component.html',
    styleUrls: ['./add-comment.component.scss'],
    standalone: true,
    imports: [TextEditorComponent]
})
export class AddCommentComponent implements OnInit {
  comment: any;
  isComment: boolean = false;
  constructor(public dialogRef: MatDialogRef<AddCommentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.comment = this.data?.data?.selectedComment;
    this.isComment = this.data?.data?.isComment
  }

  ngOnInit(): void {
  }

  emitText(event:any){
    this.dialogRef.close(event);
  }
}
