import { Component, OnInit } from '@angular/core';
import { PhoneNoInputComponent } from '../../_reusable_ui/_controls/phone-no-input/phone-no-input.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonComponent } from '../../_reusable_ui/_components/button/button.component';
import { DropdownSelectComponent } from '../../_reusable_ui/_controls/dropdown-select/dropdown-select/dropdown-select.component';
import { TextboxComponent } from '../../_reusable_ui/_controls/textbox/textbox.component';
import { MatDividerModule } from '@angular/material/divider';

@Component({
    selector: 'fyxt-create-new-company',
    templateUrl: './create-new-company.component.html',
    styleUrls: ['./create-new-company.component.scss'],
    standalone: true,
    imports: [MatDividerModule, TextboxComponent, DropdownSelectComponent, ButtonComponent, MatTooltipModule, MatIconModule, PhoneNoInputComponent]
})
export class CreateNewCompanyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
