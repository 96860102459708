import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import { BaseService } from 'src/app/services/base.service';
// import InboxSettingsService from '../../modules/inbox-settings/services/inbox-settings.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';
import { NgIf, NgFor, NgClass } from '@angular/common';
// import { MessageDetailService } from '../../services/message-detail.service';

declare var $: any;

@Component({
    selector: 'app-survey-form',
    templateUrl: './survey-form.component.html',
    styleUrls: ['./survey-form.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, NgClass]
})
export class SurveyFormComponent implements OnInit {
  loading = true;
  feedBackForm: FormGroup = this.formbuilder.group({
    // email: new FormControl('',[Validators.required, Validators.email]),
    comment: new FormControl('', [Validators.required]),
    reason: new FormControl('')
  });
  userSatisfied = true;
  list: any = [
    'The issue took too long to resolve',
    'The service coordinator\'s knowledge is unsatisfactory',
    'The issue was not resolved',
    'The service coordinator\'s attitude is unsatisfactory',
    'Some other reason'
  ];
  submitted: boolean = false;
  radiochecked: boolean = false;
  tempStatus: boolean = true;
  mailInfo: any;
  feedbackForm: boolean = true;
  viewFeedbackBtn: boolean = true;
  logo: any;
  name: any;
  send_at:any;

  constructor(
    public formbuilder: FormBuilder,
    activatedroute: ActivatedRoute,
    public _utilService: UtilityService,
    public _baseService: BaseService,
    // public inboxSettingsService: InboxSettingsService,
    // private messageDetailsService: MessageDetailService
  ) {
    // let hostname = window.location.hostname;
    // let splitted = hostname.split(".")
    // if(splitted[1] == 'qafyxt'){
    //   this.logo =  'https://'+ splitted[0] +'.qaapifyxt.com/v1/account/logo/'
    // }
    // if(splitted[1] == 'fyxt'){
    //   this.logo =  'https://'+ splitted[0] +'.apifyxt.com/v1/account/logo/'
    // }
    // if(splitted[1] == 'demofyxt'){
    //   this.logo =  'https://'+ splitted[0] +'.demoapifyxt.com/v1/account/logo/'
    // }
    let hostname = window.location.hostname;

    if (hostname.includes('delta')) {
      this.logo = "assets/images/service_logo/logo_delta.png";
    }
    else if (hostname.includes('ots')) {
      this.logo = "assets/images/service_logo/logo_ots.png";
    }
    else if (hostname.includes('cmi')) {
      this.logo = "assets/images/service_logo/logo_cmi.png";
    }
    else if (hostname.includes('continuum')) {
      this.logo = "assets/images/service_logo/logo_continuum.png";
    }
    else if (hostname.includes('thevillagescpm')) {
      this.logo = "assets/images/service_logo/thevillagescpm.png";
    }
    else if (hostname.includes('thomasduke')) {
      this.logo = "assets/images/service_logo/thomasduke.png";
    }
    else if (hostname.includes('internaltest') || hostname.includes('friedmanstaging') || hostname.includes('friedmanv3') || hostname.includes('friedman')) {
      this.logo = "assets/images/service_logo/Friedman_Logo.png";
    }
    else if (hostname.includes('buckingham') || hostname.includes('buckinghamstaging')) {
      this.logo = "assets/images/service_logo/buckingham.png";
    }
    else if (hostname.includes('livedemo') || hostname.includes('csdemo') || hostname.includes('fornessproperties')) {
      this.logo = "assets/@fyxt/svgs/Fyxt_Logo_on_White.svg";
    }
    else if (hostname.includes('fcptstaging') || hostname.includes('fcpt') || hostname.includes('ryandemo')) {
      this.logo = "assets/images/service_logo/FCPT.png";
    }
    else if (hostname.includes('dietz')) {
      this.logo = "assets/images/service_logo/dietz.jpg";
    }
    else if (hostname.includes('goodwillkentucky')) {
      this.logo = "assets/images/service_logo/goodwill.png";
    }
    else if (hostname.includes('cawleypropertymanagement')) {
      this.logo = "assets/images/service_logo/cawley_logo.png";
    }
    else if (hostname.includes('bcwoodproperties')) {
      this.logo = "assets/images/service_logo/bc-wood-logo.png";
    }
    else if (hostname.includes('mcbpropertymgmt')) {
      this.logo = "assets/images/service_logo/mcb-logo.png";
    }
    else if (hostname.includes('bigskymed')) {
      this.logo = "assets/images/service_logo/bigskymed_logo.png";
    }
    else if (hostname.includes('localhost')) {
      this.logo = "assets/images/service_logo/bigskymed_logo.png";
    } else {
      this.logo = "../../assets/@fyxt/svgs/Fyxt_Logo_on_White.svg";
    }
    //this.logo = environment.dynamic_API[0].api + 'account/logo/'
    activatedroute.url.subscribe((u) => {
      this.mailInfo = activatedroute.snapshot.queryParams;
      // this.logo = "../../assets/@fyxt/svgs/Fyxt_Logo_on_White.svg"
      // if (this.mailInfo.logo == "None") {
      //   // this.logo = "../../assets/@fyxt/svgs/Fyxt_Logo_on_White.svg"
      // } else if (this.mailInfo.logo) {
      //  // this.logo = this.mailInfo.logo + '&Signature=' + this.mailInfo.Signature + '&Expires=' + this.mailInfo.Expires;
      // }
      // else {
      //  // this.logo = "https://fs.hubspotusercontent00.net/hubfs/7038650/friedman-real-estate-logo-transparent.png"
      // }

      this.name = this.mailInfo.email;
      if (this.mailInfo.feedback_id) {
        this.feedBackForm.disable();
        // this.fetchFeedback();
        this.loading = false;
        this.viewFeedbackBtn = false;
      } else {
        this.loading = false;
        this.feedBackForm.enable()
        this.userSatisfied = this.mailInfo.response == 1;
      }
    });
  }

  ngOnInit(): void {
  }

  submit() {
    this.submitted = true;
    let reason = this.feedBackForm.value.reason
    if (reason) {
      this.radiochecked = true;
    }
    if (this.feedBackForm.valid) {
      let requestURL = environment.baseURL + 'mail/feedbacks/';

      let feedbackData = {
        reason: reason,
        comment: this.feedBackForm.value.comment
      };

      let stringifiedData = JSON.stringify(feedbackData);

      let payload = {
        email: this.mailInfo.email,
        send_at:this.mailInfo.send_at,
        job: this.mailInfo.job_id,
        contact: this.mailInfo.contact,
        review_form: this.mailInfo.reviewForm,
        satisfied: this.mailInfo.response == 1 ? true : false,
        feedback: stringifiedData
      };
      this._baseService.doPOST(requestURL, payload).subscribe(
        (response: any) => {
          // this._utilService.showSuccess(
          //   'Success',
          //   'Thank you for the feedback'
          // );
          this.feedbackForm = false;
          // this.messageDetailsService.fetchActivities();
        },
        (error) => {
          this._utilService.showErrorMessage(error);
        }
      );
    }
  }

  // fetchFeedback() {
  //   this.inboxSettingsService.getFeedback(this.mailInfo.feedback_id).subscribe(
  //     (feedback) => {
  //       this.loading = false;
  //       this.userSatisfied = feedback.satisfied;
  //       const feedbackData = JSON.parse(feedback.feedback);
  //       this.feedBackForm.get('comment').setValue(feedbackData.comment);
  //       if(!this.userSatisfied) {
  //         this.feedBackForm.get('reason').setValue(feedbackData.reason);
  //       }
  //     },
  //     (error) => {
  //       this.loading = false;
  //       this._utilService.showErrorMessage(error);
  //     }
  //   );
  // }
}
