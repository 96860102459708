import { EquipmentsService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/equipments.service';
import { Component, Inject, Input, OnInit, Optional, EventEmitter, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ItemList } from 'src/app/@fyxt/_shared/_reusable_ui/_controls/dropdown-select/itemType.class';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { SidebarService } from 'src/app/@fyxt/_services/_layout/sidebar.service';
import { ActivatedRoute } from '@angular/router';
import { GraphqlEquipmentTypeService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/equipment-type/graphql-equipment-type.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilityService } from 'src/app/services/utility.service';
import { GraphQLError } from 'graphql';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DropdownSelectComponent } from '../../_reusable_ui/_controls/dropdown-select/dropdown-select/dropdown-select.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TextboxComponent } from '../../_reusable_ui/_controls/textbox/textbox.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../_reusable_ui/_components/button/button.component';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'fyxt-create-equipment-type',
    templateUrl: './create-equipment-type.component.html',
    styleUrls: ['./create-equipment-type.component.scss'],
    standalone: true,
    imports: [NgIf, ButtonComponent, MatIconModule, MatDividerModule, FormsModule, ReactiveFormsModule, NgClass, TextboxComponent, NgxIntlTelInputModule, DropdownSelectComponent, MatCheckboxModule]
})
export class CreateEquipmentTypeComponent implements OnInit {

  @Input() updateEquipmentType: boolean = false;

  @Output() onDateChange = new EventEmitter();


  items: ItemList[] = [
    { id: 1, label: 'Active', name: 'Active' },
    { id: 2, label: 'Inactive', name: 'Inactive' },
  ];

  get formControl() {
    return this.addEquipmentForm.controls;
  }

  manufacturerListItems: any[];
  Categories: any[];
  models: any[];
  modelsSearchInput: string = '';
  disableStatus = false;

  listStatus: ItemList[] = [
    { id: 1, label: 'Months', name: 'Months' },
    { id: 2, label: 'Years', name: 'Years' },
  ];


  addEquipmentForm!: FormGroup;

  formSubmitted: boolean = false;

  constructor(
    public formbuilder: FormBuilder,
    public _dialogService: DialogService,
    public sidebarService: SidebarService,
    public _utilService: UtilityService,
    private activatedRoute: ActivatedRoute,
    public _graphql: GraphqlEquipmentTypeService,
    public equipmentService: EquipmentsService,
    @Optional() @Inject(MAT_DIALOG_DATA) public matDialogData: any,
  ) { }

  ngOnInit(): void {
    this.initializeForm();
    this.manufacturerList('', 1);
    this.categoryList('', 1);
  }

  categoryList(search: string, page: number) {
    this._graphql.loadCategoryFilters().subscribe({
      next: (res: any) => {
        this.Categories = res || [];
      }
    })
  }

  modelsList(id: any, search: string, page: number) {
    this._graphql.loadModelsList(id, search, page).subscribe({
      next: (res: any) => {
        this.models = res.listFilterModels?.items || [];
      }
    })
  }

  manufacturerList(search: string, page: number) {
    this._graphql.loadManufacturerList(search, page).subscribe({
      next: (res: any) => {
        this.manufacturerListItems = res.listFilterManufacturers?.items || [];
      }
    })
  }
  /**
   * On Change Event
   * @param event
   */
  slideToggleChange(event: any): void {
    if (this.updateEquipmentType && this._graphql.equipmentTypeResponse.is_critical !== event) {
      this.onFocusOut(event, 'is_critical')
    }
  }

  onFocusOut(e: any, fieldName: any) {
    if (this.updateEquipmentType) {
      if ((e?.target?.value || e?.target?.value === '') && (e.target.value != this._graphql.equipmentTypeResponse[fieldName])) {
        let formValues: any = { [fieldName]: e.target.value }
        if (this.addEquipmentForm.value.warranty === '') {
          this.addEquipmentForm.get('warranty_term').setValue(null)
        }
        if (this.addEquipmentForm.value.expected_useful_life === '') {
          this.addEquipmentForm.get('expected_useful_life_term').setValue(null)
        }
        if (formValues.name === '') {
          this.addEquipmentForm.get('name').setValue(this._graphql.equipmentTypeResponse[fieldName]);
          this._utilService.showError('', 'EquipmentType Name Cannot be Empty');
        } else {
          this.updateEquipmentTypeDetails(formValues, fieldName)
        }
      }

      else {
        if (fieldName === 'manufacturer') {
          this.addEquipmentForm.get('model').setValue(null);
          if (e?.id) this.modelsList(e.id, this.modelsSearchInput, 1);
          if (e?.id === undefined) this.manufacturerList('', 1)
          if (e?.id === undefined && e?.name !== undefined) {
            this._graphql.createManufacturer(e.name).subscribe({
              next: (res: any) => {
                this.addEquipmentForm.get('manufacturer').setValue(res?.addManufacturer || null)
                this._utilService.showSuccess('', 'Manufacturer Added Successfully');
                this.addEquipmentForm.get('model').setValue(null);
                this.models = [];
                this.modelsList(res?.addManufacturer?.id, "", 1);
                let formValue: any = { [fieldName]: res?.addManufacturer?.id }
                formValue.model_id = null;
                this.updateEquipmentTypeDetails(formValue, fieldName);
              },
              error: (err: HttpErrorResponse) => {
                this.manufacturerList('', 1)
                const error = err.message;
                const messageWithoutBraces = error.slice(1, -1);
                const value = messageWithoutBraces.split('=')[1];
                this._utilService.showError('', value);
              },
            })
            this.manufacturerList('', 1)
          } else {
            this.addEquipmentForm.get('model').setValue(null);
            this.models = [];
            this.modelsList(e?.id, "", 1);
            let formValues: any = { [fieldName]: e?.id }
            formValues.model = null;
            this.updateEquipmentTypeDetails(formValues, fieldName);
          }
        }
        else if (fieldName === 'status' || fieldName === 'expected_useful_life_term' || fieldName === 'warranty_term') {
          let formValues: any = { [fieldName]: e.name }
          this.updateEquipmentTypeDetails(formValues, fieldName);
        }
        else if (fieldName === 'is_critical') {
          let formValues: any = { [fieldName]: e }
          this.updateEquipmentTypeDetails(formValues, fieldName);
        }
        else if (fieldName === 'category') {
          let formValues: any = { [fieldName]: e }
          this.updateEquipmentTypeDetails(formValues, fieldName);
        }
        else if (fieldName === 'model') {
          if (this.addEquipmentForm.get('manufacturer')?.value?.id) {
            if(e?.id===undefined){
              this.modelsList(this.addEquipmentForm.get('manufacturer')?.value?.id,'',1);
            }
          if(e?.name !== undefined && !this.models.some(obj => obj.name === e.name)){
            this._graphql.createModel(this.addEquipmentForm.get('manufacturer')?.value?.id,e.name).subscribe({
              next:(res:any)=>{
                this.modelsList(this.addEquipmentForm.get('manufacturer')?.value?.id, '', 1);
                this.addEquipmentForm.get('model').setValue(res?.addModel || null)
                this._utilService.showSuccess('', 'Model Added Successfully');
                let formValue: any = { [fieldName]: res?.addModel?.id };
                this.updateEquipmentTypeDetails(formValue, fieldName);
              },
              error:(err:GraphQLError) => {
                this.modelsList(this.addEquipmentForm.get('manufacturer')?.value?.id, '', 1);
                this.addEquipmentForm.get('model').setValue(null)
                const error = err.message;
                const messageWithoutBraces = error.slice(1, -1);
                const value = messageWithoutBraces.split('=')[1];
                this._utilService.showError('', value);
               },
            });
            this.modelsList(this.addEquipmentForm.get('manufacturer')?.value?.id,'',1);
          } else {
            let formValues: any = { [fieldName]: e.id }
            this.updateEquipmentTypeDetails(formValues, fieldName);
          }
          } else {
            this.addEquipmentForm.get('model').setValue(null);
            this._utilService.showError('', "Please select the manufacuturer");
          }
        }

      }
    }
    else if (fieldName === 'manufacturer' && !this.updateEquipmentType) {
      this.models = [];
      this.addEquipmentForm.get('model')?.setValue(null)
      if (e?.id) this.modelsList(e.id, '', 1);
      if (e?.id === undefined) this.manufacturerList('', 1)
      if (e?.id === undefined && e?.name !== undefined) {
        this._graphql.createManufacturer(e.name).subscribe({
          next: (res: any) => {
            this.addEquipmentForm.get('manufacturer').setValue(res?.addManufacturer || null)
            this._utilService.showSuccess('', 'Manufacturer Added Successfully');
          },
          error: (err: HttpErrorResponse) => {
            const error = err.message;
            const messageWithoutBraces = error.slice(1, -1);
            const value = messageWithoutBraces.split('=')[1];
            this._utilService.showError('', value);
          },
        })
      }
      this.manufacturerList('', 1);
    }
    else if (fieldName === 'model' && !this.updateEquipmentType) {
    if(this.addEquipmentForm.get('manufacturer')?.value?.id){
      if (e?.name !== undefined && !this.models.some(obj => obj.name === e.name)) {
        this._graphql.createModel(this.addEquipmentForm.get('manufacturer')?.value?.id, e.name).subscribe({
          next: (res: any) => {
            this.modelsList(this.addEquipmentForm.get('manufacturer')?.value?.id,'',1);
            this.addEquipmentForm.get('model').setValue(res?.addModel || null)
            this._utilService.showSuccess('', 'Model Added Successfully');
          },
          error: (err: HttpErrorResponse) => {
            this.modelsList(this.addEquipmentForm.get('manufacturer')?.value?.id,'',1);
            this.addEquipmentForm.get('model').setValue(null)
            const error = err.message;
            const messageWithoutBraces = error.slice(1, -1);
            const value = messageWithoutBraces.split('=')[1];
            this._utilService.showError('', value);
          },
        })
      }
      this.modelsList(this.addEquipmentForm.get('manufacturer')?.value?.id,'',1); 
    }else {
        this.models = [];
        this.addEquipmentForm.get('model').setValue(null)
        this._utilService.showError('', "Please select the manufacuturer");
      }
    }

  }

  onSearchDropdown(e: any, name: string) {
    if (name === 'manufacturer') {
      this.manufacturerList(e.term, 1);
    } else {
      if (this.addEquipmentForm.get('manufacturer')?.value?.id) this.modelsList(this.addEquipmentForm.get('manufacturer')?.value?.id, e.term, 1)
    }
  }

  updateEquipmentTypeDetails(formValues: any, fieldName: string) {
    this._graphql.updateEquipmentType(this._graphql.equipmentTypeResponse.id, formValues).subscribe({
      next: (res: any) => {
        this._utilService.showSuccess('', 'Equipment Type Updated Successfully');
        this._graphql.equipmentTypeResponse[fieldName] = res.updateEquipmentType[fieldName]
      },
      error: (err: HttpErrorResponse) => {
        const errorString = err.message;
        const regexPattern = /{([^=]+)=([^}]+)}/;
        const matches = errorString.match(regexPattern);
        if (matches) {
          const value = matches[2];
          this._utilService.showError('', value);
        }
        else {
          this._utilService.showError('', err.message);
        }
      }
    })
  }



  /**
 * Initialize Create Company Form
 */
  initializeForm() {
    this.addEquipmentForm = this.formbuilder.group({
      'name': ['', Validators.required],
      'category': [null, Validators.required],
      'status': [this.items[0].name],
      'manufacturer': [null],
      'model': [null],
      'warranty': [null],
      'warranty_term': [null],
      'expected_useful_life': [null],
      'expected_useful_life_term': [null],
      'is_critical': [false],
    });

    if (this.updateEquipmentType) {
      this.setFormData();
    }
  }

  /**
   * Set Create Company Form Data
   */
  setFormData() {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this._graphql.equipmentTypeDetail(id).subscribe({
      next: (res: any) => {
        if (!res?.getEquipmentType?.status_flag && res?.getEquipmentType?.status == "Active") {
          this.disableStatus = true;
        }
        this._graphql.equipmentTypeResponse = res.getEquipmentType;
      },
      error(err) {
        this._utilService.showErrorMessage(err.message);
      },
      complete: () => {
        this.addEquipmentForm.patchValue({
          name: this._graphql.equipmentTypeResponse.name,
          category: JSON.parse(this._graphql.equipmentTypeResponse.category),
          status: this._graphql.equipmentTypeResponse.status,
          manufacturer: JSON.parse(this._graphql.equipmentTypeResponse.manufacturer),
          model: JSON.parse(this._graphql.equipmentTypeResponse.model),
          warranty: this._graphql.equipmentTypeResponse.warranty,
          warranty_term: this._graphql.equipmentTypeResponse.warranty_term,
          expected_useful_life: this._graphql.equipmentTypeResponse.expected_useful_life,
          expected_useful_life_term: this._graphql.equipmentTypeResponse.expected_useful_life_term,
          is_critical: this._graphql.equipmentTypeResponse.is_critical,
        });
        this.modelsList(JSON.parse(this._graphql.equipmentTypeResponse.manufacturer).id, this.modelsSearchInput, 1);
      }
    })


  }

  onSubmit() {
    this.formSubmitted = true;
    if (this.addEquipmentForm.valid) {
      this.onCancel();
      this._graphql.createEquipmentType(this.addEquipmentForm.value);
    }
  }



  onCancel() {
    this.sidebarService.toggleRightSidebar = !this.sidebarService.toggleRightSidebar;

    setTimeout(() => {
      this.formSubmitted = false;
      this.addEquipmentForm.reset();
      this.sidebarService.setFormConext('');
      this.initializeForm();
    }, 100);

  }


  onDateSelected() {
    this.onDateChange.emit();
  }

  clearValues(name: string) {
    if (name === 'manufacturer') {
      this.addEquipmentForm.get(name).setValue(null);
      this.addEquipmentForm.get('model').setValue(null);
      let formValues = { [name]: null, model: null };
      const id = this.activatedRoute.snapshot.paramMap.get('id');
      this.models = [];
      // if(this.updateEquipmentType)
      this._graphql.updateEquipmentType(id, formValues).subscribe({
        next: (res: any) => {
          this._utilService.showSuccess('', 'Equipment Type Updated Successfully');
          this._graphql.equipmentTypeResponse[name] = res.updateEquipmentType[name]
        },
        error: (err: HttpErrorResponse) => {
          this._utilService.showError('', JSON.parse(err.message).detail);
        },
      });
    } else {
      this.addEquipmentForm.get(name).setValue(null);
      let formValues = { [name]: null };
      const id = this.activatedRoute.snapshot.paramMap.get('id');
      // if(this.updateEquipmentType)
      this._graphql.updateEquipmentType(id, formValues).subscribe({
        next: (res: any) => {
          this._utilService.showSuccess('', 'Equipment Type Updated Successfully');
          this._graphql.equipmentTypeResponse[name] = res.updateEquipmentType[name]
        },
        error: (err: HttpErrorResponse) => {
          this._utilService.showError('', JSON.parse(err.message).detail);
        },
      });

    }
    this.manufacturerList('',1);
  }

}
