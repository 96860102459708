import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgFor } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';

export interface RadioButtonItem {
  name: string;
  value: string;
  description?:string;
  checked?:boolean;
}

export const RADIO_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RadioComponent),
  multi: true,
};

let nextUniqueId = 0;

@Component({
    selector: 'fyxt-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss'],
    providers: [RADIO_VALUE_ACCESSOR],
    standalone: true,
    imports: [MatRadioModule, NgFor]
})
export class RadioComponent implements ControlValueAccessor {
  private _name: string = `group-${nextUniqueId++}`;

  @Input() disabled: boolean = false;
  @Input() customClass: string = '';
  @Output() change = new EventEmitter<any>();

  @Input()
  items!: Array<RadioButtonItem>;
  checked: any;

  get name(): string { return this._name; }
  set name(value: string) {
    this._name = value;
  }

  private innerValue!: string | number | boolean;
  get value(): string | number | boolean {
    return this.innerValue;
  }

  set value(v: string | number | boolean) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.toggleChange(v);
    }
  }

  onChange!: Function;
  onTouched!: Function;

  writeValue(value: string | number | boolean) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  /**
  *Func triggered on value change
  */
  toggleChange(value: string | number | boolean) {
    this.innerValue = value;
    this.change.emit(value);
  }
}
