import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { NgIf } from '@angular/common';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';

@Component({
    selector: 'fyxt-save-as-template',
    templateUrl: './save-as-template.component.html',
    styleUrls: ['./save-as-template.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, TextboxComponent, NgxIntlTelInputModule, NgIf, MatDialogModule, ButtonComponent]
})
export class SaveAsTemplateComponent implements OnInit {
  public form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<SaveAsTemplateComponent>,
    public formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup<any>({
      template_name: new FormControl('', [Validators.required])
    })
  }

  get templateName(): FormControl {
    return this.form.get('template_name') as FormControl;
  }

  saveAsTemplate(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return
    }

    const { template_name } = this.form.value;
    this.dialogRef.close({ template_name })
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
