import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';
import { GraphQLError } from 'graphql';
import { BaseService } from 'src/app/@fyxt/_services/core/base.service';

@Injectable({
  providedIn: 'root'
})
export class GraphqlEquipmentTypeService {

  constructor(private readonly apollo: Apollo,public _utilService:UtilityService,private router:Router,public _baseService:BaseService) { }
  @Output() additionalDataTabCount: EventEmitter<any> = new EventEmitter();
  @Output() additionalDataTab: EventEmitter<any> = new EventEmitter();
  AdditionaDataCount:number = 0;
  equipmentTabTotal:number = 0;
  equipmentTypeResponse:any;
  _viewModule: string = "equipment_type";

  getEquipmentTypeList(columns:any,isSearch:string,pagination:number,pageSize:number,sortColumn?:string,sortDirection?:string,filter?:any){
    return gql`query MyQuery {
      listEquipmentTypes(query: {
          query: "{ ${ filter !== undefined && filter[0]?.length > 0 ? `'name':[${encodeURIComponent(filter[0].map((n:string)=> `'${n}'`))}],`:""}${ filter !== undefined && filter[1]?.length > 0 ? `'category_name':[${encodeURIComponent(filter[1].map((n:string)=> `'${n}'`))}],`:""}  ${ filter !== undefined && filter[2]?.length > 0 ? `'manufacturer_name':[${encodeURIComponent(filter[2].map((n:string)=> `'${n}'`))}],`:""}${ filter !== undefined && filter[3]?.length > 0 ? `'model_name':[${encodeURIComponent(filter[3].map((n:string)=> `'${n}'`))}],`:""} ${ filter !== undefined && filter[4]?.length > 0 ? `'status':[${encodeURIComponent(filter[4].map((n:string)=> `'${n}'`))}],`:""} ${ filter !== undefined && filter[5]?.length > 0 ? `'additional_data_item':[${encodeURIComponent(filter[5].map((n:string)=> `'${n}'`))}],`:""}}",
          columns: "[${columns.map(e=>`'${e.value}'`)},'id']",
          search: "${isSearch}",
          page: ${pagination},
          size:${pageSize},
          ${sortColumn ? `sort_column: "${sortColumn}",` : ""}
          ${sortDirection ? `sort_order: "${sortDirection}",` : ""}
        }) {
        items {
          id
          name
          category_name
          manufacturer_name
          model_name
          total_warranty
          total_expected_useful_life
          is_critical
          status
        }
        page
        size
        total
      }
    }

    `
  }

  getCreateEquipmentType(formValues:any){
    return gql `mutation MyMutation {
      addEquipmentType(input: {
          name: "${formValues.name}",
          ${formValues.category && formValues.category?.id ? `category: "${formValues.category?.id}"` : ''}
          ${formValues.category && formValues.category?.name ? `category_name: "${formValues.category?.name}"` : ''}
          ${formValues.manufacturer && formValues.manufacturer.id ? `manufacturer_id: "${formValues.manufacturer.id}"` : ''}
          ${formValues.model && formValues.model.id ? `model_id: "${formValues.model.id}"` : ''}
          ${formValues.warranty > 0 && formValues.warranty !== null && formValues.warranty_term !== null && formValues.warranty_term.name ? `warranty: ${formValues.warranty}` : ''}
          ${formValues.warranty_term !== null && formValues.warranty_term.name && formValues.warranty > 0 && formValues.warranty !== null ? `warranty_term: "${formValues.warranty_term.name}"` : ''}
          ${formValues.expected_useful_life > 0 && formValues.expected_useful_life !== null && formValues.expected_useful_life_term !== null && formValues.expected_useful_life_term.name ? `expected_useful_life: ${formValues.expected_useful_life}` : ''}
          ${formValues.expected_useful_life_term !== null && formValues.expected_useful_life_term.name && formValues.expected_useful_life > 0 && formValues.expected_useful_life !== null ? `expected_useful_life_term: "${formValues.expected_useful_life_term.name}"` : ''}
          ${formValues.status && formValues.status.name ? `status: "${formValues.status.name}"` : ''}
          is_critical: ${formValues.is_critical}
        }) {
        id
        name
        category_name
        manufacturer_name
        model_name
        total_warranty
        total_expected_useful_life
        is_critical
        status
      }
    }
    `
  }

  getDuplicateEquipmentType(formValues:any){
    return gql `mutation MyMutation {
      addEquipmentType(input: {
          name: "${formValues.name}",
          ${formValues.category && formValues.category?.id ? `category: "${formValues.category?.id}"` : ''}
          ${formValues.category && formValues.category?.name ? `category_name: "${formValues.category?.name}"` : ''}
          ${formValues.manufacturer ? `manufacturer_id: "${formValues.manufacturer}"` : ''}
          ${formValues.model ? `model_id: "${formValues.model}"` : ''}
          ${formValues.warranty > 0 && formValues.warranty !== null ? `warranty: ${formValues.warranty}` : ''}
          ${formValues.warranty_term !== null  ? `warranty_term: "${formValues.warranty_term}"` : ''}
          ${formValues.expected_useful_life > 0 && formValues.expected_useful_life !== null ? `expected_useful_life: ${formValues.expected_useful_life}` : ''}
          ${formValues.expected_useful_life_term !== null && formValues.expected_useful_life_term ? `expected_useful_life_term: "${formValues.expected_useful_life_term}"` : ''}
          ${formValues.status ? `status: "${formValues.status}"` : ''}
          is_critical: ${formValues.is_critical}
        }) {
        id
        name
        category_name
        manufacturer_name
        model_name
        total_warranty
        total_expected_useful_life
        is_critical
        status
      }
    }
    `
  }


  getUpdateEquipmentType(id: string, formValues: any) {
    return gql`
      mutation MyMutation {
        updateEquipmentType(
          id: "${id}",
          input: {
            ${formValues.warranty ? `warranty: ${formValues.warranty},` : (formValues.warranty === '' ? `warranty: ${null},` :"")}
            ${formValues.warranty === '' ? `warranty_term: ${null},` : ""}
            ${formValues.warranty_term ? `warranty_term: "${formValues.warranty_term}",` : ""}
            ${formValues.category && formValues.category?.id ? `category: "${formValues.category?.id}"` : ''}
            ${formValues.category && formValues.category?.name ? `category_name: "${formValues.category?.name}"` : ''}
            ${formValues.expected_useful_life ? `expected_useful_life: ${formValues.expected_useful_life},` : (formValues.expected_useful_life === '' ? `expected_useful_life: ${null},` :"")}
            ${formValues.expected_useful_life === '' ? `expected_useful_life_term: ${null},` : ""}
            ${formValues.expected_useful_life_term ? `expected_useful_life_term: "${formValues.expected_useful_life_term}",` : ""}
            ${formValues.is_critical !== undefined && (formValues.is_critical === true || formValues.is_critical === false) ? `is_critical: ${formValues.is_critical},` : ''}
            ${formValues.manufacturer ? `manufacturer_id: "${formValues.manufacturer}",` : ""}
            ${formValues.manufacturer === null  ? `manufacturer_id: ${null},` : ""}
            ${formValues.model  ? `model_id: "${formValues.model}",` : ""}
            ${formValues.model === null  ? `model_id: ${null},` : ""}
            ${formValues.name ? `name: "${formValues.name}",` : ''}
            ${formValues.status ? `status: "${formValues.status}",` : ''}
          }
        ) {
          ${Object.keys(formValues).map(key => `${key}`).join('\n')}
        }
      }
    `;
  }

  getEquipmentTypeDetail(id:any){
    return gql `query MyQuery {
      getEquipmentType(id: "${id}") {
        id
        name
        category
        manufacturer
        model
        warranty
        warranty_term
        expected_useful_life
        expected_useful_life_term
        is_critical
        status
        status_flag
      }
    }`
  }

  deleteTypeQuery(id:any){
    return gql`mutation MyMutation {
      updateEquipmentType(
          id: "${id}",
          input: {
              active: false
        }) {
        message
      }
    }`;
  }

  associatedEquipments(id:any, isSearch:string, pagination:number, column?:string, sort?:string, pageSize?: any){
    return gql`
    query MyQuery {
      listAssociatedEquipment(
          id: "${id}",
          query: {
            search: "${isSearch}",
            page: ${pagination},
            size: ${pageSize ? pageSize : 25},
            ${column ? `sort_column: "${column}",` : ""}
            ${sort ? `sort_order: "${sort}",` : ""}
            }) {
        page
        size
        total
        items {
          id
          name
          property_name
          specific_location
          asset_tag
          serial_number
        }
      }
    }
    `
  }

  assignItems(id:any,isSearch:string, pagination:number){
    return gql`
    query MyQuery {
      listEquipmentTypeAssignItems(
          id: "${id}",
          query: {
            search: "${isSearch}",
            page: ${pagination},
            size: 100
            }) {
        items {
          id
          name
        }
        page
        size
        total
      }
    }`
  }

  manufacturerList(isSearch:string, pagination:number){
    return gql`
    query MyQuery {
      listFilterManufacturers(query: {
        search: "${isSearch}",
        page: ${pagination},
        size: 100
        }) {
        page
        size
        total
        items {
          id
          name
        }
      }
    }`
  }


  modelsList(id:any,isSearch:string, pagination:number){
    return gql `
    query MyQuery {
      listFilterModels(query: {
          search: "${isSearch}",
          page: ${pagination},
          #size: 50,
          manufacturer_id: "${id}"
        }) {
        items {
          name
          id
        }
        page
        size
        total
      }
    }`
  }

  assignItemEquipmentType(id:any,items:any){
    return gql`
    mutation MyMutation {
      addItemEquipmentType(id: "${id}",
        input: {
          items:${JSON.stringify(items).replace(/"(\w+)":/g, '$1:')}
        })
    }`
  }

  additionalData(id:any,isSearch:string, pagination:number, column?:any, sort?:any, pageSize?: any){
    return gql`
    query MyQuery {
      listAssociatedItemsEquipmentType(
          id: "${id}",
          query: {
              search: "${isSearch}",
              page: ${pagination},
              #columns: "",
              size: ${pageSize ? pageSize : 25},
              ${column ? `sort_column: "${column}",` : ""}
              ${sort ? `sort_order: "${sort}",` : ""}
            }) {
        items {
          additional_data_item
          additional_data_item_id
          id
          value
          is_required
        }
        page
        size
        total
      }
    }
    `
  }

  updateItemEquipmentType(id:any,formValues:any){
    return gql`
    mutation MyMutation {
      updateItemEquipmentType(
          id: "${id}",
          input: {
              is_required: ${formValues.required},
              value: "${formValues.name}"
            }) {
        id
        additional_data_item
        additional_data_item_id
        is_required
        value
      }
    }`
  }

  deleteItemsDataTab(id:any){
    return gql`
    mutation MyMutation {
      deleteItemEquipmentType(id: "${id}")
    }
    `
  }

  viewListQuery(){
    return gql `
    query MyQuery {
      listDashboardView(view_type: "equipment_type") {
        items {
          label
          data {
            id
            name
            pin
            default
            count
            created_by
          }
        }
      }
    }
    `;
  }

  getFilters(){
    return gql`
    query MyQuery {
      listFilterAdditionalItems(query: {
        #search: ""
      }) {
      items {
        id
        name
      }
    }

    listFilterManufacturers(query: {
      #search: ""
    }) {
    items {
      id
      name
    }
  }

  listFilterModels(query: {
      #search: "",
      #manufacturer_id: ""
    }) {
    items {
      name
      id
    }
  }

  listFilterEquipmentsTypes(query: {
    #search: ""
  }) {
  items {
    id
    name
  }
}

listFilterStatus {
    items {
      name
    }
  }

  }`

  }

  viewsTabListing(){
    return gql`
    query MyQuery {
      my_views : listManageViews(query: {
          view_type: "equipment_type",
          manage_tab: "my_views",
          #search: "",
          #page: 1,
          size: 50
        }) {
        items {
          id
          default
          pin
          shared
          name
          count
        }
        page
        size
        total
      }
      shared_by_others : listManageViews(query: {
          view_type: "equipment_type",
          manage_tab: "shared_by_others",
          #search: "",
          #page: 1,
          size: 50
        }) {
        items {
          id
          default
          pin
          shared
          name
          count
        }
        page
        size
        total
      }
      standard : listManageViews(query: {
          view_type: "equipment_type",
          manage_tab: "standard",
          #search: "",
          #page: 1,
          size: 50
        }) {
        items {
          id
          default
          pin
          shared
          name
          count
        }
        page
        size
        total
      }
    }`;
  }


  addManufacturer(name:string){
    return gql`
    mutation MyMutation {
      addManufacturer(
          input: {
              name: "${name}"
            }) {
        id
        name
      }
    }
    `;
  }

  addModel(id:any,name:string){
    return gql`
    mutation MyMutation {
      addModel(
          input: {
              name: "${name}",
              manufacturer_id: "${id}"
            }) {
        id
        name
      }
    }
    `;
  }

  filtersList() {
    return this.apollo.query({ query: this.getFilters()}).pipe(map(res=>res.data));
  }

  loadCategoryFilters(){
    let requestURL = environment.baseURL +'categories';
    return this._baseService.doGET(requestURL);
  }

  viewList(){
    return this.apollo.query({ query:this.viewListQuery()}).pipe(map(res=>res.data));
  }


  equipmentTypeDataList(columns:any,isSearch?:string, pagination?:number,pageSize?:number,sortColumn?:string,sortDirection?:string,filters?:any) {
    return this.apollo.query({ query: this.getEquipmentTypeList(columns,isSearch,pagination,pageSize,sortColumn,sortDirection,filters)}).pipe(map(res=>res.data));
  }

  equipmentTypeDetail(id:any){
    return this.apollo.query({ query: this.getEquipmentTypeDetail(id)}).pipe(map(res=>res.data));
  }

  updateEquipmentType(id:any,formValues:any){
    return this.apollo.mutate({
      mutation:this.getUpdateEquipmentType(id,formValues)
    }).pipe(map(res=>res.data));
  }

  createEquipmentType(formValues:any){
    this.apollo.mutate({
      mutation:this.getCreateEquipmentType(formValues)
    }).pipe(map(res=>res.data)).subscribe({
      next:(res:any)=>{
        this._utilService.showSuccess('', 'Equipment Type Created Successfully');
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          const currentUserInfo: any = localStorage.getItem('userobj') ? JSON.parse(localStorage.getItem('userobj')) : {};
          if (currentUserInfo.current_Role === "Owner" || currentUserInfo.current_Role === "Manager") {
            this.router.navigate([`/pm/equipment/equipmentType/${res.addEquipmentType!.id}`])
          }
          if (currentUserInfo.current_Role === "Engineer") {
            this.router.navigate([`/engineer/equipment/equipmentType/${res.addEquipmentType!.id}`])
          }  
            },
      error:(err:any) => {
        const errorMessage = err?.graphQLErrors?.[0]?.message?.split('=')?.[1]?.replace(/[{}]/g, '').trim() ?? err.message;
        this._utilService.showError('', errorMessage);
       },
    });
  }

  duplicateEquipmentType(formValues:any,duplicate:boolean,id?:any){
    this.apollo.mutate({
      mutation:this.getDuplicateEquipmentType(formValues)
    }).pipe(map(res=>res.data)).subscribe({
      next:(res:any)=>{
        if(duplicate){
          this.mapDuplicateEqupiment(res?.addEquipmentType?.id,id).subscribe((data:any)=>{
          this._utilService.showSuccess('', 'EquipmentType Duplicated Successfully');
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          const currentUserInfo: any = localStorage.getItem('userobj') ? JSON.parse(localStorage.getItem('userobj')) : {};
          if (currentUserInfo.current_Role === "Owner" || currentUserInfo.current_Role === "Manager") {
            this.router.navigate([`/pm/equipment/equipmentType/${res.addEquipmentType!.id}`])
          }
          if (currentUserInfo.current_Role === "Engineer") {
            this.router.navigate([`/engineer/equipment/equipmentType/${res.addEquipmentType!.id}`])
          }   
          })
        }else{
          this._utilService.showSuccess('', 'EquipmentType Duplicated Successfully');
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          const currentUserInfo: any = localStorage.getItem('userobj') ? JSON.parse(localStorage.getItem('userobj')) : {};
          if (currentUserInfo.current_Role === "Owner" || currentUserInfo.current_Role === "Manager") {
            this.router.navigate([`/pm/equipment/equipmentType/${res.addEquipmentType!.id}`])
          }
          if (currentUserInfo.current_Role === "Engineer") {
            this.router.navigate([`/engineer/equipment/equipmentType/${res.addEquipmentType!.id}`])
          }     
          }
      },
      error:(err:any) => {
        const errorMessage = err?.graphQLErrors?.[0]?.message?.split('=')?.[1]?.replace(/[{}]/g, '').trim() ?? err.message;
        this._utilService.showError('', errorMessage);
       },
    });
  }


  mapDuplicateEqupiment(newEqupimentId,oldEqupimentId){
    return this.apollo.query({ query: this.mapDuplicateEqupimentQuery(newEqupimentId,oldEqupimentId)}).pipe(map(res=>res.data));
  }


  mapDuplicateEqupimentQuery(newEqupimentId,oldEqupimentId){
    return gql `mutation MyMutation {
      duplicateEquipmentTypeItems(
          id: "${newEqupimentId}", #new ID
          input: {
            duplicate_equipment_type_id: "${oldEqupimentId}"  #old ID
              }) {
        count
      }
    }`

  }

  deleteEquipmentType(id:any){
    return this.apollo.mutate({
      mutation:this.deleteTypeQuery(id)
    }).pipe(map(res=>res.data));
  }

  associatedEquipmentsList(id:any,isSearch?:string, pagination?:number, column?:string, sort?:string, pageSize?: any){
    return this.apollo.query({ query: this.associatedEquipments(id, isSearch, pagination, column, sort, pageSize)}).pipe(map(res=>res.data));
  }

  loadAssignItems(id:any,isSearch?:string, pagination?:number){
    return this.apollo.query({ query: this.assignItems(id,isSearch,pagination)}).pipe(map(res=>res.data));
  }

  loadManufacturerList(isSearch?:string, pagination?:number){
    return this.apollo.query({ query: this.manufacturerList(isSearch,pagination)}).pipe(map(res=>res.data));
  }

  loadModelsList(id:any,isSearch?:string, pagination?:number){
    return this.apollo.query({ query: this.modelsList(id,isSearch,pagination)}).pipe(map(res=>res.data));
  }

  updateAssignItemEquipmentType(id:any,items:any){
    return this.apollo.mutate({
      mutation:this.assignItemEquipmentType(id,items)
    }).pipe(map(res=>res.data)).subscribe({
      next:(res:any)=>{
        this._utilService.showSuccess('', 'Item assigned successfully');
        if(res) this.additionalDataTab.emit(true);
      },
      error:(err:GraphQLError) => {
        this._utilService.showError('', err);
       },
    })
  }

  listAdditionalDataTab(id:any,isSearch?:string, pagination?:number, column?:any, sort?:any, pageSize?: any){
    return this.apollo.query({ query: this.additionalData(id,isSearch,pagination,column,sort, pageSize)}).pipe(map(res=>res.data));
  }

  updateAddtionalDataItem(id:any,formValues:any){
    return this.apollo.mutate({
      mutation:this.updateItemEquipmentType(id,formValues)
    }).pipe(map(res=>res.data));
  }

  deleteItems(id:any){
    return this.apollo.mutate({
      mutation:this.deleteItemsDataTab(id)
    }).pipe(map(res=>res.data)).subscribe({
      next:(res:any)=>{
        this._utilService.showSuccess('', 'Item unassigned successfully');
        this.additionalDataTab.emit(true);
      },
      error:(err:GraphQLError) => {
        this._utilService.showError('', err);
       },
    })
  }

  viewsListWithoutSelectingTabDetails(){
    return this.apollo.query({ query: this.viewsTabListing()}).pipe(map(res=>res.data));
  }

  loadFilterspageQueries(search:string,name:string,page?:number){
    return this.apollo.query({ query:this.loadFilterspageQuery(search,name,page) }).pipe(map(res=>res.data));
  }

  createManufacturer(name:string){
    return this.apollo.mutate({
      mutation:this.addManufacturer(name)
    }).pipe(map(res=>res.data));
  }

  createModel(id:any,name:string){
    return this.apollo.mutate({
      mutation:this.addModel(id,name)
    }).pipe(map(res=>res.data));
  }


  loadFilterspageQuery(search:string,name:string,page?:number) {
    return gql `
    query MyQuery {
      ${name}(query: {
          page:${page?page:1}
          #size: 10,
          search: "${search}"
        }) {
        page
        size
        total
        items {
          id
          name
        }
      }
    }`
  }
}
