import { Directive, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';

@Directive({
    selector: '[scrollSpy]',
    standalone: true
})

export class ScrollSpyDirective {
    @Input() public spiedTags = [];
    @Input() childElements: any;
    @Output() public sectionChange = new EventEmitter<string>();
    private currentSection: string;

    constructor(private _el: ElementRef) { }

    @HostListener('scroll', ['$event'])
    onScroll(event: any) {
        let currentSection: string;
        let margin = 24;
        const children = this._el.nativeElement.children;
        const scrollTop = event.target.scrollTop;
        const parentOffset = event.target.offsetHeight;
        for (let i = 0; i < children.length; i++) {
            const element = children[i];
            if (this.spiedTags.some(spiedTag => spiedTag === element.tagName)) {
                if(element.id == 'JobDetails') {
                    for(let j = 0; j < this.childElements.length; j++) {
                        const childElement = this.childElements[j];
                        
                        if(childElement.offsetHeight > 200) {
                            if((scrollTop + margin) >= childElement.offsetTop) {
                                currentSection = childElement.id;
                            }
                        }
                        else {
                            if((scrollTop + margin) >= (childElement.offsetTop - (childElement.offsetHeight / 4))) {
                                currentSection = childElement.id;
                            }
                        }
                    }
                }
                else {
                    if(element.offsetHeight > 200) {
                        if((scrollTop + margin) >= element.offsetTop) {
                            currentSection = element.id;
                        }
                    }
                    else {
                        if((scrollTop + margin) >= (element.offsetTop - (element.offsetHeight / 2))) {
                            currentSection = element.id;
                        }
                    }
                }
            }
        }
        if (currentSection !== this.currentSection) {
            this.currentSection = currentSection;
            this.sectionChange.emit(this.currentSection);
        }
    }
}  