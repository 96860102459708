// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-active-color {
  color: #f55600;
}

@media (max-width: 1600px) {
  span.Terms_condition {
    font-size: 12px !important;
  }
}
/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media (min-width: 1800px) {
  span.Terms_condition {
    font-size: 14px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/Modules/core/components/onboard/onboard.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE;IACE,0BAAA;EACF;AACF;AAEA,4EAAA;AACA;EACE;IACE,0BAAA;EAAF;AACF","sourcesContent":[".text-active-color {\n  color: #f55600;\n}\n\n@media (max-width:1600px)  {\n  span.Terms_condition {\n    font-size: 12px !important;\n  }\n}\n\n/* If the screen size is 601px or more, set the font-size of <div> to 80px */\n@media (min-width: 1800px) {\n  span.Terms_condition {\n    font-size: 14px !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
