import { ActivatedRoute, Router } from '@angular/router';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject, forkJoin, of } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { environment } from 'src/environments/environment';
import { KeycloakService } from 'keycloak-angular';
import { catchError, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  isLaunchDarklyLoaded = new Subject();
  isCategoryLoaded = new Subject();

  isPropertyVendor: boolean = false;
  isPropertyTenant: boolean = false;
  assigned_property: any = '';
  vendorUpdate: boolean = false;
  tenantUpdate: boolean = false;
  currentActiveState: any = '';
  currentUserInfo: any = localStorage.getItem('userobj')? JSON.parse(localStorage.getItem('userobj')): {};

  private readonly currentUserInfoSubject$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public readonly currentUserInfo$: Observable<any> = this.currentUserInfoSubject$.asObservable();
  editCurrentUserObj: any = {
    isEdit: false,
    isChangePassword: false,
    selectedItem: {}
  };

  allUserRoles: any[] = [];
  userRoleCount = new Subject();
  editRoleObj: any = {
    isEdit: false,
    selectedItem: {}
  };

  allTeamMembers: any[] = [];
  teamMembersCount = new Subject();
  editTeamMembersObj: any = {
    isEdit: false,
    selectedItem: {}
  };

  allSuspendedTeamMembers: any[] = [];

  allClients: any[] = [];
  editClientsObj: any = {
    isEdit: false,
    selectedItem: {}
  };

  allTenantVendors: any[] = [];
  tenant_id: any;

  allVendors: any[] = [];
  editVendorObj: any = {
    isEdit: false,
    selectedItem: {}
  };
  editPropertyObj: any = {
    isEdit: false,
    selectedItem: {}
  };

  allProperties: any[] = [];
  myTeamsProperties: any[] = [];
  allTeamsProperties: any[] = [];

  selectedTeamProperty: any = {};
  showPropertyMembersDetails: boolean = false;
  showAddEditFormProperty: boolean = false;

  allStates: any[] = [];
  allCities: any[] = [];
  allWorkCategories: any[] = [];
  allTenantWorkCategories: any[] = [];
  allPropertiesList: any[] = [];

  allPropertiesListDropdown: any[] = [];
  allCreateTicketPropertiesListDropdown: any[] = [];

  allRolesListDropdown: any[] = [];

  manageTeam_SelectedTab: any = '1';

  isRefresh_PropertyMember = new Subject();

  userRolesPermissions: any = {
    canAddorEdit: [],
    canView: [],
    canApprove: [],
    canAddToJob: [],
    canDeleteJob: [],
  };

  manageTeamsPagination: any = {
    myTeams: { next: '' },
    allTeams: { next: '' },
    allUsers: { next: '' },
    userRoles: { next: '' },
    suspendedAccount: { next: '' }
  };

  manageClientsPagination: any = {
    next: ''
  };

  allVendorsPagination: any = {
    next: '',
    count: 0
  };

  allTenantVendorsPagination: any = {
    next: ''
  };

  allNotificationsPagination: any = {
    next: '',
    count: 0
  };

  isTicketCreated: boolean = false;

  externalVendorStage: number = 1;
  allTenantUnit: any[] = [];
  allTenantUnitByProperty: any[] = [];
  unitsValueByProperty: any[] = [];
  public propertyFlag: boolean = false;
  /************* Search Filters Params ****************/
  searchParams: any = {
    Property_ID: null,
    Role_ID: null,
    Type: '',
    searchKeyword: ''
  };

  selectedDashbaordPropertyID: any = '';
  all_COAs: any[] = [];

  selectedVendor: any = {};

  errorEmailShow: any = '';
  errorPhoneShow: any = '';
  errorPhoneShowNull: any = '';
  allFyxtCategories: any[] = [];
  propertyDashboardData: any;
  errorDocumentData: any = '';

  settings: any = {
    new_jobs: true,
    job_update: true,
    new_message: true,
    sms_notify: true
  };

  newNotificaitonsList: any = {
    today: [],
    yesterday: [],
    lastMonth: []
  };
  allNewNotificaitonsList: any[] = [];
  teamsPropertySearch: string;
  isLoader_Notification: boolean = false;
  public isProfileLoaded = new BehaviorSubject<boolean>(false);
  public jobsCount = new BehaviorSubject<any>(null);

  constructor(
    public _http: HttpClient,
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    public _Config: ConfigService,
    private toastr: ToastrService,
    private readonly keycloakService: KeycloakService,
  ) {
    this.currentActiveState = this.activatedRoute.snapshot['_routerState'].url;
  }

  /************** Base HTTP Request Methods *****************/

  /**
   * To Get Http Request for REST API
   * @param URL
   * @param Options
   */
  doGET(URL?: string, Options?: any): Observable<any> {
    return this._http.get(URL, Options).pipe(take(1));
  }

  /**
   * To Post Http Request for REST API
   * @param URL   // The Endpoint URL
   * @param Body    //Post Input Body
   * @param Options   //Headers or Other Optional Params
   */
  doPOST(URL?: string, Body?: any, Options?: any) {
    return this._http.post(URL, Body, Options);
  }

  /**
   * To Update Http Request for REST API
   * @param URL
   * @param Body
   * @param Options
   */
  doPUT(URL?: string, Body?: any, Options?: any) {
    return this._http.put(URL, Body, Options);
  }

  /**
   * To Delete Http Request for REST API
   * @param URL
   * @param Options
   */
  doDelete(URL?: string, Options?: any) {
    return this._http.delete(URL, Options);
  }

  /**
   * If Patch Request for Http Restfull API
   * @param URL
   * @param Body
   * @param Options
   */
  doPatch(URL?: string, Body?: any, Options?: any) {
    return this._http.patch(URL, Body, Options);
  }

  doForkJoinbyCustomObj(urls: any) {
    const convertArrayToObject = (array, key) => {
      const initialValue = {};
      return array.reduce((obj, item) => {
        return {
          ...obj,
          [item[key]]: item.url,
        };
      }, initialValue);
    };

    let convertedObj = convertArrayToObject(urls,
      'id',
    );

    return forkJoin(convertedObj);
  }

  /**
   * If redirect to next page
   * @param pageURL
   */
  redirectTo(pageURL: any) {
    this._router.navigate([pageURL]);

    setTimeout(() => {
      this.getCurrentActiveState();
    }, 100);
  }

  reFile(url): Observable<Blob> {
    return this._http.get(url,
      { responseType: 'blob' });
  }

  /************** Common Request Methods *****************/

  authenticateUser(userRole: any, isNotLoadProfile?: boolean) {
    if (
      userRole == 'Customer' ||
      userRole == 'Owner' ||
      userRole == 'Manager'
    ) {
      this.redirectTo('/pm');
    }
    if (userRole == 'Engineer') {
      this.redirectTo('/engineer');
    } else if (userRole == 'Tenant') {
      this.redirectTo('/tenant');
    } else if (userRole == 'Vendor') {
      this.redirectTo('/vendor');
    }

    if (!isNotLoadProfile) this.getUserInfo();
  }

  /**
   * Get User Profile
   */
  getUserInfo(isHideLoader?: boolean) {
    if (this.isPublicPage()) {
      return;
    }

    this._Config.isLoader = !isHideLoader;

    let requestURL = environment.baseURL + this._Config.getProfile;

    this.doGET(requestURL).subscribe(
      (result: any) => {


        this._Config.isLoader = false;

        /************** Tenant Restrict Launch Darkly Access *****************/
        if (result.types.includes('Member')) {
          this._Config.isNewDashboard = false;
          this._Config.isNewJobInfo = !!this._Config.isNewDashboard;
        } else {
          // this.get_LaunchDarkly_Access(isHideLoader);
        }

        this.setUserInfo(result, isHideLoader);
      },
      (error) => {
        this._Config.isLoader = false;
      }
    );
  }

  /**
   * Set Current User Info
   * @param result
   * @param isHideLoader
   */
  setUserInfo(result: any, isHideLoader?: boolean) {
    /************** Get Launch Darkly Access *****************/
    // this.get_LaunchDarkly_Access(isHideLoader);

    this.currentUserInfo = result || {};

    this.currentUserInfo.new_permissions = {
      "is_user_can_create_job_tickets": false,
      "is_user_can_approve_schedule": false,
      "is_user_can_schedule_service_dates": false,
      "is_user_can_approve_job_costs_for_tenant_responsible_jobs": false,
      "is_user_can_update_job_ticket_status": false,
      "is_user_can_view_vendors": false,
      "is_user_can_view_lease_abstracts": false
    };

    if (this.currentUserInfo.category == 'Tenant') {
      if (this.currentUserInfo.tenant) {

        this.currentUserInfo.can_create_job = this.currentUserInfo.tenant.can_create_job;
        this.currentUserInfo.can_schedule_own_service_dates = this.currentUserInfo.tenant.can_schedule_own_service_dates;

        if (this.currentUserInfo.tenant.properties) {
          this.currentUserInfo.property = this.currentUserInfo.tenant.properties.length > 0 ? this.currentUserInfo.tenant.properties[0] : {};
        }

        if (this.currentUserInfo.tenant?.permissions) {
          if (this.currentUserInfo.tenant?.permissions?.length > 0) {
            this.onChangeUserPermission(this.currentUserInfo.tenant?.permissions);
          }
        }

        if (this.currentUserInfo.tenant?.is_admin) {
          this.currentUserInfo.new_permissions = {
            "is_user_can_create_job_tickets": (this.currentUserInfo.property_ids?.length > 0) ? true : false,
            "is_user_can_approve_schedule": true,
            "is_user_can_schedule_service_dates": true,
            "is_user_can_approve_job_costs_for_tenant_responsible_jobs": true,
            "is_user_can_update_job_ticket_status": true,
            "is_user_can_view_vendors": true,
            "is_user_can_view_lease_abstracts": true
          };
        }
       

      }
    }

    if (result.types.length > 0)
      this.currentUserInfo.current_Role = result.types[0];

    if (this.currentUserInfo.current_Role) {
      //if member role act as Tenant
      if (this.currentUserInfo.current_Role == 'Member') {
        this.currentUserInfo.current_Role = 'Tenant';
      }
    }

    localStorage.setItem('userobj', JSON.stringify(this.currentUserInfo));

    if (typeof this.currentUserInfo.settings == 'object') {
      this.settings = this.currentUserInfo.settings;
    }

    this.currentUserInfoSubject$.next(this.currentUserInfo);

    this.isProfileLoaded.next(true);

  }


  onChangeUserPermission(permissions: any) {

    this.currentUserInfo.permissions = permissions || [];

    if (this.currentUserInfo.permissions.includes("add_job")) {
      this.currentUserInfo.new_permissions.is_user_can_create_job_tickets = (this.currentUserInfo.property_ids?.length > 0) ? true : false;
    }
    if (this.currentUserInfo.permissions.includes("update_job_status")) {
      this.currentUserInfo.new_permissions.is_user_can_update_job_ticket_status = true;
    }
    if (this.currentUserInfo.permissions.includes("add_schedule")) {
      this.currentUserInfo.new_permissions.is_user_can_schedule_service_dates = true;
    }
    if (this.currentUserInfo.permissions.includes("approve_schedule")) {
      this.currentUserInfo.new_permissions.is_user_can_approve_schedule = true;
    }
    if (this.currentUserInfo.permissions.includes("approve_cost")) {
      this.currentUserInfo.new_permissions.is_user_can_approve_job_costs_for_tenant_responsible_jobs = true;
    }
    if (this.currentUserInfo.permissions.includes("view_vendor")) {
      this.currentUserInfo.new_permissions.is_user_can_view_vendors = true;
    }
    if (this.currentUserInfo.permissions.includes("view_lease")) {
      this.currentUserInfo.new_permissions.is_user_can_view_lease_abstracts = true;
    }
    // else {
    //   this._baseService.currentUserInfo.can_create_job = false;
    // }




  }


  /**
   * Get Launch Darkly Access
   */
  get_LaunchDarkly_Access(isHideLoader?: boolean) {
    return new Promise((resolve, reject) => {
      if (this._Config.isCachedLaunchDarkly) {
        resolve(true);
        return;
      }
      this._Config.isLoader = isHideLoader ? false : true;
      let requestURL = environment.baseURL + this._Config.getLaunchDarkly_API;
      this.doGET(requestURL).subscribe(
        (result: any) => {

          /************** Advanced Flags for New UI Show/Hide *****************/
          // this._Config.isNewDashboard = result.New_Dashboard ? true : false;
          // this._Config.isNewJobInfo = this._Config.isNewDashboard ? true : false;
          // this._Config.isAdvancedFilters = result.New_Dashboard ? true : false;
          // this._Config.isShowServicePlanner = result.service_planner ? true : false;
          // this._Config.isNewInbox = result.BE_INBOX_V1_MIGRATION;
          this._Config.friedman = result.review_feedback_on_close ? true : false;
          // this._Config.isFriedmanPropertyActive = result.Friedman_Property;

          this._Config.isLoader = false;

          // this.isLaunchDarklyLoaded.next(result);
          this._Config.isCachedLaunchDarkly = true;
          resolve(result);
        },
        (error) => {
          this._Config.isLoader = false;
          reject(error);
        }
      );
    });
  }

  /**
   * Get Current Active Route Path
   */
  getCurrentActiveState() {
    this.currentActiveState = this.activatedRoute.snapshot['_routerState'].url;
  }

  isIActive(pageName: any) {
    this.currentActiveState = this.activatedRoute.snapshot['_routerState'].url;

    return this.currentActiveState.includes(pageName);
  }

  /**
   * Login Redirect to Dashboard / Account  listing
   */
  goToDashboard(result: any) {
    const userToken = result.token;
    const userRefreshToken = result.refresh_token;
    if (userToken) {
      localStorage.setItem('token', userToken);
    }

    if (!this._Config.isAccountListing) {
      this.authenticateUser(result.category);

      this.toastr.success('', 'You Have Logged In successfully.');

      return;
    }

    if (result.domains.length === 1) {
      const domain = result.domains[0].link;
      const refreshToken = this.keycloakService.getKeycloakInstance().refreshToken;
      const token = this.keycloakService.getKeycloakInstance().token;
      if (!refreshToken && userRefreshToken) {
        window.location.href = `${domain}/${userRefreshToken}`;
      } else {
        window.location.href = `${domain}${token}/${refreshToken}`;
      }
    } else {
      this.redirectTo('/accounts');
    }
  }

  /**
   * Logout all page
   */
  doLogout(redirectUrl?: string): void {
    this.removeAuthTokens();
    this.keycloakService.logout(redirectUrl || window.location.origin + '/login');
  }

  /**
   * Get User Profile
   */
  userLogoutAPI() {
    // this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.logout;

    this.doPOST(requestURL, {}).subscribe(
      (result: any) => {
        // this._Config.isLoader = false;
      },
      (error) => {
        // this._Config.isLoader = false;
      }
    );
  }

  isPermissionExist(accessType: any): boolean {
    // let isOwner = this.isRoleExist(this.currentUserInfo.types, 'Owner');
    if (this.currentUserInfo.current_Role=='Owner') {
      return true;
    } else if (this.currentUserInfo.category == 'Customer') {
      if (accessType && this._Config.isRolePermissions) {
        if (this.currentUserInfo.permissions) {
          if (this.currentUserInfo.permissions.length > 0) {
            return this.currentUserInfo.permissions.includes(accessType);
          }
        }
      }
    }

    return false;
  }

  

  /**
   * Check is Role is Exist or Not
   * @param Types
   * @param Role
   * @returns
   */
  isRoleExist(Types: any[], Role: any) {
    if (Types) return Types.includes(Role);
  }


  checkUserPermissions(accessType:string){
    if (this.currentUserInfo.current_Role=='Owner') {
      return true;
    }
    else{
      if (accessType && this._Config.isRolePermissions) {
        if (this.currentUserInfo.permissions) {
          if (this.currentUserInfo.permissions?.length > 0) {
            return this.currentUserInfo.permissions.includes(accessType);
          }
        }
      }
    }
    return false;
  }

  /**
   * Get Object Values by Comma Separated
   * @param objItems
   */
  getItemsByCommaSeparated(objItems: any) {
    let stringValue: any = '';

    if (Array.isArray(objItems)) {
      if (objItems.length > 0) {
        stringValue = objItems
          .filter(function (el) {
            return el;
          })
          .join(', ');
      }
    } else {
      if (objItems) {
        stringValue = Object.values(objItems)
          .filter(function (el) {
            return el;
          })
          .join(', ');
      }
    }

    return stringValue;
  }

  /**
   * Get All States
   */
  getAllStates() {
    // this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.getStates;

    this.doGET(requestURL).subscribe(
      (response: any) => {
        this._Config.isLoader = false;

        this.allStates = response.filter(obj => obj.name) || [];
      },
      (error) => {
        this._Config.isLoader = false;
      }
    );
  }

  /**
   * Get All States
   */
  getAllCitiesByState(state_id: any) {
    // this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.getCities;
    requestURL = requestURL.replace('{state_id}', state_id);

    this.doGET(requestURL).subscribe(
      (response: any) => {
        this._Config.isLoader = false;

        this.allCities = response || [];
      },
      (error) => {
        this._Config.isLoader = false;
      }
    );
  }

  /**
   * Get All Categories
   */
  getAllFyxtCategories() {
    // this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.getAllFyxtCategories;

    this.doGET(requestURL).subscribe(
      (response: any) => {
        this._Config.isLoader = false;

        this.allFyxtCategories = response || [];
      },
      (error) => {
        this._Config.isLoader = false;
      }
    );
  }

  /**
   * Get All Categories
   */
  getAllCategories() {
    // this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.getCategories;

    this.doGET(requestURL).subscribe(
      (response: any) => {
        this._Config.isLoader = false;

        this.allWorkCategories = response || [];

        this.allWorkCategories = this.allWorkCategories.filter((obj) => obj.name) || [];

        this.isCategoryLoaded.next(this.allWorkCategories);

        setTimeout(() => {
          this.isCategoryLoaded.next(this.allWorkCategories);
        }, 1000);


      },
      (error) => {
        this._Config.isLoader = false;
      }
    );
  }

  // get All tenant category
  getAllTenangtCategories() {
    // this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.getAllTenantCategory;

    this.doGET(requestURL).subscribe(
      (response: any) => {
        this._Config.isLoader = false;

        this.allTenantWorkCategories = response || [];
      },
      (error) => {
        this._Config.isLoader = false;
      }
    );
  }

  /**
   * Get All User Roles
   */
  getAllUserRoles(isLoader?: any, isPagination?: any, isSearch?: any) {
    this._Config.isLoader = isLoader;

    let requestURL = '';

    if (isPagination) {
      if (this.manageTeamsPagination.userRoles.next) {
        requestURL = this.manageTeamsPagination.userRoles.next;
      } else requestURL = environment.baseURL + this._Config.getRoles;
    } else {
      if (isSearch) {
        requestURL = environment.baseURL + this._Config.searchRoles;
        requestURL = requestURL.replace(
          '{searchKeyword}',
          this.searchParams.searchKeyword ? this.searchParams.searchKeyword : ''
        );
      } else requestURL = environment.baseURL + this._Config.getRoles;
    }

    // let requestURL = environment.baseURL + this._Config.getRoles;

    this.doGET(requestURL).subscribe(
      (response: any) => {
        this._Config.isLoader = false;

        // this.allUserRoles = response.results || [];

        this.manageTeamsPagination.userRoles.next = response.next
          ? response.next
          : '';

        let ListItems = response.results || [];
        this.userRoleCount.next(response.count);
        if (isPagination) {
          this.allUserRoles.push(...ListItems);
        } else {
          this.allUserRoles = ListItems;
        }
      },
      (error) => {
        this._Config.isLoader = false;
      }
    );
  }

  /**
   * Get All Team Members
   */
  getAllTeamMembers(isLoader?: any, isPagination?: any, isSearch?: any) {
    this._Config.isLoader = isLoader;

    let requestURL = '';

    if (isPagination) {
      if (this.manageTeamsPagination.allUsers.next) {
        requestURL = this.manageTeamsPagination.allUsers.next;
      } else requestURL = environment.baseURL + this._Config.getMembers;
    } else {
      // requestURL = environment.baseURL + this._Config.getMembers;

      if (isSearch) {
        requestURL = environment.baseURL + this._Config.searchMembers;
        requestURL = requestURL.replace(
          '{Property_ID}',
          this.searchParams.Property_ID ? this.searchParams.Property_ID : ''
        );
        requestURL = requestURL.replace(
          '{Role_ID}',
          this.searchParams.Role_ID ? this.searchParams.Role_ID : ''
        );
        requestURL = requestURL.replace(
          '{searchKeyword}',
          this.searchParams.searchKeyword ? this.searchParams.searchKeyword : ''
        );
      } else requestURL = environment.baseURL + this._Config.getMembers;
    }

    this.doGET(requestURL).subscribe(
      (response: any) => {
        this._Config.isLoader = false;

        this.manageTeamsPagination.allUsers.next = response.next
          ? response.next
          : '';

        let ListItems = response.results || [];
        this.teamMembersCount.next(response.count);
        ListItems.forEach((element) => {
          element.tooltipItems = element.properties.map((item) => item.name);

          let isOwner = this.isRoleExist(element.types, 'Owner');
          let isManager = this.isRoleExist(
            this.currentUserInfo.types,
            'Manager'
          );
          element.isOwner = isOwner;
          element.isManager = isManager;
        });

        if (isPagination) {
          this.allTeamMembers.push(...ListItems);
        } else {
          this.allTeamMembers = ListItems;
        }
      },
      (error) => {
        this._Config.isLoader = false;
      }
    );
  }

  /**
   * Get All Suspended Team Members
   */
  getAllSuspendedTeamMembers(
    isLoader?: any,
    isPagination?: any,
    isSearch?: any
  ) {
    this._Config.isLoader = isLoader;

    let requestURL = '';

    if (isPagination) {
      if (this.manageTeamsPagination.suspendedAccount.next) {
        requestURL = this.manageTeamsPagination.suspendedAccount.next;
      } else
        requestURL = environment.baseURL + this._Config.getSuspendedMembers;
    } else {
      if (isSearch) {
        requestURL = environment.baseURL + this._Config.searchMembers;
        requestURL = requestURL.replace(
          '{Property_ID}',
          this.searchParams.Property_ID ? this.searchParams.Property_ID : ''
        );
        requestURL = requestURL.replace(
          '{Role_ID}',
          this.searchParams.Role_ID ? this.searchParams.Role_ID : ''
        );
        requestURL = requestURL.replace(
          '{searchKeyword}',
          this.searchParams.searchKeyword ? this.searchParams.searchKeyword : ''
        );
      } else
        requestURL = environment.baseURL + this._Config.getSuspendedMembers;
    }

    // let requestURL = environment.baseURL + this._Config.getSuspendedMembers;

    this.doGET(requestURL).subscribe(
      (response: any) => {
        this._Config.isLoader = false;

        // this.allSuspendedTeamMembers = response.results || [];

        this.manageTeamsPagination.allUsers.next = response.next
          ? response.next
          : '';

        let ListItems = response.results || [];

        if (isPagination) {
          this.allSuspendedTeamMembers.push(...ListItems);
        } else {
          this.allSuspendedTeamMembers = ListItems;
        }
      },
      (error) => {
        this._Config.isLoader = false;
      }
    );
  }

  /**
   * Get All User Properties
   */
  getAllProperties() {
    this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.getProperties;

    this.doGET(requestURL).subscribe(
      (response: any) => {
        this._Config.isLoader = false;

        this.allProperties = response.results || [];
      },
      (error) => {
        this._Config.isLoader = false;
      }
    );
  }

  /**
   * Get All User Team Properties
   */
  getAllTeamsProperties(isLoader?: any, isPagination?: any, isSearch?: any) {
    this._Config.isLoader = isLoader;

    let requestURL = '';

    if (isPagination) {
      if (this.manageTeamsPagination.allTeams.next) {
        requestURL = this.manageTeamsPagination.allTeams.next;
      } else
        requestURL = environment.baseURL + this._Config.getAllTeamsProperties;
    } else {
      requestURL = environment.baseURL + this._Config.getAllTeamsProperties;
    }

    if (isSearch) {
      if (requestURL.includes('?')) {
        requestURL += '&search=' + this.teamsPropertySearch;
      } else {
        requestURL += '?search=' + this.teamsPropertySearch;
      }
    }

    this.doGET(requestURL).subscribe(
      (response: any) => {
        this._Config.isLoader = false;

        this.manageTeamsPagination.allTeams.next = response.next
          ? response.next
          : '';

        let ListItems = response.results || [];
        // this.allTeamsProperties = response.results || [];

        if (ListItems.length > 0) {
          ListItems.forEach((element) => {
            element.address.country_name = element.address.country
              ? element.address.country.name
              : '';
            element.address.state_name = element.address.state
              ? element.address.state.name
              : '';
            element.address.city_name = element.address.city
              ? element.address.city.name
              : '';

            element.members = element.members.sort(function (a, b) {
              return b.primary_contact - a.primary_contact;
            });
          });
        }

        if (isPagination) {
          this.allTeamsProperties.push(...ListItems);
        } else {
          ListItems.forEach((element, index) => {
            if (element.members.length > 0) {
              element.members.forEach((values, index) => {
                if (values.primary_contact == false) {
                  element.members[0].primary_contact = true;
                }
              });
            }
          });
          this.allTeamsProperties = ListItems;
        }
      },
      (error) => {
        this._Config.isLoader = false;
      }
    );
  }

  /**
   * Get All My Teams
   */
  getMyTeamsProperties(isLoader?: any, isPagination?: any, isSearch?: any) {
    this._Config.isLoader = isLoader;

    let requestURL = '';

    if (isPagination) {
      if (this.manageTeamsPagination.myTeams.next) {
        requestURL = this.manageTeamsPagination.myTeams.next;
      } else {
        requestURL = environment.baseURL + this._Config.getMyTeamsProperties;
        requestURL = requestURL.replace('{USER_ID}', this.currentUserInfo.id);
      }
    } else {
      requestURL = environment.baseURL + this._Config.getMyTeamsProperties;
      requestURL = requestURL.replace('{USER_ID}', this.currentUserInfo.id);
    }


    if (isSearch) {
      if (requestURL.includes('?')) {
        requestURL += '&search=' + this.teamsPropertySearch;
      } else {
        requestURL += '?search=' + this.teamsPropertySearch;
      }
    }


    this.doGET(requestURL).subscribe(
      (response: any) => {
        this._Config.isLoader = false;

        this.manageTeamsPagination.myTeams.next = response.next
          ? response.next
          : '';

        let ListItems = response.results || [];
        // this.allTeamsProperties = response.results || [];

        if (ListItems.length > 0) {
          ListItems.forEach((element) => {
            element.address.country_name = element.address.country.name;
            element.address.state_name = element.address.state.name;
            element.address.city_name = element.address.city.name;

            element.members = element.members.sort(function (a, b) {
              return b.primary_contact - a.primary_contact;
            });
          });
        }

        if (isPagination) {
          this.myTeamsProperties.push(...ListItems);
        } else {
          ListItems.forEach((element, index) => {
            if (element.members.length > 0) {
              element.members.forEach((values, index) => {
                if (values.primary_contact == false) {
                  element.members[0].primary_contact = true;
                }
              });
            }
          });
          this.myTeamsProperties = ListItems;
        }
      },
      (error) => {
        this._Config.isLoader = false;
      }
    );
  }

  /**
   * Get All My Teams
   */
  getAllClients(isLoader?: any, isPagination?: any, isSearch?: any) {
    this._Config.isLoader = isLoader;

    let requestURL = '';

    if (isPagination) {
      if (this.manageClientsPagination.next) {
        requestURL = this.manageClientsPagination.next;
      } else {
        requestURL = environment.baseURL + this._Config.getOwners;
      }
    } else {
      if (isSearch) {
        requestURL = environment.baseURL + this._Config.searchOwners;
        requestURL = requestURL.replace(
          '{searchKeyword}',
          this.searchParams.searchKeyword ? this.searchParams.searchKeyword : ''
        );
      } else requestURL = environment.baseURL + this._Config.getOwners;
    }

    // this._Config.isLoader = true;

    // let requestURL = environment.baseURL + this._Config.getOwners;

    this.doGET(requestURL).subscribe(
      (response: any) => {
        this._Config.isLoader = false;

        this.manageClientsPagination.next = response.next ? response.next : '';

        let ListItems = response.results || [];
        // this.allTeamsProperties = response.results || [];

        if (ListItems.length > 0) {
          ListItems.forEach((element) => {
            element.address = {};
            element.address.country_name = element.company.address.country.name;
            element.address.state_name = element.company.address.state.name;
            element.address.city_name = element.company.address.city.name;
          });
        }

        if (isPagination) {
          this.allClients.push(...ListItems);
        } else {
          this.allClients = ListItems;
        }
      },
      (error) => {
        this._Config.isLoader = false;
      }
    );
  }

  /**
   * Get All Vendors
   */
  getAllVendors(isLoader?: any, isPagination?: any, isSearch?: any) {
    this._Config.isLoader = isLoader;

    let requestURL = '';

    if (isPagination) {
      if (this.allVendorsPagination.next) {
        requestURL = this.allVendorsPagination.next;
      } else {
        requestURL = environment.baseURL + this._Config.getVendors;
      }
    } else {
      if (isSearch) {
        requestURL = environment.baseURL + this._Config.searchVendors;
        requestURL = requestURL.replace(
          '{searchKeyword}',
          this.searchParams.searchKeyword ? this.searchParams.searchKeyword : ''
        );
      } else requestURL = environment.baseURL + this._Config.getVendors;
    }

    // this._Config.isLoader = true;

    // let requestURL = environment.baseURL + this._Config.getVendors;

    this.doGET(requestURL).subscribe(
      (response: any) => {
        this._Config.isLoader = false;

        // this.allVendors = response.results || [];

        this.allVendorsPagination.count = response.count ? response.count : 0;
        this.allVendorsPagination.next = response.next ? response.next : '';

        let ListItems = response.results || [];

        if (isPagination) {
          this.allVendors.push(...ListItems);
        } else {
          this.allVendors = ListItems;
        }
      },
      (error) => {
        this._Config.isLoader = false;
      }
    );
  }

  /**
   * Get All Tenant Vendors
   */
  getAllTenantVendors(isLoader?: any, isPagination?: any, isSearch?: any) {
    this._Config.isLoader = true;
    let requestURL = '';

    if (isPagination) {
      if (this.allTenantVendorsPagination.next) {
        requestURL = this.allTenantVendorsPagination.next;
      } else {
        // requestURL = environment.baseURL + this._Config.getVendors;
        requestURL = environment.baseURL + this._Config.getTenantVendorsList;
      }
    } else {
      if (isSearch) {
        requestURL = environment.baseURL + this._Config.searchVendors;
        requestURL = requestURL.replace(
          '{searchKeyword}',
          this.searchParams.searchKeyword ? this.searchParams.searchKeyword : ''
        );
      }
      // requestURL = environment.baseURL + this._Config.getVendors;
      else requestURL = environment.baseURL + this._Config.getTenantVendorsList;
    }

    this.doGET(requestURL).subscribe(
      (response: any) => {
        this._Config.isLoader = false;
        this.allTenantVendorsPagination.next = response.next
          ? response.next
          : '';

        let ListItems = response.results || [];

        if (isPagination) {
          this.allTenantVendors.push(...ListItems);
        } else {
          this.allTenantVendors = ListItems;
        }

        // this.allVendors = response.results || [];

        // this.allVendors.forEach((element: any) => {
        //   element.address = {};
        //   element.address.country = element.company.address.country.name;
        //   element.address.state = element.company.address.state.name;
        //   element.address.city = element.company.address.city.name;
        // });
      },
      (error) => {
        this._Config.isLoader = false;
      }
    );
  }

  /**
  * Get All Properties Dropdown
  */
  listAllTeamPropertiesDropdown() {
    // this._Config.isLoader = true;

    let requestURL =
      environment.baseURL + this._Config.listAllTeamPropertiesDropdown;

    this.doGET(requestURL).subscribe(
      (response: any) => {
        this._Config.isLoader = false;

        this.allPropertiesListDropdown = response.results || [];


      },
      (error) => {
        this._Config.isLoader = false;
      }
    );
  }


  /**
   * Get All Properties Dropdown
   */
  listAllPropertiesDropdown() {
    // this._Config.isLoader = true;

    let requestURL =
      environment.baseURL + this._Config.listAllPropertiesDropdown;

    this.doGET(requestURL).subscribe(
      (response: any) => {
        // this._Config.isLoader = false;

        this.allPropertiesListDropdown = response || [];

        // if (this.currentUserInfo.category == "Tenant") {

        //   if (this.allPropertiesListDropdown.length == 0) {
        //     this.allPropertiesListDropdown.push(this.currentUserInfo.property)
        //   }

        // }
      },
      (error) => {
        // this._Config.isLoader = false;
      }
    );
  }

  /**
   * Get All Properties Dropdown
   */
  listAllCreateTicketPropertiesDropdown() {
    // this._Config.isLoader = true;

    let requestURL =
      environment.baseURL + this._Config.listAllPropertiesDropdown;
    requestURL = requestURL + '?source=pm';

    this.doGET(requestURL).subscribe(
      (response: any) => {
        this._Config.isLoader = false;

        this.allCreateTicketPropertiesListDropdown = response || [];

        if (this.allCreateTicketPropertiesListDropdown.length > 0) {
          this.allCreateTicketPropertiesListDropdown.forEach((element) => {
            element.property_address =
              element.name + ' ( ' + element.address + ' )';
          });
        }
      },
      (error) => {
        this._Config.isLoader = false;
      }
    );
  }

  /**
   * Get All Roles Dropdown
   */
  listAllRolesDropdown() {
    // this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.listAllRolesDropdown;

    this.doGET(requestURL).subscribe(
      (response: any) => {
        // this._Config.isLoader = false;

        this.allRolesListDropdown = response || [];
      },
      (error) => {
        // this._Config.isLoader = false;
      }
    );
  }

  /**
   * Get All Role Permissions
   */
  listAllPermissions() {
    // this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.getRolePermissions;

    this.doGET(requestURL).subscribe(
      (response: any) => {
        // this._Config.isLoader = false;

        let allPermissions: any = response || {};

        this.userRolesPermissions = {
          canAddorEdit: [],
          canView: [],
          canApprove: [],
          canAddToJob: [],
          canDeleteJob: [],
        };

        allPermissions.add.forEach((element) => {
          let can_add_edit = {
            label: element,
            isChecked: false
          };

          this.userRolesPermissions.canAddorEdit.push(can_add_edit);
        });

        allPermissions.view.forEach((element) => {
          let can_add_edit = {
            label: element,
            isChecked: false
          };

          this.userRolesPermissions.canView.push(can_add_edit);
        });

        allPermissions.approve.forEach((element) => {
          let can_add_edit = {
            label: element,
            isChecked: false
          };

          this.userRolesPermissions.canApprove.push(can_add_edit);
        });

        allPermissions.added_job.forEach((element) => {
          let can_add_to_job = {
            label: element,
            isChecked: false
          };

          this.userRolesPermissions.canAddToJob.push(can_add_to_job);
        });

        allPermissions.delete.forEach((element) => {
          let can_delete_job = {
            label: element,
            isChecked: false
          };

          this.userRolesPermissions.canDeleteJob.push(can_delete_job);
        });

      },
      (error) => {
        // this._Config.isLoader = false;
      }
    );
  }

  public getAllTenantUnits() {
    // this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.getTenantUnits;

    this.doGET(requestURL).subscribe(
      (response: any) => {
        this._Config.isLoader = false;

        let resArray = [];
        let finalArray = [];

        resArray = response || [];

        resArray.forEach((item) => {
          item.buildings.forEach((unit) => {
            unit.units.forEach((unitItem) => {
              unitItem.property_id = item.property.id;
              unitItem.property_name = item.property.name;
            });
          });

          finalArray.push(...item.buildings);
        });
        // for (let item of resArray) {
        //   item.units.forEach(unit => {
        //     unit.property_id = item.property.id;
        //     unit.property_name = item.property.name;
        //     finalArray.push(unit)
        //   })

        //   finalArray.push(...item.units)
        // }

        let allLocations = [];
        if (finalArray.length > 0) {
          finalArray.forEach((element: any, index) => {
            let units = [];
            element.units.forEach((unit: any) => {
              let unitObj = {
                id: unit.id,
                title: unit.name
                // property_id: unit.property_id,
                // property_name: unit.property_name
              };

              units.push(unitObj);
            });

            let unitsObj = {
              id: index + 1,
              title: element.address,
              subunits: element.units
            };

            allLocations.push(unitsObj);
          });
        }

        this.allTenantUnit = allLocations;

      },
      (error) => {
        this._Config.isLoader = false;
      }
    );
  }

  singleTenantData: any;
  singleTenantUnit: any;
  singleAbstractData: any;
  getSingleAbstract(x) {
    if (x) {
      this._Config.isLoader = true;

      let requestURL = environment.baseURL + 'tenants/' + x + '/';

      this.doGET(requestURL).subscribe(
        (response: any) => {
          this._Config.isLoader = false;
          this.singleTenantData = response || {};
          let unitData = [];
          if (response.properties.length > 0) {
            response.properties.forEach((element) => {
              element.buildings.forEach((element1) => {
                element1.units.forEach((unit) => {
                  unitData.push(unit.name);
                });
              });
            });

            // for (let x of response.properties) {
            //   for (let y of x.buildings) {
            //     unitData.push(y.name)
            //   }
            // }
          }

          this.singleTenantUnit = unitData;
        },
        (error) => { }
      );
    }
  }

  getSingleTenant(x) {
    if (x) {
      this._Config.isLoader = true;
      let requestURL =
        environment.baseURL + 'tenants/' + x + '/administrative/';

      this.doGET(requestURL).subscribe(
        (response: any) => {
          this._Config.isLoader = false;
          this.singleAbstractData = response || {};

          if (this.singleAbstractData.abstract) {
            if (this.singleAbstractData.abstract.terms) {
              for (let x of this.singleAbstractData.abstract.terms) {
                x['name'] = x.category.name;
              }
            }
          }
        },
        (error) => { }
      );
    }
  }

  public getAllTenantUnitsByPropertyID() {
    let requestURL =
      environment.baseURL +
      'properties/' +
      this.singleTenantData.properties[0].property.id +
      '/vacant-units/';
    //requestURL = requestURL.replace('{ID}', this.singleTenantData.id);
    this.doGET(requestURL).subscribe(
      (response: any) => {
        this._Config.isLoader = false;

        this.allTenantUnitByProperty = response || [];
        this.allTenantUnitByProperty.forEach((x) => {
          this.unitsValueByProperty.push(x.units.name);
        });
      },
      (error) => {
        this._Config.isLoader = false;
      }
    );
  }

  getAllUploadedCOA(property_id) {
    let requestURL =
      environment.baseURL + this._Config.getAllUploadedCOA_Dropdown;
    requestURL = requestURL.replace('{ID}', property_id);

    this._Config.isLoader = true;

    this.doGET(requestURL).subscribe(
      (response: any) => {
        this.all_COAs = response || [];

        if (this.all_COAs.length > 0) {
          this.all_COAs.forEach((element) => {
            if (!element.id) element.id = 'FYXT';
          });
        }

        this._Config.isLoader = false;
      },
      (error) => {
        this._Config.isLoader = false;
      }
    );
  }
  /**
   * common validation for already exist email and phone
   * @param value
   */
  postEmailCheck(value) {
    let postBody = {
      email: value
    };
    let requestURL = environment.baseURL + this._Config.postEmailIdReuse;
    this.doPOST(requestURL, postBody).subscribe(
      (result: any) => {
        this.errorEmailShow = '';
      },
      (error) => {
        this.errorEmailShow = error.error;
      }
    );
  }
  postphoneNoCheck(value) {
    if (value.length >= 11) {
      let postBody = {
        phone: value
      };
      let requestURL = environment.baseURL + this._Config.postPhoneNoReuse;
      this.doPOST(requestURL, postBody).subscribe(
        (result: any) => {
          this.errorPhoneShow = '';
        },
        (error) => {
          this.errorPhoneShow = error.error;
        }
      );
    }
  }
  postPhoneNoNullCheck(value) {
    if (value.length >= 11 || value != undefined || value != '' || value != null) {
      let postBody = {
        phone: value
      };
      let requestURL = environment.baseURL + this._Config.postPhoneNoReuse;
      this.doPOST(requestURL, postBody).subscribe(
        (result: any) => {
          this.errorPhoneShowNull = '';
        },
        (error) => {
          this.errorPhoneShowNull = error.error;
        }
      );
    }
  }
  /***----end---- */

  /**
   * Check is Engineer
   */
  isEngineer() {

    let currentUser = localStorage.getItem('userobj') ? JSON.parse(localStorage.getItem('userobj')) : {};

    if (currentUser.current_Role === 'Engineer')
      return true;
    else
      return false;
  }


  /**
   * Get User Specific Notifications
   */
  getUserNotifications(isHideLoader?: any, isPagination?: boolean) {
    if (isHideLoader) {
      this.isLoader_Notification = true;
    }

    let requestURL = environment.baseURL + this._Config.getUserNotifications_New;

    if (isPagination) {
      requestURL = this.allNotificationsPagination.next;
    }
    else {
      requestURL = requestURL.replace('v1', 'v2');
    }

    this.doGET(requestURL)
      .subscribe(
        {
          next: (response: any) => {

            this.allNotificationsPagination.next = response.next ? response.next : '';
            this.allNotificationsPagination.count = response.count ? response.count : '';

            let responseData = response.results || [];
            if (responseData.length > 0) {
              responseData.forEach((element: any) => {
                element.date_val = new Date(element.created_at);
                element.date_val.setHours(0, 0, 0, 0);
                element.text = this.convertToPlain(element.text);
              });

              let today = new Date();
              let yesterday = new Date();
              yesterday.setDate(yesterday.getDate() - 1);

              today.setHours(0, 0, 0, 0);
              yesterday.setHours(0, 0, 0, 0);

              this.newNotificaitonsList.today = responseData.filter(
                (obj) => obj.date_val.toISOString() == today.toISOString()
              );

              this.newNotificaitonsList.yesterday = responseData.filter(
                (obj) => obj.date_val.toISOString() == yesterday.toISOString()
              );

              this.newNotificaitonsList.lastMonth = responseData.filter(
                (obj) => obj.date_val.toISOString() < yesterday.toISOString()
              );
            }

            if (isPagination) {
              this.allNewNotificaitonsList.push(...responseData);
            }
            else {
              this.allNewNotificaitonsList = responseData;

            }

          },
          error: (error) => {
            this.isLoader_Notification = false;
          },
          complete: () => {
            this.isLoader_Notification = false;
          }
        });
  }

  convertToPlain(html: any) {
    var tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }

  uploadDocument(formData): Observable<any> {
    const postBody = formData;
    const requestURL = environment.baseURL + this._Config.uploadAbstractDoc;
    return this.doPOST(requestURL, postBody)
  }


  routerSettings(url: any) {
    let setPrefix = "/pm";
    if (this.currentUserInfo.current_Role == 'Tenant') {
      setPrefix = '/tenant';
    }
    else if (this.currentUserInfo.current_Role == 'Engineer') {
      setPrefix = '/engineer';
    }

    let pageURL = setPrefix + url;

    // this._router.navigate([pageURL]);

    return pageURL;

  }


  setRootPrefix() {
    let setPrefix = "/pm";
    if (this.currentUserInfo.current_Role == 'Tenant') {
      setPrefix = '/tenant';
    }
    else if (this.currentUserInfo.current_Role == 'Engineer') {
      setPrefix = '/engineer';
    }

    return setPrefix;
  }


  /**
   * restrict Public page redirections
   * @returns
   */
  isPublicPage() {
    let currentPathname = window.location.pathname;
    if (currentPathname.includes('external-vendor-jobs/') || currentPathname.includes('service-request') || currentPathname.includes('servicerequest') || currentPathname.includes('new-external-vendor/')) {
      return true;
    }

    return false;

  }

  /**
  * remove only auth tokens
  */
  public removeAuthTokens() {
    localStorage.removeItem('userobj');
    localStorage.removeItem('token');
    localStorage.removeItem('pusherTransportTLS');
  }


  isSchema(schemas: string[]) {
    return schemas.includes(this.currentUserInfo.schema);
  }

  jobsCountUpdate(): Observable<any> {
    return this.jobsCount.asObservable();
  }
}
