// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hide_visibility {
  visibility: hidden;
  height: 0px !important;
  line-height: 0px !important;
}

::ng-deep.ng-placeholder {
  color: #9FA7B3 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_reusable_ui/_controls/datepicker/date-dropdown/date-dropdown.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,sBAAA;EACA,2BAAA;AACJ;;AAEA;EACI,yBAAA;AACJ","sourcesContent":[".hide_visibility{\n    visibility: hidden;\n    height: 0px !important;\n    line-height: 0px !important;\n}\n\n::ng-deep.ng-placeholder{\n    color: #9FA7B3!important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
