import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  IGetRuleTemplateResult,
  IRuleTemplate,
  IRuleTemplateOptionsItem,
  IUpdateRuleTemplateData
} from './rule-template-graphql.interface';
import { takeParam } from '../leases-store.service';
import { ILeaseCategory, Rule, TLeaseType } from '../../../_shared/models/porperty-manager/maintenance-terms-tab';
import { RuleTemplateHttpService } from "./rule-template-http.service";

export const initRuleTemplateParams: IRuleTemplateOptionsItem = {
  sort: '',
  order: '',
  search: '',
  page: 1,
  take: takeParam,
}

@Injectable({
  providedIn: 'root'
})
export class RuleTemplateStoreService {

  private ruleTemplatesSubject$: BehaviorSubject<IGetRuleTemplateResult> = new BehaviorSubject<IGetRuleTemplateResult>({} as IGetRuleTemplateResult);
  public ruleTemplates$: Observable<IGetRuleTemplateResult> = this.ruleTemplatesSubject$.asObservable();

  private selectedRuleTemplateSubject$: BehaviorSubject<IRuleTemplate> = new BehaviorSubject<IRuleTemplate>({} as IRuleTemplate);
  public selectedRuleTemplate$: Observable<IRuleTemplate> = this.selectedRuleTemplateSubject$.asObservable();

  private readonly categoriesSubject$: BehaviorSubject<ILeaseCategory[]> = new BehaviorSubject<ILeaseCategory[]>([]);
  public readonly categories$: Observable<ILeaseCategory[]> = this.categoriesSubject$.asObservable();

  public queryTemplatesParams: IRuleTemplateOptionsItem = initRuleTemplateParams;

  constructor(
    private readonly httpService: RuleTemplateHttpService,
  ) {}

  public loadTemplateData(lease_id: number): Observable<IGetRuleTemplateResult> {
    return this.httpService.getRuleTemplates({ ...this.queryTemplatesParams, lease_id })
      .pipe(tap((data) => {
        this.ruleTemplatesSubject$.next(data)
      }));
  }

  public setQueryTemplatesParams(params: IRuleTemplateOptionsItem, lease_id: number): Observable<IGetRuleTemplateResult> {
    const { sort, order, take, page, search } = params;
    this.queryTemplatesParams = {
      sort: sort || typeof(sort) === 'string' ? sort : this.queryTemplatesParams.sort,
      order: order || typeof(order) === 'string' ? order : this.queryTemplatesParams.order,
      search: search || typeof(search) === 'string' ? search : this.queryTemplatesParams.search,
      take: take || this.queryTemplatesParams.take,
      page: page || this.queryTemplatesParams.page,
    }

    return this.loadTemplateData(lease_id);
  }


  public createTemplate(lease_id: number, template_name: string): Observable<IRuleTemplate> {
    return this.httpService.createTemplate({ lease_id, template_name });
  }

  public deleteRuleTemplate(template_id: number): Observable<number> {
    return this.httpService.deleteRuleTemplate(template_id);
  }

  public updateRuleTemplate(template_data: IUpdateRuleTemplateData): Observable<IRuleTemplate> {
    return this.httpService.updateRuleTemplate(
      template_data.id,
      { template_name: template_data.template_name, lease_type: template_data.lease_type }
    )
      .pipe(
        tap((updatedTemplate) => {
          const templates = this.ruleTemplatesSubject$.value.rule_templates;
          const updatedTemplates = templates.map(t => {
            if (template_data.id === t.id) {
              return updatedTemplate;
            }
            return t;
          })
          this.ruleTemplatesSubject$.next({ ...this.ruleTemplatesSubject$.value, rule_templates: updatedTemplates });
        })
      );
  }

  public multipleDeleteRuleTemplates(template_ids: number[]): Observable<number[]> {
    return this.httpService.deleteRuleTemplateBulkAction(template_ids);
  }

  public selectTemplate(template: IRuleTemplate) {
    this.selectedRuleTemplateSubject$.next(template);
  }

  public getTemplateCategories(template_id: number): Observable<ILeaseCategory[]> {
    return this.httpService.getTemplateCategories(template_id)
      .pipe(
        tap((categories) => {
          this.categoriesSubject$.next(categories);
          this.getRulesCount(template_id);
        }),
      );
  }

  public updateConfigForCategories(config: TLeaseType): void {
    const categories = this.categoriesSubject$.value;
    this.categoriesSubject$.next(categories.map(c => ({ ...c, is_modified: false, config })))
  }

  public getRulesCount(template_id: number){
    this.httpService.getTemplateCategoryRulesCount(template_id)
      .pipe(tap((data) => {
        const categories = this.categoriesSubject$.value;
        if (categories && categories.length) {

          if (data.length) {
            this.categoriesSubject$.next(categories.map(item => ({ ...item, ruleCount: data.find(countItem => countItem.category_id === item.id)?.count || 0 })));
          } else {
            this.categoriesSubject$.next(categories.map(item => ({ ...item, ruleCount: 0 })));
          }

          // Update applied rules value for template, during rules changing
          const { rule_templates } = this.ruleTemplatesSubject$.value;
          const template = rule_templates.find(tm => tm.id === template_id);
          const rulesCount = this.categoriesSubject$.value.reduce((acc,curVal) => (acc + (curVal.ruleCount || 0)), 0);
          if (template.rules_applied !== rulesCount) {
            this.ruleTemplatesSubject$.next({
              ...this.ruleTemplatesSubject$.value,
              rule_templates: rule_templates.map(tr => tr.id === template_id ? { ...tr, rules_applied: rulesCount } : tr)
            });
          }
        }
      })).subscribe();
  }

  public updateTemplateCategory(template_id: number, category_id: string, config: string): Observable<boolean> {
    return this.httpService.updateTemplateCategoryConfig(template_id, category_id, config)
      .pipe(
        tap(() => {
          const categories = this.categoriesSubject$.value;
          const updatedCategories = categories.map(category => {
            if (category.id === category_id) {
              return { ...category, config, is_modified: true };
            }
            return category;
          })
          this.categoriesSubject$.next(updatedCategories);
        })
      )
  }

  public cleanTemplateCategories(): void {
    this.categoriesSubject$.next([])
  }

  public getTemplateCategoryRules(category_id: string, template_id: number): Observable<Rule[]> {
    return this.httpService.getTemplateCategoryRules(template_id, category_id);
  }

  public createTemplateRule(rule: Rule): Observable<Rule> {
    return this.httpService.createTemplateRule(rule);
  }

  public updateTemplateRule(id: number, rule: Rule): Observable<Rule> {
    return this.httpService.updateTemplateRule(id, rule)
      .pipe(
        tap(() => this.getRulesCount(rule.template_id))
      );
  }

  public deleteTemplateRule(rule_id: number, template_id: number): Observable<number> {
    return this.httpService.deleteTemplateRule(rule_id)
      .pipe(
        tap(() => this.getRulesCount(template_id))
      );
  }

  public applyRuleTemplate(template_id: number, lease_id: number): Observable<boolean> {
    return this.httpService.applyTemplate(template_id, lease_id);
  }

  public clear(): void {
    this.ruleTemplatesSubject$.next({} as IGetRuleTemplateResult);
    this.selectedRuleTemplateSubject$.next({} as IRuleTemplate);
    this.queryTemplatesParams = initRuleTemplateParams;
  }
}
