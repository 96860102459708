import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilesService } from 'src/app/Modules/_fyxt_modules/companies/_services/files.service';
import { environment } from 'src/environments/environment';
import { LeasesStoreService } from '../../../../../@fyxt/_services/leases/leases-store.service'
import { LeasesHttpService } from '../../../../../@fyxt/_services/leases/leases-http.service'
import { DocumentItem } from '../../../../../@fyxt/_shared/models/porperty-manager/documents'
import { LeasesGraphqlService } from '../../../../../@fyxt/_services/leases/leases-graphql.service'
import { ConfigService } from '../../../../../services/config.service'
import { NgIf, DatePipe } from '@angular/common';
import { TextareaComponent } from '../../../../../@fyxt/_shared/_reusable_ui/_controls/textarea/textarea.component';
import { DropdownDateSelectComponent } from '../../../../../@fyxt/_shared/_reusable_ui/_controls/dropdown-select/dropdown-date-select/dropdown-date-select.component';
import { TagsComponent } from '../../../../../@fyxt/_shared/_reusable_ui/_components/tags/tags.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TextboxComponent } from '../../../../../@fyxt/_shared/_reusable_ui/_controls/textbox/textbox.component';
import { FormsModule } from '@angular/forms';
@Component({
    selector: 'fyxt-lease-tab-file-information',
    templateUrl: './lease-tab-file-information.component.html',
    styleUrls: ['./lease-tab-file-information.component.scss'],
    standalone: true,
    imports: [FormsModule, TextboxComponent, NgxIntlTelInputModule, TagsComponent, DropdownDateSelectComponent, TextareaComponent, NgIf, DatePipe]
})
export class LeaseTabFileInformationComponent implements OnInit {
  data:any;
  title = '';
  desc = '';
  effectiveDate = '';
  tags:any=[] = [];
  @Input() chipList: any[] = [];
  @Input() isLoader!: boolean;
  @Output() onChangeCategories = new EventEmitter();
  baseUrl


  constructor(
    private fileService: FilesService,
    private leaseHttpService: LeasesHttpService,
    private leasesStoreService: LeasesStoreService,
    private leaseGraphqlService: LeasesGraphqlService,
    private configService: ConfigService,
  ) { }

  ngOnInit(): void {
   this.baseUrl =  environment.files.http;
   this.baseUrl = this.baseUrl.substring(0, this.baseUrl.length - 1);
   this.leasesStoreService.currentLeaseDocument$.subscribe((data)=> {

    if (!data) return
      this.getFile(data.document.id);
   })
  }

  titleisChanged(e){
    if(this.title == '' || this.title == null){
      return
    }

    this.updateFile();
  }

  descisChanged(e){
    if(this.desc == '' || this.desc == null){
      return
    }
    this.updateFile();
  }

  dateIsChanged(e){
    if(!this.effectiveDate) return

    this.updateLeaseDocument();
  }


  updateFile(){
    this.fileService.updateFile(this.data?.document?.id,this.title,this.desc,this.tags).subscribe((data:any)=>{
    })
  }


  updateLeaseDocument(){
    this.leaseGraphqlService.updateDocument(this.data?.document?.id, { effective_date: this.effectiveDate }).subscribe((data:any)=>{
    })
  }

  getFile(leaseDocumentId){
    if (!leaseDocumentId) return

    this.configService.isLoader = true

    this.leaseGraphqlService.getLeaseDocumentById(leaseDocumentId).subscribe((data: DocumentItem) => {
      this.data = data;
      this.title = data?.document?.title;
      this.desc = data?.document?.description;
      this.effectiveDate = data?.effective_date;

      let newArray = data?.document?.tags?.length ? data?.document?.tags[0].split(',') : []
      this.chipList = newArray.filter(Boolean);

      this.configService.isLoader = false
    }, () => {
      this.configService.isLoader = false
    }, () => {
      this.configService.isLoader = false
    })
  }

  changeCategory(event: any): void {
    this.tags = [];
    event.forEach(element => {
      if(element instanceof Object) {
        this.tags.push(element.name)
      } else {
        this.tags.push(element)
      };
    });
    this.updateFile();
  }

  tagsUpdated(){
    this.updateFile();
  }
}
