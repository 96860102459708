import { DocumentInfo } from '../../_shared/models/porperty-manager/documents'

export interface RequiredSignature {
  id: string
  name: string
  company_name: string
  user_contact_id: string
  user_id: string
  user_role: string,
  signature_requested_at: string
  signature_id: string | null
  signature_file_id: string | null
  signature_text: string | null,
  signed_at: string | null
  signature_file: DocumentInfo | null

  vendor_name?: string
}

export interface ChecklistSignatures {
  engineer: RequiredSignature[],
  tenant: RequiredSignature[],
  manager: RequiredSignature[],
  vendor: RequiredSignature[],
}

export interface SignaturePostRequestBody {
  name: string,
  company_name: string,
  signature_text: string | null,
  checklist_id: string,
  user_role: string,
  job_id: string,
  signature_file_id: string | null;
  contact_id?: string;
  vendor_id?: string;
}

export enum SignatureThreeDotsActions {
  SIGN = 'SIGN',
  NOTIFY_AGAIN = 'NOTIFY_AGAIN',
  CONTACT_INFO = 'CONTACT_INFO',
  DELETE = 'DELETE',
}

export enum SignatureStatus {
  SIGNED = 'signed',
  UNSIGNED = 'unsigned',
}
