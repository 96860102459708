export const JOB_CREATE_SHOW_FIELDS = [
  'service_type',
  'category',
  'unit',
  'priority'
];

export const EQUIPMENT_CREATE_SHOW_FIELDS = [
  'name',
  'active',
  'property_name',
  'condition',
  'safety_notes',
  'category_name'
];
export const EQUIPMENT_UPDATE_DELETE_FIELDS = ['category', 'property'];

export const COMPANY_CREATE_SHOW_FIELDS = [
  'name',
  'active',
  'entity_name',
  'phones',
  'type',
  'email',
  'addresses',
  'type'
];
export const CONTACT_CREATE_SHOW_FIELDS = [
  'first_name',
  'last_name',
  'type',
  'primary_company'
];

export const specialWords = [
  'properties',
  'modified_at',
  'modified',
  'id',
  '_by',
  '_at',
  '_id',
  'status',
  '_record',
  'activate_inspection'
];

export const leaseFields = [
  'tenant',
  'property',
  'units',
  'contact_primary',
  'commencement_date',
  'expiration_date',
  'is_month_to_month',
  'external_code',
  'activate_inspection',
  'move_in_inspection',
  'move_out_inspection',
  'inspection_scheduler_id'
];

export const leaseRulesFields = [
  'category_id',
  'category_name',
  'categories',
  'rule_for',
  'rule_for',
  'rule_applies',
  'management_responsible',
  'tenant_responsible',
  'criteria'
];

export const leaseProvisionFields = ['name', 'clause', 'assigned_document'];

export const leaseDocumentsFields = ['name'];

export const leaseInspectionFields = [
  'inspection_scheduler_name',
  'move_in_checklist_id',
  'move_out_checklist_id',
  'move_in_date',
  'move_out_date',
  'move_in_inspection_job',
  'move_out_inspection_job',
  'category',
  'property',
  'target_date',
  'units'
];

export const leaseContactsFields = [
  'first_name',
  'last_name',
  'title',
  'phone',
  'email',
  'primary_company'
];

export const leaseCategoriesFields = ['id', 'name', 'type'];

export const COIFields = [
  'id',
  'title',
  'company',
  'company_type',
  'properties',
  'effective_date',
  'expiration_date',
  'categories',
  'risk_level',
  'description',
  'insurance_company'
];

export const coveragesFields = [
  'id',
  'policy_number',
  'effective_date',
  'expiration_date',
  'limits',
  'brief_description',
  'coverage_type',
  'insurance_company'
];

export enum leaseActivityActions {
  UPDATED = 'updated the lease.',
  ADDED_UNIT = 'added unit.',
  ADDED_UNITS = 'added units.',
  REMOVED_UNIT = 'removed unit.',
  REMOVED_UNITS = 'removed units.',
  ASSIGNED_PRIMARY_CONTACT = 'assigned primary contact for lease.',
  UNASSIGNED_PRIMARY_CONTACT = 'unassigned primary contact for lease.',
  ASSIGNED_CONTACT = 'assigned contact for lease.',
  ASSIGNED_CONTACTS = 'assigned contacts for lease.',
  UNASSIGNED_CONTACT = 'unassigned contact for lease.',
  UNASSIGNED_CONTACTS = 'unassigned contacts for lease.',
  ACTIVATED_INSPECTIONS = 'activated inspections for lease.',
  UNACTIVATED_INSPECTIONS = 'unactivated inspections for lease.',
  REQUESTED_DOCUMENT = 'request abstraction for lease document.',
  UPDATED_DOCUMENT = 'edited the document.',
  UPDATED_CONTACTS = 'updated the lease contacts.'
}

export enum COIActivityActions {
  UPDATED = 'edited the COI.',
  UPDATED_PROPERTIES = 'updated properties.',
  ADDED_PROPERTY = 'added property.',
  ADDED_PROPERTIES = 'added properties.',
  REMOVED_PROPERTY = 'removed property.',
  REMOVED_PROPERTIES = 'removed properties.',
  UPDATED_CATEGORIES = 'updated categories.',
  ADDED_CATEGORY = 'added category.',
  ADDED_CATEGORIES = 'added categories.',
  REMOVED_CATEGORY = 'removed category.',
  REMOVED_CATEGORIES = 'removed categories.'
}

export enum ACTIVITY_ENTITY_TYPE {
  LEASE = 'LEASES_MAIN',
  RULES = 'LEASE_RULES',
  DOCUMENTS = 'LEASE_DOCUMENTS',
  PROVISIONS = 'LEASE_PROVISIONS',
  INSPECTIONS = 'LEASE_INSPECTIONS',
  CONTACTS = 'LEASE_CONTACTS',
  OTHER_CONTACTS = 'LEASE_OTHER_CONTACTS',
  CATEGORIES = 'LEASE_CATEGORIES',
  COI = 'COI',
  COVERAGES = 'COI_COVERAGES',
  CUSTOM_ATTRIBUTES_FIELDS = 'CUSTOM_ATTRIBUTES_FIELDS',
  PROPERTY_GROUP = 'PROPERTY_GROUP'
}

export const PROPERTY_CREATE_SHOW_FIELDS = [
  'name',
  'management_agreement_limit',
  'lease_type',
  'type_of_tenant',
  'type',
  'total_square_feet',
  'property_type',
  'address',
  'categories'
];

export const JOB_SCHEDULE_CREATE_SHOW_FIELDS = [
  'date',
  '_from',
  'to',
  'status',
  'rescheduled',
  'unit_entry_permission',
  'user_type',
  'stage',
  'company',
  'type'
];

export const JOB_COST_CREATE_SHOW_FIELDS = [
  'title',
  'type',
  'identifier',
  'total',
  'material_cost',
  'stage',
  'company',
  'name',
  'email',
  'phone',
  'equipment'
];

export const JOB_TIMESHEET_CREATE_SHOW_FIELDS = [
  'date',
  '_from',
  'to',
  'status',
  'labor_time',
  'approved_on',
  'description',
  'engineer',
  'equipment'
];

export const JOB_MATERIAL_CREATE_SHOW_FIELDS = [
  'description',
  'quantity',
  'unit',
  'cost',
  'total',
  'status',
  'equipment'
];

export const JOB_SITE_VISIT_SHOW_FIELDS = [
  'date',
  '_from',
  'to',
  'status',
  'rescheduled',
  'unit_entry_permission',
  'type',
  'company',
  'stage'
];
