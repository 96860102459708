import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { ConfigService } from 'src/app/services/config.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PredefinedTasksService {
 
  constructor(private httpClient: HttpClient,  public _Config: ConfigService,) { }


   /**
   * To Get Http Request for REST API
   * @param URL
   * @param Options
   */
   doGET(URL?: string, Options?: any): Observable<any> {
    return this.httpClient.get(URL, Options).pipe(take(1));
  }
}
