import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { event } from 'jquery';
import { Subject } from 'rxjs';
import { debounce, debounceTime, map } from 'rxjs/operators';
import { FilesService } from 'src/app/Modules/_fyxt_modules/companies/_services/files.service';
import { BaseService } from 'src/app/services/base.service';
import { UtilityService } from 'src/app/services/utility.service';
import { JobsService } from 'src/app/services/v2/jobs.service';
import { environment } from 'src/environments/environment';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { DropdownSelectComponent } from '../../../_reusable_ui/_controls/dropdown-select/dropdown-select/dropdown-select.component';
import { NgIf, NgFor, DatePipe } from '@angular/common';
import { TextareaComponent } from '../../../_reusable_ui/_controls/textarea/textarea.component';
import { TagsComponent } from '../../../_reusable_ui/_components/tags/tags.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';
import { FormsModule } from '@angular/forms';
@Component({
    selector: 'fyxt-tab-file-information',
    templateUrl: './tab-file-information.component.html',
    styleUrls: ['./tab-file-information.component.scss'],
    standalone: true,
    imports: [FormsModule, TextboxComponent, NgxIntlTelInputModule, TagsComponent, TextareaComponent, NgIf, DropdownSelectComponent, NgFor, LoaderSmallComponent, DatePipe]
})
export class TabFileInformationComponent implements OnInit {
  data: any;
  title = '';
  desc = '';
  tags: any = [] = [];
  @Input() chipList: any[] = [];
  @Input() isLoader!: boolean;
  @Output() onChangeCategories = new EventEmitter();
  private debounceSubject = new Subject<any>();
  baseUrl

  showVendorList = false;
  vendorList: any[] = [];
  isVendorLoading = false;
  selectedVendorList: any[] = [];

  constructor(
    private fileService: FilesService,
    private _jobService: JobsService,
    private _baseService: BaseService,
    private _utilityService: UtilityService) { }

  ngOnInit(): void {
    this.baseUrl = environment.files.http;
    this.baseUrl = this.baseUrl.substring(0, this.baseUrl.length - 1);
    this.fileService.getCurrentFile().subscribe((data) => {
      if (data != false) {
        this.getFile(data.id);
        this.getUserPermittedFiles(data.id);
      }
    })

    this.showVendorList = this.fileService.selectedEntityType == 'JOB' ? true : false;


    if (this.showVendorList) {
      this.getAllVendors('', true);
    }

    this.debounceSubject.pipe(debounceTime(300)).subscribe((data: any) => {
      this.getAllVendors(data.term);
    })

  }

  titleisChanged(e) {
    if (this.title == '' || this.title == null) {
      return
    }

    this.updateFile();
  }

  descisChanged(e) {
    if (this.desc == '' || this.desc == null) {
      return
    }
    this.updateFile();
  }


  updateFile() {
    this.fileService.updateFile(this.data?.id, this.title, this.desc, this.tags).subscribe((data: any) => {
    })
  }

  getFile(id) {
    if (id == undefined) {
      return;
    }
    this.fileService.getFile(id).pipe(map((data: any) => data?.data?.file)).subscribe((data: any) => {
      this.title = data?.title;
      this.data = data;
      this.desc = data?.description;
      const tags = data?.tags ?? [];
      if (tags.length > 0) {
        let newArray = data?.tags[0].split(',')
        this.chipList = newArray.filter(value => value !== '');
        this.tags = this.chipList;
      } else {
        this.chipList = [];
        this.tags = [];
      }
    })
  }

  changeCategory(event: any): void {
    this.tags = [];
    event.forEach(element => {
      if (element instanceof Object) {
        this.tags.push(element.name)
      } else {
        this.tags.push(element)
      };
    });
    this.updateFile();
  }

  tagsUpdated() {
    this.updateFile();
  }

  getAllVendors(searchInput: string, loading: boolean = false) {
    if(!this._jobService.selectedJobInfo.id){
      return null;
    }
    loading ? (this.isVendorLoading = true) : (this.isVendorLoading = false)
    let requestURL = environment.baseURL + `vendor-jobs/assigned-vendors/?page=1&limit=25&offset=0&job_id=${this._jobService.selectedJobInfo.id}`;
    if (searchInput !== '') {
      requestURL += `&search=${encodeURIComponent(searchInput)}`;
    }

    this._baseService.doGET(requestURL).subscribe({
      next: (res: any) => {
        this.isVendorLoading = false;

        this.vendorList = res?.results ?? [];
        this.filterListingData();

        this.vendorList.forEach(e => {
          e["name"] = e.company_name;
        });

      },
      error: (error) => {
        this.isVendorLoading = false
      },
      complete: () => {


      }
    })
  }

  onVendorChange(event) {
    this.addFilePermission(event[event.length - 1]);
  }


  addFilePermission(event) {
    const payload = {
      "fileId": this.data.id,
      "userId": event.company_id ? event.company_id : event.id,
      "userType": "VENDOR",
      "permissionLevel": "READ",
      "createdByType": "USER"
    }

    this.fileService.addFilePermission(payload).subscribe((res) => {
      this.getUserPermittedFiles(this.data.id);
    }, (err: HttpErrorResponse) => {
      //this._utilityService.showError('', err?.error?.message)

    })
  }


  getUserPermittedFiles(id) {
    this.fileService.getUserPermittedFiles(id).subscribe((res: any) => {
      const data = res.data ?? [];
      data.map((r) => { r["name"] = r.first_name + r.last_name; return r })
      this.selectedVendorList = data;
      this.filterListingData();
    })
  }

  removeVendor(v) {
    const payload = {
      "fileId": this.data.id,
      "userId": v.id
    }
    this.fileService.removeFilePermission(payload).subscribe((res) => {
      this.getUserPermittedFiles(this.data.id);
      this.getAllVendors('', false);
    })
  }


  filterListingData(){
    this.vendorList = this.vendorList.filter(itemB => !this.selectedVendorList.some(itemA => itemA.id === itemB.company_id));
  }

  searchVendorDropdown(e) {
    this.debounceSubject.next(e);
  }

  onRemove(e){
    this.removeVendor(e);
  }

}
