import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
@Component({
    selector: 'fyxt-date-range-picker',
    templateUrl: './date-rangepicker.component.html',
    styleUrls: ['./date-rangepicker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => DateRangepickerComponent)
        }
    ],
    standalone: true,
    imports: [MatFormFieldModule, MatDatepickerModule, MatInputModule, FormsModule, NgxIntlTelInputModule]
})
export class DateRangepickerComponent implements OnInit, ControlValueAccessor {
  @Input() input: any;
  @Input() minDate: any;
  @Input() maxDate: any;
  @Input() placeHolder!: string;
  @Input() disable: boolean = false;
  @Input() label: any;
  @Input() Error: boolean = false;
  @Output() onValueChange = new EventEmitter<Event>();

  dateRange = { start: new Date(), end: new Date() }

  constructor() { }

  ngOnInit(): void {
  }

  onChange = (data: any) => { }
  onTouch = (_: any) => { }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: string) {
    if (value === null) {
      this.input = null;
    } else {
      this.input = new Date(value);
    }

    this.onChange(value);
  }

  /**
  *Func triggered on input change
  */
  onchange(event: any) {
    this.onChange(event);
    this.onValueChange.emit(event);

    this.dateRange = { start: new Date(), end: new Date() }

  }


  onDateChange(event: any) {
    if ((this.dateRange.start && this.dateRange.end)) {
      this.input = this.dateRange;
    }
  }

}
