import {Component, OnInit} from '@angular/core';
import {environment} from "../../../../../../environments/environment";
import {retry, takeUntil} from "rxjs/operators";
import {InboxService_New} from "../../../../../Modules/_fyxt_modules/inbox-management/_services/inbox.service";
import {JobsService} from "../../../../../services/v2/jobs.service";
import {UtilService} from "../../../../../services/v2/util.service";
import {Subject} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {UiService} from "../../../../../services/ui/ui.service";
import {GetAllAttachments} from "../../../../../Modules/_fyxt_modules/inbox-management/inbox.types";
import {FilesSliderComponent} from "../../_tab_components/tab-files/files-slider/files-slider.component";
import {FilesService} from "../../../../../Modules/_fyxt_modules/companies/_services/files.service";
import {
  ComposerPopupComponent
} from "../../../../../Modules/_fyxt_modules/inbox-management/components/composer-popup/composer-popup.component";
import {DialogService} from "../../../../_services/dialog/dialog.service";
import {InboxSendMessagePopupComponent} from "../inbox-send-message-popup/inbox-send-message-popup.component";
import {sequence} from "@angular/animations";
import { BaseService } from 'src/app/services/base.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { NgIf, NgFor, DatePipe } from '@angular/common';

@Component({
    selector: 'fyxt-associated-mails-in-job',
    templateUrl: './associated-mails-in-job.component.html',
    styleUrls: ['./associated-mails-in-job.component.scss'],
    standalone: true,
    imports: [NgIf, LoaderSmallComponent, SearchInputComponent, ButtonComponent, MatMenuModule, MatIconModule, NgFor, MatCheckboxModule, TooltipModule, InfiniteScrollModule, DatePipe]
})
export class AssociatedMailsInJobComponent implements OnInit {
  private readonly destroy$: Subject<null> = new Subject();
  allAssociatedMails = [];
  checkedMails = [];
  pageNumber = 1;
  isLoader = false;
  paginationLoader = false;
  bulkActionList: any[] = [
    {id: 1, label: 'Mark as Closed', value: 'Mark as Closed'},
    // {id: 2, label: 'Reply to All', value: 'Reply to All'},
  ];
  isAllChecked = false;
  conversation = [];
  allAttachments = [];
  openThreadDetails: any;
  singleThreadDetailsLoader = false;
  base: string;
  isAllSameMailBox: boolean;
  showCheckBox = true;

  constructor(public inboxService: InboxService_New, public _jobService: JobsService, public _utilService: UtilService, public _uiService: UiService,
              private _fileService: FilesService, private dialog: MatDialog,
              private router: Router, private activatedRoute: ActivatedRoute, public dialogRef: MatDialogRef<AssociatedMailsInJobComponent>,
    public _dialogService: DialogService, public _baseService: BaseService) {
    this.base = environment.files.http;
    this.base = this.base.substring(0, this.base.length - 1);
  }

  ngOnInit(): void {
    this.getAllAssociatedMails(true);
    if (this._baseService.isPermissionExist('add_mail')) {
      this.showCheckBox = false;
    }
  }

  getAllAssociatedMails(isLoader: boolean): void {
    this.isLoader = isLoader;
    let requestURL = `${environment.inbox.http}thread-jobs/jobs/${this._jobService.selectedJobInfo.id}/?page=${this.pageNumber}`;
    let isAlreadyExpandMessage = this.allAssociatedMails.find((mail: any) => {
      return mail.expand == true;
    });
    this.inboxService.getAllAssociatedMailsInJob(requestURL).pipe(takeUntil(this.destroy$)).subscribe(
      {
        next: (response: any) => {
          if (this.pageNumber == 1) {
            this.allAssociatedMails = response.data.results;
          } else {
            this.allAssociatedMails = this.allAssociatedMails.concat(response.data.results);
          }
          this.allAssociatedMails.map((mail: any) => {
            this.checkedMails.map((checkedMail: any) => {
              if (mail.sequence == checkedMail.sequence) {
                mail.checked = true;
              }
            });
            if (isAlreadyExpandMessage?.sequence == mail.sequence) {
              mail.expand = true;
            } else {
              mail.expand = false;
            }
            return mail;
          });
        },
        error: (error) => {
         // this._utilService.showErrorMessage(error);
          this.isLoader = false;
          this.paginationLoader = false;
        },
        complete: () => {
          this.isLoader = false;
          this.paginationLoader = false;
        }
      });
  }

  fetchNextPage(): void {
    this.pageNumber += 1;
    this.paginationLoader = true;
    this.getAllAssociatedMails(false);
  }

  onCheckRow(event: any, mail: any) {
    mail.checked = event.checked;
    let updatedCheckedMails: number[];
    const isMailChecked = this.checkedMails.some((checkedCompany) => checkedCompany.id === mail.id);
    if (isMailChecked) {
      updatedCheckedMails = this.checkedMails.filter((checkedCompany) => checkedCompany.id !== mail.id);
    } else {
      updatedCheckedMails = [...this.checkedMails, mail];
    }
    this.checkedMails = updatedCheckedMails;
    const isAllSelected = this.allAssociatedMails.every((mail: any) => {
      return mail.checked == true;
    });
    if (isAllSelected) {
      this.isAllChecked = true;
    } else {
      this.isAllChecked = false;
    }
    if (this.checkedMails.length == 1) {
      this.isAllSameMailBox = true;
    } else {
      this.isAllSameMailBox = this.checkedMails.every((mail: any) => {
        return mail.mailboxId == this.checkedMails[0].mailboxId;
      });
    }
    if (this.isAllSameMailBox) {
      const replyToAllPayload = {
        id: this.bulkActionList.length + 1,
        label: 'Reply to All',
        value: 'Reply to All'
      }
      const isAlreadyReplyActionThere = this.bulkActionList.some((bulk: any) => {
        return bulk.value == 'Reply to All';
      });
      if (isAlreadyReplyActionThere) {
        return null;
      } else {
        this.bulkActionList.push(replyToAllPayload);
      }
    } else {
      const isAlreadyReplyActionThere = this.bulkActionList.some((bulk: any) => {
        return bulk.value == 'Reply to All';
      });
      if (isAlreadyReplyActionThere) {
        this.bulkActionList.pop();
      } else {
        return null;
      }
    }
  };

  isAllSelected(event: any) {
    this.isAllChecked = event.checked;
    if (this.isAllChecked) {
      this.checkedMails = [];
      this.checkedMails = this.checkedMails.concat(this.allAssociatedMails);
      this.allAssociatedMails.map((companyData: any) => {
        return companyData.checked = true;
      });
      if (this.checkedMails.length == 1) {
        this.isAllSameMailBox = true;
      } else {
        this.isAllSameMailBox = this.checkedMails.every((mail: any) => {
          return mail.mailboxId == this.checkedMails[0].mailboxId;
        });
      }
    } else {
      this.checkedMails = [];
      this.allAssociatedMails.map((companyData: any) => {
        return companyData.checked = false;
      });
      this.isAllSameMailBox = true;
    }
    if (this.isAllSameMailBox) {
      const replyToAllPayload = {
        id: this.bulkActionList.length + 1,
        label: 'Reply to All',
        value: 'Reply to All'
      }
      const isAlreadyReplyActionThere = this.bulkActionList.some((bulk: any) => {
        return bulk.value == 'Reply to All';
      });
      if (isAlreadyReplyActionThere) {
        return null;
      } else {
        this.bulkActionList.push(replyToAllPayload);
      }
    } else {
      const isAlreadyReplyActionThere = this.bulkActionList.some((bulk: any) => {
        return bulk.value == 'Reply to All';
      });
      if (isAlreadyReplyActionThere) {
        this.bulkActionList.pop();
      } else {
        return null;
      }
    }
  };

  expandToggle(event: any, mail: any, status: any) {
    event.stopPropagation();
    if (status == 'open') {
      this.allAssociatedMails.map((mail: any) => {
        return mail.expand = false;
      });
      mail.expand = true;
      this.allAttachments = [];
      this.openThreadDetails = null;
      this.getSelectedThreadMessage(mail.id);
    } else if (status == 'close') {
      mail.expand = false;
    }
  }

  getSelectedThreadMessage(threadId: any): void {
    this.singleThreadDetailsLoader = true;
    this.inboxService.getSingleThreads(threadId).pipe(takeUntil(this.destroy$)).subscribe(
      {
        next: (response: any) => {
          response.data.messages.map((message: any, index: any) => {
            message.attachments = [];
            this.getReplyMessageQuotes(message, index);
            return message;
          });
          this.openThreadDetails = response.data;
          },
        error: (error) => {
          this._utilService.showErrorMessage(error);
          this.singleThreadDetailsLoader = false;
        },
        complete: () => {
          this.getAllAttachments();
          this.singleThreadDetailsLoader = false;
        }
      }
    );
  }

  private getAllAttachments() {
    const messageIds = this.openThreadDetails.messages.map((data) => data.id);
    let payload: GetAllAttachments = {
      "entityIds": messageIds,
      "entityType": "MESSAGE"
    }
    this.inboxService.getAllAttachments(payload).pipe(takeUntil(this.destroy$)).subscribe({
      next: (data: any) => {
        this.allAttachments = data.data ?? [];
        if (this.allAttachments.length > 0) {
          this.appendFilesToParticularThreads();
        }
      }
    });
  }

  appendFilesToParticularThreads(): void {
    if (this.openThreadDetails.messages.length > 0) {
      this.openThreadDetails.messages.map((message: any) => {
        this.allAttachments.map((attachment: any) => {
          if (attachment.entityId == message.id) {
            message.attachments.push(attachment);
          }
        });
      });
    }
  }

  getReplyMessageQuotes(message: any, index: number): void {
    let replaceTag = `class="gmail_quote" tabindex="` + (index + 1) + `"`;
    message.content = message.content.replace('class="gmail_quote"', replaceTag);

    let replaceTagOutlook = `id="divRplyFwdMsg" class="outlookReply" tabindex="` + (index + 1) + `"`;
    message.content = message.content.replace('id="divRplyFwdMsg"', replaceTagOutlook);

    let replaceTagNext = `class="divRplyFwdMsg_next" tabindex="` + (index + 1) + `"`;
    message.content = message.content.replace('class="divRplyFwdMsg_next"', replaceTagNext);

    let replaceTagNext1 = `class="msg_reply_new_long" tabindex="` + (index + 1) + `"`;
    message.content = message.content.replace('class="msg_reply_new_long"', replaceTagNext1);
    this._uiService.emailToggle();
  }

  bulkActionPopup(item: any): void {
    if (item.label == 'Mark as Closed') {
      this.markAsClosed();
    } else if (item.label == 'Reply to All') {
      this.replyToAllPopup();
    }
  }

  replyToAllPopup(): void {
    let popupData = {
      title: 'New Bulk Reply',
      component: InboxSendMessagePopupComponent,
      containerClass: 'snippet_popup',
      data: {
        checkedThreads: this.checkedMails,
        showToggleComposer: false,
        isFromBulkReply: true,
        openComposerFromThirdPanel: false,
        isFromAssociatedJobs: true
      }
    };
    this._dialogService.openModal(popupData).subscribe(result => {
      if (result == 'sent successfully') {
        this._utilService.showSuccess('', 'Message Sent Successfully');
      }
      this.dialogRef.close();
    });
  }

  markAsClosed(): void {
    let payload = {
      threadIds: this.checkedMails.map((thread: any) => {
        return thread.id;
      }),
      status: 'CLOSED',
      folder: 'INBOX'
    };
     this.inboxService.updateThreadStatus(payload).pipe(takeUntil(this.destroy$)).subscribe(
      {
        next: (response: any) => {
        },
        error: (error) => {
          this._utilService.showErrorMessage(error);
        },
        complete: () => {
         this._utilService.showSuccess('', 'Mails Closed Successfully');
         this.dialogRef.close();
        }
      });
  }

  navigateToInbox(mail: any): void {

    if (!this._baseService.isPermissionExist('add_mail')) {
      return null;
    }

    let status = '';
    if (mail.status == 'OPEN') {
      status = 'Open Conversations';
    } else if (mail.status = 'CLOSED') {
      status = 'Closed Conversations';
    }
    this.dialogRef.close();
    // this.router.navigate(['pm/inbox-management/' + mail.mailboxId], {relativeTo: this.activatedRoute});
    window.open('pm/inbox-management/' + mail.mailboxId + '?folder=' + status + `&threadID=` + mail.id, '_blank');
  }

  protected openAttachmentPopup(event: any, attachments: any): void {
    let data = {
      "rows": attachments
    }
    this._fileService.sliderData = data;
    this._fileService.currentFileClicked = event.key;
    this._fileService.isTenantPortal = true;
    this._fileService.isAttachment = true;

    this.filesPopUp();
  }

  private filesPopUp() {
    this.dialog.open(FilesSliderComponent, {panelClass: 'custom-dialog-container'});
    this.dialog.afterAllClosed.subscribe((data) => {
      this._fileService.isTenantPortal = false;
      this._fileService.isAttachment = false;
      // this.refreshData.emit(fourthPanelEmitter.ATTACHMENTS);
    });
  }

   /** Track by Index  **/
   trackByIndex(index: number, item: any): number {
    return index;
  }
}
