// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox-padding {
  padding-right: 24px;
}

.filter_text {
  font-size: 14px;
  font-weight: bold;
  color: #192C49;
}

.report_text {
  font-size: 16px;
  font-weight: 500;
  color: #192c49;
}

::ng-deep .bs-datepicker-head {
  width: 22vw;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_views/_popup_views/timesheet-buildreport/timesheet-buildreport.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AAEA;EACI,eAAA;EACA,iBAAA;EACA,cAAA;AACJ;;AAKA;EACI,eAAA;EACA,gBAAA;EACA,cAAA;AAFJ;;AAMA;EACI,WAAA;AAHJ","sourcesContent":[".checkbox-padding{\n    padding-right: 24px;\n}\n\n.filter_text{\n    font-size: 14px;\n    font-weight: bold;\n    color: #192C49;\n    // padding-left: 10px;\n    // margin-top: 20px;\n    // margin-bottom: 6px;\n}\n\n.report_text{\n    font-size: 16px ;\n    font-weight: 500 ;\n    color: #192c49; \n    // padding-left: 10px;\n}\n\n::ng-deep .bs-datepicker-head {\n    width: 22vw;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
