import { AfterViewChecked, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Directive({
    selector: '[fyxtBreadcrumbs]',
    standalone: true
})
export class BreadcrumbsDirective implements AfterViewChecked {

  @Input() ufp: any;
  @Output() breadCrumbs = new EventEmitter<any>();
  folderList: any = [];
  moreFolders: any = [];
  finalFolderList: any = [];
  headerWidth!: any;

  constructor(public el: ElementRef) {
  }

  ngAfterViewChecked(): void {
    this.headerWidth = this.el.nativeElement.offsetWidth;
    this.getBreadcrumbs();
  }

  getBreadcrumbs() {
    this.folderList = [];
    this.finalFolderList = [];
    this.moreFolders = [];

    let maxCharsAllowed = Math.floor(this.headerWidth / 9);
    let totalChars: number = 0;

    if (this.ufp != '/') {
      this.folderList = this.ufp.split('/');
    }
    else {
      this.folderList.push('');
    }

    this.folderList.forEach((url) => {

      totalChars += url ? url.length : 10;
      if (totalChars < maxCharsAllowed) {
        this.finalFolderList.push(url);
      }
      else {
        this.finalFolderList.push(url);
        do {
          let removedItem = this.finalFolderList.shift();
          totalChars -= removedItem?.length;
          this.moreFolders.push(removedItem);

        }
        while (totalChars > maxCharsAllowed && this.finalFolderList.length > 1)
      }
    });
    this.breadCrumbs.emit({ folderList: this.finalFolderList, moreFolders: this.moreFolders })
  }
}
