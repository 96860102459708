import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators'
import { CoiService } from '../../../../../Modules/_fyxt_modules/coi/_services/coi.service'
import { Subject } from 'rxjs'
import { UtilService } from '../../../../../services/v2/util.service'
import { AbstractControl, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import {
  ICoverage,
  ICoverageForm,
  ICoverageType,
  ICreateCoverage,
  IUpdateCoverage
} from '../../../models/coi/coverage.interface';
import { ICoi } from '../../../models/coi/coi.interface'
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DropdownComponent } from '../../../_reusable_ui/_controls/dropdown/dropdown.component';
import { DropdownDateSelectComponent } from '../../../_reusable_ui/_controls/dropdown-select/dropdown-date-select/dropdown-date-select.component';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';
import { NgIf } from '@angular/common';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { DropdownSelectComponent } from '../../../_reusable_ui/_controls/dropdown-select/dropdown-select/dropdown-select.component';

@Component({
    selector: 'app-add-coverage',
    templateUrl: './add-coverage.component.html',
    styleUrls: ['./add-coverage.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, DropdownSelectComponent, NgxIntlTelInputModule, NgIf, TextboxComponent, DropdownDateSelectComponent, DropdownComponent, MatFormFieldModule, MatDividerModule, MatDialogModule, ButtonComponent]
})
export class AddCoverageComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<null> = new Subject();
  addCoverageForm: FormGroup<ICoverageForm>;
  formSubmitted: boolean = false;
  currentCoi: ICoi | null = null;
  currentCoverage: ICoverage | null = null;

  // Coverage Types
  allCoverageTypesList: ICoverageType[] = [];

  // Company Insurance Dropdown
  allCompaniesInsuranceList: any[] = [];
  allCompaniesInsuranceListPageNumber: number = 1;
  allCompaniesInsuranceListTotal: number;
  allCompaniesInsuranceListSize: number;
  companiesInsuranceSearchInput: any = '';
  allCompaniesInsuranceListPageCount: number;
  allCompaniesInsuranceLoading: boolean = false;

  isCreating = false;
  isUpdating = false;

  constructor(
    public dialogRef: MatDialogRef<AddCoverageComponent>,
    private readonly coiService: CoiService,
    private readonly utilService: UtilService,
    @Inject(MAT_DIALOG_DATA) public matDialogData: any,
  ) {
    this.coiService.currentCoi$.pipe(takeUntil(this.destroy$)).subscribe((coi) => {
      this.currentCoi = coi;
    });
    this.currentCoverage = matDialogData.data?.coverage
  }

  ngOnInit(): void {
    this.initForm()
    this.getAllCompanyInsuranceDropdowns();
    this.getAllCoverageTypes();
  }


  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private initForm() {
    if (this.currentCoverage) return this.initUpdateForm()
    this.addCoverageForm = new FormGroup<ICoverageForm>({
      coverage_type: new FormControl(null, [Validators.required]),
      policy_number: new FormControl(''),
      effective_date: new FormControl(this.coiService.getUTCData(this.currentCoi.effective_date)),
      expiration_date: new FormControl(this.coiService.getUTCData(this.currentCoi.expiration_date),
        [Validators.required, this.expirationDateValidator]),
      company_insurer: new FormControl(null),
      limits: new FormControl(''),
      description: new FormControl(''),
    }, { validators: this.dateComparisonValidator })
  }

  private initUpdateForm() {
    const coverage_type = {
      id: this.currentCoverage.coverage_type_id,
      name: this.currentCoverage.coverage_type,
    }
    const company_insurer = {
      id: this.currentCoverage.insurer_company_id,
      name: this.currentCoverage.insurance_company,
    }
    this.addCoverageForm = new FormGroup<ICoverageForm>({
      coverage_type: new FormControl(coverage_type, [Validators.required]),
      policy_number: new FormControl(this.currentCoverage.policy_number),
      effective_date: new FormControl(this.coiService.getUTCData(this.currentCoverage.effective_date)),
      expiration_date: new FormControl(this.coiService.getUTCData(this.currentCoverage.expiration_date),
        [Validators.required, this.expirationDateValidator]),
      company_insurer: new FormControl(company_insurer, [Validators.required]),
      limits: new FormControl(this.currentCoverage.limits),
      description: new FormControl(this.currentCoverage.brief_description),
    }, { validators: this.dateComparisonValidator })
  }

  public getAllCoverageTypes() {
    this.coiService.getAllCoverageTypes().pipe(takeUntil(this.destroy$)).subscribe((coverageTypes) => {
      this.allCoverageTypesList = coverageTypes;
    })
  }

  public companyInsuranceDropdownSearch(event: any) {
    this.companiesInsuranceSearchInput = event.term;
    this.allCompaniesInsuranceListPageNumber = 1;
    this.getAllCompanyInsuranceDropdowns();
  }

  private getAllCompanyInsuranceDropdowns(pagination?: boolean) {
    this.allCompaniesInsuranceLoading = true;
    this.coiService
      .getCompaniesInsurance(this.allCompaniesInsuranceListPageNumber, this.companiesInsuranceSearchInput)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (pagination) {
            this.allCompaniesInsuranceList = this.allCompaniesInsuranceList.concat(
              response.items,
            );
          } else {
            this.allCompaniesInsuranceList = response.items;
          }
          this.allCompaniesInsuranceListTotal = response.total;
          this.allCompaniesInsuranceListSize = response.size;
          this.allCompaniesInsuranceListPageCount = Math.round(
            this.allCompaniesInsuranceListTotal / this.allCompaniesInsuranceListSize,
          );
          this.allCompaniesInsuranceLoading = false;
        },
        error: (error) => {
          this.utilService.showErrorMessage(error);
          this.allCompaniesInsuranceLoading = false;
        },
        complete: () => {
        },
      });
  }

  public companyInsuranceDropdownsScroll() {
    if (this.allCompaniesInsuranceListPageNumber < this.allCompaniesInsuranceListPageCount) {
      this.allCompaniesInsuranceListPageNumber++;
      this.getAllCompanyInsuranceDropdowns(true);
    }
  }

  public handleAddCoverage() {
    this.formSubmitted = true;

    if (this.addCoverageForm.valid && !this.isCreating) {
      const {
        coverage_type,
        policy_number,
        effective_date,
        expiration_date,
        company_insurer,
        limits,
        description
      } = this.addCoverageForm.value

      const payload: ICreateCoverage = {
        coi_id: this.currentCoi.id,
        coverage_type: coverage_type?.name,
        expiration_date,
        insurance_company_id: company_insurer?.id,
      }

      if (policy_number) payload.policy_number = policy_number;
      if (effective_date) payload.effective_date = effective_date;
      if (limits) payload.limits = limits;
      if (description) payload.brief_description = description;

      this.isCreating = true;
      this.coiService.createCoverage(payload).pipe(takeUntil(this.destroy$)).subscribe((res) => {
        this.isCreating = false;
        this.dialogRef.close(res)
      },
        () => {
          this.isCreating = false;
        },
        () => {
          this.isCreating = false;

        })
    }
  }

  public handleUpdateCoverage() {
    this.formSubmitted = true;

    if (this.addCoverageForm.valid && !this.isUpdating) {
      const {
        coverage_type,
        policy_number,
        effective_date,
        expiration_date,
        company_insurer,
        limits,
        description
      } = this.addCoverageForm.getRawValue()

      const payload: IUpdateCoverage = {}
      if (coverage_type?.name && coverage_type?.name !== this.currentCoverage.coverage_type) payload.coverage_type = coverage_type?.name;
      if (expiration_date && this.coiService.getDateIgnoringTimezone(expiration_date) !== new Date(this.currentCoverage.expiration_date).toISOString()) payload.expiration_date = expiration_date;
      if (company_insurer?.id && company_insurer?.id !== this.currentCoverage.insurer_company_id) payload.insurance_company_id = company_insurer?.id;
      if (policy_number && policy_number !== this.currentCoverage.policy_number) payload.policy_number = policy_number;
      if (effective_date && this.coiService.getDateIgnoringTimezone(effective_date) !== new Date(this.currentCoverage.effective_date).toISOString()) payload.effective_date = effective_date;
      if (limits && limits != this.currentCoverage.limits) payload.limits = +limits;
      if (description && description !== this.currentCoverage.brief_description) payload.brief_description = description;
      this.isUpdating = true;
      this.coiService.updateCoverage(this.currentCoverage.id,  payload)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.isUpdating = false;
          this.dialogRef.close(res);
        },
        () => {
          this.isUpdating = false;
        });
    }
  }

  expirationDateValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const effectiveDate = control.parent?.get('effective_date').value;
    const expirationDate = control.value;

    if (effectiveDate && expirationDate < effectiveDate) {
      return { 'expirationBeforeEffective': true };
    }

    return null;
  }

  dateComparisonValidator(formGroup: FormGroup): { [key: string]: boolean } | null {
    const effectiveDate = formGroup.get('effective_date').value;
    const expirationDate = formGroup.get('expiration_date').value;

    if (effectiveDate && expirationDate && new Date(expirationDate) < new Date(effectiveDate)) {
      formGroup.get('expiration_date').setErrors({ 'expirationBeforeEffective': true });
      return { 'expirationBeforeEffective': true };
    }

    return null;
  }
}
