import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
    selector: 'fyxt-qr-equipment-name',
    templateUrl: './qr-equipment-name.component.html',
    styleUrls: ['./qr-equipment-name.component.scss'],
    standalone: true,
    imports: [MatDialogModule, ButtonComponent, MatIconModule]
})
export class QREquipmentNameComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
