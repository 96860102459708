// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warning_associate {
  background: rgba(4, 118, 190, 0.0509803922);
  border: 1px solid #0476BE;
  border-radius: 4px;
  opacity: 1;
  color: #0476BE;
  padding: 8px;
}
.warning_associate img {
  filter: invert(21%) sepia(74%) saturate(3689%) hue-rotate(188deg) brightness(98%) contrast(97%);
  width: 18px;
  height: 18px;
}

.eqp_list_container {
  max-height: 45vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.eqp_list_container .associated_wrapper {
  background: #FFFFFF;
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.0705882353);
  border: 1px solid #DADFE6;
  border-radius: 4px;
  opacity: 1;
  padding: 12px 12px 7px 12px;
  margin: 12px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_views/_popup_views/checklist-associate-equipment/checklist-associate-equipment.component.scss"],"names":[],"mappings":"AAaA;EACE,2CAAA;EACA,yBAAA;EACA,kBAAA;EACA,UAAA;EACA,cAAA;EACA,YAAA;AAZF;AAaE;EACE,+FAAA;EACA,WAAA;EACA,YAAA;AAXJ;;AAeA;EACE,gBAAA;EACA,kBAAA;EACA,gBAAA;AAZF;AAaE;EACE,mBAAA;EACA,mDAAA;EACA,yBAAA;EACA,kBAAA;EACA,UAAA;EACA,2BAAA;EACA,cAAA;AAXJ","sourcesContent":["// .warning_associate{\n//   background: #f8d8b1;\n//   border: 1px solid #A85D00;\n//   border-radius: 4px;\n//   opacity: 1;\n//   padding:8px;\n//   color: #A85D00;\n//     img{\n//       filter: invert(35%) sepia(87%) saturate(636%) hue-rotate(359deg) brightness(95%) contrast(104%);\n//       width: 18px;\n//       height: 18px;\n//     }\n// }\n.warning_associate{\n  background: #0476BE0D;\n  border: 1px solid #0476BE;\n  border-radius: 4px;\n  opacity: 1;\n  color: #0476BE;\n  padding:8px;\n  img{\n    filter: invert(21%) sepia(74%) saturate(3689%) hue-rotate(188deg) brightness(98%) contrast(97%);\n    width: 18px;\n    height: 18px;\n  }\n}\n\n.eqp_list_container {\n  max-height: 45vh;\n  overflow-x: hidden;\n  overflow-y: auto;\n  .associated_wrapper{\n    background: #FFFFFF;\n    box-shadow: 1px 3px 6px #00000012;\n    border: 1px solid #DADFE6;\n    border-radius: 4px;\n    opacity: 1;    \n    padding: 12px 12px 7px 12px; \n    margin: 12px 0;\n \n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
