import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { ConfigService } from '../../../../../services/config.service';
import { UtilService } from '../../../../../services/v2/util.service';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../button/button.component';
import { NgIf, NgFor } from '@angular/common';


export enum FileStatus {
  notSent,
  uploadProgress,
  uploaded,
  uploadError,
}


export interface UploadFile {
  id?: string;
  fileEntity: File;
  progress: number;
  status: FileStatus;
  uploadSubscription?: Subscription;
}


const oneMbInBytes = 1048576;
const defaultFiles = ['.jpg', '.jpeg', '.png', '.gif', '.pdf', '.mp4', '.mp3', '.docx', '.csv', '.xlsx', '.tsv', '.eml', '.msg', '.mov'];


@Component({
    selector: 'upload-files',
    templateUrl: 'upload-files.component.html',
    styleUrls: ['upload-files.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, ButtonComponent, MatIconModule]
})
export class UploadFilesComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();
  private readonly maximumFileSizes = {
    'image/png': 25 * oneMbInBytes,
    'image/jpeg': 25 * oneMbInBytes,
    'image/gif': 25 * oneMbInBytes,
    'application/pdf': 25 * oneMbInBytes,
    'video/mp4': 25 * oneMbInBytes,
    'application/xls': 25 * oneMbInBytes,
    'text/xls': 25 * oneMbInBytes,
    'application/vnd.ms-excel': 25 * oneMbInBytes,
    'text/csv': 25 * oneMbInBytes,
    'application/vnd.oasis.opendocument.text': 25 * oneMbInBytes,
    'audio/mpeg': 25 * oneMbInBytes,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 25 * oneMbInBytes,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 25 * oneMbInBytes,
  };
  private readonly allowedFileTypes = Object.keys(this.maximumFileSizes);
  public fileStatuses = FileStatus;
  public files: UploadFile[] = [];
  /** @description [showLoader] Displays the loader when loading a file or files if true. False by default. */
  @Input() showLoader: boolean = false;
  /** @description [multiple] Allow multiple selection or drag and drop of files to upload if true. True by default. */
  @Input() multiple: boolean = true;
  /** @description [removeFileOnUploaded] remove a file from the view list if it is uploaded. True by default. */
  @Input() removeFileOnUploaded: boolean = true;
  @Input() formatFiles: string[] = defaultFiles;
  @Input() uploadHandler!: (file: File) => Observable<HttpEvent<{ id: string }>>;
  @Input() deleteHandler!: (fileId: string) => any;

  public formatFilesText = '';


  constructor(
    private readonly http: HttpClient,
    private readonly configService: ConfigService,
    private readonly _utilService: UtilService,
  ) {
  }

  public ngOnInit() {
    this.formatFilesText = this.formatFiles.map(v => v.toUpperCase()).join(', ');
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public fileBrowseHandler(event: Event): void {
    const input = event.target as HTMLInputElement;
    const fileList: FileList | null = input.files;

    if (fileList === null || fileList.length === 0) {
      return;
    }

    this.prepareFilesList(fileList);
    this.uploadFiles(this.files);
    input.value = '';
  }

  private prepareFilesList(fileList: FileList): void {
    const files: UploadFile[] = Array.from(fileList)
      // commented this as file size and type restriction is no longer required
      // .filter(({ type }) => this.allowedFileTypes.includes(type))
      // .filter(({ type, size }) => {
      //   log
      //   if (size > this.maximumFileSizes[type]) {
      //     this._utilService.showError('', `This file is too big`);
      //   }
      //   return size <= this.maximumFileSizes[type];
      // })
      .map((file) => ({
        fileEntity: file,
        progress: 0,
        status: FileStatus.notSent
      }));
      
    this.files = [...this.files, ...files];
  }

  private uploadFiles(files: UploadFile[]): void {
    if (!this.uploadHandler && typeof this.uploadHandler !== 'function') {
      return;
    }

    const onHttpUploadProgressEvent = (event: HttpProgressEvent, file: UploadFile) => {
      const { total, loaded } = event;
      file.progress = Math.round(loaded / (total || 0) * 100);
    }

    const onHttpResponseEvent = (event: HttpResponse<{ id: string }>, file: UploadFile) => {
      file.id = event.body.id;
      file.status = FileStatus.uploaded;
      this.disableLoaderIfNoFilesUploading();

      if (this.removeFileOnUploaded) {
        const indexToDelete = this.files.findIndex((uploadFile) => uploadFile === file);
        this.removeFileFromArray(indexToDelete);
      }
    }

    const uploadFileEventHandler = (event: HttpEvent<{ id: string }>, file: UploadFile) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          onHttpUploadProgressEvent(event, file);
          break;
        case HttpEventType.Response:
          onHttpResponseEvent(event, file);
          break;
      }
    };

    files
      .filter(({ status }) => status === FileStatus.notSent)
      .forEach((file) => {
        if (this.showLoader) { this.configService.isLoader = true; }

        const uploadSubscription = this.uploadHandler(file.fileEntity)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (event) => { uploadFileEventHandler(event, file); },
            (err) => {
              file.status = FileStatus.uploadError;
              this.disableLoaderIfNoFilesUploading();
            }
          );

        file.status = FileStatus.uploadProgress;
        file.uploadSubscription = uploadSubscription;
      });

  }

  private removeFileFromArray(fileIndex: number): void {
    this.files = this.files.filter((_, index) => index !== fileIndex);
  }

  private disableLoaderIfNoFilesUploading(): void {
    const isAnyFileUploading = this.files.some(({ status }) => status === FileStatus.uploadProgress);

    if (isAnyFileUploading) { return; }
    this.configService.isLoader = false;
  }

  public deleteFile(fileIndex: number): void {
    const file = this.files[fileIndex];

    this.removeFileFromArray(fileIndex);

    if (file.status === FileStatus.uploadProgress) {
      file.uploadSubscription?.unsubscribe();
      return;
    }

    if (
      file.status !== FileStatus.uploaded
      || !this.deleteHandler
      || !file.id
    ) {
      return;
    }

    this.deleteHandler(file.id);
  }


  /** Track by Index  **/
  trackByIndex(index: number, item: any): number {
    return index;
  }
}
