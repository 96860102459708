import { Component, HostListener } from '@angular/core';
import { Router, NavigationEnd, RouterModule, RouterLink, RouterOutlet } from '@angular/router';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { JobsService } from './services/v2/jobs.service';
import { environment } from 'src/environments/environment';

import { UiService } from './services/ui/ui.service';
import { BaseService } from 'src/app/services/base.service';
import { UtilityService } from 'src/app/services/utility.service';
import { ConfigService } from 'src/app/services/config.service';
import { TitleService } from 'src/app/services/title/title.service';
import { LayoutService } from './@fyxt/_services/_layout/layout.service';
import { LangflowService } from './services/langflow/langflow.service';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './Modules/shared/Layouts/loader/loader.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [CommonModule, LoaderComponent, RouterModule, RouterModule, RouterOutlet]
})
export class AppComponent {
  title = 'Fyxt';

  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];

  isMobile: boolean = false;
  width: number = window.innerWidth;
  height: number = window.innerHeight;
  mobileWidth: number = 960;

  constructor(
    private router: Router,
    public _uiService: UiService,
    public _baseService: BaseService,
    public _utilService: UtilityService,
    public _Config: ConfigService,
    private _titleService: TitleService,
    public _layoutService: LayoutService,
    public _langflowService: LangflowService,
  ) {

    this._layoutService.isMobileDevice.next(this.width < this.mobileWidth);

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this._uiService.initUIFunctions();

        // if (this._Config.isDelta && !ev.url.includes('doc-manager')) {
        //   this.router.navigate(['pm/doc-manager']);
        // }

      }
    });

  }

  ngOnInit() {
    /*** Dynamic Title Update*/
    this._titleService.setupTitleListener();
    // let hostname = window.location.hostname;
    // if (hostname.includes('krishna') || hostname.includes('uat') || hostname.includes('internaltest')) {
    //   this.loadScript('https://app.getbeamer.com/js/beamer-embed.js');
    // }
    this.loadScript('https://app.getbeamer.com/js/beamer-embed.js');
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;

    const beamerConfig = document.createElement('script');
    beamerConfig.innerHTML = "var beamer_config = { product_id: 'XzKSXPOu68709' };";
    beamerConfig.async = false;
    body.appendChild(beamerConfig);

    const beamerScript = document.createElement('script');
    beamerScript.innerHTML = '';
    beamerScript.src = url;
    beamerScript.async = false;
    beamerScript.defer = true;
    body.appendChild(beamerScript);

  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }


  isLoggedIn() {
    return !!localStorage.getItem('token');
  }


  @HostListener('window:load', ['$event'])
  onLoad(event) {
    this.width = event.target.innerWidth;
    this.height = event.target.innerHeight;
    this._layoutService.isMobileDevice.next(this.width < this.mobileWidth);
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.width = event.target.innerWidth;
    this.height = event.target.innerHeight;
    this._layoutService.isMobileDevice.next(this.width < this.mobileWidth);
  }
}
