import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { FilesService } from 'src/app/Modules/_fyxt_modules/companies/_services/files.service';
import { BaseService } from 'src/app/services/base.service';
import { UtilityService } from 'src/app/services/utility.service';
import { ConfigService } from 'src/app/services/config.service';
import { environment } from 'src/environments/environment';
import { ModalComponent } from '../../../_reusable_ui/_components';
import { FilesSliderComponent } from '../../_tab_components/tab-files/files-slider/files-slider.component';
import { ChipComponent } from '../../../_reusable_ui/_components/chip/chip.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { NgIf, NgFor, NgClass, CurrencyPipe } from '@angular/common';
@Component({
    selector: 'fyxt-compare-job-costs',
    templateUrl: './compare-job-costs.component.html',
    styleUrls: ['./compare-job-costs.component.scss'],
    standalone: true,
    imports: [NgIf, LoaderSmallComponent, NgFor, NgClass, ChipComponent, CurrencyPipe]
})
export class CompareJobCostsComponent implements OnInit {
  viewJobCostDetails: any[] = [];
  documents: any[] = [];
  base: string;
  isLoader: boolean = false;
  selectedJobCostObj: any = {};

  constructor(
    public _dialogService: DialogService,
    private _fileService: FilesService,
    public _utilService: UtilityService,
    public _baseService: BaseService,
    public _Config: ConfigService,
    public dialog: MatDialog,
    public _dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.base = environment.files.http
    this.base = this.base.substring(0, this.base.length - 1);
    this.selectedJobCostObj = this.data.data.selectedJobCostObj;
    this.compareJobCosts();
  }

  ngOnInit(): void {

  }

  /**
   * Get all Cost Details
   */
  compareJobCosts() {
    let requestURL = environment.LocalDev_URL_V2 + `job/costs/${this.selectedJobCostObj?.job_id}/compare/`;
    let postBody = {
      "ids": this.selectedJobCostObj?.ids
    };

    this.isLoader = true;

    this._baseService.doPOST(requestURL, postBody).subscribe({
      next: (response: any) => {
        this.viewJobCostDetails = response || [];
      },
      error: (error) => {
        this.isLoader = false;
        this._utilService.showErrorMessage(error);
      },
      complete: () => {
        this.isLoader = false;
        this.getJobCostFiles();
      }
    })
  }


  /**
   * Get each Cost Files
   */
  getJobCostFiles() {

    let fileURLs = this.viewJobCostDetails.map(obj => { let returnObj = { id: obj.id, url: this._fileService.getFiles('JOB_COST', obj.id, 'filename', 'ASC', '', 1, 25) }; return returnObj });

    this._baseService.doForkJoinbyCustomObj(fileURLs).subscribe({
      next: (response: any) => {
        let filterResponse = Object.entries(response).map((obj: any) => { let objVal = { id: obj[0], files: obj[1].data.files }; return objVal; });
        this.viewJobCostDetails.map(item => {
          item.documents = filterResponse.find(obj => obj.id == item.id).files || [];
          item.status = item.status === 'Proposed' ? 'Pending' : item.status;
        });

      },
      error: (error) => {},
      complete: () => {
      }
    });



  }

  fileSelect(file) {
    let files:any = [];
    this.viewJobCostDetails.map((res:any) => {
      res.documents.map((obj:any) => {
        files.push(obj);
      });
     
    });

    let data = {
      "rows": files
    }
 
    this._fileService.sliderData = data;
    this._fileService.currentFileClicked = file.key;
    this.filesPopUp();
  }

  filesPopUp() {
    this.dialog.open(FilesSliderComponent,
      {
        panelClass: 'custom-dialog-container',
        data: {
          isJobCost: true
        }
      });
    this.dialog.afterAllClosed.subscribe((data) => {
    });
  }
 /** Track by Index  **/
 trackByIndex(index: number, item: any): number {
  return index;
}

}
