import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {map, takeUntil} from "rxjs/operators";
import {Template} from "../../../../../Modules/_fyxt_modules/inbox-management/inbox.types";
import {
  InboxSettingsService
} from "../../../../../Modules/_fyxt_modules/inbox-management/_services/inbox-settings.service";
import {Subject} from "rxjs";
import {InboxCreateSnippetComponent} from "../inbox-create-snippet/inbox-create-snippet.component";
import {DialogService} from "../../../../_services/dialog/dialog.service";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import { ThreeDotsDropdownComponent } from '../../../_reusable_ui/_components/three-dots-dropdown/three-dots-dropdown.component';
import { NotesHolderComponent } from '../../_tab_components/tab-notes/notes-holder/notes-holder.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { RadioComponent } from '../../../_reusable_ui/_controls/radio/radio.component';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { NgIf, NgFor } from '@angular/common';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';

interface RadioButtonItem {
  name: string;
  value: string;
}

@Component({
    selector: 'app-inbox-insert-snippet',
    templateUrl: './inbox-insert-snippet.component.html',
    styleUrls: ['./inbox-insert-snippet.component.scss'],
    standalone: true,
    imports: [SearchInputComponent, NgIf, ButtonComponent, MatIconModule, NgFor, RadioComponent, FormsModule, NgxIntlTelInputModule, NotesHolderComponent, ThreeDotsDropdownComponent, MatDialogModule]
})
export class InboxInsertSnippetComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<null> = new Subject();
  allSnippets: any[];
  threeDotDropdownMenuLists: any[] = [
    {label: 'Edit', value: 'Edit'},
    {label: 'Delete', value: 'Delete'},
  ];
  selectedSnippetValue: any;
  selectedMenuItem: any;
  selectedSnippet: any;
  isFromDashboardAssociatedMailPopup: boolean;
  mailBoxId: string;

  constructor(private _inboxSettings: InboxSettingsService, public _dialogService: DialogService,
              private dialogRef: MatDialogRef<InboxInsertSnippetComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.isFromDashboardAssociatedMailPopup = this.data?.data?.isFromDashboardAssociatedMailPopup;
    this.mailBoxId = this.data?.data?.mailBoxId;
  }

  ngOnInit(): void {
    if (this.isFromDashboardAssociatedMailPopup) {
      this.getAllSnippetsFromAssociatedMailPopup();
    } else {
      this.getAllSnippets();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  getAllSnippets(): void {
    this._inboxSettings.getAllTemplates('SNIPPET').pipe(map((data: any) => data?.data), takeUntil(this.destroy$)).subscribe((data: Template[]) => {
      this.allSnippets = data;
      this.allSnippets.map((snippet: any) => {
        snippet.value = snippet.id;
        snippet.name = snippet.title;
        return snippet;
      })
    })
  }

  getAllSnippetsFromAssociatedMailPopup(): void {
    this._inboxSettings.getAllTemplatesFromDashboardAssociatedPopup('SNIPPET', this.mailBoxId).pipe(map((data: any) => data?.data), takeUntil(this.destroy$)).subscribe((data: Template[]) => {
      this.allSnippets = data;
      this.allSnippets.map((snippet: any) => {
        snippet.value = snippet.id;
        snippet.name = snippet.title;
        return snippet;
      })
    })
  }

  onChangeSnippet(event: any): void {
    this.selectedSnippetValue = event.value;
    this.selectedSnippet = event;
  }

  /**
   * On three dot menu selection
   * @param selectedMenu
   */
  menuOnSelect(selectedMenu: any, snippet: any) {
    this.selectedMenuItem = selectedMenu;
    if (selectedMenu.label == "Edit") {
      this.createNewSnippet('Edit Snippet', 'EDIT', snippet);
    }
    if (selectedMenu.label == "Delete") {
      this.deleteSnippet(snippet.id);
    }
  }

  createNewSnippet(title: string, action: string, snippet?: Template): void {
    let popupData = {
      title: title,
      action: action,
      type: 'SNIPPET',
      component: InboxCreateSnippetComponent,
      data: {
        action: action,
        template: snippet
      },
      containerClass: 'snippet_popup',
    };
    this._dialogService.openModal(popupData).subscribe(result => {
      if (result) {
        this.getAllSnippets();
      }
    });
  }

  deleteSnippet(templateId: string): void {
    this._inboxSettings.deleteTemplate(templateId).pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      this.getAllSnippets();
    })
  }

  insertSnippet(): void {
    if (this.selectedSnippetValue) {
       this.dialogRef.close({selectedSnippet: this.selectedSnippet});
    }
  }
}
