import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { UtilityService } from 'src/app/services/utility.service';
import { ConfigService } from 'src/app/services/config.service';
import { environment } from 'src/environments/environment';

import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MustMatch } from 'src/app/shared/must-match.validator';
import { NgClass, NgIf } from '@angular/common';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';


@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgxIntlTelInputModule, NgClass, NgIf, RouterLink]
})
export class ResetPasswordComponent implements OnInit {

  ResetPasswordForm: UntypedFormGroup;
  submitted = false;
  inputIntiated = false;
  uid: any = "";
  token: any = "";
  
  hide: boolean = true;
  confirmPWDhide: boolean = true;

  constructor(
    public _baseService: BaseService,
    public _utilService: UtilityService,
    public _Config: ConfigService,
    public formbuilder: UntypedFormBuilder,
    public activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(params => {

      this.uid = params.uid;
      this.token = params.token;

    });


    this.initializeForm();

  }


  initializeForm() {

    /**** To Set Form Fields Validations ***/
    this.ResetPasswordForm = this.formbuilder.group({
      Password: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
      CPassword: new UntypedFormControl('', [Validators.required]),
    },
      {
        validator: MustMatch('Password', 'CPassword')
      });

  }

  // convenience getter for easy access to form fields
  get f() { return this.ResetPasswordForm.controls; }

  inputChange(){
    this.inputIntiated = true;
  }

  doFormSubmit() {
    this.submitted = true;

    if (this.ResetPasswordForm.invalid) {
      return
    }

    let postBody = {
      "new_password1": this.ResetPasswordForm.value.Password,
      "new_password2": this.ResetPasswordForm.value.CPassword,
      "uid": this.uid,
      "token": this.token
    };

    this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.confirmResetPassword;

    this._baseService.doPOST(requestURL, postBody).subscribe((result: any) => {

      this._Config.isLoader = false;

      this._utilService.showSuccess('', 'Your Password Changed Successfully.');

      this._baseService.redirectTo('/');

    },
      (error) => {
        this._Config.isLoader = false;
        this._utilService.showErrorMessage(error);
      });

  }

  

  ShowPassword() {
    this.hide = !this.hide;
  }

  ShowConfirmPassword(){
    this.confirmPWDhide = !this.confirmPWDhide;
  }

}
