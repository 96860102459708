import { Component, OnDestroy, OnInit } from '@angular/core';
import { LeasesStoreService } from '../../../../_services/leases/leases-store.service';
import { LeasesGraphqlService } from '../../../../_services/leases/leases-graphql.service';
import { ActivatedRoute } from '@angular/router';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Property, PropertyForm } from '../../../models/porperty-manager/properties';
import { UtilService } from '../../../../../services/v2/util.service';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { DropdownComponent } from '../../../_reusable_ui/_controls/dropdown/dropdown.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { DropdownSelectComponent } from '../../../_reusable_ui/_controls/dropdown-select/dropdown-select/dropdown-select.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { NgIf, AsyncPipe } from '@angular/common';

interface UpdatePropertyForm extends Record<keyof PropertyForm, FormControl> {
}

@Component({
    selector: 'fyxt-lease-property',
    templateUrl: './lease-property.component.html',
    styleUrls: ['./lease-property.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, LoaderSmallComponent, DropdownSelectComponent, NgxIntlTelInputModule, DropdownComponent, MatDialogModule, ButtonComponent, AsyncPipe]
})
export class LeasePropertyComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<null> = new Subject();

  isLoader: boolean = false;
  listProperty$: Observable<Property[]> = this.leasesStoreService.properties$;
  unitsNames: any[] = [];

  public updatePropertyForm!: FormGroup<UpdatePropertyForm>;

  constructor(
    public formBuilder: FormBuilder,
    private readonly leasesStoreService: LeasesStoreService,
    private readonly leasesGraphqlService: LeasesGraphqlService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly utilService: UtilService,
    public dialogRef: MatDialogRef<LeasePropertyComponent>,
  ) {}

  ngOnInit() {
    this.leasesStoreService.currentLease$
      .pipe(takeUntil(this.destroy$))
      .subscribe((lease) => {
        const units = lease.units.map(v => ({ ...v, value: `${v.address} - ${v.name}` }));
        this.onChangeProperty({
          property: lease.property,
          reset: false,
          commencement_date: lease.commencement_date && new Date(lease?.commencement_date),
          expiration_date: lease.expiration_date && new Date(lease?.expiration_date),
          is_month_to_month: lease.is_month_to_month,
          leaseUnits: units,
        });
        this.updatePropertyForm = new FormGroup<UpdatePropertyForm>({
          property: new FormControl(lease.property, Validators.required),
          units: new FormControl(units, Validators.required),
          base_rent: new FormControl(lease.base_rent),
        });
      });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public onChangePropertyOnOther(event) {
    this.leasesStoreService.currentLease$
      .pipe(takeUntil(this.destroy$))
      .subscribe((lease) => {
        const units = lease.units.map(v => ({ ...v, value: `${v.address.address} - ${v.name}` }));
        this.onChangeProperty({
          property: event,
          reset: true,
          commencement_date: lease.commencement_date && new Date(lease?.commencement_date),
          expiration_date: lease.expiration_date && new Date(lease?.expiration_date),
          is_month_to_month: lease.is_month_to_month,
          leaseUnits: units,
        });
      });
  }

  public onChangeProperty(
    payload: { property: any,
      reset: boolean,
      commencement_date?: any,
      expiration_date?: any,
      is_month_to_month?: boolean,
      leaseUnits?: any[] },
  ): void {
    const { reset, commencement_date, expiration_date, is_month_to_month, property, leaseUnits } = payload;
    if (reset) this.updatePropertyForm.controls.units.reset();
    this.unitsNames = [];
    this.leasesStoreService.getUnits(property.id, commencement_date, expiration_date, is_month_to_month)
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        const data = [];
        value.forEach(v => {
          v.units.forEach(unit => {
            let disabled = unit.status === 'Occupied';
            let selected = false;
            if (disabled && (leaseUnits || []).some(u => u.id === unit.id)) {
              disabled = false;
              selected = true;
            }

            data.push({ ...unit, value: `${v.address} - ${unit.name}`, address: v.address, disabled, selected });
          });
        });
        this.unitsNames = data;
      });
  }

  public saveLeaseProperty(): void {
    if (this.updatePropertyForm.valid) {
      this.isLoader = true;
      this.leasesStoreService.currentLease$
        .pipe(
          take(1),
          switchMap((lease) => {
            let base_rent = this.updatePropertyForm.getRawValue().base_rent;
            if (!base_rent) {
              base_rent = null
            }
            return this.leasesStoreService.updateLease({
              id: lease?.id,
              property: this.updatePropertyForm.getRawValue().property.id,
              units: this.updatePropertyForm.getRawValue().units.map(u => u.id),
              base_rent,
            });
          }))
        .subscribe(
          {
            next: () => {
              this.dialogRef.close();
              this.utilService.showSuccess('', 'Lease Properties Update Successfully.');
            },
            error: (error) => {
              this.dialogRef.close();
              this.isLoader = false;
              this.utilService.showError('', 'Lease Properties Update Error');
            },
          },
        );
    }
  }
}
