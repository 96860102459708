import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';

@Component({
    selector: 'fyxt-rename-template',
    templateUrl: './rename-template.component.html',
    styleUrls: ['./rename-template.component.scss'],
    standalone: true,
    imports: [TextboxComponent, FormsModule, NgxIntlTelInputModule, MatDialogModule, ButtonComponent]
})
export class RenameTemplateComponent implements OnInit {
  public template_name: string;

  constructor(
    public dialogRef: MatDialogRef<RenameTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { template_name : string },
) { }

  ngOnInit(): void {
    const { template_name } = this.data;
    this.template_name = template_name;
  }

  renameTemplate(): void {
    this.dialogRef.close({ new_template_name: this.template_name });
  }

}
