import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ICoi, ICreateCoi, IDuplicateCoi } from '../../../models/coi/coi.interface';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { CoiService } from '../../../../../Modules/_fyxt_modules/coi/_services/coi.service';
import { UtilityService } from '../../../../../services/utility.service';
import { Subject } from 'rxjs';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { DropdownDateSelectComponent } from '../../../_reusable_ui/_controls/dropdown-select/dropdown-date-select/dropdown-date-select.component';
import { DropdownComponent } from '../../../_reusable_ui/_controls/dropdown/dropdown.component';
import { NgIf } from '@angular/common';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';

interface IFormDuplicate {
  title: FormControl,
  company: FormControl,
  properties: FormControl,
  effective_date: FormControl,
  expiration_date: FormControl,
}

@Component({
    selector: 'app-duplicate-coi',
    templateUrl: './duplicate-coi.component.html',
    styleUrls: ['./duplicate-coi.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, TextboxComponent, NgxIntlTelInputModule, NgIf, DropdownComponent, DropdownDateSelectComponent, MatDialogModule, ButtonComponent]
})
export class DuplicateCoiComponent implements OnInit {

  private readonly destroy$: Subject<null> = new Subject();


  duplicateCoiForm: FormGroup<IFormDuplicate>
  duplicationCoi!: ICoi;

  formSubmitted: boolean = false;
  isLoaderCreate: boolean = false;



  // Company Dropdown
  allCompaniesList: any[] = [];
  allCompaniesListPageNumber: number = 1;
  allCompaniesListTotal: number;
  allCompaniesListSize: number;
  companiesSearchInput: any = '';
  allCompaniesListPageCount: number;
  allCompaniesLoading: boolean = false;

  //Property Dropdown
  allPropertiesList: any[] = [];
  allPropertiesListPageNumber: number = 1;
  allPropertiesListTotal: number;
  allPropertiesListSize: number;
  propertiesSearchInput: any = '';
  allPropertiesListPageCount: number;
  allPropertiesLoading: boolean = false;

  constructor(
    private readonly coiService: CoiService,
    private readonly utilService: UtilityService,
    public dialogRef: MatDialogRef<DuplicateCoiComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.duplicationCoi = data.data.coi;
  }

  ngOnInit(): void {
    this.getAllCompanyDropdowns();
    this.getAllPropertyDropdowns();

    this.initForm();
  }

  private initForm() {
    const company = {
      id: this.duplicationCoi.company_id,
      name: this.duplicationCoi.company_name,
      type: this.duplicationCoi.company_type,
    }
    this.duplicateCoiForm = new FormGroup<IFormDuplicate>({
      title: new FormControl('', Validators.required),
      company: new FormControl(company, Validators.required),
      properties: new FormControl(this.duplicationCoi.properties || []),
      effective_date: new FormControl('', [this.effectiveDateValidator]),
      expiration_date: new FormControl('', [Validators.required, this.expirationDateValidator]),
    });
  }

  public companyDropdownSearch(event: any) {
    this.companiesSearchInput = event.term;
    this.allCompaniesListPageNumber = 1;
    this.getAllCompanyDropdowns();
  }

  private getAllCompanyDropdowns(pagination?: boolean) {
    this.allCompaniesLoading = true;
    this.coiService
      .getCompanies(this.allCompaniesListPageNumber, this.companiesSearchInput)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (pagination) {
            this.allCompaniesList = this.allCompaniesList.concat(
              response.items,
            );
          } else {
            this.allCompaniesList = response.items;
          }
          this.allCompaniesListTotal = response.total;
          this.allCompaniesListSize = response.size;
          this.allCompaniesListPageCount = Math.round(
            this.allCompaniesListTotal / this.allCompaniesListSize,
          );
          this.allCompaniesLoading = false;
        },
        error: (error) => {
          this.utilService.showErrorMessage(error);
          this.allCompaniesLoading = false;
        },
        complete: () => {
        },
      });
  }

  public companyDropdownsScroll() {
    if (this.allCompaniesListPageNumber < this.allCompaniesListPageCount) {
      this.allCompaniesListPageNumber++;
      this.getAllCompanyDropdowns(true);
    }
  }



  private getAllPropertyDropdowns(pagination?: boolean) {
    this.allPropertiesLoading = true;
    this.coiService
      .getProperties(this.allPropertiesListPageNumber, this.propertiesSearchInput)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (pagination) {
            this.allPropertiesList = this.allPropertiesList.concat(
              response.results,
            );
          } else {
            this.allPropertiesList = response.results;
          }
          this.allPropertiesListTotal = response.count;
          this.allPropertiesListSize = (response.results || []).length;
          this.allPropertiesListPageCount = Math.round(
            this.allPropertiesListTotal / this.allPropertiesListSize,
          );
          this.allPropertiesLoading = false;
        },
        error: (error) => {
          this.utilService.showError('', 'Error Server Properties');
          this.allPropertiesLoading = false;
        },
        complete: () => {
        },
      });
  }

  public propertyDropdownSearch(event: any) {
    this.propertiesSearchInput = event.term;
    this.allPropertiesListPageNumber = 1;
    this.getAllPropertyDropdowns();
  }

  public propertyDropdownsScroll() {
    if (this.allPropertiesListPageNumber < this.allPropertiesListPageCount) {
      this.allPropertiesListPageNumber++;
      this.getAllPropertyDropdowns(true);
    }
  }


  onCancel() {
    if (!this.isLoaderCreate) {
      this.resetForm();
      this.dialogRef.close(false);
    }
  }

  private resetForm() {
    setTimeout(() => {
      this.formSubmitted = false;
      this.duplicateCoiForm.reset();
    }, 100);
  }

  createCoi() {
    this.formSubmitted = true;
    if (this.duplicateCoiForm.valid && !this.isLoaderCreate) {
      const properties = (this.duplicateCoiForm.controls.properties.value || []).map(v => v.id);
      const payload: IDuplicateCoi = {
        coi_id: this.duplicationCoi.id,
        title: this.duplicateCoiForm.controls.title?.value?.trim(),
        company_id: this.duplicateCoiForm.controls.company.value?.id,
        company_type: this.duplicateCoiForm.controls.company.value?.type,
        properties: properties,
        effective_date: this.duplicateCoiForm.controls.effective_date.value,
        expiration_date: this.duplicateCoiForm.controls.expiration_date.value,
      };
      this.isLoaderCreate = true;
      this.coiService.duplicateCOI(payload)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (res) => {
            this.isLoaderCreate = false;
            this.utilService.showSuccess('', 'COI Duplicated Successfully.');
            this.dialogRef.close(res);
          },
          error: (res) => {
            this.isLoaderCreate = false;
            (res?.error?.errors || []).forEach(err => {
              this.utilService.showError('COI Duplicated Error', err.message);
            });
          },
        });
    }
  }

  effectiveDateValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const expirationDate = control.parent?.get('expiration_date').value;
    const effectiveDate = control.value;

    if ((expirationDate && effectiveDate) && effectiveDate > expirationDate) {
      return { must_be_less: true };
    }

    return null;
  }

  expirationDateValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const effectiveDate = control.parent?.get('effective_date').value;
    const expirationDate = control.value;

    if (effectiveDate && expirationDate < effectiveDate) {
      return { must_be_more: true };
    }

    return null;
  }
}
