import {Component, Inject, OnInit} from '@angular/core';
import {DialogService} from "../../../../_services/dialog/dialog.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'fyxt-export-view',
  templateUrl: './export-view.component.html',
  styleUrls: ['./export-view.component.scss']
})
export class ExportViewComponent implements OnInit {
  selectedActiveTab: any;
  mainTabsList = [
    { name: "Schedule", value: "Schedule"},
    { name: "Delivery History", value: "Delivery History"}
  ];
  selectedView: any;
  isEditScheduleViewExport: boolean;

  constructor(public _dialogService: DialogService, public dialogRef: MatDialogRef<ExportViewComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.selectedActiveTab = this.data?.data?.selectedMenu;
    this.selectedView = this.data?.data?.selectedView;
    this.isEditScheduleViewExport = !!this.selectedView.schedule;
    if (this.selectedActiveTab.value == 'Manage Schedule') {
      this.selectedActiveTab = this.mainTabsList[0];
      this.mainTabsList.map((list: any) => {
        if (list.name == 'Schedule') {
          return list.active = true;
        }
      });
    } else if (this.selectedActiveTab.value == 'Delivery History') {
      this.mainTabsList.map((list: any) => {
        if (list.name == 'Delivery History') {
          return list.active = true;
        }
      });
    }
  }

  ngOnInit(): void {
  }

  onSelectTab(event: any): void {
    this.selectedActiveTab = event;
  }

  closeModal(event: any): void {
    this.dialogRef.close(event);
  }

}
