import { Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { FilesService } from 'src/app/Modules/_fyxt_modules/companies/_services/files.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { add, format, isValid } from 'date-fns';
import moment from 'moment/moment';
import { DialogService } from '../../../../_services/dialog/dialog.service';
import { FileReminderActionType, MemberType } from './file-reminder.interface';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DropdownSelectComponent } from '../../../_reusable_ui/_controls/dropdown-select/dropdown-select/dropdown-select.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { TabComponent } from '../../../_reusable_ui/_components/tab/tab.component';
import { RadioComponent } from '../../../_reusable_ui/_controls/radio/radio.component';
import { DropdownDateSelectComponent } from '../../../_reusable_ui/_controls/dropdown-select/dropdown-date-select/dropdown-date-select.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { NgIf, NgClass, NgFor, DatePipe } from '@angular/common';


export interface RadioButtonItem {
  name: string;
  value: string;
  checked: boolean;
}

@Component({
    selector: 'app-file-reminder',
    templateUrl: './file-reminder.component.html',
    styleUrls: ['./file-reminder.component.scss'],
    standalone: true,
    imports: [NgIf, ButtonComponent, MatIconModule, MatDialogModule, FormsModule, ReactiveFormsModule, TextboxComponent, NgxIntlTelInputModule, DropdownDateSelectComponent, NgClass, RadioComponent, TabComponent, LoaderSmallComponent, DropdownSelectComponent, NgFor, MatTooltipModule, DatePipe]
})
export class FileReminderComponent implements OnInit {
  protected readonly MemberType = MemberType;
  protected readonly FileReminderActionType = FileReminderActionType;
  minDate = add(new Date(), {
    days: 1,
  });
  managerList = [];
  engineersList = [];
  contactsList = [];
  propertyList = [];
  selectedManagersList = [];
  selectedEngineersList = [];
  selectedContactsList = [];
  selectedPropertyManagersList = [];
  @Input() chipList: any[] = [];
  @Input() isLoader: boolean[] = [];
  editable: boolean = false;
  @Output() onChangeCategories = new EventEmitter();
  addReminderForm: FormGroup;
  searchDebounce: Subject<any> = new Subject<any>();
  property = {
    'propertyPageNumber': 1,
    'propertyTotalPage': 0,
  };
  manager = {
    'managerPageNumber': 1,
    'managerTotalPage': 0,
  };
  engineer = {
    'engineerPageNumber': 1,
    'engineerTotalPage': 0,
  };
  contact = {
    'conatactPageNumber': 1,
    'conatactTotalPage': 0,
  };

  selectedTab: any = { name: 'Managers', active: true, disable: false };
  tabList = [
    { name: 'Managers', active: true, disable: false },
    { name: 'Engineers', active: false, disable: false },
    { name: 'Other Contacts', active: false, disable: false },
  ];
  radioItems: RadioButtonItem[] = [
    { name: 'System Notification', value: 'NOTIFICATION', checked: false },
    { name: 'Via Email', value: 'EMAIL', checked: false },
    {
      name: 'Via System Notifications and Email',
      value: 'EMAIL_AND_NOTIFICATION',
      checked: false,
    },
  ];
  selectedItem: any;
  tz: string;

  @ViewChild('confirmDeleteReminder')
  confirmDeleteReminder!: TemplateRef<any>;

  constructor(
    private formbuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fileService: FilesService,
    private _dialogService: DialogService,
    private dialogRef: MatDialogRef<FileReminderComponent>,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.getAllManagers(true);
    this.getAllEngineers(true);
    this.getAllContacts();
    this.getAllProperties();
    this.initForm();

    this.searchDebounce.pipe(debounceTime(300)).subscribe(val => this.searchWithDebounce(val));
  }

  initForm() {
    this.editable = [FileReminderActionType.ADD, FileReminderActionType.BULK, FileReminderActionType.EDIT].includes(this.data.type)
    if ([FileReminderActionType.ADD, FileReminderActionType.BULK].includes(this.data.type)) {
      this.addReminderForm = this.formbuilder.group({
        title: ['', Validators.required],
        date: ['', Validators.required],
        type: ['', Validators.required],
        selected_managers: [[]],
        managers_assigned: [[]],
        selected_engineers: [[]],
        engineers_assigned: [[]],
        primary_engineers: [[]],
        selected_contacts: [[]],
        redirectURL: [''],
      });
    }

    if ([FileReminderActionType.INFO, FileReminderActionType.EDIT].includes(this.data.type)) {
      this.addReminderForm = this.formbuilder.group({
        title: [this.data.val.title, Validators.required],
        date: [this.data.val.scheduledAt, Validators.required],
        type: [this.data.val.types, Validators.required],
        managers_assigned: [[...this.data.val.managerPropertyIds]],
        selected_managers: [[...this.data.val.managerIds]],
        engineers_assigned: [[...this.data.val.engineerAssignedPropertyIds]],
        primary_engineers: [[...this.data.val.engineerPrimaryPropertyIds]],
        selected_engineers: [[...this.data.val.engineerIds]],
        selected_contacts: [[...this.data.val.contactIds]],
      });

      this.radioItems.forEach((element, index) => {
        if (element.value == this.data.val.types) {
          this.radioItems[index].checked = true;
        }
      });

      this.reminderType(this.data.val.types);
    }
  }

  /**
   * On Select Tab
   * @param selectedTabValue
   */
  onSelectTab(selectedTabValue: any) {
    this.selectedTab = selectedTabValue;
  }

  onDateSelected(e: any) {
    let date = e;
    this.addReminderForm.patchValue({
      date: date,
    });
  }

  reminderType(e: any) {
    this.addReminderForm.patchValue({
      type: e,
    });

    if (e == 'NOTIFICATION') {
      this.tabList.forEach(element => {
        if (element.name == 'Other Contacts') {
          element.disable = true;
        }
      });
    } else {
      this.tabList.forEach(element => {
        element.disable = false;
      });

    }

  }

  updateInput(e) {
    if (e == 'Via Email') {
      this.addReminderForm.patchValue({
        type: 'EMAIL',
      });
    } else if (e == 'System Notification') {
      this.addReminderForm.patchValue({
        type: 'NOTIFICATION',
      });
    } else if (e == 'Via System Notifications and Email') {
      this.selectedItem = 'EMAIL,NOTIFICATION';
      this.addReminderForm.patchValue({
        type: 'EMAIL,NOTIFICATION',
      });
    }
  }

  changeCategory(event: any): void {
    this.onChangeCategories.emit(event);
  }

  addReminder() {
    if (this.addReminderForm.valid) {
      let date = this.addReminderForm.value.date;
      if (isValid(this.addReminderForm.value.date)) {
        date = format(this.addReminderForm.value.date, 'yyyy-MM-dd');
      }
      this.addReminderForm.patchValue({
        redirectURL: `${window.location.origin}${this.router.url}?file=${this.data.data.id}`,
      });
      this.addReminderForm.patchValue({
        date: date,
      });
      if (this.data.type === FileReminderActionType.ADD) {
        this._fileService
          .addReminder(this.data.data.id, this.addReminderForm.value, this.tz)
          .subscribe((data: any) => {
            this.dialogRef.close({ event: 'TABLE' });
          });
      }
      if (this.data.type === FileReminderActionType.BULK) {
        this._fileService
          .addBulkReminder(this.data.data, this.addReminderForm.value, this.tz)
          .subscribe((data: any) => {
            this.dialogRef.close({ event: 'TABLE' });
          });
      }

    } else {
      this.markFormGroupAsTouched(this.addReminderForm);
    }
  }

  markFormGroupAsTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupAsTouched(control);
      }
    });
  }

  patchForm(keyToPatch, value) {
    this.addReminderForm.patchValue({
      [keyToPatch]: value,
    });
  }

  seletedManagers(e) {
    let selectedManager = [];
    e.forEach((element, index) => {
      if (element?.label != undefined || null) {
        selectedManager.push(element.label);
      } else {
        selectedManager.push(element);
      }
    });
  }

  managerAssigned(e) {
    let managerAssigned = [];
    e.forEach((element, index) => {
      if (element?.label != undefined || null) {
        managerAssigned.push(element.label);
      } else {
        managerAssigned.push(element);
      }
    });
    this.addReminderForm.patchValue({
      managers_assigned: managerAssigned,
    });
  }

  assignedEngineers(e) {
    let assignedEngineers = [];
    e.forEach((element, index) => {
      if (element?.label != undefined || null) {
        assignedEngineers.push(element.label);
      } else {
        assignedEngineers.push(element);
      }
    });
    this.addReminderForm.patchValue({
      engineers_assigned: assignedEngineers,
    });
  }

  primaryEnginners(e) {
    let primaryEnginners = [];
    e.forEach((element, index) => {
      if (element?.label != undefined || null) {
        primaryEnginners.push(element.label);
      } else {
        primaryEnginners.push(element);
      }
    });

    this.addReminderForm.patchValue({
      primary_engineers: primaryEnginners,
    });
  }

  selectedEngineers(e) {
    let selectedEngineers = [];
    e.forEach((element, index) => {
      if (element?.label != undefined || null) {
        selectedEngineers.push(element.label);
      } else {
        selectedEngineers.push(element);
      }
    });

    this.addReminderForm.patchValue({
      selected_engineers: selectedEngineers,
    });
  }

  selectedContact(e) {
    let selectedContact = [];
    e.forEach((element, index) => {
      if (element?.label != undefined || null) {
        selectedContact.push(element.label);
      } else {
        selectedContact.push(element);
      }
    });

    this.addReminderForm.patchValue({
      selected_contacts: selectedContact,
    });
  }

  updateReminder() {
    if (this.addReminderForm.valid) {
      this._fileService
        .updateReminder(this.data?.val?.id, this.addReminderForm.value, this.tz)
        .subscribe((data: any) => {
          this.editable = false;
        });
    }
  }

  getAllManagers(isInit?: boolean) {
    if ([FileReminderActionType.EDIT, FileReminderActionType.INFO].includes(this.data.type) && isInit) {
      this.isLoader.push(true);
      this._fileService.getManagersInfo([...this.data.val.managerIds]).subscribe(
        {
          next: (data: any) => {
            this.data.val.managerIds = data.data.map((element: any) => ({
              key: element.id,
              name: element.user.firstName + ' ' + element.user.lastName,
              selected: true,
            }));
            this.isLoader.shift();
          },
          error: (e: any) => {
            this.isLoader.shift();
          },
        });

      this.isLoader.push(true);
      this._fileService.getPropertiesInfo([...this.data.val.managerPropertyIds]).subscribe(
        {
          next: (data: any) => {
            this.data.val.managerPropertyIds = data.data.map((element: any) => ({
              key: element.id,
              name: element.name,
              selected: true,
            }));
            this.isLoader.shift();
          },
          error: (e: any) => {
            this.isLoader.shift();
          },
        });
    }

    this._fileService.getAllManagers(this.manager.managerPageNumber).subscribe((data: any) => {
      this.managerList = data?.data?.data?.items;
      this.manager.managerTotalPage = data?.data?.data?.pages;
      let data_list = [];
      this.managerList.forEach((element) => {
        let obj = {};
        obj['key'] = element.id;
        obj['name'] = element.user?.first_name + ' ' + element.user?.last_name;
        data_list.push(obj);
      });
      this.managerList = data_list;
    });
  }

  getAllProperties() {
    this._fileService.getAllProperty(this.property.propertyPageNumber).subscribe((data: any) => {
      this.propertyList = data.data.data.items;
      this.property.propertyTotalPage = data?.data?.data?.pages;
    });
  }

  getAllEngineers(isInit?: boolean) {
    if ([FileReminderActionType.EDIT, FileReminderActionType.INFO].includes(this.data.type) && isInit) {
      this.isLoader.push(true);
      this._fileService.getPropertiesInfo([...this.data.val.engineerAssignedPropertyIds]).subscribe(
        {
          next: (data: any) => {
            this.data.val.engineerAssignedPropertyIds = data.data.map((element: any) => ({
              key: element.id,
              name: element.name,
              selected: true,
            }));
            this.isLoader.shift();
          },
          error: (e: any) => {
            this.isLoader.shift();
          },
        });

      this.isLoader.push(true);
      this._fileService.getEngineersInfo([...this.data.val.engineerIds]).subscribe(
        {
          next: (data: any) => {
            this.data.val.engineerIds = data.data.map((element: any) => ({
              key: element.id,
              name: element.user.firstName + ' ' + element.user.lastName,
              selected: true,
            }));
            this.isLoader.shift();
          },
          error: (e: any) => {
            this.isLoader.shift();
          },
        });

      this.isLoader.push(true);
      this._fileService.getPropertiesInfo([...this.data.val.engineerPrimaryPropertyIds]).subscribe(
        {
          next: (data: any) => {
            this.data.val.engineerPrimaryPropertyIds = data.data.map((element: any) => ({
              key: element.id,
              name: element.name,
              selected: true,
            }));
            this.isLoader.shift();
          },
          error: (e: any) => {
            this.isLoader.shift();
          },
        });
    }

    this._fileService.getAllEngineers(this.engineer.engineerPageNumber).subscribe((data: any) => {
      let data_list = [];
      this.engineer.engineerTotalPage = data?.data?.data?.pages;
      if (data?.data?.data?.items) {
        data?.data?.data?.items.forEach(element => {
          let obj = {};
          obj['key'] = element.id;
          obj['name'] = element.user?.first_name + ' ' + element.user?.last_name;
          data_list.push(obj);
        });
        this.engineersList = data_list;
      }
    });
  }

  getAllContacts(searchInput?) {
    let getActiveColumn = [
      'first_name',
      'last_name',
      'type',
      'phone',
      'primary_company',
      'last_activity_at',
      'email',
    ];
    let selectViewTableRows =
      environment.fastAPI_Actual_URL +
      '/contacts/?columns=' +
      JSON.stringify(getActiveColumn) + '&page=1&size=25';
    if (searchInput) {
      selectViewTableRows += '&search=' + searchInput;
    }

    this._fileService.getSelectedTableData1(selectViewTableRows).subscribe(
      (response: any) => {
        const data_list = response.items.map((element: any) => ({
          key: element.id,
          name: element.first_name + '  ' + element.last_name,
          selected: false,
        }));
        const selectedData = this.contactsList.map(cl => {
          const selected = this.addReminderForm.getRawValue().selected_contacts.some((v) => v === cl.key);
          return {
            ...cl,
            selected,
          };
        });

        this.contactsList = [...selectedData, ...data_list];

        if (!this.selectedContactsList.length) {
          this.data.val.contactIds.forEach((selectedContact) => {
            const selectedContactItem = this.contactsList.find((contact) => contact.key === selectedContact);
            if (selectedContactItem) {
              this.selectedContactsList.push(selectedContactItem);
            }
          });
        }
      },
      (error) => {},
    );
  }

  searchManagersDropdown(searchTerm?: any): void {
    let obj = {
      type: 'MAN',
      term: searchTerm.term,
    };
    //this.searchDebounce.next(obj);
    this.searchWithDebounce(obj);
  }

  searchPropertyDropdown(searchTerm?: any): void {
    let obj = {
      type: 'PROP',
      term: searchTerm.term,
    };
    //this.searchDebounce.next(obj);
    this.searchWithDebounce(obj);

  }

  onScrollProperty() {
    if (this.property.propertyPageNumber < this.property.propertyTotalPage) {
      this.property.propertyPageNumber = this.property.propertyPageNumber + 1;
      this._fileService.getAllProperty(this.property.propertyPageNumber).subscribe((data: any) => {
        this.propertyList = [...this.propertyList, ...data?.data?.data?.items];
      });
    }
  }


  onScrollManager() {
    if (this.manager.managerPageNumber < this.manager.managerTotalPage) {
      this.manager.managerPageNumber = this.manager.managerPageNumber + 1;
      this._fileService.getAllManagers(this.manager.managerPageNumber).subscribe((data: any) => {
        let data_list = [];
        data.data.data.items.forEach((element) => {
          let obj = {};

          obj['key'] = element.id;
          obj['name'] = element.user?.first_name + ' ' + element.user?.last_name;

          data_list.push(obj);
        });
        this.managerList = [...this.managerList, ...data_list];
      });
    }
  }


  onScrollEngineer() {
    if (this.engineer.engineerPageNumber < this.engineer.engineerTotalPage) {
      this.engineer.engineerPageNumber = this.engineer.engineerPageNumber + 1;
      this._fileService.getAllEngineers(this.engineer.engineerPageNumber).subscribe((data: any) => {
        let data_list = [];
        if (data?.data?.data?.items) {
          data?.data?.data?.items.forEach(element => {
            let obj = {};
            obj['key'] = element.id;
            obj['name'] = element.user?.first_name + ' ' + element.user?.last_name;
            data_list.push(obj);
          });
          this.engineersList = [...this.engineersList, ...data_list];
        }
      });
    }
  }


  contactNextPage() {
    this.contact.conatactPageNumber = this.contact.conatactPageNumber + 1;
    let getActiveColumn = [
      'first_name',
      'last_name',
      'type',
      'phone',
      'primary_company',
      'last_activity_at',
      'email',
    ];
    let selectViewTableRows =
      environment.fastAPI_Actual_URL +
      '/contacts/?columns=' +
      JSON.stringify(getActiveColumn) + `&page=${this.contact.conatactPageNumber}&size=25`;
    this._fileService.getSelectedTableData1(selectViewTableRows).subscribe(
      (response: any) => {
        const data_list = response.items.map((element: any) => ({
          key: element.id,
          name: element.first_name + ' ' + element.last_name,
        }));
        const selectedData = this.contactsList.map(cl => {
          const selected = this.addReminderForm.getRawValue().selected_contacts.some((v) => v === cl.key);
          return {
            ...cl,
            selected,
          };
        });
        this.contactsList = [...selectedData, ...data_list];
      },
      (error) => {},
    );
  }

  searchEngineerDropdown(searchTerm?: any): void {
    let obj = {
      type: 'ENG',
      term: searchTerm.term,
    };
    this.searchWithDebounce(obj);
  }

  searchWithDebounce(val) {
    if (val.type == 'MAN') {
      this._fileService.searchManagers(val.term).subscribe((data: any) => {
        let data_list = [];
        if (data?.data?.data?.items) {
          data?.data?.data?.items.forEach(element => {
            let obj = {};
            obj['key'] = element.id;
            obj['name'] = element?.user?.first_name + ' ' + element?.user?.last_name;
            data_list.push(obj);
          });
          this.managerList = data_list;
        }
      });
    }
    if (val.type == 'PROP') {
      this._fileService.searchProperty(val.term).subscribe((data: any) => {
        let data_list = [];
        if (data?.data?.data?.items) {
          data?.data?.data?.items.forEach(element => {
            data_list.push(element);
          });
          this.propertyList = data_list;
        }
      });
    }
    if (val.type == 'ENG') {
      this._fileService.searchEngineers(val.term).subscribe((data: any) => {
        let data_list = [];

        if (data?.data?.data?.items) {
          data?.data?.data?.items.forEach(element => {
            let obj = {};
            obj['key'] = element.id;
            obj['name'] = element?.user?.first_name + ' ' + element?.user?.last_name;
            data_list.push(obj);
          });
          this.engineersList = data_list;
        }

      });
    }
  }

  searchEngineersDropdown(searchTerm?: any, isPagination?: boolean): void {
  }

  searchContactsDropdown(searchTerm?: any, isPagination?: boolean): void {
    this.getAllContacts(searchTerm.term);
  }

  onManagerChange(e, val) {
    let selectedContact = [];

    if (val == 'managers_assigned') {
      if (e.length == 0) {
        this.property.propertyPageNumber = 1;
        this.property.propertyTotalPage = 0;
        this.getAllProperties();
      }
      e.forEach(element => {
        selectedContact.push(element.id);
      });

      this.data.val.managerPropertyIds = [...e]

      this.addReminderForm.patchValue({
        managers_assigned: selectedContact,
      });
    } else if (val == 'selected_managers') {
      if (e.length == 0) {
        this.manager.managerPageNumber = 1;
        this.manager.managerTotalPage = 0;
        this.getAllManagers();
      }
      e.forEach((element, index) => {
        if (element?.label != undefined || null) {
          selectedContact.push(element.key);
        } else {
          selectedContact.push(element.key);
        }
      });

      this.data.val.managerIds = [...e];
      this.addReminderForm.patchValue({
        selected_managers: selectedContact,
      });
    }


    if (val == 'engineers_assigned') {
      if (e.length == 0) {
        this.property.propertyPageNumber = 1;
        this.property.propertyTotalPage = 0;
        this.getAllProperties();
      }
      e.forEach(element => {
        selectedContact.push(element.id);
      });

      this.data.val.engineerAssignedPropertyIds = [...e]

      this.addReminderForm.patchValue({
        engineers_assigned: selectedContact,
      });
    }

    if (val == 'primary_engineers') {
      if (e.length == 0) {
        this.property.propertyPageNumber = 1;
        this.property.propertyTotalPage = 0;
        this.getAllProperties();
      }
      e.forEach(element => {
        selectedContact.push(element.id);
      });

      this.data.val.engineerPrimaryPropertyIds = [...e]

      this.addReminderForm.patchValue({
        primary_engineers: selectedContact,
      });
    }

    if (val == 'selected_engineers') {
      if (e.length == 0) {
        this.engineer.engineerPageNumber = 1;
        this.engineer.engineerTotalPage = 0;
        this.getAllEngineers();
      }
      e.forEach(element => {
        selectedContact.push(element.key);
      });

      this.data.val.engineerIds = [...e]

      this.addReminderForm.patchValue({
        selected_engineers: selectedContact,
      });
    }
  }

  onEngineerChange(e) {
    let selectedEngineer = [];

    e.forEach((element, index) => {
      if (element?.label != undefined || null) {
        selectedEngineer.push(element.key);
      } else {
        selectedEngineer.push(element.key);
      }
    });

    this.addReminderForm.patchValue({
      selected_engineers: selectedEngineer,
    });
  }

  onContactChange(e) {
    let selectedContact = [];

    if (e.length == 0) {
      this.getAllContacts();
    }

    e.forEach((element, index) => {
      if (element?.label != undefined || null) {
        selectedContact.push(element.key);
      } else {
        selectedContact.push(element.key);
      }
    });

    this.selectedContactsList = [...e]

    this.addReminderForm.patchValue({
      selected_contacts: selectedContact,
    });
  }

  closePopup() {
    if (this.data.type === FileReminderActionType.INFO) {
      this.editable = false
      return
    }
    this.dialogRef.close();
  }

  onCloseDropdown(type) {
    if (type == 'PROPERTY') {
      this.property.propertyPageNumber = 1;
      this.property.propertyTotalPage = 0;
      this.getAllProperties();
    }
    if (type == 'MANAGER') {
      this.manager.managerPageNumber = 1;
      this.manager.managerTotalPage = 0;
      this.getAllManagers();
    }
    if (type == 'ENGG') {
      this.engineer.engineerPageNumber = 1;
      this.engineer.engineerTotalPage = 0;
      this.getAllEngineers();
    }
    if (type == 'CONTACT') {
      this.getAllContacts();
    }
  }

  formatType(type) {
    const formattedType = type.replace(/_AND_/g, ' And ')?.replace(/_/g, ' ')?.toLowerCase();

    return formattedType.charAt(0).toUpperCase() + formattedType.slice(1);
  }

  openDeletePopup() {
    let popupData = {
      title: 'Delete Reminder?',
      template: this.confirmDeleteReminder,
      confirmText: 'Delete',
      cancelText: 'Cancel',
      buttons: true,
    };

    this._dialogService.confirmDialog(popupData).subscribe((result: any) => {
      if (result) {
        this.deleteReminder();
      }
    });

  }

  deleteReminder() {
    this._fileService.deleteReminder(this.data.val.id).subscribe((data) => {
      this.dialogRef.close()
    });
  }

  removeItem(removedId: string, type: string) {
    if (type === MemberType.CONTACTS) {
      const selectedContact = []
      this.selectedContactsList = this.selectedContactsList.filter((contact) => (contact.key || contact.id) !== removedId);
      this.selectedContactsList.forEach((element) => {
        selectedContact.push(element.key);
      });
      this.addReminderForm.patchValue({
        selected_contacts: selectedContact,
      });

      return
    }

    let formKey, valuesKey

    switch (type) {
      case MemberType.MANAGER_PROPERTY:
        formKey = 'managers_assigned'
        valuesKey = 'managerPropertyIds'
        break
      case MemberType.MANAGER:
        formKey = 'selected_managers'
        valuesKey = 'managerIds'
        break
      case MemberType.ENGINEER_ASSIGNED_PROPERTY:
        formKey = 'engineers_assigned'
        valuesKey = 'engineerAssignedPropertyIds'
        break
      case MemberType.ENGINEER_PRIMARY_PROPERTY:
        formKey = 'primary_engineers'
        valuesKey = 'engineerPrimaryPropertyIds'
        break
      case MemberType.ENGINEER:
        formKey = 'selected_engineers'
        valuesKey = 'engineerIds'
        break
      case MemberType.CONTACTS:
        formKey = 'selected_engineers'
        valuesKey = 'engineerIds'
        break
    }

    let selectedValues = []

    selectedValues = (this.addReminderForm.controls[formKey].value || []).filter((id) => removedId !== id)
    this.data.val[valuesKey] = (this.data.val[valuesKey] || []).filter((item) => removedId !== (item.id || item.key))

    this.addReminderForm.patchValue({
      [formKey]: selectedValues,
    });
  }

   /** Track by Index  **/
   trackByIndex(index: number, item: any): number {
    return index;
  }
}
