import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { T_INSPECTION } from '../../../../../Modules/_fyxt_modules/leases/pages/lease-inspection/lease-inspection.interface';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';

@Component({
    selector: 'past-inspection-notification',
    templateUrl: './past-inspection-notification.component.html',
    styleUrls: ['./past-inspection-notification.component.scss'],
    standalone: true,
    imports: [MatDialogModule, ButtonComponent]
})
export class PastInspectionNotificationComponent {

  constructor(
    public dialogRef: MatDialogRef<PastInspectionNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { inspection_type: T_INSPECTION },
  ) { }


  public confirm() {
    this.dialogRef.close({ confirmed: true });
  }
}
