import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Options, NgxSliderModule } from '@angular-slider/ngx-slider';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GoogleAddressTextboxComponent } from '../google-address-textbox/google-address-textbox.component';
import { MatDividerModule } from '@angular/material/divider';
import { NgIf, NgClass } from '@angular/common';


@Component({
    selector: 'fyxt-geo-address-filter',
    templateUrl: './geo-address.component.html',
    styleUrls: ['./geo-address.component.scss'],
    standalone: true,
    imports: [NgIf, MatDividerModule, NgClass, GoogleAddressTextboxComponent, NgxSliderModule]
})
export class GeoAddressComponent implements OnInit, OnDestroy, OnChanges {
  @Input() min: number = 0;
  @Input() max: number = 10;
  @Input() step: number = 1;
  @Input() input?: any;

  @Input() clearEvent: Subject<any>;
  @Input() showDivider: boolean = true;

  @Output() onAddressChange = new EventEmitter();
  @Output() onClearChange = new EventEmitter();

  private readonly destroy$: Subject<null> = new Subject();

  options: Options;
  filterLocationInput: string = '';
  radius: number = 0;

  address: any;

  constructor() {}

  ngOnInit(): void {
    this.initValue();
    this.clearDetection();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.input?.currentValue) {
      this.initGeoAddress();
    }
  }

  private initValue(): void {
    this.initGeoAddress();
    this.options = {
      floor: 0,
      ceil: this.max,
      step: this.step,
      showTicks: true,
      hideLimitLabels: true,
      hidePointerLabels: true,
      animate: false,
      animateOnMove: false,

      showSelectionBar: true,
    }
  }

  private initGeoAddress() {
    if (this.input) {
      this.radius = this.input?.radius || 0;
      this.filterLocationInput = this.input?.address || '';
      this.address = this.input;
    } else {
      this.radius = 0;
      this.filterLocationInput ='';
      this.address = null;
    }

  }

  private clearDetection(): void {
    if (this.clearEvent) {
      this.clearEvent
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.radius = 0;
          this.address = null;
          this.filterLocationInput = '';
        })
    }
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  onChange(event: any): void {
    if (this.address) {
      this.onAddressChange.emit({
        ...this.address,
        name: this.radius ? `${this.address.address} - ${this.radius} mi` : this.address.address,
        radius: this.radius,
      });
    }
  }

  addLocationToFilter(event: any) {
    if (!event) {
      this.filterLocationInput = '';
      this.address = null;
      this.onClearChange.emit();
      return
    }

    const locationPayload = {
      address: event.formatAddress,
      name: this.radius ? `${event.formatAddress} - ${this.radius} mi` : event.formatAddress,
      city: event.location?.cityName ? event.location.cityName : null,
      state: event.location?.stateCode ? event.location.stateCode : null,
      country: event.location?.countryName ? event.location.countryName : null,
      zipcode: event.location?.postal_code ? event.location.postal_code : null,
      latitude: event.latitude ? event.latitude : null,
      longitude: event.longitude ? event.longitude : null,
      is_geo_address: true,
      radius: this.radius
    };
    this.filterLocationInput = event?.formatAddress;
    this.address = locationPayload;

    this.onAddressChange.emit({ ...locationPayload});
  }

}
