import { Directive, OnDestroy, ViewChild } from "@angular/core";
import { NgSignaturePadOptions, SignaturePadComponent } from "@almothafar/angular-signature-pad";
import { Tab } from "../../../models/tab";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { DocumentInfo } from "../../../models/porperty-manager/documents";
import { filter, map } from "rxjs/operators";
import { HttpEventType, HttpResponse } from "@angular/common/http";
import { FilesService } from "../../../../../Modules/_fyxt_modules/companies/_services/files.service";

enum Tabs {
  Draw = 'Draw',
  Type = 'Type',
}

@Directive({ selector: 'fyxt-signature-collection-model' })
export class SignatureCollectionModel implements OnDestroy {
  signed_at$: BehaviorSubject<Date | null> = new BehaviorSubject<Date | null>(null)
  isUserAgreed: boolean = false;
  handwrittenSignature: string = '';
  readonly tabsEnum = Tabs;
  @ViewChild('signature')
  readonly signaturePad: SignaturePadComponent;
  readonly signaturePadOptions: NgSignaturePadOptions = {
    minWidth: 0.9,
    maxWidth: 2.3,
    dotSize: 2.3,
    canvasWidth: 553,
    canvasHeight: 168,
    backgroundColor: '#FFFFFF',
  };
  readonly tabList: Tab<Tabs>[] = [
    {name: Tabs.Draw, active: true, disable: false},
    {name: Tabs.Type, active: false, disable: false},
  ];
  selectedTab = this.tabList.find(({active}) => active);
  protected readonly destroy$ = new Subject();

  constructor(protected filesService: FilesService) {
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
    this.signed_at$.complete();
  }

  onSelectTab(selectedTab: Tab<Tabs>): void {
    this.selectedTab = selectedTab;

    this.clearSignature();
  }

  clearSignature(): void {
    this.signaturePad?.clear();
    this.handwrittenSignature = '';
    this.signed_at$.next(null);
  }

  hasSignature(): boolean {
    const hasDrawnSignature = !!this.signaturePad && !this.signaturePad.isEmpty();
    const hasTextSignature = !!this.handwrittenSignature.trim();
    const hasSignature = hasTextSignature || hasDrawnSignature;

    this.signed_at$.next(hasSignature ? new Date() : null);

    return hasSignature;
  }

  protected uploadSignatureFile(file: Blob, contactId?: string, isPublic?: boolean): Observable<DocumentInfo> {
    const entityId = null;
    const entityType = 'CHECKLIST_SIGNATURE';
    const fileName = `checklist_signature_${new Date().getTime()}`;

   if (isPublic) return this.filesService.publicFileUpload(file, entityId, entityType, fileName, contactId, '') as Observable<any>

    return this.filesService.uploadFile(file, entityId, entityType, fileName)
      .pipe(
        filter((httpEvent): httpEvent is HttpResponse<{ data: DocumentInfo }> =>
          httpEvent.type === HttpEventType.Response
        ),
        map(({body}) => body.data),
      );
  }

  protected getDrawnSignature(): Blob | null {
    if (!this.signaturePad) {
      return null;
    }

    const imageType = 'image/png';
    const base64Image = this.signaturePad.toDataURL(imageType);
    const parts = base64Image.split(';base64,');
    const decodedData = window.atob(parts[1]);
    const uInt8Array = new Uint8Array(decodedData.length);

    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: imageType });
  }
}
