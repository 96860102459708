import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
    selector: 'app-inbox-new-conversation',
    templateUrl: './inbox-new-conversation.component.html',
    styleUrls: ['./inbox-new-conversation.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatDialogModule, ButtonComponent, MatIconModule]
})
export class InboxNewConversationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
