import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag, CdkDragPlaceholder } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChecklistService } from 'src/app/Modules/_fyxt_modules/checklist/servcie/checklist.service';
import {Subscription} from "rxjs";
import { ThreeDotsDropdownComponent } from '../../../_reusable_ui/_components/three-dots-dropdown/three-dots-dropdown.component';
import { DropdownSelectComponent } from '../../../_reusable_ui/_controls/dropdown-select/dropdown-select/dropdown-select.component';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { CheckboxComponent } from '../../../_reusable_ui/_controls/checkbox/checkbox.component';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'fyxt-tab-checklist-items',
    templateUrl: './tab-checklist-items.component.html',
    styleUrls: ['./tab-checklist-items.component.scss'],
    standalone: true,
    imports: [NgClass, FormsModule, ReactiveFormsModule, CdkDropList, NgFor, NgIf, CdkDrag, CdkDragPlaceholder, MatIconModule, CheckboxComponent, NgxIntlTelInputModule, TextboxComponent, DropdownSelectComponent, NgSwitch, NgSwitchCase, NgTemplateOutlet, ThreeDotsDropdownComponent]
})
export class TabChecklistItemsComponent implements OnInit {


  panelOpenState = false;
  @Input() selectedActiveTab: any;
  checklistForm!: FormGroup;
  reminderType: String[] = ['Inspector', 'Manager', 'Client'];
  options: string[] = ['Checkbox', 'Choose One', 'Choose Many', 'Completion Status', 'Pass/Fail/Flag', 'Text Response', 'Numeric Response'];
  flags: any[] = [{ value: 'Pass', order: 1, flag: false }, { value: 'Fail', order: 2, flag: false }, { value: 'Flag', order: 3, flag: false }];
  Status: any[] = [{ value: 'Open', order: 1, flag: false }, { value: 'In Progress', order: 2, flag: false }, { value: 'On Hold', order: 3, flag: false }, { value: 'Completed', order: 4, flag: false }, { value: 'Not Completed', order: 5, flag: false }];
  @Output() menuLists: any[] = [{ label: 'Ungroup', value: 'Ungroup' }]
  @Output() itemsCount: any = new EventEmitter();

  @Input() checklistDetails: any = {};
  @Input() asPerNewDesign = false;
  isLoader: boolean = false;
  checklistDetailsSubscription: Subscription;

  constructor(public _checklist: ChecklistService) { }

  ngOnInit(): void {
    this.itemFormat().clear()
  }

  ngOnChanges() {
    this.initializeForm()
  }

  ngOnDestroy() {
    this.checklistDetailsSubscription.unsubscribe();
  }

  initializeForm() {
    this.checklistForm = new FormGroup({
      name: new FormControl<string>('', [Validators.required]),
      description: new FormControl(''),
      checklist_type: new FormControl('Active'),
      signature: new FormControl(null),
      items: new FormArray([])
    });

    setTimeout(() => {
      this.patchValues();
    }, 1000);
  }

  patchValues() {
    if (this.checklistDetails?.id) {
      let res = this.checklistDetails?[this.checklistDetails] : [];

      this.itemsCount.emit(res[0].items.length)
      res[0].items?.map(e => {
        delete e.group_name
        let order = e.group_order
        delete e.group_order
        e.item?.map(s => {
          return { ...s, group_order: order };
        })
      })

      res[0].items = res[0].items?.map(e => e.item).flat();

      this.checklistForm.patchValue({
        name: res[0].name,
        description: res[0].description,
        checklist_type: res[0].checklist_type
      });

      res[0].items?.map((e: any) => {
        this.addPatchItem(e)
      });
    }
    else {

      if (this.selectedActiveTab.id !== undefined) {
        this.itemFormat().clear();
        if (this.checklistDetailsSubscription) {
          this.checklistDetailsSubscription.unsubscribe();
        }
        this.isLoader = true;
        this.checklistDetailsSubscription = this._checklist.getCheckList(this.selectedActiveTab.id).subscribe({
          next: (res: any) => {
            this.itemsCount.emit(res[0].items.length)
            res[0].items?.map(e => {
              delete e.group_name
              let order = e.group_order
              delete e.group_order
              e.item.map(s => {
                return { ...s, group_order: order };
              })
            })

            res[0].items = res[0].items.map(e => e.item).flat();

            this.checklistForm.patchValue({
              name: res[0].name,
              description: res[0].description,
              checklist_type: res[0].checklist_type
            });

            res[0].items.map((e: any) => {
              this.addPatchItem(e)
            });
          },
          error: () =>{
            this.isLoader = false;
          },
          complete: () => {
            this.isLoader = false;
          }
        })
      }
    }



  }

  addPatchItem(item: any) {
    if (item.group_name === null) {
      (this.checklistForm.get('items') as FormArray).push(this.addItemformats());
      const newItemIndex = this.itemFormat().length - 1;
      this.itemFormat().controls[newItemIndex].patchValue(item);
      const responses = item.responses || [];

      responses.forEach((response: any) => {
        const newFormItem = this.addOptionformats();
        this.Status = response;
        newFormItem.patchValue(response);
        const responsesArray = this.itemFormat().controls[newItemIndex].get('responses') as FormArray;
        responsesArray.push(newFormItem);
      });
    } else {
      let found = false;
      for (let i = 0; i < (this.checklistForm.get('items') as FormArray).length; i++) {
        const currentControl = (this.checklistForm.get('items') as FormArray).controls[i];
        if (currentControl.get('group_name')?.value === item.group_name) {
          found = true;
          const responsesArray = currentControl.get('responses') as FormArray;
          responsesArray.push(this.addItemformats());
          const newItemIndex = responsesArray.length - 1;
          responsesArray.controls[newItemIndex].patchValue(item);
          const responses = item.responses || [];

          responses.forEach((response: any) => {
            const newFormItem = this.addOptionformats();
            newFormItem.patchValue(response);
            (responsesArray.controls[newItemIndex].get('responses') as FormArray).push(newFormItem);
          });
          break;
        }
      }
      if (!found) {
        this.createGroup();
        const lastControlIndex = (this.checklistForm.get('items') as FormArray).length - 1;
        const lastControl = (this.checklistForm.get('items') as FormArray).controls[lastControlIndex];
        lastControl.get('group_name')?.setValue(item.group_name);
        lastControl.get('group_order')?.setValue(item.order);
        const responsesArray = lastControl.get('responses') as FormArray;
        responsesArray.push(this.addItemformats());
        const newItemIndex = responsesArray.length - 1;
        responsesArray.controls[newItemIndex].patchValue(item);
        const responses = item.responses || [];

        responses.forEach((response: any) => {
          const newFormItem = this.addOptionformats();
          newFormItem.patchValue(response);
          (responsesArray.controls[newItemIndex].get('responses') as FormArray).push(newFormItem);
        });
      }

    }
  }


  addItem() {
    (<FormArray>this.checklistForm.get('items'))?.push(this.addItemformats());
  }

  addItemformats() {
    return new FormGroup({
      group_name: new FormControl(null),
      group_order: new FormControl(null),
      order: new FormControl(null),
      groupCheckbox: new FormControl(null),
      responses: new FormArray([]),
      title: new FormControl('', [Validators.required]),
      itemCheckbox: new FormControl(false),
      response_type: new FormControl(this.options[0]),
      photos: new FormControl(false),
      comments: new FormControl(false),
    })
  }


  itemFormat() {
    return this.checklistForm.get('items') as FormArray;
  }


  createGroup() {
    (<FormArray>this.checklistForm.get('items'))?.push(this.addGroup())
  }

  addGroup() {
    return new FormGroup({
      group_name: new FormControl(''),
      group_order: new FormControl(),
      groupCheckbox: new FormControl(false),
      responses: new FormArray([]),
      itemCheckbox: new FormControl(false),
      response_type: new FormControl(this.options[0]),
      photos: new FormControl(false),
      comments: new FormControl(false),
    })
  }


  groupedFormat(i: number) {
    return this.itemFormat()?.controls[i].get('responses') as FormArray
  }

  addItemsToGroup(i: number) {
    (<FormArray>this.itemFormat()?.controls[i].get('responses'))?.push(this.addItemformats())
  }

  addItemOption(i: number) {
    this.groupedFormat(i)?.push(this.addOptionformats())
  }

  addGroupOption(i: number, j: number) {
    this.groupOptionFormat(i, j)?.push(this.addOptionformats());
  }

  addOptionformats() {
    return new FormGroup({
      value: new FormControl('', [Validators.required]),
      flag: new FormControl(false),
      order: new FormControl(''),
    })
  }


  optionFormat(i: number) {
    return this.itemFormat()?.controls[i].get('responses') as FormArray;
  }

  groupOptionFormat(i: number, j: number) {
    return this.groupedFormat(i).controls[j].get('responses') as FormArray;
  }


  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.itemFormat().controls, event.previousIndex, event.currentIndex);
  }



  onValueChange(type: string, i: number, item: any) {
    if (item.controls.group_name.value === null) {
      if (type === 'Numeric Response' || type === 'Text Response') {
        this.optionFormat(i).clear();
        if (this.optionFormat(i).controls.length === 0) (<FormArray>this.itemFormat().at(i)?.get('responses')).push(this.addOptionformats())
      } else if (type === 'Completion Status') {
        this.optionFormat(i).clear();
        if (this.optionFormat(i).controls.length === 0) {
          this.Status.forEach((response: any) => {
            const newFormItem = this.addOptionformats();
            newFormItem.patchValue(response);
            (<FormArray>this.itemFormat().at(i).get('responses')).push(newFormItem)
          });
        }
      }
      else if (type === 'Pass/Fail/Flag') {
        this.optionFormat(i).clear();
        if (this.optionFormat(i).controls.length === 0) {
          this.flags.forEach((response: any) => {
            const newFormItem = this.addOptionformats();
            newFormItem.patchValue(response);
            (<FormArray>this.itemFormat().at(i).get('responses')).push(newFormItem)
          });
        }
      }
    }

  }

  onGroupValueChange(type: string, i: number, j: number) {
    if (type === 'Text Response' || type === 'Numeric Response') {
      this.groupOptionFormat(i, j).clear();
      if (this.groupOptionFormat(i, j).controls.length === 0) {
        this.groupOptionFormat(i, j).controls.push(this.addOptionformats());
      }

    } else if (type === 'Completion Status') {
      this.groupOptionFormat(i, j).clear();
      if (this.groupOptionFormat(i, j).controls.length === 0) {
        this.Status.forEach((response: any) => {
          const newFormItem = this.addOptionformats();
          newFormItem.patchValue(response);
          this.groupOptionFormat(i, j).push(newFormItem)
        });
      }

    }
    else if (type === 'Pass/Fail/Flag') {
      this.groupOptionFormat(i, j).clear();
      if (this.groupOptionFormat(i, j).controls.length === 0) {
        this.flags.forEach((response: any) => {
          const newFormItem = this.addOptionformats();
          newFormItem.patchValue(response);
          this.groupOptionFormat(i, j).push(newFormItem)
        });
      }

    }

  }

  removeUngroupedOption(i: number, j: number) {
    (<FormArray>this.itemFormat().at(i).get('responses')).removeAt(j);
  }

  removeItemsGroup(item: any, i: number, j: number, k: number) {
    this.itemFormat().at(i).get('responses')['controls'][j].get('responses').removeAt(k)
  }

  unGroup(e: any, item: any, i: number) {
    const sk = this.itemFormat().at(i).get('responses') as FormArray
    this.itemFormat().removeAt(i);
    this.itemFormat().controls.splice(i, 0, ...sk.controls);
  }
}


