// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .mat-mdc-radio-button {
  display: block !important;
}

.cover {
  width: 800px;
}

.smpt-cover {
  width: 800px;
}

.settings-title {
  padding-top: 15px;
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_views/_popup_views/inbox-create-inbox/inbox-create-inbox.component.scss"],"names":[],"mappings":"AAAA;EACA,yBAAA;AACA;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,iBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;AACJ","sourcesContent":["::ng-deep .mat-mdc-radio-button{\ndisplay: block !important;\n}\n\n.cover{\n    width: 800px;\n}\n\n.smpt-cover{\n    width: 800px;\n}\n\n.settings-title {\n    padding-top: 15px;\n    padding-bottom: 5px;\n    font-size: 14px;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
