import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import { FileReminderComponent } from "../file-reminder/file-reminder.component";
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { Observable, of } from "rxjs";
import { catchError, filter, map, switchMap, takeUntil, tap } from "rxjs/operators";
import { ConfigService } from "../../../../../services/config.service";
import { UtilityService } from "../../../../../services/utility.service";
import { DialogService, ModalData } from '../../../../_services/dialog/dialog.service';
import { ChecklistSignaturesHttpService, SignaturePostRequestBody } from "../../../../_services/checklist-signatures";
import { FilesService } from "../../../../../Modules/_fyxt_modules/companies/_services/files.service";
import { SignatureCollectionModel } from "./signature-collection-model";
import { NotifyOthersComponent } from '../notify-others/notify-others.component';
import {BaseService} from "../../../../../services/base.service";
import { MemberRole } from '../notify-others/notify-others.inteface'
import { ChecklistService } from "src/app/services/checklist/checklist.service";
import {
  ExtVendorJobsService
} from '../../../../../Modules/_fyxt_modules/new-external-vendor/services/ext-vendor-jobs.service';
import { CheckboxComponent } from "../../../_reusable_ui/_controls/checkbox/checkbox.component";
import { AngularSignaturePadModule } from "@almothafar/angular-signature-pad";
import { NgSwitch, NgSwitchCase, NgTemplateOutlet, NgIf, AsyncPipe, LowerCasePipe, DatePipe } from "@angular/common";
import { TabComponent } from "../../../_reusable_ui/_components/tab/tab.component";
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TextboxComponent } from "../../../_reusable_ui/_controls/textbox/textbox.component";
import { MatIconModule } from "@angular/material/icon";
import { ButtonComponent } from "../../../_reusable_ui/_components/button/button.component";

export interface SignatureCollectionModalData {
  jobId: number,
  checklistId: string,
  userRole: string,
  isPublic: boolean,
  isPublicVendor?: boolean,
  contactId?: string,
  vendorId?: string,
  requiredRoles?: { [index: string]: string },
}

@Component({
    selector: 'fyxt-signature-collection',
    templateUrl: 'signature-collection.component.html',
    styleUrls: ['signature-collection.component.scss'],
    standalone: true,
    imports: [ButtonComponent, MatDialogModule, MatIconModule, FormsModule, ReactiveFormsModule, TextboxComponent, NgxIntlTelInputModule, TabComponent, NgSwitch, NgSwitchCase, AngularSignaturePadModule, NgTemplateOutlet, NgIf, CheckboxComponent, AsyncPipe, LowerCasePipe, DatePipe]
})
export class SignatureCollectionComponent extends SignatureCollectionModel implements OnInit, OnDestroy {
  readonly signatureCollectionForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    companyName: new FormControl('', [Validators.required]),
  });

  constructor(
    protected readonly dialogRef: MatDialogRef<FileReminderComponent>,
    protected readonly configService: ConfigService,
    protected readonly utilService: UtilityService,
    protected readonly checklistSignaturesService: ChecklistSignaturesHttpService,
    @Inject(MAT_DIALOG_DATA) protected readonly matDialogData: ModalData<SignatureCollectionModalData>,
    protected filesService: FilesService,
    protected readonly dialogService: DialogService,
    protected readonly baseService: BaseService,
    protected readonly _vendorJobService: ExtVendorJobsService,
    public _checklistService: ChecklistService,
  ) {
    super(filesService);
  }

  ngOnInit() {
    if (this._vendorJobService?.fullVendorDetails) {
      const { vendor_info = {} } = this._vendorJobService?.fullVendorDetails;
      if (vendor_info && vendor_info.company) {
        this.signatureCollectionForm.patchValue({ companyName: vendor_info.company });
      }
    }
  }

  shouldOpenNotifyOthers(): boolean {
    const isPublicRequest = !!this.matDialogData.data.isPublic;

    return !isPublicRequest && this.matDialogData.data.userRole !== MemberRole.TENANT
  }

  signAndNotifyOthers(): void {
    if (!this.isUserAgreed || !this.isSignatureDataValid()) return;

    of(this.shouldOpenNotifyOthers())
      .pipe(
        switchMap((shouldOpen) => shouldOpen ? this.openNotify() : of(true)),
        filter((isAllowedToSave) => isAllowedToSave),
        tap(() => this.configService.isLoader = true),
        switchMap(() => this.saveSignature()),
      )
      .subscribe({
        next: (result) => {
          this.configService.isLoader = false;
          if (result) {
            this.dialogRef.close(true)
          } else {
            this.utilService.showError('', 'Something went wrong.')
          }
        },
        error: () => {
          this.configService.isLoader = false;
          this.utilService.showError('', 'Something went wrong.')
        }
      });
  }

  openNotify(): Observable<boolean> {
    const popupData = {
      title: 'Notify Other',
      component: NotifyOthersComponent,
      containerClass: ['modal-notify-other'],
      data: {
        roles: this.matDialogData.data.requiredRoles,
        memberType: null,
        selectedChecklist: this.matDialogData.data.checklistId,
        isPublic: this.matDialogData.data.isPublic
      }
    };

    return this.dialogService.openModal(popupData).pipe(map((res) => !!res));
  }

  saveSignature(): Observable<any> {
    if (!this.isUserAgreed || !this.isSignatureDataValid()) return;

    const formValue = this.signatureCollectionForm.getRawValue();
    const isPublicRequest = !!this.matDialogData.data.isPublic;
    const isPublicVendorRequest = !!this.matDialogData.data.isPublicVendor;
    let contactId = this.matDialogData.data.contactId;

    return of(this.getDrawnSignature())
      .pipe(
        switchMap((blob) => {
          if (!blob) {
            return of(null);
          }

          if (!isPublicRequest) {
            return this.uploadSignatureFile(blob);
          }

          if (!contactId) {
            if (isPublicVendorRequest) {
              const { vendor_id } = this._vendorJobService.fullVendorDetails;
              contactId = vendor_id;
            } else {
              this.utilService.showError('', 'There is no contact id.');
              throw new Error();
            }
          }

          return this.uploadSignatureFile(blob, contactId, isPublicRequest);
        }),
        map((file) => {
          const signatureData: SignaturePostRequestBody = {
            name: formValue.name,
            company_name: formValue.companyName,
            signature_text: this.handwrittenSignature || null,
            signature_file_id: file?.id || null,
            job_id: String(this.matDialogData.data.jobId),
            checklist_id: this.matDialogData.data.checklistId,
            user_role: !isPublicRequest ? this.matDialogData.data.userRole : isPublicVendorRequest ? MemberRole.VENDOR : MemberRole.TENANT,
          };

          if (this.matDialogData.data.isPublic && !this.matDialogData.data.isPublicVendor) {
            signatureData.contact_id = this.matDialogData.data.contactId
          } else if (signatureData.user_role === 'Tenant') {
            signatureData.contact_id = this.baseService.currentUserInfo.contact_id
          }

          if (this.matDialogData.data.isPublicVendor) {
            signatureData.vendor_id = this.matDialogData.data.vendorId;
          }

          return [signatureData, file];
        }),
        switchMap(([signatureData, file]) =>
          this.checklistSignaturesService
            .saveSignature(signatureData, isPublicRequest, isPublicVendorRequest)
            .pipe(catchError(() => {
              if (!file) { return of(null); }

              const publicDeleteRequestBody = {
                key: file.key,
                createdById: file.createdById,
                createdByType: file.createdByType
              };

              if (isPublicRequest) {
                return this.filesService.publicDeleteFile(publicDeleteRequestBody).pipe(map(() => null));
              }

              return this.filesService.deleteFile(file.key).pipe(map(() => null));
            })),
        ),
        takeUntil(this.destroy$),
      );
  }

  closePopup(): void {
    if (!this.dialogRef) {
      return;
    }

    this.dialogRef.close(false);
  }

  isSignatureDataValid(): boolean {
    const isFormValid = this.signatureCollectionForm.valid;
    const hasSignature = this.hasSignature();

    return isFormValid && hasSignature;
  }

}
