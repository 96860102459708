// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textbox-label {
  margin: 0 0 8px;
  font-size: 12px;
}

.err-msg {
  color: #D13147;
  font-size: 12px;
}

.note_text {
  letter-spacing: 0;
  color: #0277BD;
  opacity: 1;
  font-size: 12px/16px;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_views/_popup_views/add-coverage/add-coverage.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,eAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;AACF;;AAEA;EACE,iBAAA;EACA,cAAA;EACA,UAAA;EACA,oBAAA;AACF","sourcesContent":[".textbox-label {\n  margin: 0 0 8px;\n  font-size: 12px;\n}\n\n.err-msg {\n  color: #D13147;\n  font-size: 12px;\n}\n\n.note_text {\n  letter-spacing: 0;\n  color: #0277BD;\n  opacity: 1;\n  font-size: 12px/16px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
