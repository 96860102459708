export enum JobCostType {
  BID = 'Bid',
  CHANGE_ORDER = 'Change Order',
  INVOICE = 'Invoice',
}

export enum JobCostStatus {
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  PROPOSED = 'Proposed',
}

export enum JobCostStages {
  BID_APPROVED = 'Bid Approved',
}

export enum UserTypes {
  VENDOR = 'Vendor',
}

export enum JobCostIdentifier {
  REPAIR = 'Repair',
  REPLACEMENT = 'Replacement',
  CHANGE_ORDER = 'Change Order',
}

export interface JobCost {
  id: string
  title: string
  type: JobCostType
  identifier: JobCostIdentifier
  total: number
  hours: number
  material_cost: number
  description: string
  exclusion: string
  has_tenant_accepted_proportionate_share: true,
  status: JobCostStatus
  status_changed_on: string
  created_at: string
  modified_at: string
  equipment: null,
  user_type: UserTypes
  user_id: string
  stage: JobCostStages
  company: string
  name: string
  email: string
  phone: string
  photo: null,
  documents:  {
    id: string
    file: string
    name: string
    size: string
    created_at: string
  }[]
}
