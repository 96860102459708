import { Component, ChangeDetectorRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { format } from 'date-fns';
import { Subscription } from 'rxjs';
import { ItemList } from 'src/app/@fyxt/_shared/_reusable_ui/_controls/dropdown-select/itemType.class';
import { JobsService } from 'src/app/services/v2/jobs.service';
import { FilterAccordianComponent } from '../../_controls/filter-accordian/filter-accordian.component';
import { CheckboxComponent } from '../../_controls/checkbox/checkbox.component';
import { TextboxComponent } from '../../_controls/textbox/textbox.component';
import { DropdownSelectComponent } from '../../_controls/dropdown-select/dropdown-select/dropdown-select.component';
import { DropdownDateSelectComponent } from '../../_controls/dropdown-select/dropdown-date-select/dropdown-date-select.component';
import { MatDividerModule } from '@angular/material/divider';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { DateDropdownComponent } from '../../_controls/datepicker/date-dropdown/date-dropdown.component';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../button/button.component';
import { NgIf, NgClass, NgFor, NgSwitch, NgSwitchCase } from '@angular/common';
import { CardComponent } from '../card/card.component';

@Component({
    selector: 'fyxt-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
    standalone: true,
    imports: [CardComponent, NgIf, ButtonComponent, MatIconModule, NgClass, DateDropdownComponent, FormsModule, NgxIntlTelInputModule, MatDividerModule, DropdownDateSelectComponent, DropdownSelectComponent, TextboxComponent, CheckboxComponent, NgFor, NgSwitch, NgSwitchCase, FilterAccordianComponent]
})
export class FilterComponent implements OnInit {
  @Input() dateRange: any = {};
  @Input() showResetDate: boolean = true;
  @Input() dateCreatedDateRange: any = { from: null, to: null };
  @Input() _lastActivityDateRange: any = { from: null, to: null };
  @Input() targetCompletionDateRange: any = { from: null, to: null };
  @Input() scheduleDateRange: any = { from: null, to: null };
  @Input() jobCostApprovedDateRange: any = { from: null, to: null };
  @Input() invoiceSubmittedDateRange: any = { from: null, to: null };
  @Input() invoiceApprovedDateRange: any = { from: null, to: null };
  @Input() bidSubmittedDateRange: any = { from: null, to: null };
  @Input() bidApprovedDateRange: any = { from: null, to: null };


  @Input() lastActivityDateRange: any;
  @Input() dateApprovedDateRange: any;
  @Input() isLastActivityAdded: boolean;
  @Input() isDateApprovedAdded: boolean;
  @Input() filter_for: any;
  @Input() showClearAllText: boolean = true;
  @Input() showCloseFilterIcon: boolean = true;
  @Input() date_filter: boolean = true;
  @Input() equiment_filter: boolean = false;
  @Input() lease_filter: boolean = false;
  @Input() inbox_filter: boolean;
  @Input() coi_filter: boolean;
  @Input() inboxFolderDropdown: any;
  @Input() inboxSubject: any;
  @Input() isInboxUnreadSelected: boolean;
  @Input() isInboxReadSelected: boolean;
  @Input() isInboxHasAttachmentSelected: boolean;
  @Input() isEmergencyJobSelected: boolean;
  @Input() customAttributesFilter: any[] = [];
  @Input() isDraft: boolean = false;
  @Input() showWithOutInnerHeight: boolean = false;
  @Input() jobCostScenario: any
  @Input() showNewCustomDateFilter: boolean = false;
  @Input() isManageTeams: boolean = false;

  @Output() cancel = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Output() onClear = new EventEmitter();
  @Output() onDateChange = new EventEmitter();
  @Output() onResetDate = new EventEmitter();
  @Output() onLastActivityDateChange = new EventEmitter();
  @Output() onLatActivityResetDate = new EventEmitter();
  @Output() onDateApprovedChange = new EventEmitter();
  @Output() onDateApprovedResetDate = new EventEmitter();
  @Output() onJobCostAmountChange = new EventEmitter();
  @Output() onCustomFiledChange = new EventEmitter();
  @Output() onInboxFolderValueChange = new EventEmitter();
  @Output() onInboxSubjectChange = new EventEmitter();
  @Output() onInboxCheckboxValueChange = new EventEmitter();
  @Output() onScenarioNameChange = new EventEmitter();
  @Output() onResetCustomFilter = new EventEmitter();
  @Output() customFilterTextSearch = new EventEmitter();
  @Output() customFilterTextScroll = new EventEmitter();

  today = new Date();
  fromDate = null;
  toDate = null;

  @Input() dateFilters: any = {
    date_created: null,
    last_activity: null,
    target_completion: null,
    schedule: null,
    jobcost_date_approved: null,
    jobcost_amount: {
      from: null,
      to: null
    },
    invoice_submitted_date: null,
    invoice_approved_date: null,
    invoice_amount: {
      from: null,
      to: null
    },
    bid_submitted_date: null,
    bid_approved_date: null,
    bid_amount: {
      from: null,
      to: null
    },
  };


  checkboxOptions = [{ name: "YES" }, { name: "NO" }]
  // filteritems: ItemList[] = [
  //   { id: 1, label: 'Orange County, CA', name: 'Orange County, CA' },
  //   { id: 2, label: 'Los Angeles, CA', name: 'Los Angeles, CA', selected: true },
  //   { id: 3, label: 'San Fransisco, CA', name: 'San Fransisco, CA' },
  //   { id: 4, label: 'Portland, OR', name: 'Portland, OR' },
  //   { id: 5, label: 'Seattle, WA', name: 'Seattle, WA' },
  //   { id: 6, label: 'Los Angeles, CA', name: 'Los Angeles, CA' },
  // ];
  // filteritems2: ItemList[] = [
  //   { id: 1, label: 'Tenant', name: 'Tenant' },
  //   { id: 2, label: 'Vendor', name: 'Vendor', selected: true },
  // ];


  dateCreatedDateRangeContainer: boolean = false;
  lastActivityDateRangeContainer: boolean = false;
  targetCompletionDateRangeContainer: boolean = false;
  scheduleDateRangeContainer: boolean = false;
  jobCostApprovedDateRangeContainer: boolean = false;
  invoice_submitted_dateRangeContainer: boolean = false;
  invoice_approved_dateRangeContainer: boolean = false;
  bid_submitted_dateRangeContainer: boolean = false;
  bid_approved_dateRangeContainer: boolean = false;

  fromDateIsNotValid: boolean = false;
  toDateIsNotValid: boolean = false;
  fromDateLastActivity: boolean = false;
  toDateLastActivity: boolean = false;

  dateCreatedOpen: boolean = false;
  lastActivityOpen: boolean = false;
  targetCompletionOpen: boolean = false;
  scheduleOpen: boolean = false;
  jobCostApprovedOpen: boolean = false;
  invoice_submitted_date_open: boolean = false;
  invoice_approved_date_open: boolean = false;
  bid_submitted_date_open: boolean = false;
  bid_approved_date_open: boolean = false;

  clearAllDateFiltersSubscription: Subscription;

  constructor(public changeDetector: ChangeDetectorRef, public _jobService: JobsService) {
  }

  ngOnInit(): void {
    this.clearAllDateFiltersSubscription = this._jobService.clearAllDateFieldFiltersOb().subscribe((res) => {
      if (res) {
        this.dateCreatedDateRangeContainer = false;
        this.dateCreatedDateRange.from = null;
        this.dateCreatedDateRange.to = null;
        this.lastActivityDateRangeContainer = false;
        this._lastActivityDateRange.from = null;
        this._lastActivityDateRange.to = null;
        this.targetCompletionDateRangeContainer = false;
        this.targetCompletionDateRange.from = null;
        this.targetCompletionDateRange.to = null;
        this.scheduleDateRangeContainer = false;
        this.scheduleDateRange.from = null;
        this.scheduleDateRange.to = null;
        this.jobCostApprovedDateRangeContainer = false;
        this.jobCostApprovedDateRange.from = null;
        this.jobCostApprovedDateRange.to = null;
      }
    })
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.clearAllDateFiltersSubscription.unsubscribe();
  }
  /**
   * Close Filter Container
   */
  closeFilter() {
    this.cancel.emit();
  }

  /**
  * Close Selected Filters
  */
  closeFilters() {
    this.onClose.emit();
  }


  /**
   * Clear Selected Filters
   */
  clearSelectedFilters() {
    this.dateCreatedDateRangeContainer = false;
    this.dateCreatedDateRange.from = null;
    this.dateCreatedDateRange.to = null;
    this.lastActivityDateRangeContainer = false;
    this._lastActivityDateRange.from = null;
    this._lastActivityDateRange.to = null;
    this.targetCompletionDateRangeContainer = false;
    this.targetCompletionDateRange.from = null;
    this.targetCompletionDateRange.to = null;
    this.scheduleDateRangeContainer = false;
    this.scheduleDateRange.from = null;
    this.scheduleDateRange.to = null;
    this.jobCostApprovedDateRangeContainer = false;
    this.jobCostApprovedDateRange.from = null;
    this.jobCostApprovedDateRange.to = null;
    this.dateFilters.date_created = null;
    this.dateFilters.last_activity = null;
    this.dateFilters.target_completion = null;
    this.dateFilters.schedule = null;
    this.dateFilters.jobcost_date_approved = null;
    this.dateFilters.jobcost_amount = {
      from: null,
      to: null
    };

    this.dateFilters.invoice_submitted_date = null;
    this.dateFilters.invoice_approved_date = null;
    this.dateFilters.invoice_amount = {
      from: null,
      to: null
    };

    this.dateFilters.bid_submitted_date = null;
    this.dateFilters.bid_approved_date = null;
    this.dateFilters.bid_amount = {
      from: null,
      to: null
    };


    this.dateCreatedOpen = false;
    this.lastActivityOpen = false;
    this.targetCompletionOpen = false;
    this.scheduleOpen = false;
    this.jobCostApprovedOpen = false;
    this.invoice_submitted_date_open = false;
    this.invoice_approved_date_open = false;
    this.bid_submitted_date_open = false;
    this.bid_approved_date_open = false;


    this.onClear.emit();
  }



  onDateChangeValue(event: any, type: string) {
    if (type === 'date_created') {
      if (event.name === 'Custom') {
        this.dateCreatedDateRangeContainer = true;
        this.dateCreatedOpen = true;
      }
      else {
        this.dateCreatedOpen = false;
        this.dateCreatedDateRangeContainer = false;
      }
    }

    if (type === 'last_activity') {
      if (event.name === 'Custom') {
        this.lastActivityDateRangeContainer = true;
        this.lastActivityOpen = true;
      }
      else {
        this.lastActivityOpen = false;
        this.lastActivityDateRangeContainer = false;
      }
    }

    if (type === 'target_completion') {
      if (event.name === 'Custom') {
        this.targetCompletionDateRangeContainer = true;
        this.targetCompletionOpen = true;
      }
      else {
        this.targetCompletionOpen = false;
        this.targetCompletionDateRangeContainer = false;
      }
    }

    if (type === 'schedule') {
      if (event.name === 'Custom') {
        this.scheduleDateRangeContainer = true;
        this.scheduleOpen = true;
      }
      else {
        this.scheduleOpen = false;
        this.scheduleDateRangeContainer = false;
      }
    }


    if (type === 'jobcost_date_approved') {
      if (event.name === 'Custom') {
        this.jobCostApprovedDateRangeContainer = true;
        this.jobCostApprovedOpen = true;
      }
      else {
        this.jobCostApprovedOpen = false;
        this.jobCostApprovedDateRangeContainer = false;
      }
    }


    if (type === 'invoice_submitted_date') {
      if (event.name === 'Custom') {
        this.invoice_submitted_dateRangeContainer = true;
        this.invoice_submitted_date_open = true;
      }
      else {
        this.invoice_submitted_date_open = false;
        this.invoice_submitted_dateRangeContainer = false;
      }
    }

    if (type === 'invoice_approved_date') {
      if (event.name === 'Custom') {
        this.invoice_approved_dateRangeContainer = true;
        this.invoice_approved_date_open = true;
      }
      else {
        this.invoice_approved_date_open = false;
        this.invoice_approved_dateRangeContainer = false;
      }
    }


    if (type === 'bid_submitted_date') {
      if (event.name === 'Custom') {
        this.bid_submitted_dateRangeContainer = true;
        this.bid_submitted_date_open = true;
      }
      else {
        this.bid_submitted_date_open = false;
        this.bid_submitted_dateRangeContainer = false;
      }
    }

    if (type === 'bid_approved_date') {
      if (event.name === 'Custom') {
        this.bid_approved_dateRangeContainer = true;
        this.bid_approved_date_open = true;
      }
      else {
        this.bid_approved_date_open = false;
        this.bid_approved_dateRangeContainer = false;
      }
    }


    if (event?.id && event.name !== 'Custom') {
      event.type = type;
      this.onDateChange.emit(event);
    } else {

      if (event.dateRange[0] && event.dateRange[1]) {
        event.type = type;
        this.onDateChange.emit(event);
      }
    }

  }

  formatDateToYYYY_MM_DD(date: Date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  // this is Date Range Selector is for !equiment_filter && !lease_filter && !inbox_filter
  onDateRangeSelected(event: any, type: string) {
    if (type === 'from date') {
      this.fromDate = event;
      this.dateRange.from = event;
    } else if (type === 'to date') {
      this.toDate = event;
      this.dateRange.to = event;
    }
    if (this.fromDate instanceof Date) {
      this.formatDateToMM_DD_YYYY(this.fromDate) > this.formatDateToMM_DD_YYYY(this.today) ? this.fromDateIsNotValid = true : this.fromDateIsNotValid = false;
    }

    if (this.toDate instanceof Date) {
      this.formatDateToMM_DD_YYYY(this.toDate) > this.formatDateToMM_DD_YYYY(this.today) ? this.toDateIsNotValid = true : this.toDateIsNotValid = false;
    }
    this.onDateChange.emit(this.dateRange);
  }

  onDateSelected(event: any, type: string) {
    if (type === 'from date') {
      this.fromDate = event;
      this.dateRange.from = event;
    } else if (type === 'to date') {
      this.toDate = event;
      this.dateRange.to = event;
    }
    if (this.fromDate instanceof Date) {
      this.formatDateToMM_DD_YYYY(this.fromDate) > this.formatDateToMM_DD_YYYY(this.today) ? this.fromDateIsNotValid = true : this.fromDateIsNotValid = false;
    }

    if (this.toDate instanceof Date) {
      this.formatDateToMM_DD_YYYY(this.toDate) > this.formatDateToMM_DD_YYYY(this.today) ? this.toDateIsNotValid = true : this.toDateIsNotValid = false;
    }
    this.onDateChange.emit(this.dateRange);
  }

  onJobCostSelected(event: any, type: string) {
    if (type === 'jobCostApprovedFromDate') {
      this.jobCostApprovedDateRange.from = event;
      if (this.jobCostApprovedDateRange.from !== null && this.jobCostApprovedDateRange.to !== null) {
        let customEvent = {
          id: 5,
          type: 'jobcost_date_approved',
          name: '(' + this.formatDateToMM_DD_YYYY(this.jobCostApprovedDateRange.from) + ' - ' + this.formatDateToMM_DD_YYYY(this.jobCostApprovedDateRange.to) + ')',
          value: 'Custom',
          dateRange: [this.jobCostApprovedDateRange.from, this.jobCostApprovedDateRange.to]
        }
        this.onDateChange.emit(customEvent);
      }
    } else if (type === 'jobCostApprovedToDate') {
      this.jobCostApprovedDateRange.to = event;
      if (this.jobCostApprovedDateRange.from !== null && this.jobCostApprovedDateRange.to !== null) {
        let customEvent = {
          id: 5,
          type: 'jobcost_date_approved',
          name: '(' + this.formatDateToMM_DD_YYYY(this.jobCostApprovedDateRange.from) + ' - ' + this.formatDateToMM_DD_YYYY(this.jobCostApprovedDateRange.to) + ')',
          value: 'Custom',
          dateRange: [this.jobCostApprovedDateRange.from, this.jobCostApprovedDateRange.to]
        }
        this.onDateChange.emit(customEvent);
      }
    }
  }

  onScheduleSelected(event: any, type: string) {
    if (type === 'scheduleFromDate') {
      this.scheduleDateRange.from = event;
      if (this.scheduleDateRange.from !== null && this.scheduleDateRange.to !== null) {
        let customEvent = {
          id: 5,
          type: 'schedule',
          name: '(' + this.formatDateToMM_DD_YYYY(this.scheduleDateRange.from) + ' - ' + this.formatDateToMM_DD_YYYY(this.scheduleDateRange.to) + ')',
          value: 'Custom',
          dateRange: [this.scheduleDateRange.from, this.scheduleDateRange.to]
        }
        this.onDateChange.emit(customEvent);
      }
    } else if (type === 'scheduleToDate') {
      this.scheduleDateRange.to = event;
      if (this.scheduleDateRange.from !== null && this.scheduleDateRange.to !== null) {
        let customEvent = {
          id: 5,
          type: 'schedule',
          name: '(' + this.formatDateToMM_DD_YYYY(this.scheduleDateRange.from) + ' - ' + this.formatDateToMM_DD_YYYY(this.scheduleDateRange.to) + ')',
          value: 'Custom',
          dateRange: [this.scheduleDateRange.from, this.scheduleDateRange.to]
        }
        this.onDateChange.emit(customEvent);
      }
    }
  }

  onTargetCompletionSelected(event: any, type: string) {
    if (type === 'targetCompletionFromDate') {
      this.targetCompletionDateRange.from = event;
      if (this.targetCompletionDateRange.from !== null && this.targetCompletionDateRange.to !== null) {
        let customEvent = {
          id: 5,
          type: 'target_completion',
          name: '(' + this.formatDateToMM_DD_YYYY(this.targetCompletionDateRange.from) + ' - ' + this.formatDateToMM_DD_YYYY(this.targetCompletionDateRange.to) + ')',
          value: 'Custom',
          dateRange: [this.targetCompletionDateRange.from, this.targetCompletionDateRange.to]
        }
        this.onDateChange.emit(customEvent);
      }
    } else if (type === 'targetCompletionToDate') {
      this.targetCompletionDateRange.to = event;
      if (this.targetCompletionDateRange.from !== null && this.targetCompletionDateRange.to !== null) {
        let customEvent = {
          id: 5,
          type: 'target_completion',
          name: '(' + this.formatDateToMM_DD_YYYY(this.targetCompletionDateRange.from) + ' - ' + this.formatDateToMM_DD_YYYY(this.targetCompletionDateRange.to) + ')',
          value: 'Custom',
          dateRange: [this.targetCompletionDateRange.from, this.targetCompletionDateRange.to]
        }
        this.onDateChange.emit(customEvent);
      }
    }
  }

  onLastActivitySelected(event: any, type: string) {
    if (type === 'lastActivityFromDate') {
      this._lastActivityDateRange.from = event;
      if (this._lastActivityDateRange.from !== null && this._lastActivityDateRange.to !== null) {
        let customEvent = {
          id: 5,
          type: 'last_activity',
          name: '(' + this.formatDateToMM_DD_YYYY(this._lastActivityDateRange.from) + ' - ' + this.formatDateToMM_DD_YYYY(this._lastActivityDateRange.to) + ')',
          value: 'Custom',
          dateRange: [this._lastActivityDateRange.from, this._lastActivityDateRange.to]
        }
        this.onDateChange.emit(customEvent);
      }
    } else if (type === 'lastActivityToDate') {
      this._lastActivityDateRange.to = event;
      if (this._lastActivityDateRange.from !== null && this._lastActivityDateRange.to !== null) {
        let customEvent = {
          id: 5,
          type: 'last_activity',
          name: '(' + this.formatDateToMM_DD_YYYY(this._lastActivityDateRange.from) + ' - ' + this.formatDateToMM_DD_YYYY(this._lastActivityDateRange.to) + ')',
          value: 'Custom',
          dateRange: [this._lastActivityDateRange.from, this._lastActivityDateRange.to]
        }
        this.onDateChange.emit(customEvent);
      }
    }
  }

  onDateCreatedSelected(event: any, type: string) {
    if (type === 'dateCreatedFromDate') {
      this.dateCreatedDateRange.from = event;
      if (this.dateCreatedDateRange.from !== null && this.dateCreatedDateRange.to !== null) {
        let customEvent = {
          id: 5,
          type: 'date_created',
          name: '(' + this.formatDateToMM_DD_YYYY(this.dateCreatedDateRange.from) + ' - ' + this.formatDateToMM_DD_YYYY(this.dateCreatedDateRange.to) + ')',
          value: 'Custom',
          dateRange: [this.dateCreatedDateRange.from, this.dateCreatedDateRange.to]
        }
        this.onDateChange.emit(customEvent);
      }
    } else if (type === 'dateCreatedToDate') {
      this.dateCreatedDateRange.to = event;
      if (this.dateCreatedDateRange.from !== null && this.dateCreatedDateRange.to !== null) {
        let customEvent = {
          id: 5,
          type: 'date_created',
          name: '(' + this.formatDateToMM_DD_YYYY(this.dateCreatedDateRange.from) + ' - ' + this.formatDateToMM_DD_YYYY(this.dateCreatedDateRange.to) + ')',
          value: 'Custom',
          dateRange: [this.dateCreatedDateRange.from, this.dateCreatedDateRange.to]
        }
        this.onDateChange.emit(customEvent);
      }
    }
  }

  onInvoiceDateSelected(event: any, type: string, changeType: string) {
    if (changeType == 'invoice_submitted_date') {
      if (type === 'FromDate') {
        this.invoiceSubmittedDateRange.from = event;
        if (event !== null && this.invoiceSubmittedDateRange.to !== null) {
          let customEvent = {
            id: 5,
            type: 'invoice_submitted_date',
            name: '(' + this.formatDateToMM_DD_YYYY(this.invoiceSubmittedDateRange.from) + ' - ' + this.formatDateToMM_DD_YYYY(this.invoiceSubmittedDateRange.to) + ')',
            value: 'Custom',
            dateRange: [this.invoiceSubmittedDateRange.from, this.invoiceSubmittedDateRange.to]
          }
          this.onDateChange.emit(customEvent);
        }
      } else if (type === 'ToDate') {
        this.invoiceSubmittedDateRange.to = event;
        if (event !== null) {
          let customEvent = {
            id: 5,
            type: 'invoice_submitted_date',
            name: '(' + this.formatDateToMM_DD_YYYY(this.invoiceSubmittedDateRange.from) + ' - ' + this.formatDateToMM_DD_YYYY(this.invoiceSubmittedDateRange.to) + ')',
            value: 'Custom',
            dateRange: [this.invoiceSubmittedDateRange.from, this.invoiceSubmittedDateRange.to]
          }
          this.onDateChange.emit(customEvent);
        }
      }
    }

    if (changeType == 'invoice_approved_date') {
      if (type === 'FromDate') {
        this.invoiceApprovedDateRange.from = event;
        if (event !== null && this.invoiceApprovedDateRange.to !== null) {
          let customEvent = {
            id: 5,
            type: 'invoice_approved_date',
            name: '(' + this.formatDateToMM_DD_YYYY(this.invoiceApprovedDateRange.from) + ' - ' + this.formatDateToMM_DD_YYYY(this.invoiceApprovedDateRange.to) + ')',
            value: 'Custom',
            dateRange: [this.invoiceApprovedDateRange.from, this.invoiceApprovedDateRange.to]
          }
          this.onDateChange.emit(customEvent);
        }
      } else if (type === 'ToDate') {
        this.invoiceApprovedDateRange.to = event;
        if (event !== null) {
          let customEvent = {
            id: 5,
            type: 'invoice_approved_date',
            name: '(' + this.formatDateToMM_DD_YYYY(this.invoiceApprovedDateRange.from) + ' - ' + this.formatDateToMM_DD_YYYY(this.invoiceApprovedDateRange.to) + ')',
            value: 'Custom',
            dateRange: [this.invoiceApprovedDateRange.from, this.invoiceApprovedDateRange.to]
          }
          this.onDateChange.emit(customEvent);
        }
      }
    }

  }

  onBidDateSelected(event: any, type: string, changeType: string) {
    if (changeType == 'bid_submitted_date') {
      if (type === 'FromDate') {
        this.bidSubmittedDateRange.from = event;
        if (event !== null && this.bidSubmittedDateRange.to !== null) {
          let customEvent = {
            id: 5,
            type: 'bid_submitted_date',
            name: '(' + this.formatDateToMM_DD_YYYY(this.bidSubmittedDateRange.from) + ' - ' + this.formatDateToMM_DD_YYYY(this.bidSubmittedDateRange.to) + ')',
            value: 'Custom',
            dateRange: [this.bidSubmittedDateRange.from, this.bidSubmittedDateRange.to]
          }
          this.onDateChange.emit(customEvent);
        }
      } else if (type === 'ToDate') {
        this.bidSubmittedDateRange.to = event;
        if (event !== null) {
          let customEvent = {
            id: 5,
            type: 'bid_submitted_date',
            name: '(' + this.formatDateToMM_DD_YYYY(this.bidSubmittedDateRange.from) + ' - ' + this.formatDateToMM_DD_YYYY(this.bidSubmittedDateRange.to) + ')',
            value: 'Custom',
            dateRange: [this.bidSubmittedDateRange.from, this.bidSubmittedDateRange.to]
          }
          this.onDateChange.emit(customEvent);
        }
      }
    }

    if (changeType == 'bid_approved_date') {
      if (type === 'FromDate') {
        this.bidApprovedDateRange.from = event;
        if (event !== null && this.bidApprovedDateRange.to !== null) {
          let customEvent = {
            id: 5,
            type: 'bid_approved_date',
            name: '(' + this.formatDateToMM_DD_YYYY(this.bidApprovedDateRange.from) + ' - ' + this.formatDateToMM_DD_YYYY(this.bidApprovedDateRange.to) + ')',
            value: 'Custom',
            dateRange: [this.bidApprovedDateRange.from, this.bidApprovedDateRange.to]
          }
          this.onDateChange.emit(customEvent);
        }
      } else if (type === 'ToDate') {
        this.bidApprovedDateRange.to = event;
        if (event !== null) {
          let customEvent = {
            id: 5,
            type: 'bid_approved_date',
            name: '(' + this.formatDateToMM_DD_YYYY(this.bidApprovedDateRange.from) + ' - ' + this.formatDateToMM_DD_YYYY(this.bidApprovedDateRange.to) + ')',
            value: 'Custom',
            dateRange: [this.bidApprovedDateRange.from, this.bidApprovedDateRange.to]
          }
          this.onDateChange.emit(customEvent);
        }
      }
    }

  }

  customDateRange(event: any, type: string, index: number, controlKey) {

    if (type == 'from') {
      this.customAttributesFilter[index].from = event;
    }

    if (type == 'to') {
      this.customAttributesFilter[index].to = event;
    }

    if (this.customAttributesFilter[index].to && this.customAttributesFilter[index].from) {

      const obj = {
        type: 'date',
        key: `custom_attributes.${controlKey.identifier}`,
        identifier: controlKey.identifier,
        name: controlKey.name,
        field: type,
        index: index,
        value: {
          type: "date",
          value: {
            custom: {
              from: this.customDateToMM_DD_YYYY(this.customAttributesFilter[index].from),
              to: this.customDateToMM_DD_YYYY(this.customAttributesFilter[index].to)
            },
            timeline: null
          }
        }
      }
      this.onCustomFiledChange.emit(obj);

    }
  }

  customDateToMM_DD_YYYY(date: Date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [month, day, year].join('/');
  }

  formatDateToMM_DD_YYYY(date: Date, joinType = '/') {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [month, day, year].join(joinType);
  }

  resetAppliedDate(type: any) {
    if (this.dateFilters.hasOwnProperty(type)) {
      if (type === 'date_created') {
        this.dateFilters[type] = null;
        this.dateCreatedDateRangeContainer = false;
        this.dateCreatedDateRange.from = null;
        this.dateCreatedDateRange.to = null;
        this.dateCreatedOpen = false;
      } else if (type === 'last_activity') {
        this.dateFilters[type] = null;
        this.lastActivityDateRangeContainer = false;
        this._lastActivityDateRange.from = null;
        this._lastActivityDateRange.to = null;
        this.lastActivityOpen = false;
      } else if (type === 'target_completion') {
        this.dateFilters[type] = null;
        this.targetCompletionDateRangeContainer = false;
        this.targetCompletionDateRange.from = null;
        this.targetCompletionDateRange.to = null;
        this.targetCompletionOpen = false;
      } else if (type === 'schedule') {
        this.dateFilters[type] = null;
        this.scheduleDateRangeContainer = false;
        this.scheduleDateRange.from = null;
        this.scheduleDateRange.to = null;
        this.scheduleOpen = false;
      } else if (type === 'jobcost_date_approved') {
        this.dateFilters[type] = null;
        this.jobCostApprovedDateRangeContainer = false;
        this.jobCostApprovedDateRange.from = null;
        this.jobCostApprovedDateRange.to = null;
        this.jobCostApprovedOpen = false;
      }
      else if (type === 'invoice_submitted_date') {
        this.dateFilters[type] = null;
        this.invoice_submitted_dateRangeContainer = false;
        this.invoiceSubmittedDateRange.from = null;
        this.invoiceSubmittedDateRange.to = null;
        // this.jobCostApprovedOpen = false;
      }
      else if (type === 'invoice_approved_date') {
        this.dateFilters[type] = null;
        this.invoice_approved_dateRangeContainer = false;
        this.invoiceApprovedDateRange.from = null;
        this.invoiceApprovedDateRange.to = null;
        // this.jobCostApprovedOpen = false;
      }
      else if (type === 'bid_submitted_date') {
        this.dateFilters[type] = null;
        this.bid_submitted_dateRangeContainer = false;
        this.bidSubmittedDateRange.from = null;
        this.bidSubmittedDateRange.to = null;
        this.bid_submitted_date_open = false;
      }
      else if (type === 'bid_approved_date') {
        this.dateFilters[type] = null;
        this.bid_approved_dateRangeContainer = false;
        this.bidApprovedDateRange.from = null;
        this.bidApprovedDateRange.to = null;
        this.bid_approved_date_open = false;
      } else {
        this.dateFilters[type] = null;
      }
    }

    this.onResetDate.emit(type);

  }

  resetCustomAppliedDate(type: string, identifier: string, index: number) {
    if (this.customAttributesFilter[index].showRange) {
      this.customAttributesFilter[index].showRange = false;
      this.customAttributesFilter[index].from = '';
      this.customAttributesFilter[index].to = '';
    }
    this.onResetDate.emit({ type, identifier });
  }

  resetCustomAppliedFilter(type: string, identifier: string) {
    this.onResetCustomFilter.emit({ type, identifier });
  }

  resetDate(): void {
    this.fromDateIsNotValid = false;
    this.toDateIsNotValid = false;
    this.fromDateLastActivity = false;
    this.toDateLastActivity = false;
    this.onResetDate.emit();
  }

  onLastActivityDateSelected(event: any, type: string) {
    if (type === 'from date') {
      this.lastActivityDateRange.from = event;
    } else if (type === 'to date') {
      this.lastActivityDateRange.to = event;
    } else if (this.equiment_filter) {
      this.lastActivityDateRange[type] = event;
    }

    if (this.lastActivityDateRange.from instanceof Date) {
      this.formatDateToMM_DD_YYYY(this.lastActivityDateRange.from) > this.formatDateToMM_DD_YYYY(this.today) ? this.fromDateLastActivity = true : this.fromDateLastActivity = false;
    }

    if (this.lastActivityDateRange.to instanceof Date) {
      this.formatDateToMM_DD_YYYY(this.lastActivityDateRange.to) > this.formatDateToMM_DD_YYYY(this.today) ? this.toDateLastActivity = true : this.toDateLastActivity = false;
    }

    this.onLastActivityDateChange.emit(this.lastActivityDateRange);
  }

  resetLastActivityDate(): void {
    this.onLatActivityResetDate.emit();
  }

  onDateApprovedSelected(event: any, type: string) {
    if (type === 'from date') {
      this.dateApprovedDateRange.from = event;
    } else if (type === 'to date') {
      this.dateApprovedDateRange.to = event;
    }
    this.onDateApprovedChange.emit(this.dateApprovedDateRange);
  }

  resetDateApprovedRange(): void {
    this.onDateApprovedResetDate.emit();
  }

  customFilter(event: any, key: any, type: string, i: number) {
    if (type == 'date' && !this.showNewCustomDateFilter) {
      if (event instanceof Date) {
        const obj = {
          type: key.inputType,
          key: `custom_attributes.${key.identifier}`,
          name: key.name,
          field: type,
          index: i,
          value: [format(new Date(event), 'yyyy-MM-dd')]
        }
        this.onCustomFiledChange.emit(obj);
      } else {
        if (event?.type === 'dateCleared') {
          const obj = {
            type: event.type,
            key: `custom_attributes.${key.identifier}`,
            identifier: key.identifier,
            name: key.name,
            field: type,
            index: i,
            value: ""
          }
          this.onCustomFiledChange.emit(obj);
        }
      }
    }

    if (type == 'date' && this.showNewCustomDateFilter) {
      if (event.name == 'Custom') {
        this.customAttributesFilter[i].showRange = true;
      } else {
        this.customAttributesFilter[i].showRange = false;
        const obj = {
          type: 'date',
          key: `custom_attributes.${key.identifier}`,
          identifier: key.identifier,
          name: key.name,
          field: type,
          index: i,
          value: {
            type: "date",
            value: {
              custom: null,
              timeline: event.value
            }
          }
        }
        this.onCustomFiledChange.emit(obj);
      }
    }
    if (type == 'text' || type == 'number' || type == 'float' || type == 'decimal') {
      const obj = {
        type: key.inputType,
        key: `custom_attributes.${key.identifier}`,
        name: key.name,
        field: type,
        index: i,
        value: [event.target.value]
      }
      this.onCustomFiledChange.emit(obj);
    }
    if (type == 'select') {
      let array = [event.name]
      const obj = {
        type: key.inputType,
        key: `custom_attributes.${key.identifier}`,
        name: key.name,
        field: type,
        index: i,
        value: array
      }
      this.onCustomFiledChange.emit(obj);
    }
    if (type == 'multiselect') {
      let array = [];
      event.forEach(element => {
        array.push(element.name)
      });
      const val = { "type": "multi", "value": array }
      const obj = {
        type: key.inputType,
        key: `custom_attributes.${key.identifier}`,
        name: key.name,
        field: type,
        index: i,
        value: val
      }
      this.onCustomFiledChange.emit(obj);
    }
    if (type == 'checkbox') {
      const val = { "value": event.name == 'YES' ? true : false, "type": "bool" };

      const obj = {
        type: key.inputType,
        key: `custom_attributes.${key.identifier}`,
        name: key.name,
        field: type,
        index: i,
        value: val
      }
      this.onCustomFiledChange.emit(obj);
    }
  }

  focusOutInput(event: any, type?: any) {
    // this.onFocusOut.emit(event);
    if (type == 'jobcost_amount') {
      let outputResponse = {
        type: 'jobcost_amount',
        jobcost_amount: this.dateFilters.jobcost_amount,
        name: 'Job cost amount: (' + this.dateFilters.jobcost_amount.from + ' - ' + this.dateFilters.jobcost_amount.to + ')'
      }
      if (this.dateFilters.jobcost_amount.from && this.dateFilters.jobcost_amount.to) {
        this.onJobCostAmountChange.emit(outputResponse);
      } else if (this.dateFilters.jobcost_amount.from == '' && this.dateFilters.jobcost_amount.to == '') {
        this.onJobCostAmountChange.emit(outputResponse);
      }
    }
    if (type == 'invoice_amount') {
      let outputResponse = {
        type: 'invoice_amount',
        jobcost_amount: this.dateFilters.invoice_amount,
        name: 'Invoice amount: (' + this.dateFilters.invoice_amount.from + ' - ' + this.dateFilters.invoice_amount.to + ')'
      }
      if (this.dateFilters.invoice_amount.from && this.dateFilters.invoice_amount.to) {
        this.onJobCostAmountChange.emit(outputResponse);
      } else if (this.dateFilters.invoice_amount.from == '' && this.dateFilters.invoice_amount.to == '') {
        this.onJobCostAmountChange.emit(outputResponse);
      }
    }
    if (type == 'bid_amount') {
      let outputResponse = {
        type: 'bid_amount',
        jobcost_amount: this.dateFilters.bid_amount,
        name: 'Bid amount: (' + this.dateFilters.bid_amount.from + ' - ' + this.dateFilters.bid_amount.to + ')'
      }
      if (this.dateFilters.bid_amount.from && this.dateFilters.bid_amount.to) {
        this.onJobCostAmountChange.emit(outputResponse);
      } else if (this.dateFilters.bid_amount.from == '' && this.dateFilters.bid_amount.to == '') {
        this.onJobCostAmountChange.emit(outputResponse);
      }
    } else if (type == 'inbox') {
      this.onInboxSubjectChange.emit(this.inboxSubject);
    } else if (type == 'jobcost_approval') {
      this.onScenarioNameChange.emit(this.jobCostScenario);
    }
  }

  changeInboxFolderType(event: any, inboxFolder: any): any {
    const payload = {
      name: inboxFolder.name,
      selectedValue: event
    };
    this.onInboxFolderValueChange.emit(payload);
  }

  inboxCheckboxValueChange(event: any, type: any) {
    const payload = {
      type: type,
      isSelected: event
    }
    this.onInboxCheckboxValueChange.emit(payload);
  }


  showSelectedFilters(selectedItems: any, key: any, type: string, i: number) {
    let selectedFiltersList = this.customAttributesFilter.filter((element) => element.listItems != undefined && element.identifier == key.identifier).map((element) => element.listItems.filter((subElement) => subElement.selected === true));

    let selectedFiltersObj: any = [];
    selectedFiltersList.forEach((element: any) => {
      selectedFiltersObj.push(...element);
    });

    let selectedFiltersListValue = selectedFiltersObj;
    const obj = {
      type: key.inputType,
      key: `custom_attributes.${key.identifier}`,
      name: key.name,
      field: type,
      index: i,
      value: selectedFiltersListValue.map(obj => obj.id)
    }
    this.onCustomFiledChange.emit(obj);
  }

  formatDateToISO(inputDate) {

    if (!inputDate) {
      return ''
    }

    if (inputDate instanceof Date) {
      return inputDate
    }

    const parts = inputDate.split('/');
    const date = new Date(`${parts[2]}-${parts[0]}-${parts[1]}`);
    const isoDateString = date.toISOString();
    return isoDateString;
  }

  customTextFilterChange(e: any, index) {
    this.customFilterTextSearch.emit({ ...e, index: index });
  }

  customTextFilterScrolled(e, index) {
    this.customFilterTextScroll.emit({ ...e, index: index });
  }

  /** Track by Index  **/
  trackByIndex(index: number, item: any): number {
    return index;
  }
}
