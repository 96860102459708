import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { UiService } from 'src/app/services/ui/ui.service';
import { UtilityService } from 'src/app/services/utility.service';
import { BaseService } from 'src/app/services/base.service';
import { MapServiceService } from 'src/app/services/Google_Map/map-service.service';
import { TicketFlowsService } from 'src/app/services/ticket-flows.service';

import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ExternalVendorService {

  origin: any = { 'lat': 0, 'lng': 0 };
  center: any = { 'lat': 0, 'lng': 0 };
  mapZoom: any;
  selectedExtVendor_AccountID: any;
  selectedExtVendorID: any;


  vendorJobInfo: any;
  vendorJobID: any;
  jobDetailVendorID: any;
  jobIDexv: any;
  exvJobDetails: any = {};
  approvedJobSiteVisit: any = {};
  acceptPassSection = false;
  scheduledSecton = false;
  siteVisitRescheduled: any;
  jobCostSection = false;
  completeClose = false;
  addMoreSchedule = false;
  addMoreScheduleBtn = false;
  addMoreJobCost = false;
  addMoreJobCostBtn = false;
  isCompletedSec = false;
  addJobCostSec = true;
  uploadPhotos: any[] = [];
  isCompletedStage: any = 'single-job';
  timelineData: any;
  allJobsData: any[] = [];
  allDashboardCount: any = {};
  allPropertyData: any[] = [];
  allOtherJobsPagination: any = {
    next: ''
  };
  shortingPrams: any = {
    sortColumn: 'id',
    sortBy: 'desc',
    defaultActiveTab: ''
  };

  vendorParamID: string;
  isLinkExpired: boolean = false;

  constructor(public _uiService: UiService, public _utilService: UtilityService, public _baseService: BaseService, public _Config: ConfigService,
    public _mapService: MapServiceService,
    public _ticketService: TicketFlowsService
  ) {
    this.mapZoom = this._Config.mapZoom;
  }

  public myLocation(): any {
    return this._mapService.getMyLocation();
  }

  public addressLocation(address: any): any {
    return this._mapService.getAddressLocation(address);
  }

  public showMap(source: any, destination: any): void {
    window.open('https://www.google.com/maps/dir/?api=1&origin=' + source.lat + ',' + source.lng + '&destination=' + destination.lat + ',' + destination.lng + '&travelmode=driving', '_blank');
  }

  getGeoLocation(address): void {
    this.center = this.addressLocation(address);
    this.origin = this.myLocation();
  }

  openDirections(): void {
    this.showMap(this.origin, this.center);
  }

  getJobVendorDetail(id?): void {
    if (id) {
      this.jobDetailVendorID = id;
    }


    this._Config.isLoader = true;
    let requestURL = environment.baseURL + this._Config.getSelectedExternalJobDetails;
    requestURL = requestURL.replace('{ID}', this.jobDetailVendorID);
    this.vendorJobID = id;
    this.siteVisitRescheduled = {};
    this._baseService.doGET(requestURL).subscribe((result: any) => {
      this._Config.isLoader = false;
      this.vendorJobInfo = result || {};
      this.selectedExtVendor_AccountID = result.account;
      this.selectedExtVendorID = result.vendor_id;

      this.getTimeline();
      if (this.vendorJobInfo?.site_visits['non_approved'].length > 0) {
        for (let index = 0; index < this.vendorJobInfo?.site_visits['non_approved'].length; index++) {
          const item = this.vendorJobInfo?.site_visits['non_approved'][index];
          item.forEach(element => {
            const fromDate = element._from.split(':');
            const toDate = element.to.split(':');
            const fromSplit = new Date().setHours(fromDate[0], fromDate[1], fromDate[2]);
            const toSplit = new Date().setHours(toDate[0], toDate[1], toDate[2]);
            element.date = new Date(element.date + ' ' + element._from);
            element._from = new Date(fromSplit);
            element.to = new Date(toSplit);
            if (element.status === 'Rescheduled') {
              this.siteVisitRescheduled = element;
            }
          });
        }
      }
      if (this.vendorJobInfo?.site_visits['approved'].length > 0) {
        for (let index = 0; index < this.vendorJobInfo?.site_visits['approved'].length; index++) {
          const item = this.vendorJobInfo?.site_visits['approved'][index];
          item.forEach(element => {
            const fromDate = element._from.split(':');
            const toDate = element.to.split(':');
            const fromSplit = new Date().setHours(fromDate[0], fromDate[1], fromDate[2]);
            const toSplit = new Date().setHours(toDate[0], toDate[1], toDate[2]);
            element.date = new Date(element.date + ' ' + element._from);
            element._from = new Date(fromSplit);
            element.to = new Date(toSplit);
            if (element.status === 'Rescheduled') {
              this.siteVisitRescheduled = element;
            }
          });
        }
      }
      if (this.vendorJobInfo?.schedule_jobs['non_approved'].length > 0) {
        for (let index = 0; index < this.vendorJobInfo?.schedule_jobs['non_approved'].length; index++) {
          const item = this.vendorJobInfo?.schedule_jobs['non_approved'][index];
          item.forEach(element => {
            const fromDate = element._from.split(':');
            const toDate = element.to.split(':');
            const fromSplit = new Date().setHours(fromDate[0], fromDate[1], fromDate[2]);
            const toSplit = new Date().setHours(toDate[0], toDate[1], toDate[2]);
            element.date = new Date(element.date + ' ' + element._from);
            element._from = new Date(fromSplit);
            element.to = new Date(toSplit);
          });
        }
      }
      if (this.vendorJobInfo?.schedule_jobs['approved'].length > 0) {
        for (let index = 0; index < this.vendorJobInfo?.schedule_jobs['approved'].length; index++) {
          const item = this.vendorJobInfo?.schedule_jobs['approved'][index];
          item.forEach(element => {
            const fromDate = element._from.split(':');
            const toDate = element.to.split(':');
            const fromSplit = new Date().setHours(fromDate[0], fromDate[1], fromDate[2]);
            const toSplit = new Date().setHours(toDate[0], toDate[1], toDate[2]);
            element.date = new Date(element.date + ' ' + element._from);
            element._from = new Date(fromSplit);
            element.to = new Date(toSplit);
          });
        }
      }
      if (this.vendorJobInfo?.job.photos?.pre_completion.length > 0) {
        const preCompPhotos = this.vendorJobInfo.job.photos.pre_completion;
        preCompPhotos.forEach(element => {
          element.type = this.getFileType(element.mime_type);
        });
      }
      if (this.vendorJobInfo?.job.photos?.post_completion.length > 0) {
        const postCompPhotos = this.vendorJobInfo.job.photos.post_completion;
        postCompPhotos.forEach(element => {
          element.type = this.getFileType(element.mime_type);
        });
      }
      if (this.vendorJobInfo.job.address.city) {
        this.getGeoLocation(this.vendorJobInfo.job.address.city);
      }
      if (this.vendorJobInfo.job.courtesy_job) {
        this.vendorJobInfo.job.responsible = 'Manager';
      }

      //Site Visit Status
      const cancelSiteVisit = [];
      this.vendorJobInfo.site_visits['non_approved'].forEach(element => {
        element.forEach(elementData => {
          if (elementData.status === 'Approved') {
            this.vendorJobInfo.approved_site_visit = elementData;
          }
          if (elementData.status === 'Rescheduled') {
            this.vendorJobInfo.approved_site_visit = elementData;
          }
          if (elementData.status === 'Cancelled') {
            cancelSiteVisit.push(elementData);
          }
        });
      });
      this.vendorJobInfo.site_visits['approved'].forEach(element => {
        element.forEach(elementData => {
          if (elementData.status === 'Approved') {
            this.vendorJobInfo.approved_site_visit = elementData;
          }
          if (elementData.status === 'Rescheduled') {
            this.vendorJobInfo.approved_site_visit = elementData;
          }
          if (elementData.status === 'Cancelled') {
            cancelSiteVisit.push(elementData);
          }
        });
      });
      this.vendorJobInfo.cancelled_site_visit = cancelSiteVisit;
      const cancelJobSchedule = [];
      this.vendorJobInfo.schedule_jobs['non_approved'].forEach(element => {
        element.forEach(elementData => {
          if (elementData.status === 'Approved') {
            this.vendorJobInfo.approved_scheduled_job = elementData;
          }
          if (elementData.status === 'Rescheduled') {
            this.vendorJobInfo.approved_scheduled_job = elementData;
          }
          if (elementData.status === 'Cancelled') {
            cancelJobSchedule.push(elementData);
          }
        });
      });
      this.vendorJobInfo.schedule_jobs['approved'].forEach(element => {
        element.forEach(elementData => {
          if (elementData.status === 'Approved') {
            this.vendorJobInfo.approved_scheduled_job = elementData;
          }
          if (elementData.status === 'Rescheduled') {
            this.vendorJobInfo.approved_scheduled_job = elementData;
          }
          if (elementData.status === 'Cancelled') {
            cancelJobSchedule.push(elementData);
          }
        });
      });
      this.vendorJobInfo.cancelled_schedule_job = cancelJobSchedule;
      this.getCurrentStage(this.vendorJobInfo.stage);
    }, (error) => {
      this.isCompletedStage = 'all-job';
      this._Config.isLoader = false;
      this.isLinkExpired = true;
      // this._utilService.showError('', 'This Job cannot be accessed');
    });
  }

  getCurrentStage(stage): void {
    if (stage === 'Created' || stage === 'Invited') {
      // btnText = "New Request";
      this.acceptPassSection = true;
      this.scheduledSecton = false;
      this.jobCostSection = false;
      this.completeClose = false;
    }
    else if (stage === 'Passed') {
      this.acceptPassSection = false;
      this.scheduledSecton = false;
      this.jobCostSection = false;
      this.completeClose = false;
      this._utilService.showError('', 'This Job is already Passed');
    }
    else if (stage === 'Cancelled') {
      // btnText = "Job Cancelled";
      this.acceptPassSection = false;
      this.scheduledSecton = false;
      this.jobCostSection = false;
      this.completeClose = false;
    }
    else if (stage === 'Completed By Vendor') {
      // btnText = "Job Completed";
      this.acceptPassSection = false;
      this.scheduledSecton = true;
      this.jobCostSection = true;
      this.completeClose = false;
      this.addMoreSchedule = true;
      this.addMoreScheduleBtn = false;
      this.addMoreJobCost = true;
      this.addMoreJobCostBtn = false;
      this.isCompletedSec = true;
    } else if (stage === 'Completed') {
      // btnText = "Job Completed";
      this.acceptPassSection = false;
      this.scheduledSecton = true;
      this.jobCostSection = true;
      this.completeClose = false;
      this.addMoreSchedule = false;
      this.addMoreScheduleBtn = false;
      this.addMoreJobCost = false;
      this.addMoreJobCostBtn = false;
      this.isCompletedSec = true;
      this.addJobCostSec = false;
    } else {
      this.acceptPassSection = false;
      this.scheduledSecton = true;
      this.jobCostSection = true;
      this.completeClose = false;
      if (stage === 'Accepted') {
        this.addMoreSchedule = true;
        this.addMoreScheduleBtn = false;
        this.addMoreJobCost = true;
        this.addMoreJobCostBtn = false;
      }
      else if (stage === 'Site Visit Cancelled') {
        this.addMoreSchedule = this.addMoreSchedule ? this.addMoreSchedule : false;
        // this.addMoreSchedule = true;
        this.addMoreScheduleBtn = true;
        this.addMoreJobCost = this.addMoreJobCost ? this.addMoreJobCost : false;
        this.addMoreJobCostBtn = false;
      } else {
        this.addMoreSchedule = false;
        this.addMoreScheduleBtn = true;
        this.addMoreJobCost = false;
        this.addMoreJobCostBtn = true;
      }
    }
  }

  getTimeline(): void {
    this._Config.isLoader = true;
    let requestURL = environment.baseURL + this._Config.timelineData;
    requestURL = requestURL.replace('{ID}', this.jobDetailVendorID);
    this._baseService.doGET(requestURL).subscribe(res => {
      this._Config.isLoader = false;
      this.timelineData = res || {};
      if (this.timelineData.site_visit?.from) {
        this.timelineData.site_visit.from = new Date('2000-01-01 ' + this.timelineData.site_visit.from);
      }
      if (this.timelineData.schedule?.from) {
        this.timelineData.schedule.from = new Date('2000-01-01 ' + this.timelineData.schedule.from);
      }
    },
      (error) => {
        this._Config.isLoader = false;
        this._utilService.showErrorMessage(error);
      }
    );
  }

  getFileType(type: any): any {
    let fileType = 'image';
    if (type.includes('image')) {
      fileType = 'image';
    }
    else if (type.includes('audio')) {
      fileType = 'audio';
    }
    else if (type.includes('video')) {
      fileType = 'video';
    }
    else if (type.includes('application/pdf')) {
      fileType = 'pdf';
    }
    return fileType;
  }

  getExvJobInfo(jobID): void {
    if (jobID) {
      this.jobIDexv = jobID;
    }
    this._Config.isLoader = true;
    let requestURL = environment.baseURL + this._Config.exvUploadPhotos;
    requestURL = requestURL.replace('{VendorID}', this.jobIDexv);
    this._baseService.doGET(requestURL).subscribe((result: any) => {
      this._Config.isLoader = false;
      this.exvJobDetails = result || {};
    }, (error) => {
      this._Config.isLoader = false;
    });
  }

  getAllJobsData(isPagination?: any, filter?: any, property?: any, isShorting?: any): void {
    this._Config.isLoader = true;
    let vendorID = this.isLinkExpired ? this.vendorParamID : this.jobDetailVendorID
    let requestURL;
    this.shortingPrams.defaultActiveTab = filter ? filter : '';
    if (isPagination) {
      requestURL = this.allOtherJobsPagination.next;
    } else {
      if (filter && property) {
        requestURL = environment.baseURL + this._Config.getAllVendorJobsPropFilter;
        requestURL = requestURL.replace('{ID}', vendorID);
        requestURL = requestURL.replace('{Data}', filter);
        requestURL = requestURL.replace('{PID}', property);
      } else if (filter) {
        requestURL = environment.baseURL + this._Config.getAllVendorJobsFilter;
        requestURL = requestURL.replace('{ID}', vendorID);
        requestURL = requestURL.replace('{Data}', filter);
      } else if (property) {
        requestURL = environment.baseURL + this._Config.getAllVendorPropFilter;
        requestURL = requestURL.replace('{ID}', vendorID);
        requestURL = requestURL.replace('{PID}', property);
      } else {
        requestURL = environment.baseURL + this._Config.getAllVendorJobs;
        requestURL = requestURL.replace('{ID}', vendorID);
      }
      if (isShorting) {
        let searchParams = '';
        if (filter || property) {
          searchParams = '&column=' + this.shortingPrams.sortColumn + '&order=' + this.shortingPrams.sortBy;
        } else {
          searchParams = '?column=' + this.shortingPrams.sortColumn + '&order=' + this.shortingPrams.sortBy;
        }
        requestURL = requestURL + searchParams;
      }
    }
    this._baseService.doGET(requestURL).subscribe((result: any) => {
      this._Config.isLoader = false;
      const resultAllJobsData = result.results || [];
      if (isPagination) {
        this.allJobsData.push(...resultAllJobsData);
      } else {
        this.allJobsData = resultAllJobsData || [];
      }
      this.allOtherJobsPagination.next = result.next ? result.next : '';
    }, (error) => {
      this._Config.isLoader = false;
    });
  }

  getAllDashboardCount(): void {
    let requestURL = environment.baseURL + this._Config.getAllDoshboardCount;
    let vendorID = this.isLinkExpired ? this.vendorParamID : this.jobDetailVendorID
    requestURL = requestURL.replace('{ID}', vendorID);
    this._baseService.doGET(requestURL).subscribe((result: any) => {
      this.allDashboardCount = result;
    }, (error) => { });
  }

  getAllProperty(): void {
    let requestURL = environment.baseURL + this._Config.getAllProperty;
    let vendorID = this.isLinkExpired ? this.vendorParamID : this.jobDetailVendorID

    requestURL = requestURL.replace('{ID}', vendorID);
    this._baseService.doGET(requestURL).subscribe((result: any) => {
      this.allPropertyData = result;
    }, (error) => { });
  }
}
