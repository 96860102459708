import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EquipmentsService {

  currentUserInfo: any = localStorage.getItem('userobj')
  ? JSON.parse(localStorage.getItem('userobj'))
  : {};

  constructor(private httpClient:HttpClient ) { }


  get add_equipment(): Boolean {
    return this.currentUserInfo.permissions.some(e=>e === 'add_equipment') || this.currentUserInfo.types.includes("Engineer");
  }

  get add_equipment_type(): Boolean {
    return this.currentUserInfo.permissions.some(e=>e === 'add_equipment_type') || this.currentUserInfo.types.includes("Engineer");
  }

  get add_additional_data_item(): Boolean {
    return this.currentUserInfo.permissions.some(e=>e === 'add_additional_data_item') || this.currentUserInfo.types.includes("Engineer");
  }

  get change_equipment(): Boolean {
    return this.currentUserInfo.permissions.some(e=>e === 'change_equipment') || this.currentUserInfo.types.includes("Engineer");
  }

  get change_equipment_type(): Boolean {
    return this.currentUserInfo.permissions.some(e=>e === 'change_equipment_type') || this.currentUserInfo.types.includes("Engineer");
  }

  get change_additional_data_item(): Boolean {
    return this.currentUserInfo.permissions.some(e=>e === 'change_additional_data_item') || this.currentUserInfo.types.includes("Engineer");
  }

    /**
   *  PATCH Method
   */
    doPATCH(endpoint_URL: string, postBody: any , Options?: any): Observable<any> {
      return this.httpClient.patch(`${environment.equipURL + '/' + endpoint_URL}`, postBody ,Options);
    }


     /**
   * Get Default Active tab Data
   * @param tabList
   * @returns
   */
  getDefaultActiveVerticalMenuID(tabList: any) {
    let activeMenu: any = {};

    let activeTabList = tabList.filter(obj => obj.menus.some((item: { default: boolean; }) => item.default == true)).map(obj => { return { activeItem: obj.menus.find((item: { default: boolean; }) => item.default == true), type: obj.type, activeIndex: obj.menus.findIndex((item: { default: boolean; }) => item.default == true) } });

    if (activeTabList.length > 0) {
      activeMenu = activeTabList[0];
    }

    if(activeMenu.activeItem==undefined){
      if(tabList.length>0 && tabList[0]?.menus?.length>0){
        tabList[0].menus[0].default=true
        activeMenu=tabList[0].menus[0];
      }
    }

    return activeMenu.activeItem;
  }
}
