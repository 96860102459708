import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {DialogService, ModalData} from 'src/app/@fyxt/_services/dialog/dialog.service';

import { TableColumnTypes,ColumnSortType } from 'src/app/@fyxt/_shared/enums/table/table-column-types.enum';
import { AppTableSource } from 'src/app/@fyxt/_shared/models/app-table-model';

import {EditColumn, EditColumnsComponent, EditColumnsModalData} from 'src/app/@fyxt/_shared/_views';
import {BehaviorSubject} from "rxjs";
import {switchMap, take} from "rxjs/operators";
import { TableComponent } from '../../../_reusable_ui/_components/table/table.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { NgIf } from '@angular/common';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';
@Component({
    selector: 'fyxt-tab-properies',
    templateUrl: './tab-properies.component.html',
    styleUrls: ['./tab-properies.component.scss'],
    standalone: true,
    imports: [SearchInputComponent, NgIf, ButtonComponent, MatIconModule, MatDividerModule, LoaderSmallComponent, TableComponent]
})
export class TabProperiesComponent implements OnInit {
  private readonly editableColumns$: BehaviorSubject<any[]> = new BehaviorSubject<EditColumn[]>([]);
  @Input() dataSource!: AppTableSource;
  @Input() propertySearchValue!: string;
  @Input() isLoader!: boolean;
  @Input() propertyEditColumnView!: [];
  @Input() moduleName:string='';
  @Input() viewModule: string;
  @Input() isReadonly: boolean = false;

  @Output() onSearchValueChange = new EventEmitter<Event>();
  @Output() onSortValueChange = new EventEmitter<Event>();
  @Output() onPaginationValueChange = new EventEmitter<Event>();
  @Output() onEditColumnChange = new EventEmitter();
  @Output() onRowClickValueChange = new EventEmitter();
  @Output() onClickAssignProperty = new EventEmitter();
  @Output() onThreeDotMenuChange = new EventEmitter<Event>();

  constructor(
    public _dialogService: DialogService,
  ) { }

  ngOnInit(): void {
  }

  ngAfterContentChecked(): void {
    //Called after every check of the component's or directive's content.
    //Add 'implements AfterContentChecked' to the class.
    if (this.isReadonly) {
      if (this.dataSource?.headers) {
        this.dataSource.headers = this.dataSource.headers.filter(obj => !(obj.value == 'action')).map(obj => {
          return obj;
        })
      }
    }

  }

  checkNormalTable(){
    if(this.viewModule=='Tenant-portal'){
      return true;
    }

    return false;
  }


  editColumnPopup() {
    this.editableColumns$.next(this.propertyEditColumnView);
    this.editableColumns$
      .pipe(
        take(1),
        switchMap((columns) => {
          let popupData: ModalData<EditColumnsModalData> = {
            title: 'Edit Table Column',
            component: EditColumnsComponent,
            containerClass: ['modal-sm', 'modal_edit_colomn'],
            data: {
              columns,
              onChange: this.editColumnOrder.bind(this)
            }
          };
          return this._dialogService.openModal<EditColumnsModalData>(popupData);
        })
      )
      .subscribe(result => {});
    // let popupData = {
    //   title: 'Edit Table Column',
    //   component: EditColumnsComponent,
    //   containerClass: ['modal-sm', 'modal_edit_colomn']
    // };
    //
    // this._dialogService.openModal(popupData).subscribe(result => {
    // });
  }

  editColumnOrder(columnLists: EditColumn[]): void {
    this.onEditColumnChange.emit(columnLists);
  }

  searchProperties(value: any): void {
    this.onSearchValueChange.emit(value);
    this.propertySearchValue = value;
  }

  propertiesSortChange(sortState: any) {
    this.onSortValueChange.emit(sortState);
  }

  onPageChange(paginationEvent: any) {
    this.onPaginationValueChange.emit(paginationEvent);
  }

  onRowClick(event: any): void {
    this.onRowClickValueChange.emit(event);
  }

  assignProperty(): void {
    this.onClickAssignProperty.emit();
  }

  threeDotMenuChange(event: any) {
    this.onThreeDotMenuChange.emit(event);
  }
}
