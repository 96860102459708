// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-layout {
  display: flex;
  padding-top: 10px;
}

::ng-deep .custom-dialog-container .mat-mdc-dialog-container {
  padding: 0 !important;
}

.center {
  height: 55vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.search-box-container {
  display: flex;
}
.search-box-container .search-label {
  align-self: center;
  padding-right: 15px;
}
.search-box-container fyxt-search-input {
  flex: 1;
}

.cardHeader {
  border-bottom: 1px solid #E6E8F0;
  padding: 24px;
}

.folder-tree {
  cursor: pointer;
  font-size: 14px;
}

.all-file {
  font-size: 14px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::ng-deep .mat-mdc-menu-panel.custom-menu {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 8px;
  max-height: 150px;
}

.gap-10 {
  gap: 10px;
}

.danger {
  color: red;
  margin-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_views/_tab_components/tab-files/tab-files.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAIA;EACE,uBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AADF;;AAIA;EACE,aAAA;AADF;AAGE;EACE,kBAAA;EACA,mBAAA;AADJ;AAIE;EACE,OAAA;AAFJ;;AAMA;EACE,gCAAA;EACA,aAAA;AAHF;;AAMA;EACE,eAAA;EACA,eAAA;AAHF;;AAMA;EACE,eAAA;AAHF;;AAMA;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;AAHF;;AAMA;EACE,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;AAHF;;AAMA;EACE,SAAA;AAHF;;AAMA;EACE,UAAA;EACA,eAAA;AAHF","sourcesContent":[".btn-layout {\n  display: flex;\n  padding-top: 10px;\n}\n\n::ng-deep .custom-dialog-container .mat-mdc-dialog-container {\n  padding: 0 !important;\n}\n\n\n\n.center {\n  height: 55vh !important;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n.search-box-container {\n  display: flex;\n\n  .search-label {\n    align-self: center;\n    padding-right: 15px;\n  }\n\n  fyxt-search-input {\n    flex: 1;\n  }\n}\n\n.cardHeader {\n  border-bottom: 1px solid #E6E8F0;\n  padding: 24px;\n}\n\n.folder-tree {\n  cursor: pointer;\n  font-size: 14px;\n}\n\n.all-file {\n  font-size: 14px;\n}\n\n.truncate {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n::ng-deep .mat-mdc-menu-panel.custom-menu {\n  overflow-x: hidden;\n  overflow-y: auto;\n  padding: 8px;\n  max-height: 150px;\n}\n\n.gap-10 {\n  gap: 10px;\n}\n\n.danger {\n  color: red;\n  margin-top: 5px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
