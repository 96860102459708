import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { UtilityService } from 'src/app/services/utility.service';
import { ConfigService } from 'src/app/services/config.service';
import { environment } from 'src/environments/environment';

import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MustMatch } from 'src/app/shared/must-match.validator';
import { KeycloakService } from "keycloak-angular";
import { CheckboxComponent } from '../../../../@fyxt/_shared/_reusable_ui/_controls/checkbox/checkbox.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-onboard',
    templateUrl: './onboard.component.html',
    styleUrls: ['./onboard.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgxIntlTelInputModule, NgClass, CheckboxComponent, RouterLink]
})
export class OnboardComponent implements OnInit {

  public ManagerActivationForm: UntypedFormGroup;
  public vendorActivationForm: UntypedFormGroup;
  public submitted: boolean = false;

  type: any = "";
  token: any = "";
  step: number = 1;
  showError: boolean = false;
  hide: boolean = true;
  termspolicy: boolean = false;

  constructor(
    public _baseService: BaseService,
    public _utilService: UtilityService,
    public _Config: ConfigService,
    public formbuilder: UntypedFormBuilder,
    public activatedRoute: ActivatedRoute,
    private readonly keycloakService: KeycloakService,
  ) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(params => {


      this.type = params.uid;
      this.token = params.token;

      this.getUserInfo();
    });



    this.ManagerActivationFormInit();
    this.VendorActivateFormInit();
  }

  ManagerActivationFormInit() {
    return this.ManagerActivationForm = this.formbuilder.group({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
      cpassword: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
      first_name: new UntypedFormControl('', [Validators.required]),
      last_name: new UntypedFormControl('', [Validators.required]),
      recovery_email: new UntypedFormControl('', [Validators.email]),
      phone: new UntypedFormControl(''),
      enable_sms: new UntypedFormControl(false)
    },
      {
        validator: MustMatch('password', 'cpassword')
      });
  }

  VendorActivateFormInit() {
    return this.vendorActivationForm = this.formbuilder.group({
      Password: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
      CPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
      FirstName: new UntypedFormControl('', [Validators.required]),
      LastName: new UntypedFormControl('', [Validators.required]),
      RecoveryEmail: new UntypedFormControl('', [Validators.email]),
      PhoneNumber: new UntypedFormControl('', [Validators.required]),
      CompanyName: new UntypedFormControl('', [Validators.required]),
      Category: new UntypedFormControl('', [Validators.required]),
    },
      {
        validator: MustMatch('Password', 'CPassword')
      });
  }

  // get Manager ActivationForm form control for using html validation
  get managerControl() { return this.ManagerActivationForm.controls; }

  // get Manager ActivationForm form control for using html validation
  get vendorControl() { return this.vendorActivationForm.controls; }


  doFormSubmit() {
    this.submitted = true;
    if (this.ManagerActivationForm.invalid) {

      if (!this.ManagerActivationForm.value.password || !this.ManagerActivationForm.value.cpassword || !(this.ManagerActivationForm.value.password == this.ManagerActivationForm.value.cpassword)) {
        this.submitted = true;
        return;
      }
      else if (!this.ManagerActivationForm.value.first_name || !this.ManagerActivationForm.value.last_name || !this.ManagerActivationForm.value.recovery_email) {
        return;
      }
    }
    let postBody = {
      "token": this.token,
      "first_name": this.ManagerActivationForm.value.first_name,
      "last_name": this.ManagerActivationForm.value.last_name,
      "email": this.ManagerActivationForm.value.email,
      "phone": this.ManagerActivationForm.value.phone,
      "recovery_email": this.ManagerActivationForm.value.recovery_email ? this.ManagerActivationForm.value.recovery_email : null,
      "password": this.ManagerActivationForm.value.password,
      "enable_sms": this.ManagerActivationForm.value.enable_sms
    };

    this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.userOnboardingFinal;

    this._baseService.doPUT(requestURL, postBody).subscribe((result: any) => {

      this._utilService.showSuccess('', 'You have successfully created your account');
      this.doLogin(postBody);

    },
      (error) => {

        this._Config.isLoader = false;
        this._utilService.showErrorMessage(error);
      });

  }




  /**
* Get All User Roles
*/
  getUserInfo() {

    this._Config.isLoader = true;

    let postBody = {
      "token": this.token
    };


    let requestURL = environment.baseURL + this._Config.getOnboardingUserInfo;

    this._baseService.doPOST(requestURL, postBody).subscribe((result: any) => {

      this._Config.isLoader = false;

      this.ManagerActivationForm.patchValue(result);

      this.showError = false;

    },
      (error) => {
        const errorDetails = error.error;
        const excludeErrorCode = 'TOKEN_INVALID';
        if (!(errorDetails && errorDetails.code && errorDetails.code === excludeErrorCode)) {
          this._utilService.showErrorMessage(error);
        }

        this.showError = true;
        this._Config.isLoader = false;
      });


  }


  ShowPassword() {
    this.hide = !this.hide;
  }

  ShowConfirmPassword() {
    this.hide = !this.hide;
  }


  /********** To Login Form Submit ***************/
  doLogin(userInfo: any) {

    let postBody = {
      "email": userInfo.email,
      "password": userInfo.password,
    };

    this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.doLogin;

    this._baseService.doPOST(requestURL, postBody).subscribe((result: any) => {

      this._Config.isLoader = false;

      this._baseService.goToDashboard(result);

    },
      (error) => {
        this._Config.isLoader = false;
        this._utilService.showErrorMessage(error);
      }
    );



  }

  scroll(tabID: any) {
    setTimeout(() => {
      document.querySelector(tabID).scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 200);
  }


  slideToggleChange(event: any) {
    this.termspolicy = event;
  }

  getLoginUrl(): string {
    const keycloakInstance = this.keycloakService.getKeycloakInstance();

    const url = keycloakInstance.createLoginUrl({
      redirectUri: window.location.origin + '/login'
    });

    const searchParams = new URLSearchParams();
    searchParams.append('hideForgotPassword', '1');
    const urlWithQuery = `${url}&${searchParams.toString()}`;

    return urlWithQuery;
  }
}
