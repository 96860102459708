import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'activityFilter',
    standalone: true
})
export class ActivityFilterPipe implements PipeTransform {

  transform(input: string, type: string): string {


    if (type == 'PROPERTY') {
      switch (input) {
        case 'management_agreement_limit':
          return "Management Agreement Limit";
        case 'type':
          return "Type";
        case 'property_type':
          return "Property Type";
        case 'name':
          return "Name";
        case 'lease_type':
          return "Lease Type";
        case 'type_of_tenant':
          return "Type of Tenant";
        case 'total_square_feet':
          return "Total Square Feet";
        case 'owner':
          return "Owner";
        case 'property_active':
          return "Property Active";
        case 'categories':
          return "Categories";
        default:
          return input;
      }
    };
    if (type == 'JOB_SCHEDULE') {
      switch (input) {
        case 'status_changed_on':
          return "Status Changed On";
        case 'status':
          return "Status";
        case 'stage':
          return "Stage";
        case 'to':
          return "To";
        case 'rescheduled':
          return "Rescheduled";
        case 'rescheduled_on':
          return "Rescheduled On";
        case '_from':
          return "From";
        case 'labor_time':
          return "Labor Time";
        case 'equipment':
          return "Equipment";
        case 'engineer':
          return "Engineer";
        case 'trip_charge':
          return "Trip charge";
        case 'overtime_rate':
          return "Overtime rate";
        case 'rejected_reason':
          return "Rejected Reason";  
        case 'vendor_name':
          return "Vendor Name";
        default:
          return input;
      }

    }
    if(type == 'COMPANY' || type == 'CONTACT'){
      switch (input) {
        case 'addresses':
          return "Address";
        case 'phones':
          return "Phone";
        case 'emails':
          return "Email";
        case 'primary_email':
          return "Primary Email";
        case 'primary_phone':
          return "Primary Phone";
        case 'rescheduled_on':
          return "Rescheduled On";
        case 'type':
          return "Type";
        case 'name':
          return "Name";
        case 'primary_company':
          return "Primary Company"
        default:
          return input;
      }
    }
    if(type == 'EQUIPMENT' || type == 'EQUIPMENT_TYPE' || type == 'EQUIPMENT_ADDITIONAL_DATA'){
      switch (input) {
        case 'asset_tag':
          return "Asset Tag";
        case 'serial_number':
          return "Serial Number";
        case 'property_name':
          return "Property Name";
        case 'model_name':
          return "Model Name";
        case 'manufacturer_name':
          return "Manufacturer Name";
        case 'category_name':
          return "Category Name";
        case 'equipment_type_name':
          return "Equipment type name";
        case 'model_name':
          return "Model Name";
        case 'manufacturer_name':
          return "Manufacturer name";
        case 'name':
          return "Name";
        default:
          return input;
      }
    }
    if(type == 'JOB_SITE_VISIT'){
      switch (input) {
        case 'date':
          return "Date";
        case '_from':
          return "From";
        case 'to':
          return "To";
        case 'rescheduled':
          return "Rescheduled";
        case 'status':
          return "Status";
        case 'stage':
          return "Stage";
        default:
          return input;
      }
    }
    if(type == 'JOB'){
      switch (input){
        case 'vendor_job_status':
          return 'vendor job status'
        case 'job_status':
          return 'job status'
        case 'job_stage':
          return 'job stage'
        case 'linked_jobs':
          return 'linked jobs'
        case 'vendor_name':
          return 'Vendor Name'
        case 'stage':
          return 'Status'
        case 'reason':
          return 'Reason'
        default:
          return input;
      }
    }

    if(type == 'CUSTOM_ATTRIBUTES_FIELDS'){
      const parts = input.replace('x_', '').split('_');
      return parts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
    }

    if(type == 'CLONE') {
      switch (input) {
        case 'DRAFT_JOB':
          return "Draft Job";
        default:
          return input;
    }
    }
  }
}



