import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Config2Service {

  isLoader: boolean = false;

  doLogin: any = "login/";
  logout: any = "logout/";

  /********** Dashboard Tabs ***********/
  allTabsList: any = "user-views/dashboard_views/";
  modifyColumns: any = "user-views/{ViewID}/column/";
  getAllJobs = "jobs/?filter=open";
  getDropdownList: any = "user-views/dropdown/";
  getViewDetailColumns: any = "user-views/{ID}/column/";
  tabDropdown: any = "user-views/{ID}/";
  createView: any = "user-views/";
  saveCurrentTabView: any = "user-views/{View_ID}/";
  get_BulkAction_Lists: any = "user-dashboard/{View_ID}/bulk_actions/";

  /********** Dashboard Job Listings ***********/
  allJobListing: any = 'user-dashboard/{ID}/job/';
  sortJobListing: any = 'user-dashboard/{ID}/job/?column={name}&order={order}';
  getViewInfo: any = 'user-views/{ID}/';
  getViewColumns: any = 'user-views/{ID}/column/';
  resetJobTableColumn: any = 'user-views/{ID}/column/';
  resetView: any = 'user-views/{ID}/';
  getSelectedJobDetails = "jobs/{ID}/";
  getAllJobEmails = "jobs/{ID}/mails/";

  /********** Filters ***********/
  getAllManagers = "jobs/assignees/";
  getAllEngineers = "jobs/engineers/";
  getAllProperties = "jobs/properties/";
  getAllTenants = "jobs/tenants/";
  getAllCategories = "jobs/categories/";
  getAllFollowers = 'jobs/followers/';
  getAllVendors = 'jobs/vendors/';
  getAllCreatedBy = 'jobs/created_by';
  getAllRecentJobTenants = 'jobs/{ID}/property-tenants/';
  getAllPriority = "jobs/priorities/";
  getAllStagesToJob = 'job/stage/?type=dashboard';
  
  /********** Advanced Filters ***********/
  getAdvancedFilterList = 'job/advanced-filter/';


  /********** Bulk actions ***********/
  bulkAction = 'jobs/bulk-actions/';
  getAdvanceFilterList = 'job/advanced-filter/{ID}/detail-filter/';
  getAdvanceFilterOwnersList = 'jobs/owners/';
  getAdvanceFilterCreatedByList = 'jobs/created_by/';

  /******* View Job Feedback ******/
  getAllJobsFeedback = "jobs/{ID}/feedback-form/";

  /******* View Job Recent Job ******/
  getAllRecentJobs = "properties/{ID}/recent-job/";
  getAllRecentFilter = "jobs/related-jobs-filter/?filter=open&query={query}&exclude_job={JobID}";
  getAllLocationUnitsByProperty = "properties/{Property_ID}/units/?type=units";


  /********** New Job Info Page ***********/
  getAllCategoriesByProperty = "properties/{ID}/categories/";
  serviceTypeDropdown = 'jobs/service-type/';
  getLocationAllUnitsByProperty = "properties/{ID}/units/";
  updateTicket = "jobs/{ID}/";
  checkEnggPermission = "jobs/{ID}/engineer_permission";

  /********** Socket URL's  ***********/
  getNewDashboardJobs_Live = "dashboard/row/{AccountID}/{UserID}/{View_ID}/";
  bulkAction_triggers = "bulk/trigger/{AccountID}/{UserID}/";
  bulkAction_triggeredIds = "bulk/{AccountID}/{UserID}/";
  getAllCounts_jobInfoProposals = "dashboard/job-info/count/{UserID}/{JobID}/";
  trigger_NewJobActions= "actions-trigger/{UserAccountID}/";
  getAllActivities_Live= "job/log/{UserAccountID}/{UserID}/{JobID}/";
  jobInfo_Update_Triggers ='dashboard/job-info/trigger/{AccountID}/{UserID}/';
  getNotes_Live = "dashboard/job-info/notes/{UserID}/{JobID}/";
  addNotes_Triggers ='notes/trigger/{AccountID}/{UserID}/';
  disconnectNotes_Live= "dashboard/job-info/notes/{UserID}/{JobID}/";
  getChecklist_Live = "checklist/{AccountID}/{UserID}/{JobID}/";
  addChecklist_Triggers ='checklist/trigger/{AccountID}/{UserID}/';
  disconnectChecklist_Live= "checklist/{AccountID}/{UserID}/{JobID}/";
  getTabs_NewCounts ='dashboard/count/{AccountID}/{UserID}/';
  all_Job_Actions= "job/{AccountID}/{UserID}/{JobID}/";

    /********** Table Column Resized  ***********/
    update_resize_column = "user-views/{ViewID}/column_resize/";

  constructor() { }


}
