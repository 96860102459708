// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-box-container {
  width: 267px;
}

.loading_container {
  min-height: 50vh;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_views/_tab_components/tab-coverage/tab-coverage.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".search-box-container{\n    width: 267px;\n  }\n\n.loading_container {\n  min-height: 50vh;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
