export interface FileForm {
  id: string;
  title: string;
  tags: any[];
  description: string;
  file_name: string;
  mime_type: string;
  source: string;
  created_at: string;
  created_by: string;
  preview_url: string;
  url: string;
}

export enum FilesFormats {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  GIF = 'image/gif',
  VIDEO = 'video/mp4',
  AUDIO = 'audio/mpeg',
  PDF = 'application/pdf',
  JPG = 'image/jpg',
}

export enum FileUploadTypes {
  DIRECT = 'DIRECT',
  URL = 'URL'
}

