import {Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import {count} from 'rxjs/operators';
import { UtilityService } from 'src/app/services/utility.service';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule, NgClass, NgIf } from '@angular/common';
import { MaterialModule } from '../../../material/material.module';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';

@Component({
    selector: 'fyxt-textbox',
    templateUrl: './textbox.component.html',
    styleUrls: ['./textbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => TextboxComponent)
        }
    ],
    standalone: true,
    imports: [CommonModule,NgClass, NgIf, MatFormFieldModule, MatIconModule, MatInputModule, FormsModule, NgxIntlTelInputModule,MaterialModule,NgxMaskDirective,
      NgxMaskPipe]
})
export class TextboxComponent implements OnInit, ControlValueAccessor {
  @Input() disable: boolean = false;
  @Input() label: any;
  @Input() Error: any;
  @Input() minValue: number = 0;
  @Input() maxLength: number = 0;
  @Input() Type: string = 'text';
  @Input() placeHolder!: string;
  @Input() readonly: boolean = false;
  @Input() required: boolean = false;
  @Input() amountMask: boolean = false;
  @Input() numericOnly: boolean = false;
  @Input() customClass: string = '';
  @Input() isSeparatorCount: boolean = false;
  @Input() isNotAllowSpecialChar: boolean = false;
  @Input() isFocusOut: boolean = true;
  @Input() allowDecimalCount: number = 0;
  @Input() isCustomAttribute: boolean = false;
  @Input() offFocus: boolean = false;
  @Input() isLink: boolean = false;
  @Input() showRequiredStar: boolean = false;
  @Input() amountInput: boolean = false;
  amountSeparator: string = '';
  amountPrefix: string = '';
  thousandSeparator: string = '';
  decimalMarker: string = '';
  @Output() onValueChange = new EventEmitter<Event>();
  @Output() onFocusOut = new EventEmitter<Event>();
  @Output() onSelect = new EventEmitter<any>();

  @ViewChild('textBox', { static: false }) inputElement: ElementRef;

  constructor(
    public _utilService: UtilityService,
  ) {
  }

  @Input() input: any;
  onSelectInputValue(){
  this.onSelect.emit(this.inputElement)
  }
  ngOnInit(): void {
    if (this.amountMask) {
      this.amountSeparator = 'separator.2';
      this.amountPrefix = '$ '
    }

    if (this.isSeparatorCount) {
      this.amountSeparator = 'separator';
      this.thousandSeparator = ',';
      // this.decimalMarker = '.';
      // thousandSeparator="." decimalMarker=","
    }
  }

  onChange = (data: any) => {
  }
  onTouch = (_: any) => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: string) {
    this.input = value;
    this.onChange(value);
  }

  /**
   *Func triggered on input change
   */
  onchange(event: any) {
    this.input = event;
    this.onChange(event);
    this.onValueChange.emit(event);
  }

  focusInput() {}

  focusOutInput(event: any) {
    if (this.offFocus) return;
    this.onFocusOut.emit(event);
  }


  public omit_special_char(e: any) {
      if (this.isNotAllowSpecialChar) {
        try {
          let k;
          if (/^[a-zA-Z0-9\s]*$/.test(e.key)) {
            if (this.Type == 'number') {
              if (e.keyCode == 69 || e.keyCode == 101) {
                return false;
              }
            } else {
              return true;
            }
          } else {
            e.preventDefault();
            return false;
          }
        } catch (e) {
        }
      }
      if (this.allowDecimalCount > 0) {
         const reg = /^-?\d*(\.\d{0,4})?$/;
         const value = (e.target.value ?? '').replaceAll(',', '');
         let input = value + String.fromCharCode(e.charCode);
         if (!reg.test(input)) {
            e.preventDefault();
        }
      }
      if (this.isCustomAttribute) {
        if (/[^a-zA-Z_]/g.test(e.key)) {
          e.preventDefault();
        }
      }
  }

  public onKeyDown(event: any): void {
    if (this.isCustomAttribute) {
      if (event.key === 'Backspace' && event.target?.value == 'x_') {
        event.preventDefault();
      }
    }
  }

  protected labelOnClick(): void {
    if (!this.isLink) return
    if(this.isValidUrl(this.input)) {
      window.open(this.input);
    }
  }

  isValidUrl(string) {
    const urlRegex = /^(https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/;
    const result = string.match(urlRegex);
    if(result == null) {
      return false;
    } else {
      return true;
    }
  }
}
