// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep.customLabel {
  font-weight: 400 !important;
  font-size: 14px !important;
}

.marginminus {
  margin-top: -10px;
}`, "",{"version":3,"sources":["webpack://./src/app/Modules/shared/components/job-modals/sharing-viewers-modal/creat-contact-shared/creat-contact-shared.component.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;EACA,0BAAA;AACJ;;AAEA;EACI,iBAAA;AACJ","sourcesContent":["::ng-deep.customLabel{\n    font-weight: 400 !important;\n    font-size: 14px !important;\n}\n\n.marginminus {\n    margin-top: -10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
