// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-header-title {
  color: #192c49;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 500;
}

.filter-border {
  max-height: 25vh;
  overflow-y: auto;
}

.flex-filter-chips {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: wrap;
}

.preferences-title {
  font-size: 16px;
  font-weight: 500;
}

.preferences-border {
  border: 1px solid #E7E8F0;
  border-radius: 4px;
}

.hw14 {
  height: 14px;
  width: 14px;
}

.ml5 {
  margin-left: 5px;
}

.mt5 {
  margin-top: 5px;
}

.pl12 {
  padding-left: 12px;
}

.pr12 {
  padding-right: 12px;
}

.desc-color {
  color: #606D81;
}

.mt12 {
  margin-top: 12px;
}

.opacity-low {
  opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_views/_popup_views/add-view/add-view.component.scss"],"names":[],"mappings":"AAKA;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;AAJF;;AAOA;EAGE,gBAAA;EACA,gBAAA;AANF;;AASA;EACE,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,uBAAA;EACA,eAAA;AANF;;AASA;EACE,eAAA;EACA,gBAAA;AANF;;AASA;EACE,yBAAA;EACA,kBAAA;AANF;;AASA;EACE,YAAA;EACA,WAAA;AANF;;AASA;EACE,gBAAA;AANF;;AASA;EACE,eAAA;AANF;;AASA;EACE,kBAAA;AANF;;AASA;EACE,mBAAA;AANF;;AASA;EACE,cAAA;AANF;;AASA;EACE,gBAAA;AANF;;AASA;EACE,YAAA;AANF","sourcesContent":["// :host ::ng-deep .ng-dropdown-panel {\n//     display: none !important;\n// }\n\n\n.filter-header-title {\n  color: #192c49;\n  margin-bottom: 8px;\n  font-size: 12px;\n  font-weight: 500;\n}\n\n.filter-border {\n  //border: solid 1px #E6E8F0;\n  //border-radius: 4px;\n  max-height: 25vh;\n  overflow-y: auto;\n}\n\n.flex-filter-chips {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-flow: wrap;\n}\n\n.preferences-title {\n  font-size: 16px;\n  font-weight: 500;\n}\n\n.preferences-border {\n  border: 1px solid #E7E8F0;\n  border-radius: 4px;\n}\n\n.hw14 {\n  height: 14px;\n  width: 14px;\n}\n\n.ml5 {\n  margin-left: 5px;\n}\n\n.mt5 {\n  margin-top: 5px;\n}\n\n.pl12 {\n  padding-left: 12px;\n}\n\n.pr12 {\n  padding-right: 12px;\n}\n\n.desc-color {\n  color: #606D81;\n}\n\n.mt12 {\n  margin-top: 12px;\n}\n\n.opacity-low {\n  opacity: 0.5;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
