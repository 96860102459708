// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host a:hover {
  text-decoration: underline;
}

.vendor-remove img {
  height: 16px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_views/_tab_components/tab-file-information/tab-file-information.component.scss"],"names":[],"mappings":"AACE;EACE,0BAAA;AAAJ;;AASE;EACE,YAAA;EACA,eAAA;AANJ","sourcesContent":[":host {\n  a:hover {\n    text-decoration: underline;\n  }\n}\n\n.vendor-text{\n  //font-size: 14px;\n}\n\n.vendor-remove{\n  img{\n    height: 16px;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
