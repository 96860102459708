import { BaseService } from './../../../../../@fyxt/_services/core/base.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { GraphQLError } from 'graphql';
import { BehaviorSubject } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
@Injectable({
  providedIn: 'root'
})
export class GraphqlEquipmentManagementService {

  @Output() additionalDataTab: EventEmitter<any> = new EventEmitter();
  @Output() additionalDataTabCount: EventEmitter<any> = new EventEmitter();
  public additionalDataTabCountSubject = new BehaviorSubject<boolean>(false)
  private deleteAssingedItems = new BehaviorSubject(false);
  @Output() age: EventEmitter<any> = new EventEmitter();
  @Output() gps_location: EventEmitter<any> = new EventEmitter();
  @Output() estimatedLife: EventEmitter<any> = new EventEmitter();
  @Output() equipmentTypeChange: EventEmitter<any> = new EventEmitter();
  public dupicate = new BehaviorSubject<boolean>(false)
  AdditionaDataCount: number = 0;
  _viewModule: string = "equipment";
  selectedItem: any;
  today = moment();
  filterPropertiesListPageNumber: number = 1;
  filterPropertiesListPageCount: number;
  filterPropertiesSize: number;
  filterPropertiesListTotal: number;
  filterPropertiesSearchInput: string = '';
  constructor(
    private readonly apollo: Apollo,
    public _utilService: UtilityService,
    private router: Router,
    public _baseService: BaseService,
    public config: ConfigService
  ) { }

  duplicateEquipment() {
    return this.dupicate.asObservable()
  }

  getAdditionalDataCount() {
    return this.additionalDataTabCountSubject.asObservable();
  }

  getdeleteAssingedItems() {
    return this.deleteAssingedItems.asObservable();
  }

  getEquipmentList(columns: any, isSearch: string, pagination: number, pageSize: number, sortColumn?: string, sortDirection?: string, filter?: any, isForPropertyEquipment?: boolean) {
    let propertyKey = isForPropertyEquipment ? 'property' : 'property_name'
    return gql`query MyQuery {
      listEquipment(query: {
        query: "{ ${filter !== undefined && filter[0]?.length > 0 ? `'name':[${encodeURIComponent(filter[0].map((n: string) => `'${n}'`))}],` : ""}${filter !== undefined && filter[1]?.length > 0 ? `'equipment_type_name':[${encodeURIComponent(filter[1].map((n: string) => `'${n}'`))}],` : ""}  ${filter !== undefined && filter[2]?.length > 0 ? `'category_name':[${encodeURIComponent(filter[2].map((n: string) => `'${n}'`))}],` : ""}${filter !== undefined && filter[3]?.length > 0 ? `'${propertyKey}':[${encodeURIComponent(filter[3].map((n: string) => `'${n}'`))}],` : ""} ${filter !== undefined && filter[4]?.length > 0 ? `'status':[${filter[4].map((n: string) => `'${n}'`)}],` : ""} ${filter !== undefined && filter[5]?.length > 0 ? `'asset_tag':[${encodeURIComponent(filter[5].map((n: string) => `'${n}'`))}],` : ""}${filter !== undefined && filter[6]?.length > 0 ? `'manufacturer_name':[${encodeURIComponent(filter[6].map((n: string) => `'${n}'`))}],` : ""}    ${filter !== undefined && filter[7]?.length > 0 ? `'model_name':[${encodeURIComponent(filter[7].map((n: string) => `'${n}'`))}],` : ""}  ${filter !== undefined && filter[8]?.length > 0 ? `'additional_data_item':[${encodeURIComponent(filter[8].map((n: string) => `'${n}'`))}],` : ""}  ${filter !== undefined && filter[9] ? `'warranty_expiration_date':'${filter[9]?.value}',` : ""} ${filter !== undefined && filter[10] ? `'expected_useful_life_expiry_date':'${filter[10]?.value}',` : ""}}",
          columns: "[${columns.map(e => `'${e.value}'`)},'id','image']",
          page:  ${pagination},
          search: "${isSearch}",
          size:${pageSize},
          ${sortColumn ? `sort_column: "${sortColumn}",` : ""}
          ${sortDirection ? `sort_order: "${sortDirection}",` : ""}
        }) {
        items {
          id
          name
          image
          equipment_type_name
          category_name
          property_name
          specific_location
          manufacturer_name
          model_name
          age
        }
        page
        size
        total
      }
    }
    `
  }

  deleteEquipmentQuery(id: any) {
    return gql`
    mutation MyMutation {
      updateEquipment(
          id: "${id}",
          input: {
            active: false
        }) {
        message
      }
    }`;
  }

  addEquipment(formValues: any, is_critical: boolean) {
    return gql`
   mutation MyMutation {
     addEquipment(input: {
         ${formValues.name !== null && formValues.name !== undefined ? `name: "${formValues.name}",` : ""}
         ${formValues.equipment_type_id?.id !== null && formValues.equipment_type_id?.id !== undefined ? `equipment_type_id: "${formValues.equipment_type_id.id}",` : ""}
         ${formValues.category?.id !== null && formValues.category?.id !== undefined ? `category: "${formValues.category.id}",` : ""}
         ${formValues.category?.name !== null && formValues.category?.name !== undefined ? `category_name: "${formValues.category.name}",` : ""}
         ${formValues.manufacturer_id?.id !== null && formValues.manufacturer_id?.id !== undefined ? `manufacturer_id: "${formValues.manufacturer_id.id}",` : ""}
         ${formValues.model_id?.id !== null && formValues.model_id?.id !== undefined ? `model_id: "${formValues.model_id.id}",` : ""}
         ${formValues.property?.id !== null && formValues.property?.id !== undefined ? `property: "${formValues.property.id}",` : ""}
         ${formValues.property?.name !== null && formValues.property?.name !== undefined ? `property_name: "${formValues.property.name}",` : ""}
         ${formValues.specific_location !== null && formValues.specific_location !== undefined ? `specific_location: "${formValues.specific_location}",` : ""}
         ${formValues.gps_location !== null && formValues.gps_location !== undefined ? `gps_location: "${JSON.stringify(formValues.gps_location).replace(/"/g, '\\"')}",` : ""}
         ${formValues.serial_number !== null && formValues.serial_number !== undefined ? `serial_number: "${formValues.serial_number}",` : ""}
         ${formValues.asset_tag !== null && formValues.asset_tag !== undefined ? `asset_tag: "${formValues.asset_tag}",` : ""}
         ${formValues.warranty !== null && formValues.warranty !== undefined && formValues.warranty_term?.name !== null && formValues.warranty_term?.name !== undefined ? `warranty: ${formValues.warranty},` : ""}
         ${formValues.warranty_term?.name !== null && formValues.warranty_term?.name !== undefined && formValues.warranty !== null && formValues.warranty !== undefined ? `warranty_term: "${formValues.warranty_term.name}",` : ""}
         ${formValues.warranty_expiration_date ? `warranty_expiration_date: "${formValues.warranty_expiration_date}",` : ""}
         ${formValues.expected_useful_life !== null && formValues.expected_useful_life !== undefined && formValues.expected_useful_life_term?.name !== null && formValues.expected_useful_life_term?.name !== undefined ? `expected_useful_life: ${formValues.expected_useful_life},` : ""}
         ${formValues.expected_useful_life_term?.name !== null && formValues.expected_useful_life_term?.name !== undefined && formValues.expected_useful_life !== null && formValues.expected_useful_life !== undefined ? `expected_useful_life_term: "${formValues.expected_useful_life_term.name}",` : ""}
         ${formValues.expected_useful_life_expiry_date ? `expected_useful_life_expiry_date: "${formValues.expected_useful_life_expiry_date}"` : ""}
         ${formValues.purchase_date !== null && formValues.purchase_date !== undefined ? `purchase_date: "${formValues.purchase_date}",` : ""}
         ${formValues.in_service_date !== null && formValues.in_service_date !== undefined ? `in_service_date: "${formValues.in_service_date}",` : ""}
         ${formValues.purchase_amount !== null && formValues.purchase_amount !== undefined ? `purchase_amount: ${formValues.purchase_amount},` : ""}
         ${formValues.replacement_cost !== null && formValues.replacement_cost !== undefined ? `replacement_cost: ${formValues.replacement_cost},` : ""}
         ${formValues.safety_notes !== null && formValues.safety_notes !== undefined ? `safety_notes: "${formValues.safety_notes}",` : ""}
         ${formValues.condition ? `condition: "${formValues.condition}",` : ""}
         ${formValues.vendor?.id !== null && formValues.vendor?.id !== undefined ? `vendor: "${formValues.vendor.id}",` : ""}
         ${formValues.vendor?.name ? `vendor_name: "${formValues.vendor?.name}",` : ""}
         ${`is_critical :${is_critical}`}
       }) {
       id
       name
       image
       equipment_type_name
       category
       property
       specific_location
       manufacturer_name
       model_name
       age
       is_critical
     }
    }`;
  }


  equipmentDetails(id: any) {
    return gql`query MyQuery {
      getEquipment(id: "${id}") {
        id
        name
        image
        equipment_type
        category
        property
        vendor
        manufacturer
        model
        gps_location
        specific_location
        serial_number
        asset_tag
        warranty_expiration_date
        expected_useful_life_expiry_date
        purchase_date
        in_service_date
        purchase_amount
        replacement_cost
        safety_notes
        condition
        warranty
        warranty_term
        expected_useful_life
        expected_useful_life_term
        is_critical
        status
        age
      }
    }`;
  }

  updateEquipmentDetailQuery(id: any, formValues: any) {
    return gql`
    mutation MyMutation {
      updateEquipment(
          id: "${id}",
          input: {
            ${formValues.name ? `name: "${formValues.name}",` : ""}
            ${formValues.equipment_type_id ? `equipment_type_id: "${formValues.equipment_type_id}",` : ""}
            ${formValues.category?.id !== null && formValues.category?.id !== undefined ? `category: "${formValues.category.id}",` : ""}
            ${formValues.category?.name !== null && formValues.category?.name !== undefined ? `category_name: "${formValues.category.name}",` : ""}
            ${formValues.manufacturer_id ? `manufacturer_id: "${formValues.manufacturer_id}",` : ""}
            ${formValues.manufacturer_id === null ? `manufacturer_id: ${null},` : ""}
            ${formValues.model_id ? `model_id: "${formValues.model_id}",` : ""}
            ${formValues.model_id === null ? `model_id: ${null},` : ""}
            ${formValues.property?.id !== null && formValues.property?.id !== undefined ? `property: "${formValues.property.id}",` : ""}
            ${formValues.property?.name !== null && formValues.property?.name !== undefined ? `property_name: "${formValues.property.name}",` : ""}
            ${formValues.specific_location ? `specific_location: "${formValues.specific_location}",` : (formValues.specific_location === '' ? `specific_location: ${null},` : "")}
            ${formValues.gps_location !== null && formValues.gps_location !== undefined ? `gps_location: "${JSON.stringify(formValues.gps_location).replace(/"/g, '\\"')}",` : ""}
            ${formValues.serial_number ? `serial_number: "${formValues.serial_number}",` : (formValues.serial_number === '' ? `serial_number: ${null},` : "")}
            ${formValues.asset_tag ? `asset_tag: "${formValues.asset_tag}",` : (formValues.asset_tag === '' ? `asset_tag: ${null},` : "")}
            ${formValues.warranty ? `warranty: ${formValues.warranty},` : (formValues.warranty === '' ? `warranty: ${null},` : "")}
            ${formValues.warranty === '' ? `warranty_term: "",` : ""}
            ${formValues.warranty_term ? `warranty_term: "${formValues.warranty_term}",` : ""}
            ${formValues.expected_useful_life ? `expected_useful_life: ${formValues.expected_useful_life},` : (formValues.expected_useful_life === '' ? `expected_useful_life: ${null},` : "")}
            ${formValues.expected_useful_life === '' ? `expected_useful_life_term: "",` : ""}
            ${formValues.expected_useful_life_term ? `expected_useful_life_term: "${formValues.expected_useful_life_term}",` : ""}
            ${formValues.purchase_date ? `purchase_date: "${formValues.purchase_date}",` : ""}
            ${formValues.in_service_date ? `in_service_date: "${formValues.in_service_date}",` : ""}
            ${formValues.purchase_amount ? `purchase_amount: ${formValues.purchase_amount},` : (formValues.purchase_amount === '' ? `purchase_amount: ${null},` : "")}
            ${formValues.warranty_expiration_date ? `warranty_expiration_date: "${formValues.warranty_expiration_date}",` : ""}
            ${formValues.replacement_cost ? `replacement_cost: ${formValues.replacement_cost},` : (formValues.replacement_cost === '' ? `replacement_cost: ${null},` : "")}
            ${formValues.safety_notes ? `safety_notes: "${formValues.safety_notes}",` : (formValues.safety_notes === '' ? `safety_notes: "",` : "")}
            ${formValues.condition ? `condition: "${formValues.condition}",` : (formValues.condition === '' ? `condition: "",` : "")}
            ${formValues.age ? `age: ${formValues.age},` : ""}
            ${formValues.status ? `status: "${formValues.status}",` : ""}
            ${formValues.is_critical !== undefined && (formValues.is_critical === true || formValues.is_critical === false) ? `is_critical: ${formValues.is_critical},` : ""}
            ${formValues.vendor?.id ? `vendor: "${formValues.vendor?.id}",` : ""}
            ${formValues.vendor?.name ? `vendor_name: "${formValues.vendor?.name}",` : ""}
            ${formValues.expected_useful_life_expiry_date ? `expected_useful_life_expiry_date: "${formValues.expected_useful_life_expiry_date}"` : ""}
        }) {
          ${Object.keys(formValues).map(key => `${key}`).join('\n')}
      }
    }
    `;

  }


  deleteItemsDataTab(id: any) {
    return gql`
    mutation MyMutation {
      deleteItemEquipment(id: "${id}")
    }
    `
  }

  additionalData(id: any, isSearch: string, pagination: number, sortColumn?: any, sortDirection?: any, pageSize?: any) {
    return gql`
    query MyQuery {
      listAssociatedItemsEquipment(
          id: "${id}",
          query: {
              search: "${isSearch}",
              #columns:"" ,
              page: ${pagination},
              size: ${pageSize ? pageSize : 25},
              ${sortColumn ? `sort_column: "${sortColumn}",` : ""}
              ${sortDirection ? `sort_order: "${sortDirection}",` : ""}
            }) {
        items {
          additional_data_item
          additional_data_item_id
          id
          value
        }
        page
        size
        total
      }
    }

    `
  }

  updateEquipmentItems(id: any, value: any) {
    return gql`mutation MyMutation {
      updateItemEquipment(
          id: "${id}",
          input: {
              value: "${value}"
            }) {
        id
        additional_data_item
        additional_data_item_id
        value
      }
    }`
  }

  equipmentAssignItemsList(id: any, search: string, page: number) {
    return gql`query MyQuery {
      listEquipmentAssignItems(
          id: "${id}",
          query: {
              search: "${search}",
              page: ${page},
              size: 100
            }) {
        items {
          id
          name
        }
      }
    }
    `
  }

  updateAssignItem(id: any, items: any) {
    return gql`
    mutation MyMutation {
      addItemEquipment(
          id: "${id}",
          input: {
              items: ${JSON.stringify(items).replace(/"(\w+)":/g, '$1:')}
            })
    }
    `
  }

  getEquipmentTypesList() {
    return gql`query MyQuery {
      listFilterEquipmentsTypes(query: {
          page: 1,
          search: "",
          size: 100
        }) {
        page
        size
        total
        items {
          name
          id
        }
      }
    }
    `
  }


  loadJobFilters(page?: any) {
    return gql`
    query MyQuery {
      listFilterEquipmentsTypes(query: {
          #page: 1,
          #search: "",
          #size: 10
        }) {
        page
        size
        total
        items {
          name
          id
        }
      }


       listFilterModels(query: {
          #page: 1,
          #size: 10,
          #search: "",
          #manufacturer_id: ""
        }) {
        page
        size
        total
        items {
          name
          id
        }
        page
        size
        total
      }

      listFilterManufacturers(query: {
          #search: "",
          #size: 10,
          #page: 1
        }) {
        page
        size
        total
        items {
          id
          name
        }
      }

      listFyxtCategories {
        items {
          id
          name
        }
      }
    }
    `;
  }

  loadFiltersQuery(page?: any) {
    return gql`
    query MyQuery {
      listFilterAdditionalItems(query: {
        #search: ""
      }) {
      items {
        id
        name
      }
    }

      listFilterEquipments(query: {
        #search: ""
      }) {
      items {
        id
        name
      }
    }

    listFilterAssetTags(query: {
      #search: ""
    }) {
    items {
      asset_tag
    }
  }

      listFilterEquipmentsTypes(query: {
        #search: ""
      }) {
      items {
        id
        name
      }
    }
    listFilterModels(query: {
      #search: "",
      #manufacturer_id: ""
    }) {
    items {
      name
      id
    }
    }

    listFilterManufacturers(query: {
      #search: ""
    }) {
    items {
      id
      name
    }
    }
    listFilterStatus {
      items {
        name
      }
    }
    }`

  }

  viewListQuery() {
    return gql`
    query MyQuery {
      listDashboardView(view_type: "equipment") {
        items {
          label
          data {
            id
            name
            pin
            default
            count
            created_by
          }
        }
      }
    }
    `
  }

  viewsTabListing() {
    return gql`
    query MyQuery {
      my_views : listManageViews(query: {
          view_type: "equipment",
          manage_tab: "my_views",
          #search: "",
          #page: 1,
          size: 50
        }) {
        items {
          id
          default
          pin
          shared
          name
          count
        }
        page
        size
        total
      }
      shared_by_others : listManageViews(query: {
          view_type: "equipment",
          manage_tab: "shared_by_others",
          #search: "",
          #page: 1,
          size: 50
        }) {
        items {
          id
          default
          pin
          shared
          name
          count
        }
        page
        size
        total
      }
      standard : listManageViews(query: {
          view_type: "equipment",
          manage_tab: "standard",
          #search: "",
          #page: 1,
          size: 50
        }) {
        items {
          id
          default
          pin
          shared
          name
          count
        }
        page
        size
        total
      }
    }`
  }

  tableData(id: any) {
    return gql`
    query MyQuery {
      getView(id: "${id}") {
        id
        name
        view_type
        table_columns
        filters
        standard
        pin
        default
        shared
      }
    }
    `
  }
  viewList() {
    return this.apollo.query({ query: this.viewListQuery() }).pipe(map(res => res.data));
  }

  loadFilters(page?: any) {
    return this.apollo.query({ query: this.loadFiltersQuery(page) }).pipe(map(res => res.data));
  }

  loadPropertyFilters() {
    let requestURL = environment.baseURL + 'properties/dropdown/';
    return this._baseService.doGET(requestURL);
  }
  
  loadNewPropertyFilters() {
    let requestURL = environment.LocalDev_URL_V2 + this.config.getPropertiesFilter;
    if (this.filterPropertiesSearchInput.trim() != '') {
      if (requestURL.includes('?')) {
        requestURL += `&search=${this.filterPropertiesSearchInput}`;
      } else {
        requestURL += `?search=${this.filterPropertiesSearchInput}`;
      }
    }
    if (this.filterPropertiesListPageNumber > 1) {
      if (requestURL.includes('?')) {
        requestURL += `&page=${this.filterPropertiesListPageNumber}`;
      } else {
        requestURL += `?page=${this.filterPropertiesListPageNumber}`;
      }
    }
    return this._baseService.doGET(requestURL);
  }

  loadCategoryFilters() {
    let requestURL = environment.baseURL + 'categories';
    return this._baseService.doGET(requestURL);
  }

  equipmentList(columns: any, isSearch?: string, pagination?: number, pageSize?: number, sortColumn?: string, sortDirection?: string, filter?: any, isForPropertyEquipment?: boolean) {
    return this.apollo.query({ query: this.getEquipmentList(columns, isSearch, pagination, pageSize, sortColumn, sortDirection, filter, isForPropertyEquipment) }).pipe(map(res => res.data));
  }

  deleteEquipment(id: any) {
    return this.apollo.mutate({
      mutation: this.deleteEquipmentQuery(id),
    }).pipe(map(res => res.data));
  }

  createEquipment(formValues: any, duplicate: boolean, id?: any, is_critical?: any) {
    return this.apollo.mutate({
      mutation: this.addEquipment(formValues, is_critical),
    }).pipe(map(res => res.data)).subscribe({
      next: (res: any) => {
        if (res) {
          if (duplicate) {
            this.mapDuplicateEqupiment(res.addEquipment.id, id).subscribe((data: any) => {
              this._utilService.showSuccess('', 'Equipment Created Successfully');
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.onSameUrlNavigation = 'reload';
              const currentUserInfo: any = localStorage.getItem('userobj') ? JSON.parse(localStorage.getItem('userobj')) : {};
              if (currentUserInfo.current_Role === "Owner" || currentUserInfo.current_Role === "Manager") {
                this.router.navigate([`/pm/equipment/view/${res.addEquipment!.id}`]);
              }
              if (currentUserInfo.current_Role === "Engineer") {
                this.router.navigate([`/engineer/equipment/view/${res.addEquipment!.id}`]);
              }
            })
          }
          else {
            this._utilService.showSuccess('', 'Equipment Created Successfully');
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            const currentUserInfo: any = localStorage.getItem('userobj') ? JSON.parse(localStorage.getItem('userobj')) : {};
            if (currentUserInfo.current_Role === "Owner" || currentUserInfo.current_Role === "Manager") {
              this.router.navigate([`/pm/equipment/view/${res.addEquipment!.id}`]);
            }
            if (currentUserInfo.current_Role === "Engineer") {
              this.router.navigate([`/engineer/equipment/view/${res.addEquipment!.id}`]);
            }
          }
        }
      },
      error: (err: any) => {
        const errorMessage = err?.graphQLErrors?.[0]?.message?.split('=')?.[1]?.replace(/[{}]/g, '').trim() ?? err.message;
        this._utilService.showError('', errorMessage);
      },
    });
  }


  mapDuplicateEqupiment(newEqupimentId, oldEqupimentId) {
    return this.apollo.query({ query: this.mapDuplicateEqupimentQuery(newEqupimentId, oldEqupimentId) }).pipe(map(res => res.data));
  }


  mapDuplicateEqupimentQuery(newEqupimentId, oldEqupimentId) {
    return gql`mutation MyMutation {
      duplicateEquipmentItems(
          id: "${newEqupimentId}", #new ID
          input: {
            duplicate_equipment_id: "${oldEqupimentId}"  #old ID
              }) {
        count
      }
    }`

  }

  getEquipmentDetails(id: any) {
    return this.apollo.query({ query: this.equipmentDetails(id) }).pipe(map(res => res.data));
  }

  updateEquipmentDetail(id: any, formValue: any) {
    return this.apollo.mutate({
      mutation: this.updateEquipmentDetailQuery(id, formValue),
    }).pipe(map(res => res.data)).subscribe({
      next: (res: any) => {
        const myObject = formValue;
        const keys = Object.keys(myObject);
        const firstKey = keys[0];
        this.selectedItem[firstKey] = res.updateEquipment[firstKey];
        if (firstKey === 'in_service_date' || firstKey === 'expected_useful_life' || firstKey === 'expected_useful_life_term'
          || firstKey === 'warranty' || firstKey === 'warranty_term') {
          this.age.emit(myObject);
        } else if (firstKey === 'gps_location') {
          this.gps_location.emit(myObject);
        }
        this._utilService.showSuccess('', 'Equipment Detail Updated Successfully');
        this.dupicate.next(true);
      },
      error: (err: GraphQLError) => {
        const errorString = err.message;
        const regexPattern = /{([^=]+)=([^}]+)}/;
        const matches = errorString.match(regexPattern);
        if (matches) {
          const value = matches[2];
          this._utilService.showError('', value);
        }
        else {
          this._utilService.showError('', err.message);
        }
      }
    })
  }



  loadAssignItems(id: any, searchInput: string, page: number) {
    return this.apollo.query({ query: this.equipmentAssignItemsList(id, searchInput, page) }).pipe(map(res => res.data));
  }

  loadEquipmentTypeList() {
    return this.apollo.query({ query: this.getEquipmentTypesList() }).pipe(map(res => res.data));
  }

  updateAssignItemEquipment(id: any, items: any) {
    return this.apollo.mutate({
      mutation: this.updateAssignItem(id, items),
    }).pipe(map(res => res.data)).subscribe({
      next: (res) => {
        if (res) this.additionalDataTab.emit(true);
        this._utilService.showSuccess('', 'Item assigned successfully');
      },
      error: (err: GraphQLError) => {
        this._utilService.showError('', err);
      },
    })
  }

  listAdditionalDataTab(id: any, isSearch?: string, pagination?: number, sortColumn?: any, sortDirection?: any, pageSize?: any) {
    return this.apollo.query({ query: this.additionalData(id, isSearch, pagination, sortColumn, sortDirection, pageSize) }).pipe(map(res => res.data));
  }

  deleteItems(id: any) {
    return this.apollo.mutate({
      mutation: this.deleteItemsDataTab(id)
    }).pipe(map(res => res.data)).subscribe({
      next: (res) => {
        if (res) {
          this.deleteAssingedItems.next(true);
          this._utilService.showSuccess('', 'Item unassigned successfully');
        }
      },
      error: (err: GraphQLError) => {
        const error = err.message;
        const messageWithoutBraces = error.slice(1, -1);
        const value = messageWithoutBraces.split('=')[1];
        this._utilService.showError('', value);
      },
    })
  }

  updateItemEquipment(id: any, value: any) {
    return this.apollo.mutate({
      mutation: this.updateEquipmentItems(id, value)
    }).pipe(map(res => res.data)).subscribe({
      next: (res) => {
        if (res) this.additionalDataTab.emit(true);
        this._utilService.showSuccess('', 'Equipment Item Updated Successfully');
      },
      error: (err: GraphQLError) => {
        this._utilService.showError('', err);
      },
    })
  }

  getAge(date: string, usefulLife?: string) {
    let age = '';
    if (usefulLife) {
      const inServiceDate = moment(date);
      const expectedUsefulLife = usefulLife;

      let expiryDate;

      if (expectedUsefulLife.includes("Years")) {
        const years = parseInt(expectedUsefulLife);
        expiryDate = inServiceDate.add(years, 'years');
      } else if (expectedUsefulLife.includes("Months")) {
        const months = parseInt(expectedUsefulLife);
        expiryDate = inServiceDate.add(months, 'months');
      }
      return expiryDate.format("YYYY-MM-DD");

    }
    else {
      const today = moment();
      const end = moment(date);
      const duration = moment.duration(today.diff(end));

      const years = duration.years();
      const months = duration.months();
      const days = duration.days();


      if (years > 0) {
        age += `${years} year${years > 1 ? 's' : ''}, `;
      }

      if (months > 0) {
        age += `${months} month${months > 1 ? 's' : ''}, `;
      }

      age += `${days} day${days > 1 ? 's' : ''}`;
    }

    return age;
  }

  getLife(date: any) {
    const today = moment();
    const end = moment(date);
    const duration = moment.duration(end.diff(today));

    const years = duration.years();
    const months = duration.months();
    const days = duration.days();

    let estimatedLifte = '';

    if (years > 0) {
      estimatedLifte += `${years} year${years > 1 ? 's' : ''}, `;
    }

    if (months > 0) {
      estimatedLifte += `${months} month${months > 1 ? 's' : ''}, `;
    }

    estimatedLifte += `${days} day${days > 1 ? 's' : ''}`;

    return estimatedLifte;
  }

  viewsListWithoutSelectingTabDetails() {
    return this.apollo.query({ query: this.viewsTabListing() }).pipe(map(res => res.data));
  }

  getSelectedViewDetails(id: any) {
    return this.apollo.query({ query: this.tableData(id) }).pipe(map(res => res.data));
  }

  loadFilterspageQueries(search: string, name: string, page?: number) {
    return this.apollo.query({ query: this.loadFilterspageQuery(search, name, page) }).pipe(map(res => res.data));
  }

  loadFilterspageQuery(search: string, name: string, page?: number) {
    return gql`
    query MyQuery {
      ${name}(query: {
          page:${page ? page : 1}
          #size: 10,
          search: "${search}"
        }) {
        page
        size
        total
        items {
          id
          name
          ${name === 'listFilterAssetTags' ? 'asset_tag' : ""}
        }
      }
    }`
  }

  viewsTabSearchList(module: string, selectedTab: string, search: string, paginationIndex?: any) {
    return gql`
    query MyQuery {
      listManageViews(query: {
          view_type: "${module}",
          manage_tab: "${selectedTab}",
          search: "${search}",
          page: ${paginationIndex},
          size: 50
        }) {
        items {
          id
          default
          pin
          shared
          name
          count
        }
        page
        size
        total
      }
    }
   `
  }

  updateSelectedView(view: any, id: any, values: any) {
    let formattedString1: any;
    let formattedString2: any;
    if (values?.filters && values?.filters[9] && values?.filters[9][0]) {
      const [key, value] = values?.filters[9][0].split(" : ");
      formattedString1 = `\\"${key.trim()}\\":\\"${value.trim()}\\"`;
    }
    if (values?.filters && values?.filters[10] && values?.filters[10][0]) {
      const [key, value] = values?.filters[10][0].split(" : ");
      formattedString2 = `\\"${key.trim()}\\":\\"${value.trim()}\\"`;
    }
    return gql`
    mutation MyMutation {
      updateView(
          id: "${id}",
          input: {
              ${values.name ? `name: "${values.name}",` : ""}
              ${(values.pin == true || values.pin == false) ? `is_pinned: ${values.pin},` : ""},
              ${(values.shared == true || values.shared == false) ? `is_shared: ${values.shared},` : ""},
              ${(values.default == true || values.default == false) ? `is_default: ${!values.default},` : ""},
              ${view === 'equipment' ? `${values?.filters ? `filters: "{\\"query\\": {\\"name\\":[${values?.filters[0]?.map(e => `\\"${e}\\"`).join(',')}],\\"equipment_type_name\\":[${values?.filters[1]?.map(e => `\\"${e}\\"`).join(',')}],\\"category_name\\":[${values?.filters[2]?.map(e => `\\"${e}\\"`).join(',')}],\\"property_name\\":[${values?.filters[3]?.map(e => `\\"${e}\\"`).join(',')}],\\"status\\":[${values?.filters[4]?.map(e => `\\"${e}\\"`).join(',')}],\\"asset_tag\\":[${values?.filters[5]?.map(e => `\\"${e}\\"`).join(',')}],\\"manufacturer_name\\":[${values?.filters[6]?.map(e => `\\"${e}\\"`).join(',')}],\\"model_name\\":[${values?.filters[7]?.map(e => `\\"${e}\\"`).join(',')}],\\"additional_data_item\\":[${values?.filters[8]?.map(e => `\\"${e}\\"`).join(',')}] ${formattedString1 ? ',' + formattedString1 : ''}${formattedString2 ? ',' + formattedString2 : ''}} }",` : ""}` : ""}
              ${view === 'equipment_type' ? `${values?.filters ? `filters: "{\\"query\\": {\\"name\\":[${values?.filters[0]?.map(e => `\\"${e}\\"`).join(',')}],\\"category_name\\":[${values?.filters[1]?.map(e => `\\"${e}\\"`).join(',')}],\\"manufacturer_name\\":[${values?.filters[2]?.map(e => `\\"${e}\\"`).join(',')}],\\"model_name\\":[${values?.filters[3]?.map(e => `\\"${e}\\"`).join(',')}],\\"status\\":[${values?.filters[4]?.map(e => `\\"${e}\\"`).join(',')}],\\"additional_data_item\\":[${values?.filters[5]?.map(e => `\\"${e}\\"`).join(',')}]} }",` : ""}` : ""}
              ${view === 'additional_data_item' ? `${values?.filters ? `filters: "{\\"query\\": {\\"name\\":[${values?.filters[0]?.map(e => `\\"${e}\\"`).join(',')}],\\"category_name\\":[${values?.filters[1]?.map(e => `\\"${e}\\"`).join(',')}]} }",` : ""}` : ""}
              ${values.table_columns ? ` table_columns: [
              ${values.table_columns[0] ? `{
                name: "${values.table_columns[0]?.name}",
                order: ${values.table_columns[0]?.order},
                active: ${values.table_columns[0]?.active},
                value: "${values.table_columns[0]?.value}"
              },`: ""}
              ${values.table_columns[1] ? `{
                name: "${values.table_columns[1]?.name}",
                order: ${values.table_columns[1]?.order},
                active: ${values.table_columns[1]?.active},
                value: "${values.table_columns[1]?.value}"
              },`: ""}
              ${values.table_columns[2] ? `{
                name: "${values.table_columns[2]?.name}",
                order: ${values.table_columns[2]?.order},
                active: ${values.table_columns[2]?.active},
                value: "${values.table_columns[2]?.value}"
              },`: ""}
              ${values.table_columns[3] ? `{
                name: "${values.table_columns[3]?.name}",
                order: ${values.table_columns[3]?.order},
                active: ${values.table_columns[3]?.active},
                value: "${values.table_columns[3]?.value}"
              },`: ""}
              ${values.table_columns[4] ? `                {
                name: "${values.table_columns[4]?.name}",
                order: ${values.table_columns[4]?.order},
                active: ${values.table_columns[4]?.active},
                value: "${values.table_columns[4]?.value}"
              },`: ""}
              ${values.table_columns[5] ? `                {
                name: "${values.table_columns[5]?.name}",
                order: ${values.table_columns[5]?.order},
                active: ${values.table_columns[5]?.active},
                value: "${values.table_columns[5]?.value}"
              },`: ""}
              ${values.table_columns[6] ? `                {
                name: "${values.table_columns[6]?.name}",
                order: ${values.table_columns[6]?.order},
                active: ${values.table_columns[6]?.active},
                value: "${values.table_columns[6]?.value}"
              },`: ""}
              ${values.table_columns[7] ? `                {
                name: "${values.table_columns[7]?.name}",
                order: ${values.table_columns[7]?.order},
                active: ${values.table_columns[7]?.active},
                value: "${values.table_columns[7]?.value}"
              },`: ""}
            ],`: ""}
        }) {
        shared
        pin
        default
        table_columns
        filters
      }
    }
    `
  }

  createNewViews(view_type: string, values: any) {
    return gql`
    mutation MyMutation {
      addView(input: {
          view_type: "${view_type}",
          name: "${values.name}",
          table_columns: [
              ${values.table_columns[0] ? `{
                name: "${values.table_columns[0]?.name}",
                order: ${values.table_columns[0]?.order},
                active: ${values.table_columns[0]?.active},
                value: "${values.table_columns[0]?.value}"
              },`: ""}
              ${values.table_columns[1] ? `{
                name: "${values.table_columns[1]?.name}",
                order: ${values.table_columns[1]?.order},
                active: ${values.table_columns[1]?.active},
                value: "${values.table_columns[1]?.value}"
              },`: ""}
              ${values.table_columns[2] ? `{
                name: "${values.table_columns[2]?.name}",
                order: ${values.table_columns[2]?.order},
                active: ${values.table_columns[2]?.active},
                value: "${values.table_columns[2]?.value}"
              },`: ""}
              ${values.table_columns[3] ? `{
                name: "${values.table_columns[3]?.name}",
                order: ${values.table_columns[3]?.order},
                active: ${values.table_columns[3]?.active},
                value: "${values.table_columns[3]?.value}"
              },`: ""}
              ${values.table_columns[4] ? `                {
                name: "${values.table_columns[4]?.name}",
                order: ${values.table_columns[4]?.order},
                active: ${values.table_columns[4]?.active},
                value: "${values.table_columns[4]?.value}"
              },`: ""}
              ${values.table_columns[5] ? `                {
                name: "${values.table_columns[5]?.name}",
                order: ${values.table_columns[5]?.order},
                active: ${values.table_columns[5]?.active},
                value: "${values.table_columns[5]?.value}"
              },`: ""}
              ${values.table_columns[6] ? `                {
                name: "${values.table_columns[6]?.name}",
                order: ${values.table_columns[6]?.order},
                active: ${values.table_columns[6]?.active},
                value: "${values.table_columns[6]?.value}"
              },`: ""}
              ${values.table_columns[7] ? `                {
                name: "${values.table_columns[7]?.name}",
                order: ${values.table_columns[7]?.order},
                active: ${values.table_columns[7]?.active},
                value: "${values.table_columns[7]?.value}"
              },`: ""}
            ],
            ${view_type === 'equipment' ? `${values?.filters ? `filters: "{\\"query\\": {\\"name\\":[${values?.filters[0]?.map(e => `\\"${e}\\"`).join(',')}],\\"equipment_type_name\\":[${values?.filters[1]?.map(e => `\\"${e}\\"`).join(',')}],\\"category_name\\":[${values?.filters[2]?.map(e => `\\"${e}\\"`).join(',')}],\\"property_name\\":[${values?.filters[3]?.map(e => `\\"${e}\\"`).join(',')}],\\"status\\":[${values?.filters[4]?.map(e => `\\"${e}\\"`).join(',')}],\\"asset_tag\\":[${values?.filters[5]?.map(e => `\\"${e}\\"`).join(',')}],\\"manufacturer_name\\":[${values?.filters[6]?.map(e => `\\"${e}\\"`).join(',')}],\\"model_name\\":[${values?.filters[7]?.map(e => `\\"${e}\\"`).join(',')}],\\"additional_data_item\\":[${values?.filters[8]?.map(e => `\\"${e}\\"`).join(',')}]} }",` : ""}` : ""}
            ${view_type === 'equipment_type' ? `${values?.filters ? `filters: "{\\"query\\": {\\"name\\":[${values?.filters[0]?.map(e => `\\"${e}\\"`).join(',')}],\\"category_name\\":[${values?.filters[1]?.map(e => `\\"${e}\\"`).join(',')}],\\"manufacturer_name\\":[${values?.filters[2]?.map(e => `\\"${e}\\"`).join(',')}],\\"model_name\\":[${values?.filters[3]?.map(e => `\\"${e}\\"`).join(',')}],\\"status\\":[${values?.filters[4]?.map(e => `\\"${e}\\"`).join(',')}],\\"additional_data_item\\":[${values?.filters[5]?.map(e => `\\"${e}\\"`).join(',')}]} }",` : ""}` : ""}
            ${view_type === 'additional_data_item' ? `${values?.filters ? `filters: "{\\"query\\": {\\"name\\":[${values?.filters[0]?.map(e => `\\"${e}\\"`).join(',')}],\\"category_name\\":[${values?.filters[1]?.map(e => `\\"${e}\\"`).join(',')}]} }",` : ""}` : ""}
            ${(values.pin == true || values.pin == false) ? `is_pinned: ${values.pin},` : ""},
            ${(values.shared == true || values.shared == false) ? `is_shared: ${values.shared},` : ""},
        }) {
        id
        name
        view_type
        table_columns
        filters
        standard
        pin
        shared
        default
      }
    }
    `
  }

  searchViews(module: string, selectedTab: string, search: string, page: number) {
    return this.apollo.query({ query: this.viewsTabSearchList(module, selectedTab, search, page) }).pipe(map(res => res.data));
  }

  paginationViews(module: string, selectedTab: string, search: string, paginationIndex: any) {
    return this.apollo.query({ query: this.viewsTabSearchList(module, selectedTab, search, paginationIndex) }).pipe(map(res => res.data));
  }

  updateView(view: any, id: any, value: any) {
    return this.apollo.mutate({
      mutation: this.updateSelectedView(view, id, value),
    }).pipe(map(res => res.data));
  }

  createView(view_type: string, values: any) {
    return this.apollo.mutate({
      mutation: this.createNewViews(view_type, values),
    }).pipe(map(res => res.data));
  }

  serviceHistoryList(id: any, searchInput?: any, pageNumber?: number, pageSize?: number, sortColumn?: any, sortDirection?: any) {
    const offset = (pageNumber - 1) * pageSize;
    let requestURL = environment.baseURL + 'jobs/equipment-service-history/?equipment_id=' + id;
    if (searchInput) requestURL += '&search=' + searchInput;
    // if(pageNumber) requestURL+='&page='+pageNumber;
    if (sortColumn && sortDirection) requestURL += '&order=' + sortDirection + '&column=' + sortColumn;
    if (pageSize) requestURL += '&limit=' + pageSize + '&offset=' + offset;
    return this._baseService.doGET(requestURL);
  }

  loadJobEquipmentFilters(page?: any) {
    return this.apollo.query({ query: this.loadJobFilters(page) }).pipe(map(res => res.data));
  }

  catchErrorGraphQL(err: GraphQLError) {
    const error = err.message;
    const messageWithoutBraces = error.slice(1, -1);
    const value = messageWithoutBraces.split('=')[1];
    this._utilService.showError('', value);
  }
}
