import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { UiService } from 'src/app/services/ui/ui.service';
import { BaseService } from 'src/app/services/base.service';
import { UtilityService } from 'src/app/services/utility.service';
import { TicketFlowsService } from 'src/app/services/ticket-flows.service';
import { ConfigService } from 'src/app/services/config.service';
import { PhotoBankService } from 'src/app/services/photo_bank/photo-bank.service';
import { ChecklistService } from 'src/app/services/checklist/checklist.service';
import { InboxService } from 'src/app/services/Inbox/inbox.service';
import { JobsService } from 'src/app/services/v2/jobs.service';

import { environment } from 'src/environments/environment';

import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';

import { SubjectDataService } from 'src/app/services/subject-data.service';
import { SocketService } from 'src/app/services/sockets/socket.service';

import { Observable, Subject, Subscription, forkJoin, of } from 'rxjs';
import { catchError, debounceTime, delay, filter, map, takeUntil, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { TabPhotobankPopupComponent, TabUploadFilesPopupComponent } from '../../_views';
import { LeasesStoreService } from 'src/app/@fyxt/_services/leases/leases-store.service';
import { ILeaseCategory } from '../../models/porperty-manager/maintenance-terms-tab';
import { Lease } from '../../models/porperty-manager/leases';
import { FilesService } from 'src/app/Modules/_fyxt_modules/companies/_services/files.service';
import { CompaniesDetailedViewService } from 'src/app/Modules/_fyxt_modules/companies/_services/companies-detailed-view.service';
import { JobSettingsService } from 'src/app/services/global-job-settings/job-settings.service';
import { FOLDER_TYPE } from '../../_views/_tab_components/tab-files/files.const';
import { ScopeAndInstructionsTemplateComponent } from '../../_views/_popup_views/scope-and-instructions-template/scope-and-instructions-template.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ButtonComponent } from '../../_reusable_ui/_components/button/button.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CheckboxComponent } from '../../_reusable_ui/_controls/checkbox/checkbox.component';
import { TextareaComponent } from '../../_reusable_ui/_controls/textarea/textarea.component';
import { TextboxComponent } from '../../_reusable_ui/_controls/textbox/textbox.component';
import { DropdownDateSelectComponent } from '../../_reusable_ui/_controls/dropdown-select/dropdown-date-select/dropdown-date-select.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { DropdownComponent } from '../../_reusable_ui/_controls/dropdown/dropdown.component';
import { NgIf, NgFor, NgClass, NgTemplateOutlet, AsyncPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'app-create-ticket-new',
    templateUrl: './create-ticket-new.component.html',
    styleUrls: ['./create-ticket-new.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgFor, NgClass, DropdownComponent, NgxIntlTelInputModule, NgTemplateOutlet, DropdownDateSelectComponent, TextboxComponent, TextareaComponent, CheckboxComponent, MatTooltipModule, ButtonComponent, MatMenuModule, MatIconModule, MatDividerModule, MatDialogModule, NgSelectModule, AsyncPipe, DatePipe]
})
export class CreateTicketNewComponent implements OnInit, OnDestroy {
  @ViewChild('myDiv') myDiv: ElementRef;
  @ViewChild('selectAllChecklist') selectAllChecklist: NgSelectComponent;
  @ViewChild('selectPropertyChecklist')
  selectPropertyChecklist: NgSelectComponent;
  @ViewChild('locationDropdown') locationDropdown: NgSelectComponent;

  @Input() isNewUI: boolean = false;
  currentUserInfo: any = localStorage.getItem('userobj') ? JSON.parse(localStorage.getItem('userobj')) : {};
  base: string;
  typeInbox = false;

  livePropertyData: Observable<responseObj[]>;
  selectedPropertyItemsList: any;
  propertyLoading: boolean = false;
  locationLoading: boolean = false;
  categoryLoading: boolean = false;
  serviceTypeLoading: boolean = false;
  liveServiceTypeData: Observable<responseObj[]>;

  liveLocationData: Observable<responseObj[]>;
  selectedLocationItemsList: any;

  liveCategoryData: Observable<responseObj[]>;
  selectedCategoryItemsList: any;
  searchTerm$ = new Subject<any>();


  liveAllVendorsDropdownLists: Observable<responseObj[]>;
  vendorLoading: any;
  allVendorsPagination: any = {
    pagination: { next: '', count: 0, total: 0, size: 25, pageCount: null, currentPage: 1 },
    tempData: [],
  };

  allPropertyPagination: any = {
    pagination: { next: '', count: 0, total: 0, size: 25, pageCount: null, currentPage: 1 },
    tempData: []
  };

  subscription: Subscription;

  createTicketForm: UntypedFormGroup;
  submitted: boolean = false;
  isMinimize = false;
  isClearProperty = false;
  isClearLocation = false;
  isClearCategory = false;

  selectedTab: any = '1';
  processList: any = [
    { id: 'not_Started', name: 'Not Started' },
    { id: 'complete', name: 'Complete' },
    { id: 'not_Complete', name: 'Not Complete' },
    { id: 'not_Applicable', name: 'Not Applicable' }
  ];
  statusList: any = [
    { id: 'Open', name: 'Open' },
    { id: 'In_Progress', name: 'In Progress' },
    { id: 'On_Hold', name: 'On Hold' },
    { id: 'Complete', name: 'Complete' }
  ];
  resultList: any = [
    { id: 'Pass', name: 'Pass' },
    { id: 'Flag', name: 'Flag' },
    { id: 'Fail', name: 'Fail' }
  ];

  RequestTypesList = [
    { value: 'In Unit', label: 'In Unit' },
    { value: 'Common Area', label: 'Common Area Maintenance' }
  ];

  // PriorityTypesList = [
  //   { value: 'Low', label: 'Low' },
  //   { value: 'Medium', label: 'Medium' },
  //   { value: 'High', label: 'High' },
  //   { value: 'Emergent', label: 'Emergent' },
  // ];

  AllLocationDropdownLists: any[] = [];
  AllCategoriesDropdownLists: any[] = [];
  AllVendorsDropdownLists: any[] = [];
  AllFollowersDropdownLists: any[] = [];
  AllAddressesDropdownLists: any[] = [];
  AllEmergentVendorsList: any[] = [];

  selectedVendorsItems: any = [];
  selectedEngineerItems: any = [];
  selectedFollowersItems: any = [];
  selectedChecklistsItems: any = [];

  isCam_Selected: boolean = false;

  viewBillableParty: boolean = false;
  BillableParty: any = {};

  isEmergentSubmited: boolean = false;

  minDate: any = new Date();
  maxDate: any = new Date();
  selectedEmergentCategoryItems: any = {};
  selctedCategoryObject: any = {};
  selectedLocationItem: any = {};
  is_vendor_engineer_error: boolean = false;
  isAllChecklist: boolean = true;
  f: any;
  searchTerm_Vendor: any = {};
  tempUploadedList: any[] = [];
  tempUploadedList_DraftFiles: any[] = [];
  copyFilesUploadedList: any[] = [];
  showOptional = true;

  selectedCategory: any;
  categories: ILeaseCategory[] = [];

  liveNotesSubscription: Subscription;
  leaseInfo: any;

  // New Lease Info
  billablePartyInfo: any = {};
  unitBasedCategories: any[] = [];
  isDraft_New: boolean;
  showAutoApproveCheckBox: boolean = false;
  selectedAssigneesItems: any;
  listAllCategoryData: any[] = [];

  propertySubscription: Subscription;

  selected_DraftJob: any = {};
  selectedChecklistFiles: any[] = [];
  previousEngineers: any[] = [];
  current_account = '';

  isGlobalJobSettings: boolean = environment.globalJobSettings ? environment.globalJobSettings : false;
  override_job_settings: boolean = false;
  globalSettings: any = {};
  locationSubscription: Subscription;
  inboxAttachments = [];

  isJobAssignmentEnable = false;
  isManagerEnable = false;
  isFollowerEnable = false;
  instructionsText: any;
  isGetVendorByProperty: boolean;
  selectedCategoryObj: any=[];
  selectedPropertyObj: any=[];

  constructor(
    public _uiService: UiService,
    public _utilService: UtilityService,
    public _baseService: BaseService,
    public _Config: ConfigService,
    public _ticketService: TicketFlowsService,
    public formBuilder: UntypedFormBuilder,
    private _subjectService: SubjectDataService,
    public _photoBankService: PhotoBankService,
    public _checklistService: ChecklistService,
    public _inboxService: InboxService,
    public _socketService: SocketService,
    public _jobService: JobsService,
    public dialogRef: MatDialogRef<CreateTicketNewComponent>,
    public _dialogService: DialogService,
    private readonly leasesStoreService: LeasesStoreService,
    public matDialogRef: MatDialog,
    public changeDetector: ChangeDetectorRef,
    private _companiesDetailedService: CompaniesDetailedViewService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fileService: FilesService,
    public _jobSettingService: JobSettingsService
  ) {
    let hostname = window.location.hostname;
    if (hostname.includes('thevillagescpm') || hostname.includes('dietz')) {
      this.current_account = 'thevillagescpm';
    }
    else if (hostname.includes('bcwoodproperties') || hostname.includes('krishna') || hostname.includes('localhost')) {
      this.current_account = 'bcwoodproperties';
    }

    this.selectedChecklistFiles = data?.selectedChecklistFiles;
    this.getAllGlobalSettings();

    if (this.selectedChecklistFiles?.length > 0) {
      this.tempUploadedList = [];
      this.selectedChecklistFiles.map((obj) => {
        if (obj.mime_type === 'application/pdf') {
          obj.previewUrl = "/assets/images/media/pdf.png";
        } else {
          obj.previewUrl = obj.file;
        }
        obj.source = 'CUSTOM';
        obj.filename = this.getFileName(obj);
        obj.from = 'checklist_item';
      });
      this.tempUploadedList = this.selectedChecklistFiles;
      this.copyFilesUploadedList = this.selectedChecklistFiles;
    }

    this.typeInbox = data.typeInbox ?? false;

    if (this.typeInbox) {
      this.inboxAttachments = this.data.attachments;
    }

    this.base = environment.files.http
    this.base = this.base.substring(0, this.base.length - 1);

    if (this.typeInbox) {
      this.inboxAttachments = this.data.attachments;
    }

    /******* Minimize and Maximize Form Data *******/
    this._dialogService.fetchMinimizeTicketDetails().subscribe((state) => {
      if (state && this._dialogService.isMinimizeButtonClicked) {
        // this.postDraftTicket();
        this.isMinimize = true;
        // this._dialogService.isMinimizeButtonClicked = false;
        // this._dialogService.minimizeTicketDetails.next(false);
      }
    });

  
  }

  getFileName(file: any) {
    const extension = file.mime_type.split('/')[1];
    const fileNameWithoutExtension = file.file.substring(file.file.lastIndexOf('/') + 1, file.file.lastIndexOf('_'));
    const fileName = `${fileNameWithoutExtension}.${extension}`;
    return fileName
  }

  ngOnInit(): void {
    if(this.current_account=="bcwoodproperties"){
      this.isGetVendorByProperty=true;
    }
    else{
      this.isGetVendorByProperty=false;
    }

    this._ticketService.uploadPhotos = [];

    this.initCreateTicketForm();
    // this.getVendors();

    if (this.typeInbox) {
      this.createTicketForm.patchValue({
        issue_type: this.data.label
      });
      this.data?.property ? this.selectedPropertyItemsList = this.data.property.id : null;

      if (this.data?.property) {
        const property = {
          "id": this.data.property.id,
          "name": this.data.property.name,
          "is_active": true,
          "address": this.data.property.address,
          "search_label": this.data.property.name
        }
        this.getAllProperties({}, false, false, property);
      } else {
        this.getAllProperties({}, false);
      }


    } else {
      this.getAllProperties({}, false);
    }
    // this._ticketService.getAllPriority();
    this._checklistService.listAllChecklistDropdownByProperty_New();
    // this.getAllServiceTypeDropdown({});
    if (this._ticketService.createJobFromJobChecklist && (this._jobService.selectedJobInfo && this._jobService.selectedJobInfo?.property?.id)) {
      this.selectedPropertyItemsList = this._jobService.selectedJobInfo.property.id
    }

    let requestURL = environment.baseURL + this._Config.getAllPriority;
    this._ticketService.doGET(requestURL).subscribe((response: any) => {
      this._ticketService.PriorityTypesList = response || [];
      this._ticketService.PriorityTypesList.forEach(element => {
        if(!this._ticketService?.selectedDraftJobDetail){
          if (element.is_default === true) {
            this.createTicketForm.patchValue({
              Priority: element.name ? element.name : null,
            });
          }
        }

      });
    });

    if (this.currentUserInfo.current_Role === "Owner" || this.currentUserInfo.current_Role === "Manager") {
      this.showAutoApproveCheckBox = true;
    }

    //Based on the Job assignment permission disable the Assign Engineer, Manager, Follower value only for the Managers
    if (this.currentUserInfo?.current_Role == 'Manager') {
      this.isJobAssignmentEnable = this.currentUserInfo.permissions.includes('assign_manager_engineer_vendor');
      this.isManagerEnable = this.currentUserInfo.permissions.includes('view_assignee');
      this.isFollowerEnable = this.currentUserInfo.permissions.includes('view_followers');
    } else {
      this.isJobAssignmentEnable = true;
      this.isManagerEnable = true;
      this.isFollowerEnable = true;
    }

    this.searchTerm$.pipe(debounceTime(300)).subscribe((searchValue) => {
      this.getAllVendorsDropdown(searchValue);
    });
  }


  ngOnDestroy(): void {
    if (this.isMinimize) {
      this.postDraftTicket();

      setTimeout(() => {
        this._dialogService.isMinimizeButtonClicked = false;
        this._dialogService.minimizeTicketDetails.next(false);
        this.isMinimize = false;
        this.dialogRef.close('Closed');
      }, 500);
    }
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
      /*********** Service Request Form data Pre populate *************/
      this._jobService.isDraftJob.subscribe((selectedJobDetails: any) => {
        if (selectedJobDetails) {
          if (selectedJobDetails.id) {
            this.selected_DraftJob = selectedJobDetails;
  
            this.createTicketForm.patchValue({
              issue_type: this.selected_DraftJob?.issue_type ? this.selected_DraftJob?.issue_type : '',
              Description: this.selected_DraftJob?.draft_items?.description ? this.selected_DraftJob?.draft_items?.description : ''
            });

            if(this.selected_DraftJob?.draft_items?.default_data){
              if(this.selected_DraftJob?.draft_items?.default_data?.service_type){
                this.createTicketForm.patchValue({
                  service_type: this.selected_DraftJob?.draft_items?.default_data?.service_type ? this.selected_DraftJob?.draft_items?.default_data?.service_type : null
                });
              }
  
              if(this.selected_DraftJob?.draft_items?.default_data?.unit_entry_permission){
                this.createTicketForm.patchValue({
                  Permissiontoenter_unit: this.selected_DraftJob?.draft_items
                  ?.default_data?.unit_entry_permission ? this.selected_DraftJob?.draft_items
                  ?.default_data?.unit_entry_permission : false
                });
              }
            }
  
            this.getDraftFiles();
          }
        }
      });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  getVendors() {
    this.allVendorsPagination = {
      pagination: { next: '', count: 0, total: 0, size: 25, pageCount: null, currentPage: 1 },
      tempData: []
    };
    this.searchTerm_Vendor = '';
    this.getAllVendorsDropdown();
  }



  /**
   * Close modak if outside click event
   * @param event
   */
  closeModalOutside(event?: any) {
    if (this._ticketService.showCreaateTicket) {
      // this.triggerFalseClick();
      // this.initCreateTicketForm();
      // this._ticketService.selectedDraftJobDetails = {};
    }
  }

  initCreateTicketForm() {
    this.submitted = false;
    this.selectedVendorsItems = [];
    this.selectedEngineerItems = [];
    this.selectedFollowersItems = [];
    this.isCam_Selected = false;
    this._baseService.isTicketCreated = false;
    // this._ticketService.uploadPhotos = [];
    this.isEmergentSubmited = false;
    this.AllLocationDropdownLists = [];
    this.BillableParty = [];
    this.viewBillableParty = false;
    this.selctedCategoryObject = {};
    this._baseService.allWorkCategories = [];
    this.is_vendor_engineer_error = false;

    let ServiceTypeID = null;
    let PropertyID = null;
    let UnitID = null;
    let AssigneeID = null;


    if (this._ticketService.isEmailCreateTicket) {
      if (this._ticketService.selectedDraftJobDetail) {
        this._inboxService.createTicketInboxData.property.id =
          this._ticketService.selectedDraftJobDetail.property;
      }

      PropertyID = this._inboxService.createTicketInboxData?.property?.id;
      AssigneeID = this._inboxService.createTicketInboxData?.manager?.id || null;

      if (PropertyID) {
        let isPropertyIndex =
          this._baseService.allCreateTicketPropertiesListDropdown.findIndex(
            (obj) => obj.id == PropertyID
          );

        if (isPropertyIndex == -1) {
          PropertyID = null;
          AssigneeID = null;
        }

        this.getAllLocationDropdown(PropertyID);
        this._checklistService.listAllChecklistDropdownByProperty(PropertyID);
      } else {
        PropertyID = null;
        AssigneeID = null;
      }
    }

    let TargetCompletionDate = new Date();
    // TargetCompletionDate.setDate(TargetCompletionDate.getDate() + 1);
    let targetDate = TargetCompletionDate.getDay();

    if (targetDate == 5) {
      TargetCompletionDate.setDate(TargetCompletionDate.getDate() + 3);
    } else TargetCompletionDate.setDate(TargetCompletionDate.getDate() + 1);

    // if (this._inboxService.selectedMailDetail.create_ticket_info != undefined) {

    //   if (this._inboxService.selectedMailDetail.create_ticket_info.service_type) {
    //     ServiceTypeID = this._inboxService.selectedMailDetail.create_ticket_info.service_type.id;
    //   }
    //   if (this._inboxService.selectedMailDetail.create_ticket_info.property) {
    //     PropertyID = this._inboxService.selectedMailDetail.create_ticket_info.property.id;
    //     this.selectedPropertyItemsList = PropertyID;
    //   }
    //   if (this._inboxService.selectedMailDetail.create_ticket_info.unit) {
    //     UnitID = this._inboxService.selectedMailDetail.create_ticket_info.unit.id;
    //     this.selectedLocationItemsList = UnitID;
    //   }

    // }

    this.createTicketForm = this.formBuilder.group({
      type: new UntypedFormControl('Regular', [Validators.required]),
      RequestType: new UntypedFormControl('In Unit', [Validators.required]),
      service_type: new UntypedFormControl(ServiceTypeID, [
        Validators.required
      ]),
      Property: new UntypedFormControl(PropertyID, [Validators.required]),
      Location: new UntypedFormControl(UnitID, [Validators.required]),
      MaintenanceCategory: new UntypedFormControl(null, [Validators.required]),
      TargetCompletionDate: new UntypedFormControl(TargetCompletionDate, [
        Validators.required
      ]),
      target_job_cost: new UntypedFormControl(null),
      Description: new UntypedFormControl(''),
      AccessInstructions: new UntypedFormControl(''),
      Vendors: new UntypedFormControl([]),
      is_automatic_approved: new UntypedFormControl({ value: false, disabled: false }),
      Engineers: new UntypedFormControl([]),
      SkipSite: new UntypedFormControl({ value: true, disabled: false }),
      request_feedback_on_close: new UntypedFormControl(false),
      request_auto_response: new UntypedFormControl(false),
      Permissiontoenter: new UntypedFormControl({
        value: true,
        disabled: false
      }),
      Permissiontoenter_unit: new UntypedFormControl({
        value: false,
        disabled: false
      }),
      SkipBid: new UntypedFormControl({ value: false, disabled: false }),
      disable_all_notification: new UntypedFormControl({ value: false, disabled: false }),
      RequestCompletionVerification: new UntypedFormControl({
        value: false,
        disabled: false
      }),
      CourtesyJob: new UntypedFormControl({ value: false, disabled: false }),
      Cam_Address: new UntypedFormControl(null),
      Cam_Location: new UntypedFormControl(null),
      Assignee: new UntypedFormControl(AssigneeID),
      Followers: new UntypedFormControl([]),
      Checklist_Property: new UntypedFormControl(null),
      Checklist_All: new UntypedFormControl(null),
      issue_type: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(150)
      ]),
      Priority: new UntypedFormControl(null, [Validators.required]),
      scope: new UntypedFormControl(null),
      instructions: new UntypedFormControl(null)
    });

    this.f = this.createTicketForm.controls;

    if (!this.isMinimize) {
      if (this._ticketService.selectedDraftJobDetail != undefined) {
        this.isDraft_New = true;
        setTimeout(() => {
          this.setDraftData();
        }, 1000);
      }
      this.setGlobalPermissions();
      this.getAllServiceTypeDropdown({});
      
    }

    // Set Inbox prepopulate data
    if (
      this._inboxService.selectedMailDetail &&
      this._ticketService.selectedDraftJobDetail == undefined
    ) {
      if (
        this._inboxService.selectedMailDetail.create_ticket_info != undefined
      ) {
        this.setInboxRelatedData();
      }
    }

  }

  // get f() { return this.createTicketForm.controls; }

  setInboxRelatedData() {
    let ServiceTypeID = null;
    let PropertyID = null;
    let UnitID = null;
    let description = '';

    this.selectedPropertyItemsList = null;
    this.selectedLocationItemsList = null;

    if (this._inboxService.selectedMailDetail.create_ticket_info != undefined) {
      if (
        this._inboxService.selectedMailDetail.create_ticket_info.service_type
      ) {
        ServiceTypeID =
          this._inboxService.selectedMailDetail.create_ticket_info.service_type
            .id;
      }
      if (this._inboxService.selectedMailDetail.create_ticket_info.property) {
        PropertyID =
          this._inboxService.selectedMailDetail.create_ticket_info.property.id;
        this.selectedPropertyItemsList = PropertyID;
      }
      if (this._inboxService.selectedMailDetail.create_ticket_info.unit) {
        UnitID =
          this._inboxService.selectedMailDetail.create_ticket_info.unit.id;
        this.selectedLocationItemsList = UnitID;
      }
      if (
        this._inboxService.selectedMailDetail.create_ticket_info.description
      ) {
        description =
          this._inboxService.selectedMailDetail.create_ticket_info.description;
      }

      this.createTicketForm.patchValue({
        service_type: ServiceTypeID ? ServiceTypeID : null,
        Property: PropertyID ? PropertyID : null,
        Location: UnitID ? UnitID : null,
        // Description: description ? description : null,
        issue_type: description ? description : null
      });

      if (PropertyID) {
        this.onChangeProperty(PropertyID, true);
        this.getAllLocationDropdown(PropertyID);
      }
    }
  }

  setDraftData() {
    let PropertyID = null;
    let AssigneeID = null;
    let TargetCompletionDate = new Date();
    let targetDate = TargetCompletionDate.getDay();

    if (targetDate == 5) {
      TargetCompletionDate.setDate(TargetCompletionDate.getDate() + 3);
    } else TargetCompletionDate.setDate(TargetCompletionDate.getDate() + 1);

    this.selectedPropertyItemsList =
      this._ticketService.selectedDraftJobDetail?.property ? this._ticketService.selectedDraftJobDetail.property : null;
    this._ticketService.uploadPhotos =
      this._ticketService.selectedDraftJobDetail?.photos?this._ticketService.selectedDraftJobDetail.photos:[];
    this.selectedLocationItemsList =
      this._ticketService.selectedDraftJobDetail?.unit;
    this.selectedCategoryItemsList =
      this._ticketService.selectedDraftJobDetail?.category;
    this.selectedVendorsItems =
      this._ticketService.selectedDraftJobDetail?._vendors;
    this.selectedFollowersItems =
      this._ticketService.selectedDraftJobDetail?.followers;
    this.selectedEngineerItems =
      this._ticketService.selectedDraftJobDetail?.engineers;
    this._ticketService.selectedEmailTemplate.id =
      this._ticketService.selectedDraftJobDetail?.mailId;
      this._ticketService.selectedDraftJobDetail?.mailId;

    this.selectedChecklistsItems = this._ticketService.selectedDraftJobDetail?.checklists;
    this.selectedAssigneesItems = this._ticketService.selectedDraftJobDetail?.pm_assignee;

    this.tempUploadedList_DraftFiles = this._ticketService.selectedDraftJobDetail?.tempUploadedList_DraftFiles;
    this.tempUploadedList = this._ticketService.selectedDraftJobDetail?.tempUploadedList;
    this.inboxAttachments = this._ticketService.selectedDraftJobDetail?.inboxAttachments;

    // this.getAllLocationDropdown(this.selectedPropertyItemsList);
    if (this.selectedPropertyItemsList) {
      // this.getAllLocations({}, false);
      this._ticketService.getAllEnginnersList(this.selectedPropertyItemsList);
      this._ticketService.getAllFollowersList(this.selectedPropertyItemsList);
      // this.getAllLocationDropdown(this.selectedPropertyItemsList);
    }
    if (this._ticketService.billablePartyItems) {
      this.selctedCategoryObject = this._ticketService.billablePartyItems;
    }

    if (
      this._ticketService.selectedDraftJobDetail.request_type === 'Common Area'
    ) {
      this.isCam_Selected = true;
    }

    if (this.isClearProperty) {
      this._ticketService.selectedDraftJobDetail.property = '';
    } else if (this.isClearLocation) {
      this._ticketService.selectedDraftJobDetail.unit = '';
    } else if (this.isClearCategory) {
      this._ticketService.selectedDraftJobDetail.category = '';
    }

    this.createTicketForm.patchValue({
      type: this._ticketService.selectedDraftJobDetail.type
        ? this._ticketService.selectedDraftJobDetail.type
        : 'Regular',
      RequestType: this._ticketService.selectedDraftJobDetail.request_type
        ? this._ticketService.selectedDraftJobDetail.request_type
        : 'In Unit',
      Property: this._ticketService.selectedDraftJobDetail.property
        ? this._ticketService.selectedDraftJobDetail.property
        : null,
      Location: this._ticketService.selectedDraftJobDetail.unit
        ? this._ticketService.selectedDraftJobDetail.unit
        : null,
      MaintenanceCategory: this._ticketService.selectedDraftJobDetail.category
        ? this._ticketService.selectedDraftJobDetail.category
        : null,
      TargetCompletionDate: this._ticketService.selectedDraftJobDetail
        .target_date
        ? new Date(this._ticketService.selectedDraftJobDetail.target_date)
        : TargetCompletionDate,
      Description: this._ticketService.selectedDraftJobDetail.description
        ? this._ticketService.selectedDraftJobDetail.description
        : '',
      AccessInstructions: this._ticketService.selectedDraftJobDetail
        .access_instruction
        ? this._ticketService.selectedDraftJobDetail.access_instruction
        : '',
      Vendors: this._ticketService.selectedDraftJobDetail._vendors
        ? this._ticketService.selectedDraftJobDetail._vendors
        : [],
      is_automatic_approved: this._ticketService.selectedDraftJobDetail?.is_automatic_approved
        ? (this.selectedVendorsItems?.length === 1) ? this._ticketService.selectedDraftJobDetail?.is_automatic_approved : false
        : false,
      Followers: this._ticketService.selectedDraftJobDetail.followers
        ? this._ticketService.selectedDraftJobDetail.followers
        : [],
      Engineers: this._ticketService.selectedDraftJobDetail.engineers
        ? this._ticketService.selectedDraftJobDetail.engineers
        : [],
      SkipSite: this._ticketService.selectedDraftJobDetail.skip_site_visit
        ? this._ticketService.selectedDraftJobDetail.skip_site_visit
        : false,
      request_feedback_on_close: this._ticketService.selectedDraftJobDetail.request_feedback_on_close
        ? this._ticketService.selectedDraftJobDetail.request_feedback_on_close
        : false,
      request_auto_response: this._ticketService.selectedDraftJobDetail.request_auto_response
        ? this._ticketService.selectedDraftJobDetail.request_auto_response
        : false,
      Permissiontoenter: this._ticketService.selectedDraftJobDetail
        .property_entry_permission
        ? this._ticketService.selectedDraftJobDetail.property_entry_permission
        : false,
      Permissiontoenter_unit: this._ticketService.selectedDraftJobDetail
        .unit_entry_permission
        ? this._ticketService.selectedDraftJobDetail.unit_entry_permission
        : false,
      SkipBid: this._ticketService.selectedDraftJobDetail.skip_bid
        ? this._ticketService.selectedDraftJobDetail.skip_bid
        : false,
      RequestCompletionVerification: this._ticketService.selectedDraftJobDetail
        .request_completion_photos
        ? this._ticketService.selectedDraftJobDetail.request_completion_photos
        : false,
      CourtesyJob: this._ticketService.selectedDraftJobDetail.courtesy_job
        ? this._ticketService.selectedDraftJobDetail.courtesy_job
        : false,
      disable_all_notification: this._ticketService.selectedDraftJobDetail.disable_all_notification
        ? this._ticketService.selectedDraftJobDetail.disable_all_notification
        : false,
      Cam_Address: this._ticketService.selectedDraftJobDetail.unit
        ? this._ticketService.selectedDraftJobDetail.unit
        : null,
      Cam_Location: this._ticketService.selectedDraftJobDetail.location
        ? this._ticketService.selectedDraftJobDetail.location
        : null,
      Assignee: this._ticketService.selectedDraftJobDetail.pm_assignee
        ? this._ticketService.selectedDraftJobDetail.pm_assignee
        : AssigneeID,
      Checklist_Property: this._ticketService.selectedDraftJobDetail.checklist
        ? this._ticketService.selectedDraftJobDetail.checklist
        : null,
      Checklist_All: this._ticketService.selectedDraftJobDetail.checklist
        ? this._ticketService.selectedDraftJobDetail.checklist
        : null,
      issue_type: this._ticketService.selectedDraftJobDetail.issue_type
        ? this._ticketService.selectedDraftJobDetail.issue_type
        : '',
      Priority: this._ticketService.selectedDraftJobDetail.priority
        ? this._ticketService.selectedDraftJobDetail.priority
        : null,
      service_type: this._ticketService.selectedDraftJobDetail.service_type
        ? this._ticketService.selectedDraftJobDetail.service_type
        : null
    });

    this.liveLocationData = this._ticketService.selectedDraftJobDetail.liveLocationData;
    this.selectedLocationItem = this._ticketService.selectedDraftJobDetail.selectedLocationItem;

    this.billablePartyInfo = this._ticketService.selectedDraftJobDetail.billablePartyInfo;
    this.unitBasedCategories = this._ticketService.selectedDraftJobDetail.unitBasedCategories;

    // New Lease Info
    if (this.selectedLocationItem?.lease_id) {
      this.leasesStoreService.getCategoriesRules(this.selectedLocationItem.lease_id)
        .subscribe(value => {
          this.unitBasedCategories = value || [];
          this.unitBasedCategories = this.unitBasedCategories.filter(obj => obj.category_id != 'global');
          this.unitBasedCategories.forEach(element => {
            element.id = element.category_id;
            element.name = element.category_name;
            element.ruleCount = element.rules?.length;
            element.handler = this.billablePartyInfo.tenant ? this.billablePartyInfo.tenant?.name : null;

            if (element.config == 'Net' || element.config == 'Gross') {
              element.responsible = (element.config == 'Net') ? 'Tenant' : 'Manager';
            }
            else {
              element.responsible = 'Both';
            }

          });
        });
    }


    // if (this.selectedCategoryItemsList) {
    //   // this.onChangeCategory({ id: this.selectedCategoryItemsList }, true);

    // }
    // else {
    //   // this.getAllCategories({}, false);
    // }
    this.getAllVendorsDropdown();
  }

  doSubmit() {

    if (this.createTicketForm.value.TargetCompletionDate)
      this.removeValidators(this.createTicketForm, ['TargetCompletionDate']);

    this.submitted = true;

    if (this.createTicketForm.invalid) {
      return;
    }

    if (this.createTicketForm.value.type == 'Emergency') {
      let selectedCategory: any =
        this._baseService.allWorkCategories.find(
          (obj) => obj.id == this.createTicketForm.value.MaintenanceCategory
        ) || {};

      let emergentVendors: any = this.AllEmergentVendorsList || {};

      let selectedVendorIDs = [];

      if (emergentVendors.vendors) {
        if (emergentVendors.vendors.length > 0) {
          selectedVendorIDs = emergentVendors.vendors.map((item) => item.id);

          this.selectedVendorsItems = selectedVendorIDs;

          if (this._ticketService.currentUserInfo.current_Role != 'Manager')
            this.createTicketForm.get('Vendors').patchValue(selectedVendorIDs);
        } else {
          // this.removeValidators(this.createTicketForm, ['Vendors']);
        }
      } else {
        // this.removeValidators(this.createTicketForm, ['Vendors']);
      }
    } else {
      // if ((this.createTicketForm.value.Vendors.length == 0)) {
      //   this.addValidators(this.createTicketForm, ['Vendors']);
      //   this.is_vendor_engineer_error = true;
      //   // this._utilService.showError('', 'Please select either Vendor or Engineer.');
      //   return;
      // }
      // this.is_vendor_engineer_error = false;
    }



    let photosList = this._ticketService.uploadPhotos.map((obj) => {
      return obj.id;
    });

    // let photosList = this.tempUploadedList.map((obj) => {
    //   return obj.id;
    // });
    let photosList_New = this.tempUploadedList.filter(e => e?.from !== 'checklist_item').map((obj) => {
      return obj.id;
    });
    let TargetCompletionDate = this._utilService.formatDate(
      this.createTicketForm.value.TargetCompletionDate
    );

    let checklistItem = '';
    let engineerRequired = false;
    if (this.createTicketForm.value.Engineers.length == 1) {
      if (this.createTicketForm.value.Engineers == 'no-engineer-required') {
        engineerRequired = true;
        this.createTicketForm.value.Engineers = [];
      }
    }

    if (this.isAllChecklist)
      checklistItem = this.createTicketForm.value.Checklist_All
        ? this.createTicketForm.value.Checklist_All
        : null;
    else
      checklistItem = this.createTicketForm.value.Checklist_Property
        ? this.createTicketForm.value.Checklist_Property
        : null;


    let priorityID: any;
    this._ticketService.PriorityTypesList.forEach(element => {
      if (element.name == this.createTicketForm.value.Priority) {
        priorityID = element.id
      }
    });

    if (this.current_account === 'thevillagescpm') {
      if ((Array.isArray(this.selectedVendorsItems) && this.selectedVendorsItems.length === 0) || this.selectedVendorsItems === null) {
        this.selectedVendorsItems = [];
      }
    }

    let selectedVendorsList = this.selectedVendorsItems;
    if (!Array.isArray(this.selectedVendorsItems)) {
      selectedVendorsList = [this.selectedVendorsItems];
    }

    let postBody: any = {
      service_type: this.createTicketForm.value.service_type,
      type: this.createTicketForm.value.type,
      request_type: this.createTicketForm.value.RequestType,
      property: this.createTicketForm.value.Property,
      category: this.createTicketForm.value.MaintenanceCategory,
      target_date: TargetCompletionDate,
      description: this.createTicketForm.value.Description
        ? this.createTicketForm.value.Description
        : '',
      access_instruction: this.createTicketForm.value.AccessInstructions,
      unit_entry_permission: this.createTicketForm.value.Permissiontoenter_unit
        ? this.createTicketForm.value.Permissiontoenter_unit
        : false,
      property_entry_permission: this.createTicketForm.value.Permissiontoenter
        ? this.createTicketForm.value.Permissiontoenter
        : false,
      skip_site_visit: !(this.createTicketForm.value.SkipSite ? this.createTicketForm.value.SkipSite : false),
      request_feedback_on_close: this.createTicketForm.value.request_feedback_on_close
        ? this.createTicketForm.value.request_feedback_on_close
        : false,
      request_auto_response: this.createTicketForm.value.request_auto_response
        ? this.createTicketForm.value.request_auto_response
        : false,
      skip_bid: !(this.createTicketForm.value.SkipBid ? this.createTicketForm.value.SkipBid : false),
      courtesy_job: this.createTicketForm.value.CourtesyJob
        ? this.createTicketForm.value.CourtesyJob
        : false,
      request_completion_photos: this.createTicketForm.value
        .RequestCompletionVerification
        ? this.createTicketForm.value.RequestCompletionVerification
        : false,
      _vendors: selectedVendorsList || [],
      is_automatic_approved: this.createTicketForm.value.is_automatic_approved
        ? (selectedVendorsList?.length === 1) ? this.createTicketForm.value.is_automatic_approved : false
        : false,
      followers: this.selectedFollowersItems,
      no_engineers_required: engineerRequired,
      engineers: this.selectedEngineerItems[0] === 'no-engineer-required' ? [] : this.selectedEngineerItems,
      photos: photosList,
      // pm_assignee: this.selectedAssigneesItems ?? this.createTicketForm.value.Assignee,
      issue_type: this.createTicketForm.value.issue_type,
      priority: priorityID,
      tenant_notify_job: (this.createTicketForm.value.request_auto_response ? this.createTicketForm.value.request_auto_response : false),
      notify_tenant: !(this.createTicketForm.value.disable_all_notification ? this.createTicketForm.value.disable_all_notification : false),
    };

    if (this.currentUserInfo.current_Role=='Manager' || this.currentUserInfo.current_Role=='Owner') {
      postBody.scope_and_instructions = {
      scope: this.createTicketForm.value.scope,
      instructions: this.createTicketForm.value.instructions
    }}

    if (this.selectedAssigneesItems && this.selectedAssigneesItems?.length > 0) {
      postBody.pm_assignee = this.selectedAssigneesItems || null;
    }
    else {
      postBody.pm_assignee = this.createTicketForm.value.Assignee || null;
    }

    postBody.assigned_checklist = this.selectedChecklistsItems || [];

    if (this.billablePartyInfo?.responsible) {
      postBody.responsible = this.billablePartyInfo?.responsible;
    }

    if (this._Config.isShowInbox) {
      if (this._ticketService.isEmailCreateTicket) {
        postBody.mail = this._ticketService.selectedEmailTemplate.id;
      } else if (this._ticketService.selectedDraftJobDetail) {
        if (this._ticketService.selectedDraftJobDetail.mailId) {
          postBody.mail = this._ticketService.selectedDraftJobDetail.mailId;
        }
      }


    }

    if (this.isCam_Selected) {
      let addressObj: any =
        this.AllLocationDropdownLists.find((obj: any) =>
          obj.subunits.find(
            (obj1: any) => obj1.id == this.createTicketForm.value.Cam_Address
          )
        ) || {};

      // postBody.address = this.createTicketForm.value.Cam_Address;
      postBody.address = addressObj.title;
      postBody.location = this.createTicketForm.value.Cam_Location
        ? this.createTicketForm.value.Cam_Location
        : '';
      postBody.unit = this.createTicketForm.value.Cam_Address;
    } else {
      postBody.unit = this.createTicketForm.value.Location;
    }


    if (this.selected_DraftJob?.id) {
      postBody.draft_job_id = this.selected_DraftJob?.id;
      postBody.service_form_contact = JSON.stringify(this.selected_DraftJob?.draft_items?.service_form_contact);
      postBody.source = this.selected_DraftJob?.draft_items?.source;
    }

    if (this._ticketService.createJobFromJobChecklist) {
      postBody.source = "Checklist";
    }


    if (this.isGlobalJobSettings && this.currentUserInfo.current_Role === 'Engineer') {
      delete postBody.skip_site_visit;
      delete postBody.skip_bid;
      delete postBody.request_completion_photos;
      delete postBody.request_auto_response;
      delete postBody.property_entry_permission;
      delete postBody.notify_tenant;
      delete postBody.tenant_notify_job;
      delete postBody.request_feedback_on_close;
      delete postBody.unit_entry_permission;
    }

    if (this.currentUserInfo.current_Role === 'Owner' || this.currentUserInfo.current_Role === 'Manager' ) {
      postBody.target_job_cost = this.createTicketForm.value.target_job_cost;
    }

    if(this.typeInbox) {
      postBody["source"] = 'Inbox'
    }

    this._Config.isLoader = true;
    const pastAssociateJobLength = this._inboxService.allAssociatedJobs.length;

    let requestURL = environment.LocalDev_URL_V2 + this._Config.createTicket;

    this._baseService.doPOST(requestURL, postBody).subscribe(
      (result: any) => {
        this._Config.isLoader = false;
        this.copyFilesUploadedList = this.copyFilesUploadedList.filter(e => e?.from === 'checklist_item').map((obj) => {
          return obj;
        });
        let copyFiles: any[] = [];
        if (this.copyFilesUploadedList.length > 0) {
          this.copyFilesUploadedList.forEach((obj) => {
            copyFiles.push({ fileName: obj.filename, key: obj.key, sourceBucket: obj.bucket });
          });
          this._companiesDetailedService.copyS3KeysAndSaveInTempLocation(copyFiles).subscribe((data: any) => {

            let tempPhotosList: any[] = [];
            if (data?.copyS3KeysAndSaveInTempLocation) {
              if (data.copyS3KeysAndSaveInTempLocation?.length > 0) {
                tempPhotosList = data.copyS3KeysAndSaveInTempLocation.map((obj) => {
                  return obj.id;
                });
              }
            }

            tempPhotosList = [...tempPhotosList, ...photosList_New];

            // Link Files to Job
            if (tempPhotosList.length > 0) {
              this._jobService.linkPhotosToJob(result.id, 'JOB', tempPhotosList, FOLDER_TYPE.PRE_COMPLETION, true);
            }
          }, (err: any) => {

          })
        } else {
          // Link Files to Job
          if (photosList_New.length > 0) {
            this._jobService.linkPhotosToJob(result.id, 'JOB', photosList_New, FOLDER_TYPE.PRE_COMPLETION, true);
          }
        }

        // Link Draft Job Files to current Job
        if (this.selected_DraftJob?.id && this.tempUploadedList_DraftFiles?.length > 0) {
          this._jobService.linkDraftJobToJobFiles(result.id, 'JOB', this.selected_DraftJob?.id, "DRAFT_JOB");
        }

        if (this._ticketService.createJobFromJobChecklist) {
          // this.addLinkRelatedJob(result.id);
          return this.dialogRef.close(result)
          // this._ticketService.createJobFromJobChecklist = false;
        }


        if (this._ticketService.selectedDraftJobDetail) {
          this._ticketService.getAllDraftJob.splice(
            this._ticketService.selectedDraftJobDetail.id - 1,
            1
          );
          const getEmailDraft = this._ticketService.getAllDraftJob.filter(
            (details: any) => {
              return details.minimizeCreateTicket === true;
            }
          );
          this._ticketService.draftJobName = getEmailDraft[0]?.name + 1;
          if (getEmailDraft.length === 0) {
            this._ticketService.draftJobName = 1;
          }
        }
        if (this._ticketService.getAllDraftJob.length >= 1) {
          let resetCount = 1;
          this._ticketService.getAllDraftJob.map((id: any) => {
            id.id = resetCount;
            resetCount = resetCount + 1;
          });
        }

        if(selectedVendorsList?.length > 0) {
          let requestURL = `${environment.fastAPI_Actual_URL}/companies/bulk-actions/`;
          let postBody: any = {
            properties_id: [this.createTicketForm.value.Property],
            companeies_id: selectedVendorsList
          };
          this._baseService.doPOST(requestURL, postBody).subscribe({
            next: (res: any) => {
              
            }
          })
        }
        
        this._utilService.showSuccess('', 'Ticket Created Successfully.');
        this._ticketService.uploadPhotos = [];
        this._photoBankService.photoBankViewObj.isCreateTicket = false;
        if (postBody.mail) {
          // if (!this.createAssociateService.isOpenFromNewInbox) {
          //   this._inboxService.updateAssociateJobList(result);
          // }
        }
        if (this._ticketService.getAllDraftJob.length == 0) {
          this._ticketService.draftJobName = 1;
        }
        if (
          this._ticketService.getCurrentActivePage() == '/pm' ||
          this._ticketService.getCurrentActivePage() == '/pm/dashboard' ||
          this._ticketService.getCurrentActivePage() == '/engineer' ||
          this._ticketService.getCurrentActivePage() == '/engineer/dashboard'
        ) {
          if (this._ticketService.currentUserInfo.current_Role == 'Engineer')
            this._ticketService.defaultActiveTab = 3;
          else this._ticketService.defaultActiveTab = 5;

          this._ticketService.searchParams.searchKeyword = '';
          this._ticketService.searchParams.timeline = '';
        }

        if (this._ticketService.getCurrentActivePage() == '/pm/inbox' || this._baseService.currentActiveState.includes('/pm/inbox')) {
          // if (this.createAssociateService.isOpenFromNewInbox) {
          //   this.messageDetailsService.fetchAssociatedJobInformation(
          //     this.createAssociateService.activeMessage
          //   );
          //   this.createAssociateService.isOpenFromNewInbox = false;
          //   this.createAssociateService.createTicketDataResponse = {};
          //   this.messageDetailsService.isAddAssociateJob = true;
          //   this.messageService.fetchMailsAndCount(false);
          // } else {
          //   this._inboxService.getAllMailList(true);
          //   if (
          //     this._inboxService.selectedMailDetail &&
          //     this._inboxService.selectedMailDetail.id
          //   ) {
          //     this._inboxService.getAssignedManager();
          //     if (pastAssociateJobLength === 0) {
          //       this._inboxService.selectedMailDetail.label[0].name =
          //         result.issue_type;
          //     }
          //   }
          //   this._inboxService.getMailDashboardCount();
          // }
        }

        if (this.createTicketForm.value.type == 'Emergency') {
          this.isEmergentSubmited = true;

          this.selectedEmergentCategoryItems =
            this._baseService.allWorkCategories.find(
              (obj) => obj.id == this.createTicketForm.value.MaintenanceCategory
            ) || {};
        } else {
          // Close poup with the create job request result if it is open from the job checklist item
          if (this._ticketService.createJobFromJobChecklist) {
            return this.dialogRef.close(result)
          }



          if(!this.typeInbox) {
            this.triggerFalseClick();
          }

        }

        this._ticketService.uploadPhotos = [];

        this.selectedPropertyItemsList = null;
        this.selectedLocationItemsList = null;

        this.isInboxTicketEvent();

        // this._ticketService.listAllTileCounts();
        this._ticketService.listJobsBySlectedAction(false);


        /************ New Job Action Triggers ***************/
        /** Pusher Trigger for realtime */
        this.triggerPusher_Realtime_Row_Updates(result.id);


        this._jobService.isJobAdded.next(true);

        if (this.isNewUI) {
          this.dialogRef.close(result);
        }

        if (this.typeInbox) {
          if(this.data.attachments.length > 0) {
            console.log("Has attachments",result);
            return this.autoAssignPhotos(this.data.attachments,result);
          } else {
            console.log("No attachments",result);
            return this.dialogRef.close(result);
          }
        }

      },
      (error: HttpErrorResponse) => {
        this._Config.isLoader = false;
        this._utilService.showErrorMessage(error);
      }
    );
  }



  //Pusher Trigger for new job update
  triggerPusher_Realtime_Row_Updates(JobID: any) {

    let requestURL = environment.LocalDev_URL_V2 + 'jobs/{JobID}/realtime_row_updates/';
    requestURL = requestURL.replace('{JobID}', JobID);

    this._baseService.doGET(requestURL).subscribe((response: any) => {

    },
      (error) => { });
  }



  addLinkRelatedJob(relatedJobID: any) {
    let requestURL = environment.baseURL + this._Config.updateTicket;
    requestURL = requestURL.replace("{ID}", this._jobService.selectedJobInfo.id);

    let payload = {
      "linked_job": [relatedJobID],
      "linked_type": "Related"
    };
    this._Config.isLoader = true;
    this._ticketService.doPatch(requestURL, payload).subscribe((response: any) => {

    }, (error) => {

      // this._Config.isLoader = false;
      // this._utilService.showErrorMessage(error);

    });
  }


  triggerFalseClick() {
    console.log("triggerFalseClick Called");

    this.dialogRef.close('Closed');

    // this.initCreateTicketForm();
    this._ticketService.deleteUploadedFiles();

    // this.isInboxTicketEvent();
    this._ticketService.isEmailCreateTicket = false;
    // this._ticketService.selectedEmailTemplate = {};

    this._ticketService.isShowCreateTicketModal = false;
    this._ticketService.showCreaateTicket = false;
    // this.createAssociateService.isOpenFromNewInbox = false;
    // this.createAssociateService.createTicketDataResponse = {};
    this._ticketService.uploadPhotos = [];
    this._photoBankService.photoBankViewObj.isCreateTicket = false;



  }

  isInboxTicketEvent() {
    if (this._Config.isShowInbox) {
      if (this._ticketService.isEmailCreateTicket) {
        this._inboxService.getAssociateJob();

        this._ticketService.isEmailCreateTicket = false;
        this._ticketService.selectedEmailTemplate = {};
        this._ticketService.showCreaateTicket = false;

        this._inboxService.rightSideTabChangeEvents(3);
      }
    }
  }

  /***************** Change Ticket Type Event *******************/

  changeTicketType(selectedItem: any) {

    if (selectedItem == 'Emergency') {
      this.isEmergentSubmited = false;
      // this.createTicketForm.get('Property').patchValue(null);
      this.AllEmergentVendorsList = [];

      this.removeValidators(this.createTicketForm, ['Vendors']);
      // this.createTicketForm.get('RequestType').patchValue('In Unit');
    } else {
      // this.createTicketForm.reset();
      this.isEmergentSubmited = false;
      // this.addValidators(this.createTicketForm, ['Vendors']);

      this.createTicketForm.get('type').patchValue(selectedItem);
      this.createTicketForm.get('TargetCompletionDate').patchValue(new Date());
    }

    this.submitted = false;
    // this.selectedVendorsItems = []; by Nirmal
    // this.selectedEngineerItems = []; by Nirmal

    // this.createTicketForm.get('Vendors').patchValue([]);
    // this.createTicketForm.get('TargetCompletionDate').patchValue(new Date());
    this.BillableParty = [];
    this.viewBillableParty = false;
    this.selctedCategoryObject = {};
    this.selectedLocationItem = {};
    this.AllLocationDropdownLists = [];
    // this.createTicketForm.get('Location').patchValue(null);

    this.inTicketChange();
  }

  inTicketChange() {
    if (this._ticketService.selectedDraftJobDetail !== undefined) {
      let property_ID = this._ticketService.selectedDraftJobDetail.property
        ? this._ticketService.selectedDraftJobDetail.property
        : null;
      let tenant_ID = this._ticketService.selectedDraftJobDetail.tenant
        ? this._ticketService.selectedDraftJobDetail.tenant.id
        : null;
      let Location = this._ticketService.selectedDraftJobDetail.unit
        ? this._ticketService.selectedDraftJobDetail.unit
        : null;
      let Description = this._ticketService.selectedDraftJobDetail.description
        ? this._ticketService.selectedDraftJobDetail.description
        : null;

      let setValueObj = {
        Property: property_ID,
        Location: Location,
        Description: Description
      };
      this.createTicketForm.patchValue(setValueObj);
      this.getAllLocationDropdown(property_ID);
      this.getAllCategoriesDropdown(property_ID, Location);
      this.getAllCategories({}, false);
    }
    // this.subscription.unsubscribe();
  }

  /***************** Change Request Type Event *******************/

  onChangeRequestType(selectedItem: any) {
    if (this.createTicketForm.value.type == 'Emergency') {
      this.isEmergentTicketChanges(selectedItem);
    } else {
      this.isRegularTicketChanges(selectedItem);
    }

    this._baseService.allWorkCategories = [];
    this.AllCategoriesDropdownLists = [];
    if (!this.selectedPropertyItemsList) {
      this.createTicketForm.get('Assignee').patchValue(null);
      this._ticketService.job_AllAssigneeDropdownLists = [];
    }
    if (!this.selectedPropertyItemsList) {
      this.createTicketForm.get('Followers').patchValue(null);
      this._ticketService.job_AllFollwersDropdownLists = [];
    }
    // this._ticketService.job_selectedFollowersItems = [];
    // this.createTicketForm.get('Assignee').patchValue(null);
    if (this.createTicketForm.value.Property) {
      this._ticketService.getAllAssigneesList(
        this.createTicketForm.value.Property
      );
    }
  }

  /**
   * if reqular Ticket
   * @param selectedItem
   */
  isRegularTicketChanges(selectedItem: any) {
    if (selectedItem.value === 'Common Area') {
      this.isCam_Selected = true;

      this.addValidators(this.createTicketForm, ['Cam_Address']);
      this.removeValidators(this.createTicketForm, [
        'Location',
        'Cam_Location'
      ]);

      let locationID = this.createTicketForm.value.Location
        ? this.createTicketForm.value.Location
        : null;

      // this.createTicketForm.get('Property').patchValue(null);
      // this.createTicketForm.get('Location').patchValue(null);
      this.createTicketForm.get('Cam_Address').patchValue(locationID);
      this.createTicketForm.get('Cam_Location').patchValue(null);
      // this.createTicketForm.get('MaintenanceCategory').patchValue(null);
    } else {
      this.isCam_Selected = false;

      this.addValidators(this.createTicketForm, ['Location']);
      this.removeValidators(this.createTicketForm, [
        'Cam_Address',
        'Cam_Location'
      ]);

      let locationID = this.createTicketForm.value.Cam_Address
        ? this.createTicketForm.value.Cam_Address
        : null;

      // this.createTicketForm.get('Property').patchValue(null);
      this.createTicketForm.get('Location').patchValue(locationID);
      // this.createTicketForm.get('Cam_Address').patchValue(null);
      this.createTicketForm.get('Cam_Location').patchValue(null);
      // this.createTicketForm.get('MaintenanceCategory').patchValue(null);
    }

    let propertyID = this.createTicketForm.value.Property;

    if (propertyID) {
      if (this.isCam_Selected) {
        this.getAllAddressesDropdown(propertyID);
        this.getAllCategoriesDropdown(propertyID);
        this.getAllCategories({});
      } else {
        this.getAllLocationDropdown(propertyID);
        this.getAllCategoriesDropdown(propertyID);
        this.getAllCategories({});
      }
    }
  }

  /**
   * If Emergency Ticket
   * @param selectedItem
   */
  isEmergentTicketChanges(selectedItem: any) {
    if (selectedItem.value === 'Common Area') {
      this.isCam_Selected = true;

      this.addValidators(this.createTicketForm, ['Cam_Address']);
      this.removeValidators(this.createTicketForm, [
        'Location',
        'Cam_Location'
      ]);

      let locationID = this.createTicketForm.value.Location
        ? this.createTicketForm.value.Location
        : null;

      // this.createTicketForm.get('Property').patchValue(null);
      // this.createTicketForm.get('Location').patchValue(null);
      this.createTicketForm.get('Cam_Address').patchValue(locationID);
      this.createTicketForm.get('Cam_Location').patchValue(null);
      // this.createTicketForm.get('MaintenanceCategory').patchValue(null);
    } else {
      this.isCam_Selected = false;

      this.addValidators(this.createTicketForm, ['Location']);
      this.removeValidators(this.createTicketForm, [
        'Cam_Address',
        'Cam_Location'
      ]);

      let locationID = this.createTicketForm.value.Cam_Address
        ? this.createTicketForm.value.Cam_Address
        : null;

      // this.createTicketForm.get('Property').patchValue(null);
      this.createTicketForm.get('Location').patchValue(locationID);
      // this.createTicketForm.get('Cam_Address').patchValue(null);
      this.createTicketForm.get('Cam_Location').patchValue(null);
      // this.createTicketForm.get('MaintenanceCategory').patchValue(null);
    }

    let propertyID = this.createTicketForm.value.Property;

    if (propertyID) {
      if (this.isCam_Selected) {
        this.getAllAddressesDropdown(propertyID);
        this.getAllCategoriesDropdown(propertyID);
        this.getAllCategories({});
      } else {
        this.getAllLocationDropdown(propertyID);
        this.getAllCategoriesDropdown(propertyID);
        this.getAllCategories({});
      }
    }
  }



  onChangeServiceType(event: any) {
    // if(!this._ticketService?.selectedDraftJobDetail){
      this.getChecklistsbyCategory_ServiceType();
      this.getTargetCost();
    // }
  }

  /***************** Change Property Event *******************/

  onChangeProperty(selectedItem: any, isInbox?: any) {

    if (selectedItem) {

      this.selectedPropertyObj = this.allPropertyPagination.tempData?.filter((obj:any) => obj.id == selectedItem)?.map((obj:any) => { 
        let payloadData = { id: obj.id, name: obj.name }; 
        return payloadData; 
      }) || [];
  

      this._baseService.allWorkCategories = [];
      this.AllCategoriesDropdownLists = [];
      this._ticketService.job_AllAssigneeDropdownLists = [];
      this.isClearProperty = false;

      // this.selctedCategoryObject = {};
      // this.selectedLocationItem = {};
      // this.selectedLocationItemsList = isInbox
      //   ? this.selectedLocationItemsList
      //   : null;
      // this.selectedCategoryItemsList = null;
      // this.liveCategoryData = null;

      this.createTicketForm.get('Property').patchValue(selectedItem);
      
      if(this._baseService.currentUserInfo?.current_Role!='Engineer'){
        this.createTicketForm.get('Assignee').patchValue(this.currentUserInfo?.id || null);
      }

      if (this.isDraft_New) {
        // this.getAllVendorsDropdown(this._ticketService.selectedDraftJobDetail.searchTerm_Vendor);
        this.isDraft_New = false;
      }
      else {
        // this.getAllVendorsDropdown();   //Get All Vendors by Property
        this.selctedCategoryObject = {};
        this.selectedLocationItem = {};
        this.selectedCategoryItemsList = null;
        this.liveCategoryData = null;

        this.createTicketForm.get('Location').patchValue(isInbox ? this.selectedLocationItemsList : null);
        this.createTicketForm.get('Cam_Address').patchValue(null);
        this.createTicketForm.get('Cam_Location').patchValue(isInbox ? this.selectedLocationItemsList : null);

        this.createTicketForm.get('MaintenanceCategory').patchValue(null);
        this.createTicketForm.get('Vendors').patchValue([]);

        this.selectedVendorsItems = [];
        this.selectedFollowersItems = [];
        this.selectedEngineerItems = [];
        this.selectedAssigneesItems = [];
        this.selectedLocationItemsList = null;

        if (this.currentUserInfo?.id && (this.currentUserInfo?.current_Role == 'Manager' || this.currentUserInfo?.current_Role == 'Owner')) {
          /*********** If Job Info Loaded *************/
          this._ticketService.isCheckAssignees.subscribe((listedManagers: any) => {
            if (Array.isArray(listedManagers)) {
              let isExistUser = listedManagers.some(obj => obj.id === this.currentUserInfo?.id);
              if (isExistUser) {
                this.selectedAssigneesItems = this.currentUserInfo?.id;
                this.createTicketForm.get('Assignee').patchValue(this.currentUserInfo?.id);
              }
            }
          });

        }
      }

      this.getAllLocationDropdown(selectedItem);


      this._ticketService.getAllAssigneesList(selectedItem);
      this._ticketService.getAllEnginnersList(selectedItem);
      this._ticketService.getAllFollowersList(selectedItem);




      // this.onChangePropertyClearSelectedItems();
      // this.getAllLocations({}, false);
    } else {
      this.liveLocationData = this.getlocationResponse([]);
      this.liveCategoryData = this.getCategoryResponse([]);
      // this.selectedLocationItemsList = null;
      this.selectedCategoryItemsList = null;
    }

    this.getTargetCost();
  }

  setGlobalPermissions() {
    if (this.isGlobalJobSettings) {

      if (this.currentUserInfo.current_Role !== "Owner") {
        this.override_job_settings = !this._baseService.checkUserPermissions('override_job_settings');
      }

      if (this.currentUserInfo.current_Role === "Owner" || this.currentUserInfo.current_Role === "Manager") {
        if(!this.isMinimize){
          this.createTicketForm.patchValue({
            SkipSite: this.globalSettings?.require_site_visit,
            SkipBid: this.globalSettings?.require_bid,
            RequestCompletionVerification: this.globalSettings?.request_completion_photos,
            request_auto_response: this.globalSettings?.tenant_notify_job,
            Permissiontoenter: this.globalSettings?.property_entry_permission,
            disable_all_notification: !this.globalSettings?.notify_tenant,
            request_feedback_on_close: this.globalSettings?.request_feedback_on_close
          });
        }
      }

    }

  }


  /***************** Change Location Event *******************/

  onChangeLocation(selectedItem: any) {
    if (selectedItem) {

      if (selectedItem.lease_id) {
        this.selectedLocationItem = selectedItem;
        this.selctedCategoryObject = {};
        this.isClearLocation = false;

        if (this.createTicketForm.value.RequestType == 'Common Area') {
          this.createTicketForm.get('Cam_Address').patchValue(selectedItem.id);
        } else {
          this.createTicketForm.get('Location').patchValue(selectedItem.id);
        }

        if (selectedItem.status == 'Vacant')
          this.createTicketForm.get('CourtesyJob').patchValue(true);
        else if (!(selectedItem?.tenant && selectedItem?.tenant?.onboarded))
          this.createTicketForm.get('CourtesyJob').patchValue(true);
        else this.createTicketForm.get('CourtesyJob').patchValue(false);

        // this.getAllCategoriesDropdown(this.createTicketForm.value.Property, selectedItem.id);
        this.getAllCategories({}, false);


        this.createTicketForm.get('MaintenanceCategory').patchValue(null);
        this.selectedCategoryItemsList = null;

        // New Lease Info
        if (this.selectedLocationItem?.lease_id) {
          this.leasesStoreService.getCategoriesRules(this.selectedLocationItem.lease_id)
            .subscribe(value => {
              this.unitBasedCategories = value || [];
              this.unitBasedCategories = this.unitBasedCategories.filter(obj => obj.category_id != 'global');
              this.unitBasedCategories.forEach(element => {
                element.id = element.category_id;
                element.name = element.category_name;
                element.ruleCount = element.rules?.length;
                element.handler = selectedItem.tenant ? selectedItem.tenant?.name : null;

                if (element.config == 'Net' || element.config == 'Gross') {
                  element.responsible = (element.config == 'Net') ? 'Tenant' : 'Manager';
                }
                else {
                  element.responsible = 'Both';
                }

              });
            });
        }



      } else {

        this.selectedLocationItem = selectedItem;
        this.selctedCategoryObject = {};
        this.isClearLocation = false;

        if (this.createTicketForm.value.RequestType == 'Common Area') {
          this.createTicketForm.get('Cam_Address').patchValue(selectedItem.id);
        } else {
          this.createTicketForm.get('Location').patchValue(selectedItem.id);
        }

        if (selectedItem.status == 'Vacant')
          this.createTicketForm.get('CourtesyJob').patchValue(true);
        else if (!selectedItem.tenant.onboarded)
          this.createTicketForm.get('CourtesyJob').patchValue(true);
        else this.createTicketForm.get('CourtesyJob').patchValue(false);

        // this.getAllCategoriesDropdown(this.createTicketForm.value.Property, selectedItem.id);
        this.getAllCategories({}, false);


        this.createTicketForm.get('MaintenanceCategory').patchValue(null);

        // this.liveCategoryData = this.getCategoryResponse([]);
        this.selectedCategoryItemsList = null;
      }


      this.setGlobalPermissions();
    }


  }

  /***************** Change Category Event *******************/

  onChangeCategory(selectedItem: any, isDraft?: any) {

    if (!selectedItem?.category_id) {
      selectedItem = this.unitBasedCategories.find(obj => obj.id == selectedItem.id) || selectedItem;
    }
    
    this.selectedCategoryObj=selectedItem || {};

    this._ticketService.billablePartyItems = selectedItem;

    // New Lease Info
    this.billablePartyInfo = selectedItem || {};
    this.billablePartyInfo.lease_id = this.selectedLocationItem.lease_id;


    if (selectedItem?.id) {
      this.createTicketForm
        .get('MaintenanceCategory')
        .patchValue(selectedItem.id);
      this.isClearCategory = false;
    } else {
      this.createTicketForm.get('MaintenanceCategory').patchValue(null);
    }
    if (selectedItem?.responsible == 'Tenant') {
      if (this.selectedLocationItem.tenant) {
        if (this.selectedLocationItem.tenant.onboarded)
          this.createTicketForm
            .get('CourtesyJob')
            .patchValue(true); // if onboarded
        else this.createTicketForm.get('CourtesyJob').patchValue(true);
      } else {
        this.createTicketForm.get('CourtesyJob').patchValue(true);
      }
    } else {
      this.createTicketForm.get('CourtesyJob').patchValue(false);
    }

    this.selctedCategoryObject = selectedItem || {};


    // if (isDraft)
    //   this.getAllVendorsDropdown(this._ticketService.selectedDraftJobDetail.searchTerm_Vendor);
    // else
    //   this.getAllVendorsDropdown();

    if(this.current_account=="bcwoodproperties"){
      if (this._ticketService.createJobFromJobChecklist && (this._jobService.selectedJobInfo && this._jobService.selectedJobInfo?.category)) {
        let propertyData=[{'id':this._jobService.selectedJobInfo?.property?.id,'name':this._jobService.selectedJobInfo?.property?.name}];
        this.selectedPropertyObj=propertyData;
        this.selectedCategoryObj=this._jobService.selectedJobInfo?.category;
      }

      this.isGetVendorByProperty=true;
      this.getAllVendorsDropdown();
    }
    else{
      this.isGetVendorByProperty=false;
    }

    if (!isDraft) {
      if (!this.isMinimize) {
        this.selectedVendorsItems = [];
        this.selectedFollowersItems = [];
        this.selectedEngineerItems = [];
        this.createTicketForm.get('Vendors').patchValue([]);
        this.createTicketForm.get('Followers').patchValue([]);
        this.createTicketForm.get('Engineers').patchValue([]);
      }
    }

    // if (!this._ticketService?.selectedDraftJobDetail) {
      this.getChecklistsbyCategory_ServiceType();
      this.getTargetCost();
    // }

    this.setGlobalPermissions();


  }


  /**
  * Get All checklist by category and service type based
  */
  getChecklistsbyCategory_ServiceType() {

    if (this.createTicketForm.value.MaintenanceCategory && this.createTicketForm.value.service_type) {

      let requestURL = environment.LocalDev_URL_V2 + 'checklist-template/job-eligible-checklists-dropdown/';

      let postBody = {
        "assigned_category": [
          this.createTicketForm.value.MaintenanceCategory
        ],
        "assigned_service_type": [
          this.createTicketForm.value.service_type
        ],
        "assigned_property": [
          this.createTicketForm.value.Property
        ]
      };

      this._baseService.doPOST(requestURL, postBody).subscribe(
        (response: any) => {
          if (response.length > 0) {
            this.selectedChecklistsItems = response || [];
          }
        },
        (error: HttpErrorResponse) => {
          this._utilService.showErrorMessage(error);
        }
      );

    }

  }

  /**
  * Get Target Cost by Property, Category and service type based
  */
  getTargetCost() {

    if (this.createTicketForm.value.MaintenanceCategory && this.createTicketForm.value.service_type && this.createTicketForm.value.Property) {

      let requestURL = environment.LocalDev_URL_V2 + 'jobs/eligible-target-cost/';

      let postBody = {
        "category": this.createTicketForm.value.MaintenanceCategory,
        "service_type": this.createTicketForm.value.service_type,
        "property": this.createTicketForm.value.Property
      };

      this._baseService.doPOST(requestURL, postBody).subscribe(
        (response: any) => {
          this.createTicketForm.get('target_job_cost').patchValue(response.target_cost);
        },
        (error: HttpErrorResponse) => {
          this._utilService.showErrorMessage(error);
        }
      );

    }

  }


  /***************** Change Address Event *******************/

  onChangeAddress(selectedItem: any) {
    if (selectedItem.id) {
      this.getAllCategoriesDropdown(selectedItem.property_id, selectedItem.id);
    }
  }

  /***************** Change Service Type Event *******************/

  /**
   * Get all service Types
   * @param searchTerm
   */
  getAllServiceTypeDropdown(searchTerm?: any): void {
    let requestURL = '';
    requestURL = environment.baseURL + this._Config.serviceTypeDropdown;

    this.serviceTypeLoading = true;

    this._ticketService.doGET(requestURL).subscribe(
      (response: any) => {

        let selectedServiceType: any = response.find(element => element.is_default) || {};
        this.liveServiceTypeData = this.getServiceType(response);
        this.createTicketForm.get('service_type').patchValue(selectedServiceType?.id);
        if (this._ticketService.createJobFromJobChecklist && (this._jobService.selectedJobInfo && this._jobService.selectedJobInfo?.service_type?.id)) {
          this.createTicketForm.get('service_type').patchValue(this._jobService.selectedJobInfo?.service_type?.id);
        }

        // setTimeout(() => {
        //   this.serviceTypeLoading = false;
        // }, 2000);
      },
      (error) => {
        this.serviceTypeLoading = false;
      },
      () => {
        this.serviceTypeLoading = false;
      }
    );
  }

  getServiceType(response): Observable<any[]> {
    return of(response).pipe(delay(500));
  }

  /***************** Change Assignee Event *******************/

  onChangeAssignee(selectedManager: any) {

    if (Array.isArray(selectedManager)) {
      if (selectedManager.length != undefined) {
        // const selected = selectedVendors.map((item) => item.id);
        const selected = selectedManager;

        this.selectedAssigneesItems = selected;

        this.createTicketForm.get('Assignee').patchValue(selected);
      }
    }

  }


  /***************** Change Vendors Event *******************/

  onChangeVendors(selectedVendors: any) {

    if (Array.isArray(selectedVendors)) {
      if (selectedVendors.length != undefined) {
        // const selected = selectedVendors.map((item) => item.id);
        const selected = selectedVendors;

        this.selectedVendorsItems = selected;

        this.createTicketForm.get('Vendors').patchValue(selected);
        this.createTicketForm.get('is_automatic_approved').patchValue(true);
        
      }
    }

  }

  /***************** Change Followers Event *******************/

  onChangeFollowers(selectedFollowers: any) {

    if (Array.isArray(selectedFollowers)) {
      if (selectedFollowers.length != undefined) {
        const selected = selectedFollowers;

        this.selectedFollowersItems = selected;

        this.createTicketForm.get('Followers').patchValue(selected);
      }
    }

  }
  /***************** Select all Vendors Event *******************/

  onSelectAllVendors(selectedVendors) {
    if (selectedVendors) {
      const selected = this._ticketService.job_AllVendorsDropdownLists.map((item) => item.id);
      this.createTicketForm.get('Vendors').patchValue(selected);

      this.selectedVendorsItems = selected;
    } else {
      this.createTicketForm.get('Vendors').patchValue([]);

      this.selectedVendorsItems = [];
    }
  }

  /***************** Select all Followers Event *******************/

  onSelectAllFollowers(selectedFollowers) {
    if (selectedFollowers) {
      const selected = this._ticketService.job_AllFollwersDropdownLists.map((item) => item.id);
      this.createTicketForm.get('Followers').patchValue(selected);

      this.selectedFollowersItems = selected;
    } else {
      this.createTicketForm.get('Followers').patchValue([]);

      this.selectedFollowersItems = [];
    }
  }

  /***************** Change Checklist Event *******************/

  onChangeChecklists(selectedChecklists: any) {

    if (Array.isArray(selectedChecklists)) {
      if (selectedChecklists.length != undefined) {
        const selected = selectedChecklists;

        this.selectedChecklistsItems = selected;

        // this.createTicketForm.get('Followers').patchValue(selected);
      }
    }

  }

  /***************** Change Engineer Event *******************/
  onChangeEngineer_bk1(selectedItems: any) {
    if (Array.isArray(selectedItems)) {
      let indexVal = selectedItems.findIndex(
        (element) => element.id == 'no-engineer-required'
      );

      this.previousEngineers = selectedItems.filter(
        (element) => element.id !== 'no-engineer-required'
      );

      if (indexVal != -1) {
        let enggData = [
          {
            company: 'No Engineer Required',
            full_name: 'No Engineer Required',
            id: 'no-engineer-required',
            photo: ''
          }
        ];
        const selectedData = enggData.map((item) => item.id);
        this.selectedEngineerItems = selectedData;
        this.createTicketForm.get('Engineers').patchValue(selectedData);
        return false;
      }

      if (selectedItems?.length != undefined) {
        const selected = selectedItems.map((item) => item.id);

        this.selectedEngineerItems = selected;

        this.createTicketForm.get('Engineers').patchValue(selected);
      }

    }



  }

  /**
  * On change Engineer Dropdown Event
  * @param selectedItems
  */
  onChangeEngineer(selectedItems: any) {
    if (Array.isArray(selectedItems)) {
      let indexVal = selectedItems.findIndex(
        (obj) => obj.id == 'no-engineer-required'
      );

      if (indexVal == -1) {
        this.previousEngineers = selectedItems.filter(
          (element) => element.id !== 'no-engineer-required'
        );
      }

      if (indexVal != -1) {
        let ExisitngValueArr = this.previousEngineers.filter(
          (obj) => {
            return selectedItems.some((f) => {
              return f.id != obj.id;
            });
          }
        );

        let indexVal = selectedItems
          .filter((obj) => {
            return !this.previousEngineers.some((f) => {
              return f == obj.id || f.id == obj.id;
            });
          })
          .findIndex((obj) => obj.id == 'no-engineer-required');

        if (ExisitngValueArr.includes('no-engineer-required') || indexVal != -1) {
          let listAllOtherUsers =
            selectedItems.filter((obj) => {
              return !this.previousEngineers.some((f) => {
                return f == obj.id || f.id == obj.id;
              });
            }) || [];
          this.previousEngineers = listAllOtherUsers;
          let selectedData = listAllOtherUsers.map(
            (obj) => obj.id
          );

          this.selectedEngineerItems = selectedData;
          this.createTicketForm.get('Engineers').patchValue(selectedData);

        } else {
          let indexVal = selectedItems
            .filter((obj) => {
              return !this.previousEngineers.some((f) => {
                return f.id == obj.id;
              });
            })
            .findIndex((obj) => obj.id == 'no-engineer-required');

          if (indexVal == -1) {
            let listAllOtherUsers =
              selectedItems.filter((obj) => obj.id != 'no-engineer-required') ||
              [];
            this.previousEngineers = listAllOtherUsers;

            let selectedData = listAllOtherUsers.map(
              (obj) => obj.id
            );

            this.selectedEngineerItems = selectedData;
            this.createTicketForm.get('Engineers').patchValue(selectedData);

          } else {
            this.selectedEngineerItems = ['no-engineer-required'];
            this.previousEngineers = ['no-engineer-required'];
          }
        }

      } else {
        this.previousEngineers = selectedItems;
      }

    }


  }

  /***************** Select all Engineer Event *******************/

  onSelectAllEngineer(selectedItems) {
    if (selectedItems) {
      const selected = this._ticketService.job_AllEngineersDropdownLists.map(
        (item) => item.id
      );

      selected.shift();

      this.createTicketForm.get('Engineers').patchValue(selected);

      this.selectedEngineerItems = selected;
    } else {
      this.createTicketForm.get('Engineers').patchValue([]);

      this.selectedEngineerItems = [];
    }
  }

  /**
   * Get All Locations Dropdown
   */
  getAllAddressesDropdown(id: any) {
    let requestURL =
      environment.baseURL + this._Config.getAllAddressesByProperty;
    requestURL = requestURL.replace('{ID}', id);

    this._baseService.doGET(requestURL).subscribe(
      (response: any) => {
        this._Config.isLoader = false;

        let allAddresses = [];
        if (response.length > 0) {
          response.forEach((element: any) => {
            let itemObj = { name: element };

            allAddresses.push(itemObj);
          });
        }

        this.AllAddressesDropdownLists = allAddresses;
      },
      (error) => { }
    );
  }

  /**
   * Get All Locations Dropdown
   */
  getAllLocationDropdown(id: any) {
    let requestURL =
      environment.baseURL + this._Config.getLocationAllUnitsByProperty;
    requestURL = requestURL.replace('{ID}', id);

    this.locationLoading = true;

    this._baseService.doGET(requestURL).subscribe(
      (response: any) => {
        this._Config.isLoader = false;

        let allLocations = [];
        if (response.length > 0) {
          response.forEach((element: any, index) => {
            let units = [];
            element.units.forEach((unit: any) => {
              let search_label = element.address;
              search_label += ', ' + unit.name;

              let unitObj = {
                id: unit.id,
                lease_id: unit.lease_id,
                title: unit.name,
                status: unit.status,
                tenant: unit.tenant,
                property_id: id,
                search_label: search_label
              };

              units.push(unitObj);
            });

            let unitsObj = {
              id: index + 1,
              title: element.address,
              subunits: units
            };

            allLocations.push(unitsObj);
          });
        }

        this.AllLocationDropdownLists = allLocations;

        this.liveLocationData = this.getlocationResponse(allLocations);


        if (this.data?.property?.location?.length) {
          if (this.data?.property?.location[0]?.units?.length == 1 && this.AllLocationDropdownLists?.length > 0) {
            this.selectedLocationItemsList = this.data?.property?.location[0]?.units[0].id;
            this.onChangeLocation(this.data?.property?.location[0]?.units[0]);
          }
        }


        if (this._ticketService.createJobFromJobChecklist && (this._jobService.selectedJobInfo && this._jobService.selectedJobInfo?.unit)) {
          this.createTicketForm.get('Location').patchValue(this._jobService.selectedJobInfo?.unit?.id);
          this.selectedLocationItemsList = this._jobService.selectedJobInfo?.unit?.id
          this.selectedLocationItem = this._jobService.selectedJobInfo?.unit;

          this.selectedCategoryItemsList = this._jobService.selectedJobInfo?.category?.id;

          // New Lease Info
          if (this.selectedLocationItem?.lease_id) {
            this.leasesStoreService.getCategoriesRules(this.selectedLocationItem.lease_id)
              .subscribe(value => {
                this.unitBasedCategories = value || [];
                this.unitBasedCategories = this.unitBasedCategories.filter(obj => obj.category_id != 'global');
                this.unitBasedCategories.forEach(element => {
                  element.id = element.category_id;
                  element.name = element.category_name;
                  element.ruleCount = element.rules?.length;
                  element.handler = this.billablePartyInfo.tenant ? this.billablePartyInfo.tenant?.name : null;

                  if (element.config == 'Net' || element.config == 'Gross') {
                    element.responsible = (element.config == 'Net') ? 'Tenant' : 'Manager';
                  }
                  else {
                    element.responsible = 'Both';
                  }

                });
                this.setChecklistFrmData();

              });
          }
          else {
            if (this.selectedLocationItemsList && !this.selectedLocationItem?.lease_id) {
              this.getAllCategories({}, false);
            }
          }
        }



        if (this.selectedLocationItemsList && !this.selectedLocationItem?.lease_id) {
          this.getAllCategories({}, false);
        }

        this.locationLoading = false;

      },
      (error) => {
        this.locationLoading = false;
      }
    );
  }

  /**
   * Get All Categories Dropdown
   */
  getAllCategoriesDropdown(id: any, UnitID?: any) {
    let requestURL =
      environment.baseURL + this._Config.getAllCategoriesByProperty;
    requestURL = requestURL.replace('{ID}', id);

    if (UnitID) requestURL = requestURL + '?unit=' + UnitID;

    if (this.createTicketForm.value.RequestType == 'Common Area') {
      if (requestURL.includes('?'))
        requestURL = requestURL + '&request_type=Common Area';
      else requestURL = requestURL + '?request_type=Common Area';
    }

    this._baseService.allWorkCategories = [];
    this.AllCategoriesDropdownLists = [];

    this._baseService.doGET(requestURL).subscribe(
      (response: any) => {
        this.AllCategoriesDropdownLists = response || [];
        this._baseService.allWorkCategories = response || [];
      },
      (error) => { }
    );
  }

  /**
   * Get All Vendors Dropdown
   */
  getAllVendorsDropdown_bk(searchTerm?: any, isPagination?: boolean): void {
    if (this.createTicketForm.value.MaintenanceCategory) {

      let requestURL = '';
      if (isPagination) {
        if (this.allVendorsPagination.pagination.next) {
          requestURL = this.allVendorsPagination.pagination.next;
        } else {
          requestURL =
            environment.baseURL + this._Config.getAllVendorsByProperty;
        }
      } else {

        requestURL = environment.baseURL + this._Config.getAllVendorsByProperty;

        if (searchTerm?.term) {
          requestURL += '&custom-search=' + searchTerm.term;
          this.searchTerm_Vendor = searchTerm;
        }

        requestURL = requestURL.replace(
          '{ID}',
          this.createTicketForm.value.Property
        );
        requestURL = requestURL.replace(
          '{category-id}',
          this.createTicketForm.value.MaintenanceCategory
        );
      }

      this.vendorLoading = true;

      this._ticketService.doGET(requestURL).subscribe({
        next: (response: any) => {

          // Pagination Code
          let ListItems = response.results || [];
          this.allVendorsPagination.pagination.next = response.next
            ? response.next
            : '';
          this.allVendorsPagination.pagination.count = response.count
            ? response.count
            : 0;

          if (ListItems.length > 0) {
            ListItems.forEach((element) => {
              element.company = element.company.replace(/\s+/g, ' ').trim();
              element.full_name = element.full_name
                ? element.full_name.replace(/\s+/g, ' ').trim()
                : element.full_name;


              element.name = element.company;

              element.search_label =
                element.company + ' ( ' + element.full_name + ' ) ';

              if (searchTerm) {
                if (searchTerm.term)
                  element.search_label += ', ' + searchTerm.term;
              }

            });
          }

          if (isPagination) {
            this.allVendorsPagination.tempData.push(...ListItems);
          } else {
            this.allVendorsPagination.tempData = ListItems;
          }

          this.allVendorsPagination.tempData = [
            ...this.allVendorsPagination.tempData
          ];

          this.liveAllVendorsDropdownLists = this.getAsyncResponse(
            this.allVendorsPagination.tempData
          );

          this._ticketService.job_AllVendorsDropdownLists = this.allVendorsPagination.tempData || [];


        },
        error: (error) => {
          this.vendorLoading = false;
        },
        complete: () => {
          this.vendorLoading = false;
        }
      });


    }
  }

  onSearchVendorsDropdown(searchTerm: any): void {
    this.searchTerm$.next(searchTerm);
  }

  clearAllVendors(event: any) {
      this.searchTerm$.next({ term: '' });
  }

  getAllVendorsDropdown(searchTerm?: any, isPagination?: boolean): void {
    let requestURL = `${environment.fastAPI_Actual_URL}/companies/?columns=['id', 'name']&query={'type': ['Vendor']{isComplaint}}&page={page}&size={size}&sort=name&order=asc`;

    if(this.isGetVendorByProperty){
      requestURL = `${environment.fastAPI_Actual_URL}/companies/?columns=['id', 'name']&query={'type': ['Vendor']{isComplaint}{isPropertyAndCategory}}&page={page}&size={size}&sort=name&order=asc`;
    }

    // let requestURL = environment.fastAPI_Actual_URL + '/' + this._Config.getVendorCompanies;
    requestURL = requestURL.replace('{size}', this.allVendorsPagination.pagination.size);
    requestURL = requestURL.replace('{page}', this.allVendorsPagination.pagination.currentPage);

    if (isPagination) {
      requestURL = requestURL.replace('{page}', this.allVendorsPagination.pagination.currentPage);
    }

    if (this.createTicketForm.value.MaintenanceCategory) {
      let categoryObj = this.listAllCategoryData.find(obj => obj.id == this.createTicketForm.value.MaintenanceCategory);
      if (this.billablePartyInfo.lease_id)
        categoryObj = this.unitBasedCategories.find(obj => obj.id == this.createTicketForm.value.MaintenanceCategory);

    }

    let postBody: any = {
      property_id: this.createTicketForm.value.Property,
      // category_name: categoryObj.name,
    };

    if (searchTerm?.term) {
      this.allVendorsPagination.pagination.currentPage = 1;
      this.searchTerm_Vendor = searchTerm;
      postBody.search = searchTerm?.term;
      requestURL = `${requestURL}&search=${encodeURIComponent(searchTerm?.term)}`;
    }

    if(this.globalSettings?.show_compliant_vendors) {
      requestURL = requestURL.replace('{isComplaint}', ", 'is_compliant': true");
    } else {
      requestURL = requestURL.replace('{isComplaint}', '');
    }

    if(this.isGetVendorByProperty){
      if (!(this.selectedPropertyObj && this.selectedCategoryObj?.name)) {
        return;
      }
      let propertyID = this.selectedPropertyObj || [];
      let categoryID = [encodeURIComponent(this.selectedCategoryObj?.name)];
      if (propertyID?.length>0 && categoryID) {
        requestURL = requestURL.replace('{isPropertyAndCategory}', `, 'properties': ${JSON.stringify(propertyID)}, 'categories': ${JSON.stringify(categoryID)}`);
      }
      else{
        requestURL = requestURL.replace('{isPropertyAndCategory}', '');
      }
    }
   
    this.vendorLoading = true;

    this._ticketService.doGET(requestURL)
      .subscribe({
        next: (response: any) => {
          this.vendorLoading = false;
          this.allVendorsPagination.pagination.total = response.total;
          this.allVendorsPagination.pagination.size = response.size;
          this.allVendorsPagination.pagination.pageCount = Math.ceil(this.allVendorsPagination.pagination.total / this.allVendorsPagination.pagination.size);

          // Pagination Code
          this.allVendorsPagination.pagination.next = response.next
            ? response.next
            : '';
          this.allVendorsPagination.pagination.count = response.count
            ? response.count
            : 0;

          let ListItems = response.items || [];

          if (isPagination) {
            this.allVendorsPagination.tempData.push(...ListItems);
          } else {
            this.allVendorsPagination.tempData = ListItems;
          }

          this.allVendorsPagination.tempData = [
            ...this.allVendorsPagination.tempData
          ];

          this.liveAllVendorsDropdownLists = this.getAsyncResponse(
            this.allVendorsPagination.tempData
          );

          this._ticketService.job_AllVendorsDropdownLists = this.allVendorsPagination.tempData || [];

          if (this._ticketService.selectedDraftJobDetail?._selectedVendorsList) {
            this._ticketService.selectedDraftJobDetail?._selectedVendorsList.forEach((element: any) => {
              let indexProperty = this._ticketService.job_AllVendorsDropdownLists.findIndex((obj: any) => obj.id == element.id);
              if (indexProperty == -1) {
                this._ticketService.job_AllVendorsDropdownLists.push(element);
              }
            });
          }


        },
        error: (error) => {
          this.vendorLoading = false;
        },
        complete: () => {
          this.vendorLoading = false;
        }
      });
  }

  onClear() {
    this.allVendorsPagination.pagination = { next: '', count: 0, total: 0, size: 25, pageCount: null, currentPage: 1 };
    this.getAllVendorsDropdown();
  }

  getAsyncResponse(response): Observable<any[]> {
    return of(response);
  }

  onScrollToEndVendor(event: any) {
    if (this.allVendorsPagination.pagination.currentPage < this.allVendorsPagination.pagination.pageCount) {
      this.allVendorsPagination.pagination.currentPage++;
      this.getAllVendorsDropdown({}, true);
    }

  }



  // getAllFollowersDropdown() {
  //   if (this.createTicketForm.value.Property) {
  //     let requestURL =
  //       environment.baseURL + this._Config.getAllFollowersByPropertyList;
  //     requestURL = requestURL.replace(
  //       '{ID}',
  //       this.createTicketForm.value.Property
  //     );
  //     this._baseService.doGET(requestURL).subscribe(
  //       (response: any) => {
  //         this.AllFollowersDropdownLists = response || [];

  //       },
  //       (error) => {
  //       }
  //     );
  //   }
  // }
  /**
   * Get All Emergent Vendors
   */
  getAllEmergentVendors(id: any) {
    let requestURL =
      environment.baseURL + this._Config.getAllEmergentVendorsByProperty;
    requestURL = requestURL.replace('{Property_ID}', id);
    requestURL = requestURL.replace(
      '{category-id}',
      this.createTicketForm.value.MaintenanceCategory
    );

    this._baseService.doGET(requestURL).subscribe(
      (response: any) => {
        this.AllEmergentVendorsList = response || {};
      },
      (error: HttpErrorResponse) => {
        this._Config.isLoader = false;
        this._utilService.showErrorMessage(error);
      }
    );
  }

  /**
   * Get Billable party
   */
  getBillableParty() {
    let requestURL = environment.baseURL + this._Config.getBillableParty;
    // requestURL = requestURL.replace("{UnitID}", this.createTicketForm.value.Location);
    requestURL = requestURL.replace(
      '{category-id}',
      this.createTicketForm.value.MaintenanceCategory
    );

    if (this.isCam_Selected) {
      requestURL = requestURL.replace(
        '{UnitID}',
        this.createTicketForm.value.Cam_Address
      );
      requestURL += '&request_type=' + this.createTicketForm.value.RequestType;
    } else {
      requestURL = requestURL.replace(
        '{UnitID}',
        this.createTicketForm.value.Location
      );
    }

    // New Lease Info
    this.billablePartyInfo = this.unitBasedCategories.map(obj => { obj.ruleCount = obj.rules.length; return obj; }).find(obj => obj.category_id == this.createTicketForm.value.MaintenanceCategory) || {};
    this.billablePartyInfo.lease_id = this.selectedLocationItem.lease_id;

    this._baseService.doGET(requestURL).subscribe(
      (response: any) => {
        let responseList: any[] = response || [];

        let billablePartyObject: any = {};

        if (responseList.length > 0) {
          billablePartyObject.isRule = true;
          billablePartyObject.responseList = responseList;

          responseList.forEach((element) => {
            billablePartyObject.condition = element.condition;

            let criterias: any[] = element.criterias || [];

            billablePartyObject.primary = criterias.find(
              (obj) => obj.type == 'Primary'
            );
            billablePartyObject.primary.rules = criterias.filter(
              (obj) => obj.type == 'Secondary'
            );

            billablePartyObject.category =
              this._baseService.allWorkCategories.find(
                (obj) =>
                  obj.id == this.createTicketForm.value.MaintenanceCategory
              ) || {};
          });
        } else {
          billablePartyObject.isRule = false;
          billablePartyObject.rules = {
            manager:
              this.selctedCategoryObject.responsible == 'Manager' ? 100 : 0,
            tenant: this.selctedCategoryObject.responsible == 'Tenant' ? 100 : 0
          };
        }

        this.BillableParty = billablePartyObject || {};
      },
      (error) => { }
    );
  }

  emergentContinnueAction() {
    this.isEmergentSubmited = true;

    this.removeValidators(this.createTicketForm, ['Vendors']);
  }

  addValidators(form?: any, control?: any) {
    control.forEach((element) => {
      form.controls[element].setValidators([Validators.required]);
      form.controls[element].updateValueAndValidity();
    });
  }

  removeValidators(form?: any, control?: any) {
    control.forEach((element) => {
      form.controls[element].clearValidators();
      form.controls[element].updateValueAndValidity();
    });
  }

  /**
   * Open Photo bank with selected type
   * @param type
   */
  openUploadPhotos(type: any) {


    // this._photoBankService.photoBankViewObj.isCreateTicket = true;
    // this._photoBankService.photoBankViewObj.isModalBoxOption = true;
    // this._photoBankService.photoBankViewObj.is_Opened_Folder = false;
    // this._photoBankService.photoBankViewObj.is_SelectedOption = type;

    // if (type == 'Photobank') this._photoBankService.getFolderPreviewImages();

    if (type == 'Photobank') {
      let popupData = {
        title: 'Photobank',
        component: TabPhotobankPopupComponent,
        data: {
          "entityId": null,
          "entityType": 'JOB'
        }
      };

      this._dialogService.openModal(popupData).subscribe(result => {
        if (result) {
          if (Array.isArray(result?.files)) {
            if (result.files.length > 0) {
              let datas = [];
              result.files.forEach(element => {
                datas.push(element.data)
              });
              // let selectedPhotos = result.files.map(obj => obj.data) || [];
              this.tempUploadedList.push(...datas) || [];
            }

          }

        }

      });
    }
    else {

      let popupData = {
        title: 'File Upload',
        component: TabUploadFilesPopupComponent,
        data: {
          "entityId": null,
          "entityType": 'JOB'
        }
      };

      this._dialogService.openModal(popupData).subscribe((result: any) => {
        if (Array.isArray(result)) {
          this.tempUploadedList.push(...result) || [];
        }

      });
    }





  }

  deleteTempFile(file) {
    for (let i = 0; i < this.tempUploadedList.length; i++) {
      if (file.key == this.tempUploadedList[i].key) {
        this.tempUploadedList.splice(i, 1);
      }
    }
  }

  /**************** Checklist Dropdowns Start **********************/

  /* ------------- All Checklist Dropdown Events ---------------- */

  openSelectPropertyChecklist() {
    this.selectPropertyChecklist.focus();
    this.selectPropertyChecklist.open();
  }

  closeSelectPropertyChecklist() {
    if (this.selectPropertyChecklist && this.selectPropertyChecklist.isOpen) {
      this.selectPropertyChecklist.close();
    }
  }

  cancelSelectPropertyChecklist() {
    this.selectPropertyChecklist.handleClearClick();
    this.selectPropertyChecklist.close();
    this.isAllChecklist = false;
  }

  /* ------------- All Checklist Dropdown Events ---------------- */
  openSelectAllChecklist() {
    this.isAllChecklist = true;
    this.selectAllChecklist.focus();
    this.selectAllChecklist.open();

    this.createTicketForm.value.Checklist_Property = null;
    this.createTicketForm.value.Checklist_All = null;
  }

  closeSelectAllChecklist() {
    if (this.selectAllChecklist && this.selectAllChecklist.isOpen) {
      this.selectAllChecklist.close();
    }
  }

  cancelSelectAllChecklist() {
    this.selectAllChecklist.handleClearClick();
    this.selectAllChecklist.close();

    if (this.createTicketForm.value.Property) this.isAllChecklist = false;
  }

  onChangePropertyClearSelectedItems() {
    // if (this.selectAllChecklist) {
    //   this.selectAllChecklist.handleClearClick();
    //   this.selectAllChecklist.close();
    // }

    // this.selectPropertyChecklist.handleClearClick();
    // this.selectPropertyChecklist.close();

    this.createTicketForm.value.Checklist_Property = null;
    this.createTicketForm.value.Checklist_All = null;

    this.isAllChecklist = false;

    // this.locationDropdown.focus();
  }

  /**************** Checklist Dropdowns End **********************/

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return (
      item.search_label.toLocaleLowerCase().indexOf(term) > -1 ||
      item.name.toLocaleLowerCase() === term
    );
  }

  /************ search property *************/
  getAllProperties(searchTerm?: any, isFromInbox?: boolean, isPagination?: boolean, tempData?: any) {
    let requestURL = '';

    // if (searchTerm.term) {
    //   requestURL =
    //     environment.baseURL + this._Config.searchCreateTicket_Properties_new;
    //   requestURL = requestURL.replace('{source}', isFromInbox ? 'inbox' : 'pm');
    //   requestURL = requestURL.replace('{searchTerm}', searchTerm.term);
    // } else {
    //   requestURL = environment.baseURL + this._Config.listAllPropertiesDropdown;
    //   requestURL = requestURL + (isFromInbox ? '?source=inbox' : '?source=pm');
    // }

    requestURL = environment.LocalDev_URL_V2 + 'properties/dropdown/?source=pm';

    if (searchTerm.term) {

      if (requestURL.includes('?'))
        requestURL += '&search=' + searchTerm.term;
      else
        requestURL += '?search=' + searchTerm.term;

    } else {
      requestURL =
        environment.LocalDev_URL_V2 + 'properties/dropdown/?source=pm'
    }

    // if (this.allPropertyPagination.pagination.next && !searchTerm.term) {
    //   if (requestURL.includes('?'))
    //     requestURL += '&page=' + this.allPropertyPagination.pagination.next;
    //   else
    //     requestURL += '?page=' + this.allPropertyPagination.pagination.next;
    // }

    if (isPagination) {
      requestURL = this.allPropertyPagination.pagination.next;
      if (searchTerm.term) {
        requestURL += '&search=' + searchTerm.term;
      }
    }


    this.propertyLoading = true;

    if (this.propertySubscription) {
      this.propertySubscription.unsubscribe();
    }

    this.propertySubscription = this._ticketService.doGET(requestURL)
      .subscribe({
        next: (response: any) => {
          // if (response.length > 0) {
          //   response.forEach((element) => {
          //     element.name = element.name.replace(/\s+/g, ' ').trim();
          //     element.address = element.address.replace(/\s+/g, ' ').trim();

          //     element.property_address =
          //       element.name + ' ( ' + element.address + ' )';
          //     element.search_label =
          //       element.name + ' ( ' + element.address + ' ) ';

          //     if (searchTerm.term) element.search_label += ', ' + searchTerm.term;
          //   });
          // }

          // this.livePropertyData = this.getProperty(response);


          this.allPropertyPagination.pagination.total = response.count;
          // this.allPropertyPagination.pagination.size = response.size;
          this.allPropertyPagination.pagination.pageCount = Math.ceil(this.allPropertyPagination.pagination.total / this.allPropertyPagination.pagination.size);

          // Pagination Code
          this.allPropertyPagination.pagination.next = response.next
            ? response.next
            : '';
          this.allPropertyPagination.pagination.count = response.count
            ? response.count
            : 0;

          let ListItems = response.results || [];

          // Set Property if not in the list
          if (this._ticketService.createJobFromJobChecklist && (this._jobService.selectedJobInfo?.property && this._jobService.selectedJobInfo?.property?.id)) {

            let indexVal = ListItems.findIndex(obj => obj.id == this._jobService.selectedJobInfo?.property?.id);
            if (indexVal == -1) {
              ListItems.push(this._jobService.selectedJobInfo?.property);
            }
          }

          if (ListItems.length > 0) {
            ListItems.forEach((element) => {
              element.search_label = element.name;
              if (searchTerm) {
                if (searchTerm.term)
                  element.search_label += ', ' + searchTerm.term;
              }

            });
          }

          if (isPagination) {
            // this.allPropertyPagination.tempData.push(...ListItems);
            this.allPropertyPagination.tempData = [...this.allPropertyPagination.tempData, ...ListItems];

          } else {
            this.allPropertyPagination.tempData = ListItems;
          }

          this.allPropertyPagination.tempData = [...this.allPropertyPagination.tempData];

          if (tempData) {

            const propertyExist = this.allPropertyPagination.tempData.find((d) => d.id === tempData.id);

            if (!propertyExist) {
              this.allPropertyPagination.tempData.push(tempData);
            }


            if (this.data.property.location.length) {

              if (this.data.property?.location[0]?.units?.length > 0) {
                // this.selectedLocationItemsList = this.data.property?.location[0]?.units[0]?.id;
                // this.onChangeLocation(this.data.property?.location[0]?.units[0]);
              }

            }

          }

          if(this._ticketService.selectedDraftJobDetail?.property && this._ticketService.selectedDraftJobDetail?.property_obj){
            let indexProperty=this.allPropertyPagination.tempData.findIndex(obj=>obj.id==this._ticketService.selectedDraftJobDetail.property);
            if(indexProperty==-1){
              this.allPropertyPagination.tempData.push(this._ticketService.selectedDraftJobDetail?.property_obj);
            }
          }

          this.livePropertyData = this.getProperty(this.allPropertyPagination.tempData);

        },
        error: (error) => {
          this.propertyLoading = false;
        },
        complete: () => {
          this.propertyLoading = false;
        }
      });


  }

  getProperty(response): Observable<any[]> {
    return of(response);
  }


  onClearProperty() {
    this.allPropertyPagination.pagination = { next: '', count: 0, total: 0, size: 100, pageCount: null, currentPage: 1 };
    this.getAllProperties();
  }

  onScrollToEndProperty(event: any) {
    if (this.allPropertyPagination.pagination.currentPage < this.allPropertyPagination.pagination.pageCount) {
      this.allPropertyPagination.pagination.currentPage++;
      this.getAllProperties({}, false, true);
    }

  }



  /************ search Locations *************/
  getAllLocations(searchTerm?: any, showError = true) {
    if (!this.selectedPropertyItemsList && showError) {
      this._utilService.showError('Property', 'Should be selected!');
      return;
    }

    let requestURL = '';
    if (searchTerm?.term) {
      requestURL =
        environment.baseURL + this._Config.searchCreateTicket_Locations;
      requestURL = requestURL.replace('{ID}', this.selectedPropertyItemsList);
      requestURL = requestURL.replace('{searchTerm}', searchTerm.term);
    } else {
      requestURL =
        environment.baseURL + this._Config.getLocationAllUnitsByProperty;
      requestURL = requestURL.replace('{ID}', this.selectedPropertyItemsList);
    }

    this.locationLoading = true;

    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
    }

    this.locationSubscription = this._ticketService.doGET(requestURL).subscribe(
      (response: any) => {
        // this._Config.isLoader = false;

        let allLocations = [];
        if (response.length > 0) {
          response.forEach((element: any, index) => {
            let units = [];
            element.units.forEach((unit: any) => {
              let search_label = element.address;
              search_label += ', ' + unit.name;

              if (unit.tenant) search_label += ', ' + unit.tenant.name;

              if (searchTerm.term) search_label += ', ' + searchTerm.term;

              let unitObj = {
                id: unit.id,
                lease_id: unit.lease_id,
                title: unit.name,
                status: unit.status,
                tenant: unit.tenant ? unit.tenant : '',
                property_id: this.selectedPropertyItemsList,
                search_label: search_label
              };

              units.push(unitObj);
            });

            let unitsObj = {
              id: index + 1,
              title: element.address,
              subunits: units
            };

            allLocations.push(unitsObj);
          });
        }

        this.liveLocationData = this.getlocationResponse(allLocations);
        if (this.selectedLocationItemsList) {
          this.getAllCategories({}, false);
        }

        // setTimeout(() => {
        //   this.locationLoading = false;
        // }, 2000);
      },
      (error) => {
        // this._Config.isLoader = false;
        this.locationLoading = false;
      },
      () => {
        this.locationLoading = false;
      }
    );
  }

  getlocationResponse(response): Observable<any[]> {
    return of(response).pipe(delay(500));
  }

  customSearchFnLocation(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return (
      item.search_label.toLocaleLowerCase().indexOf(term) > -1 ||
      item.title.toLocaleLowerCase() === term
    );
  }

  customSearchFnCategory(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return (
      item.name.toLocaleLowerCase().indexOf(term) > -1 ||
      item.name.toLocaleLowerCase() === term
    );
  }

  /************ search Category *************/
  getAllCategories(searchTerm?: any, showError = true) {
    if (!this.selectedLocationItemsList && showError) {
      this._utilService.showError('Location or Unit', 'Should be selected!');
      return;
    }

    let requestURL = '';
    if (searchTerm?.term) {
      requestURL =
        environment.baseURL + this._Config.searchCreateTicket_Category;
      requestURL = requestURL.replace(
        '{ID}',
        this.createTicketForm.value.Property
      );
      requestURL = requestURL.replace('{searchTerm}', searchTerm.term);
      requestURL = requestURL + '&unit=' + this.selectedLocationItem.id;

      if (this.createTicketForm.value.RequestType == 'Common Area') {
        requestURL = requestURL + '&request_type=Common Area';
      }
    } else {
      requestURL =
        environment.baseURL + this._Config.getAllCategoriesByProperty;
      requestURL = requestURL.replace(
        '{ID}',
        this.createTicketForm.value.Property
      );

      if (this.createTicketForm.value.RequestType != 'Common Area') {
        requestURL = requestURL + '?unit=' + (this.selectedLocationItem.id ? this.selectedLocationItem.id : this.selectedLocationItemsList);
      } else if (this.createTicketForm.value.RequestType == 'Common Area') {
        requestURL = requestURL + '?request_type=Common Area';
      }
    }

    this.categoryLoading = true;

    this._ticketService.doGET(requestURL).subscribe({
      next: (response: any) => {
        this.listAllCategoryData = response || [];
        this.liveCategoryData = this.getCategoryResponse(response);
      },
      error: (error) => {
        this.categoryLoading = false;
      },
      complete: () => {
        this.categoryLoading = false;

        this.setChecklistFrmData();

      }
    });
  }

  setChecklistFrmData() {
    // Patch the category value if the create new job form open form the job checklist
    if (this._ticketService.createJobFromJobChecklist && (this._jobService.selectedJobInfo && this._jobService.selectedJobInfo?.category)) {
      this.selectedCategoryItemsList = this._jobService.selectedJobInfo?.category?.id
      this.createTicketForm.get('MaintenanceCategory').patchValue(this.selectedCategoryItemsList);
      this.onChangeCategory({ id: this.selectedCategoryItemsList });
    }
    // Patch the assignee value if the create new job form open form the job checklist
    if (this._ticketService.createJobFromJobChecklist && (this._jobService.selectedJobInfo && this._jobService.selectedJobInfo?.assignee)) {
      this.selectedAssigneesItems = this._jobService.selectedJobInfo.assignee.id;
      this.createTicketForm.get('Assignee').patchValue(this._jobService.selectedJobInfo.assignee.id);
    }
    // Patch the engineers value if the create new job form open form the job checklist
    if (this._ticketService.createJobFromJobChecklist && (this._jobService.selectedJobInfo && this._jobService.selectedJobInfo?.engineers)) {
      if (this._jobService.selectedJobInfo.engineers.length > 0) {
        const selectedEngineers = this._jobService.selectedJobInfo.engineers.map((item) => item.id);
        this.selectedEngineerItems = selectedEngineers;
        this.createTicketForm.get('Engineers').patchValue(selectedEngineers);
      }
    }
    // Patch the followers value if the create new job form open form the job checklist
    if (this._ticketService.createJobFromJobChecklist && (this._jobService.selectedJobInfo && this._jobService.selectedJobInfo?.followers)) {
      if (this._jobService.selectedJobInfo.followers.length > 0) {
        const selectedFollowers = this._jobService.selectedJobInfo.followers.map((item) => item.id);
        this.selectedFollowersItems = selectedFollowers;
        this.createTicketForm.get('Followers').patchValue(selectedFollowers);
      }
    }
    // Patch the vendors value if the create new job form open form the job checklist
    if (this._ticketService.createJobFromJobChecklist && (this._jobService.selectedJobInfo && this._jobService.selectedJobInfo?.vendors)) {
      if (this._jobService.selectedJobInfo.vendors.length > 0) {
        const selectedVendors = this._jobService.selectedJobInfo.vendors.map((item) => item.company_id);
        this.selectedVendorsItems = selectedVendors;
        this.createTicketForm.get('Vendors').patchValue(selectedVendors);
      }
    }
  }

  getCategoryResponse(response): Observable<any[]> {
    return of(response).pipe(delay(100));
  }

  trackByFn(item: any) {
    return item.id;
  }

  toggleOptionalField(): void {
    this.showOptional = !this.showOptional;
  }

  clearProperty(): void {
    this.allPropertyPagination.pagination = { next: '', count: 0, total: 0, size: 100, pageCount: null, currentPage: 1 };
    // this.getAllProperties();
    this.isClearProperty = true;
    // this.getAllProperties({}, this.createAssociateService.isOpenFromNewInbox);
    this.selectedPropertyItemsList = null;
    this.createTicketForm.get('Property').patchValue(null);
    this._ticketService.job_AllAssigneeDropdownLists = [];
    this.createTicketForm.get('Assignee').patchValue(null);
    this._ticketService.job_AllFollwersDropdownLists = [];
    this.createTicketForm.get('Followers').patchValue([]);
    this.createTicketForm.get('Vendors').patchValue([]);
    this.createTicketForm.get('Engineers').patchValue([]);
    this.selectedVendorsItems = [];
    this.selectedFollowersItems = [];
    this.selectedEngineerItems = [];
    this.selectedAssigneesItems = [];
    this.selectedLocationItemsList = null;
    this.clearLocation();

    this.onChangeProperty(null);
    this.getAllProperties({}, false);

  }

  /****** Clear Manager Event ******/
  clearAssignee(): void {
    this.createTicketForm.get('Assignee').patchValue(null);
    this.selectedAssigneesItems = [];
  }

  clearLocation(): void {
    this.getAllLocations({}, true);
    this.isClearLocation = true;
    this.selectedLocationItemsList = null;
    this.createTicketForm.get('Location').patchValue(null);
    this.onChangeLocation(null);
    this.clearCategory();
  }

  locationFocusOut(event: any): void {
    if (this.selectedLocationItemsList == null || this.selectedLocationItemsList == "") {
      this.clearLocation();
    }
  }

  clearCategory(): void {
    this.isClearCategory = true;
    this.selectedLocationItem = {};
    this.selectedCategoryItemsList = null;
    this.selctedCategoryObject.name = null;
    this.createTicketForm.get('MaintenanceCategory').patchValue(null);
    this.unitBasedCategories = [];
    this.liveCategoryData = this.getCategoryResponse([]);
    this.billablePartyInfo = {};

  }

  minimizePopup(): void {
    this.isMinimize = true;
  }

  maximizePopup(): void {
    this.isMinimize = false;
  }

  reloadClick(): void {
    location.reload();
  }

  postDraftTicket(): void {
    console.log("POST DRAFT TICKET CALLED");
    const photosList = this._ticketService.uploadPhotos
    this._photoBankService.photoBankViewObj.isCreateTicket = false;
    const TargetCompletionDate = this._utilService.formatDate(
      this.createTicketForm.value.TargetCompletionDate
    );
    let checklistItem = this.selectedChecklistsItems || [];
    // if (this.isAllChecklist) {
    //   checklistItem = this.createTicketForm.value.Checklist_All
    //     ? this.createTicketForm.value.Checklist_All
    //     : null;
    // } else {
    //   checklistItem = this.createTicketForm.value.Checklist_Property
    //     ? this.createTicketForm.value.Checklist_Property
    //     : null;
    // }
    let engineerRequired = false;
    if (this.createTicketForm.value?.Engineers?.length == 1) {
      if (this.createTicketForm.value.Engineers == 'no-engineer-required') {
        engineerRequired = true;
        this.createTicketForm.value.Engineers = [];
      }
    }
    this._ticketService.draftJobName = !isNaN(this._ticketService.draftJobName)
      ? this._ticketService.draftJobName
      : 1;
    let postBody: any = {
      // id: this._ticketService.draftJobId,
      // name: this._ticketService.draftJobName,
      type: this.createTicketForm.value.type,
      request_type: this.createTicketForm.value.RequestType,
      property: this.selectedPropertyItemsList ?? this.createTicketForm.value.Property,
      category: this.selectedCategoryItemsList ?? this.createTicketForm.value.MaintenanceCategory,
      target_date: TargetCompletionDate,
      description: this.createTicketForm.value.Description,
      access_instruction: this.createTicketForm.value.AccessInstructions,
      unit_entry_permission: this.createTicketForm.value.Permissiontoenter_unit
        ? this.createTicketForm.value.Permissiontoenter_unit
        : false,
      property_entry_permission: this.createTicketForm.value.Permissiontoenter
        ? this.createTicketForm.value.Permissiontoenter
        : false,
      skip_site_visit: this.createTicketForm.value.SkipSite
        ? this.createTicketForm.value.SkipSite
        : false,
      request_feedback_on_close: this.createTicketForm.value.request_feedback_on_close
        ? this.createTicketForm.value.request_feedback_on_close
        : false,
      request_auto_response: this.createTicketForm.value.request_auto_response
        ? this.createTicketForm.value.request_auto_response
        : false,
      skip_bid: this.createTicketForm.value.SkipBid
        ? this.createTicketForm.value.SkipBid
        : false,
      courtesy_job: this.createTicketForm.value.CourtesyJob
        ? this.createTicketForm.value.CourtesyJob
        : false,
      request_completion_photos: this.createTicketForm.value
        .RequestCompletionVerification
        ? this.createTicketForm.value.RequestCompletionVerification
        : false,
      disable_all_notification: this.createTicketForm.value.disable_all_notification
        ? this.createTicketForm.value.disable_all_notification
        : false,
      _vendors: this.selectedVendorsItems ?? (this.createTicketForm.value.Vendors
        ? this.createTicketForm.value.Vendors
        : []),
      is_automatic_approved: this.createTicketForm.value.is_automatic_approved
        ? (this.selectedVendorsItems?.length === 1) ? this.createTicketForm.value.is_automatic_approved : false
        : false,
      followers: this.selectedFollowersItems ?? (this.createTicketForm.value.Followers
        ? this.createTicketForm.value.Followers
        : []),
        no_engineers_required: engineerRequired,
      engineers: this.selectedEngineerItems ?? (this.createTicketForm.value.Engineers
        ? this.createTicketForm.value.Engineers
        : []),
      photos: photosList,
      pm_assignee: this.selectedAssigneesItems ?? this.createTicketForm.value.Assignee,
      issue_type: this.createTicketForm.value.issue_type,
      priority: this.createTicketForm.value.Priority,
      minimizeJob: false,
      minimizeCreateTicket: true,
      service_type: this.createTicketForm.value.service_type,
      checklists: this.selectedChecklistsItems,
      billablePartyInfo: this.billablePartyInfo,
      selectedLocationItem: this.selectedLocationItem,
      unitBasedCategories: this.unitBasedCategories,
      liveLocationData: this.liveLocationData,
    };

    if(this.selectedPropertyItemsList){
      postBody.property_obj = this.allPropertyPagination.tempData.find(obj=>obj.id==this.selectedPropertyItemsList);
    }

    if (postBody._vendors?.length > 0) {
      postBody._selectedVendorsList = this._ticketService.job_AllVendorsDropdownLists.filter((obj: any) => postBody._vendors.includes(obj.id)) || [];
    }

    postBody.tempUploadedList_DraftFiles = this.tempUploadedList_DraftFiles;
    postBody.tempUploadedList = this.tempUploadedList;
    postBody.inboxAttachments = this.inboxAttachments;


    if (this.searchTerm_Vendor) {
      if (this.searchTerm_Vendor?.term) {
        postBody.searchTerm_Vendor = this.searchTerm_Vendor;
      }
    }


    if (this._Config.isShowChecklist) {
      if (checklistItem) {
        postBody.checklist = checklistItem;
      }
    }
    if (this._Config.isShowInbox) {
      if (this._ticketService.isEmailCreateTicket) {
        postBody.mail = this._ticketService.selectedEmailTemplate.id;
      }
      // if (this.createAssociateService.isOpenFromNewInbox) {
      //   const mailID = Number(
      //     this.createAssociateService.activeMessage.message_id
      //   );
      //   postBody.mail = mailID;
      // }
    }
    if (this.isCam_Selected) {
      const addressObj: any =
        this.AllLocationDropdownLists.find((obj: any) =>
          obj.subunits.find(
            (obj1: any) => obj1.id === this.createTicketForm.value.Cam_Address
          )
        ) || {};
      // postBody.address = this.createTicketForm.value.Cam_Address;
      postBody.address = addressObj.title;
      postBody.location = this.selectedLocationItemsList ?? (this.createTicketForm.value.Cam_Location
        ? this.createTicketForm.value.Cam_Location
        : '');
      postBody.unit = this.createTicketForm.value.Cam_Address;
    } else {
      postBody.unit = this.selectedLocationItemsList ?? this.createTicketForm.value.Location;
    }

    if (this._ticketService.selectedEmailTemplate?.id) {
      postBody.mailId = this._ticketService.selectedEmailTemplate.id;
    }

    if (this.isClearProperty) {
      postBody.property = '';
      this.selectedPropertyItemsList = null;
    } else if (this.isClearLocation) {
      postBody.unit = '';
      this.selectedLocationItemsList = null;
    } else if (this.isClearCategory) {
      postBody.category = '';
      this.selectedCategoryItemsList = null;
    }
    if (this._ticketService.getAllDraftJob.length === 0) {
      this._ticketService.draftJobName = 1;
    }

    if (
      this._ticketService.selectedDraftJobDetail === undefined &&
      this._ticketService.isCreateTicket
    ) {
      let draftTickets = this._ticketService.getAllDraftJob.filter(
        (obj) => obj.name
      );

      postBody.id = draftTickets.length + 1;
      postBody.name = 'Draft ' + (draftTickets.length + 1);

      if (postBody.mail) {
        let indexExists = this._ticketService.getAllDraftJob.findIndex((obj) =>
          obj.mailId == postBody.mailId ? postBody.mailId : postBody.mail
        );

        if (indexExists != -1) {
          this._utilService.showError(
            '',
            'This Job is already minimized, please check your draft'
          );
        } else {
          this._ticketService.getAllDraftJob.unshift(postBody);
        }
      } else {
        this._ticketService.getAllDraftJob.unshift(postBody);
      }
    } else {
      let indexExists = this._ticketService.getAllDraftJob.findIndex(
        (obj) => obj.name == this._ticketService.selectedDraftJobDetail.name
      );

      if (this.selected_DraftJob?.id) {
        postBody.draft_data = this.selected_DraftJob;
        postBody.id = this.selected_DraftJob?.id;

        let draftTickets = this._ticketService.getAllDraftJob.filter((obj) => obj.name);
        postBody.name = 'Draft ' + (draftTickets.length + 1);
      }
      else {
        postBody.id = this._ticketService.selectedDraftJobDetail?.id;
        postBody.name = this._ticketService.selectedDraftJobDetail?.name;
      }

      if (indexExists != -1) {
        this._ticketService.getAllDraftJob[indexExists] = postBody;
      }
      else {
        this._ticketService.getAllDraftJob.unshift(postBody);
      }
    }

    // if (this._ticketService.selectedDraftJobDetail === undefined && this._ticketService.isCreateTicket) {
    //   this._ticketService.draftJobId += 1;
    //   this._ticketService.draftJobName += 1;
    //   this._ticketService.getAllDraftJob.unshift(postBody);
    //   if (this._ticketService.getAllDraftJob.length >= 1) {
    //     let resetCount = 1;
    //     this._ticketService.getAllDraftJob.map((id: any) => {
    //       id.id = resetCount;
    //       resetCount = resetCount + 1;
    //     });
    //   }
    // } else {
    //   postBody.id = this._ticketService.selectedDraftJobDetail.id;
    //   postBody.name = this._ticketService.selectedDraftJobDetail.name;
    //   this._ticketService.getAllDraftJob[postBody.id - 1] = postBody;
    // }
    this.isClearProperty = false;
    // this.triggerFalseClick();
    this._ticketService.isDraftTicket = true;
    this.dialogRef.close('Closed');
  }


  //Get dynamic width of the lable
  getName(itemName: any) {
    let htmlElem: any = document.getElementsByClassName('ds-description')[0];
    let charCount = (htmlElem?.offsetWidth / 8) - 1;

    if (itemName?.length > charCount) {
      return itemName.slice(0, charCount) + '...';
    }
    else {
      return itemName;
    }
  }

  //Show/Hide dynamic width of the lable Tooltip
  isTooltip(itemName: any) {
    let htmlElem: any = document.getElementsByClassName('ds-description')[0];
    let charCount = (htmlElem?.offsetWidth / 8) - 1;

    if (itemName?.length > charCount) {
      return true;
    }
    else {
      return false;
    }
  }


  //Get dynamic width of the lable
  getNameSub(itemName: any) {
    let htmlElem: any = document.getElementsByClassName('ds-description')[0];
    let charCount = (htmlElem?.offsetWidth / 8) - 1;

    if (itemName?.length > charCount) {
      return itemName.slice(0, charCount) + '...';
    }
    else {
      return itemName;
    }
  }

  //Show/Hide dynamic width of the lable Tooltip
  isTooltipSub(itemName: any) {
    let htmlElem: any = document.getElementsByClassName('longTextUnits')[0];
    let charCount = (htmlElem?.offsetWidth / 8) - 1;

    if (itemName?.length > charCount) {
      return true;
    }
    else {
      return false;
    }
  }


  getDraftFiles() {

    this._fileService.getFiles('DRAFT_JOB', this.selected_DraftJob?.id, 'filename', 'ASC', '', 1, 25).subscribe((data: any) => {
      let filesTableRows = data?.data?.files;
      this.tempUploadedList_DraftFiles.push(...filesTableRows);
    })

  }


  /**
 * Get All Global Setting Flags
 */
  getAllGlobalSettings() {

    let requestURL = environment.baseURL + 'global-job-settings/job-settings/';
    requestURL = requestURL.replace('v1', 'v2');

    this._baseService.doGET(requestURL)
      .subscribe({
        next: (response: any) => {
          this.globalSettings = response || {};
        },
        error: (error) => {
        },
        complete: () => {
          this.setGlobalPermissions();

          if(this.current_account!="bcwoodproperties"){
            this.getVendors();
          }
        }
      });
  }

  /** Track by Index  **/
  trackByIndex(index: number, item: any): number {
    return index;
  }

  autoAssignPhotos(attachments,result){
    const uniqueEntity : any[] = this.createCopyObjectPayload(attachments) ?? [];
    let success = 0;
    let errors = 0;
    const reqs = uniqueEntity.map((a) =>
      this._fileService.copyFiles({entityId:a.entityId, entityType:a.entityType, toEntityId:`${result.id}`, toEntityType:'JOB', fileIds:a.id }).pipe(
        tap((_) =>{
          success++;
        }
        ),
        catchError(err => {
          errors++;
          return of(err);
        })
      )

    );
    forkJoin(reqs).subscribe(
      null,
      err => {  },
      () =>   { return this.dialogRef.close(result) }
    );
  }

  deleteInboxAttachments(file,index) {
    this.inboxAttachments.splice(index, 1);
  }

  createCopyObjectPayload(objects) {
    const map = objects.reduce((acc, obj) => {
        const { entityId, id, ...rest } = obj;
        if (!acc[entityId]) {
            acc[entityId] = { entityId, id: [id], isUnique: true, ...rest };
        } else {
            acc[entityId].id.push(id);
            acc[entityId].isUnique = false;
        }
        return acc;
    }, {});
    return Object.values(map);
  }

  contentChanged(event: any) {    
    this.createTicketForm.value.instructions = event;
  }

  openBrowseTemplates() {
    let popupData = {
      title: 'Scope & Instruction Templates',
      component: ScopeAndInstructionsTemplateComponent,
      containerClass: 'modal_scope_template',
      isReadOnly: true,
      data: {}
    };
    this._dialogService.openModal(popupData).subscribe(result => {
      this.createTicketForm.patchValue({
        scope: result?.scope,
        instructions: result?.instructions
      });
      this.instructionsText = result?.instructions;   
    })
  }

  
}

export interface responseObj {
  id: string;
  name: string;
  address: string;
  property_address: string;
}
