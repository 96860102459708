// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile_equipment ::ng-deep profile-picture .fyxt_new_ui_profile {
  width: auto;
  height: auto;
}

.dollar_img {
  position: absolute;
  left: 18.5px;
  bottom: 5.4px;
  color: #3e5469;
  font-size: 14px;
}

.textbox-label {
  color: #192c49;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 400;
}

.text-input {
  height: 44px;
  border-radius: 4px;
  border: solid 1px #E6E8F0;
  outline: none;
  box-sizing: border-box !important;
  width: 100%;
  color: #192c49;
  padding: 0 18px;
}

::ng-deep .fyxt_new_textBox .disable_input .textbox-label, ::ng-deep .fyxt_new_textBox .disable_input .text-input {
  color: #192c49 !important;
}

.profile-bg {
  background: #E6E8F0;
}

.profile-img {
  height: 100%;
  width: 100%;
}

.disabledForm {
  pointer-events: none !important;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_forms/create-equipment/create-equipment.component.scss"],"names":[],"mappings":"AAEI;EACE,WAAA;EACA,YAAA;AADN;;AAMA;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,cAAA;EACA,eAAA;AAHF;;AAMA;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;AAHF;;AAKA;EACE,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,aAAA;EACA,iCAAA;EACA,WAAA;EACA,cAAA;EACA,eAAA;AAFF;;AAIA;EACE,yBAAA;AADF;;AAGA;EACE,mBAAA;AAAF;;AAEA;EACE,YAAA;EACA,WAAA;AACF;;AACA;EACE,+BAAA;AAEF","sourcesContent":[".profile_equipment {\n  ::ng-deep profile-picture {\n    .fyxt_new_ui_profile {\n      width: auto;\n      height: auto;\n    }\n  }\n}\n\n.dollar_img {\n  position: absolute;\n  left: 18.5px;\n  bottom: 5.4px;\n  color: #3e5469;\n  font-size: 14px;\n}\n\n.textbox-label{\n  color: #192c49;\n  margin-bottom: 8px;\n  font-size: 12px;\n  font-weight: 400;\n}\n.text-input{\n  height: 44px;\n  border-radius: 4px;\n  border: solid 1px #E6E8F0;\n  outline: none;\n  box-sizing: border-box !important;\n  width: 100%;\n  color: #192c49;\n  padding: 0 18px;\n}\n::ng-deep .fyxt_new_textBox .disable_input .textbox-label, ::ng-deep .fyxt_new_textBox .disable_input .text-input {\n  color: #192c49 !important;\n}\n.profile-bg{\n  background: #E6E8F0;\n}\n.profile-img{\n  height: 100%;\n  width: 100%;\n}\n.disabledForm {\n  pointer-events: none !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
