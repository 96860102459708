import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';
import { TimesheetService } from "../../../../../Modules/_fyxt_modules/timesheet/_services/timesheet.service";
import { RadioButtonItem } from '../../../_reusable_ui/_controls';
import { PredefinedTasksService } from 'src/app/Modules/_fyxt_modules/timesheet/_services/predefined-tasks.service';
import { JobsService } from 'src/app/services/v2/jobs.service';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { MatDividerModule } from '@angular/material/divider';
import { CheckboxComponent } from '../../../_reusable_ui/_controls/checkbox/checkbox.component';
import { RadioComponent } from '../../../_reusable_ui/_controls/radio/radio.component';
import { TimePickerComponent } from '../../../_reusable_ui/_controls/time-picker/time-picker.component';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';
import { DropdownDateSelectComponent } from '../../../_reusable_ui/_controls/dropdown-select/dropdown-date-select/dropdown-date-select.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { DropdownComponent } from '../../../_reusable_ui/_controls/dropdown/dropdown.component';
import { NgIf, NgClass } from '@angular/common';
@Component({
    selector: 'fyxt-add-timesheet-popup',
    templateUrl: './add-timesheet-popup.component.html',
    styleUrls: ['./add-timesheet-popup.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, DropdownComponent, NgxIntlTelInputModule, NgClass, DropdownDateSelectComponent, TextboxComponent, TimePickerComponent, RadioComponent, CheckboxComponent, MatDividerModule, MatDialogModule, ButtonComponent]
})
export class AddTimesheetPopupComponent implements OnInit {

  @Input() currentDate = new Date();
  @Input() Error: boolean = false;
  @Input() updateTimeSheet: boolean = false;
  @Input() editDetails: any;

  currentUserInfo: any = JSON.parse(localStorage.getItem('userobj'))

  addTimeSheetForm!: FormGroup;
  isLoader: boolean = false;
  formSubmitted: boolean = false;

  allPropertiesList: any[] = [];
  allEngineerList: any[] = [];
  allJobsList: any[] = [];
  selectedPropertyId: any;
  selectEngineerId: any;

  startTime: string;
  endTime: string;
  calculateHours: number;
  calculateMinute: number;
  totalTimeError: any;
  radioItems: RadioButtonItem[] = [
    { name: 'Billable', value: 'Billable', checked: true },
    { name: 'Non Billable', value: 'Non Billable', checked: false }
  ];

  allDescriptionList: any[] = [];

  environment: any = environment;
  travelTimeError: number;

  engineerLoading: any;
  allEngineerPagination: any = {
    pagination: { next: '', count: 0, total: 0, size: 100, pageCount: null, currentPage: 1 },
    tempData: []
  };

  appliedRateList: any = [
    { id: 'Standard', name: 'Standard'},
    { id: 'Overtime', name: 'Overtime'},
    { id: 'Double Time', name: 'Double Time'},
    { id: 'Weekend', name: 'Weekend'}
  ];
  dayName: any;

  constructor(
    public _dialogService: DialogService,
    public _utilService: UtilityService,
    public dialogRef: MatDialogRef<AddTimesheetPopupComponent>,
    public _timeSheetService: TimesheetService,
    public _predefinedTaskService: PredefinedTasksService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _jobService: JobsService,

  ) {
    if (this.data?.data?.edit) {
      this.updateTimeSheet = true;
      this.editDetails = this.data?.data.selectedRecord
    }
  }

  ngOnInit(): void {
    this.createTimeSheetForm();
    // this.getAllEngineerDropdownsWithoutProperty();
    this.getAllEngineersDropdown();

    if (this.editDetails) {
      this.setFormData();
    }
    this.loadAllTasks();
    if (this.currentUserInfo.current_Role == 'Engineer') {
      this.getAllPropertiesDropdowns(this.currentUserInfo?.id)
    }
  }

  /* timeSheetForm */
  createTimeSheetForm() {
    this.addTimeSheetForm = new FormGroup({
      properties: new FormControl(null),
      jobs: new FormControl(null),
      engineer: new FormControl((this.currentUserInfo.current_Role == 'Engineer' ? this.currentUserInfo.id : null), [Validators.required]),
      workDate: new FormControl(new Date(), [Validators.required]),
      description: new FormControl(null),
      startTime: new FormControl(''),
      endTime: new FormControl(''),
      totalTimeSpent: new FormControl('', [Validators.required]),
      //totalHours: new FormControl<number>(0, [Validators.max(24), Validators.min(0), Validators.required]),
      //totalMinutes: new FormControl<number>(0, [Validators.max(59), Validators.min(0), Validators.required]),
      totalSecond: new FormControl<number>(0),
      travelTime: new FormControl(''),
      //travelHours: new FormControl<number>(0, [Validators.max(24), Validators.min(0)]),
      //travelMinutes: new FormControl<number>(0, [Validators.max(59), Validators.min(0)]),
      totalTimeBill: new FormControl<boolean>(false),
      overTimeBill: new FormControl<boolean>(false),
      is_billable: new FormControl('Billable', [Validators.required]),
      applied_rate: new FormControl(this.appliedRateList[0])
    })
    if (this.editDetails) {

      // this.getAllEngineerDropdownsWithoutProperty();
      this.getAllEngineersDropdown();
      this.selectedPropertyId = this.editDetails._property?.id;
      this.radioItems = [
        { name: 'Billable', value: 'Billable', checked: this.editDetails?.is_billable ? true : false },
        { name: 'Non Billable', value: 'Non Billable', checked: this.editDetails?.is_billable ? false : true }
      ];
      // if (this.selectedPoperyId != "") {
      //   this.getAllEngineerDropdowns();
      // }

      if (this.currentUserInfo.current_Role == 'Engineer' && this.selectedPropertyId != undefined) {
        this.getAllJobsDropdowns(this.currentUserInfo?.id)
      }

      this.engineerChange(this.editDetails._engineer)
    }

  }

  loadAllTasks() {
    let requestURL = `${environment.LocalDev_URL_V2}` + `predefined-task/`;
    this._predefinedTaskService.doGET(requestURL).subscribe({
      next: (response: any) => {
        this.allDescriptionList = response.results.map((task) => {
          return { id: task.id, name: task.description, is_billable: task.is_billable === true ? 'Billable' : 'Non Billable' };
        });
      },
      error: (error) => {
      },
      complete: () => {
      }
    })
  }

  splitHours(time: any) {
    if ((time == '') || (time == null) || (time == 'undefine')) {
      return 0
    } else {
      const timeString = time;
      const timeParts = timeString.split(':');
      const hours = Number(timeParts[0]);
      return hours;
    }

  }

  splitMinutes(time: any) {
    if ((time == '') || (time == null) || (time == 'undefine')) {
      return 0
    } else {
      const timeString = time;
      const timeParts = timeString.split(':');
      const minutes = Number(timeParts[1]);
      return minutes;
    }
  }

  splitSeconds(time: any) {
    if ((time == '') || (time == null) || (time == 'undefine')) {
      return 0
    } else {
      const timeString = time;
      const timeParts = timeString.split(':');
      const seconds = Number(timeParts[2]);
      return seconds;
    }
  }

  /*** Set Create Contact Form Data */
  setFormData() {
    this.addTimeSheetForm.patchValue({
      properties: this.editDetails._property,
      jobs: this.editDetails.job,
      engineer: this.editDetails._engineer,
      startTime: this.editDetails._from,
      endTime: this.editDetails.to,
      workDate: this.editDetails.date,
      description: this.editDetails.description,
      totalTimeBill: this.editDetails.trip_charge,
      overTimeBill: this.editDetails.overtime_rate,
      totalTimeSpent: this.editDetails.labor_time.substring(0, 5),
      //totalHours: this.splitHours(this.editDetails.labor_time),
      //totalMinutes: this.splitMinutes(this.editDetails.labor_time),
      totalSecond: this.splitSeconds(this.editDetails.labor_time),
      travelTime: this.editDetails.travel_time.substring(0, 5),
      //travelHours: this.splitHours(this.editDetails.travel_time),
      //travelMinutes: this.splitMinutes(this.editDetails.travel_time),
      is_billable: this.editDetails.is_billable ? 'Billable' : "Non Billable",
      applied_rate: this.editDetails.applied_rate
    });

  }

  getAllPropertiesDropdowns(id: any) {
    let requestURL = environment.baseURL + `engineers/${id}/property-dropdown/`;
    this._dialogService.doGET(requestURL).subscribe(
      {
        next: (response: any) => {
          this.allPropertiesList = response;
        },
        error: (error: HttpErrorResponse) => {
          this.isLoader = false;
          this.allPropertiesList = [];
          this._utilService.showErrorMessage(error);
        },
        complete: () => {
          this.isLoader = false;
        }
      }
    );
  }

  propertiesChange(event: any) {

    this.addTimeSheetForm.get('jobs').setValue(null)

    this.selectedPropertyId = event?.id;

    if (this.selectEngineerId && this.selectedPropertyId) {
      this.getAllJobsDropdowns(this.selectEngineerId);
    }

    // if (this.selectedPoperyId != "" && this.currentUserInfo.current_Role != 'Engineer') {
    //   this.getAllEngineerDropdowns();
    // }

    if (this.currentUserInfo.current_Role == 'Engineer') {
      this.getAllJobsDropdowns(this.currentUserInfo?.id)
    }
    // else {
    //   // this.addTimeSheetForm.get('engineer').setValue(null)
    // }
  }

  // getAllEngineerDropdowns() {
  //   let requestURL = environment.baseURL + `properties/` + this.selectedPoperyId + `/engineers/`;
  //   this._dialogService.doGET(requestURL).subscribe(
  //     {
  //       next: (response: any) => {
  //         this.allEngineerList = response;
  //         this.allEngineerList.map(elm => elm.name = elm.full_name)          
  //       },
  //       error: (error: HttpErrorResponse) => {
  //         this.isLoader = false;
  //         this.allEngineerList = [];
  //         this._utilService.showErrorMessage(error);
  //       },
  //       complete: () => {
  //         this.isLoader = false;
  //       }
  //     }
  //   );
  // }

  getAllEngineerDropdownsWithoutProperty() {
    let requestURL = environment.LocalDev_URL_V2 + `drop-down/engineers/`;
    this._dialogService.doGET(requestURL).subscribe(
      {
        next: (response: any) => {
          this.allEngineerList = response.result;
        },
        error: (error: HttpErrorResponse) => {
          this.isLoader = false;
          this.allEngineerList = [];
          this._utilService.showErrorMessage(error);
        },
        complete: () => {
          this.isLoader = false;
        }
      }
    );
  }


  onScrollToEndEngineer(event: any) {
    if (this.allEngineerPagination.pagination.currentPage < this.allEngineerPagination.pagination.pageCount) {
      this.allEngineerPagination.pagination.currentPage++;
      this.getAllEngineersDropdown({}, true);
    }

  }


  /**
  * Get All Vendors Dropdown
  */
  getAllEngineersDropdown(searchTerm?: any, isPagination?: boolean): void {
    let requestURL = environment.LocalDev_URL_V2 + `drop-down/engineers/`;

    if (isPagination) {
      if (this.allEngineerPagination.pagination.next) {
        requestURL = requestURL + '?page=' + this.allEngineerPagination.pagination.next;
      }
    } else {

      if (searchTerm?.term) {
        requestURL += '?search=' + searchTerm.term;
      }
    }

    this.engineerLoading = true;

    this._dialogService.doGET(requestURL).subscribe({
      next: (response: any) => {

        this.allEngineerPagination.pagination.total = response.count;
        // this.allEngineerPagination.pagination.size = response.size;
        this.allEngineerPagination.pagination.pageCount = Math.ceil(this.allEngineerPagination.pagination.total / this.allEngineerPagination.pagination.size);

        // Pagination Code
        let ListItems = response.result || [];
        this.allEngineerPagination.pagination.next = response.next
          ? response.next
          : '';
        this.allEngineerPagination.pagination.count = response.count
          ? response.count
          : 0;

        if (isPagination) {
          // this.allEngineerList.push(...ListItems);
          this.allEngineerList = [...this.allEngineerList, ...ListItems];

        } else {
          this.allEngineerList = ListItems;
        }

      },
      error: (error) => {
        this.engineerLoading = false;
      },
      complete: () => {
        this.engineerLoading = false;
      }
    });

  }

  engineerChange(event: any) {
    this.selectEngineerId = event?.id;
    this.addTimeSheetForm.get('jobs').setValue(null)
    // if (this.selectEngineerId != "") {
    //   this.getAllJobsDropdowns(this.selectEngineerId)
    //   this.getAllPropertiesDropdowns(this.selectEngineerId)
    // }
    this.getAllPropertiesDropdowns(this.selectEngineerId);
  }

  descriptionChange(event: any) {
    if (event?.id) {
      this.radioItems = [
        { name: 'Billable', value: 'Billable', checked: event?.is_billable === 'Billable' ? true : false },
        { name: 'Non Billable', value: 'Non Billable', checked: event?.is_billable === 'Non Billable' ? true : false }
      ];
      if (event?.is_billable === 'Billable') {
        this.addTimeSheetForm.patchValue({
          is_billable: event.is_billable
        });
      } else {
        this.addTimeSheetForm.patchValue({
          is_billable: event.is_billable,
          totalTimeBill: false,
          overTimeBill: false,
        });
      }
    } else {
      this.radioItems = [
        { name: 'Billable', value: 'Billable', checked: true },
        { name: 'Non Billable', value: 'Non Billable', checked: false },
      ];
      this.addTimeSheetForm.patchValue({
        is_billable: true
      });
    }

  }

  getAllJobsDropdowns(id: any) {
    let requestURL = environment.LocalDev_URL_V2 + `timesheet/jobs/?property=` + this.selectedPropertyId + `&engineer=` + id;
    this._dialogService.doGET(requestURL).subscribe(
      {
        next: (response: any) => {
          this.allJobsList = response;
          this.allJobsList.map(elm => elm.name = elm.id)
        },
        error: (error: HttpErrorResponse) => {
          this.isLoader = false;
          this.allJobsList = [];
          this._utilService.showErrorMessage(error);
        },
        complete: () => {
          this.isLoader = false;
        }
      }
    );
  }

  errorCheckingTotalTime(keyValue) {
    this.totalTimeError = (parseInt(keyValue.target.value.split(':')[0]));
  }
  errorCheckingTravelTime(keyValue) {
    this.travelTimeError = (parseInt(keyValue.target.value.split(':')[0]));
  }

  calculateDuration(event: string, time: string) {
    if (time == 'start') {
      this.startTime = event;
      this.endTime = this.addTimeSheetForm.value.endTime;
      if (this.addTimeSheetForm.get('totalTimeSpent').value != null) {
        this.addTimeSheetForm.patchValue({
          'totalTimeSpent': '',
        })
      }
    } else if (time == 'end') {
      this.endTime = event;
      this.startTime = this.addTimeSheetForm.value.startTime;
      if (this.addTimeSheetForm.get('totalTimeSpent').value != null) {
        this.addTimeSheetForm.patchValue({
          'totalTimeSpent': '',
        })
      }
    }

    // Parse the start and end times
    const startMinutes = parseInt(this.startTime?.split(':')[1]?.split(' ')[0]);
    const endMinutes = parseInt(this.endTime?.split(':')[1]?.split(' ')[0]);

    const [startHours24, endHours24] = this._utilService.convertTo24Format(this.startTime, this.endTime)
    // Calculate the duration in minutes
    const durationMinutes = (endHours24 * 60 + endMinutes) - (startHours24 * 60 + startMinutes);

    // Convert to hours and minutes
    const durationHours = Math.floor(durationMinutes / 60);
    const durationMinutesRemainder = durationMinutes % 60;

    this.calculateHours = durationHours;
    this.calculateMinute = durationMinutesRemainder;

    //Set value total Time spend  formControl
    if (this.startTime === this.endTime) {
      this.addTimeSheetForm.get('totalTimeSpent').setValue('');
      // this.addTimeSheetForm.get('totalHours').setValue(0)
      // this.addTimeSheetForm.get('totalMinutes').setValue(0)

      this.Error = true;
    }

    if (durationHours || durationMinutesRemainder) {
      if (durationHours > 0 || durationMinutesRemainder > 0) {
        //this.addTimeSheetForm.get('totalTimeSpent').setValue(displayTime);
        this.addTimeSheetForm.get('totalTimeSpent').setValue(this.digitAddedTime(durationHours) + ':' + this.digitAddedTime(durationMinutesRemainder));
        // this.addTimeSheetForm.get('totalHours').setValue(durationHours)
        // this.addTimeSheetForm.get('totalMinutes').setValue(durationMinutesRemainder)
        this.Error = false;
      }
      else {
        this.addTimeSheetForm.get('totalTimeSpent').setValue('');
        // this.addTimeSheetForm.get('totalHours').setValue(0)
        // this.addTimeSheetForm.get('totalMinutes').setValue(0)
        this.Error = true;
      }
    }
    this.totalTimeError = null;


  }

  timeSpendHoursChange() {
    this.addTimeSheetForm.get('startTime').setValue('')
    this.addTimeSheetForm.get('endTime').setValue('')
    this.startTime = '';
    this.endTime = '';
    this.Error = false;
  }

  // timeSpendMinuteChange() {
  //   let event = this.addTimeSheetForm.get('totalMinutes').value
  //   if ((event > 59) || (event < 0) || (event == null) || (event != this.calculateMinute)) {
  //     this.addTimeSheetForm.get('startTime').setValue('')
  //     this.addTimeSheetForm.get('endTime').setValue('')
  //     this.Error = false;
  //   }

  // }

  inputMaxLength(target: any) {

    if (target.value.length > 1) {
      let value = target.value;
      value = value.replace(/^0+/, '');
      value = value.replace('/\D?g', '');
      value = value.replace('/^(\d{2})/$', '');
      target.value = value;
      if (value) {
        target.value = target.value.slice(0, 2);
      }
    }
  }

  omitSpecialCharacters(e: any): any {
    try {
      if (/^[0-9\s]*$/.test(e.key)) {
        if (e.keyCode == 69 || e.keyCode == 101 || e.keyCode == 32) {
          return false;
        } else {
          return true;
        }
      } else {
        e.preventDefault();
        return false;
      }
    } catch (e) { }
  }


  // increaseValue(event: number, name: string) {

  //   if ((event < 24) && (name === 'hours')) {
  //     this.addTimeSheetForm.get('totalHours').setValue(Number(event) + 1)
  //     this.addTimeSheetForm.get('startTime').setValue('')
  //     this.addTimeSheetForm.get('endTime').setValue('')
  //     this.startTime = '';
  //     this.endTime = '';
  //   }
  //   if ((event < 59) && (name === 'minutes')) {
  //     this.addTimeSheetForm.get('totalMinutes').setValue(Number(event) + 1)
  //     this.addTimeSheetForm.get('startTime').setValue('')
  //     this.addTimeSheetForm.get('endTime').setValue('')
  //     this.startTime = '';
  //     this.endTime = '';
  //   }
  //   if ((event < 24) && (name === 'travelHour')) {
  //     this.addTimeSheetForm.get('travelHours').setValue(Number(event) + 1)
  //   }
  //   if ((event < 59) && (name === 'travelMinute')) {
  //     this.addTimeSheetForm.get('travelMinutes').setValue(Number(event) + 1)
  //   }
  // }

  // decreaseValue(event: number, name: string) {

  //   if ((event > 0) && (name === 'hours')) {
  //     this.addTimeSheetForm.get('totalHours').setValue(event - 1)
  //     this.addTimeSheetForm.get('startTime').setValue('')
  //     this.addTimeSheetForm.get('endTime').setValue('')
  //   }
  //   if ((event > 0) && (name === 'minutes')) {
  //     this.addTimeSheetForm.get('totalMinutes').setValue(event - 1)
  //     this.addTimeSheetForm.get('startTime').setValue('')
  //     this.addTimeSheetForm.get('endTime').setValue('')
  //   }
  //   if ((event > 0) && (name === 'travelHour')) {
  //     this.addTimeSheetForm.get('travelHours').setValue(event - 1)
  //   }
  //   if ((event > 0) && (name === 'travelMinute')) {
  //     this.addTimeSheetForm.get('travelMinutes').setValue(event - 1)
  //   }
  // }

  formatDateToYYYY_MM_DD(date: Date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  digitAddedTime(time: any) {
    if ((time == '') || (time == null) || (time == 'undefine')) {
      return "00"
    }
    else if (time.toString().length === 1) {
      time = "0" + time;
      return time;
    } else {
      return time;
    }
  }

  onSubmit() {
    if (this.getSubmitDisabled()) return

    let date = this.formatDateToYYYY_MM_DD(this.addTimeSheetForm.get('workDate').value)
    // let laborTimeHours = this.digitAddedTime(this.addTimeSheetForm.get('totalHours').value)
    // let laborTimeMinute = this.digitAddedTime(this.addTimeSheetForm.get('totalMinutes').value)
    // let travelTimeHours = this.digitAddedTime(this.addTimeSheetForm.get('travelHours').value)
    // let travelTimeMinute = this.digitAddedTime(this.addTimeSheetForm.get('travelMinutes').value)
    this.formSubmitted = true;

    let postBody: any = {
      "property": this.addTimeSheetForm.value.properties?.id,
      "user": (this.addTimeSheetForm.value.engineer?.id) ? (this.addTimeSheetForm.value.engineer?.id) : this.addTimeSheetForm.value.engineer,
      "date": date,
      "job": this.addTimeSheetForm.value.jobs?.id,
      "_from": this.addTimeSheetForm.value.startTime,
      "to": this.addTimeSheetForm.value.endTime,
      "labor_time": (this.addTimeSheetForm.value.totalTimeSpent?.length === 5 || this.addTimeSheetForm.value.totalTimeSpent?.length === 4) ? this.addTimeSheetForm.value.totalTimeSpent + ':00' : this.addTimeSheetForm.value.totalTimeSpent,
      "travel_time": (this.addTimeSheetForm.value.travelTime?.length === 5 || this.addTimeSheetForm.value.travelTime?.length === 4) ? this.addTimeSheetForm.value.travelTime + ':00' : this.addTimeSheetForm.value.travelTime === '' ? '00:00:00' : this.addTimeSheetForm.value.travelTime,
      "trip_charge": this.addTimeSheetForm.value.totalTimeBill,
      "overtime_rate": this.addTimeSheetForm.value.overTimeBill,
      "is_billable": this.addTimeSheetForm.value.is_billable === 'Billable' ? true : false,
      "applied_rate": this.addTimeSheetForm.value.applied_rate.name
    };
    if (this.addTimeSheetForm?.value?.description?.id) {
      postBody.task_description = this.addTimeSheetForm.value.description.id;
    } else {
      postBody.description = this.addTimeSheetForm?.value?.description?.name;
    }

    if (this.addTimeSheetForm.valid && !this.Error) {
      this.postApiCall(postBody)
    }

  }

  onResubmit() {
    // let laborTimeHours = this.digitAddedTime(this.addTimeSheetForm.get('totalHours').value)
    // let laborTimeMinute = this.digitAddedTime(this.addTimeSheetForm.get('totalMinutes').value)
    // let travelTimeHours = this.digitAddedTime(this.addTimeSheetForm.get('travelHours').value)
    // let travelTimeMinute = this.digitAddedTime(this.addTimeSheetForm.get('travelMinutes').value)
    this.formSubmitted = true;

    let postBody: any = {
      "id": this.editDetails.id,
      "property": this.addTimeSheetForm.value.properties?.id,
      "user": (this.addTimeSheetForm.value.engineer?.id) ? (this.addTimeSheetForm.value.engineer?.id) : this.addTimeSheetForm.value.engineer,
      "date": this.editDetails.date,
      "job": (this.addTimeSheetForm.value.jobs?.id) ? (this.addTimeSheetForm.value.jobs?.id) : this.editDetails.job,
      "_from": this.addTimeSheetForm.value.startTime,
      "to": this.addTimeSheetForm.value.endTime,
      "labor_time": (this.addTimeSheetForm.value.totalTimeSpent?.length === 5 || this.addTimeSheetForm.value.totalTimeSpent?.length === 4) ? this.addTimeSheetForm.value.totalTimeSpent + ':00' : this.addTimeSheetForm.value.totalTimeSpent,
      "travel_time": (this.addTimeSheetForm.value.travelTime?.length === 5 || this.addTimeSheetForm.value.travelTime?.length === 4) ? this.addTimeSheetForm.value.travelTime + ':00' : this.addTimeSheetForm.value.travelTime === '' ? '00:00:00' : this.addTimeSheetForm.value.travelTime,
      "trip_charge": this.addTimeSheetForm.value.totalTimeBill,
      "overtime_rate": this.addTimeSheetForm.value.overTimeBill,
      "is_billable": this.addTimeSheetForm.value.is_billable === 'Billable' ? true : false,
      "applied_rate": this.addTimeSheetForm.value.applied_rate.name
    };
    if (this.addTimeSheetForm?.value?.description?.id) {
      postBody.task_description = this.addTimeSheetForm.value.description.id;
    } else {
      postBody.description = this.addTimeSheetForm?.value?.description?.name;
    }
    if (this.addTimeSheetForm.valid) {
      this.postApiCall(postBody)
    }

  }

  onCancel() {
    this.addTimeSheetForm.reset();
    this.createTimeSheetForm();
  }

  getSubmitDisabled() {
    const [startHours24, endHours24] = this._utilService.convertTo24Format(this.startTime, this.endTime)

    const endBeforeStart = startHours24 > endHours24
    const noDuration = !this.addTimeSheetForm?.value?.totalTimeSpent;

    return this.isLoader || noDuration || endBeforeStart
  }

  postApiCall(payload: any) {
    this.isLoader = true;
    let updateTimeSheetUrl = environment.LocalDev_URL_V2 + 'timesheet/bulk-update-or-create/';
    const successMessage = this.updateTimeSheet ? 'Timesheet updated successfully' : 'Timesheet added successfully';
    this._dialogService.doPOST(updateTimeSheetUrl, [payload]).subscribe(
      {
        next: (response: any) => {
          this._timeSheetService.isAddOrUpdateTimeSheet = true;
          this.dialogRef.close(response);
        },
        error: (error: HttpErrorResponse) => {
          this.isLoader = false;
          this._utilService.showErrorMessage(error);
        },
        complete: () => {
          setTimeout(() => {
            this.isLoader = false;
          }, 500);
          this._utilService.showSuccess('', successMessage);

          
        }
      }
    );
  }

  deleteApiCall() {
    this.isLoader = true;
    let payload = { labors: [this.editDetails.id] }

    let updateTimeSheetUrl = environment.LocalDev_URL_V2 + 'timesheet/bulk-update-or-create/'
    this._dialogService.doDELETEapi(updateTimeSheetUrl, payload).subscribe(
      {
        next: (response: any) => {

        },
        error: (error: HttpErrorResponse) => {
          this.isLoader = false;
          this._utilService.showErrorMessage(error);
        },
        complete: () => {
          this._timeSheetService.isAddOrUpdateTimeSheet = true;
          this.isLoader = false;
          this.dialogRef.close();
          this._utilService.showSuccess('', 'Timesheet deleted successfully');

          if(this.editDetails?.job_id){
            this._jobService.triggerPusherEventForJobTabs('Timesheet', this.editDetails?.job_id);
          }
        }
      }
    );
  }

  changeRadioBtnEvent(event: any) {
    if (event === 'Billable') {
      this.addTimeSheetForm.patchValue({
        is_billable: event
      });
    } else {
      this.addTimeSheetForm.patchValue({
        is_billable: event,
        totalTimeBill: false,
        overTimeBill: false,
      });
    }


  }

  totalTimeChange(event: any): void {
    let input = event.target.value;

    // Remove any non-numeric characters
    input = input.replace(/\D/g, '');

    // Parse input as a number
    let timeValue = parseInt(input, 10);

    // Initialize hours and minutes
    let hours = 0;
    let minutes = 0;

    if (input.length === 0) {
      return;
    }

    if (input.length <= 2) {
      // Only minutes entered
      minutes = timeValue;
    } else {
      // If more than 2 digits, calculate hours and minutes
      // Last 2 digits are minutes
      minutes = timeValue % 100;
      // Remaining digits are hours
      hours = Math.floor(timeValue / 100);
    }

    // Ensure minutes are within 0-59 range
    if (minutes >= 60) {
      hours += Math.floor(minutes / 60);
      minutes = minutes % 60;
    }

    // Format hours and minutes with leading zeros if necessary
    let formattedHours = hours.toString().padStart(2, '0');
    let formattedMinutes = minutes.toString().padStart(2, '0');

    // Set the formatted time back to the input field
    const formattedTime = `${formattedHours}:${formattedMinutes}`;
    event.target.value = formattedTime;

    // Update the form value using patchValue
    this.addTimeSheetForm.patchValue({ totalTimeSpent: formattedTime });
        
    // Split formatted time into hours and minutes
    let [hrs, mins] = formattedTime.split(':').map(Number);

    // Set totalTimeError if time exceeds 24:00
    this.totalTimeError = (hrs > 24 || (hrs === 24 && mins > 0)) ? hrs : 0;
  }

  travelTimeChange(event: any): void {
    let input = event.target.value;

    // Remove any non-numeric characters
    input = input.replace(/\D/g, '');

    // Parse input as a number
    let timeValue = parseInt(input, 10);

    // Initialize hours and minutes
    let hours = 0;
    let minutes = 0;

    if (input.length === 0) {
      return;
    }

    if (input.length <= 2) {
      // Only minutes entered
      minutes = timeValue;
    } else {
      // If more than 2 digits, calculate hours and minutes
      // Last 2 digits are minutes
      minutes = timeValue % 100;
      // Remaining digits are hours
      hours = Math.floor(timeValue / 100);
    }

    // Ensure minutes are within 0-59 range
    if (minutes >= 60) {
      hours += Math.floor(minutes / 60);
      minutes = minutes % 60;
    }

    // Format hours and minutes with leading zeros if necessary
    let formattedHours = hours.toString().padStart(2, '0');
    let formattedMinutes = minutes.toString().padStart(2, '0');

    // Set the formatted time back to the input field
    const formattedTime = `${formattedHours}:${formattedMinutes}`;
    event.target.value = formattedTime;

    // Update the form value using patchValue
    this.addTimeSheetForm.patchValue({ travelTime: formattedTime });
    let [hrs, mins] = formattedTime.split(':').map(Number);

    // Set totalTimeError if time exceeds 24:00
    this.travelTimeError = (hrs > 24 || (hrs === 24 && mins > 0)) ? hrs : 0;
  }

  allowOnlyTimeInput(event: KeyboardEvent): boolean {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':'];
    const specialKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];

    const inputElement = event.target as HTMLInputElement;
    const caretPosition = inputElement.selectionStart;

    // Allow special keys like backspace, delete, arrow keys, etc.
    if (specialKeys.includes(event.key)) {
      return true;
    }

    // Prevent non-numeric characters except colon
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
      return false;
    }

    // Prevent typing colon if it already exists or at the first/last position
    if (event.key === ':' && (inputElement.value.includes(':') || caretPosition === 0 || caretPosition === inputElement.value.length)) {
      event.preventDefault();
      return false;
    }

    // Allow typing over existing characters
    if (caretPosition !== null && caretPosition < 5 && inputElement.selectionEnd === caretPosition) {
      const valueWithoutSelection = inputElement.value.slice(0, caretPosition) + inputElement.value.slice(caretPosition + 1);

      // Prevent more than 4 numbers excluding colon
      if (valueWithoutSelection.replace(':', '').length >= 4 && allowedKeys.slice(0, 10).includes(event.key)) {
        event.preventDefault();
        return false;
      }
    }

    return true;
  }

  onWorkDataChange(event: any) {
    const date = new Date(event);
    this.dayName = date.toLocaleDateString('en-US', { weekday: 'short' });

    const rateValue = (this.dayName === 'Sat' || this.dayName === 'Sun')
      ? this.appliedRateList[3]
      : this.appliedRateList[0];

    this.addTimeSheetForm.get('applied_rate')?.setValue(rateValue);
  }

}
