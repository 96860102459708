import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ButtonComponent } from '../button/button.component';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
    selector: 'fyxt-three-dots-dropdown',
    templateUrl: './three-dots-dropdown.component.html',
    styleUrls: ['./three-dots-dropdown.component.scss'],
    standalone: true,
    imports: [NgIf, ButtonComponent, MatMenuModule, NgClass, MatIconModule, NgFor]
})
export class ThreeDotsDropdownComponent implements OnInit {
  @Input() menuLists: Array<any> = [{ label: 'Set as Default View', value: 'Set as Default View'}, { label: 'Delete', value: 'Delete'}];
  @Input() customClass: string = '';
  @Output() onChange = new EventEmitter<any>();
  // menuIcon!: boolean;
  @Input() menuIcon:boolean = false;
  @Input() small: boolean = false;
  @Input() isDisabled = false;
aboveMenu: any;
  constructor() { }

  ngOnInit(): void {}
  
  menuOnSelect(selectedMenu: any) {
    this.onChange.next(selectedMenu);
  }

  /** Track by Index  **/
  trackByIndex(index: number, item: any): number {
    return index;
  }
}
