import { GraphqlEquipmentManagementService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/equipment-management/graphql-equipment-management.service';
import { EquipmentsService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/equipments.service';
import { Component, Inject, Input, OnInit, Optional, EventEmitter, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ItemList } from 'src/app/@fyxt/_shared/_reusable_ui/_controls/dropdown-select/itemType.class';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { SidebarService } from 'src/app/@fyxt/_services/_layout/sidebar.service';
import { GraphqlAdditionalDataService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/additional-data/graphql-additional-data.service'
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilityService } from 'src/app/services/utility.service';
import { GraphQLError } from 'graphql';
import { DropdownSelectComponent } from '../../_reusable_ui/_controls/dropdown-select/dropdown-select/dropdown-select.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TextboxComponent } from '../../_reusable_ui/_controls/textbox/textbox.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../_reusable_ui/_components/button/button.component';
import { LoaderSmallComponent } from '../../_reusable_ui/_components/loader-small/loader-small.component';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'fyxt-create-item',
    templateUrl: './create-item.component.html',
    styleUrls: ['./create-item.component.scss'],
    standalone: true,
    imports: [NgIf, LoaderSmallComponent, ButtonComponent, MatIconModule, MatDividerModule, FormsModule, ReactiveFormsModule, NgClass, TextboxComponent, NgxIntlTelInputModule, DropdownSelectComponent]
})
export class CreateItemComponent implements OnInit {

  @Input() updateItem:boolean = false;

  @Output() onDateChange = new EventEmitter();

  items: ItemList[]  = [
    {id:1,label:'Active',name:'Active'},
    {id:2,label:'Inactive',name:'Inactive'},
];

listStatus: ItemList[] = [
  {id:1,label:'Months',name:'Months'},
  {id:2,label:'Years',name:'Years'},
];

categoryList:any[]=[];

addItemForm!: FormGroup;

  formSubmitted : boolean = false ;

  constructor(
    public formbuilder: FormBuilder,
    public _dialogService: DialogService,
    public sidebarService: SidebarService,
    public _graphql:GraphqlAdditionalDataService,
    public graphql:GraphqlEquipmentManagementService,
    public _utilService:UtilityService,
    private activatedRoute: ActivatedRoute,
    public equipmentService:EquipmentsService,
    @Optional() @Inject(MAT_DIALOG_DATA) public matDialogData:any,
  ) { }

  ngOnInit(): void {
    this.initializeForm();
    this.loadCategories()
  }

  loadCategories(){
    this.graphql.loadCategoryFilters().subscribe((res:any)=>{
      this.categoryList = res || [];
    })
  }
 /**
  * On Change Event
  * @param event
  */
  slideToggleChange(event: any): void {}

    /**
   * Initialize Create Company Form
   */
  initializeForm(){
    this.addItemForm = this.formbuilder.group({
      'itemName': ['',Validators.required],
      'category': [null]
     });

     if(this.updateItem){
      this.setFormData();
     }
  }

  /**
   * Set Create Company Form Data
   */
   setFormData(){
    const id = this.activatedRoute.snapshot.paramMap.get('id');
      this._graphql.additionalDataDetail(id).subscribe({
        next:(res:any)=>{
          this._graphql.selectedItem = res.getAdditionalItem;
        },
        error(err) {},
        complete:()=>{
          this.addItemForm.patchValue({
            itemName:this._graphql.selectedItem.name,
            category:JSON.parse(this._graphql.selectedItem.category)
          });
        }
      })


  }

  onFocusOut(e?:any){
    if(this.updateItem) {
      if(this._graphql.selectedItem.name != this.addItemForm.value.itemName && !e){
        if(this.addItemForm.value.itemName!==''){
          this._graphql.updateAdditionalDataDetail(this._graphql.selectedItem.id,this.addItemForm.value.itemName).subscribe({
            next:(res:any)=>{
              this._graphql.selectedItem.name = res.updateAdditionalItem.name;
              this._utilService.showSuccess('', 'Additional Data Item Updated Successfully');
            },
              error:(err:GraphQLError) => {
                const error= err.message;
                const messageWithoutBraces = error.slice(1, -1);
                const value = messageWithoutBraces.split('=')[1];
                this._utilService.showError('', value);
         },
          })
        }
        else{
          this.addItemForm.get('itemName').setValue(this._graphql.selectedItem.name);
          this._utilService.showError('', 'Additonal Data Item Name Cannot be Empty');
        }
      }else{
        this._graphql.updateAdditionalDataDetail(this._graphql.selectedItem.id,null,this.addItemForm.value.category).subscribe({
          next:(res:any)=>{
            this._graphql.selectedItem.category = JSON.parse(res.updateAdditionalItem.category);
            this._utilService.showSuccess('', 'Additional Data Item Updated Successfully');
          },
            error:(err:GraphQLError) => {
        this._utilService.showError('', err);
       },
        })
      }
    }
  }



  onSubmit() {
    this.formSubmitted = true ;
    if(this.addItemForm.valid){
      this._graphql.createAdditionalDataItem(this.addItemForm.value);
      this.onCancel();
    }
  }


  onCancel() {
    this.sidebarService.toggleRightSidebar = !this.sidebarService.toggleRightSidebar;

    setTimeout(() => {
      this.formSubmitted = false;
      this.addItemForm.reset();
      this.sidebarService.setFormConext('');
      this.initializeForm();
    }, 100);

  }


  onDateSelected(){
    this.onDateChange.emit();
  }


}
