// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.export-schedule-tab .fyxt_new_ui_tabs .mat-tab-group .mat-tab-label-container {
  margin: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_views/_popup_views/export-view/export-view.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF","sourcesContent":[".export-schedule-tab .fyxt_new_ui_tabs .mat-tab-group .mat-tab-label-container {\n  margin: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
