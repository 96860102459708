import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

import {TableColumnTypes, ColumnSortType} from 'src/app/@fyxt/_shared/enums/table/table-column-types.enum';
import {AppTableRow, AppTableSource} from 'src/app/@fyxt/_shared/models/app-table-model';
import {environment} from "../../../../../../environments/environment";
import {DialogService} from "../../../../_services/dialog/dialog.service";
import {UtilityService} from "../../../../../services/utility.service";
import {TableService} from "../../../../_services/table/table.service";
import {ConfigService} from "../../../../../services/config.service";
import {SelectionModel} from "@angular/cdk/collections";
import { TableComponent } from '../../../_reusable_ui/_components/table/table.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { NgIf } from '@angular/common';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';

@Component({
    selector: 'fyxt-company-assign-properties-dialog',
    templateUrl: './company-assign-properties-dialog.component.html',
    styleUrls: ['./company-assign-properties-dialog.component.scss'],
    standalone: true,
    imports: [SearchInputComponent, FormsModule, NgxIntlTelInputModule, NgIf, ButtonComponent, MatDialogModule, LoaderSmallComponent, TableComponent]
})
export class CompanyAssignPropertiesDialogComponent implements OnInit {
  selectedCompanies = [];
  propertiesDataSource: any;
  checkedProperties: any[] = [];
  isLoader: boolean;
  assignButtonLoader: boolean;
  isSourceQueryNeeded: boolean
  isCompanyQueryNeeded: boolean;
  searchInput: any = "";
  moduleName: string;
  totalRows = 1;
  pageIndexAPI = 0;
  perPage = 25;
  currentPage = 0;
  propertiesSort: {
      active: '',
      direction: ''
    };
  propertiesHeader = [
    {
      value: 'checkbox',
      name: '',
    },
    {
      value: 'name',
      sort: ColumnSortType.none,
      name: 'Property Name',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'address',
      sort: ColumnSortType.none,
      name: 'Address',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'city',
      sort: ColumnSortType.none,
      name: 'City',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'state',
      sort: ColumnSortType.none,
      name: 'State',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'zipcode',
      sort: ColumnSortType.none,
      name: 'Zip  Code',
      type: TableColumnTypes.text,
      className: 'table-header'
    }
    ];


  constructor(
    public dialogRef: MatDialogRef<CompanyAssignPropertiesDialogComponent>,
    public _dialogService: DialogService, public _utilService: UtilityService,
    public tableService: TableService, public _Config: ConfigService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.selectedCompanies = this.data?.data.checkedCompanies;
    this.moduleName = this.data?.data.moduleName;
    this.isSourceQueryNeeded = this.data?.data.isSourceQueryNeeded;
    this.isCompanyQueryNeeded = this.data?.data.isCompanyQueryNeeded;
  }

  propertiesTableRows: any[] = [];
  selectionTable = new SelectionModel<AppTableRow>(true, []);


  ngOnInit(): void {
    this.getAllPropertiesList(true);
  }


  isPropertiesAllSelected(isSelected: any) {
    // if (isSelected) {
    //   this.checkedProperties = this.propertiesTableRows.map(({id}) => id);
    // } else {
    //   this.checkedProperties = [];
    // }
    if (isSelected) {
      this.checkedProperties = this.checkedProperties.concat(this.propertiesTableRows);
      this.checkedProperties = this.removeDuplicateBulkActionProperties(this.checkedProperties);
    } else {
      this.checkedProperties = this.checkedProperties.filter(ar => !this.propertiesTableRows.find(rm => (rm.id === ar.id)));
      this.propertiesTableRows.map((companyData: any) => {
        return companyData.checked = false;
      });
    }
  }

  onPropertiesCheckRow(properties: any) {
    // let updatedCheckedProperties: number[];
    // const isPropertiesChecked = this.checkedProperties.some((id) => id === properties.id);
    // if (isPropertiesChecked) {
    //   let indexVal = this.checkedProperties.findIndex((id) => id === properties.id);
    //   if (this.checkedProperties.includes(properties.id)) {
    //     this.checkedProperties.splice(indexVal, 1);
    //   } else {
    //     this.checkedProperties.push(properties.id);
    //   }
    //   // updatedCheckedProperties = this.checkedProperties.filter((id) => id !== properties.id);
    // } else {
    //   // updatedCheckedProperties = [...this.checkedProperties, properties];
    //   this.checkedProperties.push(properties.id);
    // }
    // // this.checkedProperties = updatedCheckedProperties;
    let updatedCheckedProperties: number[];
    const isCompanyChecked = this.checkedProperties.some((checkedProperties) => checkedProperties.id === properties.id);
    if (isCompanyChecked) {
      updatedCheckedProperties = this.checkedProperties.filter((checkedProperties) => checkedProperties.id !== properties.id);
    } else {
      updatedCheckedProperties = [...this.checkedProperties, properties];
    }
    this.checkedProperties = updatedCheckedProperties;
  }

  assignedProperties() {
    if (this.checkedProperties.length == 0) {
      this._utilService.showError('Properties', 'Should select atleast 1 property');
      return false;
    }
    const payload = {
      properties_id: this.checkedProperties.map((properties: any) => {
        return properties.id
      }),
      companeies_id: this.selectedCompanies.map((company: any) => {
        return company.id;
      }),
    }
    this.assignButtonLoader = true;
    let requestURL = environment.fastAPI_Actual_URL + "/companies/bulk-actions/";
    if (this.isSourceQueryNeeded) {
      requestURL += '?source=dashboard';
    }

    this._dialogService.doPOST(requestURL, payload).subscribe(
      {
        next: (response: any) => {
          response.isAdd = true;
          this.checkedProperties = [];
          this._utilService.showSuccess('', 'Properties Assigned Successfully.');
          this.selectionTable.clear();
          this.dialogRef.close(response);
        },
        error: (error) => {
          this._utilService.showErrorMessage(error);
          this.assignButtonLoader = false;
          // this._utilService.showError('', 'Something went wrong, please try again later');
        },
        complete: () => {
          this.assignButtonLoader = false;
        }
      }
    );
  }

  /**
   * Get Selected View Tables
   */
  getAllPropertiesList(isFromOnInit?:boolean, searchValue?: string, sortPayload?: any, paginationIndex?: any, perPage?: any) {
    if (paginationIndex == undefined || paginationIndex == 0) {
      paginationIndex = 1;
    }
    this.isLoader = isFromOnInit;
    const offset = (paginationIndex - 1) * (perPage ? perPage : 25);
    let requestURL = environment.baseURL + 'properties/assign-property-dropdown/?page=' + paginationIndex + '&limit=' + (perPage ? perPage : 25) + '&offset=' + offset;
    if (this.isCompanyQueryNeeded) {
      requestURL += '&company_id='+ this.data?.data.checkedCompanies[0].id
    }
    // let requestURL = `${environment.fastAPI_Actual_URL + '/properties/assign-property-dropdown/'}`+'?custom-search=&ordering=-name';
    if (searchValue) {
       requestURL += `&custom-search=${this.searchInput}`;
       this._Config.isLoader = true;
    }
     if (sortPayload?.active && sortPayload?.direction) {
       if (sortPayload.direction == 'asc') {
        requestURL += `&ordering=${sortPayload.active}`;
       } else if (sortPayload.direction == 'desc') {
        requestURL += `&ordering=-${sortPayload.active}`;
       }
       this._Config.isLoader = true;
     }
    this._dialogService.doGET(requestURL).subscribe(
      {
        next: (response: any) => {
          this.propertiesTableRows = response.results || [];
          this.totalRows = response.count;
          if (this.checkedProperties.length > 0) {
            this.propertiesTableRows.map((companyData: any) => {
              this.checkedProperties.map((checkedCompanyData: any) => {
                if (companyData.id == checkedCompanyData.id) {
                  this.selectionTable.selected.push(companyData);
                  return companyData.checked = true;
                }
              });
            });
          }
          this.propertiesDataSource = this.tableService.setTableData(this.moduleName, this.propertiesHeader, this.propertiesTableRows, this.totalRows, this.currentPage, this.perPage);
          },
        error: (error) => {
          this.isLoader = false;
          this._Config.isLoader = false;
          this._utilService.showErrorMessage(error.error);
        },
        complete: () => {
          this.isLoader = false;
          this._Config.isLoader = false;
        }
      }
    );
  }

  onSortChange(sortState: any) {
    this.propertiesSort = sortState
    this.pageIndexAPI = 1;
    this.currentPage = 0;
    this.checkedProperties = [];
    this.getAllPropertiesList(false, this.searchInput, this.propertiesSort ?? this.propertiesSort, this.pageIndexAPI, this.perPage);
  }

  /**
   * On Search Table Rows
   * @param inputValue
   */
  onSearch(inputValue: any) {
    this.searchInput = inputValue;
    if (inputValue.length >= 2 || inputValue.length === 0) {
      this.pageIndexAPI = 1;
      this.currentPage = 0;
      this.checkedProperties = [];
      this.getAllPropertiesList(false, this.searchInput, this.propertiesSort ?? this.propertiesSort, this.pageIndexAPI, this.perPage);
    }
  }

  onPropertiesPageChange(event: any) {
    this.perPage = event.pageSize;
    this.currentPage = event.pageIndex;
    this.pageIndexAPI = event.pageIndex + 1;
    this._Config.isLoader = true;
    this.getAllPropertiesList(false, this.searchInput, this.propertiesSort ?? this.propertiesSort, this.pageIndexAPI, this.perPage);
  }

  private removeDuplicateBulkActionProperties(list: any): any {
    return list.reduce((unique, entry) => {
      if (!unique.some((obj) => obj.id == entry.id)) {
        unique.push(entry);
      }
      return unique;
    }, []);
  }
}
