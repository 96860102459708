import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewContainerRef
} from '@angular/core';
import { ThreeDotsDropdownComponent } from '../three-dots-dropdown/three-dots-dropdown.component';
import { NgIf, NgStyle } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';

export type GalleryMenuItems = 'delete'| 'share' | 'download';

@Component({
    selector: 'gallery-image',
    templateUrl: 'gallery-image.component.html',
    styleUrls: ['gallery-image.component.scss'],
    host: { 'class': 'fyxt-gallery-image' },
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatCheckboxModule, NgIf, NgStyle, ThreeDotsDropdownComponent]
})
export class GalleryImageComponent implements AfterViewInit {
  public readonly threeDotDropdownMenuLists: { value: GalleryMenuItems, label: string }[] = [
    { label: 'Delete', value: 'delete'},
    { label: 'Share', value: 'share' },
    { label: 'Download', value: 'download' }
  ];
  public readonly defaultImagePath: string = './assets/@fyxt/svgs/default_image.svg';
  public isDefaultPicture: boolean = true;
  private pImagePath!: string | null;
  private pIsChecked: boolean = false;
  @Input() imageType: string | null = null;
  @Input() showThreeDotMenu: boolean  = true;
  @Input() description: string | null = null;
  @Output() onChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onMenuItemClick: EventEmitter<GalleryMenuItems> = new EventEmitter();
  @Output() onClicked: EventEmitter<{}> = new EventEmitter<{}>();

  @Input()
  set isChecked(value: boolean) {
    if (value === this.isChecked) {
      return;
    }
    this.pIsChecked = value;
    this.onChanged.emit(this.isChecked);
  }

  get isChecked(): boolean {
    return this.pIsChecked;
  }

  @Input()
  set imagePath(path: string | null) {
    this.pImagePath = path;
    this.isDefaultPicture = !this.imagePath;
  }

  get imagePath(): string | null {
    return this.pImagePath;
  }

  @HostBinding('class.checked')
  get isCheckedClass(): boolean {
    return this.isChecked;
  }

  constructor(private viewContainerRef: ViewContainerRef) {}

  ngAfterViewInit(): void {
    this.viewContainerRef.element.nativeElement.querySelector('.mat-checkbox').classList.remove('mat-checkbox-disabled');
  }

  public onClick(): void {
    this.isChecked = !this.isChecked;
    let payload = {
     image: this.imagePath
    }
    this.onClicked.emit(payload);    
  }

  public onImgError(): void {
    this.isDefaultPicture = true;
  }

  public onMenuItemSelect(selectedMenu: {value: GalleryMenuItems}): void {
    this.onMenuItemClick.emit(selectedMenu.value);
  }

}
