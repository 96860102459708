import {Component, OnDestroy, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {InboxService_New} from "../../../../../Modules/_fyxt_modules/inbox-management/_services/inbox.service";
import {UtilityService} from "../../../../../services/utility.service";
import { MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import { environment } from 'src/environments/environment';
import { SMTP_Payload } from 'src/app/Modules/_fyxt_modules/inbox-management/inbox.types';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { CheckboxComponent } from '../../../_reusable_ui/_controls/checkbox/checkbox.component';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { RadioComponent } from '../../../_reusable_ui/_controls/radio/radio.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';
import { NgIf } from '@angular/common';

interface RadioButtonItem {
  name: string;
  value: string;
  checked:boolean;
}

@Component({
    selector: 'app-inbox-create-inbox',
    templateUrl: './inbox-create-inbox.component.html',
    styleUrls: ['./inbox-create-inbox.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, TextboxComponent, NgxIntlTelInputModule, RadioComponent, MatDialogModule, ButtonComponent, CheckboxComponent]
})
export class InboxCreateInboxComponent implements OnInit , OnDestroy {
  radioItems: RadioButtonItem[] = [
    {name: 'Outlook', value: 'Outlook',checked:true},
    { name: 'Gmail', value: 'GMAIL', checked: false },
    { name: 'SMTP', value: 'SMTP', checked: false },
  ];
  createInboxForm: FormGroup;
  createSMTPInboxForm: FormGroup;
  submitted = false;
  inboxType = 'OUTLOOK';
  private readonly destroy$ = new Subject();

  constructor(
    private formBuilder: FormBuilder, 
    public inboxService: InboxService_New, 
    public _utilService: UtilityService,
    public dialogRef: MatDialogRef<InboxCreateInboxComponent>,
    private router:Router,
    public _Config: ConfigService) {
  }


  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.createInboxForm = this.formBuilder.group({
      name: ['', Validators.required],
      provider: [environment.showGmailInInbox ? 'OUTLOOK' : 'Outlook', Validators.required],
    });

    this.createSMTPInboxForm =  this.formBuilder.group({
      name: ['', Validators.required],
      provider:['',Validators.required],
      smtpSettings_host: ['', Validators.required],
      smtpSettings_port: ['', Validators.required],
      smtpSettings_secure: [false, Validators.required],
      smtpSettings_auth_user: ['',[Validators.required, Validators.email]],
      smtpSettings_auth_pass: ['', Validators.required],
      imapSettings_host: ['', Validators.required],
      imapSettings_port: ['', Validators.required],
      imapSettings_secure: [false, Validators.required],
      imapSettings_auth_user: ['', [Validators.required,Validators.email]],
      imapSettings_auth_pass: ['', Validators.required],
    })
  }

  typeChange(e: any): void {
    console.log("E",e);
    
    this.inboxType = e;;
    this.createInboxForm.patchValue({
      provider: e,
    });
    this.createSMTPInboxForm.patchValue({
      provider: e,
    });

    this.radioItems.forEach((data) => {
      if(data.value == e){
        data.checked = true;
      } else {
        data.checked = false;
      }
      
    });

    console.log("");
    

  }

  createInbox(): void {
    this.submitted = true;
    if (this.createInboxForm.valid) {
      const payload = {
        name: this.createInboxForm.value.name,
        provider: this.createInboxForm.value.provider,
        redirectURI:window.location.origin + '/pm/inbox-management'
      }
      this.inboxService.addNewMailAccount(payload).pipe(takeUntil(this.destroy$)).subscribe(
        {
          next: (response: any) => {
            this.dialogRef.close(response);
            window.open(response.data.url);
          },
          error: (error) => {
            this._utilService.showErrorMessage(error);
          },
          complete: () => {
          }
        }
      );
    }
  }

  addSMTPMailbox(): void {
    this.submitted = true;
    
    if(!this.createSMTPInboxForm.valid){
      return null;
    }

    const payload :SMTP_Payload = {
      name: this.createSMTPInboxForm.value.name,
      smtpSettings:{
        host: this.createSMTPInboxForm.value.smtpSettings_host,
        port: parseInt(this.createSMTPInboxForm.value.smtpSettings_port),
        secure: this.createSMTPInboxForm.value.smtpSettings_secure,
        auth: {
            user: this.createSMTPInboxForm.value.smtpSettings_auth_user,
            pass: this.createSMTPInboxForm.value.smtpSettings_auth_pass
        }
      },
      imapSettings: {
        host: this.createSMTPInboxForm.value.imapSettings_host,
        port: parseInt(this.createSMTPInboxForm.value.imapSettings_port),
        secure: this.createSMTPInboxForm.value.imapSettings_secure,
        auth: {
            user: this.createSMTPInboxForm.value.imapSettings_auth_user,
            pass: this.createSMTPInboxForm.value.imapSettings_auth_pass,
        }
      }
    }

    this._Config.isLoader = true;

    this.inboxService.addSMTPAccount(payload).pipe(takeUntil(this.destroy$)).subscribe((data)=>{

      this._Config.isLoader = false;
      this.dialogRef.close();
      this._utilService.showSuccess('','Inbox added successfully');
      this.router.navigate(['./pm/inbox-management']);

    },(err)=>{

      this._Config.isLoader = false;
      this._utilService.showErrorMessage(err.error);
      
    })
  }


  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
