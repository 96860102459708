import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { LeasesStoreService } from '../../../../_services/leases/leases-store.service';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { Rule, RuleCategory } from '../../../models/porperty-manager/maintenance-terms-tab';
import { AssignDocumentComponent } from '../../_popup_views/assign-document/assign-document.component';
import { DialogService } from '../../../../_services/dialog/dialog.service';
import { RULE_CRITERIA_TYPE } from '../../../../../Modules/_fyxt_modules/leases/leases.constants';
import { RuleTemplateStoreService } from '../../../../_services/leases/rule-template/rule-template-store.service';
import { environment } from '../../../../../../environments/environment';
import { UtilityService } from '../../../../../services/utility.service';
import { MatDialogModule } from '@angular/material/dialog';
import { DropdownComponent } from '../../../_reusable_ui/_controls/dropdown/dropdown.component';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { DropdownSelectComponent } from '../../../_reusable_ui/_controls/dropdown-select/dropdown-select/dropdown-select.component';
import { ChipComponent } from '../../../_reusable_ui/_components/chip/chip.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { CardComponent } from '../../../_reusable_ui/_components/card/card.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { NgIf, NgClass, NgFor } from '@angular/common';


@Component({
    selector: 'fyxt-tab-rules',
    templateUrl: './tab-rules.component.html',
    styleUrls: ['./tab-rules.component.scss'],
    standalone: true,
    imports: [NgIf, LoaderSmallComponent, NgClass, FormsModule, ReactiveFormsModule, NgFor, CardComponent, ButtonComponent, MatTooltipModule, MatIconModule, MatMenuModule, MatFormFieldModule, ChipComponent, DropdownSelectComponent, NgxIntlTelInputModule, MatDividerModule, MatInputModule, DropdownComponent, MatDialogModule]
})
export class TabRulesComponent implements OnInit, OnChanges {
  private readonly destroy$: Subject<null> = new Subject<null>();
  private readonly id: number = this.activateRoute.snapshot.params['id'];
  private template_id: number;

  @Input() hideAddRule: boolean = false;
  @Input() category: RuleCategory;
  @Input() templateId: number;
  @Input() leaseEditable: boolean = true;
  @Input() leaseOnlyView: boolean = false;

  @ViewChild('removeDocuments') removeDocuments: any;
  public popupData: any;

  public rulesForm: FormGroup;
  public isGlobalRule;
  public allRules: Rule[];
  public ruleForItems = [];
  public ruleAppliesItems = [];
  public categories: any[] = [];
  public criteriaType = RULE_CRITERIA_TYPE;


  isLoader: boolean = true;

  constructor(
    private readonly leasesStoreService: LeasesStoreService,
    private readonly activateRoute: ActivatedRoute,
    private readonly templateService: RuleTemplateStoreService,
    public _dialogService: DialogService,
    public utilService: UtilityService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    const {category, templateId} = changes;
    if (templateId && templateId.currentValue) {
      if (!this.template_id) {
        this.template_id = templateId.currentValue;
      }
    }

    if (category.currentValue) {
      if (this.template_id) {
        this.initTemplate();
        return;
      }
      this.init();
    } else {
      this.rulesForm = null;
    }
  }

  ngOnInit(): void {
    this.leasesStoreService.currentLease$
      .pipe(takeUntil(this.destroy$))
      .subscribe((lease) => {
        if (lease?.type === 'ENDED')
          this.leaseEditable = false;
      })
  }

  private init(clearForm = true): void {
    if (!this.category) {
      return;
    }
    if (clearForm) {
      this.rulesForm = null;
    }

    this.isGlobalRule = this.category.id === 'global' || this.category.name === 'Global Rule';
    this.isLoader = true;

    combineLatest(
      this.leasesStoreService.loadInitRulesData(this.category.id, this.id),
      this.leasesStoreService.categories$,
      this.leasesStoreService.loadInitRuleOptionsData()
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(([rules, categories, ruleOptions]) => {
        rules = rules.sort((a, b) => {
          if (a.is_global && !b.is_global) return -1;
          if (!a.is_global && b.is_global) return 1;
          return 0;
        })

        this.initForm(rules);
        this.categories = categories.map(ct => ({id: ct.id, name: ct.category_name, selected: false})).filter(item => item.id !== 'global' || item.name !== 'Global Rule');

        if (ruleOptions) {
          const {applies_options, for_options} = ruleOptions;
          this.ruleForItems = for_options.map(item => ({name: item.value, value: item.id}));
          this.ruleAppliesItems = applies_options.map(item => ({name: item.value, value: item.id}));
        }

        this.isLoader = false;
      });
  }
  private initTemplate(cleanForm = true): void {
    if (!this.category) {
      return;
    }
    this.isGlobalRule = this.category.id === 'global' || this.category.name === 'Global Rule';
    if (cleanForm) {
      this.rulesForm = null;
    }

    this.isLoader = true;

    combineLatest(
      this.templateService.getTemplateCategoryRules(this.category.id, this.template_id),
      this.templateService.categories$,
      this.leasesStoreService.loadInitRuleOptionsData()
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(([rules, categories, ruleOptions]) => {
        rules = rules.sort((a, b) => {
          if (a.is_global && !b.is_global) {
            return -1;
          }
          if (!a.is_global && b.is_global) {
            return 1;
          }
          return 0;
        });

        this.initForm(rules);
        this.categories = categories.map(ct => ({
          id: ct.id,
          name: ct.category_name,
          selected: false
        })).filter(item => item.id !== 'global' || item.name !== 'Global Rule');

        if (ruleOptions) {
          const {applies_options, for_options} = ruleOptions;
          this.ruleForItems = for_options.map(item => ({name: item.value, value: item.id}));
          this.ruleAppliesItems = applies_options.map(item => ({name: item.value, value: item.id}));
        }

        this.isLoader = false;
      });
  }

  private initForm(rules: Rule[]): void {
    this.allRules = rules;

    this.rulesForm = new FormGroup({
      rules: new FormArray(rules.map(s => {
        const categories = s.categories || [];

        return new FormGroup({
          id: new FormControl(s.id),
          is_global: new FormControl(s.is_global),
          leases_type: new FormControl(s.leases_type),
          categories: new FormControl(categories.map((v) => ({
            id: v.id,
            name: v.name,
            label: v.name
          })), this.isGlobalRule ? [Validators.required, Validators.minLength(2)] : []),
          rule_for: new FormControl(s.rule_for, Validators.required),
          rule_applies: new FormControl(s.rule_applies, Validators.required),
          management_responsible: new FormControl(s.management_responsible, [Validators.required, Validators.min(0), Validators.max(100)]),
          tenant_responsible: new FormControl(s.tenant_responsible, [Validators.required, Validators.min(0), Validators.max(100)]),
          assigned_documents: new FormControl(s.assigned_document),
          criteria: new FormArray(s.criteria.map((c) => {
            return new FormGroup({
              name: new FormControl(this.criteriaType.find(ct => ct.name === c.name)),
              count: new FormControl(c.count),
              management_responsible_limit: new FormControl(c.management_responsible_limit, [Validators.required, Validators.min(0), Validators.max(100)]),
              tenant_responsible_limit: new FormControl(c.tenant_responsible_limit, [Validators.required, Validators.min(0), Validators.max(100)]),
            });
          })),
          displayInput: new FormControl(false)
        });
      }))
    });
  }

  getCriterias(ruleIndex: number) {
    return ((<FormArray> this.rulesForm.get('rules'))?.at(ruleIndex).get('criteria') as FormArray);
  }

  addRuleCriteria() {
    return new FormGroup({
      name: new FormControl(RULE_CRITERIA_TYPE[0], Validators.required),
      count: new FormControl('', Validators.required),
      management_responsible_limit: new FormControl('', [Validators.required, Validators.min(0), Validators.max(100)]),
      tenant_responsible_limit: new FormControl('', [Validators.required, Validators.min(0), Validators.max(100)]),
    });
  }

  addMoreRuleCriteria(item) {
    item.controls['criteria'].push(this.addRuleCriteria());
  }

  removeRuleCriteria(item, index: number) {
    item.controls['criteria'].removeAt(index);
  }

  itemFormat() {
    return this.rulesForm.get('rules') as FormArray;
  }

  editRules(item): void {
    item.controls['displayInput'].setValue(true);
  }

  addRule(): void {
    (<FormArray> this.rulesForm.get('rules'))?.push(this.addItemFormats());
  }

  addItemFormats(): any {
    return new FormGroup({
      id: new FormControl(''),
      leases_type: new FormControl(''),
      categories: new FormControl([], this.isGlobalRule ? [Validators.required, Validators.minLength(2)] : []),
      rule_for: new FormControl('', Validators.required),
      rule_applies: new FormControl('', Validators.required),
      management_responsible: new FormControl('', [Validators.required, Validators.min(0), Validators.max(100)]),
      tenant_responsible: new FormControl('', [Validators.required, Validators.min(0), Validators.max(100)]),
      assigned_documents: new FormControl([]),
      criteria: new FormArray([]),
      displayInput: new FormControl(true)
    });
  }

  cancelEditRule(item: any, i: number): void {
    if (item.value.id) {
      const rule = this.allRules.find(rule => rule.id === item.value.id);

      const categories = rule.categories && rule.categories.length ?
        rule.categories.map((v) => ({id: v.id, name: v.name, label: v.name})) : [];

      item.controls['leases_type'].value = rule.leases_type || '';
      item.controls['id'].value = rule.id || '';
      item.controls['categories'].value = categories;
      item.controls['rule_for'].value = rule.rule_for;
      item.controls['rule_applies'].value = rule.rule_applies;
      item.controls['management_responsible'].value = rule.management_responsible;
      item.controls['tenant_responsible'].value = rule.tenant_responsible;
      item.controls['assigned_documents'].value = rule.assigned_document;

      const ruleCriteriaAmount = rule.criteria?.length || 0;
      const currCriteriaAmount = item.controls['criteria'].value?.length || 0;

      if (ruleCriteriaAmount) {
        if (ruleCriteriaAmount < currCriteriaAmount) {
          let redundantAmount = currCriteriaAmount;
          while (ruleCriteriaAmount !== redundantAmount) {
            item.controls['criteria'].removeAt(redundantAmount - 1);
            --redundantAmount;
          }
        } else if (ruleCriteriaAmount > currCriteriaAmount) {
          let redundantAmount = currCriteriaAmount;
          while (ruleCriteriaAmount !== redundantAmount) {
            item.controls['criteria'].push(this.addRuleCriteria());
            ++redundantAmount;
          }
        }

        item.controls['criteria'].setValue(rule.criteria.map((c) => ({
          name: c.name,
          count: +c.count,
          management_responsible_limit: c.management_responsible_limit,
          tenant_responsible_limit: c.tenant_responsible_limit,
        })));
      }



      item.controls['displayInput'].value = false;
    } else {
      (<FormArray> this.rulesForm.get('rules'))?.removeAt(i);
    }

  }

  private createRule(item: any, i: number): void {
    if (this.template_id) return this.createTemplateRule(item, i);
    const {
      rule_for,
      rule_applies,
      management_responsible,
      tenant_responsible,
    } = item.value;

    const data: Rule = {
      lease_id: +this.id,
      categories: (item.get('categories')?.value || []).map(ct => ct.id),
      rule_for: rule_for.name,
      rule_applies: rule_applies.name,
      management_responsible: +management_responsible,
      tenant_responsible: +tenant_responsible,
      criteria: (item.get('criteria')?.value || []).map(cr => ({ ...cr, name: cr.name.value })),
      assigned_document: (item.get('assigned_documents')?.value || []).map(v => ({
        id: v.id,
        name: v.name,
        url: v.url,
        pages: v.pages
      })),
      category_id: this.category.id,
    };

    item.controls['rule_for'].value = data.rule_for;
    item.controls['rule_applies'].value = data.rule_applies;


    this.isLoader = true;

    this.leasesStoreService.createRule(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((createdRule) => {
        item.controls['id'].value = createdRule.id;
        item.controls['displayInput'].value = false;
        this.allRules.push(createdRule);
        this.leasesStoreService.loadRulesCountData(this.id);
        this.isLoader = false;
      });
  }
  private createTemplateRule(item: any, i: number): void {
    const {
      rule_for,
      rule_applies,
      management_responsible,
      tenant_responsible,
    } = item.value;

    const data: Rule = {
      template_id: this.template_id,
      categories: (item.get('categories')?.value || []).map(ct => ct.id),
      rule_for: rule_for.name,
      rule_applies: rule_applies.name,
      management_responsible: +management_responsible,
      tenant_responsible: +tenant_responsible,
      criteria: (item.get('criteria')?.value || []).map(cr => ({ ...cr, name: cr.name.value })),
      assigned_document: (item.get('assigned_documents')?.value || []).map(v => ({
        id: v.id,
        name: v.name,
        url: v.url,
        pages: v.pages
      })),
      category_id: this.category.id,
    };

    item.controls['rule_for'].value = data.rule_for;
    item.controls['rule_applies'].value = data.rule_applies;


    this.isLoader = true;

    this.templateService.createTemplateRule(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((createdRule) => {
        item.controls['id'].value = createdRule.id;
        item.controls['displayInput'].value = false;
        this.allRules.push(createdRule);
        this.templateService.getRulesCount(this.template_id);
        this.isLoader = false;
      });
  }

  private updateRule(item: any, i: number): void {
    if (this.template_id) return this.updateTemplateRule(item, i)
    const {
      id,
      rule_for,
      rule_applies,
      management_responsible,
      tenant_responsible,
    } = item.value;

    const data: Rule = {
      lease_id: +this.id,
      categories: (item.get('categories')?.value || []).map(ct => ct.id),
      rule_for: rule_for.name || rule_for,
      rule_applies: rule_applies.name || rule_applies,
      management_responsible: +management_responsible,
      tenant_responsible: +tenant_responsible,
      criteria: (item.get('criteria')?.value || []).map(cr => ({ ...cr, name: cr.name.value })),
      assigned_document: (item.get('assigned_documents')?.value || []).map(v => ({
        id: v.id,
        name: v.name,
        url: v.url,
        pages: v.pages
      })),
      category_id: this.category.id,
    };


    this.isLoader = true;
    this.leasesStoreService.updateRule(id, data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.init(false);
      });

  }
  private updateTemplateRule(item: any, i: number): void {

    const {
      id,
      rule_for,
      rule_applies,
      management_responsible,
      tenant_responsible,
    } = item.getRawValue();

    const data: Rule = {
      template_id: this.template_id,
      categories: (item.get('categories')?.value || []).map(ct => ct.id),
      rule_for: rule_for.name || rule_for,
      rule_applies: rule_applies.name || rule_applies,
      management_responsible: +management_responsible,
      tenant_responsible: +tenant_responsible,
      criteria: (item.get('criteria')?.value || []).map(cr => ({ ...cr, name: cr.name.value })),
      assigned_document: (item.get('assigned_documents')?.value || []).map(v => ({
        id: v.id,
        name: v.name,
        url: v.url,
        pages: v.pages
      })),
      category_id: this.category.id,
    };

    item.controls['rule_for'].setValue(data.rule_for);
    item.controls['rule_applies'].setValue(data.rule_applies);

    this.isLoader = true;

    this.templateService.updateTemplateRule(id, data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.initTemplate(false);
      });

  }

  saveRule(item: FormGroup, i: number): void {
    if (!item.valid) {
      item.markAllAsTouched();
      return
    }

    if (item.controls['id'].value) {
      return this.updateRule(item, i);
    }
    return this.createRule(item, i);
  }

  onDeleteRuleClick(item: any, index: number): void {
    this.isLoader = true;

    if (this.template_id) {
      this.templateService.deleteTemplateRule(item.value.id, this.template_id)
        .subscribe(() => {
          this.initTemplate(false);
        });
    } else {
      this.leasesStoreService.deleteRule(item.value.id, this.id)
        .subscribe(() => {
          this.init(false);
        })
    }
  }

  onAssignDocumentClick(item: any, i: number) {
    let popupData = {
      title: 'Assign Document',
      component: AssignDocumentComponent,
      containerClass: ['modal_lease_property'],
    };

    this._dialogService.openModal(popupData).subscribe(result => {
      if (result.options?.length) {
        item.get('assigned_documents').value.push(...result.options);
        if (!item.get('displayInput')?.value) {
          this.saveRule(item, i);
        }
      }
    });
  }

  onRemoveAssignedDoc(item, doc) {
    this.popupData = doc;
    let data = {
      title: 'Unassign Document',
      confirmText: 'Unassign',
      cancelText: 'Cancel',
      template: this.removeDocuments,
      selectedData: item,
      buttons: true,
    };

    this._dialogService.confirmDialog(data).subscribe(result => {
      if (result) {
        const chips = [...item.get('assigned_documents').value];
        const deleteChipIndex = chips.findIndex((chip) => {
          return chip.id === doc.id;
        });
        chips.splice(deleteChipIndex, 1);
        item.get('assigned_documents').value = chips;
      }
    })


  }
  private countDecimals(num) {
    const str = num.toString();
    const match = str.match(/\.(\d+)/);
    if (match) {
      return match[1].length;
    } else {
      return 0;
    }
  }

  onchangePercentage(event, field, item: AbstractControl) {
    let { value } = event.target;
    const copyValue = value;
    value = +(value.replace(' %', ''));
    if (!copyValue?.includes('.')) item.get(field).setValue(value || 0);
    const calculatedValue = value < 0 || value > 100 ? 0 : 100 - value;
    const formattedValue = calculatedValue.toFixed(this.countDecimals(copyValue.replace(' %', ''))).replace('.00', '')

    switch (field) {
      case 'management_responsible':
        item.get('tenant_responsible').setValue(formattedValue);
        break;
      case 'tenant_responsible':
        item.get('management_responsible').setValue(formattedValue);
        break;
      case 'management_responsible_limit':
        item.get('tenant_responsible_limit').setValue(formattedValue);
        break;
      case 'tenant_responsible_limit':
        item.get('management_responsible_limit').setValue(formattedValue);
        break;

    }
  }

  openAssignedDocument(item) {
    if (!item.url) {
      this.utilService.showError('', 'URL not found.');
      return;
    }
    if (item.url.includes('https')) {
      window.open(item.url);
    } else {
      let url = environment.files.http;
      url = url.substring(0, url.length - 1);
      window.open(url + item.url);
    }
  }

  onChangeCriteriaName(event, item) {
    item.get('count').reset();
  }

}
