enum InboxFolder {
  UNASSIGNED = 'Unassigned',
  ALL_MAIL = 'All Mail',
  NO_JOBS = 'No Jobs Associated',
  CLOSE_OUT = 'Close Out',
  EMERGENCY_JOBS = 'Emergency Jobs',
  OPEN_CONVERSATIONS = 'Open Conversations',
  CLOSED_CONVERSATION = 'Closed Conversations',
  MY_CONVERSATIONS = 'My Conversations',
  SPAM = 'Spam',
  TRASH = 'Trash'
}

export default InboxFolder;
