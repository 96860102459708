import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-otp-verification',
    templateUrl: './otp-verification.component.html',
    styleUrls: ['./otp-verification.component.scss'],
    standalone: true
})
export class OTPVerificationComponent implements OnInit {
  cities = [
    {id: 1, name: 'Vilnius'},
    {id: 2, name: 'Kaunas'},
    
    {id: 4, name: 'Pabradė'},
    {id: 5, name: 'Klaipėda'}
];


selectedCity: any;
  constructor() { }

  ngOnInit(): void {
  }

}
