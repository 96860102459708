import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-jobpreview',
    templateUrl: './jobpreview.component.html',
    styleUrls: ['./jobpreview.component.scss'],
    standalone: true
})
export class JobpreviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
