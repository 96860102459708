import {Injectable} from '@angular/core';

export type Maps = typeof google.maps;
const GOOGLE_MAPS_API_KEY = 'AIzaSyDSofRlwq2xjgct47kN1ycMG_hsHZ2siZc';

@Injectable({
  providedIn: 'root'
})
export class GoogleAddressService {
  public readonly api = this.load();

  private load(): Promise<Maps> {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    // tslint:disable-next-line:no-bitwise
   // const callbackName = `GooglePlaces_cb_` + ((Math.random() * 1e9) >>> 0);
    const callbackName = "agmLazyMapsAPILoader" ;
    script.src = this.getScriptSrc(callbackName);

    interface MyWindow {
      [name: string]: Function;
    };
    const myWindow: MyWindow = window as any;

    const promise = new Promise((resolve, reject) => {
      myWindow[callbackName] = resolve;
      script.onerror = reject;
    });
    document.body.appendChild(script);
    return promise.then(() => google.maps);
  }

  private getScriptSrc(callback: string): string {
    interface QueryParams {
      [key: string]: string;
    };
    const query: QueryParams = {
      v: '3',
      callback,
      key: GOOGLE_MAPS_API_KEY,
      libraries: 'places',
    };
    const params = Object.keys(query).map(key => `${key}=${query[key]}`).join('&');
    return `//maps.googleapis.com/maps/api/js?${params}&language=en&region=IN`;
    // return `https://maps.googleapis.com/maps/api/js?${params}`;
  }
}
