import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';

import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';


import { TableColumnTypes,ColumnSortType } from 'src/app/@fyxt/_shared/enums/table/table-column-types.enum';
import { AppTableSource } from 'src/app/@fyxt/_shared/models/app-table-model';

import {InviteUsersComponent, RemoveUsersComponent} from 'src/app/@fyxt/_shared/_views';
import {CompaniesService} from "../../../../../Modules/_fyxt_modules/companies/_services/companies.service";
import { UtilityService } from 'src/app/services/utility.service';
import { MatDialogModule } from '@angular/material/dialog';
import { TableComponent } from '../../../_reusable_ui/_components/table/table.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { NgIf, NgFor } from '@angular/common';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';

@Component({
    selector: 'fyxt-tab-users',
    templateUrl: './tab-users.component.html',
    styleUrls: ['./tab-users.component.scss'],
    standalone: true,
    imports: [SearchInputComponent, NgIf, ButtonComponent, MatMenuModule, MatIconModule, NgFor, MatTooltipModule, MatDividerModule, LoaderSmallComponent, TableComponent, MatDialogModule]
})
export class TabUsersComponent implements OnInit {

  @Input() dataSource!: AppTableSource;
  @Input() usersSearchValue!: string;
  @Input() isLoader!: boolean;
  @Input() bulkActionList: any;
  @Input() selectionTable: any;
  @Input() selectedCompanyDetails: any;
  @Input() checkedUsers = [];
  @Output() onSearchValueChange = new EventEmitter<Event>();
  @Output() onSortValueChange = new EventEmitter<Event>();
  @Output() onPaginationValueChange = new EventEmitter<Event>();
  @Output() onBulkActionValueChange = new EventEmitter();
  @Output() onCheckRowValueChange = new EventEmitter();
  @Output() addUserValueChange = new EventEmitter();
  @Output() isAllRowSelectValueChange = new EventEmitter();
  selectedUserDetails: any;
  @Input() isUserCanNotInvite: boolean=false;
  @Input() isReadonly: boolean = false;


  constructor(
    public _dialogService: DialogService,
    public _companiesService: CompaniesService,
    public _utilService: UtilityService,
  ) { }

  ngOnInit(): void {
  }

  ngAfterContentChecked(): void {
    //Called after every check of the component's or directive's content.
    //Add 'implements AfterContentChecked' to the class.
    if (this.isReadonly) {
      if (this.dataSource?.headers) {
        this.dataSource.headers = this.dataSource.headers.filter(obj => !(obj.value == 'checkbox' || obj.value == 'action')).map(obj => {
          return obj;
        })
      }
    }

  }

  @ViewChild('removeUserTemplate')
  removeUserTemplate!: TemplateRef<any>;

  inviteUserPopup(isEdit:boolean = false) {
    let popupData = {
      title: isEdit?'Edit User':'Invite User',
      component: InviteUsersComponent,
      containerClass: ['invite_users'],
      data: { selectedCompanyDetails: this.selectedCompanyDetails, isEditUSer: isEdit, selectedUserDetails: this.selectedUserDetails, propertiesList: this._companiesService.propertiesListForUsers}
    };

    this._dialogService.openModal(popupData).subscribe(result => {
      if (result?.isAdd) {
        this.addUserValueChange.emit();
      }
    });

  }

  openRemoveUser() {
    // let popupData = {
    //   title: 'Remove User',
    //   template: this.removeUserTemplate,
    //   confirmText: 'Remove',
    //   cancelText: 'Cancel',
    //   selectedData: { name: 'san' },
    //   containerClass: ['modal-md']
    // };
    // this._dialogService.confirmDialog(popupData).subscribe(result => {
    // });
    let popupData = {
      title: 'Remove User',
      component: RemoveUsersComponent,
      containerClass: ['invite_users'],
      data: { selectedCompanyDetails: this.selectedCompanyDetails, selectedUserDetails: this.selectedUserDetails, multipleRemoveUser: false}
    };

    this._dialogService.openModal(popupData).subscribe(result => {
      if (result.isDelete) {
        this.addUserValueChange.emit();
      }
    });
  }

  menuSelectedOption(event: any){
    this.selectedUserDetails = event;
    if(event.label == "Remove as a User"){
      this.openRemoveUser();
    }
    else if(event.label == "Edit User"){
      this.inviteUserPopup(true);
    }
    else if(event.label == "Resend Invite"){

      if(this.selectedUserDetails.status==="Active"){
        this._utilService.showSuccess('', 'User was already active.');
      }
      else{
        const payload = {
          checkedUsers: event?[event] : [],
          bulkActionItem: {
            label : 'Resend Invite to Pending Users'
          },
        }
        this.onBulkActionValueChange.emit(payload);
      }
     

    }
  }

  searchUsers(value: any): void {
    this.onSearchValueChange.emit(value);
    this.usersSearchValue = value;
  }

  usersSortChange(sortState: any) {
    this.onSortValueChange.emit(sortState);
  }

  usersPageChange(paginationEvent: any) {
    this.onPaginationValueChange.emit(paginationEvent);
  }

  isAllSelected (isSelected: any) {
    this.isAllRowSelectValueChange.emit(isSelected);
  };

  onCheckRow(user: any): void {
    let updatedCheckedUsers: number[];
    const isCompanyChecked = this.checkedUsers.some((checkedUser) => checkedUser.id === user.id);
    if (isCompanyChecked) {
      updatedCheckedUsers = this.checkedUsers.filter((checkedUser) => checkedUser.id !== user.id);
    } else {
      updatedCheckedUsers = [...this.checkedUsers, user];
    }
    this.checkedUsers = updatedCheckedUsers;
    this.onCheckRowValueChange.emit(this.checkedUsers);
  }

  bulkActionPopup(item: any): void {
    const payload = {
      checkedUsers: this.checkedUsers || [],
      bulkActionItem: item,
    }
    this.onBulkActionValueChange.emit(payload);
  }

}
