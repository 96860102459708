import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { UtilityService } from 'src/app/services/utility.service';
import { ConfigService } from 'src/app/services/config.service';
import { ActivatedRoute } from '@angular/router';

import { environment } from 'src/environments/environment';
import { KeycloakOptions, KeycloakService } from 'keycloak-angular';
import { log } from 'util';
import { initializeKeycloak } from '../../../../auth/keycloak-init';
import { from, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-singleauth',
    templateUrl: './singleauth.component.html',
    styleUrls: ['./singleauth.component.scss'],
    standalone: true
})
export class SingleauthComponent implements OnInit {
  private readonly destroy$: Subject<null> = new Subject<null>();

  constructor(
    public activatedRoute: ActivatedRoute,
    public _baseService: BaseService,
    public _utilService: UtilityService,
    public _Config: ConfigService,
    private readonly keycloakService: KeycloakService,
  ) { }


  ngOnInit(): void {
    this.init();
    const token = this.activatedRoute.snapshot.params['token'];
    const refreshToken = this.activatedRoute.snapshot.params['refreshToken'];

    /*from(this.keycloakService.isLoggedIn())
      .pipe(
        switchMap((isLoggedIn => {
          if (isLoggedIn) {
            return;
          }

          localStorage.setItem('token', token);
          localStorage.setItem('refreshToken', refreshToken);

          return initializeKeycloak(this.keycloakService)();
        })),
        switchMap(() => this.keycloakService.getToken()),
        takeUntil(this.destroy$),
      )
      .subscribe((token) => {
        this.getUserInfo(token);
      });*/
  }

  async init() {
    if (await this.keycloakService.isLoggedIn()) {
      this.getUserInfo();
      return;
    }

    const token = this.activatedRoute.snapshot.params['token'];
    const refreshToken = this.activatedRoute.snapshot.params['refreshToken'];
    const keycloakOptions: KeycloakOptions = {
      config: { ...environment.keycloakConfig },
      initOptions: {
        checkLoginIframe: false,
        token,
        refreshToken,
      },
    };

    try {
      const isInitialized = await this.keycloakService.init(keycloakOptions);

      if (!isInitialized) {
        this._baseService.redirectTo('/login');
        return;
      }

      const token = this.keycloakService.getKeycloakInstance().token;
      const refreshToken = this.keycloakService.getKeycloakInstance().refreshToken;

      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refreshToken);

      this.getUserInfo();
    } catch (err) {
      this._baseService.redirectTo('/login');
    }
  }



  /**
  * Verify User and Redirect to Dashboard
  */
  getUserInfo() {
    if (this._baseService.isPublicPage()) {
      return;
    }
    
    this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.getProfile;

    this._baseService.doGET(requestURL).subscribe((result: any) => {

      this._Config.isLoader = false;

      if (result.types.length > 0)
        result.current_Role = result.types[0];

      if (result.current_Role) {
        if (result.current_Role == "Member")  //if member role act as Tenant
          result.current_Role = "Tenant";

      }

      this._baseService.setUserInfo(result);

      this._baseService.authenticateUser(result.current_Role, true);

    },
      (error) => {
        this._Config.isLoader = false;
        this._utilService.showErrorMessage(error);
      }
    );

  }


}

