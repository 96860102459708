import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import {GoogleAddressService, Maps} from "../../../../_services/google-address/google-address.service";
import { ModalComponent } from '../../../_reusable_ui/_components';
// import { AgmGeocoder, AgmMap, AgmCoreModule } from '@agm/core';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { GoogleAddressTextboxComponent } from '../../../_reusable_ui/_controls/google-address-textbox/google-address-textbox.component';

@Component({
    selector: 'fyxt-set-location',
    templateUrl: './set-location.component.html',
    styleUrls: ['./set-location.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, GoogleAddressTextboxComponent, NgxIntlTelInputModule, 
      // AgmCoreModule,
       MatDialogModule, ButtonComponent]
})
export class SetLocationComponent implements OnInit, AfterViewInit  {
  @Input() createEquipmentCheck: boolean;
  latitude: any;
  longitude: any;
  googleAddressForm = new FormGroup({
    searchAddress: new FormControl('')
  })
  locationName: string;

  constructor(
    public _googleAddressService: GoogleAddressService,
    public _dialogRef: MatDialogRef<ModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public matDialogData: any,
    // private geocoder: AgmGeocoder,
    public http: HttpClient
    ) {}

  ngOnInit(): void {
    if(this.matDialogData.data.equipmentModal) {
      this.latitude = this.matDialogData.data.equipmentModal.latitude;
      this.longitude = this.matDialogData.data.equipmentModal.longitude;
      this.getLocationName(this.latitude, this.longitude);
      const searchAddress = `${this.matDialogData.data.equipmentModal.location?.name ? this.matDialogData.data.equipmentModal.location?.name + ', ' : ''}${this.matDialogData.data.equipmentModal.location?.cityName ? this.matDialogData.data.equipmentModal.location?.cityName + ', ' : ''}${this.matDialogData.data.equipmentModal.location?.stateCode ? this.matDialogData.data.equipmentModal.location?.stateCode + ', ' : ''}${this.matDialogData.data.equipmentModal.location?.countryName ? this.matDialogData.data.equipmentModal.location?.countryName : ''}`;
      this.googleAddressForm.patchValue({
        searchAddress: searchAddress
      })
    }
    else {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
      });
    }
  }

  ngAfterViewInit(): void {
  }

  changeAddress(event: any) {
    this.latitude = event.latitude;
    this.longitude = event.longitude;
    this.getLocationName(this.latitude,this.longitude)
  }

  pinLocation($event: any) {
    this.latitude = $event?.coords?.lat;
    this.longitude = $event?.coords?.lng;
    this.getLocationName(this.latitude, this.longitude);
  }


  getLocationName(latitude: number, longitude: number) {
    // this.geocoder.geocode({ location: { lat: latitude, lng: longitude } }).subscribe((result) => {
    //   this.googleAddressForm.patchValue({
    //     searchAddress: result[0].formatted_address
    //   })
    //   this.locationName = result[0].formatted_address;
    // });
  }

  saveLocation() {
    if(this.googleAddressForm.value.searchAddress.trim()==""){
      this._dialogRef.close({Name: "", latitude: "", longitude: ""});
    } else {
    this._dialogRef.close({Name: this.locationName, latitude: this.latitude, longitude: this.longitude});
    }
  }


}
