import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment/moment';

@Component({
    selector: 'fyxt-date-viewonly',
    templateUrl: './date-viewonly.component.html',
    styleUrls: ['./date-viewonly.component.scss'],
    standalone: true
})
export class DateViewonlyComponent implements OnInit {
  @Input() public value: string = null;
  @Input() public format: string = 'M/DD/YYYY';
  @Input() public label: string = 'Date Viewonly';

  constructor() { }

  ngOnInit(): void {
  }

  formatValue(data: any) {
    return moment(data).format(this.format);
  }

}
