export enum FileReminderActionType {
  EDIT = 'EDIT',
  ADD = 'ADD',
  INFO = 'INFO',
  BULK = 'BULK'
}

export enum MemberType {
  MANAGER_PROPERTY = 'MANAGER_PROPERTY',
  MANAGER = 'MANAGER',
  ENGINEER_ASSIGNED_PROPERTY = 'ENGINEER_ASSIGNED_PROPERTY',
  ENGINEER_PRIMARY_PROPERTY = 'ENGINEER_PRIMARY_PROPERTY',
  ENGINEER = 'ENGINEER',
  CONTACTS = 'CONTACTS',
}
