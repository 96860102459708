import { Component, Input, OnInit, Output, inject } from '@angular/core';
import { ColumnSortType, TableColumnTypes } from 'src/app/@fyxt/_shared/enums/table/table-column-types.enum';
import { AppTableRequest } from 'src/app/Modules/controls/models/app-table.model';
import { TableService } from 'src/app/@fyxt/_services/table/table.service';
import { ResizedEvent } from 'angular-resize-event-package';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UtilityService } from 'src/app/services/utility.service';
import { ManageEngineersService } from 'src/app/services/manage-engineers/manage-engineers.service';
import { BaseService } from 'src/app/services/base.service';
import { UiService } from 'src/app/services/ui/ui.service';
import { ConfigService } from 'src/app/services/config.service';
import { TableComponent } from '../../../_reusable_ui/_components/table/table.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { NgIf } from '@angular/common';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';
import { CardComponent } from '../../../_reusable_ui/_components/card/card.component';

@Component({
    selector: 'fyxt-manage-engineers-table-view',
    templateUrl: './manage-engineers-table-view.component.html',
    styleUrls: ['./manage-engineers-table-view.component.scss'],
    standalone: true,
    imports: [CardComponent, SearchInputComponent, FormsModule, NgxIntlTelInputModule, NgIf, LoaderSmallComponent, TableComponent]
})
export class ManageEngineersTableViewComponent implements OnInit {
  @Input() tabType: any;
  @Output() divWidth: number;
  moduleName = "Engineers"
  searchInput: any = "";
  isLoader: boolean = false;
  //table variables
  dataSource: any = {};
  selectedViewTableRows: any[] = [];
  selectedViewTableRowsTotal: number;
  selectedViewTableRowsPageSize: number = 25;
  currentPage: number = 0;
  pageIndexAPI: number = 1;
  tableSort: {
    active: '',
    direction: ''
  };

  selectedViewTableHeaders: any[] = [
    {
      value: 'full_name',
      sort: ColumnSortType.none,
      name: 'Name',
      type: TableColumnTypes.text,
      className: 'table-header',
      resizeColumn: false,
      // sticky:true
    },
    {
      value: 'email',
      sort: ColumnSortType.none,
      name: 'Email',
      type: TableColumnTypes.text,
      className: 'table-header',
      resizeColumn: false,
    },
    {
      value: 'phone',
      sort: ColumnSortType.none,
      name: 'Phone',
      type: TableColumnTypes.text,
      className: 'table-header',
      resizeColumn: false,
    },
    {
      value: 'properties',
      sort: ColumnSortType.none,
      name: 'Properties',
      type: TableColumnTypes.equipmentListWithCountDropdown,
      className: 'table-header equipment_name',
      resizeColumn: false,
      // sortDisabled: false
    },
    {
      value: 'role',
      sort: ColumnSortType.none,
      name: 'Role',
      type: TableColumnTypes.text,
      className: 'table-header',
      resizeColumn: false,
    },
    {
      value: 'status',
      sort: ColumnSortType.none,
      name: 'Status',
      type: TableColumnTypes.textLeaseStatus,
      className: 'table-header',
      resizeColumn: false,
      // sortDisabled: true
    },
    // {
    //   value: 'action_status',
    //   sort: ColumnSortType.none,
    //   name: 'Action',
    //   type: TableColumnTypes.action_buttons_engineer,
    //   className: 'table-header',
    //   resizeColumn: false,
    //   sortDisabled: true
    // },
    {
      value: 'action',
      sort: ColumnSortType.none,
      name: '',
      type: TableColumnTypes.action,
      className: 'three-dot',
      actions: [
        { label: 'Resend Invite', value: 'Resend Invite' },
        { label: 'Edit', value: 'Edit' },
        { label: 'Suspend', value: 'Suspend' },
        { label: 'Activate', value: 'Activate' },
        { label: 'Delete', value: 'Delete' },
      ],
      stickyEnd: true, 
      // style:{'width':'100px', 'left':'0px'}
    }
  ];

  public _uiService = inject(UiService);
  public _Config = inject(ConfigService)

  constructor(
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    public tableService: TableService,
    public _utilService: UtilityService,
    public _baseService: BaseService,
    public _engineerService: ManageEngineersService,
  ) {

    /*********** If New Job Added *************/
    this._engineerService.isDoEngineerRefresh.subscribe((isDoEngineerRefresh: any) => {
      if (isDoEngineerRefresh) {
        this.getSelectedTableData();
        this._engineerService.isDoEngineerRefresh.next(false);
      }
    });

  }

  ngOnInit(): void {
    this.getSelectedTableData();
  }


  ngOnDestroy(): void {
  }


  getSelectedTableData(isSearch?: boolean, sortPayload?: any) {
    if (!this.tabType) {
      return;
    }

    const offset = (this.pageIndexAPI - 1) * this.selectedViewTableRowsPageSize;
    let requestURL1 = environment.baseURL + `engineers/?page=${this.pageIndexAPI}&limit=${this.selectedViewTableRowsPageSize}&offset=${offset}`;

    let requestURL = "";
    if (this.tabType == 'All') {
      requestURL = environment.baseURL + `engineers/?page=${this.pageIndexAPI}&limit=${this.selectedViewTableRowsPageSize}&offset=${offset}`;
    }
    else if (this.tabType == 'My') {
      requestURL = environment.baseURL + `engineers/my/?page=${this.pageIndexAPI}&limit=${this.selectedViewTableRowsPageSize}&offset=${offset}`;
    }
    else if (this.tabType == 'Suspended') {
      requestURL = environment.baseURL + `engineers/suspended/?page=${this.pageIndexAPI}&limit=${this.selectedViewTableRowsPageSize}&offset=${offset}`;
    }

    if (isSearch) {
      requestURL += '&search=' + this.searchInput;
    }

    if (sortPayload?.active && sortPayload?.direction) {
      requestURL += `&sort=${(sortPayload.active == 'status') ? 'is_active' : sortPayload.active}` + `&order=${sortPayload.direction}`;
    }

    // Append sort direction to the header if we have sort query we get from the backend
    if (sortPayload !== undefined && sortPayload?.active !== '') {
      this.selectedViewTableHeaders.map((header: any) => {
        if (header.value == sortPayload.active) {
          return header.sort = sortPayload.direction;
        } else {
          delete header['sort'];
        }
      });
    }

    //  this.isLoader = true;
    this.isLoader = true;


    this._baseService.doGET(requestURL).subscribe({
      next: (response: any) => {
        this.selectedViewTableRowsTotal = response.count;

        let listItems = response.results || [];
        if (listItems?.length > 0) {
          listItems.forEach(element => {
            element.status = element.is_active ? 'Active' : 'Pending';
            element.action_status = element.status;
            element.activeTab = this.tabType;
          });

          this.selectedViewTableRows = listItems || [];
        }

        this.selectedViewTableRows = response.results || [];

        if (sortPayload !== undefined && sortPayload?.active !== '') {
          this.selectedViewTableHeaders = this.selectedViewTableHeaders.map(element => {
            (element.value === sortPayload.active) ? (element.sort = sortPayload.direction) : (element.sort = ColumnSortType.none);
            return element;
          });
        }


        // Tab Based Action Menu restrictions
        if (this._baseService.currentUserInfo.current_Role == "Engineer") {
          this.selectedViewTableHeaders = this.selectedViewTableHeaders.filter((element: any) => element.value != "action");
        }
        else {
          // if (this.tabType == 'Suspended') {
          //   this.selectedViewTableHeaders = this.selectedViewTableHeaders.map((element: any) => {
          //     if (element.value == "action") {
          //       element.actions = element?.actions.filter(obj => (obj.value == 'Activate' || obj.value == 'Delete'));
          //     }
          //     return element;
          //   });
          // }
          // else {
          //   this.selectedViewTableHeaders = this.selectedViewTableHeaders.map((element: any) => {
          //     if (element.value == "action") {
          //       element.actions = element?.actions.filter(obj => (obj.value !== 'Delete'));
          //     }
          //     return element;
          //   });
          // }
        }


        this.dataSource = this.tableService.setTableData(this.moduleName, this.selectedViewTableHeaders, this.selectedViewTableRows, this.selectedViewTableRowsTotal, this.currentPage, this.selectedViewTableRowsPageSize);
        this.isLoader = false
      },
      error: (error: any) => { },
      complete: () => {
        this.isLoader = false;
      },
    });
  }

  /**
 * On Search Table Rows
 * @param inputValue
 */
  onSearch(inputValue: any) {
    this.searchInput = inputValue;
    if (inputValue.length >= 2 || inputValue.length === 0) {
      this.pageIndexAPI = 1;
      this.currentPage = 0;
      this.getSelectedTableData(this.searchInput.trim() !== '' ? true : false, this.tableSort ?? this.tableSort);
    }

  }

  /**
* On Sort Table Rows
* @param sortState
*/
  onSortChange(sortState: any) {
    this.tableSort = sortState;
    // this.selectedViewTableRowsPageSize = 25;
    this.pageIndexAPI = 1;
    this.currentPage = 0;
    this.getSelectedTableData(this.searchInput.trim() !== '' ? true : false, sortState ?? sortState)
  }

  /**
    * On Table Rows Pagination
    * @param PageChangeEvent
    */
  onPageChange(event: any) {
    this.currentPage = event.pageIndex;
    this.pageIndexAPI = event.pageIndex + 1;
    this.selectedViewTableRowsPageSize = event.pageSize;
    this.getSelectedTableData(this.searchInput.trim() !== '' ? true : false, this.tableSort ?? this.tableSort);
  }


  onResized(event: ResizedEvent) {
    this.divWidth = event.newRect.width;
  }

  onRowClick(event: any) {
    if (this._baseService.currentUserInfo.current_Role === "Engineer" || event.activeTab == "Suspended") {
      return
    }
    else {
      this._baseService.redirectTo('/pm/engprofile' + '/' + event.id + '/' + 1)
    }
  }

  onActionButtonClick(event: any) {
    if (event.is_active) {
      this._Config.isLoader = true;
      this._engineerService.editMemberEngineerObj.selectedItemId = event.id;

      let requestURL = environment.baseURL + 'engineers/{enginerID}/';;
      requestURL = requestURL.replace('{enginerID}', event.id);

      this._engineerService.doGET(requestURL)
        .subscribe({
          next: (response: any) => {
            this._Config.isLoader = false;
            this._engineerService.viewEngineerDetail = response || {};
          },
          error: (error) => {
            this._Config.isLoader = false;
            this._engineerService.viewEngineerDetail = {};
          },
          complete: () => {
            this._Config.isLoader = false;
            this._engineerService.editMemberEngineerObj.isEdit = true;
            this._uiService.currentActiveContent = 'edit_engineer';
            this._uiService.showRightSideBar();
          }
        });
    }
    else {
      this._engineerService.editMemberEngineerObj.selectedItemId = event.id;
      this._engineerService.confirmDialog('invite', 0);
    }
  }


  threeDotMenuChange(event: any) {
    if (event.label === 'Edit') {
      this._Config.isLoader = true;
      this._engineerService.editMemberEngineerObj.selectedItemId = event.id;

      let requestURL = environment.baseURL + 'engineers/{enginerID}/';;
      requestURL = requestURL.replace('{enginerID}', event.id);

      this._engineerService.doGET(requestURL)
        .subscribe({
          next: (response: any) => {
            this._Config.isLoader = false;
            this._engineerService.viewEngineerDetail = response || {};
          },
          error: (error) => {
            this._Config.isLoader = false;
            this._engineerService.viewEngineerDetail = {};
          },
          complete: () => {
            this._Config.isLoader = false;
            this._engineerService.editMemberEngineerObj.isEdit = true;
            this._uiService.currentActiveContent = 'edit_engineer';
            this._uiService.showRightSideBar();
          }
        });
    }
    if (event.label === 'Resend Invite') {
      this._engineerService.editMemberEngineerObj.selectedItemId = event.id;
      this._engineerService.confirmDialog('invite', 0);
    }
    if (event.label === 'Suspend') {
      this.isLoader = true
      let requestURL = environment.baseURL + "engineers/{ID}/suspend/";
      requestURL = requestURL.replace('{ID}', event.id);
      this._engineerService.doPatch(requestURL, null)
        .subscribe({
          next: (response: any) => {
            this._utilService.showSuccess('', 'Engineer Suspended Successfully.');
          },
          error: (error) => {
            this.isLoader = false
            this._engineerService.viewEngineerDetail = {};
          },
          complete: () => {
            if (event.id) {
              let indexVal = this.selectedViewTableRows.findIndex(obj => obj.id == event.id);
              if (indexVal != -1) {
                this.selectedViewTableRows.splice(indexVal, 1);
                this.dataSource = this.tableService.setTableData(this.moduleName, this.selectedViewTableHeaders, this.selectedViewTableRows, this.selectedViewTableRowsTotal, this.currentPage, this.selectedViewTableRowsPageSize);
                this.isLoader = false
              }
            }
            this.isLoader = false;
            this._engineerService.getTabsCount();
          }
        });
    }
    if (event.label === 'Activate') {
      this.isLoader = true
      let requestURL = environment.baseURL + "engineers/{ID}/activate/";
      requestURL = requestURL.replace('{ID}', event.id);
      this._engineerService.doPatch(requestURL, null)
        .subscribe({
          next: (response: any) => {
            this._utilService.showSuccess('', 'Engineer Activated Successfully.');
          },
          error: (error) => {
            this.isLoader = false
            this._engineerService.viewEngineerDetail = {};
          },
          complete: () => {
            if (event.id) {
              let indexVal = this.selectedViewTableRows.findIndex(obj => obj.id == event.id);
              if (indexVal != -1) {
                this.selectedViewTableRows.splice(indexVal, 1);
                this.dataSource = this.tableService.setTableData(this.moduleName, this.selectedViewTableHeaders, this.selectedViewTableRows, this.selectedViewTableRowsTotal, this.currentPage, this.selectedViewTableRowsPageSize);
                this.isLoader = false
              }
            }
            this.isLoader = false;
            this._engineerService.getTabsCount();
          }
        });
    }
    if (event.label === 'Delete') {
      let deleteMsg = " Are you sure that you want to delete the Engineer ? "
      let self = this;
      this._utilService.confirmDialog(true, deleteMsg, function () {
        self.deleteTrigger(event);
      }, function () {
      });

    }
  }

  deleteTrigger(event: any) {
    this.isLoader = true
    let requestURL = environment.baseURL + "engineers/{ID}/";
    requestURL = requestURL.replace('{ID}', event.id);
    this._engineerService.doDelete(requestURL)
      .subscribe({
        next: (response: any) => {
          this._utilService.showSuccess('', 'Engineer Deleted Successfully.');
        },
        error: (error) => {
          this.isLoader = false
          this._engineerService.viewEngineerDetail = {};
        },
        complete: () => {
          if (event.id) {
            let indexVal = this.selectedViewTableRows.findIndex(obj => obj.id == event.id);
            if (indexVal != -1) {
              this.selectedViewTableRows.splice(indexVal, 1);
              this.dataSource = this.tableService.setTableData(this.moduleName, this.selectedViewTableHeaders, this.selectedViewTableRows, this.selectedViewTableRowsTotal, this.currentPage, this.selectedViewTableRowsPageSize);
              this.isLoader = false
            }
          }
          this.isLoader = false;
          this._engineerService.getTabsCount();
        }
      });
  }
}
