
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UtilityService } from 'src/app/services/utility.service';
import { BaseService } from 'src/app/services/base.service';
import { TicketFlowsService } from 'src/app/services/ticket-flows.service';
import { ConfigService } from 'src/app/services/config.service';


import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';

import { formatDate, NgTemplateOutlet, NgFor, NgIf } from '@angular/common';

import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { MatDividerModule } from '@angular/material/divider';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';
import { CheckboxComponent } from '../../../_reusable_ui/_controls/checkbox/checkbox.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { DropdownComponent } from '../../../_reusable_ui/_controls/dropdown/dropdown.component';


@Component({
    selector: 'app-timesheet-buildreport',
    templateUrl: './timesheet-buildreport.component.html',
    styleUrls: ['./timesheet-buildreport.component.scss'],
    standalone: true,
    imports: [NgTemplateOutlet, DropdownComponent, FormsModule, NgxIntlTelInputModule, NgFor, CheckboxComponent, NgSelectModule, NgIf, TextboxComponent, BsDatepickerModule, MatDividerModule, MatDialogModule, ButtonComponent]
})
export class TimesheetBuildreportComponent implements OnInit {

  @ViewChild('customDateDropown') customDateDropown: NgSelectComponent;
  @ViewChild('customApprovedDateDropown') customApprovedDateDropown: NgSelectComponent;

  TimesheetTypes: any[] = [
    { id: 'All', name: 'All', isChecked: true },
    { id: 'Approved', name: 'Approved', isChecked: true },
    { id: 'Proposed', name: 'Proposed', isChecked: true },
    { id: 'Rejected', name: 'Rejected', isChecked: true }
  ];

  MaterialTypes: any[] = [
    { id: 'All', name: 'All', isChecked: true },
    { id: 'Approved', name: 'Approved', isChecked: true },
    { id: 'Proposed', name: 'Proposed', isChecked: true },
    { id: 'Rejected', name: 'Rejected', isChecked: true }
  ];
  // { id: 'All', name: 'All', isChecked: true }


  filterBy = [
    { id: "1", name: "Today", value: 0 },
    { id: "2", name: "Yesterday", value: 1 },
    { id: "3", name: "Last 7 Days", value: 7 },
    { id: "4", name: "Last 30 Days", value: 30 },
    { id: "5", name: "Last 90 Days", value: 90 },
    { id: "6", name: "Last 12 Months", value: 365 },
    { id: "7", name: "Custom", value: "Custom" },
  ]

  formData: any = {
    isSave: false,
    jobStatus: [],
    Property: [],
    Engineers: [],
  };

  allPropertiesList: any[] = [];
  allEngineersList: any[] = [];
  jobStatusList: any[] = [];

  bsInlineValue = new Date();
  bsInlineRangeValue: Date[];
  bsMaterialInlineRangeValue: Date[];
  bsInlineRangeApprovedValue: Date[];
  bsMaterialInlineRangeApprovedValue: Date[];
  maxDate = new Date();
  isSelectAllCheckbox = true;
  filterPropertiesListPageNumber: number = 1;
  filterPropertiesListPageCount: number;
  filterPropertiesSize: number;
  filterPropertiesListTotal: number;
  filterPropertiesSearchInput: string = '';

  constructor(
    public _utilService: UtilityService,
    public _baseService: BaseService,
    public _Config: ConfigService,
    public _dialogService: DialogService,
    public _ticketService: TicketFlowsService,
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<TimesheetBuildreportComponent>,

  ) {

    this.maxDate.setDate(this.maxDate.getDate() + 5);
    this.bsInlineRangeValue = [this.bsInlineValue, this.maxDate];
    this.bsInlineRangeApprovedValue = [this.bsInlineValue, this.maxDate];
    this.bsMaterialInlineRangeValue = [this.bsInlineValue, this.maxDate];
    this.bsMaterialInlineRangeApprovedValue = [this.bsInlineValue, this.maxDate];
  }

  ngOnInit(): void {
    this.getPropertiesList();
    this.getEngineersList();
    this.getAllJobStatus()

  }

  ngOnDestroy(): void {

    this.formData = {
      isSave: true,
      jobStatus: [],
      Property: [],
      Engineers: [],
    };

  }


  getPropertiesList() {
    let property: any = JSON.parse(localStorage.getItem('userobj')) || {}

    if (property.category) {
      if (property.category == "Customer") {

        let requestURL = environment.baseURL + this._Config.getPropertiesFilter;
        this._dialogService.doGET(requestURL).subscribe((response: any) => {
          this.allPropertiesList = response || [];
        },
          (error) => { });
      }
    }
  }

  getEngineersList() {
    let tenant: any = JSON.parse(localStorage.getItem('userobj')) || {}

    if (tenant.category == "Customer") {
      let requestURL = environment.baseURL + this._Config.getReport_AllEngineers;
      this._dialogService.doGET(requestURL).subscribe((response: any) => {

        this.allEngineersList = response || [];
        this.allEngineersList.filter(obj => { obj.name = obj.full_name; return obj; });

      },
        (error) => { });
    }
  }

  getAllJobStatus() {

    this._Config.isLoader = true;

    // let requestURL = environment.baseURL + this._Config.getAllStagesToJob;
    let requestURL = environment.LocalDev_URL_V2 + 'job/stage/';
    requestURL = requestURL.replace('v2', 'v1');

    this._dialogService.doGET(requestURL).subscribe((response: any) => {

      this._Config.isLoader = false;

      this.jobStatusList = [];

      if (Array.isArray(response)) {
        response = response.map(obj => { obj.name = obj.value; return obj; });
        this.jobStatusList = response || [];
      }

    },
      (error) => {
        this._Config.isLoader = false;
      });



  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  /**
 * On Change Event
 * @param event
 */
  slideToggleChange(event: any): void {
    this.formData.isSave = event;
  }

  doBuildReport_Timesheet() {
    let Timesheet_status = this.TimesheetTypes.filter(obj => obj.isChecked == true).map(obj => { return obj.id }) || [];

    let typeIndex = this.TimesheetTypes.findIndex((element) => (element.id == 'All' && element.isChecked));
    let timeline_status: any;
    if (typeIndex == -1) {
      let selectedItem: any = this.TimesheetTypes.filter((element) => (element.isChecked));
      timeline_status = selectedItem.map((status: any) => {
        return status.name;
      });
      // this.isSelectAllCheckbox = false;
    }
    else {
      // this.isSelectAllCheckbox = true;
      // this.TimesheetTypes.map((type: any) => {
      //   if (type.name == 'All') {
      //     return type.isChecked = true;
      //   } else {
      //     return type.isChecked = false;
      //   }
      // });
      timeline_status = ['Approved', 'Proposed', 'Rejected'];
    }

    let Material_status = this.MaterialTypes.filter(obj => obj.isChecked == true).map(obj => { return obj.id }) || [];

    let matTypeIndex = this.MaterialTypes.findIndex((element) => (element.id == 'All' && element.isChecked));
    let material_status: any;
    if (matTypeIndex == -1) {
      let selectedItem: any = this.MaterialTypes.filter((element) => (element.isChecked));
      material_status = selectedItem.map((status: any) => {
        return status.name;
      });
      // this.isSelectAllCheckbox = false;
    }
    else {
      material_status = ['Approved', 'Proposed', 'Rejected'];
    }
    // let errorString: string = '';
    if (this.formData.Property.length == 0) {
      this._utilService.showError('Property', 'Should be selected');
      return;
    }
    else if (this.formData.Engineers.length == 0) {
      this._utilService.showError('Engineers', 'Should be selected');
      return;
    }
    else if (Timesheet_status.length == 0) {
      this._utilService.showError('Timesheet Status', 'Should be selected');
      return;
    }
    
    else if (this.formData.dateType === undefined && !this.formData.approvedDateType === undefined) {
      this._utilService.showError('Timesheet Entry Date or Timesheet Approval Date', 'Should be filled');
      return;
    }
    // else if (this.formData.dateType && !this.formData.approvedDateType) {
    //   if (timeline_status.includes('All') || timeline_status.includes('Approved')) {
    //     if (this.formData.approvedDateType != 0) {
    //       errorString += ' [Timesheet Approved Date] ';
    //       // this._utilService.showError('Ticket Timesheet Approved Date', 'Should be filled');
    //       // return;
    //     }
    //   }

    // }
    
    else if (Material_status.length == 0) {
      this._utilService.showError('Material Status', 'Should be selected');
      return;
    }
    else if (this.formData.materialDateType === undefined && this.formData.materialApprovedDateType === undefined) {
      this._utilService.showError('Material Entry Date or Material Approval Date', 'Should be filled');
      return;
    }
    // else if (this.formData.materialDateType && !this.formData.materialApprovedDateType) {
    //   if (material_status.includes('All') || material_status.includes('Approved')) {
    //     if (this.formData.materialApprovedDateType != 0) {
    //       errorString += ' [Material Approved Date] ';
    //       // this._utilService.showError('Material Approved Date', 'Should be filled');
    //       // return;
    //     }
    //   }
    // }

    // if(errorString !== '') {
    //   this._utilService.showError(errorString, 'Should be filled');
    //   return;
    // }
    // else if (!this.formData.jobStatus || this.formData.jobStatus?.length == 0) {
    //   this._utilService.showError('Job Status', 'Should be selected');
    //   return;
    // }
  
    // else if (this.formData.isSave) {
    //   if (!this.formData.Name) {
    //     this._utilService.showError('Name', 'Should be selected');
    //     return;
    //   }
    // }




    let postBody: any = {
      "type": "Timesheet",
      "query": {
        "job_status": this.formData.jobStatus,
        "timesheet_status": timeline_status,
        "material_status": material_status,
        "properties": this.formData.Property ? this.formData.Property : '',
        "engineers": this.formData.Engineers ? this.formData.Engineers : '',
      }
    };


    // Entry Date
    if (this.formData.dateType == 'Custom') {
      if (this.formData.StartDate && this.formData.EndDate) {
        let StartDate = formatDate(this.formData.StartDate, 'YYYY-MM-dd', 'en-US');
        let EndDate = formatDate(this.formData.EndDate, 'YYYY-MM-dd', 'en-US');
        postBody.query.timesheet_created_at = [StartDate, EndDate];
      } else {
        this._utilService.showError('Timesheet Entry Date', 'Enter the Date');
        return;
      }
    }
    else {
      postBody.query.timesheet_created_timeline = Number(this.formData.dateType);
    }

    if (this.formData.materialDateType == 'Custom') {
      if (this.formData.materialStartDate && this.formData.materialEndDate) {
        let StartDate = formatDate(this.formData.materialStartDate, 'YYYY-MM-dd', 'en-US');
        let EndDate = formatDate(this.formData.materialEndDate, 'YYYY-MM-dd', 'en-US');
        postBody.query.material_created_at = [StartDate, EndDate];
      } else {
        this._utilService.showError('Material Entry Date', 'Enter the Date');
        return;
      }
    } else {
      postBody.query.material_created_timeline = Number(this.formData.materialDateType);
    }
    


    // Approval Date
    if (this.formData.approvedDateType == 'Custom') {
      if (this.formData.StartApprovedDate && this.formData.EndApprovedDate) {
        let StartDate = formatDate(this.formData.StartApprovedDate, 'YYYY-MM-dd', 'en-US');
        let EndDate = formatDate(this.formData.EndApprovedDate, 'YYYY-MM-dd', 'en-US');
        postBody.query.timesheet_approved_at = [StartDate, EndDate];
      } else {
        this._utilService.showError('Timesheet Approval Date', 'Enter the Date');
        return;
      }
    }
    else {
      postBody.query.timesheet_approved_timeline = Number(this.formData.approvedDateType);
    }

    if (this.formData.materialApprovedDateType == 'Custom') {
      if (this.formData.materialStartApprovedDate && this.formData.materialEndApprovedDate) {
        let StartDate = formatDate(this.formData.materialStartApprovedDate, 'YYYY-MM-dd', 'en-US');
        let EndDate = formatDate(this.formData.materialEndApprovedDate, 'YYYY-MM-dd', 'en-US');
        postBody.query.material_approved_at = [StartDate, EndDate];
      } else {
        this._utilService.showError('Material Approval Date', 'Enter the Date');
        return;
      }

    } else {
      postBody.query.material_approved_timeline = Number(this.formData.materialApprovedDateType);
    }

    // if (timeline_status == 'all' || timeline_status == 'approved') {
    //   if (this.formData.approvedDateType == 'Custom') {
    //     if (this.formData.StartApprovedDate && this.formData.EndApprovedDate) {
    //       let StartDate = formatDate(this.formData.StartApprovedDate, 'YYYY-MM-dd', 'en-US');
    //       let EndDate = formatDate(this.formData.EndApprovedDate, 'YYYY-MM-dd', 'en-US');
    //       postBody.query.timesheet_approved_at = [StartDate, EndDate];
    //     } else {
    //       this._utilService.showError('Ticket Approved Date', 'Enter the Date');
    //       return;
    //     }
    //   }
    //   else {
    //     postBody.query.timesheet_approved_timeline = Number(this.formData.approvedDateType);
    //   }
    // }

    if (!postBody.query.timesheet_approved_timeline) {
      delete postBody.query.timesheet_approved_timeline;
    }

    if (!postBody.query.timesheet_created_timeline) {
      delete postBody.query.timesheet_created_timeline;
    }
    if (!postBody.query.material_approved_timeline) {
      delete postBody.query.material_approved_timeline;
    }

    if (!postBody.query.material_created_timeline) {
      delete postBody.query.material_created_timeline;
    }


    postBody.type = "Timesheet";
    postBody.save = this.formData.isSave;

    // if (this.formData.isSave) {
    //   postBody.name = this.formData.Name ? this.formData.Name : '';
    // }


    // let requestURL = environment.baseURL + this._Config.downloadBuildReport_Timeline + 'download/';
    let requestURL = environment.baseURL + this._Config.saveBuildReport;

    this._Config.isLoader = true;

    this._baseService.doPOST(requestURL, postBody, {
      observe: 'response',
      responseType: 'blob'
    }).subscribe((response: any) => {


      this._Config.isLoader = false;

      if (response.status === 200) {

        this.triggerFalseClick();

        this._utilService.showSuccess('', 'Report Generated Successfully.');
        this._ticketService.getAllBuildReportsDropdown('Timesheet');

        this.downloadBlobFile(response.body);

      }
      else if (response.status === 204) {
        this._utilService.showWarning('', 'There is no job found!');
      }


    },
      (error: HttpErrorResponse) => {

        this._Config.isLoader = false;

        let self = this;
        var reader = new FileReader();
        reader.onload = function () {
          self.showError(reader.result);
        }
        reader.readAsText(error.error);

        // this._utilService.showErrorMessage(error);
      }
    );
  }

  removeNullValues(array: any[]) {
    return array.filter(a => a);
  }

  showError(error: any) {

    if (typeof (error) === "string") {
      let errorVal: any = {
        error: JSON.parse(error)
      };
      this._utilService.showErrorMessage(errorVal);
    }

  }


  downloadBlobFile(BlobData: any) {
    let filename = new Date().getTime() + "_report.xlsx";

    let navigator: any = window.navigator;

    const blob = new Blob([BlobData], { type: 'application/octet-stream' });
    if (typeof navigator.msSaveBlob !== 'undefined') {
      navigator.msSaveBlob(blob, filename);
      return;
    }

    // Other browsers
    // Create a link pointing to the ObjectURL containing the blob
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(blobURL);
    }, 100);

  }



  isSaveReport(event: any) {
    let selectedOption = event.target.checked;

    this.formData.isSave = selectedOption;

    this.formData.Name = "";

  }


  triggerFalseClick() {

    this.dialogRef.close('Closed');

  }





  isChangeTimesheetType(event: any, i: number, item: any) {

    if (item.name == 'All') {
      // this.isSelectAllCheckbox = event;
      if (event) {
        this.TimesheetTypes.filter((element: any) => { element.isChecked = event; return element; });
      }
      else {
        this.TimesheetTypes.filter((element: any) => { element.isChecked = event; return element; });
      }
    } else {
      let selectedIndex = this.TimesheetTypes.findIndex((element) => element.id == item.name);
      this.TimesheetTypes[selectedIndex].isChecked = event;

      let isAllSelected = this.TimesheetTypes.filter((element: any) => element.id != 'All').filter(obj => obj.isChecked == false);
      if (isAllSelected.length == 0) {
        this.TimesheetTypes.filter((element: any) => { element.isChecked = event; return element; });
        // this.isSelectAllCheckbox = event;
      }
      else {
        let isAllIndex = this.TimesheetTypes.findIndex((element: any) => element.id === 'All');
        this.TimesheetTypes[isAllIndex].isChecked = false;
      }

    }

  }

  isChangeMaterialType(event: any, i: number, item: any) {

    if (item.name == 'All') {
      // this.isSelectAllCheckbox = event;
      if (event) {
        this.MaterialTypes.filter((element: any) => { element.isChecked = event; return element; });
      }
      else {
        this.MaterialTypes.filter((element: any) => { element.isChecked = event; return element; });
      }
    } else {
      let selectedIndex = this.MaterialTypes.findIndex((element) => element.id == item.name);
      this.MaterialTypes[selectedIndex].isChecked = event;

      let isAllSelected = this.MaterialTypes.filter((element: any) => element.id != 'All').filter(obj => obj.isChecked == false);
      if (isAllSelected.length == 0) {
        this.MaterialTypes.filter((element: any) => { element.isChecked = event; return element; });
        // this.isSelectAllCheckbox = event;
      }
      else {
        let isAllIndex = this.MaterialTypes.findIndex((element: any) => element.id === 'All');
        this.MaterialTypes[isAllIndex].isChecked = false;
      }

    }

  }

  isShowApprovedDate() {
    var isInArray = this.TimesheetTypes.find(function (el) { return (el.id === 'All' && el.isChecked) || (el.id === 'Approved' && el.isChecked) });
    return isInArray;
  }

  isMaterialShowApprovedDate() {
    var isInArray = this.MaterialTypes.find(function (el) { return (el.id === 'All' && el.isChecked) || (el.id === 'Approved' && el.isChecked) });
    return isInArray;
  }


  /***************** Change Property Event *******************/

  onChangeProperty(selectedItems: any) {
    if (selectedItems.length != undefined) {
      // const selected = selectedItems.map(item => item.id);
      const selected = selectedItems;
      this.formData.Property = selected;
    }
  }

  /**
   * select All Property
   * @param selectedItems
   */
  onSelectAllProperty(selectedItems) {
    if (selectedItems) {
      let selected = this.allPropertiesList;
      this.formData.Property = selected.map(item => item.id);
    } else {
      this.formData.Property = [];
    }
  }

  /***************** Change Engineers Event *******************/

  onChangeEngineers(selectedItems: any) {
    if (selectedItems.length != undefined) {
      // const selected = selectedItems.map(item => item.id);
      const selected = selectedItems;
      this.formData.Engineers = selected;
    }
  }

  /**
   * select All Engineer
   * @param selectedItems
   */
  onSelectAllEngineers(selectedItems) {

    if (selectedItems) {
      let selected = this.allEngineersList;
      this.formData.Engineers = selected.map(item => item.id);
    } else {
      this.formData.Engineers = [];
    }
  }

  /***************** Change Status Event *******************/

  onChangeStatus(selectedItems: any) {
    if (selectedItems.length != undefined) {
      // const selected = selectedItems.map(item => item.id);
      const selected = selectedItems;
      this.formData.jobStatus = selected;
    }
  }


  /**
   * select All Engineer
   * @param selectedItems
   */
  onSelectAllStatus(selectedItems) {

    if (selectedItems) {
      let selected = this.jobStatusList;
      this.formData.jobStatus = selected.map(item => item.id);
    } else {
      this.formData.jobStatus = [];
    }
  }




  /**
 * Change Filter for Date Range
 */
  onChangeDateOptions(event: any, key: any) {

    if (event == undefined) {
      if (key == 'entry_Date') {
        this.formData.dateType = null;
        this.formData.dateValue = [];
      }
      if (key == 'approved_Date') {
        this.formData.approvedDateType = null;
        this.formData.approvedDateValue = null;
      }
    }
    else {
      if (key == 'entry_Date') {
        if (event) {
          let value = event.value;

          this.formData.dateType = value;

          if (this.formData.dateType != 'Custom') {
            this.formData.dateValue = [];
            this.formData.StartDate = null;
            this.formData.EndDate = null;
          }
        }
      } else {
        if (event) {
          let value = event.value;

          this.formData.approvedDateType = value;

          if (this.formData.approvedDateType != 'Custom') {
            this.formData.approvedDateValue = [];
            this.formData.StartApprovedDate = null;
            this.formData.EndApprovedDate = null;
          }
        }
      }
    }




  }
  // For Material
  onMaterialChangeDateOptions(event: any, key: any) {

    if (event == undefined) {
      if (key == 'material_entry_Date') {
        this.formData.materialDateType = null;
        this.formData.materialDateValue = [];
      }
      if (key == 'material_approved_Date') {
        this.formData.materialApprovedDateType = null;
        this.formData.materialApprovedDateValue = null;
      }
    }
    else {
      if (key == 'material_entry_Date') {
        if (event) {
          let value = event.value;

          this.formData.materialDateType = value;

          if (this.formData.materialDateType != 'Custom') {
            this.formData.materialDateValue = [];
            this.formData.materialStartDate = null;
            this.formData.materialEndDate = null;
          }
        }
      } else {
        if (event) {
          let value = event.value;

          this.formData.materialApprovedDateType = value;

          if (this.formData.materialApprovedDateType != 'Custom') {
            this.formData.materialApprovedDateValue = [];
            this.formData.materialStartApprovedDate = null;
            this.formData.materialEndApprovedDate = null;
          }
        }
      }
    }




  }


  onClearDateOptions(event?: any) {

    // if (this.customDateDropown)
    //   this.customDateDropown.clearModel();

    // if (this.customApprovedDateDropown) {
    //   this.customApprovedDateDropown.clearModel();
    // }
    // this.formData.dateType = null;
    // this.formData.approvedDateType = null;
    // this.formData.dateValue = [];
    // this.formData.approvedDateValue = null;
    // this.formData.StartDate = null;
    // this.formData.EndDate = null;
    // this.formData.StartApprovedDate = null;
    // this.formData.EndApprovedDate = null;
    // this.TimesheetTypes.forEach((element, index) => {
    //   this.TimesheetTypes[index].isChecked = true;
    // });
  }

  onValueChange(value: Date): void {
    this.formData.dateValue = value;

    let StartDate = formatDate(this.formData.dateValue[0], 'MM/dd/YYYY', 'en-US');
    let EndDate = formatDate(this.formData.dateValue[1], 'MM/dd/YYYY', 'en-US');

    // let StartDate = this._utilService.formatDate(this.formData.dateValue[0]);
    // let EndDate = this._utilService.formatDate(this.formData.dateValue[1]);

    this.formData.StartDate = StartDate;
    this.formData.EndDate = EndDate;

  }

  onApprovedValueChange(value: Date): void {
    this.formData.approvedDateValue = value;

    let StartDate = formatDate(this.formData.approvedDateValue[0], 'MM/dd/YYYY', 'en-US');
    let EndDate = formatDate(this.formData.approvedDateValue[1], 'MM/dd/YYYY', 'en-US');

    // let StartDate = this._utilService.formatDate(this.formData.dateValue[0]);
    // let EndDate = this._utilService.formatDate(this.formData.dateValue[1]);

    this.formData.StartApprovedDate = StartDate;
    this.formData.EndApprovedDate = EndDate;

  }

  onMaterialValueChange(value: Date): void {
    this.formData.materialDateValue = value;

    let StartDate = formatDate(this.formData.materialDateValue[0], 'MM/dd/YYYY', 'en-US');
    let EndDate = formatDate(this.formData.materialDateValue[1], 'MM/dd/YYYY', 'en-US');
    
    this.formData.materialStartDate = StartDate;
    this.formData.materialEndDate = EndDate;
  }

  onMaterialApprovedValueChange(value: Date): void {
    this.formData.materialApprovedDateValue = value;

    let StartDate = formatDate(this.formData.materialApprovedDateValue[0], 'MM/dd/YYYY', 'en-US');
    let EndDate = formatDate(this.formData.materialApprovedDateValue[1], 'MM/dd/YYYY', 'en-US');
    
    this.formData.materialStartApprovedDate = StartDate;
    this.formData.materialEndApprovedDate = EndDate;

  }

  focusOutStartDate(e, type?: any) {
    let StartDate = new Date(e.target.value);
    let EndDate = new Date(this.formData.EndDate);
    if ((StartDate.toString()) == 'Invalid Date') {
      this.formData.StartDate = '';
      this._utilService.showError('Start Date', 'Enter a valid Start Date');
      return;
    } else {
      this.bsInlineRangeValue = [StartDate, EndDate];
    }

    if (type === 'material') {
      let StartDate = new Date(e.target.value);
      let EndDate = new Date(this.formData.materialEndDate);
      if ((StartDate.toString()) == 'Invalid Date') {
        this.formData.materialStartDate = '';
        this._utilService.showError('Start Date', 'Enter a valid Start Date');
        return;
      } else {
        this.bsMaterialInlineRangeValue = [StartDate, EndDate];
      }
    }
  }

  focusOutEndDate(e, type?: any) {
    let StartDate = new Date(this.formData.StartDate);
    let EndDate = new Date(e.target.value);
    if ((EndDate.toString()) == 'Invalid Date') {
      this.formData.EndDate = '';
      this._utilService.showError('End Date', 'Enter a valid End Date');
      return;
    } else {
      if (StartDate > EndDate) {
        this.formData.EndDate = '';
        this._utilService.showError('Error', 'End Date should be greater than Start Date');
      } else {
        this.bsInlineRangeValue = [StartDate, EndDate];
      }
    }

    if (type === 'material') {
      let StartDate = new Date(this.formData.materialStartDate);
      let EndDate = new Date(e.target.value);
      if ((EndDate.toString()) == 'Invalid Date') {
        this.formData.materialEndDate = '';
        this._utilService.showError('End Date', 'Enter a valid End Date');
        return;
      } else {
        if (StartDate > EndDate) {
          this.formData.materialEndDate = '';
          this._utilService.showError('Error', 'End Date should be greater than Start Date');
        } else {
          this.bsMaterialInlineRangeValue = [StartDate, EndDate];
        }
      }
    }
  }

  focusOutApprovedStartDate(e, type?: any) {
    let StartDate = new Date(e.target.value);
    let EndDate = new Date(this.formData.EndDate);
    if ((StartDate.toString()) == 'Invalid Date') {
      this.formData.StartApprovedDate = '';
      this._utilService.showError('Start Date', 'Enter a valid Start Date');
      return;
    } else {
      this.bsInlineRangeApprovedValue = [StartDate, EndDate];
    }
    if (type === 'material') {
      let StartDate = new Date(e.target.value);
      let EndDate = new Date(this.formData.materialEndApprovedDate);
      if ((StartDate.toString()) == 'Invalid Date') {
        this.formData.materialStartApprovedDate = '';
        this._utilService.showError('Start Date', 'Enter a valid Start Date');
        return;
      } else {
        this.bsMaterialInlineRangeApprovedValue = [StartDate, EndDate];
      }
    }
  }

  focusOutApprovedEndDate(e, type?: any) {
    let StartDate = new Date(this.formData.StartApprovedDate);
    let EndDate = new Date(e.target.value);
    if ((EndDate.toString()) == 'Invalid Date') {
      this.formData.EndApprovedDate = '';
      this._utilService.showError('End Date', 'Enter a valid End Date');
      return;
    } else {
      if (StartDate > EndDate) {
        this.formData.EndApprovedDate = '';
        this._utilService.showError('Error', 'End Date should be greater than Start Date');
      } else {
        this.bsInlineRangeApprovedValue = [StartDate, EndDate];
      }
    }
    if (type === "material") {
      let StartDate = new Date(this.formData.materialStartApprovedDate);
      let EndDate = new Date(e.target.value);
      if ((EndDate.toString()) == 'Invalid Date') {
        this.formData.materialEndApprovedDate = '';
        this._utilService.showError('End Date', 'Enter a valid End Date');
        return;
      } else {
        if (StartDate > EndDate) {
          this.formData.materialEndApprovedDate = '';
          this._utilService.showError('Error', 'End Date should be greater than Start Date');
        } else {
          this.bsMaterialInlineRangeApprovedValue = [StartDate, EndDate];
        }
      }
    }
  }

   /** Track by Index  **/
   trackByIndex(index: number, item: any): number {
    return index;
  }

  // fetchPropertyNextPage() {
  //   if (this.filterPropertiesListPageNumber <= this.filterPropertiesListPageCount) {
  //     this.filterPropertiesListPageNumber++;
  //     this.getPropertiesList(true);
  //   }
  // }

  // propertyDropdownSearch(event: any) {
  //   this.filterPropertiesSearchInput = event.term;
  //   this.filterPropertiesListPageNumber = 1;
  //   this.getPropertiesList();
  // }

}