// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rules-container {
  max-height: 57vh;
  overflow: scroll;
}

.footer {
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #ffffff;
  padding: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_views/_popup_views/detailed-template-view/detailed-template-view.component.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,gBAAA;AADF;;AAIA;EACE,gBAAA;EACA,OAAA;EACA,SAAA;EACA,WAAA;EACA,wBAAA;EAAA,mBAAA;EACA,yBAAA;EACA,qBAAA;AADF","sourcesContent":["\n\n.rules-container {\n  max-height: 57vh;\n  overflow: scroll;\n}\n\n.footer {\n  position: sticky;\n  left: 0;\n  bottom: 0;\n  width: 100%;\n  height: fit-content;\n  background-color: #ffffff;\n  padding: 0 !important;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
