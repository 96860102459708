import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { GraphqlEquipmentTypeService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/equipment-type/graphql-equipment-type.service';
import { UtilityService } from 'src/app/services/utility.service';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { CheckboxComponent } from '../../../_reusable_ui/_controls/checkbox/checkbox.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';

@Component({
    selector: 'app-edit-item-name',
    templateUrl: './edit-item-name.component.html',
    styleUrls: ['./edit-item-name.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, TextboxComponent, NgxIntlTelInputModule, CheckboxComponent, MatDialogModule, ButtonComponent]
})
export class EditItemNameComponent implements OnInit {

  switchOnOff: boolean = true;
  addAdditionalItemForm: any = {
    itemValue: ''
  };
  EditForm:FormGroup;
  onCloseSave:boolean = false;



  constructor( public graphql:GraphqlEquipmentTypeService, @Inject(MAT_DIALOG_DATA) public data: any,public _utilService:UtilityService) {}

  ngOnInit(): void {
    this.EditForm = new FormGroup({
      name:new FormControl(this.data?.data.value),
      required:new FormControl(this.data?.data.is_required)
    })
  }


    /**
* On Change Event
* @param event
*/
selectedTags(event: any): void {
  this.addAdditionalItemForm.selectedFilters=event;
}

slideToggleChange(event: any): void {}

/**
 * save view Trigger
 */
saveView(){
  this.graphql.updateAddtionalDataItem(this.data?.data.id,this.EditForm.value).subscribe({
    next:(res:any)=>{
      this.graphql.additionalDataTab.emit(true);
      this._utilService.showSuccess('', 'Assigned Item Updated Successfully');
      this.onCloseSave = true;
    },
    error:(err:HttpErrorResponse) => {
      this._utilService.showError('', JSON.parse(err.message).detail);
     },
  })

}


}
