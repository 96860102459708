import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TimesheetService {

  _viewModule: string = "timeSheet";
  isLoader: boolean = false;
  selectedActiveTabId: string;
  isAddOrUpdateTimeSheet = false;

  isTimeSheetAddUpdate$ = new BehaviorSubject<any>(undefined);

  constructor(public httpClient: HttpClient) { }

  /**
  *  GET Method
  */
  doGET(endpoint_URL: string): Observable<any> {
    return this.httpClient.get(`${environment.fastAPI_Actual_URL + '/' + endpoint_URL}`);
  }

  /**
  *  POST Method
  */
  doPOST(endpoint_URL: string, postBody: any): Observable<any> {
    return this.httpClient.post(`${environment.fastAPI_URL + '/' + endpoint_URL}`, postBody);
  }

  /**
   *  PUT Method
   */
  doUPDATE(endpoint_URL: string, postBody: any): Observable<any> {
    return this.httpClient.put(`${environment.fastAPI_URL + '/' + endpoint_URL}`, postBody);
  }

  /**
  *  PATCH Method
  */
  doPATCH(endpoint_URL: string, postBody: any): Observable<any> {
    return this.httpClient.patch(`${environment.fastAPI_Actual_URL + '/' + endpoint_URL}`, postBody);
  }


  /**
  *  DELETE Method
  */
  doDELETE(endpoint_URL: string): Observable<any> {
    return this.httpClient.delete(`${environment.LocalDev_URL_V2 + '/' + endpoint_URL}`);
  }

  /**
   * Get All Views API responses
   * @returns
   */
  getAllViewsData() {
    let listAllStandardViews = this.httpClient.get(environment.LocalDev_URL_V2 + 'manage-dashboard/views/?source=timesheet');
    return listAllStandardViews ;
  }


  /**
   * Get all selected View Table lists
   * @param viewID
   * @returns
   */
  getSelectedTableData(viewID: string) {
    let selectedViewTableHeaders = this.httpClient.get(`${environment.fastAPI_Actual_URL + '/company-views/' + viewID + '/'}`);

    return selectedViewTableHeaders;
  }


  /**
   * Get all selected View Table lists
   * @param viewID
   * @returns
   */
  getSelectedTableData1(selectedViewTableRows: string) {
    return this.httpClient.get(selectedViewTableRows);
  }


 /**
   * Get Default Active tab Data
   * @param tabList
   * @returns
   */
 getDefaultActiveVerticalMenuID(tabList: any) {
  let activeMenu: any = {};

  let activeTabList = tabList.filter(obj => obj.menus.some((item: { default: boolean; }) => item.default == true)).map(obj => { return { activeItem: obj.menus.find((item: { default: boolean; }) => item.default == true), type: obj.type, activeIndex: obj.menus.findIndex((item: { default: boolean; }) => item.default == true) } });

  if (activeTabList.length > 0) {
    activeMenu = activeTabList[0];
  }

  if(activeMenu.activeItem==undefined){
    if(tabList.length>0 && tabList[0]?.menus?.length>0){
      tabList[0].menus[0].default=true
      activeMenu=tabList[0].menus[0];
    }
  }

  return activeMenu.activeItem;
}


  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  setTimeSheetData(response: any) {
    this.isTimeSheetAddUpdate$.next(response);
  }
}
