import {Component, EventEmitter, Inject, Input, OnInit, Optional, Output} from '@angular/core';
import { ItemList } from 'src/app/@fyxt/_shared/_reusable_ui/_controls/dropdown-select/itemType.class';
import {FormControl} from "@angular/forms";
import { DropdownComponent } from '../../../_reusable_ui/_controls/dropdown/dropdown.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'fyxt-tab-categories',
    templateUrl: './tab-categories.component.html',
    styleUrls: ['./tab-categories.component.scss'],
    standalone: true,
    imports: [NgIf, LoaderSmallComponent, DropdownComponent]
})
export class TabCategoriesComponent implements OnInit {

  @Input() chipList: any[] = [];
  @Input() isLoader!: boolean;
  @Input() companySelectedCategories: any[];
  @Input() disabled: boolean = false;
  @Output() onChangeCategories = new EventEmitter();
  @Output() onCloseCategories = new EventEmitter();
  @Output() onRemoveCategories = new EventEmitter();
  // dropdownMulti = new FormControl([{id:1,label:'One',name:'One'}]);
  constructor() { }

  ngOnInit(): void {
  }

  changeCategory(event: any): void {
    this.onChangeCategories.emit(event);
  }

  onCloseDropdown(event: any): void {
    this.onCloseCategories.emit(event);
  }

  removeCategories(event: any): void {
    this.onRemoveCategories.emit(event);
  }

}
