import { Component, EventEmitter, forwardRef, Input, NgZone, OnInit, Output , ViewChild} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { DropdownPosition, NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { throttle } from 'lodash';
import { CheckboxComponent } from '../../checkbox/checkbox.component';
import { MatIconModule } from '@angular/material/icon';
import { ChipComponent } from '../../../_components/chip/chip.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { NgClass, NgIf, NgFor, NgStyle, SlicePipe } from '@angular/common';


@Component({
    selector: 'fyxt-dropdown-select',
    templateUrl: './dropdown-select.component.html',
    styleUrls: ['./dropdown-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => DropdownSelectComponent)
        }
    ],
    standalone: true,
    imports: [NgClass, NgIf, NgSelectModule, FormsModule, NgxIntlTelInputModule, NgFor, ChipComponent, MatIconModule, NgStyle, CheckboxComponent, SlicePipe]
})
export class DropdownSelectComponent implements OnInit, ControlValueAccessor {

  @Input() items!: any[];
  @Input() placeHolder: any;
  @Input() label: any;
  @Input() Error: any;
  @Input() Image: any;
  @Input() groupKey: any;
  @Input() multi: boolean = false;
  @Input() grouped: boolean = false;
  @Input() checkbox: boolean = false;
  @Input() profile: boolean = false;
  @Input() units: boolean = false;
  @Input() searchable: boolean = false;
  @Input() selectAll: boolean = false;
  @Input() disable: boolean = false;
  @Input() readOnly: boolean = false;
  @Output() onValueChange = new EventEmitter<Event>();
  @Output() onSearchDropdown = new EventEmitter<Event>();
  @Output() clearAll = new EventEmitter<Event>();
  @Output() onScrollDropdown = new EventEmitter();
  @Output() onGetRemovableValueChange = new EventEmitter<Event>();
  @Output() close = new EventEmitter<boolean>();
  @Input() input: any;
  @Input() customClass: string = '';
  @Input() dropdownPosition: DropdownPosition = 'auto';
  @Input() bindLabel: string = 'name';
  @Input() bindValue: string = null;
  @Input() addTagText: string = 'Add Item';
  @Input() closeOnSelect: boolean = false;
  @Input() addTag: boolean = false;
  @Input() clearable: boolean = false;
  @Input() isCreateTicket: boolean = false;
  @Input() isCategoryView: boolean = false;
  @Input() isInviteUserView: boolean = false;
  @Input() isEquipment: boolean = false;
  @Input() address: boolean = false;
  @Input() isPropertyDropDown: boolean = false;
  @Input() showRequiredStar: boolean = false;
  isSelectAll: boolean = false;
  @ViewChild('dropdownSelect') dropdownSelect: NgSelectComponent;
  @Input() fullInputData: { id: string, name: string }[] = [];
  @Input() loading: boolean = false;
  @Input() isFromChecklist: boolean = false;
  @Input() disableAll: boolean = false;

  showDropdown: boolean = false;


  private onScrollThrottled: () => void;

  constructor(
    private zone: NgZone
  ) {
    this.onScrollThrottled = throttle(this.onScroll, 200);
  }

  ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      window.addEventListener('scroll', this.onScrollThrottled, true);
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.onScrollThrottled, true);
  }



  ngOnChanges(changes): void {}

  getItemName(item) {
    if (item.name) return item.name;

    if (!(this.fullInputData && this.fullInputData.length)) {
      return '';
    }

    let name = '';

    if (item.id) {
      const existInFullData = this.fullInputData.find(obj => obj.id === item.id);
      if (existInFullData && existInFullData.name) {
        name = existInFullData.name;
      }
    }

    return name;
  }


  private onScroll = (event: any) => {
    if (this.dropdownSelect && this.dropdownSelect.isOpen) {
      const isScrollingInScrollHost = (event.target.className as string).indexOf('ng-dropdown-panel-items') > -1;
      if (isScrollingInScrollHost) { return; }
      this.dropdownSelect.close();
    }
  }
  onChange = (data: any) => { }
  onTouch = (_: any) => { }
  isOpen: boolean = false;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: string) {
    this.input = value;
    this.onChange(value);
  }

  /**
  *Func triggered on input change
  */
  onchange(event: any) {

    if (this.selectAll) {
      if (this.items.length == this.input.length) {
        this.isSelectAll = true;
      }
      else {
        this.isSelectAll = false;
      }
      this.onChange(this.input);
     // this.onValueChange.emit(this.input);
    }
    else {
      this.onChange(this.input);

    }
    this.onValueChange.emit(event);

  }


  /**
* Triggered when select all
*/
  toggleSelectAll(isSelected?: boolean) {
    this.items.filter(obj => obj.selected = isSelected);

    if (isSelected == true) {
      this.input = this.items.map(obj => obj.id);
    } else {
      this.input = [];
    }

    // this.isSelectAll=isSelected;

    // this.onChange(this.input);
    // this.onValueChange.emit(this.input);

  }


  /**
* Triggered when select all
*/
toggleSelectAll_GroupedItems(isSelected?: boolean) {

  this.items.filter(obj => obj.selected = isSelected);

  if (isSelected == true) {
    this.input = this.items.map(obj => obj.id);
  } else {
    this.input = [];
  }

  // this.isSelectAll=isSelected;

  // this.onChange(this.input);
  // this.onValueChange.emit(this.input);

}

  /**
  *Func triggered on Selecting all values
  */
  onSelectAll(event: any) {
    // if (event.target.checked == true) {
    //   this.input = this.items.map(obj=>obj.id);
    // } else {
    //   this.input = [];
    // }

    // this.isSelectAll=event.target.checked;

    this.onChange(this.input);
    this.onValueChange.emit(this.input);
  }


  onRemove(event: any): void {
    this.onGetRemovableValueChange.emit(event.value);
  }

  /**
*Func triggered on scroll bar go to bottom
*/
  infiniteScroll() {
    this.onScrollDropdown.emit();
  }

  /**
*Func triggered on input change
*/
  onSearch(event: any) {
    this.onOpen();
    this.onSearchDropdown.emit(event);
  }

  stopPropagation(event: any): any {
    event.stopPropagation();
  }

  onOpen(event?: any) {
    this.isOpen = true;
    this.onCloseChip();
  }

  onToggle(event?: any) {
    this.isOpen = !this.isOpen;
    this.onCloseChip();
  }

  onOpenChip(event: any) {
    event.stopPropagation();
    this.isOpen = false;
  }

  onCloseChip() {
    this.showDropdown = false;
  }

  onClose(event?: any){
    this.isOpen = false;
    this.onCloseChip();
    this.close.emit(true);
  }

  getColor(item: any): string {
    if (item && item.name === 'Pass') {
      return '#67b267';
    } else if (item && item.name === 'Fail') {
      return '#d74e60';
    } else if (item && item.name === 'Flag') {
      return '#D63C00';
    }
  }
}
