import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { SidebarService } from 'src/app/@fyxt/_services/_layout/sidebar.service';
import { CompaniesService } from 'src/app/Modules/_fyxt_modules/companies/_services/companies.service';
import { UtilityService } from 'src/app/services/utility.service';
import { ConfigService } from 'src/app/services/config.service';
import { environment } from 'src/environments/environment';
import { ModalComponent } from '../../_reusable_ui/_components';
import { ActivatedRoute, Router } from "@angular/router";
import { BaseService } from 'src/app/services/base.service';
import { CreatContactSharedComponent } from 'src/app/Modules/shared/components/job-modals/sharing-viewers-modal/creat-contact-shared/creat-contact-shared.component';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ContactsService } from 'src/app/Modules/_fyxt_modules/contacts/_services/contacts.service';
import { TagsComponent } from '../../_reusable_ui/_components/tags/tags.component';
import { SwitchComponent } from '../../_reusable_ui/_controls/switch/switch.component';
import { GoogleAddressTextboxComponent } from '../../_reusable_ui/_controls/google-address-textbox/google-address-textbox.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { DropdownSelectComponent } from '../../_reusable_ui/_controls/dropdown-select/dropdown-select/dropdown-select.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CardComponent } from '../../_reusable_ui/_components/card/card.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TextboxComponent } from '../../_reusable_ui/_controls/textbox/textbox.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../_reusable_ui/_components/button/button.component';
import { NgIf, NgClass, NgFor, NgTemplateOutlet, DatePipe } from '@angular/common';

@Component({
    selector: 'fyxt-add-company',
    templateUrl: './add-company.component.html',
    styleUrls: ['./add-company.component.scss'],
    standalone: true,
    imports: [NgIf, ButtonComponent, MatIconModule, MatDividerModule, NgClass, FormsModule, ReactiveFormsModule, TextboxComponent, NgxIntlTelInputModule, CardComponent, NgFor, InfiniteScrollModule, DropdownSelectComponent, NgxMatIntlTelInputComponent, MatTooltipModule, NgTemplateOutlet, GoogleAddressTextboxComponent, SwitchComponent, TagsComponent, DatePipe]
})
export class AddCompanyComponent implements OnInit {
  @Input() updateCompany: boolean = false;
  @Input() companyData: any;
  @Input() data: any;
  @Input() primaryPhoneNumber: any;
  @Input() primaryEmail: any;
  @Input() primaryAddress: any;
  @Input() isAlreadyExistsNumber = false;
  @Input() isAlreadyExistsEmail = false;
  @ViewChild('confirmUserExist')
  confirmUserExist!: TemplateRef<any>;
  primaryIndexPhone: number = 0;
  primaryIndexEmail: number = 0;
  primaryIndexAddress: number = 0;
  chipList: any[] = [];
  companyModalPopup: boolean = false;
  addCompanyForm!: FormGroup;
  phone_listForm: any;
  email_listsform: any;
  address_listsform: any;
  formSubmitted: boolean = false;
  isLoader: boolean = false;
  selectedTagsList: any[] = [];
  removeList: any = { email: [], phoneNumber: [], address: [] };
  isVendorTypeCompany: boolean;
  newCompanyData: any;
  allExistingCompanies = [];
  allExistingCompaniesPageNumer: any;
  showExistingCompanyDropdown = false;
  dropdownChangeValue = false;
  companyType: string;
  phoneNumberText: any;
  removePhoneNumerButtonClicked = false;
  @Output() fetchCompanyDetails = new EventEmitter();
  newEmail: any;
  companyNameSearch: boolean = true;
  defaultCompanyType: string = '';
  allContactsList: any[] = [];
  allContactsListPageNumber: number = 1;
  allContactsListTotal: number;
  allContactsListSize: number = 25;
  companiesSearchInput: any = "";
  primaryContactId: any;
  primaryContactName: string = '';
  allContactsListPageCount: number;
  selectContactIsSelected: boolean = false;
  showSelectContactError: boolean = false;
  clickHereError: boolean = false;
  showSelectContacts: boolean = false;
  seletedContactId: string = '';
  debounceSubject = new Subject<string>(); // Create a debounce subject
  primaryContactData: any = [];
  contactValue: any = null;
  
  can_create_companies: Boolean;
  // can_view_companies:Boolean;
  // showWarningBox:Boolean = false;
  isComplaintVendor: Boolean;
  can_create_compliant: boolean;

  constructor(
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    public formbuilder: FormBuilder,
    public _dialogService: DialogService,
    public _companiesService: CompaniesService,
    public sidebarService: SidebarService,
    public _utilService: UtilityService,
    public _Config: ConfigService,
    public _baseService: BaseService,
    private _contactsService: ContactsService,
    public _dialogRef: MatDialogRef<ModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public matDialogData: any,
  ) {
    this._companiesService.fetchCreateCompanyInitialization().subscribe((state) => {
      if (state) {
        this.chipList = [];
        this.isVendorTypeCompany = false;
        this.showExistingCompanyDropdown = false;
        this._companiesService.createCompanyInitialize.next(false);
      }
    });
  }

  ngOnInit(): void {
    this.initializeForm();
    if (this.matDialogData?.data?.companyModal) {
      this.companyModalPopup = true;
    }
    if (this.matDialogData?.data?.showSelectContacts) {
      this.debounceSubject.pipe(debounceTime(1000)) // Adjust the debounce delay (1000 milliseconds in this example)
        .subscribe((event: any) => {
          this.companiesSearchInput = event.term;
          this.allContactsListPageNumber = 1;
          this.getAllContactsDropdowns();
        });
      this.getAllContactsDropdowns();
      this.showSelectContacts = true;
    }
    // dynamically disable the company name search API
    if (this.matDialogData?.data?.disableCompanyNameSearch) {
      this.companyNameSearch = false;
    }

    // set the default value in company type
    if (this.matDialogData?.data?.defaultCompanyType !== '') {
      this.defaultCompanyType = this.matDialogData?.data?.defaultCompanyType;
      let requestURL = environment.baseURL + 'company-types/?source=Company';
      this._dialogService.doGET(requestURL).subscribe((response: any) => {
        this._companiesService.formDropDownTypes.company_types = response || [];
        this._companiesService.formDropDownTypes.company_types.forEach(element => {
          if (element.name === this.defaultCompanyType) {
            this.addCompanyForm.patchValue({
              company_type: element,
            });
          }
        });
      });
    }
    if (environment.isRolesPermissionsForCompaniesContactsLeases) {
      if (this._baseService.currentUserInfo?.current_Role == 'Manager' || this._baseService.currentUserInfo?.current_Role == 'Owner') {
        if (this._baseService.currentUserInfo.permissions.includes('add_companies')) {
          this.can_create_companies = true;
        } else {
          this.can_create_companies = false;
        }

        // if (this._baseService.currentUserInfo.permissions.includes('view_companies')) {
        //   this.can_view_companies = true;
        // } else {
        //   this.can_view_companies = false;
        //   this.showWarningBox = true;
        // }
      }
    } else {
      this.can_create_companies = true;
      // this.can_view_companies = true;
    }

    if (this._baseService.currentUserInfo?.current_Role == 'Manager' || this._baseService.currentUserInfo?.current_Role == 'Owner') {
      if (this._baseService.currentUserInfo.permissions.includes('add_coi')) {
        this.can_create_compliant = true;
      } else {
        this.can_create_compliant = false;
      }
    }
  }

  ngOnChanges(changes): void {
    if (changes.companyData?.currentValue?.id) {
      this.initializeForm();
    }
  }

  ngAfterViewInit(): void { }

  ngOnDestroy(): void {
    this.companyModalPopup = false;
  }

  /*** Initialize Create Company Form */
  initializeForm() {
    const reg = "((http|https)?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?";
    this.addCompanyForm = this.formbuilder.group({
      'company_name': ['', [Validators.required, Validators.maxLength(250)]],
      'company_type': [this._companiesService.formDropDownTypes?.company_types[0] ? this._companiesService.formDropDownTypes.company_types[0] : null, Validators.required],
      // 'website': ['', [Validators.pattern(reg)]],
      'website': [''],
      'phone_numbers': this.formbuilder.array([this.addPhoneNo()]),
      'address_lists': this.formbuilder.array([this.addAddress()]),
      'email_lists': this.formbuilder.array([this.addEmailAddress()]),
      'date_created': ['']
    });
    this.phone_listForm = this.addCompanyForm.controls['phone_numbers'];
    this.email_listsform = this.addCompanyForm.controls['email_lists'];
    this.address_listsform = this.addCompanyForm.controls['address_lists'];
    if (this.updateCompany) {
      this.setFormData();
    }
  }

  get formControl() {
    return this.addCompanyForm.controls;
  }

  get s_url() {
    return this.addCompanyForm.get("website");
  }

  // PhoneNo Code starts here //
  /*** Set Create Company Form Data */
  setFormData() {
    if (this.companyData.type == 'Vendor') {
      this.isVendorTypeCompany = true;
      this.isComplaintVendor = this.companyData?.is_compliant;
    }
    this.addCompanyForm.patchValue({
      company_name: this.companyData.name,
      company_type: this.companyData.type,
      website: this.companyData.website,
      is_compliant: this.companyData?.is_compliant,
      date_created: this._utilService.formatDateToMM_DD_YYYY(new Date(this.companyData.created_at)),
    });
    this.addCompanyForm.setControl('phone_numbers', this.setPhoneNumbersList(this.companyData.phones));
    this.addCompanyForm.setControl('email_lists', this.setEmailList(this.companyData.emails));
    this.addCompanyForm.setControl('address_lists', this.setAddressList(this.companyData.addresses));
    this.phone_listForm = this.addCompanyForm.controls['phone_numbers'];
    this.email_listsform = this.addCompanyForm.controls['email_lists'];
    this.address_listsform = this.addCompanyForm.controls['address_lists'];
    if (this.companyData.tags?.length > 0) {
      this.chipList = this.companyData.tags.filter((tag: any) => {
        return tag !== null;
      }) || [];
    } else {
      this.chipList = [];
    }
  }

  getAllExistingCompanyName(searchVal?: string, pagination?: boolean): void {
    let requestURL = "companies/?columns=['name']&search=" + searchVal;
    if (pagination) {
      this.allExistingCompaniesPageNumer += 1;
      requestURL += '&page=' + this.allExistingCompaniesPageNumer;
    }
    this._companiesService.doGET(requestURL).subscribe(
      {
        next: (response: any) => {
          if (pagination) {
            this.allExistingCompanies = this.allExistingCompanies.concat(response.items);
          } else {
            this.allExistingCompanies = response.items;
          }
          this.allExistingCompaniesPageNumer = response.page;
        },
        error: (error) => {
          this.isLoader = false;
        },
        complete: () => {
          this.isLoader = false;
        }
      }
    );
  }

  navigateToCompanyDetailPage(company: any) {
    this.formSubmitted = false;
    this.addCompanyForm.reset();
    let pageURL = 'pm/companies/' + company.id;
    this._router.navigate([pageURL]);
  }

  onChangeCompanyName(searchVal: any) {
    if (this.companyNameSearch) {
      if (searchVal.trim().length > 2 && !this.updateCompany) {
        this.showExistingCompanyDropdown = true;
        this.allExistingCompaniesPageNumer = 1;
        this.getAllExistingCompanyName(searchVal, false);
      } else {
        this.showExistingCompanyDropdown = false;
      }
    }
  }

  changeCompanyType(event: any) {
    this.companyType = event.name;
    if (event.name == 'Vendor') {
      this.isVendorTypeCompany = true;
    } else {
      this.isVendorTypeCompany = false;
    }
    if (this.updateCompany) {
      // open the Attention screen based on the condition
      if ((event.name == 'Vendor' || event.name == 'Tenant')) {
        this.openAttentionPopup();
      } else {
        this.focusOutInput(event, 'company_type');
      }
    }
  }

  setPhoneNumberFormat(phNumber: any): any {
    let value = phNumber.phone.toString();
    // value = value.replace('/\D?g', '')
    value = value.replace(/^(\d{3})(\d{3})(\d{4})(\d{0,5})/, '$1 $2 $3 $4');
    phNumber.phone = value;
    return `+${phNumber.country_code}${phNumber.phone}`;
  }

  setPhoneNumbersList(phoneNumberData: any): FormArray {
    //Make primary true value should come first (Start)
    // if (phoneNumberData.length > 0) {
    //   let getFirstPrimaryValue = phoneNumberData.find((phone: any) => {
    //     return phone.primary == true;
    //   });
    //   const getFirstPrimaryValueIndex = phoneNumberData.findIndex(phone => {
    //     return phone.primary == true;
    //   });
    //   phoneNumberData.splice(getFirstPrimaryValueIndex, 1);
    //   phoneNumberData.unshift(getFirstPrimaryValue);
    // }
    //Make primary true value should come first (End)

    const formArray = new FormArray([]);
    phoneNumberData.forEach((phNumber) => {
      formArray.push(this.formbuilder.group({
        phone: `+${phNumber.country_code}${phNumber.phone}`,
        // phone: this.setPhoneNumberFormat(phNumber),
        type: phNumber.type,
        extension: phNumber.extension,
        id: phNumber.id,
        primary: phNumber.primary
      }))
    });
    return formArray;
  }

  setEmailList(emailData: any): FormArray {
    //Make primary true value should come first (Start)
    // if (emailData.length > 0) {
    //   let getFirstPrimaryValue = emailData.find((email: any) => {
    //     return email.primary == true;
    //   });
    //   const getFirstPrimaryValueIndex = emailData.findIndex(email => {
    //     return email.primary == true;
    //   });
    //   emailData.splice(getFirstPrimaryValueIndex, 1);
    //   emailData.unshift(getFirstPrimaryValue);
    // }
    // //Make primary true value should come first (End)

    const formArray = new FormArray([]);
    emailData.forEach((email) => {
      formArray.push(this.formbuilder.group({
        email: email.email,
        type: email.type,
        id: email.id,
        primary: email.primary
      }))
    });
    return formArray;
  }

  setAddressList(addressData: any): FormArray {
    //Make primary true value should come first (Start)
    // if (addressData.length > 0) {
    //   let getFirstPrimaryValue = addressData.find((address: any) => {
    //     return address.primary == true;
    //   });
    //   const getFirstPrimaryValueIndex = addressData.findIndex(address => {
    //     return address.primary == true;
    //   });
    //   addressData.splice(getFirstPrimaryValueIndex, 1);
    //   addressData.unshift(getFirstPrimaryValue);
    // }
    // //Make primary true value should come first (End)

    const formArray = new FormArray([]);
    addressData.forEach((address) => {
      formArray.push(this.formbuilder.group({
        address: address.address,
        type: address.type,
        id: address.id,
        city: address.city,
        state: address.state,
        country: address.country,
        zipcode: address.zipcode,
        latitude: address.latitude,
        longitude: address.longitude,
        primary: address.primary
      }))
    });
    return formArray;
  }

  get phoneNoArray() {
    const control = this.addCompanyForm.get('phone_numbers') as FormArray;
    return control;
  }

  addPhoneNo() {
    return this.formbuilder.group({
      phone: [''],
      type: [this._companiesService.formDropDownTypes?.phone_types[0] ? this._companiesService.formDropDownTypes.phone_types[0].name : 'Corporate'],
      extension: [''],
      id: [''],
      primary: [false]
    });
  }

  addMorePhoneNo() {
    // let isExistIndex = this.phone_listForm.value.findIndex(obj => obj.phone == null);
    // if (isExistIndex == -1) {
    //   let checkValidPhoneNumber = this.phone_listForm?.value.filter(obj => obj.phone != null).map(obj => { let ss = obj.phone ? obj.phone.number.length : 0; return this.updateCompany ? (ss >= 10) : (ss == 10) ? true : false });
    //
    //   if (!checkValidPhoneNumber.includes(false)) {aa
    //     this.phoneNoArray.push(this.addPhoneNo());
    //   }
    // }
    let isExistIndex = this.phoneNoArray.value.findIndex(obj => !obj.phone);
    if (isExistIndex == -1) {
      if (this.phoneNoArray.status == 'VALID') {
        if (this.phoneNoArray.value.length === 1) {
          this.primaryPhoneNumber = this.phoneNoArray.value[0].phone;
          this.addCompanyForm.value.phone_numbers[0].primary = true;
        }
        this.phoneNoArray.push(this.addPhoneNo());
      }
    }
  }

  removePhoneNo(phoneIndex: any) {
    if (!this.updateCompany) {
      if (this.addCompanyForm.value.phone_numbers[phoneIndex]?.primary === true) {
        this.primaryPhoneNumber = ''
        this.primaryIndexPhone = 0;
        this.phoneNoArray.removeAt(phoneIndex);
        this.makePrimaryPhoneNo(true, 0, this.addCompanyForm.value.phone_numbers[0].phone);
      } else {
        this.phoneNoArray.removeAt(phoneIndex);
      }
      if (this.isAlreadyExistsNumber) {
        this.isAlreadyExistsNumber = false
      }
    } else {
      this.removeList.phoneNumber = [this.phoneNoArray.value[phoneIndex]];
      this.phoneNoArray.removeAt(phoneIndex);
      this.removePhoneNumerButtonClicked = true;
      this.makePrimaryPhoneNo(true, 0, this.addCompanyForm.value.phone_numbers[0].phone);
    }
  }

  phoneNumberTypeValueChange(event: any) {
    this.dropdownChangeValue = true;
    this.focusOutInput(event, 'phone');
  }

  checkValidEmail(emailItem: any): boolean {
    let pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (emailItem.value.email.trim() == '' || pattern.test(emailItem.value.email)) {
      return true;
    } else {
      return false;
    }
  }

  inValidPhoneNumbers() {
    if (this.phone_listForm.value?.length > 0) {
      let checkValidPhoneNumber = this.phone_listForm.controls.map(obj => {
        if (obj.controls.phone.status != 'VALID' && obj.controls.phone.touched) {
          return true;
        } else {
          return false;
        }
      });
      return checkValidPhoneNumber[0];
    }
    return false;
  }

  validateNumber(event: any) {
    // // define a pattern to match numbers with a maximum length of 5
    // const pattern = /^\d{0,5}$/;
    // if (!pattern.test(event.target.value)) {
    //   // truncate the value to 5 digits if it exceeds the limit
    //   event.target.value = event.target.value.slice(0, 5);
    // }
    let value = event.value;
    value = value.replace('/\D?g', '')
    value = value.replace('/^(\d{6})/$', '');
    event.value = value
    if (value) {
      event.value = event.value.slice(0, 6);
    }
  }

  validatePhoneNumberFormat(event: any) {
    // const pattern = /^\d{0,15}$/;
    // if (!pattern.test(event.target.value)) {
    //   event.target.value = event.target.value.slice(0, 15);
    // }
    let value = event.value;
    value = value.replace('/\D?g', '')
    value = value.replace(/^(\d{3})(\d{3})(\d{4})(\d{0,5})/, '$1 $2 $3 $4');
    event.value = value;
    if (value) {
      event.value = event.value.slice(0, 15);
    }
    this.phoneNumberText = event.value;
  }

  phoneNumberChange(event: any) {
    this.dropdownChangeValue = true;
  }

  phoneExtensionChange(event: any) {
    this.dropdownChangeValue = true;
  }

  makePrimaryPhoneNo(primary: any, index: number, number: any) {
    this.primaryIndexPhone = index;
    if (primary) {
      this.addCompanyForm.value.phone_numbers.map((phone: any) => {
        return phone.primary = false
      });
      this.addCompanyForm.value.phone_numbers[index].primary = primary;
    } else {
      this.addCompanyForm.value.phone_numbers.map((phone: any) => {
        return phone.primary = false
      });
      this.addCompanyForm.value.phone_numbers[0].primary = true;
    }
    if (this.addCompanyForm.value.phone_numbers[index].primary === true) {
      this.primaryPhoneNumber = number;
    }
    // if (this.primaryIndexPhone) {
    //   this.addCompanyForm.value.phone_numbers.map((phone, i) => {
    //     if (i === this.primaryIndexPhone) {
    //       phone.primary = true;
    //     } else {
    //       phone.primary = false;
    //     }
    //     return phone;
    //   });
    // }
    if (this.updateCompany) {
      this.dropdownChangeValue = true;
      this.focusOutInput(event, 'phone');
    }
  }

  validatePhoneNumberIsExist(control: FormControl, index: number) {
    if (control.value && this.addCompanyForm?.value?.phone_numbers.length > 1) {
      let values = this.addCompanyForm?.value?.phone_numbers.map((value: any) => value.phone)
      values.splice(index, 1)
      let array: any = []
      for (let number of values) {
        array.push(number.replace(/"/g, ''));
      }
      this.isAlreadyExistsNumber = array.includes(control.value)
    } else if (!control.value) {
      this.isAlreadyExistsNumber = false
    }
    if (this.isAlreadyExistsNumber) {
      control.setErrors({
        customError: true
      })
    }
  }

  // countryCodeChanged(event: any, phoneControl: any) {
  //   // while changing the country code we need to update the method.
  //   const getPhoneIndex = this.companyData?.phones.findIndex((phone: any) => {
  //     return phone.id == phoneControl.controls.id.value;
  //   });
  //   if (event && event.dialCode != this.companyData?.phones[getPhoneIndex]?.country_code) {
  //     this.dropdownChangeValue = true;
  //   }
  // }

  // *Email formCode starts here* //
  addEmailAddress() {
    return this.formbuilder.group({
      email: ['', [Validators.email, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,5}$")]],
      type: [this._companiesService.formDropDownTypes?.email_types[0] ? this._companiesService.formDropDownTypes.email_types[0].name : 'Corporate'],
      id: [''],
      primary: [false]
    });
  }

  addMoreEmailAddress() {
    let isExistIndex = this.emailArray.value.findIndex(obj => !obj.email);
    if (isExistIndex == -1) {
      if (this.emailArray.status == 'VALID') {
        if (this.emailArray.value.length === 1) {
          this.primaryEmail = this.emailArray.value[0].email;
          this.addCompanyForm.value.email_lists[0].primary = true;
        }
        this.emailArray.push(this.addEmailAddress());
      }
    }
  }

  removeEmailAddress(emailIndex: any) {
    if (!this.updateCompany) {
      if (this.addCompanyForm.value.email_lists[emailIndex]?.primary === true) {
        this.primaryEmail = ''
        this.primaryIndexEmail = 0;
        this.emailArray.removeAt(emailIndex);
        this.makePrimaryEmail(true, 0, this.addCompanyForm.value.email_lists[0].email);
      } else {
        this.emailArray.removeAt(emailIndex);
      }
      if (this.isAlreadyExistsEmail) {
        this.isAlreadyExistsEmail = false
      }
    } else {
      this.removeList.email = [this.emailArray.value[emailIndex]];
      this.emailArray.removeAt(emailIndex);
      this.makePrimaryEmail(true, 0, this.addCompanyForm.value.email_lists[0].email);
    }
  }

  updateEmails(event: any, type: any, index: any) {
    if (this.addCompanyForm.value.email_lists[index].email == '' && this.addCompanyForm.value.email_lists.length > 1) {
      this.removeEmailAddress(index);
    } else {
      this.focusOutInput(event, 'email');
    }
  }

  updateAddressDetails(event: any, type: any, index: any) {
    if (this.addCompanyForm.value.address_lists[index].address == '' || this.addCompanyForm.value.address_lists[index].address == undefined || this.addCompanyForm.value.address_lists[index].address == null) {
      this.removeAddress(index);
    }
  }

  updatePhoneNumberDetails(event: any, type: any, index: any, phoneDetails: any) {
    // if (this.phoneNumberText == '') {
    //   this.removePhoneNo(index);
    // } else {
    //   this.focusOutInput(event, type);
    // }
    if (phoneDetails.status == 'VALID' && phoneDetails.value?.phone) {
      this.dropdownChangeValue = true;
      this.focusOutInput(event, type);
    }
  }

  emailTypeValueChange(event: any) {
    this.focusOutInput(event, 'email');
  }

  inValidEmails() {
    if (this.emailArray.value?.length > 0) {
      if (this.emailArray.status == 'INVALID') {
        return true;
      }
      return false;
    }
    return false;
  }

  get emailArray() {
    const control = this.addCompanyForm.get('email_lists') as FormArray;
    return control;
  }

  makePrimaryEmail(primary: any, index: number, email: any) {
    this.primaryIndexEmail = index;
    if (primary) {
      this.addCompanyForm.value.email_lists.map((email: any) => {
        return email.primary = false;
      });
      this.addCompanyForm.value.email_lists[index].primary = primary;
    } else {
      this.addCompanyForm.value.email_lists.map((email: any) => {
        return email.primary = false;
      });
      this.addCompanyForm.value.email_lists[0].primary = true;
    }
    if (this.addCompanyForm.value.email_lists[index].primary === true) {
      this.primaryEmail = email;
    }
    // if (this.primaryIndexEmail) {
    //   this.addCompanyForm.value.email_lists.map((email, i) => {
    //     if (i === this.primaryIndexEmail) {
    //       email.primary = true;
    //     } else {
    //       email.primary = false;
    //     }
    //     return email;
    //   });
    // }
    if (this.updateCompany) {
      this.dropdownChangeValue = true;
      this.focusOutInput(event, 'email');
    }
  }

  isExistEmail(control: FormControl, index: number) {
    if (control.value && this.addCompanyForm?.value?.email_lists.length > 1) {
      let values = this.addCompanyForm?.value?.email_lists.map((value: any) => value.email)
      values.splice(index, 1)
      this.isAlreadyExistsEmail = values.includes(control.value)
    } else if (!control.value) {
      this.isAlreadyExistsEmail = false
    }
    if (this.isAlreadyExistsEmail) {
      control.setErrors({
        customError: true
      })
    }
  }

  // *Address formCode starts here* //
  addAddress() {
    return this.formbuilder.group({
      address: [''],
      type: [this._companiesService.formDropDownTypes?.address_types[0] ? this._companiesService.formDropDownTypes.address_types[0].name : 'Corporate'],
      id: [''],
      city: [''],
      state: [''],
      country: [''],
      zipcode: [''],
      latitude: [''],
      longitude: [''],
      primary: [false]
    });
  }

  addMoreAddress() {
    let isExistIndex = this.addressArray.value.findIndex(obj => !obj.country);
    if (isExistIndex == -1) {
      if (this.addressArray.value.length === 1) {
        this.primaryAddress = this.addressArray.value[0].address;
        this.addCompanyForm.value.address_lists[0].primary = true;
      }
      this.addressArray.push(this.addAddress());
    }
  }

  removeAddress(addressIndex: any) {
    if (!this.updateCompany) {
      if (this.addCompanyForm.value.address_lists[addressIndex]?.primary === true) {
        this.primaryAddress = ''
        this.primaryIndexAddress = 0;
        this.addressArray.removeAt(addressIndex);
        this.makePrimaryAddress(true, 0, this.addCompanyForm.value.address_lists[0].address);
      } else {
        this.addressArray.removeAt(addressIndex);
      }
    } else {
      this.removeList.address = [this.addressArray.value[addressIndex]];
      this.addressArray.removeAt(addressIndex);
      this.makePrimaryAddress(true, 0, this.addCompanyForm.value.address_lists[0].address);
    }
  }

  addressTypeValueChange(event: any, indexval?: any) {
    if (this.updateCompany) {
      this.focusOutInput(event, 'address');
    }
  }

  makePrimaryAddress(primary: any, index: number, address: any) {
    this.primaryIndexAddress = index;
    if (primary) {
      this.addCompanyForm.value.address_lists.map((address: any) => {
        return address.primary = false
      });
      this.addCompanyForm.value.address_lists[index].primary = primary;
    } else {
      this.addCompanyForm.value.address_lists.map((phone: any) => {
        return phone.primary = false
      });
      this.addCompanyForm.value.address_lists[0].primary = true;
    }
    if (this.addCompanyForm.value.address_lists[index].primary === true) {
      this.primaryAddress = address;
    }
    if (this.updateCompany) {
      this.dropdownChangeValue = true;
      this.focusOutInput(event, 'address');
    }
  }

  selectedTags(selectedTagItems: any) {
    if (this.updateCompany) {
      selectedTagItems = selectedTagItems.map((tag: any) => {
        if (tag?.name) {
          return tag.name
        } else {
          return tag;
        }
      });
      this.selectedTagsList = selectedTagItems;
      this.focusOutInput(selectedTagItems, 'tags');
    } else {
      this.selectedTagsList = selectedTagItems;
    }
  }

  get addressArray() {
    const control = this.addCompanyForm.get('address_lists') as FormArray;
    return control;
  }

  onSubmit() {
    this.formSubmitted = true;
    if (this.inValidPhoneNumbers() || this.inValidEmails() || this.addCompanyForm.value?.company_name?.trim() == '') {
      return true;
    }
    if (this.addCompanyForm.valid) {
      if (this.primaryIndexPhone == 0) {
        this.addCompanyForm.value.phone_numbers.map((phone, i) => {
          if (i === this.primaryIndexPhone) {
            phone.primary = true;
          } else {
            phone.primary = false;
          }
          return phone;
        });
      }
      if (this.primaryIndexEmail == 0) {
        this.addCompanyForm.value.email_lists.map((email, i) => {
          if (i === this.primaryIndexEmail) {
            email.primary = true;
          } else {
            email.primary = false;
          }
          return email;
        });
      }
      let phone_numbers = this.addCompanyForm.value.phone_numbers.filter((obj: any) => obj.phone).map((obj: any) => {
        return {
          phone: obj.phone,
          type: (obj.type?.name ? obj.type.name : obj.type),
          extension: (obj.extension ? obj.extension.slice(0, 6) : null),
          primary: obj.primary
        }
      });
      let address_lists = this.addCompanyForm.value.address_lists.filter((obj: any) => obj.country).map((obj: any) => {
        return {
          address: obj.address,
          type: (obj.type?.name ? obj.type.name : obj.type),
          city: ((obj.city && obj.city.trim() != '') ? obj.city : null),
          state: ((obj.state && obj.state.trim() != '') ? obj.state : null),
          country: ((obj.country && obj.country.trim() != '') ? obj.country : null),
          zipcode: ((obj.zipcode && obj.zipcode.trim() != '') ? obj.zipcode : null),
          latitude: (obj?.latitude ? obj.latitude : null),
          longitude: (obj?.longitude ? obj.longitude : null),
          primary: obj.primary,
        }
      });
      if (address_lists.length == 1) {
        address_lists[0].primary = true;
      }
      let email_lists = this.addCompanyForm.value.email_lists.filter((obj: any) => obj.email).map((obj: any) => {
        return {
          email: obj.email.toLowerCase(),
          type: (obj.type?.name ? obj.type.name : obj.type),
          primary: obj.primary
        }
      })

      if (this.showSelectContacts && !this.selectContactIsSelected) {
        if (this.primaryContactName === '') {
          this.showSelectContactError = true;
          return false;
        } else {
          this.showSelectContactError = false;
        }
      } else {
        this.showSelectContactError = false;
      }

      let postBody = {
        "name": this.addCompanyForm.value.company_name?.trim(),
        "type": this.addCompanyForm.value.company_type ? this.addCompanyForm.value.company_type.name : '',
        "website": this.addCompanyForm.value.website ? this.addCompanyForm.value.website : null,
        "phones": phone_numbers,
        "emails": email_lists,
        "addresses": address_lists,
        "tags": this.selectedTagsList.map((obj, index) => {
          return obj.name
        })
      };
      this.isLoader = true;
      let addNewCompanyURL = environment.fastAPI_Actual_URL + '/companies/';
      this._dialogService.doPOST(addNewCompanyURL, postBody).subscribe(
        {
          next: (response: any) => {
            // response = {
            //   "id": response.id,
            //   "name": response.name,
            //   "address": response.addresses.length > 0 ? response.addresses[0].address : '',
            //   "phone": response.phones.length > 0 ? response.phones[0].phone : '',
            //   "email": response.emails.length > 0 ? response.emails[0].email : '',
            //   "website": response.website,
            //   "created_at": new Date(),
            //   "type": response.type
            // };
            if (this.showSelectContacts) {
              response.selectedContactId = this.seletedContactId;
            }
            this.newCompanyData = response;
            // this._companiesService.setCompaniesData(response);
            this._companiesService.loadCompanyDataAfterNewCompanyCreate.next(true);
            this._utilService.showSuccess('', 'Company Created Successfully.');
          },
          error: (error) => {
            this.isLoader = false;
            this._utilService.showErrorMessage(error.error);
          },
          complete: () => {
            this.isLoader = false;
            this.onCancel();
          }
        }
      );
    }
  }

  createNewContact() {
    if (!this.formControl.company_name.value || this.formControl.company_name.value.trim() === '') {
      this._utilService.showError('', 'Company Name cannot be empty');
      return;
    }
    this.primaryContactData = [];
    let popupData = {
      title: 'Create a New Contact',
      component: CreatContactSharedComponent,
      containerClass: ['contact-modal'],
      data: {
        selectedCompanyDetails: 'selectedCompanyDetails',
        viewModule: 'company',
        isbuttonText: true,
        companyName: this.addCompanyForm.value.company_name,
        calledFrom: 'assignVendor',
      }
    };

    this._dialogService.openModal(popupData).subscribe(result => {
      if (result === 'Close') {
        this.primaryContactData = [];
        this.primaryContactName = '';
      } else {
        this.fetchContactsData();
        this.getAllContactsDropdowns();
      }

    });
    this.clickHereError = true;
  }

  fetchContactsData() {
    this._contactsService.fetchContactsData().subscribe((res: any) => {
      if (res !== null) {
        this.primaryContactData = res;
        this.primaryContactName = res?.first_name + ' ' + res?.last_name;
      }
    })
  }


  selectedContact(event: any) {
    if (this.showSelectContacts) {
      this.selectContactIsSelected = true;
      this.showSelectContactError = false;
      this.seletedContactId = event.id;
    }
    this._companiesService.contactsId.next(event.id);
  }

  getAllContactsDropdowns(pagination?: boolean) {
    let requestUrl = environment.fastAPI_Actual_URL + '/' + `contacts/?columns=['first_name','last_name','type']&page=` + this.allContactsListPageNumber + '&size=' + this.allContactsListSize + '&search=' + this.companiesSearchInput;
    this._baseService.doGET(requestUrl).subscribe(
      {
        next: (response: any) => {
          response.items.map((obj) => {
            obj.name = obj.first_name + ' ' + obj.last_name;
          });
          if (pagination) {
            this.allContactsList = this.allContactsList.concat(response.items);
          } else {
            this.allContactsList = response.items;
          }
          if (this.primaryContactName !== '') {
            this.contactValue = {
              "name": this.primaryContactName,
              "id": this.primaryContactData?.id
            };
            this.seletedContactId = 'New Contact';
            this.showSelectContactError = false;
          } else {
            this.contactValue = null;
          }
          this.allContactsListTotal = response.total;
          this.allContactsListSize = response.size;
          this.allContactsListPageCount = Math.round(this.allContactsListTotal / this.allContactsListSize)
        },
        error: (error) => {
          this.isLoader = false;
          this._utilService.showErrorMessage(error);
        },
        complete: () => {
          this.isLoader = false;
        }
      }
    );
  }

  contactsDropdownSearch(event: any) {
    this.debounceSubject.next(event);
  }

  contactsDropdownsScroll() {
    if (this.allContactsListPageNumber < this.allContactsListPageCount) {
      this.allContactsListPageNumber++;
      this.getAllContactsDropdowns(true)
    }
  }

  onDismiss() {
    this.sidebarService.toggleRightSidebar = !this.sidebarService.toggleRightSidebar;
    this.sidebarService.setFormConext('Create Company');
  }

  onCancel() {
    if (this.companyModalPopup === false) {
      this.sidebarService.toggleRightSidebar = !this.sidebarService.toggleRightSidebar;
      this.sidebarService.setFormConext('Create Contact');
      setTimeout(() => {
        this.formSubmitted = false;
        this.addCompanyForm.reset();
        this.sidebarService.setFormConext('');
        this.initializeForm();
      }, 100);
      // this.sidebarService.toggleRightSidebar = false;
      // this.sidebarService.setFormConext('Create Company');
      // this.addCompanyForm.reset();
      // this.addCompanyForm.controls['company_name'].setErrors(null);
      // this.formSubmitted = false;
      // this.addressArray.clear();
      // this.emailArray.clear();
      // this.phoneNoArray.clear();
      // this.phone_listForm.controls = [];
      // this.phone_listForm.controls.push(this.addPhoneNo());
      // this.address_listsform.controls = [];
      // this.address_listsform.controls.push(this.addAddress());
      // this.email_listsform.controls = [];
      // this.email_listsform.controls.push(this.addEmailAddress());
    } else {
      this._dialogRef.close(this.newCompanyData);
      this.formSubmitted = false;
      this.addCompanyForm.reset();
      this.companyModalPopup = false;
      setTimeout(() => {
        this.formSubmitted = false;
        this.addCompanyForm.reset();
        // this.sidebarService.setFormConext('');
        // this.initializeForm();
      }, 100);
      this.companyModalPopup = false;
    }
  }

  openAttentionPopup() {
    let popupData = {
      title: 'Edit Company',
      template: this.confirmUserExist,
      confirmText: 'Yes',
      cancelText: 'Cancel',
      buttons: true,
    };
    this._dialogService.confirmDialog(popupData).subscribe(result => {
      if (result) {
        this.focusOutInput(result, 'company_type');
      } else {
        this.addCompanyForm.patchValue({
          company_type: this.companyData.type,
        });
      }
    });
  }

  focusOutInput(event: any, type: string) {
    if (this.updateCompany && type) {
      let postBody: any = {};
      if (type == 'company_name') {
        if (this.addCompanyForm.controls['company_name'].invalid || event.target?.value?.trim() == '') {
          return null;
        } else {
          if (event.target.value == this.companyData.name) {
            return null;
          } else {
            postBody.name = event.target.value;
          }
        }
      } else if (type == 'company_type') {
        postBody.type = this.addCompanyForm.controls['company_type'].value.name;
      } else if (type == 'website') {
        if (this.addCompanyForm.get('website').status == 'INVALID') {
          return;
        }
        if (event.target.value == this.companyData.website) {
          return null;
        } else {
          postBody.website = event.target.value;
        }
      } else if (type == 'phone') {
        const validPhoneNumbers = this.phone_listForm.controls.every((phoneList: any) => {
          return (phoneList.controls.phone.status === 'VALID' && phoneList.controls.phone.value != '' && phoneList.controls.phone.value != undefined && phoneList.controls.phone.value != null);
        });
        if (this.dropdownChangeValue && validPhoneNumbers) {
          // // Check all the phone numbers are valid
          // const isInvalidPhoneNumbers = this.phone_listForm.controls.some((phoneList: any) => {
          //   return (phoneList.controls.phone.status === 'INVALID' && phoneList.controls.phone.touched && phoneList.controls.phone.value != '');
          // });
          // if (isInvalidPhoneNumbers) {
          //   return;
          // }
          if (this.addCompanyForm.value.phone_numbers.length === 1) {
            this.addCompanyForm.value.phone_numbers.map((phone: any) => {
              return phone.primary = true;
            });
          }
          let phone_numbers = this.addCompanyForm.value.phone_numbers.filter((obj: any) => obj.phone).map((obj: any) => {
            return {
              phone: obj.phone,
              type: (obj.type?.name ? obj.type.name : obj.type),
              id: (obj.id ? obj.id : null),
              extension: (obj.extension ? obj.extension.toString().slice(0, 6) : null),
              primary: obj.primary
            }
          });
          postBody.phones = phone_numbers;
          postBody.active = true;
          // If we remove the valid phone number from the array we need to send the payload
          if (this.removeList.phoneNumber.length > 0) {
            postBody.remove_phones_list = this.removeList.phoneNumber.map((phone: any) => {
              return phone.id;
            });
          } else {
            delete postBody['remove_phones_list'];
          }
        }
        else if (this.dropdownChangeValue && !validPhoneNumbers) {
          if (this.removePhoneNumerButtonClicked) {
            let phone_numbers = this.addCompanyForm.value.phone_numbers.filter((obj: any) => obj.phone).map((obj: any) => {
              return {
                phone: obj.phone,
                type: (obj.type?.name ? obj.type.name : obj.type),
                id: (obj.id ? obj.id : null),
                extension: (obj.extension ? obj.extension.toString().slice(0, 6) : null),
                primary: obj.primary
              }
            });
            postBody.phones = phone_numbers;
            postBody.active = true;
            // If we remove the valid phone number from the array we need to send the payload
            if (this.removeList.phoneNumber.length > 0) {
              postBody.remove_phones_list = this.removeList.phoneNumber.map((phone: any) => {
                return phone.id;
              });
            } else {
              delete postBody['remove_phones_list'];
            }
          } else {
            this.fetchCompanyDetails.emit();
            return null;
          }
        }
        else {
          this.fetchCompanyDetails.emit();
          return null;
        }
      } else if (type == 'address') {
        let address_lists = this.addCompanyForm.value.address_lists.filter((obj: any) => obj.address).map((obj: any) => {
          return {
            address: obj.address,
            id: (obj.id ? obj.id : null),
            type: (obj.type?.name ? obj.type.name : obj.type),
            city: ((obj.city && obj.city.trim() != '') ? obj.city : null),
            state: ((obj.state && obj.state.trim() != '') ? obj.state : null),
            country: ((obj.country && obj.country.trim() != '') ? obj.country : null),
            zipcode: ((obj.zipcode && obj.zipcode.trim() != '') ? obj.zipcode : null),
            latitude: (obj?.latitude ? obj.latitude : null),
            longitude: (obj?.longitude ? obj.longitude : null),
            primary: obj.primary,
          }
        });
        postBody.addresses = address_lists;
        postBody.active = true;
        // If we remove the valid address from the array we need to send the payload
        if (this.removeList.address.length > 0 && this.removeList.address[0].address != '') {
          postBody.remove_addresses_list = this.removeList.address.map((address: any) => {
            return address.id;
          });
        } else {
          delete postBody['remove_addresses_list'];
        }
      } else if (type == 'email') {
        const isAllEmailAddressValid = this.email_listsform.controls.some((emailList: any) => {
          return (emailList.controls.email.value == '');
        });
        if (this.inValidEmails() || isAllEmailAddressValid) {
          this.fetchCompanyDetails.emit();
          return null;
        }
        //In the update view If there is only one email value then that email should be primary
        if (this.addCompanyForm.value.email_lists.length === 1) {
          this.addCompanyForm.value.email_lists.map((email: any) => {
            return email.primary = true;
          });
        }
        let email_lists = this.addCompanyForm.value.email_lists.filter((obj: any) => obj.email).map((obj: any) => {
          return {
            email: obj.email.toLowerCase(),
            type: (obj.type?.name ? obj.type.name : obj.type),
            id: (obj.id ? obj.id : null),
            primary: obj.primary
          }
        });
        postBody.emails = email_lists;
        postBody.active = true;
        let pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const isEmailAddressValid = email_lists.every((emailList: any) => {
          this.newEmail = emailList.email;
        });
        if (!pattern.test(this.newEmail)) {
          return null;
        }
        // If we remove the valid email from the array we need to send the payload
        if (this.removeList.email.length > 0) {
          postBody.remove_emails_list = this.removeList.email.map((email: any) => {
            return email.id;
          });
        } else {
          delete postBody['remove_emails_list'];
        }
      } else if (type == 'is_compliant') {
        postBody.is_compliant = event;
        this.isComplaintVendor = event;
      } else if (type == 'tags') {
        postBody.tags = this.selectedTagsList;
      }
      this.isLoader = true;
      this._Config.isLoader = true;
      let addNewCompanyURL = 'companies/' + this._companiesService.selectedActiveTabId + '/';
      this._companiesService.doPATCH(addNewCompanyURL, postBody).subscribe(
        {
          next: (response: any) => {
            this.removeList.email = [];
            this.removeList.address = [];
            this.removeList.phoneNumber = [];
            this.removePhoneNumerButtonClicked = false;
            this._utilService.showSuccess('', 'Company Updated Successfully.');
            this.companyData = response;
            if (type == 'company_name') {
              this._companiesService.selectedCompanyDetails = response;
              this._companiesService.isCompanyNameUpdate.next(true);
            } else if (type == 'company_type') {
              this._companiesService.isCompanyTypeUpdate.next(true);
            }
            this.patchUpdatePhoneNumber(this.companyData.phones);
            this.patchUpdateEmail(this.companyData.emails);
            this.patchUpdateAddress(this.companyData.addresses);
            // if (this.email_listsform.controls.length > 0) {
            //   this.email_listsform.controls[this.email_listsform.controls.length - 1].controls.id.value = this.companyData.emails[this.companyData.emails.length - 1].id;
            //   this.email_listsform.controls[this.email_listsform.controls.length - 1].value.id = this.companyData.emails[this.companyData.emails.length - 1].id;
            // }
            // if (this.address_listsform.controls.length > 0) {
            //   this.address_listsform.controls[this.address_listsform.controls.length - 1].controls.id.value = this.companyData.addresses[this.companyData.addresses.length - 1].id;
            //   this.address_listsform.controls[this.address_listsform.controls.length - 1].value.id = this.companyData.addresses[this.companyData.addresses.length - 1].id;
            // }
            // if (this.phone_listForm.controls.length > 0) {
            //   this.phone_listForm.controls[this.phone_listForm.controls.length - 1].controls.id.value = this.companyData.phones[this.companyData.phones.length - 1].id;
            //   this.phone_listForm.controls[this.phone_listForm.controls.length - 1].value.id = this.companyData.phones[this.companyData.phones.length - 1].id;
            // }
          },
          error: (error) => {
            this.isLoader = false;
            this._Config.isLoader = false;
            this._utilService.showErrorMessage(error.error);
            this.dropdownChangeValue = false;
          },
          complete: () => {
            this.isLoader = false;
            this._Config.isLoader = false;
            this.dropdownChangeValue = false;
          }
        }
      );
    }
  }

  focusOutInput_Complient(event: any, type: string) {
    if (this.can_create_compliant && type) {
      let postBody: any = {};
      if (type == 'company_name') {
        if (this.addCompanyForm.controls['company_name'].invalid || event.target?.value?.trim() == '') {
          return null;
        } else {
          if (event.target.value == this.companyData.name) {
            return null;
          } else {
            postBody.name = event.target.value;
          }
        }
      } else if (type == 'company_type') {
        postBody.type = this.addCompanyForm.controls['company_type'].value.name;
      } else if (type == 'website') {
        if (this.addCompanyForm.get('website').status == 'INVALID') {
          return;
        }
        if (event.target.value == this.companyData.website) {
          return null;
        } else {
          postBody.website = event.target.value;
        }
      } else if (type == 'phone') {
        const validPhoneNumbers = this.phone_listForm.controls.every((phoneList: any) => {
          return (phoneList.controls.phone.status === 'VALID' && phoneList.controls.phone.value != '' && phoneList.controls.phone.value != undefined && phoneList.controls.phone.value != null);
        });
        if (this.dropdownChangeValue && validPhoneNumbers) {
          // // Check all the phone numbers are valid
          // const isInvalidPhoneNumbers = this.phone_listForm.controls.some((phoneList: any) => {
          //   return (phoneList.controls.phone.status === 'INVALID' && phoneList.controls.phone.touched && phoneList.controls.phone.value != '');
          // });
          // if (isInvalidPhoneNumbers) {
          //   return;
          // }
          if (this.addCompanyForm.value.phone_numbers.length === 1) {
            this.addCompanyForm.value.phone_numbers.map((phone: any) => {
              return phone.primary = true;
            });
          }
          let phone_numbers = this.addCompanyForm.value.phone_numbers.filter((obj: any) => obj.phone).map((obj: any) => {
            return {
              phone: obj.phone,
              type: (obj.type?.name ? obj.type.name : obj.type),
              id: (obj.id ? obj.id : null),
              extension: (obj.extension ? obj.extension.toString().slice(0, 6) : null),
              primary: obj.primary
            }
          });
          postBody.phones = phone_numbers;
          postBody.active = true;
          // If we remove the valid phone number from the array we need to send the payload
          if (this.removeList.phoneNumber.length > 0) {
            postBody.remove_phones_list = this.removeList.phoneNumber.map((phone: any) => {
              return phone.id;
            });
          } else {
            delete postBody['remove_phones_list'];
          }
        }
        else if (this.dropdownChangeValue && !validPhoneNumbers) {
          if (this.removePhoneNumerButtonClicked) {
            let phone_numbers = this.addCompanyForm.value.phone_numbers.filter((obj: any) => obj.phone).map((obj: any) => {
              return {
                phone: obj.phone,
                type: (obj.type?.name ? obj.type.name : obj.type),
                id: (obj.id ? obj.id : null),
                extension: (obj.extension ? obj.extension.toString().slice(0, 6) : null),
                primary: obj.primary
              }
            });
            postBody.phones = phone_numbers;
            postBody.active = true;
            // If we remove the valid phone number from the array we need to send the payload
            if (this.removeList.phoneNumber.length > 0) {
              postBody.remove_phones_list = this.removeList.phoneNumber.map((phone: any) => {
                return phone.id;
              });
            } else {
              delete postBody['remove_phones_list'];
            }
          } else {
            this.fetchCompanyDetails.emit();
            return null;
          }
        }
        else {
          this.fetchCompanyDetails.emit();
          return null;
        }
      } else if (type == 'address') {
        let address_lists = this.addCompanyForm.value.address_lists.filter((obj: any) => obj.address).map((obj: any) => {
          return {
            address: obj.address,
            id: (obj.id ? obj.id : null),
            type: (obj.type?.name ? obj.type.name : obj.type),
            city: ((obj.city && obj.city.trim() != '') ? obj.city : null),
            state: ((obj.state && obj.state.trim() != '') ? obj.state : null),
            country: ((obj.country && obj.country.trim() != '') ? obj.country : null),
            zipcode: ((obj.zipcode && obj.zipcode.trim() != '') ? obj.zipcode : null),
            latitude: (obj?.latitude ? obj.latitude : null),
            longitude: (obj?.longitude ? obj.longitude : null),
            primary: obj.primary,
          }
        });
        postBody.addresses = address_lists;
        postBody.active = true;
        // If we remove the valid address from the array we need to send the payload
        if (this.removeList.address.length > 0 && this.removeList.address[0].address != '') {
          postBody.remove_addresses_list = this.removeList.address.map((address: any) => {
            return address.id;
          });
        } else {
          delete postBody['remove_addresses_list'];
        }
      } else if (type == 'email') {
        const isAllEmailAddressValid = this.email_listsform.controls.some((emailList: any) => {
          return (emailList.controls.email.value == '');
        });
        if (this.inValidEmails() || isAllEmailAddressValid) {
          this.fetchCompanyDetails.emit();
          return null;
        }
        //In the update view If there is only one email value then that email should be primary
        if (this.addCompanyForm.value.email_lists.length === 1) {
          this.addCompanyForm.value.email_lists.map((email: any) => {
            return email.primary = true;
          });
        }
        let email_lists = this.addCompanyForm.value.email_lists.filter((obj: any) => obj.email).map((obj: any) => {
          return {
            email: obj.email.toLowerCase(),
            type: (obj.type?.name ? obj.type.name : obj.type),
            id: (obj.id ? obj.id : null),
            primary: obj.primary
          }
        });
        postBody.emails = email_lists;
        postBody.active = true;
        let pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const isEmailAddressValid = email_lists.every((emailList: any) => {
          this.newEmail = emailList.email;
        });
        if (!pattern.test(this.newEmail)) {
          return null;
        }
        // If we remove the valid email from the array we need to send the payload
        if (this.removeList.email.length > 0) {
          postBody.remove_emails_list = this.removeList.email.map((email: any) => {
            return email.id;
          });
        } else {
          delete postBody['remove_emails_list'];
        }
      } else if (type == 'is_compliant') {
        postBody.is_compliant = event;
        this.isComplaintVendor = event;
      } else if (type == 'tags') {
        postBody.tags = this.selectedTagsList;
      }
      this.isLoader = true;
      this._Config.isLoader = true;
      let addNewCompanyURL = 'companies/' + this._companiesService.selectedActiveTabId + '/';
      this._companiesService.doPATCH(addNewCompanyURL, postBody).subscribe(
        {
          next: (response: any) => {
            this.removeList.email = [];
            this.removeList.address = [];
            this.removeList.phoneNumber = [];
            this.removePhoneNumerButtonClicked = false;
            this._utilService.showSuccess('', 'Company Updated Successfully.');
            this.companyData = response;
            if (type == 'company_name') {
              this._companiesService.selectedCompanyDetails = response;
              this._companiesService.isCompanyNameUpdate.next(true);
            } else if (type == 'company_type') {
              this._companiesService.isCompanyTypeUpdate.next(true);
            }
            this.patchUpdatePhoneNumber(this.companyData.phones);
            this.patchUpdateEmail(this.companyData.emails);
            this.patchUpdateAddress(this.companyData.addresses);
            // if (this.email_listsform.controls.length > 0) {
            //   this.email_listsform.controls[this.email_listsform.controls.length - 1].controls.id.value = this.companyData.emails[this.companyData.emails.length - 1].id;
            //   this.email_listsform.controls[this.email_listsform.controls.length - 1].value.id = this.companyData.emails[this.companyData.emails.length - 1].id;
            // }
            // if (this.address_listsform.controls.length > 0) {
            //   this.address_listsform.controls[this.address_listsform.controls.length - 1].controls.id.value = this.companyData.addresses[this.companyData.addresses.length - 1].id;
            //   this.address_listsform.controls[this.address_listsform.controls.length - 1].value.id = this.companyData.addresses[this.companyData.addresses.length - 1].id;
            // }
            // if (this.phone_listForm.controls.length > 0) {
            //   this.phone_listForm.controls[this.phone_listForm.controls.length - 1].controls.id.value = this.companyData.phones[this.companyData.phones.length - 1].id;
            //   this.phone_listForm.controls[this.phone_listForm.controls.length - 1].value.id = this.companyData.phones[this.companyData.phones.length - 1].id;
            // }
          },
          error: (error) => {
            this.isLoader = false;
            this._Config.isLoader = false;
            this._utilService.showErrorMessage(error.error);
            this.dropdownChangeValue = false;
          },
          complete: () => {
            this.isLoader = false;
            this._Config.isLoader = false;
            this.dropdownChangeValue = false;
          }
        }
      );
    }
  }

  patchUpdatePhoneNumber(data) {
    let mappedData = data.map((phone) => {
      return {
        id: phone.id,
        primary: phone.primary,
        phone: `+${phone.country_code}${phone.phone}`,
        type: phone.type,
        extension: phone.extension
      };
    });
    this.addCompanyForm.patchValue({
      phone_numbers: mappedData
    });
  }

  patchUpdateEmail(data) {
    let mappedData = data.map((email) => {
      return {
        id: email.id,
        primary: email.primary,
        email: email.email,
        type: email.type,
      };
    });
    this.addCompanyForm.patchValue({
      email_lists: mappedData
    });
  }

  patchUpdateAddress(data) {
    let mappedData = data.map((address) => {
      return {
        id: address.id,
        primary: address.primary,
        address: address.address,
        state: address.state,
        country: address.country,
        zipcode: address.zipcode,
        type: address.type,
        city: address.city,
      };
    });
    this.addCompanyForm.patchValue({
      address_lists: mappedData
    });
  }

  /*** Get Selected View Tables */

  changeAddress(event: any, index: any): void {
    let address = {
      city: '',
      state: '',
      country: '',
      zipcode: '',
      latitude: null,
      longitude: null
    };
    if (event?.location?.cityName) {
      address.city = event.location.cityName;
    }
    if (event?.location?.stateCode) {
      address.state = event.location.stateCode;
    }
    if (event?.location?.countryName) {
      address.country = event.location.countryName;
    }
    if (event?.location?.postal_code) {
      address.zipcode = event.location.postal_code;
    }
    if (event?.location?.coordinates?.latitude) {
      address.latitude = event.location.coordinates.latitude;
    }
    if (event?.location?.coordinates?.longitude) {
      address.longitude = event.location.coordinates.longitude;
    }
    // Fetching only city and states to send payload for backend
    //Changing the address field based on the google address format
    if (this.addCompanyForm.value.address_lists.length > 0) {
      let addressValue = '';
      if (event.location?.name !== null) {
        addressValue = event?.formatAddress?.includes(event.location?.name) ? event.formatAddress : event.location?.name + ',' + event.formatAddress;
      } else {
        addressValue = event?.formatAddress;
      }
      this.addCompanyForm.value.address_lists[index].address = addressValue;
      this.address_listsform.controls[index].controls.address.value = addressValue;
      // City
      this.addCompanyForm.value.address_lists[index].city = address.city;
      this.address_listsform.controls[index].controls.city.value = address.city;
      //State
      this.addCompanyForm.value.address_lists[index].state = address.state;
      this.address_listsform.controls[index].controls.state.value = address.state;
      // Country
      this.addCompanyForm.value.address_lists[index].country = address.country;
      this.address_listsform.controls[index].controls.country.value = address.country;
      //Zipcode
      this.addCompanyForm.value.address_lists[index].zipcode = address.zipcode;
      this.address_listsform.controls[index].controls.zipcode.value = address.zipcode;
      // Latitude
      this.addCompanyForm.value.address_lists[index].latitude = address.latitude;
      this.address_listsform.controls[index].controls.latitude.value = address.latitude;
      // Longitude
      this.addCompanyForm.value.address_lists[index].longitude = address.longitude;
      this.address_listsform.controls[index].controls.longitude.value = address.longitude;
      //type
      this.addCompanyForm.value.address_lists[index].type = this.addCompanyForm.value.address_lists[index].type;
      if (this.addCompanyForm.value.address_lists.length === 1) {
        this.addCompanyForm.value.address_lists[0].primary = true;
        this.primaryAddress = this.addCompanyForm.value.address_lists[0].address;
      }
      // if (this.updateCompany) {
      //   this.focusOutInput(event, 'address');
      // }
      // For repeated address value validations code Start
      let getAddressArray = this.addCompanyForm.value.address_lists.map((address: any) => {
        return address.address;
      });
      getAddressArray = getAddressArray.slice(0, -1);
      //If the Address is already there then remove the address
      // if (!this.updateCompany) {
      //   if (this.addCompanyForm.value.address_lists.length > 1 && getAddressArray.includes(event.formatAddress)) {
      //     this.removeExistingAddress(index);
      //   }
      // } else {
      //   if ((this.addCompanyForm.value.address_lists.length > 1 && getAddressArray.includes(event.formatAddress))) {
      //     this.removeExistingAddress(index);
      //   } else {
      //     if (event.location == '' || event.location == undefined || event.location == null) {
      //       return null
      //     } else {
      //       this.focusOutInput(event, 'address');
      //     }
      //   }
      // }
      if (!this.updateCompany) {
        if (this.addCompanyForm.value.address_lists.length > 1 && getAddressArray.includes(event.formatAddress)) {
          this.removeExistingAddress(index);
        }
      } else {
        if (event.location == '' || event.location == undefined || event.location == null) {
          return null;
        }
        if ((this.addCompanyForm.value.address_lists.length > 1 && getAddressArray.includes(event.formatAddress) && event.location)) {
          this.removeExistingAddress(index);
        } else {
          this.focusOutInput(event, 'address');
        }
      }
    }
  }

  removeExistingAddress(addressIndex: any) {
    this.addressArray.removeAt(addressIndex);
    this._utilService.showError('', 'Address already exist');
  }

  get atLeastOneValidNumer(): boolean {
    if (this.phone_listForm.controls.length == 2) {
      const getOneValidNumber = this.phone_listForm.controls.every((phone: any) => {
        return (phone.status == 'VALID' && (phone.value.phone != '' && phone.value.phone != undefined && phone.value.phone != null));
      });
      return getOneValidNumber;
    } else {
      return true;
    }
  }

  retrieveCompanyDetails(event: any, address: any): void {
    if ((address.value.address == undefined || address.value.address == '') && address.value.country == '') {
      this.fetchCompanyDetails.emit();
    }
  }

  /** Track by Index  **/
  trackByIndex(index: number, item: any): number {
    return index;
  }
}
