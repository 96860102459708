import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { DashboardService } from 'src/app/Modules/_fyxt_modules/dashboard/_services/dashboard.service';
import { BaseService } from 'src/app/services/base.service';
import { ConfigService } from 'src/app/services/config.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';
import { ButtonComponent } from '../../_reusable_ui/_components/button/button.component';
import { NgIf } from '@angular/common';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TextareaComponent } from '../../_reusable_ui/_controls/textarea/textarea.component';

@Component({
    selector: 'fyxt-cancel-job-reason',
    templateUrl: './cancel-job-reason.component.html',
    styleUrls: ['./cancel-job-reason.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, TextareaComponent, NgxIntlTelInputModule, NgIf, MatDialogModule, ButtonComponent]
})
export class CancelJobReasonComponent implements OnInit {
  cancelReasonForm: UntypedFormGroup;
  f: any;
  submitted: boolean = false;
  isReasonLoader: boolean = false;
  selectedJobInfo: any = {};
  isForDashboardBulkAction: any;
  selectedJobsId: any;
  isForTenant: any;
  selectedJobs:any[]=[];

  constructor(public formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CancelJobReasonComponent>,
    public _Config: ConfigService,
    public _baseService: BaseService,
    public _utilService: UtilityService,
    public _dashboardService: DashboardService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if(data?.isFromBulkAction){
      debugger;
      this.selectedJobs = data?.jobInfo;
      this.isForDashboardBulkAction = data?.isForBulkAction;
      this.selectedJobsId = data?.selectedJobsId;
      this.isForTenant = data?.isForTenant;
    }
    else{
      this.selectedJobInfo = data?.jobInfo;
      this.isForDashboardBulkAction = data?.isForBulkAction;
      this.selectedJobsId = data?.selectedJobsId;
      this.isForTenant = data?.isForTenant;
    }
    
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    // this.triggerCancelClick();
  }

  initForm() {
    this.cancelReasonForm = this.formBuilder.group({
      reason_for_cancel: new UntypedFormControl('', [
        Validators.required,
        // Validators.maxLength(150)
      ]),
    });

    this.f = this.cancelReasonForm.controls;

  }

  doSubmit() {

    this.submitted = true;
    if (this.cancelReasonForm.invalid) {
      return;
    }

    if (this.isForDashboardBulkAction) {
      this.dialogRef.close({ status: 'Success', reason_for_cancel: this.cancelReasonForm.value.reason_for_cancel });
      // this.doBulkActionSubmit()
      return;
    }

    if(this.isForTenant) {
      this.doTenantDropdownAction()
      return;
    }

    let postBody: any = {
      reason: this.cancelReasonForm.value.reason_for_cancel,
      status: "Cancelled"
    };

    this.isReasonLoader = true;

    let requestURL = environment.baseURL + this._Config.getSelectedJobDetails;
    requestURL = requestURL.replace("{ID}", this.selectedJobInfo?.id)

    this._baseService.doPatch(requestURL, postBody)
      .subscribe(
        {
          next: (response: any) => {
            // this.dialogRef.close('Updated');
          },
          error: (error) => {
            this.isReasonLoader = false;
            this._utilService.showErrorMessage(error);
          },
          complete: () => {
            this.isReasonLoader = false;
            this.dialogRef.close('Success');
          }
        });

  }

  triggerCancelClick() {
    this.dialogRef.close('Cancel');
    this.initForm();
  }

  doBulkActionSubmit(){
    let endpoint = 'jobs/bulk-actions/';
    let postBody: any = {
      jobs: this.selectedJobsId,
      bulk_action: "cancel",
      reason: this.cancelReasonForm.value.reason_for_cancel,
    };

    this.isReasonLoader = true;

    this._dashboardService.doBulkActionV1(endpoint, postBody)
      .subscribe(
        {
          next: (response: any) => {
            this._utilService.showSuccess('Bulk Action', response?.message);
          },
          error: (error) => {
            this.isReasonLoader = false;
            this._utilService.showErrorMessage(error);
          },
          complete: () => {
            this.isReasonLoader = false;
            this.dialogRef.close('Success');
          }
        });
  }

  doTenantDropdownAction() {
    let data = {
      reason: this.cancelReasonForm.value.reason_for_cancel,
      message: 'success'
    }
    this.dialogRef.close(data);
  }
}
