import {Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {UtilityService} from "../../../../../services/utility.service";
import {DialogService} from "../../../../_services/dialog/dialog.service";
import {environment} from "../../../../../../environments/environment";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { CheckboxComponent } from '../../../_reusable_ui/_controls/checkbox/checkbox.component';
import { DropdownSelectComponent } from '../../../_reusable_ui/_controls/dropdown-select/dropdown-select/dropdown-select.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { NgIf, NgClass } from '@angular/common';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';

@Component({
    selector: 'fyxt-create-contact',
    templateUrl: './create-contact.component.html',
    styleUrls: ['./create-contact.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, TextboxComponent, NgxIntlTelInputModule, NgIf, NgClass, NgxMatIntlTelInputComponent, MatFormFieldModule, DropdownSelectComponent, CheckboxComponent, MatDialogModule, ButtonComponent]
})
export class CreateContactComponent implements OnInit {

  constructor(public formbuilder: FormBuilder, public _utilService: UtilityService, public _dialogService: DialogService,
              public dialogRef: MatDialogRef<CreateContactComponent>, @Inject(MAT_DIALOG_DATA) public data: any,) {
    this.selectedCompanyDetails = this.data?.data?.selectedCompanyDetails;
    this.viewModule = this.data?.data?.viewModule;
    }

  addContactForm!: FormGroup;
  formSubmitted : boolean = false;
  companiesList: any[] = [];
  companiesListPageNumber:number = 1;
  companiesListTotal:number;
  companiesListSize:number;
  companiesSearchInput: any = "";
  allCompaniesListPageCount:number;
  isLoader: boolean = false;
  selectedCompanyDetails: any;
  viewModule: string;
  phoneNumberTypes: any[] = [];

  ngOnInit(): void {
    this.getAllCompanyDropdowns();
    this.getPhoneTypeValues();
    this.initializeForm();
  }

  validateExtensionNumber(event: any) {
    // const pattern = /^\d{0,5}$/; // define a pattern to match numbers with a maximum length of 3
    // if (!pattern.test(event.target.value)) {
    //   event.target.value = event.target.value.slice(0, 5); // truncate the value to 3 digits if it exceeds the limit
    // }
    let value = event.value;
    value = value.replace('/\D?g', '')
    value = value.replace('/^(\d{6})/$', '');
    event.value = value
    if (value) {
      event.value = event.value.slice(0, 6);
    }
  }

  validatePhoneNumber(event: any) {
    const pattern = /^\d{0,15}$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.slice(0, 15);
    }
  }

  validatePhoneNumberFormat(event: any) {
    let value = event.value;
    value = value.replace('/\D?g', '')
    value = value.replace(/^(\d{3})(\d{3})(\d{4})(\d{0,5})/, '$1 $2 $3 $4');
    event.value = value;
    if (value) {
      event.value = event.value.slice(0, 15);
    }
  }

  initializeForm(){
      this.addContactForm = this.formbuilder.group({
        'first_name': ['', [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]],
        'last_name': ['', [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]],
        'email': ['', [Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,5}$")]],
        'phone': [''],
        'extension': [null],
        'phoneType': [null, Validators.required],
        'type': [null,],
        'companyName': [null, Validators.required],
        'primary': [false]
        // 'phoneNoData': [null,],
        // 'emailData': [null,],
       });
      this.setFormData();
  }

  setFormData(): void {
    const event = {
      id: this.selectedCompanyDetails.id,
      name: this.selectedCompanyDetails.name
    };
     this.addContactForm.patchValue({
      companyName: event,
       phoneType: this.phoneNumberTypes[0] ? this.phoneNumberTypes[0]?.name : 'Home'
    });
  }

  get formControl() {
    return this.addContactForm.controls;
  }

  getPhoneTypeValues(): void {
    let requestURL = environment.baseURL + 'company-types/?source=ContactPhone';
    this._dialogService.doGET(requestURL).subscribe(
      {
        next: (response: any) => {
          this.phoneNumberTypes = response;
        },
        error: (error) => {
          this.isLoader = false;
          this._utilService.showErrorMessage(error);

        },
        complete: () => {
          this.isLoader = false;
        }
      }
    );
  }

  getAllCompanyDropdowns(pagination?:boolean) {
    // this.isLoader = true;
    let requestURL = environment.fastAPI_Actual_URL +`/companies/?columns=['id', 'name']&page=`+ this.companiesListPageNumber +'&search=' + this.companiesSearchInput;
    this._dialogService.doGET(requestURL).subscribe(
      {
        next: (response: any) => {
          if (pagination) {
            this.companiesList = this.companiesList.concat(response.items);
          } else {
            this.companiesList = response.items;
            this.companiesListTotal = response.total;
            this.companiesListSize = response.size;
            this.allCompaniesListPageCount = Math.round(this.companiesListTotal/this.companiesListSize)
          }
        },
        error: (error) => {
          this.isLoader = false;
          this._utilService.showErrorMessage(error);

        },
        complete: () => {
          this.isLoader = false;
        }
      }
    );
  }

  companyDropdownSearch(event:any){
    this.companiesSearchInput = event.term;
    this.companiesListPageNumber = 1;
    this.getAllCompanyDropdowns()
  }

  companyDropdownsScroll(){
    if(this.companiesListPageNumber < this.allCompaniesListPageCount){
      this.companiesListPageNumber++;
      this.getAllCompanyDropdowns(true)
    }
  }

  onSubmit() {
    this.formSubmitted = true;
    if (this.addContactForm.valid) {
      let postBody = {
        first_name: this.addContactForm.value.first_name,
        last_name: this.addContactForm.value.last_name,
        primary_company: this.selectedCompanyDetails.id,
        type: this.selectedCompanyDetails.type,
        source: "company",
        primary: this.addContactForm.value.primary,
      };
      if (this.selectedCompanyDetails.id == this.addContactForm.value.companyName.id) {
        postBody['assign_companies'] = [];
      } else {
        postBody['assign_companies'] = [this.addContactForm.value.companyName.id];
      }

      // If there is Phone number has value then update the all the phone number related payload or else it should be [].
      if (this.formControl.phone.valid && this.formControl.phone.value != '') {
        postBody['phone'] = [{
            "phone": this.addContactForm.value.phone,
            "type": this.addContactForm.value.phoneType?.name ? this.addContactForm.value.phoneType.name : this.addContactForm.value.phoneType,
            "extension":  this.addContactForm.value.extension ? this.addContactForm.value.extension.slice(0,6) : null,
            "primary": true
        }];
      } else {
        postBody['phone'] = [];
      }

      // If there is email has value then update the all the email related payload or else it should be [].
      if (this.formControl.email.valid && this.formControl.email.value != '') {
        postBody['email'] = [{
            "email": this.addContactForm.value.email,
            "type": "Corporate",
            "primary": true
        }];
      } else {
        postBody['email'] = [];
      }
      let requestURL = environment.fastAPI_Actual_URL + "/contacts/";
      this._dialogService.doPOST(requestURL, postBody).subscribe(
        {
          next: (response: any) => {
            response.isAdd = true;
            this.dialogRef.close(response);
            this._utilService.showSuccess('', 'Contact Created Successfully.');
          },
          error: (error) => {
            this.isLoader = false;
            this._utilService.showErrorMessage(error.error);
            // this._utilService.showError('', 'Something went wrong, please try again later');
          },
          complete: () => {
            this.isLoader = false;
          }
        }
      );
    }
  }

  onCancel() {
    // this.formSubmitted = false ;
    // this.addContactForm.reset();
    this.dialogRef.close();
  }

  validateInput(event: KeyboardEvent) {
    const pattern = /[^\w\s]/;
    if (pattern.test(event.key)) {
      event.preventDefault();
    }
  }
}
