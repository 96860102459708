// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader_container {
  text-align: center;
  height: 10vh;
}

:host ::ng-deep .search-box-container {
  width: 100%;
}

:host ::ng-deep .expanded_list {
  overflow: auto;
  max-height: calc(100vh - 25em) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_reusable_ui/_controls/filter-accordian/filter-accordian.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,cAAA;EACA,yCAAA;AACF","sourcesContent":[".loader_container {\n  text-align: center;\n  height: 10vh;\n}\n\n:host ::ng-deep .search-box-container {\n  width: 100%;\n}\n\n:host ::ng-deep .expanded_list {\n  overflow: auto;\n  max-height: calc(100vh - 25em) !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
