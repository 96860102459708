import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubjectDataService {

  public abstractSubject = new BehaviorSubject({});
  public workflowSubject = new BehaviorSubject(null);
  public abstractViewSubject = new BehaviorSubject(null);
  public tenantEditFalgSubject = new BehaviorSubject(null);
  public propertyDashboardSubject = new BehaviorSubject(null);

  private subjectDraftJob = new Subject<any>();

  constructor() { }

  getAbstract(): Observable<any> {
    return this.abstractSubject.asObservable();
  }
  getWorkFlow(): Observable<any> {
    return this.workflowSubject.asObservable();
  }
  getAbstractView(): Observable<any> {
    return this.abstractViewSubject.asObservable();
  }
  getTenantEditFlag(): Observable<any> {
    return this.tenantEditFalgSubject.asObservable();
  }
  getPropertyDashboard(): Observable<any> {
    return this.propertyDashboardSubject.asObservable();
  }


  /******************* Draft Job ************************/
  sendDraftInfo(jobObj: any) {
    this.subjectDraftJob.next(jobObj);
  }

  clearDraftInfo() {
    this.subjectDraftJob.next();
  }

  getDraftInfo(): Observable<any> {
    return this.subjectDraftJob.asObservable();
  }



}
