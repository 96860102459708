import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {InboxSettingsService} from 'src/app/Modules/_fyxt_modules/inbox-management/_services/inbox-settings.service';
import {Template} from 'src/app/Modules/_fyxt_modules/inbox-management/inbox.types';
import {InboxService_New} from 'src/app/Modules/_fyxt_modules/inbox-management/_services/inbox.service';
import {UtilityService} from "../../../../../services/utility.service";
import { NgIf } from '@angular/common';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { TextEditorComponent } from '../../../_reusable_ui/_controls/text-editor/text-editor.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';

@Component({
    selector: 'app-inbox-create-snippet',
    templateUrl: './inbox-create-snippet.component.html',
    styleUrls: ['./inbox-create-snippet.component.scss'],
    standalone: true,
    imports: [TextboxComponent, FormsModule, NgxIntlTelInputModule, TextEditorComponent, MatDialogModule, ButtonComponent, NgIf]
})
export class InboxCreateSnippetComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<null> = new Subject();
  protected templateName: string;
  protected templateBody: string;
  protected action: string;
  protected label: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
              private dialogRef: MatDialogRef<InboxCreateSnippetComponent>,
              private _inboxSettings: InboxSettingsService,
              public _utilService: UtilityService,
              private _inboxService: InboxService_New) {
  }

  ngOnInit(): void {
    this.action = this.data.data.action;

    if (this.data.type == 'SNIPPET') {
      this.label = 'Snippet'
    } else if (this.data.type == 'FEEDBACK_TEMPLATE') {
      this.label = 'Review'
    }

    if (this.action == "EDIT") {
      this.templateName = this.data.data.template.title;
      this.templateBody = this.data.data.template.content;
    }
  }

  protected bodyChange(event: string): void {
    this.templateBody = event
  }

  protected createAction(): void {
    const payload: Template = {
      "title": this.templateName,
      "content": this.templateBody,
      "mailboxId": this._inboxService.activeMailboxDetails.id,
      "category": this.data.type
    }
    this._inboxSettings.createTemplate(payload).pipe(takeUntil(this.destroy$)).subscribe(
      {
        next: (response: any) => {
          this.dialogRef.close({event: 'CREATE'});
        },
        error: (error) => {
          this._utilService.showErrorMessage(error);
        },
        complete: () => {
        }
      }
    );
  }

  protected editAction(): void {
    const payload: Partial<Template> = {
      "title": this.templateName,
      "content": this.templateBody
    }
    const templateId = this.data.data.template.id;
    this._inboxSettings.updateTemplate(payload, templateId).pipe(takeUntil(this.destroy$)).subscribe(
      {
        next: (response: any) => {
          this.dialogRef.close({event: 'EDIT'});
        },
        error: (error) => {
          this._utilService.showErrorMessage(error);
        },
        complete: () => {
        }
      }
    );
  }


  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
