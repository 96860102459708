import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactsService } from 'src/app/Modules/_fyxt_modules/contacts/_services/contacts.service';
import { UtilityService } from 'src/app/services/utility.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ButtonComponent } from '../../../../../../@fyxt/_shared/_reusable_ui/_components/button/button.component';
import { DropdownSelectComponent } from '../../../../../../@fyxt/_shared/_reusable_ui/_controls/dropdown-select/dropdown-select/dropdown-select.component';
import { PhoneNoInputComponent } from '../../../../../../@fyxt/_shared/_reusable_ui/_controls/phone-no-input/phone-no-input.component';
import { NgIf } from '@angular/common';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TextboxComponent } from '../../../../../../@fyxt/_shared/_reusable_ui/_controls/textbox/textbox.component';

@Component({
    selector: 'app-creat-contact-shared',
    templateUrl: './creat-contact-shared.component.html',
    styleUrls: ['./creat-contact-shared.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, TextboxComponent, NgxIntlTelInputModule, NgIf, PhoneNoInputComponent, DropdownSelectComponent, ButtonComponent]
})
export class CreatContactSharedComponent implements OnInit {
  addContactForm!: FormGroup;
  allCompaniesList: any[] = [];
  companiesSearchInput: any = '';
  isLoader: boolean = false;
  formSubmitted: boolean = false;
  createContactNameButton: any;
  companyName: any;

  constructor(private _contactsService:ContactsService,
    public _utilService: UtilityService,
    public formBuilder: FormBuilder,public dialogRef: MatDialogRef<CreatContactSharedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    if(this.dialogRef.componentInstance?.data){
      this.createContactNameButton = this.dialogRef.componentInstance.data?.data?.isbuttonText;    
      this.companyName = this.dialogRef.componentInstance.data?.data?.companyName;        
    }
    
    this.getAllCompanyDropdowns();
    this.initializeForm();

    if(this.createContactNameButton) {
      this.addContactForm.get('primary_company_name').setValidators(null); 
    }

  }

  initializeForm() {
    this.addContactForm = this.formBuilder.group({
      first_name: ['', [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]],
      last_name: ['', [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]],
      primary_company_name: [null, Validators.required],
      phone:[null],
      email: ['', [Validators.email, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,5}$")]],
      primary: [false],
    });
  }

  get formControl() {
    return this.addContactForm.controls;
  }

  getAllCompanyDropdowns(pagination?: boolean) {
    // this.isLoader = true;
    let requestURL = `companies/?columns=['id', 'name']&page=1&search=` + this.companiesSearchInput;
    this._contactsService.doGETLive(requestURL).subscribe(
      {
        next: (response: any) => {
          if (pagination) {
            this.allCompaniesList = this.allCompaniesList.concat(response.items);
          } else {
            this.allCompaniesList = response.items;
          }
        },
        error: (error) => {
          this.isLoader = false;
          this._utilService.showErrorMessage(error);
          },
        complete: () => {
          this.isLoader = false;
        }
      }
    );
  }

  validateInput(event: KeyboardEvent) {
    const pattern = /[^\w\s]/;
    if (pattern.test(event.key)) {
      event.preventDefault();
    }
  }


  createContact() {
    this.formSubmitted = true;
    if(this.addContactForm.valid){

    if (this.createContactNameButton) {
      this._contactsService.createContactData.next(this.addContactForm.value);
      this.dialogRef.close();
      return;
    }
    if(!this.createContactNameButton){
    let email:any = {
      email:this.addContactForm.value.email,
      type: "Other",
      primary:true
    }
    let phone:any = {
      phone : this.addContactForm.value?.phone?.e164Number,
      primary:this.addContactForm.value?.primary,
      extension:null,
      type:"Other"
    }
    let postBody = {
      "first_name": this.addContactForm.value.first_name,
      "last_name": this.addContactForm.value.last_name,
      "title": this.addContactForm.value.title,
      "type": 'Other',
      "primary_company": this.addContactForm.value.primary_company_name ? this.addContactForm.value.primary_company_name.id : '',
      "phone": [phone],
      "email": [email],
      "source": "jobs",
    };
    if(phone.phone == undefined){
      postBody.phone = [];
    }
    if(email.email == ''){
      postBody.email = []
    }
    this.isLoader = true;
    let addNewContactURL = 'contacts/';
    this._contactsService.doPOST(addNewContactURL, postBody).subscribe(
      {
        next: (response: any) => {
          this._utilService.showSuccess('', 'Contact Created Successfully.');
          this.isLoader = false;
          this.dialogRef.close({event:'ADDED',data:response});
        },
        error: (error: HttpErrorResponse) => {
          this.isLoader = false;
          this._utilService.showErrorMessage(error.error);
          this.dialogRef.close();
        },
        complete: () => {
          this.isLoader = false;
        }
      }
    );
  }
}
  }
}
