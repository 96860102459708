import { environment } from 'src/environments/environment';
import { BaseService } from './../../../../../services/base.service';
import { DatePipe, formatDate, NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GraphQLError } from 'graphql';
import { ItemList } from 'src/app/@fyxt/_shared/_reusable_ui/_controls/dropdown-select/itemType.class';
import { GraphqlEquipmentManagementService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/equipment-management/graphql-equipment-management.service';
import { EquipmentsService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/equipments.service';
import { UtilityService } from 'src/app/services/utility.service';
import { TextareaComponent } from '../../../_reusable_ui/_controls/textarea/textarea.component';
import { CheckboxComponent } from '../../../_reusable_ui/_controls/checkbox/checkbox.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { DropdownDateSelectComponent } from '../../../_reusable_ui/_controls/dropdown-select/dropdown-date-select/dropdown-date-select.component';
import { DropdownSelectComponent } from '../../../_reusable_ui/_controls/dropdown-select/dropdown-select/dropdown-select.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';
import { MatDividerModule } from '@angular/material/divider';

@Component({
    selector: 'fyxt-tab-equipment-details',
    templateUrl: './tab-details.component.html',
    styleUrls: ['./tab-details.component.scss'],
    standalone: true,
    imports: [MatDividerModule, FormsModule, ReactiveFormsModule, NgClass, TextboxComponent, NgxIntlTelInputModule, DropdownSelectComponent, DropdownDateSelectComponent, MatIconModule, MatFormFieldModule, MatInputModule, CheckboxComponent, TextareaComponent]
})
export class TabDetailsComponent implements OnInit {


  listStatus: ItemList[] = [
    { id: 1, label: 'Months', name: 'Months' },
    { id: 2, label: 'Years', name: 'Years' },
  ];
  vendorList: any[];
  vendorCurrentPage = 1;
  vendorSearch = '';
  is_critical_state: boolean = false;

  detailsForm: FormGroup;
  today = new Date();
  nextURL: string = '';



  constructor(public _graphql: GraphqlEquipmentManagementService, public equipmentService: EquipmentsService, public _baseService: BaseService, public _utilService: UtilityService,) {
    this._graphql.age.subscribe({
      next: (res: any) => {
        if (this._graphql.selectedItem?.in_service_date !== null && this._graphql.selectedItem?.expected_useful_life !== null && this._graphql.selectedItem?.expected_useful_life_term !== null) {

          if(this._graphql.selectedItem.expected_useful_life_expiry_date){
            this._graphql.selectedItem.expected_useful_life_expiry_date = new Date(this._graphql.selectedItem?.expected_useful_life_expiry_date);

            this.detailsForm.patchValue({
              expected_useful_life_expiry_date: this._graphql.selectedItem.expected_useful_life_expiry_date
            })
          }

        }
        this._graphql.estimatedLife.emit(this.detailsForm.value.expected_useful_life_expiry_date);
      }
    })
    this._graphql.equipmentTypeChange.subscribe({
      next: (res: any) => {
        this.detailsForm.patchValue({
          warranty: res?.warranty,
          warranty_term: res?.warranty_term,
          expected_useful_life: res?.expected_useful_life,
          expected_useful_life_term: res?.expected_useful_life_term,
        })
      }
    })
  }

  ngOnInit(): void {
    this.setFormValues();
  }

  async setFormValues() {
    this.initializeForm();
    if (this._graphql.selectedItem) {
      this.patchValues();
    } else {
      await new Promise<void>(resolve => {
        const checkVariable = () => {
          if (this._graphql.selectedItem !== null) {
            this.patchValues();
            resolve();
          } else {
            setTimeout(checkVariable, 100);
          }
        };
        checkVariable();
      });
    }
  }


  initializeForm() {
    this.detailsForm = new FormGroup({
      warranty: new FormControl(null),
      warranty_term: new FormControl(null),
      vendor: new FormControl(null),
      expected_useful_life: new FormControl(null),
      expected_useful_life_term: new FormControl(null),
      expected_useful_life_expiry_date: new FormControl(null),
      condition: new FormControl(null),
      purchase_date: new FormControl(null),
      in_service_date: new FormControl(null),
      purchase_amount: new FormControl(null),
      replacement_cost: new FormControl(null),
      is_critical: new FormControl(null),
      safety_notes: new FormControl(null),
    });
    this.getVendors()
  }


  patchValues() {
    if (this._graphql.selectedItem?.in_service_date) {
      // let in_service_date_Arr = this.detailsForm.value.in_service_date.split('/');
      // let newDateValue: any = in_service_date_Arr[2] + '/' + in_service_date_Arr[1] + '/' + in_service_date_Arr[0] + ' ' + in_service_date_Arr[3];
      this._graphql.selectedItem.expected_useful_life_expiry_date = new Date(this._graphql.selectedItem?.expected_useful_life_expiry_date);
      this._graphql.selectedItem.in_service_date_new = new Date(this._graphql.selectedItem?.in_service_date);
      this._graphql.selectedItem.purchase_date_new = new Date(this._graphql.selectedItem?.purchase_date);
    }

    this.detailsForm.patchValue({
      warranty: this._graphql.selectedItem?.warranty || null,
      warranty_term: (this._graphql.selectedItem?.warranty_term) ? (this._graphql.selectedItem?.warranty_term == this.listStatus[0].name ? this.listStatus[0].name : this.listStatus[1].name) : this.listStatus[0].name,
      vendor: this._graphql.selectedItem?.vendor ? JSON.parse(this._graphql.selectedItem?.vendor) : null,
      expected_useful_life: this._graphql.selectedItem?.expected_useful_life || null,
      expected_useful_life_term: (this._graphql.selectedItem?.expected_useful_life_term) ? (this._graphql.selectedItem?.expected_useful_life_term == this.listStatus[0].name ? this.listStatus[0].name : this.listStatus[1].name) : this.listStatus[0].name,
      expected_useful_life_expiry_date: this._graphql.selectedItem?.expected_useful_life_expiry_date ? this._graphql.selectedItem?.expected_useful_life_expiry_date : null,
      condition: this._graphql.selectedItem?.condition || null,
      purchase_date: this._graphql.selectedItem?.purchase_date ? this._graphql.selectedItem?.purchase_date : null,
      in_service_date: this._graphql.selectedItem?.in_service_date ? this._graphql.selectedItem?.in_service_date_new : null,
      purchase_amount: this._graphql.selectedItem?.purchase_amount || null,
      replacement_cost: this._graphql.selectedItem?.replacement_cost || null,
      is_critical: this._graphql.selectedItem?.is_critical,
      safety_notes: this._graphql.selectedItem?.safety_notes || null,
    });
    this._graphql.estimatedLife.emit(this.detailsForm.value.expected_useful_life_expiry_date);
    this.is_critical_state = true;
  }


  onValueChange(e: any, name: string) {
    if (name === 'warranty_term' || name === 'expected_useful_life_term') {
      let formValue: any = { [name]: e.name };
      if (name === 'expected_useful_life_term') {
        // formValue.expected_useful_life_expiry_date = this._graphql.selectedItem?.expected_useful_life_expiry_date ? this._graphql.selectedItem?.expected_useful_life_expiry_date : null;
        formValue.expected_useful_life_expiry_date = this._graphql.selectedItem?.expected_useful_life_expiry_date ? formatDate(this._graphql.selectedItem?.expected_useful_life_expiry_date, 'YYYY-MM-dd', 'en-US') : null;
        //this._graphql.getAge(this.detailsForm.value.in_service_date, (this.detailsForm.value?.expected_useful_life + '' + e.name));
      }
      this.updateDetails(formValue);
    } else {
      let formValue: any = { [name]: e };
      this.updateDetails(formValue);
    }
  }

  dateChange(e: any, name: string) {
    const date = new Date(e).toISOString();
    const datePipe = new DatePipe('en-US');
    const formattedDate = datePipe.transform(date, 'yyyy-MM-dd');
    let formValue: any = { [name]: formattedDate };
    // if (name === 'in_service_date') {
    //   let expectedUsefulLifeExpirationDate = this._graphql.getAge(formattedDate, (this.detailsForm.value?.expected_useful_life + '' + (this.detailsForm.value.expected_useful_life_term?.name ? this.detailsForm.value.expected_useful_life_term?.name : this.detailsForm.value.expected_useful_life_term)));
    //   formValue.in_service_date = expectedUsefulLifeExpirationDate;
    // }
    this.updateDetails(formValue);
  }


  onFocusOut(e: any, name: string) {
    let replaceValue = e.target.value.replaceAll(',', '')
    if (replaceValue != this._graphql.selectedItem[name] && this.equipmentService.change_equipment) {
      let formValue: any = { [name]: replaceValue };
      if (this.detailsForm.value.warranty === '') {
        this.detailsForm.get('warranty_term').setValue(null)
      }
      if (this.detailsForm.value.expected_useful_life === '') {
        this.detailsForm.get('expected_useful_life_term').setValue(null)
      }
      if ((name === "expected_useful_life" || name === "expected_useful_life_term") && this.detailsForm.value.expected_useful_life != '') {
        // formValue.expected_useful_life_expiry_date = this._graphql.getAge(new Date(this.detailsForm.value.in_service_date).toLocaleString(), (replaceValue + '' + (this.detailsForm.value?.expected_useful_life_term?.name ? this.detailsForm.value?.expected_useful_life_term?.name : this.detailsForm.value.expected_useful_life_term)));

        formValue.expected_useful_life_expiry_date = this._utilService.formatDate(this.detailsForm.value.in_service_date);
      }
      this.updateDetails(formValue)
    }
  }

  updateDetails(formValues: any) {
    this._graphql.updateEquipmentDetail(this._graphql.selectedItem.id, formValues);
  }



  /**
  * On Change Event
  * @param event
  */
  slideToggleChange(event: any, name: string) {
    if (this.is_critical_state) {
      let formValue: any = { [name]: event };
      this.updateDetails(formValue);
    }
  }

  getVendors() {
    let requestURL = environment.fastAPI_Actual_URL + `/companies/?page=${this.vendorCurrentPage}&size=50&columns=["id","name"]&query={'type': ['Vendor']}`;
    if (this.vendorSearch.trim() !== '') {
      requestURL += `&search=${this.vendorSearch}`;
    }
    this._baseService.doGET(requestURL).subscribe({
      next: (res: any) => {
        if (this.vendorCurrentPage == 1) {
          this.vendorList = res?.items || [];
        } else {
          this.vendorList = this.vendorList.concat(res.items);
        }
        this.vendorCurrentPage = res.page;
        // this.nextURL = res?.next;
        // this.vendorList.forEach(element => { element.name = element.company })
      }
    })
  }

  pageVendor(e: any) {
    this.vendorCurrentPage += 1;
    this.getVendors();
  }

  vendorDropdownSearch(event: any) {
    if (event.term.trim() !== '') {
      this.vendorSearch = event.term;
      this.vendorCurrentPage = 1;
      this.getVendors();
    }
  }

  focusInVendorDropdown(event: any): void {
    this.vendorSearch = '';
    this.vendorCurrentPage = 1;
    this.getVendors();
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === '-' || event.key === '+') {
      event.preventDefault();
    }
  }
}
