// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success_associate {
  background: #FCFFF5;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border: 1px solid #A8C599;
  border-radius: 4px;
  opacity: 1;
  padding: 8px;
}

.box {
  height: 54px;
  width: 52px;
  text-align: center;
  font-size: 25px;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_views/_popup_views/soft-auth-popup/soft-auth-popup.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,mDAAA;EACA,yBAAA;EACA,kBAAA;EACA,UAAA;EACE,YAAA;AACN;;AAEA;EACE,YAAA;EACE,WAAA;EACA,kBAAA;EACA,eAAA;AACJ","sourcesContent":[".success_associate{\n    background: #FCFFF5;\n    box-shadow: 0px 3px 6px #00000029;\n    border: 1px solid #A8C599;\n    border-radius: 4px;\n    opacity: 1;\n      padding:8px;\n}\n\n.box{\n  height: 54px;\n    width: 52px;\n    text-align: center;\n    font-size: 25px;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
