import { ColumnSortType, TableColumnTypes } from '../../../@fyxt/_shared/enums/table/table-column-types.enum';
import { RadioButtonItem } from './pages/maintenance-terms-tab/maintenance-terms-tab.component';

export const dataTableDraftHeaders = [
  {
    value: 'tenant',
    sort: ColumnSortType.none,
    name: 'Tenant',
    type: TableColumnTypes.text,
    className: 'table-header',
    type_lease: 'draft',
  },
  {
    value: 'property',
    sort: ColumnSortType.none,
    name: 'Property',
    type: TableColumnTypes.text,
    className: 'table-header'
  },
  {
    value: 'units',
    sort: ColumnSortType.none,
    name: 'Unit(s)',
    type: TableColumnTypes.text,
    className: 'table-header'
  },
  {
    value: 'updated_at',
    sort: ColumnSortType.none,
    name: 'Last Modified',
    type: TableColumnTypes.date,
    className: 'table-header'
  },
]

export const LeasesTenantPortalHeaders = [
  {
    value: 'tenant',
    sort: ColumnSortType.none,
    name: 'Tenant',
    type: TableColumnTypes.text,
    className: 'table-header',
    type_lease: 'default',
  },
  {
    value: 'property',
    sort: ColumnSortType.none,
    name: 'Property',
    type: TableColumnTypes.text,
    className: 'table-header'
  },
  {
    value: 'commencement_date',
    sort: ColumnSortType.none,
    name: 'Commencement Date',
    type: TableColumnTypes.date,
    className: 'table-header'
  },
  {
    value: 'expiration_date',
    sort: ColumnSortType.none,
    name: 'Expiration Date',
    type: TableColumnTypes.date,
    className: 'table-header'
  },
  {
    value: 'units',
    sort: ColumnSortType.none,
    name: 'Unit(s)',
    type: TableColumnTypes.text,
    className: 'table-header'
  },
];

export const dataTableHeaders = [
  {
    value: 'tenant',
    sort: ColumnSortType.none,
    name: 'Tenant',
    type: TableColumnTypes.text,
    className: 'table-header',
    type_lease: 'default',
  },
  {
    value: 'property',
    sort: ColumnSortType.none,
    name: 'Property',
    type: TableColumnTypes.text,
    className: 'table-header'
  },
  {
    value: 'commencement_date',
    sort: ColumnSortType.none,
    name: 'Commencement Date',
    type: TableColumnTypes.date,
    className: 'table-header'
  },
  {
    value: 'expiration_date',
    sort: ColumnSortType.none,
    name: 'Expiration Date',
    type: TableColumnTypes.date,
    className: 'table-header'
  },
  {
    value: 'units',
    sort: ColumnSortType.none,
    name: 'Unit(s)',
    type: TableColumnTypes.text,
    className: 'table-header'
  },
  {
    value: 'move_in_inspection',
    sort: ColumnSortType.none,
    name: 'Move In Inspection',
    type: TableColumnTypes.textLeaseStatus,
    className: 'table-header'
  },
  {
    value: 'move_out_inspection',
    sort: ColumnSortType.none,
    name: 'Move Out Inspection',
    type: TableColumnTypes.textLeaseStatus,
    className: 'table-header'
  },
];

export const tableHeaders = [
  {
    value: 'primary',
    sort: ColumnSortType.none,
    name: '',
    type: TableColumnTypes.checkbox,
    className: 'table-checkbox'
  },
  ...dataTableHeaders
];

export const tableDraftHeaders = [
  {
    value: 'primary',
    sort: ColumnSortType.none,
    name: '',
    type: TableColumnTypes.checkbox,
    className: 'table-checkbox'
  },
  ...dataTableDraftHeaders
];

export enum LEASES_FILTERS {
  EXTERNAL_CODE = 'Lease External Code',
  TENANT = 'Tenant',
  PROPERTY = 'Property',
  UNIT = 'Unit(s)',
  DATE_FROM = 'dateFrom',
  DATE_TO = 'dateTo',
  SEARCH = 'Search'
}

export const LEASES_FILTERS_LIST = [
  {
    label: LEASES_FILTERS.TENANT,
    isExpended: false,
    selectAll: false,
    isGrouped: false,
    listItems: []
  },
  {
    label: LEASES_FILTERS.PROPERTY,
    isExpended: false,
    selectAll: false,
    isGrouped: false,
    listItems: []
  },
  {
    label: LEASES_FILTERS.UNIT,
    isExpended: false,
    selectAll: false,
    isGrouped: false,
    listItems: []
  },
  {
    label: LEASES_FILTERS.EXTERNAL_CODE,
    isExpended: false,
    selectAll: false,
    isGrouped: false,
    listItems: []
  },
];

export const LEASES_TENANT_PORTAL_FILTERS_LIST = [
  {
    label: LEASES_FILTERS.PROPERTY,
    isExpended: false,
    selectAll: false,
    isGrouped: false,
    listItems: []
  },
  {
    label: LEASES_FILTERS.UNIT,
    isExpended: false,
    selectAll: false,
    isGrouped: false,
    listItems: []
  },
];

export const LEASES_DATE_FILTER = {
  mainLabel: 'Date',
  labelFrom: 'Commences on or after',
  labelTo: 'Expires on or before',
  from: null,
  to: null,
  isClear: true
}

export const LEASE_TYPE_RADIO: RadioButtonItem[] = [
  { name: 'Net', value: 'Net' },
  { name: 'Gross', value: 'Gross' },
];

export const CATEGORY_CONFIG = [
  { id:1,label:'Net',name:'Net' },
  { id:2,label:'Gross',name:'Gross' },
];

export const RULE_CRITERIA_TYPE = [
  { name: 'Amount', value: 'Amount' },
  { name: 'Time', value: 'Time' }
];

export const TEMPLATES_TABS = [
  { name: 'Properties', value: 'Properties', count: 0, active: true, disable: false },
  { name: 'Tenants', value: 'Tenants', count: 0, active: false, disable: false },
];

export enum ProvisionTabs {
  DETAILS = 'details',
  NOTES = 'notes',
}

export enum NotesTypes {
  LEASES_PROVISIONS = 'LEASES_PROVISIONS',
  COMPANY = 'COMPANY',
  CONTACT = 'CONTACT',
  EQUIPMENT_ADDITIONAL_DATA = 'EQUIPMENT_ADDITIONAL_DATA',
  EQUIPMENT = 'EQUIPMENT',
  EQUIPMENT_TYPE = 'EQUIPMENT_TYPE',
  LEASES_MAINTENANCE = 'LEASES_MAINTENANCE',
  LEASES_MAIN = 'LEASES_MAIN',
  LEASE = 'LEASE',
  PROPERTY = 'PROPERTY',
  PLANNER = 'PLANNER',
  JOB = 'JOB',
  COI = 'COI'
}

export enum LEASE_STATUS {
  NOT_STARTED = 'NOT_STARTED',
  CURRENT = 'CURRENT',
  ENDED = 'ENDED',
  DRAFT = 'DRAFT',
}

export const LEASE_DETAILS_STATUS_LABEL = {
  [LEASE_STATUS.ENDED]: 'Ended',
  [LEASE_STATUS.CURRENT]: 'Current Lease',
  [LEASE_STATUS.NOT_STARTED]: 'Not Started',
}

export const LEASE_STATUS_LABEL = {
  [LEASE_STATUS.ENDED]: 'Ended',
  [LEASE_STATUS.CURRENT]: 'Active',
  [LEASE_STATUS.NOT_STARTED]: 'Not Started',
}
