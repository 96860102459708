import { Component, OnInit } from '@angular/core';
import { GraphqlEquipmentTypeService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/equipment-type/graphql-equipment-type.service';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';

@Component({
    selector: 'fyxt-duplicate-equipment-type',
    templateUrl: './duplicate-equipment-type.component.html',
    styleUrls: ['./duplicate-equipment-type.component.scss'],
    standalone: true,
    imports: [TextboxComponent, FormsModule, NgxIntlTelInputModule, MatDialogModule, ButtonComponent]
})
export class DuplicateEquipmentTypeComponent implements OnInit {

  duplicateEquipmentTypeForm: any = {
    equipmentType: ''
  };
  closeOnSave:boolean = false;

  constructor(public _graphql:GraphqlEquipmentTypeService,) { }

  ngOnInit(): void {
  }

    /**
* On Change Event
* @param event
*/
selectedTags(event: any): void {
  this.duplicateEquipmentTypeForm.selectedFilters=event;
}


/**
 * save view Trigger
 */
saveView(){
  this.closeOnSave = true;
  let duplicateEquipmentType : any = {
    name:this.duplicateEquipmentTypeForm.equipmentType,
    category:JSON.parse(this._graphql.equipmentTypeResponse.category),
    status:this._graphql.equipmentTypeResponse.status,
    manufacturer:JSON.parse(this._graphql.equipmentTypeResponse.manufacturer).id,
    model:JSON.parse(this._graphql.equipmentTypeResponse.model).id,
    warranty:this._graphql.equipmentTypeResponse.warranty,
    warranty_term:this._graphql.equipmentTypeResponse.warranty_term,
    expected_useful_life:this._graphql.equipmentTypeResponse.expected_useful_life,
    expected_useful_life_term:this._graphql.equipmentTypeResponse.expected_useful_life_term,
    is_critical:this._graphql.equipmentTypeResponse.is_critical,
  }
  if(this.duplicateEquipmentTypeForm.equipmentType){
    this._graphql.duplicateEquipmentType(duplicateEquipmentType,true,this._graphql.equipmentTypeResponse.id);
  }
}
}
