import { EquipmentsService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/equipments.service';
import { Component, Inject, Input, OnInit, Optional, EventEmitter, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ItemList } from 'src/app/@fyxt/_shared/_reusable_ui/_controls/dropdown-select/itemType.class';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { SetLocationComponent } from 'src/app/@fyxt/_shared/_views';
import { SidebarService } from 'src/app/@fyxt/_services/_layout/sidebar.service';
import { GraphqlEquipmentManagementService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/equipment-management/graphql-equipment-management.service';
import { GraphqlEquipmentTypeService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/equipment-type/graphql-equipment-type.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, NgIf, NgClass } from '@angular/common';
import { UtilityService } from 'src/app/services/utility.service';
import { GraphQLError } from 'graphql';
import { Subscription } from 'rxjs';
import { TextareaComponent } from '../../_reusable_ui/_controls/textarea/textarea.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DropdownDateSelectComponent } from '../../_reusable_ui/_controls/dropdown-select/dropdown-date-select/dropdown-date-select.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GoogleAddressTextboxComponent } from '../../_reusable_ui/_controls/google-address-textbox/google-address-textbox.component';
import { DropdownSelectComponent } from '../../_reusable_ui/_controls/dropdown-select/dropdown-select/dropdown-select.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TextboxComponent } from '../../_reusable_ui/_controls/textbox/textbox.component';
import { ProfilePictureComponent } from '../../_reusable_ui/_components/profile-picture/profile-picture.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../_reusable_ui/_components/button/button.component';
import { LoaderSmallComponent } from '../../_reusable_ui/_components/loader-small/loader-small.component';



@Component({
    selector: 'fyxt-create-equipment',
    templateUrl: './create-equipment.component.html',
    styleUrls: ['./create-equipment.component.scss'],
    providers: [DatePipe],
    standalone: true,
    imports: [NgIf, LoaderSmallComponent, ButtonComponent, MatIconModule, MatDividerModule, FormsModule, ReactiveFormsModule, NgClass, ProfilePictureComponent, TextboxComponent, NgxIntlTelInputModule, DropdownSelectComponent, GoogleAddressTextboxComponent, MatTooltipModule, DropdownDateSelectComponent, MatFormFieldModule, MatInputModule, TextareaComponent]
})
export class CreateEquipmentComponent implements OnInit {


  switchOnOff: boolean = true;
  @Input() updateEquipment: boolean = false;
  @Input() isDayPorterLogin: boolean;

  @Input() statusFlag;

  @Output() onDateChange = new EventEmitter();
  today = new Date();
  disableStatus = false;
  is_critical = false;

  gps_location: any;

  get formControl() {
    return this.addEquipmentForm.controls;
  }


  items: ItemList[] = [
    { id: 1, label: 'Active', name: 'Active' },
    { id: 2, label: 'Inactive', name: 'Inactive' },
  ];

  EquipmentTypeList: any[];
  categoryList: any[];
  manufacturerList: any[];
  modelList: any[];
  propertyList: any[];

  listStatus: ItemList[] = [
    { id: 1, label: 'Months', name: 'Months' },
    { id: 2, label: 'Years', name: 'Years' },
  ];

  addEquipmentForm!: FormGroup;
  private duplicateSubscription: Subscription;
  formSubmitted: boolean = false;
  imageSource:string ='';
  createEquipmentCoords:any;

  constructor(
    public formbuilder: FormBuilder,
    public _dialogService: DialogService,
    public sidebarService: SidebarService,
    public _graphql: GraphqlEquipmentManagementService,
    public _graphqlType: GraphqlEquipmentTypeService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public _utilService: UtilityService,
    public equipmentService: EquipmentsService,
    @Optional() @Inject(MAT_DIALOG_DATA) public matDialogData: any,
  ) {
    this._graphql.age.subscribe((res: any) => {
      if (res) {
        if (res.in_service_date || res.warranty || res.warranty_term) {
          this.addEquipmentForm.patchValue({
            age: this._graphql.getAge(res.in_service_date),
          })
          if(this._graphql.selectedItem.warranty_expiration_date === null && this._graphql.selectedItem.warranty!== null && this._graphql.selectedItem.warranty_term!==null ){
            this.addEquipmentForm.patchValue({
              waranteeExpiDate: this._graphql.getAge(this._graphql.selectedItem.in_service_date, (this._graphql.selectedItem.warranty + this._graphql.selectedItem.warranty_term )),
            })
          }
        }
      }
    })
    this._graphql.gps_location.subscribe((res: any) => {
      if (res) {
        this.createEquipmentCoords = res?.gps_location;
        if(res?.gps_location?.latitude=='' && res?.gps_location?.longitude==''){
          this.addEquipmentForm.patchValue({
          gps_location:"",
        })
      } else {
        this.addEquipmentForm.patchValue({
          gps_location:res?.gps_location?.latitude + ',' + res?.gps_location?.longitude,
        })
      }
      }
    })

    this._graphql.estimatedLife.subscribe((res: any) => {
      if (res) {
        this.addEquipmentForm.patchValue({
          estimatedLife: this._graphql.getLife(res),
        })
      }
    })
  }

  ngOnInit(): void {
    this.initializeForm();
    this.duplicateSubscription = this._graphql.duplicateEquipment().subscribe({
      next: (res) => {
        if (res) {
          this.setFormData();
          this._graphql.dupicate.next(false);
        }
      }
    })
  }
  
  ngOnDestroy(){
    if (this.duplicateSubscription) {
      this.duplicateSubscription.unsubscribe();
    }
  }
  /**
  * On Change Event
  * @param event
  */
  slideToggleChange(event: any): void {}


  /**
    * Initialize Create Company Form
    */
  initializeForm() {
    this.addEquipmentForm = this.formbuilder.group({
      'name': [null, Validators.required],
      'equipment_type_id': [null, Validators.required],
      'status': [null],
      'category': [null, Validators.required],
      'manufacturer_id': [null],
      'model_id': [null],
      'property': [null, Validators.required],
      'specific_location': [null],
      'gps_location': [null],
      'serial_number': [null],
      'asset_tag': [null],
      'warranty': [null],
      'warranty_term': [null],
      'expected_useful_life': [null],
      'expected_useful_life_term': [null],
      'condition': [null],
      'purchase_date': [null],
      'in_service_date': [null],
      'purchase_amount': [null],
      'replacement_cost': [null],
      'safety_notes': [null],
      'age': [null],
      'waranteeExpiDate': [null],
      'estimatedLife': [null],
    });
    this._graphql.loadEquipmentTypeList().subscribe({
      next: (res: any) => {
        this.EquipmentTypeList = res.listFilterEquipmentsTypes?.items || [];
      },
    });
    this.manufacturersList('');
    this._graphqlType.loadCategoryFilters().subscribe({
      next: (res: any) => {
        this.categoryList = res || [];
      },
    });
    this._graphql.loadPropertyFilters().subscribe({
      next: (res: any) => {
        this.propertyList = res || [];
      },
    });

    if (this.updateEquipment) {
      this.setFormData();
    }
  }

  manufacturersList(search:any){
    this._graphqlType.loadManufacturerList(search, 1).subscribe({
      next: (res: any) => {
        this.manufacturerList = res.listFilterManufacturers?.items || [];
      },
    });
  }

  modelsList(id: any, search: string, page: number) {
    this._graphqlType.loadModelsList(id, search, 1).subscribe({
      next: (res: any) => {
        this.modelList = res.listFilterModels?.items || [];
      },
    });
  }


  onValueChange(e: any, name: string) {
    if (this.updateEquipment) {
      if (name !== 'status') {
        if (name === 'manufacturer_id') {
          if(e?.id===undefined){
            this.manufacturersList('')
          }
          if(!e?.id){
            this._graphqlType.createManufacturer(e.name).subscribe({
              next:(res:any)=>{
              this.addEquipmentForm.get('manufacturer_id').setValue(res?.addManufacturer || null)
              this._utilService.showSuccess('', 'Manufacturer Added Successfully');
              this.addEquipmentForm.get('model_id').setValue(null);
              this.modelList = [];
              this.modelsList(res?.addManufacturer?.id, "", 1);
              let formValue: any = { [name]: res?.addManufacturer?.id }
              formValue.model_id = null;
              this.updateDetails(formValue);
              },
              error:(err:GraphQLError) => {
                const error= err.message;
                const messageWithoutBraces = error.slice(1, -1);
                const value = messageWithoutBraces.split('=')[1];
                this._utilService.showError('', value);
               },
            })
          }
          else{
            this.addEquipmentForm.get('model_id').setValue(null);
            this.modelList = [];
            this.modelsList(e?.id, "", 1);
            let formValue: any = { [name]: e?.id }
            formValue.model_id = null;
            this.updateDetails(formValue);
          }
        }
        else {
          if (name === 'property' || name === 'category') {
            let formValue: any = { [name]: e };
            this.updateDetails(formValue);
          }
          else {
            if(name === 'equipment_type_id'){
              this._graphqlType.equipmentTypeDetail(e.id).subscribe({
                next:(res:any)=>{
                  this.addEquipmentForm.patchValue({
                    category: JSON.parse(res.getEquipmentType?.category),
                    manufacturer_id: JSON.parse(res.getEquipmentType?.manufacturer),
                    model_id: JSON.parse(res.getEquipmentType?.model),
                    warranty:res.getEquipmentType?.warranty,
                    warranty_term: res.getEquipmentType?.warranty_term !== null ?this.listStatus[0].name === res.getEquipmentType?.warranty_term ?this.listStatus[0]:this.listStatus[1]:null,
                    expected_useful_life:res.getEquipmentType?.expected_useful_life,
                    expected_useful_life_term: res.getEquipmentType?.expected_useful_life_term !== null ?this.listStatus[0].name === res.getEquipmentType?.expected_useful_life_term ?this.listStatus[0]:this.listStatus[1]:null,
                  })
                  this.modelsList(JSON.parse(res.getEquipmentType?.manufacturer)?.id, "", 1);
                  this._graphql.equipmentTypeChange.emit(res.getEquipmentType);
                },
                error:(err:GraphQLError) => {
                  this._utilService.showError('', err);
                 },
                complete:()=>{
                  let formValue: any = {
                    [name]: e.id,
                    category:this.addEquipmentForm.get('category')?.value,
                    manufacturer_id:this.addEquipmentForm.get('manufacturer_id')?.value?.id,
                    model_id:this.addEquipmentForm.get('model_id')?.value?.id,
                  };
                  this.updateDetails(formValue);
                }
              })
            } else{
              if(this.addEquipmentForm.get('manufacturer_id')?.value?.id){
                if(e?.id===undefined){
                  this.modelsList(this.addEquipmentForm.get('manufacturer_id')?.value?.id,'',1);
                }
              if(e?.name !== undefined && !this.modelList.some(obj => obj.name === e.name)){
                this._graphqlType.createModel(this.addEquipmentForm.get('manufacturer_id')?.value?.id,e.name).subscribe({
                  next:(res:any)=>{
                    this.modelsList(this.addEquipmentForm.get('manufacturer_id')?.value?.id, '', 1);
                    this.addEquipmentForm.get('model_id').setValue(res?.addModel || null)
                    this._utilService.showSuccess('', 'Model Added Successfully');
                    let formValue: any = { [name]: res?.addModel?.id };
                    this.updateDetails(formValue);
                  },
                  error:(err:GraphQLError) => {
                    this.modelsList(this.addEquipmentForm.get('manufacturer_id')?.value?.id, '', 1);
                    this.addEquipmentForm.get('model_id').setValue(null)
                    const error = err.message;
                    const messageWithoutBraces = error.slice(1, -1);
                    const value = messageWithoutBraces.split('=')[1];
                    this._utilService.showError('', value);
                   },
                })
              }
              else{
              let formValue: any = { [name]: e.id };
              this.updateDetails(formValue);
              }
            } else {
                this.addEquipmentForm.get('model_id').setValue(null);
                this._utilService.showError('', "Please select the manufacuturer");
            }
            }

          }
        }
      }
      else {
        let formValue: any = { [name]: e.name.trim() };
        this.updateDetails(formValue);
      }
    }
    else if (name === 'manufacturer_id' && !this.updateEquipment) {
      this.modelList = [];
      this.addEquipmentForm.get('model_id').setValue(null)
      if(e?.id)this.modelsList(e.id,'',1);
      if(e?.id===undefined){
        this.manufacturersList('')
      }
      if(e?.id===undefined && e?.name !== undefined){
        this._graphqlType.createManufacturer(e.name).subscribe({
          next:(res:any)=>{
          this.addEquipmentForm.get('manufacturer_id').setValue(res?.addManufacturer || null)
          this._utilService.showSuccess('', 'Manufacturer Added Successfully');
          },
          error:(err:GraphQLError) => {
            const error= err.message;
            const messageWithoutBraces = error.slice(1, -1);
            const value = messageWithoutBraces.split('=')[1];
            this._utilService.showError('', value);
           },
        })
      }
    }
    else if(name === 'model_id' && !this.updateEquipment ){
    if(this.addEquipmentForm.get('manufacturer_id')?.value?.id){
      if(e?.name !== undefined && !this.modelList.some(obj => obj.name === e.name)){
        this._graphqlType.createModel(this.addEquipmentForm.get('manufacturer_id')?.value?.id,e.name).subscribe({
          next:(res:any)=>{
            this.modelsList(this.addEquipmentForm.get('manufacturer_id')?.value?.id,'',1);
            this.addEquipmentForm.get('model_id').setValue(res?.addModel || null);
            this._utilService.showSuccess('', 'Model Added Successfully');
          },
          error:(err:GraphQLError) => {
            this.modelsList(this.addEquipmentForm.get('manufacturer_id')?.value?.id,'',1);
            this.addEquipmentForm.get('model_id').setValue(null)
            const error= err.message;
            const messageWithoutBraces = error.slice(1, -1);
            const value = messageWithoutBraces.split('=')[1];
            this._utilService.showError('', value);
           },
        })
      }
    } else {
      this.modelList = [];
      this.addEquipmentForm.get('model_id').setValue(null)
      this._utilService.showError('', "Please select the manufacuturer");
    }
  }
    else if (name === 'equipment_type_id'){
      this._graphqlType.equipmentTypeDetail(e.id).subscribe({
        next:(res:any)=>{
          this.is_critical = res.getEquipmentType?.is_critical;
          this.addEquipmentForm.patchValue({
            category: JSON.parse(res.getEquipmentType?.category),
            manufacturer_id: JSON.parse(res.getEquipmentType?.manufacturer),
            model_id: JSON.parse(res.getEquipmentType?.model),
            warranty:res.getEquipmentType?.warranty,
            warranty_term: res.getEquipmentType?.warranty_term !== null ?this.listStatus[0].name === res.getEquipmentType?.warranty_term ?this.listStatus[0]:this.listStatus[1]:null,
            expected_useful_life:res.getEquipmentType?.expected_useful_life,
            expected_useful_life_term: res.getEquipmentType?.expected_useful_life_term !== null ?this.listStatus[0].name === res.getEquipmentType?.expected_useful_life_term ?this.listStatus[0]:this.listStatus[1]:null,
          })
          this.modelsList(JSON.parse(res.getEquipmentType?.manufacturer)?.id, "", 1);
        }
      })
    }

  }

  changeAddress(event: any) {
    this.createEquipmentCoords = {latitude: event.latitude, longitude: event.longitude, location: event.location};
  }

  imageUpload(event:any){
  const formData: FormData = new FormData();
  formData.append('image', event);
  const id = this.router.url.split('/').pop();
  let updateContactPhotoUrl = 'equipment/' + id + '/upload-photo/';
  this.equipmentService.doPATCH(updateContactPhotoUrl, formData).subscribe(
    {
      next: (response: any) => {
        var reader = new FileReader();
        reader.readAsDataURL(event);
        reader.onload = (_event) => {
          this._utilService.showSuccess('', 'Equipment Picture Updated Successfully');
        }
      }
    }
  );
}

onSearchDropdown(e:any,name:string){
  if(name === 'manufacturer_id'){
   this.manufacturersList(e.term);
  } else{
    if(this.addEquipmentForm.get('manufacturer_id')?.value?.id)this.modelsList(this.addEquipmentForm.get('manufacturer_id')?.value?.id,e.term,1)
  }
}

  updateDetails(formValues: any) {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this._graphql.updateEquipmentDetail(id, formValues);
  }

  /**
   * Set Create Company Form Data
   */
  setFormData() {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this._graphql.getEquipmentDetails(id).subscribe({
      next: (res: any) => {
        if(res.getEquipment?.status == "Active" && !this.statusFlag){
          this.disableStatus = true
        }else{
          this.disableStatus = false;
        }
        this._graphql.selectedItem = res.getEquipment;
        this.imageSource = res.getEquipment?.image || '../../../../../assets/@fyxt/images/images.png';
        this.addEquipmentForm.patchValue({
          name: res.getEquipment?.name,
          equipment_type_id: JSON.parse(res.getEquipment?.equipment_type),
          status: res.getEquipment?.status,
          category: JSON.parse(res.getEquipment?.category),
          manufacturer_id: JSON.parse(res.getEquipment?.manufacturer),
          model_id: JSON.parse(res.getEquipment?.model),
          property: JSON.parse(res.getEquipment?.property),
          specific_location: res.getEquipment?.specific_location,
          gps_location: JSON.parse(res.getEquipment?.gps_location)?.latitude && JSON.parse(res.getEquipment?.gps_location)?.longitude ? JSON.parse(res.getEquipment?.gps_location)?.latitude + ',' + JSON.parse(res.getEquipment?.gps_location)?.longitude:'',
          serial_number: res.getEquipment?.serial_number,
          asset_tag: res.getEquipment?.asset_tag,
          warranty: res.getEquipment?.warranty,
          warranty_term: res.getEquipment?.warranty_term?.name,
          expected_useful_life: res.getEquipment?.expected_useful_life,
          expected_useful_life_term: res.getEquipment?.expected_useful_life_term?.name,
          condition: res.getEquipment?.condition,
          purchase_date: res.getEquipment?.purchase_date === null ? null : new Date(res.getEquipment?.purchase_date).toLocaleString(),
          in_service_date: res.getEquipment?.in_service_date === null ? null : new Date(res.getEquipment?.in_service_date).toLocaleString(),
          purchase_amount: res.getEquipment?.purchase_amount,
          replacement_cost: res.getEquipment?.replacement_cost,
          safety_notes: res.getEquipment?.safety_notes,
          age: res.getEquipment?.in_service_date === null ? null : this._graphql.getAge(res.getEquipment?.in_service_date),
          waranteeExpiDate: res.getEquipment?.warranty_expiration_date === null ? ((res.getEquipment?.in_service_date!== null && res.getEquipment?.warranty !==null && res.getEquipment?.warranty_term !== null )?this._graphql.getAge(res.getEquipment?.in_service_date, (res.getEquipment?.warranty + res.getEquipment?.warranty_term )):null): new Date(res.getEquipment?.warranty_expiration_date).toLocaleString(),
        })
        this.modelsList(JSON.parse(res.getEquipment?.manufacturer).id, "", 1);
       if(res.getEquipment?.gps_location) this.createEquipmentCoords =  JSON.parse(res.getEquipment?.gps_location);
      }

    })
  }

  onFocusOut(e: any, name: string) {
    let replaceValue =  e.target.value.replaceAll(',','')
    if (this.updateEquipment && replaceValue != this._graphql.selectedItem[name] && name!=='name') {
      let formValue: any = { [name]: replaceValue.trim() };
      this.updateDetails(formValue)
    }
    else if(this.addEquipmentForm.get('name')?.value !== '' && this.updateEquipment){
      let formValue: any = { [name]: replaceValue.trim() };
      this.updateDetails(formValue);
    }
    else if(this.addEquipmentForm.get('name')?.value === '' && this.updateEquipment){
      this.addEquipmentForm.get('name').setValue(this._graphql.selectedItem[name]);
      this._utilService.showError('', 'Equipment Name Cannot be Empty');
    }
  }



setLocationPopup() {

  let popupData = {
    title: 'Set Location',
    component: SetLocationComponent,
    containerClass: ['modal_add_views'],
    data: {
      equipmentModal: this.createEquipmentCoords
    }
  };

  this._dialogService.openModal(popupData).subscribe(result => {
    if (result) {
      this.gps_location = result
      if(this.gps_location?.latitude=='' && this.gps_location?.longitude==''){
        this.addEquipmentForm.patchValue({
        gps_location:"",
      })
    } else {
      this.addEquipmentForm.patchValue({
        gps_location: this.gps_location.latitude + ',' + this.gps_location.longitude
      });
    }
      if(this.updateEquipment){
        let formValue:any = { gps_location:result }
        this.updateDetails(formValue);
      }
    }
  });
}



  onSubmit() {
    this.addEquipmentForm.value.in_service_date = this.addEquipmentForm.value.in_service_date ? new DatePipe('en-US').transform(new Date(this.addEquipmentForm.value.in_service_date), 'yyyy-MM-dd'): null;
    this.addEquipmentForm.value.purchase_date = this.addEquipmentForm.value.purchase_date? new DatePipe('en-US').transform(new Date(this.addEquipmentForm.value.purchase_date), 'yyyy-MM-dd'): null;;
    this.addEquipmentForm.value.gps_location = this.gps_location;
    if (this.addEquipmentForm.value?.in_service_date) {
      let warrantyExpirationDate = this._graphql.getAge(this.addEquipmentForm.value.in_service_date, (this.addEquipmentForm.value.warranty + this.addEquipmentForm.value.warranty_term?.name));
      if (warrantyExpirationDate) {
        this.addEquipmentForm.value.warranty_expiration_date = warrantyExpirationDate;
      }
      let expectedusefulLifeExpirationDate = this._graphql.getAge(this.addEquipmentForm.value.in_service_date, (this.addEquipmentForm.value?.expected_useful_life + '' + this.addEquipmentForm.value?.expected_useful_life_term?.name));
      if (expectedusefulLifeExpirationDate) {
        this.addEquipmentForm.value.expected_useful_life_expiry_date = expectedusefulLifeExpirationDate;
      }
    }
    this.formSubmitted = true;
    if (this.addEquipmentForm.valid) {
      this._graphql.createEquipment(this.addEquipmentForm.value,false,undefined,this.is_critical);
      this.onCancel();
    }
  }


  onCancel() {
    this.sidebarService.toggleRightSidebar = !this.sidebarService.toggleRightSidebar;

    setTimeout(() => {
      this.formSubmitted = false;
      this.addEquipmentForm.reset();
      this.sidebarService.setFormConext('');
      // this.initializeForm();
    }, 100);

  }

  onDateSelected(e: any) {
    let formValue: any = { warranty_expiration_date: new DatePipe('en-US').transform(new Date(e), 'yyyy-MM-dd') };

    this.updateDetails(formValue)
  }


  onDismiss() {
    this.sidebarService.toggleRightSidebar = !this.sidebarService.toggleRightSidebar;
  }

  clearValues(name:string){
    if(name === 'manufacturer_id'){
      this.addEquipmentForm.get(name).setValue(null);
      this.addEquipmentForm.get('model_id').setValue(null);
      let formValues = {[name]:null,model_id:null};
      const id = this.activatedRoute.snapshot.paramMap.get('id');
      if(this.updateEquipment)this._graphql.updateEquipmentDetail(id, formValues);
      this.modelList = [];
    }else{
      this.addEquipmentForm.get(name).setValue(null);
      let formValues = {[name]:null};
      const id = this.activatedRoute.snapshot.paramMap.get('id');
      if(this.updateEquipment)this._graphql.updateEquipmentDetail(id, formValues);

    }
this.manufacturersList('')
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === '-' || event.key === '+') {
        event.preventDefault();
    }
}

}
