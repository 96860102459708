import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {DialogService, ModalData} from 'src/app/@fyxt/_services/dialog/dialog.service';

import { TableColumnTypes,ColumnSortType } from 'src/app/@fyxt/_shared/enums/table/table-column-types.enum';
import { AppTableSource } from 'src/app/@fyxt/_shared/models/app-table-model';

import {EditColumn, EditColumnsComponent, EditColumnsModalData} from 'src/app/@fyxt/_shared/_views';
import {switchMap, take} from "rxjs/operators";
import {BehaviorSubject} from "rxjs";
import {
  CoiFilter,
  ExpirationStatus,
  ExpiringWithinDateTypeLabel,
  ExpiringWithinDateTypeValue, ICoiFilters
} from '../../../models/coi/coi.interface';
import { LayoutService } from '../../../../_services/_layout/layout.service';
import { TableComponent } from '../../../_reusable_ui/_components/table/table.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { NgIf } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';

@Component({
    selector: 'fyxt-tab-coi',
    templateUrl: './tab-coi.component.html',
    styleUrls: ['./tab-coi.component.scss'],
    standalone: true,
    imports: [SearchInputComponent, MatDividerModule, NgIf, LoaderSmallComponent, TableComponent]
})
export class TabCoiComponent implements OnInit {
  private readonly editableColumns$: BehaviorSubject<any[]> = new BehaviorSubject<EditColumn[]>([]);
  @Input() dataSource!: AppTableSource;
  @Input() coiSearchValue!: string;
  @Input() coiFilterQuery!: ICoiFilters;
  @Input() isLoader!: boolean;
  @Input() highlightColumn: string;
  @Input() leaseEditColumnView!: [];
  @Input() moduleName: any;
  @Input() isNormalTable: any;
  @Output() onSearchValueChange = new EventEmitter<Event>();
  @Output() onSortValueChange = new EventEmitter<Event>();
  @Output() onEditColumnChange = new EventEmitter();
  @Output() onPaginationValueChange = new EventEmitter<Event>();
  @Output() onRowClickValueChange = new EventEmitter();
  @Output() onFilterValueChange = new EventEmitter();

  initialFilterState = {
    filterLists: [
      {
        label: CoiFilter.COMPANY_TYPE,
        isLocalSearch: true,
        isExpended: false,
        searchVal: '',
        selectAll: false,
        listItems: [
          { id: '1', name: 'Tenant', value: 'Tenant', selected: false },
          { id: '2', name: 'Vendor', value: 'Vendor', selected: false }
        ],
      },
      {
        label: CoiFilter.COMPANY,
        isExpended: false,
        searchVal: '',
        isLocalSearch: false,
        selectAll: false,
        enableSearch: true,
        listItems: [],
      },
      {
        label: CoiFilter.PROPERTIES,
        isExpended: false,
        searchVal: '',
        isLocalSearch: false,
        selectAll: false,
        enableSearch: true,
        listItems: [],
      },
      {
        label: CoiFilter.EXPIRING_ON_OR_AFTER,
        value: null,
      },
      {
        label: CoiFilter.EXPIRING_WITHIN,
        value: null,
        date_type: ExpiringWithinDateTypeLabel.DAYS,
      },
      {
        label: CoiFilter.EXPIRED,
        isExpended: false,
        selectAll: false,
        isLocalSearch: true,
        value: null,
        listItems: [
          { id: '0', name: 'None', value: null, selected: false },
          { id: '1', name: 'Yes', value: ExpirationStatus.EXPIRED, selected: false },
          { id: '2', name: 'No', value: ExpirationStatus.NOT_EXPIRED, selected: false }
        ],
      },
    ],
  };

  filtersDataSource: any = { ...this.initialFilterState };

  selectedFiltersList: any[] = [];

  constructor(
    public _dialogService: DialogService,
    public _layoutService: LayoutService,
  ) { }

  ngOnInit(): void {
  }
  editColumnPopup() {
    this.editableColumns$.next(this.leaseEditColumnView);
    this.editableColumns$
      .pipe(
        take(1),
        switchMap((columns) => {
          let popupData: ModalData<EditColumnsModalData> = {
            title: 'Edit Table Column',
            component: EditColumnsComponent,
            containerClass: ['modal-sm', 'modal_edit_colomn'],
            data: {
              columns,
              onChange: this.editColumnOrder.bind(this)
            }
          };
          return this._dialogService.openModal<EditColumnsModalData>(popupData);
        })
      )
      .subscribe(result => {});
  }

  editColumnOrder(columnLists: EditColumn[]): void {
    this.onEditColumnChange.emit(columnLists);
  }

  searchCoi(value: any): void {
    this.coiSearchValue = value;
    this.onSearchValueChange.emit(value);
  }

  coiSortChange(sortState: any) {
    this.onSortValueChange.emit(sortState);
  }

  coiPageChange(paginationEvent: any) {
    this.onPaginationValueChange.emit(paginationEvent);
  }

  onRowClick(event: any): void {
    this.onRowClickValueChange.emit(event);
  }

}
