import { ColumnSortType, TableColumnTypes } from '../../../enums/table/table-column-types.enum';

export const ruleTemplateTableHeaders = [
  {
    value: 'primary',
    sort: ColumnSortType.none,
    name: '',
    type: TableColumnTypes.checkbox,
    className: 'table-checkbox',
    resizeColumn:false,
  },
  {
    value: 'template_name',
    sort: ColumnSortType.none,
    name: 'Template Name',
    type: TableColumnTypes.text,
    className: 'table-header'
  },
  {
    value: 'lease_type',
    sort: ColumnSortType.none,
    name: 'Lease Type',
    type: TableColumnTypes.text,
    className: 'table-header'
  },
  {
    value: 'rules_applied',
    sort: ColumnSortType.none,
    name: 'Rules Applied',
    type: TableColumnTypes.text,
    className: 'table-header'
  },
  {
    value: 'created_by',
    sort: ColumnSortType.none,
    name: 'Created by',
    type: TableColumnTypes.text,
    className: 'table-header'
  },
  {
    value: 'created_date',
    sort: ColumnSortType.none,
    name: 'Date Created',
    type: TableColumnTypes.date,
    className: 'table-header'
  },
  {
    value: 'action',
    sort: ColumnSortType.none,
    name: '',
    type: TableColumnTypes.action,
    className: 'three-dot',
    actions: [{ label: 'Delete', value: 'Delete'}]
  },
];
