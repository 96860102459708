import { Component, OnInit } from '@angular/core';

import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';

import { TableColumnTypes,ColumnSortType } from 'src/app/@fyxt/_shared/enums/table/table-column-types.enum';
import { AppTableSource } from 'src/app/@fyxt/_shared/models/app-table-model';

import { EditColumnsComponent } from 'src/app/@fyxt/_shared/_views';
import { ActivatedRoute } from '@angular/router';
import { GraphqlEquipmentManagementService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/equipment-management/graphql-equipment-management.service';
import { TableService } from 'src/app/@fyxt/_services/table/table.service';
import { DatePipe, NgIf } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Config2Service } from 'src/app/services/v2/config2.service';
import { RestAPIService } from 'src/app/services/v2/rest-api.service';
import { UtilityService } from 'src/app/services/utility.service';
import { JobsService } from 'src/app/services/v2/jobs.service';
import {HttpErrorResponse} from "@angular/common/http";
import {ConfigService} from "../../../../../services/config.service";
import {DashboardService} from "../../../../../Modules/_fyxt_modules/dashboard/_services/dashboard.service";
import {PusherService} from "../../../../_services/pusher/pusher.service";
import {Subscription} from "rxjs";
import { TableComponent } from '../../../_reusable_ui/_components/table/table.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { MatDividerModule } from '@angular/material/divider';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';


@Component({
    selector: 'fyxt-tab-service-history',
    templateUrl: './tab-service-history.component.html',
    styleUrls: ['./tab-service-history.component.scss'],
    standalone: true,
    imports: [SearchInputComponent, MatDividerModule, NgIf, LoaderSmallComponent, TableComponent]
})
export class TabServiceHistoryComponent implements OnInit {

  dataSource!: AppTableSource;
  isLoader:boolean = false;
  sortColumn:string=null;
  sortDirection:string=null;
  pageSize:number = 25;
  updateSeenUnseenSubscription: Subscription;


  constructor(
    public _dialogService: DialogService,
    private activatedRoute: ActivatedRoute,
    public _graphql:GraphqlEquipmentManagementService,
    public tableService: TableService,
    private datePipe: DatePipe,
    public _Config2: Config2Service,
    public _baseService: RestAPIService,
    public _utilService: UtilityService,
    public _jobService: JobsService,
     public _Config: ConfigService,
    public _dashboardService: DashboardService,
    public _pusherService: PusherService,
  ) { }

 tableHeaders = [
    { value: 'starred_jobs', name: '', isDisabled: false, type: TableColumnTypes.starred },
    {
      value: 'id',
      sort: ColumnSortType.none,
      name: 'Job ID',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'last_activity',
      sort: ColumnSortType.none,
      name: 'Last Activity',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'brief_description',
      sort: ColumnSortType.none,
      name: 'Brief Description',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'category',
      sort: ColumnSortType.none,
      name: 'Category',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'status',
      sort: ColumnSortType.none,
      name: 'Status',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'service_type',
      sort: ColumnSortType.none,
      name: 'Service Type',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'source_type',
      sort: ColumnSortType.none,
      name: 'Source Type',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
  ];

  serviceListTableData:any[]=[];
  selectedViewTableRowsTotal: number;
  selectedViewTableRowsPageSize: number;
  selectedViewTableRowsPage: number;
  selectedViewTableCurrentPage: number;
  moduleName = "equipment-management";
  searchInput :any = "";

  ngOnInit(): void {
    this.serviceHistory(this.searchInput,1);
    this.realTimeUpdate();
  }

  ngOnDestroy() {
    this.updateSeenUnseenSubscription?.unsubscribe();
  }


  serviceHistory(searchInput:any,pageNumber:number,pageSize?:number,sortColumn?:any,sortDirection?:any,){
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.isLoader = true ;
    this._graphql.serviceHistoryList(id,searchInput,pageNumber,pageSize,sortColumn,sortDirection).subscribe({
      next:(res:any)=>{
        this.serviceListTableData = res?.results || [];
        this.selectedViewTableRowsTotal = res?.count;
      },
      complete:()=> {
          this.isLoader = false;
          this.selectedViewTableCurrentPage = pageNumber;
        if(sortColumn || sortDirection ){
          let sortedHeaders:any[] = this.tableHeaders.map(element => {
            (element.value === sortColumn) ? (element.sort = sortDirection) : (element.sort = ColumnSortType.none );

            return element;
          });
          this.serviceListTableData =  this.serviceListTableData.map(obj => ({...obj, last_activity:(obj.last_activity!==null)? this.datePipe.transform(obj.last_activity, 'MM/dd/yyyy'):obj.last_activity}));
          this.dataSource = this.tableService.setTableData(this.moduleName,sortedHeaders,this.serviceListTableData, this.selectedViewTableRowsTotal,pageNumber-1, this.pageSize);
        }
        else{
          this.serviceListTableData =  this.serviceListTableData.map(obj => ({...obj, last_activity:(obj.last_activity!==null)? this.datePipe.transform(obj.last_activity, 'MM/dd/yyyy'):obj.last_activity}));
          this.tableHeaders.map(element => {
            return element.sort = ColumnSortType.none;
          });
          this.dataSource = this.tableService.setTableData(this.moduleName,this.tableHeaders,this.serviceListTableData, this.selectedViewTableRowsTotal,pageNumber-1, this.pageSize);
        }
      },
    })
  }

  onRowClick(event:any){
    if (event.id) {
      const payload = {
        jobs: [event.id],
        seen: true
      };
      this._dashboardService.seenUnseenOrStarUnstarJob(payload).subscribe({
        next: (response: any) => {
          },
        error: (error: HttpErrorResponse) => {
        },
        complete: () => {
        }
      });
      this._jobService.isJob_HalfPageView = true;
      this._jobService.isLeftArrow_Minimise = false;
      this._jobService.isRightArrow_Minimise = false;
      this._jobService.sourceBack = 'equipment-management';
      this._jobService.showJobInfo.next(true);
      this._jobService.getSelectedJobInfo(event.id, false);
    }
  }

// If the Engineer is valid redirect to respective equipment or show toast message that the Engineer is not valid
checkJobId(jobId:any){
  let requestURL = environment.baseURL + this._Config2.getSelectedJobDetails;
  requestURL = requestURL.replace('{ID}', jobId);
  this._baseService.doGET(requestURL).subscribe({
    next: (response: any) => {
      const currentUserInfo: any = localStorage.getItem('userobj')?JSON.parse(localStorage.getItem('userobj')): {};
      if(currentUserInfo.current_Role==="Owner" || currentUserInfo.current_Role==="Manager"){
        window.open('/pm/dashboard/'+jobId);
      }
      if(currentUserInfo.current_Role==="Engineer"){
        window.open('/engineer/dashboard/'+jobId);
      }
    },
    error:(error:any)=>{
      this._utilService.showError('', 'This job is not assigned to this engineer');
    },
  })
}

  onPageChange(event:any){
    this.pageSize = event.pageSize;
    this.serviceHistory(this.searchInput,event.pageIndex+1, event.pageSize, this.sortColumn, this.sortDirection)
  }

  onSearch(inputValue:any){
    this.searchInput = inputValue;
    this.serviceHistory(this.searchInput,1, this.pageSize, this.sortColumn, this.sortDirection);
  }


  onSortChange(e:any){
    this.sortColumn = e.active;
    this.sortDirection = e.direction;
    this.serviceHistory(this.searchInput,1, this.pageSize, this.sortColumn, this.sortDirection);
  }

  editColumnPopup() {
    let popupData = {
      title: 'Edit Table Column',
      component: EditColumnsComponent,
      containerClass: ['modal-sm', 'modal_edit_colomn']
    };

    this._dialogService.openModal(popupData).subscribe(result => {});
  }

  markAsStarredUnstarred(payload: any) {
    this._Config.isLoader = true;
    this._dashboardService.seenUnseenOrStarUnstarJob(payload).subscribe(
      {
        next: (response: any) => {
          this._utilService.showSuccess('', 'Updated Successfully.');
          },
        error: (error: HttpErrorResponse) => {
          this._Config.isLoader = false;
          this._utilService.showErrorMessage(error);
        },
        complete: () => {
          this._Config.isLoader = false;
        }
      }
    );
  }

  onStarValueChange(event): void {
    const payload = {
      jobs: [event.id],
      star: event.is_starred
    };
    this.markAsStarredUnstarred(payload);
  }

  realTimeUpdate(): void {
    /** Associate Job _SEEN_UNSEEN Update **/
    this.updateSeenUnseenSubscription = this._pusherService.getDASHBOARD_SEEN_UNSEEN_Event(this._jobService.currentUserInfo.id, this._jobService.currentUserInfo.account).subscribe((event: any) => {
       // Seen/Unseen Update
      if (event?.event_key == 'user_job_seen') {
        this.dataSource.rows.map((row: any) => {
          if (row.id == event.id) {
            row.is_seen = event.value;
          }
        });
        //Refresh the Table data with latest response
        this.dataSource = this.tableService.setTableData(this.moduleName, this.dataSource.headers, this.dataSource.rows, this.selectedViewTableRowsTotal,this.selectedViewTableCurrentPage-1, this.pageSize);
      }
    });
  }
}
