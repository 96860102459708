// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub_text {
  color: #9FA7B3;
  opacity: 1;
  font-size: 12px;
}

.reschedule {
  padding-top: 24px;
}

.error {
  color: red;
  font-size: 12px;
}

.ds-description {
  width: 215px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_views/_popup_views/reschedule/reschedule.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,UAAA;EACA,eAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAKA;EACI,UAAA;EACA,eAAA;AAFJ;;AAKA;EACI,uBAAA;AAFJ","sourcesContent":[".sub_text {\n    color: #9FA7B3;\n    opacity: 1;\n    font-size: 12px;\n      \n}\n.reschedule{\n    padding-top: 24px;\n}\n// ::ng-deep .mat-dialog-content {\n//     padding: 0 !important;\n// }\n\n.error {\n    color: red;\n    font-size: 12px;\n  }\n  \n.ds-description{\n    width:215px !important\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
