
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'fyxt-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
    standalone: true,
    imports: [MatCardModule]
})
export class CardComponent implements OnInit {

  @Input() class:string = '';

  constructor() { }

  ngOnInit(): void {
  }
}
