import { Component, EventEmitter, Input, OnInit, Output, forwardRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
    selector: 'fyxt-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SwitchComponent)
        }
    ],
    standalone: true,
    imports: [MatSlideToggleModule, FormsModule, NgxIntlTelInputModule]
})
export class SwitchComponent implements OnInit {
  @Input() disabled: boolean = false;
  @Output() change = new EventEmitter<boolean>();
  @Input() customClass: string = '';

  inputControl!: any;

  public changed!: (value: string) => void;
  public touched!: () => void;
  onTouch: any = () => { }

  constructor() { }

  ngOnInit(): void {
  }


  public onChange(event: Event): void {
    const value: string = (<HTMLInputElement>event.target).value;
    this.changed(value);
  }


  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(inputVal: any) {
    this.inputControl = inputVal;
  }

  /**
   * On Change Event
   * @param event 
   */
  slideToggleChange(event: any): void {
    this.change.emit(event);
  }

}
