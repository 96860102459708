// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep.Approved {
  color: green !important;
}

::ng-deep.Rejected {
  color: red !important;
}

::ng-deep .modal-job-cost .mat-mdc-dialog-container, ::ng-deep .modal-job-cost .mat-mdc-dialog-content {
  padding: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_reusable_ui/_components/modal/modal.component.scss"],"names":[],"mappings":"AAAE;EACE,uBAAA;AACJ;;AAEE;EACE,qBAAA;AACJ;;AAEE;EACE,qBAAA;AACJ","sourcesContent":["  ::ng-deep.Approved {\n    color: green !important;\n  }\n  \n  ::ng-deep.Rejected {\n    color: red !important;\n  }\n\n  ::ng-deep .modal-job-cost .mat-mdc-dialog-container, ::ng-deep .modal-job-cost .mat-mdc-dialog-content {\n    padding: 0 !important;\n  }\n  .custom-padding {\n    // padding: 24px 24px 0;\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
