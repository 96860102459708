import { Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { GraphqlAdditionalDataService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/additional-data/graphql-additional-data.service';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { GraphqlEquipmentManagementService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/equipment-management/graphql-equipment-management.service';
import { GraphqlEquipmentTypeService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/equipment-type/graphql-equipment-type.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ExportViewComponent} from "../export-view/export-view.component";
import {AddViewComponent} from "../add-view/add-view.component";
import {DeleteViewComponent} from "../delete-view/delete-view.component";
import {CustomTabsService} from "../../../../../Modules/_fyxt_modules/companies/_services/custom-tabs.service";
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconPinComponent } from '../../../_reusable_ui/_components/icon-pin/icon-pin.component';
import { IconSwitchComponent } from '../../../_reusable_ui/_components/icon-switch/icon-switch.component';
import { ThreeDotsDropdownComponent } from '../../../_reusable_ui/_components/three-dots-dropdown/three-dots-dropdown.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { NgIf, NgFor } from '@angular/common';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';
import { TabComponent } from '../../../_reusable_ui/_components/tab/tab.component';

@Component({
    selector: 'fyxt-manage-views',
    templateUrl: './manage-views.component.html',
    styleUrls: ['./manage-views.component.scss'],
    standalone: true,
    imports: [TabComponent, SearchInputComponent, FormsModule, NgxIntlTelInputModule, NgIf, LoaderSmallComponent, NgFor, ThreeDotsDropdownComponent, IconSwitchComponent, IconPinComponent, MatTooltipModule, InfiniteScrollModule]
})
export class ManageViewsComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<null> = new Subject();

  _viewModule: string
  isLoader: boolean = false;

  mainTabsList = [
    { name: "My Views", value: "my_views", source: "my", count: 0 },
    { name: "Shared by Others", value: "shared_by_others", source: "shared", count: 0 },
    { name: "Standard Views", value: "standard_views", source: "standard", count: 0 }
  ];

  exportViewSchedule = { name: "Scheduled View Exports", value: "scheduled_view_exports", source: "scheduled_view", count: 0 }

  tabData: any[] = [];

  equimentModuleViews: any = {};

  //Company & Contact pagination Variabls
  allViewsListPageNumber: number = 1;
  allViewsListTotal: number;
  allViewsListSize: number;
  allViewsListPageCount: number;


  editManageView: boolean = false;

  selectedTab: any;
  searchInput: any = "";

  viewOptions = [{ label: 'Set as Default View', value: 'default' }];
  defaultViewEnabledViewOptions: any = [{ label: 'Set as Default View', value: 'default', disabled: true }];
  defaultViewOptions = [{ label: 'Set as Default View', value: 'default' }];
  setAsDefaultDisabledViewOptions: any = [{ label: 'Set as Default View', value: 'default', disabled: true }];
  viewSubscription: Subscription;
  exportViewScheduleOptionsForDashboard = [{ label: 'Edit View', value: 'Edit View' },
  { label: 'Manage Schedule', value: 'Manage Schedule' }, { label: 'Delivery History', value: 'Delivery History' }, { label: 'Delete View', value: 'delete' }];
  filterQuery: any;
  filtersDataSource: any;
  dateFilters: any;
  selectViewDetailsFromThreeDotMenu: any;
  selectedTabFromDashboard: any;
  dateFilterMapper = {
    "0": "today",
    "-1": "yesterday",
    "7": "next 7 days",
    "14": "next 14 days",
    "30": "next 30 days",
    "-7": "last 7 days",
    "-14": "last 14 days",
    "-30": "last 30 days",
  }
  selectedFilter: any = [];
  customFilter: any = [];
  currentUserInfo: any = localStorage.getItem('userobj') ? JSON.parse(localStorage.getItem('userobj')) : {};
  sortValue: any;


  constructor(
    public _dialogService: DialogService,
    public _utilService: UtilityService,
    public _graphqlEquipType: GraphqlEquipmentTypeService,
    public graphqlEquip: GraphqlEquipmentManagementService,
    public additionalData: GraphqlAdditionalDataService,
    public _customTabsService: CustomTabsService,
    public dialogRef: MatDialogRef<ManageViewsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this._viewModule = this._dialogService._viewModule;
    if (this._viewModule == "timeSheet") {
      this._viewModule = this._viewModule.toLowerCase();
    }
    this.tabCountTriggers();
    this.filtersDataSource = this.data?.data?.filtersDataSource ? this.data.data.filtersDataSource : {};
    this.filterQuery = this.data?.data?.filterQuery ? this.data.data.filterQuery : {};
    this.dateFilters = this.data?.data?.dateFilters ? this.data.data.dateFilters : {};
    this.selectedTabFromDashboard =  this.data?.data?.selectedTabFromDashboard ? this.data.data.selectedTabFromDashboard : {};
    this.sortValue = this.data?.data?.sortValue ? this.data.data.sortValue : {}
  }

  ngOnInit(): void {
    this.selectedTab = this.mainTabsList[0];
    if ((this._viewModule == "dashboard" || this._viewModule == "timesheet") && (this.currentUserInfo?.current_Role == 'Manager' || this.currentUserInfo?.current_Role == 'Owner')) {
      this.mainTabsList.push(this.exportViewSchedule);
      this.viewOptions = this.viewOptions.concat(this.exportViewScheduleOptionsForDashboard);
      this.defaultViewEnabledViewOptions = this.defaultViewEnabledViewOptions.concat(this.exportViewScheduleOptionsForDashboard);
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this._viewModule == "dashboard" || this._viewModule == "property") {
      if (this.editManageView) {
        this.dialogRef.close({ isUpdate: true, action: 'refresh', selectedView: {} });
      }
    }

    this.destroy$.next(null);
    this.destroy$.complete();
  }

  tabCountTriggers(){
    if (this._viewModule == "dashboard" || this._viewModule == "timesheet" || this._viewModule == "property") {
      this.loadDashboardtimesheetManageViewsTabsCount();
    }
    else if (this._viewModule == 'company' || this._viewModule == 'contact') {
      this.loadCompanyContactsManageViewsTabsCount();
    }
    else if (this._viewModule == "equipment_type" || this._viewModule == "equipment" || this._viewModule === "additional_data_item") {
      this.getAllEquipmentViewsListWithoutSelectingTabDetails();
    } else if (this._viewModule == "coi") {
      this.loadCoiManageViewsTabsCount();
    } else if (this._viewModule === 'lease') {
      this.loadLeaseManageViewsTabsCount();
    } else if (this._viewModule === 'service-planner') {
      this._viewModule = 'planner';
      this.loadDashboardtimesheetManageViewsTabsCount();
    }
  }

  getAllEquipmentViewsListWithoutSelectingTabDetails() {
    // this.isLoader = true;
    if (this._viewModule === "equipment_type") {
      this._graphqlEquipType.viewsListWithoutSelectingTabDetails().subscribe({
        next: (res: any) => {
          this.mainTabsList[0].count = res.my_views?.total || 0;
          this.mainTabsList[1].count = res.shared_by_others?.total || 0;
          this.mainTabsList[2].count = res.standard?.total || 0;
          this.equimentModuleViews = res;
          if (this.selectedTab.value === 'my_views') {
            this.tabData = this.equimentModuleViews.my_views?.items || [];
            this.allViewsListTotal = this.equimentModuleViews.my_views?.total;
            this.allViewsListSize = this.equimentModuleViews.my_views?.size;
            this.allViewsListPageCount = Math.round(this.allViewsListTotal / this.allViewsListSize);
          } else if (this.selectedTab.value === 'shared_by_others') {
            this.tabData = this.equimentModuleViews.shared_by_others?.items || [];
            this.allViewsListTotal = this.equimentModuleViews.shared_by_others?.total;
            this.allViewsListSize = this.equimentModuleViews.shared_by_others?.size;
            this.allViewsListPageCount = Math.round(this.allViewsListTotal / this.allViewsListSize);
          } else {
            this.tabData = this.equimentModuleViews.standard?.items || [];
          }
          this.isLoader = false;
        }
      })
    }
    else if (this._viewModule === "equipment") {
      this.graphqlEquip.viewsListWithoutSelectingTabDetails().subscribe({
        next: (res: any) => {
          this.mainTabsList[0].count = res.my_views?.total || 0;
          this.mainTabsList[1].count = res.shared_by_others?.total || 0;
          this.mainTabsList[2].count = res.standard?.total || 0;
          this.equimentModuleViews = res;
          if (this.selectedTab.value === 'my_views') {
            this.tabData = this.equimentModuleViews.my_views?.items || [];
            this.allViewsListTotal = this.equimentModuleViews.my_views?.total;
            this.allViewsListSize = this.equimentModuleViews.my_views?.size;
            this.allViewsListPageCount = Math.round(this.allViewsListTotal / this.allViewsListSize);
          } else if (this.selectedTab.value === 'shared_by_others') {
            this.tabData = this.equimentModuleViews.shared_by_others?.items || [];
            this.allViewsListTotal = this.equimentModuleViews.shared_by_others?.total;
            this.allViewsListSize = this.equimentModuleViews.shared_by_others?.size;
            this.allViewsListPageCount = Math.round(this.allViewsListTotal / this.allViewsListSize);
          } else {
            this.tabData = this.equimentModuleViews.standard?.items || [];
          }
          this.isLoader = false;
        }
      })
    }
    else if (this._viewModule === "additional_data_item") {
      this.additionalData.viewsListWithoutSelectingTabDetails().subscribe({
        next: (res: any) => {
          this.mainTabsList[0].count = res.my_views?.total || 0;
          this.mainTabsList[1].count = res.shared_by_others?.total || 0;
          this.mainTabsList[2].count = res.standard?.total || 0;
          this.equimentModuleViews = res;
          if (this.selectedTab.value === 'my_views') {
            this.tabData = this.equimentModuleViews.my_views?.items || [];
            this.allViewsListTotal = this.equimentModuleViews.my_views?.total;
            this.allViewsListSize = this.equimentModuleViews.my_views?.size;
            this.allViewsListPageCount = Math.round(this.allViewsListTotal / this.allViewsListSize);
          } else if (this.selectedTab.value === 'shared_by_others') {
            this.tabData = this.equimentModuleViews.shared_by_others?.items || [];
            this.allViewsListTotal = this.equimentModuleViews.shared_by_others?.total;
            this.allViewsListSize = this.equimentModuleViews.shared_by_others?.size;
            this.allViewsListPageCount = Math.round(this.allViewsListTotal / this.allViewsListSize);
          } else {
            this.tabData = this.equimentModuleViews.standard?.items || [];
          }
          this.isLoader = false;
        }
      })
    }
  }


  /**
   * On Change Tabs
   * @param selectedTabValue
   */
  onSelectTab(selectedTabValue: any) {
    this.selectedTab = selectedTabValue;
    this.searchInput = '';
    this.tabData = [];
    if (this._viewModule == 'dashboard' || this._viewModule == "property" || this._viewModule == 'planner') {
      this.loadDashboardSelectedTabViews(selectedTabValue);
    } else if (this._viewModule == 'timesheet') {
      this.loadtimesheetSelectedTabViews(selectedTabValue);
    }
    else if (this._viewModule == 'company' || this._viewModule == 'contact') {
      this.allViewsListPageNumber = 1;
      this.searchInput = '';
      this.loadCompanyContactsSelectedTabViews(selectedTabValue, false);
    } else if (this._viewModule == 'equipment' || this._viewModule == 'equipment_type' || this._viewModule == 'additional_data_item') {
      this.onSelectTabEquipment(selectedTabValue);
    } else if (this._viewModule === 'coi') {
      this.allViewsListPageNumber = 1;
      this.searchInput = '';
      this.loadCoiSelectedTabViews(selectedTabValue);
    } else if (this._viewModule === 'lease') {
      this.allViewsListPageNumber = 1;
      this.searchInput = '';
      this.loadLeaseSelectedTabViews(selectedTabValue);
    }


    // if (selectedTabValue.source == "my") {
    //   this.viewOptions = [{ label: 'Set as Default View', value: 'default' }];
    // }
    // else {
    //   this.viewOptions = [{ label: 'Set as Default View', value: 'default' }];
    // }

    // this.tabCountTriggers();

  }

  /**
   * On Dropdown Menu chage
   * @param selectedMenu
   * @param selectedView
   */
  menuDropdownMenuChange(selectedMenu: any, selectedView: any) {
    if (selectedMenu.value == 'delete') {
      // this.deleteView(selectedMenu.value, selectedView);
      this.openDeleteViewPopup(selectedMenu, selectedView);
    }
    else if (selectedMenu.value == 'default') {
      this.doViewActions(selectedMenu.value, selectedView);
    } else if (selectedMenu.value == 'Manage Schedule' || selectedMenu.value == 'Delivery History') {
      this.openExportViewPopup(selectedMenu, selectedView);
    } else if (selectedMenu.value == 'Edit View') {
      this.getViewDetails(selectedView);
      // this.openEditViewPopup(selectedMenu, selectedView);
    }
  }


  onSearch(inputValue: any) {
    this.searchInput = inputValue;
    if (inputValue.length >= 2 || inputValue.length === 0) {
      if (this._viewModule == 'dashboard' || this._viewModule == "property" || this._viewModule == 'planner') {
        this.loadDashboardSelectedTabViews(this.selectedTab, false, true);
      } else if (this._viewModule == 'timesheet') {
        this.loadtimesheetSelectedTabViews(this.selectedTab, false, true);
      }
      else if (this._viewModule == 'company' || this._viewModule == 'contact') {
        this.allViewsListPageNumber = 1;
        this.loadCompanyContactsSelectedTabViews(this.selectedTab, false);
      } else if (this._viewModule == 'equipment' || this._viewModule == 'equipment_type' || this._viewModule == 'additional_data_item') {
        let source = this.selectedTab.value !== 'standard_views' ? this.selectedTab.value : 'standard';
        this.graphqlEquip.searchViews(this._viewModule, source, this.searchInput, 1).subscribe({
          next: (res: any) => {
            this.tabData = res.listManageViews?.items || [];
          }
        });
      }
      else if (this._viewModule == 'coi') {
        this.loadCoiSelectedTabViews(this.selectedTab, false, true);
      } else if (this._viewModule == 'lease') {
        this.loadLeaseSelectedTabViews(this.selectedTab, false, true);
      }
    }
  }

  infiniteScroll() {
    if (this.allViewsListPageNumber <= this.allViewsListPageCount) {
      this.allViewsListPageNumber++;
      if (this._viewModule == 'company' || this._viewModule == 'contact') {
        this.loadCompanyContactsSelectedTabViews(this.selectedTab, true);
      } else if (this._viewModule == 'equipment' || this._viewModule == 'equipment_type' || this._viewModule == 'additional_data_item') {
        let source = this.selectedTab.value !== 'standard_views' ? this.selectedTab.value : 'standard';
        this.graphqlEquip.paginationViews(this._viewModule, source, this.searchInput, this.allViewsListPageNumber).subscribe({
          next: (res: any) => {
            if (res?.listManageViews?.items) {
              this.tabData = this.tabData.concat(res?.listManageViews?.items) || [];
            }
          }
        });
      }
    }
  }


  loadDashboardSelectedTabViews(selectedTabValue: any, isPagination?: boolean, isSearch?: boolean) {
    this.selectedTab = selectedTabValue;
    this.isLoader = true;

    let requestURL = "";
    // let requestURL = environment.LocalDev_URL_V2 + this._viewModule + '/' + this.selectedTab?.value;

    if (selectedTabValue.value == "my_views") {
      requestURL = environment.LocalDev_URL_V2 + 'manage-dashboard/my_views/?source=' + this._viewModule;
    }
    else if (selectedTabValue.value == "shared_by_others") {
      requestURL = environment.LocalDev_URL_V2 + 'manage-dashboard/shared_by_others/?source=' + this._viewModule;
    }
    else if (selectedTabValue.value == "standard_views") {
      requestURL = environment.LocalDev_URL_V2 + 'manage-dashboard/standard_views/?source=' + this._viewModule;
    } else if (selectedTabValue.value == "scheduled_view_exports") {
      requestURL = environment.LocalDev_URL_V2 + 'manage-dashboard/my_views/?source=' + this._viewModule + '&scheduled_view=true';
      this.viewOptions = this.viewOptions.concat(this.exportViewScheduleOptionsForDashboard);
      this.defaultViewEnabledViewOptions = this.defaultViewEnabledViewOptions.concat(this.exportViewScheduleOptionsForDashboard);
    }

    if (isSearch) {
      requestURL += '&custom-search=' + this.searchInput;
    }

    if (this.viewSubscription) {
      this.viewSubscription.unsubscribe();
    }
    this.viewSubscription = this._dialogService.doGET(requestURL).subscribe(
      {
        next: (response: any) => {
          this.tabData = response || [];
          this.tabCountTriggers();
        },
        error: (error) => {
          this.isLoader = false;
        },
        complete: () => {
          this.isLoader = false;
          if (this._viewModule == 'dashboard' && (this.currentUserInfo?.current_Role == 'Manager' || this.currentUserInfo?.current_Role == 'Owner')) {
            if (selectedTabValue.value == "my_views" || selectedTabValue.value == "scheduled_view_exports") {
              this.viewOptions = [];
              this.defaultViewEnabledViewOptions = [];
              this.viewOptions = this.defaultViewOptions.concat(this.exportViewScheduleOptionsForDashboard);
              this.defaultViewEnabledViewOptions = this.setAsDefaultDisabledViewOptions.concat(this.exportViewScheduleOptionsForDashboard);
            } else if (selectedTabValue.value == "shared_by_others" || selectedTabValue.value == "standard_views") {
              this.viewOptions = [];
              this.defaultViewEnabledViewOptions = [];
              this.viewOptions = this.defaultViewOptions;
              this.defaultViewEnabledViewOptions = this.setAsDefaultDisabledViewOptions;
            }
          }
        }
      }
    );

  }

  loadtimesheetSelectedTabViews(selectedTabValue: any, isPagination?: boolean, isSearch?: boolean) {
    this.selectedTab = selectedTabValue;
    this.isLoader = isPagination ? false : true;

    let requestURL = "";
    // let requestURL = environment.LocalDev_URL_V2 + this._viewModule + '/' + this.selectedTab?.value;

    if (selectedTabValue.value == "my_views") {
      requestURL = environment.LocalDev_URL_V2 + 'manage-dashboard/my_views/?source=timesheet';
    }
    else if (selectedTabValue.value == "shared_by_others") {
      requestURL = environment.LocalDev_URL_V2 + 'manage-dashboard/shared_by_others/?source=timesheet';
    }
    else if (selectedTabValue.value == "standard_views") {
      requestURL = environment.LocalDev_URL_V2 + 'manage-dashboard/standard_views/?source=timesheet';
    } else if (selectedTabValue.value == "scheduled_view_exports") {
      requestURL = environment.LocalDev_URL_V2 + 'manage-dashboard/my_views/?source=timesheet&scheduled_view=true';
      this.viewOptions = this.viewOptions.concat(this.exportViewScheduleOptionsForDashboard);
      this.defaultViewEnabledViewOptions = this.defaultViewEnabledViewOptions.concat(this.exportViewScheduleOptionsForDashboard);
    }

    if (isSearch) {
      requestURL += '&custom-search=' + this.searchInput;
    }
    if (this.viewSubscription) {
      this.viewSubscription.unsubscribe();
    }
    this.viewSubscription = this._dialogService.doGET(requestURL).subscribe(
      {
        next: (response: any) => {
          this.tabData = response || [];
          this.tabCountTriggers();
          // this.tabData.map(elm => { elm.shared = elm.is_private, elm.pin = elm.pinned });
        },
        error: (error) => {
          this.isLoader = false;
          if (error.status == 0) {
            this._utilService.showError('', error.message);
          }
        },
        complete: () => {
          this.isLoader = false;
          if (this._viewModule == 'timesheet' && (this.currentUserInfo?.current_Role == 'Manager' || this.currentUserInfo?.current_Role == 'Owner')) {
            if (selectedTabValue.value == "my_views" || selectedTabValue.value == "scheduled_view_exports") {
              this.viewOptions = [];
              this.defaultViewEnabledViewOptions = [];
              this.viewOptions = this.defaultViewOptions.concat(this.exportViewScheduleOptionsForDashboard);
              this.defaultViewEnabledViewOptions = this.setAsDefaultDisabledViewOptions.concat(this.exportViewScheduleOptionsForDashboard);
            } else if (selectedTabValue.value == "shared_by_others" || selectedTabValue.value == "standard_views") {
              this.viewOptions = [];
              this.defaultViewEnabledViewOptions = [];
              this.viewOptions = this.defaultViewOptions;
              this.defaultViewEnabledViewOptions = this.setAsDefaultDisabledViewOptions;
            }
          }
        }
      }
    );
  }

  loadCompanyContactsSelectedTabViews(selectedTabValue: any, isPagination?: boolean) {
    if (isPagination) {
      this.isLoader = false;
    } else {
      this.isLoader = true;
    }
    this.selectedTab = selectedTabValue;
    let requestURL = environment.fastAPI_Actual_URL + "/" + this._viewModule + '-views/?source=' + this.selectedTab.source + `&page=` + this.allViewsListPageNumber + '&search=' + this.searchInput;
    this._dialogService.doGET(requestURL).subscribe(
      {
        next: (response: any) => {
          if (this._viewModule == 'company' || this._viewModule == 'contact') {
            if (isPagination) {
              this.tabData = this.tabData.concat(response.items[0].data);
            } else {
              this.tabData = response.items[0].data;
              this.allViewsListTotal = response.total;
              this.allViewsListSize = response.size;
              this.allViewsListPageCount = Math.round(this.allViewsListTotal / this.allViewsListSize);
            }
            this.tabCountTriggers();
          }
        },
        error: (error) => {
          this.isLoader = false;
        },
        complete: () => {
          this.isLoader = false;
        }
      }
    );
  }

  loadCoiSelectedTabViews(selectedTabValue: any, isPagination?: boolean, isSearch?: boolean) {
    this.selectedTab = selectedTabValue;
    this.isLoader = !isPagination;

    let requestURL = "";
    const params = {
      source: 'coi',
    }
    if (selectedTabValue.value == "my_views") {
      requestURL = environment.coiMsURL + '/coi-view/own';
    }
    else if (selectedTabValue.value == "shared_by_others") {
      requestURL = environment.coiMsURL + '/coi-view/others-shared';
    }
    else if (selectedTabValue.value == "standard_views") {
      requestURL = environment.coiMsURL + '/coi-view/standard-views';
    }

    if (isSearch) {
      params['search'] = this.searchInput;
    }
    this._dialogService.doGET(requestURL, params).pipe(map(res => res.data)).subscribe(
      {
        next: (response: any) => {
          this.tabData = response || [];
          this.tabCountTriggers();
          // this.tabData.map(elm => { elm.shared = elm.is_private, elm.pin = elm.pinned });
        },
        error: (error) => {
          this.isLoader = false;
        },
        complete: () => {
          this.isLoader = false;
        }
      }
    );
  }

  loadLeaseSelectedTabViews(selectedTabValue: any, isPagination?: boolean, isSearch?: boolean) {
    this.selectedTab = selectedTabValue;
    this.isLoader = !isPagination;

    let requestURL = "";
    const params = {
      source: 'lease',
    }
    if (selectedTabValue.value == "my_views") {
      requestURL = environment.lease_URL + '/leases-views/own';
    }
    else if (selectedTabValue.value == "shared_by_others") {
      requestURL = environment.lease_URL + '/leases-views/others-shared';
    }
    else if (selectedTabValue.value == "standard_views") {
      requestURL = environment.lease_URL + '/leases-views/standard-views';
    }

    if (isSearch) {
      params['search'] = this.searchInput;
    }
    this._dialogService.doGET(requestURL, params)
      .subscribe(
        {
          next: (response: any) => {
            this.tabData = response || [];
            this.tabCountTriggers();
            // this.tabData.map(elm => { elm.shared = elm.is_private, elm.pin = elm.pinned });
          },
          error: (error) => {
            this.isLoader = false;
          },
          complete: () => {
            this.isLoader = false;
          }
        }
      );
  }


  /**
   * Do Action by Selected Action Type
   * @param type
   * @param selectedView
   */
  doViewActions(type: string, selectedView: any) {
    if (this._viewModule === 'lease') {
      this.manageLeaseView(type, selectedView);
    } else if (this._viewModule == "dashboard" || this._viewModule == "property" || this._viewModule == "planner") {
      this.dashboardManageViewUpdate(type, selectedView);
    } else if (this._viewModule == "timesheet") {
      this.timesheetManageViewUpdate(type, selectedView);
    }
    else if (this._viewModule == "company" || this._viewModule == "contact") {
      this.companyContactsManageViewUpdate(type, selectedView);
    }
    else if (this._viewModule == 'equipment' || this._viewModule == 'equipment_type' || this._viewModule == 'additional_data_item') {
      if (type === 'share') type += 'd';
      let updateValue: any = { [type]: selectedView[type] }
      this.graphqlEquip.updateView(this._viewModule, selectedView?.id, updateValue).subscribe({
        next: (res: any) => {
          this._utilService.showSuccess('', 'Views Updated Successfully');
          this._dialogService.isDefaultViewChanged = true;
        },
        error: (err: any) => {
          this.graphqlEquip.catchErrorGraphQL(err);
          // this._utilService.showError('', JSON.parse(err.message).detail);
          if (type == 'pin') {
            selectedView.pin = !selectedView.pin;
          }
        },
        complete: () => {
          if (this.searchInput && this.searchInput != '') {
            let source = this.selectedTab.value !== 'standard_views' ? this.selectedTab.value : 'standard';
            this.graphqlEquip.searchViews(this._viewModule, source, this.searchInput, 1).subscribe({
              next: (res: any) => {
                this.tabData = res.listManageViews?.items || [];
              }
            });
          } else {
            this.getAllEquipmentViewsListWithoutSelectingTabDetails();
          }
        }
      });
    }
    else if (this._viewModule == 'coi') {
      this.coiManageViewUpdate(type, selectedView);
    }
  }

  dashboardManageViewUpdate(type: string, selectedView: any): void {
    let postBody: any = {
      "view_id": selectedView.id,
    };
    // let requestURL = environment.LocalDev_URL_V2 + this._viewModule + '/' + selectedView.id + '/';
    let requestURL = "";

    if (this.selectedTab.value == "my_views") {
      requestURL = environment.LocalDev_URL_V2 + 'manage-dashboard/myview-field/?source=' + this._viewModule;
    }
    else if (this.selectedTab.value == "shared_by_others") {
      requestURL = environment.LocalDev_URL_V2 + 'manage-dashboard/shared-field/?source=' + this._viewModule;
    }
    else if (this.selectedTab.value == "standard_views") {
      requestURL = environment.LocalDev_URL_V2 + 'manage-dashboard/standard-field/?source=' + this._viewModule;
    } else if (this.selectedTab.value == "scheduled_view_exports") {
      requestURL = environment.LocalDev_URL_V2 + 'manage-dashboard/myview-field/?source=' + this._viewModule;
    }


    if (type == 'pin') {
      postBody.pin = selectedView.pin

      // if (!selectedView.pin)
      //   postBody.default = false;

    }
    else if (type == 'share') {
      postBody.shared = selectedView.shared
    }
    else {
      postBody.pin = true;
      postBody.default = true;
    }


    this._dialogService.doPATCH(requestURL, postBody).subscribe(
      {
        next: (response: any) => {
          // this.tabData = response || []
          this._utilService.showSuccess('', 'View Updated Successfully.');
        },
        error: (error) => {
          this.isLoader = false;
          this._utilService.showErrorMessage(error);

          if (type == 'pin') {
            selectedView.pin = !selectedView.pin;
          }
        },
        complete: () => {
          this.isLoader = false;
          this.editManageView = true;
          // Instead loading the GET API after patch method we just append the response to particular views, because of APIs loading for every patch method that's why
          if (type == 'pin') {
            selectedView.pin = postBody.pin;
            if (!selectedView.pin && selectedView.default === true) {
              selectedView.default = false;
              this._dialogService.isDefaultViewChanged = true;
            }
          } else if (type == 'share') {
            selectedView.shared = postBody.shared;
          } else if (type == 'default') {
            this.tabData.map((data: any) => {
              return data.default = false;
            });
            selectedView.default = true;
            this._dialogService.isDefaultViewChanged = true;
            // If the selected default view's pin value is false then change it to true.
            if (selectedView.pin == false) {
              selectedView.pin = true;
            }
          }

        }
      }
    );
  }


  timesheetManageViewUpdate(type: string, selectedView: any): void {
    let postBody: any = {
      "view_id": selectedView.id,
    };
    // let requestURL = environment.LocalDev_URL_V2 + this._viewModule + '/' + selectedView.id + '/';
    let requestURL = "";

    if (this.selectedTab.value == "my_views") {
      requestURL = environment.LocalDev_URL_V2 + 'manage-dashboard/myview-field/?source=timesheet';
    }
    else if (this.selectedTab.value == "shared_by_others") {
      requestURL = environment.LocalDev_URL_V2 + 'manage-dashboard/shared-field/?source=timesheet';
    }
    else if (this.selectedTab.value == "standard_views") {
      requestURL = environment.LocalDev_URL_V2 + 'manage-dashboard/standard-field/?source=timesheet';
    }
    else if (this.selectedTab.value == "scheduled_view_exports") {
      requestURL = environment.LocalDev_URL_V2 + 'manage-dashboard/myview-field/?source=timesheet';
    }


    if (type == 'pin') {
      postBody.pin = selectedView.pin
    }
    else if (type == 'share') {
      postBody.shared = selectedView.shared
    }
    else {
      postBody.pin = true;
      postBody.default = true;
    }


    this._dialogService.doPATCH(requestURL, postBody).subscribe(
      {
        next: (response: any) => {
          // this.tabData = response || []
          this._utilService.showSuccess('', 'View Updated Successfully.');
        },
        error: (error) => {
          this.isLoader = false;
          this._utilService.showErrorMessage(error);

          if (type == 'pin') {
            selectedView.pin = !selectedView.pin;
          }
        },
        complete: () => {
          // this.dialogRef.close({ isUpdate: true, action: type, selectedView: selectedView });

          this.isLoader = false;
          this.editManageView = true;
          // Instead loading the GET API after patch method we just append the response to particular views, because of APIs loading for every patch method that's why
          if (type == 'pin') {
            selectedView.pin = postBody.pin;
            if (!selectedView.pin && selectedView.default === true) {
              selectedView.default = false;
              this._dialogService.isDefaultViewChanged = true;
            }
          } else if (type == 'share') {
            selectedView.shared = postBody.shared;
          } else if (type == 'default') {
            this.tabData.map((data: any) => {
              return data.default = false;
            });
            selectedView.default = true;
            this._dialogService.isDefaultViewChanged = true;
            // If the selected default view's pin value is false then change it to true.
            if (selectedView.pin == false) {
              selectedView.pin = true;
            }
          }
        }
      }
    );
  }

  companyContactsManageViewUpdate(type: string, selectedView: any): void {
    let postBody: any = {};
    let requestURL = environment.fastAPI_Actual_URL + "/" + this._viewModule + "-views/" + selectedView.id + '/';
    if (type == 'pin') {
      postBody.pin = selectedView.pin;
      postBody.type = this.selectedTab.source;
    } else if (type == 'share') {
      postBody.shared = selectedView.shared;
      postBody.type = this.selectedTab.source;
    } else if (type == 'default') {
      postBody.default = true;
      postBody.type = this.selectedTab.source;
    }
    this._dialogService.doPATCH(requestURL, postBody).subscribe(
      {
        next: (response: any) => {
          // this.tabData = response || []
          this._utilService.showSuccess('', 'View Updated Successfully.');
        },
        error: (error) => {
          this.isLoader = false;
          this._utilService.showErrorMessage(error);
          if (type == 'pin') {
            selectedView.pin = !selectedView.pin;
          }
        },
        complete: () => {
          this.isLoader = false;
          this.editManageView = true;
          // this.loadSelectedTabViews(this.selectedTab);
          // Instead of loading the GET API everytime, we append the response from this PATCH method,
          if (type == 'pin') {
            selectedView.pin = postBody.pin;
            if (!selectedView.pin && selectedView.default === true) {
              selectedView.default = false;
              this._dialogService.isDefaultViewChanged = true;
            }
          } else if (type == 'share') {
            selectedView.shared = postBody.shared;
          } else if (type == 'default') {
            this.tabData.map((data: any) => {
              return data.default = false;
            });
            selectedView.default = true;
            this._dialogService.isDefaultViewChanged = true;
            // If the selected default view's pin value is false then change it to true.
            if (selectedView.pin == false) {
              selectedView.pin = true;
            }
          }
        }
      }
    );
  }

  coiManageViewUpdate(type: string, selectedView: any): void {
    if (!type || !selectedView) return;

    const { id, name } = selectedView;
    const isStandardView = ['All COIs', 'Tenant COIs', 'Vendor COIs'].includes(name);
    const url = `${environment.coiMsURL}/coi-view/${id}/configs`;
    const reqData = {
      is_standard: isStandardView,
    }

    if (type === 'default') {
      reqData['default'] = true;
      reqData['pinned'] = true;
    }

    if (type === 'pin') {
      reqData['pinned'] =  selectedView.pin;
    }

    if (type === 'share') {
      reqData['shared'] =  selectedView.shared;
    }

    if (this.selectedTab.value == "my_views") {
      reqData['from_my_view_tab'] = true;
    }
    if (this.selectedTab.value == "shared_by_others") {
      reqData['from_shared_view_tab'] = true;
    }

    this._dialogService.doPATCH(url, reqData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        {
          next: (response: any) => {
            // this.tabData = response || []
            this._utilService.showSuccess('', 'View Updated Successfully.');
          },
          error: (error) => {
            const msg = error.error?.errors[0]?.message || 'Can not update view';
            this.isLoader = false;
            this._utilService.showError('', msg);

            if (type === 'pin') {
              selectedView.pin = !selectedView.pin;
            }
          },
          complete: () => {
            // this.dialogRef.close({ isUpdate: true, action: type, selectedView: selectedView });

            this.isLoader = false;
            this.editManageView = true;
            // Instead loading the GET API after patch method we just append the response to particular views, because of APIs loading for every patch method that's why

            if (type === 'pin') {
              // selectedView.pin = pin;
              selectedView.pin = reqData['pinned'];
              if (!selectedView.pin && selectedView.default === true) {
                selectedView.default = false;
                this._dialogService.isDefaultViewChanged = true;
              }
            } else if (type === 'share') {
              // selectedView.shared = shared;
              selectedView.shared = reqData['shared'];
            } else if (type === 'default') {
              this.tabData.map((data: any) => {
                return data.default = false;
              });
              selectedView.default = reqData['default'];
              this._dialogService.isDefaultViewChanged = true;
              // If the selected default view's pin value is false then change it to true.
              if (selectedView.pin == false) {
                selectedView.pin = true;
              }
            }
          }
        }
      );

    return;
  }


  /**
 * Do Action by Selected Action Type
 * @param type
 * @param selectedView
 */
  deleteView(type: string, selectedView: any) {

    let actionURL = '';
    let requestURL = environment.LocalDev_URL_V2 + this._viewModule + '/manage_views/';

    if (type == 'delete') {
      if (this._viewModule == 'dashboard' || this._viewModule == "property") {
        requestURL = environment.LocalDev_URL_V2 + this._viewModule + '/' + selectedView.id;
      }
      else {
        actionURL = 'delete_view/' + selectedView.id;
        requestURL = environment.LocalDev_URL_V2 + this._viewModule + '/views/' + selectedView.id;
      }
    }



    this._dialogService.doDELETE(requestURL).subscribe(
      {
        next: (response: any) => {
          // this.tabData = response || []
          this._utilService.showSuccess('', 'View Deleted Successfully.');

          let indexVal = this.tabData.findIndex(obj => obj.id == selectedView.id);
          this.tabData.splice(indexVal, 1);
        },
        error: (error) => {
          this.isLoader = false;
          this._utilService.showErrorMessage(error);
        },
        complete: () => {
          this.isLoader = false;
        }
      }
    );

  }



  loadDashboardtimesheetManageViewsTabsCount() {
    let requestURL = '';
    // let requestURL = environment.LocalDev_URL_V2 + this._viewModule + '/manage_views/';

    // if (this._viewModule == 'dashboard') {
    //   requestURL = environment.LocalDev_URL_V2 + 'manage-dashboard/manage_views/?source=dashboard';
    // }
    // if (this._viewModule == 'property') {
    //   requestURL = environment.LocalDev_URL_V2 + 'manage-dashboard/manage_views/?source=property';
    // }
    // else {
    //   requestURL = environment.LocalDev_URL_V2 + 'manage-dashboard/manage_views/?source=timesheet';
    // }

    requestURL = environment.LocalDev_URL_V2 + 'manage-dashboard/manage_views/?source=' + this._viewModule;

    this.isLoader = true;
    this._dialogService.doGET(requestURL).subscribe(
      {
        next: (response: any) => {
          this.mainTabsList[0].count = response.my_views;
          this.mainTabsList[1].count = response.shared_by_others;
          this.mainTabsList[2].count = response.standard_views;
          if ((this._viewModule == "dashboard" || this._viewModule == "timesheet") && (this.currentUserInfo?.current_Role == 'Manager' || this.currentUserInfo?.current_Role == 'Owner')) {
            this.mainTabsList[3].count = response.schedule_view;
          }
        },
        error: (error) => {
          this.isLoader = false;
        },
        complete: () => {
          this.isLoader = false;
        }
      }
    );
  }

  loadCompanyContactsManageViewsTabsCount() {
    let requestURL = '';
    if (this._viewModule == 'company') {
      requestURL = environment.fastAPI_Actual_URL + '/company-views/tabs/'
    } else {
      requestURL = environment.fastAPI_Actual_URL + `/contact-views/tabs/`;
    }
    this.isLoader = true;
    this._dialogService.doGET(requestURL).subscribe(
      {
        next: (response: any) => {
          this.mainTabsList[0].count = response[0].count;
          this.mainTabsList[1].count = response[1].count;
          this.mainTabsList[2].count = response[2].count;
        },
        error: (error) => {
          this.isLoader = false;
        },
        complete: () => {
          this.isLoader = false;
        }
      }
    );
  }

  onSelectTabEquipment(event: any) {
    this.selectedTab = event;
    this.tabData = [];
    if (event.value === 'my_views') {
      this.tabData = this.equimentModuleViews.my_views?.items || [];
      this.allViewsListTotal = this.equimentModuleViews.my_views?.total;
      this.allViewsListSize = this.equimentModuleViews.my_views?.size;
      this.allViewsListPageCount = Math.round(this.allViewsListTotal / this.allViewsListSize);
    } else if (event.value === 'shared_by_others') {
      this.tabData = this.equimentModuleViews.shared_by_others?.items || [];
      this.allViewsListTotal = this.equimentModuleViews.shared_by_others?.total;
      this.allViewsListSize = this.equimentModuleViews.shared_by_others?.size;
      this.allViewsListPageCount = Math.round(this.allViewsListTotal / this.allViewsListSize);
    } else {
      this.tabData = this.equimentModuleViews.standard?.items || [];
    }
    this.tabCountTriggers();
  }


  loadCoiManageViewsTabsCount() {
    const requestURL = environment.coiMsURL + '/coi-view/tabs-count';
    const params = {
      source: this._viewModule,
    };

    this.isLoader = true;
    this._dialogService.doGET(requestURL, params).pipe(map(res => res.data))
      .subscribe(
        {
          next: (response: any) => {
            this.mainTabsList[0].count = response.my_views;
            this.mainTabsList[1].count = response.shared_by_others;
            this.mainTabsList[2].count = response.standard_views;
          },
          error: (error) => {
            this.isLoader = false;
          },
          complete: () => {
            this.isLoader = false;
          }
        }
      );
  }

  loadLeaseManageViewsTabsCount() {
    const requestURL = environment.lease_URL + '/leases-views/tabs-count';
    const params = {
      source: this._viewModule,
    };

    this.isLoader = true;
    this._dialogService.doGET(requestURL, params)
      .subscribe(
        {
          next: (response: any) => {
            this.mainTabsList[0].count = response.my_views;
            this.mainTabsList[1].count = response.shared_by_others;
            this.mainTabsList[2].count = response.standard_views;
          },
          error: (error) => {
            this.isLoader = false;
          },
          complete: () => {
            this.isLoader = false;
          }
        }
      );
  }

  manageLeaseView(type: string, selectedView: any) {
    if (!type || !selectedView) return;

    const { id, name } = selectedView;
    const isStandardView = ['Current Leases', 'Past Leases', 'Drafts', 'Not Started'].includes(name);
    const url = `${environment.lease_URL}/leases-views/${id}/configs`;
    const reqData = {
      is_standard: isStandardView,
    }

    if (type === 'default') {
      reqData['default'] = true;
      reqData['pinned'] = true;
    }

    if (type === 'pin') {
      reqData['pinned'] =  selectedView.pin;
    }

    if (type === 'share') {
      reqData['shared'] =  selectedView.shared;
    }

    if (this.selectedTab.value == "my_views") {
      reqData['from_my_view_tab'] = true;
    }
    if (this.selectedTab.value == "shared_by_others") {
      reqData['from_shared_view_tab'] = true;
    }

    this._dialogService.doPATCH(url, reqData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
      {
        next: (response: any) => {
          // this.tabData = response || []
          this._utilService.showSuccess('', 'View Updated Successfully.');
        },
        error: (error) => {
          const msg = error.error?.errors[0]?.message || 'Can not update view';
          this.isLoader = false;
          this._utilService.showError('', msg);

          if (type === 'pin') {
            selectedView.pin = !selectedView.pin;
          }
        },
        complete: () => {
          this.isLoader = false;
          this.editManageView = true;

          if (type === 'pin') {
            // selectedView.pin = pin;
            selectedView.pin = reqData['pinned'];
            if (!selectedView.pin && selectedView.default === true) {
              selectedView.default = false;
              this._dialogService.isDefaultViewChanged = true;
            }
          } else if (type === 'share') {
            // selectedView.shared = shared;
            selectedView.shared = reqData['shared'];
          } else if (type === 'default') {
            this.tabData.map((data: any) => {
              return data.default = false;
            });
            selectedView.default = reqData['default'];
            this._dialogService.isDefaultViewChanged = true;
            // If the selected default view's pin value is false then change it to true.
            if (selectedView.pin == false) {
              selectedView.pin = true;
            }
          }
        }
      }
    );

    return;
  }

  openExportViewPopup(selectedMenu: any, selectedView: any): void {
     let popupData = {
       title: `${selectedView.name} view`,
       component: ExportViewComponent,
       containerClass: 'export-view-schedule',
       data: { selectedMenu: selectedMenu, selectedView: selectedView }
    };

    this._dialogService.openModal(popupData).subscribe((result: any) => {
      if (result?.scheduleViewExportCreated) {
        this.loadDashboardtimesheetManageViewsTabsCount();
        this.loadDashboardSelectedTabViews(this.selectedTab);
      }
    });
  }

  getViewDetails(selectedView: any): void {
     let requestURL = environment.LocalDev_URL_V2 + "manage-dashboard/" + selectedView.id;
     this._dialogService.doGET(requestURL).subscribe(
      {
        next: (response: any) => {
          if (this._viewModule == 'dashboard') {
            this.filterQuery.map((obj: any) => {
              if (obj?.listItems) {
                obj.listItems.map(element => {
                  element.selected = false;
                  return element;
                });
              }
              return obj;
            });
          }
          this.selectViewDetailsFromThreeDotMenu = response;
          },
        error: (error) => {
          this.isLoader = false;
        },
        complete: () => {
          this.isLoader = false;
          if (this._viewModule == 'dashboard') {
            this.openEditViewPopupDashboard(this.selectViewDetailsFromThreeDotMenu);
          } else if (this._viewModule == 'timesheet') {
            this.openEditViewPopupTimesheet(this.selectViewDetailsFromThreeDotMenu);
          }
        }
      }
    );
  }

  openEditViewPopupDashboard(selectedViewDetails: any): void {
    // Iterate through the object
    const filterQuery: any = selectedViewDetails.filters.query;
    for (const key in filterQuery) {
      if (filterQuery.hasOwnProperty(key)) {
        if (key.startsWith('custom_attributes.x')) {
          const customAttributesKeys = Object.keys(filterQuery).filter(key => key.startsWith("custom_attributes.x"));
          customAttributesKeys.forEach(element => {
            let filter;
            filter = {
              name: this._customTabsService.snakeCaseToCamel(element.replace('custom_attributes.x_', '')) + ' ' + `${filterQuery[element].value ?? filterQuery[element]}`,
              customName: element,
              customValue: filterQuery[element]
            }
            if (filterQuery[element] instanceof Object) {
              let customDateObj = { value: filterQuery[element] }
              filter.name = this._customTabsService.snakeCaseToCamel(element.replace('custom_attributes.x_', '')) + ' ' + `${this.getTimeLineValue(customDateObj)}`;
              filter.value = this.getTimeLineValue(customDateObj);
            }
            this.selectedFilter.push(filter);
            this.customFilter.push(filter);
          });
        } else {
          this.updateQueryFromBackend(filterQuery);
          // let selectedFiltersList = this.filterQuery.filter((element) => element.listItems != undefined).map((element: any) =>
          //   (element.listItems && Array.isArray(element.listItems)) ? element.listItems?.filter((subElement: any) => subElement.selected === true) : []);
          //
          // let selectedFiltersObj: any = [];
          //
          // selectedFiltersList.forEach((element: any) => {
          //   selectedFiltersObj.push(...element);
          // });
          // selectedFilter = selectedFiltersObj;
        }
      }
    }
    let popupData = {
      title: 'Edit View',
      component: AddViewComponent,
      containerClass: ['add-view-modal-large'],
      data: { isFromScheduleViewExport: true, filterQuery: this.filterQuery, selectedFilter: this.selectedFilter, selectedView: selectedViewDetails, orderedColumns: this.selectViewDetailsFromThreeDotMenu.table_columns, dateFilters: this.dateFilters, customFilters: this.customFilter },
    };
    this._dialogService.openModal(popupData).subscribe((result: any) => {
      if (result?.id) {
        // this.editManageView = result.id == this.selectedTabFromDashboard.id
        if (result?.isUpdate) {
          this.editManageView = true;
          this.loadDashboardSelectedTabViews(this.selectedTab);
          this._dialogService.isDefaultViewChanged = true;
        }
      }
    });
  }

  openEditViewPopupTimesheet(selectedViewDetails: any): void {
    const filterQuery: any = selectedViewDetails.filters.query;
    this.updateQueryFromBackendTimeSheet(filterQuery);
    let setFilterQueryParams: any;
    setFilterQueryParams = {
      filterList: [
        {
          label: 'Engineer',
          listItems: this.filtersDataSource.filterLists[0].listItems.filter((list) => {
            return list.selected === true;
          })
        },
        {
          label: 'Properties',
          listItems: this.filtersDataSource.filterLists[1].listItems.filter((list) => {
            return list.selected === true;
          })
        },
        {
          label: 'Status',
          listItems: this.filtersDataSource.filterLists[2].listItems.filter((list) => {
            return list.selected === true;
          })
        },
        {
          label: 'Billable',
          listItems: this.filtersDataSource.filterLists[3].listItems.filter((list) => {
            return list.selected === true;
          })
        }
      ]
    }
    if (this.filtersDataSource.dateCreatedDateRange.from && this.filtersDataSource.dateCreatedDateRange.to) {
      setFilterQueryParams['work_date'] = {
        from: this._utilService.formatDateToMM_DD_YYYY(this.filtersDataSource.dateCreatedDateRange.from),
        to: this._utilService.formatDateToMM_DD_YYYY(this.filtersDataSource.dateCreatedDateRange.to)
      }
    }
    if (this.filtersDataSource.dateApprovedDateRange.from && this.filtersDataSource.dateApprovedDateRange.to) {
      setFilterQueryParams['approved_date'] = {
        from: this._utilService.formatDateToMM_DD_YYYY(this.filtersDataSource.dateApprovedDateRange.from),
        to: this._utilService.formatDateToMM_DD_YYYY(this.filtersDataSource.dateApprovedDateRange.to)
      }
    }
    let popupData = {
      title: 'Edit View',
      component: AddViewComponent,
      containerClass: ['add-view-modal-large'],
      data: { isFromScheduleViewExport: true, filterQuery: setFilterQueryParams, selectedFilter: this.selectedFilter, selectedView: selectedViewDetails, orderedColumns: this.selectViewDetailsFromThreeDotMenu.table_columns, sortValue: this.sortValue},
    };
    this._dialogService.openModal(popupData).subscribe((result: any) => {
      if (result?.id) {
        if (result?.isUpdate) {
          this.editManageView = true;
           this.loadtimesheetSelectedTabViews(this.selectedTab);
          this._dialogService.isDefaultViewChanged = true;
        }
      }
    });
  }

  getTimeLineValue(event) {
    if (Array.isArray(event?.value)) {
      return event.value;
    }
    if (event?.value?.value?.custom) {
      return event?.value?.value?.custom?.from + ' - ' + event?.value?.value?.custom?.to;
    } else {
      return this.dateFilterMapper[event?.value?.value?.timeline]
    }
  }

  openDeleteViewPopup(selectedMenu: any, selectedView: any): void {
     let popupData = {
       title: 'Delete View',
       component: DeleteViewComponent,
       containerClass: 'modal-md',
       data: { selectedMenu: selectedMenu, selectedView: selectedView }
    };

    this._dialogService.openModal(popupData).subscribe((result: any) => {
      if (result?.viewDeleted) {
        this.loadDashboardtimesheetManageViewsTabsCount();
        this.loadDashboardSelectedTabViews(this.selectedTab);
        this.editManageView = true;
      }
    });
  }

  updateQueryFromBackend(filterQuery: any): void {
    // getting te queries from the backend and update queries to frontend.
    if (filterQuery.hasOwnProperty('responsible')) {
      if (this.filterQuery.length == 0)
        return;
      if (this.filterQuery.length > 0) {
        this.filterQuery.find((element: any) => {
          return element.label == 'Billable Party';
        }).listItems.map((item: any) => {
          filterQuery?.responsible.map((value: any) => {
            if (value === item?.value) {
              item.selected = true;
            }
          });
        });
      }
    }
    if (filterQuery.hasOwnProperty('filter_by')) {
      if (this.filterQuery.length == 0)
        return;
      if (this.filterQuery.length > 0) {
        this.filterQuery.find((element: any) => {
          return element.label == 'Filter By';
        }).listItems.map((item: any) => {
          filterQuery?.filter_by.map((value: any) => {
            if (value === item?.value) {
              item.selected = true;
            }
          });
        });
      }
    }
    if (filterQuery.hasOwnProperty('source')) {
      if (this.filterQuery.length > 0) {
        this.filterQuery.find((element: any) => {
          return element.label == 'Source Type';
        }).listItems.map((item: any) => {
          filterQuery?.source.map((value: any) => {
            if (value === item?.name) {
              item.selected = true;
            }
          });
        });
      }
    }
    if (filterQuery.hasOwnProperty('actions')) {
      if (this.filterQuery.length > 0) {
        this.filterQuery.find((element: any) => {
          return element.label == 'Actions';
        }).listItems.map((item: any) => {
          filterQuery?.actions.map((value: any) => {
            if (value === item?.name) {
              item.selected = true;
            }
          });
        });
      }
    }
    if (filterQuery.hasOwnProperty('jobcost_type')) {
      if (this.filterQuery.length > 0) {
        this.filterQuery.find((element: any) => {
          return element.label == 'Job Cost Type';
        }).listItems.map((item: any) => {
          filterQuery?.jobcost_type.map((value: any) => {
            if (value === item?.value) {
              item.selected = true;
            }
          });
        });
      }
    }
    if (filterQuery.hasOwnProperty('priority')) {
      if (this.filterQuery.length > 0) {
        /********* If not in list add with seleced option **********/
        filterQuery?.priority.forEach(element => {
          let indexVal = this.filterQuery.find((element) => { return element.label == 'Priority'; }).listItems.findIndex(obj => obj.id == element.id)
          if (indexVal == -1) {
            element.selected = true;
            this.filterQuery.find((element) => { return element.label == 'Priority'; }).listItems.unshift(element);
          }
        });
        this.filterQuery.find((element: any) => {
          return element.label == 'Priority';
        }).listItems.map((item: any) => {
          filterQuery?.priority.map((value: any) => {
            if (value.id === item?.id) {
              item.selected = true;
            }
          });
        });
      }
    }
    if (filterQuery.hasOwnProperty('properties')) {
      if (this.filterQuery.length > 0) {
        /********* If not in list add with seleced option **********/
         filterQuery?.properties.forEach(element => {
          let indexVal = this.filterQuery.find((element) => { return element.label == 'Property'; }).listItems.findIndex(obj => obj.id == element.id)
           if (indexVal == -1) {
            element.selected = true;
            this.filterQuery.find((element) => { return element.label == 'Property'; }).listItems.unshift(element);
          }
         });
         this.filterQuery.find((element: any) => {
          return element.label == 'Property';
        }).listItems.map((item: any) => {
           filterQuery?.properties.map((value: any) => {
            if (value.id === item?.id) {
              item.selected = true;
            }
          });
        });
      }
    }
    if (filterQuery.hasOwnProperty('property_group')) {
      if (this.filterQuery.length > 0) {
        /********* If not in list add with seleced option **********/
        filterQuery?.property_group.forEach(element => {
          let indexVal = this.filterQuery.find((element) => { return element.label == 'Property Group'; }).listItems.findIndex(obj => obj.id == element.id)
          if (indexVal == -1) {
            element.selected = true;
            this.filterQuery.find((element) => { return element.label == 'Property Group'; }).listItems.unshift(element);
          }
        });
        const propertyGroup = this.filterQuery.find((element: any) => element.label === 'Property Group');
        if (propertyGroup) {
          propertyGroup.listItems.forEach((item: any) => {
            filterQuery?.property_group.forEach((value: any) => {
              if (value.id === item.id) {
                item.selected = true;
              }
            });
          });
        }
      }
    }
    if (filterQuery.hasOwnProperty('job_cost_approvers')) {
      if (this.filterQuery.length > 0) {
        /********* If not in list add with seleced option **********/
        filterQuery?.job_cost_approvers.forEach(element => {
          let indexVal = this.filterQuery.find((element) => { return element.label == 'Job Cost Approver'; }).listItems.findIndex(obj => obj.id == element.id)
          if (indexVal == -1) {
            element.selected = true;
            this.filterQuery.find((element) => { return element.label == 'Job Cost Approver'; }).listItems.unshift(element);
          }
        });
        const jobCostApprover = this.filterQuery.find((element: any) => element.label === 'Job Cost Approver');
        if (jobCostApprover) {
          jobCostApprover.listItems.forEach((item: any) => {
            filterQuery?.job_cost_approvers.forEach((value: any) => {
              if (value.id === item.id) {
                item.selected = true;
              }
            });
          });
        }
      }
    }
    if (filterQuery.hasOwnProperty('categories')) {
      if (this.filterQuery.length > 0) {
        /********* If not in list add with seleced option **********/
        filterQuery?.categories.forEach(element => {
          let indexVal = this.filterQuery.find((element) => { return element.label == 'Category'; }).listItems.findIndex(obj => obj.id == element.id)
          if (indexVal == -1) {
            element.selected = true;
            this.filterQuery.find((element) => { return element.label == 'Category'; }).listItems.unshift(element);
          }
        });
        this.filterQuery.find((element: any) => {
          return element.label == 'Category';
        }).listItems.map((item: any) => {
          filterQuery?.categories.map((value: any) => {
            if (value.id === item?.id) {
              item.selected = true;
            }
          });
        });
      }
    }
    if (filterQuery.hasOwnProperty('tenants')) {
      if (this.filterQuery.length > 0) {
        /********* If not in list add with seleced option **********/
        filterQuery?.tenants.forEach(element => {
          let indexVal = this.filterQuery.find((element) => { return element.label == 'Tenant'; }).listItems.findIndex(obj => obj.id == element.id)
          if (indexVal == -1) {
            element.selected = true;
            this.filterQuery.find((element) => { return element.label == 'Tenant'; }).listItems.unshift(element);
          }
        });
        this.filterQuery.find((element: any) => {
          return element.label == 'Tenant';
        }).listItems.map((item: any) => {
          filterQuery?.tenants.map((value: any) => {
            if (value.id === item?.id) {
              item.selected = true;
            }
          });
        });
      }
    }
    if (filterQuery.hasOwnProperty('vendors')) {
      if (this.filterQuery?.length > 0) {
        /********* If not in list add with seleced option **********/
        filterQuery?.vendors.forEach(element => {
          let indexVal = this.filterQuery?.find((element) => { return element.label == 'Vendor'; })?.listItems?.findIndex(obj => obj.id == element.id)
          if (indexVal == -1) {
            element.selected = true;
            this.filterQuery.find((element) => { return element.label == 'Vendor'; }).listItems.unshift(element);
          }
        });
        this.filterQuery?.find((element: any) => {
          return element.label == 'Vendor';
        })?.listItems.map((item: any) => {
          filterQuery?.vendors.map((value: any) => {
            if (value.id === item?.id) {
              item.selected = true;
            }
          });
        });
        /********* If not in list add with seleced option **********/
        filterQuery?.vendors.forEach(element => {
          let indexVal = this.filterQuery.find((element) => { return element.label == 'Assigned Vendor'; }).listItems.findIndex(obj => obj.id == element.id)
          if (indexVal == -1) {
            element.selected = true;
            this.filterQuery.find((element) => { return element.label == 'Assigned Vendor'; })?.listItems?.push(element);
          }});
        this.filterQuery?.find((element: any) => {
          return element.label == 'Assigned Vendor';
        })?.listItems.map((item: any) => {
          filterQuery?.vendors.map((value: any) => {
            if (value.id === item?.id) {
              item.selected = true;
            }
          });
        });
      }
    }
    if (filterQuery.hasOwnProperty('approved_vendor')) {
      if (this.filterQuery.length > 0) {
        /********* If not in list add with seleced option **********/
        filterQuery?.approved_vendor.forEach(element => {
          let indexVal = this.filterQuery.find((element) => { return element.label == 'Approved Vendor'; })?.listItems?.findIndex(obj => obj.id == element.id)
          if (indexVal == -1) {
            element.selected = true;
            this.filterQuery.find((element) => { return element.label == 'Approved Vendor'; }).listItems.unshift(element);
          }
        });
        this.filterQuery.find((element: any) => {
          return element.label == 'Approved Vendor';
        })?.listItems.map((item: any) => {
          filterQuery?.approved_vendor.map((value: any) => {
            if (value.id === item?.id) {
              item.selected = true;
            }
          });
        });
      }
    }
    if (filterQuery.hasOwnProperty('owners')) {
      if (this.filterQuery.length > 0) {
        /********* If not in list add with seleced option **********/
        filterQuery?.owners.forEach(element => {
          let indexVal = this.filterQuery.find((element) => { return element.label == 'Owner Group'; }).listItems.findIndex(obj => obj.id == element.id)
          if (indexVal == -1) {
            element.selected = true;
            this.filterQuery.find((element) => { return element.label == 'Owner Group'; }).listItems.unshift(element);
          }
        });
        this.filterQuery.find((element: any) => {
          return element.label == 'Owner Group';
        }).listItems.map((item: any) => {
          filterQuery?.owners.map((value: any) => {
            if (value.id === item?.id) {
              item.selected = true;
            }
          });
        });
      }
    }
    if (filterQuery.hasOwnProperty('service_type')) {
      if (this.filterQuery.length > 0) {
        /********* If not in list add with seleced option **********/
        filterQuery?.service_type.forEach(element => {
          let indexVal = this.filterQuery.find((element) => { return element.label == 'Service Type'; }).listItems.findIndex(obj => obj.id == element.id)
          if (indexVal == -1) {
            element.selected = true;
            this.filterQuery.find((element) => { return element.label == 'Service Type'; }).listItems.unshift(element);
          }
        });
        this.filterQuery.find((element: any) => {
          return element.label == 'Service Type';
        }).listItems.map((item: any) => {
          filterQuery?.service_type.map((value: any) => {
            if (value.id === item?.id) {
              item.selected = true;
            }
          });
        });
      }
    }
    if (filterQuery.hasOwnProperty('created_by')) {
      if (this.filterQuery.length > 0) {
        /********* If not in list add with seleced option **********/
        filterQuery?.created_by.forEach(element => {
          let indexVal = this.filterQuery.find((element) => { return element.label == 'Created By'; }).listItems.findIndex(obj => obj.id == element.id)
          if (indexVal == -1) {
            element.selected = true;
            this.filterQuery.find((element) => { return element.label == 'Created By'; }).listItems.unshift(element);
          }
        });
        this.filterQuery.find((element: any) => {
          return element.label == 'Created By';
        }).listItems.map((item: any) => {
          filterQuery?.created_by.map((value: any) => {
            if (value.id === item?.id) {
              item.selected = true;
            }
          });
        });
      }
    }
    if (filterQuery.hasOwnProperty('assignees')) {
      if (this.filterQuery.length > 0) {
        /********* If not in list add with seleced option **********/
        filterQuery?.assignees.forEach(element => {
          let indexVal = this.filterQuery.find((element) => { return element.label == 'Assigned To'; }).listItems.findIndex(obj => obj.id == element.id)
          if (indexVal == -1) {
            element.selected = true;
            this.filterQuery.find((element) => { return element.label == 'Assigned To'; }).listItems.unshift(element);
          }
        });
        this.filterQuery.find((element: any) => {
          return element.label == 'Assigned To';
        }).listItems.map((item: any) => {
          filterQuery?.assignees.map((value: any) => {
            if (value.id === item?.id) {
              item.selected = true;
            }
          });
        });
      }
    }
    if (filterQuery.hasOwnProperty('managers')) {
      if (this.filterQuery.length > 0) {
        /********* If not in list add with seleced option **********/
        filterQuery?.managers.forEach(element => {
          let indexVal = this.filterQuery.find((element) => { return element.label == 'Assigned Manager'; }).listItems.findIndex(obj => obj.id == element.id)
          if (indexVal == -1) {
            element.selected = true;
            this.filterQuery.find((element) => { return element.label == 'Assigned Manager'; }).listItems.unshift(element);
          }
        });
        this.filterQuery.find((element: any) => {
          return element.label == 'Assigned Manager';
        }).listItems.map((item: any) => {
          filterQuery?.managers.map((value: any) => {
            if (value.id === item?.id) {
              item.selected = true;
            }
          });
        });
      }
    }
    if (filterQuery.hasOwnProperty('followers')) {
      if (this.filterQuery.length > 0) {
        /********* If not in list add with seleced option **********/
        filterQuery?.followers.forEach(element => {
          let indexVal = this.filterQuery.find((element) => { return element.label == 'Assigned Follower'; }).listItems.findIndex(obj => obj.id == element.id)
          if (indexVal == -1) {
            element.selected = true;
            this.filterQuery.find((element) => { return element.label == 'Assigned Follower'; }).listItems.unshift(element);
          }
        });
        this.filterQuery.find((element: any) => {
          return element.label == 'Assigned Follower';
        }).listItems.map((item: any) => {
          filterQuery?.followers.map((value: any) => {
            if (value.id === item?.id) {
              item.selected = true;
            }
          });
        });
      }
    }
    if (filterQuery.hasOwnProperty('assigned_followed')) {
      if (this.filterQuery.length > 0) {
        /********* If not in list add with seleced option **********/
        filterQuery?.assigned_followed.forEach(element => {
          let indexVal = this.filterQuery.find((element) => { return element.label == 'Assigned or Followed'; }).listItems.findIndex(obj => obj.id == element.id)
          if (indexVal == -1) {
            element.selected = true;
            this.filterQuery.find((element) => { return element.label == 'Assigned or Followed'; }).listItems.unshift(element);
          }
        });
        this.filterQuery.find((element: any) => {
          return element.label == 'Assigned or Followed';
        }).listItems.map((item: any) => {
          filterQuery?.assigned_followed.map((value: any) => {
            if (value.id === item?.id) {
              item.selected = true;
            }
          });
        });
      }
    }
    if (filterQuery.hasOwnProperty('engineers')) {
      if (this.filterQuery.length > 0) {
        /********* If not in list add with seleced option **********/
        filterQuery?.engineers.forEach(element => {
          let indexVal = this.filterQuery.find((element) => { return element.label == 'Assigned Engineer'; }).listItems.findIndex(obj => obj.id == element.id)
          if (indexVal == -1) {
            element.selected = true;
            this.filterQuery.find((element) => { return element.label == 'Assigned Engineer'; }).listItems.unshift(element);
          }
        });
        this.filterQuery.find((element: any) => {
          return element.label == 'Assigned Engineer';
        }).listItems.map((item: any) => {
          filterQuery?.engineers.map((value: any) => {
            if (value.id === item?.id) {
              item.selected = true;
            }
          });
        });
      }
    }
    if (filterQuery.hasOwnProperty('stage')) {
      if (this.filterQuery.length > 0) {
        /********* If not in list add with seleced option **********/
        filterQuery?.stage.forEach(element => {
          let indexVal = this.filterQuery.find((element) => { return element.label == 'Status'; }).listItems.findIndex(obj => obj.id == element.id)
          if (indexVal == -1) {
            element.selected = true;
            this.filterQuery.find((element) => { return element.label == 'Status'; }).listItems.unshift(element);
          }
        });
        this.filterQuery.find((element: any) => {
          return element.label == 'Status';
        }).listItems.map((item: any) => {
          filterQuery?.stage.map((value: any) => {
            if (value.id === item?.id) {
              item.selected = true;
            }
          });
        });
      }
    }
    if (filterQuery.hasOwnProperty('checklist')) {
      if (this.filterQuery.length == 0)
        return;
      if (this.filterQuery.length > 0) {
        this.filterQuery.find((element: any) => {
          return element.label == 'Checklist';
        }).listItems.map((item: any) => {
          filterQuery?.checklist.map((value: any) => {
            if (value === item?.value) {
              item.selected = true;
            }
          });
        });
      }
    }
    if (filterQuery.hasOwnProperty('invoice_status')) {
      if (this.filterQuery.length == 0)
        return;
      if (this.filterQuery.length > 0) {
        this.filterQuery.find((element: any) => {
          return element.label == 'Invoices';
        }).listItems.map((item: any) => {
          filterQuery?.invoice_status.map((value: any) => {
            if (value === item?.value) {
              item.selected = true;
            }
          });
        });
      }
    }
    if (filterQuery.hasOwnProperty('bid_status')) {
      if (this.filterQuery.length == 0)
        return;
      if (this.filterQuery.length > 0) {
        this.filterQuery.find((element: any) => {
          return element.label == 'Bids';
        }).listItems.map((item: any) => {
          filterQuery?.bid_status.map((value: any) => {
            if (value === item?.value) {
              item.selected = true;
            }
          });
        });
      }
    }
    if (filterQuery.hasOwnProperty('property_external_code')) {
      if (this.filterQuery.length == 0)
        return;
      if (this.filterQuery.length > 0) {
        /********* If not in list add with seleced option **********/
        filterQuery.property_external_code = filterQuery?.property_external_code.map(obj => { let newObj = { id: obj, name: obj }; return newObj; });
        filterQuery?.property_external_code.forEach(element => {
          let indexVal = this.filterQuery.find((element) => { return element.label == 'Property External Code'; }).listItems.findIndex(obj => obj.id == element.id)
          if (indexVal == -1) {
            element.selected = true;
            this.filterQuery.find((element) => { return element.label == 'Property External Code'; }).listItems.unshift(element);
          }
        });
        this.filterQuery.find((element: any) => {
          return element.label == 'Property External Code';
        }).listItems.map((item: any) => {
          filterQuery?.property_external_code.map((value: any) => {
            if (value.id === item?.id) {
              item.selected = true;
            }
          });
        });
      }
    }
    if (filterQuery.hasOwnProperty('lease_external_code')) {
      if (this.filterQuery.length == 0)
        return;
      if (this.filterQuery.length > 0) {
        /********* If not in list add with seleced option **********/
        filterQuery.lease_external_code = filterQuery?.lease_external_code.map(obj => { let newObj = { id: obj, name: obj }; return newObj; });
        filterQuery?.lease_external_code.forEach(element => {
          let indexVal = this.filterQuery.find((element) => { return element.label == 'Lease External Code'; }).listItems.findIndex(obj => obj.id == element.id)
          if (indexVal == -1) {
            element.selected = true;
            this.filterQuery.find((element) => { return element.label == 'Lease External Code'; }).listItems.unshift(element);
          }
        });
        this.filterQuery.find((element: any) => {
          return element.label == 'Lease External Code';
        }).listItems.map((item: any) => {
          filterQuery?.lease_external_code.map((value: any) => {
            if (value.id === item?.id) {
              item.selected = true;
            }
          });
        });
      }
    }
    let selectedFiltersList = this.filterQuery.filter((element) => element.listItems != undefined).map((element: any) =>
      (element.listItems && Array.isArray(element.listItems)) ? element.listItems?.filter((subElement: any) => subElement.selected === true) : []);

    let selectedFiltersObj: any = [];
    selectedFiltersList.forEach((element: any) => {
      selectedFiltersObj.push(...element);
    });
    this.selectedFilter = selectedFiltersObj;

    if (filterQuery.hasOwnProperty('created_at')) {
      if (filterQuery.created_at.custom) {
        let setDateObj = this._utilService.dateDropdownItems.find(obj => obj.value == 'Custom');
        if (filterQuery.created_at.custom?.from && filterQuery.created_at.custom?.to) {
          let dateLable = '( ' + filterQuery.created_at.custom?.from + ' - ' + filterQuery.created_at.custom?.to + ' )';
          // this.listStatus = this.listStatus.map((obj: any) => { (obj.value == 'Custom') ? (obj.name = dateLable) : null; return obj })
          setDateObj.type = 'date_created';
          setDateObj.name = dateLable;
          setDateObj.lable = 'Date Created: ' + setDateObj.name;
          this.dateFilters.date_created = setDateObj;
          // this.setPayloadFilterQuery.created_at = filterQueryParams.created_at;

          //Add into selected Chips
          const dateChip = {
            id: (this.selectedFilter.length + 1).toString(),
            name: setDateObj.lable,
            type: setDateObj.type,
            isDateChip: true
          }
          this.selectedFilter.push(dateChip);
        }
      }
      else {
        let setDateObj = this._utilService.dateDropdownItems.find(obj => obj.value == filterQuery.created_at.timeline);
        setDateObj.type = 'date_created';
        setDateObj.lable = 'Date Created: ' + setDateObj.name;

        this.dateFilters.date_created = setDateObj;
        // this.setPayloadFilterQuery.created_at = this.setQuery_DateRangeValue(setDateObj);

        //Add into selected Chips
        const dateChip = {
          id: (this.selectedFilter.length + 1).toString(),
          name: setDateObj.lable,
          type: setDateObj.type,
          isDateChip: true
        }
        this.selectedFilter.push(dateChip);
      }
    }
    if (filterQuery.hasOwnProperty('last_activity')) {
      if (filterQuery.last_activity.custom) {
        let setDateObj = this._utilService.dateDropdownItems.find(obj => obj.value == 'Custom');
        if (filterQuery.last_activity.custom?.from && filterQuery.last_activity.custom?.to) {
          let dateLable = '( ' + filterQuery.last_activity.custom?.from + ' - ' + filterQuery.last_activity.custom?.to + ' )';
          // this.listStatus = this.listStatus.map((obj: any) => { (obj.value == 'Custom') ? (obj.name = dateLable) : null; return obj })
          setDateObj.type = 'last_activity';
          setDateObj.name = dateLable;
          setDateObj.lable = 'Last Activity: ' + setDateObj.name;
          this.dateFilters.last_activity = setDateObj;
          // this.setPayloadFilterQuery.last_activity = filterQueryParams.last_activity;

          //Add into selected Chips
          const dateChip = {
            id: (this.selectedFilter.length + 1).toString(),
            name: setDateObj.lable,
            type: setDateObj.type,
            isDateChip: true
          }
          this.selectedFilter.push(dateChip);
        }
      } else {
        let setDateObj = this._utilService.dateDropdownItems.find(obj => obj.value == filterQuery.last_activity.timeline);
        setDateObj.type = 'last_activity';
        setDateObj.lable = 'Last Activity: ' + setDateObj.name;
        this.dateFilters.last_activity = setDateObj;
        // this.setPayloadFilterQuery.last_activity = this.setQuery_DateRangeValue(setDateObj);

        //Add into selected Chips
        const dateChip = {
          id: (this.selectedFilter.length + 1).toString(),
          name: setDateObj.lable,
          type: setDateObj.type,
          isDateChip: true
        }
        this.selectedFilter.push(dateChip);
      }
    }
    if (filterQuery.hasOwnProperty('target_completion')) {
      if (filterQuery.target_completion.custom) {
        let setDateObj = this._utilService.dateDropdownItems_schedule.find(obj => obj.value == 'Custom');
        if (filterQuery.target_completion.custom?.from && filterQuery.target_completion.custom?.to) {
          let dateLable = '( ' + filterQuery.target_completion.custom?.from + ' - ' + filterQuery.target_completion.custom?.to + ' )';
          // this.listStatus = this.listStatus.map((obj: any) => { (obj.value == 'Custom') ? (obj.name = dateLable) : null; return obj })
          setDateObj.type = 'target_completion';
          setDateObj.name = dateLable;
          setDateObj.lable = 'Target Completion Date: ' + setDateObj.name;
          this.dateFilters.target_completion = setDateObj;
          // this.setPayloadFilterQuery.target_completion = filterQueryParams.target_completion;

          //Add into selected Chips
          const dateChip = {
            id: (this.selectedFilter.length + 1).toString(),
            name: setDateObj.lable,
            type: setDateObj.type,
            isDateChip: true
          }
          this.selectedFilter.push(dateChip);
        }
      } else {
        let setDateObj: any = this._utilService.dateDropdownItems_schedule.find(obj => obj.value == filterQuery.target_completion.timeline) || {};
        setDateObj.type = 'target_completion';
        setDateObj.lable = 'Target Completion Date: ' + setDateObj.name;
        this.dateFilters.target_completion = setDateObj;
        // this.setPayloadFilterQuery.target_completion = this.setQuery_DateRangeValue(setDateObj);

        //Add into selected Chips
        const dateChip = {
          id: (this.selectedFilter.length + 1).toString(),
          name: setDateObj.lable,
          type: setDateObj.type,
          isDateChip: true
        }
        this.selectedFilter.push(dateChip);
      }
    }
    if (filterQuery.hasOwnProperty('schedule')) {
      if (filterQuery.schedule.custom) {
        let setDateObj = this._utilService.dateDropdownItems_schedule.find(obj => obj.value == 'Custom');
        if (filterQuery.schedule.custom?.from && filterQuery.schedule.custom?.to) {
          let dateLable = '( ' + filterQuery.schedule.custom?.from + ' - ' + filterQuery.schedule.custom?.to + ' )';
          // this.listStatus = this._utilService.dateDropdownItems_schedule.map((obj: any) => { (obj.value == 'Custom') ? (obj.name = dateLable) : null; return obj })
          setDateObj.type = 'schedule';
          setDateObj.name = dateLable;
          setDateObj.lable = 'Schedule: ' + setDateObj.name;
          this.dateFilters.schedule = setDateObj;
          // this.setPayloadFilterQuery.schedule = filterQueryParams.schedule;
          //Add into selected Chips
          const dateChip = {
            id: (this.selectedFilter.length + 1).toString(),
            name: setDateObj.lable,
            type: setDateObj.type,
            isDateChip: true
          }
          this.selectedFilter.push(dateChip);
        }
      } else {
        let setDateObj: any = this._utilService.dateDropdownItems_schedule.find(obj => obj.value == filterQuery.schedule.timeline) || {};
        setDateObj.type = 'schedule';
        setDateObj.lable = 'Schedule: ' + setDateObj.name;
        this.dateFilters.schedule = setDateObj;
        // this.setPayloadFilterQuery.schedule = this.setQuery_DateRangeValue(setDateObj);
        //Add into selected Chips
        const dateChip = {
          id: (this.selectedFilter.length + 1).toString(),
          name: setDateObj.lable,
          type: setDateObj.type,
          isDateChip: true
        }
        this.selectedFilter.push(dateChip);
      }
    }
    if (filterQuery.hasOwnProperty('jobcost_date_approved')) {
      if (filterQuery.jobcost_date_approved.custom) {
        let setDateObj = this._utilService.dateDropdownItems_schedule.find(obj => obj.value == 'Custom');
        if (filterQuery.jobcost_date_approved.custom?.from && filterQuery.jobcost_date_approved.custom?.to) {
          let dateLable = '( ' + filterQuery.jobcost_date_approved.custom?.from + ' - ' + filterQuery.jobcost_date_approved.custom?.to + ' )';
          // this.listStatus = this.listStatus.map((obj: any) => { (obj.value == 'Custom') ? (obj.name = dateLable) : null; return obj })
          setDateObj.type = 'jobcost_date_approved';
          setDateObj.name = dateLable;
          setDateObj.lable = 'Job Cost Date Approved: ' + setDateObj.name;
          this.dateFilters.jobcost_date_approved = setDateObj;
          // this.setPayloadFilterQuery.jobcost_date_approved = filterQueryParams.jobcost_date_approved;
          //Add into selected Chips
          const dateChip = {
            id: (this.selectedFilter.length + 1).toString(),
            name: setDateObj.lable,
            type: setDateObj.type,
            isDateChip: true
          }
          this.selectedFilter.push(dateChip);
        }
      } else {
        let setDateObj = this._utilService.dateDropdownItems.find(obj => obj.value == filterQuery.jobcost_date_approved.timeline);
        setDateObj.type = 'jobcost_date_approved';
        setDateObj.lable = 'Job Cost Date Approved: ' + setDateObj.name;
        this.dateFilters.jobcost_date_approved = setDateObj;
        // this.setPayloadFilterQuery.jobcost_date_approved = this.setQuery_DateRangeValue(setDateObj);
        //Add into selected Chips
        const dateChip = {
          id: (this.selectedFilter.length + 1).toString(),
          name: setDateObj.lable,
          type: setDateObj.type,
          isDateChip: true
        }
        this.selectedFilter.push(dateChip);
      }
    }
    if (filterQuery.hasOwnProperty('days_in_status')) {
      if (filterQuery.days_in_status.custom) {
        let setDateObj = this._utilService.dateDropdownItems_schedule.find(obj => obj.value == 'Custom');
        if (filterQuery.days_in_status.custom?.from && filterQuery.days_in_status.custom?.to) {
          let dateLable = '( ' + filterQuery.days_in_status.custom?.from + ' - ' + filterQuery.days_in_status.custom?.to + ' )';
          // this.listStatus = this.listStatus.map((obj: any) => { (obj.value == 'Custom') ? (obj.name = dateLable) : null; return obj })
          setDateObj.type = 'days_in_status';
          setDateObj.name = dateLable;
          setDateObj.lable = 'Days in Status: ' + setDateObj.name;
          this.dateFilters.days_in_status = setDateObj;
          // this.setPayloadFilterQuery.days_in_status = filterQueryParams.days_in_status;
          //Add into selected Chips
          const dateChip = {
            id: (this.selectedFilter.length + 1).toString(),
            name: setDateObj.lable,
            type: setDateObj.type,
            isDateChip: true
          }
          this.selectedFilter.push(dateChip);
        }
      } else {
        // let setDateObj = this._utilService.dateDropdownItems.find(obj => obj.value == filterQueryParams.days_in_status.timeline);
        // setDateObj.type = 'days_in_status';
        // setDateObj.lable = 'Days in Status: ' + setDateObj.name;
        this.dateFilters.days_in_status = filterQuery.days_in_status.timeline;
        // this.setPayloadFilterQuery.days_in_status = filterQueryParams.days_in_status.timeline;
        //Add into selected Chips
        const dateChip = {
          id: (this.selectedFilter.length + 1).toString(),
          name: 'Days in Status: '+ Number(filterQuery.days_in_status.timeline),
          type: 'days_in_status',
          isDateChip: true
        }
        this.selectedFilter.push(dateChip);
      }
    }
    if (filterQuery.hasOwnProperty('jobcost')) {
      if (filterQuery.jobcost) {
        if (filterQuery.jobcost?.from && filterQuery.jobcost?.to) {
          let dateLable = 'Job Cost: ( ' + filterQuery.jobcost?.from + ' - ' + filterQuery.jobcost?.to + ' )';
          // this.setPayloadFilterQuery.jobcost = filterQueryParams.jobcost;
          this.dateFilters.jobcost_amount = filterQuery.jobcost;
          this.filtersDataSource.jobcost_amount = {
            lable: dateLable,
            type: 'jobcost',
            value: filterQuery.jobcost
          };
          //Add into selected Chips
          const dateChip = {
            id: (this.selectedFilter.length + 1).toString(),
            name: dateLable,
            type: 'jobcost',
            isDateChip: true
          }
          this.selectedFilter.push(dateChip);
        }
      }
    }
    if (filterQuery.hasOwnProperty('bid_submitted_date')) {
      if (filterQuery.bid_submitted_date.custom) {
        let setDateObj = this._utilService.dateDropdownItems.find(obj => obj.value == 'Custom');
        if (filterQuery.bid_submitted_date.custom?.from && filterQuery.bid_submitted_date.custom?.to) {
          let dateLable = '( ' + filterQuery.bid_submitted_date.custom?.from + ' - ' + filterQuery.bid_submitted_date.custom?.to + ' )';
          // this.listStatus = this.listStatus.map((obj: any) => { (obj.value == 'Custom') ? (obj.name = dateLable) : null; return obj })
          setDateObj.type = 'bid_submitted_date';
          setDateObj.name = dateLable;
          setDateObj.lable = 'Bid Submitted Date: ' + setDateObj.name;
          this.dateFilters.bid_submitted_date = setDateObj;
          // this.setPayloadFilterQuery.bid_submitted_date = filterQueryParams.bid_submitted_date;
          //Add into selected Chips
          const dateChip = {
            id: (this.selectedFilter.length + 1).toString(),
            name: setDateObj.lable,
            type: setDateObj.type,
            isDateChip: true
          }
          this.selectedFilter.push(dateChip);
        }
      } else {
        let setDateObj = this._utilService.dateDropdownItems.find(obj => obj.value == filterQuery.bid_submitted_date.timeline);
        setDateObj.type = 'bid_submitted_date';
        setDateObj.lable = 'Bid Submitted Date: ' + setDateObj.name;
        this.dateFilters.bid_submitted_date = setDateObj;
        // this.setPayloadFilterQuery.bid_submitted_date = this.setQuery_DateRangeValue(setDateObj);
        //Add into selected Chips
        const dateChip = {
          id: (this.selectedFilter.length + 1).toString(),
          name: setDateObj.lable,
          type: setDateObj.type,
          isDateChip: true
        }
        this.selectedFilter.push(dateChip);
      }
    }
    if (filterQuery.hasOwnProperty('bid_approved_date')) {
      if (filterQuery.bid_approved_date.custom) {
        let setDateObj = this._utilService.dateDropdownItems.find(obj => obj.value == 'Custom');
        if (filterQuery.bid_approved_date.custom?.from && filterQuery.bid_approved_date.custom?.to) {
          let dateLable = '( ' + filterQuery.bid_approved_date.custom?.from + ' - ' + filterQuery.bid_approved_date.custom?.to + ' )';
          // this.listStatus = this.listStatus.map((obj: any) => { (obj.value == 'Custom') ? (obj.name = dateLable) : null; return obj })
          setDateObj.type = 'bid_approved_date';
          setDateObj.name = dateLable;
          setDateObj.lable = 'Bid Approved Date: ' + setDateObj.name;
          this.dateFilters.bid_approved_date = setDateObj;
          // this.setPayloadFilterQuery.bid_approved_date = filterQueryParams.bid_approved_date;
          //Add into selected Chips
          const dateChip = {
            id: (this.selectedFilter.length + 1).toString(),
            name: setDateObj.lable,
            type: setDateObj.type,
            isDateChip: true
          }
          this.selectedFilter.push(dateChip);
        }
      } else {
        let setDateObj = this._utilService.dateDropdownItems.find(obj => obj.value == filterQuery.bid_approved_date.timeline);
        setDateObj.type = 'bid_approved_date';
        setDateObj.lable = 'Bid Approved Date: ' + setDateObj.name;
        this.dateFilters.bid_approved_date = setDateObj;
        // this.setPayloadFilterQuery.bid_approved_date = this.setQuery_DateRangeValue(setDateObj);
        //Add into selected Chips
        const dateChip = {
          id: (this.selectedFilter.length + 1).toString(),
          name: setDateObj.lable,
          type: setDateObj.type,
          isDateChip: true
        }
        this.selectedFilter.push(dateChip);
      }
    }
    if (filterQuery.hasOwnProperty('invoice_submitted_date')) {
      if (filterQuery.invoice_submitted_date.custom) {
        let setDateObj = this._utilService.dateDropdownItems.find(obj => obj.value == 'Custom');
        if (filterQuery.invoice_submitted_date.custom?.from && filterQuery.invoice_submitted_date.custom?.to) {
          let dateLable = '( ' + filterQuery.invoice_submitted_date.custom?.from + ' - ' + filterQuery.invoice_submitted_date.custom?.to + ' )';
          // this.listStatus = this.listStatus.map((obj: any) => { (obj.value == 'Custom') ? (obj.name = dateLable) : null; return obj })
          setDateObj.type = 'invoice_submitted_date';
          setDateObj.name = dateLable;
          setDateObj.lable = 'Invoice Submitted Date: ' + setDateObj.name;
          this.dateFilters.invoice_submitted_date = setDateObj;
          // this.setPayloadFilterQuery.invoice_submitted_date = filterQueryParams.invoice_submitted_date;
          //Add into selected Chips
          const dateChip = {
            id: (this.selectedFilter.length + 1).toString(),
            name: setDateObj.lable,
            type: setDateObj.type,
            isDateChip: true
          }
          this.selectedFilter.push(dateChip);
        }
      } else {
        let setDateObj = this._utilService.dateDropdownItems.find(obj => obj.value == filterQuery.invoice_submitted_date.timeline);
        setDateObj.type = 'invoice_submitted_date';
        setDateObj.lable = 'Invoice Submitted Date: ' + setDateObj.name;
        this.dateFilters.invoice_submitted_date = setDateObj;
        // this.setPayloadFilterQuery.invoice_submitted_date = this.setQuery_DateRangeValue(setDateObj);
        //Add into selected Chips
        const dateChip = {
          id: (this.selectedFilter.length + 1).toString(),
          name: setDateObj.lable,
          type: setDateObj.type,
          isDateChip: true
        }
        this.selectedFilter.push(dateChip);
      }
    }
    if (filterQuery.hasOwnProperty('invoice_approved_date')) {
      if (filterQuery.invoice_approved_date.custom) {
        let setDateObj = this._utilService.dateDropdownItems.find(obj => obj.value == 'Custom');
        if (filterQuery.invoice_approved_date.custom?.from && filterQuery.invoice_approved_date.custom?.to) {
          let dateLable = '( ' + filterQuery.invoice_approved_date.custom?.from + ' - ' + filterQuery.invoice_approved_date.custom?.to + ' )';
          // this.listStatus = this.listStatus.map((obj: any) => { (obj.value == 'Custom') ? (obj.name = dateLable) : null; return obj })
          setDateObj.type = 'invoice_approved_date';
          setDateObj.name = dateLable;
          setDateObj.lable = 'Invoice Approved Date: ' + setDateObj.name;
          this.dateFilters.invoice_approved_date = setDateObj;
          // this.setPayloadFilterQuery.invoice_approved_date = filterQueryParams.invoice_approved_date;
          //Add into selected Chips
          const dateChip = {
            id: (this.selectedFilter.length + 1).toString(),
            name: setDateObj.lable,
            type: setDateObj.type,
            isDateChip: true
          }
          this.selectedFilter.push(dateChip);
        }
      } else {
        let setDateObj = this._utilService.dateDropdownItems.find(obj => obj.value == filterQuery.invoice_approved_date.timeline);
        setDateObj.type = 'invoice_approved_date';
        setDateObj.lable = 'Invoice Approved Date: ' + setDateObj.name;
        this.dateFilters.invoice_approved_date = setDateObj;
        // this.setPayloadFilterQuery.invoice_approved_date = this.setQuery_DateRangeValue(setDateObj);
        //Add into selected Chips
        const dateChip = {
          id: (this.selectedFilter.length + 1).toString(),
          name: setDateObj.lable,
          type: setDateObj.type,
          isDateChip: true
        }
        this.selectedFilter.push(dateChip);
      }
    }
    if (filterQuery.hasOwnProperty('invoice_amount')) {
      if (filterQuery.invoice_amount) {
        if (filterQuery.invoice_amount?.from && filterQuery.invoice_amount?.to) {
          let dateLable = 'Invoice amount: ( ' + filterQuery.invoice_amount?.from + ' - ' + filterQuery.invoice_amount?.to + ' )';
          // this.setPayloadFilterQuery.invoice_amount = filterQueryParams.invoice_amount;
          this.dateFilters.invoice_amount = filterQuery.invoice_amount;
          this.filtersDataSource.invoice_amount = {
            lable: dateLable,
            type: 'invoice_amount',
            value: filterQuery.invoice_amount
          };
          //Add into selected Chips
          const dateChip = {
            id: (this.selectedFilter.length + 1).toString(),
            name: dateLable,
            type: 'invoice_amount',
            isDateChip: true
          }
          this.selectedFilter.push(dateChip);
        }
      }
    }
    if (filterQuery.hasOwnProperty('bid_amount')) {
      if (filterQuery.bid_amount) {
        if (filterQuery.bid_amount?.from && filterQuery.bid_amount?.to) {
          let dateLable = 'Invoice amount: ( ' + filterQuery.bid_amount?.from + ' - ' + filterQuery.bid_amount?.to + ' )';
          // this.setPayloadFilterQuery.bid_amount = filterQueryParams.bid_amount;
          this.dateFilters.bid_amount = filterQuery.bid_amount;
          this.filtersDataSource.bid_amount = {
            lable: dateLable,
            type: 'bid_amount',
            value: filterQuery.bid_amount
          };
          //Add into selected Chips
          const dateChip = {
            id: (this.selectedFilter.length + 1).toString(),
            name: dateLable,
            type: 'bid_amount',
            isDateChip: true
          }
          this.selectedFilter.push(dateChip);
        }
      }
    }
  }

  updateQueryFromBackendTimeSheet(filterQuery: any): void {
    // 1. Status
    // Getting the status queries from the backend and if the status query exists then we have to change to 'isSelect' value to true.
    let statusQuery = filterQuery?.status;
    if (statusQuery && statusQuery?.length > 0) {
      this.filtersDataSource.filterLists.find((element: any) => {
        return element.label == 'Status';
      }).listItems.map((statusList: any) => {
        statusQuery.map((status: any) => {
          if (status === statusList.name) {
            statusList.selected = true;
          } else {
            statusList.selected = false;
          }
        });
      });
    } else {
      this.filtersDataSource.filterLists.find((element: any) => {
        return element.label == 'Status';
      }).listItems.map((statusList: any) => {
        statusList.selected = false;
      });
    }

    // 2. Properties
    //Getting the type queries from the backend and if the property query exists then we have to change to 'isSelect' value to true.
    let propertyQuery = filterQuery?.property;
    if (propertyQuery && propertyQuery?.length > 0) {
      /********* If not in list add with seleced option **********/
      propertyQuery.forEach(element => {
        let indexVal = this.filtersDataSource.filterLists.find((element) => { return element.label == 'Properties'; }).listItems.findIndex(obj => obj.id == element.id)
        if (indexVal == -1) {
          element.selected = true;
          this.filtersDataSource.filterLists.find((element) => { return element.label == 'Properties'; }).listItems.push(element);
        }
      });
      this.filtersDataSource.filterLists.find((element: any) => {
        return element.label == 'Properties';
      }).listItems.map((propertyList: any) => {
        propertyQuery.map((property: any) => {
          if (property?.id === propertyList?.id) {
            propertyList.selected = true;
          } else {
            propertyList.selected = false;
          }
        });
      });
    } else {
      this.filtersDataSource.filterLists.find((element: any) => {
        return element.label == 'Properties';
      }).listItems.map((propertyList: any) => {
        propertyList.selected = false;
      });
    }
    // 3. Engineer
    //Getting the type queries from the backend and if the property query exists then we have to change to 'isSelect' value to true.
    let engineerQuery = filterQuery?.engineer;
    if (engineerQuery && engineerQuery?.length > 0) {
      this.filtersDataSource.filterLists.find((element: any) => {
        return element.label == 'Engineer';
      }).listItems.map((engineerList: any) => {
        engineerQuery.map((engineer: any) => {
          if (engineer?.id === engineerList?.id) {
            engineerList.selected = true;
          } else {
            engineerList.selected = false;
          }
        });
      });
    } else {
      this.filtersDataSource.filterLists.find((element: any) => {
        return element.label == 'Engineer';
      }).listItems.map((engineerList: any) => {
        engineerList.selected = false;
      });
    }
    // 3. Created from and to
    let createdAtQuery = filterQuery?.work_date;
    if (createdAtQuery?.from) {
      this.filtersDataSource.dateCreatedDateRange.from = new Date(createdAtQuery.from);
    } else {
      this.filtersDataSource.dateCreatedDateRange.from = '';
    }
    // This is for today's view in Timesheet
    if (createdAtQuery?.from == 'now') {
      this.filtersDataSource.dateCreatedDateRange.from = new Date();
    }
    if (createdAtQuery?.to) {
      this.filtersDataSource.dateCreatedDateRange.to = new Date(createdAtQuery.to);
    } else {
      this.filtersDataSource.dateCreatedDateRange.to = '';
    }
    // This is for today's view in Timesheet
    if (createdAtQuery?.to == 'now') {
      this.filtersDataSource.dateCreatedDateRange.to = new Date();
    }

    // 4. Modified at (Approve Date) from and to
    let modifiedAtQuery = filterQuery?.approved_date;
    if (modifiedAtQuery?.from) {
      this.filtersDataSource.dateApprovedDateRange.from = new Date(modifiedAtQuery.from);
    } else {
      this.filtersDataSource.dateApprovedDateRange.from = '';
    }
    if (modifiedAtQuery?.to) {
      this.filtersDataSource.dateApprovedDateRange.to = new Date(modifiedAtQuery.to);
    } else {
      this.filtersDataSource.dateApprovedDateRange.to = '';
    }

    // 5.sort query we append from backend
    if (filterQuery?.active) {
      this.sortValue.active = filterQuery.active ? filterQuery.active : '';
      this.sortValue.direction = filterQuery.direction ? filterQuery.direction : '';
    } else {
      this.sortValue = {
        active: '',
        direction: ''
      };
    }
    // 6. Billable
    // Getting the Billable queries from the backend and if the Billable query exists then we have to change to 'isSelect' value to true.
    let billableQuery = filterQuery?.is_billable;
    if (billableQuery && billableQuery?.length > 0) {
      this.filtersDataSource.filterLists.find((element: any) => {
        return element.label == 'Billable';
      }).listItems.map((billableList: any) => {
        billableQuery.map((billable: any) => {
          if (billable === billableList.value) {
            billableList.selected = true;
          } else {
            billableList.selected = false;
          }
        });
      });
    } else {
      this.filtersDataSource.filterLists.find((element: any) => {
        return element.label == 'Billable';
      }).listItems.map((billableList: any) => {
        billableList.selected = false;
      });
    }
    let selectedFiltersList = this.filtersDataSource.filterLists.map((element: any) => element.listItems.filter((subElement: any) => subElement.selected === true))
    let selectedFiltersObj: any = [];
    selectedFiltersList.forEach((element: any) => {
      selectedFiltersObj.push(...element);
    });
    this.selectedFilter = selectedFiltersObj;
    // This code is for while adding the from and to date we need to add the date chips in the selected filter chips.
    if (this.filtersDataSource.dateCreatedDateRange?.from && this.filtersDataSource.dateCreatedDateRange?.to) {
      const dateCreatedChip = {
        id: (this.selectedFilter.length + 1).toString(),
        name: 'date created: from ' + this._utilService.formatDateToMM_DD_YYYY_Slash(this.filtersDataSource.dateCreatedDateRange.from) + ' - ' + 'to ' + this._utilService.formatDateToMM_DD_YYYY(this.filtersDataSource.dateCreatedDateRange.to),
        isDateCreatedDateChip: true
      }
      this.selectedFilter.push(dateCreatedChip);
    }

    if (this.filtersDataSource.dateApprovedDateRange?.from && this.filtersDataSource.dateApprovedDateRange?.to) {
      const dateApprovedChip = {
        id: (this.selectedFilter.length + 1).toString(),
        name: 'Date Approved: from ' + this._utilService.formatDateToMM_DD_YYYY_Slash(this.filtersDataSource.dateApprovedDateRange.from) + ' - ' + 'to ' + this._utilService.formatDateToMM_DD_YYYY(this.filtersDataSource.dateApprovedDateRange.to),
        isDateApprovedChip: true
      }
      this.selectedFilter.push(dateApprovedChip);
    }
  }


  /** Track by Index  **/
  trackByIndex(index: number, item: any): number {
    return index;
  }

}
