export const APIroutesMapper = {
  PROPERTY: 'properties',
  COMPANY: 'companies',
  JOB: 'jobs',
  LEASE: 'leases',
  TENANTS: 'tenants',
  VENDORS: 'vendors',
  COI: 'coi',
  INVOICES: 'Invoices',
  BIDS: 'Bids',
  CHANGE_ORDER: 'Change Order',
  EQUIPMENT: 'Equipment',
  JOB_COST: 'job-cost'
};

export const FolderEntityMapper = {
  properties: 'PROPERTY',
  companies: 'COMPANY',
  jobs: 'JOB',
  leases: 'LEASE',
  tenants: 'COMPANY',
  vendors: 'COMPANY',
  coi: 'COI',
  Equipment: 'EQUIPMENT',
  Bids: 'JOB_COST',
  Invoices: 'JOB_COST',
  'Change Order': 'JOB_COST'
};

export const EntityType = {
  FOLDER: 'FOLDER',
  FILE: 'FILE'
};

export const NATIVE_URL = [
  'invoices',
  'bids',
  'pre-completion',
  'post-completion'
];

export const GetCompanyTypePayloadMapper = {
  vendors: 'Vendor',
  tenants: 'Tenant'
}

export const COMPANY_TYPES_DROP_DOWN = [
  {
    name: 'Vendor',
    label: 'Vendor'
  },
  {
    name: 'Tenant',
    label: 'Tenant'
  }
]
