import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'fyxt-action-chip',
    templateUrl: './action-chip.component.html',
    styleUrls: ['./action-chip.component.scss'],
    standalone: true
})
export class ActionChipComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
