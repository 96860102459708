import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, NO_ERRORS_SCHEMA, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { NgSwitch, NgSwitchCase, NgTemplateOutlet, NgSwitchDefault, CommonModule } from '@angular/common';

@Component({
    selector: 'fyxt-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    standalone: true,
    imports: [CommonModule,NgSwitch, NgSwitchCase, MatButtonModule, NgTemplateOutlet, NgSwitchDefault],
    schemas: [CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA]
})
export class ButtonComponent implements OnInit {
  @Input() btnType: 'flat' | 'outlined' | 'no-border' | 'text' | 'link' | 'icon' = 'flat';
  @Input() htmlType: 'button' | 'submit' | 'reset' = 'button';
  @Input() color: string = '';
  @Input() customClass: string = '';
  @Input() disabled: boolean = false;

  constructor() { }

  ngOnInit(): void { }

  /**
   * set Button Color
   * @param color
   * @returns
   */
  btnColor(color: string) {
    let btnColor = color;
    if (color == 'orange') {
      btnColor = 'orange';
    } else if (color == 'blue') {
       btnColor = 'blue';
    }
    return btnColor;
  }

}
