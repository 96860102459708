import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'src/app/services/config.service';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../utility.service';
import { TicketFlowsService } from 'src/app/services/ticket-flows.service';
import { FileSaverService } from 'ngx-filesaver';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class PhotoBankService {
  public myPhotoes: any[] = [];
  public viewPhoto: any = {};
  public myTeamDropdown: any[] = [];
  public allUserDropDown: any[] = [];
  public allUserSharedData: any[] = [];
  public myTeamSharedData: any[] = [];
  public isShownShare: boolean = false;
  public previewPhotoID: any = '';
  public upload_photo: any = [];

  photoBankViewObj: any = {
    selected_folder_type: '',
    is_Opened_Folder: false,
    is_Upload_Photos: false,
    is_View_Photos: false,
    is_View_Share: false,
    is_Share_Active_Tab: 'My Teams',
    selectedPhotos: [],
    is_Upload_Description: false,
    is_team_checkbox: false,
    is_User_checkbox: false,
    is_job_modal_popup: false,
    isModalBoxOption: false,
    is_SelectedOption: "",
    isEmailAttachment: false,
    isBulkEmailAttachment: false,
    isNewModal: false,
  };

  allUploadedPhotosList: any[] = [];
  listPhotoPagination: any = {
    next: ''
  };
  folderPreviewImages: any = {};
  isAddPhotoEmail = false;

  constructor(public _http: HttpClient, private _router: Router, private activatedRoute: ActivatedRoute, public _Config: ConfigService, public _utilService: UtilityService, public _ticketService: TicketFlowsService,
    public _FileSaverService: FileSaverService
  ) {
  }
  /************** Base HTTP Request Methods *****************/

  /**
   * To Get Http Request for REST API
   * @param URL
   * @param Options
   */
  doGET(URL?: string, Options?: any): Observable<any> {
    return this._http.get(URL, Options).pipe(take(1));
  }

  /**
   * To Post Http Request for REST API
   * @param URL   // The Endpoint URL
   * @param Body    //Post Input Body
   * @param Options   //Headers or Other Optional Params
   */
  doPOST(URL?: string, Body?: any, Options?: any) {
    return this._http.post(URL, Body, Options);
  }

  /**
   * To Update Http Request for REST API
   * @param URL
   * @param Body
   * @param Options
   */
  doPUT(URL?: string, Body?: any, Options?: any) {
    return this._http.put(URL, Body, Options);
  }


  /**
   * To Delete Http Request for REST API
   * @param URL
   * @param Options
   */
  doDelete(URL?: string, Options?: any) {
    return this._http.delete(URL, Options);
  }

  /**
   * If Patch Request for Http Restfull API
   * @param URL
   * @param Body
   * @param Options
   */
  doPatch(URL?: string, Body?: any, Options?: any) {
    return this._http.patch(URL, Body, Options);
  }

  getCurrentActivePage() {
    return this.activatedRoute.snapshot['_routerState'].url;;
  }
  /************** --Base HTTP Request Methods End-- *****************/

  /**
 * Get All Photo_Bank Photoes
 */
  getFolderPreviewImages() {

    this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.getAllPhotosDashboard;

    this.doGET(requestURL).subscribe((response: any) => {
      this.folderPreviewImages = response || {};

      if (this.folderPreviewImages.shared_with_me.count <= 4) {
        let image = '../../../../../assets/images/utilities/default_img.png'
        for (let i = 0; i < (4 - this.folderPreviewImages.shared_with_me.count); i++) {
          this.folderPreviewImages.shared_with_me.photos.push(image);
        }
      }
      if (this.folderPreviewImages.shared_with_others.count <= 4) {
        let image = '../../../../../assets/images/utilities/default_img.png'
        for (let i = 0; i < (4 - this.folderPreviewImages.shared_with_others.count); i++) {
          this.folderPreviewImages.shared_with_others.photos.push(image);
        }
      }
      if (this.folderPreviewImages.my_photos.count <= 4) {
        let image = '../../../../../assets/images/utilities/default_img.png'
        for (let i = 0; i < (4 - this.folderPreviewImages.my_photos.count); i++) {
          this.folderPreviewImages.my_photos.photos.push(image);
        }
      }

      this._Config.isLoader = false;


    },
      (error) => {

        this._Config.isLoader = false;

      });

  }


  /**
  * Get All Photo_Bank Photoes
  */
  getAllPhoto(isLoader?: any, isPagination?: any) {
    this._Config.isLoader = isLoader;

    let requestURL = "";

    if (isPagination) {

      if (this.listPhotoPagination.next) {
        requestURL = this.listPhotoPagination.next;
      }
      else {
        requestURL = environment.baseURL + this._Config.getAllPhotoes;

      }

    }
    else {

      if (this.photoBankViewObj.selected_folder_type == 'shared_with_me')
        requestURL = environment.baseURL + this._Config.getSharedWithMe;
      else if (this.photoBankViewObj.selected_folder_type == 'shared_with_others')
        requestURL = environment.baseURL + this._Config.getSharedWithOther;
      else
        requestURL = environment.baseURL + this._Config.getAllPhotoes;


    }




    if (this.photoBankViewObj.selected_folder_type !== 'RecentAttachMents') {
      this.doGET(requestURL).subscribe((response: any) => {

        let allphotoes = response.results || [];

        this.listPhotoPagination.next = response.next ? response.next : '';


        if (isPagination) {

          this.allUploadedPhotosList.push(...allphotoes);

        }
        else {

          this.allUploadedPhotosList = allphotoes;

        }




        this._Config.isLoader = false;

      },
        (error) => {
          this._Config.isLoader = false;
        });
    } else {
      this._Config.isLoader = false;
    }

  }
  /**
     * Get Photo_Bank View Photo
     */
  getViewSelectedPhoto(photoID) {
    // this._Config.isLoader = true;
    let requestURL = environment.baseURL + this._Config.getViewPhoto;
    requestURL = requestURL.replace("{photoID}", photoID);
    this.doGET(requestURL).subscribe((response: any) => {
      // this._Config.isLoader = false;
      this.viewPhoto = response || {};

    },
      (error) => {
        // this._Config.isLoader = false;
      });
  }


  /**share preview photo */
  toggleShow1() {

    if (this.photoBankViewObj.is_View_Photos)
      this.photoBankViewObj.is_View_Share = !this.photoBankViewObj.is_View_Share;
    else {
      this.photoBankViewObj.is_View_Photos = true;
      this.photoBankViewObj.is_View_Share = true;

    }


    // this.getAllUserDropDown();
    this.getMyTeamDropDown();
    this.getAllUserDropDown();
  }

  /**
  * Get All User dropdown
  */
  getAllUserDropDown() {
    this._Config.isLoader = true;
    let requestURL = environment.baseURL + this._Config.getAllUser;
    this.doGET(requestURL).subscribe((response: any) => {
      this._Config.isLoader = false;
      this.allUserDropDown = response || [];

    },
      (error) => {
        this._Config.isLoader = false;
      });
  }
  /**
 * Get My Team dropdown
 */
  getMyTeamDropDown() {
    let currentUserInfo: any = localStorage.getItem('userobj') ? JSON.parse(localStorage.getItem('userobj')) : {};
    this._Config.isLoader = true;
    let requestURL = environment.baseURL + this._Config.getMyTeam;
    requestURL = requestURL.replace("{photoID}", currentUserInfo.id);
    this.doGET(requestURL).subscribe((response: any) => {
      this._Config.isLoader = false;
      this.myTeamDropdown = response || [];

    },
      (error) => {
        this._Config.isLoader = false;
      });
  }
  /**
   * preview shared my team and all user checkbox selection value
   * @param event
   * @param index
   * @param type
   */
  onChangeUserSelect(event, index, type) {
    let isTrue = event.target.checked;
    if (type == 'user') {
      this.allUserDropDown[index].isChecked = isTrue;
      this.allUserSharedData = this.allUserDropDown.filter(obj => obj.isChecked);
      this.photoBankViewObj.is_User_checkbox = false

    } else if (type == 'team') {
      this.myTeamDropdown[index].isChecked = isTrue;
      this.myTeamSharedData = this.myTeamDropdown.filter(obj => obj.isChecked);
      this.photoBankViewObj.is_team_checkbox = false

    }
  }



  /**
   * shared the data from my teams and all user
   */
  sharedPhoto() {


    let allUserSharedID = this.allUserDropDown.filter(obj => obj.isChecked == true).map(obj => obj.id) || [];
    let myTeamSharedID = this.myTeamDropdown.filter(obj => obj.isChecked == true).map(obj => obj.id) || [];

    let requestURL = environment.baseURL + this._Config.postBulkSharedPhoto;

    let deleteBulkPreviewPhotoID = this.photoBankViewObj.selectedPhotos.map(obj => obj.id) || [];

    let payload: any = {
      // "users": allUserSharedID.length != 0 ? allUserSharedID : myTeamSharedID,
      "photos": deleteBulkPreviewPhotoID,
    };

    if (this.photoBankViewObj.is_Share_Active_Tab == 'My Teams')
      payload.users = myTeamSharedID;
    else
      payload.users = allUserSharedID;


    this.doPOST(requestURL, payload).subscribe((result: any) => {
      this._Config.isLoader = false;
      this._utilService.showSuccess('', 'Photo Shared Successfully.');
      this.photoBankViewObj.is_View_Share = false;

      this.photoBankViewObj.is_team_checkbox = false;
      this.photoBankViewObj.is_User_checkbox = false;

      this.getViewSelectedPhoto(this.viewPhoto.id);

    }, (error) => {
      this._Config.isLoader = false;
    })
  }

  /**
* Show Confirm Dialog box
*/
  confirmDialog(type) {

    // let checkedPhotoId = id
    if (type == 'delete') {
      let deleteMsg = " Are you sure that you want to permanently delete the selected photo ? "
      let self = this;
      this._utilService.confirmDialog(true, deleteMsg, function () {
        self.deleteSelectedPhoto();
      }, function () {
      });
    } else if (type == 'share') {
      let deleteMsg = " Are you sure that you want to share the selected photo ? "
      let self = this;
      this._utilService.confirmDialog(true, deleteMsg, function () {
        self.sharedPhoto();
      }, function () {
      });
    }
  }

  clearCheckbox() {

    this.photoBankViewObj.is_Share_Active_Tab = 'My Teams';
    this.photoBankViewObj.is_team_checkbox = false;
    this.photoBankViewObj.is_User_checkbox = false;
    if (this.photoBankViewObj.is_View_Photos) {
      this.photoBankViewObj.is_View_Photos = false;
      this.photoBankViewObj.is_Upload_Photos = false;

      this.photoBankViewObj.selectedPhotos.forEach(element => {
        element.isChecked = false;
      });
      this.photoBankViewObj.selectedPhotos = [];
    } else {
      this.photoBankViewObj.selectedPhotos.forEach(element => {
        element.isChecked = false;
      });
      this.photoBankViewObj.selectedPhotos = [];
    }
  }



  deleteSelectedPhoto() {

    this._Config.isLoader = true;
    let deleteBulkPreviewPhotoID: any;
    if (this.photoBankViewObj.selectedPhotos.length > 0) {
      deleteBulkPreviewPhotoID = this.photoBankViewObj.selectedPhotos.map(obj => obj.id) || [];
    } else if (this.upload_photo.length > 0) {
      deleteBulkPreviewPhotoID = this.upload_photo.map(obj => obj.id) || [];
    }


    if (deleteBulkPreviewPhotoID.length > 0) {

      let requestURL = environment.baseURL + this._Config.deleteBulkPhoto;
      requestURL = requestURL.replace('{photoes}', JSON.stringify(deleteBulkPreviewPhotoID));


      this.doDelete(requestURL).subscribe((event: any) => {

        this.refreshUploadedPhotos(deleteBulkPreviewPhotoID);
        if (this.photoBankViewObj.selectedPhotos.length > 0) {
          this.clearCheckbox();
          this._utilService.showSuccess('', ' Deleted Successfully.');

        }
        if (this.upload_photo.length > 0 || this.upload_photo.length == 0) {
          this.photoBankViewObj.is_Upload_Photos = false;
          this.photoBankViewObj.is_Upload_Description = false;

          this.getAllPhoto(true);
        }





        this._Config.isLoader = false;


      },
        (error) => {
          this._Config.isLoader = false;
          let errorMsg = error.error.error
          this._utilService.showError('', errorMsg);
          if (this.upload_photo.length > 0 || this.upload_photo.length == 0) {
            this.photoBankViewObj.is_Upload_Photos = false;
            this.photoBankViewObj.is_Upload_Description = false;

            this.getAllPhoto(true);
          }

        });

    }



  }



  refreshUploadedPhotos(deletedIDs: any[]) {

    deletedIDs.forEach(deleteID => {
      let indexVal = this.allUploadedPhotosList.findIndex(obj => obj.id == deleteID);

      if (indexVal != -1)
        this.allUploadedPhotosList.splice(indexVal, 1);

    });

  }


  descriptionUpdates() {
    let bulkDescription = []
    this.upload_photo.forEach((element: any) => {
      let payload = {
        "id": element.id,
        "description": element.description
      };
      bulkDescription.push(payload);
    });

    let requestURL = environment.baseURL + this._Config.bulkUploadDescription;

    this.doPatch(requestURL, bulkDescription).subscribe((result: any) => {
      this._Config.isLoader = false;
      this._utilService.showSuccess('', 'Photo and Description Saved Successfully.');
      this.isShownShare = false;

      this.photoBankViewObj.is_Opened_Folder = true;
      this.photoBankViewObj.is_Upload_Photos = false;
      this.photoBankViewObj.is_View_Photos = false;
      this.photoBankViewObj.is_View_Share = false;
      this.photoBankViewObj.is_Upload_Description = false;

      this.getAllPhoto(true);
      this._ticketService.uploadPhotos = [];
      this.upload_photo = [];
    }, (error) => {
      this._Config.isLoader = false;
    })

  }



  /**
   * Download Selected Photos
   */
  downloadSelectedPhoto() {

    this._Config.isLoader = true;

    let selectedPhotoID = this.photoBankViewObj.selectedPhotos.map(obj => obj.id) || [];

    if (selectedPhotoID.length > 0) {

      if (selectedPhotoID.length == 1) {
        let selectedFile = this.photoBankViewObj.selectedPhotos[0];

        setTimeout(() => {
          this.singleDownloadPhoto(selectedFile);
        }, 300);

      }
      else {
        this.multiDownloadPhotos(selectedPhotoID);
      }
    }


  }



  singleDownloadPhoto(fileObj: any) {

    let url = fileObj.file;
    let extension = url.split('?')[0].split('.').pop()
    let fileName = url.split('/').pop().split('#')[0].split('?')[0];

    let requestURL = environment.baseURL + this._Config.downloadPhotos;

    let postBody = {
      photos: [fileObj.id]
    };

    this._http.post(requestURL, postBody, {
      observe: 'response',
      responseType: 'blob'
    }).subscribe((res: any) => {

      this._FileSaverService.save(res.body, fileName);
      this._Config.isLoader = false;

    }, (error) => {
      this._Config.isLoader = false;
    });

    return;


  }



  multiDownloadPhotos(selectedPhotoID: any) {

    if (this.photoBankViewObj.selectedPhotos.length > 0) {

      let currentTime = new Date().getTime();
      let fileName = currentTime + '_download.zip';
      let requestURL = environment.baseURL + this._Config.downloadPhotos;

      let postBody = {
        photos: selectedPhotoID
      };

      this._http.post(requestURL, postBody, {
        observe: 'response',
        responseType: 'blob'
      }).subscribe((res: any) => {

        this._FileSaverService.save(res.body, fileName);
        this._Config.isLoader = false;

      }, (error) => {
        this._Config.isLoader = false;
      });


      return;
    }

  }

}
