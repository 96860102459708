import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class JobCostApprovalService {

  constructor(private httpClient: HttpClient) { }

  public getAllApprovalScenario(requestURL: string, payload: any) {
    return this.httpClient.post(requestURL, payload);
  }

  public createScenario(payload) {
    return this.httpClient.post(`${environment.LocalDev_URL_V2}approval-workflow/`, payload);
  }

  public getSingleScenarioDetails(scenarioId: string) {
    return this.httpClient.get(`${environment.LocalDev_URL_V2}approval-workflow/${scenarioId}/`);
  }

  public updateScenario(payload: any, scenarioId: string) {
    return this.httpClient.patch(`${environment.LocalDev_URL_V2}approval-workflow/${scenarioId}/`, payload);
  }

  public deleteApprovalScenario(scenarioId: string) {
    return this.httpClient.delete(`${environment.LocalDev_URL_V2}approval-workflow/${scenarioId}/`);
  }
}
