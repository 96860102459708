// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-tooltip {
  max-width: 100% !important;
}

.set-height {
  height: 119px;
}`, "",{"version":3,"sources":["webpack://./src/assets/@fyxt/scss/_variables.scss","webpack://./src/app/@fyxt/_shared/_reusable_ui/_controls/textarea/textarea.component.scss"],"names":[],"mappings":"AAoFA;EACE,0BAAA;ACnFF;;AAAA;EACE,aAAA;AAGF","sourcesContent":["// Prefix for :root CSS variables\n\n// Color system\n\n$purple: #814BA0;\n$black:  #000;\n$white:  #ffffff;\n$sky-blue : #0277BD;\n$red : #D13147;\n$grey : #606D81;\n\n\n//Theme Colors\n$orange:            #D63C00;\n$hover-orange:      #F44400;\n$hover-orange-o :      #D63C0014;\n$dark-blue :      #192c49;\n$hover-blue :      #274572;\n$hover-blue-o :      #192C4914;\n$border-color : #8F95B238;\n$warning-color: #A85D00;\n$success-color: #138813;\n\n\n$divider_color: #f7f8fa;\n$app_border_color: #E6E8F0;\n$app_radio_border: #C3C6D1;\n\n\n//background\n\n$bg_transperant: transparent;\n$bg_light_grey: #FBFBFD;\n$bg_light_grey1: #F8F8FB;\n\n//box-shadow\n$app_shadow: rgba(0, 0, 0, 0.2);\n$card_box_shadow: 0px 4px 12px #8F95B238;\n$dropdown_box_shadow: 0px 4px 12px #8F95B238;\n\n\n//border-radius\n$app_border_radius: 4px;\n\n//letter-spacing\n$app_letter_spacing: 0px;\n\n//font-family\n$app_font_famil_name: 'roboto';\n\n//opacity\n$app_opacity: 1;\n\n//opacity-change\n$app_opacity_change: 0.5;\n\n//no-border\n$no-border: 0;\n\n//cursor pointer\n$app_cursor_pointer: pointer;\n$app_cursor_default: default;\n\n//cursor no drop\n$app_cursor_nodrop: no-drop;\n\n\n\n//font-weight\n\n$app_font_weight_regular: 400;\n$app_font_weight_medium: 500;\n$app_font_weight_bold: 700;\n\n//font-size\n$app_font_size_xxsmall: 11px;\n$app_font_size_xsmall: 12px;\n$app_font_size_xmsmall: 13px;\n$app_font_size_small: 14px;\n$app_font_size_medium: 16px;\n$app_page_title_font_size: 28px;\n\n\n\n.mat-mdc-tooltip {\n  max-width: 100% !important;\n}\n\n// .mat-divider{\n//   border: 1px solid $divider_color !important;\n// }\n","@import 'src/assets/@fyxt/scss/_variables.scss';\n\n.set-height {\n  height: 119px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
