// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host a:hover {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/app/Modules/_fyxt_modules/leases/pages/lease-tab-file-information/lease-tab-file-information.component.scss"],"names":[],"mappings":"AACE;EACE,0BAAA;AAAJ","sourcesContent":[":host {\n  a:hover {\n    text-decoration: underline;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
