import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

export type verificationPayload = {
  vendorId: string;
  email?: string;
  number?: string;
  entityId: string;
  entityType: 'JOB';
}

export type verifyOTP = {
  vendorId: string;
  otp: string;
}


@Injectable({
  providedIn: 'root'
})
export class ExtVendorJobsService {

  showJobInfo = new Subject();
  selectedJobInfo: any = {};
  public vendorParamID: string = '';
  vendorJobId: string = '';
  public fullVendorDetails: any;
  public checklistCountUpdated = new BehaviorSubject<boolean>(false);
  public checklistUpdated = new BehaviorSubject<boolean>(false);
  public updateFile = new BehaviorSubject<boolean>(false);
  public jobDetailsDrawer = new BehaviorSubject<boolean>(false);
  public jobStatus: any;
  public workApproved: any;
  private subDomain: string;

  isUpdateSchedule = new Subject();


  constructor(private httpClient: HttpClient) {
    let url = window.location.origin;
    this.subDomain = this.extractDomain(url).split('.')[0];
    this.subDomain == 'localhost' ? this.subDomain = 'web' : this.subDomain;
  }

   extractDomain(url: string) {
    let domain;
    if (url.indexOf('://') > -1) {
      domain = url.split('/')[2];
    } else {
      domain = url.split('/')[0];
    }
    domain = domain.split(':')[0];
    return domain;
  }

  getVendorJob() {
    return this.httpClient.get(environment.baseURL + `external/vendor-jobs/${this.vendorJobId}/`);
  }

  updateVendorJobStatus(payload) {
    return this.httpClient.patch(environment.baseURL + `external/vendor-jobs/${this.vendorJobId}/`, payload);
  }

  changeScheduleStatus(scheduleId: string, payload) {
    return this.httpClient.patch(environment.baseURL + `external/vendor-jobs/schedules/${scheduleId}/`, payload);
  }

  addSchedule(payload) {
    const data = [payload]
    return this.httpClient.post(environment.baseURL + `external/vendor-jobs/${this.vendorJobId}/schedules/`, data);
  }

  updateSchedule(payload,scheduleID:any) {
    return this.httpClient.patch(environment.baseURL + `external/vendor-jobs/schedules/${scheduleID}/`, payload);
  }

  getScope() {
    return this.httpClient.get(environment.baseURL + `external/vendor-jobs/${this.vendorJobId}/vendor-scope-instructions/`);
  }

  getScopeHistory() {
    return this.httpClient.get(environment.baseURL + `external/vendor-jobs/${this.vendorJobId}/scope-instructions-history/`);
  }

  getChecklist() {
    return this.httpClient.get(environment.baseURL + `external/vendor-jobs/${this.vendorJobId}/job-checklist-items/`);
  }

  getJobStatus() {
    return this.httpClient.get(environment.baseURL + `external/vendor-jobs/status-dropdown/`);
  }

  fetchChecklistUpdated(): Observable<boolean> {
    return this.checklistUpdated.asObservable();
  }

  fetchJobDetailsDrawer(): Observable<boolean> {
    return this.jobDetailsDrawer.asObservable();
  }

  refreshFile(): Observable<boolean> {
    return this.updateFile.asObservable();
  }

  uploadJobCost(jobVendorId: string, body) {
    return this.httpClient.post(environment.baseURL + `external/vendor-jobs/${jobVendorId}/costs/`, body);
  }

  dismissJobCost(jobCostId: string, payload) {
    return this.httpClient.patch(environment.baseURL + `external/vendor-jobs/costs/${jobCostId}/`, payload);
  }

  updateJobCost(jobCostId: string, payload) {
    return this.httpClient.patch(environment.baseURL + `external/vendor-jobs/costs/${jobCostId}/`, payload);
  }

  listJobCost(jobId: string) {
    return this.httpClient.get(environment.baseURL + `external/vendor-jobs/${this.vendorParamID}/cost-list/?job=${jobId}`);
  }

  sendOTPByMail(payload: verificationPayload) {
    return this.httpClient.post(environment.softAuth.url + `auth/vendors/otp`, payload, { headers: { "x-tenant-id": this.subDomain } })
  }

  sendOTPBySMS(payload: verificationPayload) {
    return this.httpClient.post(environment.softAuth.url + `auth/vendors/sms/otp`, payload, { "headers": { "x-tenant-id": this.subDomain } })
  }

  reSendOTPByMail(payload: verificationPayload) {
    return this.httpClient.post(environment.softAuth.url + `auth/vendors/otp/resend`, payload, { "headers": { "x-tenant-id": this.subDomain } })
  }

  reSendOTPBySMS(payload: verificationPayload) {
    return this.httpClient.post(environment.softAuth.url + `auth/vendors/sms/otp/resend`, payload, { "headers": { "x-tenant-id": this.subDomain } })
  }

  validateOTP(payload: verifyOTP) {
    return this.httpClient.post(environment.softAuth.url + `auth/vendors/otp/validate  `, payload, { "headers": { "x-tenant-id": this.subDomain } })
  }

  getAllProperties() {
    return this.httpClient.get(environment.baseURL + `external/vendor-jobs/${this.vendorParamID}/properties/`)
  }

  getAllPriorities() {
    return this.httpClient.get(environment.baseURL + `jobs/priorities/`)
  }

  getExternalContacts(jobId: string, type: string) {
    const payload = {
      "type": type
    }
    return this.httpClient.post(environment.softAuth.url + `vendors/jobs/${jobId}/contacts`, payload, {
      "headers": {
        "x-tenant-id": this.subDomain,
        "ExternalVendorSkipVendor": 'ExternalVendorSkipVendor',
        "jobId": jobId.toString()
      }
    })
  }

  getExternalVendorNotesUsers(jobId: string) {
    return this.httpClient.get(environment.softAuth.url + `jobs/${jobId}/public/contacts`, {
      "headers": {
        "x-tenant-id": this.subDomain
      }
    })
  }

  /**
   * Do Pusher trigger for job tab actions
   * @param type
   * @param actionItemID
   */
  triggerPusherEventForJobTabs(type: string,actionEventID:any) {
    
    if (actionEventID === '' || actionEventID === null || actionEventID === undefined) {
      return
    }

    let requestURL = environment.baseURL + 'jobs/' + actionEventID + '/{actionType}/';

    if (type == 'Schedule') {
      requestURL = requestURL.replace('{actionType}', 'realtime-schedules-details');
    }
    else if (type == 'Job Cost') {
      requestURL = requestURL.replace('{actionType}', 'realtime-cost-details');
    }


    this.httpClient.get(requestURL).subscribe({
      next: (response: any) => { console.log(response); },
      error: (error) => { },
      complete: () => { }
    });
  }

}
