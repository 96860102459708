import { Component, OnInit } from '@angular/core';
import { GraphqlEquipmentManagementService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/equipment-management/graphql-equipment-management.service';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';

@Component({
    selector: 'app-duplicate-equipment-name',
    templateUrl: './duplicate-equipment-name.component.html',
    styleUrls: ['./duplicate-equipment-name.component.scss'],
    standalone: true,
    imports: [TextboxComponent, FormsModule, NgxIntlTelInputModule, MatDialogModule, ButtonComponent]
})
export class DuplicateEquipmentNameComponent implements OnInit {

  duplicateEquipmentNameForm: any = {
    equipmentName: '',
    serialNumber: '',
    specificLocation: '',

  };

  closeOnSave:boolean = false;


  constructor(
    private _graphql:GraphqlEquipmentManagementService
  ) { }

  ngOnInit(): void {
  }


  selectedTags(event: any): void {
    this.duplicateEquipmentNameForm.selectedFilters=event;
  }


  /**
   * save view Trigger
   */
  saveView(){
   let duplicateEquipment :  any = {
    name:this.duplicateEquipmentNameForm.equipmentName,
    equipment_type_id:JSON.parse(this._graphql.selectedItem?.equipment_type),
    status:this._graphql.selectedItem?.status,
    category:JSON.parse(this._graphql.selectedItem?.category),
    manufacturer_id:JSON.parse(this._graphql.selectedItem?.manufacturer),
    model_id:JSON.parse(this._graphql.selectedItem?.model),
    property:JSON.parse(this._graphql.selectedItem?.property),
    specific_location:this.duplicateEquipmentNameForm.specificLocation,
    // gps_location:JSON.parse(this._graphql.selectedItem?.gps_location),
    serial_number:this.duplicateEquipmentNameForm.serialNumber,
    //asset_tag:this._graphql.selectedItem?.asset_tag,
    warranty:this._graphql.selectedItem?.warranty,
    warranty_term:{name:this._graphql.selectedItem?.warranty_term},
    expected_useful_life:this._graphql.selectedItem?.expected_useful_life,
    expected_useful_life_term:{name:this._graphql.selectedItem?.expected_useful_life_term},
    condition:this._graphql.selectedItem?.condition,
    purchase_date:this._graphql.selectedItem?.purchase_date,
    in_service_date:this._graphql.selectedItem?.in_service_date,
    purchase_amount:this._graphql.selectedItem?.purchase_amount,
    replacement_cost:this._graphql.selectedItem?.replacement_cost,
    safety_notes:this._graphql.selectedItem?.safety_notes,
    age:this._graphql.selectedItem?.age,
    waranteeExpiDate:this._graphql.selectedItem?.waranteeExpiDate,
    estimatedLife:this._graphql.selectedItem?.estimatedLife,
    vendor:JSON.parse(this._graphql.selectedItem?.vendor),
   }
   if(this.duplicateEquipmentNameForm.equipmentName){
    this._graphql.createEquipment(duplicateEquipment,true,this._graphql.selectedItem.id,true);
   }
   this.closeOnSave = true;
  }

}
