import { Companies } from './companies';


export interface createInspectScheduler {
  name: string
  move_in_checklist_id: string
  move_in_inspection_job: number

  move_out_checklist_id: string
  move_out_inspection_job: number
  properties: string
  tenants: string[]
}

export interface InspectionSchedulerInput {
  name?: string
  move_in_checklist_id?: string
  move_in_inspection_job?: number

  move_out_checklist_id?: string
  move_out_inspection_job?: number
  properties?: any
  tenants?: string[]

  status?: string
}

export interface LeaseInspectionInput {
  move_in_checklist_id?: string
  move_in_inspection_job?: number
  move_out_checklist_id?: string
  move_out_inspection_job?: number
  move_in_date?: string
  move_out_date?: string
}

export interface InspectionScheduler {
  id: number
  name: string
  move_in_checklist_id: string
  move_in_inspection_job: number

  move_out_checklist_id: string
  move_out_inspection_job: number
  properties: any[]
  tenants: Companies[]

  status: string

  created_at: string
  created_by: string

  updated_at: string
  update_by: string
}

export interface InspectionSchedulerOptions {
  id?: number
  sort?: string
  order?: string
  search?: string
  page?: number
  take?: number
}

export interface InspectionSchedulerOutput {
  items: InspectionScheduler[]
  total: number
  size: number
  page: number
}

export interface IInspectionJobData {
  id: number
  job_id?: number
  job: IInspectionJobItem
}

export interface IInspectionJobItem {
  id: number
  category: { id: string, name: string }
  property: { id: string, name: string }
  unit: { id: string, name: string }
  status: string
  target_date: string
  stage: { id: string, name: string }
}

export enum INSPECTION_TYPE {
  MOVE_IN = 'Move In',
  MOVE_OUT = 'Move Out',
}

export enum JOB_STATUS {
  COMPLETED = 'Completed',
  APPROVED = 'Approved',
  TO_DO = 'To Do',
  INVITED = 'Invited',
}

export enum INSPECTION_STATUS {
  PENDING = 'Pending',
  INCOMPLETE = 'Incomplete',
  COMPLETE = 'Complete'
}

export interface IInspectionJobTableItem {
  id: number
  inspection_type: string
  property: string
  unit: string
  status: string
  target_date: string
}

export interface PrioritizationOptions {
  tenant?: string
  units?: string[]
  property?: string
}


export interface PrioritizationOutput {
  suggested: InspectionScheduler[]
  all: InspectionScheduler[]
}


export interface PropertiesForInspectionSchedulerOutput {
  items: InspectionSchedulerProperties[]
  total: number
  size: number
  page: number
}

export interface LeaseInspectionByLeaseId {
  inspection_scheduler_name: string
  lease_id: number
  inspection_scheduler_id: number
  move_in_checklist_id: InspectionSchedulerChecklist
  move_in_inspection_job: number
  move_out_checklist_id: InspectionSchedulerChecklist
  move_out_inspection_job: number
  move_in_date: string
  move_out_date: string
  create_at: string
  update_at: string
}

export interface InspectionSchedulerProperties {
  id: string
  name: string
  units: InspectionSchedulerUnit[]
  all_units: boolean
}

export interface InspectionSchedulerUnit {
  id: string
  name: string
}

export interface InspectionSchedulerChecklist
{
  id: string
  name: string
}

export interface PropertiesForInspectionSchedulerInput {
  id: number
  options: PropertiesForInspectionSchedulerOptions
}

export interface PropertiesForInspectionSchedulerOptions {
  take: number
  page: number
}
