import {Component, Inject, OnInit, Output} from '@angular/core';
import { ChecklistService } from 'src/app/Modules/_fyxt_modules/checklist/servcie/checklist.service';
import { CreateChecklistPopupComponent } from '../create-checklist-popup/create-checklist-popup.component';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import {environment} from "../../../../../../environments/environment";
import {BaseService} from "../../../../../services/base.service";
import { TabChecklistDetailsComponent } from '../../_tab_components/tab-checklist-details/tab-checklist-details.component';
import { TabChecklistItemsComponent } from '../../_tab_components/tab-checklist-items/tab-checklist-items.component';
import { TabComponent } from '../../../_reusable_ui/_components/tab/tab.component';
import { ListComponent } from '../../../_reusable_ui/_components/list/list.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { NgIf, NgFor, NgClass, NgSwitch, NgSwitchCase } from '@angular/common';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';

@Component({
    selector: 'app-checklist-template',
    templateUrl: './checklist-template.component.html',
    styleUrls: ['./checklist-template.component.scss'],
    standalone: true,
    imports: [SearchInputComponent, NgIf, ButtonComponent, MatIconModule, LoaderSmallComponent, NgFor, ListComponent, NgClass, TabComponent, NgSwitch, NgSwitchCase, TabChecklistItemsComponent, TabChecklistDetailsComponent, MatDialogModule]
})
export class ChecklistTemplateComponent implements OnInit {

  panelOpenState = false;

  selectedActiveTab: any = {};
   selectedTab: any;
   checklistItemsCount:any=0;
   selectedJobDetails: any;
   checklistTemplatesData: any;
   calledFrom: string = '';

   detectItemsCount(count:any){
    // this.checklistItemsCount = 0;
    // for (let i = 0; i < count.length; i++) {
    //   this.checklistItemsCount += count[i].item.length;
    // }
    // this.tabList[0].count=this.checklistItemsCount
     this.tabList[0].count = count;
  }

  tabList = [
    { name: 'Items', value: 'Items', count: this.checklistItemsCount, active: true, disable: false },
    { name: 'Details', value: 'Details', count: '', active: false, disable: false },
  ]

  verticalMenus:any[]= [
    {
      label:'Name',
      menus:[]
    }
  ];
  isLoader:boolean = true;
  sort:string = 'asc';
  searchInput:any='';
  selectedEquipments: any;
  selectedPlannerDetails: any;
  assignedFromEquipmentInWorkOrder = false;
  assignedFromEquipmentInServicePlanner = false;
  currentUserInfo = localStorage.getItem('userobj')
    ? JSON.parse(localStorage.getItem('userobj'))
    : {};
  can_add_checklisttemplate:Boolean;
  asPerNewDesign = false;

  constructor(public checklist:ChecklistService,public _dialogService: DialogService, private dialogRef:MatDialogRef<ChecklistTemplateComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, public _baseService: BaseService,) {
    this.selectedJobDetails = this.data?.data?.selectedJobDetails;
    this.selectedEquipments = this.data?.data?.selectedEquipments;
    this.assignedFromEquipmentInWorkOrder = this.data?.data?.assignedFromEquipment;
    this.assignedFromEquipmentInServicePlanner = this.data?.data?.assignedFromServicePlanner;
    this.selectedPlannerDetails = this.data?.data?.selectedPlannerDetails;
    this.calledFrom = this.data?.calledFrom;
    this.asPerNewDesign = this.data?.data?.asPerNewDesign ? this.data?.data?.asPerNewDesign : false;
  }

  ngOnInit(): void {
    this.assignChecklistFromEquipmentChecklist();
    if(this.currentUserInfo?.current_Role=='Manager' || this.currentUserInfo?.current_Role=='Owner'){
      if(this.currentUserInfo.permissions.includes('add_checklisttemplate')){
        this.can_add_checklisttemplate = true;
      }
      else{
        this.can_add_checklisttemplate = false;
      }
    } else if(this.currentUserInfo?.current_Role=='Engineer'){
      this.can_add_checklisttemplate = false;
    } else{
      this.can_add_checklisttemplate = true;
    }
  }

  searchChecklist(search?:any): void {
    this.localSearchChecklist(search);
  }

  sortChecklist(sort?:any): void {
    this.localSortChecklist(sort);
  }

  localSearchChecklist(search?:any): void {
    this.searchInput = search;
    this.verticalMenus[0].menus = this.checklistTemplatesData.filter(checklist => {
      return checklist.name.toLowerCase().includes(this.searchInput.toLowerCase());
    });
    if (this.verticalMenus[0].menus.length > 0) {
      const firstUnassignedChecklist = this.verticalMenus[0].menus?.find((checklist: any) => {
        return checklist.assigned == false;
      });
      this.onChangeTab(this.verticalMenus[0].menus[0]);
    }
  }

  localSortChecklist(sort?:any): void {
    this.verticalMenus[0].menus = this.verticalMenus[0].menus.reverse();
    const firstUnassignedChecklist = this.verticalMenus[0].menus?.find((checklist: any) => {
      return checklist.assigned == false;
    });
    this.onChangeTab(this.verticalMenus[0].menus[0]);
  }

  // loadChecklist(sort?:any,search?:any){
  //   this.sort = sort ? sort : 'asc';
  //   this.searchInput = search;
  //   this.isLoader = true;
  //   this.checklist.getAssignChecklist(this.sort ,this.searchInput).subscribe({
  //     next:(res:any)=>{
  //       this.verticalMenus[0].menus=res[0].results;
  //       this.onChangeTab(this.verticalMenus[0].menus[0]);
  //       this.isLoader = false;
  //     }
  //   });
  // }

  assignChecklistFromEquipmentChecklist(sort?:any, search?:any): void {
    this.sort = sort ? sort : 'asc';
    this.searchInput = search;
    this.isLoader = true;
    let requestURL = '';
    if (this.assignedFromEquipmentInWorkOrder) {
      requestURL = environment.baseURL + `jobs/${this.selectedJobDetails.id}/job-assigned-checklist/?equipment=` + JSON.stringify(this.selectedEquipments);
    } else if (this.assignedFromEquipmentInServicePlanner) {
      requestURL = environment.baseURL + `planner/${this.selectedPlannerDetails.id}/planner-assigned-checklist/?equipment=` + JSON.stringify(this.selectedEquipments);
    } else {
      requestURL = environment.LocalDev_URL_V2 + `checklist-template/all/`;
    }
    this._baseService.doGET(requestURL).subscribe({
      next: (res: any) => {
         this.verticalMenus[0].menus = res;
         this.checklistTemplatesData = res;
         const firstUnassignedChecklist = this.verticalMenus[0].menus?.find((checklist: any) => {
           return checklist?.assigned == false;
         });
         const isAllAssignedChecklist = this.verticalMenus[0].menus?.every((checklist: any) => {
           return checklist?.assigned == true;
         });
         if (this.assignedFromEquipmentInServicePlanner) {
          this.data.title = `Checklist Templates (${res?.length})`
         }
         if (this.assignedFromEquipmentInWorkOrder){
          this.data.title = `Checklist Templates (${res?.length})`
         }
         this.onChangeTab(this.verticalMenus[0].menus[0]);
         this.isLoader = false;
      },
      error: () => {
        this.isLoader = false;
      },
      complete: () => {}
    })
  }

  /**
 * On Change Left Menu
 * @param selectedActiveTab
 */
  onChangeTab(selectedActiveTab: any) {
    // if (this.assignedFromEquipmentInWorkOrder && selectedActiveTab?.assigned) {
    //   return null
    // } else {
    //   this.selectedActiveTab = selectedActiveTab;
    // }
    this.selectedActiveTab = selectedActiveTab;
  }

   /**
   * On Select Tab
   * @param selectedTabValue
   */
   onSelectTab(selectedTabValue: any) {
    this.selectedTab = selectedTabValue;
  }

  createChecklist(action:string,e?:any) {
    if((action === 'add') || (action === 'edit' && e.name !== undefined)){
      let popupData = {
        title: `${e ? ('Edit - '+e.name):'Create New Checklist'}`,
        component: CreateChecklistPopupComponent,
        containerClass: 'modal-lg',
        calledFrom: this.calledFrom,
        data:{id:e?.id, AssignChecklist:true}
      };

      this._dialogService.openModal(popupData).subscribe(result => {
        if (result && result[0]?.id) {
          this.assignChecklistFromEquipmentChecklist();
          if (this.data?.data?.isFromWorkOrderChecklist) {
            this.selectedActiveTab = result[0];
            this.closePopup('Apply');
          }
        }
      });
    }

  }

  closePopup(e?:string){
     if (!this.selectedActiveTab?.assigned) {
       if(e === 'Apply'){
         this.dialogRef.close(this.selectedActiveTab)
       } else{
         this.dialogRef.close();
       }
     }
  }

   /** Track by Index  **/
   trackByIndex(index: number, item: any): number {
    return index;
  }
}
