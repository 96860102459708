import { HttpClient } from '@angular/common/http';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, Input, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { BaseService } from 'src/app/services/base.service';
import { ConfigService } from 'src/app/services/config.service';
import { TicketFlowsService } from 'src/app/services/ticket-flows.service';
import { UtilityService } from 'src/app/services/utility.service';
import { JobsService } from 'src/app/services/v2/jobs.service';
import { environment } from 'src/environments/environment';
import { TenantPortalJobsService } from "../../../../../Modules/tenant-portal/services/tenant-portal-jobs.service";
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { MatDividerModule } from '@angular/material/divider';
import { CheckboxComponent } from '../../../_reusable_ui/_controls/checkbox/checkbox.component';
import { DropdownDateSelectComponent } from '../../../_reusable_ui/_controls/dropdown-select/dropdown-date-select/dropdown-date-select.component';
import { MatRadioModule } from '@angular/material/radio';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule, NgClass, NgFor, NgIf, SlicePipe } from '@angular/common';
@Component({
    selector: 'app-reschedule',
    templateUrl: './reschedule.component.html',
    styleUrls: ['./reschedule.component.scss'],
    standalone: true,
    imports: [CommonModule,FormsModule, ReactiveFormsModule, NgClass, NgSelectModule, NgxIntlTelInputModule, NgFor, NgIf, TooltipModule, MatRadioModule, DropdownDateSelectComponent, CheckboxComponent, MatDividerModule, MatDialogModule, ButtonComponent, SlicePipe],
    schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class RescheduleComponent implements OnInit {
  reschedule: FormGroup;
  scheduleData: any;
  isEdit: boolean;
  minDate: any = new Date();
  maxDate: Date;
  all_Dropdown_Users: any[] = [];
  selectedVendorsItems: any[] = [];
  dateErrorText: boolean = false;
  formSubmitted: boolean = false;
  isLoader: boolean = false;
  constructor(private fb: FormBuilder, private http: HttpClient,
    public _utilService: UtilityService,
    public _baseService: BaseService,
    public _Config: ConfigService,
    public _jobService: JobsService,
    public _dialogService: DialogService,
    public _tenantPortalJobsService: TenantPortalJobsService,
    @Inject(MAT_DIALOG_DATA) public matDialogData: any,
    public _ticketService: TicketFlowsService,
    private dialogRef: MatDialogRef<RescheduleComponent>) {
    this.isEdit = this.matDialogData.data.isEdit
    this.scheduleData = this.matDialogData.data.scheduleData
  }

  ngOnInit(): void {
    this.rescheduleForm();
    if (this.isEdit) {
      let from_Time_obj = this._utilService.DropdownTimes_Obj.find(
        (obj) => obj.value == this.scheduleData.time_start
      );
      let to_Time_obj = this._utilService.DropdownTimes_Obj.find(
        (obj) => obj.value == this.scheduleData.time_end
      );


      let from_time = from_Time_obj ? from_Time_obj.value : '08:00';
      let to_time = to_Time_obj ? to_Time_obj.value : '18:00';

      this.reschedule.patchValue({
        vendor: this.scheduleData?.user_id,
        type: this.scheduleData.type,
        date: this.scheduleData.date,
        _from: from_time,
        to: to_time,
        unit_entry_permission: this.scheduleData.unit_entry_permission
      });
    }
    this.getScheduleDropdown_Users();
  }

  rescheduleForm(): void {
    this.reschedule = this.fb.group({
      vendor: [null, Validators.required],
      type: ['Site Visit', Validators.required],
      date: [null, Validators.required],
      _from: ['08:00', Validators.required],
      to: ['18:00', Validators.required],
      unit_entry_permission: [false]
    });
  }

  onSubmit() {
    this.formSubmitted = true;
    if (this.reschedule.valid) {
      this.dialogRef.close(true);
      this.isLoader = true;
      let payload: any = {
        job: this._jobService.selectedJobInfo.id,
        date: this.formatDateToYYYY_MM_DD(this.reschedule.value.date),
        _from: this.reschedule.value._from,
        to: this.reschedule.value.to,
        unit_entry_permission: this.reschedule.value.unit_entry_permission,
      };
      if (this.isEdit) {
        payload.rescheduled = true;
        payload.type = this.reschedule.value.type;

        let requestURL = `${environment.baseURL}job/schedules/${this.scheduleData.id}/`;
        this._baseService.doPatch(requestURL, payload).subscribe(
          (response:any) => {
            this.isLoader = false;
            this._utilService.showSuccess('', 'Job Rescheduled Successfully.');
            this._tenantPortalJobsService.scheduleTabRefresh.next(true);
            this._jobService.triggerPusherEventForJobTabs('Schedule', response?.id);
          },
          (error) => {
            this._utilService.showErrorMessage(error);
            this.isLoader = false;
          }
        );
      } else {
        if (this.scheduleData.vendor.type === 'Engineer') {
          payload.engineer = this.scheduleData.vendor.id;
        } else {
          payload.vendor = this.scheduleData.vendor.id;
        }
        payload.type = this.reschedule.value.type;
        let requestURL = `${environment.baseURL}job/schedules/`;
        this._baseService.doPOST(requestURL, payload).subscribe(
          (response:any) => {
            this._dialogService.dialog.closeAll();
            this.isLoader = false;
            this._utilService.showSuccess('', 'Job Scheduled Successfully.');
            this._tenantPortalJobsService.scheduleTabRefresh.next(true);
            this._jobService.triggerPusherEventForJobTabs('Schedule', response?.id);
          },
          (error) => {
            this._utilService.showErrorMessage(error);
            this.isLoader = false;
          },
        );
      }
    }
  }

  // Date conversion method
  formatDateToYYYY_MM_DD(date: Date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  /**
   * Time Change Event
   * @param event
   * @returns
   */
  timeChanged(event: any) {
    const StartTime = this._utilService.getTimeVal(this.reschedule.value._from);
    const EndTime = this._utilService.getTimeVal(this.reschedule.value.to);

    // Set 8 AM as the minimum time
    const minimumStartTime = new Date();
    minimumStartTime.setHours(8, 0, 0, 0);

    // If the selected start time is before 8 AM or the end time is before or equal to the start time
    if (StartTime < minimumStartTime || EndTime <= StartTime) {
      this._utilService.showError(
        '',
        'Start time should be greater than or equal to 8 AM, and End time should be greater than Start time'
      );
      return;
    }
  }


  /**
    * Get All Job Vendors
    */
  getScheduleDropdown_Users() {
    let requestURL = environment.baseURL + this._Config.getAllJobScheduleUsers;
    requestURL = requestURL.replace(
      '{ID}',
      this._jobService.selectedJobInfo.id
    );

    this._baseService.doGET(requestURL).subscribe(
      (response: any) => {
        this.all_Dropdown_Users = response || [];

        this.all_Dropdown_Users.forEach((element) => {
          element.company = element.name;
        });
      },
      (error) => {}
    );
  }


  /**
   * On change Vendor Selection Event
   * @param type
   * @param selectedVendor
   */
  onChangeVendorSchedule(type: any, selectedVendor: any): any {
    this.scheduleData.form_type = type;

    this.scheduleData.vendor = selectedVendor;

    this.scheduleData.date = this._utilService.getNowUTC();
  }

   /** Track by Index  **/
   trackByIndex(index: number, item: any): number {
    return index;
  }

}



