import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { ConfigService } from 'src/app/services/config.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChecklistService {
  checkListTemplateCount: any;

  constructor(private httpClient: HttpClient, public _Config: ConfigService,) { }

  loadChecklistTable(search?: any, page?: number, column?: any, direction?: any, limit?: number, offset?: number, filter?: any) {
    let requestURL = `${environment.LocalDev_URL_V2}` + `checklist-template/?&limit=${limit}&offset=${offset}`;
    if (search !== '') requestURL += `&search=${search}`
    if (page !== 1 && page !== undefined) {
      requestURL += `&page=${page}`
    }
    if (column !== null && column !== undefined) {
      requestURL += `&ordering=${direction === 'asc' ? `${column}` : `-${column}`}`
    }
    if (filter !== null && filter !== undefined && filter !== "[]") {
      requestURL += `&query=${filter}`
    }
    let listAllPlanner = this.httpClient.get(requestURL);
    return forkJoin([listAllPlanner]);
  }

  createChecklist(payLoad: any) {
    let requestURL = `${environment.LocalDev_URL_V2}` + 'checklist-template/';
    let listAllPlanner = this.httpClient.post(requestURL, payLoad);
    return forkJoin([listAllPlanner]);
  }

  getCheckList(id: any) {
    let requestURL = `${environment.LocalDev_URL_V2}` + `checklist-template/${id}/`;
    let listAllPlanner = this.httpClient.get(requestURL);
    return forkJoin([listAllPlanner]);
  }

  updateChecklist(id: any, payload: any) {
    let requestURL = `${environment.LocalDev_URL_V2}` + `checklist-template/${id}/`;
    let listAllPlanner = this.httpClient.patch(requestURL, payload);
    return forkJoin([listAllPlanner]);
  }

  getAssignChecklist(sort?: any, search?: any, limit = 200) {
    let requestURL = `${environment.LocalDev_URL_V2}` + `checklist-template/?ordering=${sort === 'asc' ? 'name' : '-name'}&page=1&limit=${limit}`;
    if (search) requestURL = requestURL + `&search=${search}`;
    let listAllPlanner = this.httpClient.get(requestURL);
    return forkJoin([listAllPlanner]);
  }


  getFilter() {
    let requestURL = `${environment.LocalDev_URL_V2}` + `checklist-template/user-dropdown/`;
    let listAllPlanner = this.httpClient.get(requestURL);
    return forkJoin([listAllPlanner]);
  }

  deleteChecklist(id: any) {
    let requestURL = `${environment.LocalDev_URL_V2}` + `checklist-template/${id}/`;
    let listAllPlanner = this.httpClient.delete(requestURL);
    return forkJoin([listAllPlanner]);
  }

  /**
   * To Get Http Request for REST API
   * @param URL
   * @param Options
   */
  doGET(URL?: string, Options?: any): Observable<any> {
    return this.httpClient.get(URL, Options).pipe(take(1));
  }

  /**
  * To Post Http Request for REST API
  * @param URL   // The Endpoint URL
  * @param Body    //Post Input Body
  * @param Options   //Headers or Other Optional Params
  */
  doPOST(URL?: string, Body?: any, Options?: any) {
    return this.httpClient.post(URL, Body, Options);
  }

  appliesToDropdownValues() {
    let requestURL = `${environment.LocalDev_URL_V2}` + `checklist-template/checklist-applies-to-dropdown/`;
    this.doGET(requestURL).subscribe((response: any) => {
      let appliesToDropdownValues = [];
      response.map(element => {
        if (element.name === 'Categories')
          appliesToDropdownValues.push({ id: element.name, name: 'All Jobs of select Categories' });
        if (element.name === 'Every Job')
          appliesToDropdownValues.push({ id: element.name, name: 'Every Job' });
        if (element.name === 'Service Types')
          appliesToDropdownValues.push({ id: element.name, name: 'All Jobs of select Service Types' });
      });
      return appliesToDropdownValues;
    }, (error) => { });
  }

  updateBulkActions(postBody: any): Observable<any> {
    let requestURL = environment.LocalDev_URL_V2 + 'checklist-template/bulk-actions/';
    return this.httpClient.post(requestURL, postBody);
  }
}
