// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 1100px) {
  .left-column-container {
    flex: 0 0 100%;
    max-width: 100% !important;
  }
  .center-column-container {
    flex: 0 0 100%;
    max-width: 100% !important;
  }
}
.search-box-container {
  margin-left: 12px;
}

::ng-deep .custom-align-detail .col_middle_container {
  width: 100% !important;
  margin: 0 !important;
  padding-left: 0px !important;
}

.h-59vh {
  height: 59vh !important;
}

::ng-deep .custom-height-table .fyxt_new_custom_table {
  max-height: calc(100vh - 382px) !important;
}

.location-header-title {
  color: #192c49;
  font-weight: 700;
  margin-top: 18px;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_views/_tab_components/tab-associtecompanies/tab-associtecompanies.component.scss"],"names":[],"mappings":"AAAA;EACE;IACE,cAAA;IACA,0BAAA;EACF;EACA;IACE,cAAA;IACA,0BAAA;EACF;AACF;AAEA;EACE,iBAAA;AAAF;;AAEA;EACE,sBAAA;EACA,oBAAA;EACA,4BAAA;AACF;;AACA;EACE,uBAAA;AAEF;;AAAA;EACE,0CAAA;AAGF;;AADA;EACE,cAAA;EACA,gBAAA;EACA,gBAAA;AAIF","sourcesContent":["@media screen and (max-width: 1100px) {\n  .left-column-container {\n    flex: 0 0 100%;\n    max-width: 100% !important;\n  }\n  .center-column-container {\n    flex: 0 0 100%;\n    max-width: 100% !important;\n  }\n}\n\n.search-box-container {\n  margin-left: 12px;\n}\n::ng-deep .custom-align-detail .col_middle_container {\n  width: 100% !important;\n  margin: 0 !important;\n  padding-left: 0px !important;\n}\n.h-59vh {\n  height: 59vh !important;\n}\n::ng-deep .custom-height-table .fyxt_new_custom_table {\n  max-height: calc(100vh - 382px) !important;\n}\n.location-header-title {\n  color: #192c49;\n  font-weight: 700;\n  margin-top: 18px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
