import { Injectable, Inject, Renderer2, RendererFactory2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { ConfigService } from '../../services/config.service';
import { environment } from 'src/environments/environment';
import { UiService } from 'src/app/services/ui/ui.service';
import { UtilityService } from 'src/app/services/utility.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { Config2Service } from '../v2/config2.service';
import { take } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ChecklistService {

  currentUserInfo: any = localStorage.getItem('userobj') ? JSON.parse(localStorage.getItem('userobj')) : {};
  isChecklist_Live_Recieved = new Subject();
  socketTrigger_Checklist: WebSocketSubject<unknown>;
  processList: any = [
    { id: 'Multiple Choice', name: 'Multiple Choice' },
    { id: 'Checkbox', name: 'Checkbox' },
    { id: 'Status', name: 'Status' },
    { id: 'Pass/Fail/Flag', name: 'Pass/Fail/Flag' },
    { id: 'Textfield', name: 'Text Field' }
  ];

  serviceTypesList: any = [
    { id: 'Preventive', name: 'Preventive' },
    { id: 'Inspection', name: 'Inspection' },
    { id: 'Recurring', name: 'Recurring' },
    { id: 'Other', name: 'Other' }
  ];


  progressStatus: any = [
    { id: 'not_Started', name: 'Not Started' },
    { id: 'complete', name: 'Complete' },
    { id: 'not_Complete', name: 'Not Complete' },
    { id: 'not_Applicable', name: 'Not Applicable' }
  ];

  statusLists: any = [
    { id: 'Open', name: 'Open' },
    { id: 'In_Progress', name: 'In Progress' },
    { id: 'On_Hold', name: 'On Hold' },
    { id: 'Complete', name: 'Complete' }
  ];

  resultStatusLists: any = [
    { id: 'Pass', name: 'Pass' },
    { id: 'Flag', name: 'Flag' },
    { id: 'Fail', name: 'Fail' }
  ];

  allChecklists: any[] = [];
  allChecklistPagination: any = {
    next: '',
    counts: 0
  };

  allPropertyChecklistPagination: any = {
    next: '',
    counts: 0
  };

  searchParams: any = {
    Category_ID: null,
    Type: null,
    searchKeyword: '',
    isSort: false,
    sortBy: 'desc',
    sortColumn: 'name'
  };


  allChecklistProperties: any[] = [];
  allChecklistDropdown: any[] = [];
  allChecklistDropdownByProperty: any[] = [];
  selectedChecklistInfo: any = {};
  selectedChecklistInfoPreview: any = {};
  allPropertyChecklists: any = [];

  isCreatePropertyChecklist: boolean = false;
  selectedProperty: any = {};
  selectedPropertyChecklist: any = {};

  selectedChecklistImages: any[] = [];
  selectedImagePreview: any = {};
  selectedImageIndex = 0;
  atleastOneSignatureSigned: boolean;
  isSignaturesMissing: boolean;
  isOpenSignaturePopup = false;
  allSignatures: any;
  public isScrollToBottom = new BehaviorSubject<boolean>(false);
  public openGetSignaturePopup = new BehaviorSubject<any>(null);
  public updateGetSignatures = new BehaviorSubject<any>(null);
  public updateSignatures = new BehaviorSubject<any>(false);

  constructor(public _http: HttpClient,
    public _Config: ConfigService,
    public _uiService: UiService,
    public _utilService: UtilityService,
    public _Config2: Config2Service,
  ) {
  }



  /************** Base HTTP Request Methods *****************/

  /**
   * To Get Http Request for REST API
   * @param URL
   * @param Options
   */
  doGET(URL?: string, Options?: any): Observable<any> {
    return this._http.get(URL, Options).pipe(take(1));
  }

  /**
   * To Post Http Request for REST API
   * @param URL   // The Endpoint URL
   * @param Body    //Post Input Body
   * @param Options   //Headers or Other Optional Params
   */
  doPOST(URL?: string, Body?: any, Options?: any) {
    return this._http.post(URL, Body, Options);
  }

  /**
   * To Update Http Request for REST API
   * @param URL
   * @param Body
   * @param Options
   */
  doPUT(URL?: string, Body?: any, Options?: any) {
    return this._http.put(URL, Body, Options);
  }


  /**
   * To Delete Http Request for REST API
   * @param URL
   * @param Options
   */
  doDelete(URL?: string, Options?: any) {
    return this._http.delete(URL, Options);
  }

  /**
   * If Patch Request for Http Restfull API
   * @param URL
   * @param Body
   * @param Options
   */
  doPatch(URL?: string, Body?: any, Options?: any) {
    return this._http.patch(URL, Body, Options);
  }




  /**
* Get All Checklists
*/
  getAllChecklists(isLoader?: any, isPagination?: any, isSearch?: any) {

    this._Config.isLoader = isLoader;

    let requestURL = "";

    if (isPagination) {

      if (this.allChecklistPagination.next) {
        requestURL = this.allChecklistPagination.next;
      }
      else {
        requestURL = environment.baseURL + this._Config.getAllChecklists;

      }

    }
    else {

      if (isSearch) {
        requestURL = environment.baseURL + this._Config.searchChecklists;
        requestURL = requestURL.replace('{searchKeyword}', this.searchParams.searchKeyword ? this.searchParams.searchKeyword : '');
        requestURL = requestURL.replace('{category}', this.searchParams.Category_ID ? this.searchParams.Category_ID : '');
        requestURL = requestURL.replace('{type}', this.searchParams.Type ? this.searchParams.Type : '');
      }
      else
        requestURL = environment.baseURL + this._Config.getAllChecklists;


      if (this.searchParams.isSort) {

        let searchParams = "";

        if (requestURL.includes('?')) {

          if (this.searchParams.sortBy == "desc")
            searchParams = '&ordering=-' + this.searchParams.sortColumn;
          else
            searchParams = '&ordering=' + this.searchParams.sortColumn;

        }
        else {
          if (this.searchParams.sortBy == "desc")
            searchParams = '?ordering=-' + this.searchParams.sortColumn;
          else
            searchParams = '?ordering=' + this.searchParams.sortColumn;
        }
        requestURL = requestURL + searchParams;

      }

    }

    this._Config.isLoader = true;

    // requestURL = environment.baseURL + this._Config.getAllChecklists;

    this.doGET(requestURL).subscribe((response: any) => {

      this._Config.isLoader = false;

      this.allChecklistPagination.counts = response.count;;


      this.allChecklistPagination.next = response.next ? response.next : '';

      let ListItems = response.results || [];

      if (isPagination) {

        this.allChecklists.push(...ListItems);

      }
      else {

        this.allChecklists = ListItems;

      }




    },
      (error) => {

        this._Config.isLoader = false;

      });



  }


  /**
* Get All Checklists by property
*/
  getAllChecklistsByProperty(propertyId: any, isLoader?: any, isPagination?: any, isSearch?: any) {

    this._Config.isLoader = isLoader;

    let requestURL = "";

    if (isPagination) {

      if (this.allPropertyChecklistPagination.next) {
        requestURL = this.allChecklistPagination.next;
      }
      else {
        requestURL = environment.baseURL + this._Config.getPropertyChecklist;
      }

    }
    else {

      if (isSearch) {
        requestURL = environment.baseURL + this._Config.searchPropertyChecklists;
        requestURL = requestURL.replace('{searchKeyword}', this.searchParams.searchKeyword ? this.searchParams.searchKeyword : '');
        requestURL = requestURL.replace('{category}', this.searchParams.Category_ID ? this.searchParams.Category_ID : '');
        requestURL = requestURL.replace('{type}', this.searchParams.Type ? this.searchParams.Type : '');
      }
      else
        requestURL = environment.baseURL + this._Config.getPropertyChecklist;
    }


    requestURL = requestURL.replace('{ID}', propertyId);

    if (this.searchParams.isSort) {

      let searchParams = "";



      if (requestURL.includes('?')) {

        if (this.searchParams.sortBy == "desc")
          searchParams = '&ordering=-' + this.searchParams.sortColumn;
        else
          searchParams = '&ordering=' + this.searchParams.sortColumn;

      }
      else {
        if (this.searchParams.sortBy == "desc")
          searchParams = '?ordering=-' + this.searchParams.sortColumn;
        else
          searchParams = '?ordering=' + this.searchParams.sortColumn;
      }

      requestURL = requestURL + searchParams;

    }


    this._Config.isLoader = true;

    // requestURL = environment.baseURL + this._Config.getAllChecklists;

    this.doGET(requestURL).subscribe((response: any) => {

      this._Config.isLoader = false;

      this.allPropertyChecklistPagination.counts = response.count;;


      this.allPropertyChecklistPagination.next = response.next ? response.next : '';

      let ListItems = response.results || [];

      if (isPagination) {

        this.allPropertyChecklists.push(...ListItems);

      }
      else {

        this.allPropertyChecklists = ListItems;

      }




    },
      (error) => {

        this._Config.isLoader = false;

      });



  }


  deleteChecklist(index: any, deleteItem: any, isProperty?: any) {
    this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.deleteChecklist;
    requestURL = requestURL.replace('{ID}', deleteItem.id);

    this.doDelete(requestURL).subscribe((response: any) => {

      this._Config.isLoader = false;

      if (isProperty)
        this.allPropertyChecklists.splice(index, 1);
      else
        this.allChecklists.splice(index, 1);

      this._utilService.showSuccess('', 'Checklist Deleted Successfully.');


    },
      (error) => {

        this._Config.isLoader = false;
        this._utilService.showErrorMessage(error);

      });


  }




  /**
* Get All Checklist properties
*/
  getAllChecklistProperties() {

    // this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.getAllChecklistProperties;

    this.doGET(requestURL).subscribe((response: any) => {

      this._Config.isLoader = false;

      this.allChecklistProperties = response || [];

      if (this.allChecklistProperties.length > 0) {
        this.allChecklistProperties.forEach(element => {
          element.isChecked = false;
        });
      }



    },
      (error) => {
        this._Config.isLoader = false;

      });

  }



  /**
* Get All Checklist Dropdown
*/
  listAllChecklistDropdown() {

    // this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.getAllChecklistDropdown;
    // requestURL = requestURL + '?source=pm'

    this.doGET(requestURL).subscribe((response: any) => {

      this._Config.isLoader = false;

      this.allChecklistDropdown = response || [];

      if (this.allChecklistDropdown.length > 0) {
        this.allChecklistDropdown.forEach(element => {
          element.search_label = element.name + ' | ' + element.type + ' | ' + element.description + ' | ' + element.category;
        });
      }


    },
      (error) => {
        this._Config.isLoader = false;

      });

  }



  /**
* Get All Checklist Dropdown by Property
*/
listAllChecklistDropdownByProperty_New() {

  // this._Config.isLoader = true;

  let requestURL = environment.LocalDev_URL_V2 + 'checklist-template/all/';

  this.doGET(requestURL).subscribe((response: any) => {

    this._Config.isLoader = false;

    // let propertyArray = [];
    // if (Object.keys(response.response).length > 0)
    //   propertyArray = response.results

    this.allChecklistDropdownByProperty = response || [];

    // if (this.allChecklistDropdownByProperty.length > 0) {
    //   this.allChecklistDropdownByProperty.forEach(checklistItem => {
    //     checklistItem.checklists.forEach(element => {
    //       element.search_label = element.name + ' | ' + element.type + ' | ' + element.description + ' | ' + element.category;
    //     });
    //   });
    // }


  },
    (error) => {
      this._Config.isLoader = false;

    });

}


  /**
* Get All Checklist Dropdown by Property
*/
  listAllChecklistDropdownByProperty(propertyID: any) {

    // this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.getAllChecklistDropdownByProperty;
    requestURL = requestURL.replace('{ID}', propertyID);

    this.doGET(requestURL).subscribe((response: any) => {

      this._Config.isLoader = false;

      let propertyArray = [];
      if (Object.keys(response).length > 0)
        propertyArray = [response];

      this.allChecklistDropdownByProperty = propertyArray || [];

      if (this.allChecklistDropdownByProperty.length > 0) {
        this.allChecklistDropdownByProperty.forEach(checklistItem => {
          checklistItem.checklists.forEach(element => {
            element.search_label = element.name + ' | ' + element.type + ' | ' + element.description + ' | ' + element.category;
          });
        });
      }


    },
      (error) => {
        this._Config.isLoader = false;

      });

  }



  /**
* Get Checklist Info by Job
*/
  getChecklistInfoByJobID(jobID: any, isLoader?: boolean) {

    let requestURL = environment.baseURL + this._Config.getChecklistInfoByJobID;
    requestURL = requestURL.replace('{ID}', jobID);

    this._Config.isLoader = isLoader ? isLoader : false;

    this.selectedChecklistInfo = {};


    this.doGET(requestURL).subscribe((response: any) => {

      this._Config.isLoader = false;

      let selectedChecklistInfo: any = response || {};

      if (selectedChecklistInfo.tasks.length > 0) {

        selectedChecklistInfo.tasks.forEach(element => {

          element.images = [];
          element.documents = [];


          if (element.values) {
            if (element.type == 'Status' || element.type == 'Pass/Fail/Flag' || element.type == 'Multiple Choice') {

              if (element.values.length > 0)
                element.values = element.values[0];
              else
                element.values = null;

            }
          }
          else
            element.values = null;


          if (element.files.length > 0) {
            element.files.forEach(file => {

              if (file.mime_type.includes("image"))
                element.images.push(file);
              else
                element.documents.push(file);


            });
          }

          element.displayValue = [];

          if (element.options.length > 0) {
            element.options.forEach(item => {

              let setObj = {
                id: item,
                value: item
              }

              element.displayValue.push(setObj);

            });
          }



        });
      }



      this.selectedChecklistInfo = selectedChecklistInfo;



    },
      (error) => {
        this._Config.isLoader = false;

      });

  }

  /**
* Get Checklist Info by Job
*/
  getChecklistInfoByID(checklistID: any, isLoader?: boolean) {

    let requestURL = environment.baseURL + this._Config.ChecklistInfoByChecklistID;
    requestURL = requestURL.replace('{ID}', checklistID);

    this._Config.isLoader = isLoader ? isLoader : false;

    this.doGET(requestURL).subscribe((response: any) => {

      this._Config.isLoader = false;


      let selectedChecklistInfo: any = response || {};

      if (selectedChecklistInfo.tasks.length > 0) {

        selectedChecklistInfo.tasks.forEach(element => {

          element.images = [];
          element.documents = [];


          if (element.values) {
            if (element.type == 'Status' || element.type == 'Pass/Fail/Flag') {

              if (element.values.length > 0)
                element.values = element.values[0];
              else
                element.values = null;

            }
          }
          else
            element.values = null;


          if (element.files.length > 0) {
            element.files.forEach(file => {


              if (file.mime_type.includes("image"))
                element.images.push(file);
              else
                element.documents.push(file);


            });
          }

          element.displayValue = [];

          if (element.options.length > 0) {
            element.options.forEach(item => {

              let setObj = {
                id: item,
                value: item
              }

              element.displayValue.push(setObj);

            });
          }



        });
      }


      this.selectedChecklistInfoPreview = selectedChecklistInfo;




    },
      (error) => {
        this._Config.isLoader = false;

      });

  }




  /**
* Get Checklist Info by Job
*/
  getPlannerChecklistInfoByID(checklistID: any, isLoader?: boolean) {

    let requestURL = environment.baseURL + this._Config.plannerChecklistInfoByChecklistID;
    requestURL = requestURL.replace('{ID}', checklistID);

    this._Config.isLoader = isLoader ? isLoader : false;

    this.doGET(requestURL).subscribe((response: any) => {

      this._Config.isLoader = false;

      let selectedChecklistInfo: any = response || {};

      if (selectedChecklistInfo.tasks.length > 0) {

        selectedChecklistInfo.tasks.forEach(element => {

          element.images = [];
          element.documents = [];


          if (element.values) {
            if (element.type == 'Status' || element.type == 'Pass/Fail/Flag') {

              if (element.values.length > 0)
                element.values = element.values[0];
              else
                element.values = null;

            }
          }
          else
            element.values = null;


          if (element.files.length > 0) {
            element.files.forEach(file => {



              if (file.mime_type) {
                if (file.mime_type.includes("image"))
                  element.images.push(file);
                else
                  element.documents.push(file);
              }
              else
                element.documents.push(file);


            });
          }

          element.displayValue = [];

          if (element.options.length > 0) {
            element.options.forEach(item => {

              let setObj = {
                id: item,
                value: item
              }

              element.displayValue.push(setObj);

            });
          }



        });
      }


      this.selectedChecklistInfoPreview = selectedChecklistInfo;




    },
      (error) => {
        this._Config.isLoader = false;

      });

  }


  /**
   * Delete Checklist Tasks
   * @param TaskID
   */
  deleteChecklistTasks(TaskID: string) {

    let requestURL = environment.baseURL + this._Config.delete_ChecklistTask;
    requestURL = requestURL.replace('{ID}', TaskID);

    this.doDelete(requestURL).subscribe((result: any) => {

      this._utilService.showSuccess('', 'Checklist Deleted Successfully.');

    },
      (error) => {
        this._utilService.showErrorMessage(error);
      }
    );
  }
  public TriggerSocket_for_Checklist(jobID?: any) {
    let requestURL =
      environment.socketURL + this._Config2.addChecklist_Triggers;
    requestURL = requestURL.replace('{AccountID}',this.currentUserInfo.account);
    requestURL = requestURL.replace('{UserID}', this.currentUserInfo.id);

    let payload: any = {};

    if (jobID) payload.job_id = jobID;

    const subject = webSocket(requestURL);
    subject.subscribe(
      (msg) => {},
      (err) => {},
      () => {}
    );
    subject.next(payload);

  }


  public connectSocket_for_Checklist(jobId:any) {
    return;

    if (jobId) {
      // if (!this.socketTrigger_Checklist) {
      let requestURL = environment.socketURL + this._Config2.getChecklist_Live;
      requestURL = requestURL.replace('{AccountID}',this.currentUserInfo.account);
      requestURL = requestURL.replace('{UserID}', this.currentUserInfo.id);
      requestURL = requestURL.replace('{JobID}', jobId);

      this.socketTrigger_Checklist = webSocket({
        url: requestURL,
        openObserver: {
          next: (e) => {
            // this._jobService.socketOpened = true;
          }
        },
        closeObserver: {
          next: (e) => {
            // this._jobService.socketOpened = false;
          }
        }
       });

      this.socketTrigger_Checklist.subscribe(
        (response: any) => {
           if(response.tasks){
            let selectedChecklistInfo =[];
            selectedChecklistInfo.push(...response.tasks);
            this.selectedChecklistInfo.tasks =selectedChecklistInfo;
            this.selectedChecklistInfo.tasks.forEach(element => {

              element.images = [];
              element.documents = [];


              if (element.values) {
                if (element.type == 'Status' || element.type == 'Pass/Fail/Flag' || element.type == 'Multiple Choice') {

                  if (element.values.length > 0)
                    element.values = element.values[0];
                  else
                    element.values = null;

                }
              }
              else
                element.values = null;


              if (element.files.length > 0) {
                element.files.forEach(file => {

                  if (file.mime_type.includes("image"))
                    element.images.push(file);
                  else
                    element.documents.push(file);


                });
              }

              element.displayValue = [];

              if (element.options.length > 0) {
                element.options.forEach(item => {

                  let setObj = {
                    id: item,
                    value: item
                  }

                  element.displayValue.push(setObj);

                });
              }



            });
           }
           this.isChecklist_Live_Recieved.next(true);

        },
        (err) => {},
        () => {}
      );
      // }
    }


  }


  public disconnectSocket_for_Checklist(jobId:any) {
  return;
    if (jobId) {
      let requestURL = environment.socketURL + this._Config2.disconnectChecklist_Live;
      requestURL = requestURL.replace('{AccountID}',this.currentUserInfo.account);
      requestURL = requestURL.replace('{UserID}', this.currentUserInfo.id);
      requestURL = requestURL.replace('{JobID}', jobId);

      let payload: any = {
        action: 'disconnect'
      };

      const subject = webSocket(requestURL);
      subject.subscribe(
        (msg) => {},
        (err) => {},
        () => {}
      );
      subject.next(payload);

      //  this.socketTrigger_Checklist.unsubscribe();
    }

  }

  fetchScrollToBottom(): Observable<boolean> {
    return this.isScrollToBottom.asObservable();
  }

  fetchOpenGetSignaturePopup(): Observable<any> {
    return this.openGetSignaturePopup.asObservable();
  }
  updateGetSignaturesOb(): Observable<any> {
    return this.updateGetSignatures.asObservable();
  }
  updateSignaturesOb(): Observable<any> {
    return this.updateSignatures.asObservable();
  }

}
