// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-box-container {
  width: 267px;
}

.container_border {
  border: 1px solid #e6e8f0;
  border-radius: 4px;
  padding: 8px;
}

.title {
  color: #192c49;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 400;
}

.notes_preview .ql-editor {
  padding: 0;
  min-height: -moz-fit-content;
  min-height: fit-content;
}

.notes_preview p {
  margin-bottom: 0 !important;
  word-break: break-all;
}

.ql-indent-1 {
  padding-left: 10px;
}

.ql-indent-2 {
  padding-left: 20px;
}

.ql-indent-3 {
  padding-left: 30px;
}

.ql-indent-4 {
  padding-left: 40px;
}

.ql-indent-5 {
  padding-left: 50px;
}

.ql-indent-6 {
  padding-left: 60px;
}

.ql-indent-7 {
  padding-left: 70px;
}

.ql-indent-8 {
  padding-left: 80px;
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-align-left {
  text-align: left;
}

:host ::ng-deep .search-box-container {
  width: 180px !important;
}

.border-right-header {
  border-right: 1px solid #C3C6D1;
}

.active-snippet {
  color: #d63c00 !important;
  font-weight: 500 !important;
  background: rgba(214, 60, 0, 0.0784313725);
}

.padding-12 {
  padding: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_views/_tab_components/tab-inbox-snippets/tab-inbox-snippets.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,yBAAA;EACA,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,UAAA;EACA,4BAAA;EAAA,uBAAA;AACF;;AAEA;EACE,2BAAA;EACA,qBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAGA;EACE,uBAAA;AAAF;;AAGA;EACE,+BAAA;AAAF;;AAGA;EACE,yBAAA;EACA,2BAAA;EACA,0CAAA;AAAF;;AAGA;EACE,aAAA;AAAF","sourcesContent":[".search-box-container {\n  width: 267px;\n}\n\n.container_border {\n  border: 1px solid #e6e8f0;\n  border-radius: 4px;\n  padding: 8px;\n}\n\n.title {\n  color: #192c49;\n  margin-bottom: 8px;\n  font-size: 12px;\n  font-weight: 400;\n}\n\n.notes_preview .ql-editor {\n  padding: 0;\n  min-height: fit-content;\n}\n\n.notes_preview p {\n  margin-bottom: 0 !important;\n  word-break: break-all;\n}\n\n.ql-indent-1 {\n  padding-left: 10px;\n}\n\n.ql-indent-2 {\n  padding-left: 20px;\n}\n\n.ql-indent-3 {\n  padding-left: 30px;\n}\n\n.ql-indent-4 {\n  padding-left: 40px;\n}\n\n.ql-indent-5 {\n  padding-left: 50px;\n}\n\n.ql-indent-6 {\n  padding-left: 60px;\n}\n\n.ql-indent-7 {\n  padding-left: 70px;\n}\n\n.ql-indent-8 {\n  padding-left: 80px;\n}\n\n.ql-align-center {\n  text-align: center;\n}\n\n.ql-align-right {\n  text-align: right;\n}\n\n.ql-align-left {\n  text-align: left;\n}\n\n\n:host ::ng-deep .search-box-container {\n  width: 180px !important;\n}\n\n.border-right-header {\n  border-right: 1px solid #C3C6D1;\n}\n\n.active-snippet {\n  color: #d63c00 !important;\n  font-weight: 500 !important;\n  background: rgba(214, 60, 0, 0.0784313725);\n}\n\n.padding-12 {\n  padding: 12px;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
