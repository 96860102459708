import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  Activity,
  AssociateJob,
  AssociateJobPayload,
  EmailConnection,
  EmailMessage,
  GetAllAttachments,
  Ignore_Activity_Keys,
  SMTP_Payload,
  Template,
  Template_API,
  Thread
} from "../inbox.types";
import {environment} from '../../../../../environments/environment';
import {BehaviorSubject, Observable} from "rxjs";
import { addMinutes, format, parseISO } from 'date-fns';
import * as _ from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class InboxService_New {

  public mailSentFromComposerPopup = new BehaviorSubject<boolean>(false);
  public openAssociatedJobSlide = new BehaviorSubject<boolean>(false);
  public associatedJobFromBulkActionComplete = new BehaviorSubject<boolean>(false);
  public associatedContactsSlideFromInbox = new BehaviorSubject<boolean>(false);
  public appendDraftDetailsFromComposerPopup = new BehaviorSubject<boolean>(false);
  public hideOpenFirstPanel = new BehaviorSubject<string>('');
  public realTimeScrollToBottom = new BehaviorSubject<boolean>(false);
  public updateAssociatedContacts = new BehaviorSubject<boolean>(false);
  public openWorkOrderFromInbox = new BehaviorSubject<boolean>(false);
  public associatedJobsAddedFromInbox = new BehaviorSubject<boolean>(false);
  public assignManager = new BehaviorSubject<any>(false);
  public changeLabel = new BehaviorSubject<string>('');
  public bulkAssignManager = new BehaviorSubject<boolean>(false);
  public resetInboxFilter = new BehaviorSubject<boolean>(false);
  public changeReplyThread = new BehaviorSubject<any>('');



  public activeThread: Thread;
  public subDomain;
  activeMailboxDetails: EmailConnection;
  selectedAssociatedContacts: any;
  mailboxId;
  public emptyThread = {
    id: null,
    sequence: null,
    messageId: null,
    subject: "",
    recentMessage: null,
    status: "OPEN",
    folder: "INBOX",
    createdById: null,
    assignedToId: null,
    mailboxId: null,
    lastMessageId: null,
    lastMessageAt: null,
    label: "New Conversation",
    isRead: false,
    hasAttachments: false,
    createdAt: null,
    deletedAt: null,
    updatedAt: null,
    lastMessage: null,
    messages: [],
    threadJobIds: [],
    newThread: true
  };
  isFromBulkActionAssociatedJobOpen = false;
  selectedThreadsForBulkActionAssociatedJobs = [];
  draftDetailsComposer = {
    to: [],
    cc: [],
    subject: '',
    files: [],
    content: ''
  };
  associatedContactsList: any[] = [];
  allAssociatedJobs: AssociateJob[] = [];
  inboxBubbleCount: number = 0;

  //Draft modified user saved by details
  draftModifiedUserDetails: any;
  draftModifiedTime: any;

  showNewInboxInProd: boolean = false;
  showNewInbox = ['postal', 'friedmanv3', 'livedemo', 'internaltest', 'thevillagescpm', 'primewest', 'grim', 'localhost', 'krishna', 'rob', 'ryan', 'web', 'fcpt', 'friedmanstaging','ryandemo'];

  constructor(private httpClient: HttpClient) {
    let url = window.location.origin;
    this.subDomain = this.extractDomain(url).split('.')[0];
    this.subDomain == 'localhost' ? this.subDomain = 'web' : this.subDomain;
    this.showNewInboxInProd = this.showNewInbox.includes(this.subDomain);
  }

  extractDomain(url: string): any {
    let domain;
    if (url.indexOf('://') > -1) {
      domain = url.split('/')[2];
    } else {
      domain = url.split('/')[0];
    }
    domain = domain.split(':')[0];
    return domain;
  }

  fetchMailSentFromComposerPopup(): Observable<boolean> {
    return this.mailSentFromComposerPopup.asObservable();
  }

  fetchAssociatedContactsSlideFromInbox(): Observable<boolean> {
    return this.associatedContactsSlideFromInbox.asObservable();
  }

  fetchOpenAssociatedJobSlide(): Observable<boolean> {
    return this.openAssociatedJobSlide.asObservable();
  }

  fetchAssociatedJobFromBulkActionComplete(): Observable<boolean> {
    return this.associatedJobFromBulkActionComplete.asObservable();
  }

  fetchAppendDraftDetailsFromComposerPopup(): Observable<boolean> {
    return this.appendDraftDetailsFromComposerPopup.asObservable();
  }

  fetchHideOpenFirstPanel(): Observable<string> {
    return this.hideOpenFirstPanel.asObservable();
  }

  fetchRealTimeScrollToBottom(): Observable<boolean> {
    return this.realTimeScrollToBottom.asObservable();
  }

  fetchUpdateAssociatedContacts(): Observable<boolean> {
    return this.updateAssociatedContacts.asObservable();
  }

  fetchOpenWorkOrderFromInbox(): Observable<boolean> {
    return this.openWorkOrderFromInbox.asObservable();
  }

  fetchAssociatedJobAddedFromInbox(): Observable<boolean> {
    return this.associatedJobsAddedFromInbox.asObservable();
  }

  fetchResetInboxFilter(): Observable<boolean> {
    return this.resetInboxFilter.asObservable();
  }

  fetchAssignManager(): Observable<any> {
    return this.assignManager.asObservable();
  }

  fetchBulkAssignManager(): Observable<boolean> {
    return this.bulkAssignManager.asObservable();
  }

  fetchChangeLabel(): Observable<string> {
    return this.changeLabel.asObservable();
  }

  fetchChangeReplyThread(): Observable<any> {
    return this.changeReplyThread.asObservable();
  }

  public getInboxBubbleCount() {
    return this.httpClient.get(`${environment.inbox.http}mailboxes/unread/count`, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public addNewMailAccount(payload: any) {
    return this.httpClient.post(`${environment.inbox.http}mailboxes/oauth-url`, payload, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public syncMailbox() {
    return this.httpClient.get(`${environment.inbox.http}mailboxes/sync`, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public getAllMailCounts(payload) {
    return this.httpClient.post(`${environment.inbox.http}threads/count`, payload, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public getAllMailBox() {
    return this.httpClient.get(`${environment.inbox.http}mailboxes`, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public getInboxSignature(): Observable<Template> {
    return this.httpClient.get<Template>(`${environment.inbox.http}mailboxes/${this.mailboxId}`, {"headers": {"x-tenant-id": this.subDomain }})
  }

  public updateMailBox(mailBoxId: string, payload: any) {
    return this.httpClient.put(`${environment.inbox.http}mailboxes/${mailBoxId}`, payload, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public composeThreads(payload) {
    return this.httpClient.post(`${environment.inbox.http}threads`, payload, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public getAllThreads(requestURL: string, payload: any) {
    return this.httpClient.post(requestURL, payload, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public updateThreadStatus(payload) {
    return this.httpClient.put(`${environment.inbox.http}threads/status/update`, payload, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public updateFolderStatus(payload) {
    return this.httpClient.put(`${environment.inbox.http}threads/folder/update`, payload, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public markAsUnread(payload) {
    return this.httpClient.put(`${environment.inbox.http}threads/unread`, payload, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public getSingleThreads(threadId: string) {
    return this.httpClient.get(`${environment.inbox.http}threads/${threadId}`, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public sendMail(payload: EmailMessage, threadId: string) {
    return this.httpClient.post(`${environment.inbox.http}messages/threads/${threadId}/reply`, payload, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public bulkReplySendMail(payload: EmailMessage) {
    return this.httpClient.post(`${environment.inbox.http}messages/threads/bulk-reply`, payload, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public bulkReplyAssociatedJobs(payload) {
    return this.httpClient.post(`${environment.inbox.http}thread-jobs/bulk`, payload, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public sendNewComposeMail(payload: EmailMessage) {
    return this.httpClient.post(`${environment.inbox.http}messages`, payload, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public draftMail(payload: EmailMessage, threadId: any) {
    return this.httpClient.post(`${environment.inbox.http}messages/threads/${threadId}/draft`, payload, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public getContactList(searchInput: string) {
    let getActiveColumn = [
      'first_name',
      'last_name',
      'type',
      'phone',
      'primary_company',
      'last_activity_at',
      'email',
    ];
    let selectViewTableRows =
      environment.fastAPI_Actual_URL +
      '/contacts/?columns=' +
      JSON.stringify(getActiveColumn) + '&page=1&size=25';
    if (searchInput) {
      selectViewTableRows += '&search=' + encodeURIComponent(searchInput);
    }
    return this.httpClient.get(selectViewTableRows);
  }

  public updateThreads(payload: any, threadId: string) {
    return this.httpClient.put(`${environment.inbox.http}threads/${threadId}`, payload, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public getAllUsers(requestURL: string) {
    return this.httpClient.get(requestURL);
  }

  public updateAssignUser(payload: any, threadId: string) {
    return this.httpClient.put(`${environment.inbox.http}threads/${threadId}/assign-user`, payload, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public getAssociateJobs(url: any) {
    return this.httpClient.get(url);
  }

  public getPropertyFilterAssociateJobs(url: any) {
    return this.httpClient.get(url);
  }

  public getTenantFilterAssociateJobs(url: any) {
    return this.httpClient.get(url);
  }

  public getCategoryFilterAssociateJobs(url: any) {
    return this.httpClient.get(url);
  }

  public getLocationFilterAssociateJobs(url: any) {
    return this.httpClient.get(url);
  }

  public AssociateJobs(payload: AssociateJobPayload) {
    return this.httpClient.post(`${environment.inbox.http}thread-jobs`, payload, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public getAllAttachments(payload: GetAllAttachments) {
    return this.httpClient.post(`${environment.files.http}files/entities`, payload, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public getActivityChanges(data: any[], assignToList: any): Activity[] {
    const result = [];
    data.forEach((activity,index) => {
      const user = {
        first_name: activity?.user?.first_name ? activity?.user?.first_name : 'Unknown',
        last_name: activity?.user?.last_name ? activity?.user?.last_name : '',
        photo: activity?.user?.photo ? activity?.user?.photo : 'assets/images/users/avatar-1.jpg'
      }
      if(activity.action === "REPLY_MESSAGE" && activity.entityType ==  "MESSAGE"){
        const contentChange = _.find(activity.changes, 'content');
        const newValue = _.get(contentChange, 'content.new', 'Not Found');
        const obj = {
          user: user,
          createdAt:activity.createdAt,
          change: {
            showArrow:false,
            name: 'Replied to a Message',
            old: '',
            new: this.htmlToString(newValue)
          }
        }
        result.push(obj);
      }
      if(activity.action === "NEW_MESSAGE" && activity.entityType ==  "MESSAGE"){
        const contentChange = _.find(activity.changes, 'content');
        const newValue = _.get(contentChange, 'content.new', 'Not Found');
        const obj = {
          user: user,
          createdAt:activity.createdAt,
          change: {
            showArrow:false,
            name: 'sent a Message',
            old: '',
            new: this.htmlToString(newValue)
          }
        }
        result.push(obj);
      }
      if(activity.action === "BULK_REPLY_MESSAGE" && activity.entityType ==  "MESSAGE"){
        const contentChange = _.find(activity.changes, 'content');
        const newValue = _.get(contentChange, 'content.new', 'Not Found');
        const obj = {
          user: user,
          createdAt:activity.createdAt,
          change: {
            showArrow:false,
            name: 'sent a Bulk Reply',
            old: '',
            new: this.htmlToString(newValue)
          }
        }
        result.push(obj);
      }
      if(activity.action == 'CREATE' && activity.entityType == 'THREAD_JOB'){
        const jobId = _.find(activity.changes, 'jobId');
        const newValue = _.get(jobId, 'jobId.new', 'Not Found');
        const obj = {
          user: user,
          createdAt:activity.createdAt,
          change: {
            showArrow:false,
            name: 'Associated a job',
            old: '',
            new: `JOB : ${newValue}`
          }
        }
        result.push(obj);
      }
      if(activity.action == 'DELETE' && activity.entityType == 'THREAD_JOB'){
        const jobId = _.find(activity.changes, 'jobId');
        const newValue = _.get(jobId, 'jobId.old', 'Not Found');
        const obj = {
          user: user,
          createdAt:activity.createdAt,
          change: {
            showArrow:false,
            name: 'Removed a job',
            old: '',
            new: `JOB : ${newValue}`
          }
        }
        result.push(obj);
      }
      if(activity.action == 'UPDATE' && activity.entityType == 'THREAD'){
        const jobId = _.find(activity.changes, 'assignedToId');
        const newValue = _.get(jobId, 'jobId.old', 'Not Found');
        if (jobId) {
          const assignedUserDetails = assignToList.userList.filter(item => item.id == jobId.assignedToId.new);
          let assignedUser = {};
          if(assignedUserDetails.length > 0) {
            assignedUser = {
              first_name: assignedUserDetails[0].full_name,
              last_name: '',
              photo: assignedUserDetails[0].photo
            }
          }
           const obj = {
            user: assignedUser,
            createdAt:activity.createdAt,
            change: {
              showArrow:false,
              name: 'was assigned as manager',
              old: '',
              // new: `JOB : ${newValue}`
            }
          }
          result.push(obj);
        }
      }
      if(activity.action == 'UPDATE' && activity.entityType == 'THREAD'){
        const status = _.find(activity.changes, 'status');
        const oldStatusValue = _.get(status, 'status.old', 'Not Found');
        const newStatusValue = _.get(status, 'status.new', 'Not Found');
        if (status) {
          const statusObj = {
            user: user,
            createdAt:activity.createdAt,
            change: {
              showArrow:true,
              name: 'Changed the status',
              old: '',
              new: `${oldStatusValue} -> ${newStatusValue}`
            }
          }
          result.push(statusObj);
        }
      }
      if(activity.action == 'UPDATE' && activity.entityType == 'THREAD'){
        const isRead = _.find(activity.changes, 'isRead');
        const oldIsReadValue = _.get(isRead, 'isRead.old', 'Not Found');
        const newIsReadValue = _.get(isRead, 'isRead.new', 'Not Found');
        if (isRead) {
          const isReadObj = {
            user: user,
            createdAt:activity.createdAt,
            change: {
              showArrow:false,
              name: newIsReadValue ? 'Read the Thread' : 'Mark as unread the thread',
              old: '',
              new: ''
            }
          }
          result.push(isReadObj);
        }
      }
    });
    return result;
  }

  private htmlToString(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  }

  public getAssociatedJobsForThread(jobIds: string[]) {
    let payload = {
      "jobIds": jobIds.filter(value => value !== undefined && value !== null && value !== "undefined" && value !== "null")

    }
    return this.httpClient.post(`${environment.coreApiURL}/jobs/by-ids`, payload, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public getSenderContactCard(threadId: string) {
    return this.httpClient.get(`${environment.inbox.http}threads/${threadId}/contacts/sender`, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public getAssociatedContacts(threadId: string) {
    return this.httpClient.get(`${environment.inbox.http}threads/${threadId}/contacts/associates`, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public getContacts(payload) {
    return this.httpClient.post(`${environment.fastAPI_Actual_URL}/companies/job-contacts/`, payload, {"headers": {"x-tenant-id": this.subDomain }})
  }

  public getContactsWithCompanyDetails(payload) {
    return this.httpClient.post(`${environment.baseURL}mail/contacts/tenant-details/`, payload)
  }

  public getUsersAssociatedContacts(payload: { ids :string[]} ) {
    return this.httpClient.post(`${environment.coreApiURL}/core/users/by-ids`, payload, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public getAllAssociatedMailsInJob(requestURL: string) {
     return this.httpClient.get(requestURL, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public pingEyeball(threadId:string,body:any){
    return this.httpClient.post(`${environment.inbox.http}threads/${threadId}/ping`, body ,{"headers": {"x-tenant-id": this.subDomain }});
  }

  public getThreadView(mailboxId){
    return this.httpClient.get(`${environment.inbox.http}threads/mailboxes/${mailboxId}/viewers`, {"headers": {"x-tenant-id": this.subDomain }});
  }

  public jobSeenUpdate(jobID: string) {
    return this.httpClient.patch(environment.LocalDev_URL_V2 + 'user-job-status/job-seen-update/', { "job_id": jobID })
  }

  public getUserSignature() {
    return this.httpClient.get(`${environment.inbox.http}mailboxes/${this.mailboxId}/personal/signature`, { "headers": { "x-tenant-id": this.subDomain } });
  }

  public updateUserSignature(payload : {content : string}) {
    return this.httpClient.post(`${environment.inbox.http}mailboxes/${this.mailboxId}/personal/signature`, payload, { "headers": { "x-tenant-id": this.subDomain } } );
  }

  public addSMTPAccount(payload: SMTP_Payload){
    return this.httpClient.post(`${environment.inbox.http}mailboxes/smtp/create`, payload, { "headers": { "x-tenant-id": this.subDomain } } );
  }

  public getSenderInformation(payload: {sender_email:string}){
    return this.httpClient.post(`${environment.baseURL}mails/tenant-properties-units/`, payload );
  }

  public getPropertyDetailsForCompany(payload: any, companyId: string){
    return this.httpClient.post(`${environment.baseURL}companies/${companyId}/company-details/`, payload);
  }

  public getUnknownContacts(q: any){
    return this.httpClient.get(`${environment.inbox.http}threads/unknown-contacts?q=${q}`,  { "headers": { "x-tenant-id": this.subDomain } });
  }

  mergeContactArrays(firstArray, secondArray) {
    return _.map(firstArray, item => {
        const match = _.find(secondArray, { first_name: item.first_name, last_name: item.last_name });
        if (match) {
            return { ...item, companies: (item.companies || []).concat(match.companies || []) };
        }
        return item;
    }).concat(_.filter(secondArray, secondItem =>
        !_.find(firstArray, { first_name: secondItem.first_name, last_name: secondItem.last_name })
    ));
  }

  get userId() {
    const currentUserInfo: any = localStorage.getItem('userobj')
    ? JSON.parse(localStorage.getItem('userobj'))
    : {}

    return currentUserInfo.id;
  }

  removeDuplicateCompanies(data) {
    return data.map(c => {
        const uniqueCompanies = [];
        const companyIds = new Set();

        c.companies?.forEach(company => {
            if (!companyIds.has(company.id)) {
                companyIds.add(company.id);
                uniqueCompanies.push(company);
            }
        });

        return {
            ...c,
            companies: uniqueCompanies
        };
    });
}

  createReplyThread(thread :Thread) {

    if (thread?.messages?.length == 0) {
      return '';
    }

    const toEmail = thread.messages[thread?.messages.length - 1].participants.find((p) => p.addressedType == 'FROM');

    return `
            <div dir="ltr" class="gmail_attr">On ${format(addMinutes(parseISO(thread?.messages[thread?.messages.length - 1]?.messageAt), 330), "MMM dd, yyyy")} &nbsp; &lt;<a style="color: #0000fff2;" href="mailto:${toEmail?.email}">${toEmail?.email}</a>&gt; wrote:<br></div>
            <blockquote style="margin:0px 0px 0px 0.8ex; border-left:1px solid rgb(204,204,204); padding-left:1ex">
                <div> ${thread?.messages[thread?.messages.length - 1]?.content} </div>
            </blockquote>`
  }

  createReplyThreadForParticularMsg(thread :Thread, index) {

    if (thread?.messages?.length == 0) {
      return '';
    }

    const toEmail = thread.messages[index].participants.find((p) => p.addressedType == 'FROM');

    return `
            <div dir="ltr" class="gmail_attr">On ${format(addMinutes(parseISO(thread?.messages[index]?.messageAt), 330), "MMM dd, yyyy")} &nbsp; &lt;<a style="color: #0000fff2;" href="mailto:${toEmail?.email}">${toEmail?.email}</a>&gt; wrote:<br></div>
            <blockquote style="margin:0px 0px 0px 0.8ex; border-left:1px solid rgb(204,204,204); padding-left:1ex">
                <div> ${thread?.messages[index]?.content} </div>
            </blockquote>`

  }

}
