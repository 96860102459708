import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { CoreRoutingModule } from './core-routing.module';

import { OnboardComponent } from './components/onboard/onboard.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { AccountListingComponent } from './components/account-listing/account-listing.component';
import { SingleauthComponent } from './components/singleauth/singleauth.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { OTPVerificationComponent } from './components/otp-verification/otp-verification.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { JobpreviewComponent } from './components/jobpreview/jobpreview.component';
import { NewJobRequestsComponent } from './components/new-job-requests/new-job-requests.component';
import { LoginComponent } from './components/login/login.component';
import { DefaultServiceRequestComponent } from './components/new-job-requests/default-service-request/default-service-request.component';
import { VillagesServiceRequestComponent } from './components/new-job-requests/villages-service-request/villages-service-request.component';
import { DraftServiceRequestComponent } from './components/new-job-requests/draft-service-request/draft-service-request.component';
import { GoodwillServiceRequestComponent } from './components/new-job-requests/goodwill-service-request/goodwill-service-request.component';
import { ServiceRequestWithCompanyComponent } from './components/new-job-requests/service-request-with-company/service-request-with-company.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        CoreRoutingModule,
        NgSelectModule,
        OnboardComponent,
        ForgetPasswordComponent,
        AccountListingComponent,
        SingleauthComponent,
        ResetPasswordComponent,
        OTPVerificationComponent,
        JobpreviewComponent,
        NewJobRequestsComponent,
        LoginComponent,
        DefaultServiceRequestComponent,
        VillagesServiceRequestComponent,
        DraftServiceRequestComponent,
        GoodwillServiceRequestComponent,
        ServiceRequestWithCompanyComponent
    ]
})
export class CoreModule { }
