import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CoiService } from '../../../../../Modules/_fyxt_modules/coi/_services/coi.service';
import { ColumnSortType, TableColumnTypes } from '../../../enums/table/table-column-types.enum';
import {
  IGetCOIsListResponse,
  IGetVendorCOIAvailability,
  IGetVendorCOIsListParams,
  IVendorCOIInput
} from '../../../models/coi/coi.interface';
import { TableService } from '../../../../_services/table/table.service';
import { TableComponent } from '../../../_reusable_ui/_components/table/table.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'fyxt-vendor-coi',
    templateUrl: './vendor-coi.component.html',
    styleUrls: ['./vendor-coi.component.scss'],
    standalone: true,
    imports: [NgIf, LoaderSmallComponent, TableComponent]
})
export class VendorCOIComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<null> = new Subject();
  hasCOI: boolean;
  alerts: IGetVendorCOIAvailability;
  emptyData: boolean;
  dataSource: any;
  isLoader: boolean;
  moduleName = 'vendor_coi';

  selectedViewTableRowsTotal: number;
  selectedViewTableRowsPageSize: number;
  initPageSize = 25;
  currentPage: number = 0;
  pageIndexAPI: number = 1;
  coiSort: {
    active: '',
    direction: ''
  };
  selectViewTableHeaders: any[] = [];
  selectedViewAllTableColumns: any = [
    {
      value: 'title',
      sort: ColumnSortType.none,
      name: 'Name',
      type: TableColumnTypes.text,
      className: 'table-header',
      active: true,
    },
    {
      value: 'properties',
      sort: ColumnSortType.none,
      name: 'Properties',
      type: TableColumnTypes.usersListWithCountDropdown,
      className: 'table-header',
      active: true,
      sortDisabled: true,
    },
    {
      value: 'effective_date',
      sort: ColumnSortType.none,
      name: 'Effective Date',
      type: TableColumnTypes.date,
      className: 'table-header',
      active: true,
    },
    {
      value: 'expiration_date',
      sort: ColumnSortType.none,
      name: 'Expiration Date',
      type: TableColumnTypes.date,
      className: 'table-header',
      active: true,
    },
    {
      value: 'company_type',
      sort: ColumnSortType.none,
      name: 'Company Type',
      type: TableColumnTypes.text,
      className: 'table-header',
      active: true,
    },
    {
      value: 'coverages_count',
      sort: ColumnSortType.none,
      name: 'Number of Coverages',
      type: TableColumnTypes.textWithColor,
      className: 'table-header',
      active: true,
      sortDisabled: true,
    },
  ];
  selectedViewTableRows: any[] = [];

  input: IVendorCOIInput;

  constructor(
    private readonly tableService: TableService,
    private readonly _router: Router,
    private readonly _coiService: CoiService,
    @Inject(MAT_DIALOG_DATA) public data: { data: IVendorCOIInput }
  ) {
    this.input = { ...data?.data };
    this.hasCOI = this.input.has_coi || false;
    this.alerts = {
      has_for_category: false,
      has_for_property: false,
    }
    this.coiSort = {
      active: '',
      direction: ''
    };
    this.emptyData = false;
    this.selectedViewTableRowsPageSize = this.initPageSize;
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private init() {
    this.selectViewTableHeaders = this.selectedViewAllTableColumns.filter((header: any) => header.active) || [];

    const { vendor_id, property_id, category_id } = this.input;
    this.getList();

    if (!this.hasCOI) {
      this._coiService.getVendorCOIsAvailability(vendor_id, property_id, category_id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(res => {
          this.alerts = res;
        })
    }
  }


  getList() {
    const pagination: IGetVendorCOIsListParams = {
      page: this.pageIndexAPI,
      take: this.selectedViewTableRowsPageSize,
      vendor_id: this.input?.vendor_id,
      property_id: this.input?.property_id,
      category_id: this.input?.category_id,
      has_coi: this.hasCOI,
    }

    if (this.coiSort?.active) {
      switch (this.coiSort.active) {
        case 'company_name':
          pagination.sort = 'company_id';
          break;

        default:
          pagination.sort = this.coiSort.active;
          break;
      }
      pagination.order = this.coiSort.direction.toUpperCase();
    }

    this.isLoader = true;
    this._coiService.getVendorCOIsList(pagination)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: IGetCOIsListResponse) => {
          const {coi: COIsList = [], page, size, total} = data

          this.emptyData = !total && page === 1;

          if (COIsList.length > 0) {
            COIsList.map((response: any) => {
              response.properties = response.properties.map((i) => i.name).join(', ')
              return response.checked = false;
            });
          }
          this.selectedViewTableRows = COIsList || [];
          this.selectedViewTableRowsTotal = total;
          this.pageIndexAPI = page;
          this.selectedViewTableRowsPageSize = size;

          // Append sort direction to the header if we have sort query we get from the backend
          if (this.coiSort?.active) {
            this.selectViewTableHeaders.map((header: any) => {
              if (header.value == this.coiSort.active) {
                return header.sort = this.coiSort.direction;
              } else {
                return header.sort = ColumnSortType.none;
              }
            });
          }

          this.dataSource = this.tableService.setTableData(this.moduleName, this.selectViewTableHeaders, this.selectedViewTableRows, this.selectedViewTableRowsTotal, this.currentPage, this.selectedViewTableRowsPageSize);

          this.isLoader = false;
        },
        error => {
          this.isLoader = false;
        });
  }

  onPageChange(event: any) {
    this.currentPage = event.pageIndex;
    this.pageIndexAPI = event.pageIndex + 1;
    this.selectedViewTableRowsPageSize = event.pageSize;
    this.getList();
  }

  onSortChange(sortState: any) {
    this.coiSort = sortState;
    this.selectedViewTableRowsPageSize = this.initPageSize;
    this.pageIndexAPI = 1;
    this.currentPage = 0;
    this.getList();
  }

  onRowClick(event: any) {
    const url = this._router.serializeUrl(this._router.createUrlTree(['/pm/coi/view/' + event.id]));
    window.open(url, '_blank');
  }

}
