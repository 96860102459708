export enum EnumMailboxProvider {
    GMAIL = 'GMAIL',
    OUTLOOK = 'OUTLOOK',
}

export enum EnumMailboxConnectionStatus {
    CONNECTING = 'CONNECTING',
    CONNECTED = 'CONNECTED',
    FAILED = 'FAILED',
}

export enum EnumThreadStatus {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    CLOSEOUT = 'CLOSEOUT',
    RESOLVED = 'RESOLVED',
}

export enum EnumTemplatesCategory {
    SNIPPET = 'SNIPPET',
    FEEDBACK_TEMPLATE = 'FEEDBACK_TEMPLATE',
    AUTO_RESPONSE = 'AUTO_RESPONSE',
}

export type Template = {
    id?: string;
    title: string;
    content: string;
    mailboxId: string;
    subject?: string
    category: EnumTemplatesCategory;
    createdById?: string
    deletedAt?: string
};

export type Template_API = {
    data: Template | Template[],
    statusCode: number,
    timestamp: string
}

export type EmailRecipient = {
    name: string;
    email: string;
    addedByInput: boolean;
}

export type EmailMessage = {
    mailboxId: string;
    content: string;
    subject: string;
    to: EmailRecipient[];
    cc: EmailRecipient[];
    tempAttachmentIds: string[];
}

export type AssociateJobPayload = {
    threadId: string;
    jobs: Array<{
        id: string;
        isRemoved: boolean;
        priority?:string;
        status?:string;
    }>;
    isCreate: boolean;
};


export type Thread = {
    id: string;
    sequence: number;
    messageId: string;
    subject: string | null;
    recentMessage: string | null;
    status: string;
    folder: string;
    createdById: string;
    assignedToId: string | null;
    mailboxId: string;
    lastMessageId: string | null;
    lastMessageAt: string | null;
    label: string;
    isRead: boolean;
    hasAttachments: boolean;
    createdAt: string;
    deletedAt: string | null;
    updatedAt: string;
    lastMessage: LastMessage | null;
    messages: MessageDetail[];
    threadJobIds: string[];
    newThread?: boolean;
    typing?: { status:boolean, username:string} | null;
    eyeball?:number
    draftMessage?:any;
};

export type LastMessage = {
    id: string;
    participants: Participant[];
};

export type Participant = {
    id: string;
    messageId: string;
    email: string;
    name: string | null;
    contactId: string | null;
    userId: string | null;
    addressedType: string;
};

export type GetAllAttachments = {
    entityIds: string[];
    entityType: string;
}

export type MessageDetail = {
    content: string;
    createdById: string;
    draftStatus: string;
    externalMessageId: string;
    externalThreadId: string;
    flow: string;
    id: string;
    isShowMoreClicked: boolean;
    messageAt: string;
    messageObject: any;
    modifiedById: string | null;
    participants: Participant[];
    showMore: boolean;
    statusReason: string | null;
    threadId: string;
    attachments?: [];
    sanitizeTextContent?: string;
};

export type Attachments = {
    createdAt: string;
    createdById: string;
    createdByType: string;
    description: string | null;
    entityId: string;
    entityType: string;
    filename: string;
    id: string;
    key: string;
    mimeType: string;
    previewUrl: string;
    size: string;
    source: string;
    tags: string | null;
    title: string | null;
    updatedAt: string;
    url: string;
    versions: string | null;
}

export enum fourthPanelEmitter {
    ATTACHMENTS = 'ATTACHMENTS',
    ACTIVITY = 'ACTIVITY',
    JOBS = 'JOBS',
    CONTACTS = 'CONTACTS',
    SENDER = 'SENDER',
    TYPING = 'TYPING'
}


export type ThreadPayload = {
    typing: boolean,
    threadId: string,
    user:string
}


export type Activity = {
    user: User;
    change: Change;
    action: string;
    createdAt: string;
}

type User = {
    first_name: string;
    last_name: string;
    photo: string;
};

type Change = {
    name: string;
    old: string | null;
    new: string | null;
};

export const Ignore_Activity_Keys = ['updatedAt', 'assignedToId'];

export type AssociateJob = {
    address: string;
    category: Category;
    createdAt: string;
    description: string;
    id: string;
    issueType: string;
    modifiedAt: string;
    priorities: string;
    property: Property;
    propertyId: string;
    status: string;
};

type Category = {
    id: string;
    name: string;
};

type Property = {
    id: string;
    isActive: boolean;
    name: string;
    type: string;
};
export enum inboxFolder {
    ALL_CONVERSATION = 'All Conversations',
    UNASSIGNED = 'Unassigned',
    NO_JOBS_ASSOCIATED = 'No Jobs Associated',
    CLOSED_OUT = 'Closed Out',
    EMERGENCY_JOBS = 'Emergency Jobs',
    OPEN_CONVERSATIONS = 'Open Conversations',
    CLOSED_CONVERSATION = 'Closed Conversations',
    MY_CONVERSATION = 'My Conversations',
    SPAM = 'Spam',
    TRASH = 'Trash'
}

export enum associatedContactsTypes {
    USER = 'User',
    CONTACTS = 'Contacts',
    MAILBOX_USER = 'Mailbox User',
    UNKNOWN = 'Unknown',
}

export type liveAssociateJobs = {
    threadId:string;
    jobIds:string[];
}

export type EmailConnection = {
    connectionStatus: string;
    email: string;
    id: string;
    lastSyncedAt: string;
    name: string;
    provider: string;
}

export type SMTP_Payload = {
    name: string;
    smtpSettings: {
        host: string;
        port: number;
        secure: boolean;
        auth: {
            user: string;
            pass: string;
        };
    };
    imapSettings: {
        host: string;
        port: number;
        secure: boolean;
        auth: {
            user: string;
            pass: string;
        };
    };
}
