import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ConfigService } from 'src/app/services/config.service';
import { KeycloakService } from 'keycloak-angular';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import Keycloak from 'keycloak-js';
import { BaseService } from '../base.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _isDomainAllowed: boolean | null = null;
  private _keycloakInstance: Keycloak = this.keycloakService.getKeycloakInstance();
  schema_for_subdomain: any;
  currentUserInfo: any = localStorage.getItem('userobj') ? JSON.parse(localStorage.getItem('userobj')) : {};

  constructor(
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    public _Config: ConfigService,
    private readonly keycloakService: KeycloakService,
    private readonly http: HttpClient,
    private _baseService: BaseService
  ) { }

  isDomainAllowed(): Observable<boolean> {
    if (this._isDomainAllowed !== null) {
      return of(this._isDomainAllowed);
    }

    const checkDomainUrl = environment.baseURL + 'users/healthcheck/';
    return this.http.post<{ success: boolean }>(checkDomainUrl, null)
      .pipe(map(({ success }) => !!success))
      .pipe(tap(isDomainAllowed => { this._isDomainAllowed = isDomainAllowed }))
      // .pipe(
      //   catchError((err) => {
      //     console.log('error caught in service')
      //     console.error(err);
 
      //     //Handle the error here
      //     return throwError(err);    //Rethrow it back to component
      //   })
      // )
  }

  /********** To Check is Token Exist or Not ( True | False ) ***************/
  isLoggedIn(): Observable<boolean> {
    return of(this.keycloakService?.isLoggedIn());
  }

  /********** To get Token Value ***************/
  getTokenValue(): Observable<string> {
    return from(this.keycloakService.getToken());
  }

  logIn(redirectUrl?: string): Observable<null> {
    const loginUrl = this._keycloakInstance.createLoginUrl({ redirectUri: redirectUrl });
    return of(null).pipe(tap(() => window.location.replace(loginUrl)));
  }

  logOut(redirectUrl?: string): Observable<null> {
    const logoutUrl = this._keycloakInstance.createLogoutUrl({
      redirectUri: redirectUrl
        || this._keycloakInstance.createLoginUrl({ redirectUri: window.location.origin + '/login' })
    });

    return of(null).pipe(tap(() => {
      this._baseService.removeAuthTokens();
      window.location.replace(logoutUrl);
    }));
  }

  getLoginUrlWithErr(errCode?: string, redirectUrl?: string): string {
    const url = this._keycloakInstance.createLoginUrl({
      redirectUri: redirectUrl || window.location.origin + '/login'
    });

    if (!errCode) {
      return url;
    }

    const searchParams = new URLSearchParams();
    searchParams.append('errorCode', errCode);
    const errorCodeQuery = searchParams.toString();
    const urlWithErrQuery = `${url}&${errorCodeQuery}`;

    return urlWithErrQuery;
  }

  /*** Get Current Active Route Path ***/
  getCurrentActiveState() {

    return this.activatedRoute.snapshot['_routerState'].url;

  }

  setDashboardView(url: any) {

    // if (url.includes('localhost') || url.includes('web.devfyxt.com') || url.includes('fman.devfyxt.com') || url.includes('friedman.fyxt.com') || url.includes('krishna.qafyxt.com')) {
    //   this._Config.isNewDashboard = true;
    // }

  }

  setDomain(requestURL: any) {

    let url = window.location.origin;
    let pathname = window.location.pathname;

    this.setDashboardView(url);

    if (url.includes('localhost') || (url.includes('localhost') && pathname.includes('external-vendor-jobs'))) {

      url = environment.LocalDev_URL;

    }

    let parts = this.extractDomain(url).split('.');
    let partsLength = parts.length - 4;

    for (let i = 0; i < partsLength; i++) {
      parts.shift(); //remove sub-domains one by one
    }

    let rootDomain = requestURL;
    // let currentPath = this.getCurrentActiveState();

    let currentPath = "";
    if (requestURL.includes('/v1')) {
      currentPath = requestURL.split('/v1')[1];
    }
    else if (requestURL.includes('/v2')) {
      currentPath = requestURL.split('/v2')[1];
    }
    else {
      currentPath = requestURL.split('/v1')[1];
    }

    if (parts.length > 2) {

      environment.dynamic_API.forEach(element => {

        let dynamicURL = element.root;
        let dynamic_API_URL = element.api;

        if (requestURL.includes('/v2/')) {
          if (!dynamicURL.includes('/v2/')) {
            dynamicURL = dynamicURL.replace('/v1/', '/v2/');
            dynamic_API_URL = dynamic_API_URL.replace('/v1/', '/v2/');
          }
        }

        if (rootDomain.includes(dynamicURL)) {

          if (environment.directAPI_access.includes(currentPath) || parts[0] === 'auth') {
            requestURL = dynamicURL + currentPath;
            // rootDomain = requestURL.replace('v1//', 'v1/');

            if (requestURL.includes('v1//')) {
              rootDomain = requestURL.replace('v1//', 'v1/');
            }
            else if (requestURL.includes('v2//')) {
              rootDomain = requestURL.replace('v2//', 'v2/');
            }
            else {
              rootDomain = requestURL.replace('v1//', 'v1/');
            }
          }
          else {
            this.schema_for_subdomain = parts[0];

            dynamic_API_URL = dynamic_API_URL.replace('{subdomain}', parts[0]);
            element.socket = element.socket.replace('{subdomain}', parts[0]);

            requestURL = requestURL.replace(dynamicURL, dynamic_API_URL);
            rootDomain = requestURL;

            environment.socketURL = element.socket;
          }
        }

      });
    }

    return rootDomain;

  }


  extractDomain(url) {
    let domain;
    //find & remove protocol (http, ftp, etc.) and get domain
    if (url.indexOf("://") > -1) {
      domain = url.split('/')[2];
    } else {
      domain = url.split('/')[0];
    }

    //find & remove port number
    domain = domain.split(':')[0];

    return domain;
  }


   /**
   * If redirect to next page
   * @param pageURL
   */
   redirectTo(pageURL: any) {
    this._router.navigate([pageURL]);
  }
}
