// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img.mat-card-sm-image {
  width: 40px;
  max-height: 40px;
  border-radius: 4px;
}

img.mat-card-md-image {
  width: 70px;
  max-height: 70px;
  border-radius: 4px;
}

img.mat-card-lg-image {
  width: 100px;
  max-height: 100px;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_reusable_ui/_components/thumbnail/thumbnail.component.scss"],"names":[],"mappings":"AACA;EACI,WAAA;EACA,gBAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,WAAA;EACA,gBAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,YAAA;EACA,iBAAA;EACA,kBAAA;AAAJ","sourcesContent":["\nimg.mat-card-sm-image {\n    width: 40px;\n    max-height: 40px;\n    border-radius: 4px;\n}\n\nimg.mat-card-md-image {\n    width: 70px;\n    max-height: 70px;\n    border-radius: 4px;\n}\n\nimg.mat-card-lg-image {\n    width: 100px;\n    max-height: 100px;\n    border-radius: 4px;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
