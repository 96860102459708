import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { Rule, RuleCategory } from 'src/app/@fyxt/_shared/models/porperty-manager/maintenance-terms-tab';
import { DialogService } from '../../../../_services/dialog/dialog.service';
import { AssignDocumentComponent } from '../assign-document/assign-document.component';
import { LeasesStoreService } from '../../../../_services/leases/leases-store.service';
import { RuleTemplateStoreService } from '../../../../_services/leases/rule-template/rule-template-store.service';
import { ConfigService } from '../../../../../services/config.service';
import { RULE_CRITERIA_TYPE } from '../../../../../Modules/_fyxt_modules/leases/leases.constants';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { DropdownSelectComponent } from '../../../_reusable_ui/_controls/dropdown-select/dropdown-select/dropdown-select.component';
import { ChipComponent } from '../../../_reusable_ui/_components/chip/chip.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { CardComponent } from '../../../_reusable_ui/_components/card/card.component';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'fyxt-template-tab-rules',
    templateUrl: './template-rules-component.component.html',
    styleUrls: ['./template-rules-component.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, NgClass, CardComponent, ButtonComponent, MatTooltipModule, MatIconModule, MatMenuModule, MatFormFieldModule, ChipComponent, DropdownSelectComponent, NgxIntlTelInputModule, MatDividerModule, MatInputModule, MatDialogModule]
})
export class TemplateRulesComponentComponent implements OnInit, OnChanges {
  @Input() hideAddRule: boolean = false;
  @Input() category: RuleCategory;
  @Input() templateId: number;
  private readonly destroy$: Subject<null> = new Subject<null>();
  private template_id: number;

  public rulesForm: FormGroup;
  public isGlobalRule;
  public allRules: Rule[];
  public ruleForItems = [];
  public ruleAppliesItems = [];
  public categories: any[] = [];
  public criteriaType = RULE_CRITERIA_TYPE;

  constructor(
    private readonly leasesStoreService: LeasesStoreService,
    private readonly templateService: RuleTemplateStoreService,
    public _Config: ConfigService,
    public _dialogService: DialogService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    const {category, templateId} = changes;

    if (templateId && templateId.currentValue) {
      if (!this.template_id) {
        this.template_id = templateId.currentValue;
      }
    }

    if (category.currentValue) {
      this.init();
    }
  }

  ngOnInit(): void {
  }

  private init(cleanForm = true): void {
    if (!this.category) {
      return;
    }
    this.isGlobalRule = this.category.id === 'global' || this.category.name === 'Global Rule';
    if (cleanForm) {
      this.rulesForm = null;
    }

    this._Config.isLoader = true;

    combineLatest(
      this.templateService.getTemplateCategoryRules(this.category.id, this.template_id),
      this.templateService.categories$,
      this.leasesStoreService.loadInitRuleOptionsData()
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(([rules, categories, ruleOptions]) => {
        rules = rules.sort((a, b) => {
          if (a.is_global && !b.is_global) {
            return -1;
          }
          if (!a.is_global && b.is_global) {
            return 1;
          }
          return 0;
        });

        this.initForm(rules);
        this.categories = categories.map(ct => ({
          id: ct.id,
          name: ct.category_name,
          selected: false
        })).filter(item => item.id !== 'global' || item.name !== 'Global Rule');

        if (ruleOptions) {
          const {applies_options, for_options} = ruleOptions;
          this.ruleForItems = for_options.map(item => ({name: item.value, value: item.id}));
          this.ruleAppliesItems = applies_options.map(item => ({name: item.value, value: item.id}));
        }

        this._Config.isLoader = false;
      });
  }

  private initForm(rules: Rule[]): void {
    this.allRules = rules;

    this.rulesForm = new FormGroup({
      rules: new FormArray(rules.map(s => {
        const categories = s.categories || [];

        return new FormGroup({
          id: new FormControl(s.id),
          is_global: new FormControl(s.is_global),
          leases_type: new FormControl(s.leases_type),
          categories: new FormControl(categories.map((v) => ({
            id: v.id,
            name: v.name,
            label: v.name
          })), this.isGlobalRule ? [Validators.required, Validators.minLength(2)] : []),
          rule_for: new FormControl(s.rule_for, Validators.required),
          rule_applies: new FormControl(s.rule_applies, Validators.required),
          management_responsible: new FormControl(s.management_responsible, [Validators.required, Validators.min(0), Validators.max(100)]),
          tenant_responsible: new FormControl(s.tenant_responsible, [Validators.required, Validators.min(0), Validators.max(100)]),
          assigned_documents: new FormControl(s.assigned_document),
          criteria: new FormArray(s.criteria.map((c) => {
            return new FormGroup({
              name: new FormControl(c.name),
              count: new FormControl(c.count),
              management_responsible_limit: new FormControl(c.management_responsible_limit, [Validators.required, Validators.min(0), Validators.max(100)]),
              tenant_responsible_limit: new FormControl(c.tenant_responsible_limit, [Validators.required, Validators.min(0), Validators.max(100)]),
            });
          })),
          displayInput: new FormControl(false)
        });
      }))
    });
  }

  getCriterias(ruleIndex: number): FormArray {
    return ((<FormArray> this.rulesForm.get('rules'))?.at(ruleIndex).get('criteria') as FormArray);
  }

  addRuleCriteria() {
    return new FormGroup({
      name: new FormControl(RULE_CRITERIA_TYPE[0], Validators.required),
      count: new FormControl('', Validators.required),
      management_responsible_limit: new FormControl('', [Validators.required, Validators.min(0), Validators.max(100)]),
      tenant_responsible_limit: new FormControl('', [Validators.required, Validators.min(0), Validators.max(100)]),
    });
  }

  addMoreRuleCriteria(item) {
    item.controls['criteria'].push(this.addRuleCriteria());
  }

  removeRuleCriteria(item, index: number) {
    item.controls['criteria'].removeAt(index);
  }

  itemFormat() {
    return this.rulesForm.get('rules') as FormArray;
  }

  editRules(item): void {
    item.controls['displayInput'].value = true;
  }

  addRule(): void {
    (<FormArray> this.rulesForm.get('rules'))?.push(this.addItemFormats());
  }

  addItemFormats(): any {
    return new FormGroup({
      id: new FormControl(''),
      leases_type: new FormControl(''),
      categories: new FormControl([], this.isGlobalRule ? [Validators.required, Validators.minLength(2)] : []),
      rule_for: new FormControl('', Validators.required),
      rule_applies: new FormControl('', Validators.required),
      management_responsible: new FormControl('', [Validators.required, Validators.min(0), Validators.max(100)]),
      tenant_responsible: new FormControl('', [Validators.required, Validators.min(0), Validators.max(100)]),
      assigned_documents: new FormControl([]),
      criteria: new FormArray([this.addRuleCriteria()]),
      displayInput: new FormControl(true)
    });
  }

  cancelEditRule(item: any, i: number): void {
    if (item.value.id) {
      const rule = this.allRules.find(rule => rule.id === item.value.id);

      const categories = rule.categories && rule.categories.length ?
        rule.categories.map((v) => ({id: v.id, name: v.name, label: v.name})) : [];

      item.controls['leases_type'].value = rule.leases_type || '';
      item.controls['id'].value = rule.id || '';
      item.controls['categories'].value = categories;
      item.controls['rule_for'].value = rule.rule_for;
      item.controls['rule_applies'].value = rule.rule_applies;
      item.controls['management_responsible'].value = rule.management_responsible;
      item.controls['tenant_responsible'].value = rule.tenant_responsible;
      item.controls['assigned_documents'].value = rule.assigned_document;
      item.controls['criteria'].setValue(rule.criteria.map((c) => ({
        name: c.name,
        count: c.count,
        management_responsible_limit: c.management_responsible_limit,
        tenant_responsible_limit: c.tenant_responsible_limit,
      })));

      item.controls['displayInput'].value = false;
    } else {
      (<FormArray> this.rulesForm.get('rules'))?.removeAt(i);
    }

  }

  private createRule(item: any, i: number): void {
    const {
      categories,
      rule_for,
      rule_applies,
      management_responsible,
      tenant_responsible,
      assigned_documents,
      criteria,
    } = item.value;

    const data: Rule = {
      template_id: this.template_id,
      categories: categories.map(ct => ct.id),
      rule_for: rule_for.name,
      rule_applies: rule_applies.name,
      management_responsible,
      tenant_responsible,
      criteria: criteria.map(cr => ({...cr, name: cr.name.value})),
      assigned_document: assigned_documents,
      category_id: this.category.id,
    };

    item.controls['rule_for'].value = data.rule_for;
    item.controls['rule_applies'].value = data.rule_applies;


    this._Config.isLoader = true;

    this.templateService.createTemplateRule(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((createdRule) => {
        item.controls['id'].value = createdRule.id;
        item.controls['displayInput'].value = false;
        this.allRules.push(createdRule);
        this.templateService.getRulesCount(this.template_id);
        this._Config.isLoader = false;
      });
  }

  private updateRule(item: any, i: number): void {

    const {
      id,
      categories,
      rule_for,
      rule_applies,
      management_responsible,
      tenant_responsible,
      assigned_documents,
      criteria,
    } = item.value;

    const data: Rule = {
      template_id: this.template_id,
      categories: categories.map(ct => ct.id),
      rule_for: rule_for.name || rule_for,
      rule_applies: rule_applies.name || rule_applies,
      management_responsible,
      tenant_responsible,
      criteria: criteria.map(cr => ({...cr, name: cr.name.value || cr.name})),
      assigned_document: assigned_documents,
      category_id: this.category.id,
    };

    item.controls['rule_for'].setValue(data.rule_for);
    item.controls['rule_applies'].setValue(data.rule_applies);

    this._Config.isLoader = true;

    this.templateService.updateTemplateRule(id, data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.init(false);
      });

  }

  saveRule(item: FormGroup, i: number): void {
    if (!item.valid) {
      item.markAllAsTouched();
      return;
    }

    if (item.controls['id'].value) {
      return this.updateRule(item, i);
    }
    return this.createRule(item, i);
  }

  onDeleteRuleClick(item: any, index: number): void {
    this._Config.isLoader = true;

    this.templateService.deleteTemplateRule(item.value.id, this.template_id)
      .subscribe(() => {
        this.init(false);
      });
  }

  onAssignDocumentClick(item: any, id: number) {
    let popupData = {
      title: 'Assign Document',
      component: AssignDocumentComponent,
      containerClass: ['modal_lease_property'],
      id
    };

    this._dialogService.openModal(popupData).subscribe(result => {
      if (result.options?.length) {
        item.get('assigned_documents').value = result.options.map(v => ({
          id: v.id,
          name: v.name,
          url: v.url,
          pages: v.pages
        }));
        // update provision
        if (item.get('id')?.value) {
          // this.onSaveClick(item);
        }

      }
    });
  }

  onRemoveAssignedDoc(docs, item) {
    item.get('assigned_documents').value = docs;
  }

  onchangePercentage(event, field, item: AbstractControl) {
    let {value} = event.target;
    value = +value;
    const calculatedValue = value < 0 || value > 100 ? 0 : 100 - value;

    switch (field) {
      case 'management_responsible':
        item.get('tenant_responsible').setValue(calculatedValue);
        break;
      case 'tenant_responsible':
        item.get('management_responsible').setValue(calculatedValue);
        break;
      case 'management_responsible_limit':
        item.get('tenant_responsible_limit').setValue(calculatedValue);
        break;
      case 'tenant_responsible_limit':
        item.get('management_responsible_limit').setValue(calculatedValue);
        break;

    }
  }

  openAssignedDocument(item) {
    window.open(item.url);
  }

   /** Track by Index  **/
   trackByIndex(index: number, item: any): number {
    return index;
  }
  
}
