import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { environment } from 'src/environments/environment';
import { UtilityService } from '../utility.service';

import { Config2Service } from './config2.service';
import { RestAPIService } from './rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  currentUserInfo: any = localStorage.getItem('userobj') ? JSON.parse(localStorage.getItem('userobj')) : {};

  categoryNextUrl: string = '';
  tenantNextUrl: string = '';
  propertyNextUrl: string = '';
  vendorNextUrl: string = '';
  recentTenantNextUrl: string = '';
  searchText: string = '';
  ShowAdvanceFilterPopup = new Subject();
  isRefreshSelectedFilters = new Subject();

  advanceFilterList: any;
  advancedFilterGlobalSearch: any;
  selectedJobCostTab: any;
  dateAccordion = {
    createdCollapse: false,
    lastActivityCollapse: false,
    targetCompletionCollapse: false
  };
  isShownDetail = false; // hidden by default
  jobCostAmount: any = {
    fromAmount: '',
    toAmount: '',
    jobCostAmountErrorMsg: ''
  };

  filterItems: any = {
    managers: {
      allManagers: [],
      selectedItems: []
    },
    engineers: {
      allEngineers: [],
      selectedItems: []
    },
    categories: {
      allCategories: [],
      selectedItems: []
    },
    properties: {
      allProperties: [],
      selectedItems: []
    },
    tenants: {
      allTenant: [],
      selectedItems: []
    },
    vendors: {
      allVendor: [],
      selectedItems: []
    },
    followers: {
      allfollower: [],
      selectedItems: []
    },
    actions: {
      allAction: [],
      selectedItems: []
    },
    services: {
      allService: [],
      selectedItems: []
    },
    owners: {
      allOwners: [],
      selectedItems: []
    },
    billableParty: {
      allBillableParty: [],
      selectedItems: []
    },
    createdBy: {
      allCreatedByManagers: [],
      allCreatedByEngineers: [],
      allCreatedByTenants: [],
      selectedItems: []
    },
    schedules: {
      allSchedule: [],
      selectedItems: [],
      datePickerModel: [],
      customPayload: []
    },
    jobCosts: {
      allJobCost: [
        { id: 'Bid', name: 'Bid', value: 'Bid', selected: false },
        { id: 'Change_Order', name: 'Change Order', value: 'Change Order', selected: false },
        { id: 'Invoice', name: 'Invoice', value: 'Invoice', selected: false },
      ],
      selectedItems: [],
      amount: [],
      dateApproved: {
        allDateApproved: [],
        selectedItems: [],
        datePickerModel: [],
        customPayload: []
      },
    },
    dates: {
      created: {
        allDates: [],
        selectedItem: [],
        datePickerModel: [],
        customPayload: []
      },
      lastActivity: {
        allDates: [],
        selectedItem: [],
        datePickerModel: [],
        customPayload: []
      },
      targetCompletion: {
        allDates: [],
        selectedItem: [],
        datePickerModel: [],
        customPayload: []
      }
    },
    status: {
      Status: [
        { id: 'ToDo', name: 'To Do', value: 'To Do', selected: false },
        { id: 'In_Progress', name: 'In Progress', value: 'In Progress', selected: false },
        { id: 'OnHold', name: 'On Hold', value: 'On Hold', selected: false },
        { id: 'Cancelled', name: 'Cancelled', value: 'Cancelled', selected: false },
        { id: 'Complete', name: 'Complete', value: 'Complete', selected: false },
        // { id: 'Closed', name: 'Closed', value: 'Closed', selected: false },
      ],
      selectedItems: []
    },
    priority: {
      Priority: [
        { id: 'Low', name: 'Low', value: 'Low', selected: false },
        { id: 'Medium', name: 'Medium', value: 'Medium', selected: false },
        { id: 'High', name: 'High', value: 'High', selected: false },
        { id: 'Emergency', name: 'Emergency', value: 'Emergency', selected: false },
      ],
      selectedItems: []
    },
    source: {
      Source: [
        { id: 'In System', name: 'In System', value: 'In System', selected: false },
        { id: 'Service Planner', name: 'Service Planner', value: 'Service Planner', selected: false },
        { id: 'Inbox', name: 'Inbox', value: 'Inbox', selected: false },
        { id: 'Service Form', name: 'Service Form', value: 'Service Form', selected: false }
      ],
      selectedItems: []
    },
    locations: {
      allLocations: []
    }

  };

  filterPayload: any = {
    "assignees": [],
    "engineers": [],
    "categories": [],
    "properties": [],
    "tenants": [],
    "followers": [],
    "vendors": [],
    "stage": [],
    "priority": [],
    "actions": [],
    "service_type": [],
    "created_by": [],
    "owners": [],
    "responsible": [],
    "source": [],
    "scheduled_dates": {
      timeline: '',
      custom: []
    },
    "job_costs": {
      type: [],
      amount: [],
      date_approved: {
        timeline: '',
        custom: []
      }
    },
    "new_dates": {
      created: {
        timeline: '',
        custom: []
      },
      target_completion: {
        timeline: '',
        custom: []
      },
      last_activity: {
        timeline: '',
        custom: []
      }
    }
  };

  nestedDropdowns: any[] = [
    {
      "title": "Managers",
      "slug": "Managers",
      "unassigned": true,
      "not_required": true,
      "isSelectAll": false,
      "searchKeyword": "",
      "list_data": [],
      "isExpend": false,
      "type": "Manager",
      "selectedItems": []
    },
    {
      "title": "Engineers",
      "slug": "Engineers",
      "unassigned": true,
      "not_required": true,
      "isSelectAll": false,
      "searchKeyword": "",
      "list_data": [],
      "isExpend": false,
      "type": "Engineer",
      "selectedItems": []
    },
    {
      "title": "Followers",
      "slug": "Followers",
      "unassigned": true,
      "not_required": true,
      "isSelectAll": false,
      "searchKeyword": "",
      "list_data": [],
      "isExpend": false,
      "type": "Follower",
      "selectedItems": []
    },
  ];

  createdByDropdowns: any[] = [
    {
      "title": "Managers",
      "slug": "Managers_CreatedBy",
      "unassigned": true,
      "not_required": true,
      "isSelectAll": false,
      "searchKeyword": "",
      "list_data": [],
      "isExpend": false,
      "type": "Manager",
      "selectedItems": []
    },
    {
      "title": "Engineers",
      "slug": "Engineers_CreatedBy",
      "unassigned": true,
      "not_required": true,
      "isSelectAll": false,
      "searchKeyword": "",
      "list_data": [],
      "isExpend": false,
      "type": "Engineer",
      "selectedItems": []
    },
    {
      "title": "Tenants",
      "slug": "Tenants_CreatedBy",
      "unassigned": true,
      "not_required": true,
      "isSelectAll": false,
      "searchKeyword": "",
      "list_data": [],
      "isExpend": false,
      "type": "Tenants",
      "selectedItems": []
    },
  ];

  recentJobfilterItems: any = {
    tenants: {
      allTenant: [],
      selectedItems: []
    }
  }

  recentJobCategoryItems: any = {
    categories: {
      allCategories: [],
      selectedItems: []
    }
  }

  bulkAction: any = {
    managers: [],
    engineers: [],
    followers: []
  };


  constructor(
    public _Config: Config2Service,
    public _baseService: RestAPIService,
    public _util: UtilityService
  ) { }


  resetAppliedFilters(selectedActiveTab?: any) {
    this.filterItems = {
      managers: {
        allManagers: this.filterItems.managers.allManagers ? this.filterItems.managers.allManagers.map(obj => { obj.isChecked = false; return obj }) : [],
        selectedItems: []
      },
      engineers: {
        allEngineers: this.filterItems.engineers.allEngineers ? this.filterItems.engineers.allEngineers.map(obj => { obj.isChecked = false; return obj }) : [],
        selectedItems: []
      },
      categories: {
        allCategories: this.filterItems.categories.allCategories ? this.filterItems.categories.allCategories.map(obj => { obj.isChecked = false; return obj }) : [],
        selectedItems: []
      },
      properties: {
        allProperties: this.filterItems.properties.allProperties ? this.filterItems.properties.allProperties.map(obj => { obj.isChecked = false; return obj }) : [],
        selectedItems: []
      },
      tenants: {
        allTenant: this.filterItems.tenants.allTenant ? this.filterItems.tenants.allTenant.map(obj => { obj.isChecked = false; return obj }) : [],
        selectedItems: []
      },
      vendors: {
        allVendor: this.filterItems.vendors.allVendor ? this.filterItems.vendors.allVendor.map(obj => { obj.isChecked = false; return obj }) : [],
        selectedItems: []
      },
      followers: {
        allfollower: this.filterItems.followers.allfollower ? this.filterItems.followers.allfollower.map(obj => { obj.isChecked = false; return obj }) : [],
        selectedItems: []
      },
      locations: {
        allLocations: this.filterItems.locations.allLocations ? this.filterItems.locations.allLocations.map(obj => { obj.isChecked = false; return obj }) : [],
        selectedItems: [],
      },
      actions: {
        allAction: this.filterItems.actions.allAction ? this.filterItems.actions.allAction.map(obj => { obj.isChecked = false; return obj }) : [],
        selectedItems: []
      },
      services: {
        allService: this.filterItems.services.allService ? this.filterItems.services.allService.map(obj => { obj.isChecked = false; return obj }) : [],
        selectedItems: []
      },
      owners: {
        allOwners: this.filterItems.owners.allOwners ? this.filterItems.owners.allOwners.map(obj => { obj.isChecked = false; return obj }) : [],
        selectedItems: []
      },
      billableParty: {
        allBillableParty: this.filterItems.billableParty.allBillableParty ? this.filterItems.billableParty.allBillableParty.map(obj => { obj.isChecked = false; return obj }) : [],
        selectedItems: []
      },
      createdBy: {
        allCreatedByManagers: this.filterItems.createdBy.allCreatedByManagers ? this.filterItems.createdBy.allCreatedByManagers.map(obj => { obj.isChecked = false; return obj }) : [],
        allCreatedByEngineers: this.filterItems.createdBy.allCreatedByEngineers ? this.filterItems.createdBy.allCreatedByEngineers.map(obj => { obj.isChecked = false; return obj }) : [],
        allCreatedByTenants: this.filterItems.createdBy.allCreatedByTenants ? this.filterItems.createdBy.allCreatedByTenants.map(obj => { obj.isChecked = false; return obj }) : [],
        selectedItems: []
      },
      schedules: {
        allSchedule: [],
        selectedItems: [],
        datePickerModel: [],
        customPayload: []
      },
      jobCosts: {
        allJobCost: [
          { id: 'Bid', name: 'Bid', value: 'Bid', selected: false },
          { id: 'Change_Order', name: 'Change Order', value: 'Change Order', selected: false },
          { id: 'Invoice', name: 'Invoice', value: 'Invoice', selected: false },
        ],
        selectedItems: [],
        amount: [],
        dateApproved: {
          allDateApproved: [],
          selectedItems: [],
          datePickerModel: [],
          customPayload: []
        },
      },
      dates: {
        created: {
          allDates: [],
          selectedItem: [],
          datePickerModel: [],
          customPayload: []
        },
        lastActivity: {
          allDates: [],
          selectedItem: [],
          datePickerModel: [],
          customPayload: []
        },
        targetCompletion: {
          allDates: [],
          selectedItem: [],
          datePickerModel: [],
          customPayload: []
        }
      },
      status: {
        Status: [
          { id: 'ToDo', name: 'To Do', value: 'To Do', selected: false },
          { id: 'In_Progress', name: 'In Progress', value: 'In Progress', selected: false },
          { id: 'OnHold', name: 'On Hold', value: 'On Hold', selected: false },
          { id: 'Cancelled', name: 'Cancelled', value: 'Cancelled', selected: false },
          { id: 'Complete', name: 'Complete', value: 'Complete', selected: false },
          // { id: 'Closed', name: 'Closed', value: 'Closed', selected: false },
        ],
        selectedItems: []
      },
      priority: {
        Priority: [
          { id: 'Low', name: 'Low', value: 'Low', selected: false },
          { id: 'Medium', name: 'Medium', value: 'Medium', selected: false },
          { id: 'High', name: 'High', value: 'High', selected: false },
          { id: 'Emergency', name: 'Emergency', value: 'Emergency', selected: false },
        ],
        selectedItems: []
      },
      source: {
        Source: [
          { id: 'In System', name: 'In System', value: 'In System', selected: false },
          { id: 'Service Planner', name: 'Service Planner', value: 'Service Planner', selected: false },
          { id: 'Inbox', name: 'Inbox', value: 'Inbox', selected: false },
          { id: 'Service Form', name: 'Service Form', value: 'Service Form', selected: false }
        ],
        selectedItems: []
      },

    };

    this.nestedDropdowns = [
      {
        "title": "Managers",
        "slug": "Managers",
        "unassigned": true,
        "not_required": true,
        "isSelectAll": false,
        "searchKeyword": "",
        "list_data": [],
        "isExpend": false,
        "type": "Manager",
        "selectedItems": []
      },
      {
        "title": "Engineers",
        "slug": "Engineers",
        "unassigned": true,
        "not_required": true,
        "isSelectAll": false,
        "searchKeyword": "",
        "list_data": [],
        "isExpend": false,
        "type": "Engineer",
        "selectedItems": []
      },
      {
        "title": "Followers",
        "slug": "Followers",
        "unassigned": true,
        "not_required": true,
        "isSelectAll": false,
        "searchKeyword": "",
        "list_data": [],
        "isExpend": false,
        "type": "Follower",
        "selectedItems": []
      },
    ];

    this.createdByDropdowns = [
      {
        "title": "Managers",
        "slug": "Managers_CreatedBy",
        "unassigned": true,
        "not_required": true,
        "isSelectAll": false,
        "searchKeyword": "",
        "list_data": [],
        "isExpend": false,
        "type": "Manager",
        "selectedItems": []
      },
      {
        "title": "Engineers",
        "slug": "Engineers_CreatedBy",
        "unassigned": true,
        "not_required": true,
        "isSelectAll": false,
        "searchKeyword": "",
        "list_data": [],
        "isExpend": false,
        "type": "Engineer",
        "selectedItems": []
      },
      {
        "title": "Tenants",
        "slug": "Tenants_CreatedBy",
        "unassigned": true,
        "not_required": true,
        "isSelectAll": false,
        "searchKeyword": "",
        "list_data": [],
        "isExpend": false,
        "type": "Tenants",
        "selectedItems": []
      },
    ];


    this.filterPayload = {
      "assignees": [],
      "engineers": [],
      "categories": [],
      "properties": [],
      "tenants": [],
      "vendors": [],
      "follower": [],
      "stage": [],
      "priority": [],
      "actions": [],
      "service_type": [],
      "created_by": [],
      "owners": [],
      "responsible": [],
      "source": [],
      "scheduled_dates": {
        timeline: '',
        custom: []
      },
      "job_costs": {
        type: [],
        amount: [],
        date_approved: {
          timeline: '',
          custom: []
        }
      },
      "new_dates": {
        created: {
          timeline: '',
          custom: []
        },
        target_completion: {
          timeline: '',
          custom: []
        },
        last_activity: {
          timeline: '',
          custom: []
        }
      }
    };

  }


  loadAllFilterAPIs(selectedActiveTab: any,isFirstTime?:boolean) {

    if(isFirstTime){
      this.getFilter_Status(selectedActiveTab);
      this.getFilter_Priority(selectedActiveTab);
      this.getFilter_Properties(selectedActiveTab);
      this.getFilter_Categories(selectedActiveTab);
  
      this.getFilter_Managers(selectedActiveTab);
      this.getFilter_Engineers(selectedActiveTab);
      this.getFilter_Tenants(selectedActiveTab);
      this.getFilter_Followers(selectedActiveTab);
    }
    else{
      this.getFilter_Managers(selectedActiveTab);
      this.getFilter_Engineers(selectedActiveTab);
      this.getFilter_Tenants(selectedActiveTab);
      this.getFilter_Followers(selectedActiveTab);
    }
   
    // this.getFilter_Locations(selectedActiveTab);

  }


  /**
  * Get all Status
  */
  getFilter_Status(selectedActiveTab: any) {

    let requestURL = environment.baseURL + this._Config.getAllStagesToJob;

    this._baseService.doGET(requestURL).subscribe(
      (response: any) => {
        // this._Config.isLoader = false;

        if (Array.isArray(response)) this.filterItems.status.Status = response || [];

        this.filterItems.status.Status.forEach(element => {

          if (selectedActiveTab.query) {

            if (selectedActiveTab.query.stage) {
              if (selectedActiveTab.query.stage.includes(element.name)) {
                element.isChecked = true;
                // this.filterItems.status.selectedItems.push(element);

                var index = this.filterItems.status.selectedItems.findIndex(x => x.id == element.id);
                if (index === -1) {
                  this.filterItems.status.selectedItems.push(element);
                }
              }
            }

          }

        });

      },
      (error) => {
        // this._Config.isLoader = false;
      }
    );

  }


  /**
  * Get all Status
  */
  getFilter_Priority(selectedActiveTab: any) {

    let requestURL = environment.baseURL + this._Config.getAllPriority;

    this._baseService.doGET(requestURL).subscribe((response: any) => {

      this.filterItems.priority.Priority = response || [];

      this.filterItems.priority.Priority.forEach(element => {

        if (selectedActiveTab.query) {
          if (selectedActiveTab.query.priority) {
            if (selectedActiveTab.query.priority.includes(element.name)) {
              element.isChecked = true;
              // this.filterItems.priority.selectedItems.push(element);

              var index = this.filterItems.priority.selectedItems.findIndex(x => x.id == element.id);
              if (index === -1) {
                this.filterItems.priority.selectedItems.push(element);
              }
            }
          }
        }


      });

    },
      (error) => {
        // this._Config.isLoader = false;

      });



  }


  /**
 * Get all Managers
 */
  getFilter_Managers(selectedActiveTab?: any) {
    let requestURL = environment.baseURL + this._Config.getAllManagers;
    this._baseService.doGET(requestURL).subscribe((response: any) => {

      response.forEach((element: any) => {
        element.name = element.full_name
      });

      this.filterItems.managers.allManagers = response || [];
      this.bulkAction.managers = response || [];

      let indexVal = this.nestedDropdowns.findIndex(obj => obj.slug == 'Managers');

      if (indexVal != -1) {

        this.filterItems.managers.allManagers.forEach(element => {

          if (selectedActiveTab.query) {
            if (selectedActiveTab.query.assignees.includes(element.id)) {
              element.isChecked = true;
              // this.filterItems.managers.selectedItems.push(element);

              var index = this.filterItems.managers.selectedItems.findIndex(x => x.id == element.id);
              if (index === -1) {
                this.filterItems.managers.selectedItems.push(element);
              }

            }
          }


          // if (selectedActiveTab.name === "Assigned To Me") {
          //   if (element.id == this.currentUserInfo.id) {
          //     element.isChecked = true;

          //     let indexVal = this.filterItems.managers.selectedItems.findIndex(obj => obj.id == element.id);
          //     if (indexVal === -1) {
          //       this.filterItems.managers.selectedItems.push(element);
          //     }

          //   }
          // }

        });

        this.nestedDropdowns[indexVal].list_data = this.filterItems.managers.allManagers;

        this.nestedDropdowns.forEach(item => {

          if (selectedActiveTab.name === "Assigned To Me") {
            if (item.type == this.currentUserInfo.current_Role) {
              item.isExpend = true;
            }
          }

          if (item.type == this.currentUserInfo.current_Role) {
            item.selectedItems = this.filterItems.managers.selectedItems;

            this.isRefreshSelectedFilters.next(item.selectedItems);
          }

        });




      }

    },
      (error) => {});
  }



  /**
* Get all Engineers
*/
  getFilter_Engineers(selectedActiveTab?: any) {
    let requestURL = environment.baseURL + this._Config.getAllEngineers;
    this._baseService.doGET(requestURL).subscribe((response: any) => {

      response.forEach((element: any) => {
        element.name = element.full_name
      });

      this.filterItems.engineers.allEngineers = response || [];
      this.bulkAction.engineers = response || [];

      let indexVal = this.nestedDropdowns.findIndex(obj => obj.slug == 'Engineers');

      if (indexVal != -1) {

        this.filterItems.engineers.allEngineers.forEach(element => {

          if (selectedActiveTab.query) {
            if (selectedActiveTab.query.engineers.includes(element.id)) {
              element.isChecked = true;
              this.filterItems.engineers.selectedItems.push(element);
            }
          }

          // if (selectedActiveTab.name === "Assigned To Me") {
          //   if (element.id == this.currentUserInfo.id) {
          //     element.isChecked = true;

          //     let indexVal = this.filterItems.engineers.selectedItems.findIndex(obj => obj.id == element.id);
          //     if (indexVal === -1) {
          //       this.filterItems.engineers.selectedItems.push(element);
          //     }

          //   }
          // }

        });

        this.nestedDropdowns[indexVal].list_data = this.filterItems.engineers.allEngineers;

        this.nestedDropdowns.forEach(item => {

          if (selectedActiveTab.name === "Assigned To Me") {
            if (item.type == this.currentUserInfo.current_Role) {
              item.isExpend = true;
            }
          }

          if (item.type == this.currentUserInfo.current_Role) {
            item.selectedItems = this.filterItems.engineers.selectedItems;

            this.isRefreshSelectedFilters.next(item.selectedItems);

          }



        });

      }

    },
      (error) => {});
  }


  /**
* Get all Followers
*/
  getFilter_Followers(selectedActiveTab?: any) {
    let requestURL = environment.baseURL + this._Config.getAllManagers;
    this._baseService.doGET(requestURL).subscribe((response: any) => {

      response.forEach((element: any) => {
        element.name = element.full_name
      });

      this.filterItems.followers.allfollower = response || [];
      this.bulkAction.followers = response || [];
      let indexVal = this.nestedDropdowns.findIndex(obj => obj.slug == 'Followers');

      if (indexVal != -1) {

        // this.filterItems.followers.allfollower.forEach(element => {

        //   if (selectedActiveTab.query) {
        //     if (selectedActiveTab.query.engineers.includes(element.id)) {
        //       element.isChecked = true;
        //       this.filterItems.engineers.selectedItems.push(element);
        //     }
        //   }

        // });

        this.nestedDropdowns[indexVal].list_data = this.filterItems.followers.allfollower;

        this.nestedDropdowns.forEach(item => {

          // if (selectedActiveTab.name === "Assigned To Me") {
          //   if (item.type == this.currentUserInfo.current_Role) {
          //     item.isExpend = true;
          //   }
          // }

          // if (item.type == this.currentUserInfo.current_Role) {
          //   item.selectedItems = this.filterItems.allfollower.selectedItems;

          //   this.isRefreshSelectedFilters.next(item.selectedItems);

          // }



        });

      }

    },
      (error) => {});
  }


  /**
  * To Get All Priority
  */
  getAllPriority() {






  }


  /**
 * Get all Properties
 */
  getFilter_Properties(selectedActiveTab: any) {

    let requestURL = environment.baseURL + this._Config.getAllProperties;
    this._baseService.doGET(requestURL).subscribe((response: any) => {

      this.filterItems.properties.allProperties = response.results || [];
      this.propertyNextUrl = response.next;

      this.filterItems.properties.allProperties.forEach(element => {

        if (selectedActiveTab.query) {
          if (selectedActiveTab.query?.properties) {
            if (selectedActiveTab.query.properties.includes(element.id)) {
              element.isChecked = true;

              var index = this.filterItems.properties.selectedItems.findIndex(x => x.id == element.id);
              if (index === -1) {
                this.filterItems.properties.selectedItems.push(element);
              }


            }
          }
        }

      });

    },
      (error) => {});

  }
  onSearchChange(event: any, e?: any) {
    if (e === 'Property') {
      // let selectedItems=this.filterItems.properties.selectedItems;

      this.searchText = event.target.value;
      if (this._util.isSearchFieldValid(this.searchText) || this.searchText === '') {
        let requestURL = environment.baseURL + this._Config.getAllProperties;
        this._baseService.doGET(requestURL + '?custom-search=' + this.searchText).subscribe({
          next: (res: any) => {
            this.propertyNextUrl = res.next;
            this.filterItems.properties.allProperties = res.results;

            // this.filterItems.properties.selectedItems = selectedItems;
            this.filterItems.properties.allProperties.forEach(element => {
              if (this.filterItems.properties.selectedItems.length > 0) {
                if (this.filterItems.properties.selectedItems.map(obj => obj.id).includes(element.id)) {
                  element.isChecked = true;
                }
              }
            });

          }, error: (err: HttpErrorResponse) => {
          }
        })
      } else {
        this.filterItems.properties.allProperties = [];
      }
    }
    if (e === 'Tenant') {
      this.searchText = event.target.value;
      if (this._util.isSearchFieldValid(this.searchText) || this.searchText === '') {
        let requestURL = environment.baseURL + this._Config.getAllTenants;
        this._baseService.doGET(requestURL + '?custom-search=' + this.searchText).subscribe({
          next: (res: any) => {
            res.results.forEach((element: any) => {
              element.name = element.company.replace(/\s+/g, ' ').trim()
            });
            this.tenantNextUrl = res.next;
            this.filterItems.tenants.allTenant = res.results;

            this.filterItems.tenants.allTenant.forEach(element => {
              if (this.filterItems.tenants.selectedItems.length > 0) {
                if (this.filterItems.tenants.selectedItems.map(obj => obj.id).includes(element.id)) {
                  element.isChecked = true;
                }
              }
            });

          }, error: (err: HttpErrorResponse) => {
          }
        })
      } else {
        this.filterItems.tenants.allTenant = [];
      }
    }
    if (e === 'Tenants') {
      this.searchText = event.target.value;
      if (this._util.isSearchFieldValid(this.searchText) || this.searchText === '') {
        let requestURL = environment.baseURL + this._Config.getAllTenants;
        this._baseService.doGET(requestURL + '?custom-search=' + this.searchText).subscribe({
          next: (res: any) => {
            res.results.forEach((element: any) => {
              element.name = element.company.replace(/\s+/g, ' ').trim()
            });
            this.recentTenantNextUrl = res.next;
            this.recentJobfilterItems.tenants.allTenant = res.results;

            this.recentJobfilterItems.tenants.allTenant.forEach(element => {
              if (this.recentJobfilterItems.tenants.selectedItems.length > 0) {
                if (this.recentJobfilterItems.tenants.selectedItems.map(obj => obj.id).includes(element.id)) {
                  element.isChecked = true;
                }
              }
            });

          }, error: (err: HttpErrorResponse) => {
          }
        })
      } else {
        this.recentJobfilterItems.tenants.allTenant = [];
      }
    }
    if (e === 'Category') {
      this.searchText = event.target.value;
      if (this._util.isSearchFieldValid(this.searchText) || this.searchText === '') {
        let requestURL = environment.baseURL + this._Config.getAllCategories;
        this._baseService.doGET(requestURL + '?custom-search=' + this.searchText).subscribe({
          next: (res: any) => {
            this.categoryNextUrl = res.next;
            this.filterItems.categories.allCategories = res.results;

            this.filterItems.categories.allCategories.forEach(element => {
              if (this.filterItems.categories.selectedItems.length > 0) {
                if (this.filterItems.categories.selectedItems.map(obj => obj.id).includes(element.id)) {
                  element.isChecked = true;
                }
              }
            });

          }, error: (err: HttpErrorResponse) => {
          }
        })
      } else {
        this.filterItems.categories.allCategories = [];
      }
    }
    if (e === 'Vendor') {
      this.searchText = event.target.value;
      if (this._util.isSearchFieldValid(this.searchText) || this.searchText === '') {
        let requestURL = environment.baseURL + this._Config.getAllVendors;
        this._baseService.doGET(requestURL + '?custom-search=' + this.searchText).subscribe({
          next: (res: any) => {
            res.results.forEach((element: any) => {
              element.name = element.company.replace(/\s+/g, ' ').trim()
            });
            this.filterItems.vendors.allVendor = res.results || [];
            this.vendorNextUrl = res.next;

            this.filterItems.vendors.allVendor.forEach(element => {
              if (this.filterItems.vendors.selectedItems.length > 0) {
                if (this.filterItems.vendors.selectedItems.map(obj => obj.id).includes(element.id)) {
                  element.isChecked = true;
                }
              }
            });

          }, error: (err: HttpErrorResponse) => {
          }
        })
      } else {
        this.filterItems.vendors.allVendor = [];
      }
    }
  }
  infiniteScrollcall(e?: any) {
    if (e === 'Property') {
      if (this.propertyNextUrl != '' && this.propertyNextUrl !== null) {
        this._baseService.doGET(this.propertyNextUrl).subscribe({
          next: (res: any) => {
            this.propertyNextUrl = res.next;
            this.filterItems.properties.allProperties = this.filterItems.properties.allProperties.concat(res.results);

            this.filterItems.properties.allProperties.forEach(element => {
              if (this.filterItems.properties.selectedItems.length > 0) {
                if (this.filterItems.properties.selectedItems.map(obj => obj.id).includes(element.id)) {
                  element.isChecked = true;
                }
              }
            });

          }, error: (err: HttpErrorResponse) => {}
        });
      }
    }
    if (e === 'Tenant') {
      if (this.tenantNextUrl != '' && this.tenantNextUrl !== null) {

        this._baseService.doGET(this.tenantNextUrl).subscribe({
          next: (res: any) => {
            res.results.forEach((element: any) => {
              element.name = element.company
            });
            this.tenantNextUrl = res.next;
            this.filterItems.tenants.allTenant = this.filterItems.tenants.allTenant.concat(res.results);

            this.filterItems.tenants.allTenant.forEach(element => {
              if (this.filterItems.tenants.selectedItems.length > 0) {
                if (this.filterItems.tenants.selectedItems.map(obj => obj.id).includes(element.id)) {
                  element.isChecked = true;
                }
              }
            });

          }, error: (err: HttpErrorResponse) => {}
        });
      }
    }
    if (e === 'Tenants') {
      if (this.recentTenantNextUrl != '' && this.recentTenantNextUrl !== null) {
        this._baseService.doGET(this.recentTenantNextUrl).subscribe({
          next: (res: any) => {
            res.results.forEach((element: any) => {
              element.name = element.company
            });
            this.recentTenantNextUrl = res.next;
            this.recentJobfilterItems.tenants.allTenant = this.recentJobfilterItems.tenants.allTenant.concat(res.results);

            this.recentJobfilterItems.tenants.allTenant.forEach(element => {
              if (this.recentJobfilterItems.tenants.selectedItems.length > 0) {
                if (this.recentJobfilterItems.tenants.selectedItems.map(obj => obj.id).includes(element.id)) {
                  element.isChecked = true;
                }
              }
            });

          }, error: (err: HttpErrorResponse) => {}
        });
      }
    }
    if (e === 'Category') {
      if (this.categoryNextUrl != '' && this.categoryNextUrl !== null) {

        this._baseService.doGET(this.categoryNextUrl).subscribe({
          next: (res: any) => {
            this.categoryNextUrl = res.next;
            this.filterItems.categories.allCategories = this.filterItems.categories.allCategories.concat(res.results);

            this.filterItems.categories.allCategories.forEach(element => {
              if (this.filterItems.categories.selectedItems.length > 0) {
                if (this.filterItems.categories.selectedItems.map(obj => obj.id).includes(element.id)) {
                  element.isChecked = true;
                }
              }
            });

          }, error: (err: HttpErrorResponse) => {}
        });
      }
    }
    if (e === 'Categories') {
      if (this.categoryNextUrl != '' && this.categoryNextUrl !== null) {

        this._baseService.doGET(this.categoryNextUrl).subscribe({
          next: (res: any) => {
            this.categoryNextUrl = res.next;
            this.recentJobCategoryItems.categories.allCategories = this.recentJobCategoryItems.categories.allCategories.concat(res.results);

            this.recentJobCategoryItems.categories.allCategories.forEach(element => {
              if (this.recentJobCategoryItems.categories.selectedItems.length > 0) {
                if (this.recentJobCategoryItems.categories.selectedItems.map(obj => obj.id).includes(element.id)) {
                  element.isChecked = true;
                }
              }
            });

          }, error: (err: HttpErrorResponse) => {}
        });
      }
    }
    if (e === 'Vendor') {
      if (this.vendorNextUrl != '' && this.vendorNextUrl !== null) {
        this._baseService.doGET(this.vendorNextUrl).subscribe({
          next: (res: any) => {
            res.results.forEach((element: any) => {
              element.name = element.company
            });
            this.vendorNextUrl = res.next;
            this.filterItems.vendors.allVendor = this.filterItems.vendors.allVendor.concat(res.results);

            this.filterItems.vendors.allVendor.forEach(element => {
              if (this.filterItems.vendors.selectedItems.length > 0) {
                if (this.filterItems.vendors.selectedItems.map(obj => obj.id).includes(element.id)) {
                  element.isChecked = true;
                }
              }
            });

          }, error: (err: HttpErrorResponse) => {}
        });
      }
    }

  }



  /**
   * Get all Recent Tenants
   */
  getAllRecentJob_Tenants(JobID: any) {
    let requestURL = environment.baseURL + this._Config.getAllTenants;
    requestURL = requestURL.replace('{ID}', JobID);

    this._baseService.doGET(requestURL).subscribe((response: any) => {
      response.results.forEach((element: any) => {
        element.isChecked = false;
        element.name = element.company;
      });
      
      this.recentTenantNextUrl = response.next;
      this.recentJobfilterItems.tenants.allTenant = response.results || [];
    },
      (error) => {});
  }


  /**
   * Get all Tenants
   */
  getFilter_Tenants(selectedActiveTab: any) {
    let requestURL = environment.baseURL + this._Config.getAllTenants;
    this._baseService.doGET(requestURL).subscribe((response: any) => {
      this.tenantNextUrl = response.next;
      response.results.forEach((element: any) => {
        element.name = element.company
      });
      this.filterItems.tenants.allTenant = response.results || [];

      // let indexVal = this.nestedDropdowns.findIndex(obj => obj.slug == 'Tenants');
      // this.nestedDropdowns[indexVal].list_data = this.filterItems.tenants.allTenant;
      // this.nestedDropdowns.forEach(item => {
      //   if (item.type == this.currentUserInfo.current_Role) {
      //     item.selectedItems = this.filterItems.tenants.selectedItems;
      //   }
      // });
      this.filterItems.tenants.allTenant.forEach(element => {
        if (selectedActiveTab.query) {
          if (selectedActiveTab.query?.tenants) {
            if (selectedActiveTab.query.tenants.includes(element.id)) {
              element.isChecked = true;
              // this.filterItems.tenants.selectedItems.push(element);

              var index = this.filterItems.tenants.selectedItems.findIndex(x => x.id == element.id);
              if (index === -1) {
                this.filterItems.tenants.selectedItems.push(element);
              }

            }
          }

        }
      });
    },
      (error) => {});
  }

  /**
   * Get all Recent Categories
   */
  getFilter_RecentCategories() {
    let requestURL = environment.baseURL + this._Config.getAllCategories;
    this._baseService.doGET(requestURL).subscribe((response: any) => {
      response.results.forEach((element: any) => {
        element.isChecked = false;
      });
      this.recentJobCategoryItems.categories.allCategories = response.results || [];
      this.categoryNextUrl = response.next;

    },
      (error) => {});
  }

  /**
   * Get all Categories
   */
  getFilter_Categories(selectedActiveTab: any) {
    let requestURL = environment.baseURL + this._Config.getAllCategories;
    this._baseService.doGET(requestURL).subscribe((response: any) => {

      this.filterItems.categories.allCategories = response.results || [];
      this.categoryNextUrl = response.next;

      this.filterItems.categories.allCategories.forEach(element => {

        if (selectedActiveTab.query) {
          if (selectedActiveTab.query?.categories) {
            if (selectedActiveTab.query.categories.includes(element.id)) {
              element.isChecked = true;
              // this.filterItems.categories.selectedItems.push(element);

              var index = this.filterItems.categories.selectedItems.findIndex(x => x.id == element.id);
              if (index === -1) {
                this.filterItems.categories.selectedItems.push(element);
              }
            }
          }
        }

      });

    },
      (error) => {});
  }

  /**
   * Get all Location
   */


  getFilter_Locations(Property_ID: any) {
    let requestURL = environment.baseURL + this._Config.getAllLocationUnitsByProperty;
    requestURL = requestURL.replace('{Property_ID}', Property_ID);


    this._baseService.doGET(requestURL).subscribe((response: any) => {

      this.filterItems.locations.allLocations = response || [];

    },
      (error) => {});
  }

  getAllFollowers(): void {
    let requestURL = environment.baseURL + this._Config.getAllFollowers;
    this._baseService.doGET(requestURL).subscribe((response: any) => {

      response.forEach((element: any) => {
        element.name = element.full_name;
      });
      this.bulkAction.followers = response || [];

    },
      (error) => {});
  }

  getAllVendors(selectedActiveTab: any): void {
    let requestURL = environment.baseURL + this._Config.getAllVendors;
    this._baseService.doGET(requestURL).subscribe((response: any) => {
      response.results.forEach((element: any) => {
        element.name = element.company
      });
      this.vendorNextUrl = response.next;
      this.filterItems.vendors.allVendor = response.results || [];
      if (this.filterItems.vendors.selectedItems.length > 0) {
        this.filterItems.vendors.allVendor.forEach((allItem: any) => {
          this.filterItems.vendors.selectedItems.map((selectedItem: any) => {
            if (allItem.id == selectedItem.id) {
              return allItem.isChecked = true;
            }
          });
        });
      }
      // Advanced filter save view we add the condition here
      this.filterItems.vendors.allVendor.forEach(element => {
        if (selectedActiveTab.query && selectedActiveTab.query.vendors) {
          if (selectedActiveTab.query.vendors.includes(element.id)) {
            element.isChecked = true;
            this.filterItems.vendors.selectedItems.push(element);
          }
        }
      });
    },
      (error) => {});
  }





  /**
   * Get Selected Filter Items
   * @param selectedItems
   */
  setSelectedFilterValues(event: any) {

    // this.filterPayload=this.selectedActiveTab.query

    this.filterPayload.stage = this.filterItems.status.selectedItems.map((obj: any) => obj.id);
    this.filterPayload.priority = this.filterItems.priority.selectedItems.map((obj: any) => obj.id);
    this.filterPayload.properties = this.filterItems.properties.selectedItems.map((obj: any) => obj.id);
    this.filterPayload.assignees = this.filterItems.managers.selectedItems.map((obj: any) => obj.id);
    this.filterPayload.engineers = this.filterItems.engineers.selectedItems.map((obj: any) => obj.id);
    this.filterPayload.tenants = this.filterItems.tenants.selectedItems.map((obj: any) => obj.id);
    this.filterPayload.categories = this.filterItems.categories.selectedItems.map((obj: any) => obj.id);

  }

  getAdvancedFilterList(selectedActiveTab: any): void {
    this._Config.isLoader = true;
    let requestURL = environment.baseURL + this._Config.getAdvancedFilterList;
    this._baseService.doGET(requestURL).subscribe({
      next: (response: any) => {
        this.advanceFilterList = response || [];
        this.getAdvanceFilterDetailedResponse(selectedActiveTab);
      },
      error: error => {
        this._Config.isLoader = false;
      },
      complete: () => {
        this._Config.isLoader = false;
      }
    });
  }

  getAdvanceFilterDetailedResponse(selectedActiveTab: any): void {
    this.advanceFilterList.map((list: any) => {
      if (list.name === 'Schedules') {
        this.getAdvancedFilterSchedules(list.id, selectedActiveTab);
      } else if (list.name === 'Vendor') {
        this.getAllVendors(selectedActiveTab);
      } else if (list.name === 'Actions') {
        this.getAdvancedFilterActions(list.id, selectedActiveTab);
      } else if (list.name === 'Service Type') {
        this.getAdvancedFilterServices(list.id, selectedActiveTab);
      } else if (list.name === 'Owner Group') {
        this.getAdvancedFilterOwner(selectedActiveTab);
      } else if (list.name === 'Billable Party') {
        this.getAdvancedFilterBillableParty(list.id, selectedActiveTab);
      } else if (list.name === 'Source Type') {
        this.getFilterSourceType(selectedActiveTab);
      } else if (list.name === 'Created By') {
        this.getFilterCreatedBy(selectedActiveTab);
      }
    });
  }

  getAdvancedFilterActions(id: any, selectedActiveTab: any): void {
    this._Config.isLoader = true;
    let requestURL = environment.baseURL + this._Config.getAdvanceFilterList;
    requestURL = requestURL.replace('{ID}', id);
    this._baseService.doGET(requestURL).subscribe({
      next: (response: any) => {
        this.filterItems.actions.allAction = response || [];

        if (this.filterItems.actions.selectedItems.length > 0) {
          this.filterItems.actions.allAction.forEach((allItem: any) => {
            this.filterItems.actions.selectedItems.map((selectedItem: any) => {
              if (allItem.id == selectedItem.id) {
                return allItem.isChecked = true;
              }
            });
          });
        }

        // Advanced filter save view we add the condition here
        this.filterItems.actions.allAction.forEach(element => {
          if (selectedActiveTab.query && selectedActiveTab.query.actions) {
            if (selectedActiveTab.query.actions.includes(element.name)) {
              element.isChecked = true;
              this.filterItems.actions.selectedItems.push(element);
            }
          }
        });
      },
      error: error => {
        this._Config.isLoader = false;
      },
      complete: () => {
        this._Config.isLoader = false;
      }
    });
  }

  getAdvancedFilterServices(id: any, selectedActiveTab: any): void {
    this._Config.isLoader = true;
    let requestURL = environment.baseURL + this._Config.serviceTypeDropdown;
    // requestURL = requestURL.replace('{ID}', id);
    this._baseService.doGET(requestURL).subscribe({
      next: (response: any) => {
        this.filterItems.services.allService = response || [];
        if (this.filterItems.services.selectedItems.length > 0) {
          this.filterItems.services.allService.forEach((allItem: any) => {
            this.filterItems.services.selectedItems.map((selectedItem: any) => {
              if (allItem.id == selectedItem.id) {
                return allItem.isChecked = true;
              }
            });
          });
        }

        // Advanced filter save view we add the condition here
        this.filterItems.services.allService.forEach(element => {
          if (selectedActiveTab.query && selectedActiveTab.query.service_type) {
            if (selectedActiveTab.query.service_type.includes(element.name)) {
              element.isChecked = true;
              this.filterItems.services.selectedItems.push(element);
            }
          }
        });
      },
      error: error => {
        this._Config.isLoader = false;
      },
      complete: () => {
        this._Config.isLoader = false;
      }
    });
  }

  getAdvancedFilterOwner(selectedActiveTab: any): void {
    this._Config.isLoader = true;
    let requestURL = environment.baseURL + this._Config.getAdvanceFilterOwnersList;
    this._baseService.doGET(requestURL).subscribe({
      next: (response: any) => {
        this.filterItems.owners.allOwners = response || [];
        if (this.filterItems.owners.selectedItems.length > 0) {
          this.filterItems.owners.allOwners.forEach((allItem: any) => {
            this.filterItems.owners.selectedItems.map((selectedItem: any) => {
              if (allItem.id == selectedItem.id) {
                return allItem.isChecked = true;
              }
            });
          });
        }

        // Advanced filter save view we add the condition here
        this.filterItems.owners.allOwners.forEach(element => {
          if (selectedActiveTab.query && selectedActiveTab.query.owners) {
            if (selectedActiveTab.query.owners.includes(element.id)) {
              element.isChecked = true;
              this.filterItems.owners.selectedItems.push(element);
            }
          }
        });
      },
      error: error => {
        this._Config.isLoader = false;
      },
      complete: () => {
        this._Config.isLoader = false;
      }
    });
  }

  getAdvancedFilterBillableParty(id: any, selectedActiveTab: any): void {
    this._Config.isLoader = true;
    let requestURL = environment.baseURL + this._Config.getAdvanceFilterList;
    requestURL = requestURL.replace('{ID}', id);
    this._baseService.doGET(requestURL).subscribe({
      next: (response: any) => {
        this.filterItems.billableParty.allBillableParty = response || [];
        if (this.filterItems.billableParty.selectedItems.length > 0) {
          this.filterItems.billableParty.allBillableParty.forEach((allItem: any) => {
            this.filterItems.billableParty.selectedItems.map((selectedItem: any) => {
              if (allItem.id == selectedItem.id) {
                return allItem.isChecked = true;
              }
            });
          });
        }

        // Advanced filter save view we add the condition here
        this.filterItems.billableParty.allBillableParty.forEach(element => {
          if (selectedActiveTab.query && selectedActiveTab.query.responsible) {
            if (selectedActiveTab.query.responsible.includes(element.value)) {
              element.isChecked = true;
              this.filterItems.billableParty.selectedItems.push(element);
            }
          }
        });
      },
      error: error => {
        this._Config.isLoader = false;
      },
      complete: () => {
        this._Config.isLoader = false;
      }
    });
  }

  getAdvancedFilterSchedules(id: any, selectedActiveTab: any): void {
    this._Config.isLoader = true;
    let requestURL = environment.baseURL + this._Config.getAdvanceFilterList;
    requestURL = requestURL.replace('{ID}', id);
    this._baseService.doGET(requestURL).subscribe({
      next: (response: any) => {
        // Schedule Response
        this.filterItems.schedules.allSchedule = response || [];
        // If any selected item there we'll check with all items and if both are matching we're changing the value "isChecked is true".
        if (this.filterItems.schedules.selectedItems.length > 0) {
          this.filterItems.schedules.allSchedule.forEach((allItem: any) => {
            this.filterItems.schedules.selectedItems.map((selectedItem: any) => {
              if (allItem.id == selectedItem.id) {
                return allItem.isChecked = true;
              } else {
                return allItem.isChecked = false;
              }
            });
          });
        }

        // If already Custom value there then we're changing the value "isChecked is true".
        if (this.filterItems.schedules.customPayload?.length > 0) {
          this.filterItems.schedules.allSchedule.forEach((item: any) => {
            if (item.name === 'Custom') {
              return item.isChecked = true;
            }
          });
        }
        // Schedule Save view condition added here
        this.filterItems.schedules.allSchedule.forEach(element => {
          // Save view condition for Timeline
          if (selectedActiveTab.query && selectedActiveTab.query.scheduled_dates?.timeline) {
            if (selectedActiveTab.query.scheduled_dates.timeline == element.value) {
              this.filterItems.schedules.selectedItems.push(element);
              this.filterItems.schedules.selectedItems = [...new Set(this.filterItems.schedules.selectedItems)];
              return element.isChecked = true;
            }
          } else if (selectedActiveTab.query && selectedActiveTab.query.scheduled_dates?.custom.length > 0) {
            if (element.name === 'Custom') {
              return element.isChecked = true;
            }
            this.filterItems.schedules.customPayload = selectedActiveTab.query.scheduled_dates?.custom;

            // In date picker module need to pre-populate the dates, that's why added this condition.
            const datePicker = selectedActiveTab.query.scheduled_dates.custom.map((date: any) => {
              return new Date(date);
            });
            this.filterItems.schedules.datePickerModel = datePicker;
          }
        });
        // Job cost(date approved) and Date response also we are getting only in this API,
        // once scheduling completed then only we get the Job cost and date response. That's why added set timeout.
        setTimeout(() => {
          // Job cost response
          // If already response is there we won't get the response from backend, so why added this condition.
          if (this.filterItems.jobCosts.dateApproved.selectedItems.length === 0) {
            this.filterItems.jobCosts.dateApproved.allDateApproved = response.filter((item: any) => item.job_cost === true) || [];
          } else {
            this.filterItems.jobCosts.dateApproved.allDateApproved = this.filterItems.jobCosts.dateApproved.allDateApproved;
          }

          // Date response
          // 1. created Response
          // If already response is there we won't get the response from backend, so why added this condition.
          if (this.filterItems.dates.created.selectedItems?.length > 0) {
            this.filterItems.dates.created.allDates = this.filterItems.dates.created.allDates;
          } else {
            this.filterItems.dates.created.allDates = response.filter((item: any) => item.job_cost === true) || [];
          }

          // 2. Last Activity Response
          // If already response is there we won't get the response from backend, so why added this condition.
          if (this.filterItems.dates.lastActivity.selectedItems?.length > 0) {
            this.filterItems.dates.lastActivity.allDates = this.filterItems.dates.lastActivity.allDates;
          } else {
            this.filterItems.dates.lastActivity.allDates = response.filter((item: any) => item.job_cost === true) || [];
          }

          // 2. Target Completion Response
          // If already response is there we won't get the response from backend, so why added this condition.
          if (this.filterItems.dates.targetCompletion.selectedItems?.length > 0) {
            this.filterItems.dates.targetCompletion.allDates = this.filterItems.dates.targetCompletion.allDates;
          } else {
            this.filterItems.dates.targetCompletion.allDates = response.filter((item: any) => item.job_cost === true) || [];
          }
          this.getAdvancedFilterCreateDate(selectedActiveTab);
          this.getAdvancedFilterLastActivityDate(selectedActiveTab);
          this.getAdvancedFilterTargetCompletionDate(selectedActiveTab);
          this.getAdvancedFilterJobCost(selectedActiveTab);
        }, 1000);
      },
      error: error => {
        this._Config.isLoader = false;
      },
      complete: () => {
        this._Config.isLoader = false;
      }
    });
  }

  getAdvancedFilterJobCost(selectedActiveTab: any): void {
    if (this.filterItems.jobCosts.selectedItems.length > 0) {
      this.filterItems.jobCosts.allJobCost.forEach((allItem: any) => {
        this.filterItems.jobCosts.selectedItems.map((selectedItem: any) => {
          if (allItem.name === selectedItem.name) {
            return allItem.isChecked = true;
          }
        });
      });
    }

    // Advanced filter save view we add the condition here
    this.filterItems.jobCosts.allJobCost.forEach(element => {
      // Job Cost type query
      if (selectedActiveTab.query && selectedActiveTab.query.job_costs.type) {
        if (selectedActiveTab.query.job_costs.type.includes(element.name)) {
          element.isChecked = true;
          this.filterItems.jobCosts.selectedItems.push(element);
          this.filterItems.jobCosts.selectedItems = [...new Set(this.filterItems.jobCosts.selectedItems)];
        }
      }
      // Job cost amount query
      if (selectedActiveTab.query && selectedActiveTab.query.job_costs.amount) {
        this.filterPayload.job_costs.amount = selectedActiveTab.query.job_costs.amount;
        this.jobCostAmount.fromAmount = this.filterPayload.job_costs.amount[0];
        this.jobCostAmount.toAmount = this.filterPayload.job_costs.amount[1];
      }
    });
    // Advanced filter Save view for Date Approved
    this.filterItems.jobCosts.dateApproved.allDateApproved.forEach(element => {
      // Save view condition for Date Approved (Timeline)
      if (selectedActiveTab.query && selectedActiveTab.query.job_costs?.date_approved.timeline) {
        if (selectedActiveTab.query.job_costs.date_approved.timeline == element.value) {
          element.isChecked = true;
          this.filterItems.jobCosts.dateApproved.selectedItems.push(element);
          this.filterItems.jobCosts.dateApproved.selectedItems = [...new Set(this.filterItems.jobCosts.dateApproved.selectedItems)];
        }
      }
      // Save view condition for Date Approved (Custom date range)
      if (selectedActiveTab.query && selectedActiveTab.query.job_costs.date_approved.custom.length > 0) {
        if (element.name === 'Custom') {
          element.isChecked = true;
        }
        this.filterItems.jobCosts.dateApproved.customPayload = selectedActiveTab.query.job_costs.date_approved.custom;

        // In date picker module need to pre-populate the dates, that's why added this condition.
        const datePicker = selectedActiveTab.query.job_costs.date_approved.custom.map((date: any) => {
          return new Date(date);
        });
        this.filterItems.jobCosts.dateApproved.datePickerModel = datePicker;
      }
    });
  }

  getAdvancedFilterCreateDate(selectedActiveTab: any): void {
    // 1. Date Created
    // If any selected item there we'll check with all items and if both are matching we're changing the value "isChecked is true"
    if (this.filterItems.dates.created.selectedItems?.length > 0) {
      this.filterItems.dates.created.allDates.map((allItem: any) => {
        this.filterItems.dates.created.selectedItems.map((selectedItem: any) => {
          if (allItem.id == selectedItem.id) {
            return allItem.isChecked = true;
          } else {
            return allItem.isChecked = false;
          }
        });
      });
    } else {
      this.filterItems.dates.created.allDates.forEach((item: any) => {
        return item.isChecked = false;
      });
    }

    // If custom value already selected then we change the custom value  "isChecked is true"
    if (this.filterItems.dates.created.customPayload?.length > 0) {
      this.filterItems.dates.created.allDates.forEach((item: any) => {
        if (item.name === 'Custom') {
          return item.isChecked = true;
        }
      });
    } else {
      this.filterItems.dates.created.allDates.forEach((item: any) => {
        if (item.name === 'Custom') {
          return item.isChecked = false;
        }
      });
    }
    // Date created Save view condition added here
    this.filterItems.dates.created.allDates.map(element => {
      // Save view condition for Timeline
      if (selectedActiveTab.query && selectedActiveTab.query.new_dates.created.timeline) {
        if (selectedActiveTab.query.new_dates.created.timeline === element.value) {
          element.isChecked = true;
          this.filterItems.dates.created.selectedItems = [];
          this.filterItems.dates.created.selectedItems.push(element);
        }
      }
      // Save view condition for Custom date range
      if (selectedActiveTab.query && selectedActiveTab.query.new_dates?.created.custom.length > 0) {
        if (element.name === 'Custom') {
          element.isChecked = true;
        }
        this.filterItems.dates.created.customPayload = selectedActiveTab.query.new_dates.created.custom;

        // In date picker module need to pre-populate the dates, that's why added this condition.
        const datePicker = selectedActiveTab.query.new_dates.created.custom.map((date: any) => {
          return new Date(date);
        });
        this.filterItems.dates.created.datePickerModel = datePicker;
      }
    });
  }

  getAdvancedFilterLastActivityDate(selectedActiveTab: any): void {
    // 1. Last activity
    // If any selected item there we'll check with all items and if both are matching we're changing the value "isChecked is true"
    if (this.filterItems.dates.lastActivity.selectedItems?.length > 0) {
      this.filterItems.dates.lastActivity.allDates.map((allItem: any) => {
        this.filterItems.dates.lastActivity.selectedItems.map((selectedItem: any) => {
          if (allItem.id == selectedItem.id) {
            return allItem.isChecked = true;
          } else {
            return allItem.isChecked = false;
          }
        });
      });
    } else {
      this.filterItems.dates.lastActivity.allDates.forEach((item: any) => {
        return item.isChecked = false;
      });
    }

    // If custom value already selected then we change the custom value  "isChecked is true"
    if (this.filterItems.dates.lastActivity.customPayload?.length > 0) {
      this.filterItems.dates.lastActivity.allDates.forEach((item: any) => {
        if (item.name === 'Custom') {
          return item.isChecked = true;
        }
      });
    } else {
      this.filterItems.dates.lastActivity.allDates.forEach((item: any) => {
        if (item.name === 'Custom') {
          return item.isChecked = false;
        }
      });
    }

    // Date created Save view condition added here
    this.filterItems.dates.lastActivity.allDates.map(element => {
      // Save view condition for Timeline
      if (selectedActiveTab.query && selectedActiveTab.query.new_dates.last_activity.timeline) {
        if (selectedActiveTab.query.new_dates.last_activity.timeline === element.value) {
          element.isChecked = true;
          this.filterItems.dates.lastActivity.selectedItems = [];
          this.filterItems.dates.lastActivity.selectedItems.push(element);
        }
      }
      // Save view condition for Custom date range
      if (selectedActiveTab.query && selectedActiveTab.query.new_dates?.last_activity.custom.length > 0) {
        if (element.name === 'Custom') {
          element.isChecked = true;
        }
        this.filterItems.dates.lastActivity.customPayload = selectedActiveTab.query.new_dates.last_activity.custom;

        // In date picker module need to pre-populate the dates, that's why added this condition.
        const datePicker = selectedActiveTab.query.new_dates.last_activity.custom.map((date: any) => {
          return new Date(date);
        });
        this.filterItems.dates.lastActivity.datePickerModel = datePicker;
      }
    });
  }

  getAdvancedFilterTargetCompletionDate(selectedActiveTab: any): void {
    // 1. Last activity
    // If any selected item there we'll check with all items and if both are matching we're changing the value "isChecked is true"
    if (this.filterItems.dates.targetCompletion.selectedItems?.length > 0) {
      this.filterItems.dates.targetCompletion.allDates.map((allItem: any) => {
        this.filterItems.dates.targetCompletion.selectedItems.map((selectedItem: any) => {
          if (allItem.id == selectedItem.id) {
            return allItem.isChecked = true;
          } else {
            return allItem.isChecked = false;
          }
        });
      });
    } else {
      this.filterItems.dates.targetCompletion.allDates.forEach((item: any) => {
        return item.isChecked = false;
      });
    }

    // If custom value already selected then we change the custom value  "isChecked is true"
    if (this.filterItems.dates.targetCompletion.customPayload?.length > 0) {
      this.filterItems.dates.targetCompletion.allDates.forEach((item: any) => {
        if (item.name === 'Custom') {
          return item.isChecked = true;
        }
      });
    } else {
      this.filterItems.dates.targetCompletion.allDates.forEach((item: any) => {
        if (item.name === 'Custom') {
          return item.isChecked = false;
        }
      });
    }

    // Date created Save view condition added here
    this.filterItems.dates.targetCompletion.allDates.map(element => {
      // Save view condition for Timeline
      if (selectedActiveTab.query && selectedActiveTab.query.new_dates.target_completion.timeline) {
        if (selectedActiveTab.query.new_dates.target_completion.timeline === element.value) {
          element.isChecked = true;
          this.filterItems.dates.targetCompletion.selectedItems = [];
          this.filterItems.dates.targetCompletion.selectedItems.push(element);
        }
      }
      // Save view condition for Custom date range
      if (selectedActiveTab.query && selectedActiveTab.query.new_dates?.target_completion.custom.length > 0) {
        if (element.name === 'Custom') {
          element.isChecked = true;
        }
        this.filterItems.dates.targetCompletion.customPayload = selectedActiveTab.query.new_dates.target_completion.custom;

        // In date picker module need to pre-populate the dates, that's why added this condition.
        const datePicker = selectedActiveTab.query.new_dates.target_completion.custom.map((date: any) => {
          return new Date(date);
        });
        this.filterItems.dates.targetCompletion.datePickerModel = datePicker;
      }
    });
  }

  getFilterSourceType(selectedActiveTab: any): void {
    // Advanced filter save view we add the condition here
    this.filterItems.source.Source.forEach(element => {
      if (selectedActiveTab.query && selectedActiveTab.query.source) {
        if (selectedActiveTab.query.source.includes(element.name)) {
          element.isChecked = true;
          this.filterItems.source.selectedItems.push(element);
        }
      }
    });
  }

  getFilterCreatedBy(selectedActiveTab: any): void {
    this._Config.isLoader = true;
    let requestURL = environment.baseURL + this._Config.getAllCreatedBy;
    this._baseService.doGET(requestURL).subscribe({
      next: (response: any) => {
        this.filterItems.createdBy.allCreatedByManagers = response.filter((user: any) => {
          return user.type.includes('Manager');
        });
        this.filterItems.createdBy.allCreatedByEngineers = response.filter((user: any) => {
          return user.type.includes('Engineer');
        });
        this.filterItems.createdBy.allCreatedByTenants = response.filter((user: any) => {
          return user.type.includes('Member');
        });
        // Checking if already selected items is there or not.
        // If there is selected item then we checked the checkbox is true.
        if (this.filterItems.createdBy.selectedItems?.length > 0) {
          this.filterItems.createdBy.allCreatedByManagers.map((allItem: any) => {
            this.filterItems.createdBy.selectedItems.map((selectedItem: any) => {
              if (allItem.id == selectedItem.id) {
                return allItem.isChecked = true;
              }
            });
          });
          this.filterItems.createdBy.allCreatedByEngineers.map((allItem: any) => {
            this.filterItems.createdBy.selectedItems.map((selectedItem: any) => {
              if (allItem.id == selectedItem.id) {
                return allItem.isChecked = true;
              }
            });
          });
          this.filterItems.createdBy.allCreatedByTenants.map((allItem: any) => {
            this.filterItems.createdBy.selectedItems.map((selectedItem: any) => {
              if (allItem.id == selectedItem.id) {
                return allItem.isChecked = true;
              }
            });
          });
        } else {
          this.filterItems.createdBy.allCreatedByManagers.forEach((item: any) => {
            return item.isChecked = false;
          });
          this.filterItems.createdBy.allCreatedByEngineers.forEach((item: any) => {
            return item.isChecked = false;
          });
          this.filterItems.createdBy.allCreatedByTenants.forEach((item: any) => {
            return item.isChecked = false;
          });
        }
        this.filterItems.createdBy.selectedItems = [...new Set(this.filterItems.createdBy.selectedItems)];

        // Checking the query in save view if already selected items is there or not.
        // If there is value then we include the selected items.
        this.filterItems.createdBy.allCreatedByManagers.forEach(element => {
          if (selectedActiveTab.query && selectedActiveTab.query.created_by) {
            if (selectedActiveTab.query.created_by.includes(element.id)) {
              element.isChecked = true;
              this.filterItems.createdBy.selectedItems.push(element);
            }
          }
        });
        this.filterItems.createdBy.allCreatedByEngineers.forEach(element => {
          if (selectedActiveTab.query && selectedActiveTab.query.created_by) {
            if (selectedActiveTab.query.created_by.includes(element.id)) {
              element.isChecked = true;
              this.filterItems.createdBy.selectedItems.push(element);
            }
          }
        });
        this.filterItems.createdBy.allCreatedByTenants.forEach(element => {
          if (selectedActiveTab.query && selectedActiveTab.query.created_by) {
            if (selectedActiveTab.query.created_by.includes(element.id)) {
              element.isChecked = true;
              this.filterItems.createdBy.selectedItems.push(element);
            }
          }
        });
        this.filterItems.createdBy.selectedItems = [...new Set(this.filterItems.createdBy.selectedItems)];
        const managerIndex = this.createdByDropdowns.findIndex(obj => obj.slug === 'Managers_CreatedBy');
        const engineerIndex = this.createdByDropdowns.findIndex(obj => obj.slug === 'Engineers_CreatedBy');
        const tenantIndex = this.createdByDropdowns.findIndex(obj => obj.slug === 'Tenants_CreatedBy');
        this.createdByDropdowns[managerIndex].list_data = this.filterItems.createdBy.allCreatedByManagers;
        this.createdByDropdowns[engineerIndex].list_data = this.filterItems.createdBy.allCreatedByEngineers;
        this.createdByDropdowns[tenantIndex].list_data = this.filterItems.createdBy.allCreatedByTenants;
        this.createdByDropdowns.forEach(item => {
          if (this.filterItems.createdBy.selectedItems.length > 0) {
            item.selectedItems = this.filterItems.createdBy.selectedItems;
            this.isRefreshSelectedFilters.next(item.selectedItems);
          }
        });
      },
      error: error => {
        this._Config.isLoader = false;
      },
      complete: () => {
        this._Config.isLoader = false;
      }
    });
  }

}
