// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.on-top {
  vertical-align: top;
  margin-top: 4px;
}

.width_100 {
  width: 100%;
}

.hide-not-found ::ng-deep .ng-dropdown-panel {
  visibility: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

::ng-deep .max-width {
  max-width: 600px !important;
}

.ng-select.ng-select-disabled .ng-select-container .ng-placeholder {
  color: rgba(0, 0, 0, 0.38) !important;
}

.ng-select.ng-select-disabled {
  opacity: 0.6;
  pointer-events: none;
}

.ng-select.ng-select-disabled .ng-select-container {
  background-color: #f5f5f5;
  border-color: #e0e0e0;
}

.ng-select.ng-select-disabled .ng-value {
  color: rgba(0, 0, 0, 0.38);
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_reusable_ui/_controls/dropdown/dropdown.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,eAAA;AACF;;AAGA;EACE,WAAA;AAAF;;AAIE;EACE,kBAAA;AADJ;;AAKA;EACE,uBAAA;EACA,mBAAA;EACA,gBAAA;AAFF;;AAKA;EACE,2BAAA;AAFF;;AAKA;EACE,qCAAA;AAFF;;AAKA;EACE,YAAA;EACA,oBAAA;AAFF;;AAKA;EACE,yBAAA;EACA,qBAAA;AAFF;;AAKA;EACE,0BAAA;AAFF","sourcesContent":[".on-top {\n  vertical-align: top;\n  margin-top: 4px;\n}\n\n\n.width_100{\n  width: 100%;\n}\n\n.hide-not-found {\n  ::ng-deep .ng-dropdown-panel {\n    visibility: hidden;\n  }\n}\n\n.text-ellipsis {\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n\n::ng-deep .max-width {\n  max-width: 600px !important;\n}\n\n.ng-select.ng-select-disabled .ng-select-container .ng-placeholder {\n  color: rgba(0, 0, 0, 0.38) !important;\n}\n\n.ng-select.ng-select-disabled {\n  opacity: 0.6;\n  pointer-events: none;\n}\n\n.ng-select.ng-select-disabled .ng-select-container {\n  background-color: #f5f5f5;\n  border-color: #e0e0e0;\n}\n\n.ng-select.ng-select-disabled .ng-value {\n  color: rgba(0, 0, 0, 0.38);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
