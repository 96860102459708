// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .angular-editor .angular-editor-wrapper .angular-editor-textarea {
  word-break: break-word !important;
  border: 1px solid #e6e8f0 !important;
  border-radius: 4px !important;
  resize: none !important;
  margin-top: 0px !important;
}
::ng-deep .angular-editor-toolbar {
  background: none !important;
  border: 1px solid #e6e8f0 !important;
  border-radius: 4px !important;
}
::ng-deep .angular-editor-toolbar .angular-editor-toolbar-set .angular-editor-button {
  border: none !important;
}

.danger-zone {
  color: red;
  font-size: 17px;
  font-weight: bold;
}

.danger-zone-label {
  color: red;
  padding-bottom: 10px;
}

.delete-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  border: none !important;
  border-radius: 5px;
  background: red;
  color: white;
}
.delete-btn:hover {
  background: red;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_views/_tab_components/tab-inbox-inboxes/tab-inbox-inboxes.component.scss"],"names":[],"mappings":"AAME;EACE,iCAAA;EACA,oCAAA;EACA,6BAAA;EACA,uBAAA;EACA,0BAAA;AALJ;AAQE;EACE,2BAAA;EACA,oCAAA;EACA,6BAAA;AANJ;AASE;EACE,uBAAA;AAPJ;;AAWA;EACE,UAAA;EACA,eAAA;EACA,iBAAA;AARF;;AAWA;EACE,UAAA;EACA,oBAAA;AARF;;AAWA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;AARF;AAUE;EACE,eAAA;EACA,YAAA;AARJ","sourcesContent":["::ng-deep .ql-container.ql-snow {\n  // min-height: 272px !important;\n}\n\n// Don't change these css for any modules because based on the inbox module we updated the css here\n::ng-deep {\n  .angular-editor .angular-editor-wrapper .angular-editor-textarea {\n    word-break: break-word !important;\n    border: 1px solid #e6e8f0 !important;\n    border-radius: 4px !important;\n    resize: none !important;\n    margin-top: 0px !important;\n  }\n\n  .angular-editor-toolbar {\n    background: none !important;\n    border: 1px solid #e6e8f0 !important;\n    border-radius: 4px !important;\n  }\n\n  .angular-editor-toolbar .angular-editor-toolbar-set .angular-editor-button {\n    border: none !important;\n  }\n}\n\n.danger-zone {\n  color: red;\n  font-size: 17px;\n  font-weight: bold;\n}\n\n.danger-zone-label {\n  color: red;\n  padding-bottom: 10px;\n}\n\n.delete-btn{\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 7px;\n  border: none !important;\n  border-radius: 5px;\n  background: red;\n  color: white;\n\n  &:hover{\n    background: red;\n    color: white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
