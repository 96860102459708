import { Component, EventEmitter, forwardRef, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../_components/button/button.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { QuillModule } from 'ngx-quill';
import { NgClass, NgIf } from '@angular/common';
import 'quill-mention';
import 'quill-emoji';


@Component({
    selector: 'fyxt-editor',
    templateUrl: './text-editor.component.html',
    styleUrls: ['./text-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => TextEditorComponent)
        }
    ],
    standalone: true,
    imports: [NgClass, FormsModule, NgxIntlTelInputModule, NgIf, ButtonComponent, MatIconModule,QuillModule]
})
export class TextEditorComponent implements OnInit, OnChanges {
  @Output() change = new EventEmitter<Event>();
  @Output() save = new EventEmitter<Boolean>();
  @Output() mentionsChange = new EventEmitter();
  @Output() onFocusOut = new EventEmitter<Event>();
  @Input() content: any;
  @Input() showSendButton: boolean = true;
  @Input() toolbarState: boolean;
  @Input() notesDisabled: boolean = false;
  @Input() placeholder: string = 'Type here...';
  @Input() isComment: boolean = false;


  //Quil Editor Config
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],            // toggled buttons
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],                 // outdent/indent
        [{ direction: 'rtl' }],                               // text direction
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        // [{ color: [] }, { background: [] }],                  // dropdown with defaults from theme
        [{ font: [] }],
        // [{ align: [] }],
        ['clean']                                              // remove formatting button
        // ['link', 'image', 'video'],                         // link and image, video
        // ['emoji'],
      ]
    },
    'emoji-toolbar': true,
    'emoji-textarea': false,
    'emoji-shortname': true,
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: (range: any, context: any) => {
            return true;
          }
        }
      }
    }
  };

  @Input() quillCon: any = this.quillConfig;
  @Input() isEXV: boolean = false;

  // @Input() set isBasicToolbar(val: string) {
  //   if (val) {

  //     this.quillConfig = {
  //       toolbar: {
  //         container: [
  //           ['bold', 'italic', 'underline', 'strike'],            // toggled buttons
  //           [{ list: 'ordered' }, { list: 'bullet' }],
  //         ]
  //       },
  //       'emoji-toolbar': false,
  //       'emoji-textarea': false,
  //       'emoji-shortname': false,
  //       keyboard: {
  //         bindings: {
  //           enter: {
  //             key: 13,
  //             handler: (range: any, context: any) => {
  //               return true;
  //             }
  //           }
  //         }
  //       }
  //     };
  //   }
  // };

  textEditorForm: any = {
    input: '',
  };

  input: any;

  onChange = (data: any) => { }
  onTouch = (_: any) => { }

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.toolbarState) {
      this.quillCon.toolbar = changes.toolbarState.currentValue;
    }
    // else  if (changes.isBasicToolbar) {
    //   this.quillCon=this.quillConfig;
    // }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: string) {
    this.input = value;
    this.onChange(value);
  }

  /**
  *Func triggered on input change
  */
  onchange(event: any) {
    this.onChange(event);
    this.change.emit(event);
  }

  /**
 * save view Trigger
 */
  saveView() {
    this.save.emit(this.content);
    this.content = ''

    // alert(JSON.stringify(this.textEditorForm));

  }

  /** Function triggered on copy something in quill-editor */
  onCopy(event: ClipboardEvent) {
    const selectedText = window.getSelection()?.toString();
    if (selectedText) {
      event.preventDefault();
      event.clipboardData?.setData('text/plain', selectedText); // You can use the copied text as needed, such as assigning it to a variable or doing other things.
    }
  }

  onTextChange(event: any) {
    const mentions = this.extractDataIds(event.html);
      setTimeout(() => {
        this.mentionsChange.emit(mentions);
      }, 200);
  }

  extractDataIds(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const mentions = doc.querySelectorAll('.mention');
    const dataIds = Array.from(mentions).map(mention => mention.getAttribute('data-id'));
    return dataIds;
  } 

  focusOutInput() {
    this.onFocusOut.emit(this.content);
  }

}
