import { Component, Input, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';
import { MatListModule } from '@angular/material/list';

@Component({
    selector: 'fyxt-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    standalone: true,
    imports: [MatListModule, NgClass]
})
export class ListComponent implements OnInit {

  @Input() customClass!: string;
  @Input() type!: string;
  constructor() { }

  ngOnInit(): void {
  }

}
