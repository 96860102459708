// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img.mat-mdc-card-lg-image {
  width: 348px !important;
  height: 259px !important;
}

.img.mat-mdc-card-lg-image {
  width: 348px !important;
  height: 259px !important;
}

.google-map {
  height: 300px;
  display: block;
  width: 457px;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_views/_popup_views/set-location/set-location.component.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,wBAAA;AACJ;;AACA;EACE,uBAAA;EACA,wBAAA;AAEF;;AACA;EACE,aAAA;EACA,cAAA;EACA,YAAA;AAEF","sourcesContent":[".img.mat-mdc-card-lg-image{\n    width: 348px !important;\n    height: 259px !important;\n}\n.img.mat-mdc-card-lg-image{\n  width: 348px !important;\n  height: 259px !important;\n}\n\n.google-map {\n  height: 300px;\n  display: block;\n  width: 457px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
