import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable} from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TenantPortalJobsService {
  _viewModule: string = "tenant-portal-jobs";
  public scheduleTabRefresh = new BehaviorSubject<boolean>(false);
  public goBackToLandingPage = new BehaviorSubject<boolean>(false);


  constructor(private httpClient: HttpClient) { }

  getTenantPortalJosData(selectedViewTableRows: string) {
    return this.httpClient.get(selectedViewTableRows);

  }

  getContactsTableDataRows(selectedJobs: string) {
    return this.httpClient.get(selectedJobs);
  }

  getScheduleTableDataRows(selectedJobsSchedule: string) {
    return this.httpClient.get(selectedJobsSchedule);
  }

  getJobContactsTableDataRows(url: string,body:any) {
    return this.httpClient.post(url,body);
  }

  doPost(url: string,body:any) {
    return this.httpClient.post(url,body);
  }

  fetchScheduleTabRefresh(): Observable<boolean> {
    return this.scheduleTabRefresh.asObservable();
  }

  fetchTenantPortalLandingPage(): Observable<boolean> {
    return this.goBackToLandingPage.asObservable();
  }
}

