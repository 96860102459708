import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  FolderEntityMapper,
  GetCompanyTypePayloadMapper
} from '../file-manager.const';
import { Observable } from 'rxjs';
import { IGetCOIsListResponse } from 'src/app/@fyxt/_shared/models/coi/coi.interface';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {
  private subDomain: string;
  public showFileBrowser: boolean = false;
  public fileBrowserAvailableList = [
    'web',
    'krishna',
    'postal',
    'internaltest',
    'livedemo'
  ];
  public globalJobSettings = null;

  constructor(private http: HttpClient) {
    let url = window.location.origin;
    this.subDomain = this.extractDomain(url).split('.')[0];
    this.subDomain == 'localhost' ? (this.subDomain = 'web') : this.subDomain;
    this.showFileBrowser = this.fileBrowserAvailableList.includes(
      this.subDomain
    );
  }

  extractDomain(url: string): any {
    let domain;
    if (url.indexOf('://') > -1) {
      domain = url.split('/')[2];
    } else {
      domain = url.split('/')[0];
    }
    domain = domain.split(':')[0];
    return domain;
  }

  browseFile(path: string) {
    return this.http.get(environment.files.http + 'browse/' + `?path=${path}`, {
      headers: { 'x-tenant-id': this.subDomain }
    });
  }

  formatBrowserData(data) {
    return data.map((f) => {
      return {
        ...f,
        image: 'assets/images/svg/folder.svg',
        type: 'FOLDER',
        title: f.name,
        default: true
      };
    });
  }

  formatPropertyData(data) {
    return data.map((f) => {
      return {
        ...f,
        image: 'assets/images/svg/folder.svg',
        type: 'FOLDER',
        path: '/properties/' + f.id,
        identifier: 'SINGLE-PROPERTY',
        title: f.name,
        default: true,
        createdBy: f?.created_by_name?.trim(),
        lastActivity: f?.modified_at,
        createdAt: f?.created_at
      };
    });
  }

  formatCompanyData(data) {
    return data.map((f) => {
      return {
        ...f,
        image: 'assets/images/svg/folder.svg',
        type: 'FOLDER',
        path: '/companies/' + f.id,
        identifier: 'SINGLE-COMPANY',
        title: f.name,
        lastActivity: f?.modified_at,
        createdBy: f?.created_by_fullname,
        createdAt: f.created_at,
        default: true
      };
    });
  }

  formatJobData(data) {
    return data.map((f) => {
      return {
        ...f,
        image: 'assets/images/svg/folder.svg',
        type: 'FOLDER',
        path: '/jobs/' + f.id,
        name: `Job ID:  ${f.id}`,
        identifier: 'SINGLE-COMPANY',
        title: f.id,
        createdBy: f?.created_by,
        lastActivity: f?.modified_at,
        createdAt: f?.created_at,
        default: true
      };
    });
  }

  formatLeaseData(data) {
    return data.map((f) => {
      return {
        ...f,
        image: 'assets/images/svg/folder.svg',
        type: 'FOLDER',
        path: '/leases/' + f.id,
        name: `Lease ID:  ${f.id}`,
        identifier: 'SINGLE-COMPANY',
        title: f.id,
        default: true
      };
    });
  }

  formatFiles(data, currentPath) {
    return data.map((f) => {
      return {
        ...f,
        image:
          f.type == 'FOLDER'
            ? 'assets/images/svg/folder.svg'
            : f.previewUrl
            ? environment.files.http + f.previewUrl.slice(1)
            : this.fileIcon(f.mimeType),
        name: f.type == 'FOLDER' ? f.title : f.filename,
        path:
          f.type == 'FOLDER'
            ? currentPath + '/' + this.getFolderName(f.name)
            : null,
        lastActivity: f?.lastActivityAt,
        createdBy: f?.user?.first_name + f?.user?.last_name,
        createdAt: f.createdAt,
        default: false
      };
    });
  }

  formatCOIData(data) {
    return data.map((f) => {
      return {
        ...f,
        name: f.title,
        image: 'assets/images/svg/folder.svg',
        type: 'FOLDER',
        path: '/coi/' + f.id,
        identifier: 'COI',
        default: true
      };
    });
  }

  getAllPoperty(search, page) {
    let url = `${environment.LocalDev_URL_V2}properties/filter-dropdown/?source=document-manager&page=${page ?? 1}`;

    if (search) {
      url = url + `&search=${search}`;
    }

    return this.http.get(url);
  }

  getAllCompany(path, query, companytype = '', page) {
    const type = path.split('/');

    let url = `${
      environment.fastAPI_Actual_URL
    }/companies/?columns=["id","name","modified_at","created_at","created_by_fullname"]&page=${page}&size=25&sort=name&order=asc`;

    if (companytype) {
      url = url + `&query={"type":["${companytype}"]}`
    }

    if (query) {
      url = url + `&search=${query}`;
    }

    return this.http.get(url);
  }

  getAllPropertyJobs(property, currentPath, search, page) {
    let newActiveColumns = ['id', 'modified_at', 'created_at', 'created_by'];

    // id : '4eef59b1-7035-41a0-aeee-e9e8f2e45282';

    let postBody: any = {
      viewid: null,
      size: 25,
      columns: newActiveColumns,
      query: {
        properties: [{ id: this.getPropertyId(currentPath), name: '' }]
      },
      sort: 'created_at',
      order: 'desc',
      page: page
    };

    if (search) {
      postBody['search'] = search;
    }

    return this.http.post(
      `${environment.LocalDev_URL_V2}user-view/job/`,
      postBody
    );
  }

  getAllPropertyLeases(propertyId, search, page) {
    let payload = {
      filters: {
        properties: [propertyId]
      },
      page: page
    };

    if (search) {
      payload['search'] = search;
    }

    return this.http.post(environment.lease_URL + `/leases/property`, payload);
  }

  getPropertyId(path) {
    const match = path.match(/\/properties\/([a-zA-Z0-9-]+)/);
    return match ? match[1] : null;
  }

  isUrlNative(url) {
    // Define the patterns to match
    const patterns = [
      /^\/properties$/,
      /^\/properties\/[0-9a-fA-F-]{36}\/jobs$/,
      /^\/properties\/[0-9a-fA-F-]{36}\/leases$/,
      /^\/companies$/,
      /^\/companies\/tenants$/,
      /^\/companies\/vendors$/,
      /^\/companies\/[0-9a-fA-F-]{36}\/coi$/,
      /^\/properties\/[0-9a-fA-F-]{36}\/jobs\/[0-9]+\/Invoices$/,
      /^\/properties\/[0-9a-fA-F-]{36}\/jobs\/[0-9]+\/Bids$/,
      /^\/properties\/[0-9a-fA-F-]{36}\/jobs\/[0-9]+\/Change Order$/,
      /^\/properties\/[0-9a-fA-F-]{36}\/jobs\/[0-9]+\/Equipment$/,
      /^\/properties\/[0-9a-fA-F-]{36}\/jobs\/[0-9]+\/Bids\/[0-9a-fA-F-]{36}$/,
      /^\/properties\/[0-9a-fA-F-]{36}\/jobs\/[0-9]+\/Change Order\/[0-9a-fA-F-]{36}$/,
      /^\/properties\/[0-9a-fA-F-]{36}\/jobs\/[0-9]+\/Invoices\/[0-9a-fA-F-]{36}$/
    ];

    for (const pattern of patterns) {
      if (pattern.test(url)) {
        return false;
      }
    }

    return true;
  }

  createNextURL(path) {
    const patterns = [
      /^\/properties\/[0-9a-fA-F-]{36}\/jobs\/[0-9]+\/Bids\/[0-9a-fA-F-]{36}$/,
      /^\/properties\/[0-9a-fA-F-]{36}\/jobs\/[0-9]+\/Change Order\/[0-9a-fA-F-]{36}$/,
      /^\/properties\/[0-9a-fA-F-]{36}\/jobs\/[0-9]+\/Invoices\/[0-9a-fA-F-]{36}$/
    ];

    for (const pattern of patterns) {
      if (pattern.test(path)) {
        return 'job-cost';
      }
    }

    const segments = path.split('/').filter(Boolean);
    if (segments.length === 0) return null;
    const lastSegment = segments.pop();
    if (lastSegment.startsWith(':')) {
      return segments.length > 0 ? segments.pop() : null;
    }
    return lastSegment;
  }

  constructCurrentPathOnFolderClick(arr: string[], n: number) {
    if (n < 0 || n >= arr.length) {
      throw new Error('Invalid index');
    }
    return arr.slice(0, n + 1).join('/');
  }

  createNewFolder(payload) {
    return this.http.post(`${environment.files.http}browse/folders`, payload, {
      headers: { 'x-tenant-id': this.subDomain }
    });
  }

  constructCreateFolderPayload(url) {
    const mainPaths = [
      'properties',
      'leases',
      'jobs',
      'coi',
      'Equipment',
      'Bids',
      'Invoices',
      'Change Order',
      'companies'
    ];
    const parts = url.split('/');

    const reversedParts = parts.reverse();
    let entityType = null;
    let entityId = null;
    let path = null;

    for (let i = 0; i < reversedParts.length; i++) {
      if (mainPaths.includes(reversedParts[i])) {
        entityType = reversedParts[i];
        entityId = i == 0 ? reversedParts[i + 1] : reversedParts[i - 1];
        path = this.constructPathForFolderCreationAPI(reversedParts, i);
        break;
      }
    }

    return {
      entityId: entityId,
      entityType: FolderEntityMapper[entityType],
      path: path
    };
  }

  searchByName(data, searchTerm) {
    if (!searchTerm) {
      return data;
    }
    return data.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  fileIcon(type: string, ...args: unknown[]): unknown {
    const doc = [
      'msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword',
      'doc',
      'docx'
    ];

    const pdf = ['application/pdf', 'pdf'];

    const tiff = ['image/tiff', 'tiff'];

    const txt = ['txt', 'text/plain'];

    const excel = [
      'vnd.ms-excel',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'xlsx',
      'xlsm',
      'xls'
    ];

    const csv = ['csv', 'text/csv'];

    const odf = [
      'vnd.oasis.opendocument.text',
      'application/vnd.oasis.opendocument.text'
    ];

    switch (true) {
      case pdf.includes(type):
        return 'assets/@fyxt/svgs/iconsDocuments/PDF.svg';
      case doc.includes(type):
        return 'assets/@fyxt/svgs/iconsDocuments/DOC.svg';
      case tiff.includes(type):
        return 'assets/@fyxt/svgs/iconsDocuments/TIFF.svg';
      case txt.includes(type):
        return 'assets/@fyxt/svgs/iconsDocuments/TXT.svg';
      case excel.includes(type):
        return 'assets/@fyxt/svgs/iconsDocuments/XLS.svg';
      case csv.includes(type):
        return 'assets/@fyxt/svgs/iconsDocuments/CSV.svg';
      case odf.includes(type):
        return 'assets/@fyxt/svgs/iconsDocuments/ODF.svg';
      case ['audio', 'mpga', 'mp3', 'audio/mpeg'].includes(type):
        return 'assets/@fyxt/svgs/iconsDocuments/MP3.png';
      case ['mp4', 'avi', 'mpeg', 'webm'].includes(type):
        return 'assets/@fyxt/svgs/iconsDocuments/MP3.png';
      default:
        return 'assets/@fyxt/svgs/iconsDocuments/JPG.svg';
    }
  }

  getFolderName(name) {
    const folderArray = name.split('/');
    return folderArray[folderArray.length - 2];
  }

  constructPathForFolderCreationAPI(path, index) {
    let slicedArray = path.slice(0, index);
    slicedArray.pop();

    if (slicedArray.length == 0) {
      return '/';
    }
    return '/' + slicedArray.reverse().join('/') + '/';
  }

  public getAllCOI(path, query): Observable<IGetCOIsListResponse> {
    const url = `${environment.coiMsURL}/coi/tabs`;
    const params = { take: 50, page: 1 };
    params['companies'] = [this.getCOIsId(path)];

    if (query) {
      params['search'] = query;
    }

    return this.http
      .post<{ data: IGetCOIsListResponse }>(url, params)
      .pipe(map((res) => res.data));
  }

  getCOIsId(url) {
    const match = url.match(
      /^\/companies\/([0-9a-fA-F-]{36})\/coi$/
    );
    if (match) {
      return match[1]; 
    }
    return null;
  }

  canUploadFiles(path) {
    const jobCostPatter = [
      /^\/properties\/[a-f0-9-]+\/jobs\/\d+\/Bids\/[a-f0-9-]+$/,
      /^\/properties\/[a-f0-9-]+\/jobs\/\d+\/Change Order\/[a-f0-9-]+$/,
      /^\/properties\/[a-f0-9-]+\/jobs\/\d+\/Invoices\/[a-f0-9-]+$/
    ];

    if (path == '/' || path == '/companies') {
      return false;
    }

    for (const p of jobCostPatter) {
      if (p.test(path)) {
        return true;
      }
    }

    return this.isUrlNative(path);
  }

  getNewUrlOnBackButton(url) {
    const fullUrl = new URL(url, 'https://fyxt.com');
    const params = new URLSearchParams(fullUrl.search);
    const path = params.get('path') ?? '/';
    const ufp = params.get('ufp') ?? '/';
    return { path, ufp };
  }

  getJobIdFromUrl(url) {
    const regex = /\/jobs\/(\d+)(?:\/|$)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  getAllJobCost(jobId) {
    return this.http.get(
      environment.LocalDev_URL_V2 +
        `job/costs/${jobId}/cost-list/?sort=created_at&order=desc&page=1&limit=100`
    );
  }

  getAllFileWithEntity(payload) {
    return this.http.post(`${environment.files.http}files/entities`, payload, {
      headers: { 'x-tenant-id': this.subDomain }
    });
  }

  getLinkedEquipments(jobId) {
    return this.http.get(
      environment.baseURL + `jobs/${jobId}/job-equipment-checklist/`
    );
  }

  getAllEqupimentDetials(eqpList) {
    let requestURL =
      environment.equipURL +
      `/jobs/equipment/?query={'id': ['${eqpList.join(
        "','"
      )}'] }&columns=['name', 'id', 'image', 'equipment_type_name', 'specific_location', 'serial_number', 'asset_tag']&page=1&size=100`;
    return this.http.get(requestURL);
  }

  formatEqipmentData(data) {
    return data.map((f) => {
      return {
        ...f,
        name: f.name,
        title: f.name,
        image: f.image ?? 'assets/images/svg/folder.svg',
        type: 'FOLDER',
        path: '/equipment/' + f.id,
        identifier: 'EQIPMENT',
        default: true
      };
    });
  }

  formatJOBCOSTData(data) {
    return data.map((f) => {
      return {
        ...f,
        name: f.title,
        image: 'assets/images/svg/folder.svg',
        type: 'FOLDER',
        path: '/equipment/' + f.id,
        identifier: 'JOB_COST',
        disabled: '',
        default: true
      };
    });
  }

  getGlobalSettings() {
    let requestURL = environment.baseURL + 'global-job-settings/job-settings/';
    requestURL = requestURL.replace('v1', 'v2');
    return this.http.get(requestURL);
  }
}
