// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-dialog-content {
  overflow: unset !important;
}

form {
  width: 100%;
}

.err-msg {
  color: #ff0000;
  font-size: 12px;
}

.display-inline {
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_views/_popup_views/save-as-template/save-as-template.component.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;AACF;;AAEA;EACE,qBAAA;AACF","sourcesContent":[".mat-mdc-dialog-content {\n  overflow: unset !important;\n}\n\nform {\n  width: 100%;\n}\n\n.err-msg {\n  color: #ff0000;\n  font-size: 12px;\n}\n\n.display-inline {\n  display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
