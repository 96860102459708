import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { QuillModule } from 'ngx-quill';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-notes-holder',
    templateUrl: './notes-holder.component.html',
    styleUrls: ['./notes-holder.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [QuillModule]
})
export class NotesHolderComponent implements OnInit, OnChanges {
  sanitizedContent: SafeHtml;

  @Input() notesData: any;
  @Input() searchTerm: string = '';
  @Input() displayType: 'QUILL' | 'DIV' = 'QUILL';

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.updateContent();
  }

  private updateContent(): void {
    if (!this.notesData) {
      this.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml('');
      return;
    }

    console.log("this.searchTerm",this.searchTerm);

    if (!this.searchTerm) {
      this.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(this.notesData);
      return;
    }

    const searchRegex = new RegExp(this.searchTerm.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'gi');
    const highlightedContent = this.notesData.replace(
      searchRegex,
      match => `<span class="highlight">${match}</span>`
    );

    this.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(highlightedContent);
  }

}
