// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notes_preview .ql-editor {
  padding: 0;
  min-height: -moz-fit-content;
  min-height: fit-content;
}

.notes_preview p {
  margin-bottom: 0 !important;
  word-break: break-all;
}

.ql-indent-1 {
  padding-left: 10px;
}

.ql-indent-2 {
  padding-left: 20px;
}

.ql-indent-3 {
  padding-left: 30px;
}

.ql-indent-4 {
  padding-left: 40px;
}

.ql-indent-5 {
  padding-left: 50px;
}

.ql-indent-6 {
  padding-left: 60px;
}

.ql-indent-7 {
  padding-left: 70px;
}

.ql-indent-8 {
  padding-left: 80px;
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-align-left {
  text-align: left;
}

.highlight {
  background-color: yellow;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_views/_tab_components/tab-notes/notes-holder/notes-holder.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,4BAAA;EAAA,uBAAA;AACF;;AACA;EACI,2BAAA;EACA,qBAAA;AAEJ;;AACA;EACI,kBAAA;AAEJ;;AACA;EACI,kBAAA;AAEJ;;AACA;EACI,kBAAA;AAEJ;;AACA;EACI,kBAAA;AAEJ;;AACA;EACI,kBAAA;AAEJ;;AACA;EACI,kBAAA;AAEJ;;AACA;EACI,kBAAA;AAEJ;;AACA;EACI,kBAAA;AAEJ;;AACA;EACI,kBAAA;AAEJ;;AACA;EACI,iBAAA;AAEJ;;AACA;EACI,gBAAA;AAEJ;;AACA;EACI,wBAAA;AAEJ","sourcesContent":[".notes_preview .ql-editor {\n  padding: 0;\n  min-height: fit-content;\n}\n.notes_preview p {\n    margin-bottom: 0 !important;\n    word-break: break-all;\n  }\n\n.ql-indent-1{\n    padding-left: 10px;\n}\n\n.ql-indent-2{\n    padding-left: 20px;\n}\n\n.ql-indent-3{\n    padding-left: 30px;\n}\n\n.ql-indent-4{\n    padding-left: 40px;\n}\n\n.ql-indent-5{\n    padding-left: 50px;\n}\n\n.ql-indent-6{\n    padding-left: 60px;\n}\n\n.ql-indent-7{\n    padding-left: 70px;\n}\n\n.ql-indent-8{\n    padding-left: 80px;\n}\n\n.ql-align-center{\n    text-align: center;\n}\n\n.ql-align-right{\n    text-align: right;\n}\n\n.ql-align-left{\n    text-align: left;\n}\n\n.highlight {\n    background-color: yellow;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
