import { HostListener, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  showView: boolean = true;
  showFilter: boolean = false;
  collapasableClass: string = '';
  viewClass: string = '';
  openFromCompanyDetailedPageContactTab = false;
  columnSizes = ['view_col', 'col_middle_container', 'col-12', 'col-0'];

  // check is Mobile
  isMobile: boolean = false;

  isMobileDevice = new BehaviorSubject(false);

  constructor() {

    this.isMobileDevice.subscribe(value => {
      this.isMobile = value;

      if (this.isMobile) {
        this.isMobileLayouts();
      }
      else {
        this.setColumnState();
      }

    });
  }


  set showViewColumn(v: boolean) {
    if (v === this.showView) {
      return;
    }

    this.showView = v;
    this.setColumnState();
  }

  /**
   * Show/Hide View Column
   */
  toggleView_Column() {
    this.showView = !this.showView;
    this.setColumnState();
  }

  /**
   * Show/Hide Filter Column
   */
  toggleFilter_Column(isHide?:boolean) {

    if(isHide){
      this.showFilter = false;
      this.setColumnState();
    }
    else{
      this.showFilter = !this.showFilter;
      this.setColumnState();
    }
    return this.showFilter;
  }

  /**
   * Set Column Dynamic Classes
   */
  setColumnState() {
    if (this.showView && this.showFilter) {
      this.columnSizes = ['view_col', 'col_middle_container', 'col_middle_container_withFilter', 'col_filter'];
    } else if (this.showView && !this.showFilter) {
      this.columnSizes = ['view_col', 'col_middle_container', 'col-12', 'col-0'];
    } else if (!this.showView && !this.showFilter) {
      this.columnSizes = ['d-none', 'col-12', 'col-12', 'col-0'];
    } else if (!this.showView && this.showFilter) {
      this.columnSizes = ['d-none', 'col_middle_container_full', 'col_middle_container_withFilter', 'col_filter'];
    } else {
      throw Error('invalid state');
    }
  }


  isMobileLayouts() {

    if (this.showView && this.showFilter) {
      this.columnSizes = ['col-12', 'col-12', 'col-12', 'col-12'];
    } else if (this.showView && !this.showFilter) {
      this.columnSizes = ['col-12', 'col-12', 'col-12', 'col-0'];
    } else if (!this.showView && !this.showFilter) {
      this.columnSizes = ['d-none', 'col-12', 'col-12', 'col-0'];
    } else if (!this.showView && this.showFilter) {
      this.columnSizes = ['d-none', 'col-12', 'col_middle_container', 'col_filter'];
    } else {
      throw Error('invalid state');
    }
  }

}
