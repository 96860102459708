import { Component, Inject, Optional } from '@angular/core';
import { RadioButtonItem } from '../../../../../Modules/blank/pages/sample/sample.component';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LeasesHttpService } from '../../../../_services/leases/leases-http.service';
import { UtilService } from '../../../../../services/v2/util.service';
import { JobsService } from '../../../../../services/v2/jobs.service';
import { Member } from '../../../models/checklist-signature/checklist-signature';
import { BaseService } from '../../../../../services/base.service';
import { ErrorCodes } from '../../../enums/errors/error_codes.types'
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { NgIf, NgClass } from '@angular/common';
import { RadioComponent } from '../../../_reusable_ui/_controls/radio/radio.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';


@Component({
    selector: 'fyxt-notify-other-contacts-to-sign',
    templateUrl: 'notify-other-contacts-to-sign.component.html',
    styleUrls: ['notify-other-contacts-to-sign.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, TextboxComponent, NgxIntlTelInputModule, RadioComponent, NgIf, NgxMatIntlTelInputComponent, NgClass, ButtonComponent]
})
export class NotifyOtherContactsToSignComponent {
  public selectedChecklist: string = null;
  public jobDetails: any = null;
  public isPublic: boolean = false;

  radioItems: RadioButtonItem[] = [
    { name: 'E-mail Address', value: 'email' },
    { name: 'Text Message', value: 'text' },
  ];
  selectedRadioItem = this.radioItems[0].value;

  readonly form = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required]),
  });

  constructor(
    private readonly dialogRef: MatDialogRef<NotifyOtherContactsToSignComponent>,
    private readonly leasesHttpService: LeasesHttpService,
    private readonly jobService: JobsService,
    private readonly utilService: UtilService,
    private readonly baseService: BaseService,
    @Optional() @Inject(MAT_DIALOG_DATA) public matDialogData: any,
  ) {
    this.selectedChecklist = this.matDialogData?.data?.selectedChecklist ?? this.selectedChecklist;
    this.isPublic = this.matDialogData?.data?.isPublic ?? this.isPublic;
    this.jobDetails = this.matDialogData?.data?.jobDetails ?? this.jobDetails;
  }

  onRadiobuttonChange(selectedItem) {
    this.selectedRadioItem = selectedItem;
  }

  validatePhoneNumberFormat(target: any): void {
    let value = target.value;
    value = value.replace('/\D?g', '');
    value = value.replace(/^(\d{3})(\d{3})(\d{4})(\d{0,5})/, '$1 $2 $3 $4');
    target.value = value;
    if (value) {
      target.value = target.value.slice(0, 18);
    }
  }

  createAndNotify(): void {
    const { firstName, lastName, email, phoneNumber } = this.form.getRawValue();
    const type = this.baseService?.currentUserInfo?.current_Role
    const contact = {
      email: !!email ? [{
        email: email,
        primary: true,
        type: 'Corporate',
      }] : [],
      first_name: firstName,
      last_name: lastName,
      phone: !!phoneNumber ? [{
        phone: phoneNumber,
        type: 'Home',
        extension: null,
        primary: true,
      }] : [],
      primary_company: this.jobService.selectedJobInfo.tenant_company_id ?? this.jobDetails.tenant_company,
      source: 'contact',
      title: '',
      type: type || 'Tenant',
    };

    if (this.isPublic) {
      this.leasesHttpService.notifyOtherTenantContactsPublic({ ...contact, source: 'extenal-checklist' }).subscribe({
        next: (response) => {
          this.notify(response);
        },
        error: ({ error }) => {
          if (error?.error_code === ErrorCodes.CONTACT_ALREADY_EXISTS) {
            this.utilService.showError('Contact Already Exists', 'Contact with this email already exists. Please, click the (+) button and select this contact in the list.');
          } else {
            this.utilService.showError('Something went wrong', error.error);
          }
        },
      });
    } else {
      this.leasesHttpService.notifyOtherTenantContacts(contact).subscribe({
        next: (response) => {
          this.notify(response);
        },
        error: ({ error }) => {
          if (error?.error_code === ErrorCodes.CONTACT_ALREADY_EXISTS) {
            this.utilService.showError('Contact Already Exists', 'Contact with this email already exists. Please, click the (+) button and select this contact in the list.');
          } else {
            this.utilService.showError('Something went wrong', error.error);
          }
        },
      });
    }
  }

  notify(createdContact: any) {
    const { id, first_name, last_name, emails, phones } = createdContact;
    const type = this.baseService?.currentUserInfo?.current_Role
    const notifyContact: Member = {
      job_id: this.jobService?.selectedJobInfo?.id?.toString() ?? this.jobDetails?.id.toString(),
      name: [first_name, last_name].join(' '),
      company_name: this.jobService?.selectedJobInfo?.tenant ?? this.jobDetails?.tenant_company_name,
      checklist_id: this.selectedChecklist,
      user_id: id,
      user_contact_id: id,
      user_role: type || 'Tenant',
      user_email: !!emails?.length ? emails[0].email : null,
      user_phone: !!phones?.length ? phones[0].phone : null,
    };
      this.leasesHttpService.notifyMembers([notifyContact], { isPublic: this.isPublic }).subscribe({
        next: () => {
          this.utilService.showSuccess('', 'User notified successfully!');
          this.dialogRef.close(true);
        },
        error: (error) => {
          this.utilService.showError('Something went wrong.', error.error);
        },
      });
  }

  closePopup(): void {
    if (!this.dialogRef) {
      return;
    }

    this.dialogRef.close(false);
  }
}
