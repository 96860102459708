import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  currentUserInfo: any = localStorage.getItem('userobj')? JSON.parse(localStorage.getItem('userobj')): {};
  
  constructor(public _http: HttpClient, private _router: Router) {

  }

 
  /************** Base HTTP Request Methods *****************/

  /**
   * To Get Http Request for REST API
   * @param URL 
   * @param Options  
   */
  doGET(URL: string, Options?: any) {
    return this._http.get(URL, Options);
  }

  /**
   * To Post Http Request for REST API
   * @param URL   // The Endpoint URL
   * @param Body    //Post Input Body
   * @param Options   //Headers or Other Optional Params
   */
  public doPOST(URL: string, Body?: any, Options?: any) {
    return this._http.post(URL, Body, Options);
  }

  /**
   * To Update Http Request for REST API
   * @param URL 
   * @param Body 
   * @param Options 
   */
  doPUT(URL: string, Body?: any, Options?: any) {
    return this._http.put(URL, Body, Options);
  }


  /**
   * To Delete Http Request for REST API
   * @param URL 
   * @param Options 
   */
  doDelete(URL: string, Options?: any) {
    return this._http.delete(URL, Options);
  }

  /**
   * If Patch Request for Http Restfull API
   * @param URL 
   * @param Body 
   * @param Options 
   */
  doPatch(URL: string, Body?: any, Options?: any) {
    return this._http.patch(URL, Body, Options);
  }

  /**
   * If redirect to next page
   * @param pageURL 
   */
  redirectTo(pageURL: any) {
    this._router.navigate([pageURL]);
  }

}
