import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { CountryISO, SearchCountryField, PhoneNumberFormat, NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { NgClass } from '@angular/common';

@Component({
    selector: 'fyxt-phone-no-input',
    templateUrl: './phone-no-input.component.html',
    styleUrls: ['./phone-no-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => PhoneNoInputComponent)
        }
    ],
    standalone: true,
    imports: [NgClass, NgxIntlTelInputModule, FormsModule]
})
export class PhoneNoInputComponent implements OnInit,ControlValueAccessor {

  separateDialCode = false;
  PhoneNumberFormat = PhoneNumberFormat;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom
  ];
  input:any;

  @Input() disable:boolean = false;
  @Input() label:any;
  @Input() Error:boolean = false;
  @Input() maxlength!: number;
  @Output() onFocusOut = new EventEmitter<Event>();

  constructor() { }

  ngOnInit(): void {
  }

  onChange = (data:any) => {}
  onTouch = (_:any) => {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: string) {
    this.input = value;
    this.onChange(value);
  }

  /**
  *Func triggered on input change 
  */
  onchange(event:any){
    this.onChange(event);
  }

  focusOutInput(event:any){
    this.onFocusOut.emit(event);
  }

}
