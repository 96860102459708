import { KeycloakService, KeycloakOptions } from 'keycloak-angular';
import { environment } from '../../environments/environment';

export function initializeKeycloak(keycloak: KeycloakService): () => Promise<boolean> {
  const keycloakOptions: KeycloakOptions = {
    config: { ...environment.keycloakConfig },
    initOptions: {
      onLoad: 'check-sso',
      checkLoginIframe: false,
      token: localStorage.getItem('token'),
      refreshToken: localStorage.getItem('refreshToken'),
    },
  };

  return () => keycloak.init(keycloakOptions);
}
