import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  private subject = new Subject<any>();

  constructor(private toastr: ToastrService) { }


  /*************** Toaster Alert ******************/
  showSuccess(title: any, msg: any) {
    this.toastr.success(msg, title);
  }

  showError(title: any, msg: any) {
    this.toastr.error(msg, title);
  }

  showWarning(title: any, msg: any) {
    this.toastr.warning(msg, title);
  }

  showInfo(title: any, msg: any) {
    this.toastr.info(msg, title);
  }



  /*************** Common Error Notification ******************/

  showErrorMessage(error: any) {
    let errors = Object.values(error.error) || []
    if (errors.length > 0) {
      if (typeof (Object.values(error.error)[0]) == "object") {
        let errorObj: any = Object.values(error.error)[0];

        for (let errValue of Object.values(errorObj)) {
          this.showError('', errValue);
        }
      }
      else {
        let keyVal = Object.keys(error.error)[0];
        this.showError("Error", errors[0]);
      }
    }
    else
      this.showError('Error', error.error.detail);
  }



  /**
  * Get Object Values by Comma Separated
  * @param objItems 
  */
  getItemsByCommaSeparated(objItems: any) {

    let stringValue: any = "";

    if (Array.isArray(objItems)) {

      if (objItems.length > 0) {
        stringValue = objItems.filter(function (el) { return el; }).join(', ');
      }

    }
    else {

      if (objItems) {
        stringValue = Object.values(objItems).filter(function (el) { return el; }).join(', ');
      }

    }

    return stringValue;

  }



  /*************** Confirm Alert Dialog Box ******************/

  confirmDialog(showIcon: boolean, message: string, yesFn: () => void, noFn: () => void): any {

    let showActionIcon = showIcon ? true : false;

    this.setConfirmation(message, yesFn, noFn, showActionIcon);
  }

  setConfirmation(message: string, yesFn: () => void, noFn: () => void, showIcon: boolean): any {

    const that = this;
    this.subject.next({
      type: 'confirm',
      text: message,
      showIcon: showIcon,
      yesFn(): any {
        that.subject.next(); // This will close the modal
        yesFn();
      },
      noFn(): any {
        that.subject.next();
        noFn();
      }
    });

  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

}
