import { RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { AuthGuard } from '../../Gaurd/auth.guard';
import { SurveyFormComponent } from '../inbox/pages/survey-form/survey-form.component';
import { AccountListingComponent } from './components/account-listing/account-listing.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { JobpreviewComponent } from './components/jobpreview/jobpreview.component';
import { NewJobRequestsComponent } from './components/new-job-requests/new-job-requests.component';
import { OnboardComponent } from './components/onboard/onboard.component';
import { OTPVerificationComponent } from './components/otp-verification/otp-verification.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SingleauthComponent } from './components/singleauth/singleauth.component';
import { LoginComponent } from './components/login/login.component';
import { DraftServiceRequestComponent } from './components/new-job-requests/draft-service-request/draft-service-request.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: LoginComponent,
        data: {
          title: "Login"
        }
      },
      {
        path: 'login',
        component: LoginComponent,
        data: {
          title: "Login"
        }
      },
      {
        path: 'sso/:token/:refreshToken',
        component: SingleauthComponent,
        data: {
          title: "Authentication"
        }
      },
      {
        path: 'accounts',
        component: AccountListingComponent,
        canActivate: [AuthGuard],
        data: {
          title: "Accounts"
        }
      },
      {
        path: 'onboard/:token/:type',
        component: OnboardComponent,
        data: {
          title: "OnBoard"
        }
      },
      {
        path: 'forgot-password',
        component: ForgetPasswordComponent,
        data: {
          title: "Forgot Password"
        }
      },
      {
        path: 'password/reset/confirm/:uid/:token',
        component: ResetPasswordComponent,
        data: {
          title: "Reset Password"
        }
      },
      {
        path: 'otp',
        component: OTPVerificationComponent,
        data: {
          title: "OTP"
        }
      },
      {
        path: 'jobview/:token',
        component: JobpreviewComponent,
        data: {
          title: "Job View"
        }
      },
      {
        path: 'service-request',
        component: NewJobRequestsComponent,
        data: {
          title: "Service Request"
        }
      },
      {
        path: 'servicerequest',
        component: DraftServiceRequestComponent,
        data: {
          title: "Service Request"
        }
      },
      {
        path: 'survey-forms',
        component: SurveyFormComponent,
        data: {
          title: "Survey Forms"
        }
      },
      {
        path: '',
        redirectTo: '',
        pathMatch: 'full'
      },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
