import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from 'src/app/services/base.service';
import { ConfigService } from 'src/app/services/config.service';
import { environment } from 'src/environments/environment';
import { UtilityService } from 'src/app/services/utility.service';
import { UiService } from 'src/app/services/ui/ui.service';
import { ChecklistService } from 'src/app/services/checklist/checklist.service';

import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TicketFlowsService } from '../ticket-flows.service';
import { formatDate } from '@angular/common';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { delay, take } from 'rxjs/operators';
import { MessagesService } from 'src/app/Modules/inbox/services/messages.service';



@Injectable({
  providedIn: 'root'
})
export class InboxService {
  private triggerSocketActions = new Subject<any>();
  public updateJobDescriptionToLabel = new BehaviorSubject<boolean>(false);
  allAssociatedJobs: any[] = [];
  allAssignedManager: any = {};
  activeInboxMenu: any = 'opened';
  allMailList: any[] = [];
  mailListSortBy: any;
  mailListFilter: any;
  isFilterApplied: boolean = false;
  isSortApplied: boolean = false;
  showDefaultInbox: boolean = true;
  isComposeMailOpen: boolean = false;
  composeUploadFiles: any[] = [];
  conversationMsgList: any[] = [];
  conversationMsgListHistory: any[] = [];
  conversationNotesList: any[] = [];
  selectedMailDetail: any = {};
  mailListSearchData: any;
  mailDashboardCount: any;
  emailRightSideTabs: any = {
    isManagerTab: true,
    isSenderInfoTab: true,
    isJobsTab: true,
    isAttachmentsTab: true
  };
  allMailListAPIURL: any = '';
  QuickAction: any = null;
  interval: any;
  openEmailFromJobModal = false;

  emailPagination: any = {
    allMailsList: { next: '', count: 0 },
  };

  inboxAttachment: any;
  autoReloader: boolean = true;
  uploadedFile: any[] = [];
  uploadPhotos: any[] = [];
  senderPropertyId: any;
  isAddContact: boolean = false;
  editSenderFormOpen = false;
  senderSearchData: any;
  replyToUser: any;
  ccUser: any;
  ccUserList: any;
  replyToUserList: any;
  isShown = false;
  contactUsers: any;
  mailTextMessage: any;
  allSenderCCInfoDetails: any;
  lastConversation: any;
  timer: any;
  selectedBulkMails: any;
  jobAssociatedSearch: any;
  showAssociateJobModal = false;
  selectedMailSubject: any;
  bulkUploadMailTextMessage = '';
  templateList: any;
  selectAllCheckBox = false;
  isEmailSubjectEdit = false;
  isSendAsNewConversation = false;
  lastConversationOfSentOrReceived: any;
  footerSubjectLine: any;
  nextMailDetail: any;
  currentMailIndex: any;
  nextMailIndex: any;



  inboxQuickActionsList: any = [
    { id: 'Create_Job_&_Associate', name: 'Create Job & Associate', isVisible: true },
    { id: 'Associate_Job', name: 'Associate Job', isVisible: true },
    { id: 'Open', name: 'Move to Open', isVisible: false },
    { id: 'Resolved', name: 'Close Communication', isVisible: true },
    { id: 'Spam', name: 'Mark as Spam', isVisible: true },
    { id: 'Trash', name: 'Mark as Trash', isVisible: true },
    { id: 'Unread', name: 'Mark as Unread', isVisible: true },
  ];

  inboxQuickActionsListByFiltered: any = [];


  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    showToolbar: true,
    height: '6rem',
    minHeight: '6rem',
    toolbarPosition: 'top',
    sanitize: false,
    outline: false,
    placeholder: 'Write something here…',
    translate: 'no',
    defaultParagraphSeparator: '',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['strikeThrough',
        'subscript',
        'superscript', 'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
        // 'backgroundColor'
      ]
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  createTicketInboxData: any = {
    property: {},
    manager: {}
  };
  isJobsLoader: boolean;
  allAssociatedJobsList: any[] = [];
  addAssociatedJobSec: boolean;
  noJobError: any;
  showFilterSec: boolean = false;

  jobsDropdownPaginations: any = {
    pagination: { next: '', count: 0 },
    tempData: [],
    selectedItems: null
  };
  selectedMailActions_Socket: any;
  selectedMailDetail_error: boolean = false;

  constructor(public _http: HttpClient, private _router: Router, public _Config: ConfigService, public _utilService: UtilityService, public _baseService: BaseService,
    public _checklistService: ChecklistService,public messageService: MessagesService,
    public _ticketService: TicketFlowsService, public _uiService: UiService,
  ) {
  }

  /************** Base HTTP Request Methods *****************/

  /**
   * To Get Http Request for REST API
   * @param URL
   * @param Options
   */
  doGET(URL?: string, Options?: any): Observable<any> {
    return this._http.get(URL, Options).pipe(take(1));
  }

  /**
   * To Post Http Request for REST API
   * @param URL   // The Endpoint URL
   * @param Body    //Post Input Body
   * @param Options   //Headers or Other Optional Params
   */
  doPOST(URL?: string, Body?: any, Options?: any) {
    return this._http.post(URL, Body, Options);
  }

  /**
   * To Update Http Request for REST API
   * @param URL
   * @param Body
   * @param Options
   */
  doPUT(URL?: string, Body?: any, Options?: any) {
    return this._http.put(URL, Body, Options);
  }


  /**
   * To Delete Http Request for REST API
   * @param URL
   * @param Options
   */
  doDelete(URL?: string, Options?: any) {
    return this._http.delete(URL, Options);
  }

  /**
   * If Patch Request for Http Restfull API
   * @param URL
   * @param Body
   * @param Options
   */
  doPatch(URL?: string, Body?: any, Options?: any) {
    return this._http.patch(URL, Body, Options);
  }

  getAllMailList(isPagination: any) {


    let requestURL = "";
    if (isPagination) {
      this.autoReloader = false;
      if (this.emailPagination.allMailsList.next) {
        requestURL = this.emailPagination.allMailsList.next;
      } else {
        requestURL = environment.baseURL + this._Config.getMailList;
      }
    } else {
      this.autoReloader = true;
      requestURL = environment.baseURL + this._Config.getMailList;
      let queryData = ""
      if (this.mailListSortBy) {
        this.isSortApplied = true;
        queryData = "sort=" + this.mailListSortBy;
      }
      if (this.mailListFilter) {
        this.isFilterApplied = true;
        if (queryData) {
          queryData = queryData + "&query=" + this.mailListFilter;
        } else {
          queryData = "query=" + this.mailListFilter;
        }
      }
      if (this.activeInboxMenu) {
        if (queryData) {
          queryData = queryData + "&filter=" + this.activeInboxMenu;
        } else {
          queryData = "filter=" + this.activeInboxMenu;
        }
      }
      if (this.mailListSearchData) {
        if (queryData) {
          queryData = queryData + "&search=" + this.mailListSearchData;
        } else {
          queryData = "search=" + this.mailListSearchData;
        }
      }
      if (queryData) {
        requestURL = requestURL + "?" + queryData;
      }
    }
    this._Config.isLoader = true;
    this.allMailListAPIURL = requestURL;
    this.doGET(requestURL).subscribe((response: any) => {
      this._Config.isLoader = false;
      // if (this.autoReloader) {
      //   this.startTime();
      // } else {
      //   this.stopTime();
      // }
      this.emailPagination.allMailsList.next = response.next ? response.next : '';
      this.emailPagination.allMailsList.count = response.count ? response.count : 0;
      let ListItems = response.results || [];
      if (ListItems.length > 0) {
        ListItems.forEach(element => {
          element.isExpendVendor = false;
          element.isChecked = false;
          // if(element.sender.fullname.includes('Unknown')){
          //   let fullName = element.sender.fullname.split('[').filter(function(v){ return v.indexOf(']') > -1})
          //     .map( function(value) {
          //       return value.split(']')[0]
          //     })
          //   element.sender.fullname = fullName[0];
          // }

          // element.search_label=element.subject;
          // element.company=element.subject;
        });
      }
      if (isPagination) {
        this.allMailList.push(...ListItems);
      } else {
        this.allMailList = ListItems;
      }
      if (this.selectedBulkMails?.length > 0) {
        this.selectedBulkMails.forEach((item: any) => {
          this.allMailList.map((allMailItem: any) => {
            if (allMailItem.id == item.id) {
              return allMailItem.isChecked = true;
            }
          });
        });
      }
      if (this.selectedBulkMails?.length === 0) {
        this.isComposeMailOpen = false;
      }


    },
      (error) => {
        this._Config.isLoader = false;
      });
  }

  loadAllMailLists() {
    let requestURL = this.allMailListAPIURL;
    this._Config.isLoader = false;
    this.doGET(requestURL).subscribe((response: any) => {
      this.allMailList = response.results || [];
      if (this.selectedBulkMails?.length > 0) {
        this.selectedBulkMails.forEach((item: any) => {
          this.allMailList.map((allMailItem: any) => {
            if (allMailItem.id == item.id) {
              return allMailItem.isChecked = true;
            }
          });
        });
      }
      if (this.selectedBulkMails?.length === 0) {
        this.isComposeMailOpen = false;
      }
      this._Config.isLoader = false;
    },
      (error) => {
        this._Config.isLoader = false;
      });
  }

  startTime() {
    this.stopTime();
    this.interval = setInterval(() => {
      this.loadAllMailLists();
      this.getMailDashboardCount();
    }, 10000);
  }

  stopTime() {
    clearInterval(this.interval);
  }

  resetMailList() {
    this.showFilterSec = false;
    this.isFilterApplied = false;
    this.isSortApplied = false;
    this.mailListSearchData = '';
    this.mailListFilter = '';
    this.mailListSortBy = null;
    this.selectAllCheckBox = false;

    // this.setQuickActionDropdown();
    this.showDefaultInbox = true;
    this.selectedMailDetail = {};
    localStorage.removeItem('selectedMail');
    localStorage.removeItem('isOpenMailFromJob');
    localStorage.removeItem('closeConversation');
  }

  getIndividualMailDetail(data: any, isLoader?: any) {
    this.selectedMailDetail = data;
    this.nextMailDetail = {};
    this.selectedMailSubject = this.selectedMailDetail.subject;
    let requestURL = environment.baseURL + this._Config.viewMailDetail + '?pagination=false';
    requestURL = requestURL.replace('{ID}', data?.id);
    this._Config.isLoader = isLoader ? true : false;

    this.selectedMailDetail_error = false;

    this.doGET(requestURL).subscribe((response: any) => {

      this.selectedMailDetail_error = false;

      this.conversationMsgList = response || [];
      this.conversationMsgList.forEach((element, index) => {
        //To replace with additional attributes for existing string
        // element.body=element.body.replaceAll('class="gmail_quote"','class="gmail_quote" tabindex="1"');

        let replaceTag = `class="gmail_quote" tabindex="` + (index + 1) + `"`;
        element.body = element.body.replace('class="gmail_quote"', replaceTag);

        let replaceTagOutlook = `id="divRplyFwdMsg" class="outlookReply" tabindex="` + (index + 1) + `"`;
        element.body = element.body.replace('id="divRplyFwdMsg"', replaceTagOutlook);

        let replaceTagNext = `class="divRplyFwdMsg_next" tabindex="` + (index + 1) + `"`;
        element.body = element.body.replace('class="divRplyFwdMsg_next"', replaceTagNext);

        let replaceTagNext1 = `class="msg_reply_new_long" tabindex="` + (index + 1) + `"`;
        element.body = element.body.replace('class="msg_reply_new_long"', replaceTagNext1);

        element.files.forEach(elementFiles => {
          elementFiles.fileType = elementFiles.mime_type.split("/")[0];
        });
      });
      const isDraftAvailbale = response.some((res: any) => {
        return res.type == 'Draft';
      });
      const getType = response.map((res: any) => {
        return res.type;
      });
      const getIndexOfDraft = getType.lastIndexOf('Draft');
      const getIndexOfSent = getType.lastIndexOf('Sent');
      let isSentMessageRecent = false;
      this.lastConversationOfSentOrReceived = response.filter((res: any) => {
        return res.type != 'Draft';
      }).slice(-1).pop();
      if (this.lastConversationOfSentOrReceived && this.lastConversationOfSentOrReceived.subject == '') {
        this.lastConversationOfSentOrReceived.subject = '(no-subject)';
      }
      if (getIndexOfDraft != -1 || getIndexOfSent != -1) {
        if (getIndexOfDraft < getIndexOfSent) {
          isSentMessageRecent = true;
        } else {
          isSentMessageRecent = false;
        }
      }
      if (isDraftAvailbale && !isSentMessageRecent) {
        this.lastConversation = response.filter((res: any) => {
          return res.type == 'Draft';
        }).slice(-1).pop();
        this.footerSubjectLine = (this.lastConversation?.subject && this.lastConversation.subject != '') ? this.lastConversation.subject : '(no-subject)';
      } else {
        this.lastConversation = response.slice(-1).pop();
        this.footerSubjectLine = (this.lastConversation?.subject && this.lastConversation.subject != '') ? this.lastConversation.subject : '(no-subject)';
      }
      if (this.lastConversation?.type == 'Sent' || this.lastConversation?.type == 'Draft') {
        const getToUserId = this.lastConversation.to.map((user: any) => {
          return user.id;
        });
        const getCCUSerId = this.lastConversation.cc.map((user: any) => {
          return user.id;
        });
        this.replyToUser = getToUserId;
        this.ccUser = getCCUSerId;
        this.ccUserList = this.lastConversation.cc;
        this.replyToUserList = this.lastConversation.to;
      } else if (this.lastConversation?.type == 'Received') {
        const getToUserId = [this.lastConversation.sender.id];
        // const getCCUSerId = this.lastConversation.cc.map((user: any) => {
        //   return user.id;
        // });
        const getCCUSerId = this.lastConversation.cc.filter((user: any) => {
          return user.email != this.mailDashboardCount?.inbox.email;
        }).map((user: any) => {
          return user.id;
        });
        this.replyToUser = getToUserId;
        this.ccUser = getCCUSerId;
        this.ccUserList = this.lastConversation.cc;
        this.replyToUserList = [this.lastConversation.sender];
      }
      if (this.lastConversation?.type == 'Draft') {
        this.mailTextMessage = this.lastConversation.body;
        this.uploadedFile = this.lastConversation.files;
      }
      this.conversationMsgListHistory = this.makeGroupedHistory(this.conversationMsgList);
      this.currentMailIndex = this.allMailList.findIndex(mail => {
        return mail.id == this.selectedMailDetail.id;
      });
      this.nextMailIndex = this.currentMailIndex != -1 ? this.currentMailIndex + 1 : 0;
      this.nextMailDetail = this.allMailList[this.nextMailIndex];
      this._Config.isLoader = false;
      this.showDefaultInbox = false;
      this.isAddContact = false;
      // this.resetTabs();
      this.getInboxAttachment();
      this.getContactList();
      this.setQuickActionTabViews();
      // this.getSenderCCInfo();

      this.hideToolbar();

      setTimeout(() => {
        this.scroll('#scrollToContainer');
      }, 200);

      this.getCreateTicketRelatedInfo();
      localStorage.removeItem('selectedMail');
      localStorage.removeItem('isOpenMailFromJob');
      localStorage.removeItem('closeConversation');

      // this.inboxQuickActionsListByFiltered = this.inboxQuickActionsList.filter(obj => obj.isVisible == true);

      this._uiService.emailToggle();
      // this._uiService.emailmsgToggle();
      this.setQuickActionDropdown();



    },
      (error) => {
        this._Config.isLoader = false;
        this.selectedMailDetail_error = true;
      });
  }

  getIndividualMailDetailFromDraft(data: any, isLoader?: any) {
    let requestURL = environment.baseURL + this._Config.viewMailDetail + '?pagination=false';
    requestURL = requestURL.replace('{ID}', data?.id);
    this.doGET(requestURL).subscribe((response: any) => {
      this.conversationMsgList = response || [];
      this.conversationMsgList.forEach((element, index) => {
        //To replace with additional attributes for existing string
        // element.body=element.body.replaceAll('class="gmail_quote"','class="gmail_quote" tabindex="1"');

        let replaceTag = `class="gmail_quote" tabindex="` + (index + 1) + `"`;
        element.body = element.body.replace('class="gmail_quote"', replaceTag);

        let replaceTagOutlook = `id="divRplyFwdMsg" class="outlookReply" tabindex="` + (index + 1) + `"`;
        element.body = element.body.replace('id="divRplyFwdMsg"', replaceTagOutlook);

        let replaceTagNext = `class="divRplyFwdMsg_next" tabindex="` + (index + 1) + `"`;
        element.body = element.body.replace('class="divRplyFwdMsg_next"', replaceTagNext);

        let replaceTagNext1 = `class="msg_reply_new_long" tabindex="` + (index + 1) + `"`;
        element.body = element.body.replace('class="msg_reply_new_long"', replaceTagNext1);

        element.files.forEach(elementFiles => {
          elementFiles.fileType = elementFiles.mime_type.split("/")[0];
        });
      });
      this.lastConversation = response.slice(-1).pop();
      this.footerSubjectLine = this.lastConversation.subject;
    },
      (error) => {});
  }


  setQuickActionTabViews(): void {
    this.addAssociatedJobSec = false;
    this.emailRightSideTabs = {
      isManagerTab: false,
      isSenderInfoTab: false,
      isJobsTab: false,
      isAttachmentsTab: false,
    };
    this.getAssignedManager();
    this.getAssociateJob();
    this.getAssociateJobList();
  }


  setQuickActionDropdown() {



    this.inboxQuickActionsList = this.inboxQuickActionsList.filter(obj => obj.isVisible = true);

    if (this.activeInboxMenu == 'closed') {

      this.inboxQuickActionsList.filter(obj => obj.isVisible = false);

      let findIndex = this.inboxQuickActionsList.findIndex(obj => obj.id == 'Open');
      if (findIndex != -1)
        this.inboxQuickActionsList[findIndex].isVisible = true;

    }
    else if (this.activeInboxMenu == 'spam') {

      this.inboxQuickActionsList.filter(obj => obj.isVisible = false);

      let findIndexOpen = this.inboxQuickActionsList.findIndex(obj => obj.id == 'Open');
      let findIndexTrash = this.inboxQuickActionsList.findIndex(obj => obj.id == 'Trash');
      if (findIndexTrash != -1) {
        this.inboxQuickActionsList[findIndexTrash].isVisible = true;
      }
      if (findIndexOpen != -1) {
        this.inboxQuickActionsList[findIndexOpen].isVisible = true;
      }
    }
    else if (this.activeInboxMenu == 'trash') {

      this.inboxQuickActionsList.filter(obj => obj.isVisible = false);

      let findIndex = this.inboxQuickActionsList.findIndex(obj => obj.id == 'Open');
      let findIndexSpam = this.inboxQuickActionsList.findIndex(obj => obj.id == 'Spam');
      if (findIndexSpam != -1) {
        this.inboxQuickActionsList[findIndexSpam].isVisible = true;
      }
      if (findIndex != -1) {
        this.inboxQuickActionsList[findIndex].isVisible = true;
      }
    }
    else {

      let findIndex = this.inboxQuickActionsList.findIndex(obj => obj.id == 'Open');
      if (findIndex != -1)
        this.inboxQuickActionsList[findIndex].isVisible = false;

    }


    this.inboxQuickActionsListByFiltered = this.inboxQuickActionsList.filter(obj => obj.isVisible == true);


  }


  getIndividualNotes() {
    let requestURL = environment.baseURL + this._Config.viewMailNotes;
    requestURL = requestURL.replace('{ID}', this.selectedMailDetail.id);
    this._Config.isLoader = true;
    this.doGET(requestURL).subscribe((response: any) => {
      this.conversationNotesList = response.results || [];
      this.conversationNotesList.forEach(element => {
        element.files.forEach(elementFiles => {
          elementFiles.fileType = elementFiles.mime_type.split("/")[0];
        });
      });
      this._Config.isLoader = false;
    },
      (error) => {
        this._Config.isLoader = false;
      });
  }

  resetInboxMail() {
    this.selectedMailDetail = false;
    this.showDefaultInbox = true;
  }


  /**
   * Get All Associated jobs
   */
  getAssociateJob() {
    let requestURL = environment.baseURL + this._Config.getAssociateJob;
    requestURL = requestURL.replace('{ID}', this.selectedMailDetail.id);
    // this._Config.isLoader = true;
    this._baseService.doGET(requestURL).subscribe((response: any) => {
      this.allAssociatedJobs = response || [];
      // this._Config.isLoader = false;
    },
      (error) => {
        // this._Config.isLoader = false;

      });
  }

  updateAssociateJobList(jobResponse: any) {

    this.getAssociateJob();

  }

  /**
   * Get All associated Managers
   */
  getAssignedManager() {
    this._ticketService.showCreaateTicket = false;
    let requestURL = environment.baseURL + this._Config.getAssignedManager;
    requestURL = requestURL.replace('{ID}', this.selectedMailDetail.id);
    this._Config.isLoader = true;

    this._baseService.doGET(requestURL).subscribe((response: any) => {
      this.allAssignedManager = response || {};
      this._ticketService.showCreaateTicket = false;
      this._Config.isLoader = false;
    },
      (error) => {
        this._Config.isLoader = false;
        this._ticketService.showCreaateTicket = false;
      });
  }



  rightSideTabChangeEvents(tabID: any) {
    this.QuickAction = null;
    if (tabID == 1) {
      this.getAssignedManager();
      this.emailRightSideTabs.isManagerTab = !this.emailRightSideTabs.isManagerTab;
      // this.emailRightSideTabs = {
      //   isManagerTab: !this.emailRightSideTabs.isManagerTab,
      //   isSenderInfoTab: true,
      //   isJobsTab: true,
      //   isAttachmentsTab: true,
      // }
    }
    else if (tabID == 2) {
      this.emailRightSideTabs.isSenderInfoTab = !this.emailRightSideTabs.isSenderInfoTab;
      // this.emailRightSideTabs = {
      //   isManagerTab: true,
      //   isSenderInfoTab: !this.emailRightSideTabs.isSenderInfoTab,
      //   isJobsTab: true,
      //   isAttachmentsTab: true,
      // }
    }
    else if (tabID == 3) {
      this.getAssociateJob();
      this.emailRightSideTabs.isJobsTab = !this.emailRightSideTabs.isJobsTab
      // this.emailRightSideTabs = {
      //   isManagerTab: true,
      //   isSenderInfoTab: true,
      //   isJobsTab: !this.emailRightSideTabs.isJobsTab,
      //   isAttachmentsTab: true,
      // }
    }
    else if (tabID == 4) {
      this.emailRightSideTabs.isAttachmentsTab = !this.emailRightSideTabs.isAttachmentsTab
      // this.emailRightSideTabs = {
      //   isManagerTab: true,
      //   isSenderInfoTab: true,
      //   isJobsTab: true,
      //   isAttachmentsTab: !this.emailRightSideTabs.isAttachmentsTab,
      // }
    }

  }



  resetTabs() {
    this.emailRightSideTabs = {
      isManagerTab: true,
      isSenderInfoTab: true,
      isJobsTab: true,
      isAttachmentsTab: true,
    }
  }

  getFileType(type: any) {

    if (type) {

      let fileType = "image";

      if (type.includes('image')) {
        fileType = "image";
      }
      else if (type.includes('audio')) {
        fileType = "audio";
      }
      else if (type.includes('video')) {
        fileType = "video";
      }
      else if (type.includes('application/pdf')) {
        fileType = "pdf";
      }
      else if (type.includes('application')) {
        fileType = "others";
      }

      return fileType;

    }

    return '';

  }

  getMailDashboardCount() {
    let requestURL = environment.baseURL + this._Config.mailDashboardCount;
    this._baseService.doGET(requestURL).subscribe((response: any) => {
      this.mailDashboardCount = response;
    },
      (error) => {});
  }


  scroll(tabID: any) {
    setTimeout(() => {
      document.querySelector(tabID)?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: "start" });
    }, 200);

  }


  getInboxAttachment() {
    let requestURL = environment.baseURL + this._Config.getAttachment;
    requestURL = requestURL.replace('{ID}', this.selectedMailDetail.id);
    this._baseService.doGET(requestURL).subscribe((response: any) => {
      this._Config.isLoader = false;
      this.inboxAttachment = response;
      this.inboxAttachment.conversations.forEach(element => {
        element.fileType = element.mime_type.split("/")[0];
        element.file_name = element.file_name.split("/")[1];
        element.isChecked = false;
      });
    },
      (error) => {
        this._Config.isLoader = false;
      });
  }


  showToolbar(accessFrom?: any) {

    if (accessFrom == 'Job_Notes') {
      this.config.toolbarHiddenButtons[0].push('fontName')
    } else {
      let fontIndex = this.config.toolbarHiddenButtons[0].findIndex(item => item == 'fontName');

      if (fontIndex != -1)
        this.config.toolbarHiddenButtons[0].splice(fontIndex, 1)

    }

    this.config.showToolbar = true;
    this.config.placeholder = '';
  }

  hideToolbar() {

    let fontIndex = this.config.toolbarHiddenButtons[0].findIndex(item => item == 'fontName');

    if (fontIndex != -1)
      this.config.toolbarHiddenButtons[0].splice(fontIndex, 1)

    this.config.showToolbar = true;
    this.config.placeholder = 'Write something here…';
  }



  /****** Create Ticket *****/
  getCreateTicketRelatedInfo() {
    // this.getSenderInfo_createTicket();
    this.getAssignedManager_createTicket();
  }

  getSenderInfo_createTicket() {

    if (this.selectedMailDetail.sender) {
      let requestURL = environment.baseURL + this._Config.getSenderInfo;
      requestURL = requestURL.replace('{ID}', this.selectedMailDetail.sender.id);
      this._baseService.doGET(requestURL).subscribe((response: any) => {

        if (response.properties) {
          if (response.properties.length > 0) {

            this.createTicketInboxData.property = response.properties[0].property;

            let PropertyID = this.createTicketInboxData.property.id;

            if (PropertyID) {
              this._ticketService.getAllAssigneesList(PropertyID);
              this._ticketService.getAllEnginnersList(PropertyID);
              this._ticketService.getAllFollowersList(PropertyID);
            }

          }
          else {
            this.createTicketInboxData.property = {};
          }

        }

      },
        (error) => {});
    }

  }

  getAssignedManager_createTicket() {
    let requestURL = environment.baseURL + this._Config.getAssignedManager;
    requestURL = requestURL.replace('{ID}', this.selectedMailDetail.id);

    this._baseService.doGET(requestURL).subscribe((response: any) => {

      if (response.id) {
        this.createTicketInboxData.manager = response;
      }
      else {
        this.createTicketInboxData.manager = {};
      }

    },
      (error) => {});
  }


  get_DefaultData_createTicket() {
    let requestURL = environment.baseURL + this._Config.getDefaultCreateTicketData;
    requestURL = requestURL.replace('{ID}', this.selectedMailDetail.id);

    this._baseService.doGET(requestURL).subscribe((response: any) => {

      this.selectedMailDetail.create_ticket_info = response || {};

    },
      (error) => {});
  }



  makeGroupedHistory(data: any) {

    // this gives an object with dates as keys
    const groups = data.reduce((groups, chat) => {
      const date = (chat.modified_at ? chat.modified_at : chat.created_at).split('T')[0];

      if (!groups[date]) {
        groups[date] = [];
      }

      groups[date].push(chat);
      return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        dateString: (groups[date].length > 0) ? groups[date][0].modified_at : new Date(date),
        // dateString: this.time_ago(new Date(date)),
        inboxHistory: groups[date]
      };
    });

    return groupArrays;

  }



  time_ago(time) {
    let getDate = time;
    switch (typeof time) {
      case 'number':
        break;
      case 'string':
        time = +new Date(time);
        break;
      case 'object':
        if (time.constructor === Date) time = time.getTime();
        break;
      default:
        time = +new Date();
    }
    var todayDate = formatDate(new Date(), 'y-M-d', 'en-US');
    var getChatCurrentDate = formatDate(new Date(getDate), 'y-M-d', 'en-US');
    var time_formats = [
      [60, 'seconds', 1], // 60
      [120, '1 minute ago', '1 minute from now'], // 60*2
      [3600, 'minutes', 60], // 60*60, 60
      [7200, '1 hour ago', '1 hour from now'], // 60*60*2
      [86400, 'hours', 3600], // 60*60*24, 60*60
      [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
      [604800, 'days', 86400], // 60*60*24*7, 60*60*24
      [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
      [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
      [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
      [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
      [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
      [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
      [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
      [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];
    var seconds = (+new Date() - time) / 1000,
      token = 'ago',
      list_choice = 1;

    if (seconds == 0) {
      return 'Just now'
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      token = 'from now';
      list_choice = 2;
    }
    if (todayDate == getChatCurrentDate) {
      return 'Today';
    }
    if (seconds > 172800) {
      var getChatDate = new Date(getDate);
      return formatDate(getChatDate, 'MMMM d', 'en-US');
    }
    var i = 0,
      format;
    while (format = time_formats[i++])
      if (seconds < format[0]) {
        if (typeof format[2] == 'string')
          return format[list_choice];
        else
          return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
      }
    return time;
  }




  /**************** Inbox Quick Actions *******************/



  quickActionChangeEvent(e) {
    if (e.id == "Trash") {
      // let actioinMsg = 'Looks like this sender is a known contact. Are you sure you want to mark as ' + e.id + '?';
      //
      // let self = this;
      //
      // this._utilService.confirmDialog(true, actioinMsg, function () {
      //
      //   self.mailActionEvent(e.id, 1);
      //
      // }, function () {
      //   // alert("No clicked");
      //
      // });
      this.mailActionEvent(e.id, 1);
    } else if (e.id == "Spam") {
      let actioinMsg = 'Looks like this sender is a known contact. Are you sure you want to mark as ' + e.id + '?';

      let self = this;

      this._utilService.confirmDialog(true, actioinMsg, function () {

        self.mailActionEvent(e.id, 1);

      }, function () {
        // alert("No clicked");

      });
    } else if (e.id == "Resolved") {
      // let actioinMsg = 'Are you sure you want to close this conversation?';
      //
      // let self = this;
      //
      // this._utilService.confirmDialog(true, actioinMsg, function () {
      //
      //   self.mailActionEvent(e.id, 2);
      //
      // }, function () {
      //   // alert("No clicked");
      //
      // });
      this.mailActionEvent(e.id, 2);
    } else if (e.id == "Associate_Job") {
      // this.mailActionEvent(e.id, 3);
      this.rightSideTabChangeEvents(3);
      this.addAssociatedJobSec = true;
      this.getAssociateJobList();
    }
    else if (e.id == "Create_Job_&_Associate") {


      let requestURL = environment.baseURL + this._Config.getDefaultCreateTicketData;
      requestURL = requestURL.replace('{ID}', this.selectedMailDetail.id);

      this._baseService.doGET(requestURL).subscribe((response: any) => {

        this.selectedMailDetail.create_ticket_info = response || {};

        this.showCreateTicketPopup();

      },
        (error) => {
          this.showCreateTicketPopup();

        });

      // //Set create ticket predifined values
      // this.get_DefaultData_createTicket();

      // this.showCreateTicketPopup();

    } else if (e.id == "Unread") {
      this.markAsUnread();
    }
    else if (e.id == "Open") {

      this.moveToOpen();

    }



    this.QuickAction = [];

  }

  showConfirmDialog(action, payloadData: any, errorMsg: any) {

    let self = this;
    this._utilService.confirmDialog(true, errorMsg, function () {

      payloadData.is_move = true;
      self.doTrashConfirmAlertIfJobMapped(action, payloadData);


    }, function () {
      // alert("No clicked");
    });
  }

  doTrashConfirmAlertIfJobMapped(action, payload: any): any {
    let requestURL = environment.baseURL + this._Config.quickAction;
    requestURL = requestURL.replace('{ID}', this.selectedMailDetail.id);

    this._Config.isLoader = true;
    this._baseService.doPatch(requestURL, payload).subscribe((response: any) => {

      this._Config.isLoader = false;

      this.doMailActions(action, response);


    }, (error) => {
      this._utilService.showErrorMessage(error);
      this._Config.isLoader = false;
    });
  }

  mailActionEvent(action: any, tab: any) {
    let requestURL = environment.baseURL + this._Config.quickAction;
    requestURL = requestURL.replace('{ID}', this.selectedMailDetail.id);
    let payloadData = {};
    if (tab == 1) {
      payloadData = {
        "type": action
      }
    }
    if (tab == 2) {
      payloadData = {
        "status": action
      }
    }
    if (tab == 3) {
      return false;
    }
    this._Config.isLoader = true;
    this._baseService.doPatch(requestURL, payloadData).subscribe((response: any) => {
      this._Config.isLoader = false;

      // if (action == "Trash" && response.isError == 'True') {
      //   this.showConfirmDialog(action, payloadData, response.message);
      // }
      // else {
      //   this.doMailActions(action, response);
      // }

      this.doMailActions(action, response);


    }, (error: any) => {

      this._Config.isLoader = false;

      if (error.status == 400) {

        if (error.error) {
          if (error.error.isError == 'True') {
            this.showConfirmDialog(action, payloadData, error.error.message);
          }
        }

      }
    });
  }

  doMailActions(action, response) {
    this._utilService.showSuccess('', 'Mail was updated successfully');
    const getPaginationNumber = this.emailPagination.allMailsList.next.charAt(this.emailPagination.allMailsList.next.length - 1) - 1;
    if (action == "Trash") {
      // this.activeInboxMenu = 'trash';
      if (getPaginationNumber == 1) {
        this.getAllMailList(true);
        this.getAllMailList(false);
      } else {
        this.getAllMailList(true);
        const mailIndex = this.allMailList.findIndex((mail) => {
          return mail.id == this.selectedMailDetail.id;
        });
        if (mailIndex !== -1) {
          this.allMailList.splice(mailIndex, 1);
        }
      }
      this.getIndividualMailDetail(this.nextMailDetail);
      this.getMailDashboardCount();
    }
    if (action == "Spam") {
      // this.activeInboxMenu = 'spam';
      if (getPaginationNumber == 1) {
        this.getAllMailList(true);
        this.getAllMailList(false);
      } else {
        this.getAllMailList(true);
        const mailIndex = this.allMailList.findIndex((mail) => {
          return mail.id == this.selectedMailDetail.id;
        });
        if (mailIndex !== -1) {
          this.allMailList.splice(mailIndex, 1);
        }
      }
      this.getIndividualMailDetail(this.nextMailDetail);
      this.getMailDashboardCount();
    }
    if (action == "Resolved") {
      // this.activeInboxMenu = 'closed';
      if (getPaginationNumber == 1) {
        this.getAllMailList(true);
        this.getAllMailList(false);
      } else {
        this.getAllMailList(true);
        const mailIndex = this.allMailList.findIndex((mail) => {
          return mail.id == this.selectedMailDetail.id;
        });
        if (mailIndex !== -1) {
          this.allMailList.splice(mailIndex, 1);
        }
      }
      this.getIndividualMailDetail(this.nextMailDetail);
      this.getMailDashboardCount();
    }
    this.resetTabs();
    this.QuickAction = null;
    this.setQuickActionDropdown();

    this.doMailTriggerAction();
  }

  moveToOpen() {
    let actioinMsg = 'Are you sure you want to Move to Open?';
    let self = this;
    this._utilService.confirmDialog(true, actioinMsg, function () {
      let requestURL = environment.baseURL + self._Config.quickAction;
      requestURL = requestURL.replace('{ID}', self.selectedMailDetail.id);

      let payload: any = {};

      if (self.activeInboxMenu == 'spam' || self.activeInboxMenu == 'trash') {
        payload.type = "Normal";
      }
      else {
        payload.status = "Open";
      }
      const getPaginationNumber = self.emailPagination.allMailsList.next.charAt(self.emailPagination.allMailsList.next.length - 1) - 1;
      self._Config.isLoader = true;

      self._baseService.doPatch(requestURL, payload).subscribe((response: any) => {
        self._utilService.showSuccess('', 'Mail was updated successfully');
        // self.activeInboxMenu = 'opened';
        // self.getAllMailList(false);
        // self.getIndividualMailDetail(self.selectedMailDetail);
        if (getPaginationNumber == 1) {
          self.getAllMailList(true);
          self.getAllMailList(false);
        } else {
          self.getAllMailList(true);
          const mailIndex = self.allMailList.findIndex((mail) => {
            return mail.id == self.selectedMailDetail.id;
          });
          if (mailIndex !== -1) {
            self.allMailList.splice(mailIndex, 1);
          }
        }
        self.getIndividualMailDetail(self.nextMailDetail);
        self.getMailDashboardCount();
        // self.quickActionTrash = [];

        self.doMailTriggerAction();
        self._Config.isLoader = false;

      },
        (error) => {});
    }, function () {
      // alert("No clicked");

    });
  }

  markAsUnread() {
    let actioinMsg = 'Are you sure you want to Mark as Unread?';
    let self = this;
    this._utilService.confirmDialog(true, actioinMsg, function () {
      let requestURL = environment.baseURL + self._Config.quickAction;
      requestURL = requestURL.replace('{ID}', self.selectedMailDetail.id);
      let payload = {
        "unread": true
      }
      self._baseService.doPatch(requestURL, payload).subscribe((response: any) => {
        self._utilService.showSuccess('', 'Mail was updated successfully');
        self.getAllMailList(false);
        self.getMailDashboardCount();
        // self.quickActionTrash = [];

        self.doMailTriggerAction();

      },
        (error) => {});
    }, function () {
      // alert("No clicked");

    });
  }

  trashListChangeEvent(e) {
    let actioinMsg = 'Are you sure you want to Move to Open?';

    let self = this;

    this._utilService.confirmDialog(true, actioinMsg, function () {
      let requestURL = environment.baseURL + self._Config.quickAction;
      requestURL = requestURL.replace('{ID}', self.selectedMailDetail.id);
      let payload = {
        "type": e.id
      }
      self._baseService.doPatch(requestURL, payload).subscribe((response: any) => {
        self._utilService.showSuccess('', 'Mail was updated successfully');
        self.activeInboxMenu = 'opened';
        self.getAllMailList(false);
        self.getMailDashboardCount();
        // self.quickActionTrash = [];
      },
        (error) => {});
    }, function () {
      // alert("No clicked");

    });
  }

  showCreateTicketPopup(): void {
    this._uiService.createTicketTrigger();
    this.createTicketReletateAPIs();
    // let el: HTMLElement = this.createTicketBtn.nativeElement as HTMLElement;
    // el.click();

    setTimeout(() => {
      this._ticketService.isEmailCreateTicket = true;
      this._ticketService.selectedEmailTemplate = this.selectedMailDetail;
    }, 1000);

  }


  createTicketReletateAPIs(): void {
    if (this._ticketService.getAllDraftJob.length >= 1) {
      let resetCount = 1;
      this._ticketService.getAllDraftJob.map((id: any) => {
        id.id = resetCount;
        resetCount = resetCount + 1;
      });
    }
    this._ticketService.isShowCreateTicketModal = true;
    this._ticketService.uploadPhotos = [];
    this._ticketService.isEmailCreateTicket = true;
    this._ticketService.showCreaateTicket = true;
    if (this._baseService.currentUserInfo.category == 'Customer') {
      this._baseService.listAllCreateTicketPropertiesDropdown();
    }
    this._checklistService.listAllChecklistDropdown();
    this._ticketService.isCreateTicket = true;
    this._ticketService.selectedDraftJobDetail = undefined;
  }

  getAssociateJobList(searchData?: any): any {
    let requestURL = '';
    if (searchData) {
      requestURL = environment.baseURL + this._Config.getAssociateJobsList;
      requestURL += '?custom-search=' + searchData;
    } else {
      requestURL = environment.baseURL + this._Config.getAssociateJobsList;
    }

    this.isJobsLoader = true;

    this._baseService.doGET(requestURL).subscribe((response: any) => {

      if (Array.isArray(response)) {

        this.noJobError = null;

        if (response.length > 0) {
          response.forEach(element => {
            element.isChecked = false;
          });
        }

        this.allAssociatedJobsList = response || [];

        if (this.selectedMailDetail?.id) {
          this.getAssociateJob();
        }
      }
      else {
        // this.addAssociatedJobSec = false;
        this.allAssociatedJobsList = [];
        this.noJobError = response || {};
      }



      setTimeout(() => {
        this.isJobsLoader = false;
      }, 500);

    },
      (error) => {
        this.isJobsLoader = false;
      });
  }


  getAssociateJobList_Dropdown(searchTerm?: any, isPagination?: any) {

    let requestURL = "";
    if (isPagination) {
      if (this.jobsDropdownPaginations.pagination.next) {
        requestURL = this.jobsDropdownPaginations.pagination.next;
      } else {
        requestURL = environment.baseURL + this._Config.getAssociateJobsDropdownList;
      }
    } else {
      requestURL = environment.baseURL + this._Config.getAssociateJobsDropdownList;
      if (searchTerm.term) {
        requestURL = requestURL + '?custom-search=' + searchTerm.term
      }
    }

    this.jobsDropdownPaginations.Loading = true;

    // let requestURL = '';
    // if (searchData) {
    //   requestURL = environment.baseURL + this._Config.getAssociateJobsList;
    //   requestURL += '?custom-search=' + searchData;
    // } else {
    //   requestURL = environment.baseURL + this._Config.getAssociateJobsList;
    // }

    // this.isJobsLoader = true;

    this._baseService.doGET(requestURL).subscribe((response: any) => {

      this.jobsDropdownPaginations.pagination.next = response.next ? response.next : '';
      this.jobsDropdownPaginations.pagination.count = response.count ? response.count : 0;
      let ListItems = response.results || [];

      if (ListItems.length > 0) {
        ListItems.forEach(element => {
          element.isChecked = false;
          element.search_label = element.id + ' ' + element.property + ' ' + element._unit + ' ' + element.category + ' ' + element.description;
          if (searchTerm.term) {
            element.search_label = element.id + ' ' + element.property + ' ' + element._unit + ' ' + element.category + ' ' + element.description + ' ' + searchTerm.term;
          }
        });
      }

      // this.propertyPaginations.tempData = this.propertyPaginations.dropdownLists;

      if (isPagination) {
        this.jobsDropdownPaginations.tempData = [...this.jobsDropdownPaginations.tempData, ...ListItems];
        // this.allAssociatedJobsList = [...this.allAssociatedJobsList, ...ListItems];


      } else {
        this.jobsDropdownPaginations.tempData = ListItems || [];
        // this.allAssociatedJobsList = ListItems || [];
      }

      this.jobsDropdownPaginations.Loading = false;


      // if (Array.isArray(response)) {

      //   this.noJobError = null;

      //   if (response.length > 0) {
      //     response.forEach(element => {
      //       element.isChecked = false;
      //     });
      //   }

      //   this.allAssociatedJobsList = response || [];

      //   if (this.selectedMailDetail?.id) {
      //     this.getAssociateJob();
      //   }
      // }
      // else {
      //   // this.addAssociatedJobSec = false;
      //   this.allAssociatedJobsList = [];
      //   this.noJobError = response || {};
      // }



      // setTimeout(() => {
      //   this.isJobsLoader = false;
      // }, 500);

    },
      (error) => {
        this.isJobsLoader = false;
      });
  }



  assignJobtoThread() {
    // let getSelectedJob = [];
    // this.allAssociatedJobsList.forEach(element => {
    //   if (element.isChecked) {
    //     getSelectedJob.push(element.id);
    //   }
    // });
    // if (getSelectedJob.length == 0) {
    //   this._utilService.showError('', 'Please select any Job!!');
    //   return false;
    // }
    let requestURL = environment.baseURL + this._Config.saveJobAssignToThread;
    requestURL = requestURL.replace('{ID}', this.selectedMailDetail.id);
    this._Config.isLoader = true;
    let jobPayload = [];
    const pastAssociateJobLength = this.allAssociatedJobs.length;
    let getPaginationNumber = this.emailPagination.allMailsList.next.charAt(this.emailPagination.allMailsList.next.length - 1) - 1;


    // this.allAssociatedJobsList.forEach(element => {
    //   if (element.isChecked) {
    //     jobPayload.push(element.id);
    //   }
    // });
    if (this.allAssociatedJobs.length > 0) {
      this.allAssociatedJobs.forEach(element => {
        jobPayload.push(element.id);
      });
    }

    jobPayload = [...jobPayload, ...this.jobsDropdownPaginations.selectedItems];

    let payloadData = {
      "jobs": jobPayload
    }


    // let payloadData = {
    //   "jobs": this.jobsDropdownPaginations.selectedItems
    // }

    this._baseService.doPatch(requestURL, payloadData).subscribe((response: any) => {
      this._utilService.showSuccess('', 'Job Added Successfully!!');
      this.getAssignedManager();
      this.getAssociateJob();
      if (getPaginationNumber == 1) {
        this.getAllMailList(true);
        setTimeout(() => {
          this.getAllMailList(false);
        }, 500);
      } else {
        this.getAllMailList(true);
      }
      if (pastAssociateJobLength === 0) {
        this.selectedMailDetail.label[0].name = response.label[0].name;
      }
      this.jobAssociatedSearch = '';
      this._Config.isLoader = false;
      this.addAssociatedJobSec = false;
      this.jobsDropdownPaginations.selectedItems = null;


    },
      (error) => {
        this._Config.isLoader = false;
      });
  }


  getAsyncResponse(items: any): Observable<any[]> {
    return of(items).pipe(delay(500));
  }

  getContactList(searchTerm?: any): void {
    let requestURL = environment.baseURL + this._Config.getContactForInbox;
    if (searchTerm) {
      requestURL = requestURL.replace('{searchTerm}', searchTerm);
    }
    else {
      requestURL = requestURL.replace('{searchTerm}', '');
    }
    this._Config.isLoader = true;
    this._baseService.doGET(requestURL).subscribe((response: any) => {
      response.forEach(element => {
        element.search_label = (element.name ? element.name : element.fullname) + ' ( ' + element.email + ' ) ';
      });
      this.contactUsers = response;
      this._Config.isLoader = false;
    }, (error) => {
      this._Config.isLoader = false;
    });
  }

  getSenderCCInfo() {
    let requestURL = environment.baseURL + this._Config.getSenderCCInfo;
    requestURL = requestURL.replace('{ID}', this.selectedMailDetail.id);
    this._baseService.doGET(requestURL).subscribe((response: any) => {
      response.forEach(element => {
        element.selectedSenderInfo = 0;
        element.isOpen = false;
        element.emailEditSenderInfo = false;
        element.senderSearchData = '';
        element.phoneNo = element.phone;
        let isUnidentifiedSender = element.name.includes('Unknown');
        if (isUnidentifiedSender) {
          element.selectedSenderInfo = 0;
        } else {
          element.selectedSenderInfo = 1;
          let fullName = element.name.split(' ');
          element.firstName = fullName[0];
          element.lastName = fullName[1];
        }
        if (element.tenant) {
          element.addSenderToJobEditSec = 2;
        } else {
          element.addSenderToJobEditSec = 0;
        }
        element.updateSenderInfo = {
          firstName: '',
          lastName: '',
          phoneNo: null
        };
      });
      this.allSenderCCInfoDetails = response;
    },
      (error) => {});
  }

  sendMailMessageFromDraft(): any {
    let payloadData: any = {};
    const replyUserId = this.replyToUserList?.map((user: any) => {
      return user.id;
    });

    const ccUserId = this.ccUserList?.map((user: any) => {
      return user.id;
    });
    if (this.uploadedFile.length > 0 && this.mailTextMessage) {
      let uploadedFileArray = [];
      this.uploadedFile.forEach(element => {
        uploadedFileArray.push(element.id);
      });

      payloadData = {
        body: this.mailTextMessage,
        files: uploadedFileArray,
        to: replyUserId ? replyUserId : [],
        cc: ccUserId ? ccUserId : [],
        subject: this.footerSubjectLine,
        type: 'Draft',
      };
    } else if (this.uploadedFile.length == 0 && this.mailTextMessage) {
      payloadData = {
        body: this.mailTextMessage,
        to: replyUserId ? replyUserId : [],
        cc: ccUserId ? ccUserId : [],
        type: 'Draft',
        subject: this.footerSubjectLine,
        files: [],
      };
    } else if (this.uploadedFile.length > 0 && !this.mailTextMessage) {
      let uploadedFileArray = [];
      this.uploadedFile.forEach(element => {
        uploadedFileArray.push(element.id);
      });
      payloadData = {
        files: uploadedFileArray,
        to: replyUserId ? replyUserId : [],
        cc: ccUserId ? ccUserId : [],
        type: 'Draft',
        subject: this.footerSubjectLine,
        body: '',
      };
    } else {
      payloadData = {
        files: [],
        to: replyUserId ? replyUserId : [],
        cc: ccUserId ? ccUserId : [],
        type: 'Draft',
        subject: this.footerSubjectLine,
        body: '',
      };
    }
    let requestURL = environment.baseURL + this._Config.sendConversation;
    requestURL = requestURL.replace('{ID}', this.selectedMailDetail.id);
    const selectedMailDetailDraft = this.selectedMailDetail;

    if (this.lastConversation.type == 'Draft') {
      payloadData.id = this.lastConversation.id;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this._baseService.doPatch(requestURL, payloadData).subscribe((response: any) => {
          this.getIndividualMailDetailFromDraft(selectedMailDetailDraft);
        }, (error) => {
          this._utilService.showErrorMessage(error);
        });
      }, 1000);
    } else {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this._baseService.doPOST(requestURL, payloadData).subscribe((response: any) => {
          this.getIndividualMailDetailFromDraft(selectedMailDetailDraft);
        }, (error) => {
          this._utilService.showErrorMessage(error);
        });
      }, 1000);
    }
  }

  bulkUploadMails(payload: any): any {
    let requestURL = environment.baseURL + this._Config.bulkUploadMails;
    this._Config.isLoader = true;
    this._baseService.doPatch(requestURL, payload).subscribe((response: any) => {



      // Socket code
      this.selectedMailActions_Socket = payload.mail ? payload.mail : this.selectedMailDetail.id;;

      this.doMailTriggerAction();


      this.selectAllCheckBox = false;
      // if (payload.type === 'Spam') {
      //   this.activeInboxMenu = 'spam';
      // } else if (payload.type === 'Trash') {
      //   this.activeInboxMenu = 'trash';
      // } else if (payload.status === 'Resolved') {
      //   this.activeInboxMenu = 'closed';
      // } else if (payload.status === 'Open') {
      //   this.activeInboxMenu = 'opened';
      // }

      this.jobsDropdownPaginations.selectedItems = null;
      this.showAssociateJobModal = false;

      this.getMailDashboardCount();
      if (payload.jobs && payload.jobs.length > 0) {
        this.showAssociateJobModal = false;
        if (this.selectedMailDetail?.id) {
          this.getIndividualMailDetail(this.selectedMailDetail, true);
        } else {
          this.resetMailList();
          this.getAllMailList(false);
        }
        this.allMailList.forEach((item: any) => {
          item.isChecked = false;
        });
        this.jobAssociatedSearch = '';
        this._utilService.showSuccess('', 'Job Associated Successfully!!');
      } else if (payload.action === 'reply') {
        this.composeUploadFiles = [];
        this.bulkUploadMailTextMessage = '';
        this.isComposeMailOpen = false;
        this.allMailList.forEach((item: any) => {
          item.isChecked = false;
        });
        this.templateList.forEach((item: any) => {
          item.isChecked = false;
        });
        if (this.selectedMailDetail?.id) {
          this.getIndividualMailDetail(this.selectedMailDetail, true);
        } else {
          this.resetMailList();
          this.getAllMailList(false);
        }
      } else if (payload.action === 'empty_trash') {
        this.activeInboxMenu = 'trash';
        this.resetMailList();
        this.getAllMailList(false);
      } else {
        this.resetMailList();
        this.getAllMailList(false);
      }
      this.selectedBulkMails = [];
      this._Config.isLoader = false;





    }, (error) => {
      this._utilService.showErrorMessage(error);
      this._Config.isLoader = false;
    });
  }

  updateInboxLabel(payload): void {
    let requestURL = environment.baseURL + this._Config.editLabel;
    requestURL = requestURL.replace('{ID}', this.selectedMailDetail.labels[0].id);
    this._Config.isLoader = true;
    let getPaginationNumber = this.emailPagination.allMailsList.next.charAt(this.emailPagination.allMailsList.next.length - 1) - 1;
    this._baseService.doPatch(requestURL, payload).subscribe((response: any) => {
      this.selectedMailDetail.labelName = response.name;
      this.selectedMailDetail.labels[0].name = response.name;
      this.messageService.selectedMessageFromSecondPanel = this.selectedMailDetail;
      this._Config.isLoader = false;
      if (getPaginationNumber == 1) {
        this.getAllMailList(true);
        this.updateJobDescriptionToLabel.next(true);
        setTimeout(() => {
          this.getAllMailList(false);
        }, 500);
      } else {
        this.getAllMailList(true);
        this.updateJobDescriptionToLabel.next(true);
      }
    }, (error) => {
      this._utilService.showErrorMessage(error);
      this._Config.isLoader = false;
    });
  }

  fetchJobDescriptionToLabel(): Observable<boolean> {
    return this.updateJobDescriptionToLabel.asObservable();
  }


  doMailTriggerAction() {


    this.triggerSocketActions.next(true);


  }

  clearMailTriggerAction() {
    this.triggerSocketActions.next();
  }

  getMailTriggerAction(): Observable<any> {
    return this.triggerSocketActions.asObservable();
  }

}
