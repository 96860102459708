import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UiService } from 'src/app/services/ui/ui.service';
import { UtilityService } from 'src/app/services/utility.service';
import { BaseService } from 'src/app/services/base.service';
import { ConfigService } from 'src/app/services/config.service';
import { environment } from 'src/environments/environment';
import { FormArray, FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable, Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket';
import { TabPhotobankPopupComponent, TabUploadFilesPopupComponent } from 'src/app/@fyxt/_shared/_views';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { JobsService } from 'src/app/services/v2/jobs.service';
import { FilesService } from 'src/app/Modules/_fyxt_modules/companies/_services/files.service';
import { Title } from '@angular/platform-browser';
import requestTypeData from './request_types.json';
import { DropdownDateSelectComponent } from '../../../../../@fyxt/_shared/_reusable_ui/_controls/dropdown-select/dropdown-date-select/dropdown-date-select.component';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../../../../@fyxt/_shared/_reusable_ui/_components/button/button.component';
import { DragAndDropFileUploaderComponent } from '../../../../shared/_reusable_components/drag-and-drop-file-uploader/drag-and-drop-file-uploader.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { NgClass, AsyncPipe, NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-villages-service-request',
    templateUrl: './villages-service-request.component.html',
    styleUrls: ['./villages-service-request.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, FormsModule, NgxIntlTelInputModule, ReactiveFormsModule, NgFor, NgSelectModule, DragAndDropFileUploaderComponent, ButtonComponent, MatIconModule, DropdownDateSelectComponent, AsyncPipe]
})
export class VillagesServiceRequestComponent implements OnInit {
  propertySubscription: Subscription;

  @ViewChild('fileDropRef') fileDropRef: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;

  serviceRequestForm: FormGroup;
  villageServiceRequestForm: FormGroup;
  selectedFiles: any[] = [];
  tempUploadedList: any[] = [];
  base: string;
  submitted: boolean = false;
  villageFormSubmitted: boolean = false;

  livePropertyData: Observable<any[]>;
  liveLocationData: Observable<any[]>;
  liveCategoryData: Observable<any[]>;

  propertyLoading: boolean = false;
  locationLoading: boolean = false;
  categoryLoading: boolean = false;
  contactLoading: boolean = false;
  isFormSuccess: boolean = false;
  contactData: any;
  contactSearchInput: string;
  requestID: any;
  selectedCampus: string;

  allowedDomains: any[] = [
    'localhost',
    'web.devfyxt.com',
    'krishna.qafyxt.com',
    'friedman.demofyxt.com',
    'delta.demofyxt.com',
    'cstest.fyxt.com',
    'friedman.fyxt.com',
    'delta.fyxt.com',
    'ots.fyxt.com',
    'villages.qafyxt.com',
    'cmi.fyxt.com',
    'continuum.fyxt.com',
    '*.docker.devfyxt.com',
    '*.docker.qafyxt.com',
    '*.docker.demofyxt.com',
    'thevillagescpm.fyxt.com',
    'internaltest.fyxt.com'
  ];

  audioVisual: any[] = [
    { name: 'No AV Needs', checked: false },
    { name: 'Audio', checked: false },
    { name: 'Visual', checked: false },
    { name: 'AV Equipment Assistance', checked: false }
  ];

  furnitureList: any[] = [
    { name: 'No Furniture', checked: false },
    { name: 'Table - 6 ft', checked: false },
    { name: 'Table - High School Testing', checked: false },
    { name: 'Chair - Standard Folding', checked: false },
    { name: 'Chair - VCS Folding w/ Cushion', checked: false },
    { name: 'Rug', checked: false },
    { name: 'Podium', checked: false },
    { name: 'Clock', checked: false },
    { name: 'Furniture Delivery', checked: false },
    { name: 'Furniture Set-Up', checked: false },
    { name: 'Furniture Take-Down', checked: false },
    { name: 'Furniture Pick-Up', checked: false },
  ];

  janitorialServices: any[] = [
    { name: 'No Janitorial Services', checked: false },
    { name: 'Additional Trash Bins', checked: false },
    { name: 'Trash Removal During Event', checked: false },
    { name: 'Janitorial Staff During Event', checked: false },
    { name: 'Janitorial Service After Event', checked: false },
  ];
  campusData = [
    'Buffalo Ridge',
    'Middleton'
  ]
  eventApproverList = [
    {
      name: 'Colt McDowell',
      email: 'colt.mcdowell@tvcs.org',
      displayText: 'Colt McDowell (colt.mcdowell@tvcs.org)'
    },
    {
      name: 'CPM Operations',
      email: 'liz.rodriguez@thevillages.com',
      displayText: 'CPM Operations (liz.rodriguez@thevillages.com)'
    },
    {
      name: 'Kristine Rohan',
      email: 'kristine.rohan@tvelc.com',
      displayText: 'Kristine Rohan (kristine.rohan@tvelc.com)'
    },
    {
      name: 'Lana Hood',
      email: 'lana.hood@tvcs.org',
      displayText: 'Lana Hood (lana.hood@tvcs.org)'
    },
    {
      name: 'LeAnne Yerk',
      email: 'leanne.yerk@tvcs.org',
      displayText: 'LeAnne Yerk (leanne.yerk@tvcs.org)'
    },
    {
      name: 'Peggy Irwin',
      email: 'peggy.irwin@tvcs.org',
      displayText: 'Peggy Irwin (peggy.irwin@tvcs.org)'
    },
    {
      name: 'Randy McDaniel',
      email: 'randy.mcdaniel@tvcs.org',
      displayText: 'Randy McDaniel (randy.mcdaniel@tvcs.org)'
    },
    {
      name: 'Richard Pettus',
      email: 'richard.pettus@tvcs.org',
      displayText: 'Richard Pettus (richard.pettus@tvcs.org)'
    },
    {
      name: 'Robin Grant',
      email: 'robin.grant@tvcs.org',
      displayText: 'Robin Grant (robin.grant@tvcs.org)'
    },
    {
      name: 'Shannon Taylor',
      email: 'shannon.taylor@tvelc.com',
      displayText: 'Shannon Taylor (shannon.taylor@tvelc.com)'
    },
    {
      name: 'Tara Millow',
      email: 'tara.millow@tvcs.org',
      displayText: 'Tara Millow (tara.millow@tvcs.org)'
    },
  ];

  gateLocationList = [
    'HAE Gate 1 High School East Athletic Entrance Pedestrian', 'HAE Gate 2 High School East Athletic Entrance South Cantilever',
    'HAE Gate 3 High School East Athletic Entrance North Cantilever', 'HAE Gate C1 High School West/Staff Parking Lot',
    'HAE Gate 4 High School South Practice Field', 'ME Gate 1 Middle School South Athletic Field Double Gate',
    'ME Gate 2 Middle School North New Addition', 'ME Gate 2 Middle School West Gym', 'FE Gate 1 West Pedestrian',
    'FE Gate 2 East Pedestrian', 'IE Front Gate 1 Intermediate Center Front/Playground Gate', 'IE Gate 2 Intermediate Center South Gym',
    'IE Gate 2 Intermediate Center West Gym', 'PE Gate 1 Primary Center North'
  ];

  current_account = "friedman";
  default_logo: string = "assets/images/service_logo/Friedman_Logo.png";
  serviceJobType: any = 'Building';
  minDate: any = new Date();
  contactPageIndexAPI: number = 1;
  selectedCategory: any;
  emailList: any = [];
  showError: boolean = false;

  newRequestTypeLists = requestTypeData;


  isDomainAllowed(domain, allowedDomains) {
    const regexPatterns = allowedDomains.map((allowedDomain) =>
      new RegExp(`^${allowedDomain.replace(/\*/g, '.*')}$`, 'i')
    );
    return regexPatterns.some((regex) => regex.test(domain));
  }


  // current_account="continuum";
  // default_logo:string="assets/images/service_logo/logo_continuum.png";

  villageTitle: string = "Villages Charter School Service Request";

  constructor(
    public _uiService: UiService,
    public _utilService: UtilityService,
    public _baseService: BaseService,
    public _Config: ConfigService,
    public formbuilder: FormBuilder,
    public _dialogService: DialogService,
    public _jobService: JobsService,
    public _filesService: FilesService,
    private readonly title: Title,
  ) {
    this.base = environment.files.http
    this.base = this.base.substring(0, this.base.length - 1);
    let hostname = window.location.hostname;

    this.current_account = 'thevillagescpm';
    this.default_logo = "assets/images/service_logo/thevillagescpm.png";

    // if (!this.isDomainAllowed(hostname, this.allowedDomains)) {
    //   this._baseService.redirectTo('/');
    // }

    this.title.setTitle(this.villageTitle);

  }

  ngOnInit(): void {
    environment['x-schema'] = window.location.host.split('.')[0]
    this.initializeForm();
    this._uiService.carouselSlider();
    this.getAllEmailAddress();
  }

  initializeForm() {
    /**** To Set Form Fields Validations ***/
    this.serviceRequestForm = this.formbuilder.group({
      contactData: this.formbuilder.array([this.addContactDetails()]),
      // FName: new FormControl('', [Validators.required]),
      // LName: new FormControl('', [Validators.required]),
      // Email: new FormControl(null, [
      //   Validators.required,
      //   Validators.email,
      //   Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,5}$")
      // ]),
      // PhoneNo: new FormControl('', [
      //   Validators.required,
      //   Validators.minLength(10),
      //   Validators.maxLength(10)
      // ]),
      property: new FormControl(null, [Validators.required]),
      location: new FormControl(null, [Validators.required]),
      category: new FormControl(null, [Validators.required]),
      campus: new FormControl(null, [Validators.required]),
      building: new FormControl(''),
      extactLocation: new FormControl('', [Validators.required]),
      BriefDescription: new FormControl(null, [
        Validators.required,
        // Validators.maxLength(150)
      ]),
      AdditionalDetails: new FormControl('', [Validators.required]),
      AccessInstruction: new FormControl('', [Validators.required]),
      Photo: new FormControl(null)
    });

    this.villageServiceRequestForm = this.formbuilder.group({
      contactData: this.formbuilder.array([this.addContactDetails()]),
      // Email: new FormControl(null, [
      //   Validators.required,
      //   Validators.email,
      //   Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,5}$")
      // ]),
      BriefDescription: new FormControl('', [
        Validators.required,
        Validators.maxLength(150)
      ]),
      // OrganizorName: new FormControl('', [Validators.required]),
      building: new FormControl(''),
      campus: new FormControl(null, [Validators.required]),
      extactLocation: new FormControl('', [Validators.required]),
      EName: new FormControl('', [Validators.required]),
      EDate: new FormControl('', [Validators.required]),
      EStartTime: new FormControl('', [Validators.required]),
      EEndTime: new FormControl('', [Validators.required]),
      ENoParticipants: new FormControl('', [Validators.required]),
      EApprover: new FormControl(null, [Validators.required]),
      DoorAccess: new FormControl('', [Validators.required]),
      DoorNumber: new FormControl(''),
      DoorSchedule: new FormControl(''),
      GateAccess: new FormControl('', [Validators.required]),
      GateLocation: new FormControl(null),
      GateSchedule: new FormControl(''),
      EAudioVisual: this.formbuilder.array(Object.keys(this.audioVisual).map((key: any) => {
        return key.checked
      })),
      AudioNeeds: new FormControl(''),
      VisualNeeds: new FormControl(''),
      AVAssistance: new FormControl(''),
      EFurniture: this.formbuilder.array(Object.keys(this.furnitureList).map(key => false)),
      FurnitureTables: new FormControl(''),
      FurnitureChairs: new FormControl(''),
      FurnitureNeeds: new FormControl(''),
      FurnitureSetUp: new FormControl(''),
      EFood: new FormControl('', [Validators.required]),
      EJanitorialServices: this.formbuilder.array(Object.keys(this.janitorialServices).map(key => false)),
      TrashBins: new FormControl(''),
      TrashRemovalTime: new FormControl(''),
      EConcession: new FormControl('', [Validators.required]),
      EDecorDetails: new FormControl(''),
      EDecorations: new FormControl(''),
      EDetails: new FormControl(''),
      property: new FormControl(null, [Validators.required]),
      // location: new FormControl(null, [Validators.required]),
      location: new FormControl(null),
      category: new FormControl(null),
      Photo: new FormControl(null)
    });

    this.villageServiceRequestForm.get('EFurniture').addValidators(Validators.required);

    this.tempUploadedList = [];
  }


  addContactDetails() {
    return this.formbuilder.group({
      first_name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      email: new FormControl(null, [
        Validators.required,
        Validators.email,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,5}$")
      ]),
      phone: new FormControl('')
    });
  }

  addMoreContact() {
    if (this.serviceJobType == 'Building') {
      this.contactArray.push(this.addContactDetails());
    } else {
      this.contactEventArray.push(this.addContactDetails());
    }
  }

  removeContactData(Index: any) {
    if (this.serviceJobType == 'Building') {
      this.contactArray.removeAt(Index);
    } else {
      this.contactEventArray.removeAt(Index);
    }
  }

  get contactArray() {
    const control = this.serviceRequestForm.get('contactData') as FormArray;
    return control;
  }

  get contactEventArray() {
    const control = this.villageServiceRequestForm.get('contactData') as FormArray;
    return control;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.serviceRequestForm.controls;
  }

  // convenience getter for easy access to village event form fields
  get e() {
    return this.villageServiceRequestForm.controls;
  }

  /** */
  resetServiceForm() {
    this.initializeForm();
    this.submitted = false;
    this.tempUploadedList = [];
    this.livePropertyData = this.getAsyncResponse([]);
  }

  /**Get All Email Address */
  getAllEmailAddress() {
    let requestURL = environment.fastAPI_Actual_URL + this._Config.get_all_email_address;
    this._baseService.doGET(requestURL).subscribe(
      (response: any) => {
        this._Config.isLoader = false;
        let emailArray = [];
        response.forEach(element => {
          let emailData = { id: element, name: element }
          emailArray.push(emailData);
        });
        this.emailList = emailArray;
      },
      (error) => {
        this._Config.isLoader = false;
        if (error.status === 500) {
          this._utilService.showError(error.status + " Error!", "Something went wrong.");
        }
        else {
          this._utilService.showErrorMessage(error.error);
        }
      }
    );

  }

  /** Add New Email */
  CreateNewEmail = (term) => {
    return new Promise((resolve) => {
      if (term && this.validateEmail(term.replace(/['"]+/g, ''))) {
        this.showError = false;
        setTimeout(() => {
          resolve({ id: term, name: term });
        }, 1000);
      } else {
        this.showError = true;
        return;
      }

    })
  }

  checkEmail(event: any) {
    let value = (event || '').trim();
    if (value && this.validateEmail(value.replace(/['"]+/g, ''))) {
      this.showError = false;
    } else {
      if (value !== '')
        this.showError = true;
      else
        this.showError = false;
    }
  }

  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    if (email.includes(' ')) {
      return re.test(email.split(' ')[email.split(' ').length - 1]);
    } else
      return re.test(email);
  }

  /**
   * handle files from browsing
   */
  fileBrowseHandler_New(file: any) {
    this._Config.isLoader = true;
    this._filesService.tempFileUploadNoAuth(file[0]).subscribe((data: any) => {
      this._Config.isLoader = false;
      this.tempUploadedList.push(data?.data);
    }, (err) => this._Config.isLoader = false);
  }

  checkCheckBox(data) {
    return (
      data.filter(element => {
        return element?.checked === false;
      }).length === data.length
    );
  }

  /**
   * Submit new request
   * @returns
   */
  doSubmit() {
    this.submitted = true;
    this.isFormSuccess = false;

    if (this.serviceRequestForm.invalid) {
      return;
    }

    let descNew = "";
    descNew += (this.serviceRequestForm.value.AdditionalDetails ? this.serviceRequestForm.value.AdditionalDetails : 'NA');
    if (this.serviceRequestForm.value.campus) {
      descNew += " | Campus: " + (this.serviceRequestForm.value.campus ? this.serviceRequestForm.value.campus : 'NA');
    }
    if (this.serviceRequestForm.value.extactLocation) {
      descNew += " | Exact Location: " + (this.serviceRequestForm.value.extactLocation ? this.serviceRequestForm.value.extactLocation : 'NA');
    }

    let PostBody: any = {
      contacts: this.serviceRequestForm.value.contactData,
      property: this.serviceRequestForm.value.property,
      unit: this.serviceRequestForm.value.location,
      category: this.serviceRequestForm.value.category,
      issue_type: this.serviceRequestForm.value.BriefDescription,
      description: descNew,
      access_instruction: this.serviceRequestForm.value.AccessInstruction,
      photos: []
    };

    if (this.selectedFiles.length > 0) {
      PostBody.photos = this.selectedFiles.map((obj) => obj.id);
    }

    let requestURL = environment.baseURL + this._Config.add_New_Service_Request;

    this._Config.isLoader = true;

    this._baseService.doPOST(requestURL, PostBody).subscribe(
      (response: any) => {
        this._Config.isLoader = false;
        this.requestID = response.id;
        this._utilService.showSuccess(
          'Request ID: ' + this.requestID,
          'Service Request Submitted Successfully.'
        );

        let photosList_New = this.tempUploadedList.map((obj) => {
          return obj.id;
        });

        if (photosList_New.length > 0) {
          let payload = {
            "entityId": this.requestID.toString(),
            "entityType": "JOB",
            "title": "service-request",
            "tempFileIds": photosList_New,
            "userId": response.user_id,
            "userType": "USER"
          }
          this._filesService.timeFilesSyncNoAuth(payload).subscribe((data) => { })
        }

        this.isFormSuccess = true;
        this.submitted = false;
        // this.serviceRequestForm.reset();

        (this.serviceRequestForm.get('contactData') as FormArray).clear();
        this.serviceRequestForm.reset();
        this.addMoreContact();
        this._utilService.draggedFiles = [];
        this.tempUploadedList = [];
        this.getAllEmailAddress();
      },
      (error) => {
        this._Config.isLoader = false;
        if (error.status === 500) {
          this._utilService.showError(error.status + " Error!", "Something went wrong.");
        }
        else {
          this._utilService.showErrorMessage(error.error);
        }
      }
    );
  }

  /**
   * Submit Village Event Form new request
   * @returns
   */
  doVillageFormSubmit() {
    this.villageFormSubmitted = true;
    this.isFormSuccess = false;

    if (this.villageServiceRequestForm.invalid || this.checkCheckBox(this.audioVisual) || this.checkCheckBox(this.furnitureList) || this.checkCheckBox(this.janitorialServices)) {
      return;
    }

    let audioVisualsValue = [];
    this.audioVisual.forEach((element, index) => {
      if (element.checked) {
        audioVisualsValue.push(this.audioVisual[index].name);
      }
    });

    let furnitureValue = [];
    this.furnitureList.forEach((element, index) => {
      if (element.checked) {
        furnitureValue.push(this.furnitureList[index].name);
      }
    });

    let janitorialValue = [];
    this.janitorialServices.forEach((element, index) => {
      if (element.checked) {
        janitorialValue.push(this.janitorialServices[index].name);
      }
    });

    let descNew = "";
    descNew += "Campus: " + (this.villageServiceRequestForm.value.campus ? this.villageServiceRequestForm.value.campus : 'NA') + " | ";
    descNew += "Exact Location: " + (this.villageServiceRequestForm.value.extactLocation ? this.villageServiceRequestForm.value.extactLocation : 'NA') + " | ";
    descNew += "Event Name: " + (this.villageServiceRequestForm.value.EName ? this.villageServiceRequestForm.value.EName : 'NA') + " | ";
    descNew += "Event Date: " + (this.villageServiceRequestForm.value.EDate ? this.villageServiceRequestForm.value.EDate : 'NA') + " | ";
    descNew += "Event Start Time: " + (this.villageServiceRequestForm.value.EStartTime ? this.villageServiceRequestForm.value.EStartTime : 'NA') + " | ";
    descNew += "Event End Time: " + (this.villageServiceRequestForm.value.EEndTime ? this.villageServiceRequestForm.value.EEndTime : 'NA') + " | ";
    descNew += "Expected Number of Participants: " + (this.villageServiceRequestForm.value.ENoParticipants ? this.villageServiceRequestForm.value.ENoParticipants : 'NA') + " | ";
    descNew += "Event Approver: " + (this.villageServiceRequestForm.value.EApprover ? this.villageServiceRequestForm.value.EApprover : 'NA') + " | ";
    descNew += "Door Access: " + (this.villageServiceRequestForm.value.DoorAccess ? this.villageServiceRequestForm.value.DoorAccess : 'NA') + " | ";
    descNew += "Door Numbers: " + (this.villageServiceRequestForm.value.DoorNumber ? this.villageServiceRequestForm.value.DoorNumber : 'NA') + " | ";
    descNew += "Door Schedule: " + (this.villageServiceRequestForm.value.DoorSchedule ? this.villageServiceRequestForm.value.DoorSchedule : 'NA') + " | ";
    descNew += "Gate Access: " + (this.villageServiceRequestForm.value.GateAccess ? this.villageServiceRequestForm.value.GateAccess : 'NA') + " | ";
    descNew += "Gate Location: " + (this.villageServiceRequestForm.value.GateLocation ? this.villageServiceRequestForm.value.GateLocation : 'NA') + " | ";
    descNew += "Gate Schedule: " + (this.villageServiceRequestForm.value.GateSchedule ? this.villageServiceRequestForm.value.GateSchedule : 'NA') + " | ";
    descNew += "Audio/Visual: " + (audioVisualsValue ? audioVisualsValue : 'NA') + " | ";
    descNew += "Audio Needs: " + (this.villageServiceRequestForm.value.AudioNeeds ? this.villageServiceRequestForm.value.AudioNeeds : 'NA') + " | ";
    descNew += "Visual Needs: " + (this.villageServiceRequestForm.value.VisualNeeds ? this.villageServiceRequestForm.value.VisualNeeds : 'NA') + " | ";
    descNew += "AV Assistance Needs: " + (this.villageServiceRequestForm.value.AVAssistance ? this.villageServiceRequestForm.value.AVAssistance : 'NA') + " | ";
    descNew += "Furniture: " + (furnitureValue ? furnitureValue : 'NA') + " | ";
    descNew += "Tables: " + (this.villageServiceRequestForm.value.FurnitureTables ? this.villageServiceRequestForm.value.FurnitureTables : 'NA') + " | ";
    descNew += "Chairs: " + (this.villageServiceRequestForm.value.FurnitureChairs ? this.villageServiceRequestForm.value.FurnitureChairs : 'NA') + " | ";
    descNew += "Furniture Set-Up: " + (this.villageServiceRequestForm.value.FurnitureSetUp ? this.villageServiceRequestForm.value.FurnitureSetUp : 'NA') + " | ";
    descNew += "Furniture Needs: " + (this.villageServiceRequestForm.value.FurnitureNeeds ? this.villageServiceRequestForm.value.FurnitureNeeds : 'NA') + " | ";
    descNew += "Food: " + (this.villageServiceRequestForm.value.EFood ? this.villageServiceRequestForm.value.EFood : 'NA') + " | ";
    descNew += "Janitorial Services: " + (janitorialValue ? janitorialValue : 'NA') + " | ";
    descNew += "Trash Bins: " + (this.villageServiceRequestForm.value.TrashBins ? this.villageServiceRequestForm.value.TrashBins : 'NA') + " | ";
    descNew += "Trash Removal Time: " + (this.villageServiceRequestForm.value.TrashRemovalTime ? this.villageServiceRequestForm.value.TrashRemovalTime : 'NA') + " | ";
    descNew += "Concession: " + (this.villageServiceRequestForm.value.EConcession ? this.villageServiceRequestForm.value.EConcession : 'NA') + " | ";
    descNew += "Decorations: " + (this.villageServiceRequestForm.value.EDecorations ? this.villageServiceRequestForm.value.EDecorations : 'NA') + " | ";
    descNew += "Decor Description: " + (this.villageServiceRequestForm.value.EDecorDetails ? this.villageServiceRequestForm.value.EDecorDetails : 'NA') + " | ";
    descNew += "Event Details: " + (this.villageServiceRequestForm.value.EDetails ? this.villageServiceRequestForm.value.EDetails : 'NA');

    let PostBody: any = {
      contacts: this.villageServiceRequestForm.value.contactData,
      property: this.villageServiceRequestForm.value.property,
      unit: this.villageServiceRequestForm.value.location,
      category: this.selectedCategory ? this.selectedCategory[0] : null,
      issue_type: this.villageServiceRequestForm.value.BriefDescription,
      description: descNew,
      access_instruction: '',
      photos: []
    };

    if (this.selectedFiles.length > 0) {
      PostBody.photos = this.selectedFiles.map((obj) => obj.id);
    }

    let requestURL = environment.baseURL + this._Config.add_New_Service_Request;

    this._Config.isLoader = true;

    this._baseService.doPOST(requestURL, PostBody).subscribe(
      (response: any) => {
        this._Config.isLoader = false;

        this.requestID = response.id;
        this._utilService.showSuccess(
          'Request ID: ' + this.requestID,
          'Event Request Submitted Successfully.'
        );

        let photosList_New = this.tempUploadedList.map((obj) => {
          return obj.id;
        });

        if (photosList_New.length > 0) {
          let payload = {
            "entityId": this.requestID.toString(),
            "entityType": "JOB",
            "title": "service-request",
            "tempFileIds": photosList_New,
            "userId": response.user_id,
            "userType": "USER"
          }
          this._filesService.timeFilesSyncNoAuth(payload).subscribe((data) => { })
        }

        this.isFormSuccess = true;
        this.villageFormSubmitted = false;
        (this.villageServiceRequestForm.get('contactData') as FormArray).clear();
        this.villageServiceRequestForm.reset();
        this.addMoreContact();
        this._utilService.draggedFiles = [];
        this.tempUploadedList = [];
        this.getAllEmailAddress();
      },
      (error) => {
        this._Config.isLoader = false;
        this._utilService.showErrorMessage(error);
      }
    );

  }



  /**
   * Get All properties
   * @param searchTerm
   */
  getAllProperties(searchTerm?: any): void {
    let requestURL = '';
    if (searchTerm?.term) {
      requestURL = environment.baseURL + this._Config.servcice_Form_Properties + '?campus=' + this.selectedCampus;
      requestURL += '&custom-search=' + searchTerm.term;
    } else {
      requestURL = environment.baseURL + this._Config.servcice_Form_Properties + '?campus=' + this.selectedCampus;
    }
    this.propertyLoading = true;


    if (this.propertySubscription) {
      this.propertySubscription.unsubscribe();
    }

    this.propertySubscription = this._baseService.doGET(requestURL)
      .subscribe({
        next: (response: any) => {
          if (response.length > 0) {
            response.forEach((element) => {
              element.name = element.name.replace(/\s+/g, ' ').trim();
              element.address = element.address.replace(/\s+/g, ' ').trim();

              element.property_address =
                element.name + ' ( ' + element.address + ' )';
              element.search_label =
                element.name + ' ( ' + element.address + ' ) ';

              if (searchTerm) {
                if (searchTerm.term) element.search_label += ', ' + searchTerm.term;
              }
            });
          }

          this.livePropertyData = this.getAsyncResponse(response);

        },
        error: (error) => {
          this.propertyLoading = false;
        },
        complete: () => {
          this.propertyLoading = false;
        }
      });

  }

  /**
   * On change Campus event
   * @param selectedItem
   */
  onChangeCampus(selectedItem: any): void {
    if (selectedItem == 'Buffalo Ridge') {
      this.selectedCampus = 'BR';
    } else {
      this.selectedCampus = 'M';
    }
    this.getAllProperties();
  }

  /**
   * On change property event
   * @param selectedItem
   */
  onChangeProperty(selectedItem: any): void {
    if (selectedItem) {
      this.getAllLocationDropdown();
      this.serviceRequestForm.get('location').patchValue(null);
      this.serviceRequestForm.get('category').patchValue(null);
      this.liveCategoryData = this.getAsyncResponse([]);
    }
  }

  /**
   * On change property event
   * @param selectedItem
   */
  onChangeVillageProperty(selectedItem: any): void {
    if (selectedItem) {
      this.getAllLocationDropdown();
      this.villageServiceRequestForm.get('location').patchValue(null);
      this.villageServiceRequestForm.get('category').patchValue(null);
      this.liveCategoryData = this.getAsyncResponse([]);
    }
  }

  /**
   * On change Location event
   * @param selectedItem
   */
  onChangeLocation(selectedItem: any): void {
    if (selectedItem) {
      this.getAllCategoriesDropdown();
      this.serviceRequestForm.get('category').patchValue(null);
    }
  }

  /**
   * On change Location event
   * @param selectedItem
   */
  onChangeVillageLocation(selectedItem: any): void {
    if (selectedItem) {
      this.getAllCategoriesDropdown();
      this.villageServiceRequestForm.get('category').patchValue(null);
    }
  }

  /**
   * Get Locations by Property
   */
  getAllLocationDropdown(searchTerm?: any) {
    let propertyID = '';
    if (this.serviceJobType == 'Building') {
      propertyID = this.serviceRequestForm.value.property;
    } else {
      propertyID = this.villageServiceRequestForm.value.property;
    }
    let requestURL = environment.baseURL + this._Config.servcice_Form_Units;
    requestURL = requestURL.replace(
      '{ID}',
      propertyID
    );

    if (searchTerm?.term) {
      requestURL = requestURL + '?custom-search=' + searchTerm.term;
    }

    this.locationLoading = true;

    this._baseService.doGET(requestURL).subscribe({
      next: (response: any) => {
        let allLocations = [];
        if (response.length > 0) {
          response.forEach((element: any, index) => {
            let units = [];
            element.units.forEach((unit: any) => {
              let search_label = element.address;
              search_label += ', ' + unit.name;

              if (unit.tenant)
                search_label += ', ' + unit.tenant?.name;

              let unitObj = {
                id: unit.id,
                title: unit.name,
                status: unit.status,
                tenant: unit.tenant ? unit.tenant : '',
                search_label: search_label
              };

              units.push(unitObj);
            });

            let unitsObj = {
              id: index + 1,
              title: element.address,
              subunits: units
            };
            allLocations.push(unitsObj);
          });
        }

        this.liveLocationData = this.getAsyncResponse(allLocations);

        if (this.serviceJobType == 'Building') {
          if (allLocations.length > 0) {
            this.serviceRequestForm.get('location').patchValue(allLocations[0]["subunits"][0].id);
            this.onChangeLocation(allLocations[0]["subunits"][0].id);
          } else {
            this.serviceRequestForm.get('location').patchValue(null);
          }
        } else {
          if (allLocations.length > 0) {
            this.villageServiceRequestForm.get('location').patchValue(allLocations[0]["subunits"][0].id);
            this.onChangeLocation(allLocations[0]["subunits"][0].id);
          } else {
            this.villageServiceRequestForm.get('location').patchValue(null);
          }
        }

      },
      error: (error) => {
        this.locationLoading = false;
      },
      complete: () => {
        this.locationLoading = false;
      }
    });


  }

  getAsyncResponse(response): Observable<any[]> {
    return of(response);
  }

  changeDoorAccess(event) {
    if (event.target.value == 'Yes') {
      this.villageServiceRequestForm.get('DoorSchedule').addValidators(Validators.required);
    } else {
      this.villageServiceRequestForm.get('DoorSchedule').clearValidators();
    }
    this.villageServiceRequestForm.get('DoorSchedule').updateValueAndValidity();
  }

  /**
   * Get All Servie Types
   * @param searchTerm
   */
  getAllCategoriesDropdown(searchTerm?: any): void {
    let requestURL = '';

    let locationUnit = (this.serviceJobType == 'Event') ? this.villageServiceRequestForm.value.location : this.serviceRequestForm.value.location;
    let propertyID = (this.serviceJobType == 'Event') ? this.villageServiceRequestForm.value.property : this.serviceRequestForm.value.property;

    requestURL = environment.baseURL + this._Config.servcice_Form_Categories;
    requestURL = requestURL.replace(
      '{Propert_ID}',
      propertyID
    );
    requestURL = requestURL.replace(
      '{Unit_ID}',
      locationUnit
    );

    if (searchTerm?.term) {
      requestURL = requestURL + '&custom-search=' + searchTerm.term;
    }

    this.categoryLoading = true;


    this._baseService.doGET(requestURL).subscribe({
      next: (response: any) => {
        let responseData = response || [];
        this.liveCategoryData = this.getAsyncResponse(responseData);
        if (this.serviceJobType == 'Event') {
          this.selectedCategory = response.filter((obj) => {
            return obj.name === 'Event';
          }).map(s => s.id);
        }
      },
      error: (error) => {
        this.categoryLoading = false;
      },
      complete: () => {
        this.categoryLoading = false;
      }
    });
  }

  validatePhoneNo(event: any) {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  setGateLocationValue(): void {
    this.villageServiceRequestForm.get('GateLocation').patchValue(null);
  }

  uploadFilesPopup() {
    let popupData = {
      title: 'File Upload',
      component: TabUploadFilesPopupComponent,
      data: {
        "entityId": null,
        "entityType": 'JOB'
      },
    };

    // public _dialogService: DialogService
    this._dialogService.openModal(popupData).subscribe(result => {
      if (Array.isArray(result)) {
        this.tempUploadedList.push(...result) || [];
      }
    });

  }

  photoBankPopup() {
    let popupData = {
      title: 'Photobank',
      component: TabPhotobankPopupComponent,
      data: {
        "entityId": null,
        "entityType": 'JOB'
      }
    };

    this._dialogService.openModal(popupData).subscribe(result => {

      if (result) {
        if (Array.isArray(result?.files)) {
          if (result.files.length > 0) {
            let datas = [];
            result.files.forEach(element => {
              datas.push(element.data)
            });
            // let selectedPhotos = result.files.map(obj => obj.data) || [];
            this.tempUploadedList.push(...datas) || [];
          }

        }

      }

    });
  }

  deleteTempFile(file) {
    for (let i = 0; i < this.tempUploadedList.length; i++) {
      if (file.key == this.tempUploadedList[i].key) {
        this.tempUploadedList.splice(i, 1);
      }
    }
  }

  onChangeAudioVisual(event: any, index: any, item: any) {
    item.checked = event.target.checked;
    if (item.name == 'No AV Needs') {
      if (item.checked == true) {
        this.audioVisual.map((element: any) => {
          return element.checked = false;
        });
      }
      item.checked = event.target.checked;
    } else {
      if (item.checked == true && this.audioVisual[0].checked == true) {
        this.audioVisual[0].checked = false;
      }
    }
  }

  onChangeFurniture(event: any, index: any, item: any) {
    item.checked = event.target.checked;
    if (item.name == 'No Furniture') {
      if (item.checked == true) {
        this.furnitureList.map((element: any) => {
          return element.checked = false;
        });
      }
      item.checked = event.target.checked;
    } else {
      if (item.checked == true && this.furnitureList[0].checked == true) {
        this.furnitureList[0].checked = false;
      }
    }
  }

  onChangeJanitoralService(event: any, index: any, item: any) {
    item.checked = event.target.checked;
    if (item.name == 'No Janitorial Services') {
      if (item.checked == true) {
        this.janitorialServices.map((element: any) => {
          return element.checked = false;
        });
      }
      item.checked = event.target.checked;
    } else {
      if (item.checked == true && this.janitorialServices[0].checked == true) {
        this.janitorialServices[0].checked = false;
      }
    }
  }


  onChangeCategory(event: any) {
    this.serviceRequestForm.get('BriefDescription').patchValue(event.name);
  }
}
