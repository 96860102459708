import { formatDate, KeyValue, NgIf, NgTemplateOutlet, NgFor, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, CurrencyPipe, DatePipe, KeyValuePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ScrollToBottomDirective } from '../../_directives';
import * as _ from 'lodash';
import moment from 'moment';
import {
  ACTIVITY_ENTITY_TYPE,
  COIActivityActions,
  COIFields,
  COMPANY_CREATE_SHOW_FIELDS,
  CONTACT_CREATE_SHOW_FIELDS,
  coveragesFields,
  EQUIPMENT_CREATE_SHOW_FIELDS,
  EQUIPMENT_UPDATE_DELETE_FIELDS,
  JOB_COST_CREATE_SHOW_FIELDS,
  JOB_CREATE_SHOW_FIELDS,
  JOB_MATERIAL_CREATE_SHOW_FIELDS,
  JOB_SCHEDULE_CREATE_SHOW_FIELDS,
  JOB_SITE_VISIT_SHOW_FIELDS,
  JOB_TIMESHEET_CREATE_SHOW_FIELDS,
  leaseActivityActions,
  leaseCategoriesFields,
  leaseContactsFields,
  leaseDocumentsFields,
  leaseFields,
  leaseInspectionFields,
  leaseProvisionFields,
  leaseRulesFields,
  PROPERTY_CREATE_SHOW_FIELDS,
  specialWords,
} from './activity.const';
import { ActivityService } from 'src/app/Modules/_fyxt_modules/companies/_services/activity.service';
import { ActivityFilterPipe } from '../../_pipes/_custom-pipes/activity-filter.pipe';
import { SafeHTMLContentPipe } from '../../_pipes/_custom-pipes/safeContent-html.pipe';
import { QuillModule } from 'ngx-quill';
import { ThumbnailComponent } from '../../_reusable_ui/_components/thumbnail/thumbnail.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NotesHolderComponent } from '../../_views/_tab_components/tab-notes/notes-holder/notes-holder.component';




@Component({
    selector: 'fyxt-activities',
    templateUrl: './activities.component.html',
    styleUrls: ['./activities.component.scss'],
    standalone: true,
    imports: [NgIf, NgTemplateOutlet, InfiniteScrollModule, NgFor, NgClass, NgSwitch, NgSwitchCase, ThumbnailComponent, QuillModule, NgSwitchDefault, CurrencyPipe, DatePipe, KeyValuePipe, SafeHTMLContentPipe, ActivityFilterPipe,NotesHolderComponent]
})
export class ActivitiesComponent implements OnInit {
  // Constants, enums and other similar
  protected readonly ACTIVITY_ENTITY_TYPE = ACTIVITY_ENTITY_TYPE;
  protected readonly leaseActivityActions = leaseActivityActions;

  // Inputs
  @Input() activities: any[] = [];
  @Input() totalCount: any;
  @Input() isPDF_Preview: boolean = false;
  @Input() propertyGroups: boolean = false;
  @Input() contHeight: number = 0;
  @Input() filtersApplied: boolean = false;
  // Outputs
  @Output() activityNextPage = new EventEmitter<boolean>();

  // Other local public variables
  todayDate = new Date().toISOString().split('T')[0];
  yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0];
  year = this.todayDate.split('-');
  activityArrayData: any;
  image = 'assets/images/users/avatar.png';

  // For scroll
  @ViewChild(ScrollToBottomDirective)
  scroll!: ScrollToBottomDirective;

  // For logs
  logCounter: number = 0;

  //KeyvalueOrder
  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }

  constructor(private cdr: ChangeDetectorRef, private _activityService: ActivityService) { }

  ngOnInit(): void {
    // this.activityArrayData = this.makeGroupedHistory(this.activities);
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activities) {
      if (changes.activities?.currentValue?.length > 0) {
        changes.activities.currentValue.forEach((element, index) => {
          switch (element.entityType) {
            case 'EQUIPMENT':
            case 'EQUIPMENT_TYPE':
            case 'EQUIPMENT_ADDITIONAL_DATA':
              this.equipmentFilter(element, index);
              break;

            case 'COMPANY':
              this.companyFilter(element, index);
              break;

            case 'CONTACT':
              this.contactFilter(element, index);
              break;

            case 'JOB':
              this.jobFilter(element, index);
              break;

            case 'LEASES_MAIN':
              this.filterData(element, leaseFields);
              break;

            case 'LEASE_RULES':
              this.filterData(element, leaseRulesFields);
              break;

            case 'PROPERTY':
              this.propertyFilter(element, index);
              break;

            case 'JOB_SHEDULE':
              this.jobScheduleFilter(element, index);
              break;

            case 'JOB_COST':
              this.jobCostFilter(element, index);
              break;

            case 'JOB_TIMESHEET':
              this.jobTimeSheetFilter(element, index);
              break;

            case 'JOB_MATERIAL':
              this.jobMaterialFilter(element, index);
              break;

            case 'LEASE_PROVISIONS':
              this.filterData(element, leaseProvisionFields);
              break;

            case 'LEASE_DOCUMENTS':
              this.filterData(element, leaseDocumentsFields);
              break;

            case 'LEASE_INSPECTIONS':
              this.filterData(element, leaseInspectionFields);
              break;

            case 'LEASE_CONTACTS':
              this.filterData(element, leaseContactsFields);
              break;

            case 'LEASE_CATEGORIES':
              this.filterData(element, leaseCategoriesFields);
              break;

            case 'PROPERTY':
              this.propertyFilter(element, index);
              break;

            case 'JOB_SHEDULE':
            case 'JOB_SCHEDULE':
              this.jobScheduleFilter(element, index);
              break;

            case 'JOB_COST':
              this.jobCostFilter(element, index);
              break;

            case 'JOB_TIMESHEET':
              this.jobTimeSheetFilter(element, index);
              break;

            case 'JOB_MATERIAL':
              this.jobMaterialFilter(element, index);
              break;

            case 'JOB_CHECKLIST':
              this.jobCheckListFilter(element, index);
              break;

            case 'JOB_SITE_VISIT':
              this.jobSiteVisitFilter(element, index);
              break;

            case 'COI':
              this.filterData(element, COIFields);
              break;

            case 'COI_COVERAGES':
              this.filterData(element, coveragesFields);
              break;

            case 'PROPERTY_GROUP':
              this.propertyGroupFilter(element,index);

            default:
              break;
          }
        });
      }


    }
  }

  equipmentFilter(data, index) {
    if (data.action == 'CREATE') {
      data.show = false;
      data.changes.forEach(element => {
        for (const key in element) {
          if (element.hasOwnProperty(key)) {
            if (!EQUIPMENT_CREATE_SHOW_FIELDS.includes(key)) {
              delete element[key];
            } else {
              data[key] = element[key].new;
            }
          }
        }
      });
    }
    if (data.action == 'UPDATE') {
      data.changes.forEach(element => {
        for (const key in element) {
          if (element.hasOwnProperty(key)) {
            if (EQUIPMENT_UPDATE_DELETE_FIELDS.includes(key)) {
              delete element[key];
            }
          }
        }
      });

    }
  }

  jobCheckListFilter(data, index) {
    if (data.action == 'CREATE') {
      data.show = this.isPDF_Preview ? true : false;
      data.changes.forEach(element => {
        for (const key in element) {
          if (element.hasOwnProperty(key)) {
            if (!JOB_SCHEDULE_CREATE_SHOW_FIELDS.includes(key)) {
              delete element[key];
            } else {
              data[key] = element[key].new;
            }
          }
        }
      });
    }
    if (data.action == 'UPDATE') {
      let changes = [];
      data.changes.forEach((element) => {
        for (const key in element) {
          if (element[key].new?.length == 0 && element[key].old?.length == 0) {
            delete element[key];
            break;
          }
          if (JSON.stringify(element[key].new) == JSON.stringify(element[key].old)) {
            delete element[key];
            break;
          }
          if (key == 'data') {
            const difference = this.findDifferences(element[key].old[0], element[key].new[0]);
            difference.forEach(element => {
              let obj;
              if (element.display == 'status') {
                obj = {
                  [element.title + ' ' + element.display]: {
                    'new': element.valueInNewData,
                    'old': element.valueInOldData,
                  },
                };
              }
              if (element.display == 'comments_details') {
                obj = {
                  ['Comments']: {
                    'new': element.valueInNewData?.text,
                    'old': element.valueInOldData,
                  },
                };
              }
              changes.push(obj);
            });
            break;
          }
        }
      });
      // TODO : SHOULD REMOVE UNWANTED VALUES
      // changes.forEach((ele,i) => {
      // });
      data.changes = changes;
    }

  }

  findDifferences(oldData, newData) {
    let result = [];

    function compareObjects(oldObj, newObj, path = '') {
      for (let key in oldObj) {
        if (oldObj.hasOwnProperty(key)) {
          let newPath = path ? `${path}.${key}` : key;
          let display = key;
          if (key === 'items' || key === 'comments_details') {
            compareArraysOfObjects(oldObj[key], newObj[key], newPath, display);
          } else if (_.isObject(oldObj[key]) && _.isObject(newObj[key])) {
            compareObjects(oldObj[key], newObj[key], newPath);
          } else if (!_.isEqual(oldObj[key], newObj[key])) {
            result.push({
              path: newPath,
              valueInOldData: _.get(oldData, newPath),
              valueInNewData: _.get(newData, newPath),
              data: 'both',
              display,
              title: newData?.name,
            });
          }
        }
      }

      for (let key in newObj) {
        if (newObj.hasOwnProperty(key) && !oldObj.hasOwnProperty(key)) {
          let newPath = path ? `${path}.${key}` : key;
          let display = key;
          result.push({ path: newPath, valueInNewData: _.get(newData, newPath), data: 'new', display });
        }
      }
    }

    function compareArraysOfObjects(oldArr, newArr, path, display) {
      oldArr.forEach((oldItem, index) => {
        let newItem = newArr.find(item => item.id === oldItem.id);
        if (newItem) {
          compareObjects(oldItem, newItem, `${path}[${index}]`);
        } else {
          result.push({ path: `${path}[${index}]`, valueInOldData: oldItem, data: 'old', display });
        }
      });

      newArr.forEach((newItem, index) => {
        let oldItem = oldArr.find(item => item.id === newItem.id);
        if (!oldItem) {
          result.push({ path: `${path}[${index}]`, valueInNewData: newItem, data: 'new', display });
        }
      });
    }

    compareObjects(oldData, newData);
    return result;
  }

  jobSiteVisitFilter(data, index) {
    if (data.action == 'CREATE') {
      data.show = this.isPDF_Preview ? true : false;
      data.changes.forEach(element => {
        for (const key in element) {
          if (element.hasOwnProperty(key)) {
            if (!JOB_SITE_VISIT_SHOW_FIELDS.includes(key)) {
              delete element[key];
            } else {
              switch (key) {
                case '_from':
                  data[key] = this.convertTo12Hour(element[key].new);
                  break;
                case 'to':
                  data[key] = this.convertTo12Hour(element[key].new);
                  break;
                case 'date':
                  data[key] = element[key].new.replace(/(\d{4})-(\d{2})-(\d{2})/, '$2/$3/$1');
                  break;
                default:
                  data[key] = element[key].new;
              }
            }
          }
        }
      });
    }
    if (data.action == 'UPDATE') {
      data.changes.forEach((element) => {
        for (const key in element) {
          if (element[key].new?.length == 0 && element[key].old?.length == 0) {
            delete element[key];
            break;
          }
          if (JSON.stringify(element[key].new) == JSON.stringify(element[key].old)) {
            delete element[key];
            break;
          }
          if (key == 'date') {
            element[key].new = element[key].new.replace(/(\d{4})-(\d{2})-(\d{2})/, '$2/$3/$1');
            element[key].old = element[key].old.replace(/(\d{4})-(\d{2})-(\d{2})/, '$2/$3/$1');
          }
          if (key == '_from') {
            element[key].new = this.convertTo12Hour(element[key].new);
            element[key].old = this.convertTo12Hour(element[key].old);
          }
          if (key == 'to') {
            element[key].new = this.convertTo12Hour(element[key].new);
            element[key].old = this.convertTo12Hour(element[key].old);
          }
          if (key == 'photo') {
            delete element[key];
          }
        }
      });
      data.changes = data?.changes.filter(value => Object.keys(value).length !== 0);
    }

  }

  jobScheduleFilter(data, index) {
    if (data.action == 'CREATE') {
      data.show = this.isPDF_Preview ? true : false;
      data.changes.forEach(element => {
        for (const key in element) {
          if (element.hasOwnProperty(key)) {
            if (!JOB_SCHEDULE_CREATE_SHOW_FIELDS.includes(key)) {
              delete element[key];
            } else {
              switch (key) {
                case '_from':
                  data[key] = this.convertTo12Hour(element[key].new);
                  break;
                case 'to':
                  data[key] = this.convertTo12Hour(element[key].new);
                  break;
                case 'date':
                  data[key] = element[key].new.replace(/(\d{4})-(\d{2})-(\d{2})/, '$2/$3/$1');
                  break;
                default:
                  data[key] = element[key].new;
              }
            }
          }
        }
      });
    }
    if (data.action == 'UPDATE') {
      data.changes.forEach((element) => {
        for (const key in element) {
          if (element[key].new?.length == 0 && element[key].old?.length == 0) {
            delete element[key];
            break;
          }
          if (JSON.stringify(element[key].new) == JSON.stringify(element[key].old)) {
            delete element[key];
            break;
          }
        }
      });
      data.changes = data?.changes.filter(value => Object.keys(value).length !== 0);
    }

  }

  jobMaterialFilter(data, index) {
    if (data.action == 'CREATE') {
      data.show = this.isPDF_Preview ? true : false;
      data.changes.forEach(element => {
        for (const key in element) {
          if (element.hasOwnProperty(key)) {
            if (!JOB_MATERIAL_CREATE_SHOW_FIELDS.includes(key)) {
              delete element[key];
            } else if (key == 'equipment') {
              let equipmentList = '';
              if (element[key]?.new) {
                element[key]?.new.forEach(element => {
                  equipmentList = ' ' + equipmentList + ' ' + element?.name;
                });
                data[key] = equipmentList;
              }
            } else {
              data[key] = element[key].new;
            }
          }
        }
      });
    }
    if (data.action == 'UPDATE') {
      data.changes.forEach((element) => {
        for (const key in element) {
          if (element[key].new?.length == 0 && element[key].old?.length == 0) {
            delete element[key];
            break;
          }
          if (JSON.stringify(element[key].new) == JSON.stringify(element[key].old)) {
            delete element[key];
            break;
          }
        }
      });
      data.changes = data?.changes.filter(value => Object.keys(value).length !== 0);
    }

  }

  jobTimeSheetFilter(data, index) {
    if (data.action == 'CREATE') {
      data.show = this.isPDF_Preview ? true : false;
      data.changes.forEach(element => {
        for (const key in element) {
          if (element.hasOwnProperty(key)) {
            if (!JOB_TIMESHEET_CREATE_SHOW_FIELDS.includes(key)) {
              delete element[key];
            } else {
              switch (key) {
                case '_from':
                  data[key] = this.convertTo12Hour(element[key].new);
                  break;
                case 'to':
                  data[key] = this.convertTo12Hour(element[key].new);
                  break;
                case 'engineer':
                  data[key] = element[key].new.name;
                  break;
                case 'date':
                  data[key] = element[key].new.replace(/(\d{4})-(\d{2})-(\d{2})/, '$2/$3/$1');
                  break;
                case 'labor_time':
                  data[key] = this.convertToHours(element[key].new);
                  break;
                case 'equipment':
                  let equipmentList = '';
                  if (element[key]?.new) {
                    element[key]?.new.forEach(element => {
                      equipmentList = ' ' + equipmentList + ' ' + element?.name;
                    });
                    data[key] = equipmentList;
                  }
                  break;
                default:
                  data[key] = element[key].new;
              }
            }
          }
        }
      });

    }

    if (data.action == 'UPDATE') {
      data.changes.forEach((element) => {
        for (const key in element) {
          if (element[key].new?.length == 0 && element[key].old?.length == 0) {
            delete element[key];
            break;
          }
          if (JSON.stringify(element[key].new) == JSON.stringify(element[key].old)) {
            delete element[key];
            break;
          }

          switch (key) {
            case 'labor_time':
              element[key].new = this.convertToHours(element[key].new);
              element[key].old = this.convertToHours(element[key].old);
              break;

            case '_from':
              element[key].old = this.convertTo12Hour(element[key].old);
              element[key].new = this.convertTo12Hour(element[key].new);
              break;

            case 'to':
              element[key].old = this.convertTo12Hour(element[key].old);
              element[key].new = this.convertTo12Hour(element[key].new);
              break;

            case 'engineer':
              this.handleKeyValueData(element[key]);
              break;
          }
        }
      });
      data.changes = data?.changes.filter(value => Object.keys(value).length !== 0);
    }

  }

  jobCostFilter(data, index) {
    if (data.action == 'CREATE') {
      data.show = this.isPDF_Preview ? true : false;
      data.changes.forEach(element => {
        for (const key in element) {
          if (element.hasOwnProperty(key)) {
            if (!JOB_COST_CREATE_SHOW_FIELDS.includes(key)) {
              delete element[key];
            } else if (key == 'equipment') {
              let equipmentList = '';
              if (element[key]?.new) {
                element[key]?.new.forEach(element => {
                  equipmentList = ' ' + equipmentList + ' ' + element?.name;
                });
                data[key] = equipmentList;
              }
            } else {
              data[key] = element[key].new;
            }
          }
        }
      });
    }
    if (data.action == 'UPDATE') {
      data.changes.forEach((element) => {
        for (const key in element) {
          if (element[key].new?.length == 0 && element[key].old?.length == 0) {
            delete element[key];
            break;
          }
          if (JSON.stringify(element[key].new) == JSON.stringify(element[key].old)) {
            delete element[key];
            break;
          }
          if (key == 'documents') {
            delete element[key];
          }
          if (key == 'photo') {
            delete element[key];
          }
        }
      });
      data.changes = data?.changes.filter(value => Object.keys(value).length !== 0);
    }

  }

  propertyFilter(data, index) {
    if (data.action == 'CREATE') {
      data.show = this.isPDF_Preview ? true : false;
      data.changes.forEach(element => {
        for (const key in element) {
          if (element.hasOwnProperty(key)) {
            if (!PROPERTY_CREATE_SHOW_FIELDS.includes(key)) {
              delete element[key];
            } else {
              data[key] = element[key].new;
            }
          }
          if (key == 'address') {
            data[key] = `${element[key].new.address != null ? element[key].new.address : ''} ${element[key].new.city != null ? element[key].new.city.name : ''} ${element[key].new.state != null ? element[key].new.state.name : ''} ${element[key].new.country != null ? element[key].new.country.name : ''}`;
          }
          if (key == 'categories') {
            let category: string = '';
            if (element[key].new.length > 0 && element[key].new != null) {
              element[key].new.forEach(element => {
                category = ' ' + element.name + ' ';
              });
              data[key] = category;
            } else {
              data[key] = '-';
            }
          }
        }
      });

    }
    if (data.action == 'UPDATE') {
      data.changes.forEach((element) => {
        for (const key in element) {
          if (element[key].new?.length == 0 && element[key].old?.length == 0) {
            delete element[key];
            break;
          }
          if (JSON.stringify(element[key].new) == JSON.stringify(element[key].old)) {
            delete element[key];
            break;
          }
          switch (key) {
            case 'management_agreement_limit':
              data['title'] = 'Management Agreement Limit';
              break;

            case 'type':
              data['title'] = 'Type';
              break;

            case 'property_type':
              data['title'] = 'Property Type';
              break;

            case 'name':
              data['title'] = 'Name';
              break;

            case 'lease_type':
              data['title'] = 'Lease Type';
              break;

            case 'type_of_tenant':
              data['title'] = 'Type of Tenant';
              break;

            case 'total_square_feet':
              data['title'] = 'Total Square Feet';
              break;

            case 'property_active':
              data['title'] = 'Property Active'; 
              element[key].new = element[key].new ? 'YES' : 'NO';
              element[key].old = element[key].old ? 'YES' : 'NO';
              break;

            case 'owner':
              data['title'] = 'Owner';
              element[key].new = element[key].new?.name;
              element[key].old = element[key].old?.name;
              break;

            case 'categories':
              data['title'] = 'Categories';
              let newTypes = '';
              let oldTypes = '';
              element[key]?.new.forEach(element => {
                newTypes += ' ' + element.name + ' ';
              });
              element[key]?.old.forEach(element => {
                oldTypes += ' ' + element.name + ' ';
              });
              element[key].new = newTypes;
              element[key].old = oldTypes;
              break;

            default:
              delete element[key];
              // If the key doesn't match any of the cases, you can handle it here.
              break;
          }

        }
      });
      data.changes = data?.changes.filter(value => Object.keys(value).length !== 0);
    }
  }

  companyFilter(data, index) {
    if (data.action == 'CREATE') {
      data.show = this.isPDF_Preview ? true : false;
      data.changes.forEach(element => {
        for (const key in element) {
          if (element.hasOwnProperty(key)) {
            if (!COMPANY_CREATE_SHOW_FIELDS.includes(key)) {
              delete element[key];
            } else {
              data[key] = element[key].new;
            }
          }
        }
      });
    }
    if (data.action == 'UPDATE') {
      data.changes.forEach(element => {
        for (const key in element) {
          if (element[key].new?.length == 0 && element[key].old?.length == 0) {
            delete element[key];
            continue;
          }

          if (JSON.stringify(element[key].new) == JSON.stringify(element[key].old)) {
            delete element[key];
            continue;
          }
          if (key == 'emails') {
            const difference = _.differenceWith(element[key].new, element[key].old, (obj1, obj2) => {
              return _.isEqual(obj1, obj2);
            });
            const email = difference[0]?.email + ' Type: ' + difference[0]?.type + ' Primary: ' + (difference[0]?.primary ? 'yes' : 'no');
            element[key].new = email;
            element[key].old = '';
            continue;
          }
          if (key == 'phones') {
            // element = 'phone  numbers'
            const difference = _.differenceWith(element[key].new, element[key].old, (obj1, obj2) => {
              return _.isEqual(obj1, obj2);
            });
            const phone = difference[0]?.phone + ' Type: ' + difference[0]?.type + ' Primary: ' + (difference[0]?.primary ? 'yes' : 'no');
            element[key].new = phone;
            element[key].old = '';
            continue;
          }

          if (key == 'addresses') {
            let addressOld: any = '';
            let addressNew: any = '';
            if (element[key].old.length == 0) {
              element[key].old = '';
            } else {
              addressOld = `${element[key].old[0]?.address != null ? element[key].old[0]?.address : ''},${element[key].old[0].city != null ? element[key].old[0].city : ''} ${element[key].old[0]?.state != null ? element[key].old[0]?.state : ''},${element[key].old[0]?.country != null ? element[key].old[0]?.country : ''},${element[key].old[0]?.zipcode != null ? element[key].old[0].zipcode : ''} primary:${element[key].old[0]?.primary != null ? 'yes' : 'no'} type:${element[key].old[0]?.type != null ? element[key].old[0]?.type : ''} `;
              addressOld.replace(null, '');
              element[key].old = addressOld;
            }
            if (element[key].new.length == 0) {
              element[key].new = '';
            } else {
              addressNew = `${element[key].new[0]?.address != null ? element[key].new[0]?.address : ''},${element[key].new[0].city != null ? element[key].new[0].city : ''} ${element[key].new[0]?.state != null ? element[key].new[0]?.state : ''},${element[key].new[0]?.country != null ? element[key].new[0]?.country : ''},${element[key].new[0]?.zipcode != null ? element[key].new[0].zipcode : ''} primary:${element[key].new[0]?.primary != null ? 'yes' : 'no'} type:${element[key].new[0]?.type != null ? element[key].new[0]?.type : ''} `;
              addressNew.replaceAll(null, '');
              element[key].new = addressNew;
            }
          }
        }
      });

    }

  }

  contactFilter(data, index) {
    if (data.action == 'CREATE') {
      data.show = this.isPDF_Preview ? true : false;
      data.changes.forEach(element => {
        for (const key in element) {
          if (element.hasOwnProperty(key)) {
            if (!CONTACT_CREATE_SHOW_FIELDS.includes(key)) {
              delete element[key];
            } else {
              data[key] = element[key].new;
            }
          }
        }
      });


    }


    if (data.action == 'UPDATE') {
      data.changes.forEach(element => {
        for (const key in element) {
          if (element[key].new?.length == 0 && element[key].old?.length == 0) {
            delete element[key];
            continue;
          }

          if (JSON.stringify(element[key].new) == JSON.stringify(element[key].old)) {
            delete element[key];
            continue;
          }
          if (key == 'emails') {
            const difference = _.differenceWith(element[key].new, element[key].old, (obj1, obj2) => {
              return _.isEqual(obj1, obj2);
            });
            const email = difference[0]?.email + ' Type: ' + difference[0]?.type + ' Primary: ' + (difference[0]?.primary ? 'yes' : 'no');
            element[key].new = email;
            element[key].old = '';
            continue;
          }
          if (key == 'phones') {
            const difference = _.differenceWith(element[key].new, element[key].old, (obj1, obj2) => {
              return _.isEqual(obj1, obj2);
            });
            const phone = difference[0]?.phone + ' Type: ' + difference[0]?.type + ' Primary: ' + (difference[0]?.primary ? 'yes' : 'no');
            element[key].new = phone;
            element[key].old = '';
            continue;
          }
        }
      });

    }

  }

  filterData(data, checkFields) {
    if (data.action == 'CREATE') {
      data.show = this.isPDF_Preview ? true : false;
      data.changes.forEach((element) => {
        for (const key in element) {
          if (element.hasOwnProperty(key)) {
            if (!checkFields.includes(key)) {
              delete element[key];
            } else {
              data[key] = element[key].new;
            }
          }
        }
      });
    }

    if (data.action == 'UPDATE') {
      data.changes.forEach((element) => {
        for (const key in element) {
          if (element[key].new?.length == 0 && element[key].old?.length == 0) {
            delete element[key];
            continue;
          }
          if (JSON.stringify(element[key].new) == JSON.stringify(element[key].old)) {
            delete element[key];
            continue;
          }
        }
      });
    }
  }

  jobFilter(data, index) {
    if (data.action == 'CREATE') {
      data.show = this.isPDF_Preview ? true : false;
      data.changes.forEach(element => {
        for (const key in element) {
          if (element.hasOwnProperty(key)) {
            if (!JOB_CREATE_SHOW_FIELDS.includes(key)) {
              if (key != 'description') {
                delete element[key];
              }
            } else {
              data[key] = element[key].new?.name;
            }
            if (key == 'address') {
              if(element[key]?.new){
                data[key] = `${element[key]?.new?.address != null ? element[key].new.address : ''} ${element[key].new.city != null ? element[key].new.city : ''} ${element[key].new.state != null ? element[key].new.state : ''}`;
              }
              else{
                data[key] ="";
              }
            }
            if (key == 'description') {
              data[key] = element[key].new;
            }
          }
        }
      });
    }

    if (data.action == 'UPDATE') {
      data.changes.forEach(element => {
        for (const key in element) {
          if (element[key].new?.length == 0 && element[key].old?.length == 0) {
            delete element[key];
            continue;
          }
          if (JSON.stringify(element[key].new) == JSON.stringify(element[key].old)) {
            delete element[key];
            continue;
          }
          switch (key) {
            case 'stage':
              data['title'] = 'status';
              let stageChanges = _.xorBy(element[key].new, element[key].old, 'name');
              element[key].new = element[key].new?.name;
              element[key].old = element[key].old?.name;
              break;

            case 'followers':
              data['title'] = 'followers';
              this.handleListData(element[key], 'full_name');
              break;

            case 'vendors':
              data['title'] = 'vendors';
              this.handleListData(element[key], 'company');
              break;

            case 'assignee':
              data['title'] = 'managers';
              element[key].new = element[key]?.new?.full_name;
              element[key].old = element[key]?.old?.full_name;
              break;

            case 'priority':
              data['title'] = 'priority';
              this.handleKeyValueData(element[key]);
              break;

            case 'service_type':
              data['title'] = 'service type';
              this.handleKeyValueData(element[key]);
              break;

            case 'issue_type':
              data['title'] = 'Brief description';
              element[key].new = element[key]?.new;
              element[key].old = element[key]?.old;
              break;

            case 'target_date':
              data['title'] = 'target date';
              element[key].new = element[key].new.replace(/(\d{4})-(\d{2})-(\d{2})/, '$2/$3/$1');
              element[key].old = element[key].old.replace(/(\d{4})-(\d{2})-(\d{2})/, '$2/$3/$1');
              break;

            case 'access_instruction':
              data['title'] = 'access instruction';
              break;

            case 'description':
              data['title'] = 'Detailed description';
              break;

            case 'assigned_checklist':
              data['title'] = 'assigned Checklist';
              break;

            case 'equipment':
              data['title'] = 'equipment';
              if (typeof element[key].new == 'string' && typeof element[key].old == 'string') {
                element[key].new = element[key].new;
                element[key].old = element[key].old;
              } else {
                this.handleListData(element[key], 'name');
              }
              break;

            case 'unit':
              data['title'] = 'unit';
              element[key].new = element[key]?.new?.name;
              element[key].old = element[key]?.old?.name;
              break;

            case 'category':
              data['title'] = 'category';
              this.handleKeyValueData(element[key]);
              break;

            case 'address':
              data['title'] = 'address';
              let oldAddress = `${element[key].old.address != null ? element[key].old.address : ''} ${element[key].old.city != null ? element[key].old.city : ''} ${element[key].old.state != null ? element[key].old.state : ''}`;
              let newAddress = `${element[key].new.address != null ? element[key].new.address : ''} ${element[key].new.city != null ? element[key].new.city : ''} ${element[key].new.state != null ? element[key].new.state : ''}`;
              element[key].new = newAddress;
              element[key].old = oldAddress;
              break;

            case 'unit_entry_permission':
              data['title'] = 'Permission to Enter Unit';
              this.handleBooleanData(element[key]);
              break;

            case 'request_feedback_days':
              data['title'] = 'Request Feedback On Close in days';
              break;

            case 'request_feedback_on_close':
              data['title'] = 'Request Feedback On Close';
              this.handleBooleanData(element[key]);
              break;

            case 'confirmation_on_close':
              data['title'] = 'Confirmation On Close';
              this.handleBooleanData(element[key]);
              break;

            case 'request_completion_photos':
              data['title'] = 'Completion Verification Photos';
              this.handleBooleanData(element[key]);
              break;

            case 'courtesy_job':
              data['title'] = 'Courtesy Job';
              this.handleBooleanData(element[key]);
              break;

            case 'engineers':
              data['title'] = 'engineers';
              this.handleListData(element[key], 'full_name');
              break;

            case 'linked_jobs':
              let oldJobs = '';
              let newJobs = '';
              if (element[key].old?.jobs) {
                element[key].old?.jobs.forEach(element => {
                  oldJobs = ' ' + oldJobs + ' ' + element;
                });
                element[key].old = oldJobs;
              }
              if (element[key].new?.jobs) {
                element[key].new?.jobs.forEach(element => {
                  newJobs = ' ' + newJobs + ' ' + element;
                });
                element[key].new = newJobs;
              }
              break;

            default:
            // handle default case if needed
          }

        }
      });
      data.changes = data?.changes.filter(value => Object.keys(value).length !== 0);

      data.changes = this._activityService.transformActivityJobs(data.changes);

      if (data.changes.length == 0) {
        data.title = 'Job';
        const descriptionChange = [{ description: { new: [data.description] } }];
        data.changes = descriptionChange;
      }

    }

  }

  handleListData(listData: any, propertyName: any) {
    let newList = '';
    let oldList = '';
    if (Array.isArray(listData?.new) && listData?.new?.length > 0) {
      listData?.new?.forEach(element => {
        if (newList == '') {
          newList = element[propertyName];
        } else {
          newList = newList + ', ' + element[propertyName];
        }
      });
    }
    if (Array.isArray(listData?.old) && listData?.old?.length > 0) {
      listData?.old?.forEach(element => {
        if (oldList == '') {
          oldList = element[propertyName];
        } else {
          oldList = oldList + ', ' + element[propertyName];
        }
      });
    }
    listData.new = newList;
    listData.old = oldList;
  }

  handleKeyValueData(keyValueData) {
    keyValueData.new = keyValueData?.new?.name;
    keyValueData.old = keyValueData?.old?.name;
  }

  handleBooleanData(booleanData) {
    booleanData.new = booleanData.new ? 'YES' : 'NO';
    booleanData.old = booleanData.old ? 'YES' : 'NO';
  }

  showMoreisClicked(data) {
    data.show = this.isPDF_Preview ? true : false;
  }

  showLessisClicked(data) {
    data.show = true;
  }

  onScrollDown(e) {
    if (this.activities.length < this.totalCount) {
      this.activityNextPage.emit(true);
    }
  }

  onUp(e) {
  }

  makeGroupedHistory(data: any) {

    // this gives an object with dates as keys
    const groups = data?.reduce((groups: any, chat: any) => {
      chat.profilePic = chat.name.charAt(0) + chat.name.charAt(1);
      const date = chat.created_at.split('T')[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(chat);
      return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        dateString: this.time_ago(new Date(date)),
        activityHistory: groups[date],
      };
    });

    return groupArrays;

  }

  time_ago(time: any) {
    let getDate = time;
    switch (typeof time) {
      case 'number':
        break;
      case 'string':
        time = +new Date(time);
        break;
      case 'object':
        if (time.constructor === Date) time = time.getTime();
        break;
      default:
        time = +new Date();
    }
    var todayDate = formatDate(new Date(), 'y-M-d', 'en-US');
    var getChatCurrentDate = formatDate(new Date(getDate), 'y-M-d', 'en-US');
    var time_formats = [
      [60, 'seconds', 1], // 60
      [120, '1 minute ago', '1 minute from now'], // 60*2
      [3600, 'minutes', 60], // 60*60, 60
      [7200, '1 hour ago', '1 hour from now'], // 60*60*2
      [86400, 'hours', 3600], // 60*60*24, 60*60
      [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
      [604800, 'days', 86400], // 60*60*24*7, 60*60*24
      [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
      [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
      [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
      [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
      [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
      [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
      [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
      [58060800000, 'centuries', 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];
    var seconds = (+new Date() - time) / 1000,
      token = 'ago',
      list_choice = 1;

    if (seconds == 0) {
      return 'Just now';
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      token = 'from now';
      list_choice = 2;
    }
    if (todayDate == getChatCurrentDate) {
      return 'Today';
    }
    if (seconds > 172800) {
      var getChatDate = new Date(getDate);
      return formatDate(getChatDate, 'EEEE, MMMM d', 'en-US');
    }
    var i = 0,
      format;
    while (format = time_formats[i++])
      if (seconds < format[0]) {
        if (typeof format[2] == 'string')
          return format[list_choice];
        else
          return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
      }
    return time;
  }

  get sortData() {
    return this.activityArrayData.sort((a: any, b: any) => {
      return <any>new Date(a.date) - <any>new Date(b.date);
    });
  }

  formatPhoneNumber(phoneNumber) {
    if (phoneNumber == undefined) {
      return;
    }
    // Remove all non-digit characters from the phone number
    phoneNumber = phoneNumber.toString().replace(/\D/g, '');

    // Apply the US phone number format
    phoneNumber = phoneNumber.replace(
      /^(\d{3})(\d{3})(\d{4})$/,
      '($1) $2-$3',
    );

    return phoneNumber;
  }

  formatDate(date: any) {
    if (!date) return date;
    return moment(date).format('MM.DD.YYYY');
  }

  parseUnits(units: any, checking: boolean = false): string {
    if (!checking) {
      if (Array.isArray(units)) {
        return units?.map(v => v.name)?.join(', ');
      }
    } else {
      if (Array.isArray(units) && ![undefined, null].includes(units)) {
        return units?.map(v => v.id)?.join(', ');
      }
    }
  }

  convertTo12Hour(time) {

    if (time == null) {
      return '';
    }
    let [hours, minutes] = time.split(':');
    let modifier = +hours < 12 ? 'AM' : 'PM';

    if (hours === '00') {
      hours = '12';
    } else if (+hours > 12) {
      hours = (+hours - 12).toString().padStart(2, '0');
    }
    return `${hours}:${minutes} ${modifier}`;
  }

  convertToHours(timeString) {
    const [hours, minutes, seconds] = timeString.split(':');
    const formattedHours = hours.startsWith('0') ? parseInt(hours) : hours;
    if (minutes && minutes !== '00') {
      const formattedMinutes = minutes.startsWith('0') ? parseInt(minutes) : minutes;
      return `${formattedHours} hrs ${formattedMinutes} mins`;
    }

    return `${formattedHours} hrs`;
  }

  filteredChanges(changes: any, type?: ACTIVITY_ENTITY_TYPE): { label: string; oldValue: string; newValue: string }[] {
    const isDifferentId = (value: any, key: string): boolean => {
      if (key.includes('units')) return true;
      if ((value['old']?.id) && (value['new']?.id)) return value['old']?.id !== value['new']?.id;
      return value['old'] !== value['new']?.id;
    };

    const isDifferentUnits = (key: string, value: any): boolean => {
      return key.includes('units') && value['old'] && (value['old'].id !== value['new'].id) ? value['old'].id === this.parseUnits(value['new'].id) : true;
    };

    return Object.entries(changes)
      .filter(
        ([key, value]) =>
          this.isNotSpecialKey(key, type) &&
          isDifferentId(value, key) &&
          isDifferentUnits(key, value),
      )
      .map(([key, value]) => (
        key.includes('categories') && value['old'].length !== value['new'].length
          ? {
            label: null,
            oldValue: null,
            newValue: this.getFormattedCategories(value['old'], value['new']),
          } : {
            label: this.getLabelByKey(key),
            oldValue: this.getFormattedValue(value['old'], key),
            newValue: this.getFormattedValue(value['new'], key),
          }));
  }

  getLabelByKey(key: any) {
    if (['move_in_checklist_id', 'move_out_checklist_id'].includes(key)) {
      key = key.replace('_id', '');
    }
    const regex = /(?:_)(\w)/g;
    const label = key.replace(regex, (_, match) => ` ${match.toUpperCase()}`);
    return label.charAt(0).toUpperCase() + label.slice(1);
  }

  getFormattedValue(value: any, key: string): any {
    switch (key) {
      case 'property':
      case 'tenant':
        return value.name;
      case 'units':
        return this.parseUnits(value);
      case 'effective_date':
      case 'commencement_date':
      case 'expiration_date':
        return this.formatDate(value);
      case 'is_month_to_month':
        return value ? 'Applied' : 'Not Applied';
      default:
        return value;
    }
  }

  getFormattedCategories = (oldArr: string[], newArr: string[]) => {
    const newArrRemove = [];
    const newArrAdded = [];

    newArr.forEach(v => {
      if (!oldArr.includes(v)) {
        newArrAdded.push(v);
      }
    })

    oldArr.forEach(v => {
      if (!newArr.includes(v)) {
        newArrRemove.push(v);
      }
    })
    return [...newArrAdded, ...newArrRemove].join(', ');
  };

  getActionText(activity: any, type: ACTIVITY_ENTITY_TYPE): string {
    if (type === ACTIVITY_ENTITY_TYPE.LEASE) {
      const activateInspectionChange = activity.changes.find((change: any) => change?.activate_inspection);
      const contactPrimaryChange = activity.changes.find((change: any) => change?.contact_primary);
      const unitsChange = activity.changes.find((change: any) => change?.units);
      const inspectionSchedulerChange = activity.changes.find((change: any) => change?.inspection_scheduler);

      if (inspectionSchedulerChange?.inspection_scheduler?.new === null) {
        return 'unassigned the inspection scheduler template for the lease.';
      } else if (inspectionSchedulerChange?.inspection_scheduler?.old === null) {
        return 'assigned the inspection scheduler template for the lease.';
      } else if (inspectionSchedulerChange?.inspection_scheduler?.new !== inspectionSchedulerChange?.inspection_scheduler?.old) {
        return 'changed the inspection scheduler template for the lease.';
      }

      if (unitsChange?.units && this.isArraysEqualBySomeId(unitsChange?.units.old, unitsChange?.units.new)) {
        let equalUnits: number = 0;
        const differenceEqualOne = (unitsLength: number): boolean => {
          return unitsLength - equalUnits === 1;
        };
        if (unitsChange.units.old.length > unitsChange.units.new.length) {
          unitsChange.units.old.forEach(o => unitsChange.units.new.forEach(n => n.id === o.id && ++equalUnits));
          if (differenceEqualOne(unitsChange.units?.old.length)) {
            return leaseActivityActions.REMOVED_UNIT;
          }
          return leaseActivityActions.REMOVED_UNITS;
        } else {
          unitsChange.units.new.forEach(o => unitsChange.units.old.forEach(n => n.id === o.id && ++equalUnits));
          if (differenceEqualOne(unitsChange.units?.new.length)) {
            return leaseActivityActions.ADDED_UNIT;
          }
          return leaseActivityActions.ADDED_UNITS;
        }
      }

      if (contactPrimaryChange) {
        const { new: newContact, old: oldContact } = contactPrimaryChange.contact_primary;
        return newContact === null ? leaseActivityActions.UNASSIGNED_PRIMARY_CONTACT : leaseActivityActions.ASSIGNED_PRIMARY_CONTACT;
      }

      if (activateInspectionChange) {
        const { new: newValue } = activateInspectionChange.activate_inspection;
        return newValue ? leaseActivityActions.ACTIVATED_INSPECTIONS : leaseActivityActions.UNACTIVATED_INSPECTIONS;
      }

      return leaseActivityActions.UPDATED;
    }

    if (type === ACTIVITY_ENTITY_TYPE.DOCUMENTS) {
      const documentRequested = activity.changes.find((change: any) => change?.abstract_requested_at);

      if (documentRequested) {
        return leaseActivityActions.REQUESTED_DOCUMENT;
      }
      return leaseActivityActions.UPDATED_DOCUMENT;
    }

    if (type === ACTIVITY_ENTITY_TYPE.CONTACTS) {
      const contactChange = activity.changes.find((change: any) => change?.phone || change?.contacts);
      if (contactChange?.phones?.old.length > 1 || contactChange?.contacts?.old.length > 1) {
        return leaseActivityActions.UNASSIGNED_CONTACTS;
      }
      return leaseActivityActions.UNASSIGNED_CONTACT;
    }

    if (type === ACTIVITY_ENTITY_TYPE.OTHER_CONTACTS) {
      const contactsChange = activity.changes.find((change) => change?.other_contacts);

      function countEqualContacts(oldContacts, newContacts) {
        let equalContacts = 0;
        for (const oldContact of oldContacts) {
          for (const newContact of newContacts) {
            if (oldContact.id === newContact.id) {
              equalContacts++;
            }
          }
        }
        return equalContacts;
      }

      function isDifferenceEqualOne(totalContacts, equalContacts) {
        return totalContacts - equalContacts === 1;
      }

      if (contactsChange) {
        const equalContacts = countEqualContacts(contactsChange.other_contacts.old, contactsChange.other_contacts.new);
        if (contactsChange.other_contacts.old.length > contactsChange.other_contacts.new.length) {
          if (isDifferenceEqualOne(contactsChange.other_contacts.old.length, equalContacts)) {
            return leaseActivityActions.UNASSIGNED_CONTACT;
          }
          return leaseActivityActions.UNASSIGNED_CONTACTS;
        } else if (contactsChange.other_contacts.new.length > contactsChange.other_contacts.old.length) {
          if (isDifferenceEqualOne(contactsChange.other_contacts.new.length, equalContacts)) {
            return leaseActivityActions.ASSIGNED_CONTACT;
          }
          return leaseActivityActions.ASSIGNED_CONTACTS;
        }
      }

      return leaseActivityActions.UPDATED_CONTACTS;
    }

    if (type === ACTIVITY_ENTITY_TYPE.INSPECTIONS) {
      const schedulerId = activity.changes.find((change: any) => change?.inspection_scheduler);
      if (schedulerId?.inspection_scheduler?.new !== schedulerId?.inspection_scheduler?.old) {
        return 'changed the inspection scheduler template for the lease.';
      }
      return 'edited the lease inspections for the lease.';
    }

    if (type === ACTIVITY_ENTITY_TYPE.COI) {
      if (activity.changes.length !== 1) {
        return COIActivityActions.UPDATED;
      }

      const changes = activity.changes[0];
      const { properties, categories } = changes;

      if (properties) {
        const oldProperties = properties.old || [];
        const newProperties = properties.new || [];

        const equalProperties = oldProperties.filter(oldProp => newProperties.includes(oldProp));

        if (oldProperties.length > newProperties.length) {
          return equalProperties.length === oldProperties.length - 1
            ? COIActivityActions.REMOVED_PROPERTY
            : COIActivityActions.REMOVED_PROPERTIES;
        } else if (newProperties.length > oldProperties.length) {
          return equalProperties.length === newProperties.length - 1
            ? COIActivityActions.ADDED_PROPERTY
            : COIActivityActions.ADDED_PROPERTIES;
        }

        return COIActivityActions.UPDATED_PROPERTIES;
      }

      if (categories) {
        const oldCategories = categories.old || [];
        const newCategories = categories.new || [];

        const equalCategories = oldCategories.filter(oldCategory => newCategories.includes(oldCategory));

        if (oldCategories.length > newCategories.length) {
          return equalCategories.length === oldCategories.length - 1
            ? COIActivityActions.REMOVED_CATEGORY
            : COIActivityActions.REMOVED_CATEGORIES;
        } else if (newCategories.length > oldCategories.length) {
          return equalCategories.length === newCategories.length - 1
            ? COIActivityActions.ADDED_CATEGORY
            : COIActivityActions.ADDED_CATEGORIES;
        }

        return COIActivityActions.UPDATED_CATEGORIES;
      }

      return COIActivityActions.UPDATED;
    }

  }

  checkName(item: any, status: string) {
    const value = item.value ? item.value[status] : item;
    switch (item.key) {
      case 'phones':
        return value?.map(v => v.phone)?.join(', ');
      case 'emails':
        return value?.map(v => v.email)?.join(', ');
      case 'assigned_document':
        return value?.map(v => v.name)?.join(', ');
      case 'document':
        return value ? value?.title ?? value?.filename : '-';
      case 'move_in_date':
      case 'move_out_date':
        return moment(value).format('YYYY-MM-DD');
    }
    if (Array.isArray(value)) return value?.map(v => v.name || [v.first_name, v.last_name]?.join(' '))?.join(', ');
    return value?.name ?? value;
  }

  isNotSpecialKey = (key: string, type?: ACTIVITY_ENTITY_TYPE) => {
    if ((['move_in_checklist_id', 'move_out_checklist_id'].includes(key)) || type === ACTIVITY_ENTITY_TYPE.COI) {
      return true;
    }
    return !specialWords.some((word) => key.includes(word));
  };

  isArraysEqualBySomeId(arr1, arr2): boolean {
    return !!arr1.filter((item1) => arr2.some(item2 => item2.id === item1.id)).length;
  }

  getVendorCompanyName(obj: any, index: number) {
    let VendorName = "";

    if (obj.key === 'status') {
      let indexVal = this.activities[index].changes.findIndex(obj1 => obj1.vendor_name);
      if (indexVal != -1) {
        VendorName = this.activities[index].changes[indexVal].vendor_name.old
      }
    }

    return VendorName;

  }


  propertyGroupFilter(data, index){
    if (data.action == 'CREATE') {
      data.show = false;
      data.changes.forEach(element => {
        for (const key in element) {
          if (element.hasOwnProperty(key)) {
              data[key] = element[key].new;
          }
        }
      });
    }
    if (data.action == 'UPDATE') {
      data.changes.forEach(element => {
        for (const key in element) {
          if (element[key].new?.length == 0 && element[key].old?.length == 0) {
            delete element[key];
            continue;
          }

          if (JSON.stringify(element[key].new) == JSON.stringify(element[key].old)) {
            delete element[key];
            continue;
          }
        }
      });
    }
  }

  /** Track by ID  **/
  trackById(index: number, item: any): number {
    return item.id;
  }

  /** Track by Index  **/
  trackByIndex(index: number, item: any): number {
    return index;
  }

  getTabHeight() {
    if(!this.filtersApplied) {
      return 'height: calc(' + this.contHeight + 'px - 167px)';
    } 
    else {
      return 'height: calc(' + this.contHeight + 'px - 247px)';
    }
  }
  
}
