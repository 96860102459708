import { FormGroup, FormControl, FormsModule } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { BaseService } from 'src/app/services/base.service';
import { JobsService } from 'src/app/services/v2/jobs.service';
import { environment } from 'src/environments/environment';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { CheckboxComponent } from '../../../_reusable_ui/_controls/checkbox/checkbox.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-associated-equipment-popup',
    templateUrl: './associated-equipment-popup.component.html',
    styleUrls: ['./associated-equipment-popup.component.scss'],
    standalone: true,
    imports: [NgIf, LoaderSmallComponent, NgFor, CheckboxComponent, FormsModule, NgxIntlTelInputModule, MatTooltipModule, MatDialogModule, ButtonComponent]
})
export class AssociatedEquipmentPopupComponent implements OnInit {

  equipmentList:any[];
  selectedItems:any[]=[];
  assignEquipment:boolean = false;
  searchInput:string = "";
  pageSize:number = 25;
  sortColumn:string=null;
  equipmentImagePlaceholder:string='../../../../../assets/@fyxt/images/images.png';
  form:FormGroup;
isLoader:boolean=false;
  constructor(
    public dialogRef: MatDialogRef<AssociatedEquipmentPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _baseService: BaseService,
    public _jobService: JobsService,
  ) {
  }

  ngOnInit(): void {
    this.assignEquipment = this.data?.data?.assignChecklist
    this.getAllEquipments(this.searchInput,1,this.pageSize);
  }

  getAllEquipments(searchInput:string,pageNumber:number,pageSize:number,sortColumn?:any,sortDirection?:any,filters?:any){
    this.isLoader=true;
    if(this.data?.data?.e.length > 0){
      let requestURL = environment.equipURL + `/jobs/equipment/?query={'id': ${JSON.stringify(this.data?.data?.e)}, 'property':[], 'property_name':[],'equipment_type':[],'assigned_id': []}&search=${searchInput}&columns=['id', 'image', 'name', 'equipment_type_name', 'category_name', 'specific_location', 'manufacturer_name', 'model_name', 'age', 'serial_number', 'asset_tag']&sort_column=${sortColumn?sortColumn:'name'}&sort_order=${sortDirection?sortDirection:'asc'}&page=${pageNumber}&size=${pageSize}`;
      this._baseService.doGET(requestURL).subscribe({
        next:(res:any)=>{
          this.equipmentList = res.items || [];
          this.equipmentList.forEach(element => {
            element.checkbox = false
            this.isLoader=false;
          })
        },
      })
    }

  }

  selectionChanges(item:any){
    this.selectedItems = this.equipmentList.filter(item => item.checkbox);
  }

  assignChecklist(){
    this.dialogRef.close(this.selectedItems.map(e=>e.id))
  }

   /** Track by Index  **/
   trackByIndex(index: number, item: any): number {
    return index;
  }
}
