// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
::ng-deep .mat-radio-label {
  white-space: break-spaces !important;
  align-items: self-start !important;
}

::ng-deep mat-radio-button {
  padding-right: 24px;
}

.description {
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #9fa7b3;
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_reusable_ui/_controls/radio/radio.component.scss"],"names":[],"mappings":"AACA,4HAAA;AACA,4HAAA;AACA;EACE,oCAAA;EACA,kCAAA;AAAF;;AAGA;EACI,mBAAA;AAAJ;;AAGA;EACE,2CAAA;EACA,mBAAA;EACA,cAAA;EACA,UAAA;AAAF","sourcesContent":["\n/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */\n/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */\n::ng-deep .mat-radio-label {\n  white-space: break-spaces !important;\n  align-items: self-start !important;\n}\n\n::ng-deep mat-radio-button {\n    padding-right: 24px;\n}\n\n.description {\n  font: normal normal normal 12px/16px Roboto;\n  letter-spacing: 0px;\n  color: #9fa7b3;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
