// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .search-box-container {
  width: 180px !important;
}

.border-right-header {
  border-right: 1px solid #C3C6D1;
}

.active-review {
  color: #d63c00 !important;
  font-weight: 500 !important;
  background: rgba(214, 60, 0, 0.0784313725);
}

.padding-12 {
  padding: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_views/_tab_components/tab-inbox-reviews/tab-inbox-reviews.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;;AAEA;EACE,+BAAA;AACF;;AAEA;EACE,yBAAA;EACA,2BAAA;EACA,0CAAA;AACF;;AAEA;EACE,aAAA;AACF","sourcesContent":[":host ::ng-deep .search-box-container {\n  width: 180px !important;\n}\n\n.border-right-header {\n  border-right: 1px solid #C3C6D1;\n}\n\n.active-review {\n  color: #d63c00 !important;\n  font-weight: 500 !important;\n  background: rgba(214, 60, 0, 0.0784313725);\n}\n\n.padding-12 {\n  padding: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
