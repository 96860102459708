import { Component, OnInit } from '@angular/core';
import { AddCompanyComponent } from '../../../_forms/add-company/add-company.component';

@Component({
    selector: 'fyxt-tab-company-details',
    templateUrl: './tab-company-details.component.html',
    styleUrls: ['./tab-company-details.component.scss'],
    standalone: true,
    imports: [AddCompanyComponent]
})
export class TabCompanyDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
