import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { JobSettingsService } from 'src/app/services/global-job-settings/job-settings.service';
import { environment } from 'src/environments/environment';
import { responseObj } from '..';
import { ConfigService } from 'src/app/services/config.service';
import { UtilityService } from 'src/app/services/utility.service';
import { JobsService } from 'src/app/services/v2/jobs.service';
import { BaseService } from 'src/app/services/base.service';
import { MatDividerModule } from '@angular/material/divider';
import { OneschemaButtonComponent } from '../../oneschema-button/oneschema-button.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../_reusable_ui/_components/button/button.component';
import { RadioComponent } from '../../_reusable_ui/_controls/radio/radio.component';
import { CheckboxComponent } from '../../_reusable_ui/_controls/checkbox/checkbox.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DropdownComponent } from '../../_reusable_ui/_controls/dropdown/dropdown.component';
import { TextEditorComponent } from '../../_reusable_ui/_controls/text-editor/text-editor.component';
import { TextboxComponent } from '../../_reusable_ui/_controls/textbox/textbox.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { SwitchComponent } from '../../_reusable_ui/_controls/switch/switch.component';
import { NgIf, NgClass, NgFor, AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'fyxt-global-job-settings',
    templateUrl: './global-job-settings.component.html',
    styleUrls: ['./global-job-settings.component.scss'],
    standalone: true,
    imports: [FormsModule, NgIf, NgClass, SwitchComponent, NgxIntlTelInputModule, TextboxComponent, TextEditorComponent, DropdownComponent, NgSelectModule, NgFor, CheckboxComponent, RadioComponent, ButtonComponent, MatIconModule, MatTooltipModule, OneschemaButtonComponent, MatDividerModule, MatDialogModule, AsyncPipe]
})
export class GlobalJobSettingsComponent implements OnInit {
  @Input() isFromSettings: boolean = false;
  @Input() tabType: string = 'Job Preferences';

  serviceTypeLoading: boolean = false;
  liveServiceTypeData: Observable<responseObj[]>;

  categoryLoading: boolean = false;
  liveCategoryList: Observable<responseObj[]>;
  allCategoryPagination: any = {
    pagination: { next: '', count: 0, total: 0, size: 100, pageCount: null, currentPage: 1 },
    tempData: []
  };

  globalSettings: any = {};
  newSettingsChanges: any = {};

  requestFeedbackCloseDays = Array.from({ length: 7 }, (v, k) => k + 1);
  contacts = [];
  selectedContacts = [];
  newSelectedContacts = [];
  isSubmitClicked: boolean = false;
  allStatusLists: any[] = [];
  showGlobalJobPreferenceStatus = environment.showGlobalJobPreferenceStatus;
  feedbackTemplate: any = {};
  feedbackTemplateSubject: string;

  targetCostOptions: any[] = [
    { name: 'Select Single Target Cost for All Jobs', value: 'single' },
    { name: 'Customize Target Costs by Property, Service Type, or Category', value: 'customize' }
  ];
  isExportLoader: boolean;
  isDeleteLoader: boolean;
  feedBackErrorMessage: any
  constructor(
    public dialogRef: MatDialogRef<GlobalJobSettingsComponent>,
    public changeDetector: ChangeDetectorRef,
    public _jobSettingService: JobSettingsService,
    public _Config: ConfigService,
    public _utilService: UtilityService,
    public _jobService: JobsService,
    public _baseService: BaseService,
    private toast: ToastrService,
  ) {
  }


  ngOnInit(): void {
    this.getGlobalJobStatus();

    if (this.isFromSettings) {
      this.getCommonServiceTypes();
      this.getCommonCategories({}, false);
    }
    else {
      this.getAllContactsList();
    }

  }

  ngAfterViewInit(): void {
    this.setFormData();
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this._jobSettingService.getAllGlobalSettings();
  }

  setFormData() {

    this.globalSettings = this._jobSettingService.globalSettings;
    this.globalSettings.isJobView = false;
    this.globalSettings.enable_target_cost_option = false;
    this.feedbackTemplate = this.globalSettings?.feedback_template;
    this.feedbackTemplateSubject = this.feedbackTemplate?.subject;



    if (this.isFromSettings) {
      let serviceTypes = this.globalSettings.service_type ? this.globalSettings.service_type.map(obj => obj.id) : null;
      let categories = this.globalSettings.category ? this.globalSettings.category.map(obj => obj.id) : null;

      this.globalSettings.number_of_bids = this.globalSettings?.number_of_bids ? this.globalSettings?.number_of_bids : 0;
      this.globalSettings.service_types = serviceTypes;
      this.globalSettings.categories = categories;

      this.globalSettings.notify_tenant = !this.globalSettings?.notify_tenant;
      this.globalSettings.show_compliant_vendors = this.globalSettings?.show_compliant_vendors;
      this.globalSettings.show_vendor_target_cost = this.globalSettings?.show_vendor_target_cost;
      this.globalSettings.require_jobcost_files = this.globalSettings?.require_jobcost_files;
      this.globalSettings.limit_jobcost_to_target_cost = this.globalSettings?.limit_jobcost_to_target_cost;
    }
    else {
      this.globalSettings.isJobView = true;
      this.globalSettings.require_site_visit = !this._jobService.selectedJobInfo?.skip_site_visit;
      this.globalSettings.require_bid = !this._jobService.selectedJobInfo?.skip_bid;
      this.globalSettings.courtesy_job = this._jobService.selectedJobInfo?.courtesy_job;
      this.globalSettings.notify_on_close = this._jobService.selectedJobInfo?.confirmation_on_close;
      this.globalSettings.tenant_notify_job = this._jobService.selectedJobInfo?.tenant_notify_job;
      this.globalSettings.request_feedback_on_close = this._jobService.selectedJobInfo?.request_feedback_on_close;
      this.globalSettings.request_feedback_days = this._jobService.selectedJobInfo?.request_feedback_days;
      this.globalSettings.request_completion_photos = this._jobService.selectedJobInfo?.request_completion_photos;
      this.globalSettings.property_entry_permission = this._jobService.selectedJobInfo?.property_entry_permission;
      this.globalSettings.unit_entry_permission = this._jobService.selectedJobInfo?.unit_entry_permission;
      this.globalSettings.notify_tenant = !this._jobService.selectedJobInfo?.notify_tenant;
      this.globalSettings.number_of_bids = this._jobService.selectedJobInfo?.number_of_bids ? this._jobService.selectedJobInfo?.number_of_bids : 0;
      this.globalSettings.show_compliant_vendors = this._jobService.selectedJobInfo?.show_compliant_vendors;
      this.globalSettings.show_vendor_target_cost = this._jobService.selectedJobInfo?.show_vendor_target_cost;
      this.globalSettings.require_jobcost_files = this._jobService.selectedJobInfo?.require_jobcost_files;
      this.globalSettings.limit_jobcost_to_target_cost = this._jobService.selectedJobInfo?.limit_jobcost_to_target_cost;
      
      if (this.showGlobalJobPreferenceStatus) {
        /***** Status Value Pre Populate ******/
        this.globalSettings.require_timesheet = this._jobService.selectedJobInfo?.require_timesheet || [];
        this.globalSettings.require_material = this._jobService.selectedJobInfo?.require_material || [];

        this.globalSettings.require_timesheet_approval = this._jobService.selectedJobInfo?.require_timesheet_approval || [];
        this.globalSettings.require_material_approval = this._jobService.selectedJobInfo?.require_material_approval || [];
        this.globalSettings.require_manager = this._jobService.selectedJobInfo?.require_manager || [];
        this.globalSettings.require_engineer = this._jobService.selectedJobInfo?.require_engineer || [];
      }

      this.globalSettings.show_compliant_vendors = this._jobService.selectedJobInfo?.show_compliant_vendors;
      // this.globalSettings.service_types = null;
      // this.globalSettings.categories = null;
    }
  }

  isEditablePermissions() {
    if (this.isFromSettings || this._baseService.currentUserInfo.current_Role == 'Owner') {
      return true;
    }
    else {
      return this._jobService.selectedJobInfo?.override_job_settings;
    }

    // return this._baseService.isPermissionExist('override_job_settings');
  }

  /**
   * Get All Global Setting Flags
   */
  getCommonServiceTypes() {
    let requestURL = environment.baseURL + 'jobs/service-type/';
    this.serviceTypeLoading = true;

    this._jobSettingService.doGET(requestURL)
      .subscribe({
        next: (response: any) => {
          this.liveServiceTypeData = this.getServiceType(response || []);
        },
        error: (error) => {
          this.serviceTypeLoading = false;
        },
        complete: () => {
          this.serviceTypeLoading = false;
        }
      });
  }

  getServiceType(response): Observable<any[]> {
    return of(response).pipe(delay(500));
  }


  /**
  * Get All Global Setting Flags
  */
  getCommonCategories(searchTerm?: any, isPagination?: boolean) {
    this.categoryLoading = true;

    // let requestURL = environment.baseURL + 'drop-down/categories/';
    // requestURL = requestURL.replace('v1', 'v2');

    let requestURL = environment.baseURL + 'categories/';

    // if (searchTerm && searchTerm.term) {
    //   if (requestURL.includes('?'))
    //     requestURL += '&search=' + searchTerm.term;
    //   else
    //     requestURL += '?search=' + searchTerm.term;
    // }

    // if (isPagination) {
    //   requestURL = this.allCategoryPagination.pagination.next;
    //   if (searchTerm.term) {
    //     requestURL += '&search=' + searchTerm.term;
    //   }
    // }

    this._jobSettingService.doGET(requestURL)
      .subscribe({
        next: (response: any) => {
          // this.allCategoryPagination.pagination.total = response.count;
          // this.allCategoryPagination.pagination.pageCount = Math.ceil(this.allCategoryPagination.pagination.total / this.allCategoryPagination.pagination.size);
          // this.allCategoryPagination.pagination.next = response.next ? response.next : '';
          // this.allCategoryPagination.pagination.count = response.count ? response.count : 0;

          // let ListItems = response.result || [];

          // if (ListItems.length > 0) {
          //   ListItems.forEach((element) => {
          //     element.search_label = element.name;
          //     if (searchTerm) {
          //       if (searchTerm.term)
          //         element.search_label += ', ' + searchTerm.term;
          //     }

          //   });
          // }

          // if (isPagination) {
          //   // this.allPropertyPagination.tempData.push(...ListItems);
          //   this.allCategoryPagination.tempData = [...this.allCategoryPagination.tempData, ...ListItems];

          // } else {
          //   this.allCategoryPagination.tempData = ListItems;
          // }

          // this.allCategoryPagination.tempData = [...this.allCategoryPagination.tempData];
          // this.liveCategoryList = this.setCategoryResult(this.allCategoryPagination.tempData || []);
          this.liveCategoryList = this.setCategoryResult(response || []);
        },
        error: (error) => {
          this.categoryLoading = false;
        },
        complete: () => {
          this.categoryLoading = false;
        }
      });
  }

  setCategoryResult(response): Observable<any[]> {
    return of(response);
  }


  /**
   * Save Preference Event
   */
  saveJobSettings() {
    if (Object.keys(this.newSettingsChanges)?.length > 0) {

      let newChangedSettings: any = {};
      Object.keys(this.newSettingsChanges).forEach(key => {

        /******* Check and Set Opposite values for few flags *******/
        switch (key) {
          case "require_bid":
            newChangedSettings['skip_bid'] = !this.newSettingsChanges[key];
            break;
          case "require_site_visit":
            newChangedSettings['skip_site_visit'] = !this.newSettingsChanges[key];
            break;
          case "notify_tenant":
            newChangedSettings['notify_tenant'] = !this.newSettingsChanges[key];
            break;
          case "show_compliant_vendors":
            newChangedSettings['show_compliant_vendors'] = this.newSettingsChanges[key];
            break;
          case "tenant_notify_job":
            {
              newChangedSettings['tenant_notify_job'] = this.newSettingsChanges[key];
              newChangedSettings['request_auto_response'] = this.newSettingsChanges[key];
            }
            break;
          case "notify_on_close":
            newChangedSettings['confirmation_on_close'] = this.newSettingsChanges[key];
            break;
          case "request_feedback_on_close":
            if (this.newSettingsChanges[key] === false) {
              newChangedSettings['request_feedback_on_close'] = this.newSettingsChanges[key];
              newChangedSettings['request_feedback_days'] = 1;
              newChangedSettings['feedback_contacts'] = [];
            } else {
              newChangedSettings['request_feedback_on_close'] = this.newSettingsChanges[key];
            }
            break;
          default:
            newChangedSettings[key] = this.newSettingsChanges[key];
            break;
        }

      });

      let postBody: any = newChangedSettings;

      if (!this.isFromSettings && this._jobService.selectedJobInfo.id) {
        let requestURL = environment.baseURL + this._Config.getSelectedJobDetails;
        requestURL = requestURL.replace('{ID}', this._jobService.selectedJobInfo.id);

        this.isSubmitClicked = true;
        this._jobSettingService.doPatch(requestURL, postBody)
          .subscribe({
            next: (response: any) => {

              this._utilService.showSuccess('Job ID: ' + response.id, 'Updated Successfully.');
              for (const key of Object.keys(newChangedSettings)) {
                this._jobService.selectedJobInfo[key] = response[key];
              }


            },
            error: (error) => {
              for (const key of Object.keys(newChangedSettings)) {
                this._jobService.selectedJobInfo[key] = !newChangedSettings[key];
              }

              this._utilService.showErrorMessage(error);
              this._Config.isLoader = false;
              this.isSubmitClicked = false;
            },
            complete: () => {
              this._Config.isLoader = false;
              this.isSubmitClicked = false;
              this.dialogRef.close();
            }
          });
      }
    }
    else {
      this.dialogRef.close();
    }
  }

  emitText(event?: any) {
    if(this.globalSettings.request_feedback_on_close && event === null) {
      this.toast.error('Subject and Body fields are required');
      return;
    }
    let feedback_template = {
      body: event,
      subject: this.feedbackTemplate.subject
    }
    this.globalSettings['feedback_template'] = feedback_template;

    this.newSettingsChanges['feedback_template'] = this.globalSettings['feedback_template'];

    if (this.isFromSettings) {
      this.updateChangeAPITriggers(this.globalSettings['feedback_template'], 'feedback_template');
      this.feedbackTemplate.body = event;
    }
  }

  onChangeFeedbackTemplate(key: any, event?: any) {
    if(this.feedbackTemplate.subject !== this.feedbackTemplateSubject) {

      if (key == 'feedback_template') {
        if(this.globalSettings.request_feedback_on_close && this.feedbackTemplate.subject.trim() === '') {
          this.toast.error('Subject and Body fields are required');
          return;
        }
        let feedback_template = {
          body: this.feedbackTemplate.body,
          subject: this.feedbackTemplate.subject
        }
        this.globalSettings[key] = feedback_template;
      }
      this.newSettingsChanges[key] = this.globalSettings[key];

      if (this.isFromSettings) {
        this.updateChangeAPITriggers(this.globalSettings[key], key);
      }
    }
    this.feedbackTemplateSubject = this.feedbackTemplate.subject;
  }

  onChangeInputValue(key: any, event?: any) {
    if (key == 'feedback_contacts') {


      let feedback_contacts_list = event.map((id: any) => {
        let matchedContact = this.contacts.find((element: any) => element.id === id);
        if (matchedContact) {
          return {
            contact: matchedContact.id,
            type: matchedContact.type
          };
        }
      });
      
      this.newSettingsChanges[key] = feedback_contacts_list;
      if (this.isFromSettings) {
        this.updateChangeAPITriggers(feedback_contacts_list, key);
      }
    } else {
      this.newSettingsChanges[key] = this.globalSettings[key];
      if (this.isFromSettings) {
        this.updateChangeAPITriggers(this.globalSettings[key], key);
      }
    }
    
  }

  onChangeDropdown(event: any, key: any) {

    if (key == 'service_types') {
      this.newSettingsChanges['service_type'] = (this.globalSettings.service_types?.length > 0) ? this.globalSettings.service_types : null;

      if (this.isFromSettings) {
        this.updateChangeAPITriggers(this.newSettingsChanges['service_type'], 'service_type');
      }
    }
    else if (key == 'categories') {
      this.newSettingsChanges['category'] = (this.globalSettings.categories?.length) ? this.globalSettings.categories : null;

      if (this.isFromSettings) {
        this.updateChangeAPITriggers(this.newSettingsChanges['category'], 'category');
      }
    }

  }


  onChangeTargetCost(event: any, key: any) {
    this.globalSettings.enable_target_cost = event;

    if (key == 'enable_target_cost' && event) {
      this.globalSettings.enable_target_cost_option = 'single';
    }

    if (key == 'enable_target_cost' && !event) {
      if (this.globalSettings.target_cost_type?.length == 0) {
        return;
      }
      // this.globalSettings.target_job_cost = 0;
      // this.globalSettings.target_cost_type=[];
      // this.updateChangeAPITriggers(this.globalSettings.target_job_cost, 'target_job_cost');
      this.deleteTemplate();

      let postBody: any = {
        target_job_cost: '',
        target_cost_type: []
      };

      let requestURL = environment.baseURL + 'global-job-settings/{Job_Settings_ID}/';
      requestURL = requestURL.replace('v1', 'v2');
      requestURL = requestURL.replace('{Job_Settings_ID}', this.globalSettings.id);

      this._jobSettingService.doPatch(requestURL, postBody)
        .subscribe({
          next: (response: any) => {
            if (this.globalSettings.hasOwnProperty('target_job_cost') || this.globalSettings.hasOwnProperty('target_cost_type')) {
              this.globalSettings['target_job_cost'] = response['target_job_cost'];
              this.globalSettings['target_cost_type'] = response['target_cost_type'];
            }

            this._utilService.showSuccess(this.tabType, 'Updated Successfully.');

          },
          error: (error) => {
            this._utilService.showErrorMessage(error);
            this._Config.isLoader = false;
          },
          complete: () => {
            this._Config.isLoader = false;
            this.globalSettings.enable_target_cost_option = '';
          }
        });
    }

    if (key == 'target_job_cost') {
      this.updateChangeAPITriggers(this.globalSettings.target_job_cost, 'target_job_cost');
    }

  }

  validateFeedback(event: any, key: string) {
      if (event.target.checked) {
        // Check if the subject and body are not empty
        if (this.feedbackTemplate.subject.trim() === '' || this.feedbackTemplate.body === null) {
          // If subject or body is empty, call check() to show error and revert the checkbox
          this.globalSettings.request_feedback_on_close = false; // Revert the checkbox value
          this.toast.error('Subject and Body fields are required');
          // Prevent the default action of changing the checkbox state
          event.preventDefault();
          return;
      }
    }

    // Proceed with updating settings if validation passes
    this.updateJobSettings(event.target.checked, key);
  }

  /**
   * Update Each Toggle / Setting items
   * @param changedValue
   * @param key
   * @returns
   */
  updateJobSettings(changedValue: any, key: any) {

    this.globalSettings[key] = changedValue;
    this.newSettingsChanges[key] = changedValue;

    if (key == 'require_bid') {
      this.globalSettings['number_of_bids'] = 0;
      this.newSettingsChanges['number_of_bids'] = 0;

      if (!this.isFromSettings) {
        if (changedValue && this._jobService.selectedJobInfo.hasOwnProperty('global_number_of_bids')) {
          this.globalSettings['number_of_bids'] = this._jobService.selectedJobInfo.global_number_of_bids;
          this.newSettingsChanges['number_of_bids'] = this._jobService.selectedJobInfo.global_number_of_bids;
        }
      }

    }

    this.changeDetector.detectChanges();

    if (!this.isFromSettings && this._jobService.selectedJobInfo.id) {
      if (key == 'approve_Engineer_Timesheet' || key == 'approve_Material_Submissions' || key == 'assign_managers_to_job' || key == 'assign_engineers_to_job' || key == 'require_Timesheet_on_Job') {
        this.setTempStatuValueChanges(changedValue, key);
      }
      return true;
    }
    else {

      if (key == 'approve_Engineer_Timesheet' || key == 'approve_Material_Submissions' || key == 'assign_managers_to_job' || key == 'assign_engineers_to_job' || key == 'require_Timesheet_on_Job') {
        this.updateNewStatusChanges(changedValue, key);
      }
      else {
        this.updateChangeAPITriggers(changedValue, key);
      }
    }

  }

  setTempStatuValueChanges(changedValue: any, key: any) {
    if (key == 'approve_Engineer_Timesheet') {
      if (changedValue) {
        if (this.globalSettings.timesheet_statusLists.some(obj => obj.checked)) {
          let selectedItems = this.globalSettings.timesheet_statusLists.filter(obj => obj.checked).map(obj => obj.id) || [];
          this.newSettingsChanges['require_timesheet_approval'] = selectedItems;
        }
        else {
          this.globalSettings.timesheet_statusLists.map(obj => {
            obj.checked = (obj.name == 'Closed') ? true : false;
            return obj;
          }) || [];

          let selectedItems = this.globalSettings.timesheet_statusLists.filter(obj => obj.checked).map(obj => obj.id) || [];
          this.newSettingsChanges['require_timesheet_approval'] = selectedItems;
        }
      }
      else {
        this.globalSettings.timesheet_statusLists.map((obj: any) => { obj.checked = false; return obj; });
        this.newSettingsChanges['require_timesheet_approval'] = [];
      }

      delete this.newSettingsChanges["approve_Engineer_Timesheet"];
    }
    else if (key == 'require_Timesheet_on_Job') {
      if (changedValue) {
        if (this.globalSettings.timesheet_Job_statusLists.some(obj => obj.checked)) {
          let selectedItems = this.globalSettings.timesheet_Job_statusLists.filter(obj => obj.checked).map(obj => obj.id) || [];
          this.newSettingsChanges['require_timesheet'] = selectedItems;
        }
        else {
          this.globalSettings.timesheet_Job_statusLists.map(obj => {
            obj.checked = (obj.name == 'Closed') ? true : false;
            return obj;
          }) || [];

          let selectedItems = this.globalSettings.timesheet_Job_statusLists.filter(obj => obj.checked).map(obj => obj.id) || [];
          this.newSettingsChanges['require_timesheet'] = selectedItems;
        }
      }
      else {
        this.globalSettings.timesheet_Job_statusLists.map((obj: any) => { obj.checked = false; return obj; });
        this.newSettingsChanges['require_timesheet'] = [];
      }

      delete this.newSettingsChanges["require_Timesheet_on_Job"];
    }

    else if (key == 'approve_Material_Submissions') {
      if (changedValue) {
        if (this.globalSettings.material_statusLists.some(obj => obj.checked)) {
          let selectedItems = this.globalSettings.material_statusLists.filter(obj => obj.checked).map(obj => obj.id) || [];
          this.newSettingsChanges['require_material_approval'] = selectedItems;
        }
        else {
          this.globalSettings.material_statusLists.map(obj => {
            obj.checked = (obj.name == 'Closed') ? true : false;
            return obj;
          }) || [];

          let selectedItems = this.globalSettings.material_statusLists.filter(obj => obj.checked).map(obj => obj.id) || [];
          this.newSettingsChanges['require_material_approval'] = selectedItems;
        }
      }
      else {
        this.globalSettings.material_statusLists.map((obj: any) => { obj.checked = false; return obj; });
        this.newSettingsChanges['require_material_approval'] = [];
      }
      delete this.newSettingsChanges["approve_Material_Submissions"];

    }

    else if (key == 'assign_managers_to_job') {
      if (changedValue) {
        if (this.globalSettings.manager_statusLists.some(obj => obj.checked)) {
          let selectedItems = this.globalSettings.manager_statusLists.filter(obj => obj.checked).map(obj => obj.id) || [];
          this.newSettingsChanges['require_manager'] = selectedItems;

        }
        else {
          this.globalSettings.manager_statusLists.map(obj => {
            obj.checked = (obj.name == 'Closed') ? true : false;
            return obj;
          }) || [];

          let selectedItems = this.globalSettings.manager_statusLists.filter(obj => obj.checked).map(obj => obj.id) || [];
          this.newSettingsChanges['require_manager'] = selectedItems;
        }
      }
      else {
        this.globalSettings.manager_statusLists.map((obj: any) => { obj.checked = false; return obj; });
        this.newSettingsChanges['require_manager'] = [];
      }
      delete this.newSettingsChanges["assign_managers_to_job"];

    }
    else if (key == 'assign_engineers_to_job') {
      if (changedValue) {
        if (this.globalSettings.engineer_statusLists.some(obj => obj.checked)) {
          let selectedItems = this.globalSettings.engineer_statusLists.filter(obj => obj.checked).map(obj => obj.id) || [];
          this.newSettingsChanges['require_engineer'] = selectedItems;
        }
        else {
          this.globalSettings.engineer_statusLists.map(obj => {
            obj.checked = (obj.name == 'Closed') ? true : false;
            return obj;
          }) || [];

          let selectedItems = this.globalSettings.engineer_statusLists.filter(obj => obj.checked).map(obj => obj.id) || [];
          this.newSettingsChanges['require_engineer'] = selectedItems;
        }
      }
      else {
        this.globalSettings.engineer_statusLists.map((obj: any) => { obj.checked = false; return obj; });
        this.newSettingsChanges['require_engineer'] = [];
      }

      delete this.newSettingsChanges["assign_engineers_to_job"];

    }
  }


  updateNewStatusChanges(changedValue: any, key: any) {
    if (key == 'approve_Engineer_Timesheet') {
      if (changedValue) {
        if (this.globalSettings.timesheet_statusLists.some(obj => obj.checked)) {
          let selectedItems = this.globalSettings.timesheet_statusLists.filter(obj => obj.checked).map(obj => obj.id) || [];
          this.updateChangeAPITriggers(selectedItems, 'require_timesheet_approval');
        }
        else {
          this.globalSettings.timesheet_statusLists.map(obj => {
            obj.checked = (obj.name == 'Closed') ? true : false;
            return obj;
          }) || [];
          // this._utilService.showError('Select Status in ', 'Approve Engineer Timesheets Before Completion');

          let selectedItems = this.globalSettings.timesheet_statusLists.filter(obj => obj.checked).map(obj => obj.id) || [];
          this.updateChangeAPITriggers(selectedItems, 'require_timesheet_approval');
          return;
        }
      }
      else {
        this.globalSettings.timesheet_statusLists.map((obj: any) => {
          obj.checked = false;
          return obj;
        });
        this.updateChangeAPITriggers([], 'require_timesheet_approval');
      }

    }
    else if (key == 'require_Timesheet_on_Job') {
      if (changedValue) {
        if (this.globalSettings.timesheet_Job_statusLists.some(obj => obj.checked)) {
          let selectedItems = this.globalSettings.timesheet_Job_statusLists.filter(obj => obj.checked).map(obj => obj.id) || [];
          this.updateChangeAPITriggers(selectedItems, 'require_timesheet');
        }
        else {
          this.globalSettings.timesheet_Job_statusLists.map(obj => {
            obj.checked = (obj.name == 'Closed') ? true : false;
            return obj;
          }) || [];

          let selectedItems = this.globalSettings.timesheet_Job_statusLists.filter(obj => obj.checked).map(obj => obj.id) || [];
          this.updateChangeAPITriggers(selectedItems, 'require_timesheet');
          // this._utilService.showError('Select Status in ', 'Require a Timesheet on Jobs with Engineers Assigned');
          return;
        }
      }
      else {
        this.globalSettings.timesheet_Job_statusLists.map((obj: any) => {
          obj.checked = false;
          return obj;
        });
        this.updateChangeAPITriggers([], 'require_timesheet');
      }

    }
    else if (key == 'approve_Material_Submissions') {
      if (changedValue) {
        if (this.globalSettings.material_statusLists.some(obj => obj.checked)) {
          let selectedItems = this.globalSettings.material_statusLists.filter(obj => obj.checked).map(obj => obj.id) || [];
          this.updateChangeAPITriggers(selectedItems, 'require_material_approval');
        }
        else {
          this.globalSettings.material_statusLists.map(obj => {
            obj.checked = (obj.name == 'Closed') ? true : false;
            return obj;
          }) || [];

          let selectedItems = this.globalSettings.material_statusLists.filter(obj => obj.checked).map(obj => obj.id) || [];
          this.updateChangeAPITriggers(selectedItems, 'require_material_approval');
          // this._utilService.showError('Select Status in ', 'Approve Material Submissions Before Completion');
          return;
        }
      }
      else {
        this.globalSettings.material_statusLists.map((obj: any) => {
          obj.checked = false;
          return obj;
        });
        this.updateChangeAPITriggers([], 'require_material_approval');
      }

    }
    else if (key == 'assign_managers_to_job') {
      if (changedValue) {
        if (this.globalSettings.manager_statusLists.some(obj => obj.checked)) {
          let selectedItems = this.globalSettings.manager_statusLists.filter(obj => obj.checked).map(obj => obj.id) || [];
          this.updateChangeAPITriggers(selectedItems, 'require_manager');
        }
        else {
          this.globalSettings.manager_statusLists.map(obj => {
            obj.checked = (obj.name == 'Closed') ? true : false;
            return obj;
          }) || [];

          let selectedItems = this.globalSettings.manager_statusLists.filter(obj => obj.checked).map(obj => obj.id) || [];
          this.updateChangeAPITriggers(selectedItems, 'require_manager');
          // this._utilService.showError('Select Status in ', 'Assign Managers to Jobs Before Completion');
          return;
        }
      }
      else {
        this.globalSettings.manager_statusLists.map((obj: any) => {
          obj.checked = false;
          return obj;
        });
        this.updateChangeAPITriggers([], 'require_manager');
      }

    }
    else if (key == 'assign_engineers_to_job') {
      if (changedValue) {
        if (this.globalSettings.engineer_statusLists.some(obj => obj.checked)) {
          let selectedItems = this.globalSettings.engineer_statusLists.filter(obj => obj.checked).map(obj => obj.id) || [];
          this.updateChangeAPITriggers(selectedItems, 'require_engineer');
        }
        else {
          this.globalSettings.engineer_statusLists.map(obj => {
            obj.checked = (obj.name == 'Closed') ? true : false;
            return obj;
          }) || [];

          let selectedItems = this.globalSettings.engineer_statusLists.filter(obj => obj.checked).map(obj => obj.id) || [];
          this.updateChangeAPITriggers(selectedItems, 'require_engineer');
          //this._utilService.showError('Select Status in ', 'Assign Engineers to Jobs Before Completion');
          return;
        }
      }
      else {
        this.globalSettings.engineer_statusLists.map((obj: any) => {
          obj.checked = false;
          return obj;
        });
        this.updateChangeAPITriggers([], 'require_engineer');
      }

    }
  }

  updateChangeAPITriggers(changedValue: any, type: any) {
    if (type == 'notify_tenant') {
      changedValue = !changedValue;
    }

    let postBody: any = {};
    postBody[type] = changedValue;

    let requestURL = environment.baseURL + 'global-job-settings/{Job_Settings_ID}/';
    requestURL = requestURL.replace('v1', 'v2');
    requestURL = requestURL.replace('{Job_Settings_ID}', this.globalSettings.id);

    this._jobSettingService.doPatch(requestURL, postBody)
      .subscribe({
        next: (response: any) => {
          if (this.globalSettings.hasOwnProperty(type)) {
            this.globalSettings[type] = response[type];

            if (type == 'notify_tenant') {
              this.globalSettings[type] = !response[type];

            }

            if (type == 'target_job_cost') {
              this.globalSettings['target_cost_type'] = response['target_cost_type'];
            }
          }

          this._utilService.showSuccess(this.tabType, 'Updated Successfully.');

        },
        error: (error) => {
          this._utilService.showErrorMessage(error);
          this._Config.isLoader = false;
        },
        complete: () => {
          this._Config.isLoader = false;
        }
      });
  }

  /**
* Get Selected Job Info By Job ID
* @param jobId
*/
  doRefreshSelectedJob() {
    let requestURL = environment.baseURL + "/jobs/{ID}/";
    requestURL = requestURL.replace('{ID}', this._jobService.selectedJobInfo.id);

    this._jobSettingService.doGET(requestURL).subscribe({
      next: (response: any) => {
        // this._jobService.selectedJobInfo.work_order_count = response.work_order_count;


        for (const key of Object(this.globalSettings).keys()) {
          this._jobService.selectedJobInfo[key] = response[key];
        }

      },
      error: (error) => { },
      complete: () => {
        // this._Config.isLoader = false;
      }
    });
  }


  getAllContactsList(): void {
    let requestURL = '';
    // let contactId = this._jobService.selectedJobInfo?.submitted_by?.contact_id ? this._jobService.selectedJobInfo?.submitted_by?.contact_id : '';
    // let tenantCompanyId = this._jobService.selectedJobInfo?.tenant_company_id ? this._jobService.selectedJobInfo?.tenant_company_id : '';

    // if (tenantCompanyId != '') {
    //   if (contactId != '') {
    //     requestURL = environment.fastAPI_Actual_URL + '/companies/' + tenantCompanyId + '/contacts/?created_by=' + "'" + contactId + "'"
    //   } else {
    //     requestURL = environment.fastAPI_Actual_URL + '/companies/' + tenantCompanyId + '/contacts/'
    //   }
    // } else {
    //   requestURL = environment.baseURL + this._Config.getJobContactsDropdown;
    //   requestURL = requestURL.replace('{ID}', this._jobService.selectedJobInfo.id);
    // }

    requestURL = environment.baseURL + this._Config.getJobContactsDropdown;
    requestURL = requestURL.replace('{ID}', this._jobService.selectedJobInfo.id);

    this._baseService.doGET(requestURL).subscribe({
      next: (response: any) => {
        const contacts = response.items ? response.items : response || [];

        this.contacts = contacts.map(element => {
          element.id = element?.id ? element.id : element.contact_id; // Old response
          element.contact_id = element?.contact_id ? element.contact_id : element.id; //Missing Response Format update
          // element.full_name = element.first_name + ' ' + element.last_name;
          if(element.email !== null) {
            element.name = response.items ? element.first_name + ' ' + element.last_name : element.full_name + ` ( ${element.email} )`;
          } else {
            element.disabled = true;
            element.name = response.items ? element.first_name + ' ' + element.last_name : element.full_name + ` ( No email on record )`;
          }
          // element.name = response.items ? element.first_name + ' ' + element.last_name : element.full_name;
          element.type = element.type;
          return element;
        });

      }, error: () => {

      },
      complete: () => {
        this.getAllSelectedContactsList();
      }
    });
  }

  getAllSelectedContactsList() {
    let requestURL = environment.baseURL + this._Config.updateTicket + "feedback-contacts/";
    requestURL = requestURL.replace('{ID}', this._jobService.selectedJobInfo.id);
    this._baseService.doGET(requestURL).subscribe({
      next: (response: any) => {
        this.selectedContacts = (response || []).map(v => v.contact_id);
        this.globalSettings.feedback_contacts = this.selectedContacts || [];
      }, error: (error) => {
        this.feedBackErrorMessage = error;
      },
      complete: () => {
      }
    });
  }

  /** Track by Index  **/
  trackByIndex(index: number, item: any): number {
    return index;
  }




  getGlobalJobStatus() {
    let requestURL = environment.baseURL + 'global-job-settings/preference-status/';
    requestURL = requestURL.replace('v1', 'v2');
    this._jobSettingService.doGET(requestURL)
      .pipe(
        map((response: any) => {
          const keys = ['timesheet_Job_statusLists', 'timesheet_statusLists', 'material_statusLists', 'manager_statusLists', 'engineer_statusLists']; // Keys where the response should be placed

          // Custom values for each key
          const customValues = {
            timesheet_Job_statusLists: { custom_key: 'timesheet_job_', checked: false },
            timesheet_statusLists: { custom_key: 'timesheet_', checked: false },
            material_statusLists: { custom_key: 'material_', checked: false },
            manager_statusLists: { custom_key: 'manager_', checked: false },
            engineer_statusLists: { custom_key: 'engineer_', checked: false }
          };
          const customizedResponse = this._jobSettingService.customizeResponse(response, keys, customValues);
          return customizedResponse;
        }))
      .subscribe({
        next: (response: any) => {
          this.allStatusLists = response;

          this.globalSettings.timesheet_Job_statusLists = response?.timesheet_Job_statusLists || [];
          this.globalSettings.timesheet_statusLists = response?.timesheet_statusLists || [];
          this.globalSettings.material_statusLists = response?.material_statusLists || [];
          this.globalSettings.manager_statusLists = response?.manager_statusLists || [];
          this.globalSettings.engineer_statusLists = response?.engineer_statusLists || [];
        },
        error: (error) => {
        },
        complete: () => {

          // if (this.isFromSettings) {
          //   this.setPreferenceStatusValues();
          // }
          // else{
          // }

          this.setPreferenceStatusValues();


        }
      });
  }


  setPreferenceStatusValues() {
    if (this.globalSettings?.require_timesheet_approval && this.globalSettings?.require_timesheet_approval?.length > 0) {
      this.globalSettings.approve_Engineer_Timesheet = true;
      this.globalSettings.timesheet_statusLists = this.globalSettings.timesheet_statusLists.map(element => {
        element.checked = this.globalSettings?.require_timesheet_approval.includes(element.id);
        return element;
      });
    }

    if (this.globalSettings?.require_timesheet && this.globalSettings?.require_timesheet?.length > 0) {
      this.globalSettings.require_Timesheet_on_Job = true;
      this.globalSettings.timesheet_Job_statusLists = this.globalSettings.timesheet_Job_statusLists.map(element => {
        element.checked = this.globalSettings?.require_timesheet.includes(element.id);
        return element;
      });
    }

    if (this.globalSettings?.require_material_approval && this.globalSettings?.require_material_approval?.length > 0) {
      this.globalSettings.approve_Material_Submissions = true;
      this.globalSettings.material_statusLists = this.globalSettings.material_statusLists.map(element => {
        element.checked = this.globalSettings?.require_material_approval.includes(element.id);
        return element;
      });
    }

    if (this.globalSettings?.require_material && this.globalSettings?.require_material?.length > 0) {
      this.globalSettings.approve_Material_Submissions = true;
      this.globalSettings.material_statusLists = this.globalSettings.material_statusLists.map(element => {
        element.checked = this.globalSettings?.require_material.includes(element.id);
        return element;
      });
    }

    if (this.globalSettings?.require_manager && this.globalSettings?.require_manager?.length > 0) {
      this.globalSettings.assign_managers_to_job = true;
      this.globalSettings.manager_statusLists = this.globalSettings.manager_statusLists.map(element => {
        element.checked = this.globalSettings?.require_manager.includes(element.id);
        return element;
      });
    }
    if (this.globalSettings?.require_engineer && this.globalSettings?.require_engineer?.length > 0) {
      this.globalSettings.assign_engineers_to_job = true;
      this.globalSettings.engineer_statusLists = this.globalSettings.engineer_statusLists.map(element => {
        element.checked = this.globalSettings?.require_engineer.includes(element.id);
        return element;
      });
    }


    if (this.globalSettings?.target_cost_type && this.globalSettings?.target_cost_type?.length > 0) {
      this.globalSettings.enable_target_cost = true;

      if (this.globalSettings?.target_cost_type.includes('single')) {
        this.globalSettings.enable_target_cost_option = 'single';
      }
      else if (this.globalSettings?.target_cost_type.includes('customize')) {
        this.globalSettings.enable_target_cost_option = 'customize';
      }
    }
  }

  /**
   * Get All Global Job Status Dynamic and multiple API
   */
  // getGlobalJobStatus() {

  //   this._jobSettingService.getDynamicStatusAPI_Requests()
  //     .subscribe({
  //       next: (response: any) => {
  //         this.allStatusLists = response;

  //         this.globalSettings.timesheet_Job_statusLists = response?.timesheet_Job_statusLists || [];
  //         this.globalSettings.timesheet_statusLists = response?.timesheet_statusLists || [];
  //         this.globalSettings.material_statusLists = response?.material_statusLists || [];
  //         this.globalSettings.manager_statusLists = response?.manager_statusLists || [];
  //         this.globalSettings.engineer_statusLists = response?.engineer_statusLists || [];
  //       },
  //       error: (error) => {
  //       },
  //       complete: () => {
  //       }
  //     });
  // }

  /******* On Toggle On/Off event *********/
  statusCheckboxEvent(type: any) {


    if (type == 'require_Timesheet_on_Job') {
      this.globalSettings.require_Timesheet_on_Job = this.globalSettings.timesheet_Job_statusLists.some(obj => obj.checked);
      this.updateJobSettings(this.globalSettings.require_Timesheet_on_Job, type);
    }
    if (type == 'approve_Engineer_Timesheet') {
      this.globalSettings.approve_Engineer_Timesheet = this.globalSettings.timesheet_statusLists.some(obj => obj.checked);
      this.updateJobSettings(this.globalSettings.approve_Engineer_Timesheet, type);
    }
    if (type == 'approve_Material_Submissions') {
      this.globalSettings.approve_Material_Submissions = this.globalSettings.material_statusLists.some(obj => obj.checked);
      this.updateJobSettings(this.globalSettings.approve_Material_Submissions, type);
    }
    if (type == 'assign_managers_to_job') {
      this.globalSettings.assign_managers_to_job = this.globalSettings.manager_statusLists.some(obj => obj.checked);
      this.updateJobSettings(this.globalSettings.assign_managers_to_job, type);
    }
    if (type == 'assign_engineers_to_job') {
      this.globalSettings.assign_engineers_to_job = this.globalSettings.engineer_statusLists.some(obj => obj.checked);
      this.updateJobSettings(this.globalSettings.assign_engineers_to_job, type);
    }

  }


  changeRadioBtnEvent(event: any) {
    this.globalSettings.enable_target_cost_option = event;
    // this.globalSettings.target_job_cost = 0;
  }


  onSuccessOneschemaImport() {
    console.log('Import Success');
    this._jobSettingService.getAllGlobalSettings();
  }

  /**
   * Export Selected View Data
   */
  exportSelectedTableData(isTemplate?: boolean) {
    this.isExportLoader = true;

    let requestURL = environment.baseURL + 'target-cost-mapping/export/';
    let postBody: any = {};

    this._baseService.doPOST(requestURL, postBody).subscribe(
      {
        next: (response: any) => {
          // this._utilService.showSuccess('', "An email with export file has been sent.");
          this._utilService.showSuccess('', response ? response : 'An email with export file has been sent.');
        },
        error: (error) => {
          this.isExportLoader = false;
          this._utilService.showErrorMessage(error);
        },
        complete: () => {
          this.isExportLoader = false;
        }
      });
  }


  /**
  * Export Selected View Data
  */
  deleteTemplate() {
    this.isDeleteLoader = true;

    let requestURL = environment.baseURL + 'target-cost-mapping/delete/';

    this._baseService.doDelete(requestURL).subscribe(
      {
        next: (response: any) => {
          this._utilService.showSuccess('', response?.message);
        },
        error: (error) => {
          this.isDeleteLoader = false;
          this._utilService.showErrorMessage(error);
        },
        complete: () => {
          this.isDeleteLoader = false;
          this._jobSettingService.getAllGlobalSettings();
        }
      });
  }

}
