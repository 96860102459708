import { data } from 'jquery';
import { BaseService } from './../../../../_services/core/base.service';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppTableSource } from '../../../models/app-table-model';
import { ColumnSortType, TableColumnTypes } from '../../../enums/table/table-column-types.enum';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TenantPortalJobsService } from 'src/app/Modules/tenant-portal/services/tenant-portal-jobs.service';
import { ConfigService } from 'src/app/services/config.service';
import { TableService } from 'src/app/@fyxt/_services/table/table.service';
import { JobsService } from 'src/app/services/v2/jobs.service';
import { MatDividerModule } from '@angular/material/divider';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { FilterAccordianComponent } from '../../../_reusable_ui/_controls/filter-accordian/filter-accordian.component';
import { FilterComponent } from '../../../_reusable_ui/_components/filter/filter.component';
import { TableComponent } from '../../../_reusable_ui/_components/table/table.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { CardComponent } from '../../../_reusable_ui/_components/card/card.component';
import { AngularResizeEventModule } from 'angular-resize-event-package';
import { FilterChipListComponent } from '../../../_reusable_ui/_components/filter-chip-list/filter-chip-list.component';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';

@Component({
    selector: 'app-schedule-contact',
    templateUrl: './schedule-contact.component.html',
    styleUrls: ['./schedule-contact.component.scss'],
    standalone: true,
    imports: [SearchInputComponent, ButtonComponent, MatTooltipModule, MatIconModule, NgIf, FilterChipListComponent, AngularResizeEventModule, NgClass, CardComponent, LoaderSmallComponent, TableComponent, FilterComponent, NgFor, FilterAccordianComponent, FormsModule, NgxIntlTelInputModule, MatDividerModule]
})
export class ScheduleContactComponent implements OnInit {
  @Input() isLoader: boolean;
  
  @Output() onClear = new EventEmitter();
  @Output() onDeletedTag = new EventEmitter();
  @Input() filterChipList: any[] = [];
  @Output() divWidth:number;
  dataSource!: AppTableSource;
  public show:boolean = false;
  moduleName = "Tenant-portal";
 tableHeaders = [
    {
      value: '',
      sort: ColumnSortType.none,
      name: 'First Name',
      type: TableColumnTypes.text,
      className: 'table-header',
      resizeColumn:true,
    },
    {
      value: ' ',
      sort: ColumnSortType.none,
      name: 'Last Name',
      type: TableColumnTypes.text,
      className: 'table-header',
      resizeColumn:true,
    },
    {
      value: '',
      sort: ColumnSortType.none,
      name: 'Type',
      type: TableColumnTypes.text,
      className: 'table-header',
      resizeColumn:true,
    },
    {
      value: '',
      sort: ColumnSortType.none,
      name: 'Email',
      type: TableColumnTypes.text,
      className: 'table-header',
      resizeColumn:true,
    },
    {
      value: '',
      sort: ColumnSortType.none,
      name: 'Phone',
      type: TableColumnTypes.text,
      className: 'table-header',
      resizeColumn:true,
    },
  ];

  // lines will be uncommanded once after dev (after integeration)
  // filtersDataSource: any = {
  //   filterLists: [
  //     {
  //       label: 'Equipment Type',
  //       isExpended: false,
  //       selectAll:false,
  //       filterName:'listFilterEquipmentsTypes',
  //       pageNumber:1,
  //       total:0,
  //       listItems: [ ],
  //     },
  //     {
  //       label: 'Category',
  //       isExpended: false,
  //       selectAll:false,
  //       filterName:'listFyxtCategories',
  //       pageNumber:1,
  //       total:0,
  //       listItems: [ ],
  //     },
  //     {
  //       label: 'Manufacturer',
  //       isExpended: false,
  //       selectAll:false,
  //       filterName:'listFilterManufacturers',
  //       pageNumber:1,
  //       total:0,
  //       listItems: [ ],
  //     },
  //     {
  //       label: 'Model',
  //       isExpended: false,
  //       selectAll:false,
  //       filterName:'listFilterModels',
  //       pageNumber:1,
  //       total:0,
  //       listItems: [ ],
  //     }
  //   ],
  // };

  contactsTableDataSource: any;
  contactsTableData = {
    contactsSearchValue: '',
    contactsTableHeader: [
      {
        value: 'first_name',
        sort: ColumnSortType.none,
        name: 'First Name',
        type: TableColumnTypes.text,
        className: 'table-header',
        resizeColumn: true,
      },
      {
        value: 'last_name',
       sort: ColumnSortType.none,
        name: 'Last Name',
        type: TableColumnTypes.text,
        className: 'table-header',
        resizeColumn: true,
      },
      {
        value: 'type',
       sort: ColumnSortType.none,
        name: 'Type',
        type: TableColumnTypes.text,
        className: 'table-header',
        resizeColumn: true,
      },
      {
        value: 'primary_email',
       sort: ColumnSortType.none,
        name: 'Email',
        type: TableColumnTypes.text,
        className: 'table-header',
        resizeColumn: true,
      },
      {
        value: 'primary_phone',
       sort: ColumnSortType.none,
        name: 'Phone',
        type: TableColumnTypes.text,
        className: 'table-header',
        resizeColumn: true,
      },
    ],
    contactsTableRows: [],
    totalRows: 0,
    pageIndexAPI: 0,
    perPage: 25,
    currentPage: 1,
    contactsSort: {
      active: '',
      direction: ''
    },
  };
  chipList : any[]=[];

  showFilter: boolean = false;
  collapsableFilter: string = '';
  listEquipments:any[]=[];
  searchInput:string = "";
  pageSize:number = 25;
  sortColumn:string=null;
  jobID: any;
  sortDirection:string=null;
  selectedViewTableRowsTotal: number;
  checkedEquipments: any[] = [];
  buttonName: string;
  selectedFiltersList: any[] = [];
  constructor(
    public _jobService: JobsService,
    public _baseService:BaseService,
    public _tenantPortalJobsService: TenantPortalJobsService,
    public _Config: ConfigService,
    public tableService: TableService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.jobID = this._jobService.selectedJobInfo.id
   this.getContactsTableDataRows();
  }

  loadFilters(){
  

  }

  onSearchResult(){
  
  }



  showSelectedFilters() {

  }

  clearAllSelectedFilters() {
    
  }

   
    onCheckRow() {
      
    }

  
    isAllSelected() {
    
    };



  AssignEquipments(){

  }

  getAllEquipments(){

  }

  toggleFilter() {
 
  }


  onSearch() {
 
  }

  onPageChange(){
    
  }

  onSortChange(){
  
  }



deletedTag(){

}


    /**
   * Clear Selected Filters
   */
    clearSelectedFilters() {
      // this.filterChipList = [];
      // this.onClear.emit(this.filterChipList);
    }

    onResized() {
      }

      toggle() {
        // this.show = !this.show;
      }

       //  Contact Tab API integration
  getContactsTableDataRows(isSearch?: boolean, isSort?: any) {
    const offset = (this.contactsTableData.pageIndexAPI - 1) * this.contactsTableData.perPage;
    let selectViewTableRows = environment.baseURL + 'jobs/' + this.jobID + '/company-contacts/?page=1&size=25';
    if (isSearch) {
      selectViewTableRows += '&search=' + this.searchInput;
    }
    if (isSort) {
      selectViewTableRows += "&column=" + this.contactsTableData.contactsSort.active + "&order=" + this.contactsTableData.contactsSort.direction;
    }

    // Append sort direction to the header if we have a sort query from the backend
    if (this.contactsTableData.contactsSort !== undefined && this.contactsTableData.contactsSort.active !== '') {
      this.contactsTableData.contactsTableHeader.map((header: any) => {
        if (header.value == this.contactsTableData.contactsSort.active) {
          header.sort = this.contactsTableData.contactsSort.direction;
        } else {
          delete header.sort;
        }
      });
    }

    this.isLoader = true;
    this._tenantPortalJobsService.getContactsTableDataRows(selectViewTableRows).subscribe({
      next: (response: any) => {
        // Update the current page
        this.contactsTableData.currentPage = this.contactsTableData.pageIndexAPI;
        this.contactsTableData.contactsTableRows = response || [];
        this.contactsTableData.totalRows = response.length || 0;
        this.contactsTableDataSource = this.tableService.setTableData(this.moduleName, this.contactsTableData.contactsTableHeader,
          this.contactsTableData.contactsTableRows, this.contactsTableData.totalRows, this.contactsTableData.currentPage, this.contactsTableData.perPage);
        this.isLoader = false;
      },
      error: (error: any) => {},
      complete: () => {
        this._Config.isLoader = false;
        this.isLoader = false;
      },
    });
  }

   /** Track by Index  **/
   trackByIndex(index: number, item: any): number {
    return index;
  }
}
