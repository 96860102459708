// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
}
:host ::ng-deep mat-radio-group {
  display: flex;
  flex-direction: column;
  margin-top: -7px 0;
}
:host ::ng-deep mat-radio-group > * {
  margin: 7px 0;
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
}
:host ::ng-deep mat-radio-group > * .mat-radio-label {
  margin: 0;
}
:host .mat-intl-input {
  background: #fff;
  height: 44px;
  display: block;
  border: 1px solid #eee;
  border-radius: 3px;
  padding: 12px 0px 0px 0px;
  margin-top: 6px;
}
:host ::ng-deep .ngx-mat-tel-input-container {
  position: relative;
}
:host ::ng-deep .ngx-mat-tel-input-container .country-selector {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_views/_popup_views/notify-other-contacts-to-sign/notify-other-contacts-to-sign.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;AACJ;AACI;EACE,aAAA;EACA,4HAAA;EACA,4HAAA;AACN;AAAM;EACE,SAAA;AAER;AAGE;EACE,gBAAA;EACA,YAAA;EACA,cAAA;EACA,sBAAA;EACA,kBAAA;EACA,yBAAA;EACA,eAAA;AADJ;AAIE;EACE,kBAAA;AAFJ;AAII;EACE,UAAA;AAFN","sourcesContent":[":host {\n  display: flex;\n  flex-direction: column;\n\n  ::ng-deep mat-radio-group {\n    display: flex;\n    flex-direction: column;\n    margin-top: -7px 0;\n\n    > * {\n      margin: 7px 0;\n      /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */\n      /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */\n      .mat-radio-label {\n        margin: 0;\n      }\n    }\n  }\n\n  .mat-intl-input {\n    background: #fff;\n    height: 44px;\n    display: block;\n    border: 1px solid #eee;\n    border-radius: 3px;\n    padding: 12px 0px 0px 0px;\n    margin-top: 6px;\n  }\n\n  ::ng-deep .ngx-mat-tel-input-container {\n    position: relative;\n\n    .country-selector {\n      opacity: 1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
