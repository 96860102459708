import {Component, OnInit} from '@angular/core';
import {map, takeUntil} from "rxjs/operators";
import {
  InboxSettingsService
} from "../../../../../Modules/_fyxt_modules/inbox-management/_services/inbox-settings.service";
import {Subject} from "rxjs";
import {EnumTemplatesCategory, Template} from "../../../../../Modules/_fyxt_modules/inbox-management/inbox.types";
import {InboxService_New} from "../../../../../Modules/_fyxt_modules/inbox-management/_services/inbox.service";
import {UtilityService} from "../../../../../services/utility.service";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import { MatDialogModule } from '@angular/material/dialog';
import { AngularEditorComponent } from '../../../_reusable_ui/_components/angular-editor/angular-editor.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { MatDividerModule } from '@angular/material/divider';
import { ThreeDotsDropdownComponent } from '../../../_reusable_ui/_components/three-dots-dropdown/three-dots-dropdown.component';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';

@Component({
    selector: 'fyxt-tab-inbox-auto-responses',
    templateUrl: './tab-inbox-auto-responses.component.html',
    styleUrls: ['./tab-inbox-auto-responses.component.scss'],
    standalone: true,
    imports: [SearchInputComponent, ButtonComponent, MatIconModule, NgIf, ThreeDotsDropdownComponent, MatDividerModule, LoaderSmallComponent, NgFor, NgClass, TextboxComponent, FormsModule, NgxIntlTelInputModule, AngularEditorComponent, MatDialogModule]
})
export class TabInboxAutoResponsesComponent implements OnInit {
  private readonly destroy$: Subject<null> = new Subject();
  responseScheduleToggle: boolean = false;
  isLoader = false;
  autoResponse = [];
  autoResponseForLocalSearch = [];
  searchInput = '';
  selectedAutoResponse: any;
  threeDotDropdownMenuLists: any[] = [
    {label: 'Delete', value: 'Delete'},
    {label: 'Set as Default View', value: 'default-view'},
  ];
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        [{list: 'ordered'}, {list: 'bullet'}],
        [{indent: '-1'}, {indent: '+1'}],
        [{direction: 'rtl'}],
        [{header: [1, 2, 3, 4, 5, 6, false]}],
        [{color: []}, {background: []}],
        [{font: []}],
        [{align: []}],
        ['clean']
      ]
    },
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ['@', '#'],
      theme: 'snow',
      isolateCharacter: true,  //using email to editor
      offsetTop: 0,
      defaultMenuOrientation: "top",
      renderLoading: () => {
        return "Loading...";
      },
      'emoji-toolbar': true,
      'emoji-textarea': false,
      'emoji-shortname': true,
      keyboard: {
        bindings: {
          enter: {
            key: 13,
            handler: (range, context) => {
              return true;
            }
          }
        }
      }
    }
  };

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    showToolbar: true,
    minHeight: '8rem',
    height: '6rem',
    toolbarPosition: 'top',
    sanitize: false,
    outline: false,
    placeholder: 'Write something here…',
    translate: 'no',
    defaultParagraphSeparator: 'div',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'subscript',
        'superscript',
        'customClasses',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
        'fontSize',
        'undo',
        'redo',
        'indent',
        'outdent',
      ]
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote'
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1'
      }
    ]
  };

  constructor(private _inboxSettings: InboxSettingsService, private _inboxService: InboxService_New,
              public _utilService: UtilityService,) {
  }

  ngOnInit(): void {
    this.getAllAutoResponse();
  }

  private getAllAutoResponse(): void {
    this.isLoader = true;
    this._inboxSettings.getAllTemplates(EnumTemplatesCategory.AUTO_RESPONSE).pipe(map((data: any) => data?.data), takeUntil(this.destroy$)).subscribe(
      {
        next: (response: any) => {
          // Make default auto response should come as first
          const defaultAutoResponse = response.find((autoResponse: any) => {
            return autoResponse.isDefault == true;
          });
          response = response.filter((autoResponse: any) => {
            return autoResponse.isDefault != true;
          });
          if (defaultAutoResponse?.id) {
            response.unshift(defaultAutoResponse);
          }
          this.autoResponse = response;
          this.autoResponseForLocalSearch = response;
          if (this.autoResponse.length > 0) {
             this.selectedAutoResponse = this.autoResponse[0];
          } else {
            this.selectedAutoResponse = null;
          }
          this.isLoader = false;
        },
        error: (error) => {
          this.isLoader = false;
        },
        complete: () => {
        }
      }
    );
  }

  searchAutoResponse(searchVal: any): void {
    this.searchInput = searchVal;
    this.autoResponse = this.autoResponseForLocalSearch.filter(autoResponse => {
      return autoResponse.title.toLowerCase().includes(this.searchInput.toLowerCase());
    });
    if (this.autoResponse.length > 0) {
      this.changeAutoResponse(this.autoResponse[0]);
    } else {
      this.selectedAutoResponse = null;
    }
  }

  addAutoResponse(): void {
    const newAutoResponse = {
      title: '',
      newResponse: true,
      id: 1,
      content: ''
    };
    this.autoResponse.push(newAutoResponse);
    this.selectedAutoResponse = this.autoResponse[this.autoResponse.length - 1];
  }

   changeContentBasedOnGmail(): void {
    let content =  this.selectedAutoResponse.content.replaceAll('<p', '<div');
    content = content.replaceAll('</p>', '</div>');
    content = content.replaceAll('<p>', '<div  style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<div>', '<div style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<h1>', '<h1 style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<h2>', '<h2 style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<h3>', '<h3 style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<h4>', '<h4 style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<h5>', '<h5 style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<h6>', '<h6 style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<ol>','<ol style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<ul>','<ul style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<a>', '<a style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<li>','<li style="margin-top: 0px !important;margin-bottom: 0px !important;margin-left: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('white-space: pre-wrap', '');
    this.selectedAutoResponse.content = content;
  }

  SaveNewAutoResponse(): void {
    this.changeContentBasedOnGmail();
    const payload: Template = {
      "title": this.selectedAutoResponse.title,
      "content": this.selectedAutoResponse.content,
      "mailboxId": this._inboxService.activeMailboxDetails.id,
      "category": EnumTemplatesCategory.AUTO_RESPONSE
    }
    this._inboxSettings.createTemplate(payload).pipe(takeUntil(this.destroy$)).subscribe(
      {
        next: (response: any) => {
          this._utilService.showSuccess('', 'Auto Response Created Successfully');

          if(this.autoResponse.length == 1){
            this.selectedAutoResponse = response.data;
            this.setTemplateAsDefault();
          }

        },
        error: (error) => {
          this.isLoader = false;
          this._utilService.showErrorMessage(error);
        },
        complete: () => {
          this._inboxSettings.settingsTabCountUpdated.next(EnumTemplatesCategory.AUTO_RESPONSE);
          this.getAllAutoResponse();
        }
      }
    );
  }

  cancelResponse(): void {
    this.autoResponse.pop();
    if (this.autoResponse.length > 0) {
      this.selectedAutoResponse = this.autoResponse[0];
    } else {
      this.selectedAutoResponse = null;
    }
  }

  bodyChange(event: any): void {
    this.selectedAutoResponse.content = event;
  }

  changeAutoResponse(response: any): void {
    this.selectedAutoResponse = response;
  }

  menuOnSelect(event: any): void {
    if (event.label == 'Delete') {
      this.deleteAutoResponse(this.selectedAutoResponse.id);
    }
    if(event.value == 'default-view'){
      this.setTemplateAsDefault();
    }
  }

  deleteAutoResponse(templateId: any): void {
     this._inboxSettings.deleteTemplate(templateId).pipe(takeUntil(this.destroy$)).subscribe(
      {
        next: (response: any) => {
          this._inboxSettings.settingsTabCountUpdated.next(EnumTemplatesCategory.AUTO_RESPONSE);
          this._utilService.showSuccess('', 'Auto Response Deleted Successfully');
          this.getAllAutoResponse();
        },
        error: (error) => {
          this.isLoader = false;
          this._utilService.showErrorMessage(error);
        },
        complete: () => {
        }
      }
    );

  }

  slideToggleChange(event: any): void {
    this.responseScheduleToggle = event;
  }

  setTemplateAsDefault(){

    const payload = {
      title: this.selectedAutoResponse.title,
      content: this.selectedAutoResponse.content,
      isDefault: true,
      category: EnumTemplatesCategory.AUTO_RESPONSE,
      mailboxId: this._inboxService.activeMailboxDetails.id,
    }
      this._inboxSettings.updateTemplate(payload, this.selectedAutoResponse.id).pipe(takeUntil(this.destroy$)).subscribe(
        {
          next: (response: any) => {
            this._utilService.showSuccess('', 'Set as Default Updated Successfully');
          },
          error: (error) => {
            this._utilService.showErrorMessage(error);
          },
          complete: () => {
            this.getAllAutoResponse();
          }
        }
      );
  }

  editAutoResponse(): void {
    if (this.selectedAutoResponse.newResponse) {
      return null;
    } else {
      this.changeContentBasedOnGmail();
      const payload = {
        "title": this.selectedAutoResponse.title,
        "content": this.selectedAutoResponse.content,
        "mailboxId": this._inboxService.activeMailboxDetails.id,
        "category": EnumTemplatesCategory.AUTO_RESPONSE
      }
      this._inboxSettings.updateTemplate(payload, this.selectedAutoResponse.id).pipe(takeUntil(this.destroy$)).subscribe(
        {
          next: (response: any) => {
            this._utilService.showSuccess('', 'Auto Response Updated Successfully');
          },
          error: (error) => {
            this._utilService.showErrorMessage(error);
          },
          complete: () => {
          }
        }
      );
    }
  }
}
