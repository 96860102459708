import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { UtilityService } from 'src/app/services/utility.service';
import { GraphQLError } from 'graphql';



@Injectable({
  providedIn: 'root'
})



export class GraphqlAdditionalDataService {

  selectedItem:any;
  _viewModule:string='additional_data_item';

  constructor(private readonly apollo: Apollo,public _utilService:UtilityService,private router:Router) {

  }

  getAdditionalDataDetail(id:any){
    return gql `query MyQuery {
      getAdditionalItem(id: "${id}") {
        category
        id
        name
      }
    }`;
  }

  getupdateAdditionalDataDetail(id:any,value:string,category?:any){
    if(category){
      category = category.map(({__typename, ...rest}) => rest);
      category = JSON.stringify(category).replace(/"/g, '\\"');
    }
    return gql `mutation MyMutation {
        updateAdditionalItem(
          id: "${id}",
          input: {
              ${value ? `name: "${value}",` : ""}
              ${category ? `category: "${category}",` : ""}
              active: true
            }
        ) {
          id
          name
          category
        }
    }`
  }

  getAdditionalDataList(columns:any,isSearch:string,pagination:number,pageSize:number,sortColumn?:string,sortDirection?:string,filter?:any){
    return gql`query MyQuery {
      listAdditionalItems(query: {
        query: "{ ${ filter !== undefined && filter[0]?.length > 0 ? `'name':[${encodeURIComponent(filter[0].map((n:string)=> `'${n}'`))}],`:""}${ filter !== undefined && filter[1]?.length > 0 ? `'category_name':[${encodeURIComponent(filter[1].map((n:string)=> `'${n}'`))}],`:""}}",
        columns: "[${columns.map(e=>`'${e.value}'`)},'id']",
        page:  ${pagination},
        search: "${isSearch}",
        size:${pageSize},
        ${sortColumn ? `sort_column: "${sortColumn}",` : ""}
        ${sortDirection ? `sort_order: "${sortDirection}",` : ""}
      })
      {
        page
        size
        total
        items {
          category_name
          id
          name
        }
      }
    }
    `
  }

  addAditionalDataItem(formValue:any){
    if(formValue.category){
      formValue.categoryy = formValue.category.map(({__typename, ...rest}) => rest);
      formValue.category = JSON.stringify(formValue.category).replace(/"/g, '\\"');;
    }
    return gql`
    mutation MyMutation {
      addAdditionalItem (input: {
        name: "${formValue.itemName}",
        ${formValue.category?.length > 0 ? `category: "${formValue.category}"` : ''},
        active: true
      }) {
        category
        id
        name
      }
    }
    `;

  }

  categoriesList(){
    return gql `
    query MyQuery {
      listFyxtCategories {
        items {
          id
          name
        }
      }
    }`
  }

  deleteItemQuery(id:any){
    return gql`
    mutation MyMutation {
      updateAdditionalItem(
          id: "${id}",
          input: {
              active: false,
            }
        ) {
        message
      }
    }`;
  }


  associatedEquipTypeTabListing(id:any, isSearch:string, pagination:number, column?:string, sort?:string, pageSize?: any){
    return gql`
    query MyQuery {
      listAdditionalItemsEquipmentType(
          id: "${id}",
          query: {
              page: ${pagination},
              search:"${isSearch}",
              size: ${pageSize ? pageSize : 25},
              ${column ? `sort_column: "${column}",` : ""}
              ${sort ? `sort_order: "${sort}",` : ""}
            }) {
        items {
          id
          name
          manufacturer_name
        }
        page
        size
        total
      }
    }
    `
  }

  associatedEquipments(id:any,isSearch:string, pagination:number, column?:string, sort?:string, pageSize?: any){
    return gql`
    query MyQuery {
      listAdditionalItemsEquipment(
        id: "${id}",
        query: {
            page: ${pagination},
            search:"${isSearch}",
            size: ${pageSize ? pageSize : 25},
            ${column ? `sort_column: "${column}",` : ""}
            ${sort ? `sort_order: "${sort}",` : ""}
          }) {
        items {
          id
          name
          property_name
          specific_location
          asset_tag
          serial_number
        }
        page
        size
        total
      }
    }
    `
  }

  getFilters(){
    return gql`
    query MyQuery {
      listFilterAdditionalItems(query: {
        #search: ""
      }) {
      items {
        id
        name
      }
    }
      listFyxtCategories {
        items {
          id
          name
        }
      }
    }`
  }

  viewListQuery(){
    return gql `
    query MyQuery {
      listDashboardView(view_type: "additional_data_item") {
        items {
          label
          data {
            id
            name
            pin
            default
            count
            created_by
          }
        }
      }
    }
    `
  }

  viewsTabListing(){
    return gql`query MyQuery {
      my_views : listManageViews(query: {
          view_type: "additional_data_item",
          manage_tab: "my_views",
          #search: "",
          #page: 1,
          size: 50
        }) {
        items {
          id
          default
          pin
          shared
          name
          count
        }
        page
        size
        total
      }
      shared_by_others : listManageViews(query: {
          view_type: "additional_data_item",
          manage_tab: "shared_by_others",
          #search: "",
          #page: 1,
          size: 50
        }) {
        items {
          id
          default
          pin
          shared
          name
          count
        }
        page
        size
        total
      }
      standard : listManageViews(query: {
          view_type: "additional_data_item",
          manage_tab: "standard",
          #search: "",
          #page: 1,
          size: 50
        }) {
        items {
          id
          default
          pin
          shared
          name
          count
        }
        page
        size
        total
      }
    }
   `;
  }


  additionalDataList(columns:any,isSearch?:string, pagination?:number,pageSize?:number,sortColumn?:string,sortDirection?:string,filters?:any) {
    return this.apollo.query({ query: this.getAdditionalDataList(columns,isSearch,pagination,pageSize,sortColumn,sortDirection,filters)}).pipe(map(res=>res.data));
  }

  filtersList() {
    return this.apollo.query({ query: this.getFilters()}).pipe(map(res=>res.data));
  }

  createAdditionalDataItem(formValue:any){
    this.apollo.mutate({
      mutation:this.addAditionalDataItem(formValue),
    }).pipe(map(res=>res.data)).subscribe({
      next:(res:any)=>{
        this._utilService.showSuccess('', 'Additional Data Item Created Successfully');
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          const currentUserInfo: any = localStorage.getItem('userobj')?JSON.parse(localStorage.getItem('userobj')): {};
          if(currentUserInfo.current_Role==="Owner" || currentUserInfo.current_Role==="Manager"){
            this.router.navigate([`/pm/equipment/additionalData/${res.addAdditionalItem!.id}`])
          }
          if(currentUserInfo.current_Role==="Engineer"){
            this.router.navigate([`/engineer/equipment/additionalData/${res.addAdditionalItem!.id}`])
          }
      },
      error:(err:any) => {
        const errorMessage = err?.graphQLErrors?.[0]?.message?.split('=')?.[1]?.replace(/[{}]/g, '').trim() ?? err.message;
        this._utilService.showError('', errorMessage);
       },
    })
  }

  additionalDataDetail(id:any) {
    return this.apollo.query({ query:this.getAdditionalDataDetail(id) }).pipe(map(res=>res.data));
  }

  updateAdditionalDataDetail(id:any,name:string,category?:any){
    return this.apollo.mutate({
      mutation:this.getupdateAdditionalDataDetail(id,name,category),
    }).pipe(map(res=>res.data));
  }

  deleteItem(id:any){
    return this.apollo.mutate({
      mutation:this.deleteItemQuery(id),
    }).pipe(map(res=>res.data));
  }

  associatedEquipmentTypeListing(id:any, isSearch:string, pagination:number, column?:string, sort?:string, pageSize?: any){
    return this.apollo.query({ query: this.associatedEquipTypeTabListing(id, isSearch, pagination, column, sort, pageSize)}).pipe(map(res=>res.data));
  }

  associatedEquipmentsList(id:any,isSearch?:string, pagination?:number, column?:string, sort?:string, pageSize?: any){
    return this.apollo.query({ query: this.associatedEquipments(id, isSearch, pagination, column, sort, pageSize)}).pipe(map(res=>res.data));
  }

  categoryDropdown(){
    return this.apollo.query({ query: this.categoriesList()}).pipe(map(res=>res.data));
  }

  viewList(){
    return this.apollo.query({ query:this.viewListQuery()}).pipe(map(res=>res.data));
  }

  viewsListWithoutSelectingTabDetails(){
    return this.apollo.query({ query: this.viewsTabListing()}).pipe(map(res=>res.data));
  }

  loadFilterspageQueries(search:string,name:string,page?:number){
    return this.apollo.query({ query:this.loadFilterspageQuery(search,name,page) }).pipe(map(res=>res.data));
  }

  loadFilterspageQuery(search:string,name:string,page?:number) {
    return gql `
    query MyQuery {
      ${name}(query: {
          page:${page?page:1}
          #size: 10,
          search: "${search}"
        }) {
        page
        size
        total
        items {
          id
          name
        }
      }
    }`
  }
}
