import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { T_INSPECTION } from '../../../../../Modules/_fyxt_modules/leases/pages/lease-inspection/lease-inspection.interface';
import moment from 'moment';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { DropdownDateSelectComponent } from '../../../_reusable_ui/_controls/dropdown-select/dropdown-date-select/dropdown-date-select.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'fyxt-trigger-inspection',
    templateUrl: './trigger-inspection.component.html',
    styleUrls: ['./trigger-inspection.component.scss'],
    standalone: true,
    imports: [NgIf, DropdownDateSelectComponent, MatDialogModule, ButtonComponent]
})
export class TriggerInspectionComponent implements OnInit {

  public date;
  public dateInPast = false;

  constructor(
    public dialogRef: MatDialogRef<TriggerInspectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { inspection_type: T_INSPECTION, proposed_date: Date, max_date: Date },
  ) { }

  ngOnInit(): void {
    const { proposed_date } = this.data;
    if (proposed_date) {
      this.checkDateIsInPast(proposed_date);
      this.date = proposed_date;
    }
  }

  private checkDateIsInPast (date: Date): void {
    this.dateInPast = moment().diff(date, 'days') > 0 ||
                      (moment().format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'))
  }

  public onChangeDate(date: any) {
    this.checkDateIsInPast(date);
    this.date = date;
  }

  public confirm() {
    if (this.date) {
      const { inspection_type } = this.data;
      const res = {
        [`${inspection_type}`.toLowerCase().split(' ').join('_')]: this.date
      }
      this.dialogRef.close(res)
    }
  }
}
