// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-allowed {
  cursor: not-allowed !important;
}

.gray-out {
  color: #192C49 !important;
  opacity: 0.4 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_reusable_ui/_components/list/list.component.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;AACF;;AAEA;EACE,yBAAA;EACA,uBAAA;AACF","sourcesContent":[".not-allowed {\n  cursor: not-allowed !important;\n}\n\n.gray-out {\n  color: #192C49 !important;\n  opacity: 0.4 !important;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
