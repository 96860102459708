import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';

import { TableColumnTypes,ColumnSortType } from 'src/app/@fyxt/_shared/enums/table/table-column-types.enum';
import { AppTableSource } from 'src/app/@fyxt/_shared/models/app-table-model';

import { EditItemNameComponent } from 'src/app/@fyxt/_shared/_views';
import { AssignItemsEquipmentComponent } from 'src/app/@fyxt/_shared/_views';
import { ActivatedRoute, Router } from '@angular/router';
import { GraphqlEquipmentTypeService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/equipment-type/graphql-equipment-type.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TableService } from 'src/app/@fyxt/_services/table/table.service';
import { EquipmentsService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/equipments.service';
import { TableComponent } from '../../../_reusable_ui/_components/table/table.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { NgIf } from '@angular/common';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';


@Component({
    selector: 'fyxt-tab-additional-data',
    templateUrl: './tab-additional-data.component.html',
    styleUrls: ['./tab-additional-data.component.scss'],
    standalone: true,
    imports: [SearchInputComponent, NgIf, ButtonComponent, MatIconModule, MatDividerModule, LoaderSmallComponent, TableComponent]
})
export class TabAdditionalDataComponent implements OnInit {

  dataSource!: AppTableSource;
  searchInput:string = '';
  isLoader:boolean = false;
  listAssociatedEquipments:any[];
  selectedViewTableRowsTotal: number;
  selectedViewTableRowsPageSize: number;
  selectedViewTableRowsPage: number;
  count:number;
  moduleName ="equipments";
  itemName:any;
  sort = {
    active: '',
    direction: ''
  };

 tableHeaders = [
    {
      value: 'additional_data_item',
      sort: ColumnSortType.none,
      name: 'Item',
      type: TableColumnTypes.text,
      className: 'table-header',
      resizeColumn:true,
    },
    {
      value: 'is_required',
      sort: ColumnSortType.none,
      name: 'Required on Equipment',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'value',
      sort: ColumnSortType.none,
      name: 'Default Value',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'action',
      sort: ColumnSortType.none,
      name: '',
      type: TableColumnTypes.action,
      className: 'three-dot',
      actions: [{ label: 'Edit', value: 'Edit'}, { label: 'Unassign', value: 'Unassign'}]
    }
  ];

  constructor(
    public _dialogService: DialogService,
    private activatedRoute: ActivatedRoute,
    public _graphql:GraphqlEquipmentTypeService,
    public tableService: TableService,
    private router: Router,
    public equipementService:EquipmentsService
  ) {
    if(!this.equipementService.change_equipment_type){
      this.tableHeaders.pop()
    }
    this._graphql.additionalDataTab.subscribe((res:any)=>{
      if(res){
        this.loadEquipmentTypeAdditionalData(this.searchInput,1);
      }
    })
   }

  ngOnInit(): void {
    this.loadEquipmentTypeAdditionalData(this.searchInput,1);
  }

  loadEquipmentTypeAdditionalData(searchInput:string,pageNumber:number,sortColumn?:any,sortDirection?:any, pageSize?: any){
    if(this.activatedRoute.snapshot.routeConfig.path === 'equipmentType/:id' || this.activatedRoute.snapshot.routeConfig.path === 'equipmentType/:id/equipment/:id'
      || this.activatedRoute.snapshot.routeConfig.path === 'additionalData/:id/equipmentType/:id'){
      this.isLoader = true;
      const id = this.router.url.split('/').pop();
      this._graphql.listAdditionalDataTab(id,searchInput,pageNumber,sortColumn,sortDirection, pageSize).subscribe({
        next: (res:any)=>{
          if(res){
            this.listAssociatedEquipments = res.listAssociatedItemsEquipmentType?.items || [];
            this._graphql.AdditionaDataCount =   res.listAssociatedItemsEquipmentType?.total || 0;
            this.selectedViewTableRowsTotal =  res.listAssociatedItemsEquipmentType?.total;
            this.selectedViewTableRowsPageSize = res.listAssociatedItemsEquipmentType?.size;
            this.selectedViewTableRowsPage = res.listAssociatedItemsEquipmentType?.page;
            this._graphql.additionalDataTabCount.emit(true);
          }
        },
        error(err:HttpErrorResponse) {},
        complete:()=>{
          this.isLoader = false;
          if(sortColumn || sortDirection ){
            let sortedHeaders:any[] = this.tableHeaders.map(element => {
              (element.value === sortColumn) ? (element.sort = sortDirection) : (element.sort = ColumnSortType.none );

              return element;
            });
            this.dataSource = this.tableService.setTableData(this.moduleName,sortedHeaders, this.listAssociatedEquipments, this.selectedViewTableRowsTotal,this.selectedViewTableRowsPage-1, this.selectedViewTableRowsPageSize);
          }
          else{
            this.tableHeaders.map(element => {
              return element.sort = ColumnSortType.none;
            });
            this.dataSource = this.tableService.setTableData(this.moduleName,this.tableHeaders, this.listAssociatedEquipments, this.selectedViewTableRowsTotal,this.selectedViewTableRowsPage-1, this.selectedViewTableRowsPageSize);
          }
        }
      });
     }

  }

  onSearch(e:string){
    this.searchInput = e;
    this.sort = {
      active: undefined,
      direction: undefined
    };
    this.loadEquipmentTypeAdditionalData(this.searchInput,1, this.sort.active, this.sort.direction, this.selectedViewTableRowsPageSize);
  }

  onPageChange(e:any){
    this.selectedViewTableRowsPage = e.pageIndex + 1;
    this.selectedViewTableRowsPageSize = e.pageSize;
    this.loadEquipmentTypeAdditionalData(this.searchInput,this.selectedViewTableRowsPage, this.sort.active, this.sort.direction, this.selectedViewTableRowsPageSize);
  }


  @ViewChild('removeAdditionalItem')
  removeAdditionalItem!: TemplateRef<any>;

  addAdditionalPopup(data:any) {
    let popupData = {
      title: `${data.additional_data_item}`,
      data:data,
      component: EditItemNameComponent,
      containerClass: ['modal-sm', 'modal_edit_colomn']
    };

    this._dialogService.openModal(popupData).subscribe(result => {});
  }

  assignItemsPopup() {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    let popupData = {
      title: 'Assign Items',
      id:id,
      component: AssignItemsEquipmentComponent,
      disableClose: true,
      containerClass: 'assign_item_modal'
    };

    this._dialogService.openModal(popupData).subscribe(result => {});
  }

  openRemoveadditionalItemPopup(e:any) {

    let popupData = {
      title: `Unassign ${this.itemName}`,
      buttons:true,
      template: this.removeAdditionalItem,
      confirmText: 'Yes, Unassign',
      cancelText: 'Cancel',
      // selectedData: { name: 'san' },
    };

    this._dialogService.confirmDialog(popupData).subscribe((result:any) => {
      if(result){
        this._graphql.deleteItems(e.id)
      }
    });

  }

  menuSelectedOption(event: any){
      this.itemName = event.additional_data_item;
    if(event.label == "Edit"){
      this.addAdditionalPopup(event);
    }
    else if(event.label == "Unassign"){
      this.openRemoveadditionalItemPopup(event);
    }
  }

  onSortChange(e:any){
    this.sort.active = e.active;
    this.sort.direction = e.direction;
    this.loadEquipmentTypeAdditionalData(this.searchInput,1, this.sort.active, this.sort.direction, this.selectedViewTableRowsPageSize);
  }
}
