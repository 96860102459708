import { enableProdMode, importProvidersFrom } from '@angular/core';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { NgxPrintModule } from 'ngx-print';
import { MailComposerModule } from './app/Modules/inbox/modules/mail-composer/mail-composer.module';
import { QuillModule } from 'ngx-quill';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { GraphQLModule } from './app/graphql.module';
import { AuthModule } from './app/auth/auth.module';
import { AppRoutingModule } from './app/app-routing.module';
import { CoreModule } from './app/Modules/core/core.module';
// import { AgmCoreModule } from '@agm/core';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FileSaverModule } from 'ngx-filesaver';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { NgSelectModule } from '@ng-select/ng-select';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { MaterialModule } from './app/@fyxt/_shared/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AuthGuard } from './app/Gaurd/auth.guard';
import { AuthTokenInterceptorService } from './app/services/Auth/auth-token-interceptor.service';
import { AuthInterceptorService } from './app/services/Auth/auth-interceptor.service';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { CarouselModule } from 'ngx-owl-carousel-o';

if (environment.production) {
    enableProdMode();
}

const maskConfig: Partial<IConfig> = {
    validation: false,
};


bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            BrowserModule,
            BrowserAnimationsModule,
            CommonModule,
            FormsModule,
            ReactiveFormsModule,
            MaterialModule,
            AppRoutingModule,
            CoreModule,
            AuthModule,
            DragDropModule,
            GraphQLModule,
            MailComposerModule,
            ModalModule.forRoot(),
            TooltipModule.forRoot(),
            PopoverModule.forRoot(),
            CollapseModule.forRoot(),
            BsDatepickerModule.forRoot(),
            TimepickerModule.forRoot(),
            NgSelectModule,
            NgxIntlTelInputModule,
            FileSaverModule,
            InfiniteScrollModule,
            CarouselModule,
            PaginationModule.forRoot(),
            AngularEditorModule,
            SortableModule.forRoot(),
            AccordionModule.forRoot(),
            NgxDocViewerModule,
            QuillModule.forRoot(),
            ModalModule.forRoot(),
            NgxPrintModule,
            NgxMaskDirective,
            NgxMaskPipe,
            ToastrModule.forRoot({
                timeOut: 2000,
                positionClass: 'toast-top-right',
                preventDuplicates: true,
            }),
        ),
        AuthGuard,
        provideNgxMask(maskConfig),
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptorService, multi: true },
        // { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi())
    ]
})
    .catch(err => console.error(err));
