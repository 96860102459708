import CountUpdateEventEntity from '../models/entities/events/count_update_event_entity';
import DraftMessageEventEntity from '../models/entities/events/draft_message_event_entity';
import { Injectable } from '@angular/core';
import MailViewEventEntity from '../models/entities/events/mail_view_event_entity';
import NewMessageEventEntity from '../models/entities/events/new_messages_event-entity';
import RealtimeEvents from 'src/app/services/events/realtime-events.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export default class InboxRealtimeEventsService {
  constructor(private realtimeEvents: RealtimeEvents) {}

  getNewMessageEvent() {
    return this.realtimeEvents
      .getEvents('INBOX', 'NEW_MESSAGES')
      .pipe(map((event: NewMessageEventEntity) => event));
  }

  getDraftMessageEvent() {
    return this.realtimeEvents
      .getEvents('INBOX', 'DRAFT_MESSAGES')
      .pipe(map((event: DraftMessageEventEntity) => event));
  }

  getMailCountEvent() {
    return this.realtimeEvents
      .getEvents('INBOX', 'MESSAGES_COUNT')
      .pipe(map((event: CountUpdateEventEntity) => event));
  }

  getMailViewEvents() {
    return this.realtimeEvents
      .getEvents('INBOX', 'CURRENT_MAIL_VIEWERS')
      .pipe(map((event: MailViewEventEntity) => event));
  }

  getTypingStatus(channel){   
    return this.realtimeEvents
    .getEvents(channel, 'TYPE_STATUS')
    .pipe(map((event: MailViewEventEntity) => event));
  }

  getMailSentStatus(channel){
    return this.realtimeEvents
    .getEvents(channel, 'MAIL_SENT')
    .pipe(map((event: any) => event));
  }

  getTrashStatus(channel){
    return this.realtimeEvents
    .getEvents(channel, 'type_trash')
    .pipe(map((event: any) => event));
  }
}
