import { Injectable } from '@angular/core';
import { ColumnSortType, TableColumnTypes } from 'src/app/@fyxt/_shared/enums/table/table-column-types.enum';
import { BaseService } from 'src/app/services/base.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomTabsService {

  private localUrl = 'https://ca.devapifyxt.com/';
  public selectedPropertyDetails = null;
  public selectedLeaseDetails = null;
  public selectedCompanyDetails = null;
  public selectedContactDetails = null;
  public selectedJobDetails = null;
  subDomain: any;

  constructor(private _baseService: BaseService) {
    let url = window.location.origin;
    this.subDomain = this.extractDomain(url).split('.')[0];
    this.subDomain == 'localhost' ? this.subDomain = 'web' : this.subDomain;
  }

  extractDomain(url: string) {
    let domain;
    if (url.indexOf('://') > -1) {
      domain = url.split('/')[2];
    } else {
      domain = url.split('/')[0];
    }
    domain = domain.split(':')[0];
    return domain;
  }
  createTab(payload: any) {
    let url = environment.ca.url + 'manage/tabs'
    return this._baseService.doPOST(url, payload, { headers: { "x-tenant-id": this.subDomain } })
  }

  listTab(entityType, search = '',sortType = {name : '', type : ''}) {
    let sort = `&sortField=${sortType.name}&sortType=${sortType.type}`
    let url = environment.ca.url + `manage/tabs?entityType=${entityType}&q=${search}${sort}`;
    return this._baseService.doGET(url, { headers: { "x-tenant-id": this.subDomain } })
  }

  getTabs(entityType, showHiddenTabs = false) {
    let url = environment.ca.url + `tabs?entityType=${entityType}`;

    if (showHiddenTabs) {
      url = url + '&includeHiddenTabs=true'
    }

    return this._baseService.doGET(url, { headers: { "x-tenant-id": this.subDomain } })
  }

  getTabData(tabId, entityId) {
    let url = environment.ca.url + `tabs/${tabId}/data/${entityId}`;
    return this._baseService.doGET(url, { headers: { "x-tenant-id": this.subDomain } })
  }

  deleteTab(tabId) {
    let url = environment.ca.url + `manage/tabs/${tabId}`;
    return this._baseService.doDelete(url, { headers: { "x-tenant-id": this.subDomain } })
  }

  getParticularTab(tabId) {
    let url = environment.ca.url + `manage/tabs/${tabId}/fields`;
    return this._baseService.doGET(url, { headers: { "x-tenant-id": this.subDomain } })
  }

  updateTab(tabId, payload) {
    let url = environment.ca.url + `manage/tabs/${tabId}`;
    return this._baseService.doPUT(url, payload, { headers: { "x-tenant-id": this.subDomain } })
  }

  updateTabData(tabId, payload) {
    let url = environment.ca.url + `manage/fields`;
    return this._baseService.doPOST(url, payload, { headers: { "x-tenant-id": this.subDomain } })
  }

  getCustomAttributeHeadersData(entityType, isFilterable?) {
    let filter = ''
    if (isFilterable){
      filter = `&isFilterable=${isFilterable}`
    }
    let url = environment.ca.url + `external/fields?entityType=${entityType}${filter}`;
    return this._baseService.doGET(url, { headers: { "x-tenant-id": this.subDomain } })
  }

  snakeCaseToUserFriendly(input:string){
    // input x_gl_codes output Gl Codes
    const parts = input.replace('X_', '').split('_');
    return parts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
  }

  removeCustomAttKey(input:string){
    return input.replace("custom_attributes.", "");
  }

  snakeCaseToCamel(input:string){
    const parts = input.split('_');
    return parts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
  }

  fieldList(entityType:string,field:string,search:string,page = 1){
    let url = environment.ca.url + `external/fields/search?entityType=${entityType}&field=${field}&page=${page}`;
    if(search){
      url = url + `&q=${search}`
    }
    return this._baseService.doGET(url, { headers: { "x-tenant-id": this.subDomain } });
  }

  getServiceTypes() {
    const url = environment.baseURL + 'jobs/service-type/';
    return this._baseService.doGET(url)
  }

  swapFields(filed,payload) {
    const url = environment.ca.url + `manage/fields/${filed}/tabs/swap`;
    return this._baseService.doPOST(url, payload , { headers: { "x-tenant-id": this.subDomain } });
  }

  changeTabOrder(payload) {
    const url = environment.ca.url + `tabs/order`;
    return this._baseService.doPUT(url, payload, { headers: { "x-tenant-id": this.subDomain } });
  }

  filterTabs(tabs, type) {

    if (type == 'COMPANY') {
      const allowedCompanyTabs = ["properties", "associated_jobs", "contacts", "leases", "users", "notes", "files", "activity", "coi", "categories"];
      return tabs.map(item => {
        item.show = (item.type === "NATIVE") ? allowedCompanyTabs.includes(item.identifier) : (item.type === "CUSTOM") ? true : false;
        return item;
      });
    }

    if (type == 'PROPERTY') {
      const allowedPropertyTabs = ["vendors", "tenants", "leases", "managers", "engineers", "coi", "notes", "files","jobs","owners","activity", "equipment", "contacts", "company"];
      return tabs.map(item => {
        item.show = (item.type === "NATIVE") ? allowedPropertyTabs.includes(item.identifier) : (item.type === "CUSTOM") ? true : false;
        return item;
      });
    }

    if (type == 'JOB') {
      const allowedJobTabs = ["notes", "activity", "scheduling", "vendors", "job_cost", "linked_job", "timesheet", "material", "checklist", "equipment", "files"];
      return tabs.map(item => {
        item.show = (item.type === "NATIVE") ? allowedJobTabs.includes(item.identifier) : (item.type === "CUSTOM") ? true : false;
        return item;
      });
    }


  }

  createCustomHeaders(subFields) {

    const filteredData = subFields.filter(item => !item.isHidden);

    const tableHeaders = filteredData.map((item) => ({
      ...item,
      sort: ColumnSortType.none,
      type: TableColumnTypes.text,
      className: 'table-header',
      resizeColumn: true,
      sortDisabled: true,
      value: item.identifier
    }))

    const deleteButton = {
      value: 'action',
      sort: ColumnSortType.none,
      name: '',
      type: TableColumnTypes.action,
      className: 'three-dot',
      actions: [{ label: 'Delete', value: 'Delete' }]
    }

    return [...tableHeaders, deleteButton];
  }


  addTableRow(tableId,payload) {
    const url = environment.ca.url + `manage/fields/table/` + tableId + `/rows/add`;
    payload.entityId = `${payload.entityId}`;
    return this._baseService.doPOST(url, payload, { headers: { "x-tenant-id": this.subDomain } });
  }

  deleteTableRow(tableId, payload) {
    payload.entityId = `${payload.entityId}`;
    const options = {
      headers: { "x-tenant-id": this.subDomain },
      body: payload
    };
    const url = environment.ca.url + `manage/fields/table/` + tableId + `/rows/remove`;
    return this._baseService.doDelete(url, options);
  }

  updateTableRow(tableId, payload) {
    const url = environment.ca.url + `manage/fields/table/` + tableId + `/rows/update`;
    payload.entityId = `${payload.entityId}`;
    return this._baseService.doPUT(url, payload, { headers: { "x-tenant-id": this.subDomain } });
  }

  updateTabVisibility(tabId, payload) {
    const url = environment.ca.url + `tabs/` + tabId + `/update-visibility`;
    return this._baseService.doPOST(url, payload, { headers: { "x-tenant-id": this.subDomain } });
  }

  getFieldIdentifierValues(payload) {
    let url = environment.ca.url + `manage/fields/value?entityType=${payload.entityType}&entityId=${payload.entityId}&fieldIdentifier=${payload.fieldIdentifier}`
    return this._baseService.doGET(url, { headers: { "x-tenant-id": this.subDomain } });
  }

  getEntityIdForIdentiferField(entityType, keyChain) {
    switch (entityType) {
      case 'JOB':
        return this.getNestedValue(this.selectedJobDetails, keyChain);
  
      case 'PROPERTY':
        return this.getNestedValue(this.selectedPropertyDetails, keyChain);
  
      case 'COMPANY':
        return this.getNestedValue(this.selectedCompanyDetails, keyChain);
  
      case 'LEASE':
        return this.getNestedValue(this.selectedLeaseDetails, keyChain);
  
      default:
        return null;
    }
  }

  getNestedValue(obj: any, keyChain: string): any {
    return keyChain.split('.').reduce((acc, key) => acc && acc[key], obj);
  }

}
