import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'fyxt-loader-small',
    templateUrl: './loader-small.component.html',
    styleUrls: ['./loader-small.component.scss'],
    standalone: true
})
export class LoaderSmallComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
