import { Component, Inject, OnInit } from '@angular/core';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { AddCompanyComponent } from 'src/app/@fyxt/_shared/_forms';
import { environment } from "../../../../../../environments/environment";
import { UtilityService } from "../../../../../services/utility.service";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import { initLeaseParams, LeasesStoreService } from "../../../../_services/leases/leases-store.service";
import { LeaseOptionsItem } from "../../../models/porperty-manager/leases";
import { takeUntil } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { ILeasesAPIResponse } from "../../../../_services/leases/leases-graphql.service";
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { DropdownSelectComponent } from '../../../_reusable_ui/_controls/dropdown-select/dropdown-select/dropdown-select.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { CheckboxComponent } from '../../../_reusable_ui/_controls/checkbox/checkbox.component';
import { MatRadioModule } from '@angular/material/radio';
import { NgClass, NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'fyxt-invite-users',
    templateUrl: './invite-users.component.html',
    styleUrls: ['./invite-users.component.scss'],
    standalone: true,
    imports: [NgClass, MatRadioModule, NgIf, CheckboxComponent, FormsModule, NgxIntlTelInputModule, DropdownSelectComponent, NgFor, ButtonComponent, MatTooltipModule, MatIconModule, MatDialogModule]
})
export class InviteUsersComponent implements OnInit {
  private readonly destroy$: Subject<null> = new Subject<null>();
  private readonly leases$: Observable<ILeasesAPIResponse> = this._leasesStoreService.leases$;
  isEditUser: boolean;
  scheduleServiceDates = false;
  approveJobCost = false;
  updateJobTicketStatus = false;
  createJobTicketStatus = false;
  approveSchedules = false;
  viewLeaseDetails = false;
  viewVendorContactLibrary = false;
  isChecked: boolean = false;
  contactsList: any[] = [];
  contactsListPageNumber: number = 1;
  contactsListTotal: number;
  contactsListSize: number;
  contactsSearchInput: any = "";
  contactsListPageCount: number;
  isLoader: boolean = false;
  selectedContacts: any[] = [];
  unitsList: any[] = [];
  selectedUnits: any[] = [];
  unitsListPageNumber: number = 1;
  unitsListTotal: number;
  unitsListSize: number;
  unitsSearchInput: any = "";
  unitsListPageCount: number;
  isAdminUser = false;
  checkedValue: any = 'LimitedUser';
  selectedCompanyDetails: any;
  selectedUserDetails: any;
  propertiesList = [];
  isLoading: boolean=false;

  constructor(
    public _dialogService: DialogService,
    public _utilService: UtilityService, public dialogRef: MatDialogRef<InviteUsersComponent>,
    private readonly _leasesStoreService: LeasesStoreService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.selectedCompanyDetails = this.data?.data?.selectedCompanyDetails;
    this.isEditUser = this.data?.data?.isEditUSer;
    this.selectedUserDetails = this.data?.data?.selectedUserDetails;
    this.propertiesList = this.data?.data?.propertiesList;
  }

  ngOnInit(): void {
    this.getAllContactsList();
    // this.getAllUnitsList();
    this.getLeasesWithUpdatedParams({
      ...initLeaseParams,
      filters: {
        companies: [this.selectedCompanyDetails.id]
      }
    });
    if (this.isEditUser) {
      this.retrieveUser();
    }
  }

  onChangeRadioButton(event: any): void {
    this.checkedValue = event.value;
    if (this.checkedValue == 'AdminUser') {
      this.isAdminUser = true;
    } else {
      this.isAdminUser = false;
    }
  }

  retrieveUser(): void {
    let requestURL = environment.fastAPI_Actual_URL + "/companies/" + this.selectedCompanyDetails.id + "/users/" + this.selectedUserDetails.id + "/";
    this._dialogService.doGET(requestURL).subscribe(
      {
        next: (response: any) => {
          this.updateUserValue(response);
        },
        error: (error) => {
          this.isLoader = false;
          this._utilService.showErrorMessage(error);
        },
        complete: () => {
          this.isLoader = false;
        }
      }
    );
  }

  updateUserValue(user: any): void {
    if (user.admin) {
      this.checkedValue = 'AdminUser';
      this.isAdminUser = true;
    } else {
      this.checkedValue = 'LimitedUser';
      this.isAdminUser = false;
    }
    if (user?.contacts) {
      this.selectedContacts = [user?.contacts];
    }
    user.properties.map((unit: any) => {
      return unit.name += ' - ' + unit.property_name;
    });
    this.selectedUnits = user.properties;
    this.scheduleServiceDates = user.permissions.is_user_can_schedule_service_dates;
    this.approveJobCost = user.permissions.is_user_can_approve_job_costs_for_tenant_responsible_jobs;
    this.updateJobTicketStatus = user.permissions.is_user_can_update_job_ticket_status;
    this.createJobTicketStatus = user.permissions.is_user_can_create_job_tickets;
    this.viewLeaseDetails = user.permissions.is_user_can_view_lease_abstracts;
    this.viewVendorContactLibrary = user.permissions.is_user_can_view_vendors;
    this.approveSchedules = user.permissions.is_user_can_approve_schedule;
  }

  getAllContactsList(pagination?: boolean,isSearch?: boolean): void {
    let requestURL = environment.fastAPI_Actual_URL + "/companies/" + this.selectedCompanyDetails.id + "/contacts/?page=" + this.contactsListPageNumber + '&invite=True';
    if (isSearch) {
      requestURL = environment.fastAPI_Actual_URL + "/companies/" + this.selectedCompanyDetails.id + "/contacts/?page=" + this.contactsListPageNumber + '&search=' + this.contactsSearchInput;
    }
    this._dialogService.doGET(requestURL).subscribe(
      {
        next: (response: any) => {
          if (response.items.length > 0) {
            response.items.map((list: any) => {
              return list.name = (list.first_name || list.last_name) ? list.first_name + ' ' + list.last_name : 'Unknown contact';
            });
            response.items = response.items.filter((list: any) => {
              return list.type == 'Tenant';
            });
          }
          if (pagination) {
            this.contactsList = this.contactsList.concat(response.items);
          } else {
            this.contactsList = response.items;
            this.contactsListTotal = response.total;
            this.contactsListSize = response.size;
            this.contactsListPageCount = Math.round(this.contactsListTotal / this.contactsListSize)
          }
        },
        error: (error) => {
          this.isLoader = false;
          this._utilService.showErrorMessage(error);

        },
        complete: () => {
          this.isLoader = false;
        }
      }
    );
  }

  companyDropdownSearch(event: any) {
    this.contactsSearchInput = event.term;
    this.contactsListPageNumber = 1;
    this.getAllContactsList(false,true);
  }

  companyDropdownsScroll() {
    if (this.contactsListPageNumber <= this.contactsListPageCount) {
      this.contactsListPageNumber++;
      this.getAllContactsList(true);
    }
  }

  changeContacts(event: any) {
    this.selectedContacts = event;
  }

  changeUnits(event: any) {
    this.selectedUnits = event;
  }

  removeSelectedContact(event: any) {
    // const deleteContactIndex = this.selectedContacts.findIndex((contact) => {
    //   return contact.id == event.id;
    // });
    //  this.selectedContacts.splice(deleteContactIndex, 1);
    this.selectedContacts = this.selectedContacts.filter((user: any) => {
      return user.id != event.id;
    });
  }

  removeSelectedUnits(event: any): any {
    this.selectedUnits = this.selectedUnits.filter((unit: any) => {
      return unit.id != event.id;
    });
  }

  getAllUnitsList(): void {
    let requestURL = environment.baseURL + `units/cc-user-properties/`;
    const payload = {
      properties: this.propertiesList
    };
    this._dialogService.doPOST(requestURL, payload).subscribe(
      {
        next: (response: any) => {
          this.unitsList = response;
          this.unitsList.map((unit: any) => {
            return unit.name += ' - ' + unit.property_name;
          });
        },
        error: (error) => {
          this.isLoader = false;
          this._utilService.showErrorMessage(error);

        },
        complete: () => {
          this.isLoader = false;
        }
      }
    );
  }

  getLeasesWithUpdatedParams(params: LeaseOptionsItem, isPagination?: boolean) {
    this._leasesStoreService.setQueryLeaseParams(params)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (value) => {
          if (isPagination) {
            this.unitsList = this.unitsList.concat(value.leases);
          } else {
            this.unitsList = value.leases;
          }
          this.unitsList.map((lease: any) => {
            return lease.units.map((unit: any) => {
              if (lease.property.name != null) {
                return unit.name += ' - ' + lease.property.name;
              } else {
                return unit.name;
              }
            });
          });
          this.unitsList = this.unitsList.map((lease: any) => {
            return lease.units;
          })
          this.unitsList = Array.prototype.concat.apply([], this.unitsList);
          this.isLoader = false;
        },
        error: () => {
          this.isLoader = false;
        }
      });
  }

  unitsDropdownSearch(event: any): void {
    this.unitsSearchInput = event.term;
    this.unitsListPageNumber = 1;
    this.getLeasesWithUpdatedParams({
      ...initLeaseParams,
      search: this.unitsSearchInput,
      page: this.unitsListPageNumber,
      filters: {
        companies: [this.selectedCompanyDetails.id]
      }
    }, false);
  }

  unitDropdownsScroll(): void {
    this.unitsListPageNumber += 1;
    this.getLeasesWithUpdatedParams({
      ...initLeaseParams,
      search: this.unitsSearchInput ? this.unitsSearchInput : '',
      page: this.unitsListPageNumber,
      filters: {
        companies: [this.selectedCompanyDetails.id]
      }
    }, true);

  }

  flatten(arr): void {
    return arr.reduce(function (flat, toFlatten) {
      return flat.concat(Array.isArray(toFlatten) ? this.flatten(toFlatten) : toFlatten);
    }, []);
  }

  /**
   * Show Create Company Form
   */
  createNewCompanyPopup() {
    let popupData = {
      title: 'Create New Company',
      component: AddCompanyComponent,
      containerClass: 'modal_manage_views',
      data: {
        companyModal: true
      }
    };
    this._dialogService.openModal(popupData).subscribe(result => {});
  }

  inviteUsers(): void {
    let payload = {
      admin: this.checkedValue == 'AdminUser' ? true : false,
      permissions: {
        is_user_can_schedule_service_dates: this.scheduleServiceDates,
        is_user_can_approve_job_costs_for_tenant_responsible_jobs: this.approveJobCost,
        is_user_can_update_job_ticket_status: this.updateJobTicketStatus,
        is_user_can_create_job_tickets: this.createJobTicketStatus,
        is_user_can_view_lease_abstracts: this.viewLeaseDetails,
        is_user_can_view_vendors: this.viewVendorContactLibrary,
        is_user_can_approve_schedule: this.approveSchedules
      },
      contact_id: this.selectedContacts.map((contact: any) => {
        return contact.id || [];
      }),
      units: this.selectedUnits.map((unit: any) => {
        return unit.id || [];
      }),
    }
    if (payload.admin) {
      payload.permissions.is_user_can_create_job_tickets = true;
      payload.permissions.is_user_can_approve_job_costs_for_tenant_responsible_jobs = true;
      payload.permissions.is_user_can_update_job_ticket_status = true;
      payload.permissions.is_user_can_schedule_service_dates = true;
      payload.permissions.is_user_can_view_lease_abstracts = true;
      payload.permissions.is_user_can_view_vendors = true;
      payload.permissions.is_user_can_approve_schedule = true;
      payload.units = this.unitsList.map(obj => obj.id) || [];
    }
    let requestURL = environment.fastAPI_Actual_URL + "/companies/" + this.selectedCompanyDetails.id + "/users/";

    this.isLoading = true;
    this._dialogService.doPOST(requestURL, payload).subscribe(
      {
        next: (response: any) => {
          response.isAdd = true;
          this.dialogRef.close(response);
          this._utilService.showSuccess('', 'User invite(s) processing');
        },
        error: (error) => {
          this._utilService.showErrorMessage(error);
          // this._utilService.showError('', 'Something went wrong, please try again later');
          this.isLoading = false;

        },
        complete: () => {
          this.isLoading = false;

        }
      }
    );
  }

  updateUsers(): void {
    let payload = {
      admin: this.checkedValue == 'AdminUser' ? true : false,
      permissions: {
        is_user_can_schedule_service_dates: this.scheduleServiceDates,
        is_user_can_approve_job_costs_for_tenant_responsible_jobs: this.approveJobCost,
        is_user_can_update_job_ticket_status: this.updateJobTicketStatus,
        is_user_can_create_job_tickets: this.createJobTicketStatus,
        is_user_can_view_lease_abstracts: this.viewLeaseDetails,
        is_user_can_view_vendors: this.viewVendorContactLibrary,
        is_user_can_approve_schedule: this.approveSchedules
      },
      units: this.selectedUnits.map((unit: any) => {
        return unit.id || [];
      }),
    }
    if (payload.admin) {
      payload.permissions.is_user_can_create_job_tickets = true;
      payload.permissions.is_user_can_approve_job_costs_for_tenant_responsible_jobs = true;
      payload.permissions.is_user_can_update_job_ticket_status = true;
      payload.permissions.is_user_can_schedule_service_dates = true;
      payload.permissions.is_user_can_view_lease_abstracts = true;
      payload.permissions.is_user_can_view_vendors = true;
      payload.permissions.is_user_can_approve_schedule = true;
      payload.units = this.unitsList.map(obj => obj.id) || [];
    }
    let requestURL = environment.fastAPI_Actual_URL + "/companies/" + this.selectedCompanyDetails.id + "/users/" + this.selectedUserDetails.id + "/";
   
    this.isLoading = true;

    this._dialogService.doPATCH(requestURL, payload).subscribe(
      {
        next: (response: any) => {
          response.isAdd = true;
          this.dialogRef.close(response);
          this._utilService.showSuccess('', 'User Updated Successfully.');
        },
        error: (error) => {
          this._utilService.showErrorMessage(error);
          // this._utilService.showError('', 'Something went wrong, please try again later');
          this.isLoading = false;

        },
        complete: () => {
          this.isLoading = false;

        }
      }
    );
  }

   /** Track by Index  **/
   trackByIndex(index: number, item: any): number {
    return index;
  }

}
