import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import Pusher from 'pusher-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export default class RealtimeEvents {
  private pusher: Pusher;
  constructor() {
    this.pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster
    });
  }

  getEvents<T>(channelId: string, eventName: string) {
    return new Observable<T>((subscriber) => {
      this.pusher.subscribe(channelId).bind(eventName, (data: T) => {
        subscriber.next(data);
      });
    });
  }
}
