import { ElementRef, EventEmitter, Injectable, Output } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';

import { environment } from 'src/environments/environment';

import { RestAPIService } from './rest-api.service';
import { Config2Service } from './config2.service';
import { ConfigService } from 'src/app/services/config.service';
import { FilterService } from './filter.service';
import { UtilService } from './util.service';
import { ToastrService } from 'ngx-toastr';
import { InboxService } from "../Inbox/inbox.service";
import { TicketFlowsService } from "../ticket-flows.service";
import { BaseService } from "../base.service";
import { MessagesService } from "../../Modules/inbox/services/messages.service";
import { ActivatedRoute } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import InboxApi from 'src/app/Modules/inbox/services/inbox-api.service';
import { InboxLayoutService } from "../../Modules/inbox/services/inbox-layout.service";
import { ChecklistService } from '../checklist/checklist.service';
import RealtimeEvents from '../events/realtime-events.service';
import { map, switchMap } from 'rxjs/operators';
import { SidebarService } from "../../@fyxt/_services/_layout/sidebar.service";
import { HttpClient } from "@angular/common/http";
import { LeasesHttpService } from '../../@fyxt/_services/leases/leases-http.service';
import { CustomTabsService } from 'src/app/Modules/_fyxt_modules/companies/_services/custom-tabs.service';
import { AssociatedMailsInJobComponent } from 'src/app/@fyxt/_shared/_views';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';

@Injectable({
  providedIn: 'root'
})
export class JobsService {
  currentUserInfo: any = localStorage.getItem('userobj')
    ? JSON.parse(localStorage.getItem('userobj'))
    : {};
  dashboardRow: WebSocketSubject<unknown>;
  bulkActionTriggers: WebSocketSubject<unknown>;
  jobInfoCount: WebSocketSubject<unknown>;
  socketTrigger_Activities: WebSocketSubject<unknown>;
  socketTrigger_Notes: WebSocketSubject<unknown>;

  @Output() equipmentAssigned: EventEmitter<any> = new EventEmitter();

  showJobInfo = new Subject();
  setDashboardTab = new Subject();
  isJobLoaded = new Subject();
  isJobActionDropdown = new Subject();
  isJobStatusUpdate = new Subject();

  isAddNewOpen = new Subject();
  isJobAdded = new Subject();
  isJobUpdated = new Subject();
  isColumnResize = new Subject();
  isJobTabClicked = new Subject();
  isNotes_Live_Recieved = new Subject();
  isJobMailLoaded = new Subject();
  isDraftJob = new Subject();
  isTenantJobUpdated = new Subject();
  isTargetJobCostUpdated = new Subject();

  isSaveAsNew: boolean = false;
  isFrom_NewJob: boolean = false;

  allDashboardTabsList: any[] = [];
  selectedActiveTab: any;
  allExistingTabsList: any = {};
  currentPage: any;
  noPmErrorMessage: any;
  noTimeSheetErrorMessage: any;
  showBulkActionHeader = false;
  vendorListId: any;
  isJob_HalfPageView: boolean = false;
  isJobInfo_Preview: boolean = false;
  isJobInfo_back: boolean = false;
  isLeftArrow_Minimise: boolean = false;
  isRightArrow_Minimise: boolean = false;
  sourceBack: string = 'Dashboard';
  associateMailBulkActionAccess: boolean = false;
  associateOpened: boolean;
  selection = new SelectionModel<any>(true, []);
  jobsStatus: boolean = false;
  public showRequiredChecklistTemplate = new BehaviorSubject<boolean>(false);
  public checklistCountUpdated = new BehaviorSubject<boolean>(false);
  public workOrderTabUpdate = new BehaviorSubject<boolean>(false);
  public vendorUpdate = new BehaviorSubject<boolean>(false);
  public clearAllDateFieldFilters = new BehaviorSubject<boolean>(false);
  public updateLinkedJobDetails = new BehaviorSubject<boolean>(false);
  public updateJobsTabs = new BehaviorSubject<any>(null);
  showCommentPhotoRequiredHighlights = false;
  currentActiveTab: any;


  // listAlldashboardJobs:any[]=[];
  selectedJobInfo: any = {};
  selectedTenantJobInfo: any = {};

  listAllDashboardJobs: any = {
    is_pagination: false,
    current_page: null,
    table_columns: [],
    list_data: [],
    total_counts: 0,
    is_sort: false,
    sort_key: '',
    sort_by: 'asc',
    sort_clicked: {
      count: 0,
      column_key: ''
    },
    is_search: false,
    search_keyword: '',
    itemsPerPage: 25,
    columns_order: [],
    isShowFilter: true,
    selectedFilters: []
  };
  listAllColumns: any[] = [];
  selectedColumnItems: any[] = [];

  showAlert = {
    isSuccess: false,
    isError: false,
    successMessage: '',
    errorMessage: ''
  };

  jobBulkAction = {
    selectedModal: '',
    allCheckbox: false
  };

  assignUserModal = {
    hideSelectAll: false,
    searchVal: '',
    allUserListCheckbox: false
  };
  isLoader: boolean = false;

  /* Recent Job */
  // selectedJobDetails: any = {};
  filteredItems: any = {
    searchKey: '',
    tenants: {
      selectedItems: []
    },
    locations: {
      selectedItems: []
    },
    categories: {
      selectedItems: []
    }
  };
  filterPayload: any = {
    tenants: [],
    locations: [],
    categories: []
  };
  // selectedTenantList: any[] = [];
  // selectedLocationItemsList: any;
  // selectedCategoryItemsList: any[] = [];
  propertyLoading: boolean = false;
  // recentJobSearch: any = '';
  listAllRecentJobs: any = {
    is_pagination: false,
    current_page: 1,
    table_columns: [
      { id: 'id', order: 1, name: 'Job ID' },
      { id: 'issue_type', order: 2, name: 'Brief Description' },
      { id: 'category', order: 3, name: 'Category' },
      { id: 'status', order: 4, name: 'Priority' },
      { id: 'created_at', order: 5, name: 'Actions' },
      { id: 'unit', order: 6, name: 'Location' }
    ],
    list_data: [],
    total_counts: 0,
    is_sort: false,
    sort_key: '',
    sort_by: '',
    is_search: false,
    search_keyword: '',
    itemsPerPage: 6
  };

  selctedFilesToPreview: any[] = [];
  selectedPreview_File: any = '';
  allJobStagesList: any[] = [];

  allJobActivityList: any = {
    pagination: { next: '', count: 0 },
    data: []
  };

  allJobNotes: any[] = [];
  myMailJobSubscription: any;

  listAllJobsFeedback: any = {
    is_pagination: false,
    current_page: 1,
    list_data: [],
    total_counts: 0,
    is_sort: false,
    sort_key: '',
    sort_by: '',
    is_search: false,
    search_keyword: '',
    itemsPerPage: 6
  };

  socketOpened: boolean = false;
  jobNotesClosed: boolean;

  isEngineerJob: any = {
  };
  listEquipments_Dropdown: any;
  subDomain: any;
  isFromEXV: boolean = false;
  isSoftAuth: boolean = false;

  associatedEmailsInJob = [];
  associatedEmailsInJobCount: number = 0;
  viewIdForLinkedJob: any
  constructor(
    public _Config: ConfigService,
    public _Config2: Config2Service,
    public _baseService: RestAPIService,
    public _filterService: FilterService,
    public _utilService: UtilService,
    private toastr: ToastrService,
    public _inboxService: InboxService,
    public _ticketService: TicketFlowsService,
    public baseService: BaseService,
    private messageService: MessagesService,
    private activatedRoute: ActivatedRoute,
    public inboxApi: InboxApi,
    public inboxLayoutService: InboxLayoutService,
    public _checkList: ChecklistService,
    public sidebarService: SidebarService,
    private realtimeEvents: RealtimeEvents,
    private readonly http: HttpClient,
    private readonly leaseService: LeasesHttpService,
    private _customTabsService: CustomTabsService,
    private _dialogService: DialogService
  ) {
    let url = window.location.origin;
    this.subDomain = this.extractDomain(url).split('.')[0];
    this.subDomain == 'localhost' ? this.subDomain = 'web' : this.subDomain;
    /************ Selected Tab Active ****************/
    this.setDashboardTab.subscribe((selectedItem: any) => {
      this.selectedActiveTab = selectedItem;

      this.listAllDashboardJobs = {
        is_pagination: false,
        current_page: null,
        table_columns: selectedItem.column,
        list_data: [],
        total_counts: 0,
        is_search: false,
        search_keyword: '',
        columns_order: [],
        isShowFilter: false,
        selectedFilters: [],
        itemsPerPage: 25,
        sort_clicked: {
          count: 0,
          column_key: ''
        },
        is_sort: true,
        sort_key: 'modified_at',
        sort_by: 'desc',
      };

      if (selectedItem.query) {
        this.listAllDashboardJobs.is_sort = selectedItem.query.column
          ? true
          : this.listAllDashboardJobs.is_sort;
        this.listAllDashboardJobs.sort_key = selectedItem.query.column
          ? selectedItem.query.column
          : this.listAllDashboardJobs.sort_key;
        this.listAllDashboardJobs.sort_by = selectedItem.query.order
          ? selectedItem.query.order
          : this.listAllDashboardJobs.order;

        this.listAllDashboardJobs.itemsPerPage = selectedItem.query.per_page
          ? selectedItem.query.per_page
          : 25;
      }

      setTimeout(() => {
        this.listAllDashboardJobs.isShowFilter = true;
      }, 1500);

      // this._filterService.loadAllFilterAPIs(this.selectedActiveTab);
    });
  }

  /**
   * If redirect to next page
   * @param pageURL
   */
  redirectTo(pageURL: any) {
    this._baseService.redirectTo(pageURL);
  }

  extractDomain(url: string) {
    let domain;
    if (url.indexOf("://") > -1) {
      domain = url.split('/')[2];
    } else {
      domain = url.split('/')[0];
    }
    domain = domain.split(':')[0];
    return domain;
  }

  /**
   * Is Job Editable
   * @returns
   */
  isEditable_Job(columnType?: string): boolean {
    if (this.selectedJobInfo?.is_From_Mail && !this.selectedJobInfo?.is_edit) {
      return false;
    }
    if (
      this.currentUserInfo?.current_Role == 'Owner' ||
      this.currentUserInfo?.current_Role == 'Manager' ||
      (this.currentUserInfo?.current_Role == 'Tenant' &&
        this.selectedJobInfo?.responsible == 'Tenant')
    ) {
      if (columnType == 'courtesy_job' && this.selectedJobInfo?.responsible != 'Tenant') {
        return false;
      } else if (columnType == 'category') {
        return !['Move In', 'Move Out'].includes(this.selectedJobInfo?.category?.name)
      } else {
        if (this.selectedJobInfo?.status == 'Created') {
          return false;
        } else {
          return true;
        }
      }
    } else {
      if ((columnType == 'Status' || columnType == 'Priority') && this.currentUserInfo?.current_Role == 'Engineer') {
        return true;
      } else {
        return false;
      }
    }
  }

  /**
   * Is Job Editable
   * @returns
   */
  isEdit_JobActions() {
    if (
      this.currentUserInfo?.current_Role == 'Owner' ||
      this.currentUserInfo?.current_Role == 'Manager' || this.currentUserInfo?.current_Role == 'Engineer' ||
      (this.currentUserInfo?.current_Role == 'Tenant' &&
        this.selectedJobInfo?.responsible == 'Tenant')
    ) {

      if ((this.selectedJobInfo?.status == 'Created')) {
        return false;
      } else {
        return true;
      }

    } else {
      return false;
    }
  }

  /********** Tab Related Codes ***************/

  /**
   * Get all  user Tabs
   */
  getAllTabData() {
    let requestURL = environment.baseURL + this._Config2.allTabsList;
    this._Config.isLoader = true;


    this._baseService.doGET(requestURL).subscribe({
      next: (response: any) => {
        this.allDashboardTabsList = response || [];
        this.allDashboardTabsList.forEach((element: any) => {
          element.isPined = element.is_pin;
          element.isCountView = true;
        });
        this.allDashboardTabsList.sort(function (a: any, b: any) {
          return b.make_as_default - a.make_as_default;
        });

        let activeTab: any = this.allDashboardTabsList.find(
          (obj) => obj.current_active_tab == true
        );

        if (activeTab != undefined) {
          this.selectedActiveTab = activeTab;
          this.selectedActiveTab.activeCls = true;
        } else {
          this.selectedActiveTab = this.allDashboardTabsList[0];
          this.selectedActiveTab.activeCls = true;
        }

        this.listAllDashboardJobs.table_columns =
          this.selectedActiveTab.column || [];
        this.listAllDashboardJobs.current_page = 0;

        // if (this.selectedActiveTab.name === "Assigned To Me") {
        //   this.selectedActiveTab.query = {}

        //   if (this.currentUserInfo?.current_Role == 'Manager') {
        //     this.selectedActiveTab.query.assignees = [this.currentUserInfo.id];
        //   }
        //   else if (this.currentUserInfo?.current_Role == 'Engineer') {
        //     this.selectedActiveTab.query.engineers = [this.currentUserInfo.id];
        //   }
        // }

        this.selectedTabEvent(this.selectedActiveTab, true);

        //Dashboard Live Update Socket Trigger
        // this.connectSocketfor_Dashbaordv2_liveRow();

        this.getAllExistingTabViews();

      },
      error: (error) => {
        this._Config.isLoader = false;

      },
      complete: () => {
        this._Config.isLoader = false;
      }
    });
  }

  /**
   * Set Selected Tab Active
   * @param selectedItem
   */
  selectedTabEvent(selectedItem: any, isFirstTime?: boolean) {
    // this._jobService.listAllDashboardJobs.isShowFilter = false;

    let isFilter = false;
    if (selectedItem.query) {
      isFilter = true;

      this._filterService.filterPayload.stage = selectedItem.query.stage;
      this._filterService.filterPayload.priority = selectedItem.query.priority;
      this._filterService.filterPayload.properties =
        selectedItem.query.properties;
      this._filterService.filterPayload.assignees =
        selectedItem.query.assignees;
      this._filterService.filterPayload.engineers =
        selectedItem.query.engineers;
      this._filterService.filterPayload.tenants = selectedItem.query.tenants;
      this._filterService.filterPayload.categories =
        selectedItem.query.categories;
      this._filterService.filterPayload.actions = selectedItem.query.actions;
      this._filterService.filterPayload.vendors = selectedItem.query.vendors;
      this._filterService.filterPayload.responsible =
        selectedItem.query.responsible;
      this._filterService.filterPayload.source = selectedItem.query.source;
      this._filterService.filterPayload.owners = selectedItem.query.owners;
      this._filterService.filterPayload.service_type =
        selectedItem.query.service_type;
      this._filterService.filterPayload.scheduled_dates =
        selectedItem.query.scheduled_dates;
      this._filterService.filterPayload.new_dates =
        selectedItem.query.new_dates;
      this._filterService.filterPayload.job_costs =
        selectedItem.query.job_costs;
      this._filterService.filterPayload.created_by =
        selectedItem.query.created_by;

      this.listAllDashboardJobs.is_sort = selectedItem.query.column
        ? true
        : false;
      this.listAllDashboardJobs.sort_key = selectedItem.query.column
        ? selectedItem.query.column
        : '';
      this.listAllDashboardJobs.sort_by = selectedItem.query.order
        ? selectedItem.query.order
        : '';
      this.listAllDashboardJobs.sort_clicked = {
        count: 0,
        column_key: ''
      };

      this.listAllDashboardJobs.itemsPerPage = selectedItem.query.per_page
        ? selectedItem.query.per_page
        : 25;
    }

    // this._filterService.resetAppliedFilters(selectedItem);

    this.setDashboardTab.next(selectedItem);

    if (!selectedItem.is_pin || !selectedItem.column) {
      this.getAllJobListsByTab(true);
    } else {
      this.getAllJobListsByTab(isFilter);

      //Dashboard Live Update Socket Trigger
      this.connectSocketfor_Dashbaordv2_liveRow();
    }


    if (this.selectedActiveTab?.query || isFirstTime)
      this._filterService.loadAllFilterAPIs(this.selectedActiveTab, true);
    else
      this._filterService.loadAllFilterAPIs(this.selectedActiveTab, false);


  }

  /********** Tab *************/

  /**
   * Get all existing views list
   */
  getAllExistingTabViews(searchKey?: any) {
    let requestURL = environment.baseURL + this._Config2.getDropdownList;

    if (searchKey)
      requestURL = requestURL + '?custom-search=' + searchKey;

    this._baseService.doGET(requestURL).subscribe({
      next: (response: any) => {
        //

        this.allExistingTabsList = response || {};

        //Find values that are in result1 but not in result2
        let self = this;
        if (this.allExistingTabsList.standard) {
          const uniqueResultOne = this.allExistingTabsList.standard.filter(
            function (obj: { id: any }) {
              return !self.allDashboardTabsList.some(function (obj2: {
                id: any;
              }) {
                return obj.id == obj2.id;
              });
            }
          );

          this.allExistingTabsList.standard = uniqueResultOne;
        }

        if (this.allExistingTabsList.others) {
          const uniqueResultOne_others = this.allExistingTabsList.others.filter(
            function (obj: { id: any }) {
              return !self.allDashboardTabsList.some(function (obj2: {
                id: any;
              }) {
                return obj.id == obj2.id;
              });
            }
          );

          this.allExistingTabsList.others = uniqueResultOne_others;
        }

        if (this.allExistingTabsList.created_by_me) {
          const uniqueResultOne_created_by_me =
            this.allExistingTabsList.created_by_me.filter(function (obj: {
              id: any;
            }) {
              return !self.allDashboardTabsList.some(function (obj2: {
                id: any;
              }) {
                return obj.id == obj2.id;
              });
            });

          this.allExistingTabsList.created_by_me =
            uniqueResultOne_created_by_me;
        }
      },
      error: (error) => { },
      complete: () => {
        // this._Config.isLoader = false;
      }
    });
  }

  /**
   * Get Selected Tab Info
   */
  getSelectedTab_info(viewID?: any) {
    this.isLoader = true;

    let requestURL = environment.baseURL + this._Config2.getViewInfo;
    requestURL = requestURL.replace('{ID}', viewID);

    this._baseService.doGET(requestURL).subscribe({
      next: (response: any) => {
        localStorage.setItem('currentTab', viewID);

        response.name = response.view_name;

        /********** Assign to me Highlight current users *************/
        // if (response.name === "Assigned To Me") {
        //   response.query = response.query ? response.query : {}

        //   if (this.currentUserInfo?.current_Role == 'Manager') {
        //     response.query.assignees = [this.currentUserInfo.id];
        //   }
        //   else if (this.currentUserInfo?.current_Role == 'Engineer') {
        //     response.query.engineers = [this.currentUserInfo.id];
        //   }
        // }

        this.updateCurrentActiveTab(viewID);

        let exist_Index = this.allDashboardTabsList.findIndex(
          (obj) => obj.id == response.id && obj.is_pin == true
        );

        if (exist_Index != -1) {
          if (this.allDashboardTabsList[exist_Index].activeCls) {
            response.activeCls = true;
            this.selectedTabEvent(response);
          }
        } else {
          response.count = response.count;
          response.column = response.column;
          response.isCountView = true;

          let lastUnpined_Index = this.allDashboardTabsList.findIndex(
            (obj) => obj.isPined == false || obj.is_pin == false
          );

          if (lastUnpined_Index == -1) {
            let exist_Index = this.allDashboardTabsList.findIndex(
              (obj) => obj.id == response.id && obj.is_pin == true
            );

            if (exist_Index != -1) {
              if (this.allDashboardTabsList[exist_Index].activeCls) {
                response.activeCls = true;
                this.selectedTabEvent(response);
              }
            } else {
              this.allDashboardTabsList.push(response);
            }
          } else {
            if (this.allDashboardTabsList[lastUnpined_Index].activeCls) {
              response.activeCls = true;
              this.selectedTabEvent(response);
            }

            this.allDashboardTabsList[lastUnpined_Index] = response;
          }
        }
      },
      error: (error) => { },
      complete: () => {
        // this._Config.isLoader = false;
      }
    });
  }

  /**
   * Update Current active tab to Database
   * @param viewID
   */
  updateCurrentActiveTab(viewID: string) {
    let requestURL = environment.baseURL + this._Config2.getViewInfo;
    requestURL = requestURL.replace('{ID}', viewID);

    let postBody = { current_active_tab: true };

    this._baseService.doPatch(requestURL, postBody).subscribe({
      next: (response: any) => { },
      error: (error) => { },
      complete: () => { }
    });
  }



  /**
 * Update Current active tab to Database
 * @param viewID
 */
  linkPhotosToJob(jobID: string, entityType: string, photosID: any, path = null, forceCreateFolders = false) {

    if (photosID.length > 0) {
      let requestURL = `${environment.files.http}files/temp/entity/connect`;

      let postBody = {
        "entityId": jobID.toString(),
        "entityType": entityType,
        "title": "files (" + jobID + ")",
        "tempFileIds": photosID,
        "userId": this.currentUserInfo.id,
        "userType": "USER",
        "forceCreateFolders": forceCreateFolders
      };

      if (path) {
        postBody["path"] = path
      }

      this._baseService.doPOST(requestURL, postBody, { headers: { "x-tenant-id": this.subDomain } }).subscribe({
        next: (response: any) => { },
        error: (error) => { },
        complete: () => { }
      });
    }
  }

  /**
   * Link Draft Job to Active Job
   * @param viewID
   */
  linkDraftJobToJobFiles(jobID: string, entityType: string, draftJobID: string, draft_EntityType: string,) {

    if (draftJobID && jobID) {
      let requestURL = `${environment.files.http}files/copy`;

      let postBody = {
        "entityId": draftJobID,
        "entityType": draft_EntityType,
        "toEntityId": jobID.toString(),
        "toEntityType": entityType
      }

      this._baseService.doPOST(requestURL, postBody, { headers: { "x-tenant-id": this.subDomain } }).subscribe({
        next: (response: any) => { },
        error: (error) => { },
        complete: () => { }
      });
    }
  }

  /**
   * Get selected Tab Columns
   */
  getAllColumnsBy_selectedTab(setColumnsToTab?: boolean) {
    let requestURL = environment.baseURL + this._Config2.getViewColumns;
    requestURL = requestURL.replace('{ID}', this.selectedActiveTab.id);

    this._baseService.doGET(requestURL).subscribe({
      next: (response: any) => {
        let ListItems: any[] = response || [];

        ListItems.forEach((element) => {
          element.name = element.column_name;
        });

        if (setColumnsToTab) {
          let active_Columns = ListItems.filter((obj) => obj.order !== 0);

          this.selectedActiveTab.column = active_Columns;
          this.listAllDashboardJobs.table_columns = active_Columns;
          this.setDashboardTab.next(this.selectedActiveTab);

          this.getAllJobListsByTab(true);
        }

        this.listAllColumns = ListItems;
        const jobIdColumn = this.listAllColumns.find((item: any) => {
          return item.column_name === 'Job ID';
        });
        this.listAllColumns = this.listAllColumns.filter((item: any) => {
          return item.column_name !== 'Job ID';
        });
        this.listAllColumns.unshift(jobIdColumn);

        this.selectedColumnItems = this.listAllColumns.filter((obj: any) => {
          return obj.is_select == true;
        });
        this.listAllColumns.sort((a, b) =>
          a.column_name.localeCompare(b.column_name)
        );
        const jobIdIdx = this.listAllColumns.findIndex(
          (l: any) => l.column_name === 'Job ID'
        );
        const jobIdObj = this.listAllColumns.splice(jobIdIdx, 1);
        this.listAllColumns.unshift(jobIdObj[0]);
      },
      error: (error) => { },
      complete: () => {
        this._Config.isLoader = false;
      }
    });
  }

  onColumnSearch(e: any) {
    let searchText = e.target.value;
    let requestURL = environment.baseURL + this._Config2.getViewColumns;
    requestURL = requestURL.replace('{ID}', this.selectedActiveTab.id);
    this._baseService
      .doGET(requestURL + '?custom-search=' + searchText)
      .subscribe({
        next: (response: any) => {
          this.listAllColumns = response;
        },
        error: (error) => { },
        complete: () => {
          this._Config.isLoader = false;
        }
      });
  }
  /**
   * Reset Selected Tab Columns
   * @param isReset
   */
  restAllColumnsBy_selectedTab(isReset?: boolean) {
    let requestURL = '';
    let postBody: any = {};

    if (isReset) {
      postBody.reset = isReset;
      requestURL = environment.baseURL + this._Config2.resetView;
      requestURL = requestURL.replace('{ID}', this.selectedActiveTab.id);

      this._baseService.doPatch(requestURL, postBody).subscribe({
        next: (response: any) => {
          this.getSelectedTab_info(this.selectedActiveTab.id);

          this.showBulkActionHeader = false;

          this._filterService.filterPayload.stage = [];
          this._filterService.filterPayload.priority = [];
          this._filterService.filterPayload.properties = [];
          this._filterService.filterPayload.assignees = [];
          this._filterService.filterPayload.engineers = [];
          this._filterService.filterPayload.tenants = [];
          this._filterService.filterPayload.categories = [];
          this._filterService.filterPayload.actions = [];
          this._filterService.filterPayload.vendors = [];
          this._filterService.filterPayload.responsible = [];
          this._filterService.filterPayload.source = [];
          this._filterService.filterPayload.owners = [];
          this._filterService.filterPayload.service_type = [];
          this._filterService.filterPayload.created_by = [];
          this._filterService.jobCostAmount.fromAmount = '';
          this._filterService.jobCostAmount.toAmount = '';
          this._filterService.filterPayload.scheduled_dates = {
            timeline: '',
            custom: []
          };
          (this._filterService.filterPayload.job_costs = {
            type: [],
            amount: [],
            date_approved: {
              timeline: '',
              custom: []
            }
          }),
            (this._filterService.filterPayload.new_dates = {
              created: {
                timeline: '',
                custom: []
              },
              target_completion: {
                timeline: '',
                custom: []
              },
              last_activity: {
                timeline: '',
                custom: []
              }
            });
        },
        error: (error) => { },
        complete: () => {
          this._Config.isLoader = false;
        }
      });
    } else {
      postBody.is_default = true;

      requestURL = environment.baseURL + this._Config2.resetJobTableColumn;
      requestURL = requestURL.replace('{ID}', this.selectedActiveTab.id);

      this._baseService.doPOST(requestURL, postBody).subscribe({
        next: (response: any) => {
          this.getSelectedTab_info(this.selectedActiveTab.id);

          this.showBulkActionHeader = false;
        },
        error: (error) => { },
        complete: () => {
          this._Config.isLoader = false;
        }
      });
    }
  }

  /**
   * Get All Job Stages list
   */
  getAllStagesList() {
    let requestURL = environment.baseURL + this._Config.getAllStagesToJob;
    this._baseService.doGET(requestURL).subscribe(
      (response: any) => {
        this.allJobStagesList = [];

        if (Array.isArray(response)) this.allJobStagesList = response || [];
      },
      (error) => {
        // this._Config.isLoader = false;
      }
    );
  }

  /**
   * Set Dynamic URL by applied Additional options
   * @param requestURL
   * @param isFilter
   * @returns
   */
  setJoblistingURL(requestURL: any, isFilter?: boolean, isNoPagination?: boolean) {
    /***** is Filter Applied *****/
    if (isFilter) {
      if (requestURL.includes('?')) {
        requestURL +=
          '&query=' + JSON.stringify(this._filterService.filterPayload);
      } else {
        requestURL +=
          '?query=' + JSON.stringify(this._filterService.filterPayload);
      }
    }


    if (!isNoPagination) {
      /***** is Pagination Applied *****/
      if (this.listAllDashboardJobs.is_pagination) {
        if (requestURL.includes('?')) {
          requestURL += '&page=' + this.listAllDashboardJobs.current_page;
        } else {
          requestURL += '?page=' + this.listAllDashboardJobs.current_page;
        }
      }

    }


    /***** is Sorting Applied *****/
    if (this.listAllDashboardJobs.is_sort) {

      this.listAllDashboardJobs.sort_by = (this.listAllDashboardJobs.sort_by == undefined) ? 'asc' : this.listAllDashboardJobs.sort_by;

      if (requestURL.includes('?')) {
        requestURL +=
          '&column=' +
          this.listAllDashboardJobs.sort_key +
          '&order=' +
          this.listAllDashboardJobs.sort_by;
      } else {
        requestURL +=
          '?column=' +
          this.listAllDashboardJobs.sort_key +
          '&order=' +
          this.listAllDashboardJobs.sort_by;
      }
    }

    /***** is Search Applied *****/
    if (this.listAllDashboardJobs.is_search) {
      if (requestURL.includes('?')) {
        requestURL +=
          '&custom-search=' + this.listAllDashboardJobs.search_keyword;
      } else {
        requestURL +=
          '?custom-search=' + this.listAllDashboardJobs.search_keyword;
      }
    }

    /***** is pagi limit Applied *****/
    if (!requestURL.includes('per_page')) {
      if (requestURL.includes('?')) {
        requestURL += '&per_page=' + this.listAllDashboardJobs.itemsPerPage;
      } else {
        requestURL += '?per_page=' + this.listAllDashboardJobs.itemsPerPage;
      }
    }

    return requestURL;
  }

  /**
   * Get all Jobs by selected Tab
   */
  getAllJobListsByTab(isFilter?: any, isNoPagination?: boolean) {
    let requestURL = environment.baseURL + this._Config2.allJobListing;
    requestURL = requestURL.replace('{ID}', this.selectedActiveTab.id);

    //Set Dynamic URL by applied Additional options
    requestURL = this.setJoblistingURL(requestURL, isFilter, isNoPagination);

    this.isLoader = true;
    this.listAllDashboardJobs.list_data = [];

    this._baseService.doGET(requestURL).subscribe({
      next: (response: any) => {
        this.listAllDashboardJobs.is_Enter_Search = false;

        this.listAllDashboardJobs.total_counts = response.count;
        this.listAllDashboardJobs.list_data = response.results || [];
        this.showBulkActionHeader = false;

        if (this.listAllDashboardJobs.total_counts > 0) {
          this.listAllDashboardJobs.columns_order = Object.keys(
            response.results[0]
          );

          this.listAllDashboardJobs.list_data.forEach((element) => {
            element.status = element.stage;
          });
        }
      },
      error: (error) => {
        this.isLoader = false;
      },
      complete: () => {
        this.isLoader = false;
      }
    });
  }

  /**
   * Do Dashboard Table Bulk Actions
   * @param payloadData
   */
  bulkActionJobs(payloadData: any): void {
    this._Config.isLoader = true;
    let requestURL = environment.baseURL + this._Config2.bulkAction;

    this._baseService.doPatch(requestURL, payloadData).subscribe({
      next: (response: any) => {

        this.resetBulkActionCheckbox();

        this.showBulkActionHeader = false;
        this.assignUserModal.hideSelectAll = false;
        this.assignUserModal.searchVal = '';
        this.assignUserModal.allUserListCheckbox = false;
        this.showAlert.isSuccess = true;

        if (this.jobBulkAction.selectedModal == 'markComplete') {
          this.showAlert.successMessage = 'Jobs Marked Completed Successfully';
          this._utilService.showSuccess('', 'Job(s) Marked Completed');
        } else if (this.jobBulkAction.selectedModal == 'closeJobs') {
          this.showAlert.successMessage = 'Jobs Closed Successfully';

          if (
            response[0] &&
            (response[0]?.no_pms || response[0]?.no_timesheets)
          ) {
            this.noPmErrorMessage = response[0]?.no_pms;
            this.noTimeSheetErrorMessage = response[0]?.no_timesheets;
            if (this.noPmErrorMessage) {
              this.toastr.warning('', this.noPmErrorMessage, {
                timeOut: 10000
              });
            }
            if (this.noTimeSheetErrorMessage) {
              this.toastr.warning('', this.noTimeSheetErrorMessage, {
                timeOut: 10000
              });
            }
          } else {
            this._utilService.showSuccess('', 'Job(s) Closed Successfully');
          }
        } else if (this.jobBulkAction.selectedModal == 'Assign Manager') {
          this.showAlert.successMessage = 'Manager added successfully';
          this._utilService.showSuccess('', this.showAlert.successMessage);
        } else if (this.jobBulkAction.selectedModal == 'Assign Engineer') {
          this.showAlert.successMessage =
            payloadData.users_list.length + ' Engineer(s) added successfully';
          this._utilService.showSuccess('', this.showAlert.successMessage);
        } else if (this.jobBulkAction.selectedModal == 'Assign Follower') {
          this.showAlert.successMessage =
            payloadData.users_list.length + ' Follower(s) added successfully';
          this._utilService.showSuccess('', this.showAlert.successMessage);
        }
        this.getAllJobListsByTab(true);
        this.jobBulkAction.selectedModal = '';
      },
      error: (error) => {
        this._Config.isLoader = false;
        this.showAlert.isError = true;
        this.showAlert.errorMessage = 'Something went wrong';

        this._utilService.showErrorMessage(error);
      },
      complete: () => {
        this._Config.isLoader = false;
      }
    });
  }

  /**
   * Reset All Selected Checkboxs
   */
  resetBulkActionCheckbox(): void {
    this.jobBulkAction.allCheckbox = false;
    this.listAllDashboardJobs?.list_data?.map((item: any) => {
      item.isChecked = false;
    });
    this.showBulkActionHeader = false;
  }

  /**
   * Save Selected View With Custom Changes + Filters
   * @param event
   */
  onSaveView(event: any) {

    let postBody: any = {
      query: {
        assignees: this._filterService.filterPayload.assignees,
        engineers: this._filterService.filterPayload.engineers,
        stage: this._filterService.filterPayload.stage,
        priority: this._filterService.filterPayload.priority,
        properties: this._filterService.filterPayload.properties,
        tenants: this._filterService.filterPayload.tenants,
        categories: this._filterService.filterPayload.categories,
        actions: this._filterService.filterPayload.actions,
        vendors: this._filterService.filterPayload.vendors,
        responsible: this._filterService.filterPayload.responsible,
        source: this._filterService.filterPayload.source,
        owners: this._filterService.filterPayload.owners,
        service_type: this._filterService.filterPayload.service_type,
        created_by: this._filterService.filterPayload.created_by,
        scheduled_dates: {
          timeline: this._filterService.filterPayload.scheduled_dates.timeline,
          custom: this._filterService.filterPayload.scheduled_dates.custom
        },
        job_costs: {
          type: this._filterService.filterPayload.job_costs.type,
          amount: this._filterService.filterPayload.job_costs.amount,
          date_approved: {
            timeline:
              this._filterService.filterPayload.job_costs.date_approved
                .timeline,
            custom:
              this._filterService.filterPayload.job_costs.date_approved.custom
          }
        },
        new_dates: {
          created: {
            timeline:
              this._filterService.filterPayload.new_dates.created.timeline,
            custom: this._filterService.filterPayload.new_dates.created.custom
          },
          target_completion: {
            timeline:
              this._filterService.filterPayload.new_dates.target_completion
                .timeline,
            custom:
              this._filterService.filterPayload.new_dates.target_completion
                .custom
          },
          last_activity: {
            timeline:
              this._filterService.filterPayload.new_dates.last_activity
                .timeline,
            custom:
              this._filterService.filterPayload.new_dates.last_activity.custom
          }
        },
        column: '',
        order: '',
        per_page: this.listAllDashboardJobs.itemsPerPage
      }
    };

    if (this.listAllDashboardJobs.is_sort) {
      postBody.query.column = this.listAllDashboardJobs.sort_key;
      postBody.query.order = this.listAllDashboardJobs.sort_by;
    }

    this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config2.saveCurrentTabView;
    requestURL = requestURL.replace('{View_ID}', this.selectedActiveTab.id);

    this._baseService.doPatch(requestURL, postBody).subscribe({
      next: (response: any) => {

        response.name = response.view_name;
        response.isPined = response.is_pin;

        let exist_Index = this.allDashboardTabsList.findIndex(
          (obj) => obj.id == response.id && obj.is_pin == true
        );

        if (exist_Index != -1) {
          if (this.allDashboardTabsList[exist_Index].activeCls) {
            response.activeCls = true;
            this.selectedTabEvent(response);
          }

          this.allDashboardTabsList[exist_Index] = response;
        } else {
          this.selectedTabEvent(response);
        }

        this._utilService.showSuccess(
          this.selectedActiveTab.name,
          'View Saved Successfully'
        );
      },
      error: (error) => {
        this._Config.isLoader = false;
      },
      complete: () => {
        this._Config.isLoader = false;
      }
    });
  }

  /**
* Update Columns From Dashboard
*/
  doUpdateViewColumns(payloadData: any) {
    this._Config.isLoader = true;
    let requestURL = environment.baseURL + this._Config2.update_resize_column;
    requestURL = requestURL.replace('{ViewID}', this.selectedActiveTab.id);
    this._baseService.doPOST(requestURL, payloadData).subscribe((result: any) => {
      this._Config.isLoader = false;
      this._utilService.showSuccess('', 'Column Modified Successfully.');
    },
      (error) => {
        this._Config.isLoader = false;
        // this._utilService.showErrorMessage(error);
      }
    );
  }

  /**
   * Reset Dashboard View on Double Click
   */
  resettingDashboard(): void {
    this.listAllDashboardJobs.pagination = false;
    this.listAllDashboardJobs.is_search = false;
    this.listAllDashboardJobs.is_sort = false;
    this.listAllDashboardJobs.sort_key = '';
    this.listAllDashboardJobs.sort_by = '';
    this.listAllDashboardJobs.sort_clicked = {
      count: 0,
      column_key: ''
    };
    this.listAllDashboardJobs.current_page = 1;
    this.listAllDashboardJobs.search_keyword = '';
  }

  /**
   * On Change Event for Status and Priority
   * @param type
   * @returns
   */

  openEmailConversation(): void {
    if (this._Config.isNewInbox) {
      this.selectedJobInfo.job_AllJobEmails.map((email) => {
        if (email.mailbox_is_active && (email.mail_status == 'Closeout' || email.mail_status == 'Open') && email.mail_type != 'Trash' && email.mail_type != 'Spam') {
          this._inboxService.openEmailFromJobModal = true;
          this._inboxService.selectedMailDetail = email;
          if (email.mail_status == 'Resolved') {
            this._inboxService.activeInboxMenu = 'closed';
          } else if (email.mail_status == 'Closeout') {
            this._inboxService.activeInboxMenu = 'closeout';
          } else {
            this._inboxService.activeInboxMenu = 'opened';
          }
          localStorage.setItem(
            'closeConversation',
            JSON.stringify(email.mail_status)
          );
          localStorage.setItem('selectedMail', JSON.stringify(email.mail_id));
          localStorage.setItem(
            'isOpenMailFromJob',
            JSON.stringify(this._inboxService.openEmailFromJobModal)
          );
          window.open(
            '/pm/inbox/' +
            email.mail_id +
            '/' +
            email.mail_status +
            `/${email.mailbox_id}`,
            '_blank'
          );
        }
      });
      if (this.myMailJobSubscription) {
        this.myMailJobSubscription.unsubscribe();
      }
    }
  }

  onDashboardJobUpdate(selectedRowItem: any) {
    let postBody: any = {};
    let Msg = '';

    if (selectedRowItem.key == 'stage') {
      postBody = {
        job_id: selectedRowItem.selectedRowObj.id,
        stage: selectedRowItem.selectedItem.id
      };

      Msg = 'Status Updated Successfully.';
    }

    this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.updateDashboardJobStage;
    requestURL = requestURL.replace('{ViewID}', this.selectedActiveTab.id);

    this._baseService.doPatch(requestURL, postBody).subscribe(
      (result: any) => {
        this._Config.isLoader = false;

        this._utilService.showSuccess('', Msg);

        let indexVal = this.listAllDashboardJobs.list_data.findIndex(
          (obj) => obj.id == selectedRowItem.selectedRowObj.id
        );

        if (indexVal != -1) {
          this.listAllDashboardJobs.list_data[indexVal] = result;

          if (result.stage === 'Closed') {
            this.listAllDashboardJobs.list_data.splice(indexVal, 1);
          }
        }
        if (this.baseService.isPermissionExist('add_mail')) {
          if (selectedRowItem && selectedRowItem.selectedItem.name === 'Closed') {
            this.selectedJobInfo.id = selectedRowItem.selectedRowObj.id;
            this._ticketService.getAllJobEmails();
            if (this.associatedEmailsInJobCount > 0) {
              this.jobsStatus = true;
              this.openAssociatedMailPopup();
            }
            this.myMailJobSubscription = this.isJobMailLoaded.subscribe((isJobMailLoaded: any) => { });
          }
        }
      },
      (error) => {
        this._Config.isLoader = false;
        this._utilService.showErrorMessage(error);

        if (selectedRowItem.key == 'stage' || selectedRowItem.key == 'Priority') {
          let indexVal = this.listAllDashboardJobs.list_data.findIndex(
            (obj) => obj.id == selectedRowItem.selectedRowObj.id
          );

          if (indexVal != -1) {

            if (selectedRowItem.key == 'stage') {
              this.listAllDashboardJobs.list_data[indexVal].stage =
                selectedRowItem.selectedRowObj.status;
            }
            else if (selectedRowItem.key == 'Priority') {
              this.listAllDashboardJobs.list_data[indexVal].status =
                selectedRowItem.selectedRowObj.status;
            }

          }
        }

      }
    );
  }

  /**
   * Update Job From Dashboard
   */
  doUpdateJob(selectedRowItem: any) {
    let postBody: any = {};
    let Msg = '';

    if (selectedRowItem.key == 'stage') {
      postBody.stage = selectedRowItem.selectedItem.id;
      Msg = 'Status Updated Successfully.';
    }

    this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.updateTicket;
    requestURL = requestURL.replace('{ID}', selectedRowItem.jobID);

    this._baseService.doPatch(requestURL, postBody).subscribe(
      (result: any) => {
        this._Config.isLoader = false;

        this._utilService.showSuccess('', Msg);

      },
      (error) => {
        this._Config.isLoader = false;
        this._utilService.showErrorMessage(error);
      }
    );
  }

  /**
   * Recent Job in View Job
   */

  getAllRecentJob(selectedJobInfo?: any, isPagination?: boolean) {
    if (!selectedJobInfo) selectedJobInfo = this.selectedJobInfo;

    let postBody: any = {
      properties: [selectedJobInfo.property.id],
      tenants: this.filterPayload.tenants,
      categories: this.filterPayload.categories,
      unit: this.filterPayload.locations
    };
    let requestURL = environment.baseURL + this._Config2.getAllRecentFilter;
    requestURL = requestURL.replace('{query}', JSON.stringify(postBody));

    if (this.filteredItems.searchKey) {
      requestURL = requestURL + '&search=' + this.filteredItems.searchKey;
    }

    if (isPagination) {
      if (requestURL.includes('?')) {
        requestURL += '&page=' + this.listAllRecentJobs.current_page;
      } else {
        requestURL += '?page=' + this.listAllRecentJobs.current_page;
      }
    }

    if (this.listAllRecentJobs.is_sort) {
      if (requestURL.includes('?')) {
        requestURL +=
          '&column=' +
          this.listAllRecentJobs.sort_key +
          '&order=' +
          this.listAllRecentJobs.sort_by;
      } else {
        requestURL +=
          '?column=' +
          this.listAllRecentJobs.sort_key +
          '&order=' +
          this.listAllRecentJobs.sort_by;
      }
    }

    requestURL = requestURL.replace('{JobID}', selectedJobInfo.id);
    this._Config.isLoader = true;


    this._baseService.doGET(requestURL).subscribe(
      (response: any) => {
        this._Config.isLoader = false;

        this.listAllRecentJobs.total_counts = response.count;
        this.listAllRecentJobs.list_data = response.results || [];

      },
      (error) => {
        this._Config.isLoader = false;
        this.propertyLoading = false;
      },
      () => {
        this.propertyLoading = false;
        this._Config.isLoader = false;
      }
    );
  }

  /**
   * Recent Job Pagnination Event
   * @param event
   */
  paginationPageChanged(event: any): void {
    this.listAllRecentJobs.is_pagination = true;
    this.listAllRecentJobs.current_page = event.page;
    this.getAllRecentJob(this.selectedJobInfo, true);
  }

  /**
* Get AllJob Feedback
*/

  getAllFeedback(JobID: any) {
    let requestURL = environment.baseURL + this._Config2.getAllJobsFeedback;
    requestURL = requestURL.replace('{ID}', JobID);
    this._baseService.doGET(requestURL).subscribe({
      next: (response: any) => {
        this.listAllJobsFeedback.total_counts = response.count;
        this.listAllJobsFeedback.list_data = response.results;
        this.listAllJobsFeedback.list_data.map((e: any) => e.feedback = JSON.parse(e.feedback));
      },
      error: (error) => { },
      complete: () => {
        this._Config.isLoader = false;
      }
    });

  }

  /**
  * feedback Job Pagination Event
  * @param event
  */
  feedbackPaginationPageChanged(event: any): void {
    this.listAllJobsFeedback.is_pagination = true;
    this.listAllJobsFeedback.current_page = event.page;
    this.getAllFeedback(event);
  }

  /**
   * Set Preview File from Upload/Uploaded Files
   * @param selectedFiles
   */
  selectedFilePreivew(selectedFiles: any[], isFromEXV?: boolean) {
    this.isFromEXV = isFromEXV;
    this.selctedFilesToPreview = [];
    if (selectedFiles.length > 0) {
      this.selctedFilesToPreview = selectedFiles;
      this.selectedPreview_File = this.selctedFilesToPreview[0];
    }
  }

  /**
   * Get All Job Activities
   */
  getAllJobActivities(isPagination?: any, isFilter?: any) {
    let requestURL = environment.baseURL + this._Config.getJobActivities;

    if (isPagination) {
      if (this.allJobActivityList.pagination.next) {
        requestURL = this.allJobActivityList.pagination.next;
      } else {
        requestURL = environment.baseURL + this._Config.getJobActivities;
      }
    } else {
      if (isFilter) {
        requestURL =
          environment.baseURL +
          this._Config.getJobActivities +
          '?query=' +
          JSON.stringify(isFilter);
      } else {
        requestURL = environment.baseURL + this._Config.getJobActivities;
      }
    }

    requestURL = requestURL.replace('{ID}', this.selectedJobInfo.id);

    this._baseService.doGET(requestURL).subscribe(
      (result: any) => {
        let ListItems = result.results || [];
        this.allJobActivityList.pagination.next = result.next
          ? result.next
          : '';
        this.allJobActivityList.pagination.count = result.count
          ? result.count
          : 0;
        if (isPagination) {
          this.allJobActivityList.data = [
            ...this.allJobActivityList.data,
            ...ListItems
          ];
        } else {
          this.allJobActivityList.data = ListItems || [];
        }
      },
      (error) => {
        // this._utilService.showErrorMessage(error);
      }
    );
  }

  /********************** New Job Info Page Related API's *****************************/
  /**
   * Get Selected Job Info By Job ID
   * @param jobId
   */
  getPublicSelectedJobInfo(jobId: string, contactId: string) {
    this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config2.getSelectedJobDetails;
    requestURL = requestURL.replace('{ID}', jobId);
    const options = {
      params: {
        contact_id: contactId,
      },
      headers: {
        'x-secret-key': environment.externalVendor.xSecretKey,
      },
    }

    this._baseService.doGET(requestURL, options).subscribe({
      next: (response: any) => {
        this.selectedJobInfo = response || {};
        this.selectedJobInfo.error = null;

        this.selectedJobInfo.showJobInfo = true;

        this.selectedJobInfo?.engineers.map(elm => { elm.name = elm.full_name });

        this.selectedJobInfo.temp_engineers = response.engineers || [];

        this.isJobLoaded.next(response);
        this.isJobTabClicked.next(true);
      },
      error: (error) => {
        this._Config.isLoader = false;
        this.selectedJobInfo.isLoaded = true;
        this.selectedJobInfo.error = error;
        this.selectedJobInfo.status_code = error.status;
        this.selectedJobInfo.isJobError = true;

      },
      complete: () => {
        this._Config.isLoader = false;
        this.selectedJobInfo.isLoaded = true;
        this.isJobInfo_Preview = true;
        if (this.inboxLayoutService.associatedJobCardClick) {
          this.inboxLayoutService.openWordOrderState.next(true);
          this.inboxLayoutService.associatedJobCardClick = false;
        }
      }
    });
  }
  getSelectedJobInfo(jobId: any, isFromMail?: boolean, actionTab?: string, isJobOpen_New?: any) {
    this._Config.isLoader = true;

    let isAlreadyOppened = false;
    if (this.selectedJobInfo.id == jobId) {
      isAlreadyOppened = true;
    }

    let requestURL = environment.baseURL + this._Config2.getSelectedJobDetails;
    requestURL = requestURL.replace('{ID}', jobId);

    if (isFromMail) {
      requestURL = requestURL + '?source=inbox';
    }

    if (this.isSoftAuth && !!localStorage.getItem('soft-auth-id')) {
      if (requestURL.includes('?')) {
        requestURL = requestURL + '&contact_id=' + localStorage.getItem('soft-auth-id');
      }
      else {
        requestURL = requestURL + '?contact_id=' + localStorage.getItem('soft-auth-id');
      }
    }

    this._baseService.doGET(requestURL)
      .pipe(
        switchMap(job => {
          return this.getJobCompanyBillableParty(job)
        })
      )
      .subscribe({
      next: (response: any) => {
        this.selectedJobInfo = response || {};
        this._customTabsService.selectedJobDetails = this.selectedJobInfo;
        this.selectedJobInfo.error = null;

        this.selectedJobInfo.showJobInfo = true;
        this.selectedJobInfo.isJob_Preview = true;

        this.selectedJobInfo?.engineers.map(elm => { elm.name = elm.full_name });

        this.selectedJobInfo.temp_engineers = response.engineers || [];

        this.isJobLoaded.next(response);
        this.isJobTabClicked.next(true);


        if (isFromMail) {
          this.selectedJobInfo.is_From_Mail = isFromMail;
          this.getAllJobEmails(isFromMail);
        } else {
          this.getAllJobEmails();
        }

        setTimeout(() => {
          if (actionTab) this.isJobActionDropdown.next(actionTab);
          else this.isJobActionDropdown.next('');
        }, 500);
      },
      error: (error) => {
        this._Config.isLoader = false;
        this.selectedJobInfo.isLoaded = true;
        this.selectedJobInfo.error = error;
        this.selectedJobInfo.status_code = error.status;
        this.selectedJobInfo.isJobError = true;

        if (error.status == 404) {
          this._utilService.showError("Job not found!", `Selected job may have been removed or your access is restricted. Please contact your administrator.`);
          this.showJobInfo.next(false);
          this.selectedTenantJobInfo = {};
          return;
        }

        if(this.selectedJobInfo.isJobError){
          this._baseService.redirectTo('/server-error');
        }

      },
      complete: () => {
        this._Config.isLoader = false;
        this.selectedJobInfo.isLoaded = true;
        this.isJobInfo_Preview = true;
        if (this.inboxLayoutService.associatedJobCardClick) {
          this.inboxLayoutService.openWordOrderState.next(true);
          this.inboxLayoutService.associatedJobCardClick = false;
        }
        if (actionTab === 'Equipment') {
          this.equipmentAssigned.emit(this.selectedJobInfo);
        }


      }
    });
  }

 getJobCompanyBillableParty(job: any): Observable<any> {
    if (job.unit?.lease_id && job.category?.id) {
      return this.leaseService.getLeaseCategoryRules(job.unit?.lease_id, job.category?.id)
        .pipe(
          map((res:any) => {
            let responsible = job.responsible || 'Tenant';
            if (res && res?.config) {
              if (res.config !== 'Both') {
                responsible = res.config === 'Gross' ? 'Manager' : 'Tenant'
              } else {
                responsible = res.config;
              }
            }
            return {
              ...job,
              responsible,
            }
          })
        )
    }

    return of(job);
  }


  /**
  * Get Selected Job Info By Job ID
  * @param jobId
  */
  getSelectedJobInfoFromNotification(jobId: any, isFromMail?: boolean, actionTab?: string, isJobOpen_New?: any) {
    this._Config.isLoader = true;

    let isAlreadyOppened = false;
    if (this.selectedJobInfo.id == jobId) {
      isAlreadyOppened = true;
    }

    let requestURL = environment.baseURL + this._Config2.getSelectedJobDetails;
    requestURL = requestURL.replace('{ID}', jobId);

    if (isFromMail) {
      requestURL = requestURL + '?source=inbox';
    }

    this._baseService.doGET(requestURL).subscribe({
      next: (response: any) => {
        this.selectedJobInfo = response || {};
        this.selectedJobInfo.error = null;

        this.selectedJobInfo.showJobInfo = true;

        this.selectedJobInfo?.engineers.map(elm => { elm.name = elm.full_name });

        this.selectedJobInfo.temp_engineers = response.engineers || [];

        this.isJobLoaded.next(response);
        this.isJobTabClicked.next(true);


        if (isFromMail) {
          this.selectedJobInfo.is_From_Mail = isFromMail;
          this.getAllJobEmails(isFromMail);
        } else {
          this.getAllJobEmails();
        }

        setTimeout(() => {
          if (actionTab) this.isJobActionDropdown.next(actionTab);
          else this.isJobActionDropdown.next('');
        }, 500);
      },
      error: (error) => {
        this._Config.isLoader = false;
        this.selectedJobInfo.isLoaded = true;
        this.selectedJobInfo.error = error;

        this.isJobInfo_Preview = false;
        this.isLeftArrow_Minimise = false;
        this.isRightArrow_Minimise = false;
        this.isJob_HalfPageView = false;
        this.showJobInfo.next(false);

        if (error.status == 404 && Object.keys(error.error).length == 0) {
          this._utilService.showError("404 - Unable to see job info!", "Please contact administrator.");
        }
        else {
          this._utilService.showErrorMessage(error.error);
        }

      },
      complete: () => {
        this._Config.isLoader = false;
        this.selectedJobInfo.isLoaded = true;
        this.isJobInfo_Preview = true;
        if (this.inboxLayoutService.associatedJobCardClick) {
          this.inboxLayoutService.openWordOrderState.next(true);
          this.inboxLayoutService.associatedJobCardClick = false;
        }
        if (actionTab === 'Equipment') {
          this.equipmentAssigned.emit(this.selectedJobInfo);
        }
      }
    });
  }

  /**
  * Get Selected Job Info By Job ID
  * @param jobId
  */
  getSelectedJobInfoin_allJobs(jobId: any) {
    this._Config.isLoader = true;

    let isAlreadyOppened = false;
    if (this.selectedJobInfo.id == jobId) {
      isAlreadyOppened = true;
    }

    let requestURL = environment.baseURL + this._Config2.getSelectedJobDetails;
    requestURL = requestURL.replace('{ID}', jobId);

    this._baseService.doGET(requestURL).subscribe({
      next: (response: any) => {
        this.selectedJobInfo = response || {};
        this.selectedJobInfo.error = null;

        this.selectedJobInfo.showJobInfo = true;

        this.selectedJobInfo.temp_engineers = response.engineers || [];

        this.isJobLoaded.next(response);
        this.isJobTabClicked.next(true);


        this.getAllJobEmails();

        setTimeout(() => {
          this.isJobActionDropdown.next('Notes');
        }, 500);
      },
      error: (error) => {
        this._Config.isLoader = false;
        this.selectedJobInfo.isLoaded = true;
        this.selectedJobInfo.error = error;
      },
      complete: () => {
        this._Config.isLoader = false;
        this.selectedJobInfo.isLoaded = true;
        this.isJobInfo_Preview = true;
        if (this.inboxLayoutService.associatedJobCardClick) {
          this.inboxLayoutService.openWordOrderState.next(true);
          this.inboxLayoutService.associatedJobCardClick = false;
        }

      }
    });
  }

  /**
 * Get Selected Job Info By Job ID
 * @param jobId
 */
  getSelectedJobTabCounts(isChecklistAdded?:boolean) {
    this._Config.isLoader = false;
    let requestURL = environment.baseURL + this._Config2.getSelectedJobDetails;
    requestURL = requestURL.replace('{ID}', this.selectedJobInfo.id);

    this._baseService.doGET(requestURL).subscribe({
      next: (response: any) => {
        this.selectedJobInfo.work_order_count = response.work_order_count;
        this.selectedJobInfo.proposals = response.proposals;
        this.selectedJobInfo.vendors = response.vendors;
        this.selectedJobInfo.stage=response.stage;

        if (isChecklistAdded) this.isJobStatusUpdate.next(isChecklistAdded);

      },
      error: (error) => { },
      complete: () => {
        this._Config.isLoader = false;
      }
    });
  }



  /**
   * Add Source Params if Email
   * @param requestURL
   * @returns
   */
  addSource(requestURL: any) {
    if (this.selectedJobInfo.is_From_Mail) {
      if (requestURL.includes('?')) requestURL = requestURL + '&source=inbox';
      else requestURL = requestURL + '?source=inbox';
    }

    return requestURL;
  }

  /**
   * Reset Full Job View
   */
  resetHalfPageView() {
    this.isJobInfo_Preview = false;
    this.isLeftArrow_Minimise = false;
    this.isRightArrow_Minimise = false;
    this.isJob_HalfPageView = false;
  }

  /**
   * Get selected Job Mails
   */
  getAllJobEmails(isFromMail?: boolean, isSubject?: boolean) {
    if (this.selectedJobInfo.id) {
      let requestURL = environment.baseURL + this._Config2.getAllJobEmails;
      requestURL = requestURL.replace('{ID}', this.selectedJobInfo.id);

      if (isFromMail) {
        if (requestURL.includes('?')) requestURL = requestURL + '&source=inbox';
        else requestURL = requestURL + '?source=inbox';
      }

      this._baseService.doGET(requestURL).subscribe(
        (response: any) => {
          this.associateOpened = true
          this.associateMailBulkActionAccess = response.checkbox;
          this.selectedJobInfo.job_AllJobEmails = response.mails || [];
          this.selectedJobInfo.job_AllEmailJobs = response.jobs || [];

          if (isSubject) {
            this.isJobMailLoaded.next(true);
            if (this.sourceBack !== 'Inbox') {
              this.openEmailConversation();
            } else {
              this.messageService.fetchMailsAndCount(false);
            }
          }
        },
        (error) => { }
      );
    }
  }

  /********************** Socket based REST services *****************************/

  /**
   * Bulk Action Live Updates in Dashboard v2
   * @param event
   */
  doBulkActionLiveUpdates(selected_JobIDs: any) {
    if (Array.isArray(selected_JobIDs)) {
      let postBody: any = {
        job_ids: selected_JobIDs
      };

      let requestURL = environment.baseURL + this._Config2.get_BulkAction_Lists;
      requestURL = requestURL.replace('{View_ID}', this.selectedActiveTab.id);

      this._baseService.doPOST(requestURL, postBody).subscribe({
        next: (response: any) => {

          if (response.length > 0) {
            response.forEach((element: any) => {
              let exist_Index = this.listAllDashboardJobs.list_data.findIndex(
                (obj) => obj.id == element.id
              );

              if (exist_Index != -1) {
                this.listAllDashboardJobs.list_data[exist_Index] = element;
              }
            });
          }
        },
        error: (error) => {
          // this._Config.isLoader = false;
        },
        complete: () => {
          // this._Config.isLoader = false;
        }
      });
    }
  }


  /**
   * Bulk Action Live Updates in Dashboard v2
   * @param event
   */
  doBulkActionLiveUpdates_forClosedJobs(responseObj: any) {

    if (responseObj.closed) {
      if (Array.isArray(responseObj.job_ids)) {
        let dashboardData = this.listAllDashboardJobs.list_data.filter(b => !responseObj.job_ids.includes(b.id));
        this.listAllDashboardJobs.list_data = dashboardData;
      }
    }

  }

  /********************** Socket Code Start for new Dashboard *****************************/

  /**
   * Dashboard live update for New Dashboard
   */
  public connectSocketfor_Dashbaordv2_liveRow() {

    if (this.currentUserInfo.account) {
      if (this.dashboardRow) {
        this.dashboardRow.unsubscribe();
      }

      if (this.selectedActiveTab) {
        let requestURL =
          environment.socketURL + this._Config2.getNewDashboardJobs_Live;
        requestURL = requestURL.replace(
          '{AccountID}',
          this.currentUserInfo.account
        );
        requestURL = requestURL.replace('{UserID}', this.currentUserInfo.id);
        requestURL = requestURL.replace('{View_ID}', this.selectedActiveTab.id);

        this.dashboardRow = webSocket({
          url: requestURL,
          openObserver: {
            next: (e) => { }
          },
          closeObserver: {
            next: (e) => { }
          }
        });

        this.dashboardRow.subscribe(
          (response: any) => {
            // if (response.id) {

            //   if (this.activatedRoute.snapshot['_routerState'].url === '/pm/alljobs') {
            //     let indexVal = this._ticketService.dashboardAllJobsList.allPastJobs.findIndex(
            //       (obj) => obj.id == response.id
            //     );

            //     if (indexVal == -1) {
            //     } else {
            //       // this._ticketService.dashboardAllJobsList.allPastJobs[indexVal] =
            //       //   response;
            //     }
            //   } else {


            //     let indexVal = this.listAllDashboardJobs.list_data.findIndex(
            //       (obj) => obj.id == response.id
            //     );

            //     response.status = response.stage;
            //     if (
            //       response.status == 'Closed' ||
            //       response.status == 'Cancelled' ||
            //       response.stage == 'Closed' ||
            //       response.stage == 'Cancelled'
            //     ) {

            //       if (!response.is_show_in_list) {

            //         if (response.id === this.selectedJobInfo.id) {
            //           this.listAllDashboardJobs.list_data.splice(indexVal, 1);

            //           if (this.isJobInfo_Preview && !this.baseService.currentActiveState.includes('/pm/inbox')) {
            //             this.isJobInfo_Preview = false;
            //             this.selectedJobInfo = {};

            //             this.isJob_HalfPageView = false;
            //             this.isLeftArrow_Minimise = false;
            //             this.isRightArrow_Minimise = false;
            //           }

            //         }
            //       }

            //     } else {
            //       if (response.is_show_in_list) {
            //         if (indexVal == -1) {
            //           if (!this.listAllDashboardJobs.is_search)
            //             this.listAllDashboardJobs.list_data.unshift(response);

            //         } else {
            //           this.listAllDashboardJobs.list_data[indexVal] = response;
            //         }
            //       }
            //       else {

            //         if (indexVal != -1)
            //           this.listAllDashboardJobs.list_data.splice(indexVal, 1);

            //       }


            //     }

            //   }


            // }
          },
          (err) => { },
          () => { }
        );

        if (!this.bulkActionTriggers) {
          this.connectSocketfor_Get_all_bulkAction_IDs();
        }
      }
    }

  }

  /**
   *  Get all Bulk Action IDs
   */
  public connectSocketfor_Get_all_bulkAction_IDs() {

    if (this.currentUserInfo.account) {
      let requestURL =
        environment.socketURL + this._Config2.bulkAction_triggeredIds;
      requestURL = requestURL.replace(
        '{AccountID}',
        this.currentUserInfo.account
      );
      requestURL = requestURL.replace('{UserID}', this.currentUserInfo.id);

      this.bulkActionTriggers = webSocket({
        url: requestURL,
        openObserver: {
          next: (e) => { }
        },
        closeObserver: {
          next: (e) => { }
        }
      });

      this.bulkActionTriggers.subscribe(
        (response: any) => {

          if (response.closed) {
            //Remove from Dashboard jobs
            this.doBulkActionLiveUpdates_forClosedJobs(response);

          }
          else {
            this.doBulkActionLiveUpdates(response);
          }
        },
        (err) => { },
        () => { }
      );
    }

  }

  /**
   * Dashboard Bulk Actions -> Send all Selected jobs to socket trigger
   * @param jobIDs
   */
  public connectSocketfor_bulkAction_Triggers(selected_JobIDs: any, isClosed?: boolean) {

    if (this.currentUserInfo.account) {
      let requestURL = environment.socketURL + this._Config2.bulkAction_triggers;
      requestURL = requestURL.replace(
        '{AccountID}',
        this.currentUserInfo.account
      );
      requestURL = requestURL.replace('{UserID}', this.currentUserInfo.id);

      let payload: any = {
        job_ids: selected_JobIDs
      };

      if (isClosed)
        payload.closed = isClosed ? true : false;


      const subject = webSocket(requestURL);
      subject.subscribe(
        (msg) => { },
        (err) => { },
        () => { }
      );
      subject.next(payload);
    }

  }

  /**
   *   Live Count Updates for Job Info Proposals
   */
  public connectSocketfor_JobInfoProposals(JobID: any) {

    if (JobID) {
      let requestURL =
        environment.socketURL + this._Config2.getAllCounts_jobInfoProposals;
      requestURL = requestURL.replace('{UserID}', this.currentUserInfo.id);
      requestURL = requestURL.replace('{JobID}', JobID);

      this.jobInfoCount = webSocket({
        url: requestURL,
        openObserver: {
          next: (e) => {
            this.socketOpened = true;

          }
        },
        closeObserver: {
          next: (e) => {
            this.socketOpened = false;
          }
        }
      });

      this.jobInfoCount.subscribe(
        (response: any) => {

          this.selectedJobInfo.proposals = response.proposals
            ? response.proposals
            : this.selectedJobInfo.proposals;

          this.selectedJobInfo.approvals = response.approvals
            ? response.approvals
            : this.selectedJobInfo.approvals;

          this.selectedJobInfo.status = response.status
            ? response.status
            : this.selectedJobInfo.status;

          this.selectedJobInfo.work_order_count = response.work_order_count
            ? response.work_order_count
            : this.selectedJobInfo.work_order_count;

          this.selectedJobInfo.assignee = response.assignee
            ? response.assignee
            : this.selectedJobInfo.assignee;
        },
        (err) => { },
        () => { }
      );
    }

  }


  associatedBulkReply(mail_List: any, message?: any) {
    if (message) {
      this.inboxApi.associatedBulkMails(message.mail, message)
    }
    else {
      this.inboxApi.associatedBulkMails(mail_List)
      this.selection.clear();
    }
  }


  isValidEngineerUser() {
    if (this.isEngineerJob.is_engineer) {
      if (this.isEngineerJob.is_job_assigned_logged_in_engineer) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }




  getAllEquipments() {

    if (this.selectedJobInfo?.equipment) {
      let activeEquipments = this.selectedJobInfo?.equipment.filter(obj => obj);
      let requestURL = environment.equipURL + `/jobs/equipment/?query={'id': ${JSON.stringify(activeEquipments)}}&size=100`;

      this._baseService.doGET(requestURL).subscribe({
        next: (result: any) => {
          this.listEquipments_Dropdown = result?.items || [];
        },
        complete: () => {

        }
      })
    }

  }

  fetchRequiredChecklistTemplated(): Observable<boolean> {
    return this.showRequiredChecklistTemplate.asObservable();
  }

  fetchChecklistCountUpdated(): Observable<boolean> {
    return this.checklistCountUpdated.asObservable();
  }

  fetchWorkOrderTabUpdated(): Observable<boolean> {
    return this.workOrderTabUpdate.asObservable();
  }

  onResetJobView() {
    this.isJobInfo_Preview = false;
    this.isJob_HalfPageView = false;
    this.isLeftArrow_Minimise = false;
    this.isRightArrow_Minimise = false;
    this.sourceBack = '';
    this.showJobInfo.next(false);
    this.selectedJobInfo = {};
    this.sidebarService.toggleRightSidebar = false;
  }

  FetchVendorUpdate() {
    return this.vendorUpdate.asObservable();
  }
  clearAllDateFieldFiltersOb() {
    return this.clearAllDateFieldFilters.asObservable();
  }
  updateLinkedJobDetailsOb() {
    return this.updateLinkedJobDetails.asObservable();
  }
  updateWorkOrderJobsTab() {
    return this.updateJobsTabs.asObservable();
  }

  /*************** Realtime Update with Pusher Update*******************/
  getJobStatusEvent() {
    return this.realtimeEvents
      .getEvents('JOB', 'JOB_STATUS')
      .pipe(map((event: any) => event));
  }


  getExistenceVendorsCOI(vendor_id: string, property_id: string, category_id: string): Observable<boolean> {
    const url = `${environment.coiMsURL}/coi/check/company/${vendor_id}/property/${property_id}/category/${category_id}`;
    return this.http.get<{ data: boolean }>(url)
      .pipe(map(res => res && res.data));
  }


  /**
   * Do Pusher trigger for job tab actions
   * @param type
   * @param actionItemID
   */
  triggerPusherEventForJobTabs(type: string, actionItemID: string) {

    if (actionItemID === '' || actionItemID === null || actionItemID === undefined) {
      return
    }
    
    let requestURL = environment.baseURL + 'jobs/' + actionItemID + '/{actionType}/';
    requestURL = requestURL.replace('v1', 'v2');

    if (type == 'Schedule') {
      requestURL = requestURL.replace('{actionType}', 'realtime-schedules-details');
    }
    else if (type == 'Timesheet') {
      requestURL = requestURL.replace('{actionType}', 'realtime-timesheet-details');
    }
    else if (type == 'Material') {
      requestURL = requestURL.replace('{actionType}', 'realtime-materials-details');
    }
    else if (type == 'Job Cost') {
      requestURL = requestURL.replace('{actionType}', 'realtime-cost-details');
    }
    else if (type == 'Job Update') {
      requestURL = requestURL.replace('{actionType}', 'realtime_row_updates');
    }
    else if (type == 'New Tenant Job') {
      requestURL = requestURL.replace('{actionType}', 'realtime_row_updates');
    }


    this._baseService.doGET(requestURL).subscribe({
      next: (response: any) => { 
        // console.log(response); 
      },
      error: (error) => { },
      complete: () => { }
    });
  }

  openAssociatedMailPopup(): void {
    let popupData = {
      title: `ALL ASSOCIATED EMAILS`,
      component: AssociatedMailsInJobComponent,
      containerClass: 'modal_popup_45',
    };
    this._dialogService.openModal(popupData).subscribe(result => { });
  }
}
