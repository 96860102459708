import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { ColumnSortType, TableColumnTypes } from 'src/app/@fyxt/_shared/enums/table/table-column-types.enum';
import { AddCoverageComponent, WarningDialogComponent } from 'src/app/@fyxt/_shared/_views';
import { ICoverage, IGetCoveragesListParams, IGetCoveragesListResponse } from '../../../models/coi/coverage.interface';
import { CoiService } from '../../../../../Modules/_fyxt_modules/coi/_services/coi.service';
import { Sort } from '@angular/material/sort';
import { TableService } from '../../../../_services/table/table.service';
import { ActivatedRoute } from '@angular/router';
import { WarningDialogType } from '../../_popup_views/warning-dialog/warning-dialog.interface';
import { UtilityService } from '../../../../../services/utility.service';
import {BaseService} from "../../../../../services/base.service";
import { TableComponent } from '../../../_reusable_ui/_components/table/table.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { NgIf } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';


@Component({
    selector: 'fyxt-tab-coverage',
    templateUrl: './tab-coverage.component.html',
    styleUrls: ['./tab-coverage.component.scss'],
    standalone: true,
    imports: [SearchInputComponent, FormsModule, NgxIntlTelInputModule, ButtonComponent, MatIconModule, MatDividerModule, NgIf, LoaderSmallComponent, TableComponent]
})
export class TabCoverageComponent implements OnInit {
  protected readonly id: string = this.activatedRoute.snapshot.params['id'];
  isLoader!: boolean;
  selectViewTableHeaders: any[];
  selectViewTableRows: any[] = [];
  selectViewAllTableColumns: any = [
    {
      value: 'coverage_type',
      sort: ColumnSortType.none,
      name: 'Coverage Type',
      type: TableColumnTypes.text,
      className: 'table-header',
      active: true,
    },
    {
      value: 'policy_number',
      sort: ColumnSortType.none,
      name: 'Policy Number',
      type: TableColumnTypes.text,
      className: 'table-header',
      active: true,
    },
    {
      value: 'effective_date',
      sort: ColumnSortType.none,
      name: 'Effective Date',
      type: TableColumnTypes.date,
      className: 'table-header',
      active: true,
    },
    {
      value: 'expiration_date',
      sort: ColumnSortType.none,
      name: 'Expiration Date',
      type: TableColumnTypes.date,
      className: 'table-header',
      active: true,
    },
    {
      value: 'insurance_company',
      sort: ColumnSortType.none,
      name: 'Insurer',
      type: TableColumnTypes.text,
      className: 'table-header',
      active: true,
    },
    {
      value: 'limits',
      sort: ColumnSortType.none,
      name: 'Limits',
      type: TableColumnTypes.text,
      className: 'table-header',
      active: true,
    },
    {
      value: 'brief_description',
      sort: ColumnSortType.none,
      name: 'Description',
      type: TableColumnTypes.text,
      className: 'table-header',
      active: true,
      sortDisabled: true,
    },
    {
      value: 'action',
      sort: ColumnSortType.none,
      name: '',
      type: TableColumnTypes.action,
      className: 'three-dot',
      active: true,
      actions: [
        { label: 'Edit', value: 'EDIT' },
        { label: 'Delete', value: 'DELETE' },
      ]
    },
  ];
  dataSource: any;
  sort: Sort = {
    active: '',
    direction: '',
  }

  selectedViewTableRowsTotal: number;
  selectedViewTableRowsPageSize: number = 25;
  currentPage: number = 0;
  pageIndexAPI: number = 1;
  moduleName = 'Coverages';
  searchInput: any = '';

  @Output() onChangeCount = new EventEmitter();

  constructor(
    public _dialogService: DialogService,
    private readonly coiService: CoiService,
    private readonly tableService: TableService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly utilityService: UtilityService,
    public _baseService: BaseService,
  ) {
    if (!this._baseService.isPermissionExist('add_coi')) {
      this.selectViewAllTableColumns = this.selectViewAllTableColumns.filter(item => item.value !== 'action');
    }
  }

  ngOnInit(): void {
    this.selectViewTableHeaders = this.selectViewAllTableColumns.filter((header: any) => {
      return header.active == true;
    }) || [];

    this.getSelectedTableData();
  }

  onPageChange(event: any) {
    this.currentPage = event.pageIndex;
    this.pageIndexAPI = event.pageIndex + 1;
    this.selectedViewTableRowsPageSize = event.pageSize;
    this.getSelectedTableData(!!this.searchInput.trim(), !!(this.sort !== undefined && this.sort?.active));
  }

  onRowClick(event: any): void {
  }


  onSearch(inputValue: any) {
    this.searchInput = inputValue;
    if (inputValue.length >= 2 || inputValue.length === 0) {
      this.getSelectedTableData(!!this.searchInput.trim(), !!this.sort?.active);
    }
  }

  onSortChange(sortState: any) {
    this.sort = sortState;
    this.selectedViewTableRowsPageSize = 25;
    this.pageIndexAPI = 1;
    this.currentPage = 0;
    this.getSelectedTableData(!!this.searchInput.trim(), true);
  }

  addCoverage() {

    let popupData = {
      title: 'Add Coverage',
      component: AddCoverageComponent,
      containerClass: ['add_coverage_container', 'modal_edit_colomn'],
    };

    this._dialogService.openModal(popupData).subscribe(result => {
      if (result) {
        this.getSelectedTableData();
        this.onChangeCount.emit();
      }
    });

  }

  editCoverage(coverage: ICoverage) {
    let popupData = {
      title: 'Edit Coverage',
      component: AddCoverageComponent,
      containerClass: ['add_coverage_container', 'modal_edit_colomn'],
      data: {
        coverage,
      }
    };

    this._dialogService.openModal(popupData).subscribe(result => {
      if (result) {
        if (result === 'DELETE') this.deleteCoverage(coverage);
        else this.getSelectedTableData();
      }
    });
  }

  getSelectedTableData(isSearch?: boolean, isSort?: boolean) {
    const params: IGetCoveragesListParams = {
      page: this.pageIndexAPI,
      take: this.selectedViewTableRowsPageSize,
    }
    if (this.sort?.active) {
      switch (this.sort?.active) {
        default:
          params.sort = this.sort.active;
          break;
      }
      params.order = this.sort.direction.toUpperCase();
    }

    if (this.searchInput.trim()) {
      params.search = this.searchInput;
    }

    this.isLoader = true;
    this.coiService.getCoveragesList(this.id, params).subscribe(
      (data: IGetCoveragesListResponse) => {
        const { coverages: CoveragesList = [], page, size, total } = data
        this.selectViewTableRows = CoveragesList || [];
        this.selectedViewTableRowsTotal = total;
        this.pageIndexAPI = page;
        this.selectedViewTableRowsPageSize = size;

        // Append sort direction to the header if we have sort query we get from the backend
        if (this.sort?.active) {
          this.selectViewTableHeaders.map((header: any) => {
            if (header.value == this.sort.active) {
              return header.sort = this.sort.direction;
            } else {
              return header.sort = ColumnSortType.none;
            }
          });
        }

        this.dataSource = this.tableService.setTableData(this.moduleName, this.selectViewTableHeaders, this.selectViewTableRows, this.selectedViewTableRowsTotal, this.currentPage, this.selectedViewTableRowsPageSize);
        // this._Config.isLoader = false;
        this.isLoader = false;
      },
      error => {
        // this._Config.isLoader = false;
        this.isLoader = false;
      });
  }


  onRowAction(event: ICoverage & { label: string }) {
    if (event?.label === 'DELETE') {
      this.deleteCoverage(event);
    }
    if (event?.label === 'EDIT') {
      this.editCoverage(event);
    }

  }

  private deleteCoverage(event: ICoverage) {
    let popupData = {
      title: `Delete Coverage ?`,
      component: WarningDialogComponent,
      containerClass: ['modal-md'],
      data: { dialogType: WarningDialogType.DELETE_COVERAGE, confirmText: 'Delete' },
    };

    this._dialogService
      .openModal(popupData)
      .subscribe((value) => {
        if (value) {
          this.coiService.deleteCoverage(event.id)
            .subscribe({
              next: (res) => {
                if (res) {
                  this.utilityService.showSuccess('', 'Coverage Deleted Successfully');
                  this.getSelectedTableData();
                  this.onChangeCount.emit();
                }
              },
              error: () => {
                this.utilityService.showError('', 'Coverage Deleted Error');
              },
            })

        }
      });
  }

}

