// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .disable_select label {
  color: #192c49 !important;
}
::ng-deep .disable_select .ng-arrow-wrapper {
  display: none;
}

.disabledForm {
  pointer-events: none !important;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_forms/create-equipment-type/create-equipment-type.component.scss"],"names":[],"mappings":"AACI;EACI,yBAAA;AAAR;AAGI;EACI,aAAA;AADR;;AAIA;EACI,+BAAA;AADJ","sourcesContent":["::ng-deep .disable_select {\n    label {\n        color: #192c49 !important;\n    }\n\n    .ng-arrow-wrapper {\n        display: none;\n    }\n}\n.disabledForm {\n    pointer-events: none !important;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
