import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
    selector: 'fyxt-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => TagsComponent)
        }
    ],
    standalone: true,
    imports: [NgSelectModule, FormsModule, NgxIntlTelInputModule]
})
export class TagsComponent implements OnInit {

  @Input() items!:any;
  @Input() label:any;
  @Input() placeholder:any;
  @Output() onSelected = new EventEmitter();
  @Input() disable: boolean;
  // @Output() onDeleted = new EventEmitter();
  @Output() onValueChange = new EventEmitter<Event>();
  // dropdown:boolean = false;


  @Input() selectedItems:any[]=[]
  constructor() { }

  ngOnInit() {
    if(this.items?.length > 0){
      this.selectedItems.push(...this.items);
    }
  }

  // doDelete(item:any,index:any){
  //   this.items.splice(index,1);
  //   this.onDeleted.emit(item);
  //   this.onSelected.emit(this.items);
  // }

  // openDropdown(){
  //   this.dropdown = !this.dropdown;
  // }

  addTagFn(name) {
   return { label: name , name: name };
  }

  onChange = (data:any) => {}
  onTouch = (_:any) => {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: string) {
    this.onChange(value);
  }

  onchange(event:any){
    this.onChange(event);
    this.onValueChange.emit(event);
    this.onSelected.emit(event);
  }


}
