import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { forkJoin, BehaviorSubject } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Apollo, gql } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApolloClientOptions, DefaultOptions, InMemoryCache } from '@apollo/client/core';
import { NotesTypes } from '../../leases/leases.constants'
@Injectable({
  providedIn: 'root'
})
export class CompaniesDetailedViewService {
  _viewModule: string = "company";
  _NotesCurrentCount = 0



  getLastMonthDateRange() {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1).toISOString().split('T')[0];
    var lastDay = new Date(date.getFullYear(), date.getMonth(), 0).toISOString().split('T')[0];
    let data = {
      "firstDay": firstDay,
      "lastDay": lastDay
    }
    return data;
  }

  getLastWeekDateRange() {
    var dateNow = new Date();
    var firstDayOfTheWeek = (dateNow.getDate() - dateNow.getDay()) + 1; // Remove + 1 if sunday is first day of the week.
    var lastDayOfTheWeek = firstDayOfTheWeek + 6;
    var firstDayOfLastWeek = new Date(dateNow.setDate(firstDayOfTheWeek - 7)).toISOString().split('T')[0];
    var lastDayOfLastWeek = new Date(dateNow.setDate(lastDayOfTheWeek - 7)).toISOString().split('T')[0];
    let data = {
      "firstDay": firstDayOfLastWeek,
      "lastDay": lastDayOfLastWeek
    }
    return data;
  }

  NEW_NOTE_SUBSCRIPTION = gql`subscription {
    noteCreated {
      id,
        content,
        entityType,
        user{
          id,
          first_name,
          last_name,
          photo
        }

    }
}`;
  private newNote = new BehaviorSubject<any>({});

  constructor(private httpClient: HttpClient, private appolo: Apollo, private httpLink: HttpLink) {
  }

  getnewNote() {
    return this.newNote.asObservable();
  }


  getSelectedCompanyDetails(id: string) {
    return this.httpClient.get(`${environment.fastAPI_Actual_URL}/companies/${id}/`);
  }


  getCompanyDetailedPageTabs(id: string) {
    return this.httpClient.get(`${environment.fastAPI_URL}/${this._viewModule}/${id}/company_info_tab_count/`);
  }

  getCompanyDetailedPageTabsCount(id: string) {
    return this.httpClient.get(`${environment.fastAPI_Actual_URL}/companies/${id}/counts/`);
  }

  //Get Company Activities
  GetCompaniesActivities(id: string) {
    return this.httpClient.get(`${environment.fastAPI_URL}/${this._viewModule}/${id}/activity/`);
  }

  getCompaniesNotes(id: string | number, sort?: string, type?: NotesTypes, date?: any, pagenumber?: any, search?: string) {
    return this.appolo.use('notes').query({ query: this.getAllNotes(id, sort, type, date, pagenumber, search) }).pipe(map((res: any) => res.data?.notes));
  }

  getJobNotes(id: string | number, type?: NotesTypes) {
    return this.appolo.use('notes').query({ query: this.getAllNotes(id, 'DESC', NotesTypes.JOB, null, 1, '') }).pipe(map((res: any) => res.data?.notes));
  }

  getNoteById(id: string) {
    return this.appolo.use('notes').query({ query: this.getNoteByIdQuery(id) }).pipe(map((res: any) => res.data));
  }

  getNoteByIdQuery(id) {
    return gql`query{
      note(id: "${id}"){
        id,
        content,
        entityType,
        createdAt,
        lastEditedAt,
        createdByType,
        user{
          id,
          first_name,
          last_name,
          photo
        }
      }
    }`
  }

  getNoteVendorById(id,vendorId,entityType) {
    return this.appolo.use('notes').query({ query: this.getNoteVendorByIdQuery(id,vendorId,entityType) }).pipe(map((res: any) => res.data));
  }


  getNoteVendorByIdQuery(id,vendorId,entityType) {
    return gql`query{
      vendorNote(id: "${id}",vendorId:"${vendorId}",entityType:"${entityType}"){
        id,
        content,
        entityType,
        createdAt,
        lastEditedAt,
        createdByType,
        user{
          id,
          first_name,
          last_name,
          photo
        }
      }
    }`
  }

  getAllNotes(id: string | number, sort: string, type: string, date: any, pagenumber?: any, search?: string) {
    var dateFilter;
    var searchQuery = '';
    var currentpage = pagenumber;
    if (date != null) {
      dateFilter = `,dateFilter: {startedAt: "${date?.firstDay}",endedAt: "${date?.lastDay}"}`;
    } else {
      dateFilter = ''
    }
    if (pagenumber == undefined || currentpage == undefined) {
      currentpage = 1
    }

    if(search) {
      searchQuery = `searchTerm: "${search}"`
    }

    return gql`query{
      notes(paginate: { show: 40, page: ${currentpage} },filter:{ entityId: "${id}", entityType: "${type}" ${dateFilter}, ${searchQuery}}, sort: {createdAt: "${sort}"}){
        id,
        content,
        entityType,
        createdAt,
        lastEditedAt,
        createdByType,
        user{
          id,
          first_name,
          last_name,
          photo
        }
      }
    }`
  }

  totalAvailabePages(entity, job, sort) {
    return this.appolo.use('notes').query({ query: this.totalPagesQuery(entity, job, sort) }).pipe(map((res: any) => res.data?.notesCount));

  }

  totalPagesQuery(entity, job, sort) {
    return gql`query {
      notesCount(paginate: { show: 150, page: 1 }, filter: { entityId: "${entity}", entityType: "${job}" }, sort: {createdAt: "${sort}"})
  }`
  }


  addNewNote(entityId, noteContent, type, users?, url?) {
    return this.appolo.use('notes').mutate({
      mutation: gql`mutation($user_array:[NoteMentionedUserDTO!]!){
      createNote(
        note: {
          content: "${noteContent}"
          entityId: "${entityId}"
          entityType: "${type}",
          mentionedUserIds:$user_array
        }
      ) {
        content,
        id,
        createdAt,
        createdByType,
        user{
          id,
          first_name,
          last_name,
          photo
        }
      },

      }`, variables: {
        user_array: users
      }
    }).pipe(map(res => res.data))
  }

  addNewSystemNote(entityId, noteContent, type, users?, url?) {
    return this.appolo.use('notes').mutate({
      mutation: gql`mutation($user_array:[NoteMentionedUserDTO!]!) {
      createSystemNote(
        note: {
          content: "${noteContent}"
          entityId: "${entityId}"
          entityType: "${type}"
          mentionedUserIds:$user_array,
        }
      ) {
        content,
        id,
        createdAt,
        createdByType,
        user{
          id,
          first_name,
          last_name,
          photo
        }
      },

      }`, variables: {
        user_array: users
      }
    }).pipe(map(res => res.data))
  }

  copyS3KeysAndSaveInTempLocation(files) {
    return this.appolo.use('files').mutate({
      mutation: gql`mutation($file_array:[FileInputDTO!]!) {
        copyS3KeysAndSaveInTempLocation(
          payload: $file_array,
        ) {
          id
        },
      }`, variables: {
        file_array: files
      }
    }).pipe(map(res => res.data))
  }


  addNoteMutation(entityId, noteContent, type) {
    return gql`mutation($primary_engineers:[String!]!) {
      createNote(
        note: {
          content: "${noteContent}"
          entityId: "${entityId}"
          entityType: "${type}"
        }
      ) {
        content,
        id,
        createdAt,
        user{
          id,
          first_name,
          last_name,
          photo
        }
      },
    },`
  }

  liveNotesUpdation(entityId: string | number) {
    return this.appolo.use('noteslive').subscribe({
      query: this.livenoteQuery(entityId),
    })
  }

  livenoteQuery(entityId: string | number) {
    return gql`subscription {
      noteCreated(entityId:"${entityId}")  {
        id
    content,
    entityType,
    entityId,
    lastEditedAt,
    }
  }`
  }

  liveNotesDeletion(entityId: string | number) {
    return this.appolo.use('noteslive').subscribe({
      query: this.livenoteDeleteQuery(entityId),
    })
  }


  livenoteDeleteQuery(entityId: string | number) {
    return gql`subscription {
      noteDeleted(entityId:"${entityId}")
  }`
  }

  liveNotesContentUpdate(entityId: string | number) {
    return this.appolo.use('noteslive').subscribe({
      query: this.liveNotesContentUpdateQuery(entityId),
    })
  }



  liveNotesContentUpdateQuery(entityId: string | number) {
    return gql`subscription {
      noteUpdated(entityId:"${entityId}")  {
        id,
    content
    }
  }`
  }


  deleteNoteMutation(noteId) {
    return this.appolo.use('notes').mutate({
      mutation: this.deleteNoteQuery(noteId)
    })
  }

  deleteNoteQuery(noteId) {
    return gql`mutation {
      deleteNote(
        id: "${noteId}"
      )
    }`
  }

  updateNote(id, content) {
    content ? content = content.replaceAll('"', '\'') : content = ""
    return this.appolo.use('notes').mutate({
      mutation: this.updateNoteQuery(id, content)
    })
  }


  updateNoteQuery(id, content) {
    return gql`
    mutation {
          updateNote(id: "${id}",  note: {content: "${content}"}) {
            id
            content
            user {
                id
                first_name
            }
        }
    }`
  }

  searchUserNotes(searchTerm) {
    return this.appolo.use('notes').subscribe({
      query: this.searchUsersQuery(searchTerm),
    })
  }


  searchUsersQuery(searchTerm: string) {
    return gql`query {
      searchUsers(q: "${searchTerm}") {
          id
          firstName
          lastName
          type
      }
  } `
  }

  getAccountLevelCategories() {
    let categoryTableRowsUrl = `${environment.baseURL}` + `categories/`;
    return this.httpClient.get(categoryTableRowsUrl);
  }

  getCompaniesCategories(companyDetails: any) {
    let categoryTableRowsUrl = `${environment.fastAPI_Actual_URL}` + `/companies/` + companyDetails?.id + `/categories/`;
    return this.httpClient.get(categoryTableRowsUrl);
  }

  updateCompaniesCategories(companyDetails: any, postBody: any) {
    let categoryTableRowsUrl = `${environment.fastAPI_Actual_URL}` + `/companies/` + companyDetails?.id + `/categories/`;
    return this.httpClient.put(categoryTableRowsUrl, postBody);
  }

  changeEditColumnOrderView(id: string, selectedTabName: string, payload: any) {
    return this.httpClient.put(`${environment.fastAPI_URL}/${this._viewModule}/${id}/${selectedTabName}/edit_table/`, payload);
  }

  getCompanyContacts(id: string, searchVal: string) {
    let url = `${environment.fastAPI_URL}/${this._viewModule}/${id}/contacts/`;
    if (searchVal) {
      url += `?search=${searchVal}`;
    }
    return this.httpClient.get(url);
  }

  getPropertyTableDataRows(companyDetails: any, searchVal?: string, sortPayload?: any, paginationIndex?: any, perPage?: any, payload?: any) {
    const offset = (paginationIndex - 1) * perPage;
    let propertyTableRowsUrl = `${environment.LocalDev_URL_V2}properties/cc-assigned/` + '?page=' + paginationIndex + '&limit=' + perPage + '&offset=' + offset;
    if (searchVal) {
      propertyTableRowsUrl += `&search=${searchVal}`;
    }
    if (sortPayload?.active && sortPayload?.direction) {
      if (sortPayload.direction == 'asc') {
        propertyTableRowsUrl += `&ordering=${sortPayload.active}`;
      } else if (sortPayload.direction == 'desc') {
        propertyTableRowsUrl += `&ordering=-${sortPayload.active}`;
      }
    }
    return this.httpClient.post(propertyTableRowsUrl, payload);
  }

  unAssignProperty(postBody) {
    let url = `${environment.fastAPI_Actual_URL}/companies/properties/un-assign/`;
    return this.httpClient.patch(url, postBody);
  }

  getLeaseTableDataWithHeader(viewID: string) {
    let leaseTableHeaders = this.httpClient.get(`${environment.fastAPI_URL}/${this._viewModule}/` + viewID + `/leases/column/`);
    let leaseTableRows = this.httpClient.get(`${environment.fastAPI_URL}/${this._viewModule}/` + viewID + `/leases/`);
    let leaseOrderViewColumns = this.httpClient.get(`${environment.fastAPI_URL}/${this._viewModule}/` + viewID + `/leases/column/active/`);
    return forkJoin([leaseTableHeaders, leaseTableRows, leaseOrderViewColumns]);
  }

  getLeaseTableDataRows(viewID: string, searchVal?: string, sortPayload?: any) {
    let leaseTableRowsUrl = `${environment.fastAPI_URL}/${this._viewModule}/` + viewID + `/leases/`;
    if (searchVal) {
      leaseTableRowsUrl += `?search=${searchVal}`;
    }
    if (sortPayload.active && sortPayload.direction) {
      leaseTableRowsUrl += `?column=${sortPayload.active}&order=${sortPayload.direction}`;
    }
    return this.httpClient.get(leaseTableRowsUrl);
  }

  getAssociatedJobTableDataRows(companyDetails: any, searchVal?: string, sortPayload?: any, paginationIndex?: any, perPage?: any) {
    const offset = (paginationIndex - 1) * perPage;
    let associatedJobTableRowsUrl = `${environment.baseURL}company-jobs/` + companyDetails.id + `/?type=` + companyDetails.type + '&page=' + paginationIndex + '&limit=' + perPage + '&offset=' + offset;
    if (searchVal) {
      associatedJobTableRowsUrl += `&search=${searchVal}`;
    }
    if (sortPayload?.active && sortPayload?.direction) {
      if (sortPayload.direction == 'asc') {
        associatedJobTableRowsUrl += `&ordering=${sortPayload.active}`;
      } else if (sortPayload.direction == 'desc') {
        associatedJobTableRowsUrl += `&ordering=-${sortPayload.active}`;
      }
    }
    return this.httpClient.get(associatedJobTableRowsUrl);
  }

  getContactsTableDataWithHeader(viewID: string) {
    let contactTableHeaders = this.httpClient.get(`${environment.fastAPI_URL}/${this._viewModule}/` + viewID + `/contacts/column/`);
    let contactTableRows = this.httpClient.get(`${environment.fastAPI_URL}/${this._viewModule}/` + viewID + `/contacts/`);
    return forkJoin([contactTableHeaders, contactTableRows]);
  }

  getContactsTableDataRows(companyDetails: any, searchVal?: string, sortPayload?: any, paginationIndex?: any, perPage?: any) {
    let contactTableRowsUrl = `${environment.fastAPI_Actual_URL}` + `/companies/` + companyDetails.id + `/contacts/` + '?page=' + paginationIndex + '&size=' + perPage;
    if (searchVal) {
      if (contactTableRowsUrl.includes('?')) {
        contactTableRowsUrl += `&search=${searchVal}`;
      } else {
        contactTableRowsUrl += `?search=${searchVal}`;
      }
    }
    if (sortPayload?.active && sortPayload?.direction) {
      if (contactTableRowsUrl.includes('?')) {
        contactTableRowsUrl += `&sort=${sortPayload.active}&order=${sortPayload.direction}`;
      } else {
        contactTableRowsUrl += `?sort=${sortPayload.active}&order=${sortPayload.direction}`;
      }
    }
    return this.httpClient.get(contactTableRowsUrl);
  }

  getAssignContactsList(companyDetails: any, searchVal: string, pageNumber: any): any {
    let assignContactsUrl = `${environment.fastAPI_Actual_URL}` + `/companies/` + companyDetails.id + `/contacts-drop-down/`;
    if (searchVal && searchVal.trim() != '') {
      assignContactsUrl += `?search=${searchVal}`;
    }
    if (pageNumber > 1) {
      if (assignContactsUrl.includes('?')) {
        assignContactsUrl += '&page=' + pageNumber;
      } else {
        assignContactsUrl += '?page=' + pageNumber;
      }

    }
    return this.httpClient.get(assignContactsUrl);
  }

  assignUnAssignContacts(companyDetails: any, contactId: string, postBody: any, isFromDropDown: boolean): any {
    let assignUnAssignUrl = '';
    // If we made some changes in the assign contacts popup views then we need to send the query "?source=drop-down" to the backend
    if (isFromDropDown) {
      assignUnAssignUrl = `${environment.fastAPI_Actual_URL}` + `/companies/` + companyDetails.id + `/contacts/` + contactId + `/?source=drop-down`;
    } else {
      assignUnAssignUrl = `${environment.fastAPI_Actual_URL}` + `/companies/` + companyDetails.id + `/contacts/` + contactId + '/';
    }
    return this.httpClient.patch(assignUnAssignUrl, postBody);
  }

  bulkActionContacts(companyDetails: any, postBody: any): any {
    let bulkActionUrl = `${environment.fastAPI_Actual_URL}` + `/companies/` + companyDetails.id + `/contacts/bulk-actions/`;
    return this.httpClient.post(bulkActionUrl, postBody);
  }

  getUsersTableDataRows(companyDetails: any, searchVal?: string, sortPayload?: any, paginationIndex?: any, perPage?: any) {
    let userTableRowsUrl = `${environment.fastAPI_Actual_URL}` + `/companies/` + companyDetails.id + `/users/` + '?page=' + paginationIndex + '&size=' + perPage;
    if (searchVal) {
      if (userTableRowsUrl.includes('?')) {
        userTableRowsUrl += `&search=${searchVal}`;
      } else {
        userTableRowsUrl += `?search=${searchVal}`;
      }
    }
    if (sortPayload?.active && sortPayload?.direction) {
      if (userTableRowsUrl.includes('?')) {
        userTableRowsUrl += `&sort=${sortPayload.active}&order=${sortPayload.direction}`;
      } else {
        userTableRowsUrl += `?sort=${sortPayload.active}&order=${sortPayload.direction}`;
      }
    }
    return this.httpClient.get(userTableRowsUrl);
  }

  bulkActionUsers(companyDetails: any, postBody: any): any {
    let bulkActionUrl = `${environment.fastAPI_Actual_URL}` + `/companies/` + companyDetails.id + `/users/bulk-actions/`;
    return this.httpClient.patch(bulkActionUrl, postBody);
  }

  userPropertiesList(companyDetails: any): any {
    let userPropertyUrl = `${environment.fastAPI_Actual_URL}` + `/companies/` + companyDetails.id + `/properties/`;
    return this.httpClient.get(userPropertyUrl);
  }

  getFilesTableDataWithHeader(viewID: string) {
    let fileTableHeaders = this.httpClient.get(`${environment.fastAPI_URL}/${this._viewModule}/` + viewID + `/files/column/`);
    let fileTableRows = this.httpClient.get(`${environment.fastAPI_URL}/${this._viewModule}/` + viewID + `/files/`);
    return forkJoin([fileTableHeaders, fileTableRows]);
  }

  getFilesTableDataRows(viewID: string, searchVal?: string, sortPayload?: any) {
    let fileTableRowsUrl = `${environment.fastAPI_URL}/${this._viewModule}/` + viewID + `/files/`;
    if (searchVal) {
      fileTableRowsUrl += `?search=${searchVal}`;
    }
    if (sortPayload.active && sortPayload.direction) {
      fileTableRowsUrl += `?column=${sortPayload.active}&order=${sortPayload.direction}`;
    }
    return this.httpClient.get(fileTableRowsUrl);
  }


  getAllEXV_Notes(id: string, sort?: string, type?: string, date?: any, pagenumber?: any, vendorID?: any) {
    return this.appolo.use('notes').query({ query: this.getNotesEXVQuery(id, sort, type, date, pagenumber, vendorID) }).pipe(map((res: any) => res.data?.vendorNotes));
  }

  getNotesEXVQuery(id: string, sort: string, type: string, date: any, pagenumber?: any, vendorID?: any) {
    var dateFilter;
    var currentpage = pagenumber;
    if (date != null) {
      dateFilter = `,dateFilter: {startedAt: "${date?.firstDay}",endedAt: "${date?.lastDay}"}`;
    } else {
      dateFilter = ''
    }
    if (pagenumber == undefined || currentpage == undefined) {
      currentpage = 1
    }

    return gql`query{
      vendorNotes(
        paginate: { show: 40, page: ${currentpage} },
        filter:{ entityId: "${id}", entityType: "${type}" ${dateFilter},userId: "${vendorID}" },
        sort: {createdAt: "${sort}"}){
        id,
        content,
        entityType,
        createdAt,
        lastEditedAt,
        createdByType,
        user{
          id,
          first_name,
          last_name,
          photo
        }
      }
    }`
  }

  addNewEXVendorNote(entityId, noteContent, type, users?, url?, vendor_ID?: any) {

    //To Set object to update user type
    // if (users?.length > 0)
    //   users = users.map(obj => { let userObj = { "id": obj, "type": "VENDOR" }; return userObj })

    return this.appolo.use('notes').mutate({
      mutation: gql`mutation($user_array:[NoteMentionedUserDTO!]!){
        createVendorNote(
        note: {
          userId: "${vendor_ID}",
          createdByType: "VENDOR",
          content: "${noteContent}"
          entityId: "${entityId}"
          entityType: "${type}",
          urlToRedirect: "${url}",
          mentionedUserIds:$user_array,
        }
      ) {
        content,
        id,
        createdAt,
        entityType,
        lastEditedAt,
        createdByType,
        user{
          id,
          first_name,
          last_name,
          photo
        }
      },

      }`, variables: {
        user_array: users
      }
    }).pipe(map(res => res.data))
  }

  delete_EXV_NoteMutation(noteId, vendor_ID) {
    return this.appolo.use('notes').mutate({
      mutation: this.delete_EXV_NoteQuery(noteId, vendor_ID)
    })
  }

  delete_EXV_NoteQuery(noteId, vendor_ID) {
    return gql`mutation {
      deleteVendorNote(
        id: "${noteId}",
        note: {
          userId: "${vendor_ID}"
        }
      )
    }`
  }

  update_EXV_Note(id, content, vendor_ID) {
    return this.appolo.use('notes').mutate({
      mutation: this.update_EXV_NoteQuery(id, content, vendor_ID)
    })
  }


  update_EXV_NoteQuery(id, content, vendor_ID) {
    return gql`
    mutation {
      updateVendorNote(
            id: "${id}",
            note: {
              content: "${content}",
              userId: "${vendor_ID}"
            }) {
            id
            content
            user {
                id
                first_name
            }
        }
    }`
  }

  public notesSeenUpdate(type: string,jobID: string) {
    return this.httpClient.patch(environment.LocalDev_URL_V2 + `user-job-status/job-seen-update/?notes=${type}`, { "job_id": jobID })
  }

  searchJobMetions(id: string, q: string) {
    return this.appolo.use('notes').query({ query: this.searchJobMetionsQuery(id, q) });
  }

  searchJobMetionsQuery(id: string, searchTerm: string) {
    const currentUserInfo: any = localStorage.getItem('userobj')
    ? JSON.parse(localStorage.getItem('userobj'))
    : {};
    
    return gql`query {
      searchJobUsers(q: "${searchTerm}", jobId: "${id}", showTenants: ${currentUserInfo?.enable_tenants_in_notes}) {
          id
          firstName
          lastName
          email
          type
      }
    } `
  }
}