import { Directive, OnInit, Renderer2, Input, ElementRef } from "@angular/core";
import {CompaniesService} from "../../../../Modules/_fyxt_modules/companies/_services/companies.service";
import {TableService} from "../../../_services/table/table.service";

@Directive({
    selector: "[resizeColumn]",
    standalone: true
})
export class ResizeColumnDirective implements OnInit {
  @Input("resizeColumn") resizable!: boolean;
  @Input("resizeColumnName") resizableColumn!: any;
  columnWidth:any;
  resizedWidth:any;
  @Input() index!: number;

  private startX!: number;

  private startWidth!: number;

  private column: HTMLElement;

  private table!: HTMLElement;

  private pressed!: boolean;

  constructor(private renderer: Renderer2, private el: ElementRef, public tableService: TableService,) {
    this.column = this.el.nativeElement;
  }

  ngOnInit() {
    if (this.resizable) {
      const row = this.renderer.parentNode(this.column);
      const thead = this.renderer.parentNode(row);
      this.table = this.renderer.parentNode(thead);

      const resize = this.renderer.createElement("span");
      this.renderer.addClass(resize, "resize-holder");
      this.renderer.appendChild(this.column, resize);
      this.renderer.listen(resize, "mousedown", this.onMouseDown);
      this.renderer.listen(this.table, "mousemove", this.onMouseMove);
      this.renderer.listen("document", "mouseup", this.onMouseUp);
    }
  }

  onMouseDown = (event: MouseEvent) => {
    this.pressed = true;
    this.startX = event.pageX;
    this.startWidth = this.column.offsetWidth;
  };

  onMouseMove = (event: MouseEvent) => {
    if (this.pressed && event.buttons) {
      this.renderer.addClass(this.table, "resizing");
      const offset = 35;
      // Calculate width of column
      this.columnWidth = this.startWidth + (event.pageX - this.startX - offset);

    const tableCells = Array.from(this.table.querySelectorAll(".mat-row")).map(
      (row: any) => row.querySelectorAll(".mat-cell").item(this.index)
    );

    // Set table header width
    this.renderer.setStyle(this.column, "width", `${this.columnWidth}px`);
    }
  };

  onMouseUp = (event: MouseEvent) => {
    if (this.pressed) {
      this.pressed = false;
      const offset = 35;
          // Calculate width of column
          this.columnWidth =  this.startWidth + (event.pageX - this.startX - offset);
          this.resizedWidth = {
            name:this.resizableColumn.name,
            width:this.columnWidth
          }
          const tableCells = Array.from(this.table.querySelectorAll(".mat-row")).map(
          (row: any) => row.querySelectorAll(".mat-cell").item(this.index)
        );

        // Set table header width
        this.renderer.setStyle(this.column, "width", `${this.columnWidth}px`);

        // Set table cells width
        for (const cell of tableCells) {
          this.renderer.setStyle(cell, "width", `${this.columnWidth}px`);
        }
      this.renderer.removeClass(this.table, "resizing");
        this.tableService.resizeColumnElement = this.resizedWidth;
        this.tableService.isColumnResizeUpdate.next(true);
    }
  };
}
