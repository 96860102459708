import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, forwardRef, Input, NO_ERRORS_SCHEMA, OnInit, Output } from '@angular/core'
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { BehaviorSubject } from 'rxjs'
import { DropdownComponent } from '../dropdown/dropdown.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgClass, AsyncPipe, CommonModule } from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';


@Component({
  selector: 'fyxt-timepicker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TimePickerComponent)
    },
  ],
  standalone: true,
  imports: [
    CommonModule, 
    NgIf, 
    MatFormFieldModule, 
    MatInputModule, 
    FormsModule, 
    NgClass, 
    NgxIntlTelInputModule, 
    DropdownComponent, 
    AsyncPipe,
    NgxMaskDirective
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class TimePickerComponent implements OnInit {

  constructor() { }

  @Input() label: any;
  @Input() id: any;
  @Input() Error: any;
  @Input() placeHolder!: string;
  @Input() required: boolean = false;
  @Input() customClass: string = '';
  @Input() allowKeyboardInput: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isMeridianShow: boolean = true;

  @Input() isPicker: boolean = false;

  // set the defult time in time picker, by default 12 AM
  // ex: pass the value [defaultTime]="'08:00 AM'"
  @Input() defaultTime: string = "12";
  @Input() defaultMeridiem: 'AM' | 'PM' = 'AM';

  @Output() onValueChange = new EventEmitter<string>();
  input: any;
  formattedValue$ = new BehaviorSubject<string>('');
  private ampm: 'AM' | 'PM' = 'PM';
  time: string = '';
  private readonly ampmOptions = [{ name: 'AM', value: 'AM' }, { name: 'PM', value: 'PM' }]
  private formControlOnChangeHandler: (value: string) => void | null = null;
  private formControlOnTouchHandler: () => void | null = null;

  // input box text value
  private defaultTimeValue: string = "";

  public customPatternsSubject$ = new BehaviorSubject<{
    [index: string]: {
      pattern: RegExp
    }
  }>({
    '1': { pattern: /^[0-9]$/ }, // hours x0:00
    'l': { pattern: /^[0-9]$/ }, // hours 0x:00
    'p': { pattern: /^[0-5]$/ }, // minutes 00:x0
    '0': { pattern: /^[0-9]$/ }, // minutes 00:0x
  }) // naming is not understandable because ngx-mask module has handlers for names 'H', 'h', 'M', 'm' under the hood.

  maskSubject$ = new BehaviorSubject('1l:p0')

  ngOnInit(): void {

    // text box value will be empty if defaultTime is not set, else defaultTime will be displayed.
    this.defaultTimeValue = (this.defaultTime === "12") ? "" : this.defaultTime;

    this.formattedValue$.subscribe((_value) => {
      const currentPatterns = this.customPatternsSubject$.getValue()
      const [hours = '', minutes = ''] = _value.split(':');
      if (this.isMeridianShow) {
        this.customPatternsSubject$.next({
          ...currentPatterns,
          'l': { pattern: hours.length && hours[0] === '0' ? /^[1-9]$/ : /^[0-2]$/ }
        })
      } else {
        this.customPatternsSubject$.next({
          ...currentPatterns,
          '1l': { pattern: /^(?:[01][0-9]|2[0-3])$/ }
        })
      }

      if (hours.length && +hours[0] > 1) {
        if (this.isMeridianShow) {
          this.maskSubject$.next('1:p0')
        } else {
          this.maskSubject$.next('11:p0')
        }
      } else {
        this.maskSubject$.next('1l:p0')
      }
    })
  }

  registerOnChange(fn: () => void): void {
    this.formControlOnChangeHandler = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.formControlOnTouchHandler = fn;
  }

  writeValue(value: string) {
    if (typeof value !== 'string') {
      return;
    }

    const [time, ampm] = value.split(' ');
    const [formattedTime] = this.validateTime(time).split(' ');
    const isAmpmValid = this.ampmOptions.some(({ value }) => value === ampm);

    this.time = formattedTime || ''
    this.ampm = isAmpmValid ? ampm as 'AM' | 'PM' : this.defaultMeridiem;

    this.formattedValue$.next(this.time);
  }

  /**
*Func triggered on input change
*/
  onChange(value: string) {
    this.onValueChange.emit(value);

    if (!this.formControlOnChangeHandler) {
      return;
    }

    this.formControlOnChangeHandler(value);
  }

  handleChangeTime(event: Event) {
    let value = (event.target as HTMLInputElement).value;
    this.time = value
    // The below commented line may required if we add prefix 0 for 2 to 9
    //let displayValue: any = value;
    // value = (value.length <= 2 && value.toString() != '0' && value.toString() != '' ) ? value + ':00' : value;
    // if( displayValue.length <= 2 && parseInt(displayValue) > 1 && parseInt(displayValue) < 10) {
    //   this.time = '0' + parseInt(displayValue);
    // }
    const newValue = this.validateTime(value)
    this.onChange(newValue);
    this.formattedValue$.next(newValue)
  }

  handleChangeAmpm({ value }: any) {
    this.ampm = value
    const currentTime = this.formattedValue$.getValue()

    this.onChange(this.validateTime(currentTime));
    this.formattedValue$.next(currentTime)
  }


  onBlur() {
    const [time] = this.validateTime(this.formattedValue$.getValue()).split(' ');

    let [hours = '', minutes = ''] = time?.split(':');

    if (hours === '0') {
      this.time = '';
      return;
    }

    if (hours?.length && hours.length < 2) {
      hours = '0' + hours;
    }
    this.formattedValue$.next([hours, minutes].join(':'));
    this.time = [hours, minutes].join(':');
  }

  private validateTime(_time: string) {
    if (!_time) return _time;
    let [hours = '', minutes = ''] = _time.split(':');

    if (!/^[01]/.test(hours) && hours?.length > 1) {
      minutes = hours[1] + minutes
      hours = hours[0]
    }

    if (hours?.length && minutes?.length < 2) {
      minutes += '0'
      if (minutes.length < 2) {
        minutes += '0'
      }
    }

    const time = [hours, minutes].join(':')

    let isValidTime = null;

    if (this.isMeridianShow) {
      isValidTime = /^(0?[1-9]|1[0-2]):([0-5][0-9])$/.test(time);
    } else {
      isValidTime = /^(?:[0-9]|[01][0-9]|2[0-3]):([0-5][0-9])$/.test(time);
    }

    if (isValidTime) {
      return this.isMeridianShow ? `${time} ${this.ampm}` : `${time}`;
    }

    return _time
  }
}
