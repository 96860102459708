// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.count {
  position: relative;
  top: -12px;
  color: white;
  background: #d63c00;
  border-radius: 12px;
  padding: 0 6px;
  font-size: 12px;
}

.disable-tab {
  pointer-events: none !important;
  opacity: 0.4;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  /* default/primary color */
  --mdc-tab-indicator-active-indicator-color: #d63c00;
  --mat-tab-header-disabled-ripple-color: #192c49;
  --mat-tab-header-pagination-icon-color: #192c49;
  --mat-tab-header-inactive-label-text-color: #192c49;
  --mat-tab-header-active-label-text-color: #d63c00;
  --mat-tab-header-active-ripple-color: #d63c00;
  --mat-tab-header-inactive-ripple-color: #d63c00;
  --mat-tab-header-inactive-focus-label-text-color: #192c49;
  --mat-tab-header-inactive-hover-label-text-color: #d63c00;
  --mat-tab-header-active-focus-label-text-color: #d63c00;
  --mat-tab-header-active-hover-label-text-color: #d63c00;
  --mat-tab-header-active-focus-indicator-color: #d63c00;
  --mat-tab-header-active-hover-indicator-color: #d63c00;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_reusable_ui/_components/tab/tab.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,UAAA;EACA,YAAA;EACA,mBAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;AACJ;;AAEA;EACE,+BAAA;EACA,YAAA;AACF;;AAIE;EACE,0BAAA;EACA,mDAAA;EACA,+CAAA;EACA,+CAAA;EACA,mDAAA;EACA,iDAAA;EACA,6CAAA;EACA,+CAAA;EACA,yDAAA;EACA,yDAAA;EACA,uDAAA;EACA,uDAAA;EACA,sDAAA;EACA,sDAAA;AADJ","sourcesContent":[".count {\n    position: relative;\n    top: -12px;\n    color: white;\n    background: #d63c00;\n    border-radius: 12px;\n    padding: 0 6px;\n    font-size: 12px;\n  }\n\n.disable-tab {\n  pointer-events: none !important;\n  opacity: 0.4;\n}\n\n\n  \n  .mat-mdc-tab-group,.mat-mdc-tab-nav-bar {\n    /* default/primary color */\n    --mdc-tab-indicator-active-indicator-color: #d63c00;\n    --mat-tab-header-disabled-ripple-color: #192c49;\n    --mat-tab-header-pagination-icon-color: #192c49;\n    --mat-tab-header-inactive-label-text-color: #192c49;\n    --mat-tab-header-active-label-text-color: #d63c00;\n    --mat-tab-header-active-ripple-color: #d63c00;\n    --mat-tab-header-inactive-ripple-color: #d63c00;\n    --mat-tab-header-inactive-focus-label-text-color: #192c49;\n    --mat-tab-header-inactive-hover-label-text-color: #d63c00;\n    --mat-tab-header-active-focus-label-text-color: #d63c00;\n    --mat-tab-header-active-hover-label-text-color: #d63c00;\n    --mat-tab-header-active-focus-indicator-color: #d63c00;\n    --mat-tab-header-active-hover-indicator-color: #d63c00;\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
