import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../../environments/environment'
import { Observable } from 'rxjs'
import { SignaturePostRequestBody, RequiredSignature, ChecklistSignatures } from './checklist-signature.interface'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class ChecklistSignaturesHttpService {
  private readonly apiUrl = `${environment.coreMsURL}/checklist-signature`;

  constructor (
    private readonly http: HttpClient,
  ) {}

  getSignatures(jobId: number, checklistId: string, isPublic?: boolean): Observable<ChecklistSignatures> {
    let getSignaturesURL = `${this.apiUrl}/job/${jobId}/checklist/${checklistId}`;
    let options = {}

    if (isPublic) {
      getSignaturesURL = `${this.apiUrl}/public/job/${jobId}/checklist/${checklistId}`
      options = {
        headers: {
          'x-secret-key': environment.externalVendor.xSecretKey,
        }
      }
    }

    return this.http.get<{ data: ChecklistSignatures }>(getSignaturesURL, options)
      .pipe(map((result) => result.data))
  }

  saveSignature(signature: SignaturePostRequestBody, isPublic?: boolean, isPublicVendor?: boolean): Observable<RequiredSignature> {
    let url = `${this.apiUrl}/sign`;
    let options = {}

    if (isPublic) {
      url = `${this.apiUrl}/public/sign`
      options = {
        headers: {
          'x-secret-key': environment.externalVendor.xSecretKey,
        }
      }
    }
    if (isPublicVendor) {
      url = `${this.apiUrl}/public/sign/vendor`
      options = {
        headers: {
          'x-secret-key': environment.externalVendor.xSecretKey,
        }
      }
    }

    return this.http.post<{ data: RequiredSignature }>(url, signature, options)
      .pipe(map((result) => result.data))
  }

  deleteSignature(signature_id: string, options?: { isPublic?: boolean }): Observable<{ id: string }> {
    let url = `${this.apiUrl}/${signature_id}`;

    if (options?.isPublic) url = `${this.apiUrl}/public/${signature_id}`;

    return this.http.delete<{ data: { id: string } }>(url).pipe(map((result) => result.data))
  }

  deleteUserRoleSignaturesFromJob(jobId: string, userRole: string) {
    let url = `${this.apiUrl}/job/${jobId}?user_role=${userRole}`;
    return this.http.patch(url, {});
  }

  notifyAgain(signatureId: string): Observable<any> {
    const url = `${this.apiUrl}/notify-again/${signatureId}`;
    return this.http.post(url, {});
  }

  getContactById(checklist_id: string, isPublic?: boolean) {
    let getSignaturesURL = `${this.apiUrl}/member-contact-info/${checklist_id}`;
    let options = {}

    if (isPublic) {
      getSignaturesURL = `${this.apiUrl}/public/member-contact-info/${checklist_id}`
      options = {
        headers: {
          'x-secret-key': environment.externalVendor.xSecretKey,
        }
      }
    }

    return this.http.get<{ data: any }>(getSignaturesURL, options)
      .pipe(map((result) => result.data))
  }

  resetJobChecklistSignatures(jobId: string, checklistId: string): Observable<any> {
    let url = `${this.apiUrl}/reset/job/${jobId}/checklist/${checklistId}`;
    return this.http.patch(url, {});
  }
}
