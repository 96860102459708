// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  width: 600px;
}
.container .text {
  padding-top: 15px;
}
.container .danger {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/app/Modules/_fyxt_modules/inbox-management/components/delete-inbox/delete-inbox.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AACE;EACE,iBAAA;AACJ;AAEE;EACE,UAAA;AAAJ","sourcesContent":[".container {\n  width: 600px;\n\n  .text {\n    padding-top: 15px;\n  }\n\n  .danger {\n    color: red;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
