import { Injectable } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'

import { ConfigService } from '../services/config.service'
import { environment } from '../../environments/environment'
import { UiService } from 'src/app/services/ui/ui.service'
import { UtilityService } from 'src/app/services/utility.service'
import { SubjectDataService } from 'src/app/services/subject-data.service'
import { Observable, Subject, of } from 'rxjs'
import { delay, take } from 'rxjs/operators'
import {
  JobEmailModalsComponent,
} from '../Modules/shared/components/job-modals/job-email-modals/job-email-modals.component'
import { MatDialog } from '@angular/material/dialog'
import { BaseService } from './base.service'
import { JobCost, JobCostStatus, JobCostType } from '../@fyxt/_shared/enums/jobs/job-cost.types'

@Injectable({
  providedIn: 'root'
})
export class TicketFlowsService {

  currentUserInfo: any = localStorage.getItem('userobj') ? JSON.parse(localStorage.getItem('userobj')) : {};
  currentActiveState: any = "";

  defaultActiveTab: any = 5;
  selectedActiveTile: any = 0;

  dashboardAllJobsList: any = {
    dashboardJobs: { next: '', count: 0 },
    ActiveJobs: { next: '', count: 0 },
    RequiredJobs: { next: '', count: 0 },
    TenantResponsibleJobs: { next: '', count: 0 },
    DraftJobs: { next: '', count: 0 },
    count: 0,
    current_page: null,
    is_pagination: false,
    allPastJobs: [],
    itemsPerPage: 20
  };

  allDashboardJobs: any[] = [];
  isNotesOver = false;


  AllActiveJobs: any[] = [];
  AllRequiredActionJobs: any[] = [];
  AllTenantResponsibleJobs: any[] = [];
  allTilesCounts: any = {};
  is_newReqRecieved: any = 0;
  allLinkedItem: any[] = [];
  allLinkedData: any[] = [];
  allLinkedJob: any[] = [];
  allLinkedDuplicate: boolean = false;
  isDuplicateSection: boolean = true;
  allLinkedSection: boolean = true;
  allLinkedJobDropdownData: any = {
    pagination: { next: '', count: 0 },
    tempData: [],
    selectedItems: null
  };

  uploadPhotos: any[] = [];
  selectedJobDetails: any = {};
  JobActionTabs: any = "";
  jobDetailNotesData: any[] = [];
  isDraftTicket = false;
  getAllDraftJob = [];
  draftJobId = 1;
  draftJobName = 1;
  selectedDraftJobDetail: any;
  isCreateTicket = false;
  billablePartyItems: any;
  isNotesExpanded = false;
  isChecklistOpen = false;
  selectedEmailId: any;

  // To identify the create new job popup opens from the job checklist page
  createJobFromJobChecklist: boolean = false;


  /************* Search Filters Params ****************/
  searchParams: any = {
    Property_ID: null,
    Role_ID: null,
    searchKeyword: '',
    timeline: '',
    sortBy: 'desc',
    sortColumn: 'id',
    isFilter: false,
    isFilterQueryParams: ''
  };

  AllJobVendorsDropdownList: any[] = [];

  subModalAccess: any = {
    uploadPhotos: false,
    addvendorstoJobModal: false,
  };


  selectedSchedulingItem: any = {};
  AllJobVendorsList: any[] = [];
  allJobCosts: any = {};
  invoicesToCompare: any[] = []
  bidsToCompare: any[] = []
  selectedInvoice: any = {};
  allJobCostsListToCompare: any = [];
  awaitingSiteVisitProposal: any[] = [];
  awaitingScheduleProposal: any[] = [];
  awaitingBidProposal: any[] = [];
  awaitingInvoiceProposal: any[] = [];
  awaitingChangeOrderProposal: any[] = [];
  allJobTimeline: any = {};
  isPhotoLoaded: boolean = false;

  isQuickViewAction: any = "";

  selectedVendorsItems: any = null;
  selectedVendor: any = {};
  allBidsList: any[] = [];
  complete_and_close_Data: any = {};
  AllDraftJobs: any[] = [];

  selectedDraftJobDetails: any = {};
  showCreaateTicket: boolean = false;
  CartOfAccounts_Category: any[] = [];
  siteVisitActive: boolean = false;
  scheduleJobActive: boolean = false;

  selectedJob_status: any = "";

  job_AllLocationDropdownLists: any[] = [];
  job_AllCategoriesDropdownLists: any[] = [];
  Job_AllAddressesDropdownLists: any[] = [];
  job_AllAssigneeDropdownLists: any[] = [];
  job_AllFollwersDropdownLists: any[] = [];
  job_AllEngineersDropdownLists: any[] = [];
  job_AllVendorsDropdownLists: any[] = [];

  selectedImagePreview: any = {
    pre: {},
    post: {}
  };
  budgetDetails: any = {};
  // allPastJobs: any[];
  spendAmountDetails: any[] = [];

  showReasonModalType: any = "";

  jobNotesData: any = "";

  PriorityTypesList: any[] = [];

  // PriorityTypesList = [
  //   { value: 'Low', label: 'Low' },
  //   { value: 'Medium', label: 'Medium' },
  //   { value: 'High', label: 'High' },
  //   { value: 'Emergency', label: 'Emergency' },
  // ];

  /************ Left side Filters *****************/
  allSavedFiltersList: any[] = [];
  editValueList: any = {};
  filterItems: any = {
    dateRange: {
      isExpended: false,
      dateData: [
        { id: '1', name: 'Date Created', selected: false, datarange: [], showDateClear: false },
        { id: '2', name: 'Schedule Dates', selected: false, datarange: [], showDateClear: false },
        { id: '3', name: 'Target Completion Date', selected: false, datarange: [], showDateClear: false }
      ]
    },
    requestType: {
      isExpended: false,
      requestTypeData: [
        { id: '0', name: 'In Unit', selected: false },
        { id: '1', name: 'Common Area', selected: false }
      ]
    },
    assignedUsers: {
      isExpended: false,
      allAssignedUsers: [],
      searchKeyword: ""
    },
    categories: {
      isExpended: false,
      allCategories: [],
      searchKeyword: ""
    },
    properties: {
      isExpended: false,
      allProperties: [],
      searchKeyword: ""
    },
    tenants: {
      isExpended: false,
      allTenant: [],
      searchKeyword: ""
    },
    vendors: {
      isExpended: false,
      allVendor: [],
      searchKeyword: ""
    },
    owner_groups: {
      isExpended: false,
      allOwnerGroup: [],
      searchKeyword: ""
    },
    billable_parties: {
      isExpended: false,
      billableParty: [
        { id: '1', name: 'Manager', selected: false },
        { id: '2', name: 'Tenant', selected: false },
        { id: '3', name: 'Both', selected: false }
      ]
    },
    job_status: {
      isExpended: false,
      jobStatus: [
        { id: '1', name: 'Completed', value: 'Completed', selected: false },
      ]
    },
    job_actions: {
      isExpended: false,
      jobActions: [
        { id: '1', name: 'New Job Request ', value: 'Created', selected: false, isTenantRestrict: false },
        { id: '1', name: 'Approve Timesheet ', value: 'Labor Proposed', selected: false, isTenantRestrict: true },
        { id: '1', name: 'Approve Schedule ', value: 'Schedule Job Proposed', selected: false, isTenantRestrict: false },
        { id: '1', name: 'Approve Job Cost ', value: 'Bid Proposed', selected: false, isTenantRestrict: false },
        { id: '1', name: 'Approve Materials', value: 'Material Proposed', selected: false, isTenantRestrict: true },
      ]
    },
    job_priority: {
      isExpended: false,
      jobPriority: [
        { id: '1', name: 'Low', value: 'Low', selected: false },
        { id: '1', name: 'Medium', value: 'Medium', selected: false },
        { id: '1', name: 'High', value: 'High', selected: false },
        { id: '1', name: 'Emergency', value: 'Emergency', selected: false },
      ]
    },
    assignedEngineer: {
      isExpended: false,
      allAssignedEngineer: []
    },

  };
  dateRangeShow: any[] = [];

  /**show clearfilter */
  showDateClear: boolean = false;
  showRequestClear: boolean = false;
  showAssignClear: boolean = false;
  showCategoryClear: boolean = false;
  showPropertyClear: boolean = false;
  showTenantClear: boolean = false;
  showVendorClear: boolean = false;
  showOwnerClear: boolean = false;
  showEngineerClear: boolean = false;
  allJobContacts: any[] = [];
  allTargetDateHistory: any[] = [];

  /************ Left side Filters *****************/


  isCheckList: boolean = false;
  isPlannerJobPreview: boolean = false;


  /********Time sheet Filter *******/
  Timesheet: any = {
    all: { next: '' }
  };
  allTimesheets: any[] = [];
  selectedTab: any = 1;
  requestURLFilter: any = {};
  isTimesheetFilter: boolean = false;
  scheduleVendors_Engineer_Dropdown: any[] = [];

  /***********PastJob Filter ***********/
  isPastJob: boolean = false

  allProperties: any[] = [];
  allTenant: any[] = [];
  allCategories: any[] = [];
  allBuildReports: any[] = [];

  isShowCreateTicketModal: boolean = false;

  isViewFromInbox: boolean = false;
  isEmailCreateTicket: boolean = false;
  selectedEmailTemplate: any = {};

  allReportProperties: any[] = [];
  allReportTenants: any[] = [];
  allReportCategories: any[] = [];
  allReportEngineers: any[] = [];

  showBuildReport: boolean = false;
  showTimesheetBuildReport: boolean = false;
  isJobInfoEdit: boolean;
  allJobStagesList: any[] = [];
  openWorkOrderFromMinimize = false;
  temp_Engineers: any[] = [];
  liveLocationData: Observable<any[]>;
  all_ScheduleJob_Lists: any[] = [];
  timesheet_list_new: any[] = [];
  materials_list_new: any[] = [];

  vendorPagination: string = '';
  propertyPagination: string = '';
  tenantPagination: string = '';
  categoryPagination: string = '';

  isCheckAssignees = new Subject();
  filterPropertiesListPageNumber: number = 1;
  filterPropertiesListPageCount: number;
  filterPropertiesSize: number;
  filterPropertiesListTotal: number;
  filterPropertiesSearchInput: string = '';
  constructor(public _http: HttpClient,
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    public _Config: ConfigService,
    public _uiService: UiService,
    public _UtilityService: UtilityService,
    private _subjectService: SubjectDataService,
    public dialog: MatDialog,
    public _baseService: BaseService
  ) {

    this.currentActiveState = this.activatedRoute.snapshot['_routerState'].url;

  }




  /************** Base HTTP Request Methods *****************/

  /**
   * To Get Http Request for REST API
   * @param URL
   * @param Options
   */
  doGET(URL?: string, Options?: any): Observable<any> {
    return this._http.get(URL, Options).pipe(take(1));
  }

  /**
   * To Post Http Request for REST API
   * @param URL   // The Endpoint URL
   * @param Body    //Post Input Body
   * @param Options   //Headers or Other Optional Params
   */
  doPOST(URL?: string, Body?: any, Options?: any) {
    return this._http.post(URL, Body, Options);
  }

  /**
   * To Update Http Request for REST API
   * @param URL
   * @param Body
   * @param Options
   */
  doPUT(URL?: string, Body?: any, Options?: any) {
    return this._http.put(URL, Body, Options);
  }


  /**
   * To Delete Http Request for REST API
   * @param URL
   * @param Options
   */
  doDelete(URL?: string, Options?: any) {
    return this._http.delete(URL, Options);
  }

  /**
   * If Patch Request for Http Restfull API
   * @param URL
   * @param Body
   * @param Options
   */
  doPatch(URL?: string, Body?: any, Options?: any) {
    return this._http.patch(URL, Body, Options);
  }



  getCurrentActivePage() {
    return this.activatedRoute.snapshot['_routerState'].url;;
  }



  /**
   * Button Color Change
   * @param status
   */
  actionBtnColor(status: any) {

    let btnClass = {};

    if (status == 'Created' || status == 'Invited') {
      btnClass = { 'started-badge': true };
    }
    else if (status == 'Approved') {
      btnClass = { 'approved-badge': true };
    }
    else if (status == 'Labor Proposed' || status == 'Labor Approved' || status == 'Material Proposed' || status == 'Material Approved') {
      btnClass = { 'started-badge': true };
    }
    else if (status == 'Schedule Job Proposed' || status == 'Schedule Job Approved' || status == 'Schedule Job Rescheduled' || status == 'Schedule Job Cancelled' || status == 'Schedule Job None') {
      btnClass = { 'schedule-badge': true };
    }
    else if (status == 'Site Visit Proposed' || status == 'Site Visit Approved' || status == 'Site Visit Rescheduled' || status == 'Site Visit Cancelled' || status == 'Site Visit None') {
      btnClass = { 'site-badge': true };
    }
    else if (status == 'Cancelled') {
      btnClass = { 'canceled-badge': true };
    }
    else if (status == 'Completed' || status == 'Completed By Tenant' || status == 'Completed By Engineer' || status == 'Incompleted By Engineer') {
      btnClass = { 'closed-out-badge': true };
    }
    else if (status == 'Bid Proposed' || status == 'Bid Approved' || status == 'Bid Rejected' || status == 'Bid Cancelled' || status == 'Bid None') {
      btnClass = { 'bid-badge': true };
    }
    else if (status == 'Invoice Proposed' || status == 'Invoice Approved' || status == 'Invoice Rejected' || status == 'Invoice Cancelled') {
      btnClass = { 'invoice-badge': true };
    }
    else if (status == 'Change Order Proposed' || status == 'Change Order Approved' || status == 'Change Order Rejected' || status == 'Change Order Cancelled') {
      btnClass = { 'changeorder-badge': true };
    }
    else if (status == 'Incompleted' || status == 'Incompleted By Tenant' || status == 'Incompleted By Engineer' || status == 'Incompleted By Vendor') {
      btnClass = { 'started-badge': true };
    } else if (status === 'To Do') {
      btnClass = { 'started-badge': true };
    } else if (status == 'Closed') {
      btnClass = { 'closed-out-badge': true };
    } else if (status == 'Completed By Vendor') {
      btnClass = { 'completed-badge': true };
    }
    else {
      btnClass = { 'job-rejected-badge': true };
    }

    return btnClass;
  }

  /**
  * Button Status Text
  * @param status
  */
  actionText(status: any): any {
    return status;
    let btnText = '';
    if (status === 'Created' || status === 'Invited') {
      btnText = 'Job Requested';
    } else if (status === 'Accepted' || status === 'Passed') {
      btnText = 'Job Created';
    } else if (status === 'Approved') {
      btnText = 'Job Created';
    } else if (status === 'Labor Proposed' || status === 'Labor Approved' || status === 'Material Proposed' || status === 'Material Approved') {
      btnText = 'Job In Progress';
    } else if (status === 'Rejected') {
      btnText = 'Job Rejected';
    } else if (status === 'Site Visit Proposed' || status === 'Site Visit Approved' || status === 'Site Visit Rescheduled' || status === 'Site Visit Cancelled' || status === 'Site Visit None') {
      btnText = 'Site Visit';
    } else if (status === 'Schedule Job Proposed' || status === 'Schedule Job Approved' || status === 'Schedule Job Rescheduled' || status === 'Schedule Job Cancelled' || status === 'Schedule Job None') {
      btnText = 'Job Schedule';
    } else if (status === 'Bid Proposed' || status === 'Bid Approved' || status === 'Bid Rejected' || status === 'Bid Cancelled' || status === 'Bid None') {
      btnText = 'Bid';
    } else if (status === 'Change Order Proposed' || status === 'Change Order Approved' || status === 'Change Order Rejected' || status === 'Change Order Cancelled') {
      btnText = 'Change Order';
    } else if (status === 'Invoice Proposed' || status === 'Invoice Approved' || status === 'Invoice Rejected' || status === 'Invoice Cancelled') {
      btnText = 'Invoice';
    } else if (status === 'Completed') {
      btnText = 'Close Out';
    } else if (status === 'Completed By Tenant' || status === 'Completed By Vendor' || status === 'Completed By Engineer') {
      btnText = 'Completed';
    } else if (status === 'Incompleted' || status === 'Incompleted By Tenant' || status === 'Incompleted By Engineer' || status === 'Incompleted By Vendor') {
      btnText = 'In Progress';
    } else if (status === 'Cancelled') {
      btnText = 'Job Cancelled';
    } else if (status === 'To Do') {
      btnText = 'To Do';
    } else if (status === 'Closed') {
      btnText = 'Job Closed';
    }
    else {
      btnText = status;
    }
    // return btnText ? status : status;
    return btnText;
  }

  /**
   * Button Status Text
   * @param status
   */
  vendorActiveStage(status: any, step: any): any {
    let activeClass = {};
    if ((status === 'Site Visit Proposed' || status === 'Site Visit Approved' || status === 'Site Visit Rescheduled' || status === 'Site Visit Cancelled') && step === '1') {
      activeClass = { 'step-active': true };
    }
    else if ((status === 'Schedule Job Proposed' || status === 'Schedule Job Approved' || status === 'Schedule Job Rescheduled' || status === 'Schedule Job Cancelled') && step === '3') {
      activeClass = { 'step-active': true };
    }
    else if ((status === 'Bid Proposed' || status === 'Change Order Proposed' || status === 'Bid Rejected' || status === 'Change Order Rejected' || status === 'Bid Approved' || status === 'Change Order Approved') && step === '2') {
      activeClass = { 'step-active': true };
    }
    else if ((status === 'Invoice Proposed' || status === 'Invoice Approved' || status === 'Invoice Rejected') && step === '4') {
      activeClass = { 'step-active': true };
    }
    else if ((status === 'Completed' || status === 'Closed') && step === '5') {
      activeClass = { 'step-active': true };
    }
    return activeClass;
  }

  /**
   * Completed Vendor Stage
   * @param status
   */
  vendorCurrentStages(status: any) {

    let activeStep: any = '';

    if ((status == 'Site Visit Proposed' || status == 'Site Visit Approved' || status == 'Site Visit Rescheduled' || status == 'Site Visit Cancelled')) {
      activeStep = 1;
    }
    else if ((status == 'Bid Proposed' || status == 'Change Order Proposed' || status == 'Bid Rejected' || status == 'Change Order Rejected' || status == 'Bid Approved' || status == 'Change Order Approved')) {
      activeStep = 2;


    }
    else if ((status == 'Schedule Job Proposed' || status == 'Schedule Job Approved' || status == 'Schedule Job Rescheduled' || status == 'Schedule Job Cancelled')) {
      activeStep = 3;
    }

    else if ((status == 'Invoice Proposed' || status == 'Invoice Approved' || status == 'Invoice Rejected')) {
      activeStep = 4;

    }
    else if ((status == 'Completed' || status == 'Closed')) {
      activeStep = 5;

    }

    return Number(activeStep);
  }


  /**
  * Button Status Text
  * @param status
  */
  vendorNextStage(status: any, invoices?: any) {

    let btnText = "";

    if (status == 'Created' || status == 'Approved') {
      btnText = "N/A";
    }
    else if (status == 'Invited') {
      btnText = "Awaiting Vendor Response ";
    }
    else if (status == 'Accepted') {
      btnText = "Vendor Has Accepted this Job";
    }
    else if (status == 'Passed') {
      btnText = "Vendor Has Passed on this Job";
    }

    /*********** Site Visit *****************/

    else if (status == 'Site Visit Proposed') {
      btnText = "Review Site Visit Proposal";
    }
    else if (status == 'Site Visit Approved') {
      btnText = "Site Visit Scheduled";
    }
    else if (status == 'Site Visit Rescheduled') {
      btnText = "Site Visit Rescheduled";
    }
    else if (status == 'Site Visit Cancelled') {
      btnText = "Site Visit Cancelled";
    }

    /*********** Schedule Job *****************/

    else if (status == 'Schedule Job Proposed') {
      btnText = "Review Job Schedule Proposal";
    }
    else if (status == 'Schedule Job Approved') {
      btnText = "Job Scheduled";
    }
    else if (status == 'Schedule Job Rescheduled') {
      btnText = "Schedule Job Rescheduled";
    }
    else if (status == 'Schedule Job Cancelled') {
      btnText = "Schedule Job Cancelled";
    }

    /*********** Bid *****************/
    else if (status == 'Bid Proposed') {
      btnText = "Review Bid Proposal";
    }
    else if (status == 'Bid Approved') {
      btnText = "Schedule Job";
    }
    else if (status == 'Bid Rejected') {
      btnText = "Bid Rejected";
    }
    else if (status == 'Bid Cancelled') {
      btnText = "Bid Cancelled";
    }
    else if (status == 'Bid None') {
      btnText = "Awaiting Bid Proposal ";
    }

    /*********** Change Order *****************/
    else if (status == 'Change Order Proposed') {
      btnText = "Review Change Order Proposal";
    }
    else if (status == 'Change Order Approved') {
      btnText = "Schedule Job";
    }
    else if (status == 'Change Order Rejected') {
      btnText = "Change Order Rejected";
    }
    else if (status == 'Change Order Cancelled') {
      btnText = "Change Order Cancelled";
    }

    /*********** Invoice *****************/
    else if (status == 'Invoice Proposed') {
      btnText = "Review Invoice Proposal";
    }
    else if (status == 'Invoice Approved') {
      btnText = "Close Job";
    }
    else if (status == 'Invoice Rejected') {
      btnText = "Invoice Rejected";
    }
    else if (status == 'Invoice Cancelled') {
      btnText = "Invoice Cancelled";
    }
    else if (status == 'Completed' || status == 'Completed By Vendor' || status == 'Completed By Tenant') {

      if (!invoices.proposed && !invoices.approved)
        btnText = "Pending Invoice Submission";
      else if (invoices.proposed && !invoices.approved)
        btnText = "Review Invoice Proposal";
      else if (invoices.approved)
        btnText = "Close Job";
      else
        btnText = "Close Job";

    }
    else if (status == 'Incompleted') {
      btnText = "Job Incomplete";
    }
    else if (status == 'Cancelled') {
      btnText = "View Reason for Cancellation";
    }
    else if (status == 'Closed') {
      btnText = "";
    }



    return btnText;
  }

  isTenantRestriction(currentUserInfo: any) {

    if (currentUserInfo.category == 'Tenant') {

      if (this.selectedJobDetails.submitted_by) {

        // if ((this.selectedJobDetails.responsible == 'Manager' || this.selectedJobDetails.responsible == 'Both' || !currentUserInfo.can_schedule_own_service_dates)) {
        //   return true;
        // }

        if ((this.selectedJobDetails.responsible == 'Manager' || this.selectedJobDetails.responsible == 'Both' || !this._baseService.currentUserInfo?.permissions?.includes("add_schedule"))) {
          return true;
        }
      }

    }


    return false;

  }

  /**
   * To Get All Active jobs
   * @param isLoader
   * @param isPagination
   */
  listAllTileCounts() {


    let requestURL = environment.baseURL + this._Config.getDashboardTilesCount;


    this.doGET(requestURL).subscribe((response: any) => {


      this.allTilesCounts = response || {};


    },
      (error) => {
        // this._Config.isLoader = false;

      });



  }

  /**
  * To Get All Priority
  */
  getAllPriority() {


    let requestURL = environment.baseURL + this._Config.getAllPriority;


    this.doGET(requestURL).subscribe((response: any) => {

      this.PriorityTypesList = response || [];

    },
      (error) => {
        // this._Config.isLoader = false;

      });



  }


  /**
   * Check is Engineer
   */
  isEngineer() {

    let currentUser = localStorage.getItem('userobj') ? JSON.parse(localStorage.getItem('userobj')) : {};

    if (currentUser.current_Role === 'Engineer')
      return true;
    else
      return false;
  }



  /******************** Dashboard Lists ***************************/

  /**
   * To Get All Active jobs
   * @param isLoader
   * @param isPagination
   */
  listJobsBySlectedAction(isLoader?: any, isPagination?: any, isSearch?: any, isFilter?: any) {
    this._Config.isLoader = isLoader;

    let requestURL = "";

    if (isPagination) {

      if (this.dashboardAllJobsList.dashboardJobs.next) {
        requestURL = this.dashboardAllJobsList.dashboardJobs.next;
      }
      else {
        requestURL = this.setSelectedTabAPIURL();

      }

    }
    else {

      if (isSearch) {

        let active_tab = "";

        if (this.defaultActiveTab == 1)
          active_tab = "new";
        else if (this.defaultActiveTab == 2)
          active_tab = "unassigned";
        else if (this.defaultActiveTab == 3)
          active_tab = "assigned_to_me";
        else if (this.defaultActiveTab == 4)
          active_tab = "tenant_responsible";
        else if (this.defaultActiveTab == 5)
          active_tab = "open";

        requestURL = environment.baseURL + this._Config.searchJobs;
        requestURL = requestURL.replace('{active_tab}', active_tab);
        requestURL = requestURL.replace('{searchKeyword}', this.searchParams.searchKeyword ? this.searchParams.searchKeyword : '');
        requestURL = requestURL.replace('{timeline}', this.searchParams.timeline ? this.searchParams.timeline : '');

      }
      else
        requestURL = this.setSelectedTabAPIURL();

    }


    this.doGET(requestURL).subscribe((response: any) => {

      this._Config.isLoader = false;

      this.dashboardAllJobsList.dashboardJobs.next = response.next ? response.next : '';
      this.dashboardAllJobsList.dashboardJobs.count = response.count ? response.count : 0;

      let ListItems = response.results || [];


      if (ListItems.length > 0) {

        ListItems.forEach(element => {
          element.isExpendVendor = false;
        });

      }

      if (isPagination) {

        this.allDashboardJobs.push(...ListItems);

      }
      else {

        this.allDashboardJobs = ListItems;

      }




    },
      (error) => {
        this._Config.isLoader = false;

      });


    this.listAllTileCounts();

  }




  setSelectedTabAPIURL() {

    let requestURL = "";

    if (this.selectedActiveTile > 0) {

      if (this.selectedActiveTile == 1)
        requestURL = environment.baseURL + this._Config.getAllJobs_Tile1;
      else if (this.selectedActiveTile == 2)
        requestURL = environment.baseURL + this._Config.getAllJobs_Tile2;
      else if (this.selectedActiveTile == 3)
        requestURL = environment.baseURL + this._Config.getAllJobs_Tile3;
      else if (this.selectedActiveTile == 4) {
        this.defaultActiveTab = 4;
        requestURL = environment.baseURL + this._Config.getAllJobs_Tile4;
      }

    }
    else {

      this.searchParams;

      let active_tab = '';

      if (this.defaultActiveTab == 1)
        active_tab = "new";
      else if (this.defaultActiveTab == 2)
        active_tab = "unassigned";
      else if (this.defaultActiveTab == 3)
        active_tab = "assigned_to_me";
      else if (this.defaultActiveTab == 4)
        active_tab = "tenant_responsible";
      else if (this.defaultActiveTab == 5)
        active_tab = "open";


      requestURL = environment.baseURL + this._Config.getJobsByTab;
      requestURL = requestURL.replace('{active_tab}', active_tab);


      if (this.searchParams.isFilter) {

        let searchParams = "";

        if (!this.isEngineer()) {
          if (this.isPastJob) {
            requestURL = environment.baseURL + this._Config.getAllJobsList;
            requestURL = requestURL + '?filter=past'
          }
        }


        if (this.searchParams.isFilterQueryParams) {

          if (requestURL.includes('?'))
            searchParams = '&query=' + this.searchParams.isFilterQueryParams;
          else
            searchParams = '?query=' + this.searchParams.isFilterQueryParams;

        }

        requestURL = requestURL + searchParams;
      }
      if (this.searchParams.isSort) {
        let searchParams = '&column=' + this.searchParams.sortColumn + '&order=' + this.searchParams.sortBy;
        requestURL = requestURL + searchParams;
      }



    }


    return requestURL;
  }



  /**
   * Do View Job action in Dashboard
   */
  viewJobInfo(selectedJob: any, isMinimize: boolean) {
    this.isCheckList = false;

    this._uiService.jobInfoOpen = false;
    this.selectedJob_status = selectedJob.status;
    this.selectedJobDetails.isShowJobInfo = true;
    this.isDuplicateSection = true;
    this._uiService.currentActiveContent = 'job_info';
    this.getAllLinkedJobsList(selectedJob.id);
    this._uiService.showRightSideBar();
    if (isMinimize) {
      this.getSelectedJobInfo(selectedJob.jobId);
    } else {
      this.getSelectedJobInfo(selectedJob.id);
    }
  }

  /**
     * Do View Job action in Dashboard
     */
  viewJobInfoFromNotification(selectedJobID: any) {

    this.isCheckList = false;

    this._uiService.jobInfoOpen = false;
    this.selectedJobDetails.isShowJobInfo = true;

    this._uiService.currentActiveContent = 'job_info';
    this._uiService.showRightSideBar();
    this.getSelectedJobInfo(selectedJobID);

  }



  /**
   * Get Selected Job Info By Job ID
   * @param jobId
   */
  getSelectedJobInfo(jobId: any, isUpdateDashboard?: any, isUploaded?: boolean, isTabExpanded?: boolean) {

    this._Config.isLoader = true;

    this.JobActionTabs = isTabExpanded ? this.JobActionTabs : '';
    this.selectedJobDetails.isLoaded = false;

    let requestURL = environment.baseURL + this._Config.getSelectedJobDetails;
    requestURL = requestURL.replace("{ID}", jobId);

    if (this.isViewFromInbox) {
      requestURL = requestURL + '?source=inbox';
    }

    this.doGET(requestURL).subscribe((response: any) => {


      this._Config.isLoader = false;

      this.selectedJobDetails = {};

      this.selectedJob_status = response.status;

      this.setJobInfoNewUpdate(response);

      setTimeout(() => {
        this.selectedJobDetails.isLoaded = true;
      }, 1000);

    },
      (error) => {
        this._Config.isLoader = false;

      });



  }

  getSelectedJobInfoUpdate(jobId: any, isUpdateDashboard?: any, isTabExpanded?: boolean) {

    this._Config.isLoader = false;

    let requestURL = environment.baseURL + this._Config.getSelectedJobDetails;
    requestURL = requestURL.replace("{ID}", jobId);

    if (this.isViewFromInbox) {
      requestURL = requestURL + '?source=inbox';
    }

    this.doGET(requestURL).subscribe((response: any) => {

      this._Config.isLoader = false;

      this.updateJobinTable(response);

    },
      (error) => {
        this._Config.isLoader = false;

      });



  }


  setJobInfoNewUpdate(response: any) {

    this.selectedJobDetails = response || {};

    this.selectedJobDetails.isShowJobInfo = true;

    this.setAbstractforJob();

    // let billablePartyObject: any = {};

    // if (this.selectedJobDetails.abstract) {

    //   if (Array.isArray(this.selectedJobDetails.abstract.rules)) {

    //     this.selectedJobDetails.abstract.isRule = true;
    //     this.selectedJobDetails.abstract.responseList = this.selectedJobDetails.abstract.rules;

    //     if (this.selectedJobDetails.abstract.rules) {

    //       if (this.selectedJobDetails.abstract.rules.length > 0) {

    //         this.selectedJobDetails.abstract.rules.forEach(element => {

    //           billablePartyObject.condition = element.primaryData;

    //           let criterias: any[] = element.criterias || [];
    //           billablePartyObject = criterias.find(obj => obj.type == 'Primary');

    //         });


    //         this.selectedJobDetails.abstract_details = billablePartyObject;

    //       }


    //     }


    //   }
    //   else {

    //     this.selectedJobDetails.abstract.isRule = false;

    //   }

    // }
    // else {

    //   this.selectedJobDetails.abstract = {
    //     "rules": {
    //       "manager": 100,
    //       "tenant": 0
    //     },
    //     "notes": ""
    //   };


    //   if (this.selectedJobDetails.request_type == "Common Area") {

    //     this.selectedJobDetails.abstract = {
    //       "rules": {
    //         "manager": 100,
    //         "tenant": 0
    //       },
    //       "notes": ""
    //     };

    //   }


    // }

    if (this.selectedJobDetails.vendors) {
      if (this.selectedJobDetails.vendors.length > 0) {
        this.selectedJobDetails.vendors.forEach(element => {
          element.vendor_id = element.id;
        });
      }
    }


    this.selectedJobDetails.jobTabsActionsCount = {
      scheduling: 0,
      job_cost: 0,
      complet_close: 0
    };

    this.selectedJobDetails.jobTabsActionsCount.scheduling = Number(this.selectedJobDetails.proposals.site_visits + this.selectedJobDetails.proposals.schedule_jobs);
    this.selectedJobDetails.jobTabsActionsCount.JobCost = Number(this.selectedJobDetails.proposals.bids + this.selectedJobDetails.proposals.invoices + this.selectedJobDetails.proposals.change_orders);

    this.selectedJobDetails.temp_priority = this.selectedJobDetails.priority;
    this.selectedJobDetails.temp_stage = this.selectedJobDetails.stage ? this.selectedJobDetails.stage.id : null;


    if (this.selectedJobDetails.assignee)
      this.selectedJobDetails.editAssignnee = this.selectedJobDetails.assignee.id ? this.selectedJobDetails.assignee.id : 'Unassigned';

    if (this.selectedJobDetails.engineers) {

      this.selectedJobDetails.editEngineer = null;

      if (this.selectedJobDetails.engineers.length > 0) {
        // this.selectedJobDetails.temp_Engineers = this.selectedJobDetails.engineers;
        this.selectedJobDetails.editEngineer = this.selectedJobDetails.engineers.map(obj => obj.id);
      }

      this.selectedJobDetails.selectedEngneers = this.selectedJobDetails.engineers ? this.selectedJobDetails.engineers : [];
    }

    if (this.selectedJobDetails.followers) {
      this.selectedJobDetails.editFollower = null;
      if (this.selectedJobDetails.followers.length > 0) {
        this.selectedJobDetails.editFollower = this.selectedJobDetails.followers.map(obj => obj.id);
      }
      this.selectedJobDetails.selectedFollowers = this.selectedJobDetails.followers ? this.selectedJobDetails.followers : [];
    }

    this.selectedJobDetails.editObj = {
      target_date: this.selectedJobDetails.target_date ? new Date(this.selectedJobDetails.target_date) : new Date()
    };

    if (this.selectedJobDetails.status == 'Created') {

      this.job_AllCategoriesDropdownLists = [];

      this.selectedJobDetails.editObj = {
        request_type: this.selectedJobDetails.request_type,
        target_date: new Date(this.selectedJobDetails.target_date),
        description: this.selectedJobDetails.description,
        access_instruction: this.selectedJobDetails.access_instruction,
        category: this.selectedJobDetails.category.id,
        service_type: this.selectedJobDetails.service_type ? this.selectedJobDetails.service_type.id : null,
      };


      if (this.selectedJobDetails.request_type == 'In Unit') {

        let unitID = this.selectedJobDetails.unit ? this.selectedJobDetails.unit.id : null;
        this.selectedJobDetails.editObj.Location = unitID;

        this.getAllLocationDropdown(this.selectedJobDetails.property.id);
        this.getAllCategoriesDropdown(this.selectedJobDetails.property.id, unitID);
      }
      else {
        this.selectedJobDetails.editObj.Cam_Location = this.selectedJobDetails.location;
        this.getAllAddressesDropdown(this.selectedJobDetails.property.id);
        this.getAllCategoriesDropdown(this.selectedJobDetails.property.id);
      }

      this.getAllJobVendorsByProperty()


    }

    // this.viewJobInfo(this.selectedJobDetails);

    this.getJobPhotosByJobID();
    this.getAllStagesbyJOB();

    if (this.currentUserInfo.current_Role != 'Tenant') {
      this.getAllJobEmails();

      if (this.selectedJobDetails.property) {
        this.getAllAssigneesList(this.selectedJobDetails.property.id);
        this.getAllEnginnersList(this.selectedJobDetails.property.id);
        this.getAllFollowersList(this.selectedJobDetails.property.id);
      }
    }

  }


  setJobInfoNewUpdateNew(response: any) {

    this.selectedJobDetails = response || {};

    this.selectedJobDetails.isShowJobInfo = true;

    this.setAbstractforJob();

    // let billablePartyObject: any = {};

    // if (this.selectedJobDetails.abstract) {

    //   if (Array.isArray(this.selectedJobDetails.abstract.rules)) {

    //     this.selectedJobDetails.abstract.isRule = true;
    //     this.selectedJobDetails.abstract.responseList = this.selectedJobDetails.abstract.rules;

    //     if (this.selectedJobDetails.abstract.rules) {

    //       if (this.selectedJobDetails.abstract.rules.length > 0) {

    //         this.selectedJobDetails.abstract.rules.forEach(element => {

    //           billablePartyObject.condition = element.primaryData;

    //           let criterias: any[] = element.criterias || [];
    //           billablePartyObject = criterias.find(obj => obj.type == 'Primary');

    //         });


    //         this.selectedJobDetails.abstract_details = billablePartyObject;

    //       }


    //     }


    //   }
    //   else {

    //     this.selectedJobDetails.abstract.isRule = false;

    //   }

    // }
    // else {

    //   this.selectedJobDetails.abstract = {
    //     "rules": {
    //       "manager": 100,
    //       "tenant": 0
    //     },
    //     "notes": ""
    //   };


    //   if (this.selectedJobDetails.request_type == "Common Area") {

    //     this.selectedJobDetails.abstract = {
    //       "rules": {
    //         "manager": 100,
    //         "tenant": 0
    //       },
    //       "notes": ""
    //     };

    //   }


    // }

    if (this.selectedJobDetails.vendors.length > 0) {
      this.selectedJobDetails.vendors.forEach(element => {
        element.vendor_id = element.id;
      });
    }

    this.selectedJobDetails.jobTabsActionsCount = {
      scheduling: 0,
      job_cost: 0,
      complet_close: 0
    };

    this.selectedJobDetails.jobTabsActionsCount.scheduling = Number(this.selectedJobDetails.proposals.site_visits + this.selectedJobDetails.proposals.schedule_jobs);
    this.selectedJobDetails.jobTabsActionsCount.JobCost = Number(this.selectedJobDetails.proposals.bids + this.selectedJobDetails.proposals.invoices + this.selectedJobDetails.proposals.change_orders);

    this.selectedJobDetails.temp_priority = this.selectedJobDetails.priority;
    this.selectedJobDetails.temp_stage = this.selectedJobDetails.stage.id;

    if (this.selectedJobDetails.assignee)
      this.selectedJobDetails.editAssignnee = this.selectedJobDetails.assignee.id ? this.selectedJobDetails.assignee.id : 'Unassigned';

    if (this.selectedJobDetails.engineers) {

      this.selectedJobDetails.editEngineer = null;

      if (this.selectedJobDetails.engineers.length > 0) {
        // this.selectedJobDetails.temp_Engineers = this.selectedJobDetails.engineers;
        this.selectedJobDetails.editEngineer = this.selectedJobDetails.engineers.map(obj => obj.id);
      }

      this.selectedJobDetails.selectedEngneers = this.selectedJobDetails.engineers ? this.selectedJobDetails.engineers : [];
    }

    if (this.selectedJobDetails.followers) {
      this.selectedJobDetails.editFollower = null;
      if (this.selectedJobDetails.followers.length > 0) {
        this.selectedJobDetails.editFollower = this.selectedJobDetails.followers.map(obj => obj.id);
      }
      this.selectedJobDetails.selectedFollowers = this.selectedJobDetails.followers ? this.selectedJobDetails.followers : [];
    }

    this.selectedJobDetails.editObj = {
      target_date: this.selectedJobDetails.target_date ? new Date(this.selectedJobDetails.target_date) : new Date()
    };


  }


  setAbstractforJob() {

    let billablePartyObject: any = {};

    if (this.selectedJobDetails.abstract) {

      if (Array.isArray(this.selectedJobDetails.abstract.rules)) {

        this.selectedJobDetails.abstract.isRule = true;
        this.selectedJobDetails.abstract.responseList = this.selectedJobDetails.abstract.rules;

        if (this.selectedJobDetails.abstract.rules) {

          if (this.selectedJobDetails.abstract.rules.length > 0) {

            this.selectedJobDetails.abstract.rules.forEach(element => {

              billablePartyObject.condition = element.primaryData;

              let criterias: any[] = element.criterias || [];
              billablePartyObject = criterias.find(obj => obj.type == 'Primary');

            });


            this.selectedJobDetails.abstract_details = billablePartyObject;

          }


        }


      }
      else {

        this.selectedJobDetails.abstract.isRule = false;

      }

    }
    else {

      this.selectedJobDetails.abstract = {
        "rules": {
          "manager": 100,
          "tenant": 0
        },
        "notes": ""
      };


      if (this.selectedJobDetails.request_type == "Common Area") {

        this.selectedJobDetails.abstract = {
          "rules": {
            "manager": 100,
            "tenant": 0
          },
          "notes": ""
        };

      }


    }

  }

  /**
   * Check is Role is Exist or Not
   * @param Types
   * @param Role
   * @returns
   */
  isRoleExist(Types: any[], Role: any) {
    return Types?.includes(Role);
  }

  selectEngineerForMaterial() {

    let laborObj = {
      "date": new Date(),
      "_from": '',
      "to": '',
      "hours": 0,
      "trip_charge": false,
      "overtime_rate": false
    };

    let materialObj = {
      "description": '',
      "quantity": '',
      "unit": 'Unit',
      "cost": '',
      "total": '',
      "documents": []
    };

    this.currentUserInfo = localStorage.getItem('userobj') ? JSON.parse(localStorage.getItem('userobj')) : {};

    let engineerObj = {
      full_name: this.currentUserInfo.first_name + ' ' + this.currentUserInfo.last_name,
      email: this.currentUserInfo.email,
      current_Role: this.currentUserInfo.current_Role,
      id: this.currentUserInfo.id,
    }

    this.selectedJobDetails.selectedEngneersForMaterial = engineerObj || {};
    this.selectedJobDetails.selectedEngneersForMaterial.labors = [laborObj];
    this.selectedJobDetails.selectedEngneersForMaterial.material = [materialObj];


  }


  /******************** Dashboard Lists***************************/

  setPastJobAPIURL() {

    let requestURL = "";
    this.searchParams;

    let active_tab = 'past';

    requestURL = environment.baseURL + this._Config.getAllJobsList;
    // requestURL = requestURL.replace('{active_tab}', active_tab);


    if (this.searchParams.isFilter) {

      let searchParams = "";

      if (!this.isEngineer()) {
        if (this.isPastJob) {
          requestURL = environment.baseURL + this._Config.getAllJobsList;
          // requestURL = requestURL + '?filter=past'
        }
      }


      if (this.searchParams.isFilterQueryParams) {

        if (requestURL.includes('?'))
          searchParams = '&query=' + this.searchParams.isFilterQueryParams;
        else
          searchParams = '?query=' + this.searchParams.isFilterQueryParams;

      }

      requestURL = requestURL + searchParams;
    }
    if (this.searchParams.isSort) {

      let searchParams = "";

      if (requestURL.includes('?'))
        searchParams = '&column=' + this.searchParams.sortColumn + '&order=' + this.searchParams.sortBy;
      else
        searchParams = '?column=' + this.searchParams.sortColumn + '&order=' + this.searchParams.sortBy;

      requestURL = requestURL + searchParams;
    }



    return requestURL;
  }

  /**
   * To Get All Past jobs
   * @param isLoader
   * @param isPagination
   */
  listAllPastJobs(isLoader?: any, isSearch?: any, isFilter?: any) {
    this._Config.isLoader_All_Jobs = isLoader;

    let requestURL = "";

    if (isSearch) {

      let active_tab = "past";
      requestURL = environment.baseURL + this._Config.getAllJobsList + '?search={searchKeyword}';
      requestURL = requestURL.replace('{searchKeyword}', this.searchParams.searchKeyword ? this.searchParams.searchKeyword : '');
      // requestURL = requestURL.replace('{timeline}', this.searchParams.timeline ? this.searchParams.timeline : '');

      if (this.searchParams.isSort) {
        let searchParams = "";
        if (requestURL.includes('?'))
          searchParams = '&column=' + this.searchParams.sortColumn + '&order=' + this.searchParams.sortBy;
        else
          searchParams = '?column=' + this.searchParams.sortColumn + '&order=' + this.searchParams.sortBy;

        requestURL = requestURL + searchParams;
      }

      if (this.dashboardAllJobsList.current_page) {
        if (requestURL.includes('?')) {
          requestURL += '&page=' + this.dashboardAllJobsList.current_page;
        } else {
          requestURL = requestURL + '?page=' + this.dashboardAllJobsList.current_page;
        }
      }

    }
    else {
      requestURL = environment.baseURL + this._Config.getAllJobsList;
      if (this.searchParams.isSort) {
        let searchParams = "";

        if (requestURL.includes('?'))
          searchParams = '&column=' + this.searchParams.sortColumn + '&order=' + this.searchParams.sortBy;
        else
          searchParams = '?column=' + this.searchParams.sortColumn + '&order=' + this.searchParams.sortBy;

        requestURL = requestURL + searchParams;

      }
      if (isFilter == true) {
        requestURL = this.setPastJobAPIURL();
      }

    }


    if (this.dashboardAllJobsList.is_pagination) {
      if (requestURL.includes('?')) {
        requestURL += '&page=' + this.dashboardAllJobsList.current_page;
      } else {
        requestURL = requestURL + '?page=' + this.dashboardAllJobsList.current_page;
      }
    }

    this.dashboardAllJobsList.allPastJobs = [];

    this.doGET(requestURL)
      .subscribe({
        next: (response: any) => {
          this.dashboardAllJobsList.is_Enter_Search = false;

          this.dashboardAllJobsList.count = response.count ? response.count : 0;


          let ListItems = response.results || [];
          if (ListItems.length > 0) {
            ListItems.forEach(element => {
              element.isExpendVendor = false;
            });
          }
          this.dashboardAllJobsList.allPastJobs = ListItems;
        },
        error: (error) => {
          this._Config.isLoader_All_Jobs = false;
        },
        complete: () => {
          this._Config.isLoader_All_Jobs = false;
        }
      });


  }


  /**
* Get All Job Vendors
*/
  getAllJobVendorsByProperty() {

    this._Config.isLoader = false;

    let requestURL = environment.baseURL + this._Config.getAllJobVendorsByProperty;
    requestURL = requestURL.replace("{Property_ID}", this.selectedJobDetails.property.id);
    requestURL = requestURL.replace("{category-id}", this.selectedJobDetails.category.id);

    if (this.isViewFromInbox) {

      if (requestURL.includes('?'))
        requestURL = requestURL + '&source=inbox';
      else
        requestURL = requestURL + '?source=inbox';

    }

    this.doGET(requestURL).subscribe((response: any) => {

      this._Config.isLoader = false;

      this.AllJobVendorsDropdownList = response || [];

    },
      (error) => {
        this._Config.isLoader = false;

      });



  }



  /**
* Get All Job Vendors
*/
  getAllJobDropdownVendors() {

    this._Config.isLoader = false;

    let requestURL = environment.baseURL + this._Config.getAllJobDropdownVendors;
    requestURL = requestURL.replace("{ID}", this.selectedJobDetails.id);

    this.doGET(requestURL).subscribe((response: any) => {

      this._Config.isLoader = false;

      this.AllJobVendorsDropdownList = response || [];

    },
      (error) => {
        this._Config.isLoader = false;

      });



  }


  /**
 * Get All Job Vendors
 */
  getAllJobVendorsByJobID(JobItem: any, Index: any, isPastJob?: any) {



    let isLoadAPI: boolean = false;

    if (isPastJob) {
      this.dashboardAllJobsList.allPastJobs.forEach((element, indexVal) => {

        if (indexVal === Index)
          element.isExpendVendor = element.isExpendVendor ? true : false;
        else
          element.isExpendVendor = false;


        if (element.isExpendVendor) {
          element.isLoading = true;
          isLoadAPI = true;
        }

      });

      // this.dashboardAllJobsList.allPastJobs[Index].isLoading = true;

    }
    else {

      this.allDashboardJobs.forEach((element, indexVal) => {

        if (indexVal === Index)
          element.isExpendVendor = element.isExpendVendor ? true : false;
        else
          element.isExpendVendor = false;

        if (element.isExpendVendor) {
          element.isLoading = true;
          isLoadAPI = true;
        }

      });

      // this.allDashboardJobs[Index].isLoading = true;

    }



    if (isLoadAPI) {

      let requestURL = environment.baseURL + this._Config.getAllJobVendors;
      requestURL = requestURL.replace("{ID}", JobItem.id);

      this.doGET(requestURL).subscribe((response: any) => {

        let allJobVendors = response || [];


        if (isPastJob) {
          this.dashboardAllJobsList.allPastJobs[Index].isExpendVendor = this.dashboardAllJobsList.allPastJobs[Index].isExpendVendor ? true : false;
          this.dashboardAllJobsList.allPastJobs[Index].isLoading = false;
          this.dashboardAllJobsList.allPastJobs[Index].vendorsList = allJobVendors;
        }
        else {
          this.allDashboardJobs[Index].isExpendVendor = this.allDashboardJobs[Index].isExpendVendor ? true : false;
          this.allDashboardJobs[Index].isLoading = false;
          this.allDashboardJobs[Index].vendorsList = allJobVendors;
        }


      },
        (error) => { });

    }






  }



  /**
 * Get All Job Vendors
 */
  getAllJobVendors() {

    let requestURL = environment.baseURL + this._Config.getAllJobScheduleVendors;
    requestURL = requestURL.replace("{ID}", this.selectedJobDetails.id);


    this.doGET(requestURL).subscribe((response: any) => {

      this.AllJobVendorsList = response || [];


      this.AllJobVendorsList.forEach((element: any) => {

        if (element.type == "Engineer")
          element.company = element.full_name;

        if (element.site_visits.approved.length > 0) {

          element.site_visits.approved.forEach(MainItem => {

            MainItem.forEach(item => {

              item.user_type = element.type ? element.type : 'Vendor';
              item.phone = element.phone;
              item.stage = element.stage;

              if (element.type == "Engineer") {
                item.company = element.full_name;
              }
              else {
                item.company = element.company;
                item.full_name = element.full_name;
              }

              item.checked = false;
              item.vendor_id = element.id;
              item.type = 'Site Visit';

              let fromDate = item._from.split(':');
              let toDate = item.to.split(':');

              let from_split = new Date().setHours(fromDate[0], fromDate[1], fromDate[2]);
              let to_split = new Date().setHours(toDate[0], toDate[1], toDate[2]);

              item.date = new Date(item.date + ' ' + item._from);
              item._from = new Date(from_split);
              item.to = new Date(to_split);



            });


          });

        }

        if (element.site_visits.non_approved.length > 0) {

          element.site_visits.non_approved.forEach(MainItem => {

            MainItem.forEach(item => {

              item.user_type = element.type ? element.type : 'Vendor';
              item.phone = element.phone;
              if (element.type == "Engineer") {
                item.company = element.full_name;
              }
              else {
                item.company = element.company;
                item.full_name = element.full_name;
              }

              item.checked = false;
              item.vendor_id = element.id;
              item.type = 'Site Visit';

              let fromDate = item._from.split(':');
              let toDate = item.to.split(':');

              let from_split = new Date().setHours(fromDate[0], fromDate[1], fromDate[2]);
              let to_split = new Date().setHours(toDate[0], toDate[1], toDate[2]);

              item.date = new Date(item.date + ' ' + item._from);
              item._from = new Date(from_split);
              item.to = new Date(to_split);



            });

          });

        }


        if (element.schedule_jobs.approved.length > 0) {

          element.schedule_jobs.approved.forEach(MainItem => {

            MainItem.forEach(item => {

              item.user_type = element.type ? element.type : 'Vendor';
              item.phone = element.phone;
              if (element.type == "Engineer") {
                item.company = element.full_name;
              }
              else {
                item.company = element.company;
                item.full_name = element.full_name;
              }

              item.checked = false;
              item.vendor_id = element.id;
              item.type = 'Schedule Job';

              let fromDate = item._from.split(':');
              let toDate = item.to.split(':');

              let from_split = new Date().setHours(fromDate[0], fromDate[1], fromDate[2]);
              let to_split = new Date().setHours(toDate[0], toDate[1], toDate[2]);

              item.date = new Date(item.date + ' ' + item._from);
              item._from = new Date(from_split);
              item.to = new Date(to_split);



            });

          });


        }

        if (element.schedule_jobs.non_approved.length > 0) {

          element.schedule_jobs.non_approved.forEach(MainItem => {

            MainItem.forEach(item => {

              item.user_type = element.type ? element.type : 'Vendor';
              item.phone = element.phone;
              if (element.type == "Engineer") {
                item.company = element.full_name;
              }
              else {
                item.company = element.company;
                item.full_name = element.full_name;
              }

              item.checked = false;
              item.vendor_id = element.id;
              item.type = 'Schedule Job';

              let fromDate = item._from.split(':');
              let toDate = item.to.split(':');

              let from_split = new Date().setHours(fromDate[0], fromDate[1], fromDate[2]);
              let to_split = new Date().setHours(toDate[0], toDate[1], toDate[2]);

              item.date = new Date(item.date + ' ' + item._from);
              item._from = new Date(from_split);
              item.to = new Date(to_split);



            });

          });


        }


        /*********************** Site Visit ***********************/
        element.site_visits.Proposed = this.filterItemsListByType(element.site_visits.non_approved, 'Proposed');
        element.site_visits.Rescheduled = this.filterItemsListByType(element.site_visits.non_approved, 'Rescheduled');
        element.site_visits.Approved = this.filterItemsListByType(element.site_visits.approved, 'Approved');
        element.site_visits.Cancelled = this.filterItemsListByType(element.site_visits.non_approved, 'Cancelled');

        /*********************** Schedule Job ***********************/
        element.schedule_jobs.Proposed = this.filterItemsListByType(element.schedule_jobs.non_approved, 'Proposed');
        element.schedule_jobs.Rescheduled = this.filterItemsListByType(element.schedule_jobs.non_approved, 'Rescheduled');
        element.schedule_jobs.Approved = this.filterItemsListByType(element.schedule_jobs.approved, 'Approved');
        element.schedule_jobs.Cancelled = this.filterItemsListByType(element.schedule_jobs.non_approved, 'Cancelled');


        /************ New Job Info Code *************/
        element.schedules_list_new = [];
        element.schedules_list_new.push(...(element.site_visits.Proposed.length > 0) ? element.site_visits.Proposed[0] : []);
        element.schedules_list_new.push(...(element.site_visits.Rescheduled.length > 0) ? element.site_visits.Rescheduled[0] : []);
        element.schedules_list_new.push(...(element.site_visits.Approved.length > 0) ? element.site_visits.Approved[0] : []);
        element.schedules_list_new.push(...(element.site_visits.Cancelled.length > 0) ? element.site_visits.Cancelled[0] : []);
        element.schedules_list_new.push(...(element.schedule_jobs.Proposed.length > 0) ? element.schedule_jobs.Proposed[0] : []);
        element.schedules_list_new.push(...(element.schedule_jobs.Rescheduled.length > 0) ? element.schedule_jobs.Rescheduled[0] : []);
        element.schedules_list_new.push(...(element.schedule_jobs.Approved.length > 0) ? element.schedule_jobs.Approved[0] : []);
        element.schedules_list_new.push(...(element.schedule_jobs.Cancelled.length > 0) ? element.schedule_jobs.Cancelled[0] : []);
        /************ New Job Info Code *************/


      });


      this.scheduleVendors_Engineer_Dropdown = this.AllJobVendorsList;

      /************ New Job Info Code to combine all in single list *************/
      this.all_ScheduleJob_Lists = [];

      this.AllJobVendorsList.forEach(element => {

        if (element.schedules_list_new.length > 0)
          this.all_ScheduleJob_Lists.push(...element.schedules_list_new);

      });


    },
      (error) => { });



  }


  filterItemsListByType(allArrayList: any[], status: any) {
    let filteredItems = allArrayList.filter((element) => element.some((subElement) => subElement.status === status)) || [];
    return filteredItems;
  }



  /**
* Get All Job Vendors
*/
  getJobCostByJobID(isNewJobInfo?: boolean) {
    this.bidsToCompare = []
    this.invoicesToCompare = []

    this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.getGobCostByJobID;
    requestURL = requestURL.replace("{ID}", this.selectedJobDetails.id);

    if (isNewJobInfo)
      requestURL = requestURL + '?view=full_page';


    this.doGET(requestURL).subscribe((response: any) => {

      this._Config.isLoader = false;

      this.allJobCosts = response || {};

      this.updateJobCostsToCompare();

      if (isNewJobInfo) {
        this.allBidsList = response || [];
      }
      else {
        this.allJobCosts.bid_approval_limit = this.allJobCosts.bid_approval_limit ? this.allJobCosts.bid_approval_limit : 0;

        this.allJobCosts.allBidComparelist = [];

        this.allBidsList = [];

        if (this.allJobCosts.costs) {

          this.allJobCosts.costs.forEach(element => {

            element.bids.forEach(item => {
              item.type = "Bid";
              item.vendor_id = element.vendor_id;
              item.company = element.company;
              item.vendor_name = element.name;
              item.vendor_photo = element.photo;
              item.email = element.email;
              item.phone = element.phone;

              this.allJobCosts.allBidComparelist.push(item);


            });

            element.change_orders.forEach(item => {
              item.type = "Change Order";
              item.vendor_id = element.vendor_id;
              item.company = element.company;
              item.vendor_name = element.name;
              item.vendor_photo = element.photo;
              item.email = element.email;
              item.phone = element.phone;

              this.allJobCosts.allBidComparelist.push(item);


            });

            element.invoices.forEach(item => {
              item.type = "Invoice";
              item.vendor_id = element.vendor_id;
              item.company = element.company;
              item.vendor_name = element.name;
              item.vendor_photo = element.photo;
              item.email = element.email;
              item.phone = element.phone;

              this.allJobCosts.allBidComparelist.push(item);

            });



            element.allBids = [];
            element.allBids.push(...element.bids);
            element.allBids.push(...element.change_orders);
            element.allBids.push(...element.invoices);

            element.allBids = element.allBids.sort((a, b) => (new Date(a.status_changed_on) > new Date(b.status_changed_on) ? -1 : 1));

            this.allBidsList.push(...element.allBids);

            this.awaitingBidProposal = element.bids.filter(obj => (obj.status == 'Proposed' && obj.status != 'Cancelled' && obj.status != 'Approved')) || [];

            this.awaitingInvoiceProposal = element.change_orders.filter(obj => (obj.status == 'Proposed' && obj.status != 'Cancelled' && obj.status != 'Approved')) || [];

            this.awaitingChangeOrderProposal = element.invoices.filter(obj => (obj.status == 'Proposed' && obj.status != 'Cancelled' && obj.status != 'Approved')) || [];




          });

        }



        if (this.allJobCosts.has_approved_bid && this.isQuickViewAction) {

          if (this.allBidsList.length > 0) {

            let selectedVendor: any = this.allBidsList.find(obj => obj.status == "Approved");



            this.selectedVendor = selectedVendor || {};

            if (selectedVendor.vendor_id) {
              this.selectedVendorsItems = this.selectedVendor.vendor_id;
            }

            // this.selectedVendor.type = "Bid";
            this.selectedVendor.identifier = "Repair";
            this.selectedVendor.total = "";
            this.selectedVendor.hours = "";
            this.selectedVendor.material_cost = "";
            this.selectedVendor.isAlready_Approved = true;



            if (this.isQuickViewAction == 'Bid')
              this.selectedVendor.type = "Bid";
            else if (this.isQuickViewAction == 'Change Order')
              this.selectedVendor.type = "Change Order";
            else if (this.isQuickViewAction == 'Invoice')
              this.selectedVendor.type = "Invoice";
            else
              this.selectedVendor.type = "Bid";



          }


        }
        else {
          this.isQuickViewAction = "";
          this.selectedVendor = {};
          this.selectedVendorsItems = null;
        }
      }




    },
      (error) => {
        this._Config.isLoader = false;

      });



  }

  updateJobCostsToCompare(): void {
    if (!Array.isArray(this.allJobCosts)) {
      return;
    }

    this.allJobCosts
      .filter(({ status }) => status !== JobCostStatus.REJECTED)
      .forEach((jobCost: JobCost) => {
        const cost = {
          type: jobCost.type,
          company: jobCost.company,
          vendor_name: jobCost.name,
          vendor_photo: jobCost.photo,
          email: jobCost.email,
          phone: jobCost.phone,
        }

        switch (jobCost.type) {
          case JobCostType.BID:
          case JobCostType.CHANGE_ORDER:
            this.bidsToCompare.push(cost)
            break
          case JobCostType.INVOICE:
            this.invoicesToCompare.push(cost)
            break
          default:
            break
        }
      });
  }


  /**
* Get All Job Vendors
*/
  compareJobCostByJobID(jobCostType?) {
    this.allJobCostsListToCompare = [];
    this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.compareGobCostByJobID;
    requestURL = requestURL.replace("{ID}", this.selectedJobDetails.id);

    this.doGET(requestURL, { params: { type: jobCostType?.toLowerCase() } }).subscribe((response: any) => {

      this._Config.isLoader = false;

      this.allJobCostsListToCompare = response || [];


      if (this.allJobCostsListToCompare.length > 0) {

        this.allJobCostsListToCompare.forEach(element => {

          let costItem: any = element.cost;
          costItem.company = element.company;
          costItem.email = element.email;
          costItem.phone = element.phone;
          costItem.vendor_id = element.vendor_id;


          element = costItem;
        });
      }


    },
      (error) => {
        this._Config.isLoader = false;

      });



  }




  /**
  * Get All Job Timeline
  */
  jobCostTimeline() {

    // this._Config.isLoader = true;
    this.isPhotoLoaded = false;


    let requestURL = environment.baseURL + this._Config.getTimelineToJob;
    requestURL = requestURL.replace("{ID}", this.selectedJobDetails.id);

    this.doGET(requestURL).subscribe((response: any) => {

      // this._Config.isLoader = false;

      this.isPhotoLoaded = true;

      this.allJobTimeline = response || {};


      if (this.allJobTimeline.site_visit) {

        let item = this.allJobTimeline.site_visit;

        if (item.from) {
          let fromDate = item.from.split(':');
          let toDate = item.to.split(':');

          let from_split = new Date().setHours(fromDate[0], fromDate[1], fromDate[2]);
          let to_split = new Date().setHours(toDate[0], toDate[1], toDate[2]);

          item.date = new Date(item.date + ' ' + item.from);
          item._from = new Date(from_split);
          item.to = new Date(to_split);

          this.allJobTimeline.site_visit = item;
        }


      }

      if (this.allJobTimeline.schedule) {

        let item = this.allJobTimeline.schedule;

        if (item.from) {
          let fromDate = item.from.split(':');
          let toDate = item.to.split(':');

          let from_split = new Date().setHours(fromDate[0], fromDate[1], fromDate[2]);
          let to_split = new Date().setHours(toDate[0], toDate[1], toDate[2]);

          item.date = new Date(item.date + ' ' + item.from);
          item._from = new Date(from_split);
          item.to = new Date(to_split);

          this.allJobTimeline.schedule = item;
        }


      }

      if (this.allJobTimeline.complete) {

        let item = this.allJobTimeline.complete;

        if (item.from) {
          let fromDate = item.from.split(':');
          let toDate = item.to.split(':');

          let from_split = new Date().setHours(fromDate[0], fromDate[1], fromDate[2]);
          let to_split = new Date().setHours(toDate[0], toDate[1], toDate[2]);

          item.date = new Date(item.date + ' ' + item.from);
          item._from = new Date(from_split);
          item.to = new Date(to_split);

          this.allJobTimeline.complete = item;

        }

      }


    },
      (error) => {
        this._Config.isLoader = false;
        this.isPhotoLoaded = false;

      });



  }


  /**
 * Get All Job Timeline
 */
  jobSummary_Complete_and_Close() {

    // this._Config.isLoader = true;
    this.isPhotoLoaded = false;


    let requestURL = environment.baseURL + this._Config.getSummaryOfJob;
    requestURL = requestURL.replace("{ID}", this.selectedJobDetails.id);

    this.doGET(requestURL).subscribe((response: any) => {

      // this._Config.isLoader = false;

      this.complete_and_close_Data = response || {};


      if (this.complete_and_close_Data.approved_vendor) {


        if (this.complete_and_close_Data.approved_vendor.invoice.length > 0) {

          this.complete_and_close_Data.approved_invoices = this.complete_and_close_Data.approved_vendor.invoice || [];

          this.complete_and_close_Data.approved_invoices.forEach(element => {

            if (element.proportion) {

              element.management_cost = element.proportion.management.cost;
              element.management_percentage = Math.round(Number(element.proportion.management.percentage));

              element.tenant_cost = element.proportion.tenant.cost;
              element.tenant_percentage = Math.round(Number(element.proportion.tenant.percentage));

            }

            this.complete_and_close_Data.approved_amount = element.total;


          });

        }
        // else{
        //   this.selectedVendor=this.complete_and_close_Data.approved_vendor || {};
        // }

      }

    },
      (error) => {
        this._Config.isLoader = false;
        this.isPhotoLoaded = false;

      });



  }


  /**
* Get Big Picture Data
*/
  getJobPicPictureDataByJobID() {

    this._Config.isLoader = false;
    this._Config.isLoadedPhotos = false;

    let requestURL = environment.baseURL + this._Config.getJobBigPictureDataByJobID;
    requestURL = requestURL.replace("{ID}", this.selectedJobDetails.id);

    this.doGET(requestURL).subscribe((response: any) => {

      let GL_Code_List = [];

      if (response.gl.length > 0) {

        response.gl.forEach(element => {

          if (element.account_name) {

            let newObj = {
              gl_code: element.gl_code,
              account_name: element.account_name,
              budget: element.budget,
              actual: element.actual
            };

            GL_Code_List.push(newObj);

          }



        });
      }


      // let gl_data = Object.entries(response.gl);

      // gl_data.forEach((element: any, index) => {
      //

      //   if (element.length > 1) {

      //     if (element[1].account_name) {

      //       let newObj = {
      //         gl_code: element[0],
      //         account_name: element[1].account_name,
      //         budget: element[1].budget,
      //         actual: element[1].actual
      //       };

      //       GL_Code_List.push(newObj);

      //     }

      //   }

      // });

      response.gl_data = GL_Code_List;

      this.complete_and_close_Data.big_picture_data = response || {}



      this._Config.isLoader = false;


    },
      (error) => {
        this._Config.isLoader = false;
        this._Config.isLoadedPhotos = true;

      });



  }

  /**
* Get Big Picture Data
*/
  getBudgetDetailsByJobID() {

    this._Config.isLoader = false;
    this._Config.isLoadedPhotos = false;

    let requestURL = environment.baseURL + this._Config.getBudgetDetailsByJobID;
    requestURL = requestURL.replace("{ID}", this.selectedJobDetails.id);

    this.doGET(requestURL).subscribe((response: any) => {


      this.budgetDetails = response || {}


      this._Config.isLoader = false;


    },
      (error) => {
        this._Config.isLoader = false;
        this._Config.isLoadedPhotos = true;

      });



  }


  /**
* Get Spend Data
*/
  getSpendDetailsByJobID() {

    this._Config.isLoader = false;
    this._Config.isLoadedPhotos = false;

    let requestURL = environment.baseURL + this._Config.getJobSpendsByJobID;
    requestURL = requestURL.replace("{ID}", this.selectedJobDetails.id);

    this.doGET(requestURL).subscribe((response: any) => {


      this.spendAmountDetails = response || []


      this._Config.isLoader = false;


    },
      (error) => {
        this._Config.isLoader = false;
        this._Config.isLoadedPhotos = true;

      });



  }


  /**
* Get All Job Photos
*/
  getJobPhotosByJobID() {

    // this._Config.isLoader = false;
    this._Config.isLoadedPhotos = false;

    if (this.selectedJobDetails.id) {

      let requestURL = environment.baseURL + this._Config.getJobPhotosByJobID;
      requestURL = requestURL.replace("{ID}", this.selectedJobDetails.id);

      if (this.isViewFromInbox) {
        requestURL = requestURL + '?source=inbox';
      }

      this.doGET(requestURL).subscribe((response: any) => {


        this.selectedJobDetails.photos = response || {}

        if (this.selectedJobDetails.photos.pre_completion.length > 0) {

          this.selectedJobDetails.photos.pre_completion.forEach(element => {
            element.type = this.getFileType(element.mime_type);
          });

          this.selectedImagePreview.pre = this.selectedJobDetails.photos.pre_completion[0];

        }

        if (this.selectedJobDetails.photos.post_completion.length > 0) {

          this.selectedJobDetails.photos.post_completion.forEach(element => {
            element.type = this.getFileType(element.mime_type);
          });

          this.selectedImagePreview.post = this.selectedJobDetails.photos.post_completion[0];


        }

        setTimeout(() => {
          this._Config.isLoadedPhotos = true;
        }, 500);

        // this._Config.isLoader = false;


      },
        (error) => {
          this._Config.isLoader = false;
          this._Config.isLoadedPhotos = true;

        });

    }




  }



  /**
* Get All COA by JOB
*/
  getAllCOAbyJOB() {

    this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.getallCOAbyJob;
    requestURL = requestURL.replace("{ID}", this.selectedJobDetails.id);

    this.doGET(requestURL).subscribe((response: any) => {

      this._Config.isLoader = false;

      this.CartOfAccounts_Category = response || [];

      if (this.CartOfAccounts_Category.length > 0) {

        this.CartOfAccounts_Category.forEach(element => {
          element.label = '( ' + element.gl_code + ' ) - ' + element.account_name;
          element.value = element.id;
        });

      }



    },
      (error) => {
        this._Config.isLoader = false;
      });



  }


  /**
* Get All Stages by JOB
*/
  getAllStagesbyJOB() {

    this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.getAllStagesToJob;

    this.doGET(requestURL).subscribe((response: any) => {

      this._Config.isLoader = false;

      this.allJobStagesList = [];

      if (Array.isArray(response))
        this.allJobStagesList = response || [];

    },
      (error) => {
        this._Config.isLoader = false;
      });



  }

  /**
* Get All Job Vendors
*/
  getAllJobContacts(isLoader?: boolean) {

    this._Config.isLoader = isLoader ? true : false;
    this.allJobContacts = [];

    let requestURL = environment.baseURL + this._Config.getJobContacts;
    requestURL = requestURL.replace("{ID}", this.selectedJobDetails.id);

    this.doGET(requestURL).subscribe((response: any) => {

      this.allJobContacts = response || [];

      this.allJobContacts.forEach(element => {
        element.full_name = element.first_name + ' ' + element.last_name;
        element.company = element.first_name + ' ' + element.last_name;

        element.primary_phone = element.primary_phone ? element.primary_phone : (element.phone ? element.phone : 'No phone number available');
      });

      this._Config.isLoader = false;


    },
      (error) => {

        this._Config.isLoader = false;

      });



  }


  /**
* Get All Job Vendors
*/
  viewTargetDateChangeHistoryByJob() {

    // this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.getTargetDateChangeHistory;
    requestURL = requestURL.replace("{ID}", this.selectedJobDetails.id);

    this.doGET(requestURL).subscribe((response: any) => {

      this.allTargetDateHistory = response || [];

      // this._Config.isLoader = false;


    },
      (error) => {

        // this._Config.isLoader = false;

      });



  }



  getFileExtensionFromURL(filePath: any) {

    let url = new URL(filePath);
    url.pathname;  // returns "/products/flashplayer/include/marquee/design.swf"

    // now get the file name
    let filename = url.pathname.split("/").reverse()[0];

    // returns "design.swf"
    let ext = filename.split(".")[1] || '';

    return ext;


  }



  getFileType(type: any) {

    if (type) {

      let fileType = "image";

      if (type.includes('image')) {
        fileType = "image";
      }
      else if (type.includes('audio')) {
        fileType = "audio";
      }
      else if (type.includes('video')) {
        fileType = "video";
      }
      else if (type.includes('application/pdf')) {
        fileType = "pdf";
      }
      else if (type.includes('application')) {
        fileType = "others";
      }

      return fileType;

    }

    return '';

  }

  scheduleSelect(s) {
    if (s == 'SiteVisit') {
      this.siteVisitActive = true;
      this.scheduleJobActive = false;
    } else if (s == 'Schedule') {
      this.scheduleJobActive = true;
      this.siteVisitActive = false;
    }
  }





  setEditJobValues(isDuplicate?: any) {

    this.selectedJobDetails.isEditJob = true;
    this.selectedJobDetails.isDuplicate = isDuplicate ? isDuplicate : false;

    this.selectedJobDetails.editObj = {
      property: this.selectedJobDetails.property ? this.selectedJobDetails.property.id : null,
      request_type: this.selectedJobDetails.request_type,
      target_date: this.selectedJobDetails.target_date ? new Date(this.selectedJobDetails.target_date) : new Date(),
      description: this.selectedJobDetails.description,
      access_instruction: this.selectedJobDetails.access_instruction,
      category: this.selectedJobDetails.category.id,
      property_entry_permission: this.selectedJobDetails.property_entry_permission,
      unit_entry_permission: this.selectedJobDetails.unit_entry_permission,
      issue_type: this.selectedJobDetails.issue_type,
      service_type: this.selectedJobDetails.service_type ? this.selectedJobDetails.service_type.id : null,
    };

    if (this.selectedJobDetails.request_type == 'In Unit') {
      this.selectedJobDetails.editObj.Location = this.selectedJobDetails.unit.id;
    }
    else {
      // this.selectedJobDetails.editObj.Cam_Address= this.selectedJobDetails.unit.id;
      this.selectedJobDetails.editObj.Cam_Location = this.selectedJobDetails.location;
    }


    if (this.selectedJobDetails.assignee)
      this.selectedJobDetails.editAssignnee = this.selectedJobDetails.assignee.id ? this.selectedJobDetails.assignee.id : null;

    // if (this.selectedJobDetails.engineers.length > 0){
    //   localStorage.setItem('engineers',JSON.stringify(this.selectedJobDetails.engineers));
    // }

    localStorage.setItem('engineers', JSON.stringify(this.selectedJobDetails.engineers));


    if (this.selectedJobDetails.isDuplicate) {
      this.selectedJobDetails.editAssignnee = null;
      this.selectedJobDetails.editFollower = [];
      this.selectedJobDetails.editEngineer = "";
      this.selectedJobDetails.engineers = [];
      this.selectedJobDetails.vendors = [];
      this.selectedJobDetails.followers = [];
      this.selectedJobDetails.temp_stage = null;
      this.selectedJobDetails.selectedFollowers = [];
      this.selectedJobDetails.selectedEngneers = [];
      this.selectedJobDetails.status = "Approved";
      this.selectedJobDetails.assignee.full_name = 'Unassigned';
    }
  }


  getJobNotes(id: any) {
    let requestURL = environment.baseURL + this._Config.getJobNotes;
    requestURL = requestURL.replace("{ID}", id);

    this.doGET(requestURL).subscribe((response: any) => {
      this._Config.isLoader = false;
      this.jobDetailNotesData = response;
    }, (error) => { });
  }

  // submitJobNotes(id: any, data: any) {
  //   if (data) {
  //     let requestURL = environment.baseURL + this._Config.addJobNotes;
  //     requestURL = requestURL.replace("{ID}", id);
  //     let payload = {
  //       "text": data
  //     };

  //     this._Config.isLoader = true;

  //     this.doPOST(requestURL, payload).subscribe((response: any) => {
  //       this._Config.isLoader = false;
  //       this._UtilityService.showSuccess('', 'Notes Added Successfully');
  //       this.getJobNotes(this.selectedJobDetails.id);
  //       this.jobNotesData = "";
  //     }, (error) => {
  //       this._Config.isLoader = false;


  //     });
  //   } else {

  //     this._UtilityService.showError('', 'Notes Cannot be empty');
  //   }
  // }


  /**
 * Get All Locations Dropdown
 */
  getAllLocationDropdown(id: any) {

    let requestURL = environment.baseURL + this._Config.getLocationAllUnitsByProperty;
    requestURL = requestURL.replace("{ID}", id);

    if (this.isViewFromInbox) {
      requestURL = requestURL + '?source=inbox';
    }


    this.doGET(requestURL).subscribe((response: any) => {

      this._Config.isLoader = false;


      let allLocations = [];
      if (response.length > 0) {

        response.forEach((element: any, index) => {

          let units = [];
          element.units.forEach((unit: any) => {

            let search_label = element.address;
            search_label += ", " + unit.name;

            if (unit.tenant)
              search_label += ", " + unit.tenant.name;

            let unitObj = {
              id: unit.id,
              title: unit.name,
              status: unit.status,
              tenant: unit.tenant ? unit.tenant : '',
              search_label: search_label
            };

            units.push(unitObj);

          });

          let unitsObj = {
            id: index + 1,
            title: element.address,
            subunits: units
          };

          allLocations.push(unitsObj);

        });

      }


      this.job_AllLocationDropdownLists = allLocations;

      this.liveLocationData = this.getlocationResponse(allLocations);



    },
      (error) => { });



  }


  getlocationResponse(response): Observable<any[]> {
    return of(response).pipe(delay(500));
  }

  /**
* Get All Categories Dropdown
*/
  getAllCategoriesDropdown(id: any, UnitID?: any) {

    let requestURL = environment.baseURL + this._Config.getAllCategoriesByProperty;
    requestURL = requestURL.replace("{ID}", id);

    if (UnitID)
      requestURL = requestURL + '?unit=' + UnitID;

    if (this.isViewFromInbox) {

      if (requestURL.includes('?'))
        requestURL = requestURL + '&source=inbox';
      else
        requestURL = requestURL + '?source=inbox';

    }

    this.job_AllCategoriesDropdownLists = [];

    this.doGET(requestURL).subscribe((response: any) => {

      this.job_AllCategoriesDropdownLists = response || [];

    },
      (error) => { });



  }



  /**
* Get All Locations Dropdown
*/
  getAllAddressesDropdown(id: any) {

    let requestURL = environment.baseURL + this._Config.getAllAddressesByProperty;
    requestURL = requestURL.replace("{ID}", id);

    if (this.isViewFromInbox) {
      requestURL = requestURL + '?source=inbox';
    }

    this.doGET(requestURL).subscribe((response: any) => {

      this._Config.isLoader = false;

      let allAddresses = [];
      if (response.length > 0) {

        response.forEach((element: any) => {

          let itemObj = { name: element };

          allAddresses.push(itemObj);

        });

      }


      this.Job_AllAddressesDropdownLists = allAddresses;

    },
      (error) => { });



  }


  updateJobTable(selectedJobDetails: any) {


    if (selectedJobDetails.id) {

      let Index = this.allDashboardJobs.findIndex(obj => obj.id == selectedJobDetails.id);

      if (selectedJobDetails.status == "Closed") {
        this.allDashboardJobs.splice(Index, 1);

        this.selectedJobDetails.isEditJob = false;
        this.isJobInfoEdit = false;
        this.selectedJobDetails = {};
        this.isJobInfoEdit = false;
        this._uiService.jobInfoOpen = true;
        this._uiService.currentActiveContent = '';
        this._uiService.closeRightSideBar();

      }

    }

  }




  updateJobinTable(selectedJobDetails: any) {


    if (selectedJobDetails.id) {

      let engineer = "";
      if (Array.isArray(selectedJobDetails.engineers)) {
        let engineers = selectedJobDetails.engineers.map(obj => obj.full_name);
        engineer = this._UtilityService.getItemsByCommaSeparated(engineers)
      };

      let dashboard_Obj = {
        "id": selectedJobDetails.id,
        "courtesy_job": selectedJobDetails.courtesy_job,
        "type": selectedJobDetails.type,
        "description": selectedJobDetails.description,
        "assignee": selectedJobDetails.assignee ? selectedJobDetails.assignee.full_name : '',
        "category": selectedJobDetails.category ? selectedJobDetails.category.name : '',
        "tenant": selectedJobDetails.tenant,
        "property": selectedJobDetails.property.name,
        "actions": selectedJobDetails.total_action_count,
        "status": selectedJobDetails.status,
        "issue_type": selectedJobDetails.issue_type,
        "request_type": selectedJobDetails.request_type,
        "responsible": selectedJobDetails.responsible,
        "created_at": selectedJobDetails.created_at,
        "modified_at": selectedJobDetails.modified_at,
        "priority": selectedJobDetails.priority,
        "target_date": selectedJobDetails.target_date,
        "engineers": engineer
      };

      let Index = this.allDashboardJobs.findIndex(obj => obj.id == selectedJobDetails.id);

      if (selectedJobDetails.status == "Closed") {
        this.allDashboardJobs.splice(Index, 1);

        this.selectedJobDetails.isEditJob = false;
        this.isJobInfoEdit = false;
        this.selectedJobDetails = {};
        this.isJobInfoEdit = false;
        this._uiService.jobInfoOpen = true;
        this._uiService.currentActiveContent = '';
        this._uiService.closeRightSideBar();

      }
      else {
        this.allDashboardJobs[Index] = dashboard_Obj;
      }

      this.allDashboardJobs.sort((val1, val2) => {
        let a: any = new Date(val2.modified_at);
        let b: any = new Date(val1.modified_at);
        return (a - b);
      })

      this.searchParams.sortColumn = 'modified_at';
      this.searchParams.sortBy = 'desc';

      setTimeout(() => {
        document.querySelector(".all_job_listing_container")?.scrollIntoView({ behavior: 'smooth' });
      }, 200);
    }

  }



  changeJobEditRequestType(event: any) {

    let targetValue = event.value;

    this.selectedJobDetails.editObj.request_type = targetValue

    if (targetValue == 'In Unit') {
      this.getAllLocationDropdown(this.selectedJobDetails.property.id);

      if (this.selectedJobDetails.unit) {
        this.selectedJobDetails.editObj.Location = this.selectedJobDetails.unit.id;
        this.getAllCategoriesDropdown(this.selectedJobDetails.property.id, this.selectedJobDetails.unit.id);
      }
      else {
        this.selectedJobDetails.editObj.Location = null;
        this.getAllCategoriesDropdown(this.selectedJobDetails.property.id);

      }

    }
    else {
      this.selectedJobDetails.editObj.Cam_Location = this.selectedJobDetails.location;
      this.getAllAddressesDropdown(this.selectedJobDetails.property.id);
      this.getAllCategoriesDropdown(this.selectedJobDetails.property.id);
    }

  }

  onJobEditChangeLocation(selectedItem: any) {

    this.getAllCategoriesDropdown(this.selectedJobDetails.property.id, selectedItem.id);
    this.selectedJobDetails.unit = selectedItem;

  }


  isJobEdit() {
    this.isJobInfoEdit = true;
  }


  getAllAssigneesList(id: any) {

    let requestURL = "";

    if (this.currentUserInfo.category == 'Tenant') {

      if (!this.currentUserInfo.tenant_id)
        return true;

      requestURL = environment.baseURL + this._Config.getAllAssigneesByPropertyList;
      requestURL = requestURL.replace("{ID}", this.currentUserInfo.tenant_id);
    }
    else {
      requestURL = environment.baseURL + this._Config.getAllAssigneesByPropertyList;
      requestURL = requestURL.replace("{ID}", id);

    }

    if (this.isViewFromInbox) {

      if (requestURL.includes('?'))
        requestURL = requestURL + '&source=inbox';
      else
        requestURL = requestURL + '?source=inbox';

    }


    this.job_AllAssigneeDropdownLists = [{ id: 'Unassigned', full_name: 'Unassigned' }];

    this.doGET(requestURL).subscribe((response: any) => {

      this.job_AllAssigneeDropdownLists = response || [];

      this.job_AllAssigneeDropdownLists.forEach(element => {
        element.name = element.full_name;
      });

      this.isCheckAssignees.next(this.job_AllAssigneeDropdownLists);

    },
      (error) => { });

  }


  getAllEnginnersList(id: any, isJobUpdate?: any) {

    let requestURL = "";

    requestURL = environment.baseURL + this._Config.getAllAssignedEngineerByProperty;
    requestURL = requestURL.replace("{ID}", id);

    if (this.isViewFromInbox) {

      if (requestURL.includes('?'))
        requestURL = requestURL + '&source=inbox';
      else
        requestURL = requestURL + '?source=inbox';

    }

    this.doGET(requestURL).subscribe((response: any) => {

      this.job_AllEngineersDropdownLists = response || [];

      if (this.job_AllEngineersDropdownLists.length > 0) {

        this.job_AllEngineersDropdownLists.forEach(element => {
          element.company = element.full_name;
          element.name = element.company;
        });
      }

      let noEngineerData = {
        name: "No Engineer Required",
        company: "No Engineer Required",
        full_name: "No Engineer Required",
        id: "no-engineer-required",
        photo: ""
      }

      this.job_AllEngineersDropdownLists.unshift(noEngineerData);

      if (isJobUpdate) {
        this.selectedJobDetails.listEngineersDropdown = this.job_AllEngineersDropdownLists.filter(array => this.selectedJobDetails.engineers.some(filter => filter.id === array.id)) || [];
      }


    },
      (error) => { });

  }

  getAllFollowersList(id: any): void {
    let requestURL = '';
    requestURL = environment.baseURL + this._Config.getAllFollowersByPropertyList;
    requestURL = requestURL.replace('{ID}', id);
    if (this.isViewFromInbox) {
      if (requestURL.includes('?')) {
        requestURL = requestURL + '&source=inbox';
      } else {
        requestURL = requestURL + '?source=inbox';
      }
    }
    this.doGET(requestURL).subscribe((response: any) => {
      this.job_AllFollwersDropdownLists = response || [];

      this.job_AllFollwersDropdownLists.map(obj => { obj.name = obj.full_name; return obj; });
    },
      (error) => { });

  }


  getAllJobEmails(isOpenEmailConversation?: boolean) {

    if (this.selectedJobDetails.id) {

      let requestURL = environment.baseURL + this._Config.getAllJobEmails;
      requestURL = requestURL.replace("{ID}", this.selectedJobDetails.id);

      if (this.isViewFromInbox) {

        if (requestURL.includes('?'))
          requestURL = requestURL + '&source=inbox';
        else
          requestURL = requestURL + '?source=inbox';

      }

      this.doGET(requestURL).subscribe((response: any) => {

        this.selectedJobDetails.job_AllJobEmails = response.mails || [];
        this.selectedJobDetails.job_AllEmailJobs = response.jobs || [];
        if (isOpenEmailConversation && this._Config.isNewInbox) {
          this.openEmailConversation();
        }

      },
        (error) => { });

    }


  }

  openEmailConversation(): void {
    this.selectedJobDetails?.job_AllJobEmails.map((email) => {
      if (email.mailbox_is_active && email.mail_status !== 'Resolved') {
        window.open(
          '/pm/inbox/' +
          email.mail_id +
          '/' +
          email.mail_status +
          `/${email.mailbox_id}`,
          '_blank'
        );
      }
    });
  }


  /******************************** Left Side Filters Start *******************************************/

  /**
   * Get All saved filters
   */
  getAllSavedFilterList() {
    let requestURL = environment.baseURL + this._Config.getSaveFilter;

    this.doGET(requestURL).subscribe((response: any) => {
      this._Config.isLoader = false;
      this.allSavedFiltersList = response || [];

    },
      (error) => {
        this._Config.isLoader = false;
      });

  }


  /***********Apply Saved filters ************/
  applySavedFiltes(selectedItem: any) {
    this.searchParams.isFilter = true;
    this.searchParams.isFilterQueryParams = JSON.stringify(selectedItem.query);

    this.searchParams.isSort = true;
    this.searchParams.sortBy = 'desc';
    this.searchParams.sortColumn = 'id';

    this.defaultActiveTab = this.isEngineer() ? 3 : 5;

    if (this.isPastJob == true) {
      this.dashboardAllJobsList.is_pagination = false;
      this.listAllPastJobs(true, false, true);
    } else {
      this.listJobsBySlectedAction(true, false, false, true);
    }
  }


  loadAllFiltersListAPIs() {
    if (this._uiService.isTimesheetFilter == false) {
      this.getFilter_Categories();
      this.getFilter_Vendors();

      if (this.currentUserInfo.category != 'Tenant') {
        this.getFilter_Properties();
        this.getFilter_Tenants();
        this.getFilter_OwnerGroups();
        this.getJobEngineerFilter();
        this.getFilter_AssignedUser();


      }
    } else if (this._uiService.isTimesheetFilter == true) {
      this.getTimesheetPropertyFilter();
      this.getTimesheetEngineerFilter();
    }
  }


  /**
   * Get all Assigned Users
   */
  getFilter_AssignedUser() {
    let requestURL = environment.baseURL + this._Config.getAssignedUser;

    this.doGET(requestURL).subscribe((response: any) => {
      this.filterItems.assignedUsers.allAssignedUsers = response || [];

    },
      (error) => { });

  }


  /**
  * Get all Filter Categories
  */
  getFilter_Categories() {
    let requestURL = environment.baseURL + this._Config.getCategoryUser;

    this.doGET(requestURL).subscribe((response: any) => {
      this.categoryPagination = response.next;
      this.filterItems.categories.allCategories = (Array.isArray(response) ? response : response.results) || [];
      this.allCategories = response || [];

    },
      (error) => { });

  }

  /**
 * Get all Filter Properties
 */
  getFilter_Properties() {
    let property: any = JSON.parse(localStorage.getItem('userobj')) || {}

    if (property.category) {
      if (property.category == "Customer") {

        let requestURL = environment.baseURL + this._Config.getPropertiesUser;
        this.doGET(requestURL).subscribe((response: any) => {
          this.propertyPagination = response.next;

          this.filterItems.properties.allProperties = (Array.isArray(response) ? response : response.results) || [];

          this.allProperties = response || [];

        },
          (error) => { });
      }
    }

  }

  /**
   * Get all Filter Tenants
   */
  getFilter_Tenants() {
    let tenant: any = JSON.parse(localStorage.getItem('userobj')) || {}

    if (tenant.category == "Customer") {
      let requestURL = environment.baseURL + this._Config.getTenantsUser;
      this.doGET(requestURL).subscribe((response: any) => {
        this.tenantPagination = response.next;
        let listItems: any[] = (Array.isArray(response) ? response : response.results) || [];

        if (listItems.length > 0) {
          listItems.forEach(element => {
            element.name = element.company;
          });
        }

        this.filterItems.tenants.allTenant = listItems;
        this.allTenant = listItems;

      },
        (error) => { });
    }
  }

  /**
   * Get all Filter Vendors
   */
  getFilter_Vendors() {
    let requestURL = environment.baseURL + this._Config.getVendorsUser;
    this.doGET(requestURL).subscribe((response: any) => {

      let listItems: any[] = (Array.isArray(response) ? response : response.results) || [];
      this.vendorPagination = response.next;

      if (listItems.length > 0) {
        listItems.forEach(element => {
          element.name = element.company;
        });
      }

      this.filterItems.vendors.allVendor = listItems;

    },
      (error) => { });
  }
  paginationAllJobs(e: any) {
    if (e === 'Vendor') {
      if (this.vendorPagination != '' && this.vendorPagination !== null) {
        this._baseService.doGET(this.vendorPagination).subscribe({
          next: (res: any) => {
            this.vendorPagination = res.next;
            let listItems: any[] = (Array.isArray(res) ? res : res.results) || [];
            if (listItems.length > 0) {
              listItems.forEach(element => {
                element.name = element.company;
              });
            }
            this.filterItems.vendors.allVendor = this.filterItems.vendors.allVendor.concat(listItems);
          }, error: (err: HttpErrorResponse) => { }
        });
      }

    }
    if (e === 'Property') {

      if (this.propertyPagination != '' && this.propertyPagination !== null) {
        this.doGET(this.propertyPagination).subscribe((response: any) => {
          this.propertyPagination = response.next;


          this.filterItems.properties.allProperties = this.filterItems.properties.allProperties.concat((Array.isArray(response) ? response : response.results) || []);

          this.allProperties = response || [];

        },
          (error) => { });
      }

    }
    if (e === 'Tenant') {

      if (this.tenantPagination != '' && this.tenantPagination !== null) {
        this.doGET(this.tenantPagination).subscribe((response: any) => {
          this.tenantPagination = response.next;
          let listItems: any[] = (Array.isArray(response) ? response : response.results) || [];

          if (listItems.length > 0) {
            listItems.forEach(element => {
              element.name = element.company;
            });
          }

          this.filterItems.tenants.allTenant = this.filterItems.tenants.allTenant.concat(listItems);
          this.allTenant = listItems;

        },
          (error) => { });
      }

    }
    if (e === 'Category') {

      if (this.categoryPagination != '' && this.categoryPagination !== null) {
        this.doGET(this.categoryPagination).subscribe((response: any) => {
          this.categoryPagination = response.next;

          this.filterItems.categories.allCategories = this.filterItems.categories.allCategories.concat((Array.isArray(response) ? response : response.results) || []);
          this.allCategories = response || [];

        },
          (error) => { });
      }

    }
  }

  /**
 * Get all Filter Vendors
 */
  getFilter_OwnerGroups() {
    let requestURL = environment.baseURL + this._Config.getOwnerGroup;
    this.doGET(requestURL).subscribe((response: any) => {

      this.filterItems.owner_groups.allOwnerGroup = response || [];

    },
      (error) => { });
  }



  /**
   * Reset all filter selections
   */
  resetFilters() {

    this.filterItems = {
      dateRange: {
        isExpended: false,
        dateData: [
          { id: '1', name: 'Date Created', selected: false, datarange: [] },
          { id: '2', name: 'Schedule Dates', selected: false, datarange: [] },
          { id: '3', name: 'Target Completion Date', selected: false, datarange: [] }
        ]
      },
      requestType: {
        isExpended: false,
        requestTypeData: [
          { id: '0', name: 'In Unit', selected: false },
          { id: '1', name: 'Common Area', selected: false }
        ]
      },
      assignedUsers: {
        isExpended: false,
        allAssignedUsers: this.filterItems.assignedUsers.allAssignedUsers.filter((element: any) => { element.selected = false; return element; })
      },
      categories: {
        isExpended: false,
        allCategories: this.filterItems.categories.allCategories.filter((element: any) => { element.selected = false; return element; })
      },
      properties: {
        isExpended: false,
        allProperties: this.filterItems.properties.allProperties.filter((element: any) => { element.selected = false; return element; })
      },
      tenants: {
        isExpended: false,
        allTenant: this.filterItems.tenants.allTenant.filter((element: any) => { element.selected = false; return element; })
      },
      vendors: {
        isExpended: false,
        allVendor: this.filterItems.vendors.allVendor.filter((element: any) => { element.selected = false; return element; })
      },
      owner_groups: {
        isExpended: false,
        allOwnerGroup: this.filterItems.owner_groups.allOwnerGroup.filter((element: any) => { element.selected = false; return element; })
      },
      billable_parties: {
        isExpended: false,
        billableParty: [
          { id: '1', name: 'Manager', selected: false },
          { id: '2', name: 'Tenant', selected: false },
          { id: '3', name: 'Both', selected: false }
        ]
      },
      job_status: {
        isExpended: false,
        jobStatus: [
          { id: '1', name: 'Completed', value: 'Completed', selected: false },
        ]
      },
      job_actions: {
        isExpended: false,
        jobActions: [
          { id: '1', name: 'New Job Request ', value: 'Created', selected: false, isTenantRestrict: false },
          { id: '1', name: 'Approve Timesheet ', value: 'Labor Proposed', selected: false, isTenantRestrict: true },
          { id: '1', name: 'Approve Schedule ', value: 'Schedule Job Proposed', selected: false, isTenantRestrict: false },
          { id: '1', name: 'Approve Job Cost ', value: 'Bid Proposed', selected: false, isTenantRestrict: false },
          { id: '1', name: 'Approve Materials', value: 'Material Proposed', selected: false, isTenantRestrict: true },
        ]
      },
      job_priority: {
        isExpended: false,
        jobPriority: [
          { id: '1', name: 'Low', value: 'Low', selected: false },
          { id: '1', name: 'Medium', value: 'Medium', selected: false },
          { id: '1', name: 'High', value: 'High', selected: false },
          { id: '1', name: 'Emergency', value: 'Emergency', selected: false },
        ]
      },
      assignedEngineer: {
        isExpended: false,
        allAssignedEngineer: this.filterItems.assignedEngineer.allAssignedEngineer.filter((element: any) => { element.selected = false; return element; })
      },

    };
    this.showDateClear = false;
    this.showRequestClear = false
    this.showAssignClear = false
    this.showCategoryClear = false
    this.showPropertyClear = false
    this.showTenantClear = false
    this.showVendorClear = false
    this.showOwnerClear = false
    this.showEngineerClear = false;
  }




  /**
  * Clear Search Filter in Dashboard
  */

  clearAllAppliedFilters() {
    if (this._uiService.isTimesheetFilter == false) {
      this.selectedActiveTile = 0;
      if (this.isPastJob == true) {
        this.searchParams.isFilter = true;
        this.dashboardAllJobsList.is_pagination = false;
        this.listAllPastJobs(true, false, true);
      } else {
        this.listJobsBySlectedAction(true, false, false, true);
      }
      this.searchParams = {
        Property_ID: null,
        Role_ID: null,
        searchKeyword: '',
        timeline: '',
        sortBy: 'desc',
        sortColumn: 'id',
        isFilter: false,
        isFilterQueryParams: ''
      };
    } else {
      this.searchParams = {
        Property_ID: null,
        Role_ID: null,
        searchKeyword: '',
        timeline: '',
        sortBy: 'desc',
        sortColumn: 'id',
        isFilter: false,
        isFilterQueryParams: ''
      };
      this.requestURLFilter = {};
      this.isTimesheetFilter = false;
      this.resetFilters();
      this.getAllApprovedTimesheet(true, false, false);
    }

  }
  /**
   * clear separate filter by type for left filter
   * @param type
   */
  clearFilter(type: any) {
    if (type == 'date') {
      this.filterItems.dateRange.dateData.forEach(element => {
        element.selected = false;
        // this.dateCreate();
        for (let x = 0; x < this.filterItems.dateRange.dateData.length; x++) {
          if (this.filterItems.dateRange.dateData[x].selected == true) {
            this.dateRangeShow[x] = true;
          } else {
            this.dateRangeShow[x] = false;
            this.filterItems.dateRange.dateData[x].datarange = [];
          }
        }
      });
      if (this.filterItems.dateRange.dateData.filter(opt => opt.selected).length > 0) {
        this.showDateClear = true
      } else {
        this.showDateClear = false
      }
    }
    if (type == 'requestType') {
      this.filterItems.requestType.requestTypeData.forEach(element => {
        element.selected = false;
      });
      if (this.filterItems.requestType.requestTypeData.filter(opt => opt.selected).length > 0) {
        this.showRequestClear = true
      } else {
        this.showRequestClear = false
      }
    }
    if (type == 'assignedUsers') {
      this.filterItems.assignedUsers.allAssignedUsers.forEach(element => {
        element.selected = false;
      });
      if (this.filterItems.assignedUsers.allAssignedUsers.filter(opt => opt.selected).length > 0) {
        this.showAssignClear = true
      } else {
        this.showAssignClear = false
      }
    }
    if (type == 'categories') {
      this.filterItems.categories.allCategories.forEach(element => {
        element.selected = false;
      });
      if (this.filterItems.categories.allCategories.filter(opt => opt.selected).length > 0) {
        this.showCategoryClear = true
      } else {
        this.showCategoryClear = false
      }
    }
    if (type == 'properties') {
      this.filterItems.properties.allProperties.forEach(element => {
        element.selected = false;
      });
      if (this.filterItems.properties.allProperties.filter(opt => opt.selected).length > 0) {
        this.showPropertyClear = true
      } else {
        this.showPropertyClear = false
      }
    }
    if (type == 'tenants') {
      this.filterItems.tenants.allTenant.forEach(element => {
        element.selected = false;
      });
      if (this.filterItems.tenants.allTenant.filter(opt => opt.selected).length > 0) {
        this.showTenantClear = true
      } else {
        this.showTenantClear = false
      }
    }
    if (type == 'vendors') {
      this.filterItems.vendors.allVendor.forEach(element => {
        element.selected = false;
      });
      if (this.filterItems.vendors.allVendor.filter(opt => opt.selected).length > 0) {
        this.showVendorClear = true
      } else {
        this.showVendorClear = false

      }
    }
    if (type == 'owner_groups') {
      this.filterItems.owner_groups.allOwnerGroup.forEach(element => {
        element.selected = false;
      });
      if (this.filterItems.owner_groups.allOwnerGroup.filter(opt => opt.selected).length > 0) {
        this.showOwnerClear = true
      } else {
        this.showOwnerClear = false
      }
    }
    if (type == 'assignedEngineer') {
      this.filterItems.assignedEngineer.allAssignedEngineer.forEach(element => {
        element.selected = false;
      });
      if (this.filterItems.assignedEngineer.allAssignedEngineer.filter(opt => opt.selected).length > 0) {
        this.showEngineerClear = true
      } else {
        this.showEngineerClear = false
      }
    }
  }
  /**
   * clear button shld show after checkbox click
   * @param type
   */
  onClearChange(type) {
    if (type == 'date') {
      if (this.filterItems.dateRange.dateData.filter(opt => opt.selected).length > 0) {
        this.showDateClear = true
      } else {
        this.showDateClear = false
      }
    }
    if (type == 'request') {
      if (this.filterItems.requestType.requestTypeData.filter(opt => opt.selected).length > 0) {
        this.showRequestClear = true
      } else {
        this.showRequestClear = false
      }
    }
    if (type == 'assigned') {
      if (this.filterItems.assignedUsers.allAssignedUsers.filter(opt => opt.selected).length > 0) {
        this.showAssignClear = true
      } else {
        this.showAssignClear = false
      }
    }
    if (type == 'category') {
      if (this.filterItems.categories.allCategories.filter(opt => opt.selected).length > 0) {
        this.showCategoryClear = true
      } else {
        this.showCategoryClear = false
      }
    }
    if (type == 'property') {
      if (this.filterItems.properties.allProperties.filter(opt => opt.selected).length > 0) {
        this.showPropertyClear = true
      } else {
        this.showPropertyClear = false
      }
    }
    if (type == 'tenant') {
      if (this.filterItems.tenants.allTenant.filter(opt => opt.selected).length > 0) {
        this.showTenantClear = true
      } else {
        this.showTenantClear = false
      }
    }
    if (type == 'vendor') {
      if (this.filterItems.vendors.allVendor.filter(opt => opt.selected).length > 0) {
        this.showVendorClear = true
      } else {
        this.showVendorClear = false

      }
    }
    if (type == 'owner') {
      if (this.filterItems.owner_groups.allOwnerGroup.filter(opt => opt.selected).length > 0) {
        this.showOwnerClear = true
      } else {
        this.showOwnerClear = false
      }
    }
    if (type == 'engineer') {
      if (this.filterItems.assignedEngineer.allAssignedEngineer.filter(opt => opt.selected).length > 0) {
        this.showEngineerClear = true
      } else {
        this.showEngineerClear = false
      }
    }

  }

  onChangeDateRange() {
    for (let x = 0; x < this.filterItems.dateRange.dateData.length; x++) {
      if (this.filterItems.dateRange.dateData[x].selected == false) {
        this.filterItems.dateRange.dateData[x].datarange = [];
      }
    }
  }

  /******************************** Left Side Filters End *******************************************/



  /**
  * Delete file from files list
  * @param index (File index)
  */
  deleteUploadedFiles() {

    if (this.uploadPhotos?.length > 0) {

      let deleteBulkPreviewPhotoID = this.uploadPhotos.map(obj => obj.id) || [];

      if (deleteBulkPreviewPhotoID?.length > 0) {

        let requestURL = environment.baseURL + this._Config.deleteBulkPhoto;
        requestURL = requestURL.replace('{photoes}', JSON.stringify(deleteBulkPreviewPhotoID));


        this.doDelete(requestURL).subscribe((event: any) => {


          // this._Config.isLoader = false;


        },
          (error) => {
            // this._Config.isLoader = false;
          });

      }


    }





  }

  /*********-------Timesheet----- **********/

  /**property filter */
  getTimesheetPropertyFilter() {
    let requestURL = environment.baseURL + this._Config.getTimesheetProperty;

    this.doGET(requestURL).subscribe((response: any) => {
      this.filterItems.properties.allProperties = response || [];

    },
      (error) => { });

  }

  /**engineer filter */
  getTimesheetEngineerFilter() {
    let requestURL = environment.baseURL + this._Config.getTimesheetEngineer;
    if (this.selectedTab == 1)
      requestURL = requestURL.replace('{tab}', "unapproved");
    if (this.selectedTab == 2)
      requestURL = requestURL.replace('{tab}', "approved");

    this.doGET(requestURL).subscribe((response: any) => {
      this.filterItems.assignedEngineer.allAssignedEngineer = response || [];

    },
      (error) => { });

  }
  /**timesheet Saved filter list */
  getTimesheetSavedFilterList() {
    let requestURL = environment.baseURL + this._Config.getTimesheetListFilter;

    this.doGET(requestURL).subscribe((response: any) => {
      this._Config.isLoader = false;
      this.allSavedFiltersList = response || [];

    },
      (error) => {
        this._Config.isLoader = false;
      });

  }

  /**apply filter timesheet */
  applySavedFilterTimesheet(selectedItem: any) {
    // this.searchParams.isFilter = true;
    this.isTimesheetFilter = true;
    this.requestURLFilter = JSON.stringify(selectedItem.query);

    // this.searchParams.isSort = true;
    // this.searchParams.sortBy = 'desc';
    // this.searchParams.sortColumn = 'id';

    // this.defaultActiveTab = 5;

    this.getAllApprovedTimesheet(true, false, true);
  }

  /**timesheet list all data */
  getAllApprovedTimesheet(isLoader?: any, isPagination?: any, isFilter?: any) {

    this._Config.isLoader = isLoader;

    let requestURL = "";

    if (isPagination) {

      if (this.Timesheet.all.next) {
        requestURL = this.Timesheet.all.next;
      }
      else {
        if (isFilter == false) {
          requestURL = environment.baseURL + this._Config.getTimisheet_ByType;

          if (this.selectedTab == 1)
            requestURL = requestURL.replace('{Type}', "unapproved");
          if (this.selectedTab == 2)
            requestURL = requestURL.replace('{Type}', "approved");

        } else {

          // requestURL = environment.baseURL + this._Config.getAll_Timisheet;
          // let filterParams = '?query=' + this.requestURLFilter;
          // requestURL = requestURL + filterParams;
          if (this.selectedTab == 1) {
            requestURL = environment.baseURL + this._Config.getUnApprovedTimesheetApplyFilter;
          }
          if (this.selectedTab == 2) {
            requestURL = environment.baseURL + this._Config.getApprovedTimesheetApplyFilter;
          }
          requestURL = requestURL.replace("{query}", this.requestURLFilter);

        }
      }

    }
    else {
      if (isFilter == false) {
        requestURL = environment.baseURL + this._Config.getTimisheet_ByType;

        if (this.selectedTab == 1)
          requestURL = requestURL.replace('{Type}', "unapproved");
        if (this.selectedTab == 2)
          requestURL = requestURL.replace('{Type}', "approved");
      } else {
        // requestURL = environment.baseURL + this._Config.getAll_Timisheet;
        // let filterParams = '?query=' + this.requestURLFilter;
        // requestURL = requestURL + filterParams;
        if (this.selectedTab == 1) {
          requestURL = environment.baseURL + this._Config.getUnApprovedTimesheetApplyFilter;
        }
        if (this.selectedTab == 2) {
          requestURL = environment.baseURL + this._Config.getApprovedTimesheetApplyFilter;
        }
        requestURL = requestURL.replace("{query}", this.requestURLFilter);

      }
    }

    this.doGET(requestURL).subscribe((response: any) => {

      this._Config.isLoader = false;

      this.Timesheet.all.next = response.next ? response.next : '';

      let ListItems = response.results || [];

      if (isPagination) {

        this.allTimesheets.push(...ListItems);

      }
      else {

        this.allTimesheets = ListItems;

      }

      if (this.allTimesheets.length > 0) {

        this.allTimesheets.forEach(element => {
          if (element._from != null) {
            let fromDate = element._from.split(':');
            let from_split = new Date().setHours(fromDate[0], fromDate[1], fromDate[2]);
            element.start_time = new Date(from_split);
          }
          if (element.to != null) {
            let toDate = element.to.split(':');
            let to_split = new Date().setHours(toDate[0], toDate[1], toDate[2]);
            element.to_time = new Date(to_split);

          }

        });

      }




    },
      (error) => {
        this._Config.isLoader = false;

      });



  }






  /**
 * Get All Job Labours
 */
  getAllJobLabours() {

    this.timesheet_list_new = [];

    let requestURL = environment.baseURL + this._Config.getLabourItemToJob;
    requestURL = requestURL.replace("{ID}", this.selectedJobDetails.id);

    this.doGET(requestURL).subscribe((response: any) => {

      this.selectedJobDetails.AllJobLaboursInfo = response || [];

      if (this.selectedJobDetails.AllJobLaboursInfo.length > 0) {

        this.selectedJobDetails.AllJobLaboursInfo.forEach(laborItem => {

          laborItem.labors.forEach(element => {

            element.engineer_name = laborItem.full_name;
            element.engineer_id = laborItem.id;

            element.iswithin_24hours = this.within24Hours(element);

            element.new_date_format = this._UtilityService.convertDateStringToDatePreview(element.date);
            // element.new_date_format = new Date(element.date);


            if (element._from != null) {
              element.start_time = this._UtilityService.TimeConversion24To12Hours(element.date, element._from);

              let fromDate = element._from.split(':');
              element.time_start = fromDate[0] + ':' + fromDate[1];


            }
            if (element.to != null) {
              element.to_time = this._UtilityService.TimeConversion24To12Hours(element.date, element.to);

              let toDate = element.to.split(':');
              element.time_end = toDate[0] + ':' + toDate[1];
            }

            if (element.status == 'Approved')
              element.isChecked = true;
            else
              element.isChecked = false;

          });


          laborItem.ProposedItems = laborItem.labors.filter(obj => (obj.status == 'Proposed' || obj.iswithin_24hours == true)) || [];
          laborItem.ApprovedItems = laborItem.labors.filter(obj => (obj.status == 'Approved' && obj.iswithin_24hours == false)) || [];


          /************ New Job Info Code *************/
          this.timesheet_list_new.push(...laborItem.labors);
          /************ New Job Info Code *************/


        });

      }



    },
      (error) => { });
  }


  within24Hours(element: any) {

    let istoday = false;
    const then = new Date(element.approved_on);
    const now = new Date();

    const msBetweenDates = Math.abs(then.getTime() - now.getTime());

    // 👇️ convert ms to hours                  min  sec   ms
    const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);


    if (hoursBetweenDates < 24) {
      istoday = true;
    } else {
      istoday = false;
    }


    return istoday;
  }

  /**
* Get All Job Materials
*/
  getAllJobMaterials() {

    this.materials_list_new = [];

    let requestURL = environment.baseURL + this._Config.getMAterialItemToJob;
    requestURL = requestURL.replace("{ID}", this.selectedJobDetails.id);

    this.doGET(requestURL).subscribe((response: any) => {

      this.selectedJobDetails.AllJobMaterialsInfo = response || [];

      this.updateMaterialListingView();

    },
      (error) => { });
  }


  updateMaterialListingView() {
    if (this.selectedJobDetails.AllJobMaterialsInfo.length > 0) {

      this.selectedJobDetails.AllJobMaterialsInfo.forEach(laborItem => {


        laborItem.materials.forEach(element => {

          element.engineer_name = laborItem.full_name;
          element.engineer_id = laborItem.id;

          if (element.status == 'Approved')
            element.isChecked = true;
          else
            element.isChecked = false;

        });


        laborItem.ProposedItems = laborItem.materials.filter(obj => (obj.status == 'Proposed')) || [];
        laborItem.ApprovedItems = laborItem.materials.filter(obj => (obj.status == 'Approved')) || [];

        /************ New Job Info Code *************/
        this.materials_list_new.push(...laborItem.materials);
        /************ New Job Info Code *************/

      });

    }
  }

  /**
  * Get All saved filters  for past job
  */
  getAllPastJobFilterList() {
    let requestURL = environment.baseURL + this._Config.pastJobFilter;

    this.doGET(requestURL).subscribe((response: any) => {
      this._Config.isLoader = false;
      this.allSavedFiltersList = response || [];

    },
      (error) => {
        this._Config.isLoader = false;
      });

  }

  /**job engineer  */
  getJobEngineerFilter() {
    let requestURL = environment.baseURL + this._Config.getJobEngineer;

    this.doGET(requestURL).subscribe((response: any) => {
      this.filterItems.assignedEngineer.allAssignedEngineer = response || [];

    },
      (error) => { });

  }





  /**
* Get All Categories Dropdown
*/
  getAllBuildReportsDropdown(type?: any) {

    let requestURL = environment.baseURL + this._Config.getBuildReports;
    if (type == 'Timesheet') {
      requestURL = environment.baseURL + this._Config.getBuildReports + '?type=' + type;
    }
    else {
      requestURL = environment.baseURL + this._Config.getBuildReports;
    }

    this.doGET(requestURL).subscribe((response: any) => {

      this.allBuildReports = response || [];

    },
      (error) => { });



  }






  /**
 * Get all Filter Properties
 */
  getReport_Properties() {
    let property: any = JSON.parse(localStorage.getItem('userobj')) || {}

    if (property.category) {
      if (property.category == "Customer") {
        let requestURL = environment.baseURL + this._Config.getPropertiesFilter;
        this.doGET(requestURL).subscribe((response: any) => {
          this.allReportProperties = response || [];
        },
          (error) => { });
      }
    }

  }

  /**
   * Get all Filter Tenants
   */
  getReport_Tenants() {
    let tenant: any = JSON.parse(localStorage.getItem('userobj')) || {}

    if (tenant.category == "Customer") {
      let requestURL = environment.baseURL + this._Config.getReport_AllTenants;

      this.doGET(requestURL).subscribe((response: any) => {

        this.allReportTenants = response || [];

        if (this.allReportTenants?.length > 0)
          this.allReportTenants.filter((obj: any) => { obj.name = obj?.company ? obj?.company : null; return obj; });

      },
        (error) => { });
    }
  }

  /**
 * Get all Filter Categories
 */
  getReport_Categories() {
    let requestURL = environment.baseURL + this._Config.getReport_AllCategories;

    this.doGET(requestURL).subscribe((response: any) => {

      this.allReportCategories = response.filter(obj => obj.name) || [];

    },
      (error) => { });

  }


  /**
  * Get all Filter Tenants
  */
  getReport_Engineers() {
    let tenant: any = JSON.parse(localStorage.getItem('userobj')) || {}

    if (tenant.category == "Customer") {
      let requestURL = environment.baseURL + this._Config.getReport_AllEngineers;
      this.doGET(requestURL).subscribe((response: any) => {

        this.allReportEngineers = response || [];
        this.allReportEngineers.filter(obj => { obj.name = obj.full_name; return obj; });
        if (!this.showTimesheetBuildReport) {
          let noEngineerData = {
            company: "No Engineer Required",
            name: "No Engineer Required",
            id: "no-engineer-required",
            photo: ""
          }
          this.allReportEngineers.unshift(noEngineerData);
        }

      },
        (error) => { });
    }
  }




  scroll(tabID: any) {

    setTimeout(() => {
      document.querySelector(tabID).scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 200);

  }




  socketResetCall(selectedTab: any) {
    this.searchParams.searchKeyword = '';
    this.searchParams.timeline = '';
    this.selectedActiveTile = 0;
    this.searchParams.isFilter = false;

    this.defaultActiveTab = selectedTab;
    this.listJobsBySlectedAction(true);

  }


  /**
 * Get all Linked Jobs
 */
  getAllLinkedJobsList(jobId: any) {
    let requestURL = environment.baseURL + this._Config.getLinkedJobList;
    requestURL = requestURL.replace("{ID}", this.selectedJobDetails.id);
    this.doGET(requestURL).subscribe((response: any) => {

      let allLinkedDataList = response.linked_job;
      this.allLinkedItem = response.linked_job;
      // this.getAllLinkedJobsDropdown('', false);

      const resultRelated = this.allLinkedItem.filter(f =>
        f.type === 'Related'
      );
      let relatedJobData = [];
      resultRelated.forEach(element => {
        element.job.forEach(elementData => {
          elementData.parentID = element.id;
          elementData.parentJobID = element.id;
          relatedJobData.push(elementData);
        });
      });
      const resultDuplicate = this.allLinkedItem.filter(f =>
        f.type === 'Duplicate'
      );
      let duplicateJobData = [];
      resultDuplicate.forEach(element => {
        element.job.forEach(elementData => {
          elementData.parentID = element.id;
          duplicateJobData.push(elementData);
        });
      });
      const resultBlocked = this.allLinkedItem.filter(f =>
        f.type === 'Blocked'
      );
      let blockedJobData = [];
      resultBlocked.forEach(element => {
        element.job.forEach(elementData => {
          elementData.parentID = element.id;
          blockedJobData.push(elementData);
        });
      });
      this.allLinkedData = [
        { type: 'Related', job: relatedJobData },
        { type: 'Duplicate', job: duplicateJobData },
        { type: 'Blocked', job: blockedJobData }
      ];

      if (relatedJobData.length == 0 && duplicateJobData.length == 0 && blockedJobData.length == 0) {
        this.allLinkedSection = false;
      }

      this.allLinkedJob = [...relatedJobData, ...duplicateJobData, ...blockedJobData];
      // const result = Array.from(new Set(this.allLinkedData.map(s => s.type))).map(x => {
      //   return {
      //     type: x,
      //     job: this.allLinkedData.filter(s => s.type === x).map(edition => edition.data)
      //   }
      // })

    },
      (error) => { });


  }

  /**
 * Get all Linked Jobs
 */
  getAllLinkedJobsDropdown(searchTerm?: any, isPagination?: boolean) {

    let requestURL = "";
    if (isPagination) {
      if (this.allLinkedJobDropdownData.pagination.next) {
        requestURL = this.allLinkedJobDropdownData.pagination.next;
      } else {
        requestURL = environment.baseURL + this._Config.getLinkedJobDropdown;
      }
    } else {
      requestURL = environment.baseURL + this._Config.getLinkedJobDropdown;
      if (searchTerm.term) {
        requestURL = requestURL + '?custom-search=' + searchTerm.term
      }
    }

    this.allLinkedJobDropdownData.Loading = true;

    // let requestURL = environment.baseURL + this._Config.getLinkedJobDropdown;
    requestURL = requestURL.replace('{ID}', this.selectedJobDetails.id);
    this.doGET(requestURL).subscribe((response: any) => {
      let ListItems = response.results || [];
      // this.allLinkedJobDropdownData.tempData = response.results;
      this.allLinkedJobDropdownData.pagination.next = response.next ? response.next : '';
      this.allLinkedJobDropdownData.pagination.count = response.count ? response.count : 0;

      if (ListItems.length > 0) {
        ListItems.forEach(element => {
          element.search_label = element.id + ' ( ' + element.Property + ' ( ' + element.issue_type + ' )';
          if (searchTerm.term) {
            element.search_label = element.id + ' ( ' + element.Property + ' ( ' + element.issue_type + ' )' + searchTerm.term;
          }
        });
      }

      if (isPagination) {
        this.allLinkedJobDropdownData.tempData = [...this.allLinkedJobDropdownData.tempData, ...ListItems];
        // this.allAssociatedJobsList = [...this.allAssociatedJobsList, ...ListItems];


      } else {
        this.allLinkedJobDropdownData.tempData = ListItems || [];

        // this.allAssociatedJobsList = ListItems || [];
      }

      this.allLinkedJobDropdownData.Loading = false;

    },
      (error) => { });


  }

  onChangeJob_Dropdown(selectedItems: any) {
    if (selectedItems.length != undefined) {
      const selected = selectedItems.map(item => item.id);
    }
  }

  onScrollToEnd(event: any) {
    if (this.allLinkedJobDropdownData.pagination.next) {
      this.getAllLinkedJobsDropdown({}, true);
    }

  }

  trackByFn(item: any) {
    return item.id;
  }





}

export interface responseObj {
  id: string;
  name: string;
  address: string;
  property_address: string;
}
