import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS, ApolloModule, APOLLO_NAMED_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, DefaultOptions, InMemoryCache, split ,HttpLink as link} from '@apollo/client/core';
import {setContext} from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../environments/environment';

const uri = environment.graphqlConfig.url; // <-- add the URL of the GraphQL server here
export function createApollo(httpLink: HttpLink): any {
  const authLink = setContext((_, { headers }) => {
    const currentUserInfo: any = localStorage.getItem('userobj')
    ? JSON.parse(localStorage.getItem('userobj'))
    : {};
    return {
      headers: {
        'x-api-key': environment.graphqlConfig.apiKey,
        'x-account':`${currentUserInfo.account}`,
        'x-schema':`${currentUserInfo.schema}`
      }
    }
  });
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }

  const URL = httpLink.create({ uri })
  return {
    link: authLink.concat(URL),
    cache: new InMemoryCache({ addTypename: false }), // <-- typename - off
    defaultOptions: defaultOptions,
  };
}

function extractDomain(url:string) {
  let domain;
  if (url.indexOf("://") > -1) {
    domain = url.split('/')[2];
  } else {
    domain = url.split('/')[0];
  }
  domain = domain.split(':')[0];
  return domain;
}

export function createNamedApollo(httpLink: HttpLink):any {
  let url = window.location.origin;
  let hostname = window.location.hostname;

  let parts = extractDomain(url).split('.');

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }}
  const authLink = setContext((_, { headers, context }) => {
    if(headers){
      return {
        headers: {
          "SoftAuthSkipHeader": 'SoftAuthSkipHeader',
          "x-tenant-id": (hostname.includes('localhost'))?"web":parts[0]
        }
      }
    }else{
    return {
      headers: {
        // "x-tenant-id": parts[0]
        "x-tenant-id": (hostname.includes('localhost'))?"web":parts[0]
      }
    }
    }
  });
  const httpConnect = new link({
    uri:environment.notes.url,
    headers: {
      // "x-tenant-id": parts[0]
      "x-tenant-id": (hostname.includes('localhost'))?"web":parts[0]
    }
  });

  const wsLink = new GraphQLWsLink(createClient({
    url:environment.notes.ws,
  },));

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpConnect,
  );

  const uri = environment.notes.url;
  const URL = httpLink.create({ uri })
  const fileuri  = environment.files.url
  const fileUrl =  httpLink.create({ uri:fileuri });
  const activityuri  = environment.activity.url
  const activityUrl =  httpLink.create({ uri:activityuri });
  const leasesUri  = `${environment.lease_URL}/graphql`
  const leasesUrl =  httpLink.create({ uri: leasesUri });
  return {
     notes: {
          name: 'notes',
          link:  authLink.concat(URL),
          cache: new InMemoryCache(),
          withCredentials:true,
          defaultOptions:defaultOptions
      },
      noteslive: {
        name: 'notes',
        link:  splitLink,
        cache: new InMemoryCache(),
        withCredentials:true,
        defaultOptions:defaultOptions
    },
      files: {
      name: 'files',
      link:  authLink.concat(fileUrl),
      cache: new InMemoryCache(),
      withCredentials:true,
      defaultOptions:defaultOptions
    },
    activity: {
      name: 'activity',
      link:  authLink.concat(activityUrl),
      cache: new InMemoryCache(),
      withCredentials:true,
      defaultOptions:defaultOptions
    },
    leases: {
      name: 'leases',
      link:  authLink.concat(leasesUrl),
      cache: new InMemoryCache(),
      withCredentials:true,
      defaultOptions:defaultOptions
    },
  };
}


@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink]
    },
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: createNamedApollo,
      deps:[HttpLink]
    }
  ]
})
export class GraphQLModule {
}
