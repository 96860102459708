import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { TableService } from 'src/app/@fyxt/_services/table/table.service';
import { ConfigService } from 'src/app/services/config.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';
import { ColumnSortType, TableColumnTypes } from '../../../enums/table/table-column-types.enum';
import {SelectionModel} from "@angular/cdk/collections";
import {AppTableRow} from "../../../models/app-table-model";
import { TableComponent } from '../../../_reusable_ui/_components/table/table.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { NgIf } from '@angular/common';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';


@Component({
    selector: 'app-contact-assign-companies-dialog',
    templateUrl: './contact-assign-companies-dialog.component.html',
    styleUrls: ['./contact-assign-companies-dialog.component.scss'],
    standalone: true,
    imports: [SearchInputComponent, FormsModule, NgxIntlTelInputModule, NgIf, ButtonComponent, MatDialogModule, LoaderSmallComponent, TableComponent]
})
export class ContactAssignCompaniesDialogComponent implements OnInit {
  selectedContacts = [];
  companiesDataSource: any;
  checkedCompanies: any[] = [];
  isLoader: boolean;
  searchInput: any = "";
  moduleName: string;

  tableRowsTotal: number;
  tableRowsPerPage: number = 25;
  currentPage: number = 0;
  pageIndexAPI:number = 1;

  companiesSort: {
      active: '',
      direction: ''
    };

  companiesHeader = [
    {
      value: 'checkbox',
      sort: ColumnSortType.none,
      name: '',
      type: TableColumnTypes.checkbox,
      className: 'table-checkbox'
    },
    {
      value: 'name',
      sort: ColumnSortType.none,
      name: 'Name',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'address',
      sort: ColumnSortType.none,
      name: 'Address',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'email',
      sort: ColumnSortType.none,
      name: 'Email',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'phone',
      sort: ColumnSortType.none,
      name: 'Phone',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'type',
      sort: ColumnSortType.none,
      name: 'Type',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'website',
      sort: ColumnSortType.none,
      name: 'Website',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'created_at',
      sort: ColumnSortType.none,
      name: 'Date Added',
      type: TableColumnTypes.date,
      className: 'table-header'
    },
    ];
  selectionTable = new SelectionModel<AppTableRow>(true, []);


  constructor(
    public dialogRef: MatDialogRef<ContactAssignCompaniesDialogComponent>,
    public _dialogService: DialogService, public _utilService: UtilityService,
    public tableService: TableService, public _Config: ConfigService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.selectedContacts = this.data?.data;
  }

  companiesTableRows: any[] = [];

  ngOnInit(): void {
    this.getAllCompaniesList();
  }


  isCompaniesAllSelected(isSelected: any) {
    if (isSelected) {
      this.checkedCompanies = this.checkedCompanies.concat(this.companiesTableRows);
      this.checkedCompanies = this.removeDuplicateBulkActionCompanies(this.checkedCompanies);
    } else {
      this.checkedCompanies = this.checkedCompanies.filter(ar => !this.companiesTableRows.find(rm => (rm.id === ar.id)));
      this.companiesTableRows.map((companyData: any) => {
        return companyData.checked = false;
      });
    }
  }

  onCompaniesCheckRow(companies: any) {
    // let updatedCheckedCompanies: number[];
    // const isPropertiesChecked = this.checkedCompanies.some((id) => id === companies.id);
    // if (isPropertiesChecked) {
    //   let indexVal = this.checkedCompanies.findIndex((id) => id === companies.id);
    //   if (this.checkedCompanies.includes(companies.id)) {
    //     this.checkedCompanies.splice(indexVal, 1);
    //   } else {
    //     this.checkedCompanies.push(companies.id);
    //   }
    //   // updatedCheckedCompanies = this.checkedCompanies.filter((id) => id !== companies.id);
    // } else {
    //   // updatedCheckedCompanies = [...this.checkedCompanies, companies];
    //   this.checkedCompanies.push(companies.id);
    // }
    // // this.checkedCompanies = updatedCheckedCompanies;
    let updatedCheckedCompanies: number[];
    const isCompanyChecked = this.checkedCompanies.some((checkedCompanies) => checkedCompanies.id === companies.id);
    if (isCompanyChecked) {
      updatedCheckedCompanies = this.checkedCompanies.filter((checkedCompanies) => checkedCompanies.id !== companies.id);
    } else {
      updatedCheckedCompanies = [...this.checkedCompanies, companies];
    }
    this.checkedCompanies = updatedCheckedCompanies;
  }

  assignedCompanies() {
    if (this.checkedCompanies.length == 0) {
      this._utilService.showError('Companies', 'Should select atleast 1 company');
      return false;
    }

    const payload = {
      company_ids : this.checkedCompanies.map((companies: any) => {
        return companies.id;
      }),
      contact_ids: this.selectedContacts.map((contact: any) => {
        return contact.id;
      }),
    }
    let requestURL = environment.fastAPI_Actual_URL + "/contacts/bulk-actions/";
    this._dialogService.doPOST(requestURL, payload).subscribe(
      {
        next: (response: any) => {
          response.isAdd = true;
          this.checkedCompanies = [];
          this._utilService.showSuccess('', 'Companies Assigned Successfully.');
          this.selectionTable.clear();
          this.dialogRef.close(response);
        },
        error: (error) => {
          this._utilService.showErrorMessage(error);
          // this._utilService.showError('', 'Something went wrong, please try again later');
        },
        complete: () => {
        }
      }
    );
  }

  /**
   * Get Selected View Tables
   */
  getAllCompaniesList( isSearch?: boolean, isSort?: boolean) {

    let getActiveColumn = ['name','address', 'email', 'phone', 'type',  'website','created_at']
    let requestURL = environment.fastAPI_Actual_URL + "/companies/?columns=" + JSON.stringify(getActiveColumn)+'&page=' + this.pageIndexAPI +'&size=' + this.tableRowsPerPage +'&contact_id=' + this.data.data[0].id;
    if (isSearch) {
      requestURL += "&source=contact&search=" + this.searchInput;
      this._Config.isLoader = true;
    }

    if (isSort) {
      requestURL += "&sort=" + this.companiesSort.active + "&order=" + this.companiesSort.direction;
      this._Config.isLoader = true;
    }
    this._dialogService.doGET(requestURL).subscribe(
      {
        next: (response: any) => {
          this.companiesTableRows = response.items || [];
          this.tableRowsTotal = response.total;
          if (this.checkedCompanies.length > 0) {
            this.companiesTableRows.map((companyData: any) => {
              this.checkedCompanies.map((checkedCompanyData: any) => {
                if (companyData.id == checkedCompanyData.id) {
                  this.selectionTable.selected.push(companyData);
                  return companyData.checked = true;
                }
              });
            });
          }
          this.companiesDataSource = this.tableService.setTableData('ContactAssignCompany', this.companiesHeader, this.companiesTableRows, this.tableRowsTotal, this.currentPage, this.tableRowsPerPage);
          },
        error: (error) => {
          this.isLoader = false;
          this._Config.isLoader = false;
          this._utilService.showErrorMessage(error.error);
        },
        complete: () => {
          this.isLoader = false;
          this._Config.isLoader = false;
        }
      }
    );
  }

  onSortChange(sortState: any) {
    this.companiesSort = sortState;
    this.tableRowsPerPage = 25;
    this.pageIndexAPI = 1;
    this.currentPage = 0;
    this.checkedCompanies = [];
    this.getAllCompaniesList(this.searchInput.trim() !== '' ? true : false, true);
  }

  /**
   * On Search Table Rows
   * @param inputValue
   */
  onSearch(inputValue: any) {
    this.searchInput = inputValue;
    if (inputValue.length >= 2 || inputValue.length === 0) {
      this.pageIndexAPI = 1;
      this.currentPage = 0;
      this.checkedCompanies = [];
      this.getAllCompaniesList(this.searchInput.trim() !== '' ? true : false, (this.companiesSort !== undefined && this.companiesSort?.active !== '') ? true : false);
    }
  }

  onCompaniesPageChange(event: any) {
    this.currentPage = event.pageIndex;
    this.pageIndexAPI = event.pageIndex + 1;
    this.tableRowsPerPage = event.pageSize;
    this._Config.isLoader = true;
    this.getAllCompaniesList(this.searchInput.trim() !== '' ? true : false, (this.companiesSort !== undefined && this.companiesSort?.active !== '') ? true : false);
  }

   private removeDuplicateBulkActionCompanies(list: any): any {
    return list.reduce((unique, entry) => {
      if (!unique.some((obj) => obj.id == entry.id)) {
        unique.push(entry);
      }
      return unique;
    }, []);
  }
}
