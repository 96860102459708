import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subscription} from "rxjs";
import { AngularEditorConfig, AngularEditorModule } from "@kolkov/angular-editor";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'fyxt-angular-editor',
    templateUrl: './angular-editor.component.html',
    styleUrls: ['./angular-editor.component.scss'],
    standalone: true,
    imports: [AngularEditorModule, FormsModule, NgxIntlTelInputModule]
})
export class AngularEditorComponent implements OnInit, OnChanges {
  @Input() toolbarState = false;
  @Input() editable = true;
  @Input() height = '7rem';
  @Input() minHeight = '7rem';
  @Input() content: string;
  @Input() isDisableTextBox = false;
  @Output() onContentChange = new EventEmitter<string>();
  @Output() DebounceContentChange = new EventEmitter<boolean>();
  private composerChangeSubscription: Subscription
  @Input() config: any;

  constructor() {
  }

  ngOnInit(): void {
    this.composerChangeSubscription = this.onContentChange
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(newText => {
        this.DebounceContentChange.emit(true)
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.toolbarState) {
      this.config.showToolbar = changes.toolbarState.currentValue;
    }
    if (changes.height) {
      this.config.height = changes.height.currentValue;
    }
  }

  ngOnDestroy() {
    this.composerChangeSubscription.unsubscribe();
  }

  isClicked() {
    this.DebounceContentChange.emit(true)
  }

}
