// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.planner_font {
  font-size: 14px;
  margin-top: 10px;
  color: #192c49;
  font-weight: 500;
}

.schudule_class {
  font-size: 19px;
  font-weight: 600;
  margin-top: 9px;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_views/_popup_views/edit-service-scheduler/edit-service-scheduler.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;EACA,eAAA;AACJ","sourcesContent":[".planner_font{\n    font-size: 14px;\n    margin-top: 10px;\n    color: #192c49;\n    font-weight: 500;\n}\n\n.schudule_class{\n    font-size: 19px;\n    font-weight: 600;\n    margin-top: 9px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
