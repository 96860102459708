import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { Observable } from "rxjs";
import { IGetRuleTemplateResult, IRuleTemplate } from "./rule-template-graphql.interface";
import { ILeaseCategory, IRulesCount, Rule } from "../../../_shared/models/porperty-manager/maintenance-terms-tab";

@Injectable({
  providedIn: 'root'
})
export class RuleTemplateHttpService {
  constructor(
    private readonly http: HttpClient,
  ) {}

  private readonly URL = `${environment.lease_URL}/rule-template`;

  getRuleTemplates(options): Observable<IGetRuleTemplateResult> {
    return this.http.get<IGetRuleTemplateResult>(this.URL, { params: { ... options }});
  }

  createTemplate(data: { lease_id: number, template_name: string}): Observable<IRuleTemplate> {
    return this.http.post<IRuleTemplate>(this.URL, data);
  }

  deleteRuleTemplate(template_id: number): Observable<number> {
    return this.http.delete<number>(`${this.URL}/${template_id}`);
  }

  deleteRuleTemplateBulkAction(template_id: number[]): Observable<any> {
    return this.http.post<any>(`${this.URL}/bulk-delete`, { id: template_id });
  }

  updateRuleTemplate(id, data: { template_name: string, lease_type?: string }): Observable<IRuleTemplate> {
    return this.http.put<IRuleTemplate>(`${this.URL}/${id}`, data);
  }

  getTemplateCategories(template_id: number): Observable<ILeaseCategory[]> {
    return this.http.get<ILeaseCategory[]>(`${this.URL}/${template_id}/categories`);
  }

  updateTemplateCategoryConfig(template_id, category_id, config): Observable<boolean> {
    return this.http.patch<boolean>(`${this.URL}/${template_id}/category/${category_id}/config`, { config });
  }

  getTemplateCategoryRules(template_id: number, category_id: string): Observable<Rule[]>  {
    return this.http.get<Rule[]>(`${this.URL}/${template_id}/category/${category_id}/rules`);
  }

  getTemplateCategoryRulesCount(template_id: number): Observable<IRulesCount[]> {
    return this.http.get<IRulesCount[]>(`${this.URL}/${template_id}/rules/count`)
  }

  createTemplateRule(rule: Rule): Observable<Rule> {
    return this.http.post<Rule>(`${this.URL}/rule`, rule);
  }

  updateTemplateRule(rule_id: number, rule: Rule): Observable<Rule> {
    return this.http.put<Rule>(`${this.URL}/rule/${rule_id}`, rule);
  }

  deleteTemplateRule(rule_id: number): Observable<number> {
    return this.http.delete<number>(`${this.URL}/rule/${rule_id}`);
  }

  applyTemplate(template_id: number, lease_id: number): Observable<boolean> {
    return this.http.post<boolean>(`${this.URL}/apply`, { template_id, lease_id })
  }

}
