// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-box-container {
  width: 267px;
}

::ng-deep .textarea.mat-mdc-input-element {
  resize: none !important;
}

.snippet_wrapper {
  padding: 24px 0 24px 0;
  max-height: 46vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.container_border {
  border: 1px solid #e6e8f0;
  border-radius: 4px;
  padding: 8px;
}

.h-80 {
  height: 80px;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_views/_popup_views/inbox-insert-snippet/inbox-insert-snippet.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,sBAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,yBAAA;EACA,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".search-box-container {\n  width: 267px;\n}\n\n::ng-deep .textarea.mat-mdc-input-element {\n  resize: none !important;\n}\n\n.snippet_wrapper {\n  padding: 24px 0 24px 0;\n  max-height: 46vh;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n\n.container_border {\n  border: 1px solid #e6e8f0;\n  border-radius: 4px;\n  padding: 8px;\n}\n\n.h-80 {\n  height: 80px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
