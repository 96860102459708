import MailLabelEntity from './mail/mail-label-entity';
import { UserEntity } from './user-entity';

export class MessageEntity {
  constructor(
    public title: string,
    public message: string,
    public message_dt: string,
    public message_id: string,
    public mailbox_id: string,
    public read_message: boolean,
    public selected_message: boolean,
    public is_checked: boolean,
    public sender: UserEntity,
    public manager: string,
    public property: string,
    public labels: MailLabelEntity[],
    public associatedJobs: string[],
    public check: any[]
  ) {}

  getLastActivity(): string {
    return this.message_dt;
  }

  toggleCheck() {
    this.is_checked = !this.is_checked;
  }
}
