// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.email_coi {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #192C49;
  opacity: 1;
  padding-bottom: 12px;
}

.corporate_label {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #9FA7B3;
  opacity: 1;
}

.primary {
  font-size: 14px;
  letter-spacing: 0px;
  color: #D63C00;
  opacity: 1;
  padding-left: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_forms/create-coi/create-coi.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,cAAA;EACA,UAAA;EACA,oBAAA;AACJ;;AACA;EACI,eAAA;EACA,mBAAA;EACA,mBAAA;EACA,cAAA;EACA,UAAA;AAEJ;;AACA;EACI,eAAA;EACA,mBAAA;EACA,cAAA;EACA,UAAA;EACA,iBAAA;AAEJ","sourcesContent":[".email_coi{\n    font-size: 12px;\n    font-weight: bold;\n    letter-spacing: 0px;\n    color: #192C49;\n    opacity: 1;\n    padding-bottom: 12px;\n}\n.corporate_label{\n    font-size: 14px;\n    font-weight: normal;\n    letter-spacing: 0px;\n    color: #9FA7B3;\n    opacity: 1;\n}\n\n.primary{\n    font-size: 14px;\n    letter-spacing: 0px;\n    color: #D63C00;\n    opacity: 1;\n    padding-left: 4px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
