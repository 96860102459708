import AllAssociatedJobEntity from '../models/entities/all-associated-job-entity';
import { ConfigService } from 'src/app/services/config.service';
import CreateMailboxEntity from '../models/entities/mailbox/create-mailbox-entity';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import MailContactEntity from '../models/entities/mail/mail-contact-entity';
import MailEntity from '../models/entities/mail/mail-entity';
import MailFeedbackEntity from '../models/entities/mailbox/mail-feedback-entity';
import MailManagerEntity from '../models/entities/mail/mail-manager-entity';
import MailboxDashboardCountEntity from '../models/entities/mailbox/mailbox-dashboard-count-entity';
import MailboxEntity from '../models/entities/mailbox/mailbox-entity';
import MailboxQueryParamEntity from '../models/entities/mailbox/mailbox-query-param-entity';
import MailboxType from '../models/entities/mailbox/mailbox-type';
import { Observable } from 'rxjs';
import OutlookOAuthUrlEntity from '../models/entities/mailbox/oauth/outlook/outlook-oauth-url-entity';
import PaginatedApiResult from 'src/app/shared/models/paginated-api-result';
import ReviewFormEntity from '../models/entities/mailbox/settings/review-form-entity';
import SignatureEntity from '../models/entities/mailbox/settings/signature-entity';
import AutoResponse from '../models/entities/mailbox/settings/AutoResponse';
import SignatureRequestEntity from '../models/entities/mailbox/settings/signature-request-entity';
import SnippetEntity from '../models/entities/mailbox/snippet-entity';
import TemplateType from '../models/entities/mailbox/template-type';
import UserMailViewersEntity from '../models/entities/mailbox/user-mail-viewers-entity';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { MessageEntity } from '../models/entities/message-entity';
import { MessageDetailEntity } from '../models/entities/message-detail-entity';
import { TicketFlowsService } from 'src/app/services/ticket-flows.service';
import { UtilityService } from 'src/app/services/utility.service';

@Injectable({
  providedIn: 'root'
})
export default class InboxApi {
  currentUserInfo: any = localStorage.getItem('userobj')?JSON.parse(localStorage.getItem('userobj')): {};
  constructor(private http: HttpClient, private configService: ConfigService,private _ticketService:TicketFlowsService,public _utilService: UtilityService, ) {}

  snippetPageCount:number;
  responsePageCount:number;
  reviewPageCount:number;


  getMailboxes() {
    return this.http
      .get(this.prepareUrl(this.configService.getMailboxes))
      .pipe(
        map((response: PaginatedApiResult<MailboxEntity[]>) => response.results)
      );
  }

  getTemplates(type: TemplateType, query?: string) {
    let url =
      this.prepareUrl(this.configService.getAllSnippets) + `?type=${type}`;
    if (query) {
      url = url + `&name=${query}`;
    }

    const api = this.http.get(url);

    const finalData = api.pipe(
      map((response: PaginatedApiResult<SnippetEntity[]>) => response.results)
    );

    api.subscribe((res: any) => {
      this.snippetPageCount = res.count;

    })

    return finalData;
  }

  getPaginatedSnippets(pageNumber: number) {
    let url = this.prepareUrl(this.configService.getAllSnippets) + `?page=${pageNumber}` + `&type=${TemplateType.SNIPPET}`;
    return this.http
      .get(url)
      .pipe(
        map((response: PaginatedApiResult<SnippetEntity[]>) => response.results)
      );
  }

  getAllSignatures() {
    return this.http
      .get(this.prepareUrl(this.configService.getAllSignatures))
      .pipe(
        map(
          (response: PaginatedApiResult<SignatureEntity[]>) => response.results
        )
      );
  }

  getAllReviews() {

    let url =  this.prepareUrl(this.configService.getAllReviews);

    const api = this.http.get(url);

    const finalData = api.pipe(
      map((response: PaginatedApiResult<ReviewFormEntity[]>) => response.results)
    );

    api.subscribe((res: any) => {
      this.reviewPageCount = res.count;

    })

    return finalData;
  }

  getPaginatedReviews(pageNumber:number){
    let url = this.prepareUrl(this.configService.getAllReviews) + `?page=${pageNumber}`;
    return this.http
      .get(url)
      .pipe(
        map((response: PaginatedApiResult<ReviewFormEntity[]>) => response.results)
      );
  }

  selectedMail(selectedCheckBox: any) {
    return this.http.post(this.prepareUrl(this.configService.selectedMail), selectedCheckBox)
    .pipe(map((response: any) => response));
  }


  mailCheckAndUpdate(id: any,payload: any) {
    return this.http.patch(this.prepareUrl(this.configService.selectedMail).concat(`${id}/`), payload)
    .pipe(map((response: any) => response));
  }

  uncheckAllCheckboxMail(payload: any) {
    return this.http.patch(this.prepareUrl(this.configService.uncheckAll), payload)
      .pipe(map((response: any) => response));
  }

  showLessAllMail(payload: any) {
    return this.http.patch(this.prepareUrl(this.configService.showLessAll), payload)
      .pipe(map((response: any) => response));
  }


  setSeeMore(payload: any) {
    return this.http.post(this.prepareUrl(this.configService.showMore), payload)
    .pipe(map((response: any) => response));
  }


  updateSeeMore(id: any,payload: any) {
    return this.http.patch(this.prepareUrl(this.configService.showMore).concat(`${id}/`), payload)
    .pipe(map((response: any) => response));
  }


  saveSignature(signatureData: any) {
    return this.http
      .post(this.prepareUrl(this.configService.getAllSignatures), signatureData)
      .pipe(
        map(
          (response: PaginatedApiResult<SignatureRequestEntity[]>) => response
        )
      );
  }

  saveReviews(reviewData: any) {
    return this.http
      .post(this.prepareUrl(this.configService.getAllReviews), reviewData)
      .pipe(map((response: PaginatedApiResult<any[]>) => response));
  }

  saveTemplate(snippetData: any) {
    return this.http
      .post(this.prepareUrl(this.configService.getAllSnippets), snippetData)
      .pipe(map((response: SnippetEntity) => response));
  }

  updateSignature(signatureData: any, id: string) {
    return this.http
      .put(
        this.prepareUrl(
          this.configService.getSignatureById.replace('{ID}', id)
        ),
        signatureData
      )
      .pipe(map((response: any) => response));
  }

  updateReview(reviewData: any, id: string) {
    return this.http
      .put(
        this.prepareUrl(this.configService.getReviewById.replace('{ID}', id)),
        reviewData
      )
      .pipe(map((response: any) => response));
  }

  updateTemplate(snippetData: any, id: string) {
    return this.http
      .put(
        this.prepareUrl(
          this.configService.getSnippetById.replace('{ID}', id) +
            `?type=${snippetData.type ?? TemplateType.SNIPPET}`
        ),
        snippetData
      )
      .pipe(map((response: any) => response));
  }

  deleteSnippet(id: string, snippetData) {
     return this.http
      .patch(
        this.prepareUrl(
          this.configService.getSnippetById.replace('{ID}', id)
        ),
        snippetData
      )
      .pipe(map((response: any) => response));
  }

  addNewMailbox(mailbox: CreateMailboxEntity) {
    return this.http
      .post(this.prepareUrl(this.configService.getMailboxes), mailbox)
      .pipe(map((response: MailboxEntity) => response));
  }

  getMailboxDashboard(mailbox: MailboxEntity, query: MailboxQueryParamEntity) {
    return this.http
      .get(
        this.prepareUrl(this.configService.mailDashboardCount) +
          this.prepareMailFetchQuery(query, mailbox.id)
      )
      .pipe(map((response: MailboxDashboardCountEntity) => response));
  }

  createNewMail(mailbox: MailboxEntity) {
    const path = this.prepareUrl(
      this.configService.newMail + `?mailbox=${mailbox.id}`
    );
    return this.http
      .post(path, {})
      .pipe(map((response: MailEntity) => response));
  }

  fetchAllMails(mailbox: MailboxEntity, query: MailboxQueryParamEntity) {
    return this.http
      .get(
        this.prepareUrl(this.configService.getMailList) +
          this.prepareMailFetchQuery(query, mailbox.id)
      )
      .pipe(map((response: PaginatedApiResult<MailEntity[]>) => response));
  }

  fetchMail(mailBoxId: string, mailId: string): Observable<MailEntity> {
    const url =
      this.configService.getMailDetailById.replace('{ID}', mailId) +
      `&mailbox=${mailBoxId}`;
    return this.http
      .get(this.prepareUrl(url))
      .pipe(map((response: MailEntity) => response));
  }

  fetchNextPageMails(url: string) {
    return this.http
      .get(url)
      .pipe(map((response: PaginatedApiResult<MailEntity[]>) => response));
  }

  fetchNextPageAssociatedJobs(url: string) {
    return this.http
      .get(url)
      .pipe(
        map(
          (response: PaginatedApiResult<AllAssociatedJobEntity[]>) => response
        )
      );
  }

  fetchManagers(search: string) {
    return this.http
      .get(
        this.prepareUrl(
          this.configService.getManagerData.replace('{query}', search)
        )
      )
      .pipe(map((result: MailManagerEntity[]) => result));
  }

  fetchContacts(search: string) {
    return this.http
      .get(
        this.prepareUrl(
          this.configService.getContactForInbox.replace('{searchTerm}', search)
        )
      )
      .pipe(map((result: MailContactEntity[]) => result));
  }

  getOutlookOAuthUrl(name: string, redirectUrl: string) {
    return this.http
      .post(this.prepareUrl(this.configService.getOutlookOAuthUrl), {
        redirect_url: redirectUrl,
        name,
        type: MailboxType.Outlook
      })
      .pipe(map((response: OutlookOAuthUrlEntity) => response.url));
  }

  bulkUploadMails(payload: any, mailBoxId: string) {
    return this.http
      .patch(
        this.prepareUrl(
          this.configService.bulkUploadMails + `?mailbox=${mailBoxId}`
        ),
        payload
      )
      .pipe(map((response: any) => response));
  }

  associatedClosedOut(){
    let url = this.prepareUrl(this.configService.associatedBulkMails).replace('{ID}',this._ticketService.selectedJobDetails.id);
    const payLoad = {
      status:'Closeout'
    }
    this.http.post(url,payLoad).subscribe((res)=>{
      if(res){
        this._utilService.showSuccess('', "All associated conversations moved to Close Out in Inbox");
      }
    })

  }

  associatedBulkMails(mail_List:any, payload?:any){
    let url = this.prepareUrl(this.configService.associatedBulkMails).replace('{ID}',this._ticketService.selectedJobDetails.id);
   if(payload){
      const payLoad = {
        mail:mail_List,
        status:'Closed',
         reply: {
        body:payload.body,
        files: payload.files
                }
      }
      this.http.post(url,payLoad).subscribe({next:(res)=>{
        if(res){
          this._utilService.showSuccess('', "Reply sent and selected conversations successfully closed");
        }
      },
    complete:()=>{
      this._ticketService.getAllJobEmails();
    }})
   }
    else{
      const payLoad = {
        mail:mail_List,
        status:'Closed'
      }
      this.http.post(url,payLoad).subscribe({next:(res)=>{
        if(res){
          this._utilService.showSuccess('', "Selected conversations are closed");
        }
      },
    complete:()=>{
      this._ticketService.getAllJobEmails();
    }}
      )
    }
  }

  addLabel(payload: any, mailBoxId: string) {
    return this.http
      .post(
        this.prepareUrl(this.configService.addLabel + `?mailbox=${mailBoxId}`),
        payload
      )
      .pipe(map((response: any) => response));
  }

  editLabel(labelId: string, payload: any, mailBoxId: string) {
    return this.http
      .put(
        this.prepareUrl(
          this.configService.editLabel.replace('{ID}', labelId) +
            `?mailbox=${mailBoxId}`
        ),
        payload
      )
      .pipe(map((response: any) => response));
  }

  updateSenderInformation(payload: any, mailBoxId: string, senderId: string) {
    return this.http
      .patch(
        this.prepareUrl(
          this.configService.getSenderInfo.replace('{ID}', senderId) +
            `?mailbox=${mailBoxId}`
        ),
        payload
      )
      .pipe(map((response: any) => response));
  }

  addAssociatedJobs(payload, mailId: string, mailBoxId: string) {
    return this.http
      .patch(
        this.prepareUrl(
          this.configService.saveJobAssignToThread.replace('{ID}', mailId) +
            `?mailbox=${mailBoxId}`
        ),
        payload
      )
      .pipe(map((response: any) => response));
  }

  removeAssociateJob(jobId: string, mailId: string, mailBoxId: string) {
    return this.http
      .delete(
        this.prepareUrl(
          this.configService.deleteInboxJob
            .replace('{ID}', mailId)
            .replace('{JOBID}', jobId) + `&mailbox=${mailBoxId}`
        )
      )
      .pipe(map((response: any) => response));
  }

  createAndAssociateJobs(mailId: string, mailBoxId: string) {
    return this.http
      .get(
        this.prepareUrl(
          this.configService.getDefaultCreateTicketData.replace(
            '{ID}',
            mailId
          ) + `?mailbox=${mailBoxId}`
        )
      )
      .pipe(map((response: any) => response));
  }

  fetchMailAssignees(request: any) {
    let url =
      this.configService.getFilterAssignedTo + `?mailbox=${request.mailBoxId}`;
    if (request.search) url = url + `&search=${request.search}`;
    return this.http
      .get(this.prepareUrl(url))
      .pipe(map((response: any[]) => response));
  }

  fetchFeedback(feedbackId: string) {
    return this.http
      .get(
        this.prepareUrl(
          this.configService.getFeedback.replace('{ID}', feedbackId)
        )
      )
      .pipe(map((response: MailFeedbackEntity) => response));
  }

  pingMailView(mailId: string) {
    return this.http.put(
      this.prepareUrl(this.configService.setMailView.replace('{ID}', mailId)),
      {}
    );
  }

  deleteMailView(mailId: string) {
    return this.http.put(
      this.prepareUrl(this.configService.setMailView.replace('{ID}', mailId)),
      {unwatched:true}
    );
  }

  getCurrentMailViewers() {
    return this.http
      .get(this.prepareUrl(this.configService.getMailViewers))
      .pipe(map((response: UserMailViewersEntity) => response));
  }

  private prepareUrl(path: string) {
    return environment.baseURL + path;
  }

  private prepareMailFetchQuery(
    query: MailboxQueryParamEntity,
    mailboxId: string
  ) {
    let params = new URLSearchParams('');
    this.setQueryParam(params, 'filter', query.filter);
    this.setQueryParam(params, 'query', JSON.stringify(query.query));
    this.setQueryParam(params, 'sort', query.sort);
    this.setQueryParam(params, 'search', query.search);
    this.setQueryParam(params, 'mailbox', mailboxId);
    return this.getQueryParam(params);
  }

  public setQueryParam(params: URLSearchParams, name: string, value?: string) {
    if (value) {
      params.set(name, value);
    }
  }

  public getQueryParam(params: URLSearchParams) {
    return params.toString() ? '?' + params.toString() : '';
  }

  saveResponse(responseData: any, mailBoxId: string) {
    return this.http
      .post(this.prepareUrl(this.configService.getAllResponse+`?mailbox=${mailBoxId}`), responseData)
      .pipe(map((response: PaginatedApiResult<any[]>) => response));
  }

 getAllResponse(mailboxId: string) {
    let url = this.prepareUrl(this.configService.getAllResponse)+`?mailbox=${mailboxId}`

  const api = this.http.get(url);

  const finalData = api.pipe(
    map((response: PaginatedApiResult<AutoResponse[]>) => response.results)
  );

  api.subscribe((res: any) => {
    this.responsePageCount = res.count;

  })

  return finalData;
}

getPaginatedResponse(mailboxId:string, pageNumber:number){
  let url = this.prepareUrl(this.configService.getAllResponse) + `?mailbox=${mailboxId}&page=${pageNumber}`;
    return this.http
      .get(url)
      .pipe(
        map((response: PaginatedApiResult<AutoResponse[]>) => response.results)
      );
}

getZones(id){
  return this.http
  .get(this.prepareUrl(this.configService.avialableTimeZone.replace('{ID}', id)))
  .pipe(map((response: any) => response));
}
updatePrimary(id: string, is_primary){
  return this.http
  .patch(
    this.prepareUrl(
      this.configService.getResponseById.replace('{ID}', id)
    ),
    {is_primary}
  )
  .pipe(map((response: any) => response));

}
}
