// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disable-card {
  cursor: not-allowed;
  opacity: 0.3;
}

.pt20 {
  padding-top: 20px;
}

.pt10 {
  padding-top: 10px;
}

.btn-container {
  margin-top: 20px;
}

.row-container {
  min-width: 100%;
  max-width: 100%;
}

.container-label {
  min-width: 100%;
  max-width: 100%;
  font-weight: bold !important;
  font-size: 12px !important;
  text-align: start;
}

.display-inline {
  display: inline-block;
}

.err-msg {
  color: #ff0000;
  font-size: 12px !important;
}

.chips-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

:host ::ng-deep fyxt-card {
  /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
}
:host ::ng-deep fyxt-card mat-card-content {
  border: 1px solid #E6E8F0;
  border-radius: 6px;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_views/_popup_views/template-rules-component/template-rules-component.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,YAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;EACA,eAAA;EACA,4BAAA;EACA,0BAAA;EACA,iBAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,cAAA;EACA,0BAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,eAAA;EACA,2BAAA;AACF;;AAEA;EACE,2HAAA;EACA,2HAAA;AACF;AAAE;EACE,yBAAA;EACA,kBAAA;AAEJ","sourcesContent":[".disable-card {\n  cursor: not-allowed;\n  opacity: 0.3;\n}\n\n.pt20 {\n  padding-top: 20px;\n}\n\n.pt10 {\n  padding-top: 10px;\n}\n\n.btn-container {\n  margin-top: 20px;\n}\n\n.row-container {\n  min-width: 100%;\n  max-width: 100%;\n}\n\n.container-label {\n  min-width: 100%;\n  max-width: 100%;\n  font-weight: bold !important;\n  font-size: 12px !important;\n  text-align: start;\n}\n\n.display-inline {\n  display: inline-block;\n}\n\n.err-msg {\n  color: #ff0000;\n  font-size: 12px !important;\n}\n\n.chips-container {\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n}\n\n:host ::ng-deep fyxt-card {\n  /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */\n  /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */\n  mat-card-content {\n    border: 1px solid #E6E8F0;\n    border-radius: 6px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
