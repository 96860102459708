import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { GraphqlEquipmentManagementService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/equipment-management/graphql-equipment-management.service';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';

@Component({
    selector: 'fyxt-additional-data-item',
    templateUrl: './additional-data-item.component.html',
    styleUrls: ['./additional-data-item.component.scss'],
    standalone: true,
    imports: [TextboxComponent, FormsModule, NgxIntlTelInputModule, MatDialogModule, ButtonComponent]
})
export class AdditionalDataItemComponent implements OnInit {
  switchOnOff: boolean = true;
  addAdditionalItemForm: any = {
    itemValue: ''
  };
  closeOnSave:boolean = false;

  constructor(public _graphql:GraphqlEquipmentManagementService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    }

  ngOnInit(): void {
    this.addAdditionalItemForm.itemValue = this.data?.data.value;
  }


    /**
* On Change Event
* @param event
*/
selectedTags(event: any): void {
  this.addAdditionalItemForm.selectedFilters=event;
}

slideToggleChange(event: any): void {}

/**
 * save view Trigger
 */
saveView(){
  this._graphql.updateItemEquipment(this.data?.data.id,this.addAdditionalItemForm.itemValue);
  this.closeOnSave = true;
}

}
