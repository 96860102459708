import { FormControl } from '@angular/forms';


export enum MemberType {
  MANAGER = 'MANAGER',
  ENGINEER = 'ENGINEER',
  TENANT = 'TENANT',
  VENDOR = 'VENDOR',
}

export enum MemberRole {
  OWNER = 'Owner',
  MANAGER = 'Manager',
  ENGINEER = 'Engineer',
  TENANT = 'Tenant',
  VENDOR = 'Vendor',
}


export interface NotifyForm {
  selected_managers: any[],
  selected_engineers: any[]
  selected_tenants: any[]
  selected_vendors: any[]
}

export interface Roles {
  manager: boolean,
  engineer: boolean,
  tenant: boolean,
  vendor: boolean,
}

export interface Contact {
  id: string;
  first_name: string;
  last_name: string;
  primary_company: string;
  primary_email: string;
  primary_phone: string;
  assocaited_companies: number;
  extension?: null;
  primary: boolean;
  type: string;
}

export interface NotifyOtherForm extends Record<keyof NotifyForm, FormControl> {}
