import { Component, Input, OnInit } from '@angular/core';
import { ChecklistService } from '../../../../../Modules/_fyxt_modules/checklist/servcie/checklist.service';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { CheckboxComponent } from '../../../_reusable_ui/_controls/checkbox/checkbox.component';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChipComponent } from '../../../_reusable_ui/_components/chip/chip.component';
import { NgIf, NgFor, SlicePipe } from '@angular/common';


@Component({
    selector: 'fyxt-tab-checklist-details',
    templateUrl: './tab-checklist-details.component.html',
    styleUrls: ['./tab-checklist-details.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, ChipComponent, MatTooltipModule, MatMenuModule, MatCardModule, CheckboxComponent, LoaderSmallComponent, SlicePipe]
})
export class TabChecklistDetailsComponent implements OnInit {

  isLoaderSignature: boolean = false;
  isLoader: boolean = false;
  @Input() selectedActiveTab: any;
  public signature = {
    manager: { name: 'Manager', active: false },
    engineer: { name: 'Engineer', active: false },
    tenant: { name: 'Tenant', active: false },
    vendor: { name: 'Vendor', active: false },
  };
  checkListDetails: any;

  constructor(
    private readonly checklistService: ChecklistService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.isLoader = true;
    this.checklistService.getCheckList(this.selectedActiveTab?.id)
      .subscribe({
        next: (res: any) => {
          this.checkListDetails = res[0];
          for (const [key, value] of Object.entries(res[0].signature)) {
            if (this.signature[key])
              this.signature[key].active = value;
          }
          this.isLoaderSignature = false;
          this.isLoader = false;
        },
        error: () => {
          this.isLoaderSignature = false;
          this.isLoader = false;
        },
      });
  }

  protected readonly Object = Object;
}
