// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `video {
  width: 100%;
  height: auto;
}

button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #192c49;
  color: #fff;
  border: none;
  cursor: pointer;
  text-align: center;
}

button:hover {
  background-color: #192c49;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_reusable_ui/_components/file-detail-view/file-detail-view.component.scss"],"names":[],"mappings":"AACA;EACI,WAAA;EACA,YAAA;AAAJ;;AAEE;EACE,qBAAA;EACA,kBAAA;EACA,yBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;AACJ;;AACE;EACE,yBAAA;AAEJ","sourcesContent":["\nvideo {\n    width: 100%;\n    height: auto;\n  }\n  button {\n    display: inline-block;\n    padding: 10px 20px;\n    background-color: #192c49;\n    color: #fff;\n    border: none;\n    cursor: pointer;\n    text-align: center;\n  }\n  button:hover {\n    background-color: #192c49;\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
