import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SidebarService } from 'src/app/@fyxt/_services/_layout/sidebar.service';
import { BaseService } from 'src/app/services/base.service';
import { ConfigService } from 'src/app/services/config.service';
import { UtilityService } from 'src/app/services/utility.service';
import { JobsService } from 'src/app/services/v2/jobs.service';
import { environment } from 'src/environments/environment';
import { NotificationSource } from './notification.interface'
import { OrderBy } from '../../../../../shared/pipes/search.pipe';
import { DateAgoPipe, OrderByKey } from '../../../_pipes/_custom-pipes/search.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ThumbnailComponent } from '../../../_reusable_ui/_components/thumbnail/thumbnail.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { NgClass, NgTemplateOutlet, NgIf, NgFor, DatePipe, CommonModule } from '@angular/common';

@Component({
    selector: 'fyxt-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    standalone: true,
    imports: [CommonModule,NgClass, NgTemplateOutlet, NgIf, ButtonComponent, MatMenuModule, MatIconModule, MatBadgeModule, MatDividerModule, LoaderSmallComponent, NgFor, ThumbnailComponent, InfiniteScrollModule, DatePipe, DateAgoPipe, OrderByKey, OrderBy],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class NotificationsComponent implements OnInit {
  searchKeyword: any = "";
  isNotificationExpand: boolean = false;

  constructor(
    public sidebarService: SidebarService,
    public _baseService: BaseService,
    public _utilService: UtilityService,
    public _Config: ConfigService,
    public _jobService: JobsService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.showNotification();
  }

  // ngOnDestroy(): void {
  //   //Called once, before the instance is destroyed.
  //   //Add 'implements OnDestroy' to the class.
  //   this._baseService.newNotificaitonsList = {
  //     today: [],
  //     yesterday: [],
  //     lastMonth: []
  //   };
  // }

  onDismiss() {
    this.sidebarService.toggleRightSidebar = !this.sidebarService.toggleRightSidebar;
  }


  showNotification() {
    this._baseService.newNotificaitonsList = {
      today: [],
      yesterday: [],
      lastMonth: []
    };
    // this.isNotificationExpand = !this.isNotificationExpand;
    this._baseService.getUserNotifications(true);
  }

  markAllRead() {

    let postBody: any = {
      "mark-all-read": true
    };


    let requestURL = environment.baseURL + this._Config.readAllNotifications;

    this._baseService.doPatch(requestURL, postBody).subscribe((result: any) => {

      this._Config.isLoader = false;

      // this._utilService.showSuccess('', "Notifications Read Successfully.");

      // this._baseService.getUserNotifications(true);

      this._baseService.newNotificaitonsList.today = this._baseService.newNotificaitonsList.today.filter((element: any) => { element.read = true; return element; });

      this._baseService.newNotificaitonsList.yesterday = this._baseService.newNotificaitonsList.yesterday.filter((element: any) => { element.read = true; return element; });

      this._baseService.newNotificaitonsList.lastMonth = this._baseService.newNotificaitonsList.lastMonth.filter((element: any) => { element.read = true; return element; });

      // this._baseService.allNewNotificaitonsList = this._baseService.allNewNotificaitonsList.filter((element: any) => { element.read = true; return element; });

      this._baseService.currentUserInfo.new_notifications_count = 0;

      this._baseService.getUserInfo();


    },
      (error) => {
        this._utilService.showErrorMessage(error);
        this._Config.isLoader = false;
      });

  }

  markAsReadRequest(selectedItem: any) {
    selectedItem.read = true;

    let postBody: any = {
      "mark-all-read": false,
      "notifications": [selectedItem.id]
    };

    if (this._baseService.currentUserInfo.current_Role == "Engineer") {
      postBody.reference_id = selectedItem.reference_id;
    }
    let requestURL = environment.baseURL + this._Config.readAllNotifications;

    this._baseService.doPatch(requestURL, postBody).subscribe((result: any) => {

      this._Config.isLoader = false;

      // this.viewJobInfo(selectedItem);
      this._baseService.getUserInfo(false);


    },
      (error) => {
        this._utilService.showErrorMessage(error);
        this._Config.isLoader = false;
      });
  }


  markSelectedNotifyToRead(selectedItem: any) {
    if (selectedItem?.source === NotificationSource.NOTE) {
      window.open(selectedItem.reference_link, "_blank");
      return;
    } else if ([NotificationSource.ABSTRACTION_REQUESTED, NotificationSource.ABSTRACTION_COMPLETED, NotificationSource.SIGNATURE].includes(selectedItem?.source)) {
      window.open(selectedItem.reference_link, "_blank");
      this.markAsReadRequest(selectedItem)
      return
    }

    if (this._baseService.currentUserInfo.current_Role == 'Engineer' && selectedItem.status == 'Closed') {
      this._utilService.showError('', "Job is closed can`t be open");
    }
    if (this._baseService.currentUserInfo.current_Role == 'Tenant'  && selectedItem.source == 'Job') {

      if (selectedItem.status == 'Closed') {
        this._utilService.showError('', "Job is closed can`t be open");
        return;
      }

      // this.router.navigate(['/tenant/job/' + selectedItem.reference_id], { relativeTo: this.activatedRoute });
      this.viewTenantNotification(selectedItem);
      return;

    } else if (selectedItem.source == 'Job_Checklist') {
      this.openWorkOrderChecklist(selectedItem);
    }
    else {
      this.markAsReadRequest(selectedItem);
      if(selectedItem?.source==="Bulk Action" && !selectedItem.reference_id){
        return;
      }
      this.viewJobInfo(selectedItem);

    }


  }

  viewTenantNotification(selectedItem: any) {

    let postBody: any = {
      "mark-all-read": false,
      "notifications": [selectedItem.id],
      "reference_id": selectedItem.reference_id
    };

    selectedItem.read = true;

    let requestURL = environment.baseURL + this._Config.readAllNotifications;

    this._baseService.doPatch(requestURL, postBody).subscribe((result: any) => {

      this._Config.isLoader = false;

      if (result?.message == "successfully updated") {
        selectedItem.read = true;
        // this.viewJobInfo(selectedItem);
      } else {
        if (result.message)
          this._utilService.showError('', result.message);
      }

      this.router.navigate(['/tenant/job/' + selectedItem.reference_id], { relativeTo: this.activatedRoute });

      this._baseService.getUserInfo();
    },
      (error) => {
        this._utilService.showErrorMessage(error);
        this._Config.isLoader = false;
      });

  }

  /**
    * Do View Job
    */
  viewJobInfo(selectedJob: any) {
    // this.isNotificationExpand = false;
    this._jobService.sourceBack = 'Notification';
    // this._jobService.redirectTo('pm/job/' + selectedJob.reference_id);

    this._jobService.isJob_HalfPageView = true;
    this._jobService.isLeftArrow_Minimise = false;
    this._jobService.isRightArrow_Minimise = false;

    // this._jobService.sourceBack = 'Companies';
    this._jobService.showJobInfo.next(true);
    this._jobService.getSelectedJobInfoFromNotification(selectedJob.reference_id);

  }



  /********* To Navigate to All Notificaiton ***********/
  viewAllNotifications() {

    this.isNotificationExpand = false;

    if (this._baseService.currentUserInfo.current_Role == 'Owner' || this._baseService.currentUserInfo.current_Role == 'Manager') {
      this._baseService.redirectTo('/pm/notifications');
    }
    if (this._baseService.currentUserInfo.current_Role == 'Tenant') {
      this._baseService.redirectTo('/tenant/notifications');
    }
    else if (this._baseService.currentUserInfo.current_Role == 'Engineer') {
      this._baseService.redirectTo('/engineer/notifications');
    }
  }

   /** Track by Index  **/
   trackByIndex(index: number, item: any): number {
    return index;
  }

  public openWorkOrderChecklist(selectedItemNotification: any){
    this._jobService.isLeftArrow_Minimise = false;
    this._jobService.isRightArrow_Minimise = false;
    this._jobService.isJob_HalfPageView = false;
    this._jobService.selectedJobInfo.id = selectedItemNotification.reference_id;
    this._jobService.showJobInfo.next(true);
    // this.router.navigate([`/pm/job/${selectedItemNotification.reference_id}`]);
    window.location.href = selectedItemNotification.reference_link;
  }


}
