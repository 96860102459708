import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {DialogService, ModalData} from 'src/app/@fyxt/_services/dialog/dialog.service';

import {EditColumn, EditColumnsComponent, EditColumnsModalData} from 'src/app/@fyxt/_shared/_views';


import { TableColumnTypes,ColumnSortType } from 'src/app/@fyxt/_shared/enums/table/table-column-types.enum';
import { AppTableSource } from 'src/app/@fyxt/_shared/models/app-table-model';
import {BehaviorSubject} from "rxjs";
import {switchMap, take} from "rxjs/operators";
import { LayoutService } from 'src/app/@fyxt/_services/_layout/layout.service';
import { SidebarService } from 'src/app/@fyxt/_services/_layout/sidebar.service';
import { TableComponent } from '../../../_reusable_ui/_components/table/table.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { MatDividerModule } from '@angular/material/divider';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { CommonModule, NgIf } from '@angular/common';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';
import { MaterialModule } from '../../../material/material.module';
import { CardComponent } from '../../../_reusable_ui/_components';

@Component({
    selector: 'fyxt-tab-associtecompanies',
    templateUrl: './tab-associtecompanies.component.html',
    styleUrls: ['./tab-associtecompanies.component.scss'],
    standalone: true,
    imports: [CommonModule,SearchInputComponent, NgIf, ButtonComponent, MatDividerModule, LoaderSmallComponent, TableComponent,MaterialModule,CardComponent]
})
export class TabAssocitecompaniesComponent implements OnInit {
  private readonly editableColumns$: BehaviorSubject<any[]> = new BehaviorSubject<EditColumn[]>([]);
  @Input() dataSource!: AppTableSource;
  @Input() associatedCompanySearchValue!: string;
  @Input() isLoader!: boolean;
  @Input() hideAssignCompany!: boolean;
  @Input() associatedCompanyEditColumnView!: [];
  @Input() isReadonly: boolean = false;
  @Input() moduleName: any;
  @Input() isNormalTable: any;
  @Input() isCompanyLayout: boolean = false;
  @Input() selectedFiltersList: any;
  @Input() divWidth: any;
  @Input() filtersDataSource: any;
  @Input() isLastActivityAdded: boolean = false;
  @Input() filterCOIInput: any;
  @Input() filterCOIItems: any;
  @Input() selectedGeoAddressFilter: any;
  @Input() geoAddressClear: any;
  @Output() onSearchValueChange = new EventEmitter<Event>();
  @Output() onSortValueChange = new EventEmitter<Event>();
  @Output() onPaginationValueChange = new EventEmitter<Event>();
  @Output() onEditColumnChange = new EventEmitter();
  @Output() onRowClickValueChange = new EventEmitter();
  @Output() bulkActionAssignCompany = new EventEmitter();
  @Output() onThreeDotMenuChange = new EventEmitter<Event>();
  @Output() onResized = new EventEmitter<Event>();
  @Output() onClearAllSelectedFilters = new EventEmitter<Event>();
  @Output() onDeletedTag = new EventEmitter<Event>();
  @Output() onFilterDateChange = new EventEmitter<Event>();
  @Output() onLastActivityFilterDateChange = new EventEmitter<Event>();
  @Output() onResetDateFilter = new EventEmitter<Event>();
  @Output() onResetLastActivityDateFilter = new EventEmitter<Event>();
  @Output() onFilterSearch = new EventEmitter<Event>();
  @Output() onShowSelectedFilters = new EventEmitter<Event>();
  @Output() onFilterPageChange = new EventEmitter<Event>();
  @Output() onAddGeoAddressToFilter = new EventEmitter<Event>();
  @Output() updateCompaniesAPI = new EventEmitter<Event>();

  constructor(
    public _dialogService: DialogService,
    public sidebarService: SidebarService,
    public _layoutService: LayoutService,
  ) { }

  ngOnInit(): void {
  }

  ngAfterContentChecked(): void {
    //Called after every check of the component's or directive's content.
    //Add 'implements AfterContentChecked' to the class.
    if (this.isReadonly) {
      if (this.dataSource?.headers) {
        this.dataSource.headers = this.dataSource.headers.filter(obj => !(obj.value == 'action')).map(obj => {
          return obj;
        })
      }
    }

  }

  editColumnPopup() {
    this.editableColumns$.next(this.associatedCompanyEditColumnView);
    this.editableColumns$
      .pipe(
        take(1),
        switchMap((columns) => {
          let popupData: ModalData<EditColumnsModalData> = {
            title: 'Edit Table Column',
            component: EditColumnsComponent,
            containerClass: ['modal-sm', 'modal_edit_colomn'],
            data: {
              columns,
              onChange: this.editColumnOrder.bind(this)
            }
          };
          return this._dialogService.openModal<EditColumnsModalData>(popupData);
        })
      )
      .subscribe(result => {        
        if (this.isCompanyLayout) {
          this.updateCompaniesAPI.emit(result);
        }
      });
  }

  editColumnOrder(columnLists: EditColumn[]): void {
    this.onEditColumnChange.emit(columnLists);
  }

  searchAssociatedCompany(value: any): void {
    this.onSearchValueChange.emit(value);
    this.associatedCompanySearchValue = value;
  }

  associatedCompanySortChange(sortState: any) {
    this.onSortValueChange.emit(sortState);
  }

  onPageChange(paginationEvent: any) {
    this.onPaginationValueChange.emit(paginationEvent);
  }

  onRowClick(event: any): void {
    this.onRowClickValueChange.emit(event);
  }

  assignCompany(){
    this.bulkActionAssignCompany.emit();
  }

  threeDotMenuChange(event: any) {
    this.onThreeDotMenuChange.emit(event);
  }

  resizeEvent(event: any) {
    this.onResized.emit(event);
  }

  clearAllSelectedFilters(event: any) {
    this.onClearAllSelectedFilters.emit(event);
  }

  deletedTag(event: any) {
    this.onDeletedTag.emit(event);
  }

  filterDateChange(event: any) {
    this.onFilterDateChange.emit(event);
  }

  lastActivityFilterDateChange(event: any) {
    this.onLastActivityFilterDateChange.emit(event);
  }

  resetDateFilter(event: any) {
    this.onResetDateFilter.emit(event);
  }

  resetLastActivityDateFilter(event: any) {
    this.onResetLastActivityDateFilter.emit(event);
  }

  filterSearch(event: any) {
    this.onFilterSearch.emit(event);
  }

  showSelectedFilters(event: any) {    
    this.onShowSelectedFilters.emit(event);
  }

  filterPageChange(event: any) {
    this.onFilterPageChange.emit(event);
  }

  addGeoAddressToFilter(event: any) {
    this.onAddGeoAddressToFilter.emit(event);
  }

  ngOnDestroy(): void {
    this.sidebarService.toggleRightSidebar = false;
    this._layoutService.toggleFilter_Column(true);
    this._layoutService.showView = true;
  }

}
