import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UiService } from 'src/app/services/ui/ui.service';
import { UtilityService } from 'src/app/services/utility.service';
import { BaseService } from 'src/app/services/base.service';
import { ConfigService } from 'src/app/services/config.service';
import { environment } from 'src/environments/environment';
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable, Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket';
import { TabPhotobankPopupComponent, TabUploadFilesPopupComponent } from 'src/app/@fyxt/_shared/_views';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { JobsService } from 'src/app/services/v2/jobs.service';
import { FilesService } from 'src/app/Modules/_fyxt_modules/companies/_services/files.service';
import { Title } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../../../../@fyxt/_shared/_reusable_ui/_components/button/button.component';
import { DragAndDropFileUploaderComponent } from '../../../../shared/_reusable_components/drag-and-drop-file-uploader/drag-and-drop-file-uploader.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { NgClass, NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'fyxt-service-request-with-company',
    templateUrl: './service-request-with-company.component.html',
    styleUrls: ['./service-request-with-company.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, FormsModule, ReactiveFormsModule, NgxIntlTelInputModule, NgSelectModule, DragAndDropFileUploaderComponent, NgFor, ButtonComponent, MatIconModule, AsyncPipe]
})
export class ServiceRequestWithCompanyComponent implements OnInit {

  allowedDomains: any[] = [
    'localhost',
    'web.devfyxt.com',
    'dietz.fyxt.com',
    'cawleypropertymanagement.fyxt.com',
    'bcwoodproperties.fyxt.com',
    'mcbpropertymgmt.fyxt.com',
  ];

  isDomainAllowed(domain, allowedDomains) {
    const regexPatterns = allowedDomains.map((allowedDomain) =>
      new RegExp(`^${allowedDomain.replace(/\*/g, '.*')}$`, 'i')
    );



    return regexPatterns.some((regex) => regex.test(domain));
  }

  propertySubscription: Subscription;
  companySubscription: Subscription;

  @ViewChild('fileDropRef') fileDropRef: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;

  serviceRequestForm: FormGroup;
  selectedFiles: any[] = [];
  submitted: boolean = false;
  livePropertyData: Observable<any[]>;
  liveLocationData: Observable<any[]>;
  liveCategoryData: Observable<any[]>;
  liveCompanyData: Observable<any[]>;

  propertyLoading: boolean = false;
  locationLoading: boolean = false;
  categoryLoading: boolean = false;
  companyLoading: boolean = false;
  contactLoading: boolean = false;
  isFormSuccess: boolean = false;
  contactData: any;
  contactSearchInput: string;
  tempUploadedList: any[] = [];
  base: string;
  current_account = 'dietz';
  default_logo = "assets/images/service_logo/dietz.jpg";

  serviceJobType: any = 'Building';
  minDate: any = new Date();
  contactPageIndexAPI: number = 1;
  isEditableForm: any = false;
  emailList: any = [];
  showEmailError: boolean=false;
  isautopopulateCompany: boolean = false;
  allTenantCompanyPagination: any = {
    pagination: { next: '', count: 0, total: 0, size: 50, pageCount: null, currentPage: 1 },
    tempData: []
  };

  constructor(
    public _uiService: UiService,
    public _utilService: UtilityService,
    public _baseService: BaseService,
    public _Config: ConfigService,
    public formbuilder: FormBuilder,
    public _dialogService: DialogService,
    public _jobService: JobsService,
    public _filesService: FilesService,
    private readonly title: Title,
  ) {
    this.title.setTitle('Service Request');

    this.base = environment.files.http
    this.base = this.base.substring(0, this.base.length - 1);
    let hostname = window.location.hostname;

    if (hostname.includes('dietz')) {
      this.current_account = 'dietz';
      this.default_logo = "assets/images/service_logo/dietz.jpg";
    }
    else if (hostname.includes('cawleypropertymanagement')) {
      this.current_account = 'cawleypropertymanagement';
      this.default_logo = "assets/images/service_logo/cawley_logo.png";
    }
    else if (hostname.includes('bcwoodproperties')) {
      this.current_account = 'bcwoodproperties';
      this.default_logo = "assets/images/service_logo/bc-wood-logo.png";
    }
    else if (hostname.includes('mcbpropertymgmt') || hostname.includes('web')) {
      this.current_account = 'mcbpropertymgmt';
      this.default_logo = "assets/images/service_logo/mcb-logo.png";
    }
    else if(hostname.includes('localhost')){
      this.current_account = 'mcbpropertymgmt';
      this.default_logo = "assets/images/service_logo/mcb-logo.png";
    }

    if (!this.isDomainAllowed(hostname, this.allowedDomains)) {
      this._baseService.redirectTo('/');
    }
   }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    /**** To Set Form Fields Validations ***/
    this.serviceRequestForm = this.formbuilder.group({
      FName: new FormControl('', [Validators.required]),
      LName: new FormControl('', [Validators.required]),
      Email: new FormControl(null, [
        Validators.required,
        Validators.email,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,5}$")
      ]),
      PhoneNo: new FormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]),
      property: new FormControl(null, [Validators.required]),
      location: new FormControl(null, [Validators.required]),
      category: new FormControl(null, [Validators.required]),
      company: new FormControl(null, [Validators.required]),
      BriefDescription: new FormControl('', [
        Validators.required,
        Validators.maxLength(150)
      ]),
      AdditionalDetails: new FormControl(''),
      Photo: new FormControl(null)
    });

    this.getAllProperties();
    // this.getAllEmailAddress();
    if (this.current_account === 'dietz') {
      this.getAllCompanies();
    }
    if (this.current_account === 'cawleypropertymanagement' || this.current_account === 'bcwoodproperties' || this.current_account === 'mcbpropertymgmt') {
      this.getAllTenantCompanies();
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.serviceRequestForm.controls;
  }



  /**
   * handle files from browsing
   */
  fileBrowseHandler_New(file: any) {
    this._Config.isLoader = true;
    this._filesService.tempFileUploadNoAuth(file[0]).subscribe((data: any) => {
      this._Config.isLoader = false;
      this.tempUploadedList.push(data?.data);
    }, (err) => this._Config.isLoader = false);
  }

  /**
   * Submit new request
   * @returns
   */
  doSubmit() {
    this.submitted = true;
    this.isFormSuccess = false;

    if (this.serviceRequestForm.invalid) {
      return;
    }

    let PostBody: any = {
      contacts: [
        {
          first_name: this.serviceRequestForm.value.FName,
          last_name: this.serviceRequestForm.value.LName,
          email: this.serviceRequestForm.value.Email,
          phone: this.serviceRequestForm.value.PhoneNo
        }
      ],
      property: this.serviceRequestForm.value.property,
      unit: this.serviceRequestForm.value.location,
      category: this.serviceRequestForm.value.category,
      issue_type: this.serviceRequestForm.value.BriefDescription,
      description: this.serviceRequestForm.value.AdditionalDetails,
      company:this.serviceRequestForm.value.company,
      access_instruction: '',
      photos: []
    };
    if((this.current_account === 'cawleypropertymanagement' || this.current_account === 'bcwoodproperties' || this.current_account === 'mcbpropertymgmt') && this.isautopopulateCompany){
      delete PostBody.company
    }

    if (this.selectedFiles.length > 0) {
      PostBody.photos = this.selectedFiles.map((obj) => obj.id);
    }

    let requestURL = environment.baseURL + this._Config.add_New_Service_Request;

    this._Config.isLoader = true;

    this._baseService.doPOST(requestURL, PostBody).subscribe(
      (response: any) => {
        this._Config.isLoader = false;
        let photosList_New = this.tempUploadedList.map((obj) => {
          return obj.id;
        });
        if (photosList_New.length > 0) {
          let payload = {
            "entityId": this.isEditableForm?`${response.draft_job_id}`:`${response.id}`,
            "entityType": this.isEditableForm?"DRAFT_JOB":"JOB",
            "title": "service-request",
            "tempFileIds": photosList_New,
            "userId": response.user_id,
            "userType": this.isEditableForm?"SYSTEM":"USER",
          }
          this._filesService.timeFilesSyncNoAuth(payload).subscribe((data) => { })
          //this._jobService.linkPhotosToJob(response.id, 'JOB', photosList_New);
        }

        if (this.isEditableForm) {
          this._utilService.showSuccess(
            '',
            'Service Request Submitted Successfully.'
          );
        }
        else {
          this._utilService.showSuccess(
            'Request ID: ' + response.id,
            'Service Request Submitted Successfully.'
          );
        }

        this.isFormSuccess = true;
        this.submitted = false;
        this.serviceRequestForm.reset();
        this._utilService.draggedFiles = [];
        this.tempUploadedList = [];
        if(this.current_account === 'cawleypropertymanagement' || this.current_account === 'bcwoodproperties' || this.current_account === 'mcbpropertymgmt'){
          this.isautopopulateCompany = false;
          this.allTenantCompanyPagination.pagination = { next: '', count: 0, total: 0, size: 25, pageCount: null, currentPage: 1 };
        }
      },
      (error) => {
        this._Config.isLoader = false;
        if (error.status === 500) {
          this._utilService.showError(error.status + " Error!", "Something went wrong.");
        }
        else {
          this._utilService.showErrorMessage(error.error);
        }
      }
    );
  }

  /**
   * Get All properties
   * @param searchTerm
   */
  getAllProperties(searchTerm?: any): void {
    let requestURL = '';
    if (searchTerm?.term) {
      requestURL = environment.baseURL + this._Config.servcice_Form_Properties;
      requestURL += '?custom-search=' + searchTerm.term;
    } else {
      requestURL = environment.baseURL + this._Config.servcice_Form_Properties;
    }
    this.propertyLoading = true;

    if (this.propertySubscription) {
      this.propertySubscription.unsubscribe();
    }

    this.propertySubscription = this._baseService.doGET(requestURL)
      .subscribe({
        next: (response: any) => {
          if (response.length > 0) {
            response.forEach((element) => {
              element.name = element.name.replace(/\s+/g, ' ').trim();
              element.address = element.address.replace(/\s+/g, ' ').trim();

              element.property_address =
                element.name + ' ( ' + element.address + ' )';
              element.search_label =
                element.name + ' ( ' + element.address + ' ) ';

              if (searchTerm) {
                if (searchTerm.term) element.search_label += ', ' + searchTerm.term;
              }
            });
          }

          this.livePropertyData = this.getAsyncResponse(response);

        },
        error: (error) => {
          this.propertyLoading = false;
        },
        complete: () => {
          this.propertyLoading = false;
        }
      });

  }

  /**
   * On change property event
   * @param selectedItem
   */
  onChangeProperty(selectedItem: any): void {
    if (selectedItem) {
      this.getAllLocationDropdown();
      this.getAllProperties();
      this.propertyLoading = false;
      this.serviceRequestForm.get('location').patchValue(null);
      this.serviceRequestForm.get('category').patchValue(null);
      this.liveCategoryData = this.getAsyncResponse([]);
      if((this.current_account === 'cawleypropertymanagement' || this.current_account === 'bcwoodproperties' || this.current_account === 'mcbpropertymgmt') && this.isautopopulateCompany){
        this.isautopopulateCompany = false;
        this.serviceRequestForm.get('company').patchValue(null)
      }
    }
  }

  /**
   * On change Location event
   * @param selectedItem
   */
  onChangeLocation(selectedItem: any): void {
    if (selectedItem) {
      this.getAllCategoriesDropdown();
      this.serviceRequestForm.get('category').patchValue(null);
      if((this.current_account === 'cawleypropertymanagement' || this.current_account === 'bcwoodproperties' || this.current_account === 'mcbpropertymgmt') && selectedItem.status === 'Occupied'){
        this.isautopopulateCompany = true;
        this.serviceRequestForm.get('company').patchValue(selectedItem.tenant.name)
      } else {
        this.isautopopulateCompany = false;
      }
    }
  }

  /**
   * Get Locations by Property
   */
  getAllLocationDropdown(searchTerm?: any) {
    let propertyID = this.serviceRequestForm.value.property;
    let requestURL = environment.baseURL + this._Config.servcice_Form_Units;
    requestURL = requestURL.replace(
      '{ID}',
      propertyID
    );

    if (searchTerm?.term) {
      requestURL = requestURL + '?custom-search=' + searchTerm.term;
    }

    this.locationLoading = true;

    this._baseService.doGET(requestURL).subscribe({
      next: (response: any) => {
        let allLocations = [];
        if (response.length > 0) {
          response.forEach((element: any, index) => {
            let units = [];
            element.units.forEach((unit: any) => {
              let search_label = element.address;
              search_label += ', ' + unit.name;

              if (unit.tenant)
                search_label += ', ' + unit.tenant?.name;

              let unitObj = {
                id: unit.id,
                title: unit.name,
                status: unit.status,
                tenant: unit.tenant ? unit.tenant : '',
                search_label: search_label
              };

              units.push(unitObj);
            });

            let unitsObj = {
              id: index + 1,
              title: element.address,
              subunits: units
            };
            allLocations.push(unitsObj);
          });
        }

        this.liveLocationData = this.getAsyncResponse(allLocations);
      },
      error: (error) => {
        this.locationLoading = false;
      },
      complete: () => {
        this.locationLoading = false;
      }
    });


  }

  getAsyncResponse(response): Observable<any[]> {
    return of(response);
  }

  /**
   * Get All Servie Types
   * @param searchTerm
   */
  getAllCategoriesDropdown(searchTerm?: any): void {
    let requestURL = '';

    if (this.isEditableForm) {
      requestURL = environment.baseURL + 'categories/';
    }
    else {
      let locationUnit = this.serviceRequestForm.value.location;
      let propertyID = this.serviceRequestForm.value.property;

      if (propertyID && locationUnit) {
        requestURL = environment.baseURL + this._Config.servcice_Form_Categories;
        requestURL = requestURL.replace(
          '{Propert_ID}',
          propertyID
        );
        requestURL = requestURL.replace(
          '{Unit_ID}',
          locationUnit
        );

      }
    }



    if (searchTerm?.term) {
      requestURL = requestURL + '&custom-search=' + searchTerm.term;
    }

    this.categoryLoading = true;


    this._baseService.doGET(requestURL).subscribe({
      next: (response: any) => {
        let responseData = response || [];
        this.liveCategoryData = this.getAsyncResponse(responseData);
      },
      error: (error) => {
        this.categoryLoading = false;
      },
      complete: () => {
        this.categoryLoading = false;
      }
    });
  }

  validatePhoneNo(event: any) {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  /**
  *  Action Trigger if Service Request Submit
  */
  public TriggerSocketfor_JobActions_New_JobInfo(JobDetails: any) {
    let requestURL =
      environment.socketURL + this._Config.updateJobActionTrigger_Socket_for_v2;
    requestURL = requestURL.replace(
      '{AccountID}',
      JobDetails.account_id
    );
    requestURL = requestURL.replace('{UserID}', JobDetails.user_id);

    let payload: any = {
      job_id: JobDetails.id,
      "source": "form"
    };

    const subject = webSocket(requestURL);
    subject.subscribe(
      (msg) => {},
      (err) => {},
      () => {}
    );
    subject.next(payload);
  }

  /**
*  Action Trigger if Service Request Submit
*/
  public TriggerSocketfor_JobActions_New_TabCount(JobDetails: any) {

    let getTabs_NewCounts = 'dashboard/count/trigger/{AccountID}/{UserID}/';

    let requestURL =
      environment.socketURL + getTabs_NewCounts;
    requestURL = requestURL.replace(
      '{AccountID}',
      JobDetails.account_id
    );
    requestURL = requestURL.replace('{UserID}', JobDetails.user_id);

    let payload: any = {
      job_id: JobDetails.id,
      "source": "form", "members": []
    };

    const subject = webSocket(requestURL);
    subject.subscribe(
      (msg) => {},
      (err) => {},
      () => {}
    );
    subject.next(payload);
  }


  uploadFilesPopup() {
    let popupData = {
      title: 'File Upload',
      component: TabUploadFilesPopupComponent,
      data: {
        "entityId": null,
        "entityType": 'JOB'
      },
    };

    // public _dialogService: DialogService
    this._dialogService.openModal(popupData).subscribe(result => {
      if (Array.isArray(result)) {
        this.tempUploadedList.push(...result) || [];
      }
    });

  }

  photoBankPopup() {
    let popupData = {
      title: 'Photobank',
      component: TabPhotobankPopupComponent,
      data: {
        "entityId": null,
        "entityType": 'JOB'
      }
    };

    this._dialogService.openModal(popupData).subscribe(result => {

      if (result) {
        if (Array.isArray(result?.files)) {
          if (result.files.length > 0) {
            let datas = [];
            result.files.forEach(element => {
              datas.push(element.data)
            });
            // let selectedPhotos = result.files.map(obj => obj.data) || [];
            this.tempUploadedList.push(...datas) || [];
          }

        }

      }

    });
  }

  deleteTempFile(file) {
    for (let i = 0; i < this.tempUploadedList.length; i++) {
      if (file.key == this.tempUploadedList[i].key) {
        this.tempUploadedList.splice(i, 1);
      }
    }
  }


   /**Get All Email Address */
   getAllEmailAddress() {
    let requestURL = environment.fastAPI_Actual_URL + this._Config.get_all_email_address;
    this._baseService.doGET(requestURL).subscribe(
      (response: any) => {
        this._Config.isLoader = false;
        let emailArray = [];
        response.forEach(element => {
          let emailData = { id: element, name: element }
          emailArray.push(emailData);
        });
        this.emailList = emailArray;
      },
      (error) => {
        this._Config.isLoader = false;
        if (error.status === 500) {
          this._utilService.showError(error.status + " Error!", "Something went wrong.");
        }
        else {
          this._utilService.showErrorMessage(error.error);
        }
      }
    );

  }

  /** Add New Email */
  CreateNewEmail = (term) => {
    return new Promise((resolve) => {
      if (term && this.validateEmail(term.replace(/['"]+/g, ''))) {
        this.showEmailError = false;
        setTimeout(() => {
          resolve({ id: term, name: term });
        }, 1000);
      } else {
        this.showEmailError = true;
        return;
      }

    })
  }

  
  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    if (email.includes(' ')) {
      return re.test(email.split(' ')[email.split(' ').length - 1]);
    } else
      return re.test(email);
  }

  /**
   * Get All Companies
   * @param searchTerm
   */
  getAllCompanies(searchTerm?: any): void {
    let requestURL = '';
    if (searchTerm?.term) {
      requestURL = environment.fastAPI_Actual_URL + this._Config.service_Form_Companies;
      requestURL += '?search=' + searchTerm.term;
    } else {
      requestURL = environment.fastAPI_Actual_URL + this._Config.service_Form_Companies;
    }
    this.companyLoading = true;

    if (this.companySubscription) {
      this.companySubscription.unsubscribe();
    }

    this.companySubscription = this._baseService.doGET(requestURL)
      .subscribe({
        next: (response: any) => {
          if (response.length > 0) {
            response.forEach((element) => {
              element.name = element.name.replace(/\s+/g, ' ').trim();

              element.company_name = element.name;
              element.search_label = element.name;

              if (searchTerm) {
                if (searchTerm.term) element.search_label += ', ' + searchTerm.term;
              }
            });
          }

          this.liveCompanyData = this.getAsyncResponse(response);

        },
        error: (error) => {
          this.companyLoading = false;
        },
        complete: () => {
          this.companyLoading = false;
        }
      });

  }
  /**
   * On select company event
   * @param selectedItem
   */
  onChangeCompany(selectedItem: any): void {
    if (selectedItem) {
      this.getAllCompanies();
      this.companyLoading = false;
    }
  }

    /**
   * Get All Tenant Companies
   * @param searchTerm
   */
    getAllTenantCompanies(searchTerm?: any, isPagination?: boolean): void {
      let requestURL = '';
      if (searchTerm?.term) {
        requestURL = environment.fastAPI_Actual_URL + this._Config.service_Form_Tenant_Companies;
        requestURL += '?search=' + searchTerm.term;
      } else {
        requestURL = environment.fastAPI_Actual_URL + this._Config.service_Form_Tenant_Companies;
      }
      if (isPagination) {
        requestURL += '?page='+this.allTenantCompanyPagination.pagination.currentPage;
        if (searchTerm?.term) {
          this.allTenantCompanyPagination.pagination.currentPage = 1;
          requestURL += '?page='+this.allTenantCompanyPagination.pagination.currentPage + '&search=' + searchTerm.term;
        }
      }
      this.companyLoading = true;
  
      if (this.companySubscription) {
        this.companySubscription.unsubscribe();
      }
  
      this.companySubscription = this._baseService.doGET(requestURL)
        .subscribe({
          next: (response: any) => {
            this.allTenantCompanyPagination.pagination.total = response.total;
            this.allTenantCompanyPagination.pagination.pageCount = Math.ceil(this.allTenantCompanyPagination.pagination.total / this.allTenantCompanyPagination.pagination.size);
            // Pagination Code
            this.allTenantCompanyPagination.pagination.next = response.next
              ? response.next
              : '';
            this.allTenantCompanyPagination.pagination.count = response.count
              ? response.count
              : 0;
              let ListItems = response.items || [];
            if (ListItems.length > 0) {
              ListItems.forEach((element) => {
                element.name = element.name.replace(/\s+/g, ' ').trim();
  
                element.company_name = element.name;
                element.search_label = element.name;
  
                if (searchTerm) {
                  if (searchTerm.term) element.search_label += ', ' + searchTerm.term;
                }
              });
            }
            if (isPagination) {
              // this.allPropertyPagination.tempData.push(...ListItems);
              this.allTenantCompanyPagination.tempData = [...this.allTenantCompanyPagination.tempData, ...ListItems];
  
            } else {
              this.allTenantCompanyPagination.tempData = ListItems;
            }
  
            this.allTenantCompanyPagination.tempData = [...this.allTenantCompanyPagination.tempData];

            
  
            this.liveCompanyData = this.getAsyncResponse(this.allTenantCompanyPagination.tempData);
  
          },
          error: (error) => {
            this.companyLoading = false;
          },
          complete: () => {
            this.companyLoading = false;
          }
        });
  
    }

    onScrollToEndTenantCompany(event: any) {
      if (this.allTenantCompanyPagination.pagination.currentPage < this.allTenantCompanyPagination.pagination.pageCount) {
        this.allTenantCompanyPagination.pagination.currentPage++;
        this.getAllTenantCompanies({}, true);
      }
  
    }

    onClear() {
      this.allTenantCompanyPagination.pagination = { next: '', count: 0, total: 0, size: 25, pageCount: null, currentPage: 1 };
      this.getAllTenantCompanies();
    }

  

}
