import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { LeasesStoreService } from '../../../../_services/leases/leases-store.service';
import { Observable, Subject } from 'rxjs';
import { Contacts } from '../../../models/porperty-manager/contacts';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompaniesDetailedViewService } from '../../../../../Modules/_fyxt_modules/companies/_services/companies-detailed-view.service';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { LeasesHttpService } from '../../../../_services/leases/leases-http.service';
import { UtilService } from '../../../../../services/v2/util.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { IconSwitchComponent } from '../../../_reusable_ui/_components/icon-switch/icon-switch.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';

interface IAssignedContact {
  id: string
  first_name: string
  last_name: string
  is_assign: boolean
}

@Component({
    selector: 'fyxt-assign-contacts',
    templateUrl: './assign-contacts.component.html',
    styleUrls: ['./assign-contacts.component.scss'],
    standalone: true,
    imports: [SearchInputComponent, NgIf, NgFor, IconSwitchComponent, InfiniteScrollModule, AsyncPipe]
})
export class AssignContactsComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<null> = new Subject<null>();

  assignContactList = [];
  isLoader: boolean;
  selectedCompanyDetails: any;
  viewModule: string;
  searchValue: string;
  currentPage: number;
  listContacts$!: Observable<Contacts[]>;
  ids: string[];
  primary_contact_id!: string;
  assigned_contacts!: any[];

  new_assigned_contacts: IAssignedContact[] = [];
  new_unassigned_contacts: IAssignedContact[] = [];

  contactDetailForm: any = {
    id: '',
    contactName: ''
  };

  constructor(
    private readonly leasesStoreService: LeasesStoreService,
    public dialogRef: MatDialogRef<AssignContactsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _companiesDetailedService: CompaniesDetailedViewService,
    private readonly leasesHttpService: LeasesHttpService,
    private readonly utilService: UtilService
  ) {
    this.selectedCompanyDetails = this.data?.data?.selectedCompanyDetails;
    this.viewModule = this.data?.data?.viewModule;
    this.primary_contact_id = this.data?.data?.primary_contact_id;
    this.assigned_contacts = this.data?.data?.assigned_contacts;
  }

  ngOnInit(): void {
    this.getAssignContactsList();
  }

  ngOnDestroy(): void {
    this.leasesStoreService.currentLease$
        .pipe(takeUntil(this.destroy$))
        .pipe(
            filter((l) => !!l),
        )
        .subscribe({
          next: (lease) => {
            const newlyAssignedContactsIds = this.new_assigned_contacts.map(({ id }) => id);
            const newlyUnassignedContactsIds = this.new_unassigned_contacts.map(({ id }) => id);

            return this.dialogRef.close({ leaseId: lease.id, newlyAssignedContactsIds, newlyUnassignedContactsIds })
          },
          error: (e) => {},
        });

    this.destroy$.next(null);
    this.destroy$.complete();
  }

  getAssignContactsList(): void {
    if (this.viewModule == 'company') {
      this.getAssignContactsListFromCompany();
    }
    if (this.viewModule === 'lease') {
      this.getAssignContactsListFromLease();
    }

    if (this.viewModule === 'lease_primary') {
      this.getAssignPrimaryContactsListFromLease();
    }
  }

  getAssignContactsListFromCompany(searchVal?: string, pageNumber?: any): void {
    this._companiesDetailedService
      .getAssignContactsList(this.selectedCompanyDetails, searchVal, pageNumber)
      .subscribe({
        next: (responseList: any) => {
          if (this.currentPage != undefined && this.currentPage > 1) {
            this.assignContactList =
              this.assignContactList.concat(responseList.items) || [];
          } else {
            this.assignContactList = responseList.items;
          }
          this.currentPage = responseList.page;
        },
        error: (error) => {
          this.isLoader = false;
        },
        complete: () => {
          this.isLoader = false;
        },
      });
  }

  getAssignContactsListFromLease(searchVal?: string, pageNumber?: any): void {
    this.leasesStoreService.contacts$
      .pipe(takeUntil(this.destroy$))
      .pipe(
        filter((c) => !!Object.keys(c).length),
        take(1),
        tap((contacts) => {
          this.leasesHttpService
            .getContactsList(searchVal, pageNumber)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
              next: (responseList: any) => {
                let assignContactList = [];
                if (this.currentPage != undefined && this.currentPage > 1) {
                  assignContactList =
                    this.assignContactList.concat(
                      responseList.items.map((data) => ({
                        ...data,
                        is_assign: contacts.ids.includes(data.id),
                      })),
                    ) || [];
                } else {
                  assignContactList = responseList.items.map((data) => ({
                    ...data,
                    is_assign: contacts.ids.includes(data.id),
                  }));
                }
                this.assignContactList = assignContactList.filter(v => v.id !== this.primary_contact_id);
                this.currentPage = responseList.page;
              },
              error: (error) => {
                this.isLoader = false;
              },
              complete: () => {
                this.isLoader = false;
              },
            });
        }),
      )
      .subscribe();
  };

  getAssignPrimaryContactsListFromLease(
    searchVal?: string,
    pageNumber?: any,
  ): void {
    this.leasesStoreService.currentLease$
      .pipe(takeUntil(this.destroy$))
      .pipe(
        filter((l) => !!l),
        take(1),
        tap((lease) => {
          this.leasesHttpService
            .getContactsList(searchVal, pageNumber)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
              next: (responseList: any) => {
                if (this.currentPage != undefined && this.currentPage > 1) {
                  this.assignContactList =
                    this.assignContactList.concat(
                      responseList.items.map((data) => ({
                        ...data,
                        is_assign: data.id === lease.contact_primary,
                      })),
                    ) || [];
                } else {
                  this.assignContactList = responseList.items.map((data) => ({
                    ...data,
                    is_assign: data.id === lease.contact_primary,
                  }));
                }
                this.assignContactList = this.assignContactList.filter(v => {
                  return !this.assigned_contacts.find(a => a.id === v.id);
                });
                this.currentPage = responseList.page;
              },
              error: (error) => {
                this.isLoader = false;
              },
              complete: () => {
                this.isLoader = false;
              },
            });
        }),
      )
      .subscribe();
  };

  onSearch(searchVal: any): void {
    this.searchValue = searchVal;
    if (searchVal.length >= 3 || searchVal.length === 0) {
      if (this.viewModule == 'company') {
        this.currentPage = 1;
        this.getAssignContactsListFromCompany(
          this.searchValue,
          this.currentPage,
        );
      }
      if (this.viewModule === 'lease') {
        this.currentPage = 1;
        this.getAssignContactsListFromLease(
          this.searchValue,
          this.currentPage,
        );
      }
      if (this.viewModule === 'lease_primary') {
        this.currentPage = 1;
        this.getAssignPrimaryContactsListFromLease(
          this.searchValue,
          this.currentPage,
        );
      }
    }
  }

  onLoadAssignContacts(isPagination: boolean): void {
    if (this.viewModule == 'company') {
      this.currentPage += 1;
      this.getAssignContactsListFromCompany(this.searchValue, this.currentPage);
    }
    if (this.viewModule === 'lease') {
      this.currentPage += 1;
      this.getAssignContactsListFromLease(this.searchValue, this.currentPage);
    }
    if (this.viewModule === 'lease_primary') {
      this.currentPage += 1;
      this.getAssignPrimaryContactsListFromLease(this.searchValue, this.currentPage);
    }
  }

  assignUnAssignContacts(contact): void {
    if (this.viewModule == 'company') {
      this.assignUnAssignContactsFromCompany(contact);
    }
    if (this.viewModule === 'lease') {
      this.assignUnAssignContactsFromLease(contact);
    }
  }

  assignUnsignedContacts(): void {}

  assignUnAssignContactsFromCompany(contact): void {
    contact.is_assign = !contact.is_assign;
    let postBody = {};
    let isSourceDropDown: boolean;
    if (contact.is_assign == true) {
      postBody['assingn_company'] = true;
      isSourceDropDown = true;
    } else {
      postBody['remove_company'] = true;
      isSourceDropDown = false;
    }
    this._companiesDetailedService
      .assignUnAssignContacts(
        this.selectedCompanyDetails,
        contact.id,
        postBody,
        isSourceDropDown,
      )
      .subscribe({
        next: (responseList: any) => {},
        error: (error) => {
          this.isLoader = false;
        },
        complete: () => {
          this.isLoader = false;
        },
      });
  }

  assignUnAssignContactsFromLease(contact: IAssignedContact): void {
    contact.is_assign = !contact.is_assign;

    const isNewlyAssignedContact = this.new_assigned_contacts.includes(contact);
    const isNewlyUnassignedContact = this.new_unassigned_contacts.includes(contact);

    if (!isNewlyUnassignedContact && !isNewlyAssignedContact && contact.is_assign) {
      this.new_assigned_contacts.push(contact);
    } else if (!isNewlyUnassignedContact && isNewlyAssignedContact && !contact.is_assign) {
      this.new_assigned_contacts = this.new_assigned_contacts.filter(({ id }) => id !== contact.id);
    }

    if (!isNewlyAssignedContact && !isNewlyUnassignedContact && !contact.is_assign) {
      this.new_unassigned_contacts.push(contact);
    } else if (!isNewlyAssignedContact && isNewlyUnassignedContact && contact.is_assign) {
      this.new_unassigned_contacts = this.new_unassigned_contacts.filter(({ id }) => id !== contact.id);
    }

    // this.leasesStoreService.currentLease$
    //   .pipe(takeUntil(this.destroy$))
    //   .pipe(
    //     filter((l) => !!l),
    //     switchMap((lease) => {
    //       if (contact.is_assign) {
    //         return this.leasesStoreService.assignContact(lease.id, contact.id);
    //       }
    //       if (!contact.is_assign) {
    //         return this.leasesStoreService.removeContact(lease.id, contact.id);
    //       }
    //     }),
    //   )
    //   .subscribe({
    //     next: (r) => {},
    //     error: (e) => {
    //     },
    //   });
  }

  assignPrimaryContactFromLease(event: boolean, contact): void {
    const copyAssignContactList = [...this.assignContactList];
    this.assignContactList.forEach((v) => v.is_assign = false);
    if (event) {
      this.leasesStoreService.currentLease$
        .pipe(takeUntil(this.destroy$))
        .pipe(
          take(1),
          switchMap((lease) =>
              this.leasesStoreService.updateImportantContact({
                id: lease.id,
                contact_primary: contact.id,
              }),
          ),
        )
        .subscribe({
          next: (r) => {
            this.assignContactList.forEach((v) => v.is_assign = false);
            contact.is_assign = true;
            this.utilService.showSuccess('', 'Important contact assigned successfully!')
            this.dialogRef.close(r)
          },
          error: (e) => {
            contact.is_assign = false;
            this.assignContactList = copyAssignContactList;
            this.utilService.showError('Important contact assigned error!', e.message);
          },
        });
    } else {
      this.leasesStoreService.currentLease$
        .pipe(takeUntil(this.destroy$))
        .pipe(
          take(1),
          switchMap((lease) =>
            this.leasesStoreService.updateImportantContact({
              id: lease.id,
              contact_primary: null,
            }),
          ),
        )
        .subscribe({
          next: (r) => {
            contact.is_assign = false;
            this.utilService.showSuccess('', 'Important contact unassigned successfully!')
            this.dialogRef.close(r)
          },
          error: (e) => {
            contact.is_assign = true;
            this.assignContactList = copyAssignContactList;
            this.utilService.showError('Important contact unassigned error!', e.message);
          },
        });
    }
  }

   /** Track by Index  **/
   trackByIndex(index: number, item: any): number {
    return index;
  }
}
