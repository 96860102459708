export const FOLDER_TYPE = {
  PRE_COMPLETION: '/Pre Completion Photos',
  POST_COMPLETION: '/Post Completion Photos',
  OTHER: '/Other'
};

export const ALLOWED_IMAGE_TYPES = [
  'png',
  'jpg',
  'jpeg',
  'gif',
  'image/jpg',
  'image/jpeg',
  'xwd',
  'xpm',
  'mdi',
  'wdp',
  'image/png'
];

export const ALLOWED_DOCUMENT_TYPES = [
  'pdf',
  'docx',
  'doc',
  'xls',
  'csv',
  'xlsx',
  'eml',
  'bin'
];

export const ALLOWED_VIDEO_TYPES = [
  'mp4',
  'avi',
  'mpeg',
  'webm',
  'qt',
  'mp4s',
  'm4p',
  'mov',
  'application/mov',
  'application/wmv',
  'application/avi',
  'application/avcd',
  'application/flv',
  'application/f4v',
  'application/swf',
  'application/mkv',
  'application/webm',
  'application/html5',
  'video/quicktime'
];

export const ALLOWED_AUDIO_TYPE = ['audio', 'mpga', 'mp3'];

export const SHOW_LOADER_FOR = [
  'pdf',
  'docx',
  'doc',
  'xls',
  'csv',
  'xlsx',
  'png',
  'jpg',
  'jpeg',
  'gif'
];
