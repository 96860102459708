import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';

@Component({
    selector: 'profile-picture',
    templateUrl: 'profile-picture.component.html',
    styleUrls: ['profile-picture.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, MatIconModule]
})
export class ProfilePictureComponent implements OnInit {
  public isDefaultPicture: boolean = true;
  public readonly defaultImagePath: string = './assets/@fyxt/svgs/profile_default.svg';
  private readonly fileReader = new FileReader();
  private pImagePath!: string | null;
  @Output() onChanged: EventEmitter<File> = new EventEmitter<File>();

  @Input()
  set imagePath(path: string | null) {
    this.pImagePath = path;
    this.isDefaultPicture = !this.imagePath;
  }

  get imagePath(): string | null {
    return this.pImagePath;
  }

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.fileReader.onload = this.onFileReaderLoad.bind(this);
  }

  public onFileInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const files: FileList | null = input.files;

    if (!files || !files[0]) {
      return;
    }
    const file = files[0];
    this.onChanged.emit(file);
    this.fileReader.readAsDataURL(file);
    input.value = '';
  }

  public onImgError(): void {
    this.isDefaultPicture = true;
  }

  private onFileReaderLoad(progressEvent: ProgressEvent<FileReader>): void {
    const image: string | ArrayBuffer | null = progressEvent.target.result;

    if (typeof image !== 'string') {
      return;
    }

    this.imagePath = image;
    this.changeDetectorRef.markForCheck();
  }

}
