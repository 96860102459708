// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cursor-not-allowed {
  cursor: not-allowed;
}

.loader {
  top: 26%;
  right: 10px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 4px solid #fff;
  position: absolute;
  border-bottom-color: #cbcbcb;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.is_filter_search_input {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_reusable_ui/_controls/search-input/search-input.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,QAAA;EACA,WAAA;EACA,2BAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,kBAAA;EACA,4BAAA;EACA,kBAAA;EACA,qBAAA;EACA,sBAAA;EACA,sCAAA;AACF;;AAEA;EACE;IACE,uBAAA;EACF;EACA;IACE,yBAAA;EACF;AACF;AAEA;EACE,WAAA;AAAF","sourcesContent":[".cursor-not-allowed {\n  cursor: not-allowed;\n}\n\n.loader {\n  top: 26%;\n  right: 10px;\n  transform: translateY(-50%);\n  width: 20px;\n  height: 20px;\n  border: 4px solid #fff;\n  position: absolute;\n  border-bottom-color: #cbcbcb;\n  border-radius: 50%;\n  display: inline-block;\n  box-sizing: border-box;\n  animation: rotation 1s linear infinite;\n}\n\n@keyframes rotation {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n.is_filter_search_input{\n  width:100%\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
