import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from 'src/app/services/base.service';
import { ConfigService } from 'src/app/services/config.service';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../utility.service';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  currentUserInfo: any = localStorage.getItem('userobj') ? JSON.parse(localStorage.getItem('userobj')) : {};

  private subject: WebSocketSubject<any>;
  constructor(public _http: HttpClient, private _router: Router, private activatedRoute: ActivatedRoute, public _Config: ConfigService, public _utilService: UtilityService, public _baseService: BaseService) {
    // this.connect();
  }
  chatModalData: any[] = [];
  allChatDataList: any[] = [];
  allChatDataListPagination: any = {
    next: ''
  };
  allChatUserList: any[] = [];
  allExistingChatDetails: any[] = [];
  selectedChatUser: any[] = [];

  newChatItem: any;

  /************** Base HTTP Request Methods *****************/

  /**
   * To Get Http Request for REST API
   * @param URL 
   * @param Options  
   */
  doGET(URL?: string, Options?: any): Observable<any> {
    return this._http.get(URL, Options).pipe(take(1));
  }

  /**
   * To Post Http Request for REST API
   * @param URL   // The Endpoint URL
   * @param Body    //Post Input Body
   * @param Options   //Headers or Other Optional Params
   */
  doPOST(URL?: string, Body?: any, Options?: any) {
    return this._http.post(URL, Body, Options);
  }

  /**
   * To Update Http Request for REST API
   * @param URL 
   * @param Body 
   * @param Options 
   */
  doPUT(URL?: string, Body?: any, Options?: any) {
    return this._http.put(URL, Body, Options);
  }


  /**
   * To Delete Http Request for REST API
   * @param URL 
   * @param Options 
   */
  doDelete(URL?: string, Options?: any) {
    return this._http.delete(URL, Options);
  }

  /**
   * If Patch Request for Http Restfull API
   * @param URL 
   * @param Body 
   * @param Options 
   */
  doPatch(URL?: string, Body?: any, Options?: any) {
    return this._http.patch(URL, Body, Options);
  }


  // public connectSocketforListChat() {

  //   let requestURL = environment.socketURL + this._Config.getnewChatLsits;
  //   requestURL = requestURL.replace('{UserID}', this.currentUserInfo.id);

  //   this.newChatItem = webSocket({
  //     url: requestURL,
  //     openObserver: {
  //       next: (e) => {
  //         this.getAllChatData();
  //       }
  //     },
  //     closeObserver: {
  //       next: (e) => {
  //       }
  //     }
  //   });

  //   this.newChatItem.subscribe(
  //     msg => {

  //       let userIndexVal = this.allChatDataList.findIndex(obj => obj.id == msg.chat.id);

  //       if (userIndexVal != -1) {
  //         this.allChatDataList[userIndexVal] = msg.chat || {};
  //       }
  //       else {
  //         this.allChatDataList.unshift(msg.chat);
  //       }

  //       this.currentUserInfo.new_chats = msg.new_chats;

  //     },

  //   );

  // }

  getAllChatData(isLoader?: any, isPagination?: any) {
    let requestURL = '';
    if (isPagination) {
      if (this.allChatDataListPagination.next) {
        requestURL = this.allChatDataListPagination.next;
      }
      else {
        requestURL = environment.baseURL + this._Config.allChatList;
      }
    } else {
      requestURL = environment.baseURL + this._Config.allChatList;
    }
    this._Config.isLoader = isLoader;
    this.doGET(requestURL).subscribe((response: any) => {
      // this.allChatDataList = response.results || [];
      this._Config.isLoader = false;
      this.allChatDataListPagination.next = response.next ? response.next : '';
      let ListItems = response.results || [];

      if (ListItems.length > 0) {

        ListItems.forEach(element => {
          element.users = element.name.split(',');
        });
      }

      if (isPagination) {

        this.allChatDataList.push(...ListItems);

      }
      else {
        this.allChatDataList = ListItems;
      }

    },
      (error) => {});
  }

  chatModal(newChat?: any, data?: any) {
    let objectData = {};
    if (this.chatModalData.length > 2) {
      return false;
    }
    if (newChat) {
      let isNewChat = this.chatModalData.findIndex(x => x.isNew === true);
      if (isNewChat != -1) {
        return false;
      }
      objectData = {
        'isNew': newChat,
        'chatId': '',
        'data': data,
        'searchData': '',
        'isModal': true,
        'message': '',
        'chatHistory': [],
        'next': '',
        'socketData': '',
        'isNewMessageReceived': false
      }
      this.chatModalData.push(objectData);
      this.getAllChatUser('');
      this.scrollChatModal(this.chatModalData.length - 1);
    } else {
      objectData = {
        'isNew': newChat,
        'chatId': data.id,
        'data': data,
        'searchData': '',
        'isModal': true,
        'message': '',
        'chatHistory': [],
        'next': '',
        'socketData': '',
        'isNewMessageReceived': false
      }
      let indexData = this.chatModalData.findIndex(x => x.chatId === data.id);
      if (indexData == -1) {
        this.chatModalData.push(objectData);
        this.scrollChatModal(this.chatModalData.length - 1);
      }
    }
    let getIndexVal = this.chatModalData.indexOf(objectData);
    if (!newChat) {
      this.getExistingChatDetails(data.id, getIndexVal, false);
    }


  }

  scrollChatModal(tabID: any) {

    setTimeout(() => {
      document.querySelector('#chat_div_container_' + tabID).scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 200);

  }

  getAllChatUser(data?: any, indexVal?: any) {
    let requestURL = environment.baseURL + this._Config.chatUserList;
    this._Config.isLoader = true;
    requestURL = requestURL.replace('{query}', data);
    this.doGET(requestURL).subscribe((response: any) => {
      this.allChatUserList = response || [];
      this._Config.isLoader = false;
    },
      (error) => {});
  }



  getExistingChatDetails(id?: any, indexVal?: any, isPagination?: any) {
    let requestURL = '';
    if (isPagination) {
      if (this.chatModalData[indexVal].next) {
        requestURL = this.chatModalData[indexVal].next;
      }
      else {
        return false;
      }
    } else {
      requestURL = environment.baseURL + this._Config.getChatDetails;
      requestURL = requestURL.replace('{query}', id);
    }
    this.doGET(requestURL).subscribe((response: any) => {
      let chatHistoryData = response.results || [];
      this.chatModalData[indexVal].next = response.next ? response.next : '';
      if (isPagination) {
        chatHistoryData.forEach(element => {

          let isIndex = this.chatModalData[indexVal].chatHistory.findIndex(obj => obj.id == element.id);

          if (isIndex == -1)
            this.chatModalData[indexVal].chatHistory.push(element);

        });
      }
      else {
        chatHistoryData.forEach(element => {

          let isIndex = this.chatModalData[indexVal].chatHistory.findIndex(obj => obj.id == element.id);

          if (isIndex == -1)
            this.chatModalData[indexVal].chatHistory.push(element);

        });
        this.connectSocket(id, indexVal)
      }

      this.chatModalData[indexVal].ChatHistoryGrouped = this.makeGroupedHistory(this.chatModalData[indexVal].chatHistory);

    },
      (error) => {});
  }

  public connectSocket(id?: any, indexVal?: any) {

    let requestURL = environment.socketURL + this._Config.getAllChatLsits;
    requestURL = requestURL.replace('{Account_ID}', this.currentUserInfo.account);
    requestURL = requestURL.replace('{UserID}', this.currentUserInfo.id);
    requestURL = requestURL.replace('{ChatID}', id);

    this.chatModalData[indexVal].socketData = webSocket({
      url: requestURL,
      openObserver: {
        next: (e) => {}
      },
      closeObserver: {
        next: (e) => {}
      }
    });

    this.chatModalData[indexVal].socketData.subscribe(
      msg => {
        if (msg.channel == this.chatModalData[indexVal].chatId) {
          if (JSON.stringify(msg)) {
            var newDate = new Date().toISOString();
            let receivedMessage = {
              created_at: newDate,
              files: msg.files,
              id: msg.id,
              sender: {
                id: msg.sender.id,
                full_name: msg.sender.full_name
              },
              text: msg.text
            };

            var todayDate = formatDate(new Date(), 'y-M-d', 'en-US');
            if (this.chatModalData[indexVal].chatHistory.length == 0 && !this.chatModalData[indexVal].ChatHistoryGrouped) {
              let datePayload = {
                date: todayDate,
                dateString: "Today",
                chatHistory: [receivedMessage]
              }
              this.chatModalData[indexVal].ChatHistoryGrouped = [datePayload];
            } else {
              let isDateIndex = this.chatModalData[indexVal].ChatHistoryGrouped.findIndex(obj => obj.date == todayDate);
              if (isDateIndex == -1) {
                let datePayload = {
                  date: todayDate,
                  dateString: "Today",
                  chatHistory: [receivedMessage]
                }
                this.chatModalData[indexVal].ChatHistoryGrouped.unshift(datePayload);
              } else {
                this.chatModalData[indexVal].ChatHistoryGrouped[isDateIndex].chatHistory.unshift(receivedMessage);
              }
            }
            this.chatModalData[indexVal].isNewMessageReceived = false;
          }
        }
      },
      (err) => {},
      () => {}
    );

  }

  public send(msg: string, indexVal?: any, members_id?: any) {
    if (msg.trim()) {
      this.chatModalData[indexVal].socketData.next({
        "sender": this._baseService.currentUserInfo.id,
        "text": msg.trim(),
        "members_id": members_id
      });
      this.chatModalData[indexVal].isNewMessageReceived = true;
      this.chatModalData[indexVal].message = ' ';
    }
  }

  checkConnectionStatus(indexVal) {
    this.chatModalData[indexVal].socketData.compl
  }

  public disconnect(indexVal) {
    this.chatModalData[indexVal].socketData.complete();
  }



  makeGroupedHistory(data: any) {

    // this gives an object with dates as keys
    const groups = data.reduce((groups, chat) => {
      const date = chat.created_at.split('T')[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(chat);
      return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        dateString: this.time_ago(new Date(date)),
        chatHistory: groups[date]
      };
    });

    return groupArrays;

  }



  time_ago(time) {
    let getDate = time;
    switch (typeof time) {
      case 'number':
        break;
      case 'string':
        time = +new Date(time);
        break;
      case 'object':
        if (time.constructor === Date) time = time.getTime();
        break;
      default:
        time = +new Date();
    }
    var todayDate = formatDate(new Date(), 'y-M-d', 'en-US');
    var getChatCurrentDate = formatDate(new Date(getDate), 'y-M-d', 'en-US');
    var time_formats = [
      [60, 'seconds', 1], // 60
      [120, '1 minute ago', '1 minute from now'], // 60*2
      [3600, 'minutes', 60], // 60*60, 60
      [7200, '1 hour ago', '1 hour from now'], // 60*60*2
      [86400, 'hours', 3600], // 60*60*24, 60*60
      [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
      [604800, 'days', 86400], // 60*60*24*7, 60*60*24
      [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
      [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
      [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
      [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
      [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
      [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
      [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
      [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];
    var seconds = (+new Date() - time) / 1000,
      token = 'ago',
      list_choice = 1;

    if (seconds == 0) {
      return 'Just now'
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      token = 'from now';
      list_choice = 2;
    }
    if (todayDate == getChatCurrentDate) {
      return 'Today';
    }
    if (seconds > 172800) {
      var getChatDate = new Date(getDate);
      return formatDate(getChatDate, 'MMMM d', 'en-US');
    }
    var i = 0,
      format;
    while (format = time_formats[i++])
      if (seconds < format[0]) {
        if (typeof format[2] == 'string')
          return format[list_choice];
        else
          return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
      }
    return time;
  }



}
