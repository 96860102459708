import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { AppTableSource } from 'src/app/@fyxt/_shared/models/app-table-model';
import { Observable, Subject } from 'rxjs';
import { DetailedTemplateViewComponent } from '../detailed-template-view/detailed-template-view.component';
import { DialogService } from '../../../../_services/dialog/dialog.service';
import { map, takeUntil } from 'rxjs/operators';
import { initRuleTemplateParams, RuleTemplateStoreService } from '../../../../_services/leases/rule-template/rule-template-store.service';
import { ConfigService } from '../../../../../services/config.service';
import { initLeaseParams } from '../../../../_services/leases/leases-store.service';
import { ruleTemplateTableHeaders } from './browse-templates-dialog.interface';
import { IRuleTemplate, IRuleTemplateOptionsItem } from '../../../../_services/leases/rule-template/rule-template-graphql.interface';
import { TableComponent } from '../../../_reusable_ui/_components/table/table.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';


@Component({
    selector: 'fyxt-browse-templates-dialog',
    templateUrl: './browse-templates-dialog.component.html',
    styleUrls: ['./browse-templates-dialog.component.scss'],
    standalone: true,
    imports: [SearchInputComponent, NgIf, ButtonComponent, MatMenuModule, MatIconModule, MatDialogModule, LoaderSmallComponent, TableComponent, AsyncPipe]
})
export class BrowseTemplatesDialogComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<null> = new Subject();

  public templates$ = this.templateService.ruleTemplates$;
  public currentTemplatesList: IRuleTemplate[] = [];
  public checkedTemplates: number[] = [];
  public currentLeaseId: number;
  public isLoader: boolean = false;

  public readonly dataSource$: Observable<AppTableSource> = this.templates$.pipe(
    map((templateData) => {
      this.currentTemplatesList = templateData.rule_templates;

      return {
        headers: ruleTemplateTableHeaders,
        rows: templateData.rule_templates as any[],
        length: templateData?.total || 0,
        pageSize: templateData?.size || 0,
        pageIndex: templateData.page ? templateData.page - 1 : 0
      }
    })
  );

  constructor(
    public _dialogService: DialogService,
    public dialogRef: MatDialogRef<BrowseTemplatesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly templateService: RuleTemplateStoreService,
    private readonly _config: ConfigService,
  ) {}


  ngOnInit(): void {
    const { lease_id } = this.data;
    this.currentLeaseId = +lease_id;
    this.getTemplates();
  }

  ngOnDestroy(): void {
    this.templateService.clear();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private getTemplates(): void {
    this.isLoader = true;
    this.templateService.loadTemplateData(this.currentLeaseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isLoader = false;
      });
  }

  private getTemplatesWithUpdatedParams(params: IRuleTemplateOptionsItem): void {
    this.isLoader = true;
    this.templateService.setQueryTemplatesParams(params, this.currentLeaseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (value) => {
          this.isLoader = false;
        }
      });
  }

  public onChangePagination(paginationDetails): void {
    const { pageIndex, pageSize } = paginationDetails;
    const page = pageIndex + 1;
    const take = pageSize;
    this.getTemplatesWithUpdatedParams({ page, take })
  }

  public onSortChange(sortState: Sort): void {
    const { active: sort, direction: order } = sortState;
    this.getTemplatesWithUpdatedParams({ sort, order });
  }

  public onSearchChange(name): void {
    const len = name.length;

    if (len < 3) {
      if (len === 0) {
        this.getTemplatesWithUpdatedParams({
          ...initRuleTemplateParams,
        });
      }
      return;
    }

    this.getTemplatesWithUpdatedParams({
      ...initLeaseParams,
      search: name,
    });
  }

  public onTemplateDelete(data): void {
    this.isLoader = true;

    this.templateService.deleteRuleTemplate(data.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.checkedTemplates = this.checkedTemplates.filter((id) => id !== data.id);
        this.getTemplates();
      });

  }

  public onChangeAllSelect(isSelected: any){
    if (isSelected) {
      this.checkedTemplates = this.currentTemplatesList.map(({id}) => id);
    } else {
      this.checkedTemplates = [];
    }
  }

  public onBrowseTemplateCheckRow(template){
    const { id: templateId } = template;
    const isBrowseTemplateChecked = this.checkedTemplates.some((id) => id === templateId);

    if (isBrowseTemplateChecked) {
      this.checkedTemplates = this.checkedTemplates.filter((id) => id !== templateId);
    } else {
      this.checkedTemplates = [...this.checkedTemplates, templateId];
    }
  }

  public deleteSelectedTemplates(): void {
    this.isLoader = true;

    this.templateService.multipleDeleteRuleTemplates(this.checkedTemplates)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.getTemplates();
        this.checkedTemplates = [];
      });

  }

  public onRowClick(event: any) {
    this.templateService.selectTemplate(event);
    this.checkedTemplates = [];

    let popupData = {
      title: event.template_name,
      component: DetailedTemplateViewComponent,
      containerClass: ['custom-rule-class'],
      data: event
    };

    this._dialogService.openModal(popupData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        const { delete_template, cancel, apply_template } = res;
        if (delete_template) {
          this.getTemplates();
        }
        if (cancel) {
          this.dialogRef.close();
        }
        if (apply_template) {
          this.dialogRef.close({ apply_template });
        }
      });
  }

}
