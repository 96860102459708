import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';

import { TableColumnTypes,ColumnSortType } from 'src/app/@fyxt/_shared/enums/table/table-column-types.enum';
import { AppTableRequest, AppTableSource } from 'src/app/@fyxt/_shared/models/app-table-model';

import { EditColumnsComponent } from 'src/app/@fyxt/_shared/_views';
import { GraphqlAdditionalDataService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/additional-data/graphql-additional-data.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TableService } from 'src/app/@fyxt/_services/table/table.service';
import { TableComponent } from '../../../_reusable_ui/_components/table/table.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { NgIf } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';

@Component({
    selector: 'fyxt-tab-associated-equipment-types',
    templateUrl: './tab-associated-equipment-types.component.html',
    styleUrls: ['./tab-associated-equipment-types.component.scss'],
    standalone: true,
    imports: [SearchInputComponent, MatDividerModule, NgIf, LoaderSmallComponent, TableComponent]
})
export class TabAssociatedEquipmentTypesComponent implements OnInit {

  dataSource!: AppTableSource;
  searchInput:string = '';
  isLoader:boolean = false;
  listAssociatedEquipmentTypes:any[];
  selectedViewTableRowsTotal: number;
  selectedViewTableRowsPageSize: number;
  selectedViewTableRowsPage: number;
  count:number;
  moduleName ="equipments";
  sort = {
    active: '',
    direction: ''
  };

  tableHeaders = [
    {
      value: 'name',
      sort: ColumnSortType.none,
      name: 'Equipment Type',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'manufacturer_name',
      sort: ColumnSortType.none,
      name: 'Manufacturer',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
  ];


  constructor(
    public _dialogService: DialogService,
    private _router: Router,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    public _graphql:GraphqlAdditionalDataService,
    public tableService: TableService
  ) { }

  ngOnInit(): void {
    this.loadAssocicatedEquipmentTypes(this.searchInput,1);
  }


  loadAssocicatedEquipmentTypes(searchInput:string, pageNumber:number, sortColumn?:any, sortDirection?:any, pageSize?: any){
    this.isLoader = true;
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this._graphql.associatedEquipmentTypeListing(id, searchInput, pageNumber, sortColumn, sortDirection, pageSize).subscribe({
      next: (res:any)=>{
        if(res){
          this.listAssociatedEquipmentTypes = res.listAdditionalItemsEquipmentType?.items || [];
          if(!this.count)this.count =   res.listAdditionalItemsEquipmentType?.total;
          this.selectedViewTableRowsTotal =  res.listAdditionalItemsEquipmentType?.total;
          this.selectedViewTableRowsPageSize = res.listAdditionalItemsEquipmentType?.size;
          this.selectedViewTableRowsPage = res.listAdditionalItemsEquipmentType?.page;
        }
      },
      error(err:HttpErrorResponse) {},
      complete:()=>{
        this.isLoader = false;
        if(sortColumn || sortDirection ){
          let sortedHeaders:any[] = this.tableHeaders.map(element => {
            (element.value === sortColumn) ? (element.sort = sortDirection) : (element.sort = ColumnSortType.none );

            return element;
          });
          this.dataSource = this.tableService.setTableData(this.moduleName,sortedHeaders, this.listAssociatedEquipmentTypes, this.selectedViewTableRowsTotal,this.selectedViewTableRowsPage-1, this.selectedViewTableRowsPageSize);
        }
        else{
          this.tableHeaders.map(element => {
            return element.sort = ColumnSortType.none;
          });
          this.dataSource = this.tableService.setTableData(this.moduleName,this.tableHeaders, this.listAssociatedEquipmentTypes, this.selectedViewTableRowsTotal,this.selectedViewTableRowsPage-1, this.selectedViewTableRowsPageSize);
        }
      }
    });
  }

  onSearch(e:string){
    this.searchInput = e;
    this.sort = {
      active: undefined,
      direction: undefined
    };
    this.loadAssocicatedEquipmentTypes(this.searchInput,1, this.sort.active, this.sort.direction, this.selectedViewTableRowsPageSize);
  }

  onRowClick(event: any) {
    this._router.navigate(['equipmentType', event.id], { relativeTo: this.route });
  }

  changeEvent(event: AppTableRequest): void {}

  onPageChange(event:any){
    this.selectedViewTableRowsPage = event.pageIndex + 1;
    this.selectedViewTableRowsPageSize = event.pageSize;
    this.loadAssocicatedEquipmentTypes(this.searchInput,this.selectedViewTableRowsPage, this.sort.active, this.sort.direction, this.selectedViewTableRowsPageSize);
  }

  onSortChange(e:any){
    this.sort.active = e.active;
    this.sort.direction = e.direction;
    this.loadAssocicatedEquipmentTypes(this.searchInput,1, this.sort.active, this.sort.direction, this.selectedViewTableRowsPageSize);
  }

}
