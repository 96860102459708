import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RadioButtonItem } from "../../../_reusable_ui/_controls";
import { environment } from "../../../../../../environments/environment";
import { ConfigService } from "../../../../../services/config.service";
import { DialogService } from "../../../../_services/dialog/dialog.service";
import { HttpErrorResponse } from "@angular/common/http";
import { UtilityService } from "../../../../../services/utility.service";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag, CdkDragHandle } from "@angular/cdk/drag-drop";
import {
  JobCostApprovalService
} from "../../../../../Modules/_fyxt_modules/jobcost-approval/services/job-cost-approval.service";
import {AddCommentComponent} from "../add-comment/add-comment.component";
import {
  ApprovalScenarioConfirmationComponent
} from "../approval-scenario-confirmation/approval-scenario-confirmation.component";
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { CheckboxComponent } from '../../../_reusable_ui/_controls/checkbox/checkbox.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DropdownComponent } from '../../../_reusable_ui/_controls/dropdown/dropdown.component';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';

@Component({
    selector: 'fyxt-add-approval-scenario',
    templateUrl: './add-approval-scenario.component.html',
    styleUrls: ['./add-approval-scenario.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, TextboxComponent, NgxIntlTelInputModule, NgIf, DropdownComponent, NgClass, NgSelectModule, CheckboxComponent, CdkDropList, NgFor, CdkDrag, CdkDragHandle, ButtonComponent, MatIconModule, MatRadioModule, MatDialogModule]
})
export class AddApprovalScenarioComponent implements OnInit {
  addApprovalScenarioForm!: FormGroup;
  radioItems = [
    { name: 'Yes', value: 'Yes', checked: false },
    { name: 'No', value: 'No', checked: true }
  ];
  serviceTypes = [];
  categories = [];
  overThresholdAssignee: any = {
    pageNumber: 1,
    searchValue: '',
    assigneeItems: []
  };
  levelsApprovedAssignee: any;
  serviceTypeLoader = false;
  categoriesTypeLoader = false;
  overThresholdAssigneeLoader = false;
  levelsApprovedAssigneeLoader = false;
  updateScenario = false;
  selectedScenarioDetails: any;
  formSubmitted = false;
  levelAmountLessThanPrevious = false;
  lessAmountLevelIndex: number;
  scenarioSubmitLoader = false;
  dragAndDropLevelsChanged = false;
  isSelectAllCategories = false;
  isSelectAllOverThresholdAssignee = false;

  constructor(public formBuilder: FormBuilder, public _Config: ConfigService, public _dialogService: DialogService,
    public _utilService: UtilityService, public jobCostApprovalService: JobCostApprovalService,
    public dialogRef: MatDialogRef<AddApprovalScenarioComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.updateScenario = this.data?.data?.updateScenario;
    this.initializeForm();
  }

  ngOnInit(): void {
    this.getAllServiceTypes();
    this.getAllCategories();
    this.getAllOverThresholdAssignee();
    this.getLevelApprovedAssignee();
  }

  getAllServiceTypes(): void {
    this.serviceTypeLoader = true;
    let requestURL = environment.LocalDev_URL_V2 + 'approval-workflow/service-type-dropdown/ ';
    this._dialogService.doGET(requestURL).subscribe(
      {
        next: (response: any) => {
          response.map((service: any) => {
            // As per the ticket FCP-11734 we are commenting this condition
            // service.jobCost_approval_scenario_exist = service.is_assigned;
            // service.disabled = service.is_assigned;
            return service;
          });
          this.serviceTypes = response;
          // let defaultServiceType: any = response.find(element => element.is_default) || {};
          // this.addApprovalScenarioForm.get('service_types').patchValue(defaultServiceType?.id);
        },
        error: (error: HttpErrorResponse) => {
          this.serviceTypeLoader = false;
          this.serviceTypes = [];
          this._utilService.showErrorMessage(error);
        },
        complete: () => {
          this.serviceTypeLoader = false;
        }
      }
    );
  }

  getAllCategories(): void {
    this.categoriesTypeLoader = true;
    let requestURL = environment.baseURL + 'categories/';
    this._dialogService.doGET(requestURL).subscribe(
      {
        next: (response: any) => {
          this.categories = response;
        },
        error: (error: HttpErrorResponse) => {
          this.categoriesTypeLoader = false;
          this.categories = [];
          this._utilService.showErrorMessage(error);
        },
        complete: () => {
          this.categoriesTypeLoader = false;
        }
      }
    );
  }

  getAllOverThresholdAssignee(): void {
    this.overThresholdAssigneeLoader = true;
    let requestURL = environment.LocalDev_URL_V2 + `drop-down/assignees/?page=${this.overThresholdAssignee.pageNumber}`;
    if (this.overThresholdAssignee.searchValue.trim() !== '') {
      requestURL += `&search=${this.overThresholdAssignee.searchValue}`
    }
    this._dialogService.doGET(requestURL).subscribe(
      {
        next: (response: any) => {
          if (this.overThresholdAssignee.pageNumber > 1) {
            this.overThresholdAssignee.assigneeItems = this.overThresholdAssignee.assigneeItems.concat(response.result);
          } else {
            this.overThresholdAssignee.assigneeItems = response.result;
          }
        },
        error: (error: HttpErrorResponse) => {
          this.overThresholdAssigneeLoader = false;
          this.overThresholdAssignee.assigneeItems = [];
          this._utilService.showErrorMessage(error);
        },
        complete: () => {
          this.overThresholdAssigneeLoader = false;
        }
      }
    );
  }

  getLevelApprovedAssignee(): void {
    this.levelsApprovedAssigneeLoader = true;
    let requestURL = environment.LocalDev_URL_V2 + `approval-workflow/approvers-dropdown/`;
    this._dialogService.doGET(requestURL).subscribe(
      {
        next: (response: any) => {
          response.groups.map((group: any) => {
            group.isGroup = true;
            return group;
          });
          response.managers.map((manager: any) => {
            manager.name = manager.first_name + ' ' + manager.last_name;
            manager.isManager = true;
            return manager;
          });
          const allLevelAssignee = [
            {
              id: 1,
              name: 'User Roles',
              subunits: response.groups
            },
            {
              id: 2,
              name: 'Managers',
              subunits: response.managers
            }
          ];

          this.levelsApprovedAssignee = allLevelAssignee;
        },
        error: (error: HttpErrorResponse) => {
          this.levelsApprovedAssigneeLoader = false;
          this._utilService.showErrorMessage(error);
        },
        complete: () => {
          this.levelsApprovedAssigneeLoader = false;
        }
      }
    );

  }

  overThresholdAssigneeDropdownScroll(event: any) {
    this.overThresholdAssignee.pageNumber++;
    this.getAllOverThresholdAssignee();
  }

  overThresholdAssigneeDropdownSearch(event: any) {
    this.overThresholdAssignee.searchValue = event.term;
    this.overThresholdAssignee.pageNumber = 1;
    this.getAllOverThresholdAssignee()
  }

  initializeForm(): void {
    this.addApprovalScenarioForm = this.formBuilder.group({
      scenario_name: ['', Validators.required],
      service_types: [null, Validators.required],
      categories: [null, Validators.required],
      threshold_send_to: [null, Validators.required],
      target_cost_approver: [null],
      bid: [false],
      invoice: [false],
      change_order: [false],
      levels: this.formBuilder.array(this.updateScenario ? [] : [this.addLevels()])
    });
    if (this.updateScenario) {
      this.getScenarioDetails();
    }
  }

  getScenarioDetails(): void {
    this.jobCostApprovalService.getSingleScenarioDetails(this.data?.data?.selectedScenarioDetails.id).subscribe({
      next: (response: any) => {
        this.selectedScenarioDetails = response;
        if (this.selectedScenarioDetails.categories.length == this.categories.length) {
          this.isSelectAllCategories = true;
        }
        if (this.selectedScenarioDetails.over_threshold_approver.length == this.overThresholdAssignee?.assigneeItems.length) {
          this.isSelectAllOverThresholdAssignee = true;
        }
      },
      error: (error) => { },
      complete: () => {
        this.setFormData();
      }
    });

  }

  addLevels(): any {
    return this.formBuilder.group({
      can_approve_upto: ['', Validators.required],
      skip_threshold: ['No', Validators.required],
      must_be_approved_by: [null, Validators.required],
    });
  }

  get scenarioLevelsArray() {
    const control = this.addApprovalScenarioForm.get('levels') as FormArray;
    return control;
  }

  addMoreLevels(): void {
    let isExistIndex = this.scenarioLevelsArray.value.findIndex(obj => !obj.can_approve_upto);
    if (isExistIndex == -1) {
      if (this.scenarioLevelsArray.status == 'VALID') {
        this.formSubmitted = false;
        // Check if the last level amount should greater than previous level amount Code starts
        this.checkIfAmountGreaterThanPrevious(this.scenarioLevelsArray.value);
        if (this.scenarioLevelsArray.value.length >= 2) {
          if (this.levelAmountLessThanPrevious) {
            this._utilService.showError('', `Level ${this.lessAmountLevelIndex + 1} amount should greater than Level ${this.lessAmountLevelIndex} amount`);
            return null;
          } else {
            this.scenarioLevelsArray.push(this.addLevels());
          }
        } else {
          this.scenarioLevelsArray.push(this.addLevels());
        }
        // code End

        // If the above logic not needed means just comment that above block and uncomment below line
        // this.scenarioLevelsArray.push(this.addLevels());
      }
    }
  }

  checkIfAmountGreaterThanPrevious(data) {
    this.levelAmountLessThanPrevious = false;
    this.lessAmountLevelIndex = null;
    for (let i = 1; i < data.length; i++) {
      if (Number(data[i].can_approve_upto) < Number(data[i - 1].can_approve_upto)) {
        this.levelAmountLessThanPrevious = true;
        this.lessAmountLevelIndex = i;
        break;
      }
    }
  }

  deleteLevel(index: any): void {
    this.scenarioLevelsArray.removeAt(index);
  }

  skipThreshold(event: any, index: any, item: any) {
    item.value.skip_threshold = event;
    item.controls.skip_threshold.value = event;
  }

  setFormData(): void {
    const getServiceTypeIds = this.selectedScenarioDetails.service_types.map((serviceType: any) => {
      return serviceType.id;
    });
    const getCategoryIds = this.selectedScenarioDetails.categories.map((category: any) => {
      return category.id;
    });
    // const oveThresholdAssigneeIds = this.selectedScenarioDetails.over_threshold_approver.map((assignee: any) => {
    //   return assignee.id;
    // });

    let oveThresholdSendTo = [];
    let oveThresholdRoles = this.selectedScenarioDetails.over_threshold_approver.assigned_roles.map((obj: any) => {
      obj.isGroup = true;
      return obj;
    }) || [];
    let oveThresholdManagers = this.selectedScenarioDetails.over_threshold_approver.assigned_managers.map((obj: any) => {
      obj.name = obj.first_name +' '+obj.last_name;
      obj.isManager = true;
      return obj;
    }) || [];
    oveThresholdSendTo = [...oveThresholdRoles,...oveThresholdManagers];

    let targetCostApprovers = [];
    let targetCostApprovers_Roles = this.selectedScenarioDetails.target_cost_approver.assigned_roles.map((obj: any) => {
      obj.isGroup = true;
      return obj;
    }) || [];
    let targetCostApprovers_Managers = this.selectedScenarioDetails.target_cost_approver.assigned_managers.map((obj: any) => {
      obj.name=obj.first_name+' '+obj.last_name;
      obj.isManager = true;
      return obj;
    }) || [];
    targetCostApprovers = [...targetCostApprovers_Roles,...targetCostApprovers_Managers];

    this.selectedScenarioDetails.target_cost_approver.assigned_roles.map(obj => obj.id)
    this.addApprovalScenarioForm.patchValue({
      scenario_name: this.selectedScenarioDetails.name,
      service_types: getServiceTypeIds,
      categories: getCategoryIds,
      threshold_send_to: oveThresholdSendTo,
      target_cost_approver: targetCostApprovers,
      bid: this.selectedScenarioDetails.cost_types.includes('Bid'),
      invoice: this.selectedScenarioDetails.cost_types.includes('Invoice'),
      change_order: this.selectedScenarioDetails.cost_types.includes('Change Order'),
    });
    this.addApprovalScenarioForm.setControl('levels', this.setLevels(this.selectedScenarioDetails.levels));
  }


  setLevels(levels): any {
    const formArray = new FormArray([]);
    levels.forEach((level) => {
      const managers = level.assigned_managers.map((manager: any) => {
        manager.name = manager.first_name + ' ' + manager.last_name;
        manager.isManager = true;
        return manager;
      });
      const groups = level.assigned_roles.map((group: any) => {
        group.isGroup = true;
        return group;
      });
      const levelAssigneeUsers = groups.concat(managers) || [];
      formArray.push(this.formBuilder.group({
        can_approve_upto: [level.amount, Validators.required],
        skip_threshold: [level.skip_threshold == true ? 'Yes' : 'No', Validators.required],
        must_be_approved_by: [levelAssigneeUsers, Validators.required]
      }));
    });
    return formArray;
  }

  submitScenario(): void {
    // Check if the last level amount should greater than previous level amount Code starts
    this.checkIfAmountGreaterThanPrevious(this.scenarioLevelsArray.value);
    if (this.scenarioLevelsArray.value.length >= 2) {
      if (this.levelAmountLessThanPrevious) {
        this._utilService.showError('', `Level ${this.lessAmountLevelIndex + 1} amount should greater than Level ${this.lessAmountLevelIndex} amount`);
        return null;
      }
    }
    // code End

    this.formSubmitted = true;
    if (this.addApprovalScenarioForm.invalid) {
      return null;
    }
    let scenarioLevels = this.addApprovalScenarioForm.value.levels.filter((obj: any) => obj.can_approve_upto).map((obj: any, i: any) => {
      const groupsId = obj?.must_be_approved_by?.filter((assignee: any) => {
        return assignee.isGroup == true;
      }).map((group: any) => {
        return group.id;
      }) || [];
      const managersId = obj?.must_be_approved_by?.filter((assignee: any) => {
        return assignee.isManager == true;
      }).map((manager: any) => {
        return manager.id;
      }) || [];
      return {
        level: i + 1,
        amount: obj.can_approve_upto,
        skip_threshold: obj.skip_threshold == 'Yes' ? true : false,
        assigned_roles: groupsId.filter((group: any) => {
          return group != null;
        }),
        assigned_managers: managersId.filter((manager: any) => {
          return manager != null;
        }),
      }
    });
    let costTypes = [];
    if (this.addApprovalScenarioForm.value.bid) {
      costTypes.push("Bid");
    }
    if (this.addApprovalScenarioForm.value.change_order) {
      costTypes.push("Change Order");
    }
    if (this.addApprovalScenarioForm.value.invoice) {
      costTypes.push("Invoice");
    }
    if (costTypes.length == 0) {
      this._utilService.showError('', 'Please select atleast one Transaction types');
      return null;
    }

    let target_cost_approver: any;
    if (this.addApprovalScenarioForm.value.target_cost_approver) {
      target_cost_approver = {
        "assigned_roles": this.addApprovalScenarioForm.value.target_cost_approver.filter((obj: any) => obj.isGroup).map((obj: any) => obj.id) || [],
        "assigned_managers": this.addApprovalScenarioForm.value.target_cost_approver.filter((obj: any) => obj.isManager).map((obj: any) => obj.id) || [],
      }
    } else {
      target_cost_approver = null;
    }

    let overThresholdSendTo = {
      "assigned_roles": this.addApprovalScenarioForm.value.threshold_send_to.filter((obj: any) => obj.isGroup).map((obj: any) => obj.id) || [],
      "assigned_managers": this.addApprovalScenarioForm.value.threshold_send_to.filter((obj: any) => obj.isManager).map((obj: any) => obj.id) || [],
    }

    let payload:any = {
      name: this.addApprovalScenarioForm.value.scenario_name?.trim(),
      service_types: this.addApprovalScenarioForm.value.service_types,
      categories: this.addApprovalScenarioForm.value.categories,
      // over_threshold_approver: this.addApprovalScenarioForm.value.threshold_send_to,
      over_threshold_approver: overThresholdSendTo,
      // target_cost_approver: target_cost_approver,
      cost_types: costTypes,
      levels: scenarioLevels
    }
    this.scenarioSubmitLoader = true;
    if (this.updateScenario) {
      payload['id'] = this.selectedScenarioDetails.id;

      let isNewApproverExist=this.addApprovalScenarioForm.value.target_cost_approver.filter(o1 => (!this.selectedScenarioDetails.target_cost_approver.assigned_managers.some(o2 => o1.id === o2.id) && !this.selectedScenarioDetails.target_cost_approver.assigned_roles.some(o2 => o1.id === o2.id))) || [];

      // if(isNewApproverExist?.length>0) {
      //   payload.target_cost_approver = target_cost_approver ? target_cost_approver : {};
      // }
      payload.target_cost_approver = (target_cost_approver.assigned_roles.length == 0 && target_cost_approver.assigned_managers.length == 0) ? {} : target_cost_approver;

      // If we didn't change any values in the levels form array while updating the scenario we delete the levels key from the payload. Code Starts
      if (this.addApprovalScenarioForm.controls.levels.pristine == true && !this.dragAndDropLevelsChanged && this.selectedScenarioDetails.levels.length === this.addApprovalScenarioForm.value.levels.length) {
        delete payload['levels'];
      }
      // Code End
      this.openApprovalConfirmationModal(payload);
    } else {
      payload.target_cost_approver = target_cost_approver ? target_cost_approver : {};
      this.createApprovalScenario(payload);
    }
  }

  createApprovalScenario(payload): any {
    this.jobCostApprovalService.createScenario(payload).subscribe({
      next: (response: any) => {
      },
      error: (error) => {
        this.scenarioSubmitLoader = false;
        this._utilService.showErrorMessage(error);
      },
      complete: () => {
        this._utilService.showSuccess('', "Approval Scenario created successfully");
        const result = {
          created: true
        }
        this.scenarioSubmitLoader = false;
        this.formSubmitted = false;
        this.dragAndDropLevelsChanged = false;
        this.dialogRef.close(result);
      }
    });
  }

  updateApprovalScenario(payload: any): void {
    this.jobCostApprovalService.updateScenario(payload, this.selectedScenarioDetails.id).subscribe({
      next: (response: any) => {
      },
      error: (error) => {
        this.scenarioSubmitLoader = false;
        this._utilService.showErrorMessage(error);
      },
      complete: () => {
        this._utilService.showSuccess('', "Approval Scenario updated successfully");
        this.formSubmitted = false;
        this.scenarioSubmitLoader = false;
        this.dragAndDropLevelsChanged = false;
        const result = {
          updated: true
        }
        this.dialogRef.close(result);
      }
    });
  }

  closePopup(): void {
    this.formSubmitted = false;
    this.levelAmountLessThanPrevious = false;
    this.lessAmountLevelIndex = null;
    this.dragAndDropLevelsChanged = false;
    this.dialogRef.close();
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.scenarioLevelsArray.controls, event.previousIndex, event.currentIndex);
    moveItemInArray(this.addApprovalScenarioForm.get("levels").value, event.previousIndex, event.currentIndex);
    // this.addApprovalScenarioForm.value.levels = this.scenarioLevelsArray.controls.map((level: any) => {
    //   return level.value;
    // });
    this.dragAndDropLevelsChanged = true;
    this.levelAmountLessThanPrevious = false;
    this.lessAmountLevelIndex = null;
  }

  get formControl() {
    return this.addApprovalScenarioForm.controls;
  }

  focusInThresholdAssignee(event: any): void {
    this.overThresholdAssignee.searchValue = '';
    this.overThresholdAssignee.pageNumber = 1;
    this.getAllOverThresholdAssignee();
  }

  onRemoveServiceTypes(event: any): void {
    this.serviceTypes.map((service: any) => {
      if (service.id == event.id) {
        service.is_assigned = false;
        service.jobCost_approval_scenario_exist = false;
        service.disabled = false;
        return service;
      }
    });
  }

  openApprovalConfirmationModal(payload: any): void {
    let popupData = {
      title: 'Edit Approval Scenario',
      component: ApprovalScenarioConfirmationComponent,
      containerClass: 'modal-approval-confirmation',
      data: {}
    };
    this._dialogService.openModal(popupData).subscribe((result: any) => {
      if (result) {
        if (result?.name == 'Update Current & Future Jobs') {
          payload['apply_only_future_costs'] = false;
        } else if (result?.name == 'Update Future Jobs Only') {
          payload['apply_only_future_costs'] = true;
        }
        this.updateApprovalScenario(payload);
      } else {
        this.formSubmitted = false;
        this.scenarioSubmitLoader = false;
      }
    });
  }



  /** Track by Index  **/
  trackByIndex(index: number, item: any): number {
    return index;
  }
}
