import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class APIBaseService {
  constructor() {}

  prepareUrl(path: string): string {
    return environment.baseURL + path;
  }

  addQueryParamMailBoxId(path: string, id: string): string {
    return path + `?mailbox=${id}`;
  }

  updateId(path: string, id: string): string {
    return path.replace('{ID}', id);
  }
}
