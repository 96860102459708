import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {DialogService} from 'src/app/@fyxt/_services/dialog/dialog.service';
import {InboxCreateSnippetComponent, RadioButtonItem} from 'src/app/@fyxt/_shared/_views';
import {InboxSettingsService} from 'src/app/Modules/_fyxt_modules/inbox-management/_services/inbox-settings.service';
import {EnumTemplatesCategory, Template} from 'src/app/Modules/_fyxt_modules/inbox-management/inbox.types';
import {ColumnSortType, TableColumnTypes} from "../../../enums/table/table-column-types.enum";
import {TableService} from "../../../../_services/table/table.service";
import {SelectionModel} from "@angular/cdk/collections";
import {AppTableRow} from "../../../models/app-table-model";
import {UtilityService} from "../../../../../services/utility.service";
import {InboxService_New} from "../../../../../Modules/_fyxt_modules/inbox-management/_services/inbox.service";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import { MatDialogModule } from '@angular/material/dialog';
import { AngularEditorComponent } from '../../../_reusable_ui/_components/angular-editor/angular-editor.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { MatDividerModule } from '@angular/material/divider';
import { ThreeDotsDropdownComponent } from '../../../_reusable_ui/_components/three-dots-dropdown/three-dots-dropdown.component';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';

@Component({
    selector: 'fyxt-tab-inbox-snippets',
    templateUrl: './tab-inbox-snippets.component.html',
    styleUrls: ['./tab-inbox-snippets.component.scss'],
    standalone: true,
    imports: [SearchInputComponent, ButtonComponent, MatIconModule, NgIf, ThreeDotsDropdownComponent, MatDividerModule, LoaderSmallComponent, NgFor, NgClass, TextboxComponent, FormsModule, NgxIntlTelInputModule, AngularEditorComponent, MatDialogModule]
})
export class TabInboxSnippetsComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<null> = new Subject();
  threeDotDropdownMenuLists: { value: string, label: string }[] = [
    {label: 'Delete', value: 'Delete'},
  ];
  isLoader = false;
  allSnippets = [];
  allSnippetForLocalSearch = [];
  selectedSnippet: any;
  searchInput = '';
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        [{list: 'ordered'}, {list: 'bullet'}],
        [{indent: '-1'}, {indent: '+1'}],
        [{direction: 'rtl'}],
        [{header: [1, 2, 3, 4, 5, 6, false]}],
        [{color: []}, {background: []}],
        [{font: []}],
        [{align: []}],
        ['clean']
      ]
    },
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ['@', '#'],
      theme: 'snow',
      isolateCharacter: true,  //using email to editor
      offsetTop: 0,
      defaultMenuOrientation: "top",
      renderLoading: () => {
        return "Loading...";
      },
      'emoji-toolbar': true,
      'emoji-textarea': false,
      'emoji-shortname': true,
      keyboard: {
        bindings: {
          enter: {
            key: 13,
            handler: (range, context) => {
                return true;
            }
          }
        }
      }
    }
  };
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    showToolbar: true,
    minHeight: '8rem',
    height: '6rem',
    toolbarPosition: 'top',
    sanitize: false,
    outline: false,
    placeholder: 'Write something here…',
    translate: 'no',
    defaultParagraphSeparator: 'div',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'subscript',
        'superscript',
        'customClasses',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
        'fontSize',
        'undo',
        'redo',
        'indent',
        'outdent',
      ]
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote'
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1'
      }
    ]
  };

  constructor(public _dialogService: DialogService, private _inboxSettings: InboxSettingsService,
              public _utilService: UtilityService, private _inboxService: InboxService_New) {
  }

  ngOnInit(): void {
    this.getAllSnippets();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private getAllSnippets(): void {
    this.isLoader = true;
    this._inboxSettings.getAllTemplates('SNIPPET').pipe(takeUntil(this.destroy$)).subscribe(
      {
        next: (response: any) => {
          this.allSnippets = response.data.reverse();
          this.allSnippetForLocalSearch = response.data.reverse();
          if (this.allSnippets.length > 0) {
            this.selectedSnippet = this.allSnippets[0];
          } else {
            this.selectedSnippet = null;
          }
          this.isLoader = false;
        },
        error: (error) => {
          this.isLoader = false;
        },
        complete: () => {
        }
      }
    );
  }

  protected menuOnSelect(event: any): void {
    if (event.label == 'Delete') {
      this.deleteSnippet(this.selectedSnippet.id);
    }
  }

  // protected createSnippet(title: string, action: string, snippet?: Template): void {
  //   let popupData = {
  //     title: title,
  //     action: action,
  //     type: 'SNIPPET',
  //     component: InboxCreateSnippetComponent,
  //     data: {
  //       action: action,
  //       template: snippet
  //     },
  //     containerClass: 'snippet_popup',
  //   };
  //
  //   this._dialogService.openModal(popupData).subscribe(result => {
  //     if (result) {
  //       this._inboxSettings.settingsTabCountUpdated.next(EnumTemplatesCategory.SNIPPET);
  //       this.getAllSnippets();
  //       if (result.event == 'CREATE') {
  //         this._utilService.showSuccess('', 'Snippet Created Successfully');
  //       } else if (result.event == 'EDIT') {
  //         this._utilService.showSuccess('', 'Snippet Updated Successfully');
  //       }
  //     }
  //   });
  // }


  private deleteSnippet(templateId: string): void {
    this._inboxSettings.deleteTemplate(templateId).pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      this._inboxSettings.settingsTabCountUpdated.next(EnumTemplatesCategory.SNIPPET);
      this._utilService.showSuccess('', 'Snippet Deleted Successfully');
      this.getAllSnippets();
    })
  }

  searchSnippet(searchVal: any): void {
    this.searchInput = searchVal;
    this.allSnippets = this.allSnippetForLocalSearch.filter(snippet => {
      return snippet.title.toLowerCase().includes(this.searchInput.toLowerCase());
    });
    if (this.allSnippets.length > 0) {
      this.changeSnippet(this.allSnippets[0]);
    } else {
      this.selectedSnippet = null;
    }
  }

  addSnippet(): void {
    const newSnippet = {
      title: '',
      newSnippet: true,
      id: 1,
      content: ''
    };
    this.allSnippets.push(newSnippet);
    this.selectedSnippet = this.allSnippets[this.allSnippets.length - 1];
  }

  changeSnippet(snippet): void {
    this.selectedSnippet = snippet;
  }

  bodyChange(event: any): void {
    this.selectedSnippet.content = event;
  }

  cancelSnippet(): void {
    this.allSnippets.pop();
    if (this.allSnippets.length > 0) {
      this.selectedSnippet = this.allSnippets[0];
    } else {
      this.selectedSnippet = null;
    }
  }

  changeContentBasedOnGmail(): void {
    let content =  this.selectedSnippet.content.replaceAll('<p', '<div');
    content = content.replaceAll('</p>', '</div>');
    content = content.replaceAll('<p>', '<div  style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<div>', '<div style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<h1>', '<h1 style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<h2>', '<h2 style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<h3>', '<h3 style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<h4>', '<h4 style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<h5>', '<h5 style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<h6>', '<h6 style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<ol>','<ol style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<ul>','<ul style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<a>', '<a style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<li>','<li style="margin-top: 0px !important;margin-bottom: 0px !important;margin-left: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('white-space: pre-wrap', '');
    this.selectedSnippet.content = content;
  }

  SaveNewSnippet(): void {
    this.changeContentBasedOnGmail();
    const payload: Template = {
      "title": this.selectedSnippet.title,
      "content": this.selectedSnippet.content,
      "mailboxId": this._inboxService.activeMailboxDetails.id,
      "category": EnumTemplatesCategory.SNIPPET
    }
    this._inboxSettings.createTemplate(payload).pipe(takeUntil(this.destroy$)).subscribe(
      {
        next: (response: any) => {
          this._utilService.showSuccess('', 'Snippet Created Successfully');
        },
        error: (error) => {
          this._utilService.showErrorMessage(error);
        },
        complete: () => {
          this._inboxSettings.settingsTabCountUpdated.next(EnumTemplatesCategory.SNIPPET);
          this.getAllSnippets();
        }
      }
    );
  }

  editSnippet(): void {
    if (this.selectedSnippet.newSnippet) {
      return null;
    } else {
      this.changeContentBasedOnGmail();
      const payload = {
        "title": this.selectedSnippet.title,
        "content": this.selectedSnippet.content,
        "mailboxId": this._inboxService.activeMailboxDetails.id,
        "category": EnumTemplatesCategory.SNIPPET
      }
      this._inboxSettings.updateTemplate(payload, this.selectedSnippet.id).pipe(takeUntil(this.destroy$)).subscribe(
        {
          next: (response: any) => {
            this._utilService.showSuccess('', 'Snippet Updated Successfully');
          },
          error: (error) => {
            this._utilService.showErrorMessage(error);
          },
          complete: () => {
          }
        }
      );
    }
  }
}
