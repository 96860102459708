import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  isView_Loader: boolean = false;
  isLoader: boolean = false;
  isLoader_All_Jobs: boolean = false;
  isLoadedPhotos: boolean = false;

  isCustom_Timepicker: boolean = true;

  doLogin: any = 'login/';
  logout: any = 'logout/';

  doForgetPassword: any = 'password/reset/';
  confirmResetPassword: any = 'password/reset/confirm/';
  getOnboardingUserInfo: any = 'users/verify/';
  userOnboardingFinal: any = 'members/onboard/';

  getLaunchDarkly_API: any = 'launch_darkly/';

  getProfile: any = 'users/profile/';
  updateProfile: any = 'users/{USER_ID}/';
  changeProfilePassword: any = 'users/{USER_ID}/change-password/';
  updateProfileSettings: any = 'user-settings/{USER_Settings_ID}/';
  getUserNotifications_New: any = 'notifications/new/';
  getAllUserNotifications: any = 'notifications/';
  readAllNotifications: any = 'notifications/mark-read/';

  add_New_Service_Request = 'jobs/service-form/';
  servcice_Form_Properties = 'properties/service-form-dropdown/';
  servcice_Form_Units = 'properties/{ID}/service-form-units';
  servcice_Form_Categories = 'properties/{Propert_ID}/service-form-category/?unit={Unit_ID}';
  service_Form_Companies = '/companies/all-companies/'
  service_Form_Tenant_Companies = '/companies/tenant-companies/'
  get_all_email_address = '/companies/service-form-emails/'

  getStates = 'states/?country=a9f559a5-e33d-4b24-8b2f-633486ccff40';
  getCities = 'cities/?state={state_id}';

  getCategories = 'categories/';
  getAllFyxtCategories = 'fyxt-categories/';

  getRolePermissions = 'permission-labels/';
  getRoles: any = 'groups/';
  createRole: any = 'groups/';
  updateRole: any = 'groups/{ID}/';
  deleteRole: any = 'groups/{ID}/';

  getMembers: any = 'members/';
  createMembers: any = 'members/';
  updateMembers: any = 'members/{ID}/';
  deleteMembers: any = 'members/{ID}/';
  suspendMembers: any = 'members/{ID}/suspend/';
  activateMembers: any = 'members/{ID}/activate/';
  getSuspendedMembers: any = 'members/suspended/';
  editPropertyMemberRolePermissions: any = 'managers/{ID}/';
  deletePropertyMember: any = 'managers/{ID}/';
  inviteMemberSearch: any = 'members/?search={searchKey}&type=active';
  searchInactiveMembers: any = 'members/?type=inactive&search={searchKey}';
  inviteMembers: any = 'tenants/invite/';
  inviteMembersByProperty: any = 'teams/{property_id}/invite/';
  sendInvitationPM: any = 'members/{USER_ID}/invite/';

  createMembersByProperty: any = 'teams/{property_id}/members/';
  getMyTeamsProperties: any = 'members/{USER_ID}/teams/';
  getAllTeamsProperties: any = 'teams/';
  getAllTeamMemberByProperty: any = 'teams/{ID}/members/';
  getAllTeamEngineerByProperty: any = 'teams/{ID}/engineers/';
  getAllAbstractByProperty: any = 'properties/{ID}/abstracts/';
  getVendorListByProperty: any = 'vendors/?property={ID}';
  getTenantListByProperty: any = 'tenants/?property={ID}';
  createAbstractByTenant: any = 'abstracts/';
  getTenantContact: any = 'tenants/{ID}/contacts/';
  getCompanyContacts: any = 'companies/{ID}/contacts/';
  getTenantContactsByProperty: any = 'companies/tenant-contacts/';
  uploadAbstract: any = 'abstracts/{ID}/abstract_upload/';
  uploadAbstractDoc: any = 'documents/';
  deleteLeaseAbstract: any = 'documents/{ID}/';

  getOwners: any = 'owners/';
  createOwners: any = 'owners/';
  updateOwners: any = 'owners/{ID}/';
  deleteOwners: any = 'owners/{ID}/';

  getVendors: any = 'vendors/';
  createVendors: any = 'vendors/';
  updateVendors: any = 'vendors/{ID}/';
  getVendor: any = 'vendors/{ID}/';
  getEditVendorInfo: any = 'vendors/{ID}/edit_vendor';
  getInactiveVendors: any = 'vendors/inactive/';
  inviteVendors: any = 'vendors/invite/';
  uploadCOIpm: any = 'cois/';

  getTenants: any = 'tenants/';
  createTenants: any = 'tenants/';
  updateTenants: any = 'tenants/{ID}/';
  updateStatusTenants: any = 'tenants/{ID}/tenant_is_active/';
  addTenantContact: any = 'mail/contacts/';
  getTenant: any = 'tenants/{ID}/';

  getProperties: any = 'properties/';
  createProperties: any = 'properties/';
  getProperty: any = 'properties/{ID}/';
  updateProperty: any = 'properties/{ID}/';
  deletePropertyUnit: any = 'units/{ID}/';

  listAllPropertiesDropdown = 'properties/dropdown/';
  listAllRolesDropdown = 'groups/dropdown/';
  listAllTeamPropertiesDropdown = 'teams/dropdown/';
  listAllTeamsDropdown = 'teams/';

  getAllinvite: any = 'tenants/invite/';

  editAllTeamEngineerByProperty: any = 'property-engineers/{ID}/';
  deleteAllTeamEngineerByProperty: any = 'property-engineers/{ID}/';

  /********** Ticket Flows ************/
  getDashboardTilesCount = 'dashboard/';

  getallLeaseUnitsByProperty = 'properties/{ID}/leased-units/';
  getLocationAllUnitsByProperty = 'properties/{ID}/units/';
  getAllAddressesByProperty = 'properties/{ID}/addresses/';
  getAllCategoriesByProperty = 'properties/{ID}/categories/';
  getAllVendorsByProperty = 'properties/{ID}/vendors/?category={category-id}';
  getAllVendorsByProperty_new = 'companies/vendor-categories/?property_id={ID}&category_name={category_name}';
  getVendorCompanies = 'companies/vendor-categories/?size={size}&page={page}';
  getBillableParty =
    'jobs/billable-party/?unit={UnitID}&category={category-id}';
  getAllEmergentVendorsByProperty =
    'properties/{Property_ID}/emergent-vendors/?category={category-id}';
  getAllAssigneesByProperty = 'properties/{ID}/managers/?filter=assign_to';
  getAllAssigneesByTenanat = 'tenants/{ID}/members/';
  getAllAssigneesByPropertyList =
    'properties/{ID}/managers/?type=assign_manager';
  getAllFollowersByPropertyList = 'properties/{ID}/managers/?type=followers';

  createTicket = 'jobs/';
  updateTicket = 'jobs/{ID}/';
  duplicateTicket = 'jobs/duplicate/';
  updateDashboardJobStage = 'user-dashboard/{ViewID}/stage/';

  getAllOpenJobs = 'jobs/?filter=open';
  getAllRequiredActionJobs = 'jobs/?filter=open';
  getAllTenantReponsibleJobs = 'jobs/?filter=tenant_responsible';
  getAllDraftJobs = 'draft-jobs/';
  getJobNotes = 'jobs/{ID}/notes/';
  addJobNotes = 'jobs/{ID}/notes/';
  getJobNotes_Authors = 'jobs/{ID}/author/';

  getAllRecentJobs = 'properties/{ID}/recent-job/';
  getAllRecentFilter =
    'jobs/related-jobs-filter/?filter=open&query={query}&column=id&order=desc';
  getAllPriority = "jobs/priorities/";

  /********* Dashboard Tab Actions ***********/
  getAllJobs = 'jobs/';
  getAllJobs_Tab1 = 'jobs/?filter=open';
  getAllJobs_Tab2 = 'jobs/?filter=assigned_to_me';
  getAllJobs_Tab3 = 'jobs/?filter=unassigned';
  getAllJobs_Tab4 = 'jobs/?filter=tenant_responsible';

  /********* Dashboard Tile Actions ***********/
  getAllJobs_Tile1 = 'jobs/?filter=open';
  getAllJobs_Tile2 = 'jobs/?filter=require_actions';
  getAllJobs_Tile3 = 'jobs/?filter=overdue';
  getAllJobs_Tile4 = 'jobs/?filter=tenant_responsible';

  getAllPastJobs = 'jobs/?filter=past';

  getJobsByTab = 'jobs/?filter={active_tab}';

  // //Dummy
  // getAllRequiredActionJobs_Tile1 = "jobs/?filter=open";
  // getAllRequiredActionJobs_Tile2 = "jobs/?filter=require_actions";
  // getAllRequiredActionJobs_Tile3 = "jobs/?filter=overdue";
  // getAllRequiredActionJobs_Tile4 = "jobs/?filter=tenant_responsible";

  // getAllRequiredActionJobs_Tab1 = "jobs/?filter=open";
  // getAllRequiredActionJobs_Tab2 = "jobs/?filter=assigned_to_me";
  // getAllRequiredActionJobs_Tab3 = "jobs/?filter=unassigned";
  // getAllRequiredActionJobs_Tab4 = "jobs/?filter=tenant_responsible";

  // getAllRequiredActionJobs_Tile1 = "jobs/?filter=require_actions";
  // getAllRequiredActionJobs_Tile2 = "jobs/?filter=new";
  // getAllRequiredActionJobs_Tile3 = "jobs/?filter=approve_costs";
  // getAllRequiredActionJobs_Tile4 = "jobs/?filter=approve_schedules";
  // getAllRequiredActionJobs_Tile5 = "jobs/?filter=complete_and_close";

  getSelectedDraftJobDetails = 'draft-jobs/{ID}/';
  uploadDocuments = 'documents/';

  uploadTicketPhotos = 'job/photos/';
  removePhotos = 'photos/{ID}/';
  serviceFormRemovePhotos = 'service-form-photos/{ID}/'
  uploadProfilePhoto = 'users/photos/{User_ID}/';
  uploadServiceFormPhotos = 'service-form-photos/';

  getSelectedJobDetails = 'jobs/{ID}/';
  getSelectedExternalJobDetails = 'external/vendor-jobs/{ID}/';
  getSelectedSiteVisitsJobDetails = 'external/vendor-jobs/{ID}/schedules/';
  getSelectedScheduleJobDetails = 'external/vendor-jobs/{ID}/schedule-jobs/';
  reScheduleJobDetails = 'external/vendor-jobs/schedules/{ID}/';
  cancelEVSiteVisit = 'external/vendor-jobs/schedules/{ID}/';
  updateJobCostDetail = 'external/vendor-jobs/costs/{ID}/';
  addRatingsForJob = 'jobs/{ID}/ratings/';
  deletePendingJob = 'external/vendor-jobs/schedules/{ID}/';
  timelineData = 'external/vendor-jobs/{ID}/timeline/';
  getAllVendorJobs = 'external/vendor-jobs/{ID}/jobs/';
  getAllDoshboardCount = 'external/vendor-jobs/{ID}/dashboard/';
  getAllProperty = 'external/vendor-jobs/{ID}/properties/';
  getAllVendorJobsFilter = 'external/vendor-jobs/{ID}/jobs/?filter={Data}';
  getAllVendorJobsPropFilter =
    'external/vendor-jobs/{ID}/jobs/?filter={Data}&property=[{PID}]';
  getAllVendorPropFilter = 'external/vendor-jobs/{ID}/jobs/?property=[{PID}]';
  addYearToSpend_ToJob = 'jobs/{ID}/spent/';
  updateYearToSpend_ToJob = 'job/spent/{ID}/';

  getAllJobScheduleUsers = 'jobs/{ID}/work_order_dropdown/';
  getAllJobVendors = 'jobs/{ID}/vendors/';
  getAllJobScheduleVendors = 'jobs/{ID}/schedules/';
  getAllJobVendorsByProperty =
    'properties/{Property_ID}/vendors/?category={category-id}&rating=true';
  getAllJobDropdownVendors = 'jobs/{ID}/vendors-dropdown/';

  jobScheduleSiteVisit = 'job/schedules/';
  jobSchedule = 'job/schedules/';

  approveSiteVisit = 'job/schedules/{ID}/';
  approveSchedule = 'job/schedules/{ID}/';

  cancelSiteVisit = 'job/schedules/{ID}/';
  cancelSchedule = 'job/schedules/{ID}/';

  rescheduleSiteVisit = 'job/schedules/{ID}/';
  rescheduleSchedule = 'job/schedules/{ID}/';

  addJobCost = 'job/costs/';
  getGobCostByJobID = 'jobs/{ID}/costs/';
  compareGobCostByJobID = 'jobs/{ID}/compare-costs/';
  addJobCost_New = 'external/vendor-jobs/{ID}/costs/';

  approveJobCostByBidID = 'job/costs/{ID}/';
  rejectJobCostByBidID = 'job/costs/{ID}/';

  getJobContacts = 'jobs/{ID}/company-contacts/';
  getJobContactsDropdown = 'jobs/{ID}/contacts/?type=feedback';
  getTargetDateChangeHistory = 'jobs/{ID}/target-date-change-log/';

  inviteVendorsToJob = 'jobs/{ID}/';
  getAllStagesToJob = 'job/stage/';

  jobNotesMarkasRead = 'jobs/{ID}/mark-read/';

  getTimelineToJob = 'jobs/{ID}/timeline/';
  getSummaryOfJob = 'jobs/{ID}/summary/';
  getallCOAbyJob = 'jobs/{ID}/coas/';

  getJobBigPictureDataByJobID = 'jobs/{ID}/big-picture-data/';
  getBudgetDetailsByJobID = 'jobs/{ID}/budget-details/';
  getJobPhotosByJobID = 'jobs/{ID}/photos/';
  getJobSpendsByJobID = 'jobs/{ID}/spent/';

  getJobActivities = 'jobs/{ID}/activity/';

  getLinkedJobList = 'jobs/{ID}/linked-jobs/';
  getLinkedJobDropdown = 'jobs/{ID}/linked-jobs-dropdown/';
  updateLinkedJob = 'linked/{ID}/';

  // download_COA_Template="properties/download-template/?name=coa";
  download_COA_Template = 'properties/{ID}/download-template/';
  uploadNewCOA = 'properties/{ID}/coas/upload';
  getAllUploadedCOA = 'properties/{ID}/coas/';
  getAllUploadedCOA_Dropdown = 'properties/{ID}/coas-dropdown/';

  // download_Budget_Template="properties/download-template/?name=budget";
  download_Budget_Template = 'properties/{ID}/download-template/';
  uploadNewBudget = 'properties/{ID}/budgets/upload';
  getAllUploadedBudget = 'properties/{ID}/budgets/';
  deleteUploadedBudget = 'budgets/{ID}/';

  uploadNewActuals = 'properties/{ID}/actuals/upload';
  getAllUploadedActuals = 'properties/{ID}/actuals/';
  deleteUploadedActuals = 'actuals/{ID}/';

  /***************** PM Search APIs **************************/
  searchMembers: any =
    'members/?property={Property_ID}&group={Role_ID}&search={searchKeyword}';
  searchRoles: any = 'groups/?search={searchKeyword}';
  searchOwners: any = 'owners/?search={searchKeyword}';
  searchVendors: any = 'vendors/?search={searchKeyword}';
  searchProperty: any = 'properties/?search={searchKeyword}&type={Type}';
  searchTenants: any = 'tenants/?search={searchKeyword}';
  searchTenantsByProperty: any =
    'tenants/?property={ID}&search={searchKeyword}';
  searchVendorByProperty: any = 'vendors/?property={ID}&search={searchKeyword}';
  searchJobs: any =
    'jobs/?filter={active_tab}&search={searchKeyword}&timeline={timeline}';
  searchTenantResponsibleJobs: any =
    'jobs/?filter={active_tab}&billable_party=tenant&search={searchKeyword}&timeline={timeline}';

  /********** Tenant Role Based Flows ************/
  getTenantVendors: any = 'tenants/vendors/?category={ID}';
  // getTenantVendorsList: any = "tenants/vendors/";
  getTenantVendorsList: any = 'vendors/';
  getTenantVendorProfile: any = 'vendors/{ID}/';
  getTenantUnits: any = 'tenants/units/';
  createTicketTenant: any = 'tenant/jobs/';
  getAllTenantCategory: any = 'tenants/categories/';
  getTenantDetails: any = 'tenants/administrative/';

  /********** External Vendor Role Based Flows ************/

  exvSelectedJobInfo: any =
    'external/vendor-jobs/{Vendor_ID}/jobs/?id={Job_ID}';

  submitBid: any = 'external/vendor-jobs/{ID}/costs/exv_submit_bid';
  submitBidComplete: any = 'external/vendor-jobs/{ID}/';
  getManagerTenantContact = 'external/vendor-jobs/{ID}/';
  closeInvoiceSubmit = 'external/vendor-jobs/{ID}/complete/';
  exvUploadPhotos = 'external/vendor-jobs/{VendorID}/photos/exv_upload_photos';
  exvDeletePhotos: any = 'external/vendor-jobs/photos/{photo_id}/';

  uploadCOI = 'external/vendor-jobs/{ID}/coi/exv_upload_coi';
  /************left filter ********/
  getAssignedUser = 'jobs/assignees/';
  getCategoryUser = 'jobs/categories/';
  getPropertiesUser = 'jobs/properties/';
  getTenantsUser = 'jobs/tenants/';
  getVendorsUser = 'jobs/vendors/';
  getOwnerGroup = 'jobs/owners/';
  getApplyFilter = 'jobs/?query={query}';
  getSaveFilter = 'job/filters/';
  putEditValueFilter = 'job/filters/{ID}/';
  deleteValueFilter = 'job/filters/{ID}/';
  getJobEngineer = 'jobs/engineers/';

  /********** Tenant Ticket Flows ************/

  getAllActiveJobsTenant = 'tenant/jobs/';
  getAllRequiredActionJobsTenant = 'tenant/jobs/';
  getSelectedJobDetailsTenant = 'tenant/jobs/{ID}/';

  /********** phone and email reuse checking ************/

  postEmailIdReuse = 'check/email/';
  postPhoneNoReuse = 'check/phone/';

  /*************** ---------Photo Bank---------- **************/
  getAllPhotosDashboard = 'photos/dashboard/';
  getAllPhotoes = 'photos/';
  deletePhoto = 'photos/{photoID}/';
  getViewPhoto = 'photos/{photoID}/';
  getAllUser = 'users/?source=photo-bank';
  getMyTeam = 'members/{photoID}/my-team/';
  postBulkSharedPhoto = 'photos/share/';
  deleteBulkPhoto = 'photos/bulk-delete/?photos={photoes}';
  downloadPhotos = 'photos/download/';
  bulkUploadDescription = 'photos/bulk-update/';
  getSharedWithMe = 'photos/shared-with-me/';
  getSharedWithOther = 'photos/shared-with-others/';

  getJobSelectedPhoto = 'jobs/{selectedID}/';
  addNewPhotoToSelectedJob = 'jobs/{selectedID}/photos/';

  /*************** ---------Manage Engineer ---------- **************/

  getMyEngineer = 'engineers/my/';
  //  getMyTeamEngineer = "teams/{enginerID}/engineers/";
  getAllEngineer = 'engineers/';
  postCreateEngineer = 'engineers/';
  getViewEngineer = 'engineers/{enginerID}/';
  patchEditEngineer = 'engineers/{enginerID}/';
  getSuspendDetail = 'engineers/suspended/';
  postInviteEngineer = 'engineers/{enginerID}/invite/';
  patchUpdateSuspendDetail = 'engineers/{enginerID}/suspend/';

  /********** timesheet ************/
  getAll_Timisheet = 'job/labors/';
  getTimisheet_ByType = 'job/labors/?filter={Type}';
  approve_Timesheet = 'job/labors/bulk-approve/';
  reject_Timesheet = 'job/labors/bulk-reject/';

  approve_Materials = 'job/materials/bulk-approve/';
  reject_Materials = 'job/materials/bulk-reject/';
  updateMaterial = 'job/materials/{ID}/';

  updateTimeline = 'job/labors/{ID}/';
  getAllAssignedEngineerByProperty = 'properties/{ID}/engineers/';
  getAllAssignedManagersByProperty = 'properties/{ID}/managers/';
  getNestAllAssignedManagersByPropertyPublic = '/checklist-signature/public/properties/{property_id}/managers';
  getNestAllAssignedManagersByProperty = '/checklist-signature/properties/{property_id}/managers';
  getNestAllAssignedEngineerByPropertyPublic = '/checklist-signature/public/properties/{property_id}/engineers';
  getNestAllAssignedEngineerByProperty = '/checklist-signature/properties/{property_id}/engineers';
  getTimesheetExport = 'job/labors/export/?filter={tab}';
  postTimesheetSaveFilter = 'job/filters/';
  getTimesheetProperty = 'job/labors/properties/';
  getTimesheetEngineer = 'job/labors/engineers/?filter={tab}';
  getTimesheetListFilter = 'job/filters/?type=Labor';
  timesheetDeleteFilter = 'job/filters/{ID}/?type=Labor';
  putTimesheetEditFilter = 'job/filters/{ID}/?type=Labor';
  // getApprovedTimesheetApplyFilter = "job/labors/?query={query}&filter=approved";
  getApprovedTimesheetApplyFilter = 'job/labors/?filter=approved&query={query}';
  // getUnApprovedTimesheetApplyFilter = "job/labors/?query={query}&filter=unapproved";
  getUnApprovedTimesheetApplyFilter =
    'job/labors/?filter=unapproved&query={query}';
  addLabourItemToJob = 'jobs/{ID}/labors/';
  addMAterialItemToJob = 'jobs/{ID}/materials/';

  getLabourItemToJob = 'jobs/{ID}/labors/';
  getMAterialItemToJob = 'jobs/{ID}/materials/';
  uploadMaterialDocuments = 'documents/';
  delete_LaborInfo = 'job/labors/{ID}/';
  update_LaborInfo = 'job/labors/{ID}/';
  update_MaterialDoc = 'job/labors/{ID}/';

  update_LaborInfo_Multi = 'job/labors/bulk-update-or-create/';
  update_MaterialInfo_Multi = 'job/materials/bulk-update-or-create/';
  deleteDocument = 'documents/{Doc_ID}/?material={Mat_ID}';

  removeVendorfromJob = 'vendor-jobs/{ID}/';
  exportFilter = 'job/labors/export/';

  /****All jobs filter ****/
  pastJobFilter = 'job/filters/?type=Past';
  putUpdatePastJobFilter = 'job/filters/{ID}/?type=Past';
  deletePastJobFilter = 'job/filters/{ID}/?type=Past';
  getPastJobApplyFilter = 'jobs/?query={query}';
  searchPastJobs: any = 'jobs/?search={searchKeyword}&timeline={timeline}';
  getAllJobsList = 'jobs/';

  /****past job filter ****/
  // pastJobFilter = "job/filters/?type=Past";
  // putUpdatePastJobFilter = "job/filters/{ID}/?type=Past";
  // deletePastJobFilter = "job/filters/{ID}/?type=Past"
  // getPastJobApplyFilter = "jobs/?filter=past&query={query}";

  /**** Checklist ****/
  createChecklist: any = 'checklists/';
  getAllChecklists: any = 'checklists/';
  searchChecklists: any =
    'checklists/?search={searchKeyword}&type={type}&category={category}';
  deleteChecklist: any = 'checklists/{ID}/';
  getAllChecklistProperties: any = 'properties/dropdown/?source=checklist';
  getAllChecklistDropdown: any = 'checklists/dropdown/';
  getAllChecklistDropdownByProperty: any =
    'properties/{ID}/checklists/?source=dropdown';
  ChecklistInfoByChecklistID = 'checklists/{ID}/';
  update_NotesToChecklist = 'job/tasks/{ID}/';
  update_ChecklistTasks = 'job/tasks/{ID}/';
  delete_ChecklistTask = 'tasks/{ID}/';
  update_SingleChecklistTasks = 'job/tasks/{ID}/';
  getChecklistInfoByJobID = 'jobs/{ID}/checklist/';
  get_allNotesToChecklist = 'job/tasks/{ID}/notes/';
  delete_NoteToChecklist = 'job/task-notes/{ID}/';
  get_SingleTaskByTaskID = 'job/tasks/{ID}/';
  uploadChecklistFiles = 'job/photos/';
  createJobChecklist: any = 'job/checklists/';
  // assignJobChecklist: any = "job/checklists/";
  getPropertyChecklist: any = 'properties/{ID}/checklists/';
  searchPropertyChecklists: any =
    'properties/{ID}/checklists/?checklist-search={searchKeyword}&type={type}&category={category}';
  assignJobChecklist: any = 'jobs/{ID}/';
  update_ChecklistFiles = 'job/checklists/{ID}/';
  delete_ChecklistFiles = 'photos/{ID}/?type=all';
  update_SelectedChecklist = 'checklists/{ID}/';
  update_Checklist = 'job/checklists/{ID}/tasks/';

  /**** Build Report ****/
  saveBuildReport = 'reports/';
  getBuildReports = 'reports/dropdown/';
  downloadSavedReports = 'reports/{ID}/downloadExcelReport/';
  downloadBuildReport_Timeline = 'job/labors/';

  getReport_AllProperties = 'properties/dropdown/';
  getReport_AllTenants = 'tenants/dropdown/';
  getReport_AllCategories = 'categories/';
  getReport_AllEngineers = 'users/?source=report&type=engineer';

  /**** Chat ****/
  allChatList = 'chats/';
  createChat = 'chats/';
  allChatSearchList = 'chats/?search={query}';
  chatUserList = 'users/?source=chat&search={query}';
  getChatDetails = 'chats/{query}/';
  uploadChatDocument = 'photos/chats/';

  /****** Socket URLs *******/
  getnewChatMembersLsits = 'members/{Account_ID}/{UserID}/';
  getnewChatLsits = 'message-count/{Account_ID}/{UserID}/';
  getAllChatLsits = 'chats/{Account_ID}/{UserID}/{ChatID}/';

  getnewNotificationsCounts = 'notification/{UserID}/';
  getnewInboxCounts = 'inboxes/{UserAccountID}/';
  getnewInboxConversations = 'inboxes/conversations/{MailID}/';
  getnewJobActions = 'actions-trigger/{UserAccountID}/';
  getnewDashboardTilesCount = 'dashboard/{UserID}/';
  updateJobInfo_Socket = 'dashboard-trigger/{UserID}/';
  getnewDashboardLiveRowUpdate = 'actions/{UserID}/';
  getDashboard_NewJobRequestTab_Count = 'new-job-count/{UserID}/';
  getDashboard_jobInfoProposals = 'job-info/{UserID}/{JobID}/';

  updateInboxActionTrigger_Socket = 'inboxes/dashboard-trigger/{UserID}/';
  updateInboxActionCounts_Socket = 'inboxes/dashboard/{AccountID}/{UserID}/';
  sendAllInboxMails_ByTab_Socket =
    'inboxes/conversations/trigger/{AccountID}/{UserID}/';
  triggerInbox_mail_listings_Socket = 'inboxes/conversations/list/{UserID}/';
  getAllPlannerJobs = 'planner/{AccountID}/{UserID}/';

  //New Dashbaord v2 Triggers
  updateJobActionTrigger_Socket_for_v2 ='dashboard/row/trigger/{AccountID}/{UserID}/';

  dashboardCountTrigger = 'dashboard/count/trigger/{AccountID}/{UserID}/';
  bulkAction_triggers = "bulk/trigger/{AccountID}/{UserID}/";
  jobInfo_Update_Triggers ='dashboard/job-info/trigger/{AccountID}/{UserID}/';


  /**** Inbox ****/
  getMailboxes = 'mailbox/';
  getOutlookOAuthUrl = 'mailbox/outlook-oauth-url/';
  getMailList = 'mails/';
  mailDashboardCount = 'mails/dashboard/';
  sendMail = 'mails/';
  avialableTimeZone = 'mailbox/{ID}/supported-timezones/'
  updateTimeZone = 'mailbox/{ID}/update/'
  newMail = 'mails/new_mail/';
  viewMailDetail = 'mails/{ID}/';
  markConversationAsUnread = 'mails/{ID}/?mailbox={MAILID}';
  viewMailNotes = 'mails/{ID}/notes/';
  sendConversation = 'mails/{ID}/conversations/';
  sendNewConversation = 'mails/{ID}/new_conversations/';
  sendNotes = 'mails/{ID}/notes/';
  getManagerData = 'users/?source=inbox&search={query}';
  getAssignedManager = 'mails/{ID}/manager/';
  getDefaultCreateTicketData = 'mails/{ID}/create-job-defaults/';
  getActivities = 'mails/{ID}/mail_activity/'

  getSenderData = 'tenants/dropdown/?search={query}';
  assignManager = 'mails/{ID}/';
  getSenderInfo = 'mail/contacts/{ID}/';
  getSenderCCInfo = 'mails/{ID}/v2/senders/';
  getContactCCInfo = 'mails/{ID}/v2/contacts/';
  addSenderTenantToJob = 'mail/contacts/{ID}/';
  updateSenderEmail = 'mail/contacts/{ID}/';
  addTenantJob = 'mails/contacts/{ID}/';
  getAssociateJobsList = 'mails/jobs-dropdown/';
  getAssociateJobsDropdownList = 'mails/associated-jobs-dropdown/';
  getAllAssociateJobsDropdownList = 'mails/associated-jobs-dropdown/?query={QUERY}&custom-search={SEARCHVALUE}&mail_id={ID}';

  getAssociateJob = 'mails/{ID}/jobs/';
  getAssociateJobList = 'properties/{ID}/jobs/?source=inbox';
  getAssociateJobListSearch =
    'properties/{ID}/jobs/?source=inbox&custom-search={query}';
  saveJobAssignToThread = 'mails/{ID}/';
  getFilterProperty = 'properties/dropdown/?source=inbox';
  getFilterTenant = 'tenants/dropdown/';
  getFilterCategory = 'categories/';
  getFilterAssignedTo = 'mails/assignee/';
  applyFilterData = 'mails/?filter={query}';
  quickAction = 'mails/{ID}/';
  deleteInboxJob = 'mails/{ID}/remove-job/?job={JOBID}';
  getAttachment = 'mails/{ID}/attachments/';
  getAllJobEmails = 'jobs/{ID}/mails/';
  getAllEmailConversationsByID = 'mails/{ID}/?pagination=false';
  downloadAttachment = 'photos/download/';
  saveInboxTemplate = 'mail/templates/';
  checkEmail = 'mail/contacts/check/';
  saveSenderInfo = 'mails/{ID}/add-contacts/';
  searchInboxTenants = 'tenants/dropdown/?source=inbox&search={searchTerm}';
  getInboxTenantInfoByID = 'tenants/{ID}/new-contact/';
  removeSender = 'mails/{ID}/remove-sender/?sender={SENDERID}';
  getAllUserForInbox = 'users/?source=inbox&type=all&search={searchTerm}';
  getUserFromSearchList = 'users/{ID}/contact/';
  getMailDetailById = 'mails/{ID}/?short=true';
  getContactForInbox = 'mail/contacts/?type=contact&search={searchTerm}';
  bulkUploadMails = 'mails/bulk-action/';
  associatedBulkMails = 'jobs/{ID}/associated-mails/'

  getFilterTenantDropdown = 'tenants/tenant-dropdown/';
  getFilterPropertyDropdown = 'properties/property-dropdown/?source=inbox';
  addLabel = 'mail/label/';
  editLabel = 'mail/label/{ID}/';

  getAllSignatures = "mail/signatures/";
  getSignatureById = "mail/signatures/{ID}/"

  getAllReviews = "mail/review_forms/";
  getReviewById = "mail/review_forms/{ID}/"

  getAllSnippets = "mail/templates/";
  getSnippetById = "mail/templates/{ID}/";

  getFeedback = "mail/feedbacks/{ID}"

  getAllResponse = "mail/auto_response/";
  getResponseById = "mail/auto_response/{ID}/"

  getMailViewers = "mail/ping/";
  setMailView = "mail/ping/{ID}/";
  typeStatus = "mails/{ID}/v2/typing-status/"

  selectedMail = "mail/check/";
  showMore = "mail/show_more/";
  uncheckAll = 'mail/check/uncheck-mail/';
  showLessAll = 'mail/show_more/show-less/';

  /******* Search APIs *********/
  searchFilters_categories = 'jobs/categories/?custom-search={searchTerm}';
  searchFilters_properties = 'jobs/properties/?custom-search={searchTerm}';
  searchFilters_tenants = 'jobs/tenants/?custom-search={searchTerm}';
  searchFilters_vendors = 'jobs/vendors/?custom-search={searchTerm}';
  searchFilters_owners = 'jobs/owners/?custom-search={searchTerm}';
  searchFilters_engineers = 'jobs/engineers/?custom-search={searchTerm}';
  searchFilters_Assigneee = 'jobs/assignees/?custom-search={searchTerm}';
  searchCreateTicket_Properties =
    'properties/dropdown/?source=pm&search={searchTerm}';
  searchCreateTicket_Properties_new =
    'properties/dropdown/?source={source}&search={searchTerm}';
  searchCreateTicket_Locations =
    'properties/{ID}/units/?custom-search={searchTerm}';
  searchCreateTicket_Category =
    'properties/{ID}/categories/?custom-search={searchTerm}';

  property_dropdown_update = 'properties/{ID}/property_dropdown_update/'

  /********** Service Planner ************/
  createServicePlanner = 'planner/';
  updateSelectedPlanner = 'planner/{ID}/';
  searchPlanners: any = 'planner/?search={SearchKey}';
  duplicateSelectedPlanner = 'planner/duplicate/';

  serviceTypeDropdown = 'jobs/service-type/';
  getSelectedPlanner = 'planner/{ID}';
  getSelectedPlannerJobs = 'planner/{ID}/occurrence/';
  addSelectedPlannerNotes = 'planner/{ID}/notes/';
  getSelectedPlannerNotes = 'planner/{ID}/notes/';
  getSelectedPlannerContacts = 'planner/{ID}/contacts/';
  getSelectedPlannerFiles = 'planner/{ID}/attachments/';
  downloadPlannerFiles = 'photos/download/';

  dropdown_Managers = 'properties/{ID}/managers/?type=planner';
  dropdown_Engineers = 'properties/{ID}/engineers/?type=planner';
  dropdown_Vendors = 'properties/{ID}/vendors/?category={CatID}';

  createPlannerChecklist: any = 'planner-checklists/';
  plannerChecklistInfoByChecklistID = 'planner-checklists/{ID}/checklist/';
  planner_update_NotesToChecklist = 'planner-tasks/{ID}/';
  planner_get_allNotesToChecklist = 'planner-tasks/{ID}/notes/';
  planner_delete_NoteToChecklist = 'planner-task-notes/{ID}/';
  planner_update_Checklist = 'planner-checklists/{ID}/tasks/';
  planner_uploadChecklistFiles = 'job/photos/';
  planner_update_SingleChecklistTasks = 'planner-tasks/{ID}/';
  planner_update_ChecklistFiles = 'planner-checklists/{ID}/';
  planner_delete_ChecklistFiles = 'photos/{ID}/?type=all';
  planner_get_SingleTaskByTaskID = 'planner-tasks/{ID}/';
  planner_get_checklistAttachments = 'planner/{ID}/checklist/';

  planner_getCategoryies = 'planner/categories/';
  planner_getProperties = 'planner/properties/';
  planner_getServiceTypes = 'planner/type/';

  planner_searchFilters_categories =
    'planner/categories/?custom-search={searchTerm}';
  Planner_searchFilters_properties =
    'planner/properties/?custom-search={searchTerm}';
  Planner_searchFilters_ServiceTypes =
    'planner/type/?custom-search={searchTerm}';

  planner_createNewFilter = 'job/filters/';
  planner_updateFilter = 'job/filters/{ID}/';
  planner_getSavedFilter = 'job/filters/?type=Planner';
  planner_deletePastJobFilter = 'job/filters/{ID}/?type=Planner';
  planner_updateEditFilter = 'job/filters/{ID}/?type=Planner';

  getAllJobsFeedback = "jobs/{ID}/feedback-form/";

  /********** Feature Flags ************/
  isAccountListing: boolean = true;
  isRolePermissions: boolean = true;
  isShowChecklist: boolean = true;
  isBuildReport: boolean = true;
  isShowInbox: boolean = true;
  isShowNewFilterStatus: boolean = true;
  isShowServicePlanner: boolean = true;
  isLinkedJob: boolean = true;

  isNewDashboard: boolean = true; // For New DAsboard Module
  isNewJobInfo: boolean = true; // For New Job Info Module
  isAdvancedFilters: boolean = true; // For Advanced Filter Module
  isTenantExp: boolean = false; // For Tenant Exp Module

  isNewInbox: boolean = true; // For Inbox Migration
  friedman: boolean = true;
  isFriedmanPropertyActive: boolean = true;
  isFriedmanTenantActive: boolean = true;
  isCachedLaunchDarkly: boolean =  environment.launchDarkly ? false: true;

  mapZoom = 8;

  isDelta: boolean = false;
  isPayments: boolean = false;
  showRentCollectionPannel = ['payments', 'buckingham']

  getPropertiesFilter = 'properties/filter-dropdown/';

  constructor() {
    const url = window.location.origin;
    const subDomain = this.extractDomain(url).split('.')[0];

    if (subDomain == 'deltaprojects') {
      this.isDelta = true;
    }

    if (this.showRentCollectionPannel.includes(subDomain)) {
      this.isPayments = true
    }
  }


  extractDomain(url: string): any {
    let domain;
    if (url.indexOf('://') > -1) {
      domain = url.split('/')[2];
    } else {
      domain = url.split('/')[0];
    }
    domain = domain.split(':')[0];
    return domain;
  }
}
