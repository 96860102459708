import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ItemList } from '../../_controls/dropdown-select/itemType.class';
import { TableListDropdownComponent } from '../../_controls/dropdown-select/table-list-dropdown/table-list-dropdown.component';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { CardComponent } from '../card/card.component';

@Component({
    selector: 'fyxt-category-listing-maintenance-terms',
    templateUrl: './category-listing-maintenance-terms.component.html',
    styleUrls: ['./category-listing-maintenance-terms.component.scss'],
    standalone: true,
    imports: [CardComponent, NgIf, NgFor, NgClass, TableListDropdownComponent]
})
export class CategoryListingMaintenanceTermsComponent implements OnInit, OnChanges {
  @Input() categoryModifyList: ItemList[] = [];
  @Input() categoryList: any | undefined; //TODO: fix
  @Input() selectedCategoryInput;
  @Output() onChangeCategory = new EventEmitter<any>();
  @Output() onChangeCategoryModify = new EventEmitter<any>();
  selectedCategory: any | null = null;

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selectedCategoryInput) {
      this.selectedCategory = this.selectedCategoryInput
    } else {
      this.selectedCategory = this.categoryList[0];
    }
  }

  onCategoryStatusChange(event: any, category_id: string): void {
    this.onChangeCategoryModify.emit({ ...event, category_id });
  }

  changeCategory(category: any): void {
    this.selectedCategory = category;
    this.onChangeCategory.emit(category);
  }

  /** Track by Index  **/
  trackByIndex(index: number, item: any): number {
    return index;
  }
}
