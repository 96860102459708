import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MailComposerComponent } from './components/mail-composer/mail-composer.component';
import { MatRadioModule } from '@angular/material/radio';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    // EmailPreviewComponent,
    // MailComposerComponent,
    // EmailComposerPopupComponent,
    // TypingIndicatorComponent,
    // BulkReplyPopupComponent,
    // AttachmentComponent,
    // BulkReplyTrashPopupComponent
  ],
  imports: [CommonModule, FormsModule, MatRadioModule,TooltipModule,DragDropModule],
  exports: [
  ]
})
export class MailComposerModule {}
