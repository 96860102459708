// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bubble-count {
  position: absolute;
  margin-top: 3px;
  margin-left: 5px;
  background: #D63C00;
  border-radius: 50%;
  padding: 0px 0px;
  font-size: 12px;
  text-align: center;
  width: 18px;
  height: 18px;
  color: white;
  line-height: 20px;
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_reusable_ui/_components/tab-vertical/tab-vertical.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,iBAAA;EACA,sBAAA;AACF","sourcesContent":[".bubble-count {\n  position: absolute;\n  margin-top: 3px;\n  margin-left: 5px;\n  background: #D63C00;\n  border-radius: 50%;\n  padding: 0px 0px;\n  font-size: 12px;\n  text-align: center;\n  width: 18px;\n  height: 18px;\n  color: white;\n  line-height: 20px;\n  vertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
