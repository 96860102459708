// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custBG {
  width: 100%;
  height: 100%;
  background-color: #f4f5f6;
  position: relative;
  padding: 100px 0;
}
.custBG::after {
  content: "";
  background-color: rgb(229, 27, 36);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30vh;
  z-index: 0;
}
.custBG > div {
  z-index: 1;
}

.custInputRadio {
  width: 20px;
  aspect-ratio: 1;
  margin-right: 15px;
}

.custColor {
  background-color: #f5f8fa;
}

.logo {
  width: 260px;
  height: auto;
  padding-bottom: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/Modules/inbox/pages/survey-form/survey-form.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;AACF;AAAE;EACE,WAAA;EACA,kCAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;AAEJ;AAAE;EACE,UAAA;AAEJ;;AACA;EACE,WAAA;EACA,eAAA;EACA,kBAAA;AAEF;;AACA;EACE,yBAAA;AAEF;;AAAA;EACE,YAAA;EACA,YAAA;EACA,oBAAA;AAGF","sourcesContent":[".custBG {\n  width: 100%;\n  height: 100%;\n  background-color: #f4f5f6;\n  position: relative;\n  padding: 100px 0;\n  &::after {\n    content: '';\n    background-color: rgb(229, 27, 36);\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 30vh;\n    z-index: 0;\n  }\n  & > div {\n    z-index: 1;\n  }\n}\n.custInputRadio {\n  width: 20px;\n  aspect-ratio: 1;\n  margin-right: 15px;\n}\n\n.custColor {\n  background-color: #f5f8fa;\n}\n.logo {\n  width: 260px;\n  height: auto;\n  padding-bottom: 24px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
