import {Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { CompaniesService } from 'src/app/Modules/_fyxt_modules/companies/_services/companies.service';
import { BaseService } from 'src/app/services/base.service';
import { UtilityService } from 'src/app/services/utility.service';
import { HttpEventType } from '@angular/common/http';
import { ConfigService } from 'src/app/services/config.service';
import { JobsService } from 'src/app/services/v2/jobs.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { ModalComponent } from '../../../_reusable_ui/_components';
import { TicketFlowsService } from 'src/app/services/ticket-flows.service';
import { FilesService } from 'src/app/Modules/_fyxt_modules/companies/_services/files.service';
import { TabPhotobankPopupComponent, TabUploadFilesPopupComponent } from 'src/app/@fyxt/_shared/_views';
import {IVendorCOIInput} from "../../../models/coi/coi.interface";
import {CoiService} from "../../../../../Modules/_fyxt_modules/coi/_services/coi.service";
import { Observable, Subject } from 'rxjs';
import {takeUntil} from "rxjs/operators";
import {VendorCOIComponent} from "../vendor-coi/vendor-coi.component";
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { DropdownComponent } from '../../../_reusable_ui/_controls/dropdown/dropdown.component';
import { TextareaComponent } from '../../../_reusable_ui/_controls/textarea/textarea.component';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { DropdownSelectComponent } from '../../../_reusable_ui/_controls/dropdown-select/dropdown-select/dropdown-select.component';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
    selector: 'fyxt-add-job-cost',
    templateUrl: './add-job-cost.component.html',
    styleUrls: ['./add-job-cost.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, DropdownSelectComponent, NgxIntlTelInputModule, TextboxComponent, NgClass, TextareaComponent, DropdownComponent, NgFor, ButtonComponent, MatTooltipModule, MatIconModule, MatMenuModule, MatDividerModule, MatDialogModule]
})
export class AddJobCostComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<null> = new Subject();

  // change boolean to false
  @ViewChild('fileUpdate') fileUpdate: ElementRef;
  addJobCost!: FormGroup;
  editJobCostDetails: any;
  formSubmitted: boolean = false;
  @Input() updateJobCost: boolean = false;
  items_listsform: any;
  searchInput: string = "";
  pageSize: number = 25;
  all_Dropdown_VendorsList: any = [];
  selectedEquipments: any = [];
  jobCostDocuments: any = [];
  // For files
  documents: any = [];
  tempUploadedList: any[] = [];
  costTypeList: any[] = [
    { label: 'Bid', value: 'Bid' },
    { label: 'Invoice', value: 'Invoice' },
    { label: 'Change Order', value: 'Change Order' },
  ];
  selected_DraftJob: any = {};
  selectedTableData: any;
  costList: any;
  base: string;
  jobCostID: any;
  deleteSlectedFiles: any[]=[];
  allowTotalCalculation: boolean = true;

  allowCOIChecking: boolean;
  hasVendorCOI: boolean
  showCOIAlert: boolean;
  checkCOISettingKey = 'job_warning_no_coi';
  viewDetailCOILink: string;
  coiData: IVendorCOIInput;
  isLoader: boolean=false;
  showFileErrorMessage: boolean = false;
  readOnly = false;

  constructor(
    public formbuilder: FormBuilder,
    public _dialogService: DialogService,
    public _Config: ConfigService,
    public _companiesService: CompaniesService,
    public _utilService: UtilityService,
    public _baseService: BaseService,
    public _jobService: JobsService,
    private _fileService: FilesService,
    public _ticketService: TicketFlowsService,
    public _dialogRef: MatDialogRef<ModalComponent>,
    private readonly coiService: CoiService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.checkCOISettings()

    this.base = environment.files.http
    this.base = this.base.substring(0, this.base.length - 1);
    if (this.data.data.isEdit) {
      this.updateJobCost = true;
      this.allowTotalCalculation = false;
      this.readOnly = this.data.data.readOnly;
    }

    // To Update a vendor when it is newly added
    this._jobService.isJobUpdated.subscribe((isJobUpdated: any) => {
      if (isJobUpdated) {
        this.getScheduleDropdown_Users();
      }
    });

    this._jobService.isDraftJob.subscribe((selectedJobDetails: any) => {
      if (selectedJobDetails) {
        if (selectedJobDetails.id) {
          this.selected_DraftJob = selectedJobDetails;

          this.addJobCost.patchValue({
            issue_type: this.selected_DraftJob?.issue_type ? this.selected_DraftJob?.issue_type : '',
            Description: this.selected_DraftJob?.draft_items?.description ? this.selected_DraftJob?.draft_items?.description : ''
          });
        }
      }
    });

    this.getScheduleDropdown_Users();
    this.getAllEquipments(this.searchInput, 1, this.pageSize);
    this.initializeForm();

    this.coiData = {
      vendor_id: '',
      vendor_name: '',
      property_id: '',
      category_id: '',
      has_coi: false,
    }
  }

  ngOnInit(): void {
    this.costList = this.costTypeList.map((items) => {
      return items.value
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  initializeForm() {
    this.addJobCost = this.formbuilder.group({
      'cost_type': [null, Validators.required],
      'total_amount': [null, Validators.required],
      'vendor': [null, Validators.required],
      'title': ['', Validators.required],
      'description': [''],
      'equipment': [''],
      'items': this.formbuilder.array(this.updateJobCost ? [] : [this.lineItems()]),
      'exclusion': [''],
    });
    this.items_listsform = this.addJobCost.controls['items'];
    if (this.updateJobCost) {
      this.editJobCost(this.data.data.selectedData);
    }
  }

  editJobCost(jobCostID: any): void {
    let requestURL = environment.LocalDev_URL_V2 + `job/costs/${jobCostID}/`;
    this._baseService.doGET(requestURL).subscribe({
      next: (res: any) => {
        this.editJobCostDetails = res;
        this.jobCostID = res.id;
        this.setFormData();
        this.getJobCostFiles()
      },
    })
  }

  // for setformdata the value from the table should be populated
  setFormData() {
    this.addJobCost.patchValue({
      "vendor": this.editJobCostDetails?._vendor.name,
      "title": this.editJobCostDetails?.title,
      "cost_type": this.editJobCostDetails?.type,
      "total_amount": this.editJobCostDetails?.total,
      "description": this.editJobCostDetails?.description,
      "exclusion": this.editJobCostDetails?.exclusion,
      "documents": [],
      "equipment": this.editJobCostDetails?.assigned_equipment ? this.editJobCostDetails?.assigned_equipment : null,
      // "equipment": this.editJobCostDetails?.assigned_equipment.map((equipmentId: any) => {
      //   return equipmentId.name;
      // }),
    });
    this.addJobCost.setControl('items', this.setItemsList(this.editJobCostDetails.assigned_items));
    this.items_listsform = this.addJobCost.controls['items'];

    if (!!this.addJobCost.get('vendor').value) {
      this.checkVendorCOIsExistence(this.addJobCost?.get('vendor')?.value?.id);
    }
  }

  lineItems() {
    return this.formbuilder.group({
      item: [null],
      quantity: [null],
      unit_price: [null],
      total_cost: [null],
    });
  }

  setItemsList(items: any): FormArray {
    const formArray = new FormArray([]);
    if(items?.length > 0 ) {
      items.forEach((item) => {
        formArray.push(this.formbuilder.group({
          item: item.item,
          quantity: item.quantity,
          unit_price: item.unit_price,
          total_cost: item.total_cost
        }))
      });
    }
    return formArray;
  }

  removeItems(i: any) {
    this.itemsArray.removeAt(i);
    this.updateTotalAmount();
  }

  addMoreItems() {
    this.itemsArray.push(this.lineItems());
  }

  get formControl() {
    return this.addJobCost.controls;
  }

  get itemsArray() {
    const control = this.addJobCost.get('items') as FormArray;
    return control;
  }

  costType(event: any) {

  }

  onChangeTotal(event: any, type: any) {
    if(!isNaN(event.key)) {
      this.allowTotalCalculation = false;
    }
  }

  selectAssociatedEquipment(event: any) {

  }

  // Check if an object keys are having empty or null value
  areObjectKeysNotEmptyOrNull(obj) {
    for (let itemObject of obj) {
      for (let key in itemObject) {
        if (itemObject[key] === null || itemObject[key] === '') {
          return false;
        }
      }
      return true;
    }
  }

  addJobCostFormSubmit() {
    let photosList_New = this.tempUploadedList.map((obj) => {
      return obj.id;
    });
    let PostBody: any = {
      "job": this._jobService.selectedJobInfo.id,
      "vendor": this.addJobCost.value.vendor.id,
      "title": this.addJobCost.value.title,
      "type": this.addJobCost.value.cost_type,
      "total": this.addJobCost.value.total_amount,
      "description": this.addJobCost.value.description,
      "exclusion": this.addJobCost.value.exclusion
    };
    let equipment = this.addJobCost.value.equipment;
    if (Array.isArray(equipment)) {
      // equipment is an array, you can safely use map
      PostBody.equipment = (equipment?.length > 0) ? equipment.map((obj) => obj.id) : [];
    }
    if (this.addJobCost.value.items) {
      if (this.areObjectKeysNotEmptyOrNull(this.addJobCost.value.items)) {
        PostBody.items = this.addJobCost.value.items;
      } else {
        PostBody.items = [];
      }
    }

    /********** Set Proportions if Invoice selected **********/
    if (PostBody.type == 'Invoice') {

      let total = Number(this.addJobCost.value.total_amount);
      let management_cost = 0;
      let tenant_cost = 0;

      // if(this._jobService.selectedJobInfo.abstract?.rules?.manager>0 && this._jobService.selectedJobInfo.abstract?.rules?.tenant>0){

      //   management_cost = (total * Number(this._jobService.selectedJobInfo.abstract?.rules?.manager)) / 100;;
      //   tenant_cost = (total * Number(this._jobService.selectedJobInfo.abstract?.rules?.tenant)) / 100;;

      //   PostBody.management = Number(management_cost);
      //   PostBody.tenant = Number(tenant_cost);

      // }

      management_cost = (total * Number(this._jobService.selectedJobInfo.abstract?.rules?.manager)) / 100;;
      tenant_cost = (total * Number(this._jobService.selectedJobInfo.abstract?.rules?.tenant)) / 100;;

      PostBody.management = Number(management_cost);
      PostBody.tenant = Number(tenant_cost);


      // PostBody.management = this._jobService.selectedJobInfo.abstract?.rules?.manager;
      // PostBody.tenant = this._jobService.selectedJobInfo.abstract?.rules?.tenant;
    }

    let requestURL = environment.LocalDev_URL_V2 + `job/costs/`;
    this.isLoader = true;


    this._baseService.doPOST(requestURL, PostBody)
      .subscribe(
        (response: any) => {

          this.isLoader = false;

          this._utilService.showSuccess('', 'Job Cost Added Successfully.');
          if (photosList_New.length > 0) {
            this._jobService.linkPhotosToJob(response.id, 'JOB_COST', photosList_New);
          }

          if (this.deleteSlectedFiles.length > 0) {
            this.unlinkAllDeletedFiles();
          }

          /************ New Job Action Triggers ***************/
          this._jobService.triggerPusherEventForJobTabs('Job Cost',response?.id);
          this._dialogRef.close('Job Cost Added');

        },
        (error) => {
          this.isLoader = false;
          this._utilService.showErrorMessage(error);
        },
      );
  }


  // File Preview for Edit
  getJobCostFiles() {

    this._fileService.getFiles('JOB_COST', this.jobCostID, 'filename', 'ASC', '', 1, 25).subscribe((data: any) => {
      let filesTableRows = data?.data?.files;
      this.jobCostDocuments = filesTableRows
    })

  }
  updateJobCostFormSubmit() {
    let photosList_New = this.tempUploadedList.map((obj) => {
      return obj.id;
    });

    let PostBody: any = {
      "job": this._jobService.selectedJobInfo.id,
      "vendor": this.addJobCost.value.vendor.id,
      "title": this.addJobCost.value.title,
      "type": this.addJobCost.value.cost_type,
      "total": this.addJobCost.value.total_amount,
      "description": this.addJobCost?.value?.description,
      "exclusion": this.addJobCost?.value?.exclusion,
      "documents": [],
    };

    if (this.addJobCost.value.items) {
      if (this.areObjectKeysNotEmptyOrNull(this.addJobCost.value.items)) {
        PostBody.items = this.addJobCost.value.items;
      } else {
        PostBody.items = [];
      }
    }
    let equipment = this.addJobCost.value.equipment;
    if (Array.isArray(equipment)) {
      // equipment is an array, you can safely use map
      PostBody.equipment = (equipment?.length > 0) ? equipment.map((obj) => obj.id) : [];
    }
    /********** Set Proportions if Invoice selected **********/
    if (PostBody.type == 'Invoice') {

      let total = Number(this.addJobCost.value.total_amount);
      let management_cost = 0;
      let tenant_cost = 0;

      // if(this._jobService.selectedJobInfo.abstract?.rules?.manager>0 && this._jobService.selectedJobInfo.abstract?.rules?.tenant>0){

      //   management_cost = (total * Number(this._jobService.selectedJobInfo.abstract?.rules?.manager)) / 100;;
      //   tenant_cost = (total * Number(this._jobService.selectedJobInfo.abstract?.rules?.tenant)) / 100;;

      //   PostBody.management = Number(management_cost);
      //   PostBody.tenant = Number(tenant_cost);

      // }

      management_cost = (total * Number(this._jobService.selectedJobInfo.abstract?.rules?.manager)) / 100;;
      tenant_cost = (total * Number(this._jobService.selectedJobInfo.abstract?.rules?.tenant)) / 100;;

      PostBody.management = Number(management_cost);
      PostBody.tenant = Number(tenant_cost);

      // PostBody.management = this._jobService.selectedJobInfo.abstract?.rules?.manager;
      // PostBody.tenant = this._jobService.selectedJobInfo.abstract?.rules?.tenant;
    }

    let requestURL = environment.LocalDev_URL_V2 + `job/costs/${this.data.data.selectedData}/`;
    this._Config.isLoader = true;

    this._baseService.doPatch(requestURL, PostBody)
      .subscribe(
        (response: any) => {

          this._Config.isLoader = false;

          this._utilService.showSuccess('', 'Job Cost Updated Successfully.');


          if (photosList_New.length > 0) {
            this._jobService.linkPhotosToJob(response.id, 'JOB_COST', photosList_New);
          }

          if (this.deleteSlectedFiles.length > 0) {
            this.unlinkAllDeletedFiles();
          }


          /************ New Job Action Triggers ***************/
          this._jobService.triggerPusherEventForJobTabs('Job Cost',response?.id);
          this._dialogRef.close('Job Cost Updated');
          this._jobService.getSelectedJobTabCounts();
        },
        (error) => {
          this._Config.isLoader = false;
          this._utilService.showErrorMessage(error);
        },
      );
  }

  openUploadPhotos(type: any) {

    if (type == 'Photobank') {
      let popupData = {
        title: 'Photobank',
        component: TabPhotobankPopupComponent,
        data: {
          "entityId": null,
          "entityType": 'JOB'
        }
      };

      this._dialogService.openModal(popupData).subscribe(result => {
        if (result) {
          if (Array.isArray(result?.files)) {
            if (result.files.length > 0) {
              this.showFileErrorMessage = false;
              let datas = [];
              result.files.forEach(element => {
                datas.push(element.data)
              });
              this.tempUploadedList.push(...datas) || [];
            }

          }
        }

      });
    }
    else {

      let popupData = {
        title: 'File Upload',
        component: TabUploadFilesPopupComponent,
        data: {
          "entityId": null,
          "entityType": 'JOB'
        }
      };

      this._dialogService.openModal(popupData).subscribe((result: any) => {
        if (Array.isArray(result)) {
          this.showFileErrorMessage = false;
          this.tempUploadedList.push(...result) || [];
        }

      });
    }

  }

  deleteTempFile(file, index) {
    // this.deleteSlectedFiles.push(file);
    this.tempUploadedList.splice(index, 1);
  }

  deleteUploadedFile(file, index) {
    this.deleteSlectedFiles.push(file);
    this.jobCostDocuments.splice(index, 1);
  }

  unlinkAllDeletedFiles() {
    this.deleteSlectedFiles.forEach((file: any) => {
      this._fileService.deleteFile(file.key).subscribe((data: any) => {
        // this._utilService.showSuccess('', 'File Deleted');
      });
    });
  }


  getAllEquipments(searchInput: string, pageNumber: number, pageSize: number, sortColumn?: any, sortDirection?: any, filters?: any) {
    if (this._jobService.selectedJobInfo?.equipment?.length > 0) {
      let requestURL = environment.equipURL + `/jobs/equipment/?query={'id': [${this._jobService.selectedJobInfo?.equipment.map(e => e.startsWith("'") && e.endsWith("'") ? e : `'${e}'`).join(', ')}] , 'property':['${this._jobService.selectedJobInfo?.property?.id}'], 'property_name':[],'equipment_type':[],'assigned_id': []}&search=${searchInput}&columns=['id', 'image', 'name', 'equipment_type_name', 'category_name', 'specific_location', 'manufacturer_name', 'model_name', 'age', 'serial_number', 'asset_tag']&sort_column=${sortColumn ? sortColumn : 'name'}&sort_order=${sortDirection ? sortDirection : 'asc'}&page=${pageNumber}&size=${pageSize}`;
      this._baseService.doGET(requestURL).subscribe({
        next: (res: any) => {
          this.selectedEquipments = res.items || [];
        },
      });
    }
  }

  getScheduleDropdown_Users() {
    let requestURL = environment.baseURL + this._Config.getAllJobScheduleUsers;
    requestURL = requestURL.replace("{ID}", this._jobService.selectedJobInfo.id);
    requestURL = this._jobService.addSource(requestURL);
    this._baseService.doGET(requestURL).subscribe((response: any) => {
      let listItems: any[] = response || [];
      listItems.forEach(element => {
        element.company = element.name
      });
      this.all_Dropdown_VendorsList = listItems.filter(obj => obj.type === "Vendor");
      if(this.all_Dropdown_VendorsList.length === 1) {
        this.addJobCost.patchValue({
          vendor : {'id': this.all_Dropdown_VendorsList[0].id, 'name': this.all_Dropdown_VendorsList[0].name },
        });
        setTimeout(() => {
          this.checkVendorCOIsExistence(this.addJobCost?.get('vendor')?.value?.id);
        }, 1000)
      } else {
        this.all_Dropdown_VendorsList.map((obj) => {
          if(obj?.approved === true) {
            this.addJobCost.patchValue({
              vendor : {'id': obj.id, 'name': obj.name }
            });
            setTimeout(() => {
              this.checkVendorCOIsExistence(this.addJobCost?.get('vendor')?.value?.id);
            }, 1000)
          }
        });


        if (!!this.addJobCost?.get('vendor')?.value?.id) {
          setTimeout(() => {
            this.checkVendorCOIsExistence(this.addJobCost?.get('vendor')?.value?.id);
          }, 1000)
        }
      }
    },
      (error) => {});

  }

  updateTotalAmount() {
    if(this.addJobCost.controls['total_amount'].status === 'INVALID' || this.allowTotalCalculation || (this.addJobCost.controls['total_amount'].status === 'VALID' && this.addJobCost.controls['total_amount'].value === '0.00')) {
      let totalAmount: any = 0;
      this.items_listsform.controls.map(( obj ) => {
        totalAmount = parseFloat(totalAmount) + parseFloat(obj.value.total_cost)
      });
      this.addJobCost.controls['total_amount'].setValue(totalAmount);
      this.allowTotalCalculation = true;
    }
  }

  onCancel() {
    this._dialogRef.close();
    this.addJobCost.reset();
  }

  onSubmit() {
    this.formSubmitted = true;
    if(this.addJobCost.valid && this._jobService.selectedJobInfo.require_jobcost_files && this.tempUploadedList.length === 0 && this.jobCostDocuments.length === 0) {
      this.showFileErrorMessage = true;
      this.scrollToEnd();
      return;
    } else if (this._jobService.selectedJobInfo.require_jobcost_files && this.tempUploadedList.length === 0 && this.jobCostDocuments.length === 0) {
      this.showFileErrorMessage = true;
      return;
    }
    if (this.addJobCost.valid) {
      if (!this.updateJobCost) {
        this.addJobCostFormSubmit();
      }
    
    if (this.updateJobCost) {
      this.updateJobCostFormSubmit();
    }
  }
  }

  scrollToEnd() {
    const endPopupElement = document.getElementById('endPopup');    
    if (endPopupElement) {
      endPopupElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  // Handle Vendor's COI
  private checkCOISettings() {
    this.viewDetailCOILink = '/pm/coi';

    this.coiService.getCOISetting()
      .pipe(takeUntil(this.destroy$))
      .subscribe(settings => {
        this.allowCOIChecking = settings.find(s => s.key === this.checkCOISettingKey)?.enabled || false;
        this.showCOIAlert = false;
        this.hasVendorCOI = false;
      }, error => {
        this._utilService.showError('COI Setting', this.coiService.parseServiceError(error));
      })
  }

  COIAlertCloseDetection(): void {
    this.showCOIAlert = !this.showCOIAlert;
  }

  private checkVendorCOIsExistence(id: string): void {
    if (this.allowCOIChecking === false) {
      return;
    }

    if (!id) {
      return;
    }

    const vendors = this._jobService.selectedJobInfo?.vendors;
    const vendor = vendors.find(v => v.id === id)
    const vendorId = vendor?.company_id;
    const property = this._jobService.selectedJobInfo?.property
    const propertyId = property?.id;
    const categoryId = this._jobService.selectedJobInfo?.category?.id;

    if (vendorId && propertyId && categoryId) {
      this._jobService.getExistenceVendorsCOI(vendorId, propertyId, categoryId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(res => {
          this.hasVendorCOI = res;
          this.showCOIAlert = true;
          this.coiData = { vendor_id: vendorId, vendor_name: vendor.company, property_id: propertyId, category_id: categoryId, has_coi: this.hasVendorCOI };
          this.viewDetailCOILink = `/pm/coi/vendor/${vendorId}/property/${propertyId}/category/${categoryId}?has_coi=${this.hasVendorCOI}`
        }, err => {
          this._utilService.showError('Vendor COI checking', this.coiService.parseServiceError(err));
        })
    } else {}

  }

  openVendorCOI() {
    if (this.coiData) {
      let popupData = {
        title: `${this.coiData.vendor_name} COI`,
        component: VendorCOIComponent,
        containerClass: 'modal_lg_popup',
        data: { ...this.coiData }
      };

      this._dialogService.openModal(popupData).subscribe({
        next: (res) => {
        }
      });

    }
  }

  onVendorChange(event: any) {
    this.checkVendorCOIsExistence(event.id);
  }

   /** Track by Index  **/
   trackByIndex(index: number, item: any): number {
    return index;
  }

}
