import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { ConfigService } from '../../../services/config.service';
import { T_INSPECTION } from '../../../Modules/_fyxt_modules/leases/pages/lease-inspection/lease-inspection.interface';
import {
  CreateLease,
  IUnitsResult,
  Lease, LeaseOptionsItem,
  PermissionLease,
  UpdateLease
} from '../../_shared/models/porperty-manager/leases';
import { map } from 'rxjs/operators';
import { FilesService } from '../../../Modules/_fyxt_modules/companies/_services/files.service';
import { Member, OtherContact } from '../../_shared/models/checklist-signature/checklist-signature';
import { DocumentInfo } from '../../_shared/models/porperty-manager/documents';
import {
  InspectionSchedulerInput,
  InspectionSchedulerOptions,
  LeaseInspectionInput,
  PrioritizationOptions,
} from '../../_shared/models/porperty-manager/inspection-scheduler';
import { ILeaseInspectionJob } from './leases.interface';
import { ItemList } from '../../_shared/_reusable_ui/_controls/dropdown-select/itemType.class';
import {ILeasesAPIResponse} from "./leases-graphql.service";
import { Contact } from '../../_shared/models/porperty-manager/contacts';
import {CreateProvision, Provisions} from "../../_shared/models/porperty-manager/provisions";
import {ILeaseCategoryRule} from "../../_shared/models/porperty-manager/maintenance-terms-tab";
import {LEASE_STATUS} from "../../../Modules/_fyxt_modules/leases/leases.constants";


@Injectable({
  providedIn: 'root'
})
export class LeasesHttpService {
  constructor(
    private readonly http: HttpClient,
    private readonly configService: ConfigService,
    private readonly filesService: FilesService) {}

  leaseURL = `${environment.lease_URL}/leases`;
  contactURL = `${environment.lease_URL}/contacts`;
  leaseInspectionURL = `${environment.lease_URL}/inspection-scheduler`;
  leaseProvisionsURL = `${environment.lease_URL}/lease-provisions`;
  leaseRulesURL = `${environment.lease_URL}/lease-rules`;

  uploadDocument(formData, subDomain): Observable<HttpEvent<{ data: DocumentInfo }>> {
    const requestURL = `${environment.files.http}lease-documents`;
    return this.http.post<{ data: DocumentInfo }>(requestURL, formData, {
      headers: {
        'x-tenant-id': subDomain,
      },
      reportProgress: true,
      observe: 'events'
    });
  }

  doGETcc(endpoint_URL: string): Observable<any> {
    return this.http.get(`${environment.fastAPI_Actual_URL + '/' + endpoint_URL}`);
  }

  doGETca(endpoint_URL: string): Observable<any> {
    return this.http.get(`${environment.baseURL + endpoint_URL}`);
  }

  getContactsList(searchVal: string, pageNumber: any): Observable<any> {
    let assignContactsUrl = `${environment.fastAPI_Actual_URL}` + `/contacts/`;
    if (assignContactsUrl) {
      assignContactsUrl += `?columns=['id','first_name','last_name']`;
      assignContactsUrl += '&query=' + JSON.stringify({ type: ['Tenant'] });
    }
    if (searchVal && searchVal.trim() != '') {
      assignContactsUrl += `&search=${searchVal}`;
    }
    if (pageNumber > 1) {
      if (assignContactsUrl.includes('?')) {
        assignContactsUrl += '&page=' + pageNumber;
      } else {
        assignContactsUrl += '?page=' + pageNumber;
      }

    }
    return this.http.get(assignContactsUrl);
  }

  deleteLease(lease_id: number): Observable<any> {
    let deleteLease = `${this.leaseURL}` + `/${lease_id}`;
    return this.http.delete(deleteLease);
  }

  triggerInspection(data: { lease_id: number, date: string, inspection_type: T_INSPECTION, checklist_id: string, need_subtract_date: boolean }): Observable<any> {
    const url = `${environment.lease_URL}/inspection-scheduler/create-job`
    return this.http.post(url, data);
  }

  endLease(lease_id: number): Observable<any> {
    const url = `${environment.lease_URL}/leases/end-lease`
    return this.http.post(url, { lease_id });
  }

  getUnits(property_id: string, dates: {
    commencement_date: string;
    is_month_to_month: boolean;
    expiration_date: string
  }): Observable<IUnitsResult[]> {
    const url = `${environment.lease_URL}/leases/units`
    const convertedDates = {
      commencement_date: dates?.commencement_date ? new Date(dates?.commencement_date).toISOString() : null,
      expiration_date: dates?.expiration_date ? new Date(dates?.expiration_date).toISOString() : null,
      is_month_to_month: dates.is_month_to_month,
    }
    if (!convertedDates.commencement_date) delete convertedDates.commencement_date;
    if (!convertedDates.expiration_date) delete convertedDates.expiration_date;
    const params = { property_id, ...convertedDates };
    return this.http.get<any>(url, { params });
  }

  getDocument(id: string) {
    return this.filesService.getFile(id)
      .pipe(map((data: any) => data?.data?.file));
  }

  notifyMembers(members: Member[], options?: { isPublic?: boolean }): Observable<any> {
    let url = `${environment.coreMsURL}/checklist-signature/notify`;

    if (options?.isPublic) {
      url = `${environment.coreMsURL}/checklist-signature/public/notify`;
    }

    return this.http.post(url, { members });
  }

  notifyOtherTenantContacts(contact: OtherContact): Observable<any> {
    const url = `${environment.fastAPI_Actual_URL}/contacts/`;
    return this.http.post(url, { ...contact });
  }

  notifyOtherTenantContactsPublic(contact: OtherContact): Observable<any> {
    const url = `${environment.fastAPI_Actual_URL}/contacts/external/`;
    return this.http.post(url, { ...contact });
  }

  updateImportantContact(lease_id: number, contact_primary?: string): Observable<any> {
    const contact = {
      lease_id: lease_id,
      contact_primary: contact_primary || null,
    }
    let updateLeaseImportantContact = `${environment.lease_URL}` + `/leases/update-important-contact`;
    return this.http.put<Lease>(updateLeaseImportantContact, { ...contact });
  }

  createInspectionScheduler(template: InspectionSchedulerInput) {
    const url = `${environment.lease_URL}` + '/inspection-scheduler/';
    return this.http.post<any>(url, {...template});
  }

  updateInspectionScheduler(template_id: number, template: InspectionSchedulerInput) {
    const url = `${environment.lease_URL}` + `/inspection-scheduler/${template_id}`;
    return this.http.put<any>(url, {...template});
  }

  bulkInspectionScheduler(ids: number[], action: string) {
    const url = `${environment.lease_URL}` + '/inspection-scheduler/multi';
    return this.http.post<any>(url, {ids, action});
  }

  getAllTemplates(params: InspectionSchedulerOptions) {
    const url = `${environment.lease_URL}` + '/inspection-scheduler/templates';
    return this.http.post<any>(url, {...params});
  }

  getTemplatesWithPriority(params: PrioritizationOptions) {
    const url = `${environment.lease_URL}` + '/inspection-scheduler/templates-priority';
    return this.http.post<any>(url, {...params});
  }

  getTemplateById(template_id: any) {
    let url = `${environment.lease_URL}` + `/inspection-scheduler/${template_id}`;
    return this.http.post<any>(url, null);
  }

  getLeaseInspectionJobs(lease_id: number): Observable<ILeaseInspectionJob[]> {
    const url = `${environment.lease_URL}/inspection-scheduler/lease/${lease_id}/jobs`;
    return this.http.get<ILeaseInspectionJob[]>(url);
  }

  getInspectionByLease(lease_id: number): Observable<any> {
    const url = `${environment.lease_URL}/inspection-scheduler/lease-inspection/${lease_id}`;
    return this.http.get<any>(url);
  }

  updateInspectionLease(lease_id: number, template: LeaseInspectionInput): Observable<any> {
    const url = `${environment.lease_URL}/inspection-scheduler/lease-inspection/update`;
    return this.http.post<any>(url, {lease_id, input: { ...template }});
  }

  createLease(lease: CreateLease) {
    const url = this.leaseURL;
    const data = {
      tenant: lease.company?.id,
      property: lease.property?.id,
      units: lease.units,
      type: lease.type,
      external_code: lease.external_code,
      commencement_date: lease.commencement_date,
      expiration_date: lease.expiration_date,
      is_month_to_month: lease.is_month_to_month,
      inspection_scheduler_id: lease.inspection_scheduler_id,
      move_in_date: lease.move_in_date,
      move_out_date: lease.move_out_date,
      permission: lease.permission,
    }
    return this.http.post<any>(url, data);
  }

  getCount() {
    const url = `${this.leaseURL}/count`;
    return this.http.get<any>(url);
  }

  getLeases(options: any): Observable<ILeasesAPIResponse> {
    const url = `${this.leaseURL}/all`;
    return this.http.post<ILeasesAPIResponse>(url, options);
  }

  getCompanyLeases(options: any): Observable<ILeasesAPIResponse> {
    const url = `${this.leaseURL}/company`;
    return this.http.post<ILeasesAPIResponse>(url, options);
  }

  getPropertyLeases(options: any): Observable<ILeasesAPIResponse> {
    const url = `${this.leaseURL}/property`;
    return this.http.post<ILeasesAPIResponse>(url, options);
  }

  getLease(lease_id): Observable<any> {
    const url = `${this.leaseURL}/lease/${lease_id}`;
    return this.http.get<any>(url);
  }

  updateLease(lease: UpdateLease): Observable<Lease> {
    const lease_id = lease.id;
    delete lease.id;
    const url = `${this.leaseURL}/update/${lease_id}`;
    return this.http.put<Lease>(url, lease);
  }

  assignContact(lease_id: number, contact_id: string) {
    const url = `${this.contactURL}`;
    const data = {
      lease_id,
      contact_id,
    }
    return this.http.post<any>(url, data);
  }

  assignBulkContacts(leaseId: number, contactIds: string[]) {
    const url = `${this.contactURL}/bulk/assign`;
    const data = {
      lease_id: leaseId,
      ids: contactIds,
    }
    return this.http.post<any>(url, data);
  }

  removeContact(lease_id: number, contact_id: string) {
    const url = `${this.contactURL}/${lease_id}/${contact_id}`;
    return this.http.delete<any>(url);
  }

  removeBulkContacts(lease_id: number, ids: string[]) {
    const url = `${this.contactURL}/bulk/unassign`;
    const data = {
      lease_id,
      ids,
    }
    return this.http.post<any>(url, data);
  }

  updateContacts(lease_id: number, assignedContactsIds: string[], unassignedContactsIds: string[]) {
    const url = `${this.contactURL}/update`;
    const data = {
      lease_id,
      assigned_contacts: assignedContactsIds,
      unassigned_contacts: unassignedContactsIds,
    }
    return this.http.post<any>(url, data);
  }

  getContactById(contact_id: string): Observable<Contact> {
    const url = `${this.contactURL}/${contact_id}`;
    return this.http.get<any>(url);
  }

  getColumns() {
    const url = `${environment.lease_URL}/lease-columns`;
    return this.http.get<any>(url);
  }

  updateColumns(is_draft, params) {
    const url = `${environment.lease_URL}/lease-columns`;
    return this.http.post<any>(url, params, { params: { is_draft } });
  }

  getAllViews(): Observable<any[]> {
    return this.http.get(`${environment.lease_URL}/leases-views`) as Observable<any[]>
  }

  getViewColumns(view_id: string) {
    return this.http.get(`${environment.lease_URL}/leases-views/view/${view_id}/columns`)
      .pipe(map((cols) => {
        // remove sort icon
        // @ts-ignore
        return cols.map(col => {
          const { value } = col;
          if (['tenant', 'units'].includes(value)) {
            col.sortDisabled = true;
          }
          return col
        })
      }))
  }

  exportSelectedTableData(queryLeaseParams: LeaseOptionsItem) {
    // const url = `http://localhost:8010/leases/export`;
    const url = `${this.leaseURL}/export`;
    return this.http.post<any>(url, queryLeaseParams);
  }

  getProvisions(lease_id: number, params: { sort: string, order: string }): Observable<Provisions[]> {
    const url = `${this.leaseProvisionsURL}/lease/${lease_id}/all`;
    return this.http.get<Provisions[]>(url, { params });
  }

  createProvision(lease_id: number, input: CreateProvision): Observable<Provisions> {
    const url = `${this.leaseProvisionsURL}`;
    return this.http.post<Provisions>(url, input);
  }

  updateProvision(id: number, input: CreateProvision): Observable<Provisions> {
    const url = `${this.leaseProvisionsURL}/${id}`;
    return this.http.put<Provisions>(url, input);
  }

  deleteProvision(id: number): Observable<{ id: number }> {
    const url = `${this.leaseProvisionsURL}/${id}`;
    return this.http.delete<{ id: number }>(url);
  }

  getProvision(id: number): Observable<Provisions> {
    const url = `${this.leaseProvisionsURL}/${id}`;
    return this.http.get<Provisions>(url);
  }

  getLeaseCategoryRules(lease_id: number, category_id: string): Observable<ILeaseCategoryRule> {
    const url = `${this.leaseRulesURL}/all/lease/${lease_id}?category_id=${category_id}`;
    return this.http.get<ILeaseCategoryRule>(url).pipe(map(res => res[0]));
  }

  leaseBulkAction(lease_id: number[], action: string): Observable<any> {
    const url = `${this.leaseURL}/bulk-action?action=${action}`;
    return this.http.post<any>(url, { lease_id })
      .pipe(map(res => res[0]));
  }

  getLeaseExternalCodeFilter(lease_type: string, params: { page: number, search: string }) {
    const { page, search} = params;
    const pagination = 25;
    const take = page ? pagination * page : null;
    const skip = page ? take - pagination : null;

    const url = `${this.leaseURL}/filter/external-code`;
    return this.http.get<{ items: string[], total: number, size: number }>(url, { params: { type: lease_type, skip, take, search }});
  }

  updateLeaseStatus(lease_id: number, status: LEASE_STATUS) {
    const url = `${this.leaseURL}/status`;
    return this.http.post<any>(url, { lease_id, status });
  }
}
