import { Injectable } from '@angular/core';
import { google } from 'google-maps';
// import { MapsAPILoader, GoogleMapsAPIWrapper, AgmMap } from "@agm/core";

// declare var google: any;
declare var google;

@Injectable({
  providedIn: 'root'
})
export class MapServiceService {

	 bounds = null;

//  bounds: google.maps.LatLngBounds;
 markers: google.maps.Marker[];
 infoWindow: google.maps.InfoWindow;

origin: any = { 'lat': 0, 'lng': 0 };
center: any = { 'lat': 0, 'lng': 0 };	



  constructor(
	// private mapsAPILoader: MapsAPILoader

  ) { 
	// this.mapsAPILoader.load().then(() => {
	// 	this.bounds = new google.maps.LatLngBounds(
	// 	  new google.maps.LatLng(51.130739, -0.868052), // SW
	// 	  new google.maps.LatLng(51.891257, 0.559417) // NE
	// 	);
	//   });

  }

  protected mapReady(map) {
    map.fitBounds(this.bounds);
  }

  getAddressLocation(address: any) {

	// 

	if (address) {

		if (google) {

			let geocoder = new google.maps.Geocoder();
			geocoder.geocode({
				'address': address
			}, (results, status) => {
				if (status == google.maps.GeocoderStatus.OK) {
					this.center.lat = results[0].geometry.location.lat();
					this.center.lng = results[0].geometry.location.lng();
				}
			});

		}

	}

	return this.center;
}

getMyLocation() {
	let infoWindow = new google.maps.InfoWindow();
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(
			position => {
				this.origin.lat = position.coords.latitude;
				this.origin.lng = position.coords.longitude;
			});
	}
	return this.origin;
}

}


