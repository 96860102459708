export enum TableColumnTypes {
  text,
  date,
  checkbox,
  action,
  radio,
  image,
  icons,
  amount,
  textWithImage,
  arrayText,
  objText,
  textWithColor,
  actions_columns,
  DashboardText,
  dropdown,
  relationshipDropdown,
  priority,
  linkedJobsCount,
  usersListWithCountDropdown,
  equipmentListWithCountDropdown,
  textLeaseStatus,
  priorityText,
  innerHTML,
  approve_to_work,
  booleanWithColor,
  categoriesListWithCountDropdown,
  booleanWithColorForNotify,
  starred,
  paymentStatus,
  payPoint,
  dueAmount,
  assignedProperty,
  paymentNextDue,
  paymentBalance,
  transactionValue,
  action_buttons_engineer,
  paymentContacts,
  csvError,
  chipText,
  chipTextContent,
  managerName,
  chipOnlyCount,
  amountMaskWarn,
  url,
  chipListDropdown
}

export enum ColumnSortType {
  asc = 'asc',
  desc = 'desc',
  none = 'none'
}
