import { Component, OnInit, ViewChildren } from '@angular/core';

import { MatDialog, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { SoftAuthService } from 'src/app/Modules/soft-auth/services/soft-auth.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PhoneNoInputComponent } from '../../../_reusable_ui/_controls/phone-no-input/phone-no-input.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { RadioComponent } from '../../../_reusable_ui/_controls/radio/radio.component';
import { NgIf, NgFor } from '@angular/common';


@Component({
    selector: 'app-soft-auth-popup',
    templateUrl: './soft-auth-popup.component.html',
    styleUrls: ['./soft-auth-popup.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, RadioComponent, NgxIntlTelInputModule, PhoneNoInputComponent, MatFormFieldModule, MatDialogModule, ButtonComponent, TextboxComponent, NgFor]
})
export class SoftAuthPopupComponent implements OnInit {
  form: FormGroup;
  email: any;
  verified = false;
  formInput = ['input1', 'input2', 'input3', 'input4', 'input5', 'input6'];
  @ViewChildren('formRow') rows: any;
  type = null;
  LoginForm!: FormGroup;
  radioItems = [
    { name: 'Phone', value: 'phone' },
    { name: 'Emial', value: 'email' }
  ]
  isClicked: boolean = false;

  ngOnInit() {
    this.LoginForm = this.formBuilder.group({

      phone: [null, Validators.required],

    });
  }

  constructor(
    public formBuilder: FormBuilder,
    private toastService: ToastrService,
    private _softAuthService: SoftAuthService,
    public dialogRef: MatDialogRef<SoftAuthPopupComponent>,
    private router:Router) {
    this.form = this.toFormGroup(this.formInput);
  }

  toFormGroup(elements) {
    const group: any = {};

    elements.forEach(key => {
      group[key] = new FormControl('', Validators.required);
    });
    return new FormGroup(group);
  }

  keyUpEvent(event, index) {
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1;
    } else {
      pos = index + 1;
    }
    if (pos > -1 && pos < this.formInput.length) {
      this.rows._results[pos].nativeElement.focus();
    }

  }

  onSubmit() {}

  onChangeType(e) {
    this.type = e;
  }

  continue() {
    if (this.type == 'email') {
      this.validateEmailOtp();
    }
    else {
      let otp = `${this.form.value.input1}${this.form.value.input2}${this.form.value.input3}${this.form.value.input4}${this.form.value.input5}${this.form.value.input6}`
      this._softAuthService.verifySmsOtp({number: this.LoginForm.value.phone?.e164Number,otp: otp}).subscribe((data: any) => {
        localStorage.setItem('soft-auth-token', data.data.token);
        localStorage.setItem('soft-auth-id', data.data.userId);
        let pageURL = 'public/ticket/'+ this._softAuthService.activeTenant + '/view';
        this.router.navigate([pageURL]);
        this._softAuthService.userID = data.data.userId
        this.dialogRef.close({ event: 'login' });
      })
    }

  }


  sendSms() {
    this._softAuthService.sendSmsOtp({number: this.LoginForm.value.phone?.e164Number}).subscribe((data: any) => {
      this.toastService.success('Otp Sent to your Phone');
      this.verified = true;
    }, (err: any) => {
      this.toastService.error(err.error?.message)
    })

  }

  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  sendMail() {
    if (this.validateEmail(this.email)) {
      let payload = { "email": this.email }
      this._softAuthService.emailSendOtp(payload).subscribe((data: any) => {
        this.toastService.success('Otp Sent to your E-mail');
        this.verified = true;
      }, (err: any) => {

      })

    } else {
      this.toastService.error('not a valid E-mail')
    }
  }

  validateEmailOtp() {
    let otp = `${this.form.value.input1}${this.form.value.input2}${this.form.value.input3}${this.form.value.input4}${this.form.value.input5}${this.form.value.input6}`
    let payload = {
      "email": this.email,
      "otp": otp
    }
    this._softAuthService.emailValidateOtp(payload).subscribe((data: any) => {
      localStorage.setItem('soft-auth-token', data.data.token);
      localStorage.setItem('soft-auth-id', data.data.userId);
      let pageURL = 'public/ticket/'+ this._softAuthService.activeTenant + '/view';
      this.router.navigate([pageURL]);
      this._softAuthService.userID = data.data.userId
      this.dialogRef.close({ event: 'login' });

    })

  }


 /** Track by Index  **/
 trackByIndex(index: number, item: any): number {
  return index;
}


}
