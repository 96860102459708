import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { CreateChecklistPopupComponent } from '../../_popup_views/create-checklist-popup/create-checklist-popup.component';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { ChecklistTemplateComponent } from '../../_popup_views/checklist-template/checklist-template.component';
import { Router } from '@angular/router';
import { TableService } from 'src/app/@fyxt/_services/table/table.service';
import { BaseService } from 'src/app/services/base.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';
import { AssociatedEquipmentPopupComponent } from '../../_popup_views/associated-equipment-popup/associated-equipment-popup.component';
import { plannerService } from 'src/app/Modules/_fyxt_modules/service-planner/services/planner-service';
import {
  AssociateEquipmentMergeChecklistComponent,
  ChecklistAssociateEquipmentComponent
} from 'src/app/@fyxt/_shared/_views'
import {ConfigService} from "../../../../../services/config.service";
import {Subject} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { PhotoPreviewWithDownloadAndDeleteComponent } from '../../../_reusable_ui/_components/photo-preview-with-download-and-delete/photo-preview-with-download-and-delete.component';
import { MatIconModule } from '@angular/material/icon';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';
import { TextareaComponent } from '../../../_reusable_ui/_controls/textarea/textarea.component';
import { MatRadioModule } from '@angular/material/radio';
import { CheckboxComponent } from '../../../_reusable_ui/_controls/checkbox/checkbox.component';
import { CardComponent } from '../../../_reusable_ui/_components/card/card.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';
import { MatDividerModule } from '@angular/material/divider';
import { ThreeDotsDropdownComponent } from '../../../_reusable_ui/_components/three-dots-dropdown/three-dots-dropdown.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { NgIf, NgFor, NgTemplateOutlet, NgSwitch, NgSwitchCase, NgClass } from '@angular/common';

@Component({
    selector: 'fyxt-tab-checklist-planner',
    templateUrl: './tab-checklist-planner.component.html',
    styleUrls: ['./tab-checklist-planner.component.scss'],
    standalone: true,
    imports: [NgIf, LoaderSmallComponent, ThreeDotsDropdownComponent, MatDividerModule, SearchInputComponent, FormsModule, NgxIntlTelInputModule, NgFor, CardComponent, NgTemplateOutlet, NgSwitch, NgSwitchCase, CheckboxComponent, MatRadioModule, TextareaComponent, TextboxComponent, InfiniteScrollModule, MatIconModule, PhotoPreviewWithDownloadAndDeleteComponent, NgClass, ButtonComponent]
})
export class TabChecklistPlannerComponent implements OnInit {

  isLoader: boolean = false;
  toggle = true;
  allCheckList:any[]=[];
  @Input() checkListTemplateCount: any;
  @Input() checklistDetail: any;
  @Input() showItemsFiles: boolean;
  checklist: any;

  @ViewChild('saveTemplate')
  saveTemplate!: TemplateRef<any>;
  selectedEquipments:any[]=[];
  @Output() backToChecklist = new EventEmitter();
  @Output() onMenuSelect = new EventEmitter();
  currentUserInfo = localStorage.getItem('userobj')
    ? JSON.parse(localStorage.getItem('userobj'))
    : {};
  can_add_checklisttemplate:Boolean;
  can_engineer_assign_checklist_equipment:Boolean;
  checklistLimit = 25;
  checklistPageNumber = 1;
  searchChecklistItems: string = '';
  checklistResponseShallowCopy = [];
  isNextPaginationAvailable = '';
  checkListDetailsData = [];
  checkListItemsPaginationLoader = false;
  threeDotDropdownMenuHeaderLists: any[] = [
    {label: 'Save as a Template', value: 'Save as a Template'},
    {label: 'Remove from Job', value: 'Remove from Job'},
  ];
  searchChanges = new Subject<any>();


  constructor(
    public _dialogService: DialogService,
    private router: Router,
    public _plannerService:plannerService,
    public tableService: TableService,
    public _baseService: BaseService,
    public _utilService: UtilityService,
    private http: HttpClient,
    private _router: Router,
    public _Config: ConfigService,
  ) { }

  ngOnInit(): void {
    // this.loadChecklist();
    this.loadChecklistDetail();
    this.getCheckListItemsDetails(true);
    this.loadEquipments();
    this.checklist = this.checklistDetail;
    if(this.currentUserInfo?.current_Role=='Manager' || this.currentUserInfo?.current_Role=='Owner'){
      if(this.currentUserInfo.permissions.includes('add_checklisttemplate')){
        this.can_add_checklisttemplate = true;
      }
      else{
        this.can_add_checklisttemplate = false;
      }
    } else if(this.currentUserInfo?.current_Role=='Engineer'){
      this.can_add_checklisttemplate = false;
      this.can_engineer_assign_checklist_equipment = true;
    } else{
      this.can_add_checklisttemplate = true;
      this.can_engineer_assign_checklist_equipment = false;
    }
    this.searchChanges.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(payload => {
        this.onSearchChecklist(payload);
      });
  }

  loadChecklist(){
    this.isLoader = true;
    const id = this.router.url.split('/').pop();
    let requestURl = environment.baseURL + `planner/${id}/checklist_template/`;
    this._baseService.doGET(requestURl).subscribe({
      next:(res:any)=>{
        this.isLoader = false;
        this.allCheckList = res;
        this.allCheckList.forEach(element => {
          element.toggle = true
        });
      },
      error:(res:HttpErrorResponse)=>{
        this._utilService.showError('',res);
        this.isLoader = false;
      }
    })

  }

  loadChecklistDetail(){
    this.isLoader = true;
    const id = this.router.url.split('/').pop();
    let requestURl = environment.LocalDev_URL_V2 + `planner/`+id+`/checklist_template/?checklist=`+this.checklistDetail.id;
    if(this.checklistDetail.assigned_equipment_id){
      requestURl += `&equipment=`+ this.checklistDetail.assigned_equipment_id;
    }
    this._baseService.doGET(requestURl).subscribe({
      next:(res:any)=>{
        this.isLoader = false;
        this.checklist = res;
        if(this.checklistDetail.assigned_equipment_id){
          this.checklist.assigned_equipment_id = this.checklistDetail.assigned_equipment_id;
        }
      },
      error:(res:HttpErrorResponse)=>{
        this._utilService.showError('',res);
        this.isLoader = false;
      }
    })

  }

  loadEquipments(){
    let requestURL : any;
    if(this._plannerService.plannerEquipments.length > 0){
      requestURL = environment.equipURL + `/jobs/equipment/?query={'id': [${this._plannerService.plannerEquipments.map(e=>`'${e}'`)}]}&search=&columns=['name', 'image', 'id', 'equipment_type_name', 'specific_location', 'serial_number', 'asset_tag']&sort_column=name&sort_order=asc`;
      this._baseService.doGET(requestURL).subscribe({
        next: (res: any) => {
          this.selectedEquipments = res.items;
         this.isLoader = false;
        },
        error: (error) => {
          this.isLoader = false;
        },

      })
    }
  }


  toggleCard(i:number): void {
    this.allCheckList[i].toggle = !this.allCheckList[i].toggle;
  }

  createChecklist() {
    const id = this.router.url.split('/').pop();

    let popupData = {
      title: 'Create New Checklist',
      component: CreateChecklistPopupComponent,
      containerClass: 'modal-lg',
      data:{id:undefined, AssignChecklist:true}
    };

    this._dialogService.openModal(popupData).subscribe(result => {
      if(result){
        // If no equipment available in the job then the checklist will be directly applied without showing the popup.
        if (this._plannerService.plannerEquipments?.length == 0) {
          let payLoad: any = {
            checklist_id:result[0].id,
            equipment: []
          }
          let requestURL = environment.baseURL + `planner/${id}/planner-checklist-equipment/`;
          return this._baseService.doPatch(requestURL, payLoad).subscribe({
            next: (res: any) => {
              this._plannerService.plannerEquipmentsUpdate.next(true);
            this.ngOnInit()
            },
            error: (err: HttpErrorResponse) => {
              this._utilService.showError('', err)
            },
          })
        }
        else{
        let popupData = {
          title: `Does this checklist apply to equipment on this job? If so please select the equipment:`,
          // title: `Checklists - Associated Equipment`,
          component: AssociateEquipmentMergeChecklistComponent,
          // component: AssociatedEquipmentPopupComponent,
          containerClass: ['modal_lg_popup'],
          data:{e:this._plannerService.plannerEquipments,assignChecklist:true}
        };

        this._dialogService.openModal(popupData).subscribe(response => {
          if(response){
        let payLoad: any = {
          checklist_id:result[0].id,
          equipment: response
        }
        let requestURL = environment.baseURL + `planner/${id}/planner-checklist-equipment/`;
        this._baseService.doPatch(requestURL, payLoad).subscribe({
          next: (res: any) => {
            this.ngOnInit();
            this._plannerService.plannerEquipmentsUpdate.next(true);
          }
        })
          }
        });
      }
      }
    });



  }

  assignChecklist(){
    let popupData = {
      title: `Checklist Templates (` + this.checkListTemplateCount + `)`,
      component: ChecklistTemplateComponent,
      // containerClass: 'modal-lg'
      containerClass: ['modal-lg', 'modal_checklist_height'],
      data: {asPerNewDesign: true}
    };
    this._dialogService.openModal(popupData).subscribe(result => {
      if(result?.id) {
        if(this._plannerService.plannerEquipments?.length==0){
        const id = this.router.url.split('/').pop();
        let payLoad :any = {
          checklist_template: [result.id]
        }
        let requestURl = environment.baseURL + `planner/${id}/`;
        this._baseService.doPatch(requestURl,payLoad).subscribe({
          next:(res:any)=>{
            this._utilService.showSuccess('',"Checklist Assigned Successfully");
            this.loadChecklist();
            this._plannerService.plannerEquipmentsUpdate.next(true);
          },
          error:(res:HttpErrorResponse)=>{
            this._utilService.showError('',res)
          }, complete: () => {
            this.isLoader = false;
            this._plannerService.plannerTabCountUpdate.next(true);
          }
        });
      } else {
          let plannerEquipment = this._plannerService?.plannerEquipments.map(qqID=>qqID.replace(/'/g,''));
          let popupData = {
            title: `Does this checklist apply to equipment on this job? If so please select the equipment:`,
            // title: `[${this._jobService.selectedJobInfo?.id}] Checklists - Associated Equipment`,
            component: AssociateEquipmentMergeChecklistComponent,
            containerClass: ['modal-lg'],
            data: { e:plannerEquipment,assignChecklist:true }
          };
          this._dialogService.openModal(popupData).subscribe(response => {
            if(response){
              const id = this.router.url.split('/').pop();
              let payLoad: any = {
                checklist_id: result.id,
                equipment: response
              }
              let requestURL = environment.baseURL + `planner/${id}/planner-checklist-equipment/`;
              this._baseService.doPatch(requestURL, payLoad).subscribe({
                next: (res: any) => {
                  this._utilService.showSuccess('','Checklist Assigned Successfully');
                  this.loadChecklist();
                  this.loadEquipments();
                },
                error:(err:HttpErrorResponse) => {
                  this._utilService.showError('',err)
                }, complete: () => {
                  this.isLoader = false;
                  this._plannerService.plannerTabCountUpdate.next(true);
                }
              });
            }
          });
        }
      }
    });
  }

  getEquipmentName(assigned_equipment:any){
  if(assigned_equipment !==undefined && assigned_equipment){
    const getSelectedEquipment = this.selectedEquipments.find((equipment: any) => {
      return equipment.id == assigned_equipment;
    });
    if(getSelectedEquipment?.name)return getSelectedEquipment.name;
  }
  }


  associatedEquipment(checklist:any){
    if(this._plannerService.plannerEquipments?.length){
    let popupData = {
      title:  `Assign [${checklist.name}] to:`,
      component: AssociatedEquipmentPopupComponent,
      containerClass: ['modal_lg_popup'],
      data: { e:this._plannerService.plannerEquipments,assignChecklist:true }
    };

    this._dialogService.openModal(popupData).subscribe(result => {
      if (result) {
        let payLoad:any = {
          checklist_id: checklist.id,
          equipment: result
      }
      const id = this.router.url.split('/').pop();
      let requestURL = environment.baseURL + `planner/${id}/planner-checklist-equipment/`;
      this._baseService.doPatch(requestURL,payLoad).subscribe({
        next:(res:any)=>{
          this.loadChecklist();
          this.loadEquipments();
          this._plannerService.plannerTabCountUpdate.next(true);
        }
      })
      }
    });
  } else {
    this._utilService.showError('', 'Equipment must be added from the Equipment tab before being assigned to a Checklist');
  }
  }

  saveTemplatePopup(checklistID) {
    const id = this.router.url.split('/').pop();
    let requestURL = environment.baseURL + `planner/${id}/checklist-status/ `;
    let payLoad = {
      "checklist": checklistID
    }
    this._baseService.doPatch(requestURL, payLoad).subscribe({
      next: (res: any) => {
        // Show an alert popup if the checklist template with the same name already exists
        if (res?.status && res?.status === 400) {
          let popupData = {
            title: 'Save as Template',
            template: this.saveTemplate,
            confirmText: 'Okay',
            cancelText: '',
            button: true
          };
          this._dialogService.confirmDialog(popupData).subscribe((result: any) => {
          });
        } else {
          // Show success toaster if the checklist saved as a template successfully
          this._utilService.showSuccess('', res.message);
        }
      },
      error: (err: HttpErrorResponse) => {
        this._utilService.showError('', err);
      }
    })
  }


  toEquipment(id:any){
    const currentUserInfo: any = localStorage.getItem('userobj')?JSON.parse(localStorage.getItem('userobj')): {};
    if(currentUserInfo.current_Role==="Owner" || currentUserInfo.current_Role==="Manager"){
      // this.router.navigate([`/pm/equipment/view/${id}`]);
      window.open(`/pm/equipment/view/${id}`, '_blank');
    }
    if(currentUserInfo.current_Role==="Engineer"){
      // this.router.navigate([`/engineer/equipment/view/${id}`]);
      window.open(`/engineer/equipment/view/${id}`, '_blank');
    }
  }

  backList(){
    this.backToChecklist.emit();
  }

  protected getFilesByType(allFiles: any[], type: any) {
    let files = [];
    if (type == 'image') {
      files = allFiles.filter(obj => obj.mime_type.includes('image/'));
    } else if (type == 'documents') {
      files = allFiles.filter(obj => (obj.mime_type.includes('application/') || obj.mime_type.includes('pdf')));
    } else if (type == 'video') {
      files = allFiles.filter(obj => obj.mime_type.includes('video/'));
    }
    return files;
  }

  protected getFileName(file: any) {
    const extension = file.mime_type.split('/')[1];
    const fileNameWithoutExtension = file.file.substring(file.file.lastIndexOf('/') + 1, file.file.lastIndexOf('_'));
    const fileName = `${fileNameWithoutExtension}.${extension}`;
    return fileName
  }

   downloadPhoto(event: any): void {
    const extension = event.mime_type.split('/')[1];
    const fileNameWithoutExtension = event.file.substring(event.file.lastIndexOf('/') + 1, event.file.lastIndexOf('_'));
    const fileName = `${fileNameWithoutExtension}.${extension}`;
    this.http.get(event.file, {responseType: 'blob'}).subscribe(response => {
      const blob = new Blob([response], {type: response.type});
      const downloadUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);
    });
    // window.open(event.file);
  }

  onChecklistMenuSelect(event: any): void {
    this.onMenuSelect.emit(event);
  }

  assignEquipment(): void {
    if (this.checklist?.assigned_equipment) {
      this._utilService.showWarning(this.checklist?.assigned_equipment, 'was already assigned for this checklist');
    } else {
      this.associatedChecklist(this.checklistDetail);
    }
  }

  associatedChecklist(checkListDetails: any) {
    if (this._plannerService.plannerEquipments?.length) {
      const id = this._router.url.split('/').pop();
      let popupData = {
        title: `Assign ${checkListDetails.name} to:`,
        component: ChecklistAssociateEquipmentComponent,
        containerClass: 'modal_popup_45',
        data: {
          checkListDetails: checkListDetails,
          fromPlanner: true,
          id: this._plannerService.plannerEquipments,
          plannerID: id
        }
      };
      this._dialogService.openModal(popupData).subscribe(result => {
        if (result?.idAddEquipment) {
          this.checklist.assigned_equipment = result.assigned_equipment_name;
          this.checklist.assigned_equipment_id = result.assigned_equipment_id;
        }
      });
    } else {
      this._utilService.showError('', 'Equipment must be added from the Equipment tab before being assigned to a Checklist');
    }
  }
  getCheckListItemsDetails(loader: boolean): void {
    if (this.checklistDetail?.checklist_id) {
      this.isLoader = loader;
      const offset = (this.checklistPageNumber - 1) * this.checklistLimit;
      let requestURL = environment.LocalDev_URL_V2 + `checklist-template/${this.checklistDetail?.checklist_id}/items?limit=${this.checklistLimit}&offset=${offset}&page=${this.checklistPageNumber}`;
      if (this.searchChecklistItems.trim() != '') {
        requestURL += `&search=${this.searchChecklistItems}`;
      }
      this._dialogService.doGET(requestURL).subscribe(
        {
          next: (response: any) => {
            this.isNextPaginationAvailable = response.next;
            response.results.map((res: any) => {
              res.isToggle = true;
              res.checked = false;
              if (res.files && res.files?.length > 0) {
                res.files.forEach((element: any) => {
                  if (element.mime_type == 'pdf') {
                    element.mime_type = "application/pdf";
                  }
                });
              }
              return res;
            });
            // name field added for completion status and pass/fail tag checklist items
            response.results.map((data: any) => {
              if (data.response_type == 'Completion Status' || data.response_type == 'Pass/Fail/Flag' || data.response_type == 'Acceptable/NA/IssueNoted') {
                return data.responses.map((element: any) => {
                  return element.name = element.value;
                });
              } else {
                return null;
              }
            });
            // Choose many item change the check value true, if there is a checked value
            response.results.map((data: any) => {
              return data.responses.map((element: any) => {
                if (data?.value?.length > 0 && Array.isArray(data.value)) {
                  return data.value.map((value: any) => {
                    if (value.order == element.order) {
                      return element.checked = true;
                    }
                  });
                } else {
                  return null;
                }
              });
            });
            if (this.checklistPageNumber == 1) {
              this.checklistResponseShallowCopy = response.results;
            } else {
              this.checklistResponseShallowCopy = [...this.checklistResponseShallowCopy, ...response.results];
            }
            let groupOrder = 1;
            let getGroupedResponse = [];
            this.checklistResponseShallowCopy.map((checklist: any) => {
              if (checklist.group_name == null) {
                getGroupedResponse.push(checklist);
              } else {
                const findIndex = getGroupedResponse.findIndex((checklistResp: any) => {
                  return (checklistResp.group_name === checklist.group_name && checklistResp.group_order === checklist.group_order);
                });
                if (findIndex == -1) {
                  const grpItems = {
                    group_name: checklist.group_name,
                    group_order: groupOrder,
                    group_items_total_count: checklist.group_items_total_count,
                    group_items_completed_count: checklist.group_items_completed_count,
                    items: []
                  };
                  grpItems.items.push(checklist);
                  getGroupedResponse.push(grpItems);
                  groupOrder++;
                } else {
                  getGroupedResponse[findIndex]?.items?.push(checklist);
                }
              }
            });
            let itemOrder = 0;
            getGroupedResponse.map((checklist: any) => {
              itemOrder++;
              return checklist.orderItem = itemOrder;
            })
            this.checkListDetailsData = getGroupedResponse;
            console.log(this.checkListDetailsData, 'data');
            this.isLoader = false;
            this._Config.isLoader = false;
            this.checkListItemsPaginationLoader = false;
          },
          error: (error) => {
            this.isLoader = false;
            this.checkListItemsPaginationLoader = false;
            this._Config.isLoader = false;
            // this._Config.isLoader = false;
            this._utilService.showErrorMessage(error);
          },
          complete: () => {
            this.isLoader = false;
            this.checkListItemsPaginationLoader = false;
            this._Config.isLoader = false;
          }
        }
      );
    } else {
      return null
    }
  }

  searchChange(event: any): void {
    this.searchChanges.next(event);
  }

  onSearchChecklist(payload: any) {
    this.checklistPageNumber = 1;
    this.searchChecklistItems = payload;
    this.getCheckListItemsDetails(true);
  }

  fetchNextPage(): void {
    if (this.isNextPaginationAvailable != null && !this.checkListItemsPaginationLoader) {
      this.checklistPageNumber++;
      this.checkListItemsPaginationLoader = true;
      this.getCheckListItemsDetails(false);
    }
  }
}
