import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LangflowService {
  public hostUrl = environment.langflow.hostUrl;
  public apiKey = environment.langflow.apiKey;
  public flowId: string;
  public tweaks: Record<string, any> = {};

  constructor(private _baseService: BaseService) {
    if (!_baseService.currentUserInfo?.ai_bot_enabled) return

    if (_baseService.currentUserInfo?.langflow_config) {
      const { flow_id: flowId, config_component_name: configName, vector_store } = _baseService?.currentUserInfo?.langflow_config || {}

      if (flowId) this.flowId = flowId;
      if (configName) {
        const { first_name, last_name } = _baseService.currentUserInfo

        this.tweaks = {
          [configName]: {
            schema: _baseService?.currentUserInfo?.schema,
            user_name: first_name + ' ' + last_name,
            vector_store: vector_store,
          }
        }
      }
    }
  }
}
