import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class LoaderComponent implements OnInit {
  isLoader:boolean=false;

  constructor(
    public _Config: ConfigService,
    public changeDetector: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.isLoader=this._Config.isLoader;
    this.changeDetector.detectChanges();
  }

  ngDoCheck(): void {
    this.isLoader=this._Config.isLoader;
    this.changeDetector.detectChanges();
  }
}
