import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../button/button.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-photo-preview-with-download-and-delete',
    templateUrl: './photo-preview-with-download-and-delete.component.html',
    styleUrls: ['./photo-preview-with-download-and-delete.component.scss'],
    standalone: true,
    imports: [NgIf, ButtonComponent, MatIconModule]
})
export class PhotoPreviewWithDownloadAndDeleteComponent implements OnInit {
  @Input() isOpenImg: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() disableDelete: boolean = false;
  @Input() photo: any;
  @Input() showDeleteIcon: boolean;
  @Input() showDownloadIcon: boolean;
  @Output() onDeletePhoto = new EventEmitter();
  @Output() onDownloadPhoto = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onDelete(photo: any): void {
    this.onDeletePhoto.emit(photo);
  }

  onDownload(photo: any): void {
    this.onDownloadPhoto.emit(photo);
  }

  openImg(img) {
    if(this.isOpenImg && !this.readOnly){
      window.open(img.file)
    }
  }
}
