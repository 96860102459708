import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { from, Observable } from 'rxjs';

import { AuthService } from './auth.service';
import { KeycloakService } from 'keycloak-angular';
import { switchMap } from 'rxjs/operators';
import { environment } from "../../../environments/environment";

export const SoftAuthSkipHeader = 'SoftAuthSkipHeader';

export const ExternalVendorSkipVendor = 'ExternalVendorSkipVendor';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenInterceptorService {

  constructor(public injector: Injector, private readonly keycloakService: KeycloakService) { }

  getSchema(authService): string {
    // this is case when we need define only one schema for whole environment, only one use case is dynamic env for now
    if (environment['x-schema']) {
      return environment['x-schema'];
    }
    return (authService.schema_for_subdomain === undefined) ? 'web' : authService.schema_for_subdomain;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let _authService = this.injector.get(AuthService);
    let contactId: string | undefined = localStorage.getItem('soft-auth-id');

    /************ for Core & Public API Header *************/
    if (request.url.includes("login") || request.url.includes("forget-password") || request.url.includes("password/reset")) {
      request = request.clone({ url: request.url });
      return next.handle(request);
    }

    /************ for Soft Auth Contact ID Header *************/
    if (contactId && contactId != undefined) {
      if (!(request.url.includes('https://equipment') || request.url.includes('https://cc'))) {
        request = request.clone({
          setHeaders: { 'contact-id': `${contactId}` }
        });
      }
    }

    /************ for Soft Auth Tocken Header for few API's *************/
    if (request.headers.has(SoftAuthSkipHeader)) {
      let obj = {
        'x-secret-key': environment.externalVendor.xSecretKey
      };
      const token = localStorage.getItem('soft-auth-token');
      if (token == null) {
        obj["Authorization"] = '';
      } else {
        obj["Authorization"] = token;
      }

      // if (contactId != undefined) {
      //   obj["contact-id"] = contactId;
      // }

      let authRequest = request.clone({
        url: request.url,
        setHeaders: obj
      });
      return next.handle(authRequest);
    }


    /************ for Soft Auth Tocken Header for few API's *************/
    if (request.headers.has(ExternalVendorSkipVendor)) {

      let obj = {
        'x-secret-key': environment.externalVendor.xSecretKey
      };
      const jobId = request.headers.get('jobId');
      const token_obj = JSON.parse(localStorage.getItem('exv-contact-token'));
      const token = token_obj.find(item => item.jobId == jobId);

      if (token == null) {
        obj["Authorization"] = '';
      } else {
        obj["Authorization"] = token.token;
      }

      // if (contactId != undefined) {
      //   obj["contact-id"] = contactId;
      // }

      let authRequest = request.clone({
        url: request.url,
        setHeaders: obj
      });
      return next.handle(authRequest);
    }

    let dynamicURL = _authService.setDomain(request.url);

    let x_schema = this.getSchema(_authService);

    /************** Without Token Handle ******************/
    if (request.url.includes("members/onboard") || request.url.includes("users/verify") || request.url.includes("v1/external/") || request.url.includes("/exv_submit_bid") || request.url.includes("/exv_upload_coi") || request.url.includes("/exv_upload_photos") || request.url.includes("s3.amazonaws.com/photos/") || request.url.includes('jobs/service-form/') || request.url.includes('properties/service-form-dropdown') || request.url.includes('service-form-units') || request.url.includes('service-form-category/') || request.url.includes('service-form-photos/') || request.url.includes('mail/feedbacks/') || request.url.includes('jobs/equipment/exv') || request.url.includes('external/vendor-jobs/documents') || (request.url.includes('external/vendor-jobs/') && request.url.includes('job-checklist-items/'))) {
      if (request.url.includes("/exv_submit_bid")) {
        dynamicURL = dynamicURL.replace('/exv_submit_bid', '/')
      } else if (request.url.includes("/exv_upload_coi")) {
        dynamicURL = dynamicURL.replace('/exv_upload_coi', '/')
      } else if (request.url.includes("/exv_upload_photos")) {
        dynamicURL = dynamicURL.replace('/exv_upload_photos', '/')
      } else if (request.url.includes("jobs/equipment/exv")) {
        dynamicURL = dynamicURL.replace('jobs/equipment/exv', 'jobs/equipment')
      } else if (request.url.includes("external/vendor-jobs/documents/")) {
        dynamicURL = dynamicURL.replace('external/vendor-jobs/documents/', '');
      }

      // request = request.clone({ url: dynamicURL });
      if (request.url.includes("v1/external/") || request.url.includes("v2/external/")) {
        request = request.clone({
          url: dynamicURL,
          setHeaders: {
            'x-schema': `${x_schema}`,
            'x-secret-key': environment.externalVendor.xSecretKey
          }
        });
      } else {
        request = request.clone({
          url: dynamicURL,
          setHeaders: {
            'x-schema': `${x_schema}`,
            'x-secret-key': environment.externalVendor.xSecretKey
          }
        });
      }

      return next.handle(request);

    }

    /************** With Token Handler ******************/
    if (request.url.includes("job/photos/") || request.url.includes("job/costs/") || request.url.includes("cois/") || request.url.includes("users/photos/") || request.url.includes("download-template/") || request.url.includes("/budgets/upload") || request.url.includes("/actuals/upload") || request.url.includes("/coas/upload") || request.url.includes("/abstract_upload") || request.url.includes("labors/export/") || request.url.includes("documents/") || request.url.includes("photos/chats/") || request.url.includes("reports/download/") || request.url.includes("/downloadExcelReport") || request.url.includes('downloadInboxPhotos/')) {
      if (request.url.includes("job/photos/")) {
        dynamicURL = dynamicURL.replace('job/photos/', 'photos/');
      }
      if (request.url.includes("users/photos/")) {
        dynamicURL = dynamicURL.replace('users/photos/', 'users/');
      }
      else if (request.url.includes("/coas/upload")) {
        dynamicURL = dynamicURL.replace('/coas/upload', '/coas/');
      }
      else if (request.url.includes("/budgets/upload")) {
        dynamicURL = dynamicURL.replace('/budgets/upload', '/budgets/');
      }
      else if (request.url.includes("/actuals/upload")) {
        dynamicURL = dynamicURL.replace('/actuals/upload', '/actuals/');
      } else if (request.url.includes("/abstract_upload")) {
        dynamicURL = dynamicURL.replace('/abstract_upload', '')
      } else if (request.url.includes("photos/chats/")) {
        dynamicURL = dynamicURL.replace('chats/', '')
      }
      else if (request.url.includes("reports/download/")) {
        dynamicURL = dynamicURL.replace('download/', '')
      }
      else if (request.url.includes("downloadExcelReport/")) {
        dynamicURL = dynamicURL.replace('downloadExcelReport', 'download')
      } else if (request.url.includes("downloadInboxPhotos/")) {
        dynamicURL = dynamicURL.replace('downloadInboxPhotos/', 'download')
      }


      if (request.url.includes("download-template/") || request.url.includes("reports/download/") || request.url.includes("downloadExcelReport/") || request.url.includes("downloadInboxPhotos/") || request.url.includes("job/labors/download/")) {
        return from(this.keycloakService.getToken())
          .pipe(switchMap((token) => {
            const authRequest = request.clone({
              url: dynamicURL,
              headers: new HttpHeaders({
                'Authorization': `Bearer ${token}`,
                'x-schema': `${x_schema}`
              }),
              responseType: 'blob'
            });

            return next.handle(authRequest);
          })
          );

      } else {
        if (request.url.includes('s3.amazonaws.com/documents')) {
          return from(this.keycloakService.getToken())
            .pipe(switchMap((token) => {
              request = request.clone({
                url: dynamicURL,
                headers: new HttpHeaders({})
              });

              return next.handle(request);
            })
            );
        }
        /**** File Upload Headers  ****/
        return from(this.keycloakService.getToken())
          .pipe(switchMap((token) => {
            request = request.clone({
              url: dynamicURL,
              setHeaders: {
                'x-schema': `${x_schema}`,
                Authorization: `Bearer ${token}`,
              }
            });

            return next.handle(request);
          })
          );

      }

    } else if (request.url.includes("cc.") || request.url.includes("equipment")) {
      /**** Other API Headers  ****/
      if (request.url.includes("/upload-photo/")) {
        return from(this.keycloakService.getToken())
          .pipe(switchMap((token,) => {
            const currentUserInfo: any = localStorage.getItem('userobj')
              ? JSON.parse(localStorage.getItem('userobj'))
              : {};
            const authRequest = request.clone({
              url: dynamicURL,
              headers: new HttpHeaders({
                'Authorization': `Bearer ${token}`,
                'x-account': `${currentUserInfo.account}`,
                'x-schema': `${x_schema}`,
                'x-secret-key': environment.externalVendor.xSecretKey
              }),
            });
            return next.handle(authRequest);
          })
          );
      } else {
        return from(this.keycloakService.getToken())
          .pipe(switchMap((token,) => {
            const currentUserInfo: any = localStorage.getItem('userobj')
              ? JSON.parse(localStorage.getItem('userobj'))
              : {};
            let authRequest;
            if (request.url.includes("/files")) {
              authRequest = request.clone({
                url: dynamicURL,
                setHeaders: {
                  'Authorization': `Bearer ${token}`,
                  'x-account': `${currentUserInfo.account}`,
                  'x-schema': `${x_schema}`,
                  'x-secret-key': environment.externalVendor.xSecretKey
                },
              });
            } else {
              authRequest = request.clone({
                url: dynamicURL,
                setHeaders: {
                  //'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
                  'x-account': `${currentUserInfo.account}`,
                  'x-schema': `${x_schema}`,
                  'x-secret-key': environment.externalVendor.xSecretKey
                },
              });
            }

            // if (contactId != undefined) {
            //   authRequest.headers.headers.set('contact-id', contactId)
            // }
            return next.handle(authRequest);
          })
          );
      }
    }
    else {

      /**** Other API Headers  ****/
      return from(this.keycloakService.getToken())
        .pipe(switchMap((token) => {
          if (token == undefined) {
            request = request.clone({
              url: dynamicURL,
              setHeaders: {
                'x-schema': `${x_schema}`,
                'x-secret-key': environment.externalVendor.xSecretKey
              }
            });

            return next.handle(request);
          }

          let authRequest;
          if (request.url.includes("/files")) {
            authRequest = request.clone({
              url: dynamicURL,
              setHeaders: {
                'x-schema': `${x_schema}`,
                'Authorization': `Bearer ${token}`,
                'x-secret-key': environment.externalVendor.xSecretKey,
              },
            });
          } else {

            if (request.url.includes("healthcheck")) {
              authRequest = request.clone({
                url: dynamicURL,
                setHeaders: {
                  'x-schema': `${x_schema}`,
                  'Authorization': `Bearer ${token}`,
                  'x-secret-key': environment.externalVendor.xSecretKey
                },
              });
            }
            else {
              const currentUserInfo: any = localStorage.getItem('userobj')
                ? JSON.parse(localStorage.getItem('userobj'))
                : {};

              const headers: any = {
                'x-schema': `${x_schema}`,
                'x-secret-key': environment.externalVendor.xSecretKey
              }

              if (token) headers.Authorization = `Bearer ${token}`

              authRequest = request.clone({
                url: dynamicURL,
                setHeaders: headers,
              });
            }
          }

          // if (contactId != undefined) {
          //   authRequest.headers.headers.set('contact-id', contactId)
          // }

          return next.handle(authRequest);
        })
        );

    }
  }

}
