import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { initLeaseParams, LeasesStoreService } from '../../../../_services/leases/leases-store.service';
import { switchMap, take } from 'rxjs/operators';
import { ConfigService } from '../../../../../services/config.service';
import { UtilService } from '../../../../../services/v2/util.service';
import { BaseService } from '../../../../../services/base.service';
import { WarningDialogType } from './warning-dialog.interface';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { NgSwitch, NgSwitchCase, NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'fyxt-warning-dialog',
    templateUrl: './warning-dialog.component.html',
    styleUrls: ['./warning-dialog.component.scss'],
    standalone: true,
    imports: [NgSwitch, NgSwitchCase, NgIf, MatDialogModule, ButtonComponent, AsyncPipe]
})
export class WarningDialogComponent implements OnInit {

  protected readonly WarningDialogType = WarningDialogType;
  public readonly lease$ = this.leasesStoreService.currentLease$;
  public endType = 'current';
  public leaseName = null;
  public name: string;

  public dialogType: WarningDialogType;
  public warnTitle: string = '';
  public warnMessage: string = '';
  public haveCancel: boolean = true;
  public confirmText: string = '';

  constructor(
    public dialogRef: MatDialogRef<WarningDialogComponent>,
    public leasesStoreService: LeasesStoreService,
    public utilService: UtilService,
    public readonly _Config: ConfigService,
    public readonly _baseService: BaseService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    const type = this.data?.data?.type;
    this.dialogType = this.data?.data?.dialogType ?? this.dialogType;
    this.warnTitle = this.data?.data?.warnTitle ?? this.warnTitle;
    this.warnMessage = this.data?.data?.warnMessage ?? this.warnMessage;
    this.confirmText = this.data?.data?.confirmText ?? this.confirmText;
    this.haveCancel = this.data?.data?.haveCancel ?? this.haveCancel;
    if (type) this.endType = type;
  }

  ngOnInit(): void {
    const name = this.data?.data?.name;
    if (name) this.leaseName = name;
    if (name) this.name = name;
  }

  endLease(): void {
    if (this.endType === 'current') {
      this.leasesStoreService.currentLease$
        .pipe(
          take(1),
          switchMap((lease) => {
            return this.leasesStoreService.endLease(lease?.id);
          }))
        .subscribe(
          {
            next: () => {
              this.dialogRef.close();
              this.utilService.showSuccess('', 'Lease Ended Successfully.');
            },
            error: (error) => {
              this.dialogRef.close();
              this.utilService.showError('', 'Lease Ended Error');
            },
            complete: () => {
              this.leasesStoreService.setEventLeases('lease-ended');
              this._baseService.redirectTo('/pm/leases');
            },
          }
        );
    }
    if (this.endType === 'multi') {
      return this.dialogRef.close(this.endType)
    }
    this.dialogRef.close();
  }

  closeModal(): void {
    this.dialogRef.close(false);
  }
}
