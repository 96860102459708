import { Component, OnInit } from '@angular/core';

import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';

import { TableColumnTypes,ColumnSortType } from 'src/app/@fyxt/_shared/enums/table/table-column-types.enum';
import { AppTableSource } from 'src/app/@fyxt/_shared/models/app-table-model';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { TableService } from 'src/app/@fyxt/_services/table/table.service';
import { GraphqlAdditionalDataService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/additional-data/graphql-additional-data.service';
import { GraphqlEquipmentTypeService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/equipment-type/graphql-equipment-type.service';
import { TableComponent } from '../../../_reusable_ui/_components/table/table.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { NgIf } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';

@Component({
    selector: 'fyxt-tab-associated-equipment',
    templateUrl: './tab-associated-equipment.component.html',
    styleUrls: ['./tab-associated-equipment.component.scss'],
    standalone: true,
    imports: [SearchInputComponent, MatDividerModule, NgIf, LoaderSmallComponent, TableComponent]
})
export class TabAssociatedEquipmentComponent implements OnInit {

  dataSource!: AppTableSource;
  searchInput:string = '';
  isLoader:boolean = false;
  listAssociatedEquipments:any[];
  selectedViewTableRowsTotal: number;
  selectedViewTableRowsPageSize: number;
  selectedViewTableRowsPage: number;
  count:number;
  moduleName ="equipments";
  sort = {
    active: '',
    direction: ''
  };

  tableHeaders = [
    {
      value: 'name',
      sort: ColumnSortType.none,
      name: 'Equipment Name',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'property_name',
      sort: ColumnSortType.none,
      name: 'Property',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'specific_location',
      sort: ColumnSortType.none,
      name: 'Specific Location',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'serial_number',
      sort: ColumnSortType.none,
      name: 'Serial Number',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'asset_tag',
      sort: ColumnSortType.none,
      name: 'Asset Tag',
      type: TableColumnTypes.text,
      className: 'table-header'
    }
  ];

  constructor(
    public _dialogService: DialogService,
    private activatedRoute: ActivatedRoute,
    public _graphql:GraphqlAdditionalDataService,
    public _graphqlType:GraphqlEquipmentTypeService,
    public tableService: TableService,
    private _router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.loadAssocicatedEquipments(this.searchInput,1);
  }

  loadAssocicatedEquipments(searchInput:string, pageNumber:number, sortColumn?:any, sortDirection?:any, pageSize?: any){
   if(this.activatedRoute.snapshot.routeConfig.path === 'additionalData/:id' ||
   this.activatedRoute.snapshot.routeConfig.path === 'additionalData/:id/duplicateItem/:newId'){
    this.isLoader = true;
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this._graphql.associatedEquipmentsList(id, searchInput, pageNumber, sortColumn, sortDirection, pageSize).subscribe({
      next: (res:any)=>{
        if(res){
          this.listAssociatedEquipments = res.listAdditionalItemsEquipment?.items || [];
          if(!this.count)this.count =   res.listAdditionalItemsEquipment?.total;
          this.selectedViewTableRowsTotal =  res.listAdditionalItemsEquipment?.total;
          this.selectedViewTableRowsPageSize = res.listAdditionalItemsEquipment?.size;
          this.selectedViewTableRowsPage = res.listAdditionalItemsEquipment?.page;
        }
      },
      error(err:HttpErrorResponse) {},
      complete:()=>{
        this.isLoader = false;
        if(sortColumn || sortDirection ){
          let sortedHeaders:any[] = this.tableHeaders.map(element => {
            (element.value === sortColumn) ? (element.sort = sortDirection) : (element.sort = ColumnSortType.none );
            return element;
          });
          this.dataSource = this.tableService.setTableData(this.moduleName,sortedHeaders, this.listAssociatedEquipments, this.selectedViewTableRowsTotal,this.selectedViewTableRowsPage-1, this.selectedViewTableRowsPageSize);
        }
        else{
          this.tableHeaders.map(element => {
            return element.sort = ColumnSortType.none;
          });
          this.dataSource = this.tableService.setTableData(this.moduleName,this.tableHeaders, this.listAssociatedEquipments, this.selectedViewTableRowsTotal,this.selectedViewTableRowsPage-1, this.selectedViewTableRowsPageSize);
        }

      }
    });
   }
   else{
    this.isLoader = true;
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this._graphqlType.associatedEquipmentsList(id,searchInput,pageNumber,sortColumn,sortDirection, pageSize).subscribe({
      next: (res:any)=>{
        if(res){
          this.listAssociatedEquipments = res.listAssociatedEquipment?.items || [];
          if(!this.count)this.count =   res.listAssociatedEquipment?.total;
          this.selectedViewTableRowsTotal =  res.listAssociatedEquipment?.total;
          this.selectedViewTableRowsPageSize = res.listAssociatedEquipment?.size;
          this.selectedViewTableRowsPage = res.listAssociatedEquipment?.page;
        }
      },
      error(err:HttpErrorResponse) {},
      complete:()=>{
        this.isLoader = false;
        if(sortColumn || sortDirection ){
          let sortedHeaders:any[] = this.tableHeaders.map(element => {
            (element.value === sortColumn) ? (element.sort = sortDirection) : (element.sort = ColumnSortType.none );

            return element;
          });
          this.dataSource = this.tableService.setTableData(this.moduleName,sortedHeaders, this.listAssociatedEquipments, this.selectedViewTableRowsTotal,this.selectedViewTableRowsPage-1, this.selectedViewTableRowsPageSize);
        }
        else{
          this.tableHeaders.map(element => {
            return element.sort = ColumnSortType.none;
          });
          this.dataSource = this.tableService.setTableData(this.moduleName,this.tableHeaders, this.listAssociatedEquipments, this.selectedViewTableRowsTotal,this.selectedViewTableRowsPage-1, this.selectedViewTableRowsPageSize);
        }
      }
    });
   }
  }

  onSearch(e:string){
    this.searchInput = e;
    this.sort = {
      active: undefined,
      direction: undefined
    };
    this.loadAssocicatedEquipments(this.searchInput ? this.searchInput : "",1, this.sort.active, this.sort.direction, this.selectedViewTableRowsPageSize);
  }

  onPageChange(e:any){
    this.selectedViewTableRowsPage = e.pageIndex + 1;
    this.selectedViewTableRowsPageSize = e.pageSize;
    this.loadAssocicatedEquipments(this.searchInput, this.selectedViewTableRowsPage, this.sort.active, this.sort.direction, this.selectedViewTableRowsPageSize);
  }

  onSortChange(e:any){
    this.sort.active = e.active;
    this.sort.direction = e.direction;
    this.loadAssocicatedEquipments(this.searchInput,1, this.sort.active, this.sort.direction, this.selectedViewTableRowsPageSize);
  }

  onRowClick(event:any){
    this._router.navigate(['equipment', event.id], { relativeTo: this.route });
  }
}

