import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'fyxt-edit-users',
    templateUrl: './edit-users.component.html',
    styleUrls: ['./edit-users.component.scss'],
    standalone: true
})
export class EditUsersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
