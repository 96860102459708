import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RestAPIService {

  constructor(public _http: HttpClient, private _router: Router) {

  }


  /************** Base HTTP Request Methods *****************/

  /**
   * To Get Http Request for REST API
   * @param URL 
   * @param Options  
   */
  doGET(URL: string, Options?: any) {
    return this._http.get(URL, Options);
  }

  /**
   * To Post Http Request for REST API
   * @param URL   // The Endpoint URL
   * @param Body    //Post Input Body
   * @param Options   //Headers or Other Optional Params
   */
  doPOST(URL: string, Body?: any, Options?: any) {
    return this._http.post(URL, Body, Options);
  }

  /**
   * To Update Http Request for REST API
   * @param URL 
   * @param Body 
   * @param Options 
   */
  doPUT(URL: string, Body?: any, Options?: any) {
    return this._http.put(URL, Body, Options);
  }


  /**
   * To Delete Http Request for REST API
   * @param URL 
   * @param Options 
   */
  doDelete(URL: string, Options?: any) {
    return this._http.delete(URL, Options);
  }

  /**
   * If Patch Request for Http Restfull API
   * @param URL 
   * @param Body 
   * @param Options 
   */
  doPatch(URL: string, Body?: any, Options?: any) {
    return this._http.patch(URL, Body, Options);
  }

  /**
 * To Get Http Request for REST API
 * @param URL 
 * @param Options  
 */
  doGETBlobFile(URL: string) {
    return this._http.get(URL, { responseType: 'blob' }).pipe(switchMap((blob) => this.convertBlobToBase64(blob)));
    }

  /**
   * If redirect to next page
   * @param pageURL 
   */
  redirectTo(pageURL: any) {
    this._router.navigate([pageURL]);
  }

  convertBlobToBase64(blob: Blob) {
    return Observable.create((observer) => {
      const reader = new FileReader();
      const binaryString = reader.readAsDataURL(blob);
      reader.onload = (event: any) => {
        // console.log('Image in Base64: ', event.target.result);
        observer.next(event.target.result);
        observer.complete();
      };

      reader.onerror = (event: any) => {
        console.log('File could not be read: ' + event.target.error.code);
        observer.next(event.target.error.code);
        observer.complete();
      };
    });
  }


}
