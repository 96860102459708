import { Component, EventEmitter, Input, OnInit, Output, forwardRef, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
    selector: 'fyxt-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => CheckboxComponent)
        }
    ],
    standalone: true,
    imports: [MatCheckboxModule, FormsModule, NgxIntlTelInputModule, NgClass]
})
export class CheckboxComponent implements OnInit {
  @Input() checked?: boolean;
  @Input() id?: string;
  @Input() name?: string;
  @Input() disabled: boolean = false;
  @Output() change = new EventEmitter<boolean>();
  @Input() customClass: string = '';
  @Input() readOnly: boolean = false;

  inputControl!: any;

  public changed!: (value: string) => void;
  public touched!: () => void;
  onTouch: any = () => { }

  constructor() { }

  ngOnInit(): void {
  }


  // public onChange(event: Event): void {
  //   const value: string = (<HTMLInputElement>event.target).value;
  //   this.changed(value);
  // }


  registerOnChange(fn: any): void {
    this.onchange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(inputVal: any) {
    this.inputControl = inputVal;
  }

  /**
   * On Change Event
   * @param event
   */
  onchange(event:Event){
    const value: string = (<HTMLInputElement>event.target)?.value;
    if (value) {
      this.changed(value);
    }
    this.inputControl=event;
    this.change.emit(this.inputControl);

  }


}
