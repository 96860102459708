import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { TableColumnTypes, ColumnSortType } from 'src/app/@fyxt/_shared/enums/table/table-column-types.enum';
import { AppTableSource } from 'src/app/@fyxt/_shared/models/app-table-model';
import { RescheduleComponent } from '../../_popup_views/reschedule/reschedule.component';
import { TableComponent } from '../../../_reusable_ui/_components/table/table.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'fyxt-tab-schedule',
    templateUrl: './tab-schedule.component.html',
    styleUrls: ['./tab-schedule.component.scss'],
    standalone: true,
    imports: [NgIf, SearchInputComponent, NgClass, ButtonComponent, MatDialogModule, MatDividerModule, LoaderSmallComponent, TableComponent]
})
export class TabScheduleComponent implements OnInit {
  @Input() dataSource!: AppTableSource;
  @Input() isLoader!: boolean;
  @Input() contactsSearchValue!: string;
  @Input() viewModule: string;
  @Input() selectionTable: any;
  @Input() schedulePermissions: any = {};
  @Output() onRowClickValueChange = new EventEmitter();
  @Output() onThreeDotMenuChange = new EventEmitter<Event>();
  @Output() onSearchValueChange = new EventEmitter<Event>();
  @Output() onSortValueChange = new EventEmitter<Event>();

  constructor(public _dialogService: DialogService) { }

  ngOnInit(): void {}

  checkNormalTable() {
    if (this.viewModule == 'Tenant-portal') {
      return true;
    }

    return false;
  }


  onRowClick(event: any): void {
    this.onRowClickValueChange.emit(event);
  }

  threeDotMenuChange(event: any) {
    this.onThreeDotMenuChange.emit(event);
  }

  searchSchedules(value: any): void {
    this.onSearchValueChange.emit(value);
  }

  sortSchedules(sortState: any): void {
    this.onSortValueChange.emit(sortState);
  }

  addSchedule() {
    let popupData = {
      title: 'Add Schedule',
      component: RescheduleComponent,
      containerClass: '',
      data: {
        scheduleData: {},
        isEdit: false
      }

    };
    this._dialogService.openModal(popupData).subscribe(result => {

    });
  }


  checkUserPermissions(type: string) {
    if (this.viewModule == 'Tenant-portal') {
      if ((type == 'is_user_can_schedule_service_dates')) {
        return !!this.schedulePermissions?.is_user_can_schedule_service_dates;
      }
    }
  }

}


