import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { TableColumnTypes,ColumnSortType } from 'src/app/@fyxt/_shared/enums/table/table-column-types.enum';
import { AppTableSource } from 'src/app/@fyxt/_shared/models/app-table-model';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup } from '@angular/forms';
import { FilesService } from 'src/app/Modules/_fyxt_modules/companies/_services/files.service';
import { ToastrService } from 'ngx-toastr';
import { map, tap } from 'rxjs/operators';
import { ConfigService } from 'src/app/services/config.service';
import { DocumentInfo } from "../../../models/porperty-manager/documents";
import db from 'mime-db';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { MatDividerModule } from '@angular/material/divider';
import { UploadFilesComponent } from '../../../_reusable_ui/_components/upload-files-component/upload-files.component';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, DatePipe } from '@angular/common';

@Component({
    selector: 'app-replace-file',
    templateUrl: './replace-file.component.html',
    styleUrls: ['./replace-file.component.scss'],
    standalone: true,
    imports: [NgIf, MatIconModule, UploadFilesComponent, MatDividerModule, MatDialogModule, ButtonComponent, DatePipe]
})
export class ReplaceFileComponent implements OnInit {

  @Input() dataSource!: AppTableSource;
  fileData;
  isLeaseDocument:boolean;
  entityType:string;
  entityId:string;
  fileUploaded = false;
  isWarning = false;
  newFileData = {};
  source:string;
  fileToBeUploded;
  constructor(
    public http: HttpClient,
    public _dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private filesService:FilesService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<ReplaceFileComponent>,
    private configService:ConfigService
  ) {
    this.fileData = data.data.fileData;
    this.entityId = data.data.entityId;
    this.entityType = data.data.entityType;
    this.isLeaseDocument = data.data.isLeaseDocument;
    this.source = environment.files.http;
    this.source = this.source.substring(0,  this.source.length - 1);
  }

  myGroup = new FormGroup({
    url: new FormControl()
   });

  ngOnInit(): void {
    this.dummyData();
  }

  dummyData() {
    const tableHeaders = [
      {
        value: 'Image',
        sort: ColumnSortType.none,
        name: 'Name',
        type: TableColumnTypes.image,
        className: 'table-header'
      },
      {
        value: 'name',
        sort: ColumnSortType.none,
        name: '',
        type: TableColumnTypes.text,
        className: 'table-header'
      },
      {
        value: 'action',
        sort: ColumnSortType.none,
        name: '',
        type: TableColumnTypes.action,
        className: 'three-dot',
      },

    ];

    const Jpeg = "../../../../../assets/@fyxt/images/PartsDiagram.jpg";
    const Pdf = "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1667px-PDF_file_icon.svg.png";
    const link ="Repair Manual"

    const tableRows: any[] = [
      { name:'Repair Manual', imageLink:Pdf},
      { name:'Exploded Parts Diagram',imageLink:Jpeg},
      { name:'Purchase Invoice', imageLink:Pdf},
      { name:'Statement of Work', imageLink:Pdf},
      { name:'Warranty Terms', imageLink:Pdf},
    ];

    const source: AppTableSource = {
      headers: tableHeaders,
      rows: tableRows,
      length: tableRows.length,
    };
    this.dataSource = source;
  }


    //fake simulation of uploading file
    uploadFilesSimulator(file: File): Observable<HttpEvent<{ id: string }>> {
      const fakeProgress$ = new Subject<HttpEvent<{ id: string }>>();
      let progress = 0;

      const progressInterval = setInterval(() => {
        if (progress === 100) {
          const fakeHttpResponse = {
            type: HttpEventType.Response,
            body: { id: '123' }
          } as HttpResponse<{ id: string }>;

          fakeProgress$.next(fakeHttpResponse);
          clearInterval(progressInterval);

          return;
        } else {
          progress += 5;
        }

        const fakeProgressEvent = {
          type: HttpEventType.UploadProgress,
          loaded: progress,
          total: 100,
        };

        fakeProgress$.next(fakeProgressEvent as HttpProgressEvent);
      }, 70);

      return fakeProgress$.asObservable();
    }

    //common use of the download component
  getUploadFileHandler() {
    return this.uploadFileHandler.bind(this);
  }
  uploadFileHandler(file: File) {
    const entityId = null;
    const subDomain = this.filesService.subDomain;
    const entityType = this.entityType;
    const url = `${environment.files.http}files`;
    const formData: any = new FormData();
    this.fileToBeUploded = file;
    //any other fields
    formData.append('entityId', entityId);
    formData.append('entityType', entityType);
    formData.append('file', file);
    formData.append('title', file.name);

    return this.http.post<{ data: DocumentInfo }>(
      url,
      formData,
      {
        headers: {
          "x-tenant-id": subDomain
        }, reportProgress: true,
        observe: 'events'
      }
    ).pipe(
      map((event) => {
        if (event.type === HttpEventType.Response) {
          return {
            ...event,
            body: event.body.data,
          } as HttpResponse<DocumentInfo>;
        }

        return  event as HttpEvent<DocumentInfo>;
      }),
      tap((event) => {
        const isSuccessEventStatus = 'status' in event && event.status === 201;
        if (event.type === HttpEventType.Response && isSuccessEventStatus) {
          event.body['type'] = 'FILE';
          this.fileUploaded = true;
          this.newFileData = event.body;
        }
      }),
    );
  }

      uploadFromUrl(){
        let urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        var regex = new RegExp(urlRegex);
        if (this.myGroup.value.url.match(regex)) {
          let payload = {
            "entityId": this.entityId,
            "entityType": this.entityType,
            "url": this.myGroup.value.url
          }
          this.myGroup.patchValue({
            url:''
          })
          this.filesService.uploadUrl(payload).pipe(map((x:any)=>x.data)).subscribe((data:any)=>{
            this.fileUploaded = true;
            this.newFileData = data;

          //  data.type = 'URL'
            //this.uploadedList.push(data);

          },(err:any)=>{})

        } else {
          this.toastr.error('not a valid url');
        }

      }
  deleteFile(fileId: string) {
    //make http request to delete file
  }

  replaceFile(){
    this.configService.isLoader = true;
    const formData: any = new FormData();
    formData.append('file', this.fileToBeUploded);
    this.filesService.replaceFile(this.fileData.id, formData, { isLeaseDocument: this.isLeaseDocument }).subscribe((data:any)=>{
      this.configService.isLoader = false;
      this.dialogRef.close({event:'file replaced'})
    },(err:any)=>{
      this.configService.isLoader = false;
      this.dialogRef.close({ event: 'file replaced' })
    })
  }

}
