import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, FormsModule } from '@angular/forms';
import { BaseService } from 'src/app/services/base.service';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { ThreeDotsDropdownComponent } from '../../../_reusable_ui/_components/three-dots-dropdown/three-dots-dropdown.component';
import { DropdownSelectComponent } from '../../../_reusable_ui/_controls/dropdown-select/dropdown-select/dropdown-select.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { RadioComponent } from '../../../_reusable_ui/_controls/radio/radio.component';
import { NgClass, NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-sharing-viewers-popup',
    templateUrl: './sharing-viewers-popup.component.html',
    styleUrls: ['./sharing-viewers-popup.component.scss'],
    standalone: true,
    imports: [FormsModule, NgClass, RadioComponent, NgxIntlTelInputModule, NgIf, DropdownSelectComponent, NgFor, ThreeDotsDropdownComponent, ButtonComponent]
})
export class SharingViewersPopupComponent implements OnInit {

  public selectedRadioItem: string;
  id: any;
  commentsMenuList = [
    { label: 'Remove as Viewer', value: 'remove' },
    { label: 'Resend Invite', value: 'resend' }
  ];
  RadioButtonItem = [] = [
    { name: 'Limited Information(Public Link)', value: '1', description: 'This method only shares limited information like Company Name, Job Information and status via the Link Below' },
    { name: 'Detailed Information (Add as a Viewer)', value: '2', description: 'This method is only available for current contacts to be added as viewers to see the job Full details page that includes Company Name, Job Information, status, Permissions, assignee and more.' },
  ];
  formDropdownTypes: any[] = [
    { id: 1, label: 'first', name: 'Fyxt Company', group: '' },
    { id: 2, label: 'second', name: 'This is a List text Sample', group: '' },

  ];

  groupedItems: any[] = [


    {
      "id": "1",
      "title": "Shawn Freeman",

      "search_label": "Shawn Freeman"
    },
    {
      "id": "2",
      "title": "Shawn Stan",

      "search_label": "Shawn Stan"
    },
    {
      "id": "3",
      "title": "Shawn Wayans",

      "search_label": "Shawn Wayans"
    },


  ];
  groupbyItem = new FormControl(null);

  constructor(public _baseService: BaseService,) { }

  ngOnInit(): void {
  }

  onChangeLeaseType(event: any) {
    // if(event == 1){
    //   this.getLink();
    // }
    this.id = event;


  }

   /** Track by Index  **/
   trackByIndex(index: number, item: any): number {
    return index;
  }
}
