import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { OutsideClickDirective } from '../../../_directives/_custom_directives/click-outside.directive';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgIf, NgFor, NgStyle, CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';

@Component({
    selector: 'fyxt-chip',
    templateUrl: './chip.component.html',
    styleUrls: ['./chip.component.scss'],
    standalone: true,
    imports: [CommonModule,MatChipsModule, NgClass, NgIf, MatIconModule, OutsideClickDirective, MatCardModule, NgFor, NgStyle, MatTooltipModule],
    schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class ChipComponent implements OnInit {
  @Input() color: string = '';
  @Input() deletable = false;
  @Input() data!: Object;
  @Input() customClass: string = '';
  @Input() infoChip: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isDropdown: boolean = false;
  @Input() infoList: any[];
  @Input() showDropdown: boolean = false;
  @Input() isInbox: boolean = false;

  @Output() onDelete = new EventEmitter<Object>();
  @Output() showDropdownChange = new EventEmitter<Boolean>();
  isMenuOpen: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  /** Delete Chip **/
  deleteChip(): void {
    this.onDelete.emit(this.data);
  }

  /** Toggle More Chip Lists **/
  openMoreChipLists() {
    if (this.infoChip) {
      this.showDropdown = !this.showDropdown;
      this.showDropdownChange.emit(this.showDropdown);
      setTimeout(() => {
        this.isMenuOpen = !this.isMenuOpen;
      }, 500);
    }
  }

  /** Delete Selected Chip **/
  deleteInfoChip(chipList: any): void {
    this.onDelete.emit(chipList);
  }

  /** Outside Click Menu Close **/
  outsideClickMenuClose(hasClickedOutside: boolean) {
    if (hasClickedOutside && this.isMenuOpen) {
      this.showDropdown = false;
      this.isMenuOpen = false;
      this.showDropdownChange.emit(this.showDropdown);
    }
  }

  /** Track by ID  **/
  trackById(index: number, item: any): number {
    return item.id;
  }


}
