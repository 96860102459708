import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';

import { TableColumnTypes,ColumnSortType } from 'src/app/@fyxt/_shared/enums/table/table-column-types.enum';

import { AdditionalDataItemComponent } from 'src/app/@fyxt/_shared/_views';
import { AssignItemsComponent } from 'src/app/@fyxt/_shared/_views';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { GraphqlEquipmentManagementService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/equipment-management/graphql-equipment-management.service';
import { TableService } from 'src/app/@fyxt/_services/table/table.service';
import { EquipmentsService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/equipments.service';
import { debounce, scan, takeLast } from 'rxjs/operators';
import { interval } from 'rxjs';
import { TableComponent } from '../../../_reusable_ui/_components/table/table.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { NgIf } from '@angular/common';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';

@Component({
    selector: 'fyxt-tab-addional-data-equipment',
    templateUrl: './tab-addional-data-equipment.component.html',
    styleUrls: ['./tab-addional-data-equipment.component.scss'],
    standalone: true,
    imports: [SearchInputComponent, NgIf, ButtonComponent, MatIconModule, MatDividerModule, LoaderSmallComponent, TableComponent]
})
export class TabAddionalDataEquipmentComponent implements OnInit {

  dataSource!: any;
  tableHeaders = [
    {
      value: 'additional_data_item',
      sort: ColumnSortType.none,
      name: 'Item',
      type: TableColumnTypes.text,
      className: 'table-header',
      resizeColumn:true,
    },

    {
      value: 'value',
      sort: ColumnSortType.none,
      name: 'Value',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'action',
      sort: ColumnSortType.none,
      name: '',
      type: TableColumnTypes.action,
      className: 'three-dot',
      actions: [{ label: 'Edit', value: 'Edit'}, { label: 'Unassign', value: 'Unassign'}]
    }
  ];

  searchInput:string = '';
  isLoader:boolean = false;
  listAssociatedEquipments:any[]=[];
  selectedViewTableRowsTotal: number;
  selectedViewTableRowsPageSize: number;
  selectedViewTableRowsPage: number;
  count:number;
  moduleName ="equipments";
  itemName:any;
  sort = {
    active: '',
    direction: ''
  };

  constructor(
    public _dialogService: DialogService,
    private activatedRoute: ActivatedRoute,
    public _graphql:GraphqlEquipmentManagementService,
    public tableService: TableService,
    public equipementService:EquipmentsService
  ) {
    if(!this.equipementService.change_equipment){
      this.tableHeaders.pop()
    }
    this._graphql.additionalDataTab.subscribe((res:any)=>{
      if(res){
        this.loadEquipmentAdditionalData(this.searchInput,1);
      }
    })
  }

  ngOnInit(): void {
    this._graphql.AdditionaDataCount = 0;
    this.loadEquipmentAdditionalData(this.searchInput,1)
    this._graphql.getdeleteAssingedItems().pipe(scan(i => ++i, 1),debounce(i => interval(1 * i))).subscribe((data)=>{
      if(data){
        this.loadEquipmentAdditionalData(this.searchInput,1);
      }
    })
  }

  loadEquipmentAdditionalData(searchInput:string, pageNumber:number, sortColumn?:any, sortDirection?:any, pageSize?: any){
    if(this.activatedRoute.snapshot.routeConfig.path === 'view/:id'
    || this.activatedRoute.snapshot.routeConfig.path === 'equipmentType/:id/equipment/:id'
    || this.activatedRoute.snapshot.routeConfig.path === 'additionalData/:id/equipmentType/:id/equipment/:id'
    || this.activatedRoute.snapshot.routeConfig.path === 'additionalData/:id/equipment/:id'){
      this.isLoader = true;
      const id = this.activatedRoute.snapshot.paramMap.get('id');
      this._graphql.listAdditionalDataTab(this._graphql.selectedItem.id,searchInput,pageNumber,sortColumn,sortDirection, pageSize).subscribe({
        next: (res:any)=>{
          if(res){
            this.listAssociatedEquipments = res.listAssociatedItemsEquipment?.items || [];
            this._graphql.AdditionaDataCount =   res.listAssociatedItemsEquipment?.total || 0;
            this.selectedViewTableRowsTotal =  res.listAssociatedItemsEquipment?.total;
            this.selectedViewTableRowsPageSize = res.listAssociatedItemsEquipment?.size;
            this.selectedViewTableRowsPage = res.listAssociatedItemsEquipment?.page;

            this._graphql.additionalDataTabCountSubject.next(res?.listAssociatedItemsEquipment?.items?.length ?? null);
          }
        },
        error(err:HttpErrorResponse) {},
        complete:()=>{
          if(sortColumn || sortDirection ){
            let sortedHeaders:any[] = this.tableHeaders.map(element => {
              (element.value === sortColumn) ? (element.sort = sortDirection) : (element.sort = ColumnSortType.none );
              return element;
            });
            this.dataSource = this.tableService.setTableData(this.moduleName,sortedHeaders, this.listAssociatedEquipments, this.selectedViewTableRowsTotal,this.selectedViewTableRowsPage-1, this.selectedViewTableRowsPageSize);
          }
          else{
            this.tableHeaders.map(element => {
              return element.sort = ColumnSortType.none;
            });
            this.dataSource = this.tableService.setTableData(this.moduleName,this.tableHeaders, this.listAssociatedEquipments, this.selectedViewTableRowsTotal,this.selectedViewTableRowsPage-1, this.selectedViewTableRowsPageSize);
          }
          this.isLoader = false;
        }
      });
     }

  }

  onSearch(e:string){
    this.searchInput = e;
    this.sort = {
      active: undefined,
      direction: undefined
    };
    this.loadEquipmentAdditionalData(this.searchInput,1, this.sort.active, this.sort.direction, this.selectedViewTableRowsPageSize);
  }


  @ViewChild('removeAdditionalItem')
  removeAdditionalItem!: TemplateRef<any>;

  addAdditionalPopup(event:any) {
    let popupData = {
      title: `${this.itemName}`,
      data:event,
      component: AdditionalDataItemComponent,
      containerClass: ['modal-sm', 'modal_edit_colomn']
    };

    this._dialogService.openModal(popupData).subscribe(result => {});
  }




  assignItemsPopup() {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    let popupData = {
      title: 'Assign Items',
      id:id,
      component: AssignItemsComponent,
      disableClose: true,
      containerClass: 'assign_item_modal'
    };

    this._dialogService.openModal(popupData).subscribe(result => {});
  }

  openRemoveadditionalItemPopup(e:any) {

    let popupData = {
      title: `Unassign ${this.itemName}`,
      buttons:true,
      template: this.removeAdditionalItem,
      confirmText: 'Yes, Unassign',
      cancelText: 'Cancel',
      // selectedData: { name: 'san' },
    };

    this._dialogService.confirmDialog(popupData).subscribe((result:any) => {
      if(result){
        this._graphql.deleteItems(e.id)
      }
    });

  }


  menuSelectedOption(event: any){
    this.itemName = event.additional_data_item;
    if(event.label == "Edit"){
      this.addAdditionalPopup(event);
    }
    else if(event.label == "Unassign"){
      this.openRemoveadditionalItemPopup(event);
    }
  }

  onSortChange(e:any){
    this.sort.active = e.active;
    this.sort.direction = e.direction;
    this.loadEquipmentAdditionalData(this.searchInput,1, this.sort.active, this.sort.direction, this.selectedViewTableRowsPageSize);
  }

  onPageChange(e: any) {
    this.selectedViewTableRowsPage = e.pageIndex + 1;
    this.selectedViewTableRowsPageSize = e.pageSize;
    this.loadEquipmentAdditionalData(this.searchInput, this.selectedViewTableRowsPage, this.sort.active, this.sort.direction, this.selectedViewTableRowsPageSize);
  }

}
