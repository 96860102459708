import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField
} from 'ngx-intl-tel-input-gg';

import { ConfigService } from 'src/app/services/config.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { SignatureStatus } from '../@fyxt/_services/checklist-signatures'

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  private subject = new Subject<any>();

  draggedFiles: any[] = [];

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
    CountryISO.India
  ];


  DropdownTimes: any[] = [
    '12:00 AM',
    '12:05 AM',
    '12:10 AM',
    '12:15 AM',
    '12:20 AM',
    '12:25 AM',
    '12:30 AM',
    '12:35 AM',
    '12:40 AM',
    '12:45 AM',
    '12:50 AM',
    '12:55 AM',
    '1:00 AM',
    '1:05 AM',
    '1:10 AM',
    '1:15 AM',
    '1:20 AM',
    '1:25 AM',
    '1:30 AM',
    '1:35 AM',
    '1:40 AM',
    '1:45 AM',
    '1:50 AM',
    '1:55 AM',
    '2:00 AM',
    '2:05 AM',
    '2:10 AM',
    '2:15 AM',
    '2:20 AM',
    '2:25 AM',
    '2:30 AM',
    '2:35 AM',
    '2:40 AM',
    '2:45 AM',
    '2:50 AM',
    '2:55 AM',
    '3:00 AM',
    '3:05 AM',
    '3:10 AM',
    '3:15 AM',
    '3:20 AM',
    '3:25 AM',
    '3:30 AM',
    '3:35 AM',
    '3:40 AM',
    '3:45 AM',
    '3:50 AM',
    '3:55 AM',
    '4:00 AM',
    '4:05 AM',
    '4:10 AM',
    '4:15 AM',
    '4:20 AM',
    '4:25 AM',
    '4:30 AM',
    '4:35 AM',
    '4:40 AM',
    '4:45 AM',
    '4:50 AM',
    '4:55 AM',
    '5:00 AM',
    '5:05 AM',
    '5:10 AM',
    '5:15 AM',
    '5:20 AM',
    '5:25 AM',
    '5:30 AM',
    '5:35 AM',
    '5:40 AM',
    '5:45 AM',
    '5:50 AM',
    '5:55 AM',
    '6:00 AM',
    '6:05 AM',
    '6:10 AM',
    '6:15 AM',
    '6:20 AM',
    '6:25 AM',
    '6:30 AM',
    '6:35 AM',
    '6:40 AM',
    '6:45 AM',
    '6:50 AM',
    '6:55 AM',
    '7:00 AM',
    '7:05 AM',
    '7:10 AM',
    '7:15 AM',
    '7:20 AM',
    '7:25 AM',
    '7:30 AM',
    '7:35 AM',
    '7:40 AM',
    '7:45 AM',
    '7:50 AM',
    '7:55 AM',
    '8:00 AM',
    '8:05 AM',
    '8:10 AM',
    '8:15 AM',
    '8:20 AM',
    '8:25 AM',
    '8:30 AM',
    '8:35 AM',
    '8:40 AM',
    '8:45 AM',
    '8:50 AM',
    '8:55 AM',
    '9:00 AM',
    '9:05 AM',
    '9:10 AM',
    '9:15 AM',
    '9:20 AM',
    '9:25 AM',
    '9:30 AM',
    '9:35 AM',
    '9:40 AM',
    '9:45 AM',
    '9:50 AM',
    '9:55 AM',
    '10:00 AM',
    '10:05 AM',
    '10:10 AM',
    '10:15 AM',
    '10:20 AM',
    '10:25 AM',
    '10:30 AM',
    '10:35 AM',
    '10:40 AM',
    '10:45 AM',
    '10:50 AM',
    '10:55 AM',
    '11:00 AM',
    '11:05 AM',
    '11:10 AM',
    '11:15 AM',
    '11:20 AM',
    '11:25 AM',
    '11:30 AM',
    '11:35 AM',
    '11:40 AM',
    '11:45 AM',
    '11:50 AM',
    '11:55 AM',
    '12:00 PM',
    '12:05 PM',
    '12:10 PM',
    '12:15 PM',
    '12:20 PM',
    '12:25 PM',
    '12:30 PM',
    '12:35 PM',
    '12:40 PM',
    '12:45 PM',
    '12:50 PM',
    '12:55 PM',
    '1:00 PM',
    '1:05 PM',
    '1:10 PM',
    '1:15 PM',
    '1:20 PM',
    '1:25 PM',
    '1:30 PM',
    '1:35 PM',
    '1:40 PM',
    '1:45 PM',
    '1:50 PM',
    '1:55 PM',
    '2:00 PM',
    '2:05 PM',
    '2:10 PM',
    '2:15 PM',
    '2:20 PM',
    '2:25 PM',
    '2:30 PM',
    '2:35 PM',
    '2:40 PM',
    '2:45 PM',
    '2:50 PM',
    '2:55 PM',
    '3:00 PM',
    '3:05 PM',
    '3:10 PM',
    '3:15 PM',
    '3:20 PM',
    '3:25 PM',
    '3:30 PM',
    '3:35 PM',
    '3:40 PM',
    '3:45 PM',
    '3:50 PM',
    '3:55 PM',
    '4:00 PM',
    '4:05 PM',
    '4:10 PM',
    '4:15 PM',
    '4:20 PM',
    '4:25 PM',
    '4:30 PM',
    '4:35 PM',
    '4:40 PM',
    '4:45 PM',
    '4:50 PM',
    '4:55 PM',
    '5:00 PM',
    '5:05 PM',
    '5:10 PM',
    '5:15 PM',
    '5:20 PM',
    '5:25 PM',
    '5:30 PM',
    '5:35 PM',
    '5:40 PM',
    '5:45 PM',
    '5:50 PM',
    '5:55 PM',
    '6:00 PM',
    '6:05 PM',
    '6:10 PM',
    '6:15 PM',
    '6:20 PM',
    '6:25 PM',
    '6:30 PM',
    '6:35 PM',
    '6:40 PM',
    '6:45 PM',
    '6:50 PM',
    '6:55 PM',
    '7:00 PM',
    '7:05 PM',
    '7:10 PM',
    '7:15 PM',
    '7:20 PM',
    '7:25 PM',
    '7:30 PM',
    '7:35 PM',
    '7:40 PM',
    '7:45 PM',
    '7:50 PM',
    '7:55 PM',
    '8:00 PM',
    '8:05 PM',
    '8:10 PM',
    '8:15 PM',
    '8:20 PM',
    '8:25 PM',
    '8:30 PM',
    '8:35 PM',
    '8:40 PM',
    '8:45 PM',
    '8:50 PM',
    '8:55 PM',
    '9:00 PM',
    '9:05 PM',
    '9:10 PM',
    '9:15 PM',
    '9:20 PM',
    '9:25 PM',
    '9:30 PM',
    '9:35 PM',
    '9:40 PM',
    '9:45 PM',
    '9:50 PM',
    '9:55 PM',
    '10:00 PM',
    '10:05 PM',
    '10:10 PM',
    '10:15 PM',
    '10:20 PM',
    '10:25 PM',
    '10:30 PM',
    '10:35 PM',
    '10:40 PM',
    '10:45 PM',
    '10:50 PM',
    '10:55 PM',
    '11:00 PM',
    '11:05 PM',
    '11:10 PM',
    '11:15 PM',
    '11:20 PM',
    '11:25 PM',
    '11:30 PM',
    '11:35 PM',
    '11:40 PM',
    '11:45 PM',
    '11:50 PM',
    '11:55 PM'
  ];

  DropdownTimes_Obj: any[] = [
    {
      lable: '8:00 AM',
      value: '08:00'
    },
    {
      lable: '8:30 AM',
      value: '08:30'
    },
    {
      lable: '9:00 AM',
      value: '09:00'
    },
    {
      lable: '9:30 AM',
      value: '09:30'
    },
    {
      lable: '10:00 AM',
      value: '10:00'
    },
    {
      lable: '10:30 AM',
      value: '10:30'
    },
    {
      lable: '11:00 AM',
      value: '11:00'
    },
    {
      lable: '11:30 AM',
      value: '11:30'
    },
    {
      lable: '12:00 PM',
      value: '12:00'
    },
    {
      lable: '12:30 PM',
      value: '12:30'
    },
    {
      lable: '1:00 PM',
      value: '13:00'
    },
    {
      lable: '1:30 PM',
      value: '13:30'
    },
    {
      lable: '2:00 PM',
      value: '14:00'
    },
    {
      lable: '2:30 PM',
      value: '14:30'
    },
    {
      lable: '3:00 PM',
      value: '15:00'
    },
    {
      lable: '3:30 PM',
      value: '15:30'
    },
    {
      lable: '4:00 PM',
      value: '16:00'
    },
    {
      lable: '4:30 PM',
      value: '16:30'
    },
    {
      lable: '5:00 PM',
      value: '17:00'
    },
    {
      lable: '5:30 PM',
      value: '17:30'
    },
    {
      lable: '6:00 PM',
      value: '18:00'
    },
    {
      lable: '6:30 PM',
      value: '18:30'
    },
    {
      lable: '7:00 PM',
      value: '19:00'
    },
    {
      lable: '7:30 PM',
      value: '19:30'
    },
    {
      lable: '8:00 PM',
      value: '20:00'
    },
    {
      lable: '8:30 PM',
      value: '20:30'
    },
    {
      lable: '9:00 PM',
      value: '21:00'
    },
    {
      lable: '9:30 PM',
      value: '21:30'
    },
    {
      lable: '10:00 PM',
      value: '22:00'
    },
    {
      lable: '10:30 PM',
      value: '22:30'
    },
    {
      lable: '11:00 PM',
      value: '23:00'
    },
    {
      lable: '11:30 PM',
      value: '23:30'
    },
    {
      lable: '12:00 AM',
      value: '00:00'
    },
    {
      lable: '12:30 AM',
      value: '00:30'
    },
    {
      lable: '1:00 AM',
      value: '01:00'
    },
    {
      lable: '1:30 AM',
      value: '01:30'
    },
    {
      lable: '2:00 AM',
      value: '02:00'
    },
    {
      lable: '2:30 AM',
      value: '02:30'
    },
    {
      lable: '3:00 AM',
      value: '03:00'
    },
    {
      lable: '3:30 AM',
      value: '03:30'
    },
    {
      lable: '4:00 AM',
      value: '04:00'
    },
    {
      lable: '4:30 AM',
      value: '04:30'
    },
    {
      lable: '5:00 AM',
      value: '05:00'
    },
    {
      lable: '5:30 AM',
      value: '05:30'
    },
    {
      lable: '6:00 AM',
      value: '06:00'
    },
    {
      lable: '6:30 AM',
      value: '06:30'
    },
    {
      lable: '7:00 AM',
      value: '07:00'
    },
    {
      lable: '7:30 AM',
      value: '07:30'
    }
  ];


  sliderConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    cssEase: 'linear',
    nextArrow:
      "<button class='slick-next slick-arrow' type='button'><i class='fa fa-chevron-right'></i></button>",
    prevArrow:
      "<button class='slick-prev slick-arrow' type='button'><i class='fa fa-chevron-left'></i></button>"
  };

  evSliderConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    removeHistoryDuplicates: false,
    preventDuplicates: false,
    resetTimeoutOnDuplicate: false,
    countDuplicates: false,
    cssEase: 'linear',
    nextArrow:
      "<button class='slick-next slick-arrow' type='button'><i class='fa fa-chevron-right'></i></button>",
    prevArrow:
      "<button class='slick-prev slick-arrow' type='button'><i class='fa fa-chevron-left'></i></button>"
  };
  photoBankSliderConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    cssEase: 'linear',
    nextArrow:
      "<button class='slick-next slick-arrow margin_right_common'  type='button'><i class='fa fa-chevron-right  cursor-pointer  btn-font-color'></i></button>",
    prevArrow:
      "<button class='slick-prev slick-arrow margin_left_common' type='button'><i class='fa fa-chevron-left  cursor-pointer  btn-font-color'></i></button>"
  };

  countryDialCodes: any[] = [];
  URL = './assets/jsons/countrycodes.json';
  currentUserInfo: any = localStorage.getItem('userobj') ? JSON.parse(localStorage.getItem('userobj')) : {};

  dateDropdownItems: any[] = [
    { id: 1, name: 'Today', value: '0' },
    { id: 2, name: 'Yesterday', value: '-1' },
    { id: 3, name: 'Last 7 Days', value: '-7' },
    { id: 4, name: 'Last 14 Days', value: '-14' },
    { id: 5, name: 'Last 30 Days', value: '-30' },
    { id: 5, name: 'Custom', value: 'Custom' }
  ];

  dateDropdownItems_schedule: any[] = [
    { id: 1, name: 'Today', value: '0' },
    { id: 2, name: 'Yesterday', value: '-1' },
    { id: 3, name: 'Current Week', value: 'week' },
    { id: 4, name: 'Current Month', value: 'month' },
    { id: 5, name: 'Next 7 Days', value: '7' },
    { id: 6, name: 'Last 7 Days', value: '-7' },
    { id: 7, name: 'Next 14 Days', value: '14' },
    { id: 8, name: 'Last 14 Days', value: '-14' },
    { id: 9, name: 'Next 30 Days', value: '30' },
    { id: 10, name: 'Last 30 Days', value: '-30' },
    { id: 11, name: 'Custom', value: 'Custom' }
  ];

  owl_slider_carouselOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    animateOut: 'fadeOut',
    dots: true,
    nav: true,
    navText: ['<', '>'],
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 4 }
    }
  };

  constructor(
    private toastr: ToastrService,
    public _Config: ConfigService,
    private httpClient: HttpClient,
    private snackBar: MatSnackBar,
  ) {
    // this.httpClient.get("assets/jsons/times_15_mint_interval.json").subscribe((data: any) => {
    //   this.DropdownTimes_Obj = data || [];
    // })



    // if (this.currentUserInfo?.id) {
    //   setTimeout(() => {
    //     this.httpClient.get(this.URL).subscribe((response: any) => {
    //       this.countryDialCodes = response;
    //     });
    //   }, 15000);
    // }


  }

  /*************** Toaster Alert ******************/
  showSuccess(title: any, msg: any) {
    this.toastr.success(msg, title,{enableHtml: true});
  }

  showError(title: any, msg: any) {
    this.toastr.error(msg, title,{enableHtml: true});
  }

  showConnection(title: any, msg: any, timeOut: any) {
    this.toastr.error(msg, title, timeOut);
  }

  showWarning(title: any, msg: any) {
    this.toastr.warning(msg, title,{enableHtml: true});
  }

  showInfo(title: any, msg: any) {
    this.toastr.info(msg, title,{enableHtml: true});
  }

  /*************** Confirm Alert Dialog Box ******************/

  confirmDialog(
    showIcon: boolean,
    message: string,
    yesFn: () => void,
    noFn: () => void
  ): any {
    let showActionIcon = showIcon ? true : false;

    this.setConfirmation(message, yesFn, noFn, showActionIcon);
  }

  setConfirmation(
    message: string,
    yesFn: () => void,
    noFn: () => void,
    showIcon: boolean
  ): any {
    const that = this;
    this.subject.next({
      type: 'confirm',
      text: message,
      showIcon: showIcon,
      yesFn(): any {
        that.subject.next(); // This will close the modal
        yesFn();
      },
      noFn(): any {
        that.subject.next();
        noFn();
      }
    });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  setFormFieldErrors(formGroup: FormGroup, httpError: HttpErrorResponse, showSnack?: boolean, controlName?: any) {
    let err = httpError.error;
    if (httpError.status === 400 && err) {
      let errorKeys = Object.keys(err);
      errorKeys.forEach(e => {
        let error = err[e];
        //if show snackbar and form filed error
        if (formGroup && showSnack) {
          //if the error group is object
          if (typeof error[0] == 'object') {
            let errorObj = Object.values(error[0])
            this.showSnackNotification("danger", errorObj[0])
            formGroup.get(controlName)?.setErrors({ "filedError": error });
          }
          //if the error group is String
          else {
            this.showSnackNotification("danger", error);
            formGroup.get(controlName)?.setErrors({ "filedError": error });
          }
        }
        //if show snackbar error
        if (formGroup == null || !formGroup.get(e)) {
          if (showSnack) {
            //if the error group is object
            if (typeof error[0] == 'object') {
              let errorObj = Object.values(error[0])
              this.showSnackNotification("danger", errorObj[0])
            }
            //if the error group is String
            else {
              this.showSnackNotification("danger", error);
            }
          }

        }
        // show form field error
        else {
          formGroup.get(e)?.setErrors({ "filedError": error });
        }
      });
    }
  }

  showSnackNotification(colorName: string, text: any, placementFrom?: any, placementAlign?: any) {
    this.snackBar.open(text, "", {
      duration: 3000,
      verticalPosition: placementFrom ? placementFrom : "top",
      horizontalPosition: placementAlign ? placementAlign : "right",
      panelClass: "snackbar-" + colorName
    });
  }


  customFieldValidator(regExp: RegExp, errorMsg?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValid = regExp.test(control.value);
      return isValid ? null : { customFieldValidationError: errorMsg ? errorMsg : "Please enter valid input" };
    };
  }

  /*************** Common Error Notification ******************/

  showErrorMessage(error: any) {
    if (error.status === 500 || error.status === 0) {
      let errorCode = `Error Code: ${error.status}`;
      let errorMsg = `${error.message}`;
      errorMsg = errorMsg.replace(': 500 OK', '');
      this.showError(errorCode, errorMsg);
      return;
    }

    if (typeof error.error === 'string') {
      this.showError('', error.error);
      return;
    }

    let errors = Object.values(error.error) || [];
    if (errors.length > 0) {
      if (typeof Object.values(error.error)[0] == 'object') {
        let errorObj = Object.values(error.error)[0];
        // loop over values
        for (let errValue of Object.values(errorObj)) {
          this.showError('', errValue);
        }
      } else {
        let keyVal = Object.keys(error.error)[0];
        this.showError('', errors[0]);
      }
    } else this.showError('', error.error.detail);
  }

  getErrorMessage(error: any) {
    let errors = Object.values(error.error) || [];

    if (errors.length > 0) {
      if (typeof Object.values(error.error)[0] == 'object') {
        let errorObj = Object.values(error.error)[0];

        // loop over values
        for (let errValue of Object.values(errorObj)) {
          return errValue;
        }
      } else return errors[0];
    } else return error.error.detail;
  }

  /*************** Phone Number Input ******************/

  keyPressPhoneNumber(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  /******only character,number,periods, commas, and ampersands allow *******/
  idValidate(event) {
    if (event.charCode !== 0) {
      const pattern = /[.a-zA-Z0-9,& ]/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        event.preventDefault();
      }
    }
  }
  /****** ***** */

  /*** only character, numeric */
  nameValidate(event) {
    if (event.charCode !== 0) {
      const pattern = /[a-zA-Z0-9 ]/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        event.preventDefault();
      }
    }
  }
  /** ***** */

  /**
   * Get Format as +91 996-236-6362
   * @param value
   */
  getFormatedPhoneNumber(value: any) {
    if (value) {
      let mobile_number = value.toString();
      let phone_number = null;
      let code = '';

      if (mobile_number) {
        phone_number = mobile_number.substr(mobile_number.length - 10);

        if (mobile_number.length > 12) {
          code = mobile_number.substring(1, 3);
        } else if (mobile_number.length == 12) {
          code = mobile_number.substring(1, 2);
        }
      }

      let str = phone_number;
      let res = str.length;
      let i;
      let phone = '';
      for (i = 0; i < str.length; i++) {
        if (i == 3) {
          phone += '-' + str[i];
        } else if (i == 6) {
          phone += '-' + str[i];
        } else {
          phone += str[i];
        }
      }

      if (code) return '+' + code + ' ' + phone;
      else return phone;
    }

    return value;
  }

  /**
   * Get Format as +91
   * @param value
   */
  getCountryCode(value: any) {
    if (value) {
      let mobile_number = value.toString();
      let code = '';

      if (mobile_number) {
        if (mobile_number.length > 12) {
          code = mobile_number.substring(1, 3);
        } else if (mobile_number.length == 12) {
          code = mobile_number.substring(1, 2);
        }
      }

      if (code) return '+' + code;
    }

    return value;
  }

  /**
   * Get Formated Object
   * @param value
   */
  getFormatedPhoneNumberObject(value: any) {
    let phone_obj = {
      number: value.number,
      e164Number: value.e164_number,
      dialCode: value.dial_code,
      countryCode: value.country_code
    };

    return phone_obj;
  }

  /**
   * Set Phone number Object
   * @param phoneValue
   * @returns
   */
  getPhoneNumberData(phoneValue: any) {
    let phone_obj: any = phoneValue;
    let phoneNumber = phone_obj.phone;

    if (phoneNumber) {
      let countryCode = this.getCountryCode(phoneNumber);
      countryCode = countryCode.replace(' ', '');
      let value = this.countryDialCodes.find(obj => obj.dial_code === countryCode) || {}

      if (value?.code) {
        let e164_number = phoneNumber.replace(' ', '');
        let PhoneNumber = e164_number.replace(countryCode, '');

        phone_obj.phone = {
          number: PhoneNumber,
          e164Number: e164_number,
          dialCode: value?.dial_code,
          countryCode: value.code
        };
      }
    }


    return phone_obj;

  }


  alphaNumericValidation(event): boolean {
    var keyCode = event.keyCode || event.which;
    var regex = new RegExp('^[a-zA-Z0-9 ]+$');
    var key = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );

    if (!regex.test(key)) {
      return false;
    }

    return true;
  }

  zipCodeValidation(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      if (charCode == 45) {
        return true;
      }
      return false;
    }
    return true;
  }

  onlyNumberWithDecimal(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      if (charCode == 45 || charCode == 46) {
        return true;
      }
      return false;
    }
    return true;
  }

  onlyNumber(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      if (charCode == 45) {
        return true;
      }
      return false;
    }
    return true;
  }

  onlyValidNumber(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onlyValidCost(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  /**
   * Format to YYYY-MM-DD => 2021-01-29
   * @param date
   */
  formatDate(date: Date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  DateTimeValidations(shceduleDate: any, selectedVendor: any) {
    if (this._Config.isCustom_Timepicker) {
      let fromTime = selectedVendor.from_time;
      let toTime = selectedVendor.to_time;

      if (fromTime && toTime) {
        let startDatetime = new Date(
          this.formatDate(shceduleDate) + ' ' + fromTime.toLocaleTimeString()
        );
        let endDatetime = new Date(
          this.formatDate(shceduleDate) + ' ' + toTime.toLocaleTimeString()
        );

        var startTime = new Date(startDatetime).getTime();
        var endTime = new Date(endDatetime).getTime();

        if (startTime < endTime) {
          return false;
        } else if (startTime > endTime) {
          this.showError(
            'Time Error!',
            'End Time Should be Greater than Start time!'
          );

          return true;
        } else {
          this.showError(
            'Time Error!',
            'Start time and End time should not be same!'
          );
          return true;
        }
      } else {
        this.showError('', 'Please Select Start time and End time!');
        return true;
      }
    } else {
      let fromTime = selectedVendor.from
        ? selectedVendor.from
        : selectedVendor._from;
      let toTime = selectedVendor.to;

      if (fromTime && toTime) {
        let startDatetime = new Date(shceduleDate + ' ' + fromTime);
        let endDatetime = new Date(shceduleDate + ' ' + toTime);

        var startTime = new Date(startDatetime).getTime();
        var endTime = new Date(endDatetime).getTime();

        if (startTime < endTime) {
          return false;
        } else if (startTime > endTime) {
          this.showError(
            'Time Error!',
            'End Time Should be Greater than Start time!'
          );

          return true;
        } else {
          this.showError(
            'Time Error!',
            'Start time and End time should not be same!'
          );
          return true;
        }
      } else {
        this.showError('', 'Please Select Start time and End time!');
        return true;
      }
    }
  }

  /**
   * Format 24 hours format ( 17:06:12 )
   * @param date
   * @returns
   */
  formatto24(dateTime: any) {
    return new Date(dateTime).toLocaleString('en-US', {
      hour: 'numeric',
      hour12: false,
      minute: '2-digit'
    });
  }

  /**
   *  Format 12:30 AM to hours format ( 17:06:12 )
   * @param Time
   * @returns
   */
  formatTo24Hours(Time: any) {
    let ampm = Time.split(' ')[1];
    let time = Time.split(' ')[0];
    if (ampm == 'PM') {
      let hours = time.split(':')[0];
      let minutes = time.split(':')[1];
      let seconds = time.split(':')[2];
      let hours24 = JSON.parse(hours) + 12;
      // return hours24 + ":" + minutes + ":" + seconds;
      return hours + ':' + minutes + ':00';
    }
    if (ampm == 'AM') {
      let hours = time.split(':')[0];
      let minutes = time.split(':')[1];
      let seconds = time.split(':')[2];
      let hours24 = JSON.parse(hours) + 12;
      // return hours24 + ":" + minutes + ":" + seconds;
      return hours + ':' + minutes + ':00';
    } else {
      return time + ':00';
    }
  }

  /**
   * format from ( 17:06:12 ) to (4:21 pm)
   * @param date
   * @returns
   */
  formatAMPM(time: any) {
    let dateVal = new Date(time);
    var hours: any = dateVal.getHours();
    var minutes: any = dateVal.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  /**
   * Get File name from URL
   * @param url
   */
  getFileNameFromURL(url: any) {
    if (url) {
      let filename = url.split('/').pop().split('?')[0];

      let lastIndex = filename.lastIndexOf('_');
      let name = filename.substring(0, lastIndex);

      if (name) {
        var extension = url.split('.').pop();
        let ext =
          extension.split('?').length > 0 ? extension.split('?')[0] : 'pdf';

        let completFilename = name + '.' + ext;

        return completFilename;
      } else {
        return filename;
      }
    }
  }

  startRatingArray(i: number) {
    let starCounts: any[] = [];

    for (let index = 0; index < 5; index++) {
      let starClass = {};

      if (index < i) {
        starClass = { isActive: true };
      } else {
        starClass = { isActive: false };
      }

      starCounts.push(starClass);
    }

    return starCounts;
  }

  initalZeroRestrict(event) {
    if (event.key == 0) {
      if (event.target.value.length == 0) {
        event.preventDefault();
      }
    } else {
      if (event.key != 0) {
        const pattern = /[0-9]/;
        const inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
          event.preventDefault();
        }
      }
    }
  }

  /**
   * Get Object Values by Comma Separated
   * @param objItems
   */
  getItemsByCommaSeparated(objItems: any) {
    let stringValue: any = '';

    if (Array.isArray(objItems)) {
      if (objItems.length > 0) {
        stringValue = objItems
          .filter(function (el) {
            return el;
          })
          .join(', ');
      }
    } else {
      if (objItems) {
        stringValue = Object.values(objItems)
          .filter(function (el) {
            return el;
          })
          .join(', ');
      }
    }

    return stringValue;
  }


  getUserNames_withCommaSeparated(users:any[]){

    if(users && users?.length>0){
      let userNames=users.map(obj=>obj.full_name);
      return this.getItemsByCommaSeparated(userNames);
    }

    return 'Unassigned';

  }

  convertDateStringToDatePreview(dateVal: any) {
    if (dateVal) {
      let dateArr = dateVal.split('-');
      if (dateArr.length > 0) {
        let newDate = dateArr[1] + '/' + dateArr[2] + '/' + dateArr[0];
        return newDate;
      }
    }

    return null;
  }

  TimeConversion24To12Hours(date, timeString: any) {
    var hourEnd = timeString.indexOf(':');
    var H = +timeString.substr(0, hourEnd);
    var h: any = H % 12 || 12;
    var ampm = H < 12 ? 'AM' : 'PM';

    if (h < 10) h = '0' + h;

    timeString = h + timeString.substr(hourEnd, 3) + ' ' + ampm;

    return timeString;
  }

  timeDropdownFormatByTime(expected_window_from_time: any) {
    let fromDate = expected_window_from_time.split(':');
    let isAMPM_from: any;
    let isMinute_AMPM_arr: any;

    let set_expected_window_from_time: any = new Date().setHours(
      fromDate[0],
      fromDate[1],
      fromDate[2]
    );

    return fromDate[0] + ':' + fromDate[1];

    // set_expected_window_from_time = new Date(set_expected_window_from_time).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

    // let expected_window_from_time_arr = set_expected_window_from_time.split(':');

    // isMinute_AMPM_arr = expected_window_from_time_arr[1].split(' ');
    // isAMPM_from = isMinute_AMPM_arr[1];

    // let hour = expected_window_from_time_arr[0];
    // let minutes = isMinute_AMPM_arr[0];

    // if (fromDate[0] == '00')
    //   hour = 0;

    // let finalTimeFormat = hour + ':' + minutes + ' ' + isAMPM_from;

    // return finalTimeFormat;
  }

  getFirstLetterForImage(firstName: string, lastName: string): string {
    const userName = firstName + ' ' + lastName;
    const firstLetters = userName
      .split(' ')
      .map((word) => word[0])
      .join('')
      .toUpperCase();
    return firstLetters.substring(0, 2);
  }

  get2LetterName(nameString: any) {
    /*********** First 2 letters from string *****************/
    // if (nameString) {
    //   let first2Letters = nameString.substring(0, 2);
    //   first2Letters = first2Letters.toString();

    //   return first2Letters.toUpperCase();
    // }

    /***********  First letters of each Word *****************/
    if (nameString) {
      var matches = nameString.match(/\b(\w)/g); // ['J','S','O','N']
      var acronym = matches.join(''); // JSON
      let result = [];
      if (acronym.length > 2) {
        result = acronym.slice(0, 2);
      }
      else {
        result = acronym;
      }

      let shortName = result.toString();

      return shortName.toUpperCase();
    }
  }


  isSearchFieldValid(searchText: string, regExp?: RegExp) {
    const pattern = regExp ? regExp : /^[a-zA-Z0-9\-\@\.\s]*$/;
    const isValid = searchText != '' ? pattern.test(searchText) : false;
    return isValid;
  }

  /**
   * 24 Hours to date Time value
   * @param timeVal  ( 15:30 )
   * @returns
   */
  getTimeVal(timeVal: any) {
    let hours = timeVal.split(':');

    const d = new Date();
    d.setHours(hours[0]);
    d.setMinutes(hours[1]);

    return d;
  }

  /**
   * number n th value
   * @param d
   * @returns
   */
  nth(d) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  /**
   * Format to MM-DD-YYYY => 01-20-2023
   * @param date
   */
  formatDateToMM_DD_YYYY(date: Date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [month, day, year].join('-');
  }

  formatDateToYYYY_MM_DD(date: Date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  /**
  * Format to MM/DD/YYYY => 01/20/2023
  * @param date
  */
  formatDateToMM_DD_YYYY_Slash(date: Date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [month, day, year].join('/');
  }

  public getNowUTC() {
    const now = new Date();
    return new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
  }


  getSignatureTextColor(rowValue: any, isTenant: boolean = true): any {
    let signature = rowValue.signature;
    let signature_status = rowValue.signature_status;
    let required_signature = ``;

    if (signature) {
      const onlyTenant = Object.values(signature)?.filter(v => v)?.length === 1 && signature?.tenant;

      if ((signature?.manager == false && signature?.tenant == false && signature?.engineer == false && signature?.vendor == false) || onlyTenant && !isTenant) {
        required_signature = `<span  class="col">No signature required</span>`;
        return required_signature;
      }

      const SUCCESS_IMAGE: string = `<img src="assets/@fyxt/svgs/success_check_filled.svg" alt="success" class="success-icon" style="width: 16px;">`
      const ATTENTION_IMAGE: string = `<img src="assets/@fyxt/svgs/attention_filled.svg" alt="attention" class="attention-icon" style="width: 16px;">`

      const CONTENT_STYLES: string = 'display: flex; align-items: center; gap: 8px;'

      const getSuccessSpan = (content: string) => `<span style="color:#67b267; ${CONTENT_STYLES}">${SUCCESS_IMAGE}<div>${content}</div></span>`
      const getWarningSpan = (content: string) => `<span style="color:#A85D00; ${CONTENT_STYLES}">${ATTENTION_IMAGE}<div>${content}</div></span>`

      if (signature?.manager && signature_status?.manager === SignatureStatus.SIGNED) {
        required_signature += getSuccessSpan('Manager');
      } else if (signature?.manager && signature_status?.manager === SignatureStatus.UNSIGNED) {
        required_signature += getWarningSpan('Manager');
      }

      if (isTenant) {
        if (signature?.tenant && signature_status?.tenant === SignatureStatus.SIGNED) {
          required_signature += getSuccessSpan('Tenant');
        } else if (signature?.tenant && signature_status?.tenant === SignatureStatus.UNSIGNED) {
          required_signature += getWarningSpan('Tenant');
        }
      }

      if (signature?.engineer && signature_status?.engineer === SignatureStatus.SIGNED) {
        required_signature += getSuccessSpan('Engineer');
      } else if (signature?.engineer && signature_status?.engineer === SignatureStatus.UNSIGNED) {
        required_signature += getWarningSpan('Engineer');
      }

      if (signature?.vendor && signature_status?.vendor === SignatureStatus.SIGNED) {
        required_signature += getSuccessSpan('Vendor');
      } else if (signature?.vendor && signature_status?.vendor === SignatureStatus.UNSIGNED) {
        required_signature += getWarningSpan('Vendor');
      }

    }

    return `<div style="display: flex; align-items: center; column-gap: 32px; row-gap: 8px; flex-wrap: wrap" class="col">${required_signature}</div>`;

  }

  getCheckListSignatureTextColor(rowValue: any, isTenant: boolean = true, vendor?: any): any {
    let signature = rowValue.signature;
    let signature_status = rowValue.signature_status;
    let required_signature = ``;

    const currentUserInfo: { current_Role: string } = localStorage.getItem('userobj') ? JSON.parse(localStorage.getItem('userobj')) : {};
    const { current_Role } = currentUserInfo;
    if (current_Role && current_Role === 'Engineer') {
      delete signature.vendor;
    }

    if (signature) {
      const onlyTenant = Object.values(signature)?.filter(v => v)?.length === 1 && signature?.tenant;

      if ((signature?.manager == false && signature?.tenant == false && signature?.engineer == false && (!signature?.vendor || signature?.vendor == false)) || onlyTenant && !isTenant) {
        required_signature = `<span  class="col">No signature required</span>`;
        return required_signature;
      }

      const SUCCESS_IMAGE: string = `<img src="assets/@fyxt/svgs/success_check_filled.svg" alt="success" class="success-icon" style="width: 16px;">`
      const ATTENTION_IMAGE: string = `<img src="assets/@fyxt/svgs/attention_filled.svg" alt="attention" class="attention-icon" style="width: 16px;">`

      const CONTENT_STYLES: string = 'display: flex; align-items: center; gap: 8px; max-width: 120px;'

      const getSuccessSpan = (content: string) => `<span style="color:#67b267; ${CONTENT_STYLES}">${SUCCESS_IMAGE}<div class="text-truncate">${content}</div></span>`
      const getWarningSpan = (content: string) => `<span style="color:#A85D00; ${CONTENT_STYLES}">${ATTENTION_IMAGE}<div class="text-truncate">${content}</div></span>`

      if (signature_status?.manager === SignatureStatus.SIGNED) {
        required_signature += getSuccessSpan('Manager');
      } else if (signature_status?.manager === SignatureStatus.UNSIGNED) {
        required_signature += getWarningSpan('Manager');
      }

      if (isTenant) {
        if (signature_status?.tenant === SignatureStatus.SIGNED) {
          required_signature += getSuccessSpan('Tenant');
        } else if (signature_status?.tenant === SignatureStatus.UNSIGNED) {
          required_signature += getWarningSpan('Tenant');
        }
      }

      if (signature_status?.engineer === SignatureStatus.SIGNED) {
        required_signature += getSuccessSpan('Engineer');
      } else if (signature_status?.engineer === SignatureStatus.UNSIGNED) {
        required_signature += getWarningSpan('Engineer');
      }

      const currentUserInfo: { current_Role: string } = localStorage.getItem('userobj') ? JSON.parse(localStorage.getItem('userobj')) : {};
      const { current_Role } = currentUserInfo;
      if (current_Role !== 'Engineer') {
        if (signature_status?.vendor === SignatureStatus.SIGNED) {
          // required_signature += getSuccessSpan(vendor?.company || 'Vendor');
          required_signature += getSuccessSpan('Vendor');
        } else if (signature_status?.vendor === SignatureStatus.UNSIGNED) {
          required_signature += getWarningSpan('Vendor');
        }
      }

    }

    return `<div style="display: flex; align-items: center; column-gap: 32px; row-gap: 8px; flex-wrap: wrap" class="col">${required_signature}</div>`;

  }

  getPlannerCheckListSignatureTextColor(rowValue: any, isTenant: boolean = true): any {
    let signature = rowValue.signature;
    let signature_status = rowValue.signature_status;
    let required_signature = ``;

    if (signature) {
      const onlyTenant = Object.values(signature)?.filter(v => v)?.length === 1 && signature?.tenant;

      if ((signature?.manager == false && signature?.tenant == false && signature?.engineer == false) || onlyTenant && !isTenant) {
        required_signature = `<span  class="col pl-0">No signature required</span>`;
        return required_signature;
      }

      const getSuccessSpan = (content: string) => `${content}`;

      if (signature?.manager) {
        required_signature += getSuccessSpan('Manager');
      }

      if (isTenant) {
        if (signature?.tenant) {
          if(required_signature){
            required_signature += ', ';
          }
          required_signature += getSuccessSpan('Tenant');
        }
      }

      if (signature?.engineer) {
        if(required_signature){
          required_signature += ', ';
        }
        required_signature += getSuccessSpan('Engineer');
      }

    }

    return required_signature;

  }

  convertTo24Format(startTime: string, endTime: string) {
    const startHours = parseInt(startTime?.split(':')[0]);
    const startMeridiem = startTime?.split(' ')[1];
    const endHours = parseInt(endTime?.split(':')[0]);
    const endMeridiem = endTime?.split(' ')[1];

    // Convert to 24-hour format
    const startHours24 = (startMeridiem === 'AM' && startHours === 12) ? 0 : ((startMeridiem === 'PM' && startHours !== 12) ? startHours + 12 : startHours);
    const endHours24 = (endMeridiem === 'AM' && endHours === 12) ? 0 : ((endMeridiem === 'PM' && endHours !== 12) ? endHours + 12 : endHours);

    return [startHours24, endHours24]
  }


  routerRolebasedRedirection(url: any) {
    let setPrefix = "/pm";
    if (this.currentUserInfo.current_Role == 'Tenant') {
      setPrefix = '/tenant';
    }
    else if (this.currentUserInfo.current_Role == 'Engineer') {
      setPrefix = '/engineer';
    }

    let pageURL = setPrefix + url;

    // this._router.navigate([pageURL]);

    return pageURL;

  }

  setUTCDate(dateVal: any) {
    if(dateVal){
      let dateArr = dateVal.split('-');
      // return new Date(Date.UTC(dateArr[0], dateArr[1], dateArr[2]));
      let d = new Date();
      d.setDate(dateArr[2]);
      d.setMonth(dateArr[1] - 1);
      d.setFullYear(dateArr[0]);
      return d;
    }
    return new Date();
  }

  /******* Get Unique List from Array *******/
  getUniqueListBy(arr: { map: (arg0: (item: any) => any[]) => Iterable<readonly [unknown, unknown]>; }, key: string | number) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }
}
