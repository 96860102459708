import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {environment} from "../../../../../../environments/environment";
import {TabUploadFilesPopupComponent} from "../tab-upload-files-popup/tab-upload-files-popup.component";
import {DialogService} from "../../../../_services/dialog/dialog.service";
import {InboxInsertSnippetComponent} from "../inbox-insert-snippet/inbox-insert-snippet.component";
import {
  InboxSettingsService
} from "../../../../../Modules/_fyxt_modules/inbox-management/_services/inbox-settings.service";
import {EmailMessage, Thread} from "../../../../../Modules/_fyxt_modules/inbox-management/inbox.types";
import {map, takeUntil} from "rxjs/operators";
import {InboxService_New} from "../../../../../Modules/_fyxt_modules/inbox-management/_services/inbox.service";
import {Subject} from "rxjs";
import {UtilService} from "../../../../../services/v2/util.service";
import { MatMenuModule } from '@angular/material/menu';
import { NotesHolderComponent } from '../../_tab_components/tab-notes/notes-holder/notes-holder.component';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TextEditorComponent } from '../../../_reusable_ui/_controls/text-editor/text-editor.component';
import { ChipComponent } from '../../../_reusable_ui/_components/chip/chip.component';
import { NgIf, NgFor } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
    selector: 'fyxt-inbox-send-message-popup',
    templateUrl: './inbox-send-message-popup.component.html',
    styleUrls: ['./inbox-send-message-popup.component.scss'],
    standalone: true,
    imports: [MatExpansionModule, NgIf, NgFor, ChipComponent, TextEditorComponent, MatTooltipModule, ButtonComponent, MatIconModule, NotesHolderComponent, MatMenuModule]
})
export class InboxSendMessagePopupComponent implements OnInit {
  private readonly destroy$: Subject<null> = new Subject();
  showToggleComposer: boolean;
  isFromBulkReply: boolean;
  isFromAssociatedJobs: boolean = false;
  openComposerFromThirdPanel: boolean
  checkedThreads = [];
  content= '';
  uploadedFiles = [];
  base: string;
  toolbarState = true;
  isLoaderForSend = false;
  signatureText = '';
  messageThread: string = '';

  constructor(private _dialogService: DialogService, public inboxSettings: InboxSettingsService, private _inboxService: InboxService_New,
              public _utilService: UtilService, public dialogRef: MatDialogRef<InboxSendMessagePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.showToggleComposer = this.data?.data?.showToggleComposer;
    this.checkedThreads = this.data?.data?.checkedThreads;
    this.isFromBulkReply = this.data?.data?.isFromBulkReply;
    this.openComposerFromThirdPanel = this.data?.data?.openComposerFromThirdPanel;
    this.base = environment.files.http;
    this.isFromAssociatedJobs = this.data?.data?.isFromBulkReply
    this.base = this.base.substring(0, this.base.length - 1);
    this.getInboxSignature();
    this.constructMessageThread();    
  }


  ngOnInit(): void {
  }

  getInboxSignature() {
    this.inboxSettings.getInboxSignature(this.checkedThreads[0].mailboxId).pipe(map((data:any)=>data.data)).subscribe((data: any) => {
      this.signatureText = data.signature ?? '';
    })
  }

  deleteFile(file: any, i: any) {
    this.uploadedFiles.splice(i, 1);
  }

  deletedChips(chip: any): void {
    let index = this.checkedThreads.findIndex((thread: any) => {
      if (chip.sequence === thread.sequence) {
        return true;
      }
    })
    this.checkedThreads.splice(index, 1);
  }

  toggleToolbar(): void {
    this.toolbarState = !this.toolbarState;
    const display = this.toolbarState ? 'block' : 'none';
    document.documentElement.style.setProperty('--text-editor-display', display);
  }

  bodyChange(event: any) {
    this.content = event;
  }

  addPhoto() {
    let popupData = {
      title: 'File Upload',
      component: TabUploadFilesPopupComponent,
      data: {
        "entityId": null,
        "entityType": 'INBOX'
      },
    };

    this._dialogService.openModal(popupData).subscribe(result => {
      this.uploadedFiles = this.uploadedFiles.concat(result);
    });
  }

  insertSnippet() {
    let popupData = {
      title: 'Insert Snippet',
      component: InboxInsertSnippetComponent,
      containerClass: 'snippet_popup',
      data:{isFromDashboardAssociatedMailPopup: true, mailBoxId: this.checkedThreads[0].mailboxId}
    };
    this._dialogService.openModal(popupData).subscribe(result => {
      if (result.selectedSnippet) {
        if (this.content && this.content?.trim() != '') {
          let lineBreak = '<div style="margin:10px;"></div>';
          this.content = this.content + lineBreak + result.selectedSnippet.content;
        } else {
          this.content = result.selectedSnippet.content;
        }
      }
    });
  }

  sendMail(sendAndClose?: boolean) {
    const attachments = this.uploadedFiles.map((data) => data.id);
    let lineBreak = '<div style="margin:10px;"></div>';
    let payload: any = {
      mailboxId: this.checkedThreads[0].mailboxId,
      content: this.content + lineBreak + this.signatureText + lineBreak + this.messageThread,
      subject: '',
      to: [],
      cc: [],
      tempAttachmentIds: attachments,
      threadIds: this.checkedThreads.map((thread: any) => {
        return thread.id;
      })
    }
    if (sendAndClose) {
      payload['isClosed'] = true;
    }
    this.bulkMailSend(payload);
  }

  sendAndClose(): void {
    this.sendMail(true);
  }

    bulkMailSend(messageDetails: any): void {
    this.isLoaderForSend = true;
    this._inboxService.bulkReplySendMail(messageDetails).pipe(takeUntil(this.destroy$)).subscribe(
      {
        next: (response: any) => {
          this.content = '';
          this.uploadedFiles = [];
          this.isLoaderForSend = false;
          // this.toastStatus = 'Success';
          // this.isToastVisible = true;
          // this.toastMessage = 'Your message was successfully sent.';
        },
        error: (error) => {
          this._utilService.showErrorMessage(error);
          this.isLoaderForSend = false;
          // this.toastStatus = 'Error';
          // this.isToastVisible = true;
          // this.toastMessage = 'You message failed to send. Click to Retry';
        },
        complete: () => {
          this.dialogRef.close('sent successfully');
        }
      });

  }

 /** Track by Index  **/
 trackByIndex(index: number, item: any): number {
  return index;
}

constructMessageThread() {
  if(this.checkedThreads.length == 1) {
    this._inboxService.getSingleThreads(this.checkedThreads[0].id).pipe(takeUntil(this.destroy$),map((d:any)=> d.data)).subscribe((t: Thread) => {
      this.messageThread = this._inboxService.createReplyThread(t);
    })
  }
}
}
