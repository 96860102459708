import { Injectable } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { ActivatedRouteSnapshot, ResolveEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  constructor(private readonly title: Title, private readonly router: Router) { }

  setupTitleListener() {
    this.router.events.pipe(filter(e => e instanceof ResolveEnd)).subscribe((e: ResolveEnd) => {
      const { data } = getDeepestChildSnapshot(e.state.root);
      if(data?.title){
        this.title.setTitle(data.title);
      }
    });
  }
}

function getDeepestChildSnapshot(snapshot: ActivatedRouteSnapshot) {
  let deepestChild = snapshot.firstChild;
  while (deepestChild?.firstChild) {
    deepestChild = deepestChild.firstChild
  };
  return deepestChild || snapshot
}
