import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'fyxt-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => TextareaComponent)
        }
    ],
    standalone: true,
    imports: [NgClass, NgIf, MatFormFieldModule, MatInputModule, FormsModule, NgxIntlTelInputModule]
})
export class TextareaComponent implements OnInit,ControlValueAccessor {
  @Input() label:any;
  @Input() disable:boolean = false
  @Input() Error:boolean = false;
  @Input() placeHolder!:string;
  @Input() resize:boolean = true;
  @Input() readonly:boolean = false;
  @Input() rows:number=4;
  @Input() maxLength = 0;
  @Input() setHeight: boolean = false;
  @Input() offFocus: boolean = false;

  @Input() input:any;
  @Input() showRequiredStar: boolean = false;

  @Output() onValueChange = new EventEmitter<Event>();
  @Output() onFocusOut = new EventEmitter<Event>();

  constructor() { }



  ngOnInit(): void {
  }

  onChange = (data:any) => {}
  onTouch = (_:any) => {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: string) {
    this.input = value;
    this.onChange(value);
  }

    /**
   *Func triggered on input change
   */
  onchange(event:any){
    this.onChange(event);
    this.onValueChange.emit(event);
  }

  focusInput(){}

  focusOutInput(event:any){
    if (this.offFocus) return;
    this.onFocusOut.emit(event);
  }

}
