import { Component, Input, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/services/utility.service';
import { SidebarService } from 'src/app/@fyxt/_services/_layout/sidebar.service';
import { takeUntil } from 'rxjs/operators';
import { CoiService } from '../../../../Modules/_fyxt_modules/coi/_services/coi.service';
import { Subject } from 'rxjs';
import { AbstractControl, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ICoi, ICoiForm, ICreateCoi, IUpdateCoiForm } from '../../models/coi/coi.interface';
import { ActivatedRoute } from '@angular/router';
import { AddCompanyComponent } from '../add-company/add-company.component';
import { DialogService } from '../../../_services/dialog/dialog.service';
import { ConfigService } from '../../../../services/config.service';
import * as _ from 'lodash';
import { BaseService } from '../../../../services/base.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TextareaComponent } from '../../_reusable_ui/_controls/textarea/textarea.component';
import { DateViewonlyComponent } from '../../../../Modules/tenant-portal/components/date-viewonly/date-viewonly.component';
import { DropdownDateSelectComponent } from '../../_reusable_ui/_controls/dropdown-select/dropdown-date-select/dropdown-date-select.component';
import { DropdownComponent } from '../../_reusable_ui/_controls/dropdown/dropdown.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TextboxComponent } from '../../_reusable_ui/_controls/textbox/textbox.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../_reusable_ui/_components/button/button.component';
import { NgIf, NgClass, NgFor } from '@angular/common';


@Component({
    selector: 'fyxt-create-coi',
    templateUrl: './create-coi.component.html',
    styleUrls: ['./create-coi.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, ButtonComponent, MatIconModule, MatDividerModule, TextboxComponent, NgxIntlTelInputModule, DropdownComponent, DropdownDateSelectComponent, DateViewonlyComponent, TextareaComponent, NgClass, MatTooltipModule, NgFor]
})
export class CreateCoiComponent implements OnInit {
  private readonly destroy$: Subject<null> = new Subject();
  private readonly id: string = this.activatedRoute.snapshot.params['id'];

  @Input() updateCOI: boolean = false;
  @Input() isReadonly: boolean = false;
  chipList: string[] = ['High', 'Medium', 'Low'];

  // Company Dropdown
  allCompaniesList: any[] = [];
  allCompaniesListPageNumber: number = 1;
  allCompaniesListTotal: number;
  allCompaniesListSize: number;
  companiesSearchInput: any = '';
  allCompaniesListPageCount: number;
  allCompaniesLoading: boolean = false;

  // Company Insurance Dropdown
  allCompaniesInsuranceList: any[] = [];
  allCompaniesInsuranceListPageNumber: number = 1;
  allCompaniesInsuranceListTotal: number;
  allCompaniesInsuranceListSize: number;
  companiesInsuranceSearchInput: any = '';
  allCompaniesInsuranceListPageCount: number;
  allCompaniesInsuranceLoading: boolean = false;

  //Property Dropdown
  allPropertiesList: any[] = [];
  allPropertiesListPageNumber: number = 1;
  allPropertiesListTotal: number;
  allPropertiesListSize: number;
  propertiesSearchInput: any = '';
  allPropertiesListPageCount: number;
  allPropertiesLoading: boolean = false;
  COIPropertiesFullData: any[] = [];

  //Category Dropdown
  allCategoriesList: any[] = [];
  allCategoriesListPageNumber: number = 1;
  allCategoriesListTotal: number;
  allCategoriesListSize: number;
  categoriesSearchInput: any = '';
  allCategoriesListPageCount: number;
  allCategoriesLoading: boolean = false;

  createCoiForm: FormGroup<ICoiForm>;
  updateCoiForm: FormGroup<IUpdateCoiForm>;
  formSubmitted: boolean = false;

  isLoaderCreate: boolean = false;

  currentCoi!: ICoi;

  isUpdateCOI = this.baseService.isPermissionExist('change_coi')

  // Insurance

  constructor(
    public sidebarService: SidebarService,
    public utilService: UtilityService,
    private readonly coiService: CoiService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly dialogService: DialogService,
    private readonly configService: ConfigService,
    private readonly baseService: BaseService,
  ) {
  }

  ngOnInit(): void {
    this.COIPropertiesFullData = [];
    this.getAllCompanyDropdowns();
    this.getAllCompanyInsuranceDropdowns();
    this.getAllPropertyDropdowns();
    if (this.updateCOI) {
      this.getAllCategoryDropdowns();
      this.initUpdateForm();
    } else {
      this.initForm();
    }
  }

  private initForm() {
    this.createCoiForm = new FormGroup({
      name: new FormControl('', Validators.required),
      company: new FormControl(null, Validators.required),
      properties: new FormControl([]),
      effective_date: new FormControl('', [this.effectiveDateValidator]),
      expiration_date: new FormControl('', [Validators.required, this.expirationDateValidator]),
      company_insurer: new FormControl(null),
      categories: new FormControl(null),
      risk_level: new FormControl(null),
      description: new FormControl(null),
    });
  }

  public initUpdateForm() {
    this.coiService.getById(this.id)
      .subscribe({
        next: (data: ICoi) => {
          this.currentCoi = data;
          const company = {
            id: data.company_id,
            name: data.company_name,
            type: data.company_type,
          };
          this.createCoiForm = new FormGroup({
            name: new FormControl(data.title, Validators.required),
            company: new FormControl(company, Validators.required),
            properties: new FormControl(data.properties.map(item => item.id)),
            effective_date: new FormControl(this.coiService.getUTCData(data.effective_date)),
            expiration_date: new FormControl(this.coiService.getUTCData(data.expiration_date), Validators.required),
            company_insurer: new FormControl(data.insurer_company, Validators.required),
            categories: new FormControl(data.categories, Validators.required),
            risk_level: new FormControl(data.risk_level),
            description: new FormControl(data.description),
          });
          this.COIPropertiesFullData = data.properties;
          this.configService.isLoader = false;
          if (data.insurer_company_id)
            this.coiService.getCompanyById(data.insurer_company_id).subscribe({
              next: (res) => {
                this.createCoiForm.controls.company_insurer.setValue(res);
              },
              error: () => {
              },
            });
        },
        error: () => {
          this.configService.isLoader = false;
        },
      });
  }

  public companyDropdownSearch(event: any) {
    this.companiesSearchInput = event.term;
    this.allCompaniesListPageNumber = 1;
    this.getAllCompanyDropdowns();
  }

  private getAllCompanyDropdowns(pagination?: boolean) {
    this.allCompaniesLoading = true;
    this.coiService
      .getCompanies(this.allCompaniesListPageNumber, this.companiesSearchInput)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (pagination) {
            this.allCompaniesList = this.allCompaniesList.concat(
              response.items,
            );
          } else {
            this.allCompaniesList = response.items;
          }
          this.allCompaniesListTotal = response.total;
          this.allCompaniesListSize = response.size;
          this.allCompaniesListPageCount = Math.round(
            this.allCompaniesListTotal / this.allCompaniesListSize,
          );
          this.allCompaniesLoading = false;
        },
        error: (error) => {
          this.utilService.showErrorMessage(error);
          this.allCompaniesLoading = false;
        },
        complete: () => {
        },
      });
  }

  public companyDropdownsScroll() {
    if (this.allCompaniesListPageNumber < this.allCompaniesListPageCount) {
      this.allCompaniesListPageNumber++;
      this.getAllCompanyDropdowns(true);
    }
  }

  public companyInsuranceDropdownSearch(event: any) {
    this.companiesInsuranceSearchInput = event.term;
    this.allCompaniesInsuranceListPageNumber = 1;
    this.getAllCompanyInsuranceDropdowns();
  }

  private getAllCompanyInsuranceDropdowns(pagination?: boolean) {
    this.allCompaniesInsuranceLoading = true;
    this.coiService
      .getCompaniesInsurance(this.allCompaniesInsuranceListPageNumber, this.companiesInsuranceSearchInput)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (pagination) {
            this.allCompaniesInsuranceList = this.allCompaniesInsuranceList.concat(
              response.items,
            );
          } else {
            this.allCompaniesInsuranceList = response.items;
          }
          this.allCompaniesInsuranceListTotal = response.total;
          this.allCompaniesInsuranceListSize = response.size;
          this.allCompaniesInsuranceListPageCount = Math.round(
            this.allCompaniesInsuranceListTotal / this.allCompaniesInsuranceListSize,
          );
          this.allCompaniesInsuranceLoading = false;
        },
        error: (error) => {
          this.utilService.showErrorMessage(error);
          this.allCompaniesInsuranceLoading = false;
        },
        complete: () => {
        },
      });
  }

  public companyInsuranceDropdownsScroll() {
    if (this.allCompaniesInsuranceListPageNumber < this.allCompaniesInsuranceListPageCount) {
      this.allCompaniesInsuranceListPageNumber++;
      this.getAllCompanyInsuranceDropdowns(true);
    }
  }

  private getAllPropertyDropdowns(pagination?: boolean) {
    this.allPropertiesLoading = true;
    this.coiService
      .getProperties(this.allPropertiesListPageNumber, this.propertiesSearchInput)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (pagination) {
            this.allPropertiesList = this.allPropertiesList.concat(
              response.results,
            );
          } else {
            this.allPropertiesList = response.results;
          }
          this.allPropertiesListTotal = response.count;
          this.allPropertiesListSize = (response.results || []).length;
          this.allPropertiesListPageCount = Math.round(
            this.allPropertiesListTotal / this.allPropertiesListSize,
          );
          this.allPropertiesLoading = false;
        },
        error: (error) => {
          this.utilService.showError('', 'Error Server Properties');
          this.allPropertiesLoading = false;
        },
        complete: () => {
        },
      });
  }

  public propertyDropdownSearch(event: any) {
    this.propertiesSearchInput = event.term;
    this.allPropertiesListPageNumber = 1;
    this.getAllPropertyDropdowns();
  }

  public propertyDropdownsScroll() {
    if (this.allPropertiesListPageNumber < this.allPropertiesListPageCount) {
      this.allPropertiesListPageNumber++;
      this.getAllPropertyDropdowns(true);
    }
  }

  private getAllCategoryDropdowns(pagination?: boolean) {
    this.allCategoriesLoading = true;
    this.coiService
      .getCategories(this.categoriesSearchInput)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.allCategoriesList = (response || []);
          this.allCategoriesLoading = false;
        },
        error: (error) => {
          this.utilService.showError('', 'Error Server Categories');
          this.allCategoriesLoading = false;
        },
        complete: () => {
        },
      });
  }

  public categoryDropdownSearch(event: any) {
    this.categoriesSearchInput = event.term;
    this.allCategoriesListPageNumber = 1;
    this.getAllCategoryDropdowns();
  }

  public categoryDropdownsScroll() {
    if (this.allCategoriesListPageNumber < this.allCategoriesListPageCount) {
      this.allCategoriesListPageNumber++;
      this.getAllCategoryDropdowns(true);
    }
  }

  createCoi() {
    this.formSubmitted = true;
    if (this.createCoiForm.valid && !this.isLoaderCreate) {
      const properties = this.createCoiForm.controls.properties.value || [];
      const payload: ICreateCoi = {
        title: this.createCoiForm.controls.name?.value?.trim(),
        company_id: this.createCoiForm.controls.company.value?.id,
        company_type: this.createCoiForm.controls.company.value?.type,
        properties: properties,
        effective_date: this.createCoiForm.controls.effective_date.value,
        expiration_date: this.createCoiForm.controls.expiration_date.value,
      };
      if (!this.baseService.isPermissionExist('add_coi')) return this.utilService.showError('', 'Not permission')
      this.isLoaderCreate = true;
      this.coiService.createCOI(payload)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (res) => {
            this.isLoaderCreate = false;
            this.utilService.showSuccess('', 'COI Created Successfully.');
            this.sidebarService.setEvent('Created');
            this.coiService.addedCOISubject$.next(res)
            this.onCancel();
          },
          error: (res) => {
            this.isLoaderCreate = false;
            (res?.error?.errors || []).forEach(err => {
              this.utilService.showError('COI Created Error', err.message);
            });
          },
        });
    }
  }

  onCancel() {
    if (!this.isLoaderCreate) {
      this.sidebarService.resetData();
      this.sidebarService.toggleCustom();
      this.sidebarService.setFormConext('Add COI');
      this.resetForm();
    }
  }

  private resetForm() {
    setTimeout(() => {
      this.formSubmitted = false;
      this.createCoiForm.reset();
      this.sidebarService.setFormConext('');
    }, 100);
  }

  timer: NodeJS.Timer;

  updateName() {
    const newValue = this.createCoiForm.controls?.name?.value?.trim();
    if (this.createCoiForm.controls.name.valid && newValue !== this.currentCoi.title) {
      this.updateCoi({ title: newValue });
    }
  }

  updateDesc() {
    const newValue = this.createCoiForm.controls?.description?.value;
    if (this.createCoiForm.controls.description.valid && newValue !== this.currentCoi.description) {
      this.updateCoi({ description: newValue });
    }
  }

  updateCompany(event: any) {
    this.updateCoi({ company_id: event.id, company_type: event.type });
  }

  updateProperty() {
    const oldIds = (this.currentCoi.properties || []).map(v => v.id);
    const ids = this.createCoiForm.controls.properties.value;
    if (!_.isEqual(oldIds, ids))
      this.updateCoi({ properties: ids });
  }

  updateCategory() {
    const oldIds = (this.currentCoi.categories || []).map(v => v.id);
    const ids = (this.createCoiForm.controls.categories.value || []).map(v => v.id);
    if (!_.isEqual(oldIds, ids))
      this.updateCoi({ categories: ids });
  }

  updateCompanyInsurance(event: any) {
    this.updateCoi({ insurer_company_id: event.id });
  }

  public createNewCompanyPopup() {
    let popupData = {
      title: 'Create New Company',
      component: AddCompanyComponent,
      containerClass: 'modal_manage_views',
      data: {
        companyModal: true,
        defaultCompanyType: 'Insurance',
      },
    };

    this.dialogService
      .openModal(popupData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result) {
          this.allCompaniesListPageNumber = 1;
          this.getAllCompanyInsuranceDropdowns();
          if (result && result?.type === 'Insurance') {
            const company_insurer = {
              id: result.id,
              name: result.name,
              type: result.type,
            };
            this.createCoiForm.controls.company_insurer.setValue(company_insurer);
            this.updateCoi({ insurer_company_id: company_insurer.id });
          }
        }
      });
  }

  updateCoi(payload: any) {
    if (this.configService.isLoader) {
      return;
    }

    this.configService.isLoader = true;
    this.coiService.updateCOI(this.id, { ...payload })
      .subscribe({
        next: (res) => {
          this.initUpdateForm();
          this.utilService.showSuccess('', 'COI Updated Successfully.');
        },
        error: () => {
          this.configService.isLoader = false;
          this.utilService.showError('', 'COI Updated Error.');
        },
      });
  }

  onChangeDateEffectiveDate() {
    if (this.createCoiForm.controls.effective_date.value) {
      const time = new Date(this.createCoiForm.getRawValue().effective_date).getTime();
      const timeExp = new Date(this.createCoiForm.getRawValue().expiration_date).getTime();
      if (time > timeExp) {
        this.createCoiForm.controls.effective_date.setErrors({ must_be_less: true });
      } else {
        this.updateCoi({ effective_date: this.createCoiForm.controls.effective_date.value });
      }
    } else {
      this.updateCoi({ effective_date: this.createCoiForm.controls.effective_date.value });
    }
  }

  onChangeDateExpirationDate() {
    if (!this.createCoiForm.getRawValue().expiration_date) {
      this.createCoiForm.controls.expiration_date.setErrors({ required: true });
      return;
    }
    if (this.createCoiForm.controls.effective_date.value) {
      const time = new Date(this.createCoiForm.getRawValue().expiration_date).getTime();
      const timeEff = new Date(this.createCoiForm.getRawValue().effective_date).getTime();
      if (time > timeEff) {
        this.updateCoi({ expiration_date: this.createCoiForm.controls.expiration_date.value });
      } else {
        this.createCoiForm.controls.expiration_date.setErrors({ must_be_more: true });
      }
    } else {
      this.updateCoi({ expiration_date: this.createCoiForm.controls.expiration_date.value });
    }
  }

  expirationDateValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const effectiveDate = control.parent?.get('effective_date').value;
    const expirationDate = control.value;

    if (effectiveDate && expirationDate < effectiveDate) {
      return { must_be_more: true };
    }

    return null;
  }

  effectiveDateValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const expirationDate = control.parent?.get('expiration_date').value;
    const effectiveDate = control.value;

    if ((expirationDate && effectiveDate) && effectiveDate > expirationDate) {
      return { must_be_less: true };
    }

    return null;
  }

   /** Track by Index  **/
   trackByIndex(index: number, item: any): number {
    return index;
  }
}
