import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import oneschemaImporter from "@oneschema/importer";

import { BaseService } from 'src/app/services/base.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';
import { DialogService } from '../../_services/dialog/dialog.service';
import { forkJoin } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../_reusable_ui/_components/button/button.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIf } from '@angular/common';

@Component({
    selector: 'fyxt-oneschema-button',
    templateUrl: './oneschema-button.component.html',
    styleUrls: ['./oneschema-button.component.scss'],
    standalone: true,
    imports: [NgIf, MatTooltipModule, ButtonComponent, MatIconModule]
})
export class OneschemaButtonComponent implements OnInit {
  @Input() templateKey: string = '';
  @Input() btnText: string = '';
  @Input() disabled: boolean = false;
  @Output() onSuccess = new EventEmitter<Object>();
  

  templateOverrides: any = [];
  columnsToUpdate: any;

  /********** OneSchema Config ***********/
  oneSchemaImporter = oneschemaImporter({
    clientId: environment.oneschemaConfig.clientId,
    importConfig: { type: "webhook", key: environment.oneschemaConfig.webhookKey },
    devMode: false,
    className: 'oneschema-importer',
    styles: {
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100vw',
      height: '100vh',
      zIndex: '1000',
    }
  });

  /********** Allowed Domain Restrictions ***********/
  allowedDomains: any[] = [
    'localhost',
    '*.docker.devfyxt.com',
    '*.docker.qafyxt.com',
    '*.docker.demofyxt.com',
    'internaltest.fyxt.com',
    'midamericafacilities.fyxt.com',
    'livedemo.fyxt.com',
    'stagindustrial.fyxt.com'
  ];

  constructor(
    public _baseService: BaseService,
    public _utilService: UtilityService,
    public _dialogService: DialogService
  ) {

  }

  ngOnInit(): void {
    if (this.templateKey == 'update_job_details') {
      this.getDashboardColumnsToUpdate();
    }
    else if (this.templateKey == 'company_and_contacts') {
      this.getCompanyColumnsToUpdate();
    }
    else if(this.templateKey == 'service_planner') {
      this.getServicePlannerColumnsToUpdate();
    }
    else if(this.templateKey == 'target_cost_mapping') {
      this.getGlobalJobSettingsColumnsToUpdate();
    }
    else if(this.templateKey == 'coi_insert_update') {
      this.getCOIColumnsToUpdate();
    }
    else if(this.templateKey == 'properties') {
      this.getPropertiesColumnsToUpdate();
    }

    
  }

  /**
   * Check Allowed Domain Access
   * @returns
   */
  allowOneSchemaButton() {
    let hostname = window.location.hostname;
    if (this.isDomainAllowed(hostname, this.allowedDomains)) {
      return true;
    }
    return false;
  }

  /**
   * Validate Allowed Domain
   * @returns
   */
  isDomainAllowed(domain, allowedDomains) {
    const regexPatterns = allowedDomains.map((allowedDomain) =>
      new RegExp(`^${allowedDomain.replace(/\*/g, '.*')}$`, 'i')
    );
    return regexPatterns.some((regex) => regex.test(domain));
  }

  /**
   * OneSchema Button Trigger event
   */
  onClickOneSchemaButton() {
    let oneschema_token = this._baseService.currentUserInfo?.oneschema_token;
    if (oneschema_token) {

      /************** Module based Dynamic template config Start ******************/
      // pass overrides and values not specified at creation time:
      this.oneSchemaImporter.launch({
        userJwt: oneschema_token,
        templateKey: this.templateKey,
        templateOverrides: {
          columns_to_update: this.columnsToUpdate
        }
      })
      /************** Module based template config End ******************/


      /******** handle success *******/
      this.oneSchemaImporter.on("success", (data) => {
        // console.log(data);
        this.onSuccess.emit(data);
      })

      /******** handle cancel *******/
      this.oneSchemaImporter.on("cancel", () => {
        console.log('cancel');
      })

      /******** handle error *******/
      this.oneSchemaImporter.on("error", (error) => {
        console.log(error);
      })

    }
    else {
      this._utilService.showSuccess('OneSchema', 'Token not available!');
    }
  }

  getDashboardColumnsToUpdate() {
    let propertyURL = this._dialogService.doGET(environment.baseURL + 'properties/dropdown/?source=pm');
    let serviceTypeURL = this._dialogService.doGET(environment.baseURL + 'jobs/service-type/');
    let vendorsURL = this._dialogService.doGET(environment.fastAPI_Actual_URL + '/companies/vendor-companies/');

    let propertyDropdown, serviceTypeDropdown, vendorsDropdown = [];

    forkJoin([propertyURL, serviceTypeURL, vendorsURL]).subscribe(([propertyList, serviceTypeList, vendorsList]) => {
      propertyDropdown = this.getDropdownList(propertyList);
      serviceTypeDropdown = this.getDropdownList(serviceTypeList);
      vendorsDropdown = this.getDropdownList(vendorsList);

      this.columnsToUpdate = [
        {
          key: 'property_name',
          validation_options: {
            picklist_options: propertyDropdown
          }
        },
        {
          key: 'service_type',
          validation_options: {
            picklist_options: serviceTypeDropdown
          }
        },
        {
          key: 'assigned_vendors',
          validation_options: {
            picklist_options: vendorsDropdown
          }
        },
        {
          key: 'approved_vendor',
          validation_options: {
            picklist_options: vendorsDropdown
          }
        }
      ];
    });
  }

  getServicePlannerColumnsToUpdate() {
    let propertyURL = this._dialogService.doGET(environment.baseURL + 'properties/dropdown/?source=pm');
    let serviceTypeURL = this._dialogService.doGET(environment.baseURL + 'jobs/service-type/');
    let vendorsURL = this._dialogService.doGET(environment.fastAPI_Actual_URL + '/companies/vendor-companies/');
    let requestURL = environment.baseURL + 'checklist-template/all/';
    requestURL = requestURL.replace('v1', 'v2');
    let checklistURL = this._dialogService.doGET(requestURL);
    
    let propertyDropdown, serviceTypeDropdown, vendorsDropdown, checklistDropdown = [];

    forkJoin([propertyURL, serviceTypeURL, vendorsURL, checklistURL]).subscribe(([propertyList, serviceTypeList, vendorsList, checklistList]) => {
      propertyDropdown = this.getDropdownList(propertyList);
      serviceTypeDropdown = this.getDropdownList(serviceTypeList);
      vendorsDropdown = this.getDropdownList(vendorsList);
      checklistDropdown = this.getDropdownList(checklistList);

      this.columnsToUpdate = [
        {
          key: 'property_name',
          validation_options: {
            picklist_options: propertyDropdown
          }
        },
        {
          key: 'service_type',
          validation_options: {
            picklist_options: serviceTypeDropdown
          }
        },
        {
          key: 'vendor_name',
          validation_options: {
            picklist_options: vendorsDropdown
          }
        },
        {
          key: 'checklist_name',
          validation_options: {
            picklist_options: checklistDropdown
          }
        }
      ];
    });
  }

  getCompanyColumnsToUpdate() {
    let companyTypesURL = this._dialogService.doGET(environment.baseURL + 'company-types/?source=Company');
    let companyStateURL = this._dialogService.doGET(environment.baseURL + 'states/?country=a9f559a5-e33d-4b24-8b2f-633486ccff40');
    let associatedPropertyURL = this._dialogService.doGET(environment.baseURL + 'properties/dropdown/?source=pm');
    let vendorCategoryURL = this._dialogService.doGET(environment.baseURL + 'categories');

    let companyTypeDropdown, companyStateDropdown, assocPropertyDropdown, vendorCategoryDropdown = [];

    forkJoin([companyTypesURL, companyStateURL, associatedPropertyURL, vendorCategoryURL]).subscribe(([companyTypeList, companyStateList, assocPropertyList, vendorList]) => {
      companyTypeDropdown = this.getDropdownList(companyTypeList);
      companyStateDropdown = this.getStateDropdownList(companyStateList);
      assocPropertyDropdown = this.getDropdownList(assocPropertyList);
      vendorCategoryDropdown = this.getDropdownList(vendorList);

      this.columnsToUpdate = [
        {
          key: 'company_type',
          validation_options: {
            picklist_options: companyTypeDropdown
          }
        },
        {
          key: 'company_state',
          validation_options: {
            picklist_options: companyStateDropdown
          }
        },
        {
          key: 'associated_property',
          validation_options: {
            picklist_options: assocPropertyDropdown
          }
        },
        {
          key: 'vendor_category',
          validation_options: {
            picklist_options: vendorCategoryDropdown
          }
        }
      ];
    });
  }

  getGlobalJobSettingsColumnsToUpdate() {
    let propertyURL = this._dialogService.doGET(environment.baseURL + 'properties/filter-dropdown/?source=oneschema');
    let categoryURL = this._dialogService.doGET(environment.baseURL + 'categories/');
    let serviceTypeURL = this._dialogService.doGET(environment.baseURL + 'jobs/service-type/');
    
    let propertyDropdown, categoryDropdown, serviceTypeDropdown = [];

    forkJoin([propertyURL, categoryURL, serviceTypeURL]).subscribe(([propertyList, categoryList, serviceTypeList]) => {
      propertyDropdown = this.getDropdownList_address(propertyList);
      categoryDropdown  = this.getDropdownList(categoryList);
      serviceTypeDropdown = this.getDropdownList(serviceTypeList);

      this.columnsToUpdate = [
        {
          key: 'property_address',
          validation_options: {
            picklist_options: propertyDropdown
          }
        },
        {
          key: 'service_type',
          validation_options: {
            picklist_options: serviceTypeDropdown
          }
        },
        {
          key: 'category',
          validation_options: {
            picklist_options: categoryDropdown
          }
        }
      ];
    });
  }

  getCOIColumnsToUpdate() {
    let vendorType={'type':['Vendor']};
    let vendorTypeQuery=JSON.stringify(vendorType)
    let companyURL = this._dialogService.doGET(environment.fastAPI_Actual_URL + "/companies/oneschema/?columns=name&query="+vendorTypeQuery);
    let company_external_identifierURL = this._dialogService.doGET(environment.fastAPI_Actual_URL + "/companies/oneschema/?columns=external_identifier&query="+vendorTypeQuery);
    let propertyURL = this._dialogService.doGET(environment.baseURL + 'properties/oneschema/?columns=serial_number');
    let property_external_identifierURL = this._dialogService.doGET(environment.baseURL + 'properties/oneschema/?columns=external_identifier');
    

    let companyDropdown, company_external_identifierDropdown, propertyDropdown, property_external_identifierDropdown = [];


    forkJoin([companyURL,company_external_identifierURL,propertyURL,property_external_identifierURL]).subscribe(([companyList,company_external_identifierList,propertyList,property_external_identifierList]) => {
      companyDropdown = this.getDropdownList(companyList);
      company_external_identifierDropdown = this.getDropdownList(company_external_identifierList);
      propertyDropdown = this.getDropdownList(propertyList);
      property_external_identifierDropdown = this.getDropdownList(property_external_identifierList);

      this.columnsToUpdate = [
        {
          key: 'company',
          validation_options: {
            picklist_options: companyDropdown
          }
        },
        {
          key: 'company_external_identifier',
          validation_options: {
            picklist_options: company_external_identifierDropdown
          }
        },
        {
          key: 'property',
          validation_options: {
            picklist_options: propertyDropdown
          }
        },
        {
          key: 'property_external_identifier',
          validation_options: {
            picklist_options: property_external_identifierDropdown
          }
        }
      ];
    });
  }

  getPropertiesColumnsToUpdate() {
    let propertyTypeURL = this._dialogService.doGET(environment.baseURL + 'properties/type/');
    let propertyLeasesTypeURL = this._dialogService.doGET(environment.baseURL + 'properties/lease_type/');
    let stateURL = this._dialogService.doGET(environment.baseURL + 'states/');
    let cityURL = this._dialogService.doGET(environment.baseURL + 'cities/');
    
    let propertyTypeDropdown, propertyLeasesDropdown, statesDropdown, citiesDropdown = [];

    forkJoin([propertyTypeURL, propertyLeasesTypeURL, stateURL, cityURL]).subscribe(([propertyTypeList, propertyLeaseList, stateList, citiesList]) => {
      propertyTypeDropdown = this.getDropdownList(propertyTypeList);
      propertyLeasesDropdown  = this.getDropdownList(propertyLeaseList);
      statesDropdown = this.getDropdownList(stateList);
      citiesDropdown = this.getDropdownList(citiesList);

      this.columnsToUpdate = [
        {
          key: 'property_type',
          validation_options: {
            picklist_options: propertyTypeDropdown
          }
        },
        {
          key: 'general_lease_type',
          validation_options: {
            picklist_options: propertyLeasesDropdown
          }
        },
        {
          key: 'state',
          validation_options: {
            picklist_options: statesDropdown
          }
        },
        {
          key: 'city',
          validation_options: {
            picklist_options: citiesDropdown
          }
        }
      ];
    });
  }

  getDropdownList_address(list: any) {
    let picklist_options = [];
    list.forEach(res => {
      const index = picklist_options?.findIndex(obj => obj.value == res.address);
      if (index === -1) {
        picklist_options.push({ value: res.address, color: '#D63C00' });
      }
    });
    return picklist_options;
  }

  getDropdownList(list: any) {
    let picklist_options = [];
    list.forEach(res => {
      const index = picklist_options?.findIndex(obj => obj.value == res.name);
      if (index === -1) {
        picklist_options.push({ value: res.name, color: '#D63C00' });
      }
    });
    return picklist_options;
  }

  getStateDropdownList(list: any) {
    let picklist_options = [];
    list.forEach(res => {
      const index = picklist_options?.findIndex(obj => obj.value == res.abbreviation);
      if (index === -1) {
        picklist_options.push({ value: res.abbreviation, color: '#D63C00' });
      }
    });
    return picklist_options;
  }
}
