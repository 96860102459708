
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectorRef, ChangeDetectionStrategy, AfterViewInit, ElementRef,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA
} from '@angular/core';
import {
  AppTableRequest,
  AppTableRow,
  AppTableSource
} from 'src/app/@fyxt/_shared/models/app-table-model';
import { Subscription, fromEvent } from 'rxjs';
import { TableColumnTypes } from 'src/app/@fyxt/_shared/enums/table/table-column-types.enum';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { MatMenuTrigger } from '@angular/material/menu';
import { BaseService } from 'src/app/services/base.service';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, take, takeUntil, throttleTime } from 'rxjs/operators';
import moment from "moment";
import { TableService } from 'src/app/@fyxt/_services/table/table.service';
import { SafeHTMLContentPipe } from '../../../_pipes/_custom-pipes/safeContent-html.pipe';
import { PaginationComponent } from './pagination/pagination.component';
import { NoDataFountComponent } from '../no-data-fount/no-data-fount.component';
import { ButtonComponent } from '../button/button.component';
import { ThreeDotsDropdownComponent } from '../three-dots-dropdown/three-dots-dropdown.component';
import { TableListDropdownComponent } from '../../_controls/dropdown-select/table-list-dropdown/table-list-dropdown.component';
import { PriorityDropdownComponent } from '../../_controls/priority-dropdown/priority-dropdown.component';
import { ChipComponent } from '../chip/chip.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgClass, NgFor, NgIf, NgStyle, SlicePipe, CurrencyPipe, DatePipe, CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MaterialModule } from '../../../material/material.module';

@Component({
    selector: 'fyxt-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule,MatTableModule, MatSortModule, NgClass, NgFor, NgIf, MatCheckboxModule, NgStyle, MatTooltipModule, MatRadioModule, MatChipsModule, ChipComponent, PriorityDropdownComponent, TableListDropdownComponent, ThreeDotsDropdownComponent, ButtonComponent, NoDataFountComponent, PaginationComponent, SlicePipe, CurrencyPipe, DatePipe, SafeHTMLContentPipe, MaterialModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA]
})

export class TableComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @Input() isNormalTable: boolean = false;
  @Input() dataSource!: AppTableSource;
  @Input() visibleColumnKeys!: string[];
  @Input() ListedItems!: any[];
  @Input() ListedRelationship!: any[];
  @Input() PriorityTypesList!: any[];
  @Input() highlightColumn!: string;
  @Input() clearSelection: boolean;
  @Input() hiddenPagination: boolean = false;
  @Input() customFooterText: string = '';
  @Input() moduleName: string = '';
  @Input() moveInOut: boolean = false;
  @Input() showDateInUTC: boolean = false;
  @Input() showPagination: boolean = true;
  @Input() schedulePermissions: any = {};
  @Input() isMobile: boolean = false;
  @Input() isPaginationFooter: boolean = false;
  @Input() isSeenUnseenJobsTable: boolean = false;
  @Input() isStarJobsTable: boolean = false;
  @Input() isCOI: boolean = false;
  @Input() isJobUpdateAccess: boolean = false;
  @Input() isStaticRow: boolean = false;
  @Input() isSelectAllRowswithPaginatedItems: boolean = false;
  @Input() isSingleSelection: boolean = false;
  @Input() alternateRows: boolean = false;
  @Input() isPopup: boolean = false;
  @Input() filterHeight: number = 0;

  @Output() onChangeTableEvent = new EventEmitter<AppTableRequest>();
  @Output() onTableRowClick = new EventEmitter<Event>();
  @Output() onPaginationChange = new EventEmitter<Event>();
  @Output() onTableRowCheck = new EventEmitter<string>();
  @Output() isAllRowsSelected = new EventEmitter<boolean>();
  @Output() isAllEntireRowsSelected = new EventEmitter<boolean>();
  @Output() menuSelected = new EventEmitter<Event>();
  @Output() onSortChange = new EventEmitter();
  @Output() onDropdownValueChange = new EventEmitter();
  @Output() onStarValueChange = new EventEmitter();
  @Output() scrollToBottom = new EventEmitter();
  @Output() onActionButtonClick_Eng = new EventEmitter<Event>();
  @Output() isSelectAllRowsSelected = new EventEmitter<boolean>();

  /** Checkbox events  */
  @Input() selection = new SelectionModel<AppTableRow>(true, []);
  @Input() isPriorityText: boolean = false;
  @Input() selectedPropertyInfo: any;
  @Input() isForProperty: any;

  private eventSubscription!: Subscription;
  private scrollSubscription: Subscription;
  public math = Math;

  actions: string[] = [];
  TableColumnTypes = TableColumnTypes;

  @ViewChild('tableContainer') table: ElementRef;
  @ViewChild(MatSort) sort!: MatSort;
  manualSortEnabled: boolean = false;
  equipmentImage: string = '../../../../../assets/@fyxt/images/images.png';

  pageCount!: number;
  @Input() disable: boolean = false;
  infoChipList = ['Chip Name here', 'Chip Name', 'Another Chip'];
  currentUserInfo: any = localStorage.getItem('userobj') ? JSON.parse(localStorage.getItem('userobj')) : {};
  approvedToWorkList: any = [
    { id: 'Pending', name: 'Pending' },
    { id: 'Approved', name: 'Approve' },
    { id: 'Rejected', name: 'Reject' },
  ];

  constructor(
    private cd: ChangeDetectorRef,
    public _baseService: BaseService,
    private _router: Router,
    public tableService: TableService,
    private activatedRouted: ActivatedRoute
  ) { }

  ngOnInit(): void {

    if (Array.isArray(this.visibleColumnKeys)) {
      return;
    }

    /** Title */
    this.visibleColumnKeys = this.dataSource?.headers.map((value: any) => {
      return value.value;
    });

    if (this.dataSource?.headers) {
      this.dataSource.headers.filter(header => {
        if (header.type === TableColumnTypes.action && header.actions) {
          header.actions.forEach(action => this.actions.push(action));
        }
      });

      // for (let header of this.dataSource.headers) {
      //   if (header.type === TableColumnTypes.action) {
      //     if (header.actions) {
      //       for (let action of header.actions) {
      //         this.actions.push(action);
      //       }
      //     }
      //   }
      // }
    }


    /** pageCount rounded */
    if (this.dataSource?.pageSize) {
      this.pageCount = Math.round(this.dataSource?.length / this.dataSource?.pageSize)
    }

    this.selection.changed.subscribe((event) => {
      if (event.removed.length) {
        this.cd.detectChanges();
      }
    })




    this.scrollSubscription = fromEvent(this.table.nativeElement, 'scroll').pipe(
      debounceTime(100)
    ).subscribe(() => this.onScroll())


    if (this.dataSource?.rows) {
      this.selectAllCheckedItems();
    }
  }

  /**
   * Set Selected Items to Table
   */
  selectAllCheckedItems() {

    // Table Select All Feature
    if (this.tableService.isTotalTableRowsSelected.getValue()) {
      if (this.tableService.unCheckedTableRows.getValue()?.length > 0) {
        this.dataSource.rows = this.dataSource.rows.map((obj: any) => { obj.checked = !this.tableService.unCheckedTableRows.getValue().map(job => job.id).includes(obj.id); return obj; });
        let checkedJobLists = this.dataSource.rows.filter((obj: any) => obj.checked) || [];
        checkedJobLists?.forEach(item => this.selection.select(item));
      }
      else {
        this.selectAllPaginatedRows();
      }
    }
    else {
      if (this.tableService.isAllCurrentPageSelected.getValue()) {
        if (this.tableService.unCheckedTableRows.getValue()?.length > 0) {
          if (Array.isArray(this.tableService.checkedRowItems.getValue()) && this.tableService.checkedRowItems.getValue()?.length > 0) {
            this.dataSource.rows = this.dataSource.rows.map((obj: any) => { obj.checked = this.tableService.checkedRowItems.getValue()?.map((job: any) => job.id).includes(obj.id); return obj; }) || [];
            let checkedJobLists = this.dataSource.rows.filter((obj: any) => obj.checked) || [];
            checkedJobLists?.forEach(item => this.selection.select(item));
          }
        }
        else {
          // this.tableService.unCheckedTableRows.next(this.dataSource.rows);
        }
      }
      else {
        let checkedJobLists = this.dataSource.rows.filter((obj: any) => obj.checked) || [];
        checkedJobLists?.forEach(item => this.selection.select(item));
      }

    }

    /*********  Table select All Checked Items Count ***********/
    this.tableService.checkedRowItems.subscribe((response: any) => {
      if (Array.isArray(response) && response?.length > 0) {
        response?.forEach(item => this.selection.select(item));

        this.getSelectedItemsCount();
      }
    });
  }

  ngAfterViewInit(): void {
    let s: any = this.dataSource?.headers?.find((e: any) => {
      if (e.sort != 'none') return e.value;
    })
    this.manualSortEnabled = true;
    if (s) {
      this.sort?.sort({
        id: s.value,
        start: s.sort,
        disableClear: false,
      });
    }
    this.manualSortEnabled = false;
    this.cd.detectChanges();
  }

  ngOnChanges(changes): void {
    if (this.cd)
      this.cd.detectChanges();

    this.selection.clear();
    // this.clearAllSelectedRows();
  }

  trackByIndex(index: number): number {
    return index;
  }

  /** Action Menu  Value Changed */
  menuOnSelect(selectedMenu: any, event: any) {
    let eventValue: any = { label: event.value }
    const objectWithEvent = Object.assign(selectedMenu, eventValue)
    this.menuSelected.emit(objectWithEvent);
  }

  /** sorting */
  announceSortChange(sortState: Sort) {
    if (!this.manualSortEnabled) this.onSortChange.emit(sortState);
  }



  /** Dropdown value changed  */
  onStatusChange(event: any, rowValue?: any) {
    event.type = 'stage';
    event.rowValue = rowValue;
    this.onDropdownValueChange.emit(event)
  }

  /** Dropdown Relationship value changed  */
  onRelationshipChange(event: any, rowValue?: any) {
    event.type = 'relationship';
    event.rowValue = rowValue;
    this.onDropdownValueChange.emit(event)
  }

  /** Dropdown value changed  */
  onPriorityChange(event: any, rowValue?: any) {
    let eventVal = {
      id: event,
      label: event,
      name: event,
      type: 'priority'
    }
    event = eventVal;
    event.rowValue = rowValue;
    this.onDropdownValueChange.emit(event)
  }

  /** Dropdown value changed  */
  onApprovedToWorkChange(event: any, rowValue?: any) {
    let eventVal = {
      id: event,
      rowValue: rowValue,
      type: 'approveToWork'
    }
    this.onDropdownValueChange.emit(eventVal)
  }

  doCheckIndeterminate() {
    if (this.isSelectAllRowswithPaginatedItems) {
      if (this.tableService.isTotalTableRowsSelected.getValue()) {
        if (this.tableService.unCheckedTableRows.getValue()?.length > 0) {
          return true;
        }
        return false;
      }
      if (this.tableService.isAllCurrentPageSelected.getValue()) {
        if (this.tableService.unCheckedTableRows.getValue()?.length > 0) {
          return true;
        }
        else if ((!this.isAllSelected() && this.dataSource.rows.filter((obj: any) => !obj.checked)?.length > 0)) {
          return true;
        }
        return false;
      }
    }

    if ((this.selection.hasValue() && !this.isAllSelected()) || this.isInterMediateBulkAction()) {
      return true;
    }
    return false;
  }

  isAllSelected() {
    if(this.dataSource.moduleName === 'assignPropertyToPG') {
      let possibleSelectedLength = 0;
      this.dataSource.rows.forEach((row: any) => {
        if (row.property_group === null) {
          possibleSelectedLength++;
        }
      });
      const numSelected = this.selection.selected.length;
      const numRows = possibleSelectedLength;
      return numSelected === numRows;

    }
    else if (this.dataSource.moduleName == 'teams-add-property') {
      let possibleSelectedLength = 0;
      this.dataSource.rows.forEach((row: any) => {
        if (row.teams === null) {
          possibleSelectedLength++;
        }
      });
      const numSelected = this.selection.selected.length;
      const numRows = possibleSelectedLength;
      return numSelected === numRows;
    } 
    else {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.rows.length;
      return numSelected === numRows;
    }
  }

  isSelectAllBulkAction(): any {
    return this.dataSource.rows.every((row: any) => {
      if (row?.checked) {
        return row.checked == true;
      } else {
        return false;
      }
    })
  }

  isInterMediateBulkAction(): any {
    return this.dataSource.rows.some((row: any) => {
      return row.checked == true;
    });

  }

  toggleAllRows(event: any) {
    if (this.tableService.isAllCurrentPageSelected.getValue() || this.tableService.isTotalTableRowsSelected.getValue()) {
      if ((!this.isAllSelected() && this.dataSource.rows.filter((obj: any) => !obj.checked)?.length > 0) || (this.isAllSelected() && this.tableService.unCheckedTableRows.getValue().length > 0 && this.dataSource.rows.filter((obj: any) => !obj.checked)?.length > 0)) {
        this.selection.clear();
        this.dataSource.rows?.forEach(item => this.selection.select(item));
        this.dataSource.rows.filter((row: any) => row.checked = this.selection.selected.some((obj: any) => obj.id == row.id));

        this.isAllRowsSelected.emit(true)
      }
      else {
        this.selection.clear();
        this.isAllRowsSelected.emit(false);
        return;
      }
    }

    if (this.isAllSelected()) {
      this.selection.clear();
      this.isAllRowsSelected.emit(false);
      return;
    } else {
      if(this.dataSource.moduleName === 'assignPropertyToPG') {
        this.dataSource.rows.forEach((item: any) => {
          if (item && item?.property_group === null) {
            this.selection.select(item);
          }
        });
        this.isAllRowsSelected.emit(true);
        return;
      }
      if(this.dataSource.moduleName == 'teams-add-property') {
        this.dataSource.rows.forEach((item: any) => {
          if(item && item?.teams === null) {
            this.selection.select(item);
          }
        });
        this.isAllRowsSelected.emit(true);
        return;
      }
    } 

    this.dataSource.rows?.forEach(item => this.selection.select(item));
    this.isAllRowsSelected.emit(true)


    // if (!event.checked) {
    //   this.selection.clear();
    //   this.isAllRowsSelected.emit(false);
    //   return;
    // }

    // this.dataSource.rows?.forEach(item => this.selection.select(item));
    // this.isAllRowsSelected.emit(true);
  }

  checkboxLabel(row?: AppTableRow): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  /** Pagination */
  paginationChange(paginationDetails: any) {
    if (this.dataSource?.pageSize) {
      this.pageCount = Math.round(this.dataSource?.length / paginationDetails.pageSize)
    }
    this.onPaginationChange.emit(paginationDetails)
  }

  /** Row events */
  onRowClick(row: any) {
    this.onTableRowClick.emit(row);
  }

  /** Button events */
  onEngActionButtonClick(row: any) {
    this.onActionButtonClick_Eng.emit(row);
  }

  onCheckRow(event: any, row: any) {
    if (this.isSingleSelection) {
      if (this.selection.selected.some((obj: any) => obj.id == row.id)) {
        row.checked = false;
        this.selection.isEmpty();
      }
      else {
        this.dataSource.rows.map((obj: any) => { obj.checked = false; return obj })
        row.checked = true;
        this.selection.clear();
        this.selection.isSelected(row);
      }
    }

    event.stopPropagation();
    this.onTableRowCheck.emit(row);
  }

  isEllipsisActive(e: HTMLElement): boolean {
    return e ? (e.clientWidth < e.scrollWidth - 20) : false;
  }

  isEllipsCharLimit(e: HTMLElement, usersCount: any): any {

    let width = (e.scrollWidth - 5) / 8;

    if (usersCount > 1) {
      width = (e.scrollWidth - 85) / 8;
    }
    return Math.ceil(width);
  }


  getDateTextColor(date: any, columnName: string): any {
    if ((columnName !== this.highlightColumn) || !date) return;
    const today = moment();
    let dateValue = isNaN(+date) ? date : +date;
    const difference = moment(moment(dateValue).format('YYYY-MM-DD')).diff(today, 'days');

    const redCondition = this.isCOI ? difference <= 1 : difference <= 30;
    const yellowCondition = this.isCOI ? difference <= 30 : difference >= 30 && difference <= 60;

    if (redCondition) {
      return '#D13147';
    } else if (yellowCondition) {
      return '#A85D00';
    } else {
      return '#192C49';
    }
  }

  getBooleanColor(value: any): any {
    if (value === true) {
      return '#0277BD';
    }
  }

  handleCOICoveragesColor(row: any, column_name: string) {
    if (this.isCOI && column_name === 'coverages_count') {
      const { coverages_count, coverage_max_expiration_date } = row;
      if (!!coverages_count && coverage_max_expiration_date) {
        const today = moment();
        const diff = moment(moment(coverage_max_expiration_date).format('YYYY-MM-DD')).diff(today, 'days');

        if (diff <= 1) {
          return '#D13147';
        } else if (diff <= 30) {
          return '#A85D00';
        } else {
          return '#192C49';
        }
      } else {
        return '#192C49';
      }
    }

    return '#192C49';
  }

  getTextColor(value: any): any {
    if (value == 'Invited') {
      return '#0277BD';
    } else if (value == 'Active') {
      return '#138813';
    } else if (value == 'Pending') {
      return '#0277BD';
    } else if (value == 'Requested') {
      return '#D13147';
    } else if (value == 'Not Requested') {
      return '#606D81';
    } else if (value == 'Not Started') {
      return '#606D81';
    } else if (value == 'In Progress') {
      return '#D13147';
    } else if (value == 'Needs Review') {
      return '#D13147';
    } else if (value == 'Complete') {
      return '#138813';
    } else if (value == 'In-Active') {
      return '#D13147';
    } else if (value == 'Approved') {
      return '#67b267';
    } else if (value == 'Rejected') {
      return '#d74e60';
    } else if (value == 'Proposed') {
      return '#4199cd';
    } else if (value == 'Low') {
      return '#192c49';
    } else if (value == 'Medium') {
      return '#0277BD';
    } else if (value == 'High') {
      return '#814BA0';
    } else if (value == 'Emergency') {
      return '#D13147';
    } else if (value == 'Incomplete' || value == 'No') {
      return '#D13147';
    } else if (value == 'Completed') {
      return '#192C49';
    } else if ((typeof (value) !== 'object') && value?.includes('Approved on') && this.moduleName == 'Tenant-portal') {
      return '#3F852B';
    } else if ((typeof (value) !== 'object') && value?.includes('Pending on') && this.moduleName == 'Tenant-portal') {
      return '#4199cd';
    } else if ((typeof (value) !== 'object') && value?.includes('Proposed on') && this.moduleName == 'Tenant-portal') {
      return '#4199cd';
    } else if ((typeof (value) !== 'object') && value?.includes('Rejected on') && this.moduleName == 'Tenant-portal') {
      return '#d74e60';
    } else if ((typeof (value) !== 'object') && value?.includes('Cancelled on') && this.moduleName == 'Tenant-portal') {
      return '#192c49';
    } else if (value == 'Operational') {
      return '#138813';
    } else if (value == 'Requires Attention') {
      return '#A85D00';
    } else if (value == 'Success' || value == 'Yes') {
      return '#138813';
    } else if (this.moduleName == 'Job Checklist Workorder') {
      return value > 0 ? '#D13147' : '#192c49';
    }
  }

  displayNameLabel(data: any): any {
    return data.map((obj: any) => {
      if (obj.name) {
        return obj.name;
      } else if (obj.full_name) {
        return obj.full_name;
      }
    }).join(', ');
  }

  objNameLabel(data: any): any {
    if (data.name) {
      return data.name;
    } else if (data.full_name) {
      return data.full_name;
    }
  }

  getType(val) {
    return typeof val;
  }

  ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }

  /**
   * Scroll Reset
   */
  resetScroll() {
    const tableElement = this.table.nativeElement
    tableElement.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  cellClicked(row, column) {
    if (this.moduleName == 'disable_redirect' && this._baseService.currentUserInfo.current_Role === 'Engineer') {
      return null;
    }
    // && !(column == "managers" || column == "followers" || column == "engineers" || column == "vendors")
    if ((column == 'stage' || column == 'priority') && column && (this.moduleName == 'dashboardLandingPage' || (this.moduleName == 'tenant_all_jobs' && this.isJobUpdateAccess))) {
      return null;
    } else if (column == 'status' && column && this.moduleName == 'timesheetLandingPage') {
      return null;
    } else if ((column == 'stage' || column == 'relationship') && column && this.moduleName == 'linkedJob') {
      return null;
    } else if ((column == 'users_sent_to' || column == 'schedule_date') && this.moduleName == 'exportViewDeliveryHistory') {
      return null;
    } else {
      if (this.isSeenUnseenJobsTable && !row.is_seen) {
        row.is_seen = true;
      }
      this.onRowClick(row);
    }

  }

  getCategories(cellValue: any) {
    if (Array.isArray(cellValue)) {
      let newCellValue = cellValue || [];
      return (newCellValue.length > 0) ? newCellValue : [];
    } else {

      if (cellValue) {
        let makeStringArrayNames = cellValue;
        if (typeof (cellValue) == 'string') {
          cellValue = cellValue.toString();
          makeStringArrayNames = cellValue.replaceAll(", ", ',').split(',');
        }
        return (makeStringArrayNames.length > 0) ? makeStringArrayNames : [];
      }

    }

    return [];
  }

  /**
   * check value and convert to chip values
   * @param cellValue
   * @returns
   */
  getNames(cellValue: any) {
    if (Array.isArray(cellValue)) {
      let newCellValue = cellValue || [];
      return (newCellValue.length > 0) ? newCellValue : [];
    }
    else {
      if (cellValue) {
        let makeStringArrayNames = cellValue;
        if (typeof (cellValue) == 'string') {
          cellValue = cellValue.toString();
          makeStringArrayNames = cellValue.replaceAll(", ", ',').split(',');
        }
        return (makeStringArrayNames.length > 0) ? makeStringArrayNames : [];
      }

    }

    return [];
  }

  // getNames(cellValue: any) {
  //   if (Array.isArray(cellValue)) {
  //     let newCellValue = cellValue;
  //     return (newCellValue.length > 0) ? newCellValue : [];
  //   } else {
  //     if (cellValue) {
  //       let makeStringArrayNames = cellValue.replaceAll(", ", ',').split(',')
  //       return (makeStringArrayNames.length > 0) ? makeStringArrayNames : [];
  //     }

  //   }

  //   return [];
  // }

  isArrayValue(cellValue: any) {
    return Array.isArray(cellValue)
  }


  /**
   * Get More Values list
   * @param cellValue
   * @returns
   */
  getMoreNamesList(cellValue: any) {

    if (Array.isArray(cellValue)) {
      let makeStringArrayNames = cellValue || [];
      let extractFirstValue = [];
      if (makeStringArrayNames.length > 1) {
        extractFirstValue = makeStringArrayNames[0];
      }
      return makeStringArrayNames;

    }
    else {
      if (cellValue) {
        let makeStringArrayNames = cellValue;
        if (typeof (cellValue) == 'string') {
          cellValue = cellValue.toString();
          makeStringArrayNames = cellValue.replaceAll(", ", ',').split(',');
        }
        // let makeStringArrayNames = cellValue.replaceAll(", ", ',').split(',')
        let extractFirstValue = [];

        if (makeStringArrayNames.length > 1) {
          extractFirstValue = makeStringArrayNames[0];
        }

        return makeStringArrayNames;
      }
    }


    return [];
  }

  /**
   * check if unassigned or no eng required value
   * @param cellValue
   * @returns
   */
  checkUnassignedorNoEngRequired(cellValue: any) {

    if (cellValue === "Unassigned" || cellValue === "No Engineer Required") {
      return true;
    }

    return false;
  }

  openMyMenu() {
    this.trigger.toggleMenu();
  }
  closeMyMenu() {
    this.trigger.closeMenu();
  }

  setActionlists(actions: any, rows: any) {
    let actionList = actions;
    if (rows.status == 'Active') {
      actionList = actions.filter(obj => obj.value !== 'Resend Invite');
    }
    if (this.dataSource.moduleName == 'Engineers') {
      if (rows.activeTab == 'Suspended') {
        actionList = actions.filter(obj => (obj.value == 'Activate'));
        // if (rows.status == "Active") {
        //   actionList = actionList.filter(obj => !(obj.value == 'Activate'));
        // }
      }
      else {
        actionList = actions.filter(obj => !(obj.value == 'Activate' || obj.value == 'Delete'));
        if (rows.status == "Pending") {
          actionList = actions.filter(obj => (obj.value == 'Resend Invite' || obj.value == 'Edit'));
        }
        if (rows.status == "Active") {
          actionList = actionList.filter(obj => !(obj.value == 'Resend Invite'));
        }
      }
    }
    else if (this.dataSource.moduleName == 'timeSheet' && (this.currentUserInfo.current_Role === "Owner" || this.currentUserInfo.current_Role === "Manager")) {
      if (rows.status == 'Approved') {
        actionList = actions.map(obj => obj.label === 'Approve' ? { ...obj, ['disabled']: true } : obj);
      }
      if (rows.status == 'Rejected') {
        actionList = actions.map(obj => obj.label === 'Reject' ? { ...obj, ['disabled']: true } : obj);
      }
    }
    else if (this.dataSource.moduleName == "Tenant-portal") {
      let isCreatedByUser = false;
      let isRescheduleUser = false;
      if (rows.status == 'Approved') {
        actionList = actions.filter(obj => !(obj.value == 'Approve' || obj.value == 'Rejected'));
      }
      else if (rows.status == 'Cancelled' || rows.status == 'Rejected') {
        actionList = actions.filter(obj => (!(obj.value == 'Cancel' || obj.value == 'Approve' || obj.value == 'Rejected'))).map(obj => !this.schedulePermissions?.is_user_can_schedule_service_dates ? { ...obj, ['disabled']: true } : obj);
      }
      else {
        if (rows.status == 'Proposed') {
          if (rows.created_by === this.currentUserInfo.id) {
            isCreatedByUser = true;
          } else {
            isCreatedByUser = false;
          }
          if (rows.rescheduled_by === this.currentUserInfo.id) {
            isRescheduleUser = true;
          } else {
            isRescheduleUser = false;
          }
          if ((rows.rescheduled && isRescheduleUser) || (!rows.rescheduled && isCreatedByUser)) {
            actions = actions.filter(obj => (obj.value == 'Cancel'));
          } else if ((rows.rescheduled && !isRescheduleUser) || (!rows.rescheduled && !isCreatedByUser)) {
            actions = actions.filter(obj => !(obj.value == 'Cancel'));
          }
        }
        actionList = actions.map(obj => {
          if (obj.value == 'Reschedule') {
            this.schedulePermissions?.is_user_can_schedule_service_dates ? obj.disabled = false : obj.disabled = true;
          }
          else {
            this.schedulePermissions?.is_user_can_approve_schedule ? obj.disabled = false : obj.disabled = true;
          }

          return obj;
        });
      }

      actionList = actionList.filter(obj => (!obj.disabled)); //To hide disabled values

    }
    else if (this.moduleName === "Job Checklist" && this._router.url.includes('tenant')) {
      actionList = actions.filter(obj => (!(obj.value == 'Save Template' || obj.value == 'Remove')));
    } else if (this.moduleName === 'jobCost') {
      // if (rows.lock_approved_jobcost && rows.status == 'Approved') {
      //   actionList = actions.filter(obj => (!(obj.value == 'Edit')));
      // }
      // else {
      //   actionList = actions;
      // }
    }
    else if (this.dataSource.moduleName === 'payments') {
      if (rows.paypoint) {
        actionList = actions.filter(obj => obj.value !== 'CONNECT_PAY_POINT');
      }
      else {
        if (rows.propertyNames) {
          actionList = actions.filter(obj => obj.value == 'ASSIGN_PROPERTY');
        }
        else if (rows.contactList) {
          actionList = actions.filter(obj => obj.value == 'EDIT_CONTACT' || obj.value === 'PAYMENT_EMAIL' || obj.value == 'EMAIL_ACTIVITY');
        }
        else {
          actionList = actions.filter(obj => obj.value == 'CONNECT_PAY_POINT');
        }
      }
    }
    else if (this.dataSource.moduleName === 'teams') {
      if (rows.primary) {
        actionList = actions.filter(obj => obj.value !== 'set_primary');
      }
      else {
        actionList = actions.filter(obj => obj.value !== 'remove_primary');
      }
    }
    else if (this.dataSource.moduleName === 'BROWSER') {
      let isPropertyActive =  this.activatedRouted.snapshot.queryParams['isPropertyActive'] ?? true;
      isPropertyActive = isPropertyActive === "true";

      if (rows.type == 'FOLDER' && !rows.default) {
        actionList = isPropertyActive ? [{ label: 'Delete', value: 'delete' }] : [];
      }

      if (rows.type == 'FOLDER' && rows.createdByType == 'SYSTEM') {
        actionList = [];
      }

      if (rows.type == 'FOLDER' && rows.createdByType == 'USER') {
        actionList = [{ label: 'Delete', value: 'delete' }];
      }

      if (rows.type == 'FOLDER' && rows.default) {
        actionList = [];
      }

      if (rows.type == 'FILE' && !isPropertyActive) {
        actionList = []
      }

      if (rows.type == 'FILE' && rows?.isDisabled) {
        actionList = [];
      }
    } else if (this.dataSource.moduleName === 'FILES') {

      if (rows.createdByType == 'SYSTEM' && rows.mimeType == 'application/folder') {
        actionList = [];
      }

      if (rows.createdByType == 'USER' && rows.mimeType == 'application/folder') {
        actionList = [{ label: 'Delete', value: 'delete' }];
      }

      if (rows?.isDisabled && rows?.type == 'INVOICE') {
        actionList = [];
      }
      

    }
    else if(this.dataSource.moduleName === 'job_vendor'){
      if (rows?.can_notify === true) {
        actionList = actions.filter(obj => obj.label !== 'Notify Vendor');
      } else {
        actionList = actions.filter(obj => obj.label !== 'Stop Notifications' && obj.value !== 'Re-send Job');
      }

      if(!this._baseService.isPermissionExist('add_job')){
        actionList = actionList.map(obj => (obj.label === 'Add Scope' || obj.label === 'Notify Vendor' || obj.label === 'Stop Notifications' || obj.label === 'Remove Vendor') ? { ...obj, ['disabled']: true } : obj);
      }
    }
    else {
      if (rows?.can_notify === true) {
        actionList = actions.filter(obj => obj.label !== 'Notify Vendor');
      } else {
        actionList = actions.filter(obj => obj.label !== 'Stop Notifications' && obj.value !== 'Re-send Job');
      }
    }
    return actionList;
  }

  starredImageChange(row: any): void {
    row.is_starred = !row.is_starred;
    this.onStarValueChange.emit(row);
  }

  onScroll(): void {
    const element = this.table.nativeElement;
    const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
    if (atBottom) {
      this.scrollToBottom.emit(true);
    }
  }


  getPaymentColor(val) {
    switch (val) {
      case 'Live':
        return '#138813';
      case 'Activated':
        return '#138813';
      case 'Held':
        return '#A85D00';
      case 'Pending Signature':
        return '#A85D00';
      case 'Underwriting':
        return '#0277bd';
      case 'In Process':
        return '#0277BD'
      case 'Boarding':
        return '#0277bd'
      case 'Declined':
        return '#0277bd'
      case 'Rejected':
        return '#D13147'
      case 'Approved':
        return '#138813'
      case 'Submitted':
        return '#0277BD'
      default:
        return '#192c49';
    }
  }

  paymentStatusClick(row) {
    this.onTableRowClick.emit({ ...row, isPaymentStatus: true })
  }

  getNumberOfChips(e: HTMLElement, input: any) {
    if (input.length > 2) {
      let chipLength = 0;
      let chipCount = 0;
      input.forEach((item, index) => {
        item.length > 16 ? chipLength += 160 : chipLength += (item.length * 10);
        chipLength < (e.scrollWidth - 55) ? chipCount++ : '';
      });
      return chipCount;
    }
    else {
      return input.length;
    }
  }

  selectAllPaginatedRows() {
    this.selection.clear();
    this.dataSource.rows?.forEach(item => this.selection.select(item));

    this.tableService.isTotalTableRowsSelected.next(true);
    this.tableService.isAllCurrentPageSelected.next(false)
    this.tableService.unCheckedTableRows.next([]);
    this.isAllEntireRowsSelected.emit(true);
  }

  clearAllSelectedRows() {
    this.selection.clear();
    this.tableService.isTotalTableRowsSelected.next(false);
    this.tableService.isAllCurrentPageSelected.next(false);
    this.tableService.unCheckedTableRows.next([]);
    this.tableService.checkedRowItems.next([]);

    this.isAllEntireRowsSelected.emit(false);
  }

  getSelectedItemsCount() {
    let activeSelectedCount = this.tableService.checkedRowItems.getValue()?.length || 0;

    if (this.dataSource && this.dataSource?.length) {
      if (this.dataSource && this.dataSource?.rows && this.dataSource?.headers) {

        if (this.tableService.isTotalTableRowsSelected.getValue()) {
          this.dataSource.rows = this.dataSource.rows.map((obj: any) => { obj.checked = this.selection?.selected.map((job: any) => job.id).includes(obj.id); return obj; });
          let unCheckedJobs: any[] = this.dataSource.rows.filter((obj: any) => !obj.checked) || [];

          let totalPageCount: number = this.tableService.isAllCurrentPageSelected.getValue() ? this.dataSource?.pageSize : this.dataSource.length;

          activeSelectedCount = totalPageCount - unCheckedJobs?.length;
          let existingRows: any = this.tableService.unCheckedTableRows.getValue() || [];
          if (existingRows?.length > 0) {
            activeSelectedCount = totalPageCount - existingRows?.length;
          }
        }
        else if (this.tableService.isAllCurrentPageSelected.getValue()) {
          activeSelectedCount = this.tableService.checkedRowItems.getValue()?.length || 0;
        }

      }
    }

    return (Number(activeSelectedCount) > 0) ? Number(activeSelectedCount) : 0;
  }

  getUniqueListBy(arr: { map: (arg0: (item: any) => any[]) => Iterable<readonly [unknown, unknown]>; }, key: string | number) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  /******* Sticky Columns ********/
  isSticky(header: any) {
    return (header.sticky) ? true : false;
  }

  isStickyEnd(header: any) {
    return (header.stickyEnd || header.value == 'action') ? true : false;
  }

  /** Track by Column Value  **/
  trackByColumnValue(index: number, item: any): number {
    return item.value;
  }

   expandTableHeight(dataSource: AppTableSource): boolean {
    return dataSource.headers.some((header: any) => {
      // return header.type === TableColumnTypes.textWithImage;
      return header.smallTableRow === true;
    });
  }

  navigateToLink(row, value) {
    window.open(row.url, "_blank");
  }
  getCustomHeight() {
    if(this.filterHeight) {
      return 'height: calc(80vh - 250px - ' + this.filterHeight + 'px)';
    }
    else {
      return 'height: calc(80vh - 250px)';
    }
  }

}
