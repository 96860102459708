import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private sidebarState = 'close';
  public sidebarStateChanged$ = new BehaviorSubject<string>(this.sidebarState);
  public sidebarStateObservable$ = this.sidebarStateChanged$.asObservable();

  public event: string = "";
  public data: any = null;
  public toggleRightSidebar: boolean = false;
  private toggleRightSidebarChanged$ = new BehaviorSubject<boolean>(this.toggleRightSidebar)
  public toggleRightSidebarStateObservable$ = this.toggleRightSidebarChanged$.asObservable()
  formType: string = "";
  slideOutType:string = "side";


  constructor() {
    this.sidebarStateChanged$.next('close');
    this.toggleRightSidebarChanged$.next(false)
  }

  toggle() {
    this.sidebarState = this.sidebarState === 'open' ? 'close' : 'open';
    this.sidebarStateChanged$.next(this.sidebarState);
  }

  setEvent(event: string) {
    this.event = event;
  }

  setData(event: any) {
    this.data = event;
  }

  getData() {
    return this.data;
  }

  resetData() {
    this.data = null;
  }

  resetEvent() {
    this.event = '';
  }

  toggleCustom() {
    this.toggleRightSidebar = !this.toggleRightSidebar;
    this.toggleRightSidebarChanged$.next(this.toggleRightSidebar);
  }

/**
 * Set Form Context
 * @param formType
 */
  setFormConext(formType: string) {
    this.formType = formType;
  }

  setSlideOutType(slideOutType: string) {
    this.slideOutType = slideOutType;
  }
}
