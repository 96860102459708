import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { RenameTemplateComponent } from '../rename-template/rename-template.component';
import { Subject } from 'rxjs';
import { DialogService } from '../../../../_services/dialog/dialog.service';
import { takeUntil } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { RuleTemplateStoreService } from '../../../../_services/leases/rule-template/rule-template-store.service';
import { ConfigService } from '../../../../../services/config.service';
import { IRuleTemplate } from '../../../../_services/leases/rule-template/rule-template-graphql.interface';
import { ILeaseCategory, TLeaseType } from '../../../models/porperty-manager/maintenance-terms-tab';
import { ModalComponent } from '../../../_reusable_ui/_components';
import { CATEGORY_CONFIG, LEASE_TYPE_RADIO } from '../../../../../Modules/_fyxt_modules/leases/leases.constants';
import { LeasesStoreService } from '../../../../_services/leases/leases-store.service';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { TabNotesComponent } from '../../_tab_components/tab-notes/tab-notes.component';
import { TabRulesComponent } from '../../_tab_components/tab-rules/tab-rules.component';
import { TabComponent } from '../../../_reusable_ui/_components/tab/tab.component';
import { CardComponent } from '../../../_reusable_ui/_components/card/card.component';
import { CategoryListingMaintenanceTermsComponent } from '../../../_reusable_ui/_components/category-listing-maintenance-terms/category-listing-maintenance-terms.component';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { ThreeDotsDropdownComponent } from '../../../_reusable_ui/_components/three-dots-dropdown/three-dots-dropdown.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { RadioComponent } from '../../../_reusable_ui/_controls/radio/radio.component';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';

@Component({
    selector: 'fyxt-detailed-template-view',
    templateUrl: './detailed-template-view.component.html',
    styleUrls: ['./detailed-template-view.component.scss'],
    standalone: true,
    imports: [SearchInputComponent, RadioComponent, FormsModule, NgxIntlTelInputModule, ThreeDotsDropdownComponent, MatDividerModule, NgIf, CategoryListingMaintenanceTermsComponent, CardComponent, TabComponent, NgSwitch, NgSwitchCase, TabRulesComponent, TabNotesComponent, ButtonComponent, MatDialogModule]
})
export class DetailedTemplateViewComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<null> = new Subject();

  public radioItems = LEASE_TYPE_RADIO;
  public categoryModifyList = CATEGORY_CONFIG
  public tabList = [
    { name: 'Rules', value: 'Rules', count: 0, active: false, disable: false },
    { name: 'Notes', value: 'Notes', count: 0, active: false, disable: false },
  ];

  public selectedRadioItem: string;
  public selectedTab: any;
  public selectedCategory: any;

  public threeDotDropdownMenuLists: any[] = [
    { label: 'Rename', value: 'Rename' },
    { label: 'Delete', value: 'Delete' },
  ];


  public categories: ILeaseCategory[] = [];
  public categoriesForTable: ILeaseCategory[] = [];

  public currentTemplate: IRuleTemplate = {} as IRuleTemplate;
  public templateId: number;
  public leaseId: number;
  public categorySearchStr = '';


  constructor(
    public _dialogService: DialogService,
    public dialogRef: MatDialogRef<DetailedTemplateViewComponent>,
    public parentModalRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly templateService: RuleTemplateStoreService,
    private readonly leasesService: LeasesStoreService,
    private readonly _Config: ConfigService,
  ) {
  }

  ngOnInit(): void {
    this.categorySearchStr = '';

    this.currentTemplate = this.data?.data;
    this.selectedRadioItem = this.currentTemplate.lease_type;
    if (!this.templateId) {
      this.templateId = this.currentTemplate.id;
    }

    this.templateService.getTemplateCategories(this.currentTemplate.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe();

    this.leasesService.currentLease$
      .pipe(takeUntil(this.destroy$))
      .subscribe((lease) => this.leaseId = lease.id);


    this.getCategories();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
    this.templateService.cleanTemplateCategories();
  }

  private getCategories() {
    this.templateService.categories$
      .pipe(takeUntil(this.destroy$))
      .subscribe((categories) => {
        this.categories = categories;
        this.onSearch(this.categorySearchStr)
        if (!this.selectedCategory) {
          this.changeCategory(categories[0]);
        }
        this.tabList[0].count = this.selectedCategory && this.categories.find(ct => ct.id === this.selectedCategory.id)?.ruleCount;
        this.tabList[0].active = true;
      })
  }

  onSearch(e: any) {
    this.categorySearchStr = e;
    this.categoriesForTable = this.categories.filter((c) => (c.category_name).toLowerCase().includes(e.toLowerCase()));
  }

  private renameTemplate(): void {
    let popupData = {
      title: 'Rename Template',
      component: RenameTemplateComponent,
      containerClass: ['rename-template-modal'],
      template_name: this.currentTemplate.template_name,
    };

    this._dialogService.openModal(popupData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        const { new_template_name } = res;
        if (new_template_name && new_template_name !== this.currentTemplate.template_name) {
          this._Config.isLoader = true;
          this.templateService.updateRuleTemplate({ id: this.currentTemplate.id, template_name: new_template_name })
            .subscribe((template) => {
              this.currentTemplate = template;
              this.parentModalRef._containerInstance._config.data.title = template.template_name
              this._Config.isLoader = false;
            })
        }
      });

  }

  private deleteTemplate() {
    this._Config.isLoader = true;

    this.templateService.deleteRuleTemplate(this.currentTemplate.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.dialogRef.close({ delete_template: true })
      })
  }

  public menuOnSelect(event: any) {
    switch (event.value) {
      case 'Rename':
        this.renameTemplate();
        break;
      case 'Delete':
        this.deleteTemplate();
        break;
    }
  }

  public onSelectTab(selectedTabValue: any) {
    this.selectedTab = selectedTabValue;
  }

  public changeCategory(event: any) {
    this.selectedCategory = event;
    this.tabList[0].count = this.selectedCategory && this.selectedCategory.ruleCount;
  }

  public changeCategoryModify(event: any) {
    this._Config.isLoader = true;
    this.templateService.updateTemplateCategory(this.currentTemplate.id, event.category_id, event.name)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this._Config.isLoader = false;
      });
  }

  public onChangeLeaseType(type: TLeaseType) {
    this._Config.isLoader = true;
    this.templateService.updateRuleTemplate({ id: this.currentTemplate.id, lease_type: type})
      .pipe(takeUntil(this.destroy$))
      .subscribe((updatedTemplate) => {
        this.currentTemplate = updatedTemplate;
        this.templateService.updateConfigForCategories(type);
        this._Config.isLoader = false;
      })
  }

  onCancel() {
    this.dialogRef.close({ cancel: true });
  }

  onApply() {
    this._Config.isLoader = true;
    this.templateService.applyRuleTemplate(this.templateId, this.leaseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this._Config.isLoader = false;
        this.dialogRef.close({ apply_template: this.currentTemplate.lease_type });
      })
  }

}
