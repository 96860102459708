import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginator, PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { MatOptionModule } from '@angular/material/core';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'fyxt-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
    providers: [
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }
    ],
    standalone: true,
    imports: [NgIf, MatPaginatorModule, MatFormFieldModule, MatSelectModule, FormsModule, NgxIntlTelInputModule, NgFor, MatOptionModule]
})
export class PaginationComponent implements OnInit {
  pageSize!: number;
  pageIndex!: number;
  length!: number;
  currentPage!: number;
  pageNumbers!: number[];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Input() disabled = false;
  @Input() hidePageSize = false;
  @Input() pageSizeOptions!: number[];
  @Input() showFirstLastButtons = true;
  @Input() isMobile: boolean = false;
  @Output() page = new EventEmitter<PageEvent>();
  @Input('pageIndex') set pageIndexChanged(pageIndex: number) {
    this.pageIndex = pageIndex;
    this.updateCurrentPage();
  }
  @Input('length') set lengthChanged(length: number) {
    this.length = length;
    this.updateCurrentPage();
  }
  @Input('pageSize') set pageSizeChanged(pageSize: number) {
    this.pageSize = pageSize;
    this.updateCurrentPage();
  }

  constructor() {}

  ngOnInit() {
    this.updateCurrentPage();
  }

  updateCurrentPage() {
    this.currentPage = (this.pageIndex || 0) + 1;
    this.pageNumbers = [];
    for (let i = 1; i <= Math.ceil(this.length / this.pageSize); i++) {
      this.pageNumbers.push(i);
    }
  }

  paginationChange(pageEvt: PageEvent) {
    this.length = pageEvt.length;
    this.pageIndex = pageEvt.pageIndex;
    this.pageSize = pageEvt.pageSize;
    this.updateCurrentPage();
    this.emitPageEvent(pageEvt);
  }

  currentPageChange() {
    this.paginator.pageIndex = this.currentPage - 1;
    const event: PageEvent = {
      length: this.paginator.length,
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
    };
    this.paginator.page.next(event);
    this.emitPageEvent(event);
  }

  emitPageEvent(pageEvent: PageEvent) {
    this.page.next(pageEvent);
  }

}