import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'src/app/services/config.service';
import { environment } from 'src/environments/environment';
import { UtilityService } from 'src/app/services/utility.service';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ManageEngineersService {
  public allEngineerTab: any = [];
  public myEngineerList: any = [];
  public suspendedDetailTab: any = [];
  public viewEngineerDetail: any = [];
  public defaultActiveTab: any;
  public currentUserInfo: any = localStorage.getItem('userobj') ? JSON.parse(localStorage.getItem('userobj')) : {};

  editMemberEngineerObj: any = {
    isEdit: false,
    selectedItemId: {}
  };

  EngineerPagination: any = {
    myEngineer: { next: '' },
    allEngineer: { next: '' },
    suspendedEngineer: { next: '' }
  };

  isDoEngineerRefresh = new Subject();

  tabList = [
    { name: 'My Engineers', active: true, disable: false, value: 'My', count: 0 },
    { name: 'All Engineers', active: false, disable: false, value: 'All', count: 0 },
    { name: 'Suspended Engineers', active: false, disable: false, value: 'Suspended', count: 0 },
  ];

  constructor(public _http: HttpClient, private _router: Router, private activatedRoute: ActivatedRoute, public _Config: ConfigService, public _utilService: UtilityService,) {
    this.defaultActiveTab = 1;
  }

  /************** Base HTTP Request Methods *****************/

  /**
   * To Get Http Request for REST API
   * @param URL
   * @param Options
   */
  doGET(URL?: string, Options?: any): Observable<any> {
    return this._http.get(URL, Options).pipe(take(1));
  }

  /**
   * To Post Http Request for REST API
   * @param URL   // The Endpoint URL
   * @param Body    //Post Input Body
   * @param Options   //Headers or Other Optional Params
   */
  doPOST(URL?: string, Body?: any, Options?: any) {
    return this._http.post(URL, Body, Options);
  }

  /**
   * To Update Http Request for REST API
   * @param URL
   * @param Body
   * @param Options
   */
  doPUT(URL?: string, Body?: any, Options?: any) {
    return this._http.put(URL, Body, Options);
  }


  /**
   * To Delete Http Request for REST API
   * @param URL
   * @param Options
   */
  doDelete(URL?: string, Options?: any) {
    return this._http.delete(URL, Options);
  }

  /**
   * If Patch Request for Http Restfull API
   * @param URL
   * @param Body
   * @param Options
   */
  doPatch(URL?: string, Body?: any, Options?: any) {
    return this._http.patch(URL, Body, Options);
  }


  getCurrentActivePage() {
    return this.activatedRoute.snapshot['_routerState'].url;;
  }
  /************** --Base HTTP Request Methods End-- *****************/


  /** Tab Change Filter in Engineer
   * @param selectedTab
   * */

  tabChangeEvent(selectedTab: any) {
    this.defaultActiveTab = selectedTab;
    if (this.defaultActiveTab == 1) {
      // this.getMyEngineerTab(true, false);
    }
    if (this.defaultActiveTab == 2) {
      // this.getAllEngineerTab(true, false);
    }
    if (this.defaultActiveTab == 3) {
      this.getSuspendedTab(true, false);
    } else {
      // this.getMyEngineerTab(true, false);
    }
  }
  /**** all engineer *****/

  getAllEngineerTab(isLoader?: any, isPagination?: any) {
    this._Config.isLoader = isLoader;
    let requestURL = "";
    if (isPagination) {
      if (this.EngineerPagination.allEngineer.next) {
        requestURL = this.EngineerPagination.allEngineer.next;
      }
      else {
        requestURL = environment.baseURL + this._Config.getAllEngineer;
      }
    } else {
      requestURL = environment.baseURL + this._Config.getAllEngineer;
    }

    this.doGET(requestURL).subscribe((response: any) => {
      this._Config.isLoader = false;
      this.EngineerPagination.allEngineer.next = response.next ? response.next : '';
      let allEngineer = response.results || [];
      if (isPagination) {
        this.allEngineerTab.push(...allEngineer);
      }
      else {
        this.allEngineerTab = allEngineer;
      }
    }, (error) => {
      this._Config.isLoader = false;
    });
  }

  /**** my engineer *****/

  getMyEngineerTab(isLoader?: any, isPagination?: any) {
    this._Config.isLoader = isLoader;
    let requestURL = "";
    if (isPagination) {
      if (this.EngineerPagination.myEngineer.next) {
        requestURL = this.EngineerPagination.myEngineer.next;
      }
      else {
        requestURL = environment.baseURL + this._Config.getMyEngineer;
        requestURL = requestURL.replace('{enginerID}', this.currentUserInfo.id);
      }
    } else {
      requestURL = environment.baseURL + this._Config.getMyEngineer;
      requestURL = requestURL.replace('{enginerID}', this.currentUserInfo.id);
    }

    this.doGET(requestURL).subscribe((response: any) => {
      this._Config.isLoader = false;
      this.EngineerPagination.myEngineer.next = response.next ? response.next : '';
      let myEngineer = response.results || [];
      if (isPagination) {
        this.myEngineerList.push(...myEngineer);
      }
      else {
        this.myEngineerList = myEngineer;
      }
    }, (error) => {
      this._Config.isLoader = false;
    });
  }

  /**** Suspended tab *****/

  getSuspendedTab(isLoader?: any, isPagination?: any) {
    this._Config.isLoader = isLoader;
    let requestURL = "";
    if (isPagination) {
      if (this.EngineerPagination.suspendedEngineer.next) {
        requestURL = this.EngineerPagination.suspendedEngineer.next;
      }
      else {
        requestURL = environment.baseURL + this._Config.getSuspendDetail;
      }
    } else {
      requestURL = environment.baseURL + this._Config.getSuspendDetail;
    }

    this.doGET(requestURL).subscribe((response: any) => {
      this._Config.isLoader = false;
      this.EngineerPagination.suspendedEngineer.next = response.next ? response.next : '';
      let suspendedDetail = response.results || [];
      if (isPagination) {
        this.suspendedDetailTab.push(...suspendedDetail);
      }
      else {
        this.suspendedDetailTab = suspendedDetail;
      }
    }, (error) => {
      this._Config.isLoader = false;
    });
  }


  /**
   * view selected list and edit Engineer Detail
   * @param selectedDetail
   */
  editEngineer(selectedDetail) {
    this.editMemberEngineerObj.selectedItemId = selectedDetail;

    this.getViewEngineerDetail()
  }

  /**** View Engineer *****/
  getViewEngineerDetail() {
    this._Config.isLoader = true;
    let requestURL = environment.baseURL + this._Config.getViewEngineer;
    requestURL = requestURL.replace('{enginerID}', this.editMemberEngineerObj.selectedItemId);

    this.doGET(requestURL).subscribe((response: any) => {
      this._Config.isLoader = false;
      this.viewEngineerDetail = response || {};
    },
      (error) => {
        this._Config.isLoader = false;
        this.viewEngineerDetail = {};
      });
  }



  /**
 * Show Confirm Dialog box
 * @param type
 * @param index
 */
  confirmDialog(type, index) {

    // let checkedPhotoId = id
    if (type == 'invite') {
      let deleteMsg = " Are you sure that you want to send an Invite ? "
      let self = this;
      this._utilService.confirmDialog(true, deleteMsg, function () {
        self.postInvite();
      }, function () {
      });
    }
    else if (type == 'delete') {
      let deleteMsg = " Are you sure that you want to delete the Property Detail ? "
      let self = this;
      this._utilService.confirmDialog(true, deleteMsg, function () {
        self.removeProperty(index);
      }, function () {
      });
    }
  }

  /**** Invite engineer *****/
  postInvite() {
    this._Config.isLoader = true;
    let requestURL = environment.baseURL + this._Config.postInviteEngineer;
    requestURL = requestURL.replace('{enginerID}', this.editMemberEngineerObj.selectedItemId);

    this.doPOST(requestURL).subscribe((response: any) => {
      this._Config.isLoader = false;
      let inviteEngineer = response || {};
      this._utilService.showSuccess('', inviteEngineer.message);

    },
      (error) => {
        this._Config.isLoader = false;
        this._utilService.showError('', error.error.error);

      });
  }
  /**
   * remove the assigned property
   * @param index
   */
  removeProperty(index) {
    this.viewEngineerDetail?.engineer.properties.splice(index, 1);
    let selectedProperties = this.viewEngineerDetail?.engineer.properties.map(item => item.id) || [];

    this._Config.isLoader = true;

    let postBody: any = {
      properties: selectedProperties
    };

    let requestURL = environment.baseURL + this._Config.patchEditEngineer;
    requestURL = requestURL.replace('{enginerID}', this.editMemberEngineerObj.selectedItemId);

    this.doPatch(requestURL, postBody).subscribe((response: any) => {
      this._Config.isLoader = false;
      let inviteEngineer = response || {};
      this._utilService.showSuccess('', "Property Removed Successfully.");

    },
      (error) => {
        this._Config.isLoader = false;
        this._utilService.showError('', error.error.error);

      });


  }

  getTabsCount() {
    let requestURL = environment.baseURL + 'engineers/engineers-count/';;
    this.doGET(requestURL)
      .subscribe({
        next: (response: any) => {
          this.tabList.map(obj => {
            switch (obj.value) {
              case 'My':
                obj.count = response['my-engineers'];
                break;
              case 'All':
                obj.count = response['all-engineers'];
                break;
              case 'Suspended':
                obj.count = response['suspended-engineers'];
                break;
            }
            return obj;
          })
        },
        error: (error) => {
        },
        complete: () => {
        }
      });
  }

}
