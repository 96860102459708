import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { UtilityService } from 'src/app/services/utility.service';
import { ConfigService } from 'src/app/services/config.service';
import { environment } from 'src/environments/environment';

import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-forget-password',
    templateUrl: './forget-password.component.html',
    styleUrls: ['./forget-password.component.scss'],
    standalone: true,
    imports: [RouterLink, FormsModule, ReactiveFormsModule, NgxIntlTelInputModule, NgClass, NgIf]
})
export class ForgetPasswordComponent implements OnInit {
  ForgetPasswordForm: UntypedFormGroup;
  submitted = false;

  constructor(
    public _baseService: BaseService,
    public _utilService: UtilityService,
    public _Config: ConfigService,
    public formbuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {

    this.initializeForm();

  }


  initializeForm() {

    this.submitted = false;

    /**** To Set Form Fields Validations ***/
    this.ForgetPasswordForm = this.formbuilder.group({
      Email: new UntypedFormControl('', [Validators.required, Validators.email]),
    });

  }

  // convenience getter for easy access to form fields
  get f() { return this.ForgetPasswordForm.controls; }


  doFormSubmit() {

    this.submitted = true;

    if (this.ForgetPasswordForm.invalid) {
      return
    }

    let postBody = {
      "email": this.ForgetPasswordForm.value.Email,
    };

    this._Config.isLoader = true;

    let requestURL = environment.baseURL + this._Config.doForgetPassword;

    this._baseService.doPOST(requestURL, postBody).subscribe((result: any) => {

      this._Config.isLoader = false;

      this._utilService.showSuccess('', 'Reset Password Link Sent To Your Email Successfully.');

      this.initializeForm();

    },
      (error) => {
        this._Config.isLoader = false;
        this._utilService.showErrorMessage(error);
      }
    );

  }


}
