import { ChecklistService } from './../../../../../Modules/_fyxt_modules/checklist/servcie/checklist.service';
import { FormGroup, FormControl, FormArray, Validators, AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag, CdkDragPlaceholder, CdkDragHandle } from '@angular/cdk/drag-drop';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { UtilityService } from 'src/app/services/utility.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseService } from 'src/app/services/base.service';
import { ThreeDotsDropdownComponent } from '../../../_reusable_ui/_components/three-dots-dropdown/three-dots-dropdown.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { OutsideClickDirective } from '../../../_directives/_custom_directives/click-outside.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { CheckboxComponent } from '../../../_reusable_ui/_controls/checkbox/checkbox.component';
import { DropdownComponent } from '../../../_reusable_ui/_controls/dropdown/dropdown.component';
import { DropdownSelectComponent } from '../../../_reusable_ui/_controls/dropdown-select/dropdown-select/dropdown-select.component';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { TextareaComponent } from '../../../_reusable_ui/_controls/textarea/textarea.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';
import { NgClass, NgIf, NgTemplateOutlet, NgFor, NgSwitch, NgSwitchCase, CommonModule } from '@angular/common';
import { TextEditorComponent } from '../../../_reusable_ui/_controls';

@Component({
    selector: 'app-create-checklist-popup',
    templateUrl: './create-checklist-popup.component.html',
    styleUrls: ['./create-checklist-popup.component.scss'],
    standalone: true,
    imports: [CommonModule,FormsModule, ReactiveFormsModule, NgClass, TextboxComponent, NgxIntlTelInputModule, NgIf, TextareaComponent, ButtonComponent, DropdownSelectComponent, DropdownComponent, CheckboxComponent, NgTemplateOutlet, MatMenuModule, MatIconModule, NgFor, OutsideClickDirective, LoaderSmallComponent, CdkDropList, CdkDrag, CdkDragPlaceholder, CdkDragHandle, MatCheckboxModule, NgSwitch, NgSwitchCase, MatTooltipModule, ThreeDotsDropdownComponent, MatDialogModule, TextEditorComponent]
})
export class CreateChecklistPopupComponent implements OnInit {


  bulkActionList: any[] = [
    { label: 'New Group', value: 'New Group' },
    { label: 'Ungroup', value: 'Ungroup' },
    { label: 'Move To', value: 'Move To' },
    { label: 'Require Comments', value: 'Require Comments' },
    { label: 'Require Photos', value: 'Require Photos' },
    { label: 'Delete', value: 'Delete' }
  ];


  panelOpenState = false;
  checklistForm!: FormGroup;
  reminderType: String[] = ['Inspector', 'Manager', 'Client'];
  options: string[] = ['Checkbox', 'Choose One', 'Choose Many', 'Completion Status', 'Pass/Fail/Flag', 'Text Response', 'Numeric Response'];
  flags: any[] = [{ value: 'Pass', order: 1, flag: false, checked: false }, { value: 'Fail', order: 2, flag: false, checked: false }, { value: 'Flag', order: 3, flag: true, checked: false }];
  stagOptions: any[] = [{ value: 'Acceptable', order: 1, flag: false, checked: false }, { value: 'Issue Noted', order: 2, flag: false, checked: false }, { value: 'N/A', order: 3, flag: false, checked: false },];
  Status: any[] = [{ value: 'Open', order: 1, flag: false, checked: false }, { value: 'In Progress', order: 2, flag: false, checked: false }, { value: 'On Hold', order: 3, flag: false, checked: false }, { value: 'Completed', order: 4, flag: false, checked: false }, { value: 'Not Completed', order: 5, flag: false, checked: false }];
  @Output() menuLists: any[] = [{ label: 'Ungroup', value: 'Ungroup' }]

  editChecklist: boolean = false;
  duplicate: boolean = false;
  loadOption: boolean = false;
  showMoveToDropdown = false;
  appliesToDropdownValues: any;
  appliesToCategoriesValues: any;
  appliesToServiceTypesValues: any;
  appliesToPropertiesValues: any;
  showAppliesToCategories: boolean = false;
  showAppliesToServiceTypes: boolean = false;
  showAppliesToProperties: boolean = false;

  // @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef;
  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  @ViewChild('fileInput') fileInputRef: ElementRef;
  selectedItemValue: any = {};
  isFileUploadLoader: boolean = false;
  selectedItemUploadProgress: any = {};

  get checkListCount(): number {
    let itemLength: number = 0;
    let groupNumber: number = 0;
    let items = [];
    this.itemFormat().controls.forEach((item: any) => {
      if (item.value?.group_name !== null) {
        groupNumber += 1;
        const groupOrder = groupNumber;
        item.value.responses.forEach((response: any) => {
          itemLength += 1;
          const newItem = { ...response, group_name: item.value.group_name, group_order: groupOrder, order: itemLength };
          items.push(newItem);
        });
      } else {
        itemLength += 1;
        const newItem = { ...item.value, order: itemLength };
        items.push(newItem);
      }
    });
    return items.length;
  }

  get checkListSelectedCount(): number {
    let itemLength: number = 0;
    let groupNumber: number = 0;
    let items = [];
    this.itemFormat().controls.forEach((item: any) => {
      if (item.value?.group_name !== null) {
        groupNumber += 1;
        const groupOrder = groupNumber;
        if (item.value.responses.length > 0) {
          item.value.responses.forEach((response: any) => {
            itemLength += 1;
            const newItem = { ...response, group_name: item.value.group_name, group_order: groupOrder, order: itemLength };
            if (newItem.itemCheckbox) {
              items.push(newItem);
            }
          });
        } else {
          if (item.value.groupCheckbox) {
            items.push(item);
          }
        }
      } else {
        itemLength += 1;
        const newItem = { ...item.value, order: itemLength };
        if (newItem.itemCheckbox) {
          items.push(newItem);
        }
      }
    });
    return items.length;
  }

  AssignChecklist: boolean = false;
  calledFrom: string = '';

  signatureData: any = {
    "engineer": false,
    "manager": false,
    "tenant": false,
    "vendor": false,
  }
  disableEditChecklist: boolean = false;
  outsideclickCount = 0;
  isLoader = false;

  filterPropertiesListPageNumber: number = 1;
  filterPropertiesListPageCount: number;
  filterPropertiesSize: number;
  filterPropertiesListTotal: number;
  filterPropertiesSearchInput: string = '';
  isNextPaginationAvailable = false;

  constructor(
    public _checklist: ChecklistService,
    public _utilService: UtilityService,
    private cd: ChangeDetectorRef,
    public dialogRef: MatDialogRef<CreateChecklistPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _httpClient: HttpClient,
    public _baseService: BaseService,
  ) {
    if (this.data?.data?.AssignChecklist) {
      this.AssignChecklist = this.data?.data?.AssignChecklist
    }
    if (this.data?.data?.duplicate) {
      this.duplicate = this.data?.data?.duplicate
    }

    if (this.data?.calledFrom) {
      this.calledFrom = this.data?.calledFrom;
    }
    if (this.data?.data?.readOnly) {
      this.disableEditChecklist = this.data?.data?.readOnly;
    }
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  ngAfterViewInit(): void {
    if (environment.stagChecklistItem) {
      this.options = ['Checkbox', 'Choose One', 'Choose Many', 'Completion Status', 'Pass/Fail/Flag', 'Acceptable/NA/IssueNoted', 'Text Response', 'Numeric Response'];
    }

    this.cd.detectChanges();
  }

  initializeForm() {
    this.getAppliesToDropdownValues();
    this.checklistForm = new FormGroup({
      name: new FormControl<string>('', [Validators.required, Validators.maxLength(250)]),
      description: new FormControl(''),
      checklist_type: new FormControl('Active'),
      signature: new FormControl(null),
      items: new FormArray([]),
      applies_to: new FormControl(null),
      assigned_category: new FormControl(null),
      assigned_service_type: new FormControl(null),
      require_photos_on_flagged_items: new FormControl(false),
      require_comments_on_flagged_items: new FormControl(false),
      assigned_property: new FormControl(null),
    })
    if (this.data?.data?.id) {
      if (!this.data?.data?.duplicate) this.editChecklist = true;
      this.patchValues();
    }
  }

  getAppliesToDropdownValues(dropdownValue: string = '') {
    let requestURL = `${environment.LocalDev_URL_V2}` + `checklist-template/checklist-applies-to-dropdown/`;
    this._checklist.doGET(requestURL).subscribe((response: any) => {
      let result = [];
      response.map(element => {
        if (element.name === 'Categories')
          result.push({ id: element.name, name: 'All Jobs of select Categories' });
        if (element.name === 'Every Job')
          result.push({ id: element.name, name: 'Every Job' });
        if (element.name === 'Service Types')
          result.push({ id: element.name, name: 'All Jobs of select Service Types' });
        if (element.name === 'Properties')
          result.push({ id: element.name, name: 'All Jobs of select Properties' });
      });
      this.appliesToDropdownValues = result;
      if (dropdownValue !== '') {
        this.checklistForm.patchValue({
          applies_to: dropdownValue,
        });
      }
    }, (error) => { });
  }

  getAppliesToCategoriesValues(categories: any = []) {
    let requestURL = `${environment.baseURL}` + `categories/`;
    this._checklist.doGET(requestURL).subscribe((response: any) => {
      this.appliesToCategoriesValues = response;
      if (categories.length > 0) {
        this.checklistForm.patchValue({
          assigned_category: categories.map((obj: any) => obj.id),
        });
      }
    }, (error) => { });
  }

  getAppliesToServiceTypesValues(serviceTypes: any = []) {
    let requestURL = `${environment.baseURL}` + `jobs/service-type/`;
    this._checklist.doGET(requestURL).subscribe((response: any) => {
      this.appliesToServiceTypesValues = response;
      this.checklistForm.patchValue({
        assigned_service_type: serviceTypes.map((obj: any) => obj.id),
      });
    }, (error) => { });
  }

  getAppliesToPropertiesValues(properites: any = []) {
    let requestURL = `${environment.LocalDev_URL_V2}` + `properties/dropdown/?source=pm/&page=` + this.filterPropertiesListPageNumber;
    if (this.filterPropertiesSearchInput !== '') {
      requestURL += `&search=${this.filterPropertiesSearchInput}`;
    }
    this._checklist.doGET(requestURL).subscribe((response: any) => {
      if (this.filterPropertiesListPageNumber > 1) {
        this.appliesToPropertiesValues = this.appliesToPropertiesValues.concat(response.results);
      } else {
        this.appliesToPropertiesValues = response.results || [];
      }
      this.filterPropertiesSize = response.results.length;
      this.filterPropertiesListTotal = response.count;
      this.filterPropertiesListPageCount = Math.round(
        this.filterPropertiesListTotal / this.filterPropertiesSize,
      );
      if (properites.length > 0) {
        this.appliesToPropertiesValues = this.appliesToPropertiesValues.concat(properites);
        this.checklistForm.patchValue({
          assigned_property: properites.map((obj: any) => obj.id),
        });
      }
      this.appliesToPropertiesValues = this.removeDuplicates(this.appliesToPropertiesValues);
      this.isNextPaginationAvailable = response.next;
    }, (error) => { });
  }

  appliesToChange(event: any) {
    this.checklistForm.get('assigned_category').clearValidators();
    this.checklistForm.get('assigned_service_type').clearValidators();
    this.checklistForm.get('assigned_property').clearValidators();
    if (event.id === 'Categories') {
      this.showAppliesToCategories = true;
      this.showAppliesToServiceTypes = false;
      this.showAppliesToProperties = false;

      this.checklistForm.get('assigned_service_type').setValue(null);
      this.checklistForm.get('assigned_property').setValue(null);
      this.checklistForm.get('assigned_category').addValidators(Validators.required);
      this.checklistForm.get('assigned_category').updateValueAndValidity();

      this.getAppliesToCategoriesValues();
    } else if (event.id === 'Service Types') {
      this.showAppliesToServiceTypes = true;
      this.showAppliesToCategories = false;
      this.showAppliesToProperties = false;

      this.checklistForm.get('assigned_category').setValue(null);
      this.checklistForm.get('assigned_property').setValue(null);
      this.checklistForm.get('assigned_service_type').addValidators(Validators.required);
      this.checklistForm.get('assigned_service_type').updateValueAndValidity();

      this.getAppliesToServiceTypesValues();
    } else if (event.id === "Properties") {
      this.showAppliesToProperties = true;
      this.showAppliesToCategories = false;
      this.showAppliesToServiceTypes = false;

      this.checklistForm.get('assigned_property').addValidators(Validators.required);
      this.checklistForm.get('assigned_property').updateValueAndValidity();

      this.checklistForm.get('assigned_category').setValue(null);
      this.checklistForm.get('assigned_service_type').setValue(null);
      this.getAppliesToPropertiesValues();
    } else {
      this.showAppliesToCategories = false;
      this.showAppliesToServiceTypes = false;
      this.showAppliesToProperties = false;

      this.checklistForm.get('assigned_category').setValue(null);
      this.checklistForm.get('assigned_service_type').setValue(null);
      this.checklistForm.get('assigned_property').setValue(null);

      this.checklistForm.get('assigned_category').clearValidators();
      this.checklistForm.get('assigned_category').updateValueAndValidity();
      this.checklistForm.get('assigned_service_type').clearValidators();
      this.checklistForm.get('assigned_service_type').updateValueAndValidity();
      this.checklistForm.get('assigned_property').clearValidators();
      this.checklistForm.get('assigned_property').updateValueAndValidity();
    }
  }

  appliesToClear() {

    this.checklistForm.get('assigned_category').clearValidators();
    this.checklistForm.get('assigned_category').updateValueAndValidity();
    this.checklistForm.get('assigned_service_type').clearValidators();
    this.checklistForm.get('assigned_service_type').updateValueAndValidity();
    this.checklistForm.get('assigned_property').clearValidators();
    this.checklistForm.get('assigned_property').updateValueAndValidity();

    this.checklistForm.get('applies_to').setValue(null);
    this.checklistForm.get('assigned_category').setValue(null);
    this.checklistForm.get('assigned_property').setValue(null);
    this.checklistForm.get('assigned_service_type').setValue(null);

    this.showAppliesToCategories = false;
    this.showAppliesToServiceTypes = false;
    this.showAppliesToProperties = false;
  }


  incrementDuplicateString(str) {
    if (str.includes("Duplicate")) {
      const lastDuplicateIndex = str.lastIndexOf("Duplicate");
      const prefix = str.slice(0, lastDuplicateIndex);
      const suffix = str.slice(lastDuplicateIndex);
      if (!isNaN(suffix.slice(10))) {
        const num = parseInt(suffix.slice(10));
        return prefix + "Duplicate " + (!Number.isNaN(num) ? num + 1 : 1);
      } else {
        return prefix + "Duplicate 1";
      }
    } else {
      return str + " Duplicate";
    }
  }




  patchValues() {
    this.isLoader = true;
    this._checklist.getCheckList(this.data?.data?.id).subscribe({
      next: (res: any) => {
        res[0].items?.map(e => {
          delete e.group_name
          let order = e.group_order
          delete e.group_order
          e.item.map(s => {
            return { ...s, group_order: order};
          })
        })

        res[0].items = res[0].items.map(e => e.item).flat();

        let appliesTo = null;
        if (res[0].applies_to) {
          if (res[0].applies_to === 'All Jobs of select Categories') {
            appliesTo = 'Categories';
          } else if (res[0].applies_to === 'All Jobs of select Service Types') {
            appliesTo = 'Service Types';
          } else if (res[0].applies_to === 'All Jobs of select Properties') {
            appliesTo = 'Properties';
          } else {
            appliesTo = 'Every Job';
          }
        }
        if (appliesTo && appliesTo !== '') {
          this.getAppliesToDropdownValues(appliesTo);
          this.showAppliesToCategories = false;
          this.showAppliesToServiceTypes = false;
          this.showAppliesToProperties = false;
        }
        if (res[0].assigned_category && res[0].assigned_category !== null) {
          this.getAppliesToCategoriesValues(res[0].assigned_category);
          this.showAppliesToCategories = true;
          this.showAppliesToServiceTypes = false;
          this.showAppliesToProperties = false;
        }
        if (res[0].assigned_service_type && res[0].assigned_service_type !== null) {
          this.getAppliesToServiceTypesValues(res[0].assigned_service_type);
          this.showAppliesToCategories = false;
          this.showAppliesToProperties = false;
          this.showAppliesToServiceTypes = true;
        }
        if (res[0].assigned_property && res[0].assigned_property !== null) {
          this.getAppliesToPropertiesValues(res[0].assigned_property);
          this.showAppliesToProperties = true;
          this.showAppliesToCategories = false;
          this.showAppliesToServiceTypes = false;
        }

        this.checklistForm.patchValue({
          name: this.duplicate ? ` ${this.incrementDuplicateString(res[0].name)}` : res[0].name,
          description: res[0].description,
          checklist_type: res[0].checklist_type,
          require_photos_on_flagged_items: res[0]?.preference?.photos ? true : false,
          require_comments_on_flagged_items: res[0]?.preference?.comments ? true : false,
        });

        res[0].items.map((e: any) => {
          this.addPatchItem(e)
        })

        this.signatureData = res[0].signature;
        this.isLoader = false;
      }, error: (error) => {
        this.isLoader = false;
      },
    })


  }

  addPatchItem(item: any) {
    if (item.group_name === null) {
      (this.checklistForm.get('items') as FormArray).push(this.addItemformats());
      const newItemIndex = this.itemFormat().length - 1;
      this.itemFormat().controls[newItemIndex].patchValue(item);
      const responses = item.responses || [];
      responses.forEach((response: any) => {
        let newFormItem: any;
        if (item.response_type == 'Text Response' || item.response_type == 'Numeric Response') {
          newFormItem = this.addOptionformats('removeValidator');
        } else {
          newFormItem = this.addOptionformats();
        }
        // this.Status = response;
        newFormItem.patchValue(response);
        const responsesArray = this.itemFormat().controls[newItemIndex].get('responses') as FormArray;
        responsesArray.push(newFormItem);
      });
    } else {
      let found = false;
      for (let i = 0; i < (this.checklistForm.get('items') as FormArray).length; i++) {
        const currentControl = (this.checklistForm.get('items') as FormArray).controls[i];
        if (currentControl.get('group_name')?.value === item.group_name) {
          found = true;
          const responsesArray = currentControl.get('responses') as FormArray;
          responsesArray.push(this.addItemformats());
          const newItemIndex = responsesArray.length - 1;
          responsesArray.controls[newItemIndex].patchValue(item);
          const responses = item.responses || [];

          responses.forEach((response: any) => {
            let newFormItem: any;
            if (item.response_type == 'Text Response' || item.response_type == 'Numeric Response') {
              newFormItem = this.addOptionformats('removeValidator');
            } else {
              newFormItem = this.addOptionformats();
            }
            newFormItem.patchValue(response);
            (responsesArray.controls[newItemIndex].get('responses') as FormArray).push(newFormItem);
          });
          break;
        }
      }
      if (!found) {
        this.createGroup();
        const lastControlIndex = (this.checklistForm.get('items') as FormArray).length - 1;
        const lastControl = (this.checklistForm.get('items') as FormArray).controls[lastControlIndex];
        lastControl.get('group_name')?.setValue(item.group_name);
        lastControl.get('group_order')?.setValue(item.order);
        const responsesArray = lastControl.get('responses') as FormArray;
        responsesArray.push(this.addItemformats());
        const newItemIndex = responsesArray.length - 1;
        responsesArray.controls[newItemIndex].patchValue(item);
        const responses = item.responses || [];

        responses.forEach((response: any) => {
          let newFormItem: any;
          if (item.response_type == 'Text Response' || item.response_type == 'Numeric Response') {
            newFormItem = this.addOptionformats('removeValidator');
          } else {
            newFormItem = this.addOptionformats();
          }
          newFormItem.patchValue(response);
          (responsesArray.controls[newItemIndex].get('responses') as FormArray).push(newFormItem);
        });
      }

    }
  }


  addItem(i: number) {
    const newFormItem = this.addItemformats(i);
    (<FormArray>this.checklistForm.get('items'))?.push(newFormItem);
    if (this.options[i] === 'Completion Status') {
      this.Status.forEach((response: any) => {
        const newFormItem = this.addOptionformats();
        newFormItem.setValue(response);
        (<FormArray>this.itemFormat().controls[this.itemFormat().controls.length - 1].get('responses')).push(newFormItem)
      });
    } else if (this.options[i] === 'Pass/Fail/Flag') {
      this.flags.forEach((response: any) => {
        const newFormItem = this.addOptionformats();
        newFormItem.setValue(response);
        (<FormArray>this.itemFormat().controls[this.itemFormat().controls.length - 1].get('responses')).push(newFormItem)
      });

    }
    else if (this.options[i] === 'Acceptable/NA/IssueNoted') {
      this.stagOptions.forEach((response: any) => {
        response.flag = false;
        response.checked = false;
        const newFormItem = this.addOptionformats();
        newFormItem.setValue(response);
        (<FormArray>this.itemFormat().controls[this.itemFormat().controls.length - 1].get('responses')).push(newFormItem)
      });

    }
    else if (this.options[i] === 'Numeric Response' || this.options[i] === 'Text Response') {
      (<FormArray>this.itemFormat().controls[this.itemFormat().controls.length - 1].get('responses')).push(this.addOptionformats('removeValidator'))
    } else if (this.options[i] === 'Choose One' || this.options[i] === 'Choose Many') {
      newFormItem.controls['responses'].push(this.addOptionformats());
      newFormItem.controls['responses'].push(this.addOptionformats());
    }
    this.scrollToElement(this.scrollContainer);


  }

  addItemformats(i?: number) {
    return new FormGroup({
      group_name: new FormControl(null),
      group_order: new FormControl(null),
      order: new FormControl(null),
      groupCheckbox: new FormControl(false),
      responses: new FormArray([]),
      title: new FormControl('', [Validators.required]),
      itemCheckbox: new FormControl(false),
      response_type: new FormControl(this.options[i]),
      photos: new FormControl(false),
      comments: new FormControl(false),
      files: new FormControl(null),
      is_FileUpoading: new FormControl(false),
    })
  }


  itemFormat() {
    return this.checklistForm.get('items') as FormArray;
  }


  createGroup() {
    (<FormArray>this.checklistForm.get('items'))?.push(this.addGroup())
    this.scrollToElement(this.scrollContainer);
  }

  addGroup() {
    return new FormGroup({
      group_name: new FormControl('', [Validators.required]),
      group_order: new FormControl(),
      groupCheckbox: new FormControl(false),
      responses: new FormArray([]),
      itemCheckbox: new FormControl(false),
      response_type: new FormControl(this.options[0]),
      photos: new FormControl(false),
      comments: new FormControl(false),
      toggle: new FormControl(false),
    })
  }


  groupedFormat(i: number) {
    return this.itemFormat()?.controls[i].get('responses') as FormArray
  }

  get isEmptyGroupElement(): any {
    const getGroupedItem = this.itemFormat().controls.filter((item: any) => {
      return item.value.group_name != null;
    })
    return getGroupedItem.some((item: any) => {
      return item.value.responses.length == 0;
    });
  }


  addItemsToGroup(item: any, i: number, j: number) {
    if (item.get('group_name')?.value !== null && item.get('group_name')?.value !== '') {
      const newFormItem = this.addItemformats(j);
      (<FormArray>this.itemFormat()?.at(i).get('responses'))?.push(newFormItem);
      if (this.options[j] === 'Text Response' || this.options[j] === 'Numeric Response') {
        // i refers to group index, j refers to group item index
        this.groupOptionFormat(i, this.itemFormat()?.at(i).get('responses')['controls'].length - 1).push(this.addOptionformats('removeValidator'));
      } else if (this.options[j] === 'Completion Status') {
        this.Status.forEach((response: any) => {
          const newFormItem = this.addOptionformats();
          newFormItem.patchValue(response);
          this.groupOptionFormat(i, this.itemFormat()?.at(i).get('responses')['controls'].length - 1).push(newFormItem)
        });
      } else if (this.options[j] === 'Pass/Fail/Flag') {
        this.flags.forEach((response: any) => {
          const newFormItem = this.addOptionformats();
          newFormItem.patchValue(response);
          this.groupOptionFormat(i, this.itemFormat()?.at(i).get('responses')['controls'].length - 1).push(newFormItem)
        });
      }
      else if (this.options[j] === 'Acceptable/NA/IssueNoted') {
        this.stagOptions.forEach((response: any) => {
          response.flag = false;
          const newFormItem = this.addOptionformats();
          newFormItem.patchValue(response);
          this.groupOptionFormat(i, this.itemFormat()?.at(i).get('responses')['controls'].length - 1).push(newFormItem)
        });
      }
      else if (this.options[j] === 'Choose One' || this.options[j] === 'Choose Many') {
        newFormItem.controls['responses'].push(this.addOptionformats());
        newFormItem.controls['responses'].push(this.addOptionformats());
      }
      item.get('groupCheckbox').setValue(false);
    }
  }

  addItemOption(i: number) {
    this.groupedFormat(i)?.push(this.addOptionformats())
  }

  addGroupOption(i: number, j: number) {
    this.groupOptionFormat(i, j)?.push(this.addOptionformats());
  }

  addOptionformats(controlName?: string) {
    const validators = controlName ? [] : [Validators.required];
    return new FormGroup({
      value: new FormControl('', validators),
      flag: new FormControl(false),
      order: new FormControl(''),
      checked: new FormControl(false),
    });
  }



  optionFormat(i: number) {
    return this.itemFormat()?.controls[i].get('responses') as FormArray;
  }

  groupOptionFormat(i: number, j: number) {
    return this.groupedFormat(i).controls[j].get('responses') as FormArray;
  }

  createCheckList(job?: string) {
    if (this.checkListCount > 0) {
      let itemLength: number = 0;
      let groupNumber: number = 0;
      let items = [];

      /**Must part To construct PayLoad */

      this.itemFormat().controls?.forEach((item: any) => {
        if (item.value?.group_name !== null) {
          groupNumber += 1;
          const groupOrder = groupNumber;
          item.value.responses.forEach((response: any) => {
            itemLength += 1;
            delete response.groupCheckbox;
            delete response.itemCheckbox;
            const newItem = { ...response, group_name: item.value.group_name, group_order: groupOrder, order: itemLength };
            items.push(newItem);
          });
        } else {
          itemLength += 1;
          delete item.value.itemCheckbox;
          delete item.value.groupCheckbox;
          delete  item.value.toggle;
          const newItem = { ...item.value, order: itemLength };
          items.push(newItem);
        }
      });
      items.map((checklistItem: any) => {

        checklistItem.files = Array.isArray(checklistItem.files) ? checklistItem.files?.map((obj: any) => obj.id) : [];

        if (checklistItem.hasOwnProperty('is_FileUpoading')) {
          delete checklistItem["is_FileUpoading"];
        }

        return checklistItem.responses.map((value: any, index: number) => {
          return value.order = index + 1;
        });
      });

      let appliesTo = null;
      if (this.duplicate) {
        if (this.checklistForm.value.applies_to === 'Categories')
          appliesTo = 'All Jobs of select Categories';
        if (this.checklistForm.value.applies_to === 'Every Job')
          appliesTo = 'Every Job';
        if (this.checklistForm.value.applies_to === 'Service Types')
          appliesTo = 'All Jobs of select Service Types';
        if (this.checklistForm.value.applies_to === 'Properties')
          appliesTo = 'All Jobs of select Properties';
      } else {
        appliesTo = this.checklistForm.value.applies_to ? this.checklistForm.value.applies_to?.name : null;
      }

      let payLoad: any = {
        id: this.data?.data?.id,
        name: this.checklistForm.value.name,
        description: this.checklistForm.value.description,
        checklist_type: job ? 'Job' : this.checklistForm.value.checklist_type,
        signature: this.signatureData,
        items: items,
        applies_to: appliesTo,
        assigned_category: this.checklistForm.value.assigned_category ? this.checklistForm.value.assigned_category : null,
        assigned_service_type: this.checklistForm.value.assigned_service_type ? this.checklistForm.value.assigned_service_type : null,
        preference: {
          photos: this.checklistForm.value.require_photos_on_flagged_items,
          comments: this.checklistForm.value.require_comments_on_flagged_items
        },
        assigned_property: this.checklistForm.value.assigned_property ? this.checklistForm.value.assigned_property : null,
      }
      if (this.checklistForm.valid) {
        if (this.editChecklist) {
          if (job && job === 'job') {
            this._checklist.createChecklist(payLoad).subscribe({
              next: (res: any) => {
                this._utilService.showSuccess('', 'Checklist Edited Successfully');
                this.dialogRef.close(res)
              },
              error: (res: HttpErrorResponse) => {
                this._utilService.showError('', res.error.name);
              }
            });
            return true;
          }
          this._checklist.updateChecklist(this.data?.data?.id, payLoad).subscribe({
            next: (res: any) => {
              this._utilService.showSuccess('', 'Checklist Updated Successfully');
              this.dialogRef.close(res)
            },
            error: (res: HttpErrorResponse) => {
              this._utilService.showError('', res.error.name);
            }
          })

        } else {
          if (this.duplicate) delete payLoad.id
          this._checklist.createChecklist(payLoad).subscribe({
            next: (res: any) => {
              this._utilService.showSuccess('', 'Checklist Created Successfully');
              this.dialogRef.close(res)
            },
            error: (res: HttpErrorResponse) => {
              this._utilService.showError('', res.error.name);
            }
          })
        }

      }

    }
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.itemFormat().controls, event.previousIndex, event.currentIndex);
    moveItemInArray(this.checklistForm.get("items").value, event.previousIndex, event.currentIndex);
  }

  dropInsideGroupItem(event: CdkDragDrop<any[]>, index: any) {
    moveItemInArray(this.groupedFormat(index).controls, event.previousIndex, event.currentIndex);
    moveItemInArray(this.checklistForm.get("items").value[index].responses, event.previousIndex, event.currentIndex);
  }

  onValueChange(type: string, i: number, item: any) {
    if (item.controls.group_name.value === null) {
      if (type === 'Numeric Response' || type === 'Text Response') {
        this.optionFormat(i).clear();
        if (this.optionFormat(i).controls.length === 0) (<FormArray>this.itemFormat().at(i)?.get('responses')).push(this.addOptionformats('removeValidator'))
      } else if (type === 'Completion Status') {
        this.optionFormat(i).clear();
        if (this.optionFormat(i).controls.length === 0) {
          this.Status.forEach((response: any) => {
            const newFormItem = this.addOptionformats();
            newFormItem.patchValue(response);
            (<FormArray>this.itemFormat().at(i).get('responses')).push(newFormItem)
          });
        }
      }
      else if (type === 'Pass/Fail/Flag') {
        this.optionFormat(i).clear();
        if (this.optionFormat(i).controls.length === 0) {
          this.flags.forEach((response: any) => {
            const newFormItem = this.addOptionformats();
            newFormItem.patchValue(response);
            (<FormArray>this.itemFormat().at(i).get('responses')).push(newFormItem)
          });
        }
      }
      else if (type === 'Acceptable/NA/IssueNoted') {
        this.optionFormat(i).clear();
        if (this.optionFormat(i).controls.length === 0) {
          this.stagOptions.forEach((response: any) => {
            const newFormItem = this.addOptionformats();
            newFormItem.patchValue(response);
            (<FormArray>this.itemFormat().at(i).get('responses')).push(newFormItem)
          });
        }
      }
      else {
        this.optionFormat(i).clear();
      }
    }

  }

  onGroupValueChange(type: string, i: number, j: number) {
    if (type === 'Text Response' || type === 'Numeric Response') {
      this.groupOptionFormat(i, j).clear();
      if (this.groupOptionFormat(i, j).controls.length === 0) {
        this.groupOptionFormat(i, j).controls.push(this.addOptionformats('removeValidator'));
      }

    } else if (type === 'Completion Status') {
      this.groupOptionFormat(i, j).clear();
      if (this.groupOptionFormat(i, j).controls.length === 0) {
        this.Status.forEach((response: any) => {
          const newFormItem = this.addOptionformats();
          newFormItem.patchValue(response);
          this.groupOptionFormat(i, j).push(newFormItem)
        });
      }

    }
    else if (type === 'Pass/Fail/Flag') {
      this.groupOptionFormat(i, j).clear();
      if (this.groupOptionFormat(i, j).controls.length === 0) {
        this.flags.forEach((response: any) => {
          const newFormItem = this.addOptionformats();
          newFormItem.patchValue(response);
          this.groupOptionFormat(i, j).push(newFormItem)
        });
      }

    }
    else if (type === 'Acceptable/NA/IssueNoted') {
      this.groupOptionFormat(i, j).clear();
      if (this.groupOptionFormat(i, j).controls.length === 0) {
        this.stagOptions.forEach((response: any) => {
          const newFormItem = this.addOptionformats();
          newFormItem.patchValue(response);
          this.groupOptionFormat(i, j).push(newFormItem)
        });
      }

    }
    else {
      this.groupOptionFormat(i, j).clear();
    }

  }

  removeUngroupedOption(i: number, j: number) {
    (<FormArray>this.itemFormat().at(i).get('responses')).removeAt(j);
  }

  removeItemsGroup(item: any, i: number, j: number, k: number) {
    this.itemFormat().at(i).get('responses')['controls'][j].get('responses').removeAt(k)
  }

  unGroup(e: any, item: any, i: number) {
    // const sk = this.itemFormat().at(i).get('responses') as FormArray;
    // sk.controls.forEach((element:any) => {
    //   element.get('group_name').setValue(null);
    //   element.get('group_order').setValue(null);
    //   element.get('itemCheckbox').setValue(false);
    // });
    // this.itemFormat().removeAt(i);
    // sk.controls.forEach(control => this.itemFormat().insert(i, control));

    const sk = this.itemFormat().at(i).get('responses') as FormArray;
    sk.controls.forEach((element: any) => {
      element.get('group_name').setValue(null);
      element.get('group_order').setValue(null);
      element.get('itemCheckbox').setValue(false);
    });
    // this.itemFormat().removeAt(i);
    sk.controls.forEach(control => this.itemFormat().insert(i + 1, control));
    this.itemFormat().controls[i]['controls'].responses.value = [];
    this.itemFormat().controls[i]['controls'].responses.controls = [];
    this.itemFormat().controls[i].value.responses = [];
    this.itemFormat().value[i].responses = [];
  }

  selectDeselectCheckbox(item: any, i: any, selectInsideGrpItems?: true) {
    setTimeout(() => {
      if (item.get('group_name').value !== null) {
        const responsesArray = item.get('responses') as FormArray;
        // If we select/deselect the group checkbox based on the condition inside items also select/deselect
        if (selectInsideGrpItems) {
          item.get('groupCheckbox').setValue(false);
        } else {
          responsesArray.controls.map((element: any) => {
            element.get('itemCheckbox').setValue(item.get('groupCheckbox').value);
          });
        }
        // If we deselect any one the item in this group then group checkbox should be deselect
        const isAllGroupItemsSelected = responsesArray.controls.every(element => {
          return (element.get('itemCheckbox').value == true);
        });
        if (isAllGroupItemsSelected) {
          item.get('groupCheckbox').setValue(true);
        } else {
          item.get('groupCheckbox').setValue(false);
        }
      }
    }, 500)
  }

  selectionChanges(e: any, i: number, j: number) {
    //bulk actions need to be done
    if (e.get('group_name').value !== null) {
      const responsesArray = e.get('responses') as FormArray;
      responsesArray.controls.forEach(element => {
        if (element.get('itemCheckbox')) element.get('itemCheckbox')?.setValue(e.get('groupCheckbox').value)
      });
    }
  }

  bulkActionPopup(item: any) {
    if (item.value === 'Delete') {
      this.bulkActionDelete(true);
    } else if (item.value === 'Ungroup') {
      this.bulkActionUngroup();
      // for (let i = this.itemFormat().length - 1; i >= 0; i--) {
      //   const item = this.itemFormat().at(i) as FormGroup;
      //   if (item.get('groupCheckbox').value) {
      //     this.unGroup('','',i)
      //   }
      //   else {
      //     const checklist = this.itemFormat().at(i).get('responses') as FormArray;
      //     checklist.controls.forEach((element:any,j:number) => {
      //      if(element.get('itemCheckbox')?.value){
      //       element.get('group_name').setValue(null);
      //       element.get('group_order').setValue(null);
      //       element.get('itemCheckbox').setValue(false)
      //       this.itemFormat().push(element);
      //       checklist.removeAt(j);
      //       if(checklist.length === 0)this.itemFormat().removeAt(i)
      //     }
      //     });
      //   }
      // }
    } else if (item.value === 'New Group') {
      this.bulkActionNewGroup();
      //   const group = this.addGroup();
      //   for (let i = this.itemFormat().length - 1; i >= 0; i--) {
      //     const item = this.itemFormat().at(i) as FormGroup;
      //     if (item.get('groupCheckbox')?.value) {
      //       item.controls.responses['controls'].forEach(element => {
      //         element.get('group_name').setValue(null);
      //         element.get('group_order').setValue(null);
      //         element.get('itemCheckbox').setValue(false);
      //         element.get('group_name').setValue('New Group');
      //         (<FormArray>group.get('responses')).push(element);
      //       });
      //       this.itemFormat().removeAt(i);
      //     } else if(item.get('responses')?.value.length > 0){
      //       item.controls.responses['controls'].forEach((element:any,j:number) => {
      //        if(element.get('itemCheckbox')?.value){
      //         element.get('group_name').setValue(null);
      //         element.get('group_order').setValue(null);
      //         element.get('itemCheckbox').setValue(false);
      //         element.get('group_name').setValue('New Group');
      //         (<FormArray>item.get('responses')).removeAt(j);
      //         if(item.controls.responses['controls'].length === 0) this.itemFormat().removeAt(i);
      //         (<FormArray>group.get('responses')).push(element);
      //        }
      //       });
      //     } else if(item.get('itemCheckbox').value){
      //       item.get('itemCheckbox')?.setValue(false);
      //       item.get('group_name').setValue('New Group');
      //       (<FormArray>group.get('responses')).push(item);
      //       this.itemFormat().removeAt(i);
      //     }
      //   }
      //   group.get('group_name').setValue('New Group')
      //   this.itemFormat().push(group);
    } else if (item.value === 'Move To') {
      // this.showMoveToDropdown = true;
    } else if (item.value === 'Require Comments') {
      this.selectAllRequireComments();
    } else if (item.value === 'Require Photos') {
      this.selectAllRequirePhotos();
    }
  }

  bulkActionUngroup(): void {
    this.itemFormat().controls.map((checklist: any, i: number) => {
      const item = this.itemFormat().at(i) as FormGroup;
      if (item.get('groupCheckbox').value) {
        this.unGroup('', '', i);
        item.get('groupCheckbox').setValue(false);
        item.get('itemCheckbox').setValue(false);
      } else {
        item.get('itemCheckbox').setValue(false);
        item.controls.responses['controls'].forEach((element: any, j: number) => {
          if (element.get('itemCheckbox')?.value) {
            element.get('group_name').setValue(null);
            element.get('group_order').setValue(null);
            element.get('itemCheckbox').setValue(false);
            this.itemFormat().push(element);
            (<FormArray>item.get('responses')).removeAt(j);
          }
        });
      }
    });
    if (this.checkListSelectedCount >= 1) {
      this.bulkActionUngroup();
    }
  }

  bulkActionDelete(isDeleteChecklist?: boolean): void {
    for (let i = this.itemFormat().length - 1; i >= 0; i--) {
      const item = this.itemFormat().at(i) as FormGroup;
      if (item.get('group_name').value == null) {
        if (item.get('itemCheckbox').value) {
          item.get('itemCheckbox').setValue(false);
          this.itemFormat().removeAt(i);
        }
      } else if (item.get('group_name').value !== null) {
        if (item.get('groupCheckbox').value) {
          item.get('groupCheckbox').setValue(false);
          if (isDeleteChecklist) {
            // If we delete the checklist from bulk action we will delete the whole checklist
            this.itemFormat().removeAt(i);
          } else {
            // If we create a new group or move-to the checklist means we will delete only the response not the whole checklist
            (<FormArray>item.get('responses')).clear();
          }
        } else {
          item.get('itemCheckbox').setValue(false);
          item.controls.responses['controls'].forEach((e: any, j: number) => {
            if (e.get('itemCheckbox')?.value) {
              (<FormArray>item.get('responses')).removeAt(j);
            }
            // if(item.controls.responses['controls'].length === 0) this.itemFormat().removeAt(i)
          });
        }
      }
    }
    if (this.checkListSelectedCount >= 1) {
      this.bulkActionDelete();
    }
  }

  bulkActionNewGroup() {
    // Creating a new group and named as New Group
    const group = this.addGroup();
    group.get('group_name').setValue('New Group');

    // Non-Grouped selected checklist values
    let getNonGroupedSelectedChecklist = this.itemFormat().controls.filter((checklist: any) => {
      return checklist.value.itemCheckbox == true;
    });

    // Getting group checklist
    let getGroupedSelectedChecklist = this.itemFormat().controls.filter((checklist: any) => {
      return checklist.controls.group_name.value;
    });

    // Grouped selected checklist controls
    let getGroupedSelectedChecklistControls = getGroupedSelectedChecklist.map((checklist: any) => {
      return checklist.controls.responses.controls.filter((response: any) => {
        return response.controls.itemCheckbox.value == true;
      });
    });

    // Grouped selected checklist values
    let getGroupedSelectedChecklistValues = getGroupedSelectedChecklist.map((checklist: any) => {
      return checklist.value.responses.filter((response: any) => {
        return response.itemCheckbox == true;
      });
    });

    getGroupedSelectedChecklistControls = Array.prototype.concat.apply([], getGroupedSelectedChecklistControls);
    getGroupedSelectedChecklistValues = Array.prototype.concat.apply([], getGroupedSelectedChecklistValues);

    // Delete the selected checklist
    this.bulkActionDelete(false);

    // Move the selected non-grouped checklist to particular group
    if (getNonGroupedSelectedChecklist.length > 0) {
      // 1. Controls
      getNonGroupedSelectedChecklist.map((selectedItem: any) => {
        selectedItem.value.itemCheckbox = false;
        selectedItem.controls.itemCheckbox.value = false;
        selectedItem.get('itemCheckbox').setValue(false);
        return (<FormArray>group.get('responses')).push(selectedItem);
        // return group.controls?.responses?.controls.push(selectedItem);
      });
      // 2. Values
      getNonGroupedSelectedChecklist.map((selectedItem: any) => {
        return group.value?.responses.push(selectedItem.value);
      });
    }

    // Move the selected grouped checklist to particular group
    // 1. Controls
    if (getGroupedSelectedChecklistControls.length > 0) {
      getGroupedSelectedChecklist.map((selectedItem: any) => {
        selectedItem.value.groupCheckbox = false;
        selectedItem.controls.groupCheckbox.value = false;
        selectedItem.get('groupCheckbox').setValue(false);
      });

      getGroupedSelectedChecklistControls.map((selectedItem: any) => {
        selectedItem.value.itemCheckbox = false;
        selectedItem.controls.itemCheckbox.value = false;
        selectedItem.get('itemCheckbox').setValue(false);
        return (<FormArray>group.get('responses')).push(selectedItem);
        // return group.controls?.responses?.controls.push(selectedItem);
      });
    }
    // 2. Values
    if (getGroupedSelectedChecklistValues.length > 0) {
      getGroupedSelectedChecklistValues.map((selectedItem: any) => {
        selectedItem.itemCheckbox = false;
        return group.value?.responses.push(selectedItem);
      });
    }
    this.itemFormat().push(group);
    this.showMoveToDropdown = false;
  }

  moveToAnotherGroup(SelectedGroupItem: any): void {
    // Non-Grouped selected checklist
    let getNonGroupedSelectedChecklist = this.itemFormat().controls.filter((checklist: any) => {
      return checklist.value.itemCheckbox == true;
    });

    // Grouped selected checklist
    let getGroupedSelectedChecklist = this.itemFormat().controls.filter((checklist: any) => {
      return checklist.controls.group_name.value;
    });

    //1. Grouped selected checklist Values
    let getGroupedSelectedChecklistControls = getGroupedSelectedChecklist.map((checklist: any) => {
      return checklist.controls.responses.controls.filter((response: any) => {
        return response.controls.itemCheckbox.value == true;
      });
    });

    //2. Grouped selected checklist Controls
    let getGroupedSelectedChecklistValues = getGroupedSelectedChecklist.map((checklist: any) => {
      return checklist.value.responses.filter((response: any) => {
        return response.itemCheckbox == true;
      });
    });

    getGroupedSelectedChecklistControls = Array.prototype.concat.apply([], getGroupedSelectedChecklistControls);
    getGroupedSelectedChecklistValues = Array.prototype.concat.apply([], getGroupedSelectedChecklistValues);

    // Delete the selected checklist
    this.bulkActionDelete(false);

    // Move the selected non-grouped checklist to particular group
    if (getNonGroupedSelectedChecklist.length > 0) {
      // 1. Controls
      getNonGroupedSelectedChecklist.map((selectedItem: any) => {
        selectedItem.value.itemCheckbox = false;
        selectedItem.controls.itemCheckbox.value = false;
        selectedItem.get('itemCheckbox').setValue(false);
        return (<FormArray>SelectedGroupItem.get('responses')).push(selectedItem);
      });

      //2. Values
      getNonGroupedSelectedChecklist.map((selectedItem: any) => {
        return SelectedGroupItem.value?.responses.push(selectedItem.value);
      });
    }

    // Move the selected grouped checklist to particular group
    // 1. Controls
    if (getGroupedSelectedChecklistControls.length > 0) {
      getGroupedSelectedChecklist.map((selectedItem: any) => {
        selectedItem.value.groupCheckbox = false;
        selectedItem.controls.groupCheckbox.value = false;
        selectedItem.get('groupCheckbox').setValue(false);
      });

      getGroupedSelectedChecklistControls.map((selectedItem: any) => {
        selectedItem.value.itemCheckbox = false;
        selectedItem.controls.itemCheckbox.value = false;
        selectedItem.get('itemCheckbox').setValue(false);
        return (<FormArray>SelectedGroupItem.get('responses')).push(selectedItem);
        // return group.controls?.responses?.controls.push(selectedItem);
      });
    }

    // 2. Values
    if (getGroupedSelectedChecklistValues.length > 0) {
      getGroupedSelectedChecklistValues.map((selectedItem: any) => {
        selectedItem.itemCheckbox = false;
        return SelectedGroupItem.value?.responses.push(selectedItem);
      });
    }
    this.showMoveToDropdown = false;
  }

  scrollToElement(el): void {
    if (this.scrollContainer?.nativeElement && this.scrollContainer?.nativeElement !== undefined) {
      // setTimeout(() => {
      //   this.scrollContainer.nativeElement.scrollTo(0, this.scrollContainer.nativeElement.scrollHeight);
      // }, 0);
      setTimeout(() => {
        this.scrollContainer.nativeElement.scroll({
          top: Math.max(0, this.scrollContainer.nativeElement.scrollHeight - this.scrollContainer.nativeElement.offsetHeight),
          left: 0,
          behavior: 'smooth'
        });
      }, 0);
    }
  }


  /**
   * Option Value Change event for Stag
   * @param event
   * @param arrayList
   * @param indexVal
   */
  doCheckStagFlag(event: any, arrayList: any[], indexVal: number) {
    this.stagOptions.map((obj: any) => {
      if (obj.value == event.value.value) {
        obj.flag = event.value.flag;
        obj.checked = event.value.flag;
      }
      return obj;
    });

    arrayList.map((obj: any) => {
      if (obj.value.value == event.value.value) {
        obj.value.flag = event.value.flag;
        obj.value.checked = event.value.flag;
      }
      return obj;
    });

    /***** Set Disable / Enable if Issue noted Selected *****/
    if (event.value.value == 'Issue Noted') {
      const item = this.itemFormat().at(indexVal) as FormGroup;
      if (item.get('responses')) {
        let indexSelected = item.value.responses.findIndex(obj => obj.value == 'Issue Noted' && obj.flag == true);

        // if (indexSelected != -1) {
        //   item.get('photos').setValue(true);
        //   item.get('comments').setValue(true);
        // }
        // else {
        //   item.get('photos').setValue(false);
        //   item.get('comments').setValue(false);
        // }

      }
    }


  }

  /**
   * Stag Feature Disable Photo/Comments
   * @param indexVal
   * @returns
   */
  disableCheckbox(indexVal: number) {
    return false;

    // const item = this.itemFormat().at(indexVal) as FormGroup;
    // if (item.get('responses')) {
    //   let indexSelected = item.value.responses.findIndex(obj => obj.value == 'Issue Noted' && obj.flag == true);

    //   if (indexSelected != -1) {
    //     return true;
    //   }
    //   else {
    //     return false;
    //   }

    // }
  }

  selectAllRequireComments(): void {
    this.itemFormat().controls.map((item: any) => {
      if (item.value?.group_name !== null) {
        const responsesArray = item.get('responses') as FormArray;
        responsesArray.value.map((grpItem: any, index: any) => {
          if (grpItem.itemCheckbox == true) {
            grpItem.comments = true;
            responsesArray.controls.map((element: any) => {
              if (grpItem.itemCheckbox == true && (grpItem.order == element.value.order && grpItem.group_name == element.value.group_name && grpItem.title == element.value.title)) {
                element.get('comments').setValue(true);
                element.get('itemCheckbox').setValue(false);
              }
            });
          }
        });
        item.get('groupCheckbox').setValue(false);
      } else {
        this.itemFormat().controls.map((item: any) => {
          if (item.value.itemCheckbox == true) {
            item.value.comments = true;
            item.controls.comments.value = true;
            item.get('comments').setValue(true);
            item.get('itemCheckbox').setValue(false);
          }
        });
      }
    });
  }

  selectAllRequirePhotos(): void {
    this.itemFormat().controls.map((item: any) => {
      if (item.value?.group_name !== null) {
        const responsesArray = item.get('responses') as FormArray;
        responsesArray.value.map((grpItem: any, index: any) => {
          if (grpItem.itemCheckbox == true) {
            grpItem.photos = true;
            responsesArray.controls.map((element: any) => {
              if (grpItem.itemCheckbox == true && (grpItem.order == element.value.order && grpItem.group_name == element.value.group_name && grpItem.title == element.value.title)) {
                element.get('photos').setValue(true);
                element.get('itemCheckbox').setValue(false);
              }
              return null;
            });
          }
        });
        item.get('groupCheckbox').setValue(false);
      } else {
        this.itemFormat().controls.map((item: any) => {
          if (item.value.itemCheckbox == true) {
            item.value.photos = true;
            item.controls.photos.value = true;
            item.get('photos').setValue(true);
            item.get('itemCheckbox').setValue(false);
          }
        });
      }
    });
  }


  /** Track by Index  **/
  trackByIndex(index: number, item: any): number {
    return index;
  }


  uploadchecklistItemFiles(indexVal: number, selectedItem: any, groupedItemIndex?: number) {
    selectedItem.indexVal = indexVal;
    selectedItem.groupedItemIndex = (groupedItemIndex >= 0) ? groupedItemIndex : -1;
    this.selectedItemValue = selectedItem;

    this.fileInputRef.nativeElement.click();
  }

  onFileInputChange(event: any) {
    // this.selectedItemValue.isFileUploadLoader = true;
    if (event.target.files?.length == 0 || this.selectedItemValue.indexVal < 0) {
      return;
    }

    this.selectedItemUploadProgress = this.selectedItemValue;

    if (this.selectedItemUploadProgress.indexVal >= 0 && this.selectedItemUploadProgress.groupedItemIndex >= 0) {
      let selectedItemsControl = this.groupedFormat(this.selectedItemUploadProgress.indexVal).controls[this.selectedItemUploadProgress.groupedItemIndex];
      selectedItemsControl.patchValue({ 'is_FileUpoading': true });
    }
    else if (this.selectedItemUploadProgress.indexVal >= 0) {
      let listItemsControl = this.checklistForm.get('items') as FormArray;
      let selectedItems = listItemsControl.at(this.selectedItemUploadProgress.indexVal);
      selectedItems.patchValue({ 'is_FileUpoading': true });
    }

    this.selectedItemValue = {};

    const formData: FormData = new FormData();
    formData.append('file', event.target.files[0]);
    formData.append('mime_type', event.target.files[0].type);
    formData.append('source', "Checklist");

    let requestURL = environment.baseURL + 'photos/';



    this._checklist.doPOST(requestURL, formData).subscribe({
      next: (res: any) => {
        if (this.selectedItemUploadProgress.indexVal >= 0 && this.selectedItemUploadProgress.groupedItemIndex >= 0) {
          let selectedItemsControl = this.groupedFormat(this.selectedItemUploadProgress.indexVal).controls[this.selectedItemUploadProgress.groupedItemIndex];
          selectedItemsControl.patchValue({ 'files': [res], 'is_FileUpoading': false });
        }
        else if (this.selectedItemUploadProgress.indexVal >= 0) {
          let listItemsControl = this.checklistForm.get('items') as FormArray;
          let selectedItems = listItemsControl.at(this.selectedItemUploadProgress.indexVal);
          selectedItems.patchValue({ 'files': [res], 'is_FileUpoading': false })
        }

        event.target.value = '';
        // this.selectedItemUploadProgress.isFileUploadLoader = false;
      },
      error: (error: HttpErrorResponse) => {
        // this.selectedItemUploadProgress.isFileUploadLoader = false;

        if (this.selectedItemUploadProgress.indexVal >= 0 && this.selectedItemUploadProgress.groupedItemIndex >= 0) {
          let selectedItemsControl = this.groupedFormat(this.selectedItemUploadProgress.indexVal).controls[this.selectedItemUploadProgress.groupedItemIndex];
          selectedItemsControl.patchValue({ 'is_FileUpoading': false });
        }
        else if (this.selectedItemUploadProgress.indexVal >= 0) {
          let listItemsControl = this.checklistForm.get('items') as FormArray;
          let selectedItems = listItemsControl.at(this.selectedItemUploadProgress.indexVal);
          selectedItems.patchValue({ 'is_FileUpoading': false })
        }

        event.target.value = '';
        this._utilService.showErrorMessage(error);
      },
      complete: () => {
        this.selectedItemValue = {};
      }

    });
  }


  getFilesByType(allFiles: any[], type: any) {
    let files = [];
    if (type == 'image') {
      files = allFiles.filter(obj => obj.mime_type.includes('image/'));
    } else if (type == 'documents') {
      files = allFiles.filter(obj => (obj.mime_type.includes('application/') || obj.mime_type.includes('pdf')));
    } else if (type == 'video') {
      files = allFiles.filter(obj => obj.mime_type.includes('video/'));
    }
    return files;
  }

  getFileType(fileObj: any, type: any) {
    if (type == 'image') {
      return fileObj.mime_type.includes('image/');
    } else if (type == 'documents') {
      return (fileObj.mime_type.includes('application/') || fileObj.mime_type.includes('pdf/'));
    } else if (type == 'video') {
      return fileObj.mime_type.includes('video/');
    }
    return false;
  }

  getFileName(file: any) {
    const url = file.file;
    let filename = '';
    try {
      filename = new URL(url).pathname.split('/').pop();
    } catch (e) {
      console.error(e);
    }
    return filename;
  }



  deleteFile(selectedFile: any, checklistItem: any): void {
    checklistItem.patchValue({ 'files': null });

    if (!this.editChecklist) {
      if (selectedFile?.id) {
        let requestURL = environment.baseURL + `photos/${selectedFile.id}/?type=all`;
        this._baseService.doDelete(requestURL)
          .subscribe({
            next: (res: any) => {
            },
            error: (err: HttpErrorResponse) => {
            },
          })
      }
    }

  }




  downloadPhoto(event: any) {
    window.open(event.file, '_blank');

    // const extension = event.mime_type.split('/')[1];
    // const fileNameWithoutExtension = event.file.substring(event.file.lastIndexOf('/') + 1, event.file.lastIndexOf('_'));
    // const fileName = `${fileNameWithoutExtension}.${extension}`;
    // this._httpClient.get(event.file, { responseType: 'blob' }).subscribe(response => {
    //   const blob = new Blob([response], { type: response.type });
    //   const downloadUrl = URL.createObjectURL(blob);
    //   const link = document.createElement('a');
    //   link.href = downloadUrl;
    //   link.download = fileName;
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    //   URL.revokeObjectURL(downloadUrl);
    // });
  }

  /** Hide the Move to option menu when clicking outside of the move to option menu **/
  outsideClickMenuClose(clickedOutside: boolean) {
    this.outsideclickCount++;
    if (clickedOutside && this.outsideclickCount > 1) {
      this.showMoveToDropdown = false;
      this.outsideclickCount = 0;
    }
  }

  isToggle(checklistItem: any) {
    checklistItem.value.toggle = !checklistItem.value.toggle;
  }

  fetchPropertyNextPage() {
    if ((this.filterPropertiesListPageNumber <= this.filterPropertiesListPageCount) && this.isNextPaginationAvailable) {
      this.filterPropertiesListPageNumber++;
      this.getAppliesToPropertiesValues();
    }
  }

  propertyDropdownSearch(event: any) {
    this.filterPropertiesSearchInput = event.term;
    this.filterPropertiesListPageNumber = 1;
    this.getAppliesToPropertiesValues();
  }

  onChangeProperties(event: any): void {
    if (Array.isArray(event)) {
      if (event.length != undefined) {
        this.checklistForm.get('assigned_property').setValue(event);
      }
    }
  }

  private removeDuplicates(list: any): any {
    return list.reduce((unique, entry) => {
      if (!unique.some((obj) => obj.id == entry.id)) {
        unique.push(entry);
      }
      return unique;
    }, []);
  }

  checkListAppliesToChange(event: any,key: string) {    
    const checkListAppliesToControl = this.checklistForm.get(key);
    if (event.length === 0) {
      checkListAppliesToControl.addValidators(Validators.required);
    }  
    // Update the control's validity state
    checkListAppliesToControl.updateValueAndValidity();
  } 

}

