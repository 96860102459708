import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TableService } from 'src/app/@fyxt/_services/table/table.service';
import { BaseService } from 'src/app/services/base.service';
import { environment } from 'src/environments/environment';
import { ColumnSortType, TableColumnTypes } from '../../../enums/table/table-column-types.enum';
import { UtilityService } from 'src/app/services/utility.service';
import { plannerService } from "../../../../../Modules/_fyxt_modules/service-planner/services/planner-service";
import { TableComponent } from '../../../_reusable_ui/_components/table/table.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { NgIf } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';

@Component({
    selector: 'app-tab-planner-upcoming-jobs',
    templateUrl: './tab-planner-upcoming-jobs.component.html',
    styleUrls: ['./tab-planner-upcoming-jobs.component.scss'],
    standalone: true,
    imports: [MatDividerModule, NgIf, LoaderSmallComponent, TableComponent]
})
export class TabPlannerUpcomingJobsComponent implements OnInit {


  dataSource: any;
  moduleName = "service-planner";

  activeColumn: string;
  activeDirection: string;

  selecteViewTableHeaders: any[] = [
    {
      value: 'job_id',
      sort: ColumnSortType.none,
      name: 'Job ID    ',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'checklist',
      sort: ColumnSortType.none,
      name: ' Checklist Name',
      type: TableColumnTypes.arrayText,
      className: 'table-header'
    },
    {
      value: 'start_date',
      sort: ColumnSortType.none,
      name: 'Start Date  ',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'target_date',
      sort: ColumnSortType.none,
      name: 'Target Date  ',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    // {
    //   value: 'modified_at',
    //   sort: ColumnSortType.none,
    //   name: ' Last Activity ',
    //   type: TableColumnTypes.text,
    //   className: 'table-header'
    // },
    {
      value: 'status',
      sort: ColumnSortType.none,
      name: 'Status',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'pm_assignee',
      sort: ColumnSortType.none,
      name: 'Manager',
      type: TableColumnTypes.usersListWithCountDropdown,
      className: 'table-header'
    },
    {
      value: 'engineers',
      sort: ColumnSortType.none,
      name: 'Engineer',
      type: TableColumnTypes.usersListWithCountDropdown,
      className: 'table-header'
    },
    {
      value: 'vendors',
      sort: ColumnSortType.none,
      name: 'Vendors',
      type: TableColumnTypes.usersListWithCountDropdown,
      className: 'table-header'
    }
  ];

  isLoader: boolean = false;
  searchInput: string = '';
  currentPage: number = 0;
  pageIndexAPI: number = 1;
  selectedViewTableRowsPageSize: number = 25;

  constructor(
    private router: Router,
    public tableService: TableService,
    public _utilService: UtilityService,
    public _baseService: BaseService,
    public _plannerService: plannerService,
  ) { }

  ngOnInit(): void {
    this.loadUpcomingJobs(this.searchInput, 1, this.selectedViewTableRowsPageSize);
    this._plannerService.fetchPlannerUpcomingJobUpdate().subscribe((state) => {
      if (state) {
        this.loadUpcomingJobs(this.searchInput, 1, this.selectedViewTableRowsPageSize);
        this._plannerService.plannerUpcomingJobsUpdate.next(false);
      }
    });
  }



  loadUpcomingJobs(search: string, page: number, pageSize: number, column?: any, direction?: any) {
    const offset = (this.pageIndexAPI - 1) * this.selectedViewTableRowsPageSize
    this.isLoader = true;
    const id = this.router.url.split('/').pop();
    let requestURL = environment.baseURL + `planner/${id}/upcoming-job-occurrence/?search=${search}&limit=${this.selectedViewTableRowsPageSize}&offset=${offset}`;
    if (column !== null && column !== undefined) {
      requestURL += `&ordering=${direction === 'asc' ? `${column}` : `-${column}`}`
    }
    this._baseService.doGET(requestURL).subscribe({
      next: (res: any) => {
        res.results.map((planner: any) => {
          if (!planner.job_id) {
            planner.job_id = 'TBD';
          }

          if (!planner.modified_at) {
            planner.modified_at = 'NA';
          }

          if (!planner.modified_at) {
            planner.modified_at = 'NA';
          }
          if (planner.pm_assignee) {
            planner.pm_assignee = planner.pm_assignee.name
          } else {
            planner.pm_assignee = 'Unassigned';
          }
          if (!Array.isArray(planner.pm_assignee)) {
            planner.pm_assignee = planner.pm_assignee ? planner.pm_assignee.split(',') : planner.pm_assignee;
          }

          if (Array.isArray(planner.engineers) && planner?.engineers?.length > 0) {
            let engineers = planner.engineers.map(obj => obj.full_name);
            planner.engineers = engineers;
            // list.engineers = engineers.toString();
          } else {
            planner.engineers = 'Unassigned';
            planner.engineers = planner.engineers ? planner.engineers.split(',') : planner.engineers;
          }

          if (Array.isArray(planner.followers) && planner.followers?.length > 0) {
            let followers = planner.followers.map(obj => obj.full_name);
            planner.followers = followers;
            // list.followers = followers.toString();
          }

          if (Array.isArray(planner.vendors) && planner.vendors?.length > 0) {
            let vendors = planner.vendors.map(obj => obj.company);
            planner.vendors = vendors;
            // list.vendors = vendors.toString();
          } else {
            planner.vendors = 'Unassigned';
            planner.vendors = planner.vendors ? planner.vendors.split(',') : planner.vendors;
          }

          if (!planner.status) {
            planner.status = 'Upcoming';
          }

          return planner;

        });


        this.dataSource = this.tableService.setTableData(this.moduleName, this.selecteViewTableHeaders, res.results, res.count, page - 1, pageSize);
        this.isLoader = false;
      },
      error: (res: HttpErrorResponse) => {
        this.isLoader = false;
        this._utilService.showError('', res);
      }
    })
  }

  searchJobs(e: any) {
    // this.searchInput = e;
    // this.loadUpcomingJobs(this.searchInput,1,this.selectedViewTableRowsPageSize, this.activeColumn,this.activeDirection)
  }

  onPageChange(e: any) {
    this.currentPage = e.pageIndex;
    this.pageIndexAPI = e.pageIndex + 1;
    this.selectedViewTableRowsPageSize = e.pageSize;
    this.loadUpcomingJobs(this.searchInput, this.pageIndexAPI, e.pageSize, this.activeColumn, this.activeDirection)

  }

  onSortChange(e: any) {
    this.activeDirection = e.direction;
    this.activeColumn = e.active;
    this.selecteViewTableHeaders.forEach(element => {
      (element.value === e.active) ? (element.sort = e.direction) : (element.sort = ColumnSortType.none);

      return element;
    });

    this.loadUpcomingJobs(this.searchInput, this.pageIndexAPI, this.selectedViewTableRowsPageSize, this.activeColumn, this.activeDirection)

  }
}
