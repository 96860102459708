import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import {debounceTime, map, takeUntil} from 'rxjs/operators';
import { InboxSettingsService } from 'src/app/Modules/_fyxt_modules/inbox-management/_services/inbox-settings.service';
import { Template } from 'src/app/Modules/_fyxt_modules/inbox-management/inbox.types';
import {UtilityService} from "../../../../../services/utility.service";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import { InboxService_New } from 'src/app/Modules/_fyxt_modules/inbox-management/_services/inbox.service';
import { DeleteInboxComponent } from 'src/app/Modules/_fyxt_modules/inbox-management/components/delete-inbox/delete-inbox.component';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { Router } from '@angular/router';
import { AngularEditorComponent } from '../../../_reusable_ui/_components/angular-editor/angular-editor.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'fyxt-tab-inbox-inboxes',
    templateUrl: './tab-inbox-inboxes.component.html',
    styleUrls: ['./tab-inbox-inboxes.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, TextboxComponent, NgxIntlTelInputModule, AngularEditorComponent]
})
export class TabInboxInboxesComponent implements OnInit,OnDestroy {
  inboxForm: FormGroup;
  timezone = []
  signature: any;
  userSignature: any;
  searchDebounce = new Subject();
  private readonly destroy$: Subject<null> = new Subject();

  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ direction: 'rtl' }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        // [{ color: [] }, { background: [] }],
        [{ font: [] }],
        // [{ align: [] }],
        ['clean']
      ]
    },
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ['@', '#'],
      theme: 'snow',
      isolateCharacter: true,  //using email to editor
      offsetTop: 0,
      defaultMenuOrientation: "top",
      renderLoading: () => {
        return "Loading...";
      },
      'emoji-toolbar': true,
      'emoji-textarea': false,
      'emoji-shortname': true,
      keyboard: {
        bindings: {
          enter: {
            key: 13,
            handler: (range, context) => {
              return true;
            }
          }
        }
      }
    }
  };
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    showToolbar: true,
    minHeight: '8rem',
    height: '6rem',
    toolbarPosition: 'top',
    sanitize: false,
    outline: false,
    placeholder: 'Write something here…',
    translate: 'no',
    defaultParagraphSeparator: 'div',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'subscript',
        'superscript',
        'customClasses',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
        'fontSize',
        'undo',
        'redo',
        'indent',
        'outdent',
      ]
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote'
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1'
      }
    ]
  };
  @Input() activeMailboxDetails: any;

  constructor(
    private formBuilder: FormBuilder,
    private inboxSettings: InboxSettingsService,
    public _utilService: UtilityService,
    private inboxService: InboxService_New,
    private _dialogService:DialogService,
    private router:Router) { }

  ngOnInit(): void {
    this.inboxForm = this.formBuilder.group({
      inboxName: ['', Validators.required],
      timeZone: ['', Validators.required]
    });
    this.getInbox();
    this.getUserSignature();
    this.searchDebounce.pipe(debounceTime(300)).subscribe((data:string) => {
      this.signature = data;
      // this.updateInbox();
    })
  }

  getInbox() {
    this.inboxSettings.getInbox().pipe(map((data:any)=>data.data)).subscribe((data: any) => {
      this.inboxForm.patchValue({inboxName:data.name})
      this.signature = data.signature;
      this.inboxSettings.signature = data.signature;
    })
  }

  saveButtonIsClicked(event: string) {
    this.inboxForm.patchValue({ signature: event })
  }

  contentChanged(event: any) {
    this.searchDebounce.next(event);
  }

  timeZoneValueChange(event: any) {
    this.inboxForm.patchValue({ timeZone: event })
  }

  changeContentBasedOnGmail(): void {
    let content =  this.signature.replaceAll('<p', '<div');
    content = content.replaceAll('</p>', '</div>');
    content = content.replaceAll('<p>', '<div  style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<div>', '<div style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<h1>', '<h1 style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<h2>', '<h2 style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<h3>', '<h3 style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<h4>', '<h4 style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<h5>', '<h5 style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<h6>', '<h6 style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<ol>','<ol style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<ul>','<ul style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<a>', '<a style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<li>','<li style="margin-top: 0px !important;margin-bottom: 0px !important;margin-left: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('white-space: pre-wrap', '');
    this.signature = content;
  }

  updateInbox(type) {
    if (type == 'signature') {
      this.changeContentBasedOnGmail();
    }
    const payload = {
     signature:this.signature,
     name:this.inboxForm.value.inboxName,
    }
    this.inboxSettings.updateInbox(payload).pipe(takeUntil(this.destroy$)).subscribe(
      {
        next: (response: any) => {
          this._utilService.showSuccess('', 'Signature Details Updated Successfully');
          this.getInbox();
        },
        error: (error) => {
           this._utilService.showErrorMessage(error);
        },
        complete: () => {
        }
      }
    );
  }

  getUserSignature() {
    this.inboxService.getUserSignature().pipe(takeUntil(this.destroy$)).subscribe((data:any) => {
      this.userSignature = data.data.content
    }, (err: any) => {
      this.userSignature = '';
    })
  }


  userSignatureContentChanged(event: any) {
    this.userSignature = event;
  }

  updateUserSignature() {
    let content = this.userSignature.replaceAll('<p', '<div');
    content = content.replaceAll('</p>', '</div>');
    content = content.replaceAll('<p>', '<div  style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<div>', '<div style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<h1>', '<h1 style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<h2>', '<h2 style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<h3>', '<h3 style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<h4>', '<h4 style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<h5>', '<h5 style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<h6>', '<h6 style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<ol>', '<ol style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<ul>', '<ul style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<a>', '<a style="margin-top: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('<li>', '<li style="margin-top: 0px !important;margin-bottom: 0px !important;margin-left: 0px !important;padding-top: 0px !important;padding-bottom: 0px !important;">');
    content = content.replaceAll('white-space: pre-wrap', '');
    this.userSignature = content;

    const payload = {
      content:this.userSignature
    }
    
    this.inboxService.updateUserSignature(payload).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this._utilService.showSuccess('', 'Signature Details Updated Successfully');
    }, (err) => {})
  }


  deleteInboxPopup(){
    let popupData = {
      title: 'Delete Inbox',
      component: DeleteInboxComponent,
    };
    this._dialogService.openModal(popupData).subscribe((data)=>{
      if(data){
        this.deleteInbox();
      } 
    });
  }


  deleteInbox(){
    this.inboxSettings.deleteInbox(this.activeMailboxDetails.id).subscribe((data)=>{
      this._utilService.showSuccess('','Inbox removed');
      this.router.navigate(['./pm/inbox-management']);
    })

  }

  ngOnDestroy(): void {
      this.destroy$.next(null);
      this.destroy$.complete();
  }

}
