import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { SidebarService } from 'src/app/@fyxt/_services/_layout/sidebar.service';
import { FileReminderComponent } from 'src/app/@fyxt/_shared/_views';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { FilesService } from '../../../../Modules/_fyxt_modules/companies/_services/files.service';
import { TabReminderComponent } from '../../_views/_tab_components/tab-reminder/tab-reminder.component';
import { TabFileInformationComponent } from '../../_views/_tab_components/tab-file-information/tab-file-information.component';
import { NgSwitch, NgSwitchCase } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../_reusable_ui/_components/button/button.component';
import { TabComponent } from '../../_reusable_ui/_components/tab/tab.component';


@Component({
    selector: 'fyxt-file-information-slide',
    templateUrl: './file-information-slide.component.html',
    styleUrls: ['./file-information-slide.component.scss'],
    standalone: true,
    imports: [TabComponent, ButtonComponent, MatIconModule, MatDividerModule, NgSwitch, NgSwitchCase, TabFileInformationComponent, TabReminderComponent]
})
export class FileInformationSlideComponent implements OnInit {

  threeDotDropdownMenuLists: any[] = [
    { label: 'Edit', value: 'Edit' },
    { label: 'Delete', value: 'Delete' },
  ];

  selectedTab: any;
  tabList = [
    { name: 'Detail', value: 'Detail', active: true, disable: false },
    { name: 'Reminder', value: 'Reminder', count: 0, active: false, disable: false },
  ];

  reminderData: any;

  @Input() chipList: any[] = [];
  @Output() onChangeCategories = new EventEmitter();
  @Output() onClose = new EventEmitter();

  constructor(
    public sidebarService: SidebarService,
    public filesService: FilesService,
    public dialogService: DialogService,
  ) {
  }

  ngOnInit(): void {
    this.filesService.getCurrentFile().subscribe((data) => {
      this.getReminderData(data.id);
    });
  }

  /**
   * On Select Tab
   * @param selectedTabValue
   */
  onSelectTab(selectedTabValue: any) {
    this.selectedTab = selectedTabValue;
  }

  changeCategory(event: any): void {
    this.onChangeCategories.emit(event);
  }

  onDismiss() {
    this.onClose.emit('closed');
  }

  onCancel() {
    this.sidebarService.toggleCustom();
  }

  menuOnSelect(event: any) {
    if (event.value === 'Edit') {
      // this.renameTemplate();
    }
  }

  getReminderData(id) {
    this.filesService.getRemindeForFiles(id).subscribe((data: any) => {
      this.reminderData = data?.data?.remindersForFile;
      this.tabList[1].count = this.reminderData.length;
    });
  }

  updateReminderCount(reminderCount: number) {
    this.tabList[1].count = reminderCount;
  }
}
