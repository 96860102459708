import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { ButtonComponent } from '../../../../../@fyxt/_shared/_reusable_ui/_components/button/button.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { TextboxComponent } from '../../../../../@fyxt/_shared/_reusable_ui/_controls/textbox/textbox.component';

@Component({
    selector: 'fyxt-create-folder',
    templateUrl: './create-folder.component.html',
    styleUrls: ['./create-folder.component.scss'],
    standalone: true,
    imports: [TextboxComponent, FormsModule, NgxIntlTelInputModule, MatDialogModule, ButtonComponent]
})
export class CreateFolderComponent implements OnInit {

  protected search: string = null;
  constructor(private readonly dialogRef: MatDialogRef<CreateFolderComponent>,) { }

  ngOnInit(): void {
  }

  protected createFolder() : void {
    this.dialogRef.close({"result":this.search})
  }

  protected closePopup(): void {
    this.dialogRef.close(null);
  }


}
