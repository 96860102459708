import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FileReminderComponent } from 'src/app/@fyxt/_shared/_views';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { FilesService } from 'src/app/Modules/_fyxt_modules/companies/_services/files.service';
import { MatDialog } from '@angular/material/dialog';
import { FileReminderActionType } from '../../_popup_views/file-reminder/file-reminder.interface';
import { ThreeDotsDropdownComponent } from '../../../_reusable_ui/_components/three-dots-dropdown/three-dots-dropdown.component';
import { NgFor, NgIf, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';


@Component({
    selector: 'fyxt-tab-reminder',
    templateUrl: './tab-reminder.component.html',
    styleUrls: ['./tab-reminder.component.scss'],
    standalone: true,
    imports: [ButtonComponent, MatIconModule, MatDividerModule, FormsModule, ReactiveFormsModule, NgFor, NgIf, ThreeDotsDropdownComponent, DatePipe]
})
export class TabReminderComponent implements OnInit {

  threeDotDropdownMenuLists: any[] = [
    { label: 'Edit', value: 'Edit' },
    { label: 'Delete', value: 'Delete' },
  ];

  @Input() chipList: any[] = [];
  @Input() isLoader!: boolean;
  @Output() onChangeCategories = new EventEmitter();
  @Output() countChange = new EventEmitter();

  selectedTab: any;
  deletableData: any;
  reminderData: any = [];

  @ViewChild('confirmDeleteReminder')
  confirmDeleteReminder!: TemplateRef<any>;

  constructor(
    public dialog: MatDialog,
    public _dialogService: DialogService,
    private _filesService: FilesService,) {
  }

  ngOnInit(): void {
    this._filesService.getCurrentFile().subscribe((data) => {
      this.getReminderData(data.id);
    });
  }

  /**
   * On Select Tab
   * @param selectedTabValue
   */
  onSelectTab(selectedTabValue: any) {
    this.selectedTab = selectedTabValue;
  }

  changeCategory(event: any): void {
    this.onChangeCategories.emit(event);
  }


  getReminderData(id) {
    this._filesService.getRemindeForFiles(id).subscribe((data: any) => {
      this.reminderData = data?.data?.remindersForFile;
      this.countChange.emit(this.reminderData.length);
    });
  }

  menuOnSelect(event: any, i) {
    if (event.value == 'Edit') {
      this.addReminder(FileReminderActionType.EDIT, this.reminderData[i]);
    }
    if (event.value == 'Delete') {
      this.openDeletePopup(i)
    }
  }

  deleteReminder(i) {
    this._filesService.deleteReminder(this.reminderData[i].id).subscribe((data) => {
      this.getReminderData(this._filesService.currentViewingFile.id);
    });
  }

  addReminder(type = 'ADD', val = {}) {

    let popupData = {
      title: 'Add Reminder',
      component: FileReminderComponent,
      containerClass: 'remider_model',
      data: this._filesService.currentViewingFile,
      hide_header: true,
      type: type,
      val: val,
    };

    this._dialogService.openModal(popupData).subscribe(result => {
      this.getReminderData(this._filesService.currentViewingFile.id);
    });

  }

  formatType(type) {
    const formattedType = type
      .replace(/_AND_/g, ' And ')
      .replace(/_/g, ' ')
      .toLowerCase();

    return formattedType.charAt(0).toUpperCase() + formattedType.slice(1);
  }

  openReminderInfo(reminder: any) {
    let popupData = {
      title: 'Reminder',
      component: FileReminderComponent,
      containerClass: ['remider_model'],
      data: this._filesService.currentViewingFile,
      type: FileReminderActionType.INFO,
      val: reminder,
      hide_header: true
    };

    this._dialogService.openModal(popupData).subscribe(result => {
      this.getReminderData(this._filesService.currentViewingFile.id);
    });
  }

  openDeletePopup(i) {
    let popupData = {
      title: 'Delete Reminder?',
      template: this.confirmDeleteReminder,
      confirmText: 'Delete',
      cancelText: 'Cancel',
      buttons: true,
    };

    this.deletableData = this.reminderData[i]

    this._dialogService.confirmDialog(popupData).subscribe((result: any) => {
      if (result) {
        this.deleteReminder(i);
      }
    });
  }

}
