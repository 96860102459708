// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .mat-mdc-menu-panel.fyxt_notification {
  width: 357px !important;
  max-width: inherit !important;
  overflow: hidden;
  max-height: 89vh !important;
  min-height: 20vh !important;
  top: 14px;
}

.notification_wrap_out {
  max-height: 79vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.notification_wrap_out .dot {
  width: 8px;
  height: 8px;
  background-color: #D63C00;
  border-radius: 50%;
  display: inline-block;
  margin-left: 0 !important;
}
.notification_wrap_out .notification_wrap {
  padding: 12px 24px;
}
.notification_wrap_out .notification_wrap.hover {
  cursor: pointer;
  background-color: #FBFBFD;
}
.notification_wrap_out .notification_wrap:hover {
  cursor: pointer;
  background-color: #FBFBFD;
}

::ng-deep .mat-badge-content {
  border-radius: 12px;
  opacity: 1;
  font-size: 12px !important;
  font-weight: 400 !important;
  background: #D63C00;
  color: #ffffff;
  padding: 1px 6px !important;
  min-height: 18px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_views/_tab_components/notifications/notifications.component.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,6BAAA;EACA,gBAAA;EACA,2BAAA;EACA,2BAAA;EACA,SAAA;AACJ;;AACE;EACE,gBAAA;EACA,gBAAA;EACA,kBAAA;AAEJ;AADI;EACE,UAAA;EACA,WAAA;EACA,yBAAA;EACA,kBAAA;EACA,qBAAA;EACA,yBAAA;AAGN;AADI;EACE,kBAAA;AAGN;AAFM;EACE,eAAA;EACA,yBAAA;AAIR;AADI;EACE,eAAA;EACA,yBAAA;AAGN;;AAaE;EACE,mBAAA;EACA,UAAA;EACA,0BAAA;EACA,2BAAA;EACA,mBAAA;EACA,cAAA;EACA,2BAAA;EACA,2BAAA;AAVJ","sourcesContent":["::ng-deep .mat-mdc-menu-panel.fyxt_notification {\n    width: 357px !important;\n    max-width: inherit !important;\n    overflow: hidden;\n    max-height: 89vh !important;\n    min-height: 20vh !important;\n    top: 14px;\n  }\n  .notification_wrap_out{\n    max-height: 79vh;\n    overflow-y: auto;\n    overflow-x:hidden;\n    .dot {\n      width: 8px;\n      height: 8px;\n      background-color: #D63C00;\n      border-radius: 50%;\n      display: inline-block;\n      margin-left: 0 !important;\n    }\n    .notification_wrap{\n      padding: 12px 24px;\n      &.hover{\n        cursor: pointer;\n        background-color: #FBFBFD;\n      }\n    }\n    .notification_wrap:hover{\n      cursor: pointer;\n      background-color: #FBFBFD;\n    }\n  }\n\n  // ::ng-deep span#mat-badge-content-0 {\n  //   border-radius: 12px;\n  //   opacity: 1;\n  //   font-size: 12px !important;\n  //   font-weight: 400 !important;\n  //   background: #D63C00;\n  //   color: #ffffff;\n  //   padding: 1px 6px !important;\n  //   min-height: 18px !important;\n  // }\n\n  \n  ::ng-deep .mat-badge-content {\n    border-radius: 12px;\n    opacity: 1;\n    font-size: 12px !important;\n    font-weight: 400 !important;\n    background: #D63C00;\n    color: #ffffff;\n    padding: 1px 6px !important;\n    min-height: 18px !important;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
