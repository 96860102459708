import { Injectable } from '@angular/core';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class UiService {

  jobInfoOpen: boolean = true;
  dashboardFilterOpen: boolean = false;
  currentActiveContent: any = "";
  selectedContentArea: any = "";
  getRightSidebarClass: any;
  isTimesheetFilter: boolean = false;
  inboxMenu: boolean = true;

  constructor() {



  }

  /**
   * Init Scroller for Filters
   */
  initUIFunctions() {

    $(document).ready(function () {

      let $body = $('body');
      let $window = $(window);

      $('.slimscroll-menu').slimscroll({
        height: '100vh',
        position: 'right',
        size: "5px",
        color: '#9ea5ab',
        wheelStep: 5,
        touchScrollStep: 20
      });



    });


  }





  /**
   * Sidebar Menu Toggle
   */
  sideBarToggle() {

    $(document).ready(function () {

      if ($('body').hasClass('enlarged')) {
        $('body').removeClass('sidebar-enable enlarged');
      }
      else {
        $('body').addClass('sidebar-enable enlarged');
      }


    });

  }


  createTicketTrigger() {
    $(document).ready(function () {
      $('#createTicketBtn').click();
    });
  }



  /**
   * Set Right Side bar width sm, md, lg depends on action type
   * @param type
   */
  getRightSliderClass(type: any) {

    if (type === 'job_info' || type === 'add_roles' || type === 'edit_roles') {

      return 'right_sidebar_lg';

    }
    else if (type === 'edit_profile' || type === 'change_password' || type === 'add_team' || type === 'edit_team' || type === 'search_team' || type === 'invite_team' || type === 'add_properties' || type === 'add_tenants' || type === 'add_vendors' || type == 'edit_vendors' || type === 'add_clients' || type === 'edit_clients' || type === 'add_workflow' || type === 'add_abstract' || type === 'upload_budget' || type === 'add_COA' || type === 'add_engineer' || type === 'edit_engineer') {

      return 'right_sidebar_sm';

    }
    else if (type === 'notifications') {
      return 'right_sidebar_notifications';
    }
    else if (type === '') {
      return 'right_sidebar_hide';
    }

    return 'right_sidebar_hide';

  }


  /**
   * Show Right Side Slideout
   */
  showRightSideBar() {

    let getRightSidebarClass = this.getRightSliderClass(this.currentActiveContent);

    $('body').addClass('right-bar-enabled');
    $('.right-bar').addClass(getRightSidebarClass);

  }


  /**
   * Close Right Side slideout
   */
  closeRightSideBar() {
    this.currentActiveContent = '';
    this.jobInfoOpen = true;
    $(document).ready(function () {

      $('body').removeClass('right-bar-enabled');
      $('.right-bar').removeClass('right_sidebar_lg');
      $('.right-bar').removeClass('right_sidebar_sm');
    });

  }


  /**
   * Toggle Filters
   */
  showFilterSideBar() {

    if (this.dashboardFilterOpen) {

      if ($('body').hasClass('left-filter-enabled')) {
        // $('body').removeClass('left-filter-enabled');
        // $('.left-bar').removeClass('filter-popup');
        // $('.left-bar').addClass('display_none');
      }
      else {
        $('body').addClass('left-filter-enabled');
        $('.left-bar').addClass('filter-popup');
        $('.left-bar').removeClass('display_none');
      }

    }
    else {
      $(document).ready(function () {
        if ($('body').hasClass('left-filter-enabled')) {
          $('body').removeClass('left-filter-enabled');
          $('.left-bar').removeClass('filter-popup');
          $('.left-bar').addClass('display_none');
        }
        else {
          this.dashboardFilterOpen = false;
          $('body').addClass('left-filter-enabled');
          $('.left-bar').addClass('filter-popup');
          $('.left-bar').removeClass('display_none');
        }
      });

    }




  }
  /** */
  /**
   * Expand collapse toggle bar
   */

  expandToggleBar() {
    $(document).ready(function () {
      $(".left_bar_close").click(function () {
        $(".collapse").collapse('hide');
      });
    });

  }


  /**
   * Hide Filters
   */
  closeFilterSideBar() {

    $(document).ready(function () {

      $('body').removeClass('left-filter-enabled');
      $('.left-bar').removeClass('filter-popup');
      $('.left-bar').addClass('display_none');

    });

    this.selectedContentArea = '';
  }



  /**
    * Zoom Window
    */
  ZoomWindow() {

    $(document).ready(function () {

      /* Get the element you want displayed in fullscreen */
      var elem: any = document.documentElement;

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem = window.top.document.body; //To break out of frame in IE
        elem.msRequestFullscreen();
      }


    });

  }

  hubClick(selectedMailContainerID?: any) {

    let containerElement = ".mail_body_container_" + selectedMailContainerID;
    let containerElementActionText = ".show_more_less_" + selectedMailContainerID;

    $(document).ready(function () {

      $(containerElement).toggleClass("show_more_height");

      if ($(containerElement).hasClass("show_more_height")) {
        $(containerElementActionText).text("Show More");
      } else {
        $(containerElementActionText).text("Show Less");
      }

    });

  }



  /**carousel slider */
  carouselSlider() {
    $(function () {
      $('.carousel').carousel({
        interval: 2500
      });
      $('.carousel-control.right').trigger('click');
    });
  }


  emailToggle() {
    $(document).ready(function () {
      $(".gmail_quote").click(function () {
        $(this).toggleClass("show");
      });
      // $('.gmail_quote').first().addClass('divRplyFwdMsg_prev');
      $(".outlookReply").click(function () {
        $(this).toggleClass("show");
      });

      $('.outlookReply').next().addClass('divRplyFwdMsg_next');

      $(".outlookReply").click(function () {
        $(".divRplyFwdMsg_next").toggleClass("show");
      });

    });
  }

}
