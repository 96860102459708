// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .select-units > .ng-select-container {
  height: 100% !important;
}

::ng-deep .select-units > ng-dropdown-panel {
  position: relative;
  margin: 0 !important;
  border: none !important;
}
::ng-deep .select-units > ng-dropdown-panel .ng-dropdown-header {
  border: 1px solid #d2d2d2;
  border-bottom: none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  margin-top: 3px;
}
::ng-deep .select-units > ng-dropdown-panel .ng-dropdown-header label {
  margin-bottom: 0 !important;
}
::ng-deep .select-units > ng-dropdown-panel .ng-dropdown-panel-items {
  border: 1px solid #d2d2d2;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lease_property_data {
  width: 25vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.property_of_the_lease_container_loader {
  text-align: center;
  width: 27vw;
  height: 25vh;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_views/_popup_views/lease-property/lease-property.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;;AAEA;EACE,kBAAA;EACA,oBAAA;EACA,uBAAA;AACF;AACE;EACE,yBAAA;EACA,mBAAA;EACA,4BAAA;EACA,2BAAA;EACA,eAAA;AACJ;AACI;EACE,2BAAA;AACN;AAGE;EACE,yBAAA;EACA,8BAAA;EACA,+BAAA;AADJ;;AAKA;EACE,WAAA;EACA,kBAAA;EACA,gBAAA;AAFF;;AAKA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AAFF","sourcesContent":["::ng-deep .select-units > .ng-select-container {\n  height: 100%!important;\n}\n\n::ng-deep .select-units > ng-dropdown-panel {\n  position: relative;\n  margin: 0 !important;\n  border: none !important;\n\n  .ng-dropdown-header {\n    border: 1px solid #d2d2d2;\n    border-bottom: none;\n    border-top-right-radius: 4px;\n    border-top-left-radius: 4px;\n    margin-top: 3px;\n\n    label {\n      margin-bottom: 0 !important;\n    }\n  }\n\n  .ng-dropdown-panel-items {\n    border: 1px solid #d2d2d2;\n    border-bottom-left-radius: 4px;\n    border-bottom-right-radius: 4px;\n  }\n}\n\n.lease_property_data {\n  width: 25vw;\n  overflow-x: hidden;\n  overflow-y: auto;\n}\n\n.property_of_the_lease_container_loader {\n  text-align: center;\n  width: 27vw;\n  height: 25vh;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
