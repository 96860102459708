import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { DropdownComponent } from '../../dropdown/dropdown.component';


export interface ItemList {
  id: any;
  name: any;
  value: any;
}

@Component({
    selector: 'app-date-dropdown',
    templateUrl: './date-dropdown.component.html',
    styleUrls: ['./date-dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => DateDropdownComponent)
        }
    ],
    standalone: true,
    imports: [DropdownComponent, FormsModule, NgxIntlTelInputModule, BsDatepickerModule]
})

export class DateDropdownComponent implements OnInit {
  @Input() placement: string = 'right'; // || 'bottom' || 'left' || 'top';
  @Input() id: string = 'dropdown_datepicker_selector';
  @Input() placeholder: string = 'Select Date';
  @Input() dateValueObj: any = {};
  @Input() isCustomFilter: boolean = false;

  @Output() onValueChange = new EventEmitter<Event>();
  @Output() onFocusOut = new EventEmitter<any>();


  @ViewChild('rangePicker') rangePicker: ElementRef;
  bsConfig?: Partial<BsDatepickerConfig>;

  listStatus: ItemList[] = [
    { id: 1, name: 'Today', value: '0' },
    { id: 2, name: 'Yesterday', value: '-1' },
    { id: 3, name: 'Last 7 Days', value: '-7' },
    { id: 4, name: 'Last 14 Days', value: '-14' },
    { id: 5, name: 'Last 30 Days', value: '-30' },
    { id: 5, name: 'Custom', value: 'Custom' }
  ];

  dateValue: any = null;
  bsRangeValue: Date[] | String;
  tempObj: any = {};


  minDate: Date = new Date();
  maxDate: Date = new Date();

  isDateUpdated: boolean = false;
  tempDate: any[]=[];

  constructor(public changeDetector: ChangeDetectorRef) {
    // this.minDate.setDate(this.minDate.getDate() - 1);
    this.maxDate.setDate(this.maxDate.getDate() + 1);
    this.bsConfig = Object.assign({}, { containerClass: 'datePicker_dropdown' });


  }

  ngOnInit(): void {
  }

  ngAfterContentChecked(): void {

   
  if (this.dateValueObj) {
    if (this.dateValueObj.hasOwnProperty('value') && !this.isCustomFilter) {
      if (this.dateValueObj.value === 'Custom') {
        if (!this.isDateUpdated) {
          this.dateValueObj.dateRange = this.tempDate;
          this.dateValue = 'Custom';
          this.listStatus = this.listStatus.map((obj: any) => { (obj.value == 'Custom') ? (obj.name = this.dateValueObj.name) : null; return obj })
          this.isDateUpdated = true;

          this.onDateChange(this.tempDate);

          // this.onChange(this.dateValueObj);
          // this.onValueChange.emit(this.dateValueObj);
        }
      }
      else {
        this.dateValue = this.dateValueObj.value;
        this.isDateUpdated = false;
      }
    } else if(this.isCustomFilter){

      if(typeof this.dateValueObj == 'object' && this.dateValueObj.hasOwnProperty('custom')){
        this.dateValue = 'Custom';
      }

      if(typeof this.dateValueObj == 'object' && !this.dateValueObj.hasOwnProperty('custom')){
        this.dateValue = this.dateValueObj.value;
      }

      if(typeof this.dateValueObj == 'string'){
        this.dateValue = this.dateValueObj;
      }
     
    }
  }

    this.changeDetector.detectChanges();

  }

  onChange = (data: any) => {}
  onTouch = (_: any) => { }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: string) {
    if (value == null) {

      if (this.id == 'schedule' || this.id == 'days_in_status') {
        this.listStatus = [
          { id: 1, name: 'Today', value: '0' },
          { id: 2, name: 'Yesterday', value: '-1' },
          { id: 3, name: 'Current Week', value: 'week' },
          { id: 4, name: 'Current Month', value: 'month' },
          { id: 5, name: 'Next 7 Days', value: '7' },
          { id: 6, name: 'Last 7 Days', value: '-7' },
          { id: 7, name: 'Next 14 Days', value: '14' },
          { id: 8, name: 'Last 14 Days', value: '-14' },
          { id: 9, name: 'Next 30 Days', value: '30' },
          { id: 10, name: 'Last 30 Days', value: '-30' },
          { id: 11, name: 'Custom', value: 'Custom' }
        ];
      } else if (this.id == 'target_completion') {
        this.listStatus = [
          { id: 1, name: 'Before Today', value: '-1' },
          { id: 2, name: 'Today', value: '0' },
          { id: 3, name: 'Tomorrow', value: '1' },
          { id: 4, name: 'Next 7 Days', value: '7' },
          { id: 5, name: 'Next 14 Days', value: '14' },
          { id: 6, name: 'Next 30 Days', value: '30' },
          { id: 7, name: 'Custom', value: 'Custom' }
        ];
      }
      else if (this.id == 'custom_date_filter') {
        this.listStatus = [
          { id: 1, name: 'Today', value: '0' },
          { id: 2, name: 'Yesterday', value: '-1' },
          { id: 3, name: 'Next 7 Days', value: '7' },
          { id: 4, name: 'Next 14 Days', value: '14' },
          { id: 5, name: 'Next 30 Days', value: '30' },
          { id: 6, name: 'Last 7 Days', value: '-7' },
          { id: 7, name: 'Last 14 Days', value: '-14' },
          { id: 8, name: 'Last 30 Days', value: '-30' },
          { id: 9, name: 'Custom', value: 'Custom' }
        ];
      }
      else {
        this.listStatus = [
          { id: 1, name: 'Today', value: '0' },
          { id: 2, name: 'Yesterday', value: '-1' },
          { id: 3, name: 'Last 7 Days', value: '-7' },
          { id: 4, name: 'Last 14 Days', value: '-14' },
          { id: 5, name: 'Last 30 Days', value: '-30' },
          { id: 5, name: 'Custom', value: 'Custom' }
        ];
      }

    }

    this.dateValue = value;
    this.onChange(value);
  }

  /**
 *Func triggered on input change
 */
  onchange(event: any) {
    this.onChange(event);
    this.onValueChange.emit(event);
  }


  onChangeValue(event: any) {
    if (event?.value == 'Custom') {
      event.dateRange = [];
      this.bsRangeValue = 'Custom';
    }
    else {
      this.bsRangeValue = [new Date(), null];
    }
    event.dateRange = [];
    this.listStatus = this.listStatus.map(obj => { (obj.value == 'Custom') ? (obj.name = 'Custom') : null; return obj });
    this.tempObj = event;
    this.onChange(this.tempObj);
    this.onValueChange.emit(this.tempObj);
  }

  onDateChange(event: any) {
    if (Array.isArray(event)) {
      this.tempDate=event;
      if (this.dateValue == 'Custom' && event.length == 2) {
        this.tempObj.dateRange = event;
        //let dateLable = '( ' + this.formatDateToMM_DD_YYYY(event[0]) + ' - ' + this.formatDateToMM_DD_YYYY(event[1]) + ' )';
        // this.listStatus = this.listStatus.map(obj => { (obj.value == 'Custom') ? (obj.name = dateLable) : null; return obj })
      }
      // this.onChange(this.tempObj);
      // this.onValueChange.emit(this.tempObj);
    }


  }

  /**
 * Format to MM-DD-YYYY => 01-20-2023
 * @param date
 */
  formatDateToMM_DD_YYYY(date: Date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [month, day, year].join('/');
  }


  focusOut(event?: any) {
    // if(this.listStatus.find((obj:any)=>obj.value=='Custom' && (obj?.dateRange && obj?.dateRange?.length>0))==undefined){
    //   this.dateValue=null;
    // }
  }
}
