import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';

import {
  ConfirmDialogComponent
} from 'src/app/@fyxt/_shared/_reusable_ui/_components/confirm-dialog/confirm-dialog.component';
import { ModalComponent } from 'src/app/@fyxt/_shared/_reusable_ui/_components/modal/modal.component';
import { ConfirmDialogData } from './confirm-dialog-data';
import { catchError } from 'rxjs/operators';

export interface ModalData<T = void> {
  title?: string;
  component?: any;
  containerClass?: string[] | string;
  data?: T;
  inputValues?: any;
  hide_header?: boolean;
  enterAnimationDuration?: string;
  exitAnimationDuration?: string;
}

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  _viewModule: string = '';
  isDefaultViewChanged: boolean;
  isMinimizeButtonClicked = false;
  public isUploading = new BehaviorSubject<any>(false);
  public minimizeTicketDetails = new BehaviorSubject<boolean>(false);
  public contactFormSubmitted = new BehaviorSubject<boolean>(false);
  public editPendingStatus = new BehaviorSubject<any>(null);

  getisUploading() {
    return this.isUploading.asObservable();
  }


  triggerMinimizeJobFunction = new EventEmitter();
  subsVar: Subscription;

  constructor(
    public dialog: MatDialog,
    private httpClient: HttpClient
  ) {
  }

  confirmDialog(data: ConfirmDialogData, style?: { width?: string }): Observable<boolean> {
    return this.dialog
      .open(ConfirmDialogComponent, {
        data,
        width: style?.width || '620px',
        panelClass: data.containerClass,
        disableClose: true,
      })
      .afterClosed();
  }

  confirmRefDialog(data: ConfirmDialogData, style?: { width?: string }) {
    return this.dialog
      .open(ConfirmDialogComponent, {
        data,
        width: style?.width || '620px',
        panelClass: data.containerClass,
        disableClose: true,
      });
  }


  openModal<T>(data: ModalData<T>) {

    const dialogRef = this.dialog
    .open(ModalComponent, {
      data,
      panelClass: data.containerClass,
      disableClose: true,
      enterAnimationDuration: data?.enterAnimationDuration || "1000ms",
      exitAnimationDuration: data?.exitAnimationDuration || "250ms",
      autoFocus: true
    });

    //Prevent Space bar event
    dialogRef.keydownEvents().subscribe((e:any) => {
      if (e.keyCode === 32) {
        if(e.target?.tagName==='BUTTON'){
          e.preventDefault();
        }
      }
    });

    return dialogRef.afterClosed();


    // return this.dialog
    //   .open(ModalComponent, {
    //     data,
    //     panelClass: data.containerClass,
    //     disableClose: true,
    //     enterAnimationDuration:"1000ms",
    //     exitAnimationDuration:"250ms",
    //   })
    //   .afterClosed();
  }

  /**
   *  GET Method
   */
  doGET(endpoint_URL: string, params?: any): Observable<any> {
    return this.httpClient.get(endpoint_URL, { params });
  }



  /**
   *  POST Method
   */
  doPOST(endpoint_URL: string, postBody: any): Observable<any> {
    return this.httpClient.post(endpoint_URL, postBody);
  }



  /**
   *  PUT Method
   */
  doUPDATE(endpoint_URL: string, postBody: any): Observable<any> {
    return this.httpClient.put(endpoint_URL, postBody);
  }

  /**
   *  PATCH Method
   */
  doPATCH(endpoint_URL: string, postBody: any, params?: any): Observable<any> {
    return this.httpClient.patch(endpoint_URL, postBody, { params });
  }


  /**
   *  DELETE Method
   */
  doDELETE(endpoint_URL: string): Observable<any> {
    return this.httpClient.delete(endpoint_URL);
  }

   /**
   *  DELETE Method with Payload
   */
   doDELETEapi(endpoint_URL: string ,postBody: any): Observable<any> {
    return this.httpClient.delete(endpoint_URL,  { body: postBody });
  }


  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  fetchMinimizeTicketDetails(): Observable<boolean> {
    return this.minimizeTicketDetails.asObservable();
  }
  fetchStatusDetails(): Observable<any> {
    return this.editPendingStatus.asObservable();
  }
}
