import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  _viewModule: string = "company";
  isLoader: boolean = false;
  selectedActiveTabId: string;
  propertiesListForUsers = [];
  selectedCompanyDetails: any;
  associatedJobCount: any;
  leaseTabCount: any;
  coiTabCount: any;
  formDropDownTypes: any = {company_types: [], email_types: [], phone_types: [], address_types: []};
  isCompanyAddUpdate$ = new BehaviorSubject<any>(undefined);
  public createCompanyInitialize = new BehaviorSubject<boolean>(false);
  public contactCreateFromCompanyDetailedPage = new BehaviorSubject<boolean>(false);
  public loadCompanyDataAfterNewCompanyCreate = new BehaviorSubject<boolean>(false);
  public isCompanyNameUpdate = new BehaviorSubject<boolean>(false);
  public isCompanyTypeUpdate = new BehaviorSubject<boolean>(false);
  public contactsId = new BehaviorSubject<any>(null);
  setPreviousTab:any={};
  isDropDown: boolean = false;
  isContactCreated: boolean = true;

  isBack: boolean = false;
  temp_PreviousFilters: any = {};
  
  constructor(private httpClient: HttpClient) { }

  /**
  *  GET Method
  */
  doGET(endpoint_URL: string): Observable<any> {
    return this.httpClient.get(`${environment.fastAPI_Actual_URL + '/' + endpoint_URL}`);
  }

  /**
  *  POST Method
  */
  doPOST(endpoint_URL: string, postBody: any): Observable<any> {
    return this.httpClient.post(`${environment.fastAPI_URL + '/' + endpoint_URL}`, postBody);
  }

  /**
   *  PUT Method
   */
  doUPDATE(endpoint_URL: string, postBody: any): Observable<any> {
    return this.httpClient.put(`${environment.fastAPI_URL + '/' + endpoint_URL}`, postBody);
  }

  /**
  *  PATCH Method
  */
  doPATCH(endpoint_URL: string, postBody: any): Observable<any> {
    return this.httpClient.patch(`${environment.fastAPI_Actual_URL + '/' + endpoint_URL}`, postBody);
  }


  /**
  *  DELETE Method
  */
  doDELETE(endpoint_URL: string): Observable<any> {
    return this.httpClient.delete(`${environment.fastAPI_URL + '/' + endpoint_URL}`);
  }

  /**
   * Get All Views API responses
   * @returns
   */
  getAllViewsData() {
    let listAllStandardViews = this.httpClient.get(environment.fastAPI_Actual_URL + '/company-views/dashboard/');
    return listAllStandardViews ;
  }


  /**
   * Get all selected View Table lists
   * @param viewID
   * @returns
   */
  getSelectedTableData(viewID: string) {
    let selecteViewTableHeaders = this.httpClient.get(`${environment.fastAPI_Actual_URL + '/company-views/' + viewID + '/'}`);

    return selecteViewTableHeaders;
  }


  /**
   * Get all selected View Table lists
   * @param viewID
   * @returns
   */
  getSelectedTableData1(selecteViewTableRows: string) {
    return this.httpClient.get(selecteViewTableRows);
  }


  /**
   * Get Default Active tab Data
   * @param tabList
   * @returns
   */
  getDefaultActiveVerticalMenuID(tabList: any) {
    let activeMenu: any = {};

    let activeTabList = tabList.filter(obj => obj.menus.some((item: { default: boolean; }) => item.default == true)).map(obj => { return { activeItem: obj.menus.find((item: { default: boolean; }) => item.default == true), type: obj.type, activeIndex: obj.menus.findIndex((item: { default: boolean; }) => item.default == true) } });

    if (activeTabList.length > 0) {
      activeMenu = activeTabList[0];
    }

    if(activeMenu.activeItem==undefined){
      if(tabList.length>0 && tabList[0]?.menus?.length>0){
        tabList[0].menus[0].default=true
        activeMenu=tabList[0].menus[0];
      }
    }

    return activeMenu.activeItem;
  }


  /**
 * Get all selected View Table lists
 * @param viewID
 * @returns
 */
  getAllCompanyTypes() {

    let company_types = this.httpClient.get(environment.baseURL + 'company-types/?source=Company');
    let email_types = this.httpClient.get(environment.baseURL + 'company-types/?source=CompanyEmail');
    let phone_types = this.httpClient.get(environment.baseURL + 'company-types/?source=CompanyPhone');
    let address_types = this.httpClient.get(environment.baseURL + 'company-types/?source=Address');



    // return forkJoin([company_types, email_types, phone_types,address_types]);
    return forkJoin({ company_types: company_types, email_types: email_types, phone_types: phone_types, address_types: address_types });
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  fetchCreateCompanyInitialization(): Observable<boolean> {
    return this.createCompanyInitialize.asObservable();
  }

  fetchContactCreateFromCompanyDetailedPage(): Observable<boolean> {
    return this.contactCreateFromCompanyDetailedPage.asObservable();
  }

  fetchCompanyDataLoaded(): Observable<boolean> {
    return this.loadCompanyDataAfterNewCompanyCreate.asObservable();
  }

  fetchCompanyNameUpdate(): Observable<boolean> {
    return this.isCompanyNameUpdate.asObservable();
  }

  fetchCompanyTypeUpdate(): Observable<boolean> {
    return this.isCompanyTypeUpdate.asObservable();
  }
  getContactId(): Observable<boolean> {
    return this.contactsId.asObservable();
  }

  setCompaniesData(response: any) {
    this.isCompanyAddUpdate$.next(response);
  }
}
