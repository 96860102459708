import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {DialogService, ModalData} from 'src/app/@fyxt/_services/dialog/dialog.service';

import { TableColumnTypes,ColumnSortType } from 'src/app/@fyxt/_shared/enums/table/table-column-types.enum';
import { AppTableSource } from 'src/app/@fyxt/_shared/models/app-table-model';

import {EditColumn, EditColumnsComponent, EditColumnsModalData} from 'src/app/@fyxt/_shared/_views';
import {BehaviorSubject} from "rxjs";
import {switchMap, take} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import { TableComponent } from '../../../_reusable_ui/_components/table/table.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { NgIf } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';

@Component({
    selector: 'fyxt-tab-associatedjobs',
    templateUrl: './tab-associatedjobs.component.html',
    styleUrls: ['./tab-associatedjobs.component.scss'],
    standalone: true,
    imports: [SearchInputComponent, MatDividerModule, NgIf, LoaderSmallComponent, TableComponent]
})
export class TabAssociatedjobsComponent implements OnInit {
  private readonly editableColumns$: BehaviorSubject<any[]> = new BehaviorSubject<EditColumn[]>([]);
  @Input() dataSource!: AppTableSource;
  @Input() associatedJobSearchValue!: string;
  @Input() isLoader!: boolean;
  @Input() associatedJobEditColumnView!: [];
  @Output() onSearchValueChange = new EventEmitter<Event>();
  @Output() onSortValueChange = new EventEmitter<Event>();
  @Output() onEditColumnChange = new EventEmitter();
  @Output() onPaginationValueChange = new EventEmitter<Event>();
  @Output() onRowClickValueChange = new EventEmitter();
  @Output() onStarValueChangeEmit = new EventEmitter();
  constructor(
    public _dialogService: DialogService,
  ) { }

  ngOnInit(): void {
  }

  editColumnPopup() {
    this.editableColumns$.next(this.associatedJobEditColumnView);
    this.editableColumns$
      .pipe(
        take(1),
        switchMap((columns) => {
          let popupData: ModalData<EditColumnsModalData> = {
            title: 'Edit Table Column',
            component: EditColumnsComponent,
            containerClass: ['modal-sm', 'modal_edit_colomn'],
            data: {
              columns,
              onChange: this.editColumnOrder.bind(this)
            }
          };
          return this._dialogService.openModal<EditColumnsModalData>(popupData);
        })
      )
      .subscribe(result => {});
  }

  editColumnOrder(columnLists: EditColumn[]): void {
    this.onEditColumnChange.emit(columnLists);
  }

  searchAssociatedJobs(value: any): void {
    this.onSearchValueChange.emit(value);
    this.associatedJobSearchValue = value;
  }

  associatedJobsSortChange(sortState: any) {
    this.onSortValueChange.emit(sortState);
  }

   associatedJobsPageChange(paginationEvent: any) {
    this.onPaginationValueChange.emit(paginationEvent);
  }

  onRowClick(event: any): void {
    this.onRowClickValueChange.emit(event);
  }

  onStarValueChange(event): void {
    const payload = {
      jobs: [event.id],
      star: event.is_starred
    };
    this.onStarValueChangeEmit.emit(payload);
  }
}
