import { Component, EventEmitter, Input, OnInit, Output, forwardRef, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { NgClass } from '@angular/common';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';



@Component({
    selector: 'fyxt-priority-dropdown',
    templateUrl: './priority-dropdown.component.html',
    styleUrls: ['./priority-dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => PriorityDropdownComponent)
        }
    ],
    standalone: true,
    imports: [NgSelectModule, FormsModule, NgxIntlTelInputModule, NgClass]
})
export class PriorityDropdownComponent implements OnInit {
  @ViewChildren("dropdown") dropdown: QueryList<NgSelectComponent>;
  
  @Input() priorities:any[]=[];
  @Input() inputControl!:any;
  @Input() customClass: string = '';
  @Output() onValueChange = new EventEmitter<Event>();

  public changed!: (value: string) => void;
  public touched!: () => void;

  constructor() { }

  ngOnInit(): void {
  }

  onChange = (data:any) => {}
  onTouch = (_:any) => {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: string) {
    this.inputControl = value;
    this.onChange(value);
  }
  
  /**
  *Func triggered on input change 
  */
  onchange(event:any){
    this.onChange(event);
    this.onValueChange.emit(event);
  }

  onScroll = (event: any):void => {
    const array = this.dropdown.toArray()
    let index = array.findIndex(x => x.isOpen === true);
    if (array && array[index]?.isOpen) {
      const isScrollingInScrollHost =(event.target.className as string).indexOf('ng-dropdown-panel-items')>-1;
      if (isScrollingInScrollHost) {
        return;
      }
      array[index]?.close();
    }
  }

}
