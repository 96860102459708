import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { TableService } from 'src/app/@fyxt/_services/table/table.service';
import { AssignJobEquipmentComponent, ChecklistTemplateComponent } from 'src/app/@fyxt/_shared/_views';
import { ColumnSortType, TableColumnTypes } from 'src/app/@fyxt/_shared/enums/table/table-column-types.enum';
import { AppTableSource } from 'src/app/@fyxt/_shared/models/app-table-model';
import { GraphqlEquipmentManagementService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/equipment-management/graphql-equipment-management.service';
import { plannerService } from 'src/app/Modules/_fyxt_modules/service-planner/services/planner-service';
import { BaseService } from 'src/app/services/base.service';
import { UtilityService } from 'src/app/services/utility.service';
import { JobsService } from 'src/app/services/v2/jobs.service';
import { environment } from 'src/environments/environment';
import { NoDataFountComponent } from '../../../_reusable_ui/_components/no-data-fount/no-data-fount.component';
import { TableComponent } from '../../../_reusable_ui/_components/table/table.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { NgIf, NgFor } from '@angular/common';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';

@Component({
    selector: 'fyxt-tab-equipment-planner',
    templateUrl: './tab-equipment-planner.component.html',
    styleUrls: ['./tab-equipment-planner.component.scss'],
    standalone: true,
    imports: [SearchInputComponent, NgIf, ButtonComponent, MatMenuModule, MatIconModule, NgFor, MatDividerModule, LoaderSmallComponent, TableComponent, NoDataFountComponent]
})
export class TabEquipmentPlannerComponent implements OnInit {



  @ViewChild('archiveEquipment')
  archiveEquipment!: TemplateRef<any>;

  @Input() isLoader: boolean;
  @Input() id: any;
  @Input() checkListTemplateCount: any;
  @Input() selectedPlannerData: any;

  tableHeaders = [
    {
      value: 'checkbox',
      sort: ColumnSortType.none,
      name: '',
      type: TableColumnTypes.checkbox,
      className: 'table-checkbox'
    },
    {
      value: 'name',
      sort: ColumnSortType.none,
      name: 'Equipment',
      type: TableColumnTypes.textWithImage,
      className: 'table-header'
    },
    {
      value: 'equipment_type_name',
      sort: ColumnSortType.none,
      name: 'Equipment Type',
      type: TableColumnTypes.text,
      className: 'table-header',
      resizeColumn: true,
    },
    {
      value: 'serial_number',
      sort: ColumnSortType.none,
      name: 'Serial Number',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'asset_tag',
      sort: ColumnSortType.none,
      name: 'Asset Tag',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'specific_location',
      sort: ColumnSortType.none,
      name: 'Specific Location',
      type: TableColumnTypes.text,
      className: 'table-header',
      resizeColumn: true,
    },
    {
      value: 'checklistNames',
      sort: ColumnSortType.none,
      name: 'Assigned Checklists',
      type: TableColumnTypes.text,
      className: 'table-header',
      resizeColumn: true,
    },
    {
      value: 'action',
      sort: ColumnSortType.none,
      name: '',
      type: TableColumnTypes.action,
      className: 'three-dot',
      actions: [{ label: 'Assign Checklist', value: 'Assign Checklist' }, { label: 'Remove', value: 'Remove' }]
    }
  ];

  listEquipments: any[] = [];
  searchInput: string = "";
  pageSize: number = 25;
  page: number = 1;
  sortColumn: string = null;
  moduleName = "dashboard";
  sortDirection = '';
  selectedViewTableRowsTotal: number;
  checkedEquipments: any[] = [];

  bulkActionList: any[] = [
    { label: 'Assign Checklist', value: 'Assign Checklist' },
    { label: 'Remove', value: 'Remove' }
  ]

  dataSource!: AppTableSource;
  checklists: any[] = [];

  constructor(
    public _dialogService: DialogService,
    public _baseService: BaseService,
    public _graphql: GraphqlEquipmentManagementService,
    public tableService: TableService,
    public _utilService: UtilityService,
    public plannerService: plannerService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.plannerService.fetchPlannerEquipmentUpdate().subscribe((state) => {
      if (state) {
        const id = this.activatedRoute.snapshot.paramMap.get('id');
        this.plannerService.getSelectedPlannerDetails(id).subscribe({
          next: (res: any) => {
            this.plannerService.plannerEquipments = res.equipment;
            this.getAllChecklists();
          }
        });
        this.plannerService.plannerEquipmentsUpdate.next(false);
      }
    });
  }

  ngOnInit(): void {
    this.getAllChecklists()

  }

  getAllChecklists() {
    const id = this.router.url.split('/').pop();
    this.isLoader = true;
    let requestURL = environment.baseURL + `planner/${id}/planner-equipment-checklist/`;
    this._baseService.doGET(requestURL).subscribe({
      next: (res: any) => {
        this.checklists = res.data
      },
      error: () => {
        this.getAllEquipments(this.searchInput, this.page, this.pageSize, this.sortColumn, this.sortDirection);
      },
      complete: () => {
        this.getAllEquipments(this.searchInput, this.page, this.pageSize, this.sortColumn, this.sortDirection);
      }
    })

  }

  getAllEquipments(searchInput: string, pageNumber: number, pageSize: number, sortColumn?: any, sortDirection?: any, filters?: any) {
    this.isLoader = true;
    let requestURL: any;
    if (this.plannerService.plannerEquipments.length > 0) {
      requestURL = environment.equipURL + `/jobs/equipment/?query={'id': [${this.plannerService.plannerEquipments.map(e => `'${e}'`)}]}&search=${searchInput}&columns=['name', 'image', 'id', 'equipment_type_name', 'specific_location', 'serial_number', 'asset_tag']&sort_column=${sortColumn ? sortColumn : 'name'}&sort_order=${sortDirection ? sortDirection : 'asc'}&page=${pageNumber}&size=${pageSize}`;
      this._baseService.doGET(requestURL).subscribe({
        next: (res: any) => {
          this.listEquipments = res?.items.map((equipment) => {
            const matchingChecklist = this.checklists.find((checklist) => checklist.equipment === equipment.id);
            if (matchingChecklist) {
              return { ...equipment, checklistNames: matchingChecklist.checklist.map((item) => item.checklist_template__name).join(", ") };
            } else {
              return equipment;
            }
          }) || [];
          this.selectedViewTableRowsTotal = res.total;
        },
        error: (error) => {
          this.isLoader = false;
        },
        complete: () => {
          if (sortColumn || sortDirection) {
            let sortedHeaders: any[] = this.tableHeaders.map(element => {
              (element.value === sortColumn) ? (element.sort = sortDirection) : (element.sort = ColumnSortType.none);

              return element;
            });

            this.dataSource = this.tableService.setTableData(this.moduleName, sortedHeaders, this.listEquipments, this.selectedViewTableRowsTotal, pageNumber - 1, pageSize);
          }
          else {

            this.dataSource = this.tableService.setTableData(this.moduleName, this.tableHeaders, this.listEquipments, this.selectedViewTableRowsTotal, pageNumber - 1, pageSize);
          }
          this.isLoader = false;
        }
      })
    }


  }

  bulkActionPopup(e: any) {
    if (e.label === 'Remove') {
      let equipmentIds: any[] = this.checkedEquipments.map(e => `${e.id}`);
      // const headers = new HttpHeaders({
      //   'Content-Type': 'application/json'
      // });

      let ids = { equipment: equipmentIds };
      const id = this.router.url.split('/').pop();
      let requestURL = environment.baseURL + 'planner/' + id + '/remove-equipment/';

      this._baseService.doDelete(requestURL, { body: ids }).subscribe({
        next: (res: any) => {
          this.checkedEquipments = [];
          this._utilService.showSuccess('', 'Equipments Removed Successfully');
          this.plannerService.plannerEquipmentsUpdate.next(true);
        },
        error: (res: HttpErrorResponse) => {
          if (res.status === 400) {
            let popupData: any;
            popupData = {
              title: 'Delete Item',
              template: this.archiveEquipment,
              buttons: false
            };
            this._dialogService.confirmDialog(popupData).subscribe(result => {
              if (result) {}
            });
          } else {
            this._utilService.showError('', res)
          }
        }
      })

    } else {
      this.applyCheckList(this.checkedEquipments);
    }
  }

  threeDotMenuChange(e: any) {
    if (e.label === 'Remove') {
      this.removeEquipments([e]);
    }
    else {
      this.applyCheckList([e])
    }
  }


  applyCheckList(e?: any) {
    const selectEquipmentId = e.map((eqp: any) => {
      return eqp.id;
    });
    let popupData = {
      title: `Checklist Templates (` + this.checkListTemplateCount + `)`,
      component: ChecklistTemplateComponent,
      // containerClass: 'modal-lg',
      containerClass: ['modal-lg', 'modal_checklist_height'],
      data: { selectedPlannerDetails: this.selectedPlannerData, selectedEquipments: selectEquipmentId, assignedFromServicePlanner: true, asPerNewDesign: true }
    };
    let equipmentIds: any[] = this.checkedEquipments.map(e => `${e.id}`);
    this._dialogService.openModal(popupData).subscribe(result => {
      if(result){
      const id = this.activatedRoute.snapshot.paramMap.get('id');
      let requestURL = environment.baseURL + `planner/${id}/planner-checklist-equipment/`;
      let payload: any = { checklist_id: result.id, equipment: equipmentIds.length > 0 ? equipmentIds : selectEquipmentId };
      this._baseService.doPatch(requestURL, payload).subscribe({
        next: (res: any) => {
          this.getAllChecklists();
          this.checkedEquipments = [];
        }
      })
    }
    });

  }




  removeEquipments(e: any) {
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json'
    // });
    if (Array.isArray(e) && e) {
      let ids = { equipment: [e[0].id] };
      const id = this.router.url.split('/').pop();
      let requestURL = environment.baseURL + 'planner/' + id + '/remove-equipment/';

      this._baseService.doDelete(requestURL, { body: ids }).subscribe({
        next: (res: any) => {
          this.plannerService.plannerEquipmentsUpdate.next(true);
          this._utilService.showSuccess('', 'Equipment Removed Successfully');
        },
        error: (res: HttpErrorResponse) => {
          let popupData: any;
          popupData = {
            title: 'Delete Item',
            template: this.archiveEquipment,
            buttons: false
          };
          this._dialogService.confirmDialog(popupData).subscribe(result => {
            if (result) {}
          });
        }
      });
    }


  }

  /**
* Single TableRow Selection
* @param UniqueId
*/
  onCheckRow(equipment: any) {
    let updatedcheckedEquipments: number[];
    const isequipmentChecked = this.checkedEquipments.some((chekcedEquipment) => chekcedEquipment.id === equipment.id);

    if (isequipmentChecked) {
      updatedcheckedEquipments = this.checkedEquipments.filter((chekcedEquipment) => chekcedEquipment.id !== equipment.id);
    } else {
      updatedcheckedEquipments = [...this.checkedEquipments, equipment];
    }

    this.checkedEquipments = updatedcheckedEquipments;
  }

  /**
* All TableRow Selection
* @param SelectValue
*/
  isAllSelected(isSelected: any) {
    if (isSelected) {
      this.checkedEquipments = this.listEquipments;
    } else {
      this.checkedEquipments = [];
    }
  };


  openAssignJobEquipment() {
    if (this.id) {
      let popupData = {
        title: 'Assign Equipment',
        component: AssignJobEquipmentComponent,
        containerClass: 'invite_users',
        data: {
          companyModal: true,
          propertyId: this.id,
          equipments: this.plannerService.plannerEquipments
        }
      };
      this._dialogService.openModal(popupData).subscribe(result => {});
    } else {
      this._utilService.showError('', 'A property must be selected before assigning equipment');
    }
  }

  onSearch(inputValue: any) {
    this.searchInput = inputValue;
    if (inputValue.length >= 2 || inputValue.length === 0) {
      this.getAllChecklists()
    }
    if (this.checkedEquipments.length) {
      this.checkedEquipments = []
    }
  }

  onPageChange(event: any) {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getAllChecklists();
  }

  onSortChange(e: any) {
    this.sortColumn = e.active;
    this.sortDirection = e.direction;
    if (e.active == 'checklistNames') {
      this.isLoader = true;
      this.manualChecklistSorting();
    } else {
      this.getAllChecklists();
    }
    if (this.checkedEquipments.length) {
      this.checkedEquipments = []
    }
  }

  manualChecklistSorting(): void {
    let sortedHeaders = this.tableHeaders.map((element: any) => {
      (element.value === this.sortColumn) ? (element.sort = this.sortDirection) : (element.sort = ColumnSortType.none);
      return element;
     });
    if (this.sortDirection == 'asc') {
      this.listEquipments.sort((a, b) => (a.checklistNames.toLowerCase() > b.checklistNames.toLowerCase()) ? 1 : -1)
    } else {
      this.listEquipments.sort((a, b) => (b.checklistNames.toLowerCase() > a.checklistNames.toLowerCase()) ? 1 : -1)
    }
    this.dataSource = this.tableService.setTableData(this.moduleName, sortedHeaders, this.listEquipments, this.selectedViewTableRowsTotal, 0, this.pageSize);
    setTimeout(() => {
      this.isLoader = false;
    }, 1000);
  }

  onRowClick(event: any) {
    const currentUserInfo: any = localStorage.getItem('userobj')?JSON.parse(localStorage.getItem('userobj')): {};
    if(!currentUserInfo.permissions.includes('view_equipment')){
      return this._utilService.showError('', 'You don\'t have permission to view this equipment')
    }
    if(currentUserInfo.current_Role==="Owner" || currentUserInfo.current_Role==="Manager"){
    this.router.navigate([`/pm/equipment/view/${event.id}`]);
    }
    if(currentUserInfo.current_Role==="Engineer"){
    this.router.navigate([`/engineer/equipment/view/${event.id}`]);
    }
  }

}
