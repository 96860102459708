import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import RealtimeEvents from 'src/app/services/events/realtime-events.service';

@Injectable({
  providedIn: 'root'
})
export class PusherService {

  constructor(private realtimeEvents: RealtimeEvents) { }

  /*************** Realtime Update with Pusher Update*******************/


  /** Job Status Update **/
  getJobStatusEvent(account_id) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_STATUS')
      .pipe(map((event: any) => event));
  }

  /** Job Engineer Update **/
  getJobEngineerEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_ENGINEERS')
      .pipe(map((event: any) => event));
  }

  /** Job Assignee Update **/
  getJobAssigneeEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_PM_ASSIGNEE')
      .pipe(map((event: any) => event));
  }


  /** Job Follower Update **/
  getJobFollowerEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_FOLLOWERS')
      .pipe(map((event: any) => event));
  }

  /** Job AssociatedMail Update **/
  getJobAssociatedMailEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_ASSOCIATED_MAILS')
      .pipe(map((event: any) => event));
  }

  /** Job Schedule Count Update **/
  getJobScheduleCountEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_SCHEDULE_COUNT')
      .pipe(map((event: any) => event));
  }

  /** Job Schedule Count Update **/
  getJobSiteVisitCountEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_SITEVISIT_COUNT')
      .pipe(map((event: any) => event));
  }

  /** Job Job Cost Count Update **/
  getJobJobCostCountEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_COST_COUNT')
      .pipe(map((event: any) => event));
  }

  /** Job Timesheet Update **/
  getJobTimesheetEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_TIMESHEET_COUNT')
      .pipe(map((event: any) => event));
  }

  /** Job Material Update **/
  getJobMaterialCountEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_MATERIALS_COUNT')
      .pipe(map((event: any) => event));
  }

  /** Job Checklist count Update **/
  getJobChecklistCountEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_CHECKLIST_COUNT')
      .pipe(map((event: any) => event));
  }


  /** Job Liked Job Update **/
  getJobLinkedJobEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'LINKED_JOBS_COUNT')
      .pipe(map((event: any) => event));
  }

  /** Job Priority Update **/
  getJobPriorityEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_PRIORITY')
      .pipe(map((event: any) => event));
  }

  /** Job Target Date Update **/
  getJobTargetDateEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_TARGET_DATE')
      .pipe(map((event: any) => event));
  }

  /** Job Issue Type Update **/
  getJobIssueTypeEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_ISSUE_TYPE')
      .pipe(map((event: any) => event));
  }

  /** Job Category Update **/
  getJobCategoryEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_CATEGORY')
      .pipe(map((event: any) => event));
  }

  /** Job Service Type Update **/
  getJobServiceTypeEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_SERVICE_TYPE')
      .pipe(map((event: any) => event));
  }

  /** Job Description Update **/
  getJobDescriptionEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_DESCRIPTION')
      .pipe(map((event: any) => event));
  }

  /** Job Unit Update **/
  getJobUnitEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_UNIT')
      .pipe(map((event: any) => event));
  }

  /** Job JOB_ACCESS_INSTRUCTION Update **/
  getJobJOB_ACCESS_INSTRUCTIONEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_ACCESS_INSTRUCTION')
      .pipe(map((event: any) => event));
  }

  /** Job JOB_VENDORS Update **/
  getJOB_VENDORSEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_VENDORS')
      .pipe(map((event: any) => event));
  }


  /** Job JOB_TENANT Update **/
  getJOB_TENANTEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_TENANT')
      .pipe(map((event: any) => event));
  }

  /** Job JOB_NOTES Update **/
  getJOB_NOTESEvent(user_id: any, account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + user_id + '_' + account_id, 'JOB_NOTES')
      .pipe(map((event: any) => event));
  }

  /** Job JOB_LAST_ACTIVITY Update **/
  getJOB_LAST_ACTIVITYEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_LAST_ACTIVITY')
      .pipe(map((event: any) => event));
  }

  /** Job recent Job Update **/
  getRecentJobsEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'RECENT_JOBS')
      .pipe(map((event: any) => event));
  }


  /** Job Feedback Update **/
  getJobFeedbackEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_FEEDBACK')
      .pipe(map((event: any) => event));
  }

  /** Job JOB_TENANT_CONTACT Update **/
  getJOB_TENANT_CONTACTEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_TENANT_CONTACT')
      .pipe(map((event: any) => event));
  }


  /** Job DASHBOARD_VIEW_COUNT Update **/
  getDASHBOARD_VIEW_COUNTEvent(user_id: any, account_id: string) {
    return this.realtimeEvents
      .getEvents('DASHBOARD_' + user_id + '_' + account_id, 'DASHBOARD_VIEW_COUNT');
    // .pipe(map((event: any) => event));
  }

  /** Job DASHBOARD_JOB_ROW_UPDATE Update **/
  getDASHBOARD_JOB_ROW_UPDATEEvent(user_id: any, account_id: string) {
    return this.realtimeEvents
      .getEvents('DASHBOARD_ROW_' + user_id + '_' + account_id, 'DASHBOARD_JOB_ROW_UPDATE');
    // .pipe(map((event: any) => event));
  }

  /** Job DASHBOARD_JOB_ROW_REMOVE Update **/
  getDASHBOARD_JOB_ROW_REMOVEEvent(user_id: any, account_id: string) {
    return this.realtimeEvents
      .getEvents('DASHBOARD_ROW_REMOVE_' + user_id + '_' + account_id, 'DASHBOARD_JOB_ROW_REMOVE');
    // .pipe(map((event: any) => event));
  }

  /** Job DASHBOARD_DRAFT_JOB_ROW_REMOVE Update **/
  getDASHBOARD_DRAFT_JOB_ROW_UPDATEEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('DRAFT_JOB_ROW_UPDATE_' + account_id, 'DRAFT_JOB_ROW_UPDATE');
    // .pipe(map((event: any) => event));
  }

  /** Job DASHBOARD_DRAFT_JOB_ROW_REMOVE Update **/
  getDASHBOARD_DRAFT_JOB_ROW_REMOVEEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('DRAFT_JOB_ROW_REMOVE_' + account_id, 'DRAFT_JOB_ROW_REMOVE');
    // .pipe(map((event: any) => event));
  }

  /** Job DASHBOARD_SEEN_UNSEEN Update **/
  getDASHBOARD_SEEN_UNSEEN_Event(userId: string, account_id: string) {
    return this.realtimeEvents
      .getEvents('USER_JOB_SEEN_' + userId + '_' + account_id, 'USER_JOB_SEEN');
    // .pipe(map((event: any) => event));
  }

  /** Job BULK_ACTION_COMPLETED Update **/
  getBULK_ACTION_COMPLETED_Event(userId: string, account_id: string) {
    return this.realtimeEvents
      .getEvents('BULK_ACTION_COMPLETED' + userId + '_' + account_id, 'BULK_ACTION_COMPLETED');
    // .pipe(map((event: any) => event));
  }

  /** Job DASHBOARD_ROW_DELETE Update **/
  getDASHBOARD_ROW_DELETE__Event(userId: string, account_id: string) {
    return this.realtimeEvents
      .getEvents('DASHBOARD_ROW_DELETE_' + account_id, 'DASHBOARD_JOB_ROW_DELETE');
    // .pipe(map((event: any) => event));
  }

  /************* Notification *************/

  /** Notification Count **/
  getNotificationCountEvent(user_id: any, account_id: string) {
    return this.realtimeEvents
      .getEvents('BELL_' + user_id + '_' + account_id, 'NOTIFICATION')
      .pipe(map((event: any) => event));
  }



  /************* Workorder Tabs Related Code *************/

  /** Job Schedule Count Update **/
  getJobTabScheduleCountEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_SCHEDULE_COUNT')
      .pipe(map((event: any) => event));
  }

  /** Job Schedule Count Update **/
  getJobTabSiteVisitCountEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_SITEVISIT_COUNT')
      .pipe(map((event: any) => event));
  }

  /** Job Timesheet Update **/
  getJobTabTimesheetEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_TIMESHEET_COUNT')
      .pipe(map((event: any) => event));
  }

  /** Job Material Update **/
  getJobTabMaterialCountEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_MATERIALS_COUNT')
      .pipe(map((event: any) => event));
  }


  /** Job Job Cost Count Update **/
  getJobTabJobCostCountEvent(account_id: string) {
    return this.realtimeEvents
      .getEvents('JOB_' + account_id, 'JOB_COST_COUNT')
      .pipe(map((event: any) => event));
  }

  /** Service Planner set as active Update **/
  getServicePlannerSetAsActiveEvent(account_id: string) {
    return this.realtimeEvents.getEvents('BULK_ACTION_ACTIVE_PLANNERS_' + account_id, 'BULK_ACTION_ACTIVE_PLANNERS');
    // .pipe(map((event: any) => event));
  }

  getServicePlannerDeleteEvent(account_id: string) {
    return this.realtimeEvents.getEvents('BULK_ACTION_DELETE_PLANNERS_' + account_id, 'BULK_ACTION_DELETE_PLANNERS');
    // .pipe(map((event: any) => event));
  }
}
