import {EventEmitter, Injectable, Output} from '@angular/core';
import {BehaviorSubject, forkJoin, Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class plannerService {

  isLoader: boolean = false;
  _viewModule: string = "service-planner";
  selectedActiveTabId:string;
  plannerEquipments:any;

  public isPlannerNameUpdate = new BehaviorSubject<any>(null);
  public plannerTabCountUpdate = new BehaviorSubject<boolean>(false);
  public plannerPropertyChange = new BehaviorSubject<boolean>(false);
  public plannerJobsUpdate = new BehaviorSubject<boolean>(false);
  public plannerUpcomingJobsUpdate = new BehaviorSubject<boolean>(false);
  public plannerEquipmentsUpdate = new BehaviorSubject<boolean>(false);
  public plannerContactsUpdate = new BehaviorSubject<boolean>(false);
  @Output() refreshPlanner: EventEmitter<any> = new EventEmitter();
  statusError = new Subject<any>();

  public plannerInfoUpdate = new BehaviorSubject<any>({});
  setPreviousTab:any={};
  selectedActiveTab:any = {};

  selectedPlannerData={};


  constructor(private httpClient: HttpClient) { }


  getAllCheckList(id?:any){
    if(id){
      let listAllCheckList = this.httpClient.get(`${environment.baseURL}` + 'properties/' + id +'/checklists/?source=dropdown');
      return forkJoin([listAllCheckList]);
    }
    else{
      let listAllCheckList = this.httpClient.get(`${environment.baseURL}` + 'checklists/dropdown/');
      return forkJoin([listAllCheckList]);
    }
  }

  getSelectedPlannerDetails(id:any){
      let listAllPlanner = this.httpClient.get(`${environment.baseURL}`+ `planner/${id}/`);
      return listAllPlanner;
    }


  getSelectedTableData1(selectedViewTableRows: string) {
    return this.httpClient.get(selectedViewTableRows);
  }

  getServicePlannerDetailedPageTabsCount(id: string) {
    return this.httpClient.get(`${environment.baseURL}planner/${id}/`);
  }


  getPlannerJobTableDataRows(plannerId: any, searchVal?: string, sortPayload?: any, paginationIndex?: any,perPage?:any) {
    const offset = (paginationIndex -1)*perPage
    let plannerJobTableRowsUrl =`${environment.baseURL}planner/`+ plannerId +`/current-job-occurrence`+'/?limit=' + perPage + '&offset=' + offset ;
    if (searchVal) {
      plannerJobTableRowsUrl+= `&search=${searchVal}`;
    }
    if (sortPayload?.active && sortPayload?.direction) {
      if (sortPayload.direction == 'asc') {
        plannerJobTableRowsUrl += `&ordering=${sortPayload.active}`;
      } else if (sortPayload.direction == 'desc') {
        plannerJobTableRowsUrl+= `&ordering=-${sortPayload.active}`;
      }
    }
    return this.httpClient.get(plannerJobTableRowsUrl);
  }

  getContactTableDataRows(plannerId: any, searchVal?: string, sortPayload?: any, paginationIndex?: any,perPage?:any) {
    const offset = (paginationIndex -1)*perPage
    let plannerJobTableRowsUrl =`${environment.baseURL}planner/`+ plannerId +`/planner-contacts`+'/?limit=' + perPage + '&offset=' + offset ;
    if (searchVal) {
      plannerJobTableRowsUrl+= `&search=${searchVal}`;
    }
    if (sortPayload?.active && sortPayload?.direction) {
      if (sortPayload.direction == 'asc') {
        plannerJobTableRowsUrl += `&ordering=${sortPayload.active}`;
      } else if (sortPayload.direction == 'desc') {
        plannerJobTableRowsUrl+= `&ordering=-${sortPayload.active}`;
      }
    }
    return this.httpClient.get(plannerJobTableRowsUrl);
  }

  fetchPlannerTabCountUpdate(): Observable<boolean> {
    return this.plannerTabCountUpdate.asObservable();
  }

  fetchPlannerPropertyUpdate(): Observable<boolean> {
    return this.plannerPropertyChange.asObservable();
  }

  fetchPlannerUpcomingJobUpdate(): Observable<boolean> {
    return this.plannerUpcomingJobsUpdate.asObservable();
  }

  fetchPlannerJobsUpdate(): Observable<boolean> {
    return this.plannerJobsUpdate.asObservable();
  }

  fetchPlannerEquipmentUpdate(): Observable<boolean> {
    return this.plannerEquipmentsUpdate.asObservable();
  }

  fetchPlannerContactUpdate(): Observable<boolean> {
    return this.plannerContactsUpdate.asObservable();
  }

  // export(requestURL: string, postBody: any, { query, ordering }: any) {
  //   const url = `${environment.LocalDev_URL_V2}${requestURL}`;
  //   const params: any = {};
  //   if (query) {
  //     params.query = JSON.stringify(query);
  //   }
  //   if (ordering) params.ordering = ordering;
  //   return this.httpClient.post(url, postBody, { params });
  // }

  export(requestURL: string, postBody: any) {
    const url = `${environment.LocalDev_URL_V2}${requestURL}`;
    return this.httpClient.post(url, postBody);
  }

  updatePlannerBulkActions(postBody: any): Observable<any> {
    let requestURL = environment.LocalDev_URL_V2 + 'planner/bulk-actions/';
    return this.httpClient.post(requestURL, postBody);
  }

  getPlannerViews(): Observable<any> {
    let requestURL = environment.LocalDev_URL_V2 + 'manage-dashboard/views/?source=planner';
    return this.httpClient.get(requestURL);
  }

  getSelectedTableColumns(viewID: string) {
    let selectedViewTableHeaders = this.httpClient.get(`${environment.LocalDev_URL_V2}` + `manage-dashboard/column/?source=planner&viewid=` + viewID);
    let selectedViewAllTableColumns = this.httpClient.get(`${environment.LocalDev_URL_V2}` + `manage-dashboard/edit-column/?source=planner&viewid=` + viewID);
    return forkJoin([selectedViewTableHeaders, selectedViewAllTableColumns]);
  }
}
