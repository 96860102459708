import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, tap } from 'rxjs/operators';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from '../../../../../environments/environment';
import {
  ICategory,
  ICoi,
  ICreateCoi, IDuplicateCoi,
  IGetCOIsListParams,
  IGetCOIsListResponse, IGetVendorCOIAvailability, IGetVendorCOIsListParams,
  IUpdateCoi
} from '../../../../@fyxt/_shared/models/coi/coi.interface';
import { BehaviorSubject, Observable, of } from 'rxjs';
import moment from 'moment';
import {
  ICOISetting, ICOISettingUpdateData,
  ICoverage,
  ICoverageType, ICreateCoverage,
  IGetCoveragesListParams,
  IGetCoveragesListResponse, IUpdateCoverage,
} from '../../../../@fyxt/_shared/models/coi/coverage.interface';
import coiViewsListResult from '../pages/hardcoded-data/coi-views-response.json';
import * as http from "http";


@Injectable({
  providedIn: 'root'
})
export class CoiService {
  private readonly baseUrl = `${environment.coiMsURL}/coi`
  private readonly coverageUrl = `${environment.coiMsURL}/coi-coverage`
  private readonly settingsUrl = `${environment.coiMsURL}/coi-settings`
  selectedItem:any;

  constructor(
    private readonly http: HttpClient,
    private readonly apollo: Apollo,
    public _utilService:UtilityService,
    private router:Router,
    ) {

  }


  public readonly currentCoiSubject$: BehaviorSubject<ICoi> = new BehaviorSubject<ICoi>(null);
  public readonly currentCoi$: Observable<ICoi> = this.currentCoiSubject$.asObservable();
  public readonly addedCOISubject$: BehaviorSubject<ICoi> = new BehaviorSubject<ICoi>(null);
  public readonly addedCOI$: Observable<ICoi> = this.addedCOISubject$.asObservable();


  getAdditionalDataDetail(id:any){
    return gql `query MyQuery {
      getAdditionalItem(id: "${id}") {
        category
        id
        name
      }
    }`;
  }

  getupdateAdditionalDataDetail(id:any,value:string,category?:any){
    if(category){
      category = category.map(({__typename, ...rest}) => rest);
      category = JSON.stringify(category).replace(/"/g, '\\"');
    }
    return gql `mutation MyMutation {
        updateAdditionalItem(
          id: "${id}",
          input: {
              ${value ? `name: "${value}",` : ""}
              ${category ? `category: "${category}",` : ""}
              active: true
            }
        ) {
          id
          name
          category
        }
    }`
  }

  getAdditionalDataList(columns:any,isSearch:string,pagination:number,pageSize:number,sortColumn?:string,sortDirection?:string,filter?:any){
    return gql`query MyQuery {
      listAdditionalItems(query: {
        query: "{ ${ filter !== undefined && filter[0]?.length > 0 ? `'name':[${encodeURIComponent(filter[0].map((n:string)=> `'${n}'`))}],`:""}${ filter !== undefined && filter[1]?.length > 0 ? `'category_name':[${encodeURIComponent(filter[1].map((n:string)=> `'${n}'`))}],`:""}}",
        columns: "[${columns.map(e=>`'${e.value}'`)},'id']",
        page:  ${pagination},
        search: "${isSearch}",
        size:${pageSize},
        ${sortColumn ? `sort_column: "${sortColumn}",` : ""}
        ${sortDirection ? `sort_order: "${sortDirection}",` : ""}
      })
      {
        page
        size
        total
        items {
          category_name
          id
          name
        }
      }
    }
    `
  }

  addAditionalDataItem(formValue:any){
    if(formValue.category){
      formValue.categoryy = formValue.category.map(({__typename, ...rest}) => rest);
      formValue.category = JSON.stringify(formValue.category).replace(/"/g, '\\"');;
    }
    return gql`
    mutation MyMutation {
      addAdditionalItem (input: {
        name: "${formValue.itemName}",
        ${formValue.category?.length > 0 ? `category: "${formValue.category}"` : ''},
        active: true
      }) {
        category
        id
        name
      }
    }
    `;

  }

  categoriesList(){
    return gql `
    query MyQuery {
      listFyxtCategories {
        items {
          id
          name
        }
      }
    }`
  }

  deleteItemQuery(id:any){
    return gql`
    mutation MyMutation {
      updateAdditionalItem(
          id: "${id}",
          input: {
              active: false,
            }
        ) {
        message
      }
    }`;
  }


  associatedEquipTypeTabListing(id:any, isSearch:string, pagination:number, column?:string, sort?:string, pageSize?: any){
    return gql`
    query MyQuery {
      listAdditionalItemsEquipmentType(
          id: "${id}",
          query: {
              page: ${pagination},
              search:"${isSearch}",
              size: ${pageSize ? pageSize : 25},
              ${column ? `sort_column: "${column}",` : ""}
              ${sort ? `sort_order: "${sort}",` : ""}
            }) {
        items {
          id
          name
          manufacturer_name
        }
        page
        size
        total
      }
    }
    `
  }

  associatedEquipments(id:any,isSearch:string, pagination:number, column?:string, sort?:string, pageSize?: any){
    return gql`
    query MyQuery {
      listAdditionalItemsEquipment(
        id: "${id}",
        query: {
            page: ${pagination},
            search:"${isSearch}",
            size: ${pageSize ? pageSize : 25},
            ${column ? `sort_column: "${column}",` : ""}
            ${sort ? `sort_order: "${sort}",` : ""}
          }) {
        items {
          id
          name
          property_name
          specific_location
          asset_tag
          serial_number
        }
        page
        size
        total
      }
    }
    `
  }

  getFilters(){
    return gql`
    query MyQuery {
      listFilterAdditionalItems(query: {
        #search: ""
      }) {
      items {
        id
        name
      }
    }
      listFyxtCategories {
        items {
          id
          name
        }
      }
    }`
  }

  viewListQuery(){
    return gql `
    query MyQuery {
      listDashboardView(view_type: "additional_data_item") {
        items {
          label
          data {
            id
            name
            pin
            default
            count
            created_by
          }
        }
      }
    }
    `
  }

  viewsTabListing(){
    return gql`query MyQuery {
      my_views : listManageViews(query: {
          view_type: "additional_data_item",
          manage_tab: "my_views",
          #search: "",
          #page: 1,
          size: 50
        }) {
        items {
          id
          default
          pin
          shared
          name
          count
        }
        page
        size
        total
      }
      shared_by_others : listManageViews(query: {
          view_type: "additional_data_item",
          manage_tab: "shared_by_others",
          #search: "",
          #page: 1,
          size: 50
        }) {
        items {
          id
          default
          pin
          shared
          name
          count
        }
        page
        size
        total
      }
      standard : listManageViews(query: {
          view_type: "additional_data_item",
          manage_tab: "standard",
          #search: "",
          #page: 1,
          size: 50
        }) {
        items {
          id
          default
          pin
          shared
          name
          count
        }
        page
        size
        total
      }
    }
   `;
  }


  additionalDataList(columns:any,isSearch?:string, pagination?:number,pageSize?:number,sortColumn?:string,sortDirection?:string,filters?:any) {
    return this.apollo.query({ query: this.getAdditionalDataList(columns,isSearch,pagination,pageSize,sortColumn,sortDirection,filters)}).pipe(map(res=>res.data));
  }

  filtersList() {
    return this.apollo.query({ query: this.getFilters()}).pipe(map(res=>res.data));
  }

  createAdditionalDataItem(formValue:any){
    this.apollo.mutate({
      mutation:this.addAditionalDataItem(formValue),
    }).pipe(map(res=>res.data)).subscribe({
      next:(res:any)=>{
        this._utilService.showSuccess('', 'Additional Data Item Created Successfully');
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          const currentUserInfo: any = localStorage.getItem('userobj')?JSON.parse(localStorage.getItem('userobj')): {};
          if(currentUserInfo.current_Role==="Owner" || currentUserInfo.current_Role==="Manager"){
            this.router.navigate([`/pm/equipment/additionalData/${res.addAdditionalItem!.id}`])
          }
          if(currentUserInfo.current_Role==="Engineer"){
            this.router.navigate([`/engineer/equipment/additionalData/${res.addAdditionalItem!.id}`])
          }
      },
      error:(err:any) => {
        const errorMessage = err?.graphQLErrors?.[0]?.message?.split('=')?.[1]?.replace(/[{}]/g, '').trim() ?? err.message;
        this._utilService.showError('', errorMessage);
       },
    })
  }

  additionalDataDetail(id:any) {
    return this.apollo.query({ query:this.getAdditionalDataDetail(id) }).pipe(map(res=>res.data));
  }

  updateAdditionalDataDetail(id:any,name:string,category?:any){
    return this.apollo.mutate({
      mutation:this.getupdateAdditionalDataDetail(id,name,category),
    }).pipe(map(res=>res.data));
  }

  deleteItem(id:any){
    return this.apollo.mutate({
      mutation:this.deleteItemQuery(id),
    }).pipe(map(res=>res.data));
  }

  associatedEquipmentTypeListing(id:any, isSearch:string, pagination:number, column?:string, sort?:string, pageSize?: any){
    return this.apollo.query({ query: this.associatedEquipTypeTabListing(id, isSearch, pagination, column, sort, pageSize)}).pipe(map(res=>res.data));
  }

  associatedEquipmentsList(id:any,isSearch?:string, pagination?:number, column?:string, sort?:string, pageSize?: any){
    return this.apollo.query({ query: this.associatedEquipments(id, isSearch, pagination, column, sort, pageSize)}).pipe(map(res=>res.data));
  }

  categoryDropdown(){
    return this.apollo.query({ query: this.categoriesList()}).pipe(map(res=>res.data));
  }

  viewList(){
    return this.apollo.query({ query:this.viewListQuery()}).pipe(map(res=>res.data));
  }

  viewsListWithoutSelectingTabDetails(){
    return this.apollo.query({ query: this.viewsTabListing()}).pipe(map(res=>res.data));
  }

  loadFilterspageQueries(search:string,name:string,page?:number){
    return this.apollo.query({ query:this.loadFilterspageQuery(search,name,page) }).pipe(map(res=>res.data));
  }

  loadFilterspageQuery(search:string,name:string,page?:number) {
    return gql `
    query MyQuery {
      ${name}(query: {
          page:${page?page:1}
          #size: 10,
          search: "${search}"
        }) {
        page
        size
        total
        items {
          id
          name
        }
      }
    }`
  }

  //Helpers
  public getDateIgnoringTimezone(date: number | string | Date): string {
    if (!date) return null;
    return new Date(moment.utc(new Date(date)).add('minutes', moment().utcOffset()).unix() * 1000).toISOString();
  }

  public getUTCData(date: number | string | Date): string {
    if (!date) return null;
    return moment.utc(new Date(date)).add(new Date().getTimezoneOffset(), 'm').toISOString();
  }


  // COI
  public getCompanies(page: number, search?: string) {
    const url = `${environment.fastAPI_Actual_URL}/companies/`;
    const params = {
      columns: `['id', 'name', 'type']`,
      // query: `{ type: ['Tenant']}`,
      page: page,
      search: search,
    };
    return this.http.get(url, { params: params });
  }

  public getCompaniesInsurance(page: number, search?: string) {
    const url = `${environment.fastAPI_Actual_URL}/companies/`;
    const params = {
      columns: `['id', 'name', 'type']`,
      // columns: `['id', 'name', 'type', 'addresses', 'phones', 'emails']`,
      query: `{ 'type': ['Insurance']}`,
      page: page,
      search: search,
    };
    return this.http.get(url, { params: params });
  }

  public getCompanyById(id: string) {
    const url = `${environment.fastAPI_Actual_URL}/companies/${id}/`;
    const params = {
    };
    return this.http.get(url, { params: params });
  }

  public getProperties(page: number, search?: string) {
    const url = `${environment.baseURL}properties/assign-property-dropdown/`;
    const size = 50;
    const offset = (page - 1) * size; // size
    const params = {
      page: page,
      limit: size,
      offset: offset,
      'custom-search': search,
    };
    return this.http.get(url, { params: params });
  }

  public getCategories(search?: string) {
    const url = `${environment.coreMsURL}/category`;
    const params = {
      search: search,
    };
    return this.http.get<{ data: ICategory[] }>(url, { params: params })
      .pipe(map((res) => res.data));
  }

  public createCOI(payload: ICreateCoi): Observable<ICoi> {
    const url = this.baseUrl;
    const data = {
      ...payload,
      effective_date: this.getDateIgnoringTimezone(payload.effective_date),
      expiration_date: this.getDateIgnoringTimezone(payload.expiration_date),
    };
    return this.http.post<{ data: ICoi }>(url, data).pipe(map(res => res.data));
  }

  public duplicateCOI(payload: IDuplicateCoi) {
    const url = `${this.baseUrl}/duplicate`;
    const data = {
      ...payload,
      effective_date: this.getDateIgnoringTimezone(payload.effective_date),
      expiration_date: this.getDateIgnoringTimezone(payload.expiration_date),
    };
    return this.http.post<{ data: ICoi }>(url, data)
      .pipe(map(res => res.data));
  }

  public getCOIsList(pagination: IGetCOIsListParams): Observable<IGetCOIsListResponse> {
    const url = `${this.baseUrl}/all`;
    const params = { ...pagination };

    return this.http.post<{ data: IGetCOIsListResponse }>(url, params).pipe(map((res) => res.data));
  }

  public getVendorCOIsList(params: IGetVendorCOIsListParams): Observable<IGetCOIsListResponse> {
    const url = `${this.baseUrl}/vendors`;
    return this.http.post<{ data: IGetCOIsListResponse }>(url, params).pipe(map((res) => res.data));
  }

  public getVendorCOIsAvailability(vendor_id: string, property_id: string, category_id: string): Observable<IGetVendorCOIAvailability> {
    const url = `${this.baseUrl}/check-missed-for-vendor/vendor/${vendor_id}/property/${property_id}/category/${category_id}`;
    return this.http.get<{ data: IGetVendorCOIAvailability }>(url).pipe(map((res) => res.data));

  }

  public getCOIsListTab(pagination: IGetCOIsListParams): Observable<IGetCOIsListResponse> {
    const url = `${this.baseUrl}/tabs`;
    const params = { ...pagination };

    return this.http.post<{ data: IGetCOIsListResponse }>(url, params).pipe(map((res) => res.data));
  }

  public getPropertyCOIsListTab(pagination: IGetCOIsListParams): Observable<IGetCOIsListResponse> {
    const url = `${this.baseUrl}/all/property`;
    const params = { ...pagination };

    return this.http.post<{ data: IGetCOIsListResponse }>(url, params).pipe(map((res) => res.data));
  }

  public getById(id: string) {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<{ data: ICoi }>(url).pipe(
      map((res) => res.data),
      tap((coi: ICoi) => this.currentCoiSubject$.next(coi))
    );
  }

  public updateCOI(id: string, payload: IUpdateCoi) {
    const url = `${this.baseUrl}/${id}`;
    if (payload.effective_date) {
      payload.effective_date = this.getDateIgnoringTimezone(payload.effective_date);
    }
    if (payload.expiration_date) {
      payload.expiration_date = this.getDateIgnoringTimezone(payload.expiration_date);
    }
    return this.http.put<{ data: ICoi }>(url, payload).pipe(
      map((res) => res.data),
      tap((coi: ICoi) => this.currentCoiSubject$.next(coi))
    );
  }

  public getCoveragesList(coi_id: string, params: IGetCoveragesListParams): Observable<IGetCoveragesListResponse> {
    const url = `${this.coverageUrl}/coi/${coi_id}`;
    return this.http.get<{ data: IGetCoveragesListResponse }>(url, { params: { ...params } })
      .pipe(map((res) => res.data));
  }


  deleteBulk(ids: string[]): Observable<string[]> {
    const url = `${this.baseUrl}/bulk/delete`;
    const payload = {
      id: ids,
    }
    return this.http.patch<{ data: string[] }>(url, payload).pipe(
      map((res) => res.data),
    );
  }

  delete(id: string): Observable<string> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.delete<{ data: string }>(url).pipe(
      map((res) => res.data),
    );
  }

  getAllCoverageTypes(): Observable<ICoverageType[]> {
    const url = `${this.coverageUrl}/type`;
    return this.http.get<{ data: ICoverageType[] }>(url).pipe(
      map((res) => res.data),
    );
  }

  createCoverage(payload: ICreateCoverage): Observable<ICoverage> {
    const url = `${this.coverageUrl}`;
    const data = {
      ...payload,
      effective_date: this.getDateIgnoringTimezone(payload.effective_date),
      expiration_date: this.getDateIgnoringTimezone(payload.expiration_date),
    };
    return this.http.post<{ data: ICoverage }>(url, data).pipe(
      map((res) => res.data),
    );
  }

  deleteCoverage(id: string): Observable<string> {
    const url = `${this.coverageUrl}/${id}`;
    return this.http.delete<{ data: string }>(url).pipe(
      map((res) => res.data),
    );
  }

  updateCoverage(id: string, payload: IUpdateCoverage): Observable<ICoverage> {
    const url = `${this.coverageUrl}/${id}`;
    if (payload.effective_date) {
      payload.effective_date = this.getDateIgnoringTimezone(payload.effective_date);
    }
    if (payload.expiration_date) {
      payload.expiration_date = this.getDateIgnoringTimezone(payload.expiration_date);
    }
    return this.http.put<{ data: ICoverage }>(url, payload).pipe(
      map((res) => res.data),
    );
  }

  getCoveragesCount(coi_id: string): Observable<number> {
    const url = `${this.coverageUrl}/count/coi/${coi_id}`;
    return this.http.get<{ data: number }>(url).pipe(
      map((res) => res.data),
    );
  }

  getCOISetting(): Observable<ICOISetting[]> {
    return this.http.get<{ data: ICOISetting[] }>(this.settingsUrl)
      .pipe(map(res => res.data));
  }

  updateCOISetting(id: number, data: ICOISettingUpdateData): Observable<ICOISetting> {
    const url = `${this.settingsUrl}/${id}`;
    return this.http.put<{ data: ICOISetting }>(url, data)
      .pipe(map(res => res.data));
  }

  public parseServiceError(e: any): string {
    const { error, message } = e;
    if (!error) {
      return message;
    }

    const { errors } = error;
    if (!(errors && errors.length)) {
      return message;
    }

    return errors.reduce((acc, curr) => {
      const { message: errMsg } = curr;
      if (!message) {
        return acc;
      }

      return `${acc} ${errMsg}`;

    }, '')
  }

  // Views
  getAllViews() {
    const params = {
      source: 'coi'
    }
    return this.http.get<{ data: any }>(`${environment.coiMsURL}/coi-view`, { params })
      .pipe(map(res => res.data));
  }

  exportDetailsCOI(currentCOIId: string) {
    const url = `${this.baseUrl}/export`;
    const params = {
      coi_id: currentCOIId,
    }
    return this.http.post(url,  params,{ responseType: 'blob', observe: 'body' });
  }

  exportBulkCOI(pagination: IGetCOIsListParams, view_name: string, file_type: string) {
    // const url = `http://localhost:8013/coi/bulk/export`;
    const url = `${this.baseUrl}/bulk/export`;
    const params = { ...pagination, view_name, file_type };

    return this.http.post(url, params);
  }

  exportCOI_Template(payload:any) {
    const url = `${environment.baseURL}properties/coi_template_export/`;
    return this.http.post(url, payload);
  }
}
