import { NgIf, NgClass, NgFor } from '@angular/common';
import {
  Component,
  Inject,
  OnInit,
  Optional,
} from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { BaseService } from 'src/app/services/base.service';
import { ConfigService } from 'src/app/services/config.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';
import { LoaderSmallComponent, ChipComponent, ButtonComponent, NoDataFountComponent } from '../../../_reusable_ui/_components';
import { SearchInputComponent, TextboxComponent, TextEditorComponent } from '../../../_reusable_ui/_controls';
import { PaginateLoaderComponent } from 'src/app/Modules/shared/Layouts/paginate-loader/paginate-loader.component';

@Component({
  selector: 'fyxt-scope-and-instructions-template',
  templateUrl: './scope-and-instructions-template.component.html',
  styleUrls: ['./scope-and-instructions-template.component.scss'],
  standalone: true,
  imports: [NgIf, LoaderSmallComponent, ButtonComponent, MatIconModule, FormsModule, ReactiveFormsModule, NgClass, NgFor, TextboxComponent, NgxIntlTelInputModule, MatTooltipModule, TextEditorComponent, MatDialogModule,LoaderSmallComponent, SearchInputComponent, PaginateLoaderComponent,NoDataFountComponent]
})
export class ScopeAndInstructionsTemplateComponent implements OnInit {
  scopeInstructionsForm!: FormGroup;
  formSubmitted: boolean = false;
  isLoader: boolean = false;
  isReadOnly: boolean = false;
  isNewTemplate: boolean = false;
  isEditTemplate: boolean = false;
  menuLists = [{ label: 'Delete', value: 'Delete' }];
  verticalMenus: any[] = [
    {
      label: 'Name',
      menus: []
    }
  ];
  instructionsText: any;
  selectedTab: any;
  callNextPage = null;
  searchTerm: string;

  constructor(
    public formbuilder: FormBuilder,
    public _dialogService: DialogService,
    public _dialogRef: MatDialogRef<ScopeAndInstructionsTemplateComponent>,
    public _baseService: BaseService,
    public _Config: ConfigService,
    public _utilService: UtilityService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data) {
      this.isReadOnly = this.data.isReadOnly;
    }
  }

  ngOnInit(): void {
    this.intializeForm();
    this.getTemplates();
  }

  intializeForm() {
    this.instructionsText = "";
    this.scopeInstructionsForm = this.formbuilder.group({
      scope: ['', Validators.required],
      instructions: ['', Validators.required],
    });
  }

  getTemplates(searchTerm?: any, isPagination?: boolean, sortOrder?: any) {
    let requestURL = `${environment.LocalDev_URL_V2}scope-instructions/`;
    if (searchTerm) {
      requestURL += `?search=${encodeURIComponent(searchTerm)}`;
    }
    if (isPagination) {
      requestURL = this.callNextPage;
    } else {
      this.isLoader = true;
    }
    if (sortOrder) {
      if (requestURL.includes('?')) {
        requestURL += `&order=${sortOrder}`
      } else {
        requestURL += `?order=${sortOrder}`
      }
    }
    this._baseService.doGET(requestURL).subscribe({
      next: (response: any) => {
        this.callNextPage = response.next;
        this.isLoader = false;
        let templateLists = response.results.map(item => {
          return { id: item.id, scope: item.scope, instructions: item.instructions };
        });
        if (isPagination) {
          this.verticalMenus[0].menus.push(...templateLists);
        } else {
          this.verticalMenus[0].menus = templateLists;
          this.selectedTab = response.results[0];
          this.onChangeTab(this.selectedTab);
        }
      },
      error: (err: any) => {
        this.isLoader = false;
      },
      complete: () => {
        this.isLoader = false;
      },
    });
  }

  searchScope(event: any) {
    this.searchTerm = event;
    this.getTemplates(event);
  }

  createScopeTemplate() {
    this.isNewTemplate = true;
    this.instructionsText = '';
    this.scopeInstructionsForm.reset({
      scope: '',
      instructions: ''
    });
    this.scopeInstructionsForm.markAsPristine();
  }

  onChangeTab(event: any) {
    this.selectedTab = event;
    this.isNewTemplate = false;
    this.isEditTemplate = true;
    this.scopeInstructionsForm.patchValue({
      scope: event.scope,
      instructions: event.instructions
    });
    this.instructionsText = event.instructions;
    this.scopeInstructionsForm.markAsPristine();
  }

  scrollToEnd() {
    this.getTemplates('', true);
  }

  onChangeOptions(event: any) {
    if (event.value === 'Delete') {
      let requestURL = `${environment.LocalDev_URL_V2}scope-instructions/${this.selectedTab.id}/`;
      let payLoad = {
        scope: this.scopeInstructionsForm.value.scope,
        instructions: this.scopeInstructionsForm.value.scope
      }
      this.isLoader = true;
      this._baseService.doDelete(requestURL, payLoad).subscribe({
        next: (response: any) => {
          this.isLoader = false;
          this._utilService.showSuccess('', 'Scope & Instructions Deleted Successfully')
        },
        error: (err: any) => {
          this.isLoader = false;
        },
        complete: () => {
          this.isLoader = false;
          this.getTemplates();
        },
      });
    }
  }

  contentChanged(event: any) {
    this.instructionsText = event;
  }

  sortScopeName(sortOrder: any) {
    this.getTemplates(this.searchTerm,false,sortOrder)
  }

  onCancel(data?: any) {
    if (!this.isReadOnly && !this.isNewTemplate && this.isEditTemplate && !this.scopeInstructionsForm.pristine) {
      this.scopeInstructionsForm.patchValue({
        scope: data.scope,
        instructions: data.instructions
      });
      this.instructionsText = data.instructions;    
    } else if (!this.isReadOnly && this.isNewTemplate && this.scopeInstructionsForm.valid) {
      this.scopeInstructionsForm.reset();
      this.instructionsText = '';
    } else if (this.isReadOnly) {
      this._dialogRef.close();
    }
  }

  onSubmit(data?: any) {
    if (!this.isReadOnly) {
      if (this.scopeInstructionsForm.invalid) {
        return;
      }
      if (this.isNewTemplate) {
        let requestURL = `${environment.LocalDev_URL_V2}scope-instructions/`;
        let payLoad = {
          scope: this.scopeInstructionsForm.value.scope,
          instructions: this.scopeInstructionsForm.value.instructions
        }
        this.isLoader = true;
        this._baseService.doPOST(requestURL, payLoad).subscribe({
          next: (response: any) => {
            this.isLoader = false;
            this._utilService.showSuccess('', 'Scope & Instructions Added Successfully')
          },
          error: (err: any) => {
            this.isLoader = false;
            this._utilService.showError('', err.error.scope)
          },
          complete: () => {
            this.isLoader = false;
            this.getTemplates();
          },
        });
      } else if (this.isEditTemplate) {
        let requestURL = `${environment.LocalDev_URL_V2}scope-instructions/${this.selectedTab.id}/`;
        let payLoad = {
          scope: this.scopeInstructionsForm.value.scope,
          instructions: this.scopeInstructionsForm.value.instructions
        }
        this._baseService.doPatch(requestURL, payLoad).subscribe({
          next: (response: any) => {
            this.verticalMenus[0].menus.forEach((item) => {
              if (item.id === response.id) {
                item.scope = response.scope;
                item.instructions = response.instructions;
              }
            });
            this._utilService.showSuccess('', 'Scope & Instructions Updated Successfully')
          },
          error: (err: any) => {},
          complete: () => {},
        });
      }
    } else {
      this._dialogRef.close(data);
    }
  }

}
