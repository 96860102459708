import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ChipComponent } from '../chip/chip.component';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
    selector: 'fyxt-tab',
    templateUrl: './tab.component.html',
    styleUrls: ['./tab.component.scss'],
    standalone: true,
    imports: [MatTabsModule, NgClass, NgFor, NgIf, ChipComponent]
})
export class TabComponent implements OnInit, OnChanges {

  @Input() customClass: string = '';
  @Input() type!: string;
  @Input() tabList: any[] = [];
  @Input() selectedTab: any;
  @Output() onSelectTab: any = new EventEmitter();
  @Input() noBorder: boolean = false;
  @Input() disable: boolean;

  selectedIndex: any;
  constructor() { }

  ngOnInit(): void {
    this.tabList?.map((ele: any, index: any) => {
      if (ele.active == true) {
        this.selectedIndex = index;

        let selectedTab = this.tabList[index];
        this.onSelectTab.emit(selectedTab);

      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.tabList) {
      this.tabList?.map((ele: any, index: any) => {
        if (ele.active == true) {
          this.selectedIndex = index;
          let selectedTab = this.tabList[index];
          //this.onSelectTab.emit(selectedTab);
        }
      });
    }
    if (changes?.selectedTab) {
      this.tabList.map((tab: any) => {
        return tab.active = false;
      });
      this.tabList?.map((tab: any) => {
        if (this.selectedTab?.value == tab.value) {
          return tab.active = true;
        }
      });
      this.tabList?.map((ele: any, index: any) => {
        if (ele.active == true) {
          this.selectedIndex = index;
          let selectedTab = this.tabList[index];
          //this.onSelectTab.emit(selectedTab);
        }
      });
    }
  }


  changeTab(event: any) {
    let selectedTab = this.tabList[event.index];
    this.onSelectTab.emit(selectedTab);
  }

  /** Track by Index  **/
  trackByIndex(index: number, item: any): number {
    return index;
  }
}
