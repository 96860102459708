import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './Gaurd/auth.guard';
import { NgModule } from '@angular/core';
import { FilesSliderComponent } from './@fyxt/_shared/_views/_tab_components/tab-files/files-slider/files-slider.component';

const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./Modules/core/core.module').then(m => m.CoreModule)
  },
  {
    path: 'pm',
    loadChildren: () => import('./Modules/property-manager/property-manager.module').then(m => m.PropertyManagerModule),
    canActivate: [AuthGuard],
    data: { roles: ['Owner', 'Manager'] }
  },
  {
    path: 'engineer',
    loadChildren: () => import('./Modules/property-manager/property-manager.module').then(m => m.PropertyManagerModule),
    canActivate: [AuthGuard],
    data: { roles: ['Engineer'] }
  },
  // {
  //   path: 'tenant',
  //   loadChildren: () => import('./Modules/tenants/tenants.module').then(m => m.TenantsModule),
  //   canActivate: [AuthGuard],
  //   data: { roles: ['Tenant'] }
  // },
  {
    path: 'tenant',
    loadChildren: () => import('./Modules/tenant-portal/tenant-portal.module').then(m => m.TenantPortalModule),
    canActivate: [AuthGuard],
    data: { roles: ['Tenant', 'Owner', 'Manager'] }
  },
  {
    path: 'new-external-vendor-jobs',
    loadChildren: () => import('./Modules/_fyxt_modules/new-external-vendor/new-external-vendor.module').then(m => m.NewExternalVendorModule),
  },
  {
    path: 'view-job',
    loadChildren: () => import('./Modules/job-view/job-view.module').then(m => m.JobViewModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pm/approval',
    loadChildren: () => import('./Modules/_fyxt_modules/jobcost-approval/jobcost-approval.module').then(m => m.JobcostApprovalModule),
    canActivate: [AuthGuard],
    data: { roles: ['Owner', 'Manager', 'Engineer'] }
  },

  { path: 'blank', loadChildren: () => import('./Modules/blank/blank.module').then(m => m.BlankModule) },

  { path: 'public', loadChildren: () => import('./Modules/soft-auth/soft-auth.module').then(m => m.SoftAuthModule) },

  { path: 'pay', loadChildren: () => import('./Modules/public-payment/public-payment.module').then(m => m.PublicPaymentModule) },

  { path: 'server-error', loadChildren: () => import('./server-error/server-error.module').then(m => m.ServerErrorModule) },

  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
