import { Component, OnInit } from '@angular/core';
import { GraphqlAdditionalDataService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/additional-data/graphql-additional-data.service';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';

@Component({
    selector: 'app-duplicate-item-name',
    templateUrl: './duplicate-item-name.component.html',
    styleUrls: ['./duplicate-item-name.component.scss'],
    standalone: true,
    imports: [TextboxComponent, FormsModule, NgxIntlTelInputModule, MatDialogModule, ButtonComponent]
})
export class DuplicateItemNameComponent implements OnInit {

  duplicateItemForm: any = {
    itemName: ''
  };
  onSave:boolean = false;

  constructor(public _graphql:GraphqlAdditionalDataService) { }

  ngOnInit(): void {
  }

    /**
* On Change Event
* @param event
*/
selectedTags(event: any): void {
  this.duplicateItemForm.selectedFilters=event;
}


/**
 * save view Trigger
 */
saveView(){
  let duplicateItem : any = {
    itemName:this.duplicateItemForm.itemName,
    category:JSON.parse(this._graphql.selectedItem!.category)
  }
  if(this.duplicateItemForm.itemName){
    this._graphql.createAdditionalDataItem(duplicateItem);
  }
  this.onSave = true;
}

}
