import {Component, OnDestroy, OnInit} from '@angular/core';
import {SidebarService} from 'src/app/@fyxt/_services/_layout/sidebar.service';
import {LayoutService} from 'src/app/@fyxt/_services/_layout/layout.service';
import {DashboardService} from 'src/app/Modules/_fyxt_modules/dashboard/_services/dashboard.service';
import {log} from 'console';
import {AlljobsService} from 'src/app/Modules/_fyxt_modules/alljobs/_services/alljobs.service';
import {InboxService_New} from 'src/app/Modules/_fyxt_modules/inbox-management/_services/inbox.service';
import {Subject, from, of} from 'rxjs';
import {concatMap, debounceTime, switchMap, takeUntil} from 'rxjs/operators';
import {AssociateJobPayload} from 'src/app/Modules/_fyxt_modules/inbox-management/inbox.types';
import {UtilityService} from 'src/app/services/utility.service';
import {environment} from "../../../../../environments/environment";
import {
  SelectUploadTypesModalComponent
} from "../../../../Modules/shared/components/photo-bank/select-upload-types/select-upload-types-modal.component";
import {AssociatedJobsFilterPopupComponent} from "../../_views";
import {AddCompanyComponent} from "../add-company/add-company.component";
import {DialogService} from "../../../_services/dialog/dialog.service";
import {error} from "protractor";
import { CheckboxComponent } from '../../_reusable_ui/_controls/checkbox/checkbox.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgIf, NgFor, DatePipe } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { SearchInputComponent } from '../../_reusable_ui/_controls/search-input/search-input.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../_reusable_ui/_components/button/button.component';

@Component({
    selector: 'fyxt-associate-jobs-slide',
    templateUrl: './associate-jobs-slide.component.html',
    styleUrls: ['./associate-jobs-slide.component.scss'],
    standalone: true,
    imports: [ButtonComponent, MatIconModule, MatDividerModule, SearchInputComponent, FormsModule, NgxIntlTelInputModule, MatTooltipModule, NgIf, InfiniteScrollModule, NgFor, CheckboxComponent, DatePipe]
})
export class AssociateJobsSlideComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<null> = new Subject();

  standardView;
  associateJobs: any[] = [];
  selectedJobs: any[] = [];
  searchValue = '';
  page = 1;
  nextPageUrl = '';
  searchDebounce = new Subject();
  isLoading = false;

  constructor(
    public sidebarService: SidebarService,
    public _layoutService: LayoutService,
    private _dashboardService: DashboardService,
    private inboxService: InboxService_New,
    public _dialogService: DialogService,
    private _utilService: UtilityService
  ) {
  }

  ngOnInit(): void {
    // // TODO : write custom api as this is slow
    // this._dashboardService.getAllViewsData().subscribe((data) => {
    //   this.standardView = data[0].data.find((data) => data.name == 'All Open Jobs');
    //   this.getTableData();
    // })
    this.getAssociatedJobData(false);

    this.searchDebounce.pipe(debounceTime(300)).subscribe((data) => {
      this.getAssociatedJobData(false);
    });
    this.inboxService.fetchOpenAssociatedJobSlide().subscribe((state) => {
      if (state) {
        this.getAssociatedJobData(false);
        this.inboxService.openAssociatedJobSlide.next(false);
      }
    });
  }

  searchTermUpdated(event) {
    this.searchValue = event;
    this.page = 1;
    this.searchDebounce.next(event);
  }

  getAssociatedJobData(isPagination: boolean) {
    this.isLoading = isPagination ? false : true;
    let requestURL = `${environment.baseURL}mails/associated-jobs-dropdown/`;
    if (this.searchValue.trim() != '') {
      if (requestURL.includes('?')) {
        requestURL += `&custom-search=${this.searchValue}`;
      } else {
        requestURL += `?custom-search=${this.searchValue}`;
      }
    }
    if (this.page > 1) {
      if (requestURL.includes('?')) {
        requestURL += `&page=${this.page}`;
      } else {
        requestURL += `?page=${this.page}`;
      }
    }
    this.inboxService.getAssociateJobs(requestURL).pipe(takeUntil(this.destroy$)).subscribe(
      {
        next: (response: any) => {
          this.isLoading = false;
          this.nextPageUrl = response.next;
          if (this.page > 1) {
            this.associateJobs = this.associateJobs.concat(response.results);
          } else {
            this.associateJobs = response.results ?? [];
          }
          this.inboxService.allAssociatedJobs.map((job: any) => {
            this.associateJobs = this.associateJobs.filter((associateJob: any) => {
              return job.id != associateJob.id;
            });
          });
        },
        error: (error) => {
        },
        complete: () => {
        }
      });
  }

  onSelect(event: boolean, job: any, index: number) {
    if (event) {
      this.selectedJobs.push(job);
    } else {
      this.selectedJobs.splice(index, 1)
    }
  }

  fetchNextPage() {
    if (this.nextPageUrl == null) {
      return null;
    } else {
      this.page += 1;
      this.getAssociatedJobData(true);
    }
  }

  ngOnDestroy(): void {
    //this.sidebarService.toggleRightSidebar = false;
    this._layoutService.toggleFilter_Column(true);
    this._layoutService.showView = true;
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  onCancel() {
    this.selectedJobs = [];
    this.searchValue = '';
    this.page = 1;
    this.nextPageUrl = '';
    this.sidebarService.toggleRightSidebar = !this.sidebarService.toggleRightSidebar;
    //this.inboxService.hideOpenFirstPanel.next('open');
  }


  protected associateJob() {
    if (this.inboxService.isFromBulkActionAssociatedJobOpen) {
      this.bulkActionAssociatedJob();
    } else {
      let payload: AssociateJobPayload = {
        "threadId": this.inboxService.activeThread.id,
        "jobs": this.selectedJobs.map((data) => {
          return {"id": `${data.id}`, "isRemoved": false , "status":data.status}
        }),
        "isCreate": false
      }
      this.emitAssignToEvent(this.selectedJobs);
      this.emitChangeLabelEvent(this.selectedJobs);
      this.inboxService.AssociateJobs(payload).pipe(takeUntil(this.destroy$)).subscribe({
        next: () => {
          this.bulkJobStatusChange(payload.jobs);
          this.getAssociatedJobs(payload.jobs);
          this._utilService.showSuccess('', 'Job Associated to this thread');
          this.onCancel();
        },
        error: () => {
          this._utilService.showError('', 'Error Associating Job');
        },
        complete: () => {
          this.inboxService.associatedJobsAddedFromInbox.next(true);
        }
      });
    }
  }

  bulkActionAssociatedJob(): void {
    let payload = {
      threadIds: this.inboxService.selectedThreadsForBulkActionAssociatedJobs.map((thread: any) => {
        return thread.id;
      }),
      jobs: this.selectedJobs.map((data) => {
        return {"id": `${data.id.toString()}`, "isRemoved": false}
      })
    };
    this.changeLabeForThreads(this.selectedJobs, this.inboxService.selectedThreadsForBulkActionAssociatedJobs);
    this.inboxService.bulkReplyAssociatedJobs(payload).pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.bulkJobStatusChange(payload.jobs);
        this._utilService.showSuccess('', 'Bulk Job Associated Successfully');
        this.onCancel();
        this.inboxService.bulkAssignManager.next(true);
      },
      error: () => {
        this._utilService.showError('', 'Something went wrong, Please try again later');
      },
      complete: () => {
        this.inboxService.associatedJobFromBulkActionComplete.next(true);
        this.inboxService.isFromBulkActionAssociatedJobOpen = false;
      }
    })
  }

  getAssociatedJobs(jobIds: any): void {
    const associatedJobId = jobIds.map((job: any) => {
      return job.id;
    });
    this.inboxService.getAssociatedJobsForThread(associatedJobId).pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      this.inboxService.allAssociatedJobs = [...this.inboxService.allAssociatedJobs, ...data.data];
      this.inboxService.allAssociatedJobs = this.removeDuplicates(this.inboxService.allAssociatedJobs);
    })  
  }

  private removeDuplicates(list: any): any {
    return list.reduce((unique, entry) => {
      if (!unique.some((obj) => obj.id == entry.id)) {
        unique.push(entry);
      }
      return unique;
    }, []);
  }

  openAssociatedJobFilterPopup(): void {
    let popupData = {
      title: 'Associate Job(s)',
      component: AssociatedJobsFilterPopupComponent,
      containerClass: ['modal-xl'],
    };
    this._dialogService.openModal(popupData).subscribe(result => {
      if (result?.jobAssociated) {
        this.onCancel();
      }
    });

  }


  private bulkJobStatusChange(jobs) {
    from(jobs).pipe(
      concatMap((job: any) =>
        this.inboxService.jobSeenUpdate(job?.id)
      ), takeUntil(this.destroy$)
    ).subscribe(
      response => { },
      error => { },
      () => { }
    );
  }

  private emitAssignToEvent(jobs) {
    for (let index = 0; index < jobs.length; index++) {
      if(jobs[index].pm_assignee) {
        this.inboxService.assignManager.next(jobs[index].pm_assignee);
        break;
      }
    }
  }

  private emitChangeLabelEvent(jobs) {
    if (!jobs || jobs.length === 0) {
      return null; 
    }
    jobs.sort((a, b) => b.id - a.id);
    
    this.inboxService.changeLabel.next(jobs[0].issue_type);
  }

  private changeLabeForThreads(jobs, threads) {
    threads.forEach((thread:any) => {

      if (!jobs || jobs.length === 0) {
        return null; 
      }

      jobs.sort((a, b) => b.id - a.id);
      this.onChangeSubject(jobs[0]?.issue_type, thread.id);
    });
  }

  onChangeSubject(event: any, ThreadId) {
    const payload = {
      label: event
    }
    this.inboxService.updateThreads(payload, ThreadId).pipe(takeUntil(this.destroy$)).subscribe(
      {
        next: (response: any) => {
        },
        error: (error) => {
          this._utilService.showErrorMessage(error);
        }
      });
  }


 /** Track by Index  **/
 trackByIndex(index: number, item: any): number {
  return index;
}

}
