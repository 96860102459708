import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Template, Template_API } from '../inbox.types';
import {BehaviorSubject, Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import { InboxService_New } from './inbox.service';

@Injectable({
  providedIn: 'root'
})
export class InboxSettingsService {
  public signature: string = '';
  public subDomain:string;
  public settingsTabCountUpdated = new BehaviorSubject<string>('');

  constructor(private httpClient: HttpClient,private _inboxService:InboxService_New) {
    let url = window.location.origin;
    this.subDomain = this.extractDomain(url).split('.')[0];
    this.subDomain == 'localhost' ? this.subDomain = 'web' : this.subDomain;
  }

  extractDomain(url: string): any {
    let domain;
    if (url.indexOf('://') > -1) {
      domain = url.split('/')[2];
    } else {
      domain = url.split('/')[0];
    }
    domain = domain.split(':')[0];
    return domain;
  }

  fetchSettingsTabCountUpdated(): Observable<string> {
    return this.settingsTabCountUpdated.asObservable();
  }

  public getInbox(): Observable<Template> {
    return this.httpClient.get<Template>(`${environment.inbox.http}mailboxes/${this._inboxService.activeMailboxDetails.id}`, { "headers": { "x-tenant-id": this.subDomain } })
  }

  public getInboxSignature(id: string): Observable<Template> {
    return this.httpClient.get<Template>(`${environment.inbox.http}mailboxes/${id}`, { "headers": { "x-tenant-id": this.subDomain } })
  }

  public updateInbox(obj: any) {
    return this.httpClient.put(`${environment.inbox.http}mailboxes/${this._inboxService.activeMailboxDetails.id}`, obj, { "headers": { "x-tenant-id": this.subDomain } })
  }

  public createTemplate(obj: Template) {
    return this.httpClient.post(`${environment.inbox.http}templates`, obj, { "headers": { "x-tenant-id": this.subDomain } })
  }

  public updateTemplate(obj: Partial<Template>, templateId: string) {
    return this.httpClient.put(`${environment.inbox.http}templates/${templateId}`, obj, { "headers": { "x-tenant-id": this.subDomain } })
  }

  public getTemplate(templateId: string): Observable<Template_API> {
    return this.httpClient.get<Template_API>(`${environment.inbox.http}templates/${templateId}`, { "headers": { "x-tenant-id": this.subDomain } })
  }

  public getAllTemplates(category: string): Observable<Template_API> {
    return this.httpClient.get<Template_API>(`${environment.inbox.http}templates?category=${category}&mailboxId=${this._inboxService.activeMailboxDetails.id}`, { "headers": { "x-tenant-id": this.subDomain } })
  }

  public deleteTemplate(templateId: string) {
    return this.httpClient.delete(`${environment.inbox.http}templates/${templateId}`, { "headers": { "x-tenant-id": this.subDomain } })
  }

  public setAsDefault(templateId:string){
    return this.httpClient.put(`${environment.inbox.http}templates/${templateId}/set-default`, { }, { "headers": { "x-tenant-id": this.subDomain } })
  }

  public getAllTemplatesFromDashboardAssociatedPopup(category: string, id: string): Observable<Template_API> {
    return this.httpClient.get<Template_API>(`${environment.inbox.http}templates?category=${category}&mailboxId=${id}`, { "headers": { "x-tenant-id": this.subDomain } })
  }

  public getAllMailboxUsers(mailboxId: string){
    return this.httpClient.get<Template_API>(`${environment.inbox.http}mailbox-users/mailboxes/${mailboxId}`, { "headers": { "x-tenant-id": this.subDomain } })
  }

  public addUserToMailbox(payload: {userId:string, mailboxId:string} ){
    return this.httpClient.post(`${environment.inbox.http}mailbox-users`, payload , { "headers": { "x-tenant-id": this.subDomain } })
  }

  public deleteUserToMailbox(payload: {userId:string, mailboxId:string} ){
    const options = {
      headers: new HttpHeaders({
        'x-tenant-id': this.subDomain,
      }),
      body:payload,
    };
    return this.httpClient.delete(`${environment.inbox.http}mailbox-users`,options);
  }

  public getAllManagers(search){
    let url =  environment.LocalDev_URL_V2 +'drop-down/assignees/'
    if(search){
      url = url + `?search=${search}`
    }
    return this.httpClient.get(url);
  }

  public deleteInbox(mailbox:string){
    return this.httpClient.delete(`${environment.inbox.http}mailboxes/${mailbox}`, { "headers": { "x-tenant-id": this.subDomain } })
  }


}
