import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

export interface activeClasses {
  active: string;
  inactive: string;
}

@Component({
    selector: 'fyxt-icon-switch',
    templateUrl: './icon-switch.component.html',
    styleUrls: ['./icon-switch.component.scss'],
    standalone: true,
    imports: [MatIconModule]
})
export class IconSwitchComponent implements OnInit {

  @Input() active: boolean = false;
  @Input() classList: activeClasses = {
    active: '',
    inactive: ''
  };

  @Output() onChangeIcon: any = new EventEmitter();


  constructor() { }

  ngOnInit(): void {
  }

  setActionClass() {
    return this.active ? this.classList.active : this.classList.inactive;
  }

  toggleIcon(){
    this.active=!this.active;
    this.onChangeIcon.emit(this.active);
  }
}
