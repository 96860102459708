// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disable_color {
  color: #B9B9B9;
}

::ng-deep .ng-option.ng-option-marked:not(.ng-option-selected) {
  font-weight: normal !important;
}

.item-address {
  color: #9FA7B3;
  font-size: 12px;
  padding-left: 20px;
}

img.flag-image {
  height: 18px;
  width: 18px;
  float: right;
  filter: invert(24%) sepia(83%) saturate(4246%) hue-rotate(18deg) brightness(97%) contrast(101%) !important;
}

.item-property {
  color: #9FA7B3;
  font-size: 12px;
  padding-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_reusable_ui/_controls/dropdown-select/dropdown-select/dropdown-select.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;EACA,YAAA;EACA,0GAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;AACF","sourcesContent":[".disable_color {\n  color: #B9B9B9;\n}\n\n::ng-deep .ng-option.ng-option-marked:not(.ng-option-selected) {\n  font-weight: normal !important;\n}\n\n.item-address {\n  color: #9FA7B3;\n  font-size: 12px;\n  padding-left: 20px;\n}\n\nimg.flag-image {\n  height: 18px;\n  width: 18px;\n  float: right;\n  filter: invert(24%) sepia(83%) saturate(4246%) hue-rotate(18deg) brightness(97%) contrast(101%) !important;\n}\n\n.item-property {\n  color: #9FA7B3;\n  font-size: 12px;\n  padding-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
