import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { Contact, MemberRole, MemberType, NotifyOtherForm, Roles } from './notify-others.inteface';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { CreateContactComponent } from '../create-contact/create-contact.component';
import { DialogService } from '../../../../_services/dialog/dialog.service';
import { environment } from '../../../../../../environments/environment';
import { ConfigService } from '../../../../../services/config.service';
import { BaseService } from '../../../../../services/base.service';
import { JobsService } from '../../../../../services/v2/jobs.service';
import { LeasesHttpService } from '../../../../_services/leases/leases-http.service';
import { takeUntil } from 'rxjs/operators';
import { UtilService } from "../../../../../services/v2/util.service";
import { SoftAuthService } from '../../../../../Modules/soft-auth/services/soft-auth.service';
import { ChecklistService } from 'src/app/services/checklist/checklist.service';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { DropdownComponent } from '../../../_reusable_ui/_controls/dropdown/dropdown.component';
import { NgIf, NgClass, NgFor } from '@angular/common';


@Component({
    selector: 'app-notify-others',
    templateUrl: './notify-others.component.html',
    styleUrls: ['./notify-others.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgClass, DropdownComponent, NgxIntlTelInputModule, NgFor, ButtonComponent, MatTooltipModule, MatIconModule, MatDividerModule, MatDialogModule]
})
export class NotifyOthersComponent implements OnInit, OnDestroy {
// Imported interface values
  protected readonly MemberType = MemberType;

// Variables
  private readonly destroy$: Subject<null> = new Subject<null>();
  public managersList = [];
  public engineersList = [];
  public tenants: any = {list: [], total: 0, page: 1, size: 25, search: ''};
  public vendors: any = {list: [], total: 0, page: 1, size: 25, search: ''};

  public selectedManagersList = [];
  public selectedEngineerList = [];
  public selectedTenantsList = [];
  public selectedVendorsList = [];

  public notifyOtherForm!: FormGroup<NotifyOtherForm>;

  public singleRole?: boolean;
  public doesntExist!: boolean;
  public clearSearchValue: boolean = true;
  public searchContact: string = '';

  public memberType?: MemberType;
  public selectedChecklist?: any;
  public roles?: Roles;
  private signatureUserIds: string[] | null = null;

  private isPublic: boolean = false;
  public isUserEngineer: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<NotifyOthersComponent>,
    private dialogService: DialogService,
    private leasesHttpService: LeasesHttpService,
    public jobService: JobsService,
    public configService: ConfigService,
    public baseService: BaseService,
    public utilService: UtilService,
    public softAuthService: SoftAuthService,
    @Optional() @Inject(MAT_DIALOG_DATA) public matDialogData: any,
    public _checklistService: ChecklistService,
  ) {
    this.isPublic = this.matDialogData?.data?.isPublic;
    this.memberType = this.matDialogData?.data?.memberType;
    this.roles = this.matDialogData?.data?.roles;
    this.singleRole = this.roles ? Object.values(this?.roles)?.filter(v => v)?.length === 1 : false;
    this.selectedChecklist = this.matDialogData?.data?.selectedChecklist;
    this.signatureUserIds = this.matDialogData?.data?.signatureUserIds;
  }

  ngOnInit(): void {
    const currentUserInfo: { current_Role: string } = localStorage.getItem('userobj') ? JSON.parse(localStorage.getItem('userobj')) : {};
    const { current_Role } = currentUserInfo;
    this.isUserEngineer = current_Role === 'Engineer';

    if (this.memberType === MemberType?.MANAGER || this.roles?.manager)
      this.getMembersList(this.jobService.selectedJobInfo.property.id, MemberType?.MANAGER);
    if (this.memberType === MemberType?.ENGINEER || this.roles?.engineer)
      this.getMembersList(this.jobService.selectedJobInfo.property.id, MemberType?.ENGINEER);
    if (this.memberType === MemberType?.TENANT || this.roles?.tenant)
      this.getTenantContactDetail();
    if (this.memberType === MemberType?.VENDOR || this.roles?.vendor)
      this.getVendorContactDetail();
    this.notifyOtherForm = new FormGroup<any>({
      selected_managers: new FormControl([]),
      selected_tenants: new FormControl([]),
      selected_engineers: new FormControl([]),
      selected_vendors: new FormControl([]),
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  searchManagersDropdown(e) {
    // Add a request here if you need to search for Managers, where e.term that searched value
  }

  searchEngineersDropdown(e) {
    // Add a request here if you need to search for Engineers, where e.term that searched value
  }

  searchTenantsDropdown(e) {
    const {term} = e;
    this.tenants.page = 1
    this.tenants.search = term ? term.trim() : '';
    this.getTenantContactDetail();
  }

  searchVendorsDropdown(e) {
    // Add a request here if you need to search for Vendors, where e.term that searched value
    const {term} = e;
    this.vendors.page = 1
    this.vendors.search = term ? term.trim() : '';
    this.getVendorContactDetail();
  }

  onSelectedChange(selectedItems, type: MemberType) {
    switch (type) {
      case MemberType.MANAGER:
        this.selectedManagersList = selectedItems;
        break;

      case MemberType.ENGINEER:
        this.selectedEngineerList = selectedItems;
        break;

      case MemberType.TENANT:
        this.selectedTenantsList = selectedItems;
        break;

      case MemberType.VENDOR:
        this.selectedVendorsList = selectedItems;
        break;
    }
  }

  onScrollManagers() {
    // Add a request here if you need lazy loading for Managers
  }

  onScrollEngineers() {
    // Add a request here if you need lazy loading for Engineers
  }

  onScrollTenants() {
    // Add a request here if you need lazy loading for Tenants
    if (this.tenants.page * this.tenants.size < this.tenants.total) {
      this.tenants.page += 1
      this.getTenantContactDetail(true);
    }
  }

  onScrollVendors() {
    // Add a request here if you need lazy loading for Tenants
    if (this.vendors.page * this.vendors.size < this.vendors.total) {
      this.vendors.page += 1
      this.getVendorContactDetail(true);
    }
  }

  removeItem(id: string, type: MemberType) {
    if (type === MemberType.MANAGER) {
      this.selectedManagersList = this.selectedManagersList.filter(selectedManager => selectedManager.id !== id);
      this.notifyOtherForm.patchValue({
        selected_managers: this.selectedManagersList.map(v => v.id),
      });

    } else if (type === MemberType.TENANT) {
      this.selectedTenantsList = this.selectedTenantsList.filter(selectedTenant => selectedTenant.id !== id);
      this.notifyOtherForm.patchValue({
        selected_tenants: this.selectedTenantsList.map(v => v.id),
      });
    } else if (type === MemberType.VENDOR) {
      this.selectedVendorsList = this.selectedVendorsList.filter(selectedVendor => selectedVendor.id !== id);
      this.notifyOtherForm.patchValue({
        selected_vendors: this.selectedVendorsList.map(v => v.id),
      });
    } else {
      this.selectedEngineerList = this.selectedEngineerList.filter(selectedTenant => selectedTenant.id !== id);
      this.notifyOtherForm.patchValue({
        selected_engineers: this.selectedEngineerList.map(v => v.id),
      });
    }
  }

  openCreateContact() {
    let popupData = {
      title: 'Create New Contact',
      component: CreateContactComponent,
      containerClass: ['modal-sm', 'create_new_contact_model'],
      data: {
        selectedCompanyDetails: {
          id: this.jobService.selectedJobInfo.tenant_company_id,
          name: this.jobService.selectedJobInfo.tenant,
          type: 'Tenant',
        }, viewModule: true,
      },
    };
    this.dialogService.openModal(popupData).subscribe(
      (result) => !!result && this.clearSearch()
    )
  }

  getMembersList(propertyId: string, memberType?: MemberType) {
    const requestURL = this.getRequestURL(propertyId, memberType);
    this.fetchList(requestURL, memberType);
  }

  getRequestURL(propertyId: any, memberType: MemberType): string {
    const managersUrl = this.isPublic ? this.configService.getNestAllAssignedManagersByPropertyPublic : this.configService.getNestAllAssignedManagersByProperty;
    const engineersUrl = this.isPublic ? this.configService.getNestAllAssignedEngineerByPropertyPublic : this.configService.getNestAllAssignedEngineerByProperty;
    let requestURL = environment.coreMsURL + (memberType === this.MemberType.MANAGER ? managersUrl : engineersUrl);
    return requestURL.replace('{property_id}', propertyId);
  }

  fetchList(requestURL: string, memberType: MemberType): void {
    this.baseService.doGET(requestURL).subscribe(
      (response: any) => {
        const parseData = response.data
          .filter(({id}) => !this.signatureUserIds?.includes(id))
          .map((user: any) => {
            return {...user, name: user.full_name};
          });
        switch (memberType) {
          case MemberType.MANAGER:
            this.managersList = parseData;
            break;

          case MemberType.ENGINEER:
            this.engineersList = parseData;
            break;
        }
      },
      (error) => {
      },
    );
  }

  getTenantContactDetail(isNextPage?: boolean) {
    let requestURL = `${environment.fastAPI_Actual_URL}/${this.configService.getCompanyContacts}`;
    requestURL = requestURL.replace('{ID}', this.jobService.selectedJobInfo.tenant_company_id)

    const params: { page: number, size: number, search?: string } = {
      page: this.tenants.page,
      size: this.tenants.size,
    }

    if (this.tenants.search) {
      params.search = this.tenants.search;
    }

    const softUserId = localStorage.getItem('soft-auth-id');

    this.baseService.doGET(requestURL, {params})
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          let list = []
          let filteredList = response.items
            .filter((contact: Contact) => contact.primary_email)
            .filter((contact: Contact) => softUserId !== contact.id)
            .filter(({id}) => !this.signatureUserIds?.includes(id))
            .map((contact: Contact) => ({
              id: contact.id,
              name: [contact.first_name, contact.last_name].join(' '),
              email: contact.primary_email,
              company: contact.primary_company,
              selected: false,
              contact: contact
            }))

          if (isNextPage) {
            list = [...this.tenants.list, ...filteredList]
          } else {
            list = [...filteredList]
          }

          this.tenants = {
            list: list,
            total: response.total,
            page: response.page,
            size: response.size,
            search: this.tenants.search,
          }
          this.doesntExist = !this.tenants.list.length;
          this.searchContact = this.doesntExist ? this.tenants.search : '';
        },
        error: (error) => {
          this.utilService.showError('', 'Get Tenant Contacts Error')
        },
      });
  }

  getVendorContactDetail(isNextPage?: boolean) {
    let requestURL = environment.baseURL + `vendor-jobs/assigned-vendors/?job_id=${this.jobService.selectedJobInfo.id}&all=true`;
    this.baseService.doGET(requestURL)
      .subscribe((vendors) => {
        const vendor = (vendors || []).find(vendor => vendor.approve_to_work === 'Approved');
        if (!vendor) return;
        vendor.link = vendor.vendor_link;
        let requestURL = `${environment.fastAPI_Actual_URL}/${this.configService.getCompanyContacts}`;
        requestURL = requestURL.replace('{ID}', vendor.company_id);

    const params: { page: number, size: number, search?: string } = {
      page: this.vendors.page,
      size: this.vendors.size,
    }

    if (this.vendors.search) {
      params.search = encodeURIComponent(this.vendors.search)
    }

    const softUserId = localStorage.getItem('soft-auth-id');

    this.baseService.doGET(requestURL, {params}).subscribe({
      next: (response: any) => {
        let list = []
        let filteredList = response.items
          .filter((contact: Contact) => contact.primary_email)
          .filter((contact: Contact) => contact.primary)
          .map((contact: any) => ({
            id: contact.id,
            name: [contact.first_name, contact.last_name].join(' '),
            email: contact.primary_email,
            company: contact.primary_company,
            selected: false,
            contact: contact,
            vendor: vendor,
          }))

        if (isNextPage) {
          list = [...this.vendors.list, ...filteredList]
        } else {
          list = [...filteredList]
        }


        this.vendors = {
          list: list,
          total: response.total,
          page: response.page,
          size: response.size,
          search: this.vendors.search,
        }
      },
      error: (error) => {
      },
      complete: () => {
      }
    });
      });
  }

  /*** That's second old request */
  // getAllContacts(searchInput?) {
  //   let getActiveColumn = [
  //     'first_name',
  //     'last_name',
  //     'type',
  //     'phone',
  //     'primary_company',
  //     'last_activity_at',
  //     'email',
  //   ];
  //   let selectViewTableRows = environment.fastAPI_Actual_URL + '/contacts/?columns=' + JSON.stringify(getActiveColumn) + '&page=1&size=25';
  //
  //   if (searchInput) {
  //     selectViewTableRows += '&search=' + searchInput;
  //   }
  //
  //   this.fileService.getSelectedTableData1(selectViewTableRows).subscribe(
  //     (response: any) => {
  //       this.tenantsList = response.items
  //         .filter((contact: Contact) => contact.email)
  //         .map((contact: Contact) => ({
  //           id: contact.id,
  //           name: [contact.first_name, contact.last_name].join(' '),
  //           email: contact.email,
  //           selected: false,
  //         }));
  //       this.doesntExist = this.tenantsList.length === 0;
  //       this.searchContact = this.doesntExist ? searchInput : '';
  //     },
  //     (error) => {
  //     },
  //   );
  // }

  submitNotify() {
    const engineerMembers = this.selectedEngineerList.map(member => ({
      ...member,
      user_role: MemberRole.ENGINEER
    }));
    const managerMembers = this.selectedManagersList.map(member => ({
      ...member,
      user_role: MemberRole.MANAGER
    }));
    const tenantMembers = this.selectedTenantsList.map(member => ({
      ...member,
      user_role: MemberRole.TENANT
    }));
    const vendorMembers = this.selectedVendorsList.map(member => ({
      ...member,
      user_role: MemberRole.VENDOR
    }));
    const selectList = [...engineerMembers, ...managerMembers, ...tenantMembers, ...vendorMembers];

    const notifyMembers = selectList.map(member => ({
      job_id: this.jobService.selectedJobInfo.id.toString(),
      name: member.name,
      company_name: member.user_role === MemberRole.VENDOR ? member.vendor.company_name : this.jobService.selectedJobInfo.tenant,
      checklist_id: this.selectedChecklist,
      user_id: member.user_role === MemberRole.VENDOR ? member.vendor.vendor : member.id,
      user_contact_id: member.contact_id || member.id,
      user_role: member.user_role,
      user_email: member.email,
      sign_link: member.user_role === MemberRole.VENDOR
        ? `${member.vendor.link}&contact_id=${member.id}&tab=job_checklist&checklist=${this.selectedChecklist}`
        : undefined,
    }));

    this.leasesHttpService.notifyMembers(notifyMembers, {isPublic: this.isPublic}).subscribe({
      next: () => {
        this._checklistService.updateSignatures.next(true);
        this.dialogRef.close(true);
      },
      error: (error) => {
        this.utilService.showError('Sign Error', 'Something went wrong.');
      },
    });
  }

  onAddClick(memberType: MemberType) {
    let selectList = [];
    switch (memberType) {
      case MemberType.ENGINEER:
        selectList = [...this.selectedEngineerList];
        break;
      case MemberType.MANAGER:
        selectList = [...this.selectedManagersList];
        break;
      case MemberType.TENANT:
        selectList = [...this.selectedTenantsList];
        break;
      case MemberType.VENDOR:
        selectList = [...this.selectedVendorsList];
        break;
    }

    const signedMembers = selectList.map(v =>
      ({
        job_id: this.jobService.selectedJobInfo.id.toString(),
        name: v.name,
        company_name: MemberRole[memberType] === MemberRole.VENDOR ? v.vendor.company_name : this.jobService.selectedJobInfo.tenant,
        checklist_id: this.selectedChecklist.id,
        user_id: MemberRole[memberType] === MemberRole.VENDOR ? v.vendor.vendor : v.id,
        user_contact_id: v.contact_id || v.id,
        user_role: MemberRole[memberType],
        user_email: v.email,
        sign_link: MemberRole[memberType] === MemberRole.VENDOR
          ? `${v.vendor.link}&contact_id=${v.id}&tab=job_checklist&checklist=${this.selectedChecklist}`
          : undefined,
      }),
    );

    this.leasesHttpService.notifyMembers(signedMembers, {isPublic: this.isPublic}).subscribe({
      next: () => {
        this.utilService.showSuccess('', 'Notified Successfully.');
        this.dialogRef.close(true);
      },
      error: (error) => {
        this.utilService.showError('Notified Error', 'Something went wrong.');
      },
    });
  }

  closeModalWindow(result: boolean) {
    this._checklistService.updateSignatures.next(result);
    this.dialogRef.close(result);
  }

  clearSearch() {
    this.clearSearchValue = !this.clearSearchValue;
    this.doesntExist = false;
    this.getTenantContactDetail();
  }

  /** Track by Index  **/
  trackByIndex(index: number, item: any): number {
    return index;
  }
}
