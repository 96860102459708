import { Component, OnInit } from '@angular/core';
import { AddContactComponent } from '../../../_forms/add-contact/add-contact.component';

@Component({
    selector: 'fyxt-tab-contact-details',
    templateUrl: './tab-contact-details.component.html',
    styleUrls: ['./tab-contact-details.component.scss'],
    standalone: true,
    imports: [AddContactComponent]
})
export class TabContactDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
