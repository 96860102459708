import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  HttpEventType,
  HttpProgressEvent,
  HttpResponse
} from '@angular/common/http';
import { UtilityService } from 'src/app/services/utility.service';

import { ConfigService } from 'src/app/services/config.service';
import { BaseService } from 'src/app/services/base.service';
import { FileProgressComponent } from '../../components/file-progress/file-progress.component';
import { NgClass, NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-drag-and-drop-file-uploader',
    templateUrl: './drag-and-drop-file-uploader.component.html',
    styleUrls: ['./drag-and-drop-file-uploader.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, NgFor, FileProgressComponent]
})
export class DragAndDropFileUploaderComponent implements OnInit {
  @ViewChild('fileDropRef') fileDropRef: ElementRef;

  @Output() selectedFiles = new EventEmitter<any>();
  @Input() is_error:boolean=false;

  files: any[] = [];
  errorValue: any[] = [];
  public errorMsg: any;
  public progees: any;
  public cancelValue: boolean;

  constructor(
    public _utilService: UtilityService,
    public _Config: ConfigService,
    public _baseService: BaseService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(): void {}

  /**
   * on file drop handler
   */
  onFileDropped($event: FileList) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files: FileList) {
    this.prepareFilesList(files);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: FileList) {
    let uploadableFiles: any = Array.from(files);

    this.selectedFiles.emit(files);
    // CHANGED API FOR IMAGE UPLOAD
    return;

    uploadableFiles.forEach((file: any, index: any) => {
     if(!file.type.includes('image')){
      let error = {
        error:"File type should be image"
      }
      this._utilService.showErrorMessage(error);
      return;
     }
      let lastIndex = this._utilService.draggedFiles.length;
      this._utilService.draggedFiles.push(file);

      file = file;
      this.progees = file;
      file.progress = 0;

      // if(file.size <= 20971520 ){
      if (file.size <= 104857600) {
        this.cancelValue = false;
      } else {
        this.cancelValue = true;
        this.progees.progress = 100;
        return;
      }

      let input = new FormData();
      input.append('file', file);
      input.append('mime_type', file.type);

      // input.append("type", 'Pre Completion');

      let PostBody = input;

      let headerOptions = {
        reportProgress: true,
        observe: 'events'
      };

      let requestURL = environment.baseURL + this._Config.uploadServiceFormPhotos;

      if (this.cancelValue == false) {
        this._baseService
          .doPOST(requestURL, PostBody, headerOptions)
          .subscribe({
            next: (event: any) => {
              switch (event.type) {
                case HttpEventType.Sent:
                  break;
                case HttpEventType.ResponseHeader:
                  break;
                case HttpEventType.UploadProgress:
                  let progress = Math.round((event.loaded / event.total) * 100);
                  file.progress = progress;
                  break;
                case HttpEventType.Response:
                  file.id = event.body.id;
                  file.photo = event.body.file;
                  file.photoType = event.body.mime_type;
              }
            },
            error: (error) => {
              this._utilService.showErrorMessage(error);
            },
            complete: () => {
              this.fileDropRef.nativeElement.value = '';
              this._utilService.draggedFiles[lastIndex] = file;
            }
          });
      }
    });

    setTimeout(() => {
      this.selectedFiles.emit(this._utilService.draggedFiles);
    }, 1000);

    this.fileDropRef.nativeElement.value = '';

  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    for (let x = 0; x <= this._utilService.draggedFiles.length; x++) {
      // if(bytes <= 20971520 ){
      if (bytes <= 104857600) {
        this.errorValue[x] = false;
      } else {
        this.errorValue[x] = true;
        this.progees.progress = 100;
      }
    }
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  getFileType(type: any) {
    if (type) {
      let fileType = 'image';

      if (type.includes('image')) {
        fileType = 'image';
      } else if (type.includes('audio')) {
        fileType = 'audio';
      } else if (type.includes('video')) {
        fileType = 'video';
      } else if (type.includes('application/pdf')) {
        fileType = 'pdf';
      } else if (type.includes('application')) {
        fileType = 'others';
      }

      return fileType;
    }

    return '';
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number, id: any) {
    this._utilService.draggedFiles.splice(index, 1);

    if (id) {
      let requestURL = environment.baseURL + this._Config.serviceFormRemovePhotos;
      requestURL = requestURL.replace('{ID}', id);
      this._baseService.doDelete(requestURL).subscribe(
        (event: any) => {
          // this._utilService.showSuccess('', 'File Removed Successfully.');
        },
        (error) => {
          this._utilService.showErrorMessage(error);
        }
      );
    }
  }
}
