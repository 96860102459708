import { Component, OnInit,  TemplateRef, ViewChild  } from '@angular/core';
import { LayoutService } from 'src/app/@fyxt/_services/_layout/layout.service';
import { ColumnSortType, TableColumnTypes } from 'src/app/@fyxt/_shared/enums/table/table-column-types.enum';
import { AppTableSource } from 'src/app/@fyxt/_shared/models/app-table-model';
import { TableService } from 'src/app/@fyxt/_services/table/table.service';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { SidebarService } from 'src/app/@fyxt/_services/_layout/sidebar.service';
import { DropdownComponent } from '../../_reusable_ui/_controls/dropdown/dropdown.component';
import { DropdownSelectComponent } from '../../_reusable_ui/_controls/dropdown-select/dropdown-select/dropdown-select.component';
import { TextboxComponent } from '../../_reusable_ui/_controls/textbox/textbox.component';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../_reusable_ui/_components/button/button.component';
import { LoaderSmallComponent } from '../../_reusable_ui/_components/loader-small/loader-small.component';


export interface ItemList {
  id: any;
  label: any;
  name: any;
  group?: any,
  selected?: any;
}


@Component({
    selector: 'fyxt-add-property',
    templateUrl: './add-property.component.html',
    styleUrls: ['./add-property.component.scss'],
    standalone: true,
    imports: [LoaderSmallComponent, ButtonComponent, MatIconModule, MatDividerModule, FormsModule, TextboxComponent, DropdownSelectComponent, DropdownComponent]
})




export class AddPropertyComponent implements OnInit {

  items: ItemList[] = [
    { id: 1, label: 'Industrial', name: 'Industrial', group: 'Odd' },
    { id: 2, label: 'Multifamily', name: 'Multifamily', group: 'Even' },
    { id: 3, label: 'Office', name: 'Office', group: 'Odd' },
    { id: 4, label: 'Mixed Use', name: 'Mixed Use', group: 'Even' },
    { id: 4, label: 'Retail', name: 'Retail', group: 'Even' },
    { id: 4, label: 'Single Family', name: 'Single Family', group: 'Even' },
    { id: 4, label: 'Other', name: 'Other', group: 'Even' }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
