import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { DefaultServiceRequestComponent } from './default-service-request/default-service-request.component';
import { DraftServiceRequestComponent } from './draft-service-request/draft-service-request.component';
import { ServiceRequestWithCompanyComponent } from './service-request-with-company/service-request-with-company.component';
import { GoodwillServiceRequestComponent } from './goodwill-service-request/goodwill-service-request.component';
import { VillagesServiceRequestComponent } from './villages-service-request/villages-service-request.component';
import { NgSwitch, NgSwitchCase, NgSwitchDefault, NgIf } from '@angular/common';
@Component({
    selector: 'app-new-job-requests',
    templateUrl: './new-job-requests.component.html',
    styleUrls: ['./new-job-requests.component.scss'],
    standalone: true,
    imports: [NgSwitch, NgSwitchCase, VillagesServiceRequestComponent, GoodwillServiceRequestComponent, ServiceRequestWithCompanyComponent, NgSwitchDefault, NgIf, DraftServiceRequestComponent, DefaultServiceRequestComponent]
})
export class NewJobRequestsComponent implements OnInit {

  allowedDomains: any[] = [
    'localhost',
    'web.devfyxt.com',
    'krishna.qafyxt.com',
    'delta.demofyxt.com',
    'cstest.fyxt.com',
    'friedman.demofyxt.com',
    'friedman.fyxt.com',
    'friedmanstaging.fyxt.com',
    'friedmanv3.fyxt.com',
    'delta.fyxt.com',
    'ots.fyxt.com',
    'villages.qafyxt.com',
    'cmi.fyxt.com',
    'continuum.fyxt.com',
    '*.docker.devfyxt.com',
    '*.docker.qafyxt.com',
    '*.docker.demofyxt.com',
    'thevillagescpm.fyxt.com',
    'thomasduke.fyxt.com',
    'internaltest.fyxt.com',
    'livedemo.fyxt.com',
    'csdemo.fyxt.com',
    'fornessproperties.fyxt.com',
    'buckingham.fyxt.com',
    'buckinghamstaging.fyxt.com',
    'fcptstaging.fyxt.com',
    'ryandemo.docker.demofyxt.com',
    'fcpt.fyxt.com',
    'dietz.fyxt.com',
    'goodwillkentucky.fyxt.com',
    'cawleypropertymanagement.fyxt.com',
    'mcbpropertymgmt.fyxt.com',
    'bcwoodproperties.fyxt.com',
    'bigskymed.fyxt.com',
    'midamericafacilities.fyxt.com',
  ];

  current_account = "friedman";
  default_logo: string = "assets/images/service_logo/Friedman_Logo.png";
  contactPageIndexAPI: number = 1;


  isDomainAllowed(domain, allowedDomains) {
    const regexPatterns = allowedDomains.map((allowedDomain) =>
      new RegExp(`^${allowedDomain.replace(/\*/g, '.*')}$`, 'i')
    );



    return regexPatterns.some((regex) => regex.test(domain));
  }

  constructor(
    public _baseService: BaseService
  ) {
    let hostname = window.location.hostname;

    if (hostname.includes('delta')) {
      this.current_account = 'delta';
      this.default_logo = "assets/images/service_logo/logo_delta.png";
    }
    else if (hostname.includes('ots')) {
      this.current_account = 'ots';
      this.default_logo = "assets/images/service_logo/logo_ots.png";
    }
    else if (hostname.includes('cmi')) {
      this.current_account = 'cmi';
      this.default_logo = "assets/images/service_logo/logo_cmi.png";
    }
    // else if (hostname.includes('ryandemo')) {
    //   this.current_account = 'ryandemo';
    //   this.default_logo = "assets/images/service_logo/fyxt.png";
    // }
    else if (hostname.includes('continuum')) {
      this.current_account = 'continuum';
      this.default_logo = "assets/images/service_logo/logo_continuum.png";
    }
    else if (hostname.includes('thevillagescpm') || hostname.includes('krishna')) {
      this.current_account = 'thevillagescpm';
      this.default_logo = "assets/images/service_logo/thevillagescpm.png";
    }
    else if (hostname.includes('thomasduke')) {
      this.current_account = 'thomasduke';
      this.default_logo = "assets/images/service_logo/thomasduke.png";
    }
    else if (hostname.includes('internaltest') || hostname.includes('friedmanstaging') || hostname.includes('friedmanv3') || hostname.includes('friedman')) {
      this.current_account = 'friedman';
      this.default_logo = "assets/images/service_logo/Friedman_Logo.png";
    }
    else if (hostname.includes('buckingham') || hostname.includes('buckinghamstaging')) {
      this.current_account = 'buckingham';
      this.default_logo = "assets/images/service_logo/buckingham.png";
    }
    else if (hostname.includes('livedemo') || hostname.includes('csdemo') || hostname.includes('fornessproperties') ) {
      if(hostname.includes('livedemo'))
      this.current_account = 'csdemo';
      else if(hostname.includes('csdemo'))
      this.current_account = 'livedemo';
      else if(hostname.includes('fornessproperties'))
      this.current_account = 'fornessproperties';

      this.default_logo = "assets/@fyxt/svgs/Fyxt_Logo_on_White.svg";
    }
    else if (hostname.includes('fcptstaging') || hostname.includes('fcpt') || hostname.includes('ryandemo')) {
      this.current_account = 'fcpt';
      this.default_logo = "assets/images/service_logo/FCPT.png";
    }
    else if (hostname.includes('dietz')) {
      this.current_account = 'dietz';
      this.default_logo = "assets/images/service_logo/dietz.jpg";
    }
    else if (hostname.includes('goodwillkentucky')) {
      this.current_account = 'goodwillkentucky';
      this.default_logo = "assets/images/service_logo/goodwill.png";
    }
    else if (hostname.includes('cawleypropertymanagement')) {
      this.current_account = 'cawleypropertymanagement';
      this.default_logo = "assets/images/service_logo/cawley_logo.png";
    }
    else if (hostname.includes('bcwoodproperties')) {
      this.current_account = 'bcwoodproperties';
      this.default_logo = "assets/images/service_logo/bc-wood-logo.png";
    }
    else if (hostname.includes('mcbpropertymgmt')) {
      this.current_account = 'mcbpropertymgmt';
      this.default_logo = "assets/images/service_logo/mcb-logo.png";
    }
    else if (hostname.includes('bigskymed')) {
      this.current_account = 'bigskymed';
      this.default_logo = "assets/images/service_logo/bigskymed_logo.png";
    }
    else if (hostname.includes('midamericafacilities') || hostname.includes('web')) {
      this.current_account = 'midamericafacilities';
      this.default_logo = "assets/images/service_logo/mid-america-logo.png";
    }
    else if(hostname.includes('localhost')){
      this.current_account = 'midamericafacilities';
      this.default_logo = "assets/images/service_logo/mid-america-logo.png";
    }

    if (!this.isDomainAllowed(hostname, this.allowedDomains)) {
      this._baseService.redirectTo('/');
    }
  }

  ngOnInit(): void {
  }

}
