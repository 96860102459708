import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ItemList } from 'src/app/@fyxt/_shared/_reusable_ui/_controls/dropdown-select/itemType.class';
import { ResizedEvent, AngularResizeEventModule } from 'angular-resize-event-package';
import { ActivitiesComponent } from '../../../_templates/activities/activities.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { FilterChipListComponent } from '../../../_reusable_ui/_components/filter-chip-list/filter-chip-list.component';
import { NgIf, NgClass } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';

@Component({
    selector: 'fyxt-tab-activities',
    templateUrl: './tab-activities.component.html',
    styleUrls: ['./tab-activities.component.scss'],
    standalone: true,
    imports: [AngularResizeEventModule, ButtonComponent, MatTooltipModule, MatIconModule, MatDividerModule, NgIf, FilterChipListComponent, NgClass, LoaderSmallComponent, ActivitiesComponent]
})
export class TabActivitiesComponent implements OnInit ,OnDestroy {
  @Input() activities: any[] = [];
  @Input() isLoader: boolean;
  @Input() totalCount: any;
  @Input() propertyGroups: boolean = false;
  @Input() containerHeight: number = 0;
  @Output() toggleFilter = new EventEmitter();
  @Output() onClear = new EventEmitter();
  @Output() onDeletedTag = new EventEmitter();
  @Output() activityPageChange = new EventEmitter();
  @Output() activityComponentDestroyed =  new EventEmitter();

  @Input() filterChipList: any[] = [];
  @Output() divWidth:number;


  constructor() {}

  ngOnInit(): void {
   // let tableCell = document.querySelector('#id');
   // this.divWidth = tableCell.clientWidth - (2*(tableCell.clientWidth*8/100));
  }

  /**
* On Selected Tags Change Event
* @param event
*/
  deletedTag(filterChip: any): void {
    const deleteChipIndex = this.filterChipList.findIndex((chip) => {
      return chip.id === filterChip.id;
    });
    this.filterChipList.splice(deleteChipIndex, 1);
    this.onDeletedTag.emit(filterChip);
  }

  ontoggleFilter() {
    this.toggleFilter.emit();
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.activities){
      let user = {
        first_name:"unknown",
        last_name:"",
        photo:'assets/images/users/avatar.png'
      }
      if(this.activities){
        this.activities.forEach(element => {
          if(element.user == null){
            element.user = user
          }
        });
      }
    }
  }

  /**
   * Clear Selected Filters
   */
  clearSelectedFilters() {
    this.filterChipList = [];
    this.onClear.emit(this.filterChipList);
  }

  onResized(event: ResizedEvent) {
    this.divWidth = event.newRect.width - (2*(event.newRect.width*8/100));
    }

  activityNextPage(e:boolean){
    this.activityPageChange.emit(true);
  }

  ngOnDestroy(): void {
    this.activityComponentDestroyed.emit(true);
  }
}
