import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { ButtonComponent } from '../../../../../@fyxt/_shared/_reusable_ui/_components/button/button.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { CheckboxComponent } from '../../../../../@fyxt/_shared/_reusable_ui/_controls/checkbox/checkbox.component';

@Component({
    selector: 'fyxt-delete-inbox',
    templateUrl: './delete-inbox.component.html',
    styleUrls: ['./delete-inbox.component.scss'],
    standalone: true,
    imports: [CheckboxComponent, FormsModule, NgxIntlTelInputModule, MatDialogModule, ButtonComponent]
})
export class DeleteInboxComponent implements OnInit {

  checkBoxSelected = false;

  constructor( public _dialogRef: MatDialogRef<DeleteInboxComponent>,) { }

  ngOnInit(): void {
  }

  onCancel(){
    this._dialogRef.close();
  }


  deleteInbox(){
    this._dialogRef.close(true);
  }

  checkBoxSelectedToggle(event){
    this.checkBoxSelected = event;
  }

}
