import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { CdkDragDrop, CdkDropList, CdkDrag } from '@angular/cdk/drag-drop';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DialogService, ModalData } from '../../../../_services/dialog/dialog.service';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { SwitchComponent } from '../../../_reusable_ui/_controls/switch/switch.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { NgFor, NgIf } from '@angular/common';

export interface EditColumnsModalData {
  columns: EditColumn[];
  onChange: (columns: EditColumn[]) => any;
}

export interface EditColumn {
  name: string;
  checked: boolean;
  value: string;
  active?: boolean;
  disable?: boolean;
}

@Component({
    selector: 'fyxt-edit-columns',
    templateUrl: './edit-columns.component.html',
    styleUrls: ['./edit-columns.component.scss'],
    standalone: true,
    imports: [MatDialogModule, CdkDropList, NgFor, CdkDrag, MatIconModule, NgIf, MatTooltipModule, SwitchComponent, FormsModule, NgxIntlTelInputModule]
})
export class EditColumnsComponent implements OnInit {
  @Output() onValueChange = new EventEmitter<Event>();

  @Input() columnLists!: EditColumn[];
  @Input() updateURL: string = '';


  constructor(@Inject(MAT_DIALOG_DATA) private readonly modalData: ModalData<EditColumnsModalData>,
    public _dialogService: DialogService) { }

  ngOnInit(): void {
    const defaultColumns = [
      { name: 'Tenant', value: 'tenant', checked: false },
      { name: 'Property', value: 'property', checked: true },
      { name: 'Start Date', value: 'commencement_date', checked: false },
      { name: 'End Date', value: 'expiration_date', checked: true },
      { name: 'Unit/s', value: 'units', checked: false },
      { name: 'Move In Inspection', value: 'move_in_inspection', checked: false },
      { name: 'Move Out Inspection', value: 'move_out_inspection', checked: false }
    ];

    if (this.modalData.data?.columns)
      this.columnLists = this.modalData.data?.columns || defaultColumns;
      this.columnLists = this.columnLists.filter((column: any) => {
        return column.value !== 'checkbox';
      })
  }

  drop({ previousIndex, currentIndex }: CdkDragDrop<any[]>) {
    const movedElement = this.columnLists[previousIndex];
    const listWithoutElement = [...this.columnLists];
    listWithoutElement.splice(previousIndex, 1);

    const newArr = [...listWithoutElement.slice(0, currentIndex), movedElement, ...listWithoutElement.slice(currentIndex)]

    let setNewOrder = newArr.map((obj: any, index) => { obj.order = index + 1; return obj });

    this.columnLists = setNewOrder;
    this.callExternalOnChange();
  }

  onChange(value: boolean, index: number): void {
    this.columnLists[index].checked = value;
    this.columnLists[index].active = value;
    this.callExternalOnChange();
  }

  callExternalOnChange(): void {
    if (!this.modalData.data?.onChange) {
      return;
    }

    this.modalData.data.onChange(this.columnLists);
  }


 /** Track by Index  **/
 trackByIndex(index: number, item: any): number {
  return index;
}


}
