import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { DropdownSelectComponent } from '../../../_reusable_ui/_controls/dropdown-select/dropdown-select/dropdown-select.component';

@Component({
    selector: 'fyxt-approval-scenario-confirmation',
    templateUrl: './approval-scenario-confirmation.component.html',
    styleUrls: ['./approval-scenario-confirmation.component.scss'],
    standalone: true,
    imports: [DropdownSelectComponent, MatDialogModule, ButtonComponent]
})
export class ApprovalScenarioConfirmationComponent implements OnInit {

  dropdownValue = [
    {
    id: 1,
    name: 'Update Current & Future Jobs'
    },
    {
    id: 2,
    name: 'Update Future Jobs Only'
    }
  ];
  selectedValue: any;

  constructor(private dialogRef: MatDialogRef<ApprovalScenarioConfirmationComponent>,) {

  }

  ngOnInit(): void {
  }

  confirmationUpdate(event: any) {
    this.selectedValue = event;
  }

  updateApprovalConfirmation(): void {
    this.dialogRef.close(this.selectedValue);
  }

  closePopup(): void {
    this.dialogRef.close(undefined);
  }
}
