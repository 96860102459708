import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';

@Component({
    selector: 'fyxt-unassign-document',
    templateUrl: './unassign-document.component.html',
    styleUrls: ['./unassign-document.component.scss'],
    standalone: true,
    imports: [MatDialogModule, ButtonComponent]
})
export class UnassignDocumentComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<UnassignDocumentComponent>) { }

  ngOnInit(): void {
  }

  unAssignDocument(): void {
    this.closeModal();
  }

   closeModal(): void {
    this.dialogRef.close();
  }

}
