import {Component, ElementRef, EventEmitter, forwardRef, Input, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import {GoogleAddressService, Maps} from "../../../../_services/google-address/google-address.service";
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgClass } from '@angular/common';

const place = null as google.maps.places.PlaceResult;
type Components = typeof place.address_components;

@Component({
    selector: 'fyxt-google-address-textbox',
    templateUrl: './google-address-textbox.component.html',
    styleUrls: ['./google-address-textbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => GoogleAddressTextboxComponent)
        }
    ],
    standalone: true,
    imports: [NgIf, MatFormFieldModule, NgClass, MatInputModule, FormsModule, NgxIntlTelInputModule]
})
export class GoogleAddressTextboxComponent implements OnInit, ControlValueAccessor {
  @Input() input: string = '';
  @Input() disable: boolean = false
  @Input() label: any;
  @Input() Error: any;
  @Input() Type: string = 'text';
  @Input() placeHolder!: string;
  @Input() alwaysNeedClean?: boolean = true;

  @Output() onValueChange = new EventEmitter<any>();
  @Output() onFocusOut = new EventEmitter<Event>();

  @ViewChild('googleAddressSearch')
  public searchElementRef: ElementRef;

  public entries = [];
  public place: google.maps.places.PlaceResult;
  googleAddress: {
    formattedAddress: string;
    geometry: any;
  }

  googleCoordinates:any;


  constructor(public _googleAddressService: GoogleAddressService, private ngZone: NgZone) {
    _googleAddressService.api.then((maps) => {
      this.initAutocomplete(maps);
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes): void{
    //This is only for filter location address, because every time we add the location we need to clear the value
    if (changes?.input?.currentValue.trim() !== '' && this.alwaysNeedClean) {
      this.input = '';
    }
  }


  onChange = (data: any) => {
  }

  onTouch = (_: any) => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: string) {
    this.input = value;
    this.onChange(value);
  }

  /**
   *Func triggered on input change
   */
  onchange(event: any) {
    this.input = event;
    this.onChange(event);
    if (event == '') {
      this.onValueChange.emit(event);
    }
  }

  focusInput() {}

  focusOutInput(event: any) {
    this.onFocusOut.emit(event);
  }

  // Google address Related functions
  initAutocomplete(maps: Maps) {
    // If you don't want to restrict any places, then use this code
    // let autocomplete = new maps.places.Autocomplete(
    //   this.searchElementRef.nativeElement
    // );

    // If you want to restrict any places, then use this code
    // Use componentRestrictions method to get the places what we preferred
    let autocomplete = new maps.places.Autocomplete(this.searchElementRef?.nativeElement, {
      componentRestrictions: {country: ["us", "ca"]},
    });
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        this.onPlaceChange(autocomplete.getPlace());
      });
    });
  }

  onPlaceChange(place: google.maps.places.PlaceResult) {
    const location = this.locationFromPlace(place);
    this.entries.unshift({
      location,
    });
    this.input = place.formatted_address;
    const payload = {
      formatAddress: place.formatted_address,
      location: location,
      latitude: this.googleCoordinates.latitude,
      longitude: this.googleCoordinates.longitude
    }
    this.onValueChange.emit(payload);
  }

  public locationFromPlace(place: google.maps.places.PlaceResult) {
    const components = place.address_components;
    if (components === undefined) {
      return null;
    }

    const areaLevel3 = this.getShort(components, 'administrative_area_level_3');
    const locality = this.getLong(components, 'locality');

    const cityName = locality || areaLevel3;
    const countryName = this.getLong(components, 'country');
    const countryCode = this.getShort(components, 'country');
    const stateCode = this.getShort(components, 'administrative_area_level_1');
    const postal_code = this.getShort(components, 'postal_code');
    const name = place.name !== cityName ? place.name : null;

    const coordinates = {
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    };
    this.googleCoordinates = coordinates;
    const bounds = place.geometry.viewport.toJSON();

    // placeId is in place.place_id, if needed
    return {
      name,
      cityName,
      countryName,
      countryCode,
      stateCode,
      bounds,
      coordinates,
      postal_code
    };
  }

  getComponent(components: Components, name: string) {
    return components.filter((component) => component.types[0] === name)[0];
  }

  getLong(components: Components, name: string) {
    const component = this.getComponent(components, name);
    return component && component.long_name;
  }

  getShort(components: Components, name: string) {
    const component = this.getComponent(components, name);
    return component && component.short_name;
  }
}

