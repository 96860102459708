import { Component, OnInit } from '@angular/core';
import {DialogService} from "../../../../_services/dialog/dialog.service";
import { MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import {BehaviorSubject, Observable} from "rxjs";
import {Contacts, PrimaryContacts} from "../../../models/porperty-manager/contacts";
import {LeasesStoreService} from "../../../../_services/leases/leases-store.service";
import {Companies} from "../../../models/porperty-manager/companies";
import {filter, map, switchMap, take, takeUntil, tap} from "rxjs/operators";
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { ListComponent } from '../../../_reusable_ui/_components/list/list.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';

@Component({
    selector: 'fyxt-edit-primary-contact',
    templateUrl: './edit-primary-contact.component.html',
    styleUrls: ['./edit-primary-contact.component.scss'],
    standalone: true,
    imports: [SearchInputComponent, NgIf, NgFor, ListComponent, MatDialogModule, ButtonComponent, AsyncPipe]
})
export class EditPrimaryContactComponent implements OnInit {
  clearSearchInput: BehaviorSubject<any> = new BehaviorSubject(null);
  listContacts$: Observable<Contacts[]> = this.leasesStoreService.contactsByCompany$;

  contacts: any[] = [
    { name: "Person 1" },
    { name: "Person 2" },
    { name: "Person 3" },
    { name: "Person 4" },
    { name: "Person 5" },
    { name: "Person 6" },
    { name: "Person 7" },
    { name: "Person Gregor VIII" },
  ];

  contactDetailsForm: any = {
    id: '',
    companyName: ''
  };

  constructor(
    private readonly leasesStoreService: LeasesStoreService,
    public dialogRef: MatDialogRef<EditPrimaryContactComponent>,
  ) { }

  ngOnInit(): void {
  }

  handlePrimaryContact(event: Event, contacts: PrimaryContacts ): void{
    this.contactDetailsForm.id = contacts.id
  }

  saveContact(): void {
    this.leasesStoreService.currentLease$
      .pipe(
        take(1),
        switchMap(lease => this.leasesStoreService.updateLease({
          id: lease.id,
          contact_primary: this.contactDetailsForm.id,
        }))
      ).subscribe({
      next: () => {
        this.closeModal();
      },
      error: () => {
        this.closeModal();
      }
    })
    // this.closeModal();

  }

  closeModal(): void {
    this.dialogRef.close();
  }

  searchLeaseContact(searchStr) {
    this.searchedContact(searchStr);
  }

  public searchedContact(name) {
    if (!name) {
      this.listContacts$ = this.leasesStoreService.contactsByCompany$;
    }
      this.listContacts$ = this.leasesStoreService.contactsByCompany$
        .pipe(map((value) => {
          return value.filter(((item) => {
            const full_name = [item.first_name, item.last_name].join(' ')
            return full_name.toLowerCase().includes(name.toLowerCase())
          }))
        }))
  }


 /** Track by Index  **/
 trackByIndex(index: number, item: any): number {
  return index;
}

}
