import {Component, OnDestroy, OnInit} from '@angular/core';
import {InboxService_New} from "../../../../../Modules/_fyxt_modules/inbox-management/_services/inbox.service";
import {environment} from "../../../../../../environments/environment";
import {concatMap, takeUntil} from "rxjs/operators";
import {Subject, from} from "rxjs";
import {ColumnSortType, TableColumnTypes} from "../../../enums/table/table-column-types.enum";
import {TableService} from "../../../../_services/table/table.service";
import {AssociateJobPayload} from "../../../../../Modules/_fyxt_modules/inbox-management/inbox.types";
import {UtilityService} from "../../../../../services/utility.service";
import {MatDialogRef} from "@angular/material/dialog";
import { ConfigService } from 'src/app/services/config.service';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { TableComponent } from '../../../_reusable_ui/_components/table/table.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';
import { NgIf, NgClass } from '@angular/common';
import { DropdownComponent } from '../../../_reusable_ui/_controls/dropdown/dropdown.component';

@Component({
    selector: 'fyxt-associated-jobs-filter-popup',
    templateUrl: './associated-jobs-filter-popup.component.html',
    styleUrls: ['./associated-jobs-filter-popup.component.scss'],
    standalone: true,
    imports: [DropdownComponent, NgIf, NgClass, SearchInputComponent, FormsModule, NgxIntlTelInputModule, LoaderSmallComponent, TableComponent, ButtonComponent]
})
export class AssociatedJobsFilterPopupComponent implements OnInit, OnDestroy {
    private readonly destroy$: Subject<null> = new Subject();
    moduleName = 'Inbox';
    tableDataLoading = false;
    associateJobTableDataSource: any;
    associateJobTableData = {
        associateJobList: [],
        associateJobTableHeader: [
            {
                value: 'checkbox',
                name: '',
            },
            {
                value: 'id',
                sort: ColumnSortType.none,
                name: 'Job ID',
                type: TableColumnTypes.text,
                className: 'table-header',
                resizeColumn: true,
            },
            {
                value: 'issue_type',
                sort: ColumnSortType.none,
                name: 'Description',
                type: TableColumnTypes.text,
                className: 'table-header',
                resizeColumn: true,
            },
            {
                value: 'category',
                sort: ColumnSortType.none,
                name: 'Category',
                type: TableColumnTypes.text,
                className: 'table-header',
                resizeColumn: true,
            },
            {
                value: 'jobStatus',
                sort: ColumnSortType.none,
                name: 'Status',
                type: TableColumnTypes.text,
                className: 'table-header',
                resizeColumn: true,
            },
            {
                value: 'property',
                sort: ColumnSortType.none,
                name: 'Property',
                type: TableColumnTypes.text,
                className: 'table-header',
                resizeColumn: true,
            },
            {
                value: 'location',
                sort: ColumnSortType.none,
                name: 'Location',
                type: TableColumnTypes.text,
                className: 'table-header',
                resizeColumn: true,
            },
        ],
        sortState: {
            active: '',
            direction: ''
        },
        searchValue: '',
        currentPage: 1,
        pageIndex: 0,
        nextPageUrl: '',
        totalRows: 1,
        perPage: 25
    }
    selectedJobs = [];
    property = {
        searchValue: '',
        page: 1,
        nextPageUrl: '',
        list: [],
        selectedList: []
    };
    tenants = {
        searchValue: '',
        page: 1,
        list: [],
        selectedList: [],
        totalPages: 1
    };
    categories = {
        searchValue: '',
        list: [],
        selectedList: []
    };
    location = {
        searchValue: '',
        list: [],
        selectedList: []
    };
    associatedJobFilterQuery = {};

    constructor(private _inboxService: InboxService_New, public tableService: TableService,
                private _utilService: UtilityService, public dialogRef: MatDialogRef<AssociatedJobsFilterPopupComponent>,
                public config: ConfigService,
            ) {
    }

    ngOnInit(): void {
        this.getAssociatedJobData()
        this.getPropertyList();
        this.getTenantList();
        this.getCategoriesList();
    }

    ngOnDestroy(): void {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    getAssociatedJobData() {
        this.tableDataLoading = true;
        const offset = 0;
        let requestURL = `${environment.baseURL}mails/associated-jobs-dropdown/?page=${this.associateJobTableData.currentPage}&limit=25&offset=${offset}`;
        if (this.associateJobTableData.searchValue.trim() != '') {
            if (requestURL.includes('?')) {
                requestURL += `&custom-search=${this.associateJobTableData.searchValue}`;
            } else {
                requestURL += `?custom-search=${this.associateJobTableData.searchValue}`;
            }
        }
        if (this.associateJobTableData.currentPage > 1) {
            if (requestURL.includes('?')) {
                requestURL += `&page=${this.associateJobTableData.currentPage}`;
            } else {
                requestURL += `?page=${this.associateJobTableData.currentPage}`;
            }
        }
        if (this.associateJobTableData.sortState.active != '') {
            requestURL += `&column=${this.associateJobTableData.sortState.active}&order=${this.associateJobTableData.sortState.direction}`;
        }
        if (this.isObjectHasValue(this.associatedJobFilterQuery)) {
            requestURL += '&query=' + JSON.stringify(this.associatedJobFilterQuery);
        }
        // Append sort direction to the header if we have sort query we get from the backend
        if (this.associateJobTableData.sortState !== undefined && this.associateJobTableData.sortState?.active !== '') {
            this.associateJobTableData.associateJobTableHeader.map((header: any) => {
                if (header.value == this.associateJobTableData.sortState.active) {
                    return header.sort = this.associateJobTableData.sortState.direction;
                } else {
                    delete header['sort'];
                }
            });
        }
        this._inboxService.getAssociateJobs(requestURL).pipe(takeUntil(this.destroy$)).subscribe(
            {
                next: (response: any) => {
                    this.tableDataLoading = false;
                    this.associateJobTableData.nextPageUrl = response.next;
                    this.associateJobTableData.associateJobList = response.results ?? [];
                    this.associateJobTableData.associateJobList.forEach(job => {
                        job.checked = false;
                        job.jobStatus = job.stage,
                        job.location = job._unit?.name
                    });
                    this.associateJobTableData.totalRows = response.count;
                    this.associateJobTableDataSource = this.tableService.setTableData(this.moduleName, this.associateJobTableData.associateJobTableHeader, this.associateJobTableData.associateJobList, this.associateJobTableData.totalRows, this.associateJobTableData.currentPage - 1, this.associateJobTableData.perPage);
                },
                error: (error) => {
                    this.tableDataLoading = false;
                },
                complete: () => {
                }
            });
    }

    isObjectHasValue(obj): boolean {
        return Object.keys(obj).length > 0;
    }

    associateJobSearch(event: any): void {
        this.associateJobTableData.searchValue = event;
        this.associateJobTableData.currentPage = 1;
        this.getAssociatedJobData();
    }

    associateJobSortChange(sortState: any): void {
        this.associateJobTableData.sortState = sortState;
        this.associateJobTableData.pageIndex = 0;
        this.associateJobTableData.currentPage = 1;
        this.getAssociatedJobData();
    }

    associateJobPageChange(event: any): void {
        this.associateJobTableData.perPage = event.pageSize;
        this.associateJobTableData.currentPage = event.pageIndex + 1;
        this.getAssociatedJobData();
    }

    selectedAssociatedJobs(jobs: any): void {
        let updatedCheckedJobs: number[];
        const isCompanyChecked = this.selectedJobs.some((checkedJobs) => checkedJobs.id === jobs.id);
        if (isCompanyChecked) {
            updatedCheckedJobs = this.selectedJobs.filter((checkedJobs) => checkedJobs.id !== jobs.id);
        } else {
            updatedCheckedJobs = [...this.selectedJobs, jobs];
        }
        this.selectedJobs = updatedCheckedJobs;
    }

    isAllJobsSelected(isSelected: any) {
        if (isSelected) {
            this.selectedJobs = this.selectedJobs.concat(this.associateJobTableData.associateJobList);
            this.selectedJobs = this.removeDuplicateById(this.selectedJobs);
        } else {
            this.selectedJobs = this.selectedJobs.filter(ar => !this.associateJobTableData.associateJobList.find(rm => (rm.id === ar.id)));
            this.associateJobTableData.associateJobList.map((companyData: any) => {
                return companyData.checked = false;
            });
        }
    };

    private removeDuplicateById(list: any): any {
        return list.reduce((unique, entry) => {
            if (!unique.some((obj) => obj.id == entry.id)) {
                unique.push(entry);
            }
            return unique;
        }, []);
    }

    getPropertyList() {
        let requestURL = `${environment.LocalDev_URL_V2 + this.config.getPropertiesFilter}`;
        if (this.property.searchValue.trim() != '') {
            if (requestURL.includes('?')) {
                requestURL += `&search=${this.property.searchValue}`;
            } else {
                requestURL += `?search=${this.property.searchValue}`;
            }
        }
        if (this.property.page > 1) {
            if (requestURL.includes('?')) {
                requestURL += `&page=${this.property.page}`;
            } else {
                requestURL += `?page=${this.property.page}`;
            }
        }
        this._inboxService.getPropertyFilterAssociateJobs(requestURL).pipe(takeUntil(this.destroy$)).subscribe(
            {
                next: (response: any) => {
                    this.property.nextPageUrl = response.next;
                    if (this.property.page > 1) {
                        this.property.list = this.property.list.concat(response.results);
                    } else {
                        this.property.list = response.results ?? [];
                    }
                },
                error: (error) => {
                },
                complete: () => {
                }
            });
    }

    propertyDropdownSearch(event: any) {
        this.property.searchValue = event.term;
        this.property.page = 1;
        this.getPropertyList();
    }

    fetchPropertyNextPage() {
        if (this.property.nextPageUrl == null) {
            return null;
        } else {
            this.property.page += 1;
            this.getPropertyList();
        }
    }

    changeProperty(event: any): void {
        this.property.searchValue = '';
        event = event ? [event] : [];
        this.property.selectedList = event;
        if (this.property.selectedList.length == 0) {
            delete this.associatedJobFilterQuery['properties'];
            this.location.selectedList = []
        } else {
            this.associatedJobFilterQuery['properties'] = this.property.selectedList.map((property: any) => {
                return property.id;
            });
        }
        this.getAssociatedJobData();
        if (event.length > 0) {
            this.fetchPropertyBasedLocation(this.property.selectedList[0].id);
        }
    }

    getTenantList() {
        let requestURL = environment.fastAPI_Actual_URL + `/companies/?columns=['id', 'name']&query={'type': ['Tenant']}`
        if (this.tenants.searchValue.trim() == '') {
            requestURL = requestURL.replace('&search=' , '');
        } else {
            requestURL = requestURL + `&search=${this.tenants.searchValue}`
        }
        if (this.tenants.page > 1) {
            requestURL = requestURL + `&page=${this.tenants.page}`
        }
        this._inboxService.getPropertyFilterAssociateJobs(requestURL).pipe(takeUntil(this.destroy$)).subscribe(
            {
                next: (response: any) => {    
                    this.tenants.totalPages = Math.floor(response.total / response.size);
                    if (this.tenants.page > 1) {
                        this.tenants.list = this.tenants.list.concat(response.items);
                    } else {
                        this.tenants.list = response.items ?? [];
                    }         
                },
                error: (error) => {
                },
                complete: () => {
                }
            });
    }

    tenantDropdownSearch(event: any) {
        this.tenants.searchValue = event.term;
        this.tenants.page = 1;
        this.getTenantList();
    }

    fetchTenantNextPage() {
        if (this.tenants.totalPages == this.tenants.page) {
            return null;
        } else {
            this.tenants.page += 1;
            this.getTenantList();
        }
    }

    changeTenant(event: any): void {
        this.tenants.searchValue = '';
        this.tenants.selectedList = event;
        if (this.tenants.selectedList.length == 0) {
            delete this.associatedJobFilterQuery['tenant_companies'];
        } else {
            this.associatedJobFilterQuery['tenant_companies'] = this.tenants.selectedList.map((tenant: any) => {
                return tenant.id;
            });
        }
        this.getAssociatedJobData();
    }

    getCategoriesList() {
        let requestURL = `${environment.baseURL}categories/`;
        if (this.categories.searchValue.trim() != '') {
            if (requestURL.includes('?')) {
                requestURL += `search=${this.categories.searchValue}`;
            } else {
                requestURL += `?search=${this.categories.searchValue}`;
            }
        }
        this._inboxService.getPropertyFilterAssociateJobs(requestURL).pipe(takeUntil(this.destroy$)).subscribe(
            {
                next: (response: any) => {
                    this.categories.list = response;
                },
                error: (error) => {
                },
                complete: () => {
                }
            });
    }

    categoriesDropdownSearch(event: any) {
        this.categories.searchValue = event.term;
        this.getCategoriesList();
    }

    changeCategories(event: any): void {
        this.categories.selectedList = event;
        if (this.categories.selectedList.length == 0) {
            delete this.associatedJobFilterQuery['categories'];
        } else {
            this.associatedJobFilterQuery['categories'] = this.categories.selectedList.map((category: any) => {
                return category.id;
            });
        }
        this.getAssociatedJobData();
    }

    fetchPropertyBasedLocation(propertyId: any) {
        let requestURL = `${environment.baseURL}properties/${propertyId}/units/`;
        if (this.location.searchValue.trim() !== '') {
            requestURL += 'custom-search=' + this.location.searchValue;
        }
        this._inboxService.getPropertyFilterAssociateJobs(requestURL).pipe(takeUntil(this.destroy$)).subscribe(
            {
                next: (response: any) => {
                    const unit = response.map((resp: any) => {
                        return resp.units.map((unit: any) => {
                            unit.title = unit.name;
                            unit.address = resp.address;
                            unit.value = `${unit.address} - ${unit.name}`;
                            return unit;
                        });
                    });

                    const locationList = [];
                    
                    unit.forEach(e => {
                        locationList.push(...e);
                    });

                    this.location.list = locationList;
                    
                },
                error: (error) => {
                },
                complete: () => {
                }
            });
    }

    locationsDropdownSearch(event: any): void {
        this.location.searchValue = event.term;
        this.fetchPropertyBasedLocation(this.property.selectedList[0].id);
    }

    changeLocations(event: any): void {
        this.location.searchValue = '';
        this.location.selectedList = event;
        if (this.location.selectedList.length == 0) {
            delete this.associatedJobFilterQuery['unit'];
        } else {
            this.associatedJobFilterQuery['unit'] = this.location.selectedList.map((location: any) => {
                return location.id;
            });
        }
        this.getAssociatedJobData();
    }

    submitAssociateJob(): void {
        let payload: AssociateJobPayload = {
            "threadId": this._inboxService.activeThread.id,
            "jobs": this.selectedJobs.map((data) => {
                return {"id": `${data.id}`, "isRemoved": false , "status":data.status}
            }),
            "isCreate": false
        }
        this._inboxService.AssociateJobs(payload).pipe(takeUntil(this.destroy$)).subscribe({
            next: () => {
                this.bulkJobStatusChange(payload.jobs);
                this._utilService.showSuccess('', 'Job Associated to this thread');
                const event = {
                    jobAssociated: true
                }
                this.dialogRef.close(event);
            },
            error: () => {
                this._utilService.showError('', 'Error Associating Job');
            },
            complete: () => {
            }
        });

    }


    private bulkJobStatusChange(jobs) {
        from(jobs).pipe(
            concatMap((job: any) =>
                this._inboxService.jobSeenUpdate(job?.id)
            ), takeUntil(this.destroy$)
        ).subscribe(
            response => { },
            error => { },
            () => { }
        );
    }

    onCloseProperty(){
        this.property.searchValue = '';
        this.property.page = 1;
        this.getPropertyList();
    }

    onCloseTenant(){
        this.tenants.searchValue = '';
        this.tenants.page = 1;
        this.getTenantList();
    }

    onCloseCategory(){
        this.categories.searchValue = ''
        this.getCategoriesList();
    }

    onCloseLocation(){
        this.location.searchValue = '';
        this.fetchPropertyBasedLocation(this.property.selectedList[0].id);
    }
}
