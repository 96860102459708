import { Component, EventEmitter, forwardRef, Input, OnInit, Output, } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { ItemList } from '../dropdown-select/itemType.class';
import { SearchPipe } from '../../../_pipes/_custom-pipes/search.pipe';
import { CardComponent } from '../../_components/card/card.component';
import { LoaderSmallComponent } from '../../_components/loader-small/loader-small.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { SearchInputComponent } from '../search-input/search-input.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
    selector: 'fyxt-filter-accordian',
    templateUrl: './filter-accordian.component.html',
    styleUrls: ['./filter-accordian.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => FilterAccordianComponent)
        }
    ],
    standalone: true,
    imports: [NgIf, MatExpansionModule, MatCheckboxModule, NgClass, MatIconModule, SearchInputComponent, FormsModule, NgxIntlTelInputModule, InfiniteScrollModule, LoaderSmallComponent, NgFor, CardComponent, SearchPipe]
})
export class FilterAccordianComponent implements OnInit, ControlValueAccessor {
  @Input() isNewFilter: boolean = false;
  @Input() moduleName: any;
  @Input() filterName: any;
  @Input() localSearchValue: boolean = false;
  @Input() loader: boolean = false;
  @Input() withoutPanel: boolean = false;
  @Input() enableSearch: boolean = true;
  @Input() isUnits: boolean = false;
  @Input() items!: ItemList[];
  @Input() placeHolder: string = 'Search';
  @Input() isExpanded: boolean = false;
  @Input() panelOpenState: boolean = false;

  @Output() onSearchResult = new EventEmitter<any>();
  @Output() filteredItem = new EventEmitter<any>();
  @Output() scrolled = new EventEmitter<any>();
  @Output() onEmitSearchValue = new EventEmitter<any>();
  // public panelOpenState: boolean = false;


  disabled = false;
  checkValues = [false, true, null];
  len = this.checkValues.length;
  index = 0;
  checked = this.checkValues[this.index]
  indeterminate: boolean = false;

  @Input() value: string;
  filterArray: any;
  @Input() input: any;
  localSearch: any;
  focused: boolean = false;

  constructor() { }

  ngOnInit(): void {

  }

  ngDoCheck() {
    if (this.input) {
      if (this.input.hasOwnProperty('indeterminate')) {
        this.input.indeterminate = false;
      }
      if (!this.input.hasOwnProperty('previous_selected_items')) {
        this.input.previous_selected_items = [];
      }

      if (this.input?.listItems) {
        this.filterArray = Array.isArray(this.input.listItems) ? this.input.listItems.filter((ele: any) => ele?.selected == true) : [];
        this.checkSelectAll();
      }
    }

  }

  onChange = (data: any) => { }
  onTouch = (_: any) => { }


  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(inputVal: any) {
    this.input = inputVal;
    if (this.input) {
      this.filterArray = Array.isArray(this.input.listItems) ? this.input.listItems.filter((ele: any) => ele.selected == true) : [];
    }
  }

  /**
  * Show Selected Filters
  */
  addFilteredItem(selectedItem: any, index: any) {
    // this.input.listItems[index].selected = !this.input.listItems[index].selected;
    // this.checkSelectAll();
    // this.filteredItem.emit(this.input);
    selectedItem.selected = !selectedItem.selected;
    this.checkSelectAll();
    this.filteredItem.emit(selectedItem);
  }

  /**
  * emit on scroll
  */
  onScroll(event: any) {
    // if (event.currentScrollPosition)
    //   this.input.scroll_position = event.currentScrollPosition;

    this.scrolled.emit(this.input);
  }

  /**
  * emit search result
  */
  onSearch(event: any) {
    this.localSearch = event;

    if (!this.localSearchValue) {

      /******* set Previously Selected Items to make selected action count ********/
      this.input.previous_selected_items = this.input.listItems.filter((ele) => ele?.selected == true) || [];

      const payload = {
        item: this.input,
        value: event
      };
      this.onSearchResult.emit(payload);
    }

  }

  emitSearchValue(value: string): void {
    this.onEmitSearchValue.emit(value);
  }

  /**
  * emit search result on click
  */
  search(val: any) {
    this.onSearchResult.emit(val);
  }

  /**
  * Triggered when select all
  */
  selectAll(event: any) {
    if (event.checked == true) {
      this.input.selectAll = true;
      this.input.listItems.map((ele: any) => {
        return ele.selected = true;
      })
    } else {
      this.input.selectAll = false;
      this.input.listItems.map((ele: any) => {
        return ele.selected = false;
      })
    }
    this.onChange(this.input);
    this.filteredItem.emit(this.input);
  }

  /**
 * To Check all items selected or not
 */
  checkSelectAll() {
    let selectCheck: boolean;

    if (this.input.listItems.length) {
      if (this.input?.isGrouped) {
        selectCheck = this.input.listItems.every((list) => {
          list.items.every(li => li.selected == true)
        })
      } else {
        selectCheck = this.input.listItems.every(function (element: any) {
          return element.selected == true;
        });
      }
    } else {
      selectCheck = false;
    }

    this.input.selectAll = selectCheck;

    this.checkSelectedItemList(this.input?.listItems);
  }

  focusin() {
    this.focused = true
  }

  focusout() {
    this.focused = false
  }

  checker() {
    this.checked = this.checkValues[++this.index % this.len];
    this.indeterminate = false;
    if (this.checked === null) {
      this.indeterminate = true;
    }
  }

  clicked(item) {
  }

  /**
  * To Check all items and make indeterminate check as minus
  */
  checkSelectedItemList(listItems: any[]) {
    let selectedItems = [];

    if (listItems?.length > 0) {
      selectedItems = listItems?.filter((obj: any) => obj.selected == true) || [];
    }

    this.input.indeterminate = (selectedItems?.length > 0 && !this.input.selectAll) ? true : false;
  }


  checkUncheckedItems(filterArray: any[]) {
    let unSelectedItems = this.input?.listItems.filter((obj: any) => obj.selected == false) || [];
    return (this.input?.totalCount - unSelectedItems.length);
  }

  /** Track by Index  **/
  trackByIndex(index: number, item: any): number {
    return index;
  }

  doCheckIsAdvanceFilter() {
    if (this.moduleName == 'Dashboard' && this.isExpanded) {
      return true;
    }
    return false;
  }
}
