import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { LeasesStoreService } from '../../../../_services/leases/leases-store.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigService } from '../../../../../services/config.service';
import { DocumentItem } from '../../../models/porperty-manager/documents';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { MatDividerModule } from '@angular/material/divider';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';
import { CheckboxComponent } from '../../../_reusable_ui/_controls/checkbox/checkbox.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { NgIf, NgFor } from '@angular/common';


export interface DocumentOptionsForm extends DocumentItem {
  name: string,
  url: string,
  pages: string
}


@Component({
    selector: 'fyxt-assign-document',
    templateUrl: './assign-document.component.html',
    styleUrls: ['./assign-document.component.scss'],
    standalone: true,
    imports: [NgIf, LoaderSmallComponent, NgFor, CheckboxComponent, TextboxComponent, InfiniteScrollModule, MatDividerModule, MatDialogModule, ButtonComponent]
})
export class AssignDocumentComponent implements OnInit {
  documentsOptions: DocumentOptionsForm[] = [];
  private readonly destroy$: Subject<null> = new Subject();

  public documents: DocumentItem[] = [];
  currentPage: number;
  isLoader: boolean = false;

  constructor(
    private readonly leasesStoreService: LeasesStoreService,
    public dialogRef: MatDialogRef<AssignDocumentComponent>,
    private readonly configService: ConfigService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.isLoader = true;
    this.onLoadDocuments(false);
  }

  handlePageChange(event: any, document: DocumentItem & { pages: string }) {
    this.documentsOptions = this.documentsOptions.map((item) => {
      if (item.id === document.id) {
        return { ...item, pages: event ,url: document.document.url};
      } else {
        return item;
      }
    });
    document.pages = event
  }

  onOptionCheck(document: DocumentItem & { pages: string }) {
    let updatedList: DocumentOptionsForm[];
    const isOptionExist = this.documentsOptions.some((item) => item.id === document.id);
    if (isOptionExist) {
      updatedList = this.documentsOptions.filter((item) => item.id !== document.id);
    } else {
      updatedList = [...this.documentsOptions, { ...document, name: document.title, pages: document?.pages || '',url: document.document.url}];
    }
    this.documentsOptions = updatedList;
  }

  isFieldEnabled(document: DocumentItem): boolean {
    return this.documentsOptions.some((item) => item.id === document.id);
  }

  onAssign(): void {
    const mappedData = this.documentsOptions.map((document) => {
      if (document.pages !== '') {
        return { ...document, name: `${document.name}: Page (${document.pages})` };
      }
      return document;
    });

    this.dialogRef.close({ id: this.data.id, options: mappedData });
  }

  onLoadDocuments(pagination: boolean): void {
    if (pagination)
      this.currentPage += 1;
    this.leasesStoreService
      .setQueryDocumentByLeaseParams({ take: 10, page: this.currentPage ?? 1 }, false)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (value) => {
          if (pagination) {
            this.documents = this.documents.concat(value.items);
            this.currentPage = value.page;
          } else {
            this.documents = value.items;
            this.currentPage = value.page;
          }
          this.isLoader = false;
        },
        error: () => {
          this.isLoader = false;
        }
      });
  }

   /** Track by Index  **/
   trackByIndex(index: number, item: any): number {
    return index;
  }
}
