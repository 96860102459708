// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  --mat-form-field-container-height: 48px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 14px;
}

::ng-deep .with_clear_date {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
}
::ng-deep .with_clear_date input {
  width: 75% !important;
}
::ng-deep .with_clear_date .clear_date_icon {
  font-size: 20px;
  color: #979797;
  float: right;
  cursor: pointer;
  width: 16px;
  height: 16px;
  align-content: center;
}
::ng-deep .with_clear_date .mat-form-field-suffix {
  top: 0.5em !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
::ng-deep .custom-outline.mat-form-field-appearance-outline.mat-form-field-invalid .mat-form-field-outline {
  opacity: 0.2 !important;
  color: #666666 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_reusable_ui/_controls/dropdown-select/dropdown-date-select/dropdown-date-select.component.scss"],"names":[],"mappings":"AAAA;EACE,uCAAA;EACA,4CAAA;EACA,iDAAA;AACF;;AAEA;EAcE,iIAAA;EACA,iIAAA;AAZF;AAFE;EACE,qBAAA;AAIJ;AADE;EACE,eAAA;EACA,cAAA;EACA,YAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,qBAAA;AAGJ;AACE;EACE,qBAAA;AACJ;;AAEA,iIAAA;AACA,iIAAA;AACA;EAGE,uBAAA;EACA,yBAAA;AADF","sourcesContent":["* {\n  --mat-form-field-container-height: 48px;\n  --mat-form-field-filled-label-display: block;\n  --mat-form-field-container-vertical-padding: 14px;\n}\n\n::ng-deep .with_clear_date {\n  input {\n    width: 75% !important;\n  }\n\n  .clear_date_icon {\n    font-size: 20px;\n    color: #979797;\n    float: right;\n    cursor: pointer;\n    width: 16px;\n    height: 16px;\n    align-content: center;\n  }\n  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */\n  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */\n  .mat-form-field-suffix {\n    top: 0.5em !important;\n  }\n}\n/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */\n/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */\n::ng-deep\n  .custom-outline.mat-form-field-appearance-outline.mat-form-field-invalid\n  .mat-form-field-outline {\n  opacity: 0.2 !important;\n  color: #666666 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
