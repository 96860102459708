import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import { ItemList } from 'src/app/@fyxt/_shared/_reusable_ui/_controls/dropdown-select/itemType.class';
import { ScrollToBottomDirective } from '../../../_directives';
import { Subject } from 'rxjs';
import {
  CompaniesDetailedViewService,
} from 'src/app/Modules/_fyxt_modules/companies/_services/companies-detailed-view.service';
import { CompaniesService } from 'src/app/Modules/_fyxt_modules/companies/_services/companies.service';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, map, takeUntil } from 'rxjs/operators'
import { ExtVendorJobsService } from 'src/app/Modules/_fyxt_modules/new-external-vendor/services/ext-vendor-jobs.service';
import { JobsService } from 'src/app/services/v2/jobs.service';
import { BaseService } from '../../../../../services/base.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { TextEditorComponent } from '../../../_reusable_ui/_controls/text-editor/text-editor.component';
import { NotesHolderComponent } from './notes-holder/notes-holder.component';
import { ThreeDotsDropdownComponent } from '../../../_reusable_ui/_components/three-dots-dropdown/three-dots-dropdown.component';
import { ThumbnailComponent } from '../../../_reusable_ui/_components/thumbnail/thumbnail.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TableListDropdownComponent } from '../../../_reusable_ui/_controls/dropdown-select/table-list-dropdown/table-list-dropdown.component';
import { NgIf, NgClass, NgFor, NgStyle, SlicePipe, DatePipe } from '@angular/common';

enum TabNotesMenuOptions {
  DELETE = 'delete',
  UPDATE = 'update'
}

@Component({
    selector: 'fyxt-tab-notes',
    templateUrl: './tab-notes.component.html',
    styleUrls: ['./tab-notes.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, FormsModule, ReactiveFormsModule, TableListDropdownComponent, NgxIntlTelInputModule, InfiniteScrollModule, LoaderSmallComponent, NgFor, ThumbnailComponent, ThreeDotsDropdownComponent, NgStyle, NotesHolderComponent, TextEditorComponent, ButtonComponent, MatIconModule, BsDatepickerModule, SlicePipe, DatePipe],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ width: 0, opacity: 0 }),
        animate('200ms ease-out', style({ width: '*', opacity: 1 }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ width: 0, opacity: 0 }))
      ])
    ])
  ]

})
export class TabNotesComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  private readonly destroy$: Subject<null> = new Subject();
  private readonly notesSearchDebounce = new Subject();
  @Input() isReadonly: boolean = false;
  showSearch = false;
  searchText = '';

  allUsers = [];
  selectedUser = [];
  selectedUserFromHtml = [];
  daterange:any;
  eventType:String = null;
  deBounce = new Subject();
  formSort!: FormGroup
  //Quil Editor Config
  quillConfig: any = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],            // toggled buttons
        // ['blockquote', 'code-block'],
        // [{ 'header': 1 }, { 'header': 2 }],                // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        // [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }],                 // outdent/indent
        [{ direction: 'rtl' }],                               // text direction
        // [{ 'size': ['small', false, 'large', 'huge'] }],   // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        // [{ color: [] }, { background: [] }],                  // dropdown with defaults from theme
        [{ font: [] }],
        // [{ align: [] }],
        ['clean']                                              // remove formatting button
        // ['link', 'image', 'video'],                         // link and image, video
        // ['emoji'],
      ]
    },
    keyboard: {
      bindings: {
        shiftEnter: {
          key: 13,
          shiftKey: true,
          handler: (range, context) => {
            this.saveButtonisClicked(this.newNotesText);
            return false;
          }
        }
      }
    }
  };
  image = "assets/images/users/avatar.png";


  systemMessageImage = 'assets/images/logo-small.png';
  newNotesText: string;
  todayDate = new Date().toISOString().split('T')[0];
  yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0];
  year = this.todayDate.split('-');

  listStatus: ItemList[] = [
    {id: 1, label: 'Newest First',  name: 'DESC'},
    {id: 2, label: 'Oldest First', name: 'ASC'}
    // {id: 2, label: 'Last Week',     name: 'LW'},
    // {id: 2, label: 'Last Month',    name: 'LM'},
    // {id: 2, label: 'Custom Range',    name: 'CR'},
  ];

  @Input() notes: any[] = [];
  @Input() title: string = 'Notes';
  @Input() noDataMsg: string = 'No Notes Yet';
  @Input() isLoader: boolean;
  @Input() isOnlyView: boolean = false;
  @Input() showloader: boolean;
  @Input() isEXV: boolean=false;
  @Input() tabHeight: any;
  @Input() isMobile: boolean = false;
  @Input() isPopup: boolean = false;
  @Input() hideActionMenu: boolean = false;
  @Input() entityType: string = '';
  @ViewChild(ScrollToBottomDirective) scroll!: ScrollToBottomDirective;
  @Output() sortChange = new EventEmitter();
  @Output() saveNewNote = new EventEmitter();
  @Output() scrollUp = new EventEmitter();
  @Output() deleteNote = new EventEmitter();
  @Output() updateNote = new EventEmitter();
  @Output() newMessagePopup = new EventEmitter();
  @Output() notesComponentDestroyed = new EventEmitter();
  @Output() searchChange = new EventEmitter();
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  @ViewChild('deleteDialogTemplate')
  deleteDialogTemplate!: TemplateRef<any>;
  @ViewChild('updateDialogTemplate')
  updateDialogTemplate!: TemplateRef<any>;
  @ViewChild('datePicker')
  datePicker!: TemplateRef<any>;
  selectedNote:any;
  loadUsersEvent: any;
  notesDisabled: boolean = false;
  threeDotDropdownMenuLists = [
    { label: 'Delete', value: TabNotesMenuOptions.DELETE },
    { label: 'Edit', value: TabNotesMenuOptions.UPDATE }
  ];
  @Input() userData = JSON.parse(localStorage.getItem('userobj'))?.id;
  managersList: any[] = [];
  @Input() inActiveProperty: boolean = false;


  constructor(private CompanyDetailViewService:CompaniesDetailedViewService,
    public _companiesService: CompaniesService,
    private router: Router,
    private cd: ChangeDetectorRef,
    public _dialogService: DialogService,
    public dialog: MatDialog,
    public _jobService: JobsService,
    private _externalVendor: ExtVendorJobsService,
    public _baseService: BaseService,) {
    }

  ngOnInit(): void {
    if (!this.isEXV) {
      this.loadUsers();
    } else {
      this.loadExternalVendorUsers();
    }
    setTimeout(() => {
      this.scrollToBottom();
    }, 2000);

    this.formSort = new FormGroup<any>({
      sort: new FormControl('Newest First')
    })
    if ((this.entityType === 'PROPERTY' && this._baseService.isPermissionExist('view_property') && !this._baseService.isPermissionExist('add_property')) || this.inActiveProperty) {
      this.notesDisabled = true;
    } else {
      this.notesDisabled = false;
    }

    if (!this.isEXV) {
      this.quillConfig.mention = {
        allowedChars: /^[A-Za-z\sÅÄÖåäö.]*$/,
        mentionDenotationChars: ['@', '#'],
        theme: 'snow',
        isolateCharacter: true,
        offsetTop: 0,
        defaultMenuOrientation: "top",
        onSelect: (item, insertItem) => {
          this.selectedUser.push({ id: item?.id, type: this.allUsers[item?.index].type });
          insertItem(item);
        },
        renderLoading: () => "Loading...",
        source: (searchTerm, renderList) => {
          if (this.entityType == 'JOB') {
            if (this.loadUsersEvent) this.loadUsersEvent.unsubscribe();
            this.loadUsersEvent = this.CompanyDetailViewService.searchJobMetions(this._jobService.selectedJobInfo.id, searchTerm)
              .pipe(takeUntil(this.destroy$))
              .subscribe((data: any) => {
                this.allUsers = data.data.searchJobUsers;
                this.allUsers.forEach(element => {
                  element.name = element.firstName + ' ' + element.lastName;
                  element.value = element.name;
                });
                renderList(this.allUsers);
              }, (err: any) => {
                renderList([{ value: 'Not found', disabled: true }]);
              });
          } else {
            if (this.loadUsersEvent) this.loadUsersEvent.unsubscribe();
            this.loadUsersEvent = this.CompanyDetailViewService.searchUserNotes(searchTerm)
              .pipe(takeUntil(this.destroy$))
              .subscribe((data: any) => {
                this.allUsers = data.data.searchUsers;
                this.allUsers.forEach(element => {
                  element.name = element.firstName + ' ' + element.lastName;
                  element.value = element.name;
                });
                renderList(this.allUsers);
              }, (err: any) => {
                renderList([{ value: 'Not found', disabled: true }]);
              });
          }
        },
        'emoji-toolbar': true,
        'emoji-textarea': false,
        'emoji-shortname': true,
        keyboard: {
          bindings: {
            enter: {
              key: 13,
              handler: (range, context) => true,
            }
          }
        }
      };
    }

    this.notesSearchDebounce.pipe(debounceTime(300))
    .subscribe((d: string) => {
      this.searchText = d;
      this.searchChange.emit(d);
    })
  }

  ngOnDestroy(): void {
    this.notesComponentDestroyed.emit(true);
    this.destroy$.next(null)
    this.destroy$.complete()
  }

  scrollToBottom(): void {
    try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) {
     }
   this.eventType = null;
  }


  onScrollDown(ev) {}

  onUp(ev) {
    if(!this.isEXV) {
      this.scrollUp.emit(true);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.scrollToBottom();
    }, 500);

  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes.notes) {
    //   if(this.eventType == 'ADD' || 'DATE'){
    //     setTimeout(() => {
    //       this.scrollToBottom();
    //     }, 500);
    //   }
    // }
  }


  contentChanged(event:any){
    this.newNotesText = event;
  }

  saveButtonisClicked(event:any){

    let obj = {
      data: this.newNotesText,
      // selected:this.selectedUser,
      selected: this.constructMentionedUsers(),
      url:  this.isEXV ? `${window.location.origin}/pm/job/${this._externalVendor.selectedJobInfo.id}?tab=notes` :`${window.location.origin}${this.router.url}?tab=notes`,
    }

    this.saveNewNote.emit(obj);
    this.newNotesText = '';
    this.eventType = 'ADD';
    this.selectedUser = [];

    setTimeout(() => {
      this.scrollToBottom();
    }, 300);
  }

  updateContentChanged(event){
    this.selectedNote.content = event;
  }

  sortValueChange(event){
    if (event.name == 'CR') {
      let popupData = {
        title: 'Update Note',
        template: this.datePicker,
        confirmText: 'Yes',
        cancelText: 'No',
      };
      this._dialogService.confirmDialog(popupData)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
        this.eventType = 'DATE';
        this.formSort.controls.sort.setValue(null);
      });

    } else {
      this.sortChange.emit(event.name);
      this.eventType = 'DATE';
    }
  }

  dateRangeSelected(){
    this.dialog.closeAll();
    this.sortChange.emit({value:'CR',range:this.daterange});

  }

  /**
   * On Change Event
   * @param event
   */
  selectedTags(event: any): void {}

  onScroll(){}

  menuOnSelect(event,data){
    let popupData;
    this.selectedNote = { ...data };
    if (event.value === TabNotesMenuOptions.DELETE) {
       popupData = {
        title: 'Delete Note',
        template: this.deleteDialogTemplate,
        confirmText: 'Delete',
        cancelText: 'Cancel',
        buttons: true,
      };

      this._dialogService.confirmDialog(popupData).pipe(takeUntil(this.destroy$)).subscribe((result) => {
        if (!result) return

        this.deleteNote.emit(this.selectedNote);
      });
     } else if (event.value === TabNotesMenuOptions.UPDATE) {
      popupData = {
        title: this.isEXV ? 'Edit Note' : 'Update Note',
        template: this.updateDialogTemplate,
        confirmText: 'Edit',
        cancelText: 'Cancel',
        buttons: true,
        containerClass:''
      };

      this._dialogService.confirmDialog(popupData).pipe(takeUntil(this.destroy$)).subscribe((result) => {
        if (!result) return

        this.updateNote.emit(this.selectedNote);
      });
    }
  }


  loadUsers(){
    this.CompanyDetailViewService.searchUserNotes('').pipe(takeUntil(this.destroy$)).subscribe((data:any)=>{

      this.allUsers = data.data.searchUsers
      if (this.allUsers.length > 0) {
        this.allUsers.forEach((element) => {
          element.name = element.firstName;
          element.value = element.firstName;
        });
      }
    },(err:any)=>{})
  }

  loadExternalVendorUsers() {
    this._externalVendor.getExternalVendorNotesUsers(this._externalVendor.selectedJobInfo.id).pipe(map((data: any) => data?.data?.data)).subscribe((data) => {
       this.managersList = data.filter((data) => data.type == 'Manager');
      if (this.managersList.length > 0) {
        this.managersList.forEach((element) => {
          element.name = element.firstName + ' ' + element.lastName;
          element.value = element.firstName + ' ' + element.lastName;
        });
      }
    })
  }

  getHeight() {
    //return 'height: calc(100vh - 100px - ' + (this.tabHeight) + 'px)'
    return 'height: calc(' + this.tabHeight + 'px - 290px)'
  }

  addNewMessage() {
    this.newMessagePopup.emit(true);
  }

  mentionsChanged(event) {
    this.selectedUserFromHtml = event;
  }

  constructMentionedUsers() {
    const arr1Set = new Set(this.selectedUserFromHtml);
    return this.selectedUser.filter(item => arr1Set.has(item.id)).map((u) => { return { id:u.id, urlToRedirect:  this.constuctRedirectURL(), type: u.type == 'Vendor' ? 'VENDOR' : 'USER' }});
  }

  constuctRedirectURL() {
    if (this.isEXV) {
     return `${window.location.origin}/pm/job/${this._externalVendor.selectedJobInfo.id}?tab=notes`
    }
    if (this.entityType == 'JOB') {
      return `${window.location.origin}/pm/job/${this._jobService.selectedJobInfo.id}?tab=notes`
    } else {
      return `${window.location.origin}${this.router.url}?tab=notes`
    }
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
    if (!this.showSearch) {
      this.searchText = '';
      this.searchChange.emit(this.searchText);
    }
  }

  searchTermUpdated(e) {
    this.notesSearchDebounce.next(e);
  }
}
