import { Component, Inject, OnInit } from '@angular/core';
import { ItemList } from 'src/app/@fyxt/_shared/_reusable_ui/_controls/dropdown-select/itemType.class';
import { GalleryMenuItems } from 'src/app/@fyxt/_shared/_reusable_ui/_components';
import { FilesService } from 'src/app/Modules/_fyxt_modules/companies/_services/files.service';
import { catchError, map, tap } from 'rxjs/operators';
import { ColumnSortType, TableColumnTypes } from '../../../enums/table/table-column-types.enum';
import { TableService } from 'src/app/@fyxt/_services/table/table.service';
import { forkJoin, of } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigService } from 'src/app/services/config.service';
import { environment } from 'src/environments/environment';
import { BaseService } from 'src/app/services/base.service';
import { JobsService } from 'src/app/services/v2/jobs.service';
import { GalleryImageComponent } from '../../../_reusable_ui/_components/gallery-image/gallery-image.component';
import { TableComponent } from '../../../_reusable_ui/_components/table/table.component';
import { MatDividerModule } from '@angular/material/divider';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { CheckboxComponent } from '../../../_reusable_ui/_controls/checkbox/checkbox.component';
import { IconSwitchComponent } from '../../../_reusable_ui/_components/icon-switch/icon-switch.component';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { NgIf, NgFor } from '@angular/common';
import { TabComponent } from '../../../_reusable_ui/_components/tab/tab.component';


@Component({
    selector: 'app-tab-photobank-popup',
    templateUrl: './tab-photobank-popup.component.html',
    styleUrls: ['./tab-photobank-popup.component.scss'],
    standalone: true,
    imports: [TabComponent, NgIf, ButtonComponent, IconSwitchComponent, CheckboxComponent, FormsModule, NgxIntlTelInputModule, MatDividerModule, TableComponent, NgFor, GalleryImageComponent]
})
export class TabPhotobankPopupComponent implements OnInit {

  moduleName = 'company';
  urlString = 'me';
  filesTableDataSource: any;

  photos: any[] = [];
  selectedPhotos: any[] = [];
  selectAll = false;
  currentFilePath = '/';


  listStatus: ItemList[] = [
    { id: 1, label: 'Newest First', name: 'Newest First' },
    { id: 2, label: 'Oldest First', name: 'Oldest First' },
    { id: 3, label: 'Last Week', name: 'Last Week' },
    { id: 4, label: 'Last Month', name: 'Last Month' },
  ];
  tabList = [
    { name: 'My Photo Bank', count: '0', active: true, disable: false },
    { name: 'Shared with Me', count: '0', active: false, disable: false },
    { name: 'Shared with Others', count: '0', active: false, disable: false },
  ];

  filesTableData = {
    filesSearchValue: '',
    filesTableHeader: [
      {
        value: 'checkbox',
        name: '',
      }, {
        value: 'file',
        sort: ColumnSortType.none,
        name: 'Name',
        type: TableColumnTypes.textWithImage,
        className: 'table-header',
        resizeColumn: true,
        sortDisabled : true
      },
      {
        value: 'file1',
        sort: ColumnSortType.none,
        name: 'Title',
        type: TableColumnTypes.text,
        className: 'table-header',
        resizeColumn: true,
        sortDisabled : true
      },
      {
        value: 'created_at',
        sort: ColumnSortType.none,
        name: 'Date Added',
        type: TableColumnTypes.date,
        className: 'table-header',
        resizeColumn: true,
        sortDisabled : true
      },
      {
        value: 'mime_type',
        sort: ColumnSortType.none,
        name: 'File Type',
        type: TableColumnTypes.textWithColor,
        className: 'table-header',
        resizeColumn: true,
        isChangeTextColor: true,
        sortDisabled : true
      },
    ],
    filesTableRows: [],
    filesSort: {
      active: '',
      direction: ''
    },
  };
  selectedTab: any;

  public show: boolean = false;
  public tableDiv: boolean = true;
  totalRows:any = 1;
  pageIndexAPI:any = 1;
  perPage:any = 25;
  currentPage:any = 0;
  // buttonName: string;
  vendorList:any[] = [];

  constructor(private _fileService: FilesService,
    private tableService: TableService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TabPhotobankPopupComponent>,
    private _configService:ConfigService,
    private _baseService:BaseService,
    private _jobService:JobsService
  ) {
    this.currentFilePath = data.data.currentFilePath;

    if(data.data.entityType == 'JOB' && this._jobService.selectedJobInfo.id){
      this.getAllVendors();
    }

  }

  ngOnInit(): void {
    this.getCount();
  }

  allRowisSelected(event: any) {
    if (event) {
      this.selectedPhotos = [];
      this.filesTableData.filesTableRows.forEach(element => {
        let obj = {
          "entityId": this.data.data.entityId,
          "entityType": this.data.data.entityType,
          "url": element.image
        }
        this.selectedPhotos.push(obj);
      });
    } else {
      this.selectedPhotos = [];
    }
  }


  getCount(){
    this._fileService.photoBankMyPhotos('shared-with-others').subscribe((data:any)=>{
      this.tabList.forEach(element => {
        if(element.name == 'Shared with Others'){
          element.count = data.data?.count
        }
      });
    });
    this._fileService.photoBankMyPhotos('shared-with-me').subscribe((data:any)=>{
      this.tabList.forEach(element => {
        if(element.name == 'Shared with Me'){
          element.count = data.data?.count
        }
      });
    })
  }

  /**
   * On Select Tab
   * @param selectedTabValue
   */
  onSelectTab(selectedTabValue: any) {
    this.selectedTab = selectedTabValue;
    if (this.selectedTab.name == 'My Photo Bank') {
      this.urlString = 'me'
      const offset = (this.pageIndexAPI - 1) * (this.perPage ? this.perPage : 25);
      this.urlString = this.urlString + '?page=' + this.pageIndexAPI + '&limit=' + (this.perPage ? this.perPage : 25);
      this._fileService.photoBankMyPhotos(this.urlString).pipe(map((x: any) =>{
        this.totalRows = x?.data?.count;
        this.tabList[this.tabList.findIndex(o=>o.name == this.selectedTab.name)].count = x?.data?.count;
        return x?.data?.results
      })).subscribe((data: any) => {
        this.selectedPhotos = [];
        data.forEach(item => {
          item.checked = false;
          item.image = item.file;
        });
        this.filesTableData.filesTableRows = data;
        this.photos = data;
        this.filesTableDataSource = this.tableService.setTableData('FILES', this.filesTableData.filesTableHeader, this.filesTableData.filesTableRows, this.totalRows, this.currentPage, this.perPage);
      })
    }
    if (this.selectedTab.name == 'Shared with Others') {
      this.currentPage = 0;
      this.pageIndexAPI = 1;
      this.urlString = 'shared-with-others'
      const offset = (this.pageIndexAPI - 1) * (this.perPage ? this.perPage : 25);
      //this.urlString = this.urlString + '?page=' + this.pageIndexAPI + '&limit=' + (this.perPage ? this.perPage : 25) + '&offset=' + offset  ;
      this._fileService.photoBankMyPhotos(this.urlString).pipe(map((x: any) =>{
        this.totalRows = x?.data?.count;
        this.tabList[this.tabList.findIndex(o=>o.name == this.selectedTab.name)].count = x?.data?.count;
        return x?.data?.results
      })).subscribe((data: any) => {
        this.selectedPhotos = [];
        data.forEach(item => {
          item.checked = false;
          item.image = item.file;
        });
        this.filesTableData.filesTableRows = data;
        this.photos = data;
        this.filesTableDataSource = this.tableService.setTableData('FILES', this.filesTableData.filesTableHeader, this.filesTableData.filesTableRows, this.totalRows, this.currentPage, this.perPage);
      })
    }
    if (this.selectedTab.name == 'Shared with Me') {
      this.currentPage = 0;
      this.pageIndexAPI = 1;

      this.urlString = 'shared-with-me'
       const offset = (this.pageIndexAPI - 1) * (this.perPage ? this.perPage : 25);
       //this.urlString = this.urlString + '?page=' + this.pageIndexAPI + '&limit=' + (this.perPage ? this.perPage : 25) + '&offset=' + offset  ;
      this._fileService.photoBankMyPhotos(this.urlString).pipe(map((x: any) =>{
        this.totalRows = x?.data?.count;
        this.tabList[this.tabList.findIndex(o=>o.name == this.selectedTab.name)].count = x?.data?.count;
        return x?.data?.results
      })).subscribe((data: any) => {
        this.selectedPhotos = [];
        data.forEach(item => {
          item.checked = false;
          item.image = item.file;
        });
        this.filesTableData.filesTableRows = data;
        this.photos = data;
        this.filesTableDataSource = this.tableService.setTableData('FILES', this.filesTableData.filesTableHeader, this.filesTableData.filesTableRows, this.totalRows, this.currentPage, this.perPage);
      })
    }
    if (this.pageIndexAPI == undefined || this.pageIndexAPI == 0) {
      this.pageIndexAPI = 1;
    }

  }

  onGalleryMenuClick(menuItem: GalleryMenuItems, photo: any) {}

  photoIsSelected(event) {
    let exist = false;
    let existIndex;
    let obj = {
      "entityId": `${this.data.data.entityId}`,
      "entityType": this.data.data.entityType,
      "url": event.image
    }

    this.selectedPhotos.forEach((element,index) => {
      if(element.url == event.image){
        exist = true;
        existIndex = index;
      }else{
        exist = false;
      }
    });


    if(!exist){
      this.selectedPhotos.push(obj);
    }else{
      this.selectedPhotos.splice(existIndex,1)
    }


  }



  addAllFiles() {
    this.photos.forEach(ele =>{
      let obj = {
        "entityId": `${this.data.data.entityId}`,
        "entityType": this.data.data.entityType,
        "url": ele.file
      }
      this.selectedPhotos.push(obj)
    })
  }

  removeAllFiles() {
    this.selectedPhotos = [];
  }

  toggle() {
   // this.show = !this.show;
    this.tableDiv = !this.tableDiv;
    // this.selectedPhotos = [];
  }

  addFiles() {
    if (this.data.data.entityId != null) {
      this._configService.isLoader = true;
      let success = 0;
      let errors = 0;
      const reqs = this.selectedPhotos.map(url =>
        this._fileService.addFilePhotoBank(url).pipe(
          tap((_:any) => {
          // HANDLING THIS IN BE
          //this.assignFileToApprovedVendors(_.data,this.vendorList);
          }
        ),
          catchError(err => {
            errors++;
            return of(err);
          })
        )

      );
      forkJoin(reqs).subscribe(
        null,
        err => {},
        () => {this.dialogRef.close({ event: 'photobank updated' });this._configService.isLoader = false;}
      );
    } else if (this.data.data.entityId == null) {
      this._configService.isLoader = true;
      let selected = [];
      let responce = []
      this.selectedPhotos.forEach(element => {
        let obj = {
          "url": element.url
        }
        selected.push(obj);

      });
      let success = 0;
      let errors = 0;
      const reqs = selected.map(url =>
        this._fileService.addTempFilePhotoBank(url).pipe(
          tap(_ => success++),
          catchError(err => {
            errors++;
            return of(err);
          })
        )

      );
      forkJoin(reqs).subscribe(
        (response: any) => {
          this.dialogRef.close({ event: 'close', files: response });
          this._configService.isLoader = false;
        },
        err => {}
      );



    }

  }

  tableRowChecked(event) {
    let fileRow: number[];
    let obj = {
      "entityId": this.data.data.entityId,
      "entityType": this.data.data.entityType,
      "url": event.image,
      "path": this.currentFilePath
    }
    const isFileChecked = this.selectedPhotos.some((chekcedEquipment) => chekcedEquipment.url === obj.url);
    if (isFileChecked) {
      fileRow = this.selectedPhotos.filter((chekcedEquipment) => chekcedEquipment.url !== obj.url);
    } else {
      fileRow = [...this.selectedPhotos, obj];
    }
    this.selectedPhotos = fileRow;


  }

  onClickMonth(event){
    this.selectedPhotos = [];
    if(event == true){
      this.addAllFiles();
    }else {
      this.removeAllFiles();
    }

  }

  onPropertiesPageChange(event: any) {
    this.perPage = event.pageSize;
    this.currentPage = event.pageIndex;
    this.pageIndexAPI = event.pageIndex + 1;
    this.onSelectTab(this.selectedTab);
    this.removeAllFiles();
  }

  checkedPhoto(photo) {
    return this.selectedPhotos.some(e => e.url === photo.image);
  }

  getAllVendors() {
    if(!this._jobService.selectedJobInfo.id){
      return null;
    }
    let requestURL = environment.baseURL + `vendor-jobs/assigned-vendors/?page=1&limit=25&offset=0&job_id=${this._jobService.selectedJobInfo.id}`;
    this._baseService.doGET(requestURL).subscribe({
      next: (res: any) => {
        this.vendorList = res?.results ?? [];
        this.vendorList.forEach(e => {
          e["name"] = e.company_name;
        });
      },
      error: (error) => {
      },
      complete: () => {
      }
    })
  }


  assignFileToApprovedVendors(file, vendorList) {

    vendorList.forEach(vendor => {

      if (vendor.approve_to_work == 'Approved') {

        const payload = {
          "fileId": file.id,
          "userId": vendor.company_id,
          "userType": "VENDOR",
          "permissionLevel": "READ",
          "createdByType": "USER"
        }

        this._fileService.addFilePermission(payload).subscribe((res) => {
        })
      }

    });

  }

   /** Track by Index  **/
   trackByIndex(index: number, item: any): number {
    return index;
  }
}
