import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SidebarService } from 'src/app/@fyxt/_services/_layout/sidebar.service';
import { LeaseTabFileInformationComponent } from '../lease-tab-file-information/lease-tab-file-information.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../../../../@fyxt/_shared/_reusable_ui/_components/button/button.component';


@Component({
    selector: 'fyxt-lease-file-information-slide',
    templateUrl: './lease-file-information-slide.component.html',
    styleUrls: ['./lease-file-information-slide.component.scss'],
    standalone: true,
    imports: [ButtonComponent, MatIconModule, MatDividerModule, LeaseTabFileInformationComponent]
})
export class LeaseFileInformationSlideComponent implements OnInit {

  threeDotDropdownMenuLists: any[] = [
    { label: 'Edit', value: 'Edit' },
    { label: 'Delete', value: 'Delete' },
  ];

  selectedTab: any;
  tabList = [
    { name: 'Detail', value: 'Detail', active: true, disable: false },
    { name: 'Reminder', value: 'Reminder', count: 0, active: false, disable: false },
  ];

  @Input() chipList: any[] = [];
  @Output() onChangeCategories = new EventEmitter();
  @Output() onClose = new EventEmitter();

  constructor(
    public sidebarService: SidebarService,
  ) {
  }

  ngOnInit(): void {}

  /**
   * On Select Tab
   * @param selectedTabValue
   */
  onSelectTab(selectedTabValue: any) {
    this.selectedTab = selectedTabValue;
  }

  changeCategory(event: any): void {
    this.onChangeCategories.emit(event);
  }

  onDismiss() {
    this.onClose.emit('closed');
  }

  onCancel() {
    this.sidebarService.toggleCustom();
  }

  menuOnSelect(event: any) {
    if (event.value === 'Edit') {
      // this.renameTemplate();
    }
  }
}
