import { GraphqlEquipmentManagementService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/equipment-management/graphql-equipment-management.service';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ItemList } from 'src/app/@fyxt/_shared/_reusable_ui/_controls/dropdown-select/itemType.class';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { GraphQLError } from 'graphql';
import { LEASES_FILTERS } from "../../../../../Modules/_fyxt_modules/leases/leases.constants";
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { CheckboxComponent } from '../../../_reusable_ui/_controls/checkbox/checkbox.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';
import { ChipComponent } from '../../../_reusable_ui/_components/chip/chip.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'fyxt-add-view',
    templateUrl: './add-view.component.html',
    styleUrls: ['./add-view.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgFor, ChipComponent, TextboxComponent, NgxIntlTelInputModule, CheckboxComponent, MatDialogModule, ButtonComponent]
})
export class AddViewComponent implements OnInit {
  @ViewChild('closePopup') closePopup: ElementRef;

  currentUserInfo: any = localStorage.getItem('userobj')
    ? JSON.parse(localStorage.getItem('userobj'))
    : {};

  _viewModule: string


  chipList: ItemList[] = [];


  //  this._utilService.customFieldValidator(/^[a-zA-Z0-9\-\s]*$/, "Please enter valid view Name")

  addViewForm = new FormGroup({
    viewName: new FormControl('', [Validators.required]),
    isPinned: new FormControl<boolean>(true),
    isShareToOthers: new FormControl<boolean>(true),
  });

  isLoaderForSave: boolean = false;
  isLoaderForUpdate: boolean = false;
  selectedFilters: any[] = [];
  selectedView: any = {};
  filterTableColumns = [];
  filterPayload: any = {
    query: {}
  };
  sort: any = {
    active: '',
    direction: ''
  }
  none: any[] = [{ name: "None", id: null }]
  setPayloadFilterQuery: any = {};
  customSelectedFilters: any[] = [];
  isFromScheduleViewExport = false;

  constructor(
    public _dialogService: DialogService,
    public _utilService: UtilityService,
    public dialogRef: MatDialogRef<AddViewComponent>,
    public graphql: GraphqlEquipmentManagementService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this._viewModule = this._dialogService._viewModule;
    if (this._viewModule === 'service-planner') {
      this._viewModule = 'planner';
    }
    if (this._viewModule == 'equipment' || this._viewModule == 'equipment_type' || this._viewModule == 'additional_data_item') {
      let selectedFiltersObj: any = [];
      this.data?.data?.selectedFilter.forEach((element: any) => {
        selectedFiltersObj.push(...element);
      });
      this.chipList = selectedFiltersObj;
    }
    else {
      this.chipList = this.data?.data?.selectedFilter || [];
      this.filterPayload.query = this.data.data.filterQuery ? this.data.data.filterQuery : {};
      this.customSelectedFilters = this.data.data.customFilters ?? [];
    }
    this.selectedView = this.data?.data?.selectedView;
    this.filterTableColumns = this.data?.data?.orderedColumns || [];
    this.isFromScheduleViewExport = this.data?.data?.isFromScheduleViewExport ? this.data.data.isFromScheduleViewExport : false;

    this.filterTableColumns = this.filterTableColumns.filter(item => item.value !== "checkbox" && item.value !== "action");

    this.sort = this.data?.data?.sortValue;
  }

  ngOnInit(): void {
    if (this.selectedView?.created_by == this.currentUserInfo?.id) {
      this.updateForm();
    }
    this.getFiltersFromQuery();
  }

  /**
 * On Change Event
 * @param event
 */
  slideToggleChange(event: any): void { }

  /**
* On Change Event
* @param event
*/
  selectedTags(event: any): void {
    this.selectedFilters = event;
    this.chipList = event;
  }

  updateForm(): void {
    this.addViewForm.patchValue({
      viewName: this.selectedView.name,
      isPinned: this.selectedView.pin,
      isShareToOthers: this.selectedView.shared
    })
  }

  removeSelectedFilters(event: any): any {
    if (this._viewModule == 'company') {
      this.removeCompanyFilters(event)
    } else if (this._viewModule == 'contact') {
      this.removeContactFilters(event)
    } else if (this._viewModule == 'equipment') {
      this.removeEquipmentFilters(event)
    } else if (this._viewModule == 'timeSheet') {
      this.removeTimeSheetFilters(event);
    }
    else if (this._viewModule == 'dashboard' || this._viewModule == 'property') {
      this.onDeletedTag(event);
    } else if (this._viewModule === 'coi') {
      this.removeCoiFilters(event);
    } else if (this._viewModule === 'lease') {
      this.removeLeaseFilters(event);
    } else if (this._viewModule == 'planner') {
      this.removeServicePlannerFilter(event);
    }
  }

  onDeletedTag(filterChip: any) {
    // Delete chips from the chipsList also
    const deleteChipListIndex = this.chipList.findIndex((chip) => {
      return chip.name == filterChip.name;
    });
    if (deleteChipListIndex != -1) {
      this.chipList.splice(deleteChipListIndex, 1);
    }


    // Type filter
    this.data.data.filterQuery.map((ele: any, i: any) => {
      if (ele.listItems) {
        ele.listItems.map((element: any, i: any) => {
          if (element.id == filterChip.id || element.name == filterChip.name) {
            element.selected = false;
          }
        })
      }
    });

    //Date Filter
    if (filterChip.isDateChip) {
      if (filterChip.type == 'jobcost') {
        this.data.data.dateFilters['jobcost_amount'] =  null;
      } else {
        this.data.data.dateFilters[filterChip.type] =  null;
      }
    }


    this.setQueryParams();

  }


  setQueryParams() {

    this.setDateFilters_Dashboard(this.data.data.dateFilters);
    let selectedFilters = this.data.data.filterQuery.filter((element) => element.listItems != undefined).map((element) => { let kk = this.setKeyValuepushToAry(this.setColumnNames(element.name), this.setValueByKey(element)); return kk });
    if (selectedFilters.length > 0) {
      selectedFilters.forEach((element: any) => {
        let keys: any = Object.keys(element);
        let selectedItems = element[keys];
        if (selectedItems.length > 0) {
          if (keys.includes('geographical_address')) {
            this.setPayloadFilterQuery[keys] = (selectedItems?.length > 0) ? selectedItems[0] : {};
          }
          else {
            this.setPayloadFilterQuery[keys] = selectedItems;
          }
        }
        else {
          delete this.setPayloadFilterQuery[keys];
        }

      });
    }

    return this.setPayloadFilterQuery;
  }


  setDateFilters_Dashboard(filtersDataSource: any) {
    if (filtersDataSource.hasOwnProperty('date_created')) {

      if (filtersDataSource.date_created?.value) {
        this.setPayloadFilterQuery.created_at = this.setCommonDateRangeValue(filtersDataSource.date_created);
      } else {
        delete this.setPayloadFilterQuery['created_at'];
      }

    }

    if (filtersDataSource.hasOwnProperty('days_in_status')) {

      if (filtersDataSource.days_in_status) {
        let setDateParams = {
          timeline: Number(filtersDataSource.days_in_status),
          custom: null
        };

        this.setPayloadFilterQuery.days_in_status = setDateParams;
      } else {
        delete this.setPayloadFilterQuery['days_in_status'];
      }

    }

    if (filtersDataSource.hasOwnProperty('last_activity')) {

      if (filtersDataSource.last_activity?.value) {
        this.setPayloadFilterQuery.last_activity = this.setCommonDateRangeValue(filtersDataSource.last_activity);
      } else {
        delete this.setPayloadFilterQuery['last_activity'];
      }

    }

    if (filtersDataSource.hasOwnProperty('target_completion')) {

      if (filtersDataSource.target_completion?.value) {
        this.setPayloadFilterQuery.target_completion = this.setCommonDateRangeValue(filtersDataSource.target_completion);
      } else {
        delete this.setPayloadFilterQuery['target_completion'];
      }

    }

    if (filtersDataSource.hasOwnProperty('schedule')) {

      if (filtersDataSource.schedule?.value) {
        this.setPayloadFilterQuery.schedule = this.setCommonDateRangeValue(filtersDataSource.schedule);
      } else {
        delete this.setPayloadFilterQuery['schedule'];
      }

    }


    if (filtersDataSource.hasOwnProperty('jobcost_date_approved')) {

      if (filtersDataSource.jobcost_date_approved?.value) {
        this.setPayloadFilterQuery.jobcost_date_approved = this.setCommonDateRangeValue(filtersDataSource.jobcost_date_approved);
      } else {
        delete this.setPayloadFilterQuery['jobcost_date_approved'];
      }

    }


    if (filtersDataSource.hasOwnProperty('invoice_submitted_date')) {

      if (filtersDataSource.invoice_submitted_date?.value) {
        this.setPayloadFilterQuery.invoice_submitted_date = this.setCommonDateRangeValue(filtersDataSource.invoice_submitted_date);
      } else {
        delete this.setPayloadFilterQuery['invoice_submitted_date'];
      }

    }

    if (filtersDataSource.hasOwnProperty('invoice_approved_date')) {

      if (filtersDataSource.invoice_approved_date?.value) {
        this.setPayloadFilterQuery.invoice_approved_date = this.setCommonDateRangeValue(filtersDataSource.invoice_approved_date);
      } else {
        delete this.setPayloadFilterQuery['invoice_approved_date'];
      }

    }

    if (filtersDataSource.hasOwnProperty('bid_submitted_date')) {

      if (filtersDataSource.bid_submitted_date?.value) {
        this.setPayloadFilterQuery.bid_submitted_date = this.setCommonDateRangeValue(filtersDataSource.bid_submitted_date);
      } else {
        delete this.setPayloadFilterQuery['bid_submitted_date'];
      }

    }

    if (filtersDataSource.hasOwnProperty('bid_approved_date')) {

      if (filtersDataSource.bid_approved_date?.value) {
        this.setPayloadFilterQuery.bid_approved_date = this.setCommonDateRangeValue(filtersDataSource.bid_approved_date);
      } else {
        delete this.setPayloadFilterQuery['bid_approved_date'];
      }

    }

    if (filtersDataSource.hasOwnProperty('jobcost_amount')) {

      if (filtersDataSource.jobcost_amount?.from && filtersDataSource.jobcost_amount?.to) {
        this.setPayloadFilterQuery.jobcost = filtersDataSource.jobcost_amount;
      }
      else {
        if (filtersDataSource.jobcost_amount?.value) {
          this.setPayloadFilterQuery.jobcost = filtersDataSource.jobcost_amount?.value;
        } else {
          delete this.setPayloadFilterQuery['jobcost'];
        }
      }

      // if (filtersDataSource.jobcost_amount?.value) {
      //   this.setPayloadFilterQuery.jobcost = this.setCommonDateRangeValue(filtersDataSource.jobcost_amount);
      // } else {
      //   delete this.setPayloadFilterQuery['jobcost'];
      // }

    }


    if (filtersDataSource.hasOwnProperty('invoice_amount')) {

      if (filtersDataSource.invoice_amount?.from && filtersDataSource.invoice_amount?.to) {
        this.setPayloadFilterQuery.invoice_amount = filtersDataSource.invoice_amount;
      }
      else {
        if (filtersDataSource.invoice_amount?.value) {
          this.setPayloadFilterQuery.invoice_amount = filtersDataSource.invoice_amount?.value;
        } else {
          delete this.setPayloadFilterQuery['invoice_amount'];
        }
      }

    }


    if (filtersDataSource.hasOwnProperty('bid_amount')) {

      if (filtersDataSource.bid_amount?.from && filtersDataSource.bid_amount?.to) {
        this.setPayloadFilterQuery.bid_amount = filtersDataSource.bid_amount;
      }
      else {
        if (filtersDataSource.bid_amount?.value) {
          this.setPayloadFilterQuery.bid_amount = filtersDataSource.bid_amount?.value;
        } else {
          delete this.setPayloadFilterQuery['bid_amount'];
        }
      }

    }

  }

  setCommonDateRangeValue(event: any) {

    let setDateParams = {};

    if (event.value == 'Custom' || event.value?.custom) {
      if (event.dateRange && event.dateRange.length > 0) {
        setDateParams = {
          timeline: null,
          custom: {
            "from": this._utilService.formatDateToMM_DD_YYYY(event.dateRange[0]),
            "to": this._utilService.formatDateToMM_DD_YYYY(event.dateRange[1])
          }
        };
      } else {
        if (event.name != 'Custom') {
          let splitDateArr = event.name.split(' - ');

          if (splitDateArr.length > 0) {
            if (splitDateArr[0] && splitDateArr[1]) {
              let from = splitDateArr[0].replaceAll(/\s/g, '').replace(/[()]/g, '');
              let to = splitDateArr[1].replaceAll(/\s/g, '').replace(/[()]/g, '');

              setDateParams = {
                timeline: null,
                custom: {
                  "from": from,
                  "to": to
                }
              };
            }

          }
        }
      }
    }
    else {
      setDateParams = {
        timeline: (event.type === "schedule" && (event.name === "Current Week" || event.name === "Current Month")) ? event.value : Number(event.value),
        custom: null
      };
    }

    return setDateParams;

  }


  setValueByKey(element) {


    let listItems = (element.listItems.filter((subElement) => subElement.selected === true)) || [];

    if (this._viewModule == 'property') {
      if (element.name == 'Property Type' || element.name == 'Status') {
        listItems = listItems.map(obj => obj.id);
      }
      else if (element.name == 'Property External Code' || element.name == 'Lease External Code') {
        listItems = listItems.map(obj => obj.name);
      }
      else if (element.name == 'Geographical Area') {
        listItems = element.listItems;
      } else {
        listItems = listItems.map((obj) => { let newVal = { id: obj.id, name: obj.name }; return newVal; });
      }
    }
    else {
      if (element.name == 'Billable Party' || element.name == 'Actions' || element.name == 'Job Cost Type' || element.name == 'Filter By' || element.name == 'Checklist' || element.name == 'Invoices' || element.name == 'Bids') {
        listItems = listItems.map(obj => obj.value);
      }
      else if (element.name == 'Source Type' || element.name == 'Actions' || element.name == 'Property External Code' || element.name == 'Lease External Code') {
        listItems = listItems.map(obj => obj.name);
      }
      else if (element.name == 'Status') {
        listItems = listItems.map((obj) => { let newVal = { id: obj.id, name: obj.name, value: obj.value }; return newVal; });
      }
      else {
        listItems = listItems.map((obj) => { let newVal = { id: obj.id, name: obj.name }; return newVal; });
      }
    }


    return listItems;
  }

  setKeyValuepushToAry(name, val) {
    var obj = {};
    obj[name] = val;

    return obj;
  }

  setColumnNames(name: any) {

    let columnName = '';
    if (this._viewModule == 'property') {
      switch (name) {
        case 'Tenant':
          columnName = 'tenants';
          break;
        case 'Property Type':
          columnName = 'type';
          break;
        case 'Status':
          columnName = 'status';
          break;
        case 'Geographical Area':
          columnName = 'geographical_address';
          break;
        case 'Property Group':
          columnName = 'property_group';
          break;
        case 'Job Cost Approver':
          columnName = 'job_cost_approvers';
          break;
        case 'Property Name':
          columnName = 'name';
          break;
        case 'State':
          columnName = 'state';
          break;
        case 'City':
          columnName = 'city';
          break;
        case 'Property External Code':
          columnName = 'external_code';
          break;
        case 'Lease External Code':
          columnName = 'lease_external_code';
          break;
        case 'Teams':
          columnName = 'teams';
          break;
        default:
          break;

      }
    }
    else {
      switch (name) {
        case 'Billable Party':
          columnName = 'responsible';
          break;

        case 'Source Type':
          columnName = 'source';
          break;

        case 'Job Cost Type':
          columnName = 'jobcost_type';
          break;

        case 'Actions':
          columnName = 'actions';
          break;

        case 'Priority':
          columnName = 'priority';
          break;

        case 'Property':
          columnName = 'properties';
          break;

        case 'Category':
          columnName = 'categories';
          break;

        case 'Tenant':
          columnName = 'tenants';
          break;

        case 'Vendor':
          columnName = 'vendors';
          break;
        case 'Assigned Vendor':
          columnName = 'vendors';
          break;
        case 'Approved Vendor':
          columnName = 'approved_vendor';
          break;

        case 'Owner Group':
          columnName = 'owners';
          break;

        case 'Date':
          columnName = 'date';
          break;

        case 'Service Type':
          columnName = 'service_type';
          break;

        case 'Created By':
          columnName = 'created_by';
          break;

        case 'Assigned To':
          columnName = 'assignees';
          break;

        case 'Engineers':
          columnName = 'engineers';
          break;

        case 'Assigned Manager':
          columnName = 'managers';
          break;
        case 'Assigned Follower':
          columnName = 'followers';
          break;
        case 'Assigned or Followed':
          columnName = 'assigned_followed';
          break;
        case 'Assigned Engineer':
          columnName = 'engineers';
          break;

        case 'Status':
          columnName = 'stage';
          break;
        case 'Filter By':
          columnName = 'filter_by';
          break;
        case 'Checklist':
          columnName = 'checklist';
          break;
        case 'Invoices':
          columnName = 'invoice_status';
          break;
        case 'Bids':
          columnName = 'bid_status';
          break;
        case 'Property Group':
          columnName = 'property_group';
          break;
        case 'Job Cost Approver':
          columnName = 'job_cost_approvers';
          break;
        case 'Property Name':
          columnName = 'name';
          break;
        case 'State':
          columnName = 'state';
          break;
        case 'City':
          columnName = 'city';
          break;
        case 'Property External Code':
          columnName = 'property_external_code';
          break;
        case 'Lease External Code':
          columnName = 'lease_external_code';
          break;
        case 'COI':
          columnName = 'coi';
          break;
        case 'Teams':
          columnName = 'teams';
          break;
        default:
          break;

      }
    }

    return columnName;


  }


  removeCompanyFilters(event: any) {
    // Condition is for we segregate the date chip with the key of "isDateChip", if the key is true then this is date chip filter or else it's a type or location filter
    if (event?.isDateCreatedDateChip && event.isDateCreatedDateChip === true) {
      // 1. Ceared at Date
      this.data.data.filterQuery.dateCreatedDateRange.from = '';
      this.data.data.filterQuery.dateCreatedDateRange.to = '';
    } else if (event?.isLastActivityDateChip && event.isLastActivityDateChip === true) {
      this.data.data.filterQuery.lastActivityDateRange.from = '';
      this.data.data.filterQuery.lastActivityDateRange.to = '';
    } else {
      // 2.Type
      // If we remove the value means we have find it in type array if exist then we change the value selected = false
      event.selected = false;
      const typeFilter = this.data.data.filterQuery.filterList.find((list: any) => {
        return list.label === 'Company Type';
      });
      typeFilter.listItems.map((item: any) => {
        if (item.name == event.name) {
          return item.selected = false;
        }
      });
      // 3. Property
      const propertyFilter = this.data.data.filterQuery.filterList.find((list: any) => {
        return list.label === 'Properties';
      });
      propertyFilter.listItems.map((item: any) => {
        if (item.id == event.id) {
          return item.selected = false;
        }
      });

      // 3. Category
      const categoryFilter = this.data.data.filterQuery.filterList.find((list: any) => {
        return list.label === 'Category';
      });
      categoryFilter.listItems.map((item: any) => {
        if (item.id == event.id) {
          return item.selected = false;
        }
      });

      // 4.Location
      // If we remove the value means we have find it in Location array if exist then we change the value selected = false
      const locationFilter = this.data.data.filterQuery.filterList.find((list: any) => {
        return list.label === 'Location';
      });
      const deleteChipIndex = locationFilter.listItems.findIndex((chip) => {
        return chip.name == event.name;
      });
      if (deleteChipIndex != -1) {
        locationFilter.listItems.splice(deleteChipIndex, 1);
      }
      // locationFilter.listItems.map((item: any) => {
      //   if (item.id == event.id) {
      //     return item.selected = false;
      //   }
      // });
    }
    // Delete chips from the chipsList also
    const deleteChipListIndex = this.chipList.findIndex((chip) => {
      return chip.name == event.name;
    });
    if (deleteChipListIndex != -1) {
      this.chipList.splice(deleteChipListIndex, 1);
    }
    this.getFiltersFromQuery();
  }

  removeContactFilters(event: any) {
    // Condition is for we segregate the date chip with the key of "isDateChip", if the key is true then this is date chip filter or else it's a type or location filter
    if (event?.isDateChip && event.isDateChip === true) {
      // 1. Ceared at Date
      this.data.data.filterQuery.dateCreatedDateRange.from = '';
      this.data.data.filterQuery.dateCreatedDateRange.to = '';
    } else if (event?.isLastActivityDateChip && event.isLastActivityDateChip === true) {
      this.data.data.filterQuery.lastActivityDateRange.from = '';
      this.data.data.filterQuery.lastActivityDateRange.to = '';
    } else {
      // 2.Type
      // If we remove the value means we have find it in type array if exist then we change the value selected = false
      event.selected = false;
      const typeFilter = this.data.data.filterQuery.filterList.find((list: any) => {
        return list.label === 'Contact Type';
      });
      typeFilter.listItems.map((item: any) => {
        if (item.name == event.name) {
          return item.selected = false;
        }
      });
      // 3.Companies
      const companyFilter = this.data.data.filterQuery.filterList.find((list: any) => {
        return list.label === 'Companies';
      });
      companyFilter.listItems.map((item: any) => {
        if (item.id == event.id) {
          return item.selected = false;
        }
      });
      // 3.Companies Type
      const companyType = this.data.data.filterQuery.filterList.find((list: any) => {
        return list.label === 'Company Type';
      });
      companyType.listItems.map((item: any) => {
        if (item.name == event.name) {
          return item.selected = false;
        }
      });
    }
    const deleteChipListIndex = this.chipList.findIndex((chip) => {
      return chip.name == event.name;
    });
    if (deleteChipListIndex != -1) {
      this.chipList.splice(deleteChipListIndex, 1);
    }
    this.getFiltersFromQuery();
  }

  removeCoiFilters(event: any) {
    // Condition is for we segregate the date chip with the key of "isDateChip", if the key is true then this is date chip filter or else it's a type or location filter
    if (event?.isDateCreatedDateChip && event.isDateCreatedDateChip === true) {
      // 1. Ceared at Date
      this.data.data.filterQuery.dateCreatedDateRange.from = '';
      this.data.data.filterQuery.dateCreatedDateRange.to = '';
    } else if (event?.isLastActivityDateChip && event.isLastActivityDateChip === true) {
      this.data.data.filterQuery.lastActivityDateRange.from = '';
      this.data.data.filterQuery.lastActivityDateRange.to = '';
    } else {
      // 2.Type
      // If we remove the value means we have find it in type array if exist then we change the value selected = false
      event.selected = false;
      const typeFilter = this.data.data.filterQuery.filterList.find((list: any) => {
        return list.label === 'Company Type';
      });
      typeFilter.listItems.map((item: any) => {
        if (item.name == event.name) {
          return item.selected = false;
        }
      });
      // 3. Property
      const propertyFilter = this.data.data.filterQuery.filterList.find((list: any) => {
        return list.label === 'Properties';
      });
      propertyFilter.listItems.map((item: any) => {
        if (item.id == event.id) {
          return item.selected = false;
        }
      });

      // 4.Company
      // If we remove the value means we have find it in Location array if exist then we change the value selected = false
      const locationFilter = this.data.data.filterQuery.filterList.find((list: any) => {
        return list.label === 'Company';
      });
      const deleteChipIndex = locationFilter.listItems.findIndex((chip) => {
        return chip.name == event.name;
      });
      if (deleteChipIndex != -1) {
        locationFilter.listItems.splice(deleteChipIndex, 1);
      }
      // locationFilter.listItems.map((item: any) => {
      //   if (item.id == event.id) {
      //     return item.selected = false;
      //   }
      // });
    }
    // Delete chips from the chipsList also
    const deleteChipListIndex = this.chipList.findIndex((chip) => {
      return chip.name == event.name;
    });
    if (deleteChipListIndex != -1) {
      this.chipList.splice(deleteChipListIndex, 1);
    }
    this.getFiltersFromQuery();
  }

  removeLeaseFilters(event: any) {
    console.log('remove event => ', event)
    // 1. Tenant
    event.selected = false;
    const tenantFilter = this.data.data.filterQuery.filterList.find((list: any) => {
      return list.label === LEASES_FILTERS.TENANT;
    });
    tenantFilter.listItems.map((item: any) => {
      if (item.name == event.name) {
        return item.selected = false;
      }
    });

    // 2. Property
    const propertyFilter = this.data.data.filterQuery.filterList.find((list: any) => {
      return list.label === LEASES_FILTERS.PROPERTY;
    });
    propertyFilter.listItems.map((item: any) => {
      if (item.id == event.id) {
        return item.selected = false;
      }
    });

    // 3.Units
    const locationFilter = this.data.data.filterQuery.filterList.find((list: any) => {
      return list.label === LEASES_FILTERS.UNIT;
    });
    const deleteChipIndex = locationFilter.listItems.findIndex((chip) => {
      return chip.name == event.name;
    });
    if (deleteChipIndex != -1) {
      locationFilter.listItems.splice(deleteChipIndex, 1);
    }

    const deleteChipListIndex = this.chipList.findIndex((chip) => {
      return chip.name === event.name;
    });
    if (deleteChipListIndex != -1) {
      this.chipList.splice(deleteChipListIndex, 1);
    }
    this.getFiltersFromQuery();
  }

  removeTimeSheetFilters(event: any): void {
    // Condition is for we segregate the date chip with the key of "isDateChip", if the key is true then this is date chip filter or else it's a type or location filter
    if (event?.isDateCreatedDateChip && event.isDateCreatedDateChip === true) {
      // 1. Created at Date
      if (this.data.data.filterQuery?.work_date) {
        this.data.data.filterQuery.work_date.from = '';
        this.data.data.filterQuery.work_date.to = '';
      }
    } else if (event?.isDateApprovedChip && event.isDateApprovedChip === true) {
      if (this.data.data.filterQuery?.approved_date) {
        this.data.data.filterQuery.approved_date.from = '';
        this.data.data.filterQuery.approved_date.to = '';
      }
    } else {
      // 2.Type
      // If we remove the value means we have find it in type array if exist then we change the value selected = false
      event.selected = false;
      const typeFilter = this.data.data.filterQuery.filterList.find((list: any) => {
        return list.label === 'Engineer';
      });
      typeFilter.listItems.map((item: any) => {
        if (item.name == event.name) {
          return item.selected = false;
        }
      });
      // 3. Property
      const propertyFilter = this.data.data.filterQuery.filterList.find((list: any) => {
        return list.label === 'Properties';
      });
      propertyFilter.listItems.map((item: any) => {
        if (item.id == event.id) {
          return item.selected = false;
        }
      });

      //4. status
      const statusFilter = this.data.data.filterQuery.filterList.find((list: any) => {
        return list.label === 'Status';
      });
      statusFilter.listItems.map((item: any) => {
        if (item.name == event.name) {
          return item.selected = false;
        }
      });
    }
    // Delete chips from the chipsList also
    const deleteChipListIndex = this.chipList.findIndex((chip) => {
      return chip.name == event.name;
    });
    if (deleteChipListIndex != -1) {
      this.chipList.splice(deleteChipListIndex, 1);
    }
    this.getFiltersFromQuery();
  }


  removeEquipmentFilters(event: any) {
    for (let i = 0; i < this.data?.data?.selectedFilter.length; i++) {
      let index = this.data?.data?.selectedFilter[i].findIndex(obj => obj.name === event.name);
      if (index !== -1) {
        this.data?.data?.selectedFilter[i].splice(index, 1);
      }
    }
    // Delete chips from the chipsList also
    const deleteChipListIndex = this.chipList.findIndex((chip) => {
      return chip.name == event.name;
    });
    if (deleteChipListIndex != -1) {
      this.chipList.splice(deleteChipListIndex, 1);
    }
  }



  /**
   * save view Trigger
   */
  saveView() {
    if (this.addViewForm.valid && this.addViewForm.get('viewName')?.value != this.selectedView?.name) {
      this.isLoaderForSave = true;
      let postBody = {}
      let requestURL = '';

      if (this._viewModule == 'dashboard' || this._viewModule == 'property') {


        this.filterPayload.query = this.setQueryParams();

        this.customSelectedFilters.forEach((ele) => {
          this.filterPayload.query[ele.customName] = ele.customValue;
        })

        this.filterTableColumns.forEach((element, i) => {
          if (element?.isCustom) {
            if (!element.value.includes('custom_attributes')) {
              element.value = `custom_attributes.${element.value}`;
            }
          }
          if (!element?.order) {
            element.order = i + 1;
          }
        });

        postBody = {
          // "view_id":this.selectedView.id,
          "name": this.addViewForm.value.viewName,
          "pin": this.addViewForm.value.isPinned,
          "shared": this.addViewForm.value.isShareToOthers,
          "default": false,  //this.selectedView.default,
          "table_columns": this.filterTableColumns,
          "filters": this.filterPayload,
          "source": this._viewModule,
          "draft_view": this.selectedView?.draft_view ? this.selectedView?.draft_view : false
        };
        // this.dialogRef.close(postBody);

        requestURL = environment.LocalDev_URL_V2 + "manage-dashboard/create/";
        this.postApiCall(requestURL, postBody);
      }

      if (this._viewModule == 'company') {
        postBody = {
          // "filters": this.data.data.filterQuery ? this.data.data.filterQuery : {},
          "filters": this.filterPayload,
          "name": this.addViewForm.value.viewName,
          "pin": this.addViewForm.value.isPinned,
          "shared": this.addViewForm.value.isShareToOthers,
          "default": false,
          "table_columns": this.filterTableColumns,
          // "draft_view": this.selectedView?.draft_view ? this.selectedView?.draft_view : false
        };

        requestURL = environment.fastAPI_Actual_URL + "/company-views/";
        this.postApiCall(requestURL, postBody)
      } else if (this._viewModule == 'contact') {
        postBody = {
          "filters": this.filterPayload,
          "name": this.addViewForm.value.viewName,
          "pin": this.addViewForm.value.isPinned,
          "shared": this.addViewForm.value.isShareToOthers,
          "table_columns": this.filterTableColumns,
          // "draft_view": this.selectedView?.draft_view ? this.selectedView?.draft_view : false
        };

        requestURL = environment.fastAPI_Actual_URL + "/contact-views/";
        this.postApiCall(requestURL, postBody)
      } else if (this._viewModule == 'timeSheet') {
        postBody = {
          "filters": this.filterPayload,
          "name": this.addViewForm.value.viewName,
          "pin": this.addViewForm.value.isPinned,
          "shared": this.addViewForm.value.isShareToOthers,
          "table_columns": this.filterTableColumns,
          "default": false,
          "source": "timesheet",
          "draft_view": this.selectedView?.draft_view ? this.selectedView?.draft_view : false
        };

        requestURL = environment.LocalDev_URL_V2 + "manage-dashboard/create/";
        this.postApiCall(requestURL, postBody)
      } else if (this._viewModule === 'coi') {
        postBody = {
          // "filters": this.data.data.filterQuery ? this.data.data.filterQuery : {},
          "filters": this.filterPayload,
          "name": this.addViewForm.value.viewName,
          "pin": this.addViewForm.value.isPinned,
          "shared": this.addViewForm.value.isShareToOthers,
          "default": false,
          "source": "coi",
          "table_columns": this.filterTableColumns,
          // "draft_view": this.selectedView?.draft_view ? this.selectedView?.draft_view : false
        };

        requestURL = environment.LocalDev_URL_V2 + "manage-dashboard/create/";
        this.postApiCall(requestURL, postBody)
      } else if (this._viewModule === 'lease') {

        // set custom attr
        this.customSelectedFilters.forEach((ele) => {
          this.filterPayload.query[ele.customName] = ele.customValue;
        })

        this.filterTableColumns.forEach((element, i) => {
          if (element?.isCustom) {
            if (!element.value.includes('custom_attributes')) {
              element.value = `custom_attributes.${element.value}`;
            }
          }
          if (!element?.order) {
            element.order = i + 1;
          }
        });

        postBody = {
          "filters": this.filterPayload,
          "name": this.addViewForm.value.viewName,
          "pin": this.addViewForm.value.isPinned,
          "shared": this.addViewForm.value.isShareToOthers,
          "default": false,
          "source": "lease",
          "table_columns": this.filterTableColumns,
        };

        requestURL = environment.LocalDev_URL_V2 + "manage-dashboard/create/";
        this.postApiCall(requestURL, postBody)
      } else if (this._viewModule == 'planner') {
        postBody = {
          "filters": this.filterPayload,
          "name": this.addViewForm.value.viewName,
          "pin": this.addViewForm.value.isPinned,
          "shared": this.addViewForm.value.isShareToOthers,
          "default": false,
          "table_columns": this.filterTableColumns,
          "source": this._viewModule,
          "draft_view": this.selectedView?.draft_view ? this.selectedView?.draft_view : false
        };
        requestURL = environment.LocalDev_URL_V2 + "manage-dashboard/create/";
        this.postApiCall(requestURL, postBody)
      }


      if (this._viewModule == 'equipment' || this._viewModule == 'equipment_type' || this._viewModule == 'additional_data_item') {
        let payload: any = {
          name: this.addViewForm.value.viewName,
          pin: this.addViewForm.value.isPinned,
          shared: this.addViewForm.value.isShareToOthers,
          table_columns: this.filterTableColumns.filter((ele: any) => {
            return ele.value != "checkbox";
          }),
          filters: this.data?.data?.selectedFilter.map((element: any) => {
            return element.map((selectedItem: any) => selectedItem.name);
          })
        }

        this.graphql.createView(this._viewModule, payload).subscribe({
          next: (res: any) => {
            this._utilService.showSuccess('', 'View Created Successfully.');
            this.dialogRef.close(res);
            this.isLoaderForSave = false;
          },
          error: (err: GraphQLError) => {
            const error = err.message;
            const messageWithoutBraces = error.slice(1, -1);
            const value = messageWithoutBraces.split('=')[1];
            this._utilService.showError('', value);
            this.isLoaderForSave = false;
          },
        })
      }

    }

  }

  postApiCall(requestURL: any, postBody: any, isUpdate?: boolean) {
    this._dialogService.doPOST(requestURL, postBody).subscribe(
      {
        next: (response: any) => {
          // this.dialogRef.close(response);
          // this._utilService.showSuccess('', 'View Created Successfully.');
          // response.isAdd = true;

          if (isUpdate) {
            response.isUpdate = true;
            response.pin = this.addViewForm.value.isPinned;
            this._utilService.showSuccess('', 'View Updated Successfully.');
          }
          else {
            response.isAdd = true;
            this._utilService.showSuccess('', 'View Created Successfully.');
          }

          this.dialogRef.close(response);

        },
        error: (error: HttpErrorResponse) => {
          if (isUpdate) {
            this.isLoaderForUpdate = false;
          }
          else {
            this.isLoaderForSave = false;
          }
          const err = error.message;
          const messageWithoutBraces = err.slice(1, -1);
          const value = messageWithoutBraces.split('=')[1];
          this._utilService.showErrorMessage(error);
        },
        complete: () => {
          if (isUpdate) {
            this.isLoaderForUpdate = false;
          }
          else {
            this.isLoaderForSave = false;
          }
        }
      }
    );

  }

  updateView() {
    if (this.addViewForm.valid) {
      this.isLoaderForUpdate = true;
      let postBody = {}
      let requestURL = '';


      if (this._viewModule == 'dashboard' || this._viewModule == 'property') {

        // if (Object.keys(this.setPayloadFilterQuery).length > 0) {
        //   this.filterPayload.query = this.setPayloadFilterQuery;
        // }

        this.filterPayload.query = this.setQueryParams();

        this.customSelectedFilters.forEach((ele) => {
          this.filterPayload.query[ele.customName] = ele.customValue;
        })

        this.filterTableColumns.forEach((element, i) => {
          if (element?.isCustom) {
            if (!element.value.includes('custom_attributes')) {
              element.value = `custom_attributes.${element.value}`;
            }
          }
          if (!element?.order) {
            element.order = i + 1;
          }
        });

        postBody = {
          "view_id": this.selectedView.id,
          "name": this.addViewForm.value.viewName,
          "pin": this.addViewForm.value.isPinned,
          "shared": this.addViewForm.value.isShareToOthers,
          // "default": this.selectedView.default,
          "table_columns": this.filterTableColumns,
          "filters": this.filterPayload,
          "source": this._viewModule,
          "draft_view": this.selectedView?.draft_view ? this.selectedView?.draft_view : false
        };
        if (!postBody['pin'] && this.selectedView.default) {
          postBody['default'] = false;
        }

        requestURL = environment.LocalDev_URL_V2 + "manage-dashboard/create/";
        this.postApiCall(requestURL, postBody, true);
        return;
      }


      if (this._viewModule == 'company') {
        postBody = {
          // "filters": this.data.data.filterQuery ? this.data.data.filterQuery : {},
          "filters": this.filterPayload,
          "name": this.addViewForm.value.viewName,
          "pin": this.addViewForm.value.isPinned,
          "shared": this.addViewForm.value.isShareToOthers,
          // "default": this.selectedView.default,
          "table_columns": this.filterTableColumns,
          "type": 'my',
          // "draft_view": this.selectedView?.draft_view ? this.selectedView?.draft_view : false
        };
        requestURL = environment.fastAPI_Actual_URL + "/company-views/" + this.selectedView.id + '/';
        this.patchApiCall(requestURL, postBody);
      }
      else if (this._viewModule == 'contact') {
        postBody = {
          "filters": this.filterPayload,
          "name": this.addViewForm.value.viewName,
          "pin": this.addViewForm.value.isPinned,
          "shared": this.addViewForm.value.isShareToOthers,
          "table_columns": this.filterTableColumns,
          "type": 'my',
          // "draft_view": this.selectedView?.draft_view ? this.selectedView?.draft_view : false
        };
        requestURL = environment.fastAPI_Actual_URL + "/contact-views/" + this.selectedView.id + '/';
        this.patchApiCall(requestURL, postBody);
      } else if (this._viewModule == 'timeSheet') {
        postBody = {
          "filters": this.filterPayload,
          "name": this.addViewForm.value.viewName,
          "pin": this.addViewForm.value.isPinned,
          "shared": this.addViewForm.value.isShareToOthers,
          "table_columns": this.filterTableColumns,
          // "default": this.selectedView.default,
          "source": "timesheet",
          "view_id": this.selectedView.id,
          "draft_view": this.selectedView?.draft_view ? this.selectedView?.draft_view : false
        };
        requestURL = environment.LocalDev_URL_V2 + "manage-dashboard/create/";
        this.postApiCall(requestURL, postBody, true);
      } else if (this._viewModule === 'coi') {
        postBody = {
          // "filters": this.data.data.filterQuery ? this.data.data.filterQuery : {},
          "filters": this.filterPayload,
          "name": this.addViewForm.value.viewName,
          "pin": this.addViewForm.value.isPinned,
          "shared": this.addViewForm.value.isShareToOthers,
          "table_columns": this.filterTableColumns,
          // "default": this.selectedView.default,
          "source": 'coi',
          "view_id": this.selectedView.id,
          // "draft_view": this.selectedView?.draft_view ? this.selectedView?.draft_view : false
        };
        requestURL = `${environment.LocalDev_URL_V2}manage-dashboard/create/`;
        this.postApiCall(requestURL, postBody, true);
      } else if (this._viewModule === 'lease') {
        postBody = {
          "filters": this.filterPayload,
          "name": this.addViewForm.value.viewName,
          "pin": this.addViewForm.value.isPinned,
          "shared": this.addViewForm.value.isShareToOthers,
          "table_columns": this.filterTableColumns,
          // "default": this.selectedView.default,
          "source": "lease",
          "view_id": this.selectedView.id,
        };
        requestURL = `${environment.LocalDev_URL_V2}manage-dashboard/create/`;
        this.postApiCall(requestURL, postBody, true);
      } else if (this._viewModule == 'planner') {
        postBody = {
          "view_id": this.selectedView.id,
          "name": this.addViewForm.value.viewName,
          "pin": this.addViewForm.value.isPinned,
          "shared": this.addViewForm.value.isShareToOthers,
          // "default": this.selectedView.default,
          "table_columns": this.filterTableColumns,
          "filters": this.filterPayload,
          "source": this._viewModule,
          "draft_view": this.selectedView?.draft_view ? this.selectedView?.draft_view : false
        };
        requestURL = environment.LocalDev_URL_V2 + "manage-dashboard/create/";
        this.postApiCall(requestURL, postBody, true);
      }

      if (this._viewModule == 'equipment' || this._viewModule == 'equipment_type' || this._viewModule == 'additional_data_item') {
        let payload: any = {
          name: this.addViewForm.value.viewName,
          pin: this.addViewForm.value.isPinned,
          shared: this.addViewForm.value.isShareToOthers,
          table_columns: this.filterTableColumns.filter((ele: any) => {
            return ele.value != "checkbox";
          }),
          filters: this.data?.data?.selectedFilter.map((element: any) => {
            return element.map((selectedItem: any) => selectedItem.name);
          })
        };
        this.graphql.updateView(this._viewModule, this.selectedView.id, payload).subscribe({
          next: (res: any) => {
            this._utilService.showSuccess('', 'View Updated Successfully.');
            this.isLoaderForSave = false;
            this.isLoaderForUpdate = false;
            this.dialogRef.close(res);
          },
          error: (err: GraphQLError) => {
            this._utilService.showError('', err);
            this.isLoaderForSave = false;
            this.isLoaderForUpdate = false;
          },
        })
      }

    }
  }

  patchApiCall(requestURL: any, postBody: any): void {
    this._dialogService.doPATCH(requestURL, postBody).subscribe(
      {
        next: (response: any) => {
          response.isUpdate = true;
          response.pin = this.addViewForm.value.isPinned;
          this.dialogRef.close(response);
          this._utilService.showSuccess('', 'View Updated Successfully.');
        },
        error: (error) => {
          this.isLoaderForUpdate = false;
          this._utilService.setFormFieldErrors(this.addViewForm, error, true)

        },
        complete: () => {
          this.isLoaderForUpdate = false;
        }
      }
    );
  }

  getFiltersFromQuery(): void {
    if (this._viewModule === 'company') {
      this.companyFiltersQuery();
    } else if (this._viewModule === 'contact') {
      this.contactFiltersQuery();
    } else if (this._viewModule === 'timeSheet') {
      this.timesheetFiltersQuery();
    } else if (this._viewModule === 'coi') {
      this.coiFiltersQuery();
    } else if (this._viewModule === 'lease') {
      this.leaseFiltersQuery();
    } else if (this._viewModule === 'planner') {
      this.servicePlannerFiltersQuery();
    }
  }

  companyFiltersQuery(): void {
    if (this.data.data.filterQuery) {
      // 1. set payload for createdAt from and to.
      if (this.data.data.filterQuery.dateCreatedDateRange?.from && this.data.data.filterQuery.dateCreatedDateRange?.to) {
        this.filterPayload.query['created_at'] = {
          from: this.data.data.filterQuery.dateCreatedDateRange.from,
          to: this.data.data.filterQuery.dateCreatedDateRange.to
        }
      } else {
        delete this.filterPayload.query['created_at'];
      }

      if (this.data.data.filterQuery.lastActivityDateRange?.from && this.data.data.filterQuery.lastActivityDateRange?.to) {
        this.filterPayload.query['modified_at'] = {
          from: this.data.data.filterQuery.lastActivityDateRange.from,
          to: this.data.data.filterQuery.lastActivityDateRange.to
        }
      } else {
        delete this.filterPayload.query['modified_at'];
      }

      // 2. set payload for type filterList
      // We have to find the type list using 'find' method
      let typeQuery = this.data.data.filterQuery.filterList.find((list: any) => {
        return list.label === 'Company Type';
      });
      // After we getting the type list then we have to get the name of the types for sending the payload to backend.
      if (typeQuery.listItems.length > 0) {
        typeQuery = typeQuery?.listItems.map((list: any) => {
          if (list.selected == true) {
            return list.name;
          }
        });
        typeQuery = typeQuery?.filter((list: any) => {
          return (list != undefined || list != null);
        });
        // Then append to payload
        if (typeQuery.length > 0) {
          this.filterPayload.query['type'] = typeQuery;
        } else {
          delete this.filterPayload.query['type'];
        }
      }

      // 3.Set payload for Property filterList
      // We have to find the Property list using 'find' method
      let propertyQuery = this.data.data.filterQuery.filterList.find((list: any) => {
        return list.label === 'Properties';
      });
      // After we getting the proeperty list then we have to get the name of the types for sending the payload to backend.
      if (propertyQuery.listItems.length > 0) {
        propertyQuery = propertyQuery?.listItems.map(({ id, name, selected }) => {
          if (selected == true) {
            return ({ id, name, selected })
          }
        });
        // Then append to payload
        if (propertyQuery.length > 0) {
          propertyQuery = propertyQuery.filter(obj => obj);
          this.filterPayload.query['properties'] = propertyQuery;
        } else {
          delete this.filterPayload.query['properties'];
        }
      }


      //Category Filter
      let indexVal = this.data.data.filterQuery.filterList.findIndex((element: any) => element.label == 'Category');

      if (indexVal != -1) {
        let categoriesQuery = this.data.data.filterQuery.filterList.find((element: any) => {
          return element.label == 'Category';
        }).listItems.filter((typeList: any) => {
          return typeList.selected == true;
        });


        // Then append to payload
        if (categoriesQuery?.length > 0) {
          categoriesQuery = categoriesQuery.filter(obj => obj).map(obj => obj.name);
          this.filterPayload.query['categories'] = categoriesQuery;
        } else {
          delete this.filterPayload.query['categories'];
        }

      }




      // 4. sort filter query
      if (this.sort.active) {
        this.filterPayload['active'] = this.sort.active;
        this.filterPayload['direction'] = this.sort.direction;
      } else {
        delete this.filterPayload['active'];
        delete this.filterPayload['direction'];
      }


      // 5. set payload for location filterList
      // We have to find the location list using 'find' method
      let locationQuery = this.data.data.filterQuery.filterList.find((list: any) => {
        return list.label === 'Location';
      });
      // After we getting the location list then we have to segregate the city and states from the location.
      // if (locationQuery.listItems.length > 0) {
      //   locationQuery = locationQuery.listItems.map((list: any) => {
      //     if (list.selected == true) {
      //       return list.address;
      //     }
      //   });
      if (locationQuery.listItems.length > 0) {
        locationQuery = locationQuery.listItems.map((list: any) => {
          return list;
        });
      }
      // Then append to payload
      if (locationQuery.length > 0) {
        this.filterPayload.query['address'] = locationQuery;
      } else {
        delete this.filterPayload.query['address'];
      }
    }

    // 6. set payload for COI filterList
    // We have to find the COI list using 'find' method
    let coiQuery = this.data.data.filterQuery.filterList.find((list: any) => {
      return list.label === 'COI';
    });
    // After we getting the COI list then we have to get the name of the types for sending the payload to backend.
    if (coiQuery.listItems.length > 0) {
      coiQuery = coiQuery?.listItems
    }
    // Then append to payload
    if (coiQuery.length > 0) {
      this.filterPayload.query['coi'] = coiQuery;
    } else {
      delete this.filterPayload.query['coi'];
    }
  }


  contactFiltersQuery(): void {
    if (this.data.data.filterQuery) {
      // 1. set payload for createdAt from and to.
      if (this.data.data.filterQuery.dateCreatedDateRange?.from && this.data.data.filterQuery.dateCreatedDateRange?.to) {
        this.filterPayload.query['created_at'] = {
          from: this.data.data.filterQuery.dateCreatedDateRange.from,
          to: this.data.data.filterQuery.dateCreatedDateRange.to
        }
      } else {
        delete this.filterPayload.query['created_at'];
      }

      if (this.data.data.filterQuery.lastActivityDateRange?.from && this.data.data.filterQuery.lastActivityDateRange?.to) {
        this.filterPayload.query['modified_at'] = {
          from: this.data.data.filterQuery.lastActivityDateRange.from,
          to: this.data.data.filterQuery.lastActivityDateRange.to
        }
      } else {
        delete this.filterPayload.query['modified_at'];
      }

      // 2. set payload for type filterList
      // We have to find the type list using 'find' method
      let typeQuery = this.data.data.filterQuery.filterList.find((list: any) => {
        return list.label === 'Contact Type';
      });
      // After we getting the type list then we have to get the name of the types for sending the payload to backend.
      if (typeQuery.listItems.length > 0) {
        typeQuery = typeQuery?.listItems.map((list: any) => {
          if (list.selected == true) {
            return list.name;
          }
        });
        typeQuery = typeQuery?.filter((list: any) => {
          return (list != undefined || list != null);
        });
        // Then append to payload
        if (typeQuery.length > 0) {
          this.filterPayload.query['type'] = typeQuery;
        } else {
          delete this.filterPayload.query['type'];
        }
      }
      // 2. set payload for type companyList
      // We have to find the type list using 'find' method
      let companiesQuery = this.data.data.filterQuery.filterList.find((list: any) => {
        return list.label === 'Companies';
      });
      // After we getting the type list then we have to get the name of the types for sending the payload to backend.
      if (companiesQuery.listItems.length > 0) {
        companiesQuery = companiesQuery?.listItems.map((list: any) => {
          if (list.selected == true) {
            return list.id;
          }
        });
        companiesQuery = companiesQuery?.filter((list: any) => {
          return (list != undefined || list != null);
        });
        // Then append to payload
        if (companiesQuery.length > 0) {
          this.filterPayload.query['companies'] = companiesQuery;
        } else {
          delete this.filterPayload.query['companies'];
        }
      }

      // 2. set payload for type companyList
      // We have to find the type list using 'find' method
      let companiesTypeQuery = this.data.data.filterQuery.filterList.find((list: any) => {
        return list.label === 'Company Type';
      });
      // After we getting the type list then we have to get the name of the types for sending the payload to backend.
      if (companiesTypeQuery.listItems.length > 0) {
        companiesTypeQuery = companiesTypeQuery?.listItems.map((list: any) => {
          if (list.selected == true) {
            return list.name;
          }
        });
        companiesTypeQuery = companiesTypeQuery?.filter((list: any) => {
          return (list != undefined || list != null);
        });
        // Then append to payload
        if (companiesTypeQuery.length > 0) {
          this.filterPayload.query['companies_type'] = companiesTypeQuery;
        } else {
          delete this.filterPayload.query['companies_type'];
        }
      }
      // sort filter query
      if (this.sort.active) {
        this.filterPayload['active'] = this.sort.active;
        this.filterPayload['direction'] = this.sort.direction;
      } else {
        delete this.filterPayload['active'];
        delete this.filterPayload['direction'];
      }

    }
  }

  coiFiltersQuery(): void {
    if (this.data.data.filterQuery) {
      // 1. set payload for Company type filterList
      if (this.data.data.filterQuery.company_type && this.data.data.filterQuery.company_type.length) {
        this.filterPayload.query['company_type'] = this.data.data.filterQuery.company_type;
      } else {
        let typeQuery = this.data.data.filterQuery.filterList.find((list: any) => {
          return list.label === 'Company Type';
        });

        // After we getting the type list then we have to get the name of the types for sending the payload to backend.
        if (typeQuery.listItems.length > 0) {
          typeQuery = typeQuery?.listItems.map((list: any) => {
            if (list.selected == true) {
              return list.name;
            }
          });
          typeQuery = typeQuery?.filter((list: any) => {
            return (list != undefined || list != null);
          });
          // Then append to payload
          if (typeQuery.length > 0) {
            this.filterPayload.query['company_type'] = typeQuery;
          } else {
            delete this.filterPayload.query['company_type'];
          }
        }
      }


      // 2.Set payload for Property filterList
      // We have to find the Property list using 'find' method
      let companyQuery = this.data.data.filterQuery.filterList.find((list: any) => {
        return list.label === 'Company';
      });
      // After we getting the company list then we have to get the name of the types for sending the payload to backend.
      if (companyQuery.listItems.length > 0) {
        companyQuery = companyQuery?.listItems.map(({ id, name, selected }) => {
          if (selected == true) {
            return ({ id, name, selected })
          }
        });
        // Then append to payload
        if (companyQuery.length > 0) {
          this.filterPayload.query['companies'] = companyQuery;
        } else {
          delete this.filterPayload.query['companies'];
        }
      }

      // 3.Set payload for Property filterList
      // We have to find the Property list using 'find' method
      let propertyQuery = this.data.data.filterQuery.filterList.find((list: any) => {
        return list.label === 'Properties';
      });
      // After we getting the proeperty list then we have to get the name of the types for sending the payload to backend.
      if (propertyQuery.listItems.length > 0) {
        propertyQuery = propertyQuery?.listItems.map(({ id, name, selected }) => {
          if (selected == true) {
            return ({ id, name, selected })
          }
        });
        // Then append to payload
        if (propertyQuery.length > 0) {
          this.filterPayload.query['properties'] = propertyQuery;
        } else {
          delete this.filterPayload.query['properties'];
        }
      }

      // 4.Set payload for expiring_on_or_after
      // We have to find the EXPIRING_ON_OR_AFTER list using 'find' method
      // let expiringOnOrAfterQuery = this.data.data.filterQuery.filterList.find((list: any) => {
      //   return list.label === CoiFilter.EXPIRING_ON_OR_AFTER;
      // });

      if (this.data.data.filterQuery.expiring_on_or_after) {
        this.filterPayload.query['expiring_on_or_after'] = this.data.data.filterQuery.expiring_on_or_after;
      } else {
        delete this.filterPayload.query['expiring_on_or_after'];
      }

      if (this.data.data.filterQuery.expiring_within?.value) {
        this.filterPayload.query['expiring_within'] = this.data.data.filterQuery.expiring_within;
      } else {
        delete this.filterPayload.query['expiring_within'];
      }

      if (this.data.data.filterQuery.expiration?.value) {
        this.filterPayload.query['expiration'] = this.data.data.filterQuery.expiration;
      } else {
        delete this.filterPayload.query['expiration'];
      }



      // 0. sort filter query
      if (this.sort.active) {
        this.filterPayload['active'] = this.sort.active;
        this.filterPayload['direction'] = this.sort.direction;
      } else {
        delete this.filterPayload['active'];
        delete this.filterPayload['direction'];
      }
    }
  }

  leaseFiltersQuery(): void {
    if (this.data?.data?.type) {
      this.filterPayload.query['type'] = this.data?.data?.type;
    }

    if (this.data?.data?.leaseDateFilter) {
      const { from, to } = this.data.data.leaseDateFilter;
      if (from) {
        this.filterPayload.query['from_date'] = from?.value;
      } else {
        delete this.filterPayload.query['from_date'];
      }

      if (to) {
        this.filterPayload.query['to_date'] = to?.value;
      } else {
        delete this.filterPayload.query['to_date'];
      }
    }


    if (this.data?.data?.filterQuery) {
      // 1. set payload for Tenant filterList
      let tenantQuery = this.data.data.filterQuery.filterList.find((list: any) => {
        return list.label === LEASES_FILTERS.TENANT;
      });

      if (tenantQuery.listItems.length > 0) {
        tenantQuery = tenantQuery?.listItems.map(({ id, name, selected }) => {
          if (selected === true) {
            return { id, name };
          }
        });
        // Then append to payload
        if (tenantQuery.length > 0) {
          this.filterPayload.query['tenant'] = tenantQuery;
        } else {
          delete this.filterPayload.query['tenant'];
        }
      }

      // 2.Set payload for Property filterList
      // We have to find the Property list using 'find' method
      let propertyQuery = this.data.data.filterQuery.filterList.find((list: any) => {
        return list.label === LEASES_FILTERS.PROPERTY;
      });
      if (propertyQuery.listItems.length > 0) {
        propertyQuery = propertyQuery?.listItems.map(({ id, name, selected }) => {
          if (selected === true) {
            return ({ id, name })
          }
        });

        if (propertyQuery.length > 0) {
          this.filterPayload.query['properties'] = propertyQuery;
        } else {
          delete this.filterPayload.query['properties'];
          delete this.filterPayload.query['units'];
        }

        // 3.Set payload for Units filterList
        let unitQuery = this.data.data.filterQuery.filterList.find((list: any) => {
          return list.label === LEASES_FILTERS.UNIT;
        });
        if (unitQuery.listItems.length > 0) {
          unitQuery = unitQuery?.listItems.map(({ id, name, selected }) => {
            if (selected === true) {
              return ({ id, name, selected })
            }
          });
          // Then append to payload
          if (unitQuery.length > 0) {
            this.filterPayload.query['units'] = unitQuery;
          } else {
            delete this.filterPayload.query['units'];
          }
        }

      }

      // 2.Set payload for External Code filterList
      let externalCodeQuery = this.data.data.filterQuery.filterList.find((list: any) => {
        return list.label === LEASES_FILTERS.EXTERNAL_CODE;
      });

      if (externalCodeQuery.listItems.length > 0) {
        externalCodeQuery = externalCodeQuery?.listItems.map(({ id, name, selected }) => {
          if (selected === true) {
            return { id, name };
          }
        });
        // Then append to payload
        if (externalCodeQuery.length > 0) {
          this.filterPayload.query['external_code'] = externalCodeQuery;
        } else {
          delete this.filterPayload.query['external_code'];
        }
      }


      // 0. sort filter query
      if (this.sort.active) {
        this.filterPayload['active'] = this.sort.active;
        this.filterPayload['direction'] = this.sort.direction;
      } else {
        delete this.filterPayload['active'];
        delete this.filterPayload['direction'];
      }
    }
  }

  timesheetFiltersQuery(): void {
    // Filter for date created
    if (this.data.data.filterQuery.work_date?.from && this.data.data.filterQuery.work_date?.to) {
      this.filterPayload.query['work_date'] = {
        from: this.data.data.filterQuery.work_date.from,
        to: this.data.data.filterQuery.work_date.to
      }
    } else {
      delete this.filterPayload.query['work_date'];
    }

    // Filter for date approved
    if (this.data.data.filterQuery.approved_date?.from && this.data.data.filterQuery.approved_date?.to) {
      this.filterPayload.query['approved_date'] = {
        from: this.data.data.filterQuery.approved_date.from,
        to: this.data.data.filterQuery.approved_date.to
      }
    } else {
      delete this.filterPayload.query['approved_date'];
    }

    // 3.Set payload for Engineers filterList
    // We have to find the Engineers list using 'find' method
    let engineerQuery = this.data.data.filterQuery.filterList.find((list: any) => {
      return list.label === 'Engineer';
    });
    // After we getting the Engineer list then we have to get the name of the types for sending the payload to backend.
    if (engineerQuery?.listItems.length > 0) {
      engineerQuery = engineerQuery?.listItems.map(({ id, name, selected }) => {
        if (selected == true) {
          return ({ id, name, selected })
        }
      });
      // Remove null values
      engineerQuery = engineerQuery?.filter((list: any) => {
        return list != undefined;
      });
      // Then append to payload
      if (engineerQuery.length > 0) {
        this.filterPayload.query['engineer'] = engineerQuery;
      } else {
        delete this.filterPayload.query['engineer'];
      }
    }

    // 4.Set payload for Engineers filterList
    // We have to find the Engineers list using 'find' method
    let propertyQuery = this.data.data.filterQuery.filterList.find((list: any) => {
      return list.label === 'Properties';
    });
    // After we getting the property list then we have to get the name of the types for sending the payload to backend.
    if (propertyQuery?.listItems.length > 0) {
      propertyQuery = propertyQuery?.listItems.map(({ id, name, selected }) => {
        if (selected == true) {
          return ({ id, name, selected })
        }
      });
      // Remove null values
      propertyQuery = propertyQuery?.filter((list: any) => {
        return list != undefined;
      });
      // Then append to payload
      if (propertyQuery.length > 0) {
        this.filterPayload.query['property'] = propertyQuery;
      } else {
        delete this.filterPayload.query['property'];
      }
    }

    // 5. set payload for type companyList
    // We have to find the type list using 'find' method
    let statusQuery = this.data.data.filterQuery.filterList.find((list: any) => {
      return list.label === 'Status';
    });
    // After we getting the type list then we have to get the name of the types for sending the payload to backend.
    if (statusQuery?.listItems.length > 0) {
      statusQuery = statusQuery?.listItems.map((list: any) => {
        if (list.selected == true) {
          return list.name;
        }
      });
      // Remove null values
      statusQuery = statusQuery?.filter((list: any) => {
        return list != undefined;
      });
      // Then append to payload
      if (statusQuery.length > 0) {
        this.filterPayload.query['status'] = statusQuery;
      } else {
        delete this.filterPayload.query['status'];
      }
    }

    // 6. set payload for type Billable FilterList
    // We have to find the type list using 'find' method
    let selectedBillable = this.data.data.filterQuery.filterList.find((element: any) => {
      return element.label == 'Billable';
    }).listItems.filter((BillableList: any) => {
      return BillableList.selected == true;
    });

    // Based on the query params we get only the names from the selected type filter list array
    let billableListQueryParam = selectedBillable.map((billable: any) => {
      return billable.name === 'Yes' ? 'True' : 'False';
    });

    // Append type filter query params
    if (billableListQueryParam.length > 0) {
      this.filterPayload.query['is_billable'] = billableListQueryParam;
    } else {
      delete this.filterPayload.query['is_billable'];
    }

    // 7.Set payload for Teams filterList
    // We have to find the Teams list using 'find' method
    let teamsQuery = this.data.data.filterQuery.filterList.find((list: any) => {
      return list.label === 'Teams';
    });
    // After we getting the property list then we have to get the name of the types for sending the payload to backend.
    if (teamsQuery?.listItems.length > 0) {
      teamsQuery = teamsQuery?.listItems.map(({ id, name, selected }) => {
        if (selected == true) {
          return ({ id, name, selected })
        }
      });
      // Remove null values
      teamsQuery = teamsQuery?.filter((list: any) => {
        return list != undefined;
      });
      // Then append to payload
      if (teamsQuery.length > 0) {
        this.filterPayload.query['teams'] = teamsQuery;
      } else {
        delete this.filterPayload.query['teams'];
      }
    }
  }

  triggerClosePopupView() {
    // let el: HTMLElement = this.closePopup.nativeElement as HTMLElement;
    // el.click();
  }

  /** Track by Index  **/
  trackByIndex(index: number, item: any): number {
    return index;
  }

  toggleShareViewChange(event: any): void {
    this.addViewForm.value.isShareToOthers = event;
  }

  togglePinChange(event: any): void {
    this.addViewForm.value.isPinned = event;
  }

  removeServicePlannerFilter(filterChip: any): void {
    // Delete chips from the chipsList also
    const deleteChipListIndex = this.chipList.findIndex((chip) => {
      return chip.name == filterChip.name;
    });
    if (deleteChipListIndex != -1) {
      this.chipList.splice(deleteChipListIndex, 1);
    }

    this.data.data.filterQuery.filterList.map((ele: any, i: any) => {
      if (ele.listItems) {
        ele.listItems.map((element: any, i: any) => {
          if (element.id == filterChip.id || element.name == filterChip.name) {
            element.selected = false;
          }
        })
      }
    });
    this.getFiltersFromQuery();
  }

  servicePlannerFiltersQuery(): void {
    // 1. set payload for Category filterList
    // We have to find the type list using 'find' method
    let categoryQuery = this.data.data.filterQuery.filterList.find((list: any) => {
      return list.label === 'Category';
    });
    // After we getting the Category list then we have to get the id and name of the Category for sending the payload to backend.
    if (categoryQuery.listItems.length > 0) {
      categoryQuery = categoryQuery?.listItems.map((list: any) => {
        if (list.selected == true) {
          return {id: list.id, name: list.name};
        }
      });
      categoryQuery = categoryQuery?.filter((list: any) => {
        return (list != undefined || list != null);
      });
      // Then append to payload
      if (categoryQuery.length > 0) {
        this.filterPayload.query['category'] = categoryQuery;
      } else {
        delete this.filterPayload.query['category'];
      }
    }

    // 2. set payload for Property filterList
    // We have to find the type list using 'find' method
    let propertyQuery = this.data.data.filterQuery.filterList.find((list: any) => {
      return list.label === 'Property';
    });
    // After we getting the Property list then we have to get the id and name of the Property for sending the payload to backend.
    if (propertyQuery.listItems.length > 0) {
      propertyQuery = propertyQuery?.listItems.map((list: any) => {
        if (list.selected == true) {
          return {id: list.id, name: list.name};
        }
      });
      propertyQuery = propertyQuery?.filter((list: any) => {
        return (list != undefined || list != null);
      });
      // Then append to payload
      if (propertyQuery.length > 0) {
        this.filterPayload.query['properties'] = propertyQuery;
      } else {
        delete this.filterPayload.query['properties'];
      }
    }

    // 3. set payload for Service Types filterList
    // We have to find the type list using 'find' method
    let serviceTypeQuery = this.data.data.filterQuery.filterList.find((list: any) => {
      return list.label === 'Service Types';
    });
    // After we getting the Property list then we have to get the id and name of the Service Types for sending the payload to backend.
    if (serviceTypeQuery.listItems.length > 0) {
      serviceTypeQuery = serviceTypeQuery?.listItems.map((list: any) => {
        if (list.selected == true) {
          return {id: list.id, name: list.name};
        }
      });
      serviceTypeQuery = serviceTypeQuery?.filter((list: any) => {
        return (list != undefined || list != null);
      });
      // Then append to payload
      if (serviceTypeQuery.length > 0) {
        this.filterPayload.query['service_type'] = serviceTypeQuery;
      } else {
        delete this.filterPayload.query['service_type'];
      }
    }

    // 4. set payload for Status filterList
    // We have to find the type list using 'find' method
    let statusQuery = this.data.data.filterQuery.filterList.find((list: any) => {
      return list.label === 'Status';
    });
    // After we getting the Property list then we have to get the name of the Status for sending the payload to backend.
    if (statusQuery.listItems.length > 0) {
      statusQuery = statusQuery?.listItems.map((list: any) => {
        if (list.selected == true) {
          return list.name
        }
      });
      statusQuery = statusQuery?.filter((list: any) => {
        return (list != undefined || list != null);
      });
      // Then append to payload
      if (statusQuery.length > 0) {
        this.filterPayload.query['status'] = statusQuery;
      } else {
        delete this.filterPayload.query['status'];
      }
    }

    // 5. set payload for Property Types filterList
    // We have to find the type list using 'find' method
    let propertyTypeQuery = this.data.data.filterQuery.filterList.find((list: any) => {
      return list.label === 'Property Types';
    });
    // After we getting the Property list then we have to get the id and name of the Property Types for sending the payload to backend.
    if (propertyTypeQuery.listItems.length > 0) {
      propertyTypeQuery = propertyTypeQuery?.listItems.map((list: any) => {
        if (list.selected == true) {
          return list.name
        }
      });
      propertyTypeQuery = propertyTypeQuery?.filter((list: any) => {
        return (list != undefined || list != null);
      });
      // Then append to payload
      if (propertyTypeQuery.length > 0) {
        this.filterPayload.query['property_type'] = propertyTypeQuery;
      } else {
        delete this.filterPayload.query['property_type'];
      }
    }

    // 6. set payload for Checklist filterList
    // We have to find the type list using 'find' method
    let checklistQuery = this.data.data.filterQuery.filterList.find((list: any) => {
      return list.label === 'Checklist';
    });
    // After we getting the Property list then we have to get the name of the Checklist for sending the payload to backend.
    if (checklistQuery.listItems.length > 0) {
      checklistQuery = checklistQuery?.listItems.map((list: any) => {
        if (list.selected == true) {
          return list.name
        }
      });
      checklistQuery = checklistQuery?.filter((list: any) => {
        return (list != undefined || list != null);
      });
      // Then append to payload
      if (checklistQuery.length > 0) {
        this.filterPayload.query['checklist'] = checklistQuery;
      } else {
        delete this.filterPayload.query['checklist'];
      }
    }

    // 7. set payload for Service Completed By filterList
    // We have to find the type list using 'find' method
    let serviceCompletedByQuery = this.data.data.filterQuery.filterList.find((list: any) => {
      return list.label === 'Service Completed By';
    });
    // After we getting the Property list then we have to get the name of the Service Completed By for sending the payload to backend.
    if (serviceCompletedByQuery.listItems.length > 0) {
      serviceCompletedByQuery = serviceCompletedByQuery?.listItems.map((list: any) => {
        if (list.selected == true) {
          return list.name
        }
      });
      serviceCompletedByQuery = serviceCompletedByQuery?.filter((list: any) => {
        return (list != undefined || list != null);
      });
      // Then append to payload
      if (serviceCompletedByQuery.length > 0) {
        this.filterPayload.query['service_completed_by'] = serviceCompletedByQuery;
      } else {
        delete this.filterPayload.query['service_completed_by'];
      }
    }

    // 8. set payload for Tenant filterList
    // We have to find the type list using 'find' method
    let tenantQuery = this.data.data.filterQuery.filterList.find((list: any) => {
      return list.label === 'Tenant';
    });
    // After we getting the Property list then we have to get the id and name of the Tenant for sending the payload to backend.
    if (tenantQuery.listItems.length > 0) {
      tenantQuery = tenantQuery?.listItems.map((list: any) => {
        if (list.selected == true) {
          return {id: list.id, name: list.name};
        }
      });
      tenantQuery = tenantQuery?.filter((list: any) => {
        return (list != undefined || list != null);
      });
      // Then append to payload
      if (tenantQuery.length > 0) {
        this.filterPayload.query['tenant'] = tenantQuery;
      } else {
        delete this.filterPayload.query['tenant'];
      }
    }

    // 9. set payload for Vendor filterList
    // We have to find the type list using 'find' method
    let vendorQuery = this.data.data.filterQuery.filterList.find((list: any) => {
      return list.label === 'Vendor';
    });
    // After we getting the Property list then we have to get the id and name of the Vendor for sending the payload to backend.
    if (vendorQuery.listItems.length > 0) {
      vendorQuery = vendorQuery?.listItems.map((list: any) => {
        if (list.selected == true) {
          return {id: list.id, name: list.name};
        }
      });
      vendorQuery = vendorQuery?.filter((list: any) => {
        return (list != undefined || list != null);
      });
      // Then append to payload
      if (vendorQuery.length > 0) {
        this.filterPayload.query['vendor'] = vendorQuery;
      } else {
        delete this.filterPayload.query['vendor'];
      }
    }

    // 10. sort filter query
      if (this.sort?.active) {
        this.filterPayload['active'] = this.sort.active;
        this.filterPayload['direction'] = this.sort.direction;
      } else {
        delete this.filterPayload['active'];
        delete this.filterPayload['direction'];
      }
      delete this.filterPayload.query['filterList'];
  }
}
