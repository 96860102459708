import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-required-checklist-missing',
    templateUrl: './required-checklist-missing.component.html',
    styleUrls: ['./required-checklist-missing.component.scss'],
    standalone: true,
    imports: [MatIconModule, MatDialogModule, ButtonComponent]
})
export class RequiredChecklistMissingComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<RequiredChecklistMissingComponent>,) { }

  ngOnInit(): void {
  }

  viewCheckListItems(): void {
    const result = {
      isShowChecklistItems: true
    }
    this.dialogRef.close(result);
  }

  ChangeStatusAnyway(): void {
    const result = {
      changesStatusAnyway: true
    }
    this.dialogRef.close(result);
  }

}
