import { Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnInit } from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'fyxt-icon-pin',
    templateUrl: './icon-pin.component.html',
    styleUrls: ['./icon-pin.component.scss'],
    standalone: true,
    imports: [CommonModule,MatIconModule, NgClass],
    schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class IconPinComponent implements OnInit {
  @Input() active:boolean = false;
  @Input() disabled: boolean = false;
  @Input() default: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  togglePin(): void{
    this.active = !this.active
  }


}
