import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Apollo, gql } from 'apollo-angular';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { DocumentInfo } from "../../../../@fyxt/_shared/models/porperty-manager/documents";
import { FOLDER_TYPE } from 'src/app/@fyxt/_shared/_views/_tab_components/tab-files/files.const';


@Injectable({
  providedIn: 'root',
})
export class FilesService {
  public subDomain;
  public selectedEntityId;
  public selectedEntityType;
  public sliderData;
  public currentFileClicked;
  public isAttachment: boolean = false;
  public showFolderListing = ['postal', 'internaltest', 'krishna', 'web', 'livedemo']

  public pageData: any[] = [];
  public currentViewingFile;
  public currentFile = new BehaviorSubject<any>(false);

  isTenantPortal: boolean = false;

  getCurrentFile() {
    return this.currentFile.asObservable();
  }


  constructor(private httpClient: HttpClient, private appolo: Apollo) {
    let url = window.location.origin;
    this.subDomain = this.extractDomain(url).split('.')[0];
    this.subDomain == 'localhost' ? this.subDomain = 'web' : this.subDomain;
  }


  defaultImage(type) {
    const doc = [
      'msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword',
      'doc',
      'docx',
    ]

    const pdf = ['application/pdf', 'pdf']

    const tiff = ['image/tiff', 'tiff']

    const txt = ['txt', 'text/plain']

    const excel = ['vnd.ms-excel', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'xlsx', 'xlsm', 'xls']

    const csv = ['csv', 'text/csv']

    const odf = ['vnd.oasis.opendocument.text', 'application/vnd.oasis.opendocument.text']

    if (pdf.includes(type))
      return 'assets/@fyxt/svgs/iconsDocuments/PDF.svg';
    if (doc.includes(type))
      return 'assets/@fyxt/svgs/iconsDocuments/DOC.svg';
    if (tiff.includes(type))
      return 'assets/@fyxt/svgs/iconsDocuments/TIFF.svg';
    if (txt.includes(type))
      return 'assets/@fyxt/svgs/iconsDocuments/TXT.svg';
    if (excel.includes(type))
      return 'assets/@fyxt/svgs/iconsDocuments/XLS.svg';
    if (csv.includes(type))
      return 'assets/@fyxt/svgs/iconsDocuments/CSV.svg';
    if (odf.includes(type))
      return 'assets/@fyxt/svgs/iconsDocuments/ODF.svg';
    if (type === 'audio' || type === 'mpga' || type === 'mp3' || type === 'audio/mpeg')
      return 'assets/@fyxt/svgs/iconsDocuments/MP3.png';
    if (type === 'mp4' || type === 'avi' || type === 'mpeg' || type === 'webm')
      return 'assets/@fyxt/svgs/iconsDocuments/MP3.png';
    if (type == 'text/html')
      return 'assets/@fyxt/svgs/iconsDocuments/HTML.svg';
    else 
      return 'assets/@fyxt/svgs/iconsDocuments/DEFAULT.svg';
  }

  extractDomain(url: string) {
    let domain;
    if (url.indexOf('://') > -1) {
      domain = url.split('/')[2];
    } else {
      domain = url.split('/')[0];
    }
    domain = domain.split(':')[0];
    return domain;
  }

  uploadFiles(formData) {
    return this.httpClient.post(environment.files.http + 'files', formData, { headers: { 'x-tenant-id': this.subDomain } } );
  }

  getFiles(entityType, entityId, sortField, sortType, searchValue, pageNumber, show, path = '/') {
    let excludedMimeTypes = [];

    if (!this.showFolderListing.includes(this.subDomain)) {
      excludedMimeTypes = ["application/folder"];
    }

    return this.appolo.use('files').query({ query: this.getAllFilesQuery(entityType, entityId, sortField, sortType, searchValue, pageNumber, show, path, excludedMimeTypes), variables: { excludedMimeTypes: excludedMimeTypes }  });
  }

  getFiles_ExportPDF(entityType, entityId) {
    return this.httpClient.get(environment.files.http+`files/for-entity?entityId=${entityId}&entityType=${entityType}`, { headers: { 'x-tenant-id': this.subDomain } });
  }

  getJobCostFiles_ExportPDF(formData) {
    return this.httpClient.post(environment.files.http + `files/entities`, formData, { headers: { 'x-tenant-id': this.subDomain } });
  }

  getPraticularFile(id) {
    return this.appolo.use('files').query({ query: this.getPraticularFileQuery(id) });
  }


  getPraticularFileQuery(id) {
    return gql`query{
      file(id:"${id}"){
        id,
        filename,
        size,
        mimeType,
        key,
        previewUrl,
        url,
        title,
        description,
        entityType,
        entityId,
        createdByType,
        source,
        createdById,
        createdAt,
        updatedAt,
        user{
          first_name,
          last_name
        },
        remindersCount
      }
    }`;

  }


  getAllFilesQuery(entityType, entityId, sortField, sortType, searchValue, pageNumber, show, path, excludedMimeTypes = []) {
    let sort = '';
    let search = '';
    let excludedMimeTypesFilter = '';
    let excludedMimeTypesVariable = '';
    if (sortField == '' && sortType == '') {
      sort = 'sort:{}';
    } else {
      sort = `sort:{${sortField}:"${sortType}"}`;
    }

    if (searchValue != '') {
      search = `searchTerm:"${searchValue}"`;
    }

    if (excludedMimeTypes.length > 0) {
      excludedMimeTypesFilter = `excludedMimeTypes:$excludedMimeTypes`;
      excludedMimeTypesVariable = `($excludedMimeTypes: [String!]!)`;
    }

    return gql`query ${excludedMimeTypesVariable} {
      files(filter:{entityId:"${entityId}",entityType:"${entityType}",path:"${path}",${search},${excludedMimeTypesFilter}},${sort},paginate:{show:${show},page:${pageNumber}}){
        id,
        filename,
        size,
        mimeType,
        key,
        previewUrl,
        url,
        title,
        description,
        entityType,
        entityId,
        createdByType,
        source,
        createdById,
        createdAt,
        updatedAt,
        tags,
        user{
          first_name,
          last_name
        },
        remindersCount
      }
    }`;
  }

  totalPagesInFiles(entityId, entityType) {
    const excludedMimeTypes = ["application/folder"];
    return this.appolo.use('files').query({ query: this.totalPagesInFilesQuery(entityId, entityType), variables:{ excludedMimeTypes: excludedMimeTypes } });
  }

  totalPageInSoftAuthFiles(entityId, entityType) {
    return this.appolo.use('files').query({
      query: this.totalPageInSoftAuthFilesQuery(entityId, entityType),
      context: {
        addCustomHeaders: true,
        headers: {
          "SoftAuthSkipHeader": 'SoftAuthSkipHeader',
        }
      }
    });
  }

  totalPageInSoftAuthFilesQuery(entityId, entityType) {
    return gql`query{
      vendorFilesCount(paginate:{},filter:{entityId:"${entityId}",entityType:"${entityType}"},sort:{})
    }` ;
  }

  getFilesForSoftAuth(entityType, entityId, sortField, sortType, searchValue, pageNumber, show) {
    return this.appolo.use('files').query({
      query: this.getAllFilesSoftAuthQuery(entityType, entityId, sortField, sortType, searchValue, pageNumber, show),
      context: {
        addCustomHeaders: true,
        headers: {
          "SoftAuthSkipHeader": 'SoftAuthSkipHeader',
        }
      }
    });
  }

  getAllFilesSoftAuthQuery(entityType, entityId, sortField, sortType, searchValue, pageNumber, show) {
    let sort = '';
    let search = '';
    if (sortField == '' && sortType == '') {
      sort = 'sort:{}';
    } else {
      sort = `sort:{${sortField}:"${sortType}"}`;
    }

    if (searchValue != '') {
      search = `searchTerm:"${searchValue}"`;
    }

    return gql`query{
      vendorFiles(filter:{entityId:"${entityId}",entityType:"${entityType}",${search}},${sort},paginate:{show:${show},page:${pageNumber}}){
        id,
        filename,
        size,
        mimeType,
        key,
        previewUrl,
        url,
        title,
        description,
        entityType,
        entityId,
        createdByType,
        source,
        createdById,
        createdAt,
        updatedAt,
        tags,
        user{
          first_name,
          last_name
        },
        remindersCount
      }
    }`;
  }


  totalPagesInFilesQuery(entityId, entityType) {
    return gql`query($excludedMimeTypes: [String!]!){
      filesCount(paginate:{},filter:{entityId:"${entityId}",entityType:"${entityType}",excludedMimeTypes:$excludedMimeTypes},sort:{})
    }`;

  }


  updateFile(fileId, title, desc, tags) {
    if (title == 'null' || title == null) {
      title = '';
    }
    if (desc == 'null' || desc == null) {
      desc = '';
    }
    return this.appolo.use('files').query({ query: this.updateFileQuery(fileId, title, desc, tags) });
  }

  updateFileQuery(fileId, title, desc, tags) {
    return gql`mutation{
      updateFile(id:"${fileId}",file:{title:"${title}",description:"${desc}",tags:"${tags}"}){
        id,
        filename,
        size,
        mimeType,
        key,
        previewUrl,
        url,
        title,
        description,
        entityType,
        entityId,
        createdByType,
        source,
        createdById,
        createdAt,
        updatedAt,
        user{
          first_name,
          last_name
        },
      }
    }
    `;
  }

  getFile(fileId) {
    return this.appolo.use('files').query({ query: this.getFileQuery(fileId) });
  }

  getFileQuery(fileId) {
    return gql`query{
      file(id:"${fileId}"){
        id,
        filename,
        size,
        mimeType,
        key,
        previewUrl,
        url,
        title,
        description,
        entityType,
        entityId,
        createdByType,
        source,
        createdById,
        createdAt,
        updatedAt,
        tags,
        user{
          first_name,
          last_name
        },
      }
    }`;
  }

  replaceFile(id, body, params?: { isLeaseDocument: boolean }) {
    let controllerName = 'files'

    if (params?.isLeaseDocument) {
      controllerName = 'lease-documents'
    }

    return this.httpClient.put(`${environment.files.http}${controllerName}/replace`, body, { headers: { 'x-tenant-id': this.subDomain }, params: { id } });
  }

  addFilePhotoBank(body) {
    return this.httpClient.post(`${environment.files.http}files/photo-bank`, body, { headers: { 'x-tenant-id': this.subDomain } });
  }

  addTempFilePhotoBank(body) {
    return this.httpClient.post(`${environment.files.http}files/temp/photo-bank`, body, { headers: { 'x-tenant-id': this.subDomain } });
  }

  uploadUrl(body) {
    return this.httpClient.post(`${environment.files.http}files/url`, body, { headers: { 'x-tenant-id': this.subDomain } });
  }

  photoBankMyPhotos(url: string) {
    return this.httpClient.get(`${environment.files.http}files/photo/${url}`, { headers: { 'x-tenant-id': this.subDomain } });
  }

  donwloadFile(id) {
    return this.httpClient.get(`${environment.files.http}files/download?key=${id}`, { responseType: 'blob' });
  }

  deleteFile(data: any) {
    let payload: any = {
      key: data,
    };
    const options = {
      headers: {
        'x-tenant-id': this.subDomain,
      },
      body: payload,
    };
    return this.httpClient.delete(`${environment.files.http}files`, options);
  }

  addReminder(fileId, data, zone) {
    return this.appolo.use('files').mutate({
      mutation: gql`mutation($primary_engineers:[String!]!,
        $engineers_assigned:[String!]!,
        $selected_contacts:[String!]!,
        $selected_engineers:[String!]!,
        $selected_managers:[String!]!,
        $managers_assigned:[String!]!){
        createReminder(createReminder:{
          fileId:"${fileId}",
          scheduledAt:"${data.date}",
          title:"${data.title}",
          types:"${data.type}",
          managerIds:$selected_managers,
          engineerIds:$selected_engineers,
          contactIds:$selected_contacts,
          engineerAssignedPropertyIds:$engineers_assigned,
          engineerPrimaryPropertyIds:$primary_engineers,
          status:"AWAITING",
          managerPropertyIds:$managers_assigned,
          redirectURL:"${data.redirectURL}",
          timeZone:"${zone}"
        }){
          id,fileId,engineerAssignedPropertyIds,engineerPrimaryPropertyIds
        }
      }`, variables: {
        primary_engineers: data.primary_engineers,
        engineers_assigned: data.engineers_assigned,
        selected_contacts: data.selected_contacts,
        selected_engineers: data.selected_engineers,
        selected_managers: data.selected_managers,
        managers_assigned: data.managers_assigned,
      },
    });
  }


  addBulkReminder(fileId, data, zone) {
    return this.appolo.use('files').mutate({
      mutation: gql`mutation(
        $file_id:[String!]!,
        $primary_engineers:[String!]!,
        $engineers_assigned:[String!]!,
        $selected_contacts:[String!]!,
        $selected_engineers:[String!]!,
        $selected_managers:[String!]!,
        $managers_assigned:[String!]!){
        createBulkReminder(createReminder:{
          fileIds:$file_id,
          scheduledAt:"${data.date}",
          title:"${data.title}",
          types:"${data.type}",
          managerIds:$selected_managers,
          engineerIds:$selected_engineers,
          contactIds:$selected_contacts,
          engineerAssignedPropertyIds:$engineers_assigned,
          engineerPrimaryPropertyIds:$primary_engineers,
          managerPropertyIds:$managers_assigned,
          redirectURL:"${data.redirectURL}",
          timeZone:"${zone}"
        }){
          id,fileId,engineerAssignedPropertyIds,engineerPrimaryPropertyIds
        }
      }`, variables: {
        file_id: fileId,
        primary_engineers: data.primary_engineers,
        engineers_assigned: data.engineers_assigned,
        selected_contacts: data.selected_contacts,
        selected_engineers: data.selected_engineers,
        selected_managers: data.selected_managers,
        managers_assigned: data.managers_assigned,
      },
    });
  }

  getRemindeForFiles(id) {
    return this.appolo.use('files').query({
      query: gql`query{remindersForFile(id:"${id}"){
        id,
        fileId,
        createdById,
        createdByType,
        scheduledAt,
        title,
        types,
        managerPropertyIds,
        managerIds,
        engineerIds,
        contactIds,
        engineerAssignedPropertyIds,
        engineerPrimaryPropertyIds,
        status,
        createdAt,
        updatedAt
      }}`,
    });
  }

  deleteReminder(id) {
    return this.appolo.use('files').mutate({ mutation: gql`mutation{deleteReminder(id:"${id}")}` });
  }

  updateReminder(id, data, zone) {
    return this.appolo.use('files').mutate({
      mutation: gql`mutation($primary_engineers:[String!]!,
        $engineers_assigned:[String!]!,
        $selected_contacts:[String!]!,
        $selected_engineers:[String!]!,
        $selected_managers:[String!]!,
        $managers_assigned:[String!]!){
        updateReminder(
          id:"${id}",
          updateReminder:{
            scheduledAt:"${data.date}",
            title:"${data.title}",
            types:"${data.type}",
            managerIds:$selected_managers,
            engineerIds:$selected_engineers,
            contactIds:$selected_contacts,
            engineerAssignedPropertyIds:$engineers_assigned,
            engineerPrimaryPropertyIds:$primary_engineers,
            status:"AWAITING",
            managerPropertyIds:$managers_assigned,
            timeZone:"${zone}"

          }){
          id,fileId,engineerAssignedPropertyIds,engineerPrimaryPropertyIds
        }
      }`, variables: {
        primary_engineers: data.primary_engineers,
        engineers_assigned: data.engineers_assigned,
        selected_contacts: data.selected_contacts,
        selected_engineers: data.selected_engineers,
        selected_managers: data.selected_managers,
        managers_assigned: data.managers_assigned,
      },
    });

  }


  uploadTempUrl(body) {
    return this.httpClient.post(`${environment.files.http}files/temp/url`, body, { headers: { 'x-tenant-id': this.subDomain } });
  }

  uploadTempUrlEXT(body) {
    return this.httpClient.post(`${environment.files.http}files/temp/url/external`, body, { headers: { 'x-tenant-id': this.subDomain } });
  }

  getAllManagers(page) {
    return this.httpClient.get(`${environment.files.http}reminders/managers?page=${page}`, { headers: { 'x-tenant-id': this.subDomain } });
  }

  searchManagers(data) {
    return this.httpClient.get(`${environment.files.http}reminders/managers?search=${data}`, { headers: { 'x-tenant-id': this.subDomain } });
  }

  getAllEngineers(page) {
    return this.httpClient.get(`${environment.files.http}reminders/engineers?page=${page}`, { headers: { 'x-tenant-id': this.subDomain } });
  }

  searchEngineers(data) {
    return this.httpClient.get(`${environment.files.http}reminders/engineers?search=${data}`, { headers: { 'x-tenant-id': this.subDomain } });
  }

  getAllProperty(pagenumber) {
    return this.httpClient.get(`${environment.files.http}reminders/properties?page=${pagenumber}`, { headers: { 'x-tenant-id': this.subDomain } });
  }

  searchProperty(data) {
    return this.httpClient.get(`${environment.files.http}reminders/properties?search=${data}`, { headers: { 'x-tenant-id': this.subDomain } });
  }

  getlink(obj) {
    return this.httpClient.post(`${environment.softAuth.url}links`, obj, {
      headers: {
        'x-tenant-id': this.subDomain,
      },
    });
  }


  getJob(id) {
    return this.httpClient.get(`${environment.softAuth.url}links/public/job/${id}`, {
      headers: {
        'x-tenant-id': this.subDomain,
      },
    });


  }



  getSelectedTableData1(selectedViewTableRows: string) {
    return this.httpClient.get(selectedViewTableRows);
  }

  getManagersInfo(ids) {
    return this.httpClient.post(`${environment.files.http}reminders/ids/managers`, { ids }, { headers: { 'x-tenant-id': this.subDomain } });
  }

  getEngineersInfo(ids) {
    return this.httpClient.post(`${environment.files.http}reminders/ids/engineers`, { ids }, { headers: { 'x-tenant-id': this.subDomain } });
  }

  getPropertiesInfo(ids) {
    return this.httpClient.post(`${environment.files.http}reminders/ids/properties`, { ids }, { headers: { 'x-tenant-id': this.subDomain } });
  }

  publicFileUpload(file, entityId, entityType, title, createdById, tag) {
    let formData = new FormData()
    formData.append('file', file);
    formData.append('entityId', entityId);
    formData.append('entityType', entityType);
    formData.append('title', title);
    formData.append('createdById', createdById);
    formData.append('createdByType', 'VENDOR');
    formData.append('tags[0]', tag);

    if(tag) {
      const folderType = 
      tag === 'post-completion' ? FOLDER_TYPE.POST_COMPLETION :
      tag === 'pre-completion' ? FOLDER_TYPE.PRE_COMPLETION :
      FOLDER_TYPE.OTHER;
      formData.append('path', folderType);
    }

   
    return this.httpClient.post(`${environment.files.http}files/public`, formData, { headers: { 'x-tenant-id': this.subDomain } })
  }

  publicDeleteFile(obj) {
    const options = {
      headers: new HttpHeaders({
        'x-tenant-id': this.subDomain,
      }),
      body: obj,
    };
    return this.httpClient.delete(`${environment.files.http}files/public`, options)
  }


  getVendorFiles(createdById, CreatedByType, entityId, entityType) {
    return this.appolo.use('files').query({ query: this.getVendorQuery(createdById, CreatedByType, entityId, entityType) });
  }


  getVendorQuery(createdById, CreatedByType, entityId, entityType) {
    return gql`query{
      externalFiles(filter:{entityId:"${entityId}",entityType:"${entityType}",vendorId:"${createdById}"},paginate:{page:1},sort: { mimeType: "ASC" }){
        id,
        filename,
        size,
        mimeType,
        key,
        previewUrl,
        url,
        title,
        description,
        entityType,
        entityId,
        createdByType,
        source,
        createdById,
        createdAt,
        updatedAt,
        tags
      }
    }`;
  }



  getEXTVendorJobCostFiles(entityId, entityType) {
    return this.appolo.use('files').query({ query: this.getEXTVendorJobCostFilesQuery(entityId, entityType) });
  }


  getEXTVendorJobCostFilesQuery(entityId, entityType) {
    return gql`query{
      externalFiles(filter:{entityId:"${entityId}",entityType:"${entityType}"},paginate:{page:1},sort: { mimeType: "ASC" }){
        id,
        filename,
        size,
        mimeType,
        key,
        previewUrl,
        url,
        title,
        description,
        entityType,
        entityId,
        createdByType,
        source,
        createdById,
        createdAt,
        updatedAt,
        tags
      }
    }`;
  }


  tempFileUploadNoAuth(file) {
    let formData = new FormData()
    formData.append('file', file)
    return this.httpClient.post(`${environment.files.http}files/temp/external`, formData, { headers: { 'x-tenant-id': this.subDomain } })
  }

  timeFilesSyncNoAuth(payload) {
    return this.httpClient.post(`${environment.files.http}files/temp/entity/connect/external`, payload, { headers: { 'x-tenant-id': this.subDomain } })
  }
  getFileById(id: string) {
    return this.httpClient.get(`${environment.files.http}files/${id}`, { headers: { 'x-tenant-id': this.subDomain } });
  }

  uploadFile(
    file: Blob,
    entityId: string | null,
    entityType: string,
    fileName?: string
  ): Observable<HttpEvent<{ data: DocumentInfo }>> {
    const url = `${environment.files.http}files`;
    const formData: any = new FormData();
    //any other fields
    formData.append('entityId', entityId);
    formData.append('entityType', entityType);
    formData.append('file', file, fileName);

    return this.httpClient.post<{ data: DocumentInfo }>(url, formData
      , {
        headers: {
          'x-tenant-id': this.subDomain,
        }, reportProgress: true,
        observe: 'events',
      },
    );
  }

  getPreCompletionFiles(entityType, entityId, CreatedByType, createdById, sortField?, sortType?, excludedMimeTypes = ["application/folder"]) {
    return this.appolo.use('files').query({ 
      query: this.getPreCompletionFilesQuery(entityType, entityId, CreatedByType, createdById, sortField, sortType, excludedMimeTypes), 
      variables: { excludedMimeTypes: excludedMimeTypes }
    });
  }

  getPreCompletionFilesQuery(entityType, entityId, CreatedByType, createdById, sortField?, sortType?, excludedMimeTypes = []) {
    let sort = '';
    let excludedMimeTypesFilter = '';
    let excludedMimeTypesVariable = '';
  
    // Sorting logic
    if (sortField == '' && sortType == '') {
      sort = 'sort:{}';
    } else {
      sort = `sort:{${sortField}:"${sortType}"}`;
    }
  
    // Handling excludedMimeTypes
    if (excludedMimeTypes.length > 0) {
      excludedMimeTypesFilter = `excludedMimeTypes: $excludedMimeTypes`;
      excludedMimeTypesVariable = `($excludedMimeTypes: [String!]!)`;
    }
  
    return gql`query ${excludedMimeTypesVariable} {
      externalFiles(filter:{entityId: "${entityId}", entityType: "${entityType}", createdByType: "${CreatedByType}", createdById: "${createdById}", ${excludedMimeTypesFilter}, tags: ["pre-completion"]}, paginate: { page: 1 }, ${sort}) {
        id,
        filename,
        size,
        mimeType,
        key,
        previewUrl,
        url,
        title,
        description,
        entityType,
        entityId,
        createdByType,
        source,
        createdById,
        createdAt,
        updatedAt,
        tags,
        user {
          first_name,
          last_name
        },
        remindersCount
      }
    }`;
  }
  

  getOtherFiles(entityType, entityId, CreatedByType, createdById, sortField?, sortType?, excludedMimeTypes = ["application/folder"]) {
    return this.appolo.use('files').query({ 
      query: this.getOtherFilesQuery(entityType, entityId, CreatedByType, createdById, sortField, sortType, excludedMimeTypes), 
      variables: { excludedMimeTypes: excludedMimeTypes } 
    });
  }

  getOtherFilesQuery(entityType, entityId, CreatedByType, createdById, sortField?, sortType?, excludedMimeTypes = []) {
    let sort = ''
    let excludedMimeTypesFilter = '';
    let excludedMimeTypesVariable = '';

    if (sortField == '' && sortType == '') {
      sort = 'sort:{}';
    } else {
      sort = `sort:{${sortField}:"${sortType}"}`;
    }

    if (excludedMimeTypes.length > 0) {
      excludedMimeTypesFilter = `excludedMimeTypes:$excludedMimeTypes`;
      excludedMimeTypesVariable = `($excludedMimeTypes: [String!]!)`;
    }

    return gql`query ${excludedMimeTypesVariable} {
      externalFiles(filter:{entityId:"${entityId}",entityType:"${entityType}",vendorId:"${createdById}", ${excludedMimeTypesFilter}},paginate:{page:1},${sort}){
        id,
        filename,
        size,
        mimeType,
        key,
        previewUrl,
        url,
        title,
        description,
        entityType,
        entityId,
        createdByType,
        source,
        createdById,
        createdAt,
        updatedAt,
        tags,
        user{
          first_name,
          last_name
        },
        remindersCount
      }
    }`;
  }


  getPostCompletionFiles(entityType, entityId, CreatedByType, createdById, sortField?, sortType?, excludedMimeTypes = ["application/folder"]) {
    return this.appolo.use('files').query({ 
      query: this.getPostCompletionFilesQuery(entityType, entityId, CreatedByType, createdById, sortField, sortType, excludedMimeTypes), 
      variables: { excludedMimeTypes: excludedMimeTypes }
    });
  }

  getPostCompletionFilesQuery(entityType, entityId, CreatedByType, createdById, sortField?, sortType?, excludedMimeTypes = []) {
    let sort = '';
    let excludedMimeTypesFilter = '';
    let excludedMimeTypesVariable = '';
  
    // Sorting logic
    if (sortField == '' && sortType == '') {
      sort = 'sort:{}';
    } else {
      sort = `sort:{${sortField}:"${sortType}"}`;
    }
  
    // Handling excludedMimeTypes
    if (excludedMimeTypes.length > 0) {
      excludedMimeTypesFilter = `excludedMimeTypes: $excludedMimeTypes`;
      excludedMimeTypesVariable = `($excludedMimeTypes: [String!]!)`;
    }
  
    return gql`query ${excludedMimeTypesVariable} {
      externalFiles(filter:{entityId: "${entityId}", entityType: "${entityType}", createdByType: "${CreatedByType}", createdById: "${createdById}", ${excludedMimeTypesFilter}, tags: ["post-completion"]}, paginate: { page: 1 }, ${sort}) {
        id,
        filename,
        size,
        mimeType,
        key,
        previewUrl,
        url,
        title,
        description,
        entityType,
        entityId,
        createdByType,
        source,
        createdById,
        createdAt,
        updatedAt,
        tags,
        user {
          first_name,
          last_name
        },
        remindersCount
      }
    }`;
  }

  jobSeenUpdate(jobID: string) {
    return this.httpClient.patch(environment.LocalDev_URL_V2 + 'user-job-status/job-seen-update/', { "job_id": jobID })
  }

  addFilePermission(payload) {
    return this.httpClient.post(`${environment.files.http}permissions/add`, payload, { headers: { 'x-tenant-id': this.subDomain } });
  }

  removeFilePermission(payload) {
    return this.httpClient.post(`${environment.files.http}permissions/remove`, payload, { headers: { 'x-tenant-id': this.subDomain } });
  }

  getUserPermittedFiles(fileId) {
    return this.httpClient.get(`${environment.files.http}permissions/files/${fileId}/users`, { headers: { 'x-tenant-id': this.subDomain } });
  }

  copyFiles(payload) {
    return this.httpClient.post(`${environment.files.http}files/copy`, payload, { headers: { 'x-tenant-id': this.subDomain } })
  }

  deleteFolder(path, entityId, entityType) {
    const options = {
      headers: {
        'x-tenant-id': this.subDomain,
      }
    };
    return this.httpClient.delete(`${environment.files.http}files/folders/delete?folderKey=${path}&entityId=${entityId}&entityType=${entityType}`,options);
  }


}
