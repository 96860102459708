import {BehaviorSubject, Observable, merge, of} from 'rxjs';
import {debounceTime, finalize, map, throttleTime} from 'rxjs/operators';

import CountUpdateEventEntity from '../models/entities/events/count_update_event_entity';
import {DropdownInput} from '../../controls/modules/app-dropdown/models/dropdown.model';
import InboxApi from './inbox-api.service';
import InboxFolder from '../models/enum/inbox-folder';
import InboxFolderFilterMap from '../models/mappings/inbox-folder-filter-map';
import {InboxMenuService} from './inbox-menu.service';
import InboxRealtimeEventsService from './inbox-realtime-events-service';
import InboxSortOption from '../models/enum/inbox-sort-option';
import InboxSortOptionMap from '../models/mappings/inbox-sort-option-map';
import {Injectable} from '@angular/core';
import MailEntity from '../models/entities/mail/mail-entity';
import MailStatus from '../models/entities/mail/mail-status';
import MailboxEntity from '../models/entities/mailbox/mailbox-entity';
import MailboxQueryParamEntity from '../models/entities/mailbox/mailbox-query-param-entity';
import {MessageEntity} from '../models/entities/message-entity';
import NewMessageEventEntity from '../models/entities/events/new_messages_event-entity';
import PaginatedApiResult from 'src/app/shared/models/paginated-api-result';
import { InboxActionApiService } from './inbox-action-api.service';
import { environment } from 'src/environments/environment';
import { BaseService } from 'src/app/services/base.service';
import { ConfigService } from 'src/app/services/config.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  public mailbox: MailboxEntity;
  private messagesPaginationUrl = new BehaviorSubject<string | undefined>(
    undefined
  );
  public iboxMessageComponentRefresh:Boolean = false;
  private messages = new BehaviorSubject<MessageEntity[]>([]);
  private messagesLoading = new BehaviorSubject<boolean>(false);
  public scrollBottomConversation = new BehaviorSubject<boolean>(false);
  private activeMessage = new BehaviorSubject<MessageEntity | undefined>(
    undefined
  );
  private selectedMessages: MessageEntity[] = [];
  public readonly defaultInboxFolder = InboxFolder.UNASSIGNED;
  public activeMenu = new BehaviorSubject<InboxFolder>(
    this.defaultInboxFolder
  );

  // TODO: Create a Model for Message Filters
  private sortOption = new BehaviorSubject<InboxSortOption | null>(null);
  private searchString = new BehaviorSubject<string | undefined>(undefined);
  selectedProperties: DropdownInput[] = [];
  selectedAssignees: DropdownInput[] = [];
  selectedTenants: DropdownInput[] = [];
  selectedCategories: DropdownInput[] = [];
  selectedSearchType: DropdownInput;
  addtionalFilters: {
    from?: string;
    to?: string;
    subject?: string;
    hasAttachment?: boolean;
    unread?: boolean;
  };
  isFilterAddedMail = false;
  selectedMessageFromSecondPanel: any;

  constructor(
    private inboxApi: InboxApi,
    private inboxMenuService: InboxMenuService,
    private realtimeEvents: InboxRealtimeEventsService,
    private inboxActionApiService:InboxActionApiService,
    private __baseService:BaseService,
    private _Config:ConfigService,
    private _router:Router
  ) {
    this.setupSubscriptions();
  }

  getActiveMenu(): Observable<InboxFolder> {
    return this.activeMenu.asObservable();
  }

  setActiveMenu(folder: InboxFolder, fetchMails = true, openDefaultInbox?: boolean) {
    // this.activeMenu.next(folder);
    // this.activeMessage.next(undefined);
    // if (fetchMails) {
    //   if (this.selectedSearchType?.value) {
    //     this.selectedSearchType = {
    //       value: InboxFolderFilterMap[folder],
    //       viewValue: folder
    //     }
    //   }
    //   this.uncheckAllCheckbox();
    //   this.showLessAllConversation();      
    //   this.fetchMailsAndCount(openDefaultInbox);
    // }
  }

  public createNewMail() {
    this._Config.isLoader = true;
    if (!this.mailbox) {
      return;
    }
    this.inboxApi.createNewMail(this.mailbox).subscribe((data:any) => {
      let mail_id = data.id;
      let user_id = JSON.parse(localStorage.getItem('userobj')).id;
      this.iboxMessageComponentRefresh = false;
      const request = {
        mailId: mail_id,
        manager: user_id,
        mailBoxId: this.inboxMenuService.fetchSelectedMailBoxId()
      };
      this.inboxActionApiService.assignManager(request).subscribe((data:any) => {
        this.removeAllFilters();
        this.setActiveMenu(InboxFolder.OPEN_CONVERSATIONS);
        this.inboxMenuService.selectedFolder = InboxFolder.OPEN_CONVERSATIONS;
      //  this.fetchMailsAndCount(true);
      });
    });
  }

  public removeAllFilters(): void {
   // this.setSortOption(null);
    this.sortOption.next(null);
    this.setSearchString(undefined);
    this.addtionalFilters.from = undefined;
    this.addtionalFilters.to = undefined;
    this.addtionalFilters.subject = undefined;
    this.addtionalFilters.hasAttachment = undefined;
    this.addtionalFilters.unread = undefined;
    this.selectedProperties = [];
    this.selectedTenants = [];
    this.selectedAssignees = [];
    this.selectedCategories = [];
    this.isFilterAddedMail = false;
    this.setDefaultSearchType();
  }


  getSortOption(): Observable<InboxSortOption> {
    return this.sortOption.asObservable();
  }

  setSortOption(option: InboxSortOption) {
    this.sortOption.next(option);
    this.fetchMails(false).subscribe();
  }

  getSearchString(): Observable<string> {
    return this.searchString.asObservable();
  }

  setSearchString(val: string) {
    this.searchString.next(val);
  }

  fetchMessages(): Observable<MessageEntity[]> {
    return this.messages.asObservable();
  }

  fetchMessagesIndicator(): Observable<boolean> {
    return this.messagesLoading.asObservable();
  }

  fetchScrollBottomIndicator(): Observable<boolean> {
    return this.scrollBottomConversation.asObservable();
  }


  fetchNextPage() {
    const url = this.messagesPaginationUrl.value;
    if (!url) {
      return;
    }
    this.inboxApi
      .fetchNextPageMails(url)
      .subscribe((mails) => this.handleMails(this.messages.value, mails));
  }

  getPaginationUrl(): Observable<string | undefined> {
    return this.messagesPaginationUrl.asObservable();
  }

  fetchMessageByIndex(index: number): MessageEntity {
    return this.messages[index];
  }

  viewMessage(message: MessageEntity) {
    this.activeMessage.next(message);
  }

  addMessage(message: MessageEntity): void {
    this.selectedMessages.push(message);
  }

  getActiveMessage(): Observable<MessageEntity | undefined> {
    return this.activeMessage.asObservable();
  }

  getActiveMessageDetail(): MessageEntity | undefined {
    return this.activeMessage?.value;
  }

  getActiveMessageId(): string | undefined {
    return this.activeMessage?.value?.message_id;
  }

  getSelectedMessages(): MessageEntity[] {
    return this.selectedMessages;
  }

  fetchMails(flushActiveMessage = true, cacheMails: MessageEntity[] = []): Observable<MessageEntity[]> {
    const mailbox = this.mailbox;
    if (!mailbox) {
      return of();
    }
    if (flushActiveMessage) {
      this.activeMessage.next(undefined);
      this.messagesLoading.next(true);
    }
    return this.inboxApi.fetchAllMails(mailbox, this.prepareMailQuery()).pipe(
      map((mails) => this.handleMails(cacheMails, mails, flushActiveMessage)),
      finalize(() => {
        this.messagesLoading.next(false);
      })
    );
  }

  fetchMailsAndCount(flushActiveMessage = true, handleMails?: (mails: MessageEntity[]) => void) {
    this.fetchMails(flushActiveMessage).subscribe((handleMails)=>{
      setTimeout(() => { 
        this.inboxMenuService.getMenus(this.prepareMailQuery());
      }, 500);  
    });
  }

  bulkActionsUpload(payload: any) {
    this.inboxApi.bulkUploadMails(payload, this.mailbox.id).subscribe(() => {
      this.fetchMailsAndCount();
    });
  }

  clearActiveMessage() {
    this.activeMessage.next(undefined);
  }

  clearAllMessageFilter(): void {
    this.selectedAssignees = [];
    this.selectedCategories = [];
    this.selectedProperties = [];
    this.selectedTenants = [];
    this.setDefaultSearchType();
    this.addtionalFilters = {};
    this.isFilterAddedMail = false;
  }

  uncheckAllCheckbox(): void {
    if (this.inboxApi.currentUserInfo?.id && this._router.url.includes('inbox')) {
      const payload = {
        mailbox: this.mailbox?.id
      }
      this.inboxApi.uncheckAllCheckboxMail(payload).subscribe(() => {
      });
    }
  }

  showLessAllConversation(): void {
    if(this.mailbox?.id){
      const payload = {
        mailbox: this.mailbox?.id
        }
        this.inboxApi.showLessAllMail(payload).subscribe(() => {});
    }
  }

  private async openSelectedEmail(mailBox: MailboxEntity): Promise<void> {
    if (!this.inboxMenuService.selectedMessage) return;
    let folder: InboxFolder;
    switch (this.inboxMenuService.selectedMessage.mailStatus) {
      case MailStatus.Resolved:
        folder = InboxFolder.CLOSED_CONVERSATION;
        break;
      case MailStatus.Closeout:
        folder = InboxFolder.CLOSE_OUT;
        break;
      default:
        folder = InboxFolder.OPEN_CONVERSATIONS;
        break;
    }

    // this.activeMenu.next(folder);
    this.setActiveMenu(folder, true, false);

    // set active message based on selected mail id
    const mail = await this.inboxApi
      .fetchMail(
        this.inboxMenuService.selectedMessage.mailBoxId,
        this.inboxMenuService.selectedMessage.mailId
      )
      .toPromise();

    // fetch all mails under selected menu
    const message = this.mapToMessageEntity(mail, mailBox);
    this.activeMessage.next(message);

    // this.fetchMails(false, [message]).subscribe();

    // clear the selected message
    this.inboxMenuService.selectedMessage = undefined;
  }

  getUserInfo() {
    if (this.__baseService.isPublicPage()) {
      return;
    }
    
    let requestURL = environment.baseURL + this._Config.getProfile;
    this.__baseService.doGET(requestURL).subscribe(
      (result: any) => {
        this.inboxMenuService.count.next(result);
        this.__baseService.setUserInfo(result);

      },
      (error) => {
      }
    );
  }

  private setupSubscriptions() {
    this.inboxMenuService.fetchSelectedMailbox().subscribe((mailbox) => {
      this.clearAllMessageFilter();
      this.mailbox = mailbox;
      if (mailbox && this.inboxMenuService.selectedMessage) {
        this.openSelectedEmail(mailbox);
      } else {
        this.setActiveMenu(this.defaultInboxFolder);
      }
    });

    merge(
      this.realtimeEvents.getNewMessageEvent(),
      this.realtimeEvents.getMailCountEvent()
    )
      .pipe(throttleTime(1000))
      .subscribe((event: NewMessageEventEntity | CountUpdateEventEntity) => {
        this.getUserInfo();
        if (event.mailbox_id === this.mailbox?.id) {
          this.fetchMailsAndCount(false);
        }
      });

    this.getSearchString()
      .pipe(debounceTime(1000))
      .subscribe((key) => {
        if (key !== undefined) this.fetchMailsAndCount();
      });
  }

  private prepareMailQuery() {
    const query: MailboxQueryParamEntity = {
     // filter: InboxFolderFilterMap[this.activeMenu.value]
    };

    if (this.sortOption.value) {
    //  query.sort = InboxSortOptionMap[this.sortOption.value];
    }

    if (this.searchString.value) {
      query.search = this.searchString.value;
      // delete query.filter;
    }

    query.query = {};
    if (this.selectedAssignees.length)
      query.query.assignees = this.selectedAssignees.map(($0) => $0.value);
    if (this.selectedCategories.length)
      query.query.categories = this.selectedCategories.map(($0) => $0.value);
    if (this.selectedProperties.length)
      query.query.properties = this.selectedProperties.map(($0) => $0.value);
    if (this.selectedTenants.length)
      query.query.tenants = this.selectedTenants.map(($0) => $0.value);
    if (this.selectedSearchType) {
      query.query.search = [this.selectedSearchType.value];
      // query.filter = this.selectedSearchType.value;
    }
    if (this.addtionalFilters?.from)
      query.query.from = [this.addtionalFilters.from];
    if (this.addtionalFilters?.to) query.query.to = [this.addtionalFilters.to];
    if (this.addtionalFilters?.hasAttachment)
      query.query.has_attachments = ['attachments'];
    if (this.addtionalFilters?.unread) query.query.unread = ['unread'];
    if (this.addtionalFilters?.subject)
      query.query.subject = [this.addtionalFilters.subject];

    return query;
  }

  fetchAppliedFilters(): MailboxQueryParamEntity {
    return this.prepareMailQuery();
  }

  private handleMails(
    currentMails: MessageEntity[],
    mails: PaginatedApiResult<MailEntity[]>,
    flushActiveMessage?: boolean
  ) {
    const mailbox = this.mailbox;
    if (!mailbox) {
      return;
    }
    this.messagesPaginationUrl.next(mails.next);
    const newMessages = mails.results.map((mail) =>
      this.mapToMessageEntity(mail, mailbox)
    );

    let finalMessages: MessageEntity[] = [];
    if (
      currentMails.length === 1 &&
      newMessages.find(($0) => $0.message_id === currentMails[0].message_id)
    ) {
      finalMessages = newMessages;
    } else {
      finalMessages = currentMails.concat(newMessages);
    }

    this.messages.next(finalMessages);
    if (flushActiveMessage) {
      this.showLessAllConversation();
      this.activeMessage.next(finalMessages[0]);
      this.scrollBottomConversation.next(true);
    }
    return finalMessages;
  }

  private mapToMessageEntity(mail: MailEntity, mailbox: MailboxEntity) {
    return new MessageEntity(
      mail.subject,
      mail.recent_message,
      mail.recent_message_time ?? mail.modified_at,
      mail.id + '',
      mailbox.id,
      mail.read,
      false,
      mail.check.filter((o) => o.is_checked === true).length > 0 ? true : false,
      {
        first_name: mail.sender?.fullname ?? '',
        last_name: '',
        id: mail.sender?.id ?? ''
      },
      mail.manager,
      'Property Name',
      mail.label,
      mail.jobs,
      mail.check
    );
  }

  setDefaultSearchType(): void {
    this.selectedSearchType = undefined;
  }
}
