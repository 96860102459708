import { Component, Inject, OnInit, Optional } from '@angular/core';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { ConfigService } from 'src/app/services/config.service';
import { UtilityService } from 'src/app/services/utility.service';
import { ModalComponent } from '../../_reusable_ui/_components';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RadioButtonItem } from '../../_reusable_ui/_controls';
import { BaseService } from 'src/app/services/base.service';
import { ButtonComponent } from '../../_reusable_ui/_components/button/button.component';
import { MatDividerModule } from '@angular/material/divider';
import { RadioComponent } from '../../_reusable_ui/_controls/radio/radio.component';
import { NgIf } from '@angular/common';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TextboxComponent } from '../../_reusable_ui/_controls/textbox/textbox.component';

@Component({
    selector: 'fyxt-add-predefined-task',
    templateUrl: './add-predefined-task.component.html',
    styleUrls: ['./add-predefined-task.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatDialogModule, TextboxComponent, NgxIntlTelInputModule, NgIf, RadioComponent, MatDividerModule, ButtonComponent]
})
export class AddPredefinedTaskComponent implements OnInit {
  isLoader: boolean = false;
  createTaskForm: UntypedFormGroup;
  f: any;
  submitted: boolean = false;
  editPredefinedTaskData:any;
  isUpdatePredefinedTask:boolean = false;
  radioItems: RadioButtonItem[] = [
    { name: 'Billable', value: 'Billable', checked: true },
    { name: 'Non Billable', value: 'Non Billable', checked: false }
  ];

  constructor(
    public _dialogService: DialogService,
    public _utilService: UtilityService,
    public _Config: ConfigService,
    public _baseService: BaseService,
    public _dialogRef: MatDialogRef<ModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public matDialogData: any,
    public formBuilder: UntypedFormBuilder,
  ) { 
    if(matDialogData?.data?.isEdit){
      this.isUpdatePredefinedTask = true;
      this.editPredefinedTaskData = matDialogData?.data?.selectedData;
    }
  }

  ngOnInit(): void {
    this.initializeForm();

  }

  initializeForm() {
    this.submitted = false;

    this.createTaskForm = this.formBuilder.group({
      "task_description": new UntypedFormControl('', [Validators.required]),
      "is_billable": new UntypedFormControl('Billable', [Validators.required])
    });

    this.f = this.createTaskForm.controls;
    if(this.isUpdatePredefinedTask){
      this.setFormData();
    }
  }

  changeRadioBtnEvent(event: any) { 
    this.createTaskForm.patchValue({
      is_billable: event
    });
  }

  // Edit Predefined Data
  setFormData() {
    this.createTaskForm.patchValue({
      "task_description": this.editPredefinedTaskData?.description,
      "is_billable": this.editPredefinedTaskData?.is_billable === true ? "Billable" : "Non Billable"
    });
    this.radioItems = [
      { name: 'Billable', value: 'Billable', checked: this.editPredefinedTaskData?.is_billable ? true : false },
      { name: 'Non Billable', value: 'Non Billable', checked: this.editPredefinedTaskData?.is_billable ? false : true }
    ];
  }

  updatePredefinedTask(){
    let postBody = {
      "description": this.createTaskForm.value.task_description,
      "is_billable": this.createTaskForm.value.is_billable === "Billable" ? true : false
    }
    let requestURL = environment.LocalDev_URL_V2 + `predefined-task/${this.editPredefinedTaskData.id}/`;
    this._Config.isLoader = true;

    this._baseService.doPatch(requestURL, postBody)
      .subscribe(
        (response: any) => {
          
          this._Config.isLoader = false;

          this._utilService.showSuccess('', 'Predefined Task Updated Successfully.');
          this._dialogRef.close(true);
        },
        (error) => {
          this._Config.isLoader = false;
          this._utilService.showErrorMessage(error);
        },
      );
  }

  /**
   * submit form data
   * @returns 
   */
  doSubmit() {
    this.submitted = true;
    if (this.createTaskForm.invalid || this.createTaskForm.value.task_description.trim()=='') {
      return;
    }
    if(!this.isUpdatePredefinedTask){
      let postBody = {
        "description": this.createTaskForm.value.task_description,
        "is_billable": (this.createTaskForm.value.is_billable == 'Billable') ? true : false,
      };
  
      this.isLoader = true;
  
      let addNewCompanyURL = environment.LocalDev_URL_V2 + 'predefined-task/';
      this._dialogService.doPOST(addNewCompanyURL, postBody).subscribe(
        {
          next: (response: any) => {
            this._utilService.showSuccess('', 'Predefined Task Created Successfully.');
            this._dialogRef.close(response);
            this.isLoader = false;
          },
          error: (error) => {
            this.isLoader = false;
            this._utilService.showError('',error.error.description);
          },
          complete: () => {
            this.isLoader = false;
            this.initializeForm();
          }
        }
      );
    } else{
        this.updatePredefinedTask();
    }
    
  }


  onCancel() {
    this.initializeForm();
    this._dialogRef.close('Cancel');
  }

}
