import { BehaviorSubject, Observable } from 'rxjs';

import CreateMailboxEntity from '../models/entities/mailbox/create-mailbox-entity';
import InboxApi from './inbox-api.service';
import { InboxMenuEntity } from '../models/entities/inboxmenu-entity';
import { Injectable } from '@angular/core';
import MailboxDashboardCountEntity from '../models/entities/mailbox/mailbox-dashboard-count-entity';
import MailboxEntity from '../models/entities/mailbox/mailbox-entity';
import MailboxQueryParamEntity from '../models/entities/mailbox/mailbox-query-param-entity';
import { map } from 'rxjs/operators';
import { ConfigService } from 'src/app/services/config.service';
import InboxFolder from '../models/enum/inbox-folder';

@Injectable({
  providedIn: 'root'
})
export class InboxMenuService {
  private mailboxes = new BehaviorSubject<MailboxEntity[]>([]);
  public removeAllFilters = new BehaviorSubject<string | undefined>(undefined);
  private selectedMailbox = new BehaviorSubject<MailboxEntity | undefined>(
    undefined
  );
  private inboxViewModel = new BehaviorSubject<InboxMenuEntity | undefined>(
    undefined
  );

  private mailCount = new BehaviorSubject<
    MailboxDashboardCountEntity | undefined
  >(undefined);

  public count = new BehaviorSubject<any>({});


  getInboxCountUpdate(){
    return this.count.asObservable();
  }

  currentMenuQuery:any = {};

  // open message detail
  selectedMessage:
    | {
        mailId: string;
        mailBoxId: string;
        mailStatus: string;
      }
    | undefined;
  selectedFolder: string;

  constructor(private inboxApi: InboxApi,private _Config:ConfigService) {
    this.defaultMenu();
    // this.getAccountMailboxes();
  }

  public getAccountMailboxes() {
    this.inboxApi.getMailboxes().subscribe((mailboxes) => {
      //this.inboxSettingsService.setTimeZone(mailboxes);
      this.mailboxes.next(mailboxes);
      if (mailboxes.length > 0 && this.selectedMailbox.value === undefined) {
        if (this.selectedMessage) {
          const mailBox = mailboxes.find(
            ($0) => $0.id === this.selectedMessage.mailBoxId
          );
          this.selectMailbox(mailBox);
        } else {
          this.selectMailbox(mailboxes[0]);
        }
      }
    });
  }

  getMenus(query: MailboxQueryParamEntity) {    
    this.currentMenuQuery =  query;
    const mailbox = this.selectedMailbox.value;
    if (!mailbox) {
      return;
    }
    this.inboxApi
      .getMailboxDashboard(mailbox, query)
      .subscribe((count) => { this._Config.isLoader = false;this.prepareInboxViewModel(count)});
  }

  fetchMenus(): Observable<InboxMenuEntity> {
    return this.inboxViewModel.asObservable();
  }

  fetchMailCount(): Observable<MailboxDashboardCountEntity> {
    return this.mailCount.asObservable();
  }

  fetchMailboxes(): Observable<MailboxEntity[]> {
    return this.mailboxes.asObservable();
  }

  addNewMailbox(mailbox: CreateMailboxEntity) {
    return this.inboxApi.addNewMailbox(mailbox).pipe(
      map((mailbox) => {
        this.mailboxes.next([...this.mailboxes.value, mailbox]);
      })
    );
  }

  fetchRemoveAllFilters(): Observable<string| undefined> {
    return this.removeAllFilters.asObservable();
  }
  
  connectToOutlook(name: string, redirectDomain: string) {
    return this.inboxApi.getOutlookOAuthUrl(name, redirectDomain);
  }

  fetchSelectedMailbox(): Observable<MailboxEntity> {
    return this.selectedMailbox.asObservable();
  }

  selectMailbox(mailbox: MailboxEntity) {
    if (this.mailboxes.value.includes(mailbox)) {
      this.selectedMailbox.next(mailbox);
      this.getMenuAfterRedirect();
    }
  }

  private getMenuAfterRedirect() {
    const query: MailboxQueryParamEntity = {};
    this.getMenus(query);
  }


  fetchSelectedMailBoxId(): string {
    return this.selectedMailbox.value.id;
  }

  fetchSelectedMailboxEmail(): string {
    return this.selectedMailbox.value?.email;
  }

  private defaultMenu() {
    // this.selectedMailbox.subscribe((selectedMailbox) => {
    //   const query: MailboxQueryParamEntity = {
    //     filter: 'opened'
    //   };
    //   this.getMenus(query);
    // });
  }

  public updatemenu(){
    setTimeout(() => {
      this.getMenus(this.currentMenuQuery);
    }, 500);
  }

  private prepareInboxViewModel(dashboardCount: MailboxDashboardCountEntity) {
    this.mailCount.next(dashboardCount);
    this.inboxViewModel.next({
      categories: [
        {
          category_name: 'All conversations',
          folders: [
            {
              folder_name: InboxFolder.UNASSIGNED,
              badge: dashboardCount.unassigned,
              is_new_message: false
            },
            {
              folder_name: InboxFolder.NO_JOBS,
              badge: dashboardCount.no_jobs,
              is_new_message: false
            },
            {
              folder_name: InboxFolder.CLOSE_OUT,
              badge: dashboardCount.closeout,
              is_new_message: false
            },
            {
              folder_name: InboxFolder.EMERGENCY_JOBS,
              badge: dashboardCount.emergency,
              is_new_message: false
            },
            {
              folder_name: InboxFolder.OPEN_CONVERSATIONS,
              badge: dashboardCount.opened,
              newBadge: dashboardCount.open_unread,
              is_new_message: false
            },
            {
              folder_name: InboxFolder.CLOSED_CONVERSATION,
              badge: dashboardCount.closed,
              is_new_message: false
            }
          ]
        },
        {
          category_name: 'Assigned to me',
          folders: [
            {
              folder_name: InboxFolder.MY_CONVERSATIONS,
              badge: dashboardCount.assigned_to_me,
              is_new_message: false
            }
          ]
        },
        {
          category_name: 'Other Folders',
          folders: [
            {
              folder_name: InboxFolder.SPAM,
              badge: dashboardCount.spam,
              is_new_message: false
            },
            {
              folder_name: InboxFolder.TRASH,
              badge: dashboardCount.trash,
              is_new_message: false
            }
          ]
        },
        {
          category_name: 'Settings',
          folders: []
        }
      ]
    });
  }
}
