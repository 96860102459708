import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { AuthService } from '../services/Auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard  {

  constructor(protected _router: Router, protected keycloakAngular: KeycloakService, protected authService: AuthService) {
    super(_router, keycloakAngular);
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!this.authenticated) {
      if (state.url.includes('view-job')) {
        let paramList = state.url.split('/view-job/');
        let jobId = (paramList.length > 1) ? paramList[1] : '';

        if (jobId) {
          this._router.navigate(['login'], { queryParams: { job: jobId } });
        }
        else {
          this._router.navigate(['login']);
        }
      }
      else {
        this._router.navigate(['login']);
      }

      return false;
    }

    const isDomainAllowed = await this.authService.isDomainAllowed().toPromise();

    if (!isDomainAllowed) {
      const loginUrl: string = this.authService.getLoginUrlWithErr('401');
      this.authService.logOut(loginUrl).subscribe();
    }


    const currentUser = this.authService.currentUserInfo;
    if (currentUser && isDomainAllowed) {
        // check if route is restricted by role
        if (route.data.roles && route.data.roles.indexOf(currentUser.current_Role) === -1) {
            // role not authorised so redirect to home page
            this.router.navigate(['/']);
            return false;
        }

        // authorised so return true
        return true;
    }

    return isDomainAllowed;
  }

}
