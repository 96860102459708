import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'fyxt-no-data-fount',
    templateUrl: './no-data-fount.component.html',
    styleUrls: ['./no-data-fount.component.scss'],
    standalone: true
})
export class NoDataFountComponent implements OnInit {
  @Input() message:string = 'No Data Found';

  constructor() { }

  ngOnInit(): void {
  }

}
