import {BehaviorSubject, Observable, Subject} from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InboxLayoutService {
  private leftPanelState = new Subject<boolean>();
  private rightPanelState = new Subject<boolean>();
  public openWordOrderState = new BehaviorSubject<boolean>(false);
  associatedJobCardClick = false;

  constructor() {}

  getLeftPanelObserver(): Observable<boolean> {
    return this.leftPanelState.asObservable();
  }

  getRightPanelObserver(): Observable<boolean> {
    return this.rightPanelState.asObservable();
  }

  updateLeftPanelState(state: boolean): void {
    this.leftPanelState.next(state);
  }

  updateRightPanelState(state: boolean): void {
    this.rightPanelState.next(state);
  }

  openWordOrderFromInbox(): Observable<boolean> {
    return this.openWordOrderState.asObservable();
  }
}
