export const environment = {
  production: true,
  LocalDev_URL: 'https://krishna.qaapifyxt.com/v1/',
  baseURL: 'https://qaapifyxt.com/v1/',
  socketURL: 'wss://qaapifyxt.com/ws/',
  fastAPI_URL: 'https://fastapi.qaapifyxt.com/ms/v1',
  LocalDev_URL_V2: 'https://qaapifyxt.com/v2/',
  fastAPI_Actual_URL: 'https://cc.qaapifyxt.com/v1',
  lease_URL: 'https://lease.qaapifyxt.com',
  coreMsURL: 'https://nest.qaapifyxt.com',
  equipURL:'https://equipment.qaapifyxt.com/api/v1',
  coreApiURL:'https://core-ms.qaapifyxt.com',
  coiMsURL: 'https://coi.qaapifyxt.com',
  dynamic_API: [
    {
      env: 'QA',
      web: 'qafyxt',
      root: 'https://qaapifyxt.com/v1/',
      api: 'https://{subdomain}.qaapifyxt.com/v1/',
      socket: 'wss://{subdomain}.qaapifyxt.com/ws/',
    }
  ],
  directAPI_access: ['/login/'], // '/members/onboard/', '/users/verify/', '/password/reset/', '/password/reset/confirm/', '/sso',
  pusher: {
    key: 'f3ce7720bb0c2c42ec74',
    cluster: 'us3'
  },
  launchDarkly: true,
  keycloakConfig: {
    realm: "qa",
    url: 'https://keycloak.qafyxt.com/auth',
    clientId: 'web',
  },
  graphqlConfig: {
    url: 'https://udlqggirdfgg5hnm6m37dwa27q.appsync-api.ap-south-1.amazonaws.com/graphql',
    apiKey: 'da2-i2lolgnfpbgxhmh6ucfjejbyee',
  },
  notes:{
    url:'https://notes.qaapifyxt.com/graphql',
    ws:'wss://notes.qaapifyxt.com/graphql'
  },
  files:{
    url:'https://files.qaapifyxt.com/graphql',
    http:'https://files.qaapifyxt.com/'
  },
  activity:{
    url:'https://activities.qaapifyxt.com/graphql',
    http: 'https://activities.qaapifyxt.com/'
  },
  softAuth:{
    url:'https://ee.qaapifyxt.com/',
  },
  externalVendor:{
    xSecretKey:"z$C&F)J@NcRfUjXn2r5u7x!A%D*G-KaP"
  },
  ca:{
    url:'https://ca.qaapifyxt.com/'
  },
  inbox: {
    http:'https://inbox.qaapifyxt.com/'
  },
  oneschemaConfig: {
    clientId: "5004ae47-cce7-40cd-957d-414b9b1fe23e",
    webhookKey: 'qa_fyxt_docker'
  },
  payment: {
    http:'https://payment.qaapifyxt.com/',
    sandBoxHttp:'https://payment-sandbox.apifyxt.com/'
  },
  langflow: {
    hostUrl: 'https://langflow-ui.qafyxt.com',
    apiKey: 'sk-1JZ19SLZ7omr8GCAk8wgjVBICQwbqu06gkEk0wb7q9Q',
  },
  isShowSignatureFeature:true,
  isShowSoftAuth:true,
  showNewInbox:true,
  showVendorTab:true,
  showJobCostNewTab:true,
  showDashboardExport:true,
  showJobCostApproval: true,
  showGmailInInbox: true,
  showSavedReport: true,
  newRealtimeFix: true,
  showDataBrain: false,
  showOneSchemaImport: true,
  globalJobSettings: true,
  stagChecklistItem: true,
  vendorTenantApproval: true,
  showNewChecklist: true,
  isSelectEntireRows_Feature: true,
  showNewTenantChecklist: true,
  showPropertyGroups: true,
  isRolesPermissionsForCompaniesContactsLeases:true,
  showGlobalJobPreferenceStatus:true,
  showCompaniesOptions: true,
  showNewManageTeams: true
};
