import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JobSettingsService {
  globalSettingsLoading: boolean = false;
  globalSettings: any = {};

  constructor(public _http: HttpClient) { }

  doGET(URL?: string, Options?: any): Observable<any> {
    return this._http.get(URL, Options).pipe(take(1));
  }

  doPOST(URL?: string, Body?: any, Options?: any): any {
    return this._http.post(URL, Body, Options);
  }

  doPUT(URL?: string, Body?: any, Options?: any): any {
    return this._http.put(URL, Body, Options);
  }

  doDelete(URL?: string, Options?: any): any {
    return this._http.delete(URL, Options);
  }

  doPatch(URL?: string, Body?: any, Options?: any) {
    return this._http.patch(URL, Body, Options);
  }


  /**
   * Get All Global Setting Flags
   */
  getAllGlobalSettings() {

    let requestURL = environment.baseURL + 'global-job-settings/job-settings/';
    requestURL = requestURL.replace('v1', 'v2');

    this.globalSettingsLoading = true;

    this.doGET(requestURL)
      .subscribe({
        next: (response: any) => {
          this.globalSettings = response || {};
        },
        error: (error) => {
          this.globalSettingsLoading = false;
        },
        complete: () => {
          this.globalSettingsLoading = false;
        }
      });
  }


  /***** Customise API reponse *******/
  customizeResponse(response: any, keys: string[], customValues: { [key: string]: any }): any {
    const customizedResponse: any = {};
    keys.forEach(key => {
      customizedResponse[key] = this.customize(response, customValues[key]);
    });
    return customizedResponse;
  }

  // Customize the response by adding custom values
  private customize(response: any, customValues: any): any {
    const customized = response;
    return customized.map((obj: any, index: number) => {
      customValues.custom_id = customValues.custom_key + (index + 1);
      obj = { ...obj, ...customValues };
      return obj;
    });
  }

  /**** Customize the response by adding custom values using forkJoin  *****/
  // getDynamicStatusAPI_Requests() {
  //   let requestURL = environment.baseURL + 'global-job-settings/preference-status/';
  //   requestURL = requestURL.replace('v1', 'v2');

  //   let statusResponseObj = {
  //     timesheet_statusLists: this.doGET(requestURL).pipe(
  //       map((response: any) => { return response.filter((obj: any, index: number) => {
  //         obj.custom_id = 'timesheet_' + (index + 1);
  //         obj.checked = false;
  //         return obj;
  //       }) || [];})),
  //     material_statusLists: this.doGET(requestURL).pipe(
  //       map((response: any) => { return response.filter((obj: any, index: number) => {
  //         obj.custom_id = 'material_' + (index + 1);
  //         obj.checked = false;
  //         return obj;
  //       }) || [];})),
  //     manager_statusLists: this.doGET(requestURL).pipe(
  //       map((response: any) => { return response.filter((obj: any, index: number) => {
  //         obj.custom_id = 'manager_' + (index + 1);
  //         obj.checked = false;
  //         return obj;
  //       }) || [];})),
  //     engineer_statusLists: this.doGET(requestURL).pipe(
  //       map((response: any) => { return response.filter((obj: any, index: number) => {
  //         obj.custom_id = 'engineer_' + (index + 1);
  //         obj.checked = false;
  //         return obj;
  //       }) || [];})),
  //   }

  //   return forkJoin(statusResponseObj)
  // }

}
