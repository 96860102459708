import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, forkJoin, throwError, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConfigService } from 'src/app/services/config.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  _viewModule: string = "dashboard";
  isLoader: boolean = false;
  public goBackToDashBoard = new Subject<boolean>();

  customFilters: any = {
    filterBy: {
      "id": "Filter_By",
      "name": "Filter By",
      "url": null,
      "listItems": [
        {
          "id": "ef1d3f42-1c8a-43f1-8fa8-91d001d46d32",
          "name": "Hide Seen Jobs",
          "value": "unseen-jobs"
        },
        {
          "id": "116fbf9a-ed25-46c0-a7cc-085f1b127b43",
          "name": "Show Starred Jobs",
          "value": "starred-jobs"
        },
        {
          "id": "116fbf9a-ed25-46c0-a7cc-085f1b127b44",
          "name": "Action Needed",
          "value": "action-needed"
        }
      ],
      "isLocalSearch": true,
      "label": "Filter By",
      "isExpended": false,
      "selectAll": false
    },
    checklist: {
      "id": "Checklist",
      "name": "Checklist",
      "url": null,
      "listItems": [
        {
          "id": "has_no_checklist",
          "name": "Does not have Checklist",
          "value": "has_no_checklist"
        },
        {
          "id": "has_checklist",
          "name": "Has Checklist",
          "value": "has_checklist"
        },
        {
          "id": "flagged_response",
          "name": "Flags Identified",
          "value": "flagged_response"
        }
      ],
      "isLocalSearch": true,
      "label": "Checklist",
      "isExpended": false,
      "selectAll": false
    },
    invoices: {
      "id": "invoice_status",
      "name": "Invoices",
      "url": null,
      "listItems": [
        {
          "id": "Proposed",
          "name": "Proposed Invoices",
          "value": "Proposed"
        },
        {
          "id": "Approved",
          "name": "Approved Invoices",
          "value": "Approved"
        },
        {
          "id": "Rejected",
          "name": "Rejected Invoices",
          "value": "Rejected"
        }
      ],
      "isLocalSearch": true,
      "label": "Invoices",
      "isExpended": false,
      "selectAll": false
    },
    bids: {
      "id": "bid_status",
      "name": "Bids",
      "url": null,
      "listItems": [
        {
          "id": "Proposed",
          "name": "Proposed Bids",
          "value": "Proposed"
        },
        {
          "id": "Approved",
          "name": "Approved Bids",
          "value": "Approved"
        },
        {
          "id": "Rejected",
          "name": "Rejected Bids",
          "value": "Rejected"
        }
      ],
      "isLocalSearch": true,
      "label": "Bids",
      "isExpended": false,
      "selectAll": false
    }
  };


  constructor(private httpClient: HttpClient, public config: ConfigService) { }
  /**
  *  GET Method
  */
  doGET(endpoint_URL: string): Observable<any> {
    return this.httpClient.get(`${environment.LocalDev_URL_V2 + endpoint_URL}`).pipe(catchError(this.handleError));
  }


  /**
  *  POST Method
  */
  doPOST(endpoint_URL: string, postBody: any): Observable<any> {
    return this.httpClient.post(`${environment.LocalDev_URL_V2 + endpoint_URL}`, postBody).pipe(catchError(this.handleError));
  }

  /**
   *  PUT Method
   */
  doUPDATE(endpoint_URL: string, postBody: any): Observable<any> {
    return this.httpClient.put(`${environment.LocalDev_URL_V2 + endpoint_URL}`, postBody).pipe(catchError(this.handleError));
  }

  /**
*  PATCH Method
*/
  doPATCH(endpoint_URL: string, postBody: any): Observable<any> {
    return this.httpClient.patch(`${environment.LocalDev_URL_V2 + endpoint_URL}`, postBody).pipe(catchError(this.handleError));
  }

  /**
  *  DELETE Method
  */
  doDELETE(endpoint_URL: string): Observable<any> {
    return this.httpClient.delete(`${environment.LocalDev_URL_V2 + endpoint_URL}`).pipe(catchError(this.handleError));
  }

  /**
   * Get All Views API responses
   * @returns
   */
  getAllViewsData() {
    // let listAllStandardViews = this.httpClient.get(`${environment.LocalDev_URL_V2 + this._viewModule}/views/`);
    let listAllStandardViews = this.httpClient.get(`${environment.LocalDev_URL_V2}manage-dashboard/views/?source=dashboard`);
    return listAllStandardViews
  }


  /**
   * Get all selected View Table lists
   * @param viewID
   * @returns
   */
  getSelectedTableData(viewID: string, pageSize?: any) {

    // let selectedViewTableHeaders = this.httpClient.get(`${environment.LocalDev_URL_V2 + this._viewModule}/` + viewID + `/column/`);
    // let selectedViewAllTableColumns = this.httpClient.get(`${environment.LocalDev_URL_V2 + this._viewModule}/` + viewID + `/edit-column/`);

    let selectedViewTableHeaders = this.httpClient.get(`${environment.LocalDev_URL_V2}` + `manage-dashboard/column/?source=dashboard&viewid=` + viewID);
    let selectedViewAllTableColumns = this.httpClient.get(`${environment.LocalDev_URL_V2}` + `manage-dashboard/edit-column/?source=dashboard&viewid=` + viewID);


    let postBody = {
      "viewid": viewID,
      "size": pageSize
    };

    let selectedViewTableRows = this.httpClient.post(`${environment.LocalDev_URL_V2}user-view/job/`, postBody);

    return forkJoin([selectedViewTableHeaders, selectedViewTableRows, selectedViewAllTableColumns])
  }

  /**
 * Get all selected View Table lists
 * @param viewID
 * @returns
 */
  getSelectedTableColumns(viewID: string) {

    let selectedViewTableHeaders = this.httpClient.get(`${environment.LocalDev_URL_V2}` + `manage-dashboard/column/?source=dashboard&viewid=` + viewID);
    let selectedViewAllTableColumns = this.httpClient.get(`${environment.LocalDev_URL_V2}` + `manage-dashboard/edit-column/?source=dashboard&viewid=` + viewID);

    return forkJoin([selectedViewTableHeaders, selectedViewAllTableColumns])
  }

  /**
 * Get all Jobs Columns
 * @param viewID
 * @returns
 */
  getAllJobsColumns(viewID: string) {

    let selectedViewTableHeaders = this.httpClient.get(`${environment.LocalDev_URL_V2}` + `manage-dashboard/column/?source=jobs&viewid=` + viewID);
    let selectedViewAllTableColumns = this.httpClient.get(`${environment.LocalDev_URL_V2}` + `manage-dashboard/edit-column/?source=jobs&viewid=` + viewID);

    return forkJoin([selectedViewTableHeaders, selectedViewAllTableColumns])
  }


  /**
   * Get Default Active tab Data
   * @param tabList
   * @returns
   */
  getDefaultActiveVerticalMenuID(tabList: any) {
    let activeMenu: any = {};

    let activeTabList = tabList.filter(obj => obj.menus.some((item: { default: boolean; }) => item.default == true)).map(obj => { return { activeItem: obj.menus.find((item: { default: boolean; }) => item.default == true), type: obj.type, activeIndex: obj.menus.findIndex((item: { default: boolean; }) => item.default == true) } });

    if (activeTabList.length > 0) {
      activeMenu = activeTabList[0];
      return activeMenu.activeItem;
    }

    if (activeMenu.activeItem == undefined) {
      if (tabList.length > 0 && tabList[0]?.menus?.length > 0) {
        tabList[0].menus[0].default = true
        activeMenu = tabList[0].menus[0];
      }
      return activeMenu;
    }

    // return activeMenu.activeItem;
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
  // https://docker.devapifyxt.com/v2/dashboard/manage_views/
  // let listManageViewTabs = this.httpClient.get(`${environment.LocalDev_URL_V2+this._viewModule}/manage_views/`);



  /**
*  GET Method
*/
  doGETV1(endpoint_URL: string): Observable<any> {
    let requestURL = environment.LocalDev_URL_V2 + endpoint_URL;
    requestURL = requestURL.replace('v2', 'v1');

    return this.httpClient.get(`${requestURL}`).pipe(catchError(this.handleError));
  }

  /**
*  GET Method
*/
  doPatchV1(endpoint_URL: string, postBody): Observable<any> {
    let requestURL = environment.LocalDev_URL_V2 + endpoint_URL;
    requestURL = requestURL.replace('v2', 'v1');

    return this.httpClient.patch(`${requestURL}`, postBody);
  }

  /**
*  Patch Method
*/
  doBulkActionV1(endpoint_URL: string, postBody: any): Observable<any> {
    let requestURL = environment.LocalDev_URL_V2 + endpoint_URL;
    requestURL = requestURL.replace('v2', 'v1');

    return this.httpClient.patch(requestURL, postBody);
  }

  getallFilterItems(allTriggers: any) {
    // let requestURL = environment.LocalDev_URL_V2;
    // requestURL = requestURL.replace('v2', 'v1');
    let urls = allTriggers.map(obj => {
      let requestURL = environment.LocalDev_URL_V2;
      let ccURL = environment.fastAPI_Actual_URL;

      if ((obj.name == 'Date Created' || obj.name == 'Last Activity' || obj.name == 'Target Completion Date' || obj.name == 'Schedule Date' || obj.name == 'Job Cost Date Approved' || obj.name == 'Job Cost Amount' || obj.name == 'Invoice Amount' || obj.name == 'Invoices Submitted Date' || obj.name == 'Invoices Approved Date' || obj.name == 'Bid Amount' || obj.name == 'Bid Submitted Date' || obj.name == 'Bid Approved Date' || obj.name == 'Custom Attributes' || obj.name == 'days_in_status' || obj.name == 'Days in Status' || obj.name == 'Filter By' || obj.name == 'Checklist' || obj.name == 'Bids' || obj.name == 'Invoices')) {
        obj.url = null;
      }
      else {
        switch (obj.name) {
          case 'Tenant':
            obj.url = ccURL + '/companies/?columns=["id","name"]&page=1&size=25&sort=name&order=asc&query={"type":["Tenant"]}';
            break;
          case 'Vendor':
            obj.url = ccURL + '/companies/?columns=["id","name"]&page=1&size=25&sort=name&order=asc&query={"type":["Vendor"]}';
            break;
          case 'Assigned Vendor':
            obj.url = ccURL + '/companies/?columns=["id","name"]&page=1&size=25&sort=name&order=asc&query={"type":["Vendor"]}';
            break;
          case 'Approved Vendor':
            obj.url = ccURL + '/companies/?columns=["id","name"]&page=1&size=25&sort=name&order=asc&query={"type":["Vendor"]}';
            break;
          case 'Property External Code':
            obj.url = requestURL + 'properties/external-code/';
            break;
          case 'Property':
            obj.url = requestURL + this.config.getPropertiesFilter;
            break;
          case 'Property Group':
            obj.url = requestURL + 'property-group/filter/';
            break;
          case 'Job Cost Approver':
            obj.url = requestURL + 'drop-down/approvers/';
            break;

          case 'Category':
            obj.url = requestURL + 'drop-down/categories/';
            break;

          case 'Owner Group':
            obj.url = requestURL + 'drop-down/owners/';
            break;

          case 'Created By':
            obj.url = requestURL + 'drop-down/created_by/';
            break;

          case 'Assigned To':
            obj.url = requestURL + 'drop-down/assignees/';
            break;

          case 'Assigned Manager':
            obj.url = requestURL + 'drop-down/assignees/';
            break;
          case 'Assigned Follower':
            obj.url = requestURL + 'drop-down/assignees/';
            break;
          case 'Assigned or Followed':
            obj.url = requestURL + 'drop-down/assignees/';
            break;
          case 'Engineers':
            obj.url = requestURL + 'drop-down/engineers/';
            break;
          case 'Assigned Engineer':
            obj.url = requestURL + 'drop-down/engineers/';
            break;
          case 'Source Type':
            obj.url = requestURL + 'job/advanced-filter/' + obj.id + '/detail-filter/';
            break;
          case 'Service Type':
            obj.url = requestURL + 'job/advanced-filter/' + obj.id + '/detail-filter/';
            break;
          case 'Priority':
            {
              requestURL = requestURL.replace('v2', 'v1');
              obj.url = requestURL + 'jobs/priorities/';
            }
            break;
          case 'Status':
            {
              requestURL = requestURL.replace('v2', 'v1');
              obj.url = requestURL + 'job/stage/';
            }
            break;
          case 'Job Cost Type':
            obj.url = requestURL + 'job/advanced-filter/' + obj.id + '/detail-filter/';
            break;
          case 'Lease External Code':
            {
              obj.url = `${environment.lease_URL}/leases/filter/external-code?type=CURRENT&skip=0&take=100`;
            }
            break;
          default:
            {
              requestURL = requestURL.replace('v2', 'v1');
              obj.url = requestURL + 'job/advanced-filter/' + obj.id + '/detail-filter/';
            }
            break;
          case 'Teams':
            obj.url = requestURL + 'teams/dropdown/';
            break;

        }
      }

      return {
        id: obj.id, url: this.doCustomResponseFormat(obj)
      };


    });


    /****** Convert to Object with URL ********/
    const convertArrayToObject = (array, key) => {
      const initialValue = {};
      return array.reduce((obj, item) => {
        return {
          ...obj,
          [item[key]]: item.url,
        };
      }, initialValue);
    };

    urls = urls.filter((item: any) => item.url);
    let convertedObj = convertArrayToObject(urls,
      'id',
    );

    return forkJoin(convertedObj)
  }

  doCustomResponseFormat(obj: any) {

    if (!obj.url) {
      return null;
    }

    if (obj.name === 'Lease External Code') {
      return this.httpClient.get(obj.url).pipe(
        map((response: any) => {
          const urlParams = new URLSearchParams(obj.url);
          let pageVal = Number(urlParams.get('skip'));

          obj.page = (pageVal == 0) ? (pageVal + 1) : (Number(obj.page) + 1);

          let totalPageCount = Math.round(response.total / response.size);
          if (response.total > (obj.page * response.size)) {
            totalPageCount = totalPageCount + 1;
          }

          if (obj.page <= totalPageCount) {
            let requestUrl = obj.url;
            obj.next = requestUrl.replace('skip=' + pageVal, 'skip=' + (Number(obj.page) * response.size));
          }
          else {
            obj.next = null
          }

          response.page = obj.page;
          response.next = obj.next;

          return response;
        }),
        catchError(err => of({ isError: true, error: err })),
      )

    }

    return this.httpClient.get(obj.url).pipe(
      catchError(err => of({ isError: true, error: err }))
    )

  }

  seenUnseenOrStarUnstarJob(postBody: any): Observable<any> {
    let requestURL = environment.LocalDev_URL_V2 + 'user-job-status/job-status-star/';
    return this.httpClient.patch(requestURL, postBody);
  }

  fetchGoBackToDashboard(): Observable<boolean> {
    return this.goBackToDashBoard.asObservable();
  }
}
