import {Component, EventEmitter, Inject, Input, OnInit, Optional, Output, TemplateRef, ViewChild} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AddCompanyComponent} from 'src/app/@fyxt/_shared/_forms';
import {LayoutService} from "../../../_services/_layout/layout.service";
import {CompaniesService} from "../../../../Modules/_fyxt_modules/companies/_services/companies.service";
import {DialogService} from 'src/app/@fyxt/_services/dialog/dialog.service';
import {SidebarService} from 'src/app/@fyxt/_services/_layout/sidebar.service';
import {ContactsService} from 'src/app/Modules/_fyxt_modules/contacts/_services/contacts.service';
import {UtilityService} from 'src/app/services/utility.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {BaseService} from 'src/app/services/base.service';
import {HttpErrorResponse} from '@angular/common/http';
import {
  ContactsDetailedViewService
} from 'src/app/Modules/_fyxt_modules/contacts/_services/contacts-detailed-view.service';
import {ActivatedRoute} from "@angular/router";
import {InboxService_New} from "../../../../Modules/_fyxt_modules/inbox-management/_services/inbox.service";
import {associatedContactsTypes} from "../../../../Modules/_fyxt_modules/inbox-management/inbox.types";
import { environment } from 'src/environments/environment';
import { TagsComponent } from '../../_reusable_ui/_components/tags/tags.component';
import { CheckboxComponent } from '../../_reusable_ui/_controls/checkbox/checkbox.component';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DropdownSelectComponent } from '../../_reusable_ui/_controls/dropdown-select/dropdown-select/dropdown-select.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatCardModule } from '@angular/material/card';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TextboxComponent } from '../../_reusable_ui/_controls/textbox/textbox.component';
import { ProfilePictureComponent } from '../../_reusable_ui/_components/profile-picture/profile-picture.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../_reusable_ui/_components/button/button.component';
import { NgIf, NgClass, NgFor, DatePipe } from '@angular/common';

declare var $: any;

@Component({
    selector: 'fyxt-add-contact',
    templateUrl: './add-contact.component.html',
    styleUrls: ['./add-contact.component.scss'],
    standalone: true,
    imports: [NgIf, ButtonComponent, MatIconModule, MatDividerModule, FormsModule, ReactiveFormsModule, NgClass, ProfilePictureComponent, TextboxComponent, NgxIntlTelInputModule, MatCardModule, NgFor, InfiniteScrollModule, DropdownSelectComponent, MatTooltipModule, NgxMatIntlTelInputComponent, CheckboxComponent, TagsComponent, DatePipe]
})

export class AddContactComponent implements OnInit {
  @Input() updateContact: boolean = false;
  @Input() contactData: any;
  @ViewChild('confirmUserExist') confirmUserExist!: TemplateRef<any>;
  @ViewChild("phoneNumber") phoneNumber;
  @Input() primaryPhoneNumber: any;
  @Input() primaryEmail: any;
  @Input() isAlreadyExistsNumber: boolean = false;
  @Input() isAlreadyExistsEmail: boolean = false;
  @Input() defaultPrimaryNumber: boolean = false;
  @Input() defaultPrimaryEmail: boolean = false;
  @Output() onFetchAssociatedCompanyDetails = new EventEmitter<Event>();
  @Output() fetchContactsDetails = new EventEmitter();

  //Form Control Variables
  addContactForm!: FormGroup;
  phone_listForm: any;
  email_listForm: any;
  formSubmitted: boolean = false;
  formDropdownTypes: any = {contact_types: [], email_types: [], phone_types: []}
  isLoader: boolean = false;

  //Company Dropdown
  allCompaniesList: any[] = [];
  allCompaniesListPageNumber: number = 1;
  allCompaniesListTotal: number;
  allCompaniesListSize: number;
  companiesSearchInput: any = "";
  allCompaniesListPageCount: number;

  selectedTagsList: any[] = [];
  chipList: any[] = [];
  checkValue: boolean = false;
  firstNameSearch: any[] = [];
  lastNameSearch: any[] = [];

  //add contact
  primaryIndexPhone: number = 0;
  primaryIndexEmail: number = 0;

  popupData: any;
  autoCompleteCheck: boolean;
  isFromInboxPage = false;
  currentUserInfo: any = localStorage.getItem('userobj') ? JSON.parse(localStorage.getItem('userobj')) : {};
  can_create_contacts: Boolean;
  // can_view_contacts: Boolean;
  // showWarningBox:Boolean = false;

  constructor(
    public _dialogService: DialogService,
    public _contactsService: ContactsService,
    public sidebarService: SidebarService,
    public formBuilder: FormBuilder,
    public _baseService: BaseService,
    public _utilService: UtilityService,
    public _layoutService: LayoutService,
    public _companiesService: CompaniesService,
    private activatedRoute: ActivatedRoute,
    private _contactsDetailedViewService: ContactsDetailedViewService,
    private _inboxService: InboxService_New,
    @Optional() @Inject(MAT_DIALOG_DATA) public matDialogData: any,
  ) {
    this.getAllContactTypesDropdowns();
    let currentPage = this.activatedRoute.snapshot['_routerState'].url;
    if (currentPage.includes('pm/inbox')) {
      this.isFromInboxPage = true;
    }
  }

  ngOnInit(): void {
    this.getAllCompanyDropdowns();
    this.initializeForm();
    this._contactsService.fetchPrimaryCompanyAssignUnAssign().subscribe((state) => {
      if (state) {
        this.contactData = this._contactsService.selectedContactDetails;
        this.addContactForm.patchValue({
         primary_company_name: this.contactData.primary_company,
       });
        this._contactsService.isPrimaryCompanyAssignUnAssign.next(false);
      }
    });
    this._inboxService.fetchAssociatedContactsSlideFromInbox().subscribe((state) => {
      if (state) {
        this.isFromInboxPage = true;
        this.setFormDataFromInbox();
        // this._inboxService.associatedContactsSlideFromInbox.next(false);
      }
    });
    if (environment.isRolesPermissionsForCompaniesContactsLeases) {
      if (this.currentUserInfo?.current_Role == 'Manager' || this.currentUserInfo?.current_Role == 'Owner') {
        if (this.currentUserInfo.permissions.includes('add_contacts')) {
          this.can_create_contacts = true;
        } else {
          this.can_create_contacts = false;
        }
        // if (this.currentUserInfo.permissions.includes('view_contacts')) {
        //   this.can_view_contacts = true;
        // } else {
        //   this.can_view_contacts = false;
        //   this.showWarningBox = true;
        // }
      }
    } else {
      this.can_create_contacts = true;
    }
  }

  ngOnChanges(changes): void {
    if (changes.contactData?.currentValue?.id) {
      this.initializeForm();
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.initializeForm();
  }

  getAllContactTypesDropdowns() {
    this._contactsService.getAllContactTypes().subscribe({
      next: (responseList: any) => {
        this.formDropdownTypes = responseList;
        // this.addContactForm.patchValue({ contact_type: this.formDropdownTypes.contact_types[0] });
        // this.addContactForm.patchValue({ phone_numbers: this.formDropdownTypes.phone_types[0] });
        // this.addContactForm.patchValue({ email_lists: this.formDropdownTypes.email_types[0] });
      },
      error: (error: any) => {
      },
      complete: () => {
      }
    });
  }

  getAllCompanyDropdowns(pagination?: boolean) {
    // this.isLoader = true;
    let requestURL = `companies/?columns=['id', 'name']&page=` + this.allCompaniesListPageNumber + '&search=' + this.companiesSearchInput;
    this._contactsService.doGETLive(requestURL).subscribe(
      {
        next: (response: any) => {
          if (pagination) {
            this.allCompaniesList = this.allCompaniesList.concat(response.items);
          } else {
            this.allCompaniesList = response.items;
          }
          this.allCompaniesListTotal = response.total;
          this.allCompaniesListSize = response.size;
          this.allCompaniesListPageCount = Math.round(this.allCompaniesListTotal / this.allCompaniesListSize)
        },
        error: (error:HttpErrorResponse) => {
          this.isLoader = false;
          this._utilService.showErrorMessage(error);
          },
        complete: () => {
          this.isLoader = false;
        }
      }
    );
  }

  companyDropdownSearch(event: any) {
    this.companiesSearchInput = event.term;
    this.allCompaniesListPageNumber = 1;
    this.getAllCompanyDropdowns()
  }

  companyDropdownsScroll() {
    if (this.allCompaniesListPageNumber < this.allCompaniesListPageCount) {
      this.allCompaniesListPageNumber++;
      this.getAllCompanyDropdowns(true)
    }
  }

  /*** Initialize Create Contact Form */
  initializeForm() {
    this.addContactForm = this.formBuilder.group({
      first_name: ['', [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]],
      last_name: ['', [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]],
      title: [''],
      contact_type: [null, Validators.required],
      primary_company_name: [null, Validators.required],
      phone_numbers: this.formBuilder.array([this.addPhoneNo()]),
      email_lists: this.formBuilder.array([this.addEmailAddress()]),
      primary: [false]
    });
    this.phone_listForm = this.addContactForm.controls['phone_numbers'];
    this.email_listForm = this.addContactForm.controls['email_lists'];
    if (this.updateContact) {
      this.setFormData();
    }
  }

  /*** Set Create Contact Form Data */
  setFormData() {
    this.addContactForm.patchValue({
      first_name: this.contactData.first_name,
      last_name: this.contactData.last_name,
      title: this.contactData.title,
      contact_type: this.contactData.type,
      primary_company_name: this.contactData.primary_company,
    });
    this.addContactForm.setControl('phone_numbers', this.setPhoneNumbersList(this.contactData.phones));
    this.addContactForm.setControl('email_lists', this.setEmailList(this.contactData.emails));
    this.phone_listForm = this.addContactForm.controls['phone_numbers'];
    this.email_listForm = this.addContactForm.controls['email_lists'];
    if (this.contactData.tag?.length > 0) {
      this.chipList = this.contactData.tag.filter((tag: any) => {
        return tag !== null;
      }) || [];
    } else {
      this.chipList = [];
    }
  }

  setFormDataFromInbox(): void {
    if (this._inboxService.selectedAssociatedContacts.showType == associatedContactsTypes.UNKNOWN) {
      this.addContactForm.patchValue({
        first_name: this._inboxService.selectedAssociatedContacts.first_name,
        last_name: this._inboxService.selectedAssociatedContacts.last_name,
        title: this._inboxService.selectedAssociatedContacts?.title ? this._inboxService.selectedAssociatedContacts.title : '',
        contact_type: this._inboxService.selectedAssociatedContacts?.type ? this._inboxService.selectedAssociatedContacts.type : null,
        primary_company_name:  this._inboxService.selectedAssociatedContacts?.primary_company ?  this._inboxService.selectedAssociatedContacts.primary_company : null,
      });
      this.addContactForm.setControl('phone_numbers', this.setPhoneNumbersList([]));
      this.addContactForm.setControl('email_lists', this.setEmailList([]));
      this.phone_listForm = this.addContactForm.controls['phone_numbers'];
      this.email_listForm = this.addContactForm.controls['email_lists'];
      if (this._inboxService.selectedAssociatedContacts?.tag?.length > 0) {
        this.chipList = this.contactData.tag.filter((tag: any) => {
          return tag !== null;
        }) || [];
      } else {
        this.chipList = [];
      }
    } else if (this._inboxService.selectedAssociatedContacts.showType == associatedContactsTypes.CONTACTS) {
      this.getSelectedContactDetailsFromInbox();
    }
  }

  get formControl() {
    return this.addContactForm.controls;
  }

  profileUpload(event: any) {
    const formData: FormData = new FormData();
    formData.append('photo', event)
    this.isLoader = true;
    let updateContactPhotoUrl = 'contacts/' + this._contactsService.selectedActiveTabId + '/upload-photo/';
    this._contactsService.doPATCH(updateContactPhotoUrl, formData).subscribe(
      {
        next: (response: any) => {
          var reader = new FileReader();
          reader.readAsDataURL(event);
          reader.onload = (_event) => {
            this._utilService.showSuccess('', 'Profile Picture Updated Successfully');
          }
        },
        error: (error) => {
          this.isLoader = false;
          this._utilService.showErrorMessage(error.Message);
        },
        complete: () => {
          this.isLoader = false;
        }
      }
    );
  }

  firstNameChange(event: any) {
    if (event.length === 0) {
      this.firstNameSearch = [];
    }
    this.checkValue = true;
    // if (event.length > 2 && this.updateContact) {
    //   this.firstNameRequest(event);
    // }
  }

  lastNameChange(event: any) {
    if (event.length === 0) {
      this.lastNameSearch = [];
    }
    this.checkValue = true;
    // if (event.length > 2 && this.updateContact) {
    //   this.lastNameRequest(event);
    // }
  }

  titleChange($event) {
    this.checkValue = true;
  }

  validateInput(event: KeyboardEvent) {
    const pattern = /[^\w\s]/;
    if (pattern.test(event.key)) {
      event.preventDefault();
    }
  }

  validateNumber(target: any) {
    let value = target.value;
    value = value.replace('/\D?g', '')
    value = value.replace('/^(\d{6})/$', '');
    target.value = value
    if (value) {
      target.value = target.value.slice(0, 6);
    }
  }

  changeContactType(event: any) {
    this.focusOutInput(event, 'type');
  }

  changeCompanyName(event: any) {
    this.focusOutInput(event, 'primary_company');
  }

  /*** Show Create Company Form */
  createNewCompanyPopup() {
    this._companiesService.createCompanyInitialize.next(true);
    let popupData = {
      title: 'Create New Company',
      component: AddCompanyComponent,
      containerClass: 'modal_manage_views',
      data: {
        companyModal: true
      }
    };
    this._dialogService.openModal(popupData).subscribe(result => {
      if (result) {
        this.allCompaniesListPageNumber = 1;
        this.getAllCompanyDropdowns();
        const payload = {
          id: result.id,
          name: result.name,
        };
        if (payload.id && payload.name) {
          this.addContactForm.patchValue({
            primary_company_name: payload
          })
        }
        if (this.updateContact) {
          let postBody: any = {};
          postBody.primary_company = this.addContactForm.value.primary_company_name.id;
          this.updateApiHit(postBody, "Primary Company Name")
        }
      }
    });
  }

  // *PhoneNo formCode starts here* //
  get phoneNoArray() {
    const control = this.addContactForm.get('phone_numbers') as FormArray;
    return control;
  }

  addPhoneNo() {
    return this.formBuilder.group({
      id: (null),
      phone: [''],
      extension: (null),
      type: [this.formDropdownTypes.phone_types[0] ? this.formDropdownTypes.phone_types[0].name : {name: 'Home'}, Validators.required],
      primary: (false)
    });
  }

  addMorePhoneNo() {
    let isExistIndex = this.phoneNoArray.value.findIndex(obj => !obj.phone);
    if (isExistIndex == -1) {
      if (this.phoneNoArray.status == 'VALID') {
        if (this.phoneNoArray.value.length === 1) {
          this.primaryPhoneNumber = this.phoneNoArray.value[0].phone;
          this.addContactForm.value.phone_numbers[0].primary = true;
        }
        this.phoneNoArray.push(this.addPhoneNo());
      }
    }
  }

  validatePhoneNumberFormat(target: any) {
    // Input value Digit check
    //   const pattern = /^\d{0,15}$/;
    //   if (!pattern.test(event.target.value)) {
    //     event.target.value = event.target.value.slice(0, 15);
    //   }
    let value = target.value;
    value = value.replace('/\D?g', '')
    value = value.replace(/^(\d{3})(\d{3})(\d{4})(\d{0,5})/, '$1 $2 $3 $4');
    target.value = value
    if (value) {
      target.value = target.value.slice(0, 18);
    }
  }

  removePhoneNo(phoneIndex: any) {
    if (!this.updateContact) {
      if (this.addContactForm.value.phone_numbers[phoneIndex]?.primary === true) {
        this.primaryPhoneNumber = ''
        this.primaryIndexPhone = 0;
      }
      if (this.isAlreadyExistsNumber) {
        this.isAlreadyExistsNumber = false
      }
      this.phoneNoArray.removeAt(phoneIndex);
    }
    if (this.updateContact && this.contactData?.phones[phoneIndex]?.id) {
      if (this.addContactForm.value.phone_numbers[phoneIndex]?.primary == true) {
        this.defaultPrimaryNumber = true;
      }
      this.deleteRequest('phones', this.contactData.phones[phoneIndex].id, phoneIndex);
      this.phoneNoArray.removeAt(phoneIndex);
      this.makePrimaryPhoneNo(false, phoneIndex);
    } else {
      this.phoneNoArray.removeAt(phoneIndex);
    }
  }

  makePrimaryPhoneNo(primary: any, index: number, number?: any) {
    this.primaryIndexPhone = index;
    if (primary) {
      this.addContactForm.value.phone_numbers.map((phone: any) => {
        return phone.primary = false
      });
      this.addContactForm.value.phone_numbers[index].primary = primary;
      this.phone_listForm.controls[index].value.primary = primary;
      this.phone_listForm.controls[index].controls.primary.value = primary;
    } else {
      this.addContactForm.value.phone_numbers.map((phone: any) => {
        return phone.primary = false
      });
      this.addContactForm.value.phone_numbers[0].primary = true;
      this.phone_listForm.controls[0].value.primary = true;
      this.phone_listForm.controls[0].controls.primary.value = true;
    }
    if (this.addContactForm.value.phone_numbers[index].primary === true) {
      this.primaryPhoneNumber = number
    }
    // if(this.primaryIndexPhone){
    //   this.addContactForm.value.phone_numbers.map((phone, i) => {
    //     if (i ===  this.primaryIndexPhone) {
    //       phone.primary = true;
    //     } else {
    //       phone.primary = false;
    //     }
    //     return phone;
    //   });
    // }
  }

  makePrimaryPhoneEdit(event: any, index: number, controls: any) {
    this.defaultPrimaryNumber = false;
    this.checkValue = true;
    this.focusOutInput(event, 'phonePrimary', index, controls);
  }

  setPhoneNumbersList(phoneNumberData: any): FormArray {
    const formArray = new FormArray([]);
    phoneNumberData.forEach((phNumber) => {
      formArray.push(this.formBuilder.group({
        phone: JSON.stringify(phNumber.full_phone),
        type: phNumber.type,
        extension: phNumber.extension,
        id: phNumber.id,
        primary: phNumber.primary
      }))
    });
    return formArray;
  }

  PhoneNumberTypeChange(event: any, index: any, controls: any) {
    this.checkValue = true;
    this.focusOutInput(event, 'phoneType', index, controls);
  }

  phoneNumberChange(event: any) {
    this.checkValue = true;
  }

  phoneExtensionChange(event: any) {
    this.checkValue = true;
  }

  validatePhoneNumberIsExist(control: FormControl, index: number) {
    if (control.value && this.addContactForm?.value?.phone_numbers.length > 1) {
      let values = this.addContactForm?.value?.phone_numbers.map((value: any) => value.phone)
      values.splice(index, 1)
      let array: any = []
      for (let number of values) {
        array.push(number.replace(/"/g, ''));
      }
      this.isAlreadyExistsNumber = array.includes(control.value)
    } else if (!control.value) {
      this.isAlreadyExistsNumber = false
    }
    if (this.isAlreadyExistsNumber) {
      control.setErrors({
        customError: true
      })
    }
  }

  patchUpdatePhoneNumber(data) {
    let mappedData = data.map((phone) => {
      return {
        id: phone.id,
        primary: phone.primary,
        phone: JSON.stringify(phone.full_phone),
        type: phone.type,
        extension: phone.extension
      };
    });
    this.addContactForm.patchValue({
      phone_numbers: mappedData
    });
  }

  // *Email formCode starts here* //

  get emailArray() {
    const control = this.addContactForm.get('email_lists') as FormArray;
    return control;
  }

  addEmailAddress() {
    return this.formBuilder.group({
      id: (null),
      email: ['', [Validators.email, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,5}$")]],
      type: [this.formDropdownTypes?.email_types[0] ? this.formDropdownTypes.email_types[0].name : {name: 'Corporate'}, Validators.required],
      primary: (false)
    });
  }

  addMoreEmailAddress() {
    let isExistIndex = this.emailArray.value.findIndex(obj => !obj.email);
    if (isExistIndex == -1) {
      if (this.emailArray.status == 'VALID') {
        if (this.emailArray.value.length === 1) {
          this.primaryEmail = this.emailArray.value[0].email;
          this.addContactForm.value.email_lists[0].primary = true;
          this.email_listForm.controls[0].controls.primary.value = true;
        }
        this.emailArray.push(this.addEmailAddress());
      }
    }
  }

  setEmailList(emailData: any): FormArray {
    const formArray = new FormArray([]);
    emailData.forEach((email) => {
      formArray.push(this.formBuilder.group({
        email: email.email,
        type: email.type,
        id: email.id,
        primary: email.primary
      }))
    });
    return formArray;
  }

  removeEmailAddress(emailIndex: any) {
    if (!this.updateContact) {
      if (this.addContactForm.value.email_lists[emailIndex]?.primary == true) {
        this.primaryEmail = '';
        this.primaryIndexEmail = 0;
      }
      if (this.isAlreadyExistsEmail) {
        this.isAlreadyExistsEmail = false;
      }
      this.emailArray.removeAt(emailIndex);
    }
    if (this.updateContact && this.contactData.emails[emailIndex]?.id) {
      if (this.addContactForm.value.email_lists[emailIndex]?.primary == true) {
        this.defaultPrimaryEmail = true;
      }
      this.deleteRequest('emails', this.contactData.emails[emailIndex].id, emailIndex);
      this.emailArray.removeAt(emailIndex);
      this.makePrimaryEmail(false, emailIndex);
    } else {
      this.emailArray.removeAt(emailIndex);
    }

    // this.focusOutInput(emailIndex, 'email');
  }

  checkValidEmail(emailItem: any): boolean {
    let pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (emailItem.value.email.trim() == '' || pattern.test(emailItem.value.email)) {
      return true;
    } else {
      return false;
    }
  }

  emailChange(event: any) {
    this.checkValue = true;
  }

  emailTypeValueChange(event: any, index: any, control: any) {
    this.checkValue = true;
    this.focusOutInput(event, 'emailType', index, control);
  }

  isExistEmail(control: FormControl, index: number) {
    if (control.value && this.addContactForm?.value?.email_lists.length > 1) {
      let values = this.addContactForm?.value?.email_lists.map((value: any) => value.email)
      values.splice(index, 1)
      this.isAlreadyExistsEmail = values.includes(control.value)
    } else if (!control.value) {
      this.isAlreadyExistsEmail = false
    }
    if (this.isAlreadyExistsEmail) {
      control.setErrors({
        customError: true
      })
    }
  }

  makePrimaryEmail(primary: any, index: number, email?: any) {
    this.primaryIndexEmail = index;
    if (primary) {
      this.addContactForm.value.email_lists.map((phone: any) => {
        return phone.primary = false;
      });
      this.addContactForm.value.email_lists[index].primary = primary;
      this.email_listForm.controls[index].controls.primary.value = primary;
      this.email_listForm.controls[index].value.primary = primary;
    } else {
      this.addContactForm.value.email_lists.map((phone: any) => {
        return phone.primary = false;
      });
      this.addContactForm.value.email_lists[0].primary = true
      this.email_listForm.controls[0].controls.primary.value = true;
      this.email_listForm.controls[0].value.primary = true;
    }
    if (this.addContactForm.value.email_lists[index].primary === true) {
      this.primaryEmail = email;
    }
    // if(this.primaryIndexEmail){
    //  this.addContactForm.value.email_lists.map((email, i) => {
    //    if (i ===  this.primaryIndexEmail) {
    //      email.primary = true;
    //    } else {
    //      email.primary = false;
    //    }
    //    return email;
    //  });
    // }
  }

  makePrimaryEmailEdit(event: any, index: number, controls: any) {
    this.defaultPrimaryEmail = false;
    this.checkValue = true;
    this.focusOutInput(event, 'emailPrimary', index, controls);
  }

  patchUpdateEmail(data) {
    let mappedData = data.map((email) => {
      return {
        id: email.id,
        primary: email.primary,
        email: email.email,
        type: email.type,
      };
    });
    this.addContactForm.patchValue({
      email_lists: mappedData
    });
  }
  // *Email formCode ends here* //

  //Tags
  selectedTags(selectedTagItems: any) {
    if (this.updateContact) {
      selectedTagItems = selectedTagItems.map((tag: any) => {
        if (tag?.name) {
          return tag.name
        } else {
          return tag;
        }
      });
      this.selectedTagsList = selectedTagItems;
      this.focusOutInput(selectedTagItems, 'tags');
    } else {
      this.selectedTagsList = selectedTagItems;
    }
  }

  setFieldError(httpError: HttpErrorResponse) {
    let error = httpError.error.error
    let errorValues = Object.values(error)
    errorValues.forEach((value: any) => {
      const emailRegex = /\b[\w\.-]+@[\w\.-]+\.\w{2,}\b/g;
      const email = value.email?.match(emailRegex)?.[0];
      const phoneNumberRegex = /\+\d{11,15}/;
      const phoneNumberMatch = value.phone?.match(phoneNumberRegex)?.[0]
      if (phoneNumberMatch) {
        this.phoneNoArray.value?.map((obj: any, index: number) => {
          if (obj.phone === phoneNumberMatch) {
            this.phoneNoArray.at(index).get('phone')?.setErrors({'serverError': true})
          }
        })
      }
      if (email) {
        this.emailArray.value?.map((obj: any, index) => {
          if (obj.email === email) {
            this.emailArray.at(index).get('email')?.setErrors({'serverError': true})
          }
        })
      }
    })
  }

  onSubmit() {
    this.formSubmitted = true;
    if (this.addContactForm.valid) {
      if (this.primaryIndexPhone == 0) {
        this.addContactForm.value.phone_numbers.map((phone, i) => {
          if (i === this.primaryIndexPhone) {
            phone.primary = true;
          } else {
            phone.primary = false;
          }
          return phone;
        });
      }
      if (this.primaryIndexEmail == 0) {
        this.addContactForm.value.email_lists.map((email, i) => {
          if (i === this.primaryIndexEmail) {
            email.primary = true;
          } else {
            email.primary = false;
          }
          return email;
        });
      }
      let phone_numbers = this.addContactForm.value.phone_numbers.filter((obj: any) => obj.phone).map((obj: any) => {
        return {
          phone: obj.phone,
          type: obj.type?.name ? obj.type.name : obj.type,
          extension: obj.extension ? parseInt(obj.extension.toString().slice(0, 6)) : null,
          primary: obj.primary
        }
      });
      let email_lists = this.addContactForm.value.email_lists.filter((obj: any) => obj.email).map((obj: any) => {
        return {email: obj.email.toLowerCase(), type: obj.type?.name ? obj.type.name : obj.type, primary: obj.primary}
      });
      let postBody = {
        "first_name": this.addContactForm.value.first_name,
        "last_name": this.addContactForm.value.last_name,
        "title": this.addContactForm.value.title,
        "type": this.addContactForm.value.contact_type ? this.addContactForm.value.contact_type.name : '',
        "primary_company": this.addContactForm.value.primary_company_name ? this.addContactForm.value.primary_company_name.id : '',
        "phone": phone_numbers,
        "email": email_lists,
        "source": "contact",
      };
      if (this._layoutService.openFromCompanyDetailedPageContactTab) {
        postBody['primary'] = this.addContactForm.value.primary;
        postBody['source'] = 'company';
      }
      this.isLoader = true;
      let addNewContactURL = 'contacts/';
      this._contactsService.doPOST(addNewContactURL, postBody).subscribe(
        {
          next: (response: any) => {
            response = {
              "id": response.id,
              "first_name": response.first_name,
              "last_name": response.last_name,
              "type": response.type,
              "phone": response.phones.length > 0 ? response.phones[0].phone : '',
              "last_activity_at": response.last_activity_at,
              "primary_company": response.primary_company,
            };
            this._contactsService.setContactData(response);
            this._utilService.showSuccess('', 'Contact Created Successfully.');
            if (this._layoutService.openFromCompanyDetailedPageContactTab) {
              this._companiesService.contactCreateFromCompanyDetailedPage.next(true);
            }
            },
          error: (error: HttpErrorResponse) => {
            this.isLoader = false;
            this._utilService.showErrorMessage(error.error);
            this.setFieldError(error)
          },
          complete: () => {
            this.isLoader = false;
            this.onCancel();
          }
        }
      );
    }
  }

  onDismiss() {
    this.sidebarService.toggleRightSidebar = !this.sidebarService.toggleRightSidebar;
    this.sidebarService.setFormConext('Create Contact');
  }

  onCancel() {
    this.sidebarService.toggleRightSidebar = !this.sidebarService.toggleRightSidebar;
    this.sidebarService.setFormConext('Create Contact');
    //this._inboxService.hideOpenFirstPanel.next('open');
    setTimeout(() => {
      this.formSubmitted = false;
      this.addContactForm.reset();
      this.sidebarService.setFormConext('');
      this.initializeForm();
    }, 100);
  }

  focusOutInput(event: any, type: string, index?: any, controls?: any) {
    setTimeout(() => {
      this.firstNameSearch = [];
      this.lastNameSearch = [];
    }, 500);
    if (this.updateContact && type) {
      let postBody: any = {};
      if (type == 'first_name' && this.addContactForm.controls['first_name'].valid && (this.contactData.first_name !== this.addContactForm.value.first_name)) {
        setTimeout(() => {
          postBody.first_name = this.addContactForm.value.first_name;
          if (this.autoCompleteCheck) {
            return;
          }
          this.updateApiHit(postBody, "First Name Updated");
        }, 200);
      } else if (type == 'last_name' && this.addContactForm.controls['last_name'].valid && this.checkValue == true && (this.contactData.last_name !== this.addContactForm.value.last_name)) {
        setTimeout(() => {
          postBody.last_name = this.addContactForm.value.last_name;
          if (this.autoCompleteCheck) {
            return;
          }
          this.updateApiHit(postBody, "Last Name updated");
        }, 200);
      } else if (type == 'type' && this.addContactForm.controls['contact_type'].value != '') {
        postBody.type = event.name;
        this.updateApiHit(postBody, "Type updated");
      } else if (type == 'primary_company' && this.addContactForm.controls['primary_company_name'].value != '') {
        postBody.primary_company = event.id;
        this.updateApiHit(postBody, "Primary Company changed")
      } else if ((type == 'phone' || type == 'phoneType' || type == 'extension' || type == 'phonePrimary') && controls.status === "VALID") {

        let updatedNumberDetails = this.addContactForm.value.phone_numbers[index];
        if (this.contactData.phones[index]?.id) {
          if (type == 'phone') {
             if (updatedNumberDetails.phone == '' || updatedNumberDetails.phone == undefined) {
               this.fetchContactsDetails.emit();
               return null;
             } else {
               postBody.phones = [{phone: updatedNumberDetails.phone, id: this.contactData.phones[index]?.id}];
             }
          }
          if (type == 'extension') {
            postBody.phones = [{extension: parseInt(updatedNumberDetails.extension.toString().slice(0, 6)), id: this.contactData.phones[index]?.id}];
          }
          if (type == 'phoneType') {
            postBody.phones = [{type: updatedNumberDetails.type.name, id: this.contactData.phones[index]?.id}];
          }
          if (type == 'phonePrimary') {
            postBody.phones = [{primary: updatedNumberDetails.primary = true, id: this.contactData.phones[index]?.id}];
          }
        } else {
          if (index == 0) {
            updatedNumberDetails.primary = true;
          } else {
            updatedNumberDetails.primary = false;
          }
          postBody.phones = [{
            phone: updatedNumberDetails.phone,
            type: (updatedNumberDetails.type?.name ? updatedNumberDetails.type?.name : updatedNumberDetails.type),
            extension: updatedNumberDetails.extension ? parseInt(updatedNumberDetails.extension.toString().slice(0, 6)) : null,
            primary: updatedNumberDetails.primary
          }];
        }
        if (this.checkValue) {
          this.updateApiHit(postBody, "Phone Updated");
        }
      }
      // (/\s/.test(this.addContactForm.value.email_lists[index].email) Remove White space
      else if ((type == 'email' || type === 'emailType' || type == 'emailPrimary') && controls.status === "VALID" && !(/\s/.test(this.addContactForm.value.email_lists[index].email))) {
        let updatedEmailDetails = this.addContactForm.value.email_lists[index]
        if (this.contactData.emails[index]?.id) {
          if (type == 'email') {
            // If te email is empty ten we won't allow to send the empty email value
            if (updatedEmailDetails.email != '') {
              postBody.emails = [{email: updatedEmailDetails.email, id: this.contactData.emails[index]?.id}];
            } else {
              this.fetchContactsDetails.emit();
              return null;
            }
          }
          if (type === 'emailType') {
            postBody.emails = [{
              type: (updatedEmailDetails.type?.name ? updatedEmailDetails.type.name : updatedEmailDetails.type),
              id: this.contactData.emails[index]?.id
            }];
          }
          if (type === 'emailPrimary') {
            postBody.emails = [{primary: updatedEmailDetails.primary = true, id: this.contactData.emails[index]?.id}];
          }
        } else {
          if (index == 0) {
            updatedEmailDetails.primary = true;
          } else {
            updatedEmailDetails.primary = false;
          }
          postBody.emails = [{
            email: updatedEmailDetails.email.toLowerCase(),
            type: (updatedEmailDetails.type?.name ? updatedEmailDetails.type.name : updatedEmailDetails.type),
            primary: updatedEmailDetails.primary
          }];
        }
        let pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (pattern.test(updatedEmailDetails.email)) {
          if (this.checkValue) {
            this.updateApiHit(postBody, "Email Updated");
          }
        }
        else {
          return null;
        }

      } else if (type == 'tags') {
        postBody.tag = this.selectedTagsList;
        this.updateApiHit(postBody, "Tag Updated");
      } else if (type == 'title' && this.addContactForm.controls['title'].valid && (this.contactData.title !== this.addContactForm.value.title)) {
        postBody.title = this.addContactForm.value.title;
        if (this.checkValue) {
          this.updateApiHit(postBody, "Title Updated");
        }
      }
    }
  }

  updateApiHit(postBody: any, message: any) {
    this.isLoader = true;
    let updateContactUrl = 'contacts/' + this._contactsService.selectedActiveTabId + '/'
    this._contactsService.doPATCH(updateContactUrl, postBody).subscribe(
      {
        next: (response: any) => {
          this.contactData = response;
          this.patchUpdatePhoneNumber(response.phones);
          this.patchUpdateEmail(response.emails)
          this._utilService.showSnackNotification("success", ` Contact's ${message}  successfully`);
          if (postBody.first_name || postBody.last_name) {
            this._contactsService.isContactNameUpdate.next({
              first_name: response.first_name,
              last_name: response.last_name
            });
          }
        },
        error: (error: HttpErrorResponse) => {
          this.isLoader = false;
          this._utilService.showErrorMessage(error.error);
          this.setFieldError(error)
        },
        complete: () => {
          this.isLoader = false;
          this.checkValue = false;
          if (message == 'Primary Company changed') {
            this.onFetchAssociatedCompanyDetails.emit();
          }
        }
      }
    );
  }

  deleteRequest(type: any, id: any, index: number) {
    let requestURL = 'contacts/' + this.contactData.id + '/' + type + '/' + id + '/';
    this._contactsService.doDELETE(requestURL).subscribe({
      next: (res: any) => {
        this._utilService.showSuccess('', `Contact details Deleted Successfully.`);
        if (type == "phones") {
          this.contactData.phones.splice(index, 1)
        }
        if (type == "emails") {
          this.contactData.emails.splice(index, 1)
        }
      },
      error: (error: HttpErrorResponse) => {

         this._utilService.showErrorMessage(error);
         if (error.error.error == 'Either email or phone number is required.') {
           this.patchUpdatePhoneNumber(this.contactData.phones);
           this.patchUpdateEmail(this.contactData.emails);
         }
      },
      complete: () => {
      }
    })
  }

  get isFormValid(): boolean {
    let isValid = false;
    isValid = this.addContactForm.valid;
    return isValid;
  }

//User Already Exist Api Hits
  openAttentionPopup(data: any) {
    this.autoCompleteCheck = true;
    this.lastNameSearch = [];
    this.firstNameSearch = [];
    this.popupData = data;
    let popupData = {
      title: 'Attention',
      template: this.confirmUserExist,
      confirmText: 'Yes',
      buttons: true,
      selectedData: data,
      cancelText: 'Cancel',
    };
    this._dialogService.confirmDialog(popupData).subscribe((result: any) => {
      this.autoCompleteCheck = false;
      if (result?.selectedData?.id) {
        // this._baseService.redirectTo('pm/contacts/'+ result?.selectedData?.id);
        window.open('pm/contacts/' + result?.selectedData?.id, '_blank');
      }
      this.addContactForm.patchValue({
        first_name: this.contactData.first_name,
        last_name: this.contactData.last_name
      });
    });
  }

  firstNameRequest(searchValue?: any) {
    let requestURL = "contacts/?columns=['first_name','last_name']&search=" + searchValue;
    this._contactsService.doGETLive(requestURL).subscribe({
      next: (res: any) => {
        this.firstNameSearch = res.items;
      }
    });
  }

  lastNameRequest(searchValue?: any) {
    let requestURL = "contacts/?columns=['first_name','last_name']&search=" + searchValue;
    this._contactsService.doGETLive(requestURL).subscribe({
      next: (res: any) => {
        this.lastNameSearch = res.items;
      }
    });
  }

  getSelectedContactDetailsFromInbox() {
    this.isLoader = true;
    this._contactsDetailedViewService.getSelectedContactDetails(this._inboxService.selectedAssociatedContacts.id).subscribe({
      next: (response: any) => {
        this.contactData = response;
        this.setFormData();
      },
      error: (error) => {
        this.isLoader = false;
      },
      complete: () => {
        this.isLoader = false;
      }
    })
  }

  get atLeastOneValidNumer(): boolean {
    if (this.phone_listForm.controls.length == 2) {
      const getOneValidNumber = this.phone_listForm.controls.every((phone: any) => {
        return (phone.status == 'VALID' && (phone.value.phone != '' && phone.value.phone != undefined && phone.value.phone != null));
      });
      return getOneValidNumber;
    } else {
      return true;
    }
  }

  protected readonly associatedContactsTypes = associatedContactsTypes;

  /** Track by Index  **/
  trackByIndex(index: number, item: any): number {
    return index;
  }
}
