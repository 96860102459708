import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChipComponent } from '../chip/chip.component';
import { ButtonComponent } from '../button/button.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'fyxt-filter-chip-list',
    templateUrl: './filter-chip-list.component.html',
    styleUrls: ['./filter-chip-list.component.scss'],
    standalone: true,
    imports: [NgIf, ButtonComponent, NgFor, ChipComponent, MatTooltipModule]
})
export class FilterChipListComponent implements OnInit, OnDestroy {
  @Input() filterChipList: any[] = [];
  @Input() divWidth: number;
  filter: any[] = [];
  moreFilter: any[] = [];
  @Output() onClearAll = new EventEmitter();
  @Output() onDeleteFilterChip = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  clearSelectedFilters(): void {
    this.onClearAll.emit();
  }

  deletedTag(filterChip: any): void {
    // let index = this.filterChipList.findIndex((e: any) => {
    //   if (e.id === filterChip.id) {
    //     return true;
    //   }
    // })
    // this.filterChipList.splice(index, 1);
    this.chipsSplit();
    this.onDeleteFilterChip.emit(filterChip);
  }

  ngOnChanges() {
    this.moreFilter = [];
    this.chipsSplit();
  }

  ngOnDestroy(): void {
    this.filterChipList = [];
    this.moreFilter = [];
  }

  chipsSplit() {
    this.filterChipList.forEach((item, index) => {
      if (item && item?.name === undefined) this.filterChipList.splice(index, 1);
    });

    let maxTabAllowed = Math.floor((Math.round(this.divWidth) - ((this.divWidth * 8 / 100))) / 160);
    let totalTabs = this.filterChipList.length;

    // if (maxTabAllowed > 3) {
    //   maxTabAllowed = maxTabAllowed - 1;
    // }
    this.filterChipList.sort((a, b) => a.name > b.name ? 1 : -1);
    if (totalTabs >= maxTabAllowed) {
      this.filter = this.filterChipList.slice(0, maxTabAllowed - 1);
      this.moreFilter = this.filterChipList.slice(maxTabAllowed - 1, this.filterChipList.length - 1);
      this.filter = this.filter.concat(this.filterChipList.slice(this.filterChipList.length - 1));
      this.filterChipList = this.filter.concat(this.moreFilter);
    } else {
      this.filter = this.filterChipList.slice(0, maxTabAllowed);
    }
  }

  /** Track by ID  **/
  trackById(index: number, item: any): number {
    return item.id;
  }
}
