import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import inboxFolder from "../../../../../Modules/inbox/models/enum/inbox-folder";
import { ListComponent } from '../list/list.component';
import { MatListModule } from '@angular/material/list';
import { NgFor, NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'fyxt-tab-vertical',
    templateUrl: './tab-vertical.component.html',
    styleUrls: ['./tab-vertical.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, MatListModule, ListComponent, NgClass]
})
export class TabVerticalComponent implements OnInit {
  @Input() customClass: string = '';
  @Input() type!: string;
  @Input() tabList: any[] = [];
  @Input() isInboxMenu: any;
  @Input() selectedActiveTab: any;
  @Input() inboxBubbleCount: number;
  @Input() hideCount: boolean = false;
  @Output() onSelectTab: any = new EventEmitter();
  selectedIndex: number = 0;
  protected readonly inboxFolder = inboxFolder;

  constructor() { }

  ngOnInit(): void {
    let activeTabList = this.tabList.filter(obj => obj.menus.some((item: { default: boolean; }) => item.default == true)).map(obj => { return { type: obj.type, activeIndex: obj.menus.findIndex((item: { default: boolean; }) => item.default == true) } });
    if (activeTabList.length > 0) {
      let activeMenu = activeTabList[0];
      this.onChangeMenu(activeMenu.type, activeMenu.activeIndex);
    }
  }

  /** On Change Menu Event  **/
  onChangeMenu(type: string, active_index: number) {
    this.tabList.forEach((element, index) => {
      if (element.type == type) {
        element.menus.forEach((childItem: any, index: number) => {
          if (index == active_index) {
            childItem.default = true;
            this.onSelectTab.emit(childItem);
          } else {
            childItem.default = false;
          }
        });
      }
      else {
        element.menus.filter((obj: any) => obj.default = false);
      }
    });
  }


  /** Track by ID  **/
  trackById(index: number, item: any): number {
    return item.id;
  }

  /** Track by Index  **/
  trackByIndex(index: number, item: any): number {
    return index;
  }

}
