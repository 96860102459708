import { Component, Inject, ViewChild, TemplateRef, ViewContainerRef, ComponentRef, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import {BaseService} from "../../../../../services/base.service";
import { LayoutService } from 'src/app/@fyxt/_services/_layout/layout.service';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonComponent } from '../button/button.component';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'fyxt-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, ButtonComponent, MatTooltipModule, MatDialogModule, MatIconModule]
})
export class ModalComponent implements OnInit {
  @ViewChild('target', { read: ViewContainerRef, static: true }) vcRef!: ViewContainerRef;

  componentRef!: ComponentRef<any>;
  create_ticket_popup: boolean = false;
  hide_header: boolean = false;
  customStyles: boolean = false;
  btnBack: boolean = false;

  isCreateJob: boolean = false;
  is_BuildReport: boolean = false;
  is_uploading :boolean = false;

  @ViewChild('deleteDialogTemplate')
  deleteDialogTemplate!: TemplateRef<any>;
  statusCheck: boolean = false;
  status:string;
  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _dialogService: DialogService,
    public dialog: MatDialog,
    public _baseService: BaseService,
    public _layoutService: LayoutService
    ) { 
      if(this.data?.data?.viewStatus) {
        this.statusCheck = this.data?.data?.viewStatus;
        this.status = this.data?.data?.status
      }
    }

  ngOnInit(): void {
    this.componentRef = this.vcRef.createComponent(this.data.component);
    this.isCreateJob = !!this.data.isCreateJob;
    this.is_BuildReport = !!this.data.is_BuildReport;
    this.btnBack = !!this.data.btnBack;
    this.customStyles = !!this.data.customStyles;
    this.create_ticket_popup = !!this.data.create_ticket_popup;
    this.hide_header = !!this.data.hide_header;
    // this.setInputValues();

    if (this.data.inputValues) {
      if (this.data.inputValues.length > 0) {
        this.data.inputValues.forEach(element => {
          this.componentRef.setInput(element.key, element.value);
        });
      }
    }

    this._dialogService.getisUploading().subscribe((data)=>{
     this.is_uploading = data;
    });

    this._dialogService.contactFormSubmitted.subscribe((state) => {
      if(state) {
        this.data.title = 'Code Sent Successfully';
      }
    })

  }

  ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(this.data);
  }

  onDismiss(): void {
    if(this.is_uploading == true){
      let popupData = {
        title: 'Alert',
        template: this.deleteDialogTemplate,
        confirmText: 'Close',
        cancelText: 'Wait for Upload',
        buttons:true,
        data:''
      };
      this._dialogService.confirmDialog(popupData).subscribe((result:any) => {
        if(result){
          this.is_uploading == false;
          this.dialogRef.close();
        }
      });
    }else{
      this.is_uploading == false;
      if(this.data?.data?.calledFrom === 'assignVendor' || this.data?.data?.calledFrom === 'openLinkedJobs') {
        this.dialogRef.close('Close');
        return ;
      }
      this.dialogRef.close();
    }

    setTimeout(() => {
      this.is_uploading = false;
    }, 10000);

  }

  onMinimize(minimize:any): void {
    this._dialogService.isMinimizeButtonClicked = true;
    this._dialogService.minimizeTicketDetails.next(true);
    this.dialogRef.close();
  }

  onEdit(){
    this.dialogRef.close();
    this._dialogService.editPendingStatus.next(this.data?.data?.selectedData);
    this._dialogService.editPendingStatus.next(false);
  }

  setInputValues() {

    if (this.data.inputValues) {
      if (this.data.inputValues.length > 0) {
        this.data.inputValues.forEach(element => {
          this.componentRef.setInput(element.key, element.value);
        });
      }
    }
  }

modifyTitle(title: string): string {
  const modifiedTitle = title.replace(/(Approved|Rejected)/g, '<span class="$1">$1</span>');
  return modifiedTitle;
}

}
