import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';

import { TableColumnTypes,ColumnSortType } from 'src/app/@fyxt/_shared/enums/table/table-column-types.enum';
import { AppTableSource } from 'src/app/@fyxt/_shared/models/app-table-model';

import { CreateContactComponent,AssignContactsComponent } from 'src/app/@fyxt/_shared/_views';
import { environment } from 'src/environments/environment';
import { TableComponent } from '../../../_reusable_ui/_components/table/table.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { NgIf, NgFor } from '@angular/common';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';


@Component({
    selector: 'fyxt-tab-contacts',
    templateUrl: './tab-contacts.component.html',
    styleUrls: ['./tab-contacts.component.scss'],
    standalone: true,
    imports: [SearchInputComponent, NgIf, ButtonComponent, MatMenuModule, MatIconModule, NgFor, MatTooltipModule, MatDividerModule, LoaderSmallComponent, TableComponent]
})
export class TabContactsComponent implements OnInit {

  @Input() dataSource!: AppTableSource;
  @Input() contactsSearchValue!: string;
  @Input() isLoader!: boolean;
  @Input() selectedCompanyDetails: any;
  @Input() viewModule: string;
  @Input() bulkActionList: any;
  @Input() selectionTable: any;
  @Input() checkedContacts = [];
  @Input() hideAddContact: boolean;
  @Input() hideAssignContact: boolean;
  @Input() isReadonly: boolean = false;
  @Output() onSearchValueChange = new EventEmitter<Event>();
  @Output() onSortValueChange = new EventEmitter<Event>();
  @Output() onPaginationValueChange = new EventEmitter<Event>();
  @Output() onThreeDotMenuChange = new EventEmitter<Event>();
  @Output() onAssignContactValueChange = new EventEmitter<Event>();
  @Output() onBulkActionValueChange = new EventEmitter();
  @Output() onCheckRowValueChange = new EventEmitter();
  @Output() isAllRowSelectValueChange = new EventEmitter();
  @Output() onRowClickValueChange = new EventEmitter();
  @Output() onCreateContactValueChange = new EventEmitter();
  currentUserInfo = localStorage.getItem('userobj')
    ? JSON.parse(localStorage.getItem('userobj'))
    : {};
  can_create_contacts:Boolean;
  constructor(
    public _dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    if (environment.isRolesPermissionsForCompaniesContactsLeases) {
      if (this.currentUserInfo?.current_Role == 'Manager' || this.currentUserInfo?.current_Role == 'Owner') {
        if (this.currentUserInfo.permissions.includes('add_contacts')) {
          this.can_create_contacts = true;
        } else {
          this.can_create_contacts = false;
        }
      }
    } else {
      this.can_create_contacts = true;
    }
  }

  ngAfterContentChecked(): void {
    //Called after every check of the component's or directive's content.
    //Add 'implements AfterContentChecked' to the class.
    if (this.isReadonly) {
      if (this.dataSource?.headers) {
        this.dataSource.headers = this.dataSource.headers.filter(obj => !(obj.value == 'checkbox' || obj.value == 'action')).map(obj => {
          return obj;
        })
      }
    }

  }

  checkNormalTable(){
    if(this.viewModule=='Tenant-portal'){
      return true;
    }

    return false;
  }


  createContactPopup() {
    let popupData = {
      title: 'Create New Contact',
      component: CreateContactComponent,
      containerClass: ['modal-sm','create_new_contact_model'],
      data: {selectedCompanyDetails: this.selectedCompanyDetails, viewModule: this.viewModule}
    };

    this._dialogService.openModal(popupData).subscribe(result => {
      if (result.isAdd) {
        this.onAssignContactValueChange.emit();
      }
    });
    // this.onCreateContactValueChange.emit();
  }

  assignContactsPopup() {
    let popupData = {
      title: 'Assign Contacts to Company',
      component: AssignContactsComponent,
      containerClass: 'assign_contacts_model',
      data: {selectedCompanyDetails: this.selectedCompanyDetails, viewModule: this.viewModule}
    };

    this._dialogService.openModal(popupData).subscribe(result => {
      this.onAssignContactValueChange.emit();
    });

  }

  searchContacts(value: any): void {
    this.onSearchValueChange.emit(value);
    this.contactsSearchValue = value;
  }

  contactsSortChange(sortState: any) {
    this.onSortValueChange.emit(sortState);
  }

  contactsPageChange(paginationEvent: any) {
    this.onPaginationValueChange.emit(paginationEvent);
  }

  threeDotMenuChange(event: any) {
    this.onThreeDotMenuChange.emit(event);
  }

  isAllSelected (isSelected: any) {
    this.isAllRowSelectValueChange.emit(isSelected);
  };

  onCheckRow(contact: any): void {
    let updatedCheckedContacts: number[];
    const isCompanyChecked = this.checkedContacts.some((checkedContact) => checkedContact.id === contact.id);
    if (isCompanyChecked) {
      updatedCheckedContacts = this.checkedContacts.filter((checkedContact) => checkedContact.id !== contact.id);
    } else {
      updatedCheckedContacts = [...this.checkedContacts, contact];
    }
    this.checkedContacts = updatedCheckedContacts;
    this.onCheckRowValueChange.emit(this.checkedContacts);
  }

  bulkActionPopup(item: any): void {
    const payload = {
      checkedContacts: this.checkedContacts || [],
      bulkActionItem: item,
    }
    this.onBulkActionValueChange.emit(payload);
  }

  onRowClick(event: any): void {
    this.onRowClickValueChange.emit(event);
  }
}
