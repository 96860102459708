import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { GraphqlEquipmentManagementService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/equipment-management/graphql-equipment-management.service';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { CheckboxComponent } from '../../../_reusable_ui/_controls/checkbox/checkbox.component';
import { NgFor, NgIf } from '@angular/common';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';

@Component({
    selector: 'fyxt-assign-items',
    templateUrl: './assign-items.component.html',
    styleUrls: ['./assign-items.component.scss'],
    standalone: true,
    imports: [SearchInputComponent, NgFor, CheckboxComponent, FormsModule, NgxIntlTelInputModule, MatDialogModule, ButtonComponent, NgIf]
})
export class AssignItemsComponent implements OnInit {

  items: any[] = [];
  searchInput:string = '';
  selectedItems:any[]=[];

  constructor(
    public _graphql:GraphqlEquipmentManagementService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AssignItemsComponent>
    ) {
      dialogRef.disableClose = true;
    }


  ngOnInit(): void {
    this.loadAssignItems(this.searchInput,1)
  }

  loadAssignItems(searchInput:string,page:number){
    this._graphql.loadAssignItems(this.data?.id,searchInput,page).subscribe({
      next:(res:any)=>{
        this.items = res.listEquipmentAssignItems?.items || [];
        this.items = this.items.map(item => {
          const matchingItem = this.selectedItems.find(otherItem => otherItem.id === item.id);
          item.checkbox = matchingItem ? true : false;
          item.toggle = false;
          return item;
        })
      }
    })
}


  onSearchChange(e:any){
    this.searchInput = e;
    this.loadAssignItems(this.searchInput,1)
  }


  /**
* On Change Event
* @param event
*/
slideToggleChange(event: any): void {}

onChange(e:any){
  this.selectedItems = this.items.filter(item => item.checkbox);
}

change(e:any){}
  /**
   * save view Trigger
   */
  saveView(){
    let resultArray = this.selectedItems.map(item => ({ additional_data_item_id: `${item.id}`, is_required: item.toggle }));
   if(resultArray?.length > 0){
    this._graphql.updateAssignItemEquipment(this.data?.id,resultArray);
    this.dialogRef.close()
   }
  }


   /** Track by Index  **/
   trackByIndex(index: number, item: any): number {
    return index;
  }
}
