import { environment } from '../../../../environments/environment'
import { DocumentInfo, DocumentItem } from '../../_shared/models/porperty-manager/documents'

export const getFileUrl = (file: DocumentInfo) => {
  if (file.source === 'URL') return file.url

  const filesBaseUrl = environment.files.http.substring(0, environment.files.http.length - 1);

  return `${filesBaseUrl}${file.url}`
}
export const getFilePreviewUrl = (file: DocumentInfo) => {
  const previewUrl = file.previewUrl
  if (!previewUrl) return null

  const filesBaseUrl = environment.files.http.substring(0, environment.files.http.length - 1);

  return `${filesBaseUrl}${file.previewUrl}`
}
