// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-intl-input {
  background: #fff;
  height: 43px;
  display: block;
  border: 1px solid #eee;
  border-radius: 3px;
  padding: 12px 0px 0px 0px;
  margin-top: 4px;
}

.mat-intl-input > :host ::ng-deep .mat-focus-indicator {
  height: 44px !important;
  width: 100px !important;
  margin: 0px 0px 23px 0px;
}

:host ::ng-deep .is-invalid {
  border: 1px solid #f96a74 !important;
}

:host ::ng-deep .country-selector {
  opacity: 1 !important;
  height: 44px !important;
  bottom: 2px !important;
  margin-top: 22px;
}

:host ::ng-deep .mat-mdc-menu-panel {
  max-height: 34vh !important;
}

.mat-mdc-menu-panel {
  max-height: 290px !important;
}

:host ::ng-deep .flag {
  display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/app/@fyxt/_shared/_views/_popup_views/create-contact/create-contact.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,YAAA;EACA,cAAA;EACA,sBAAA;EACA,kBAAA;EACA,yBAAA;EACA,eAAA;AACJ;;AAGA;EACI,uBAAA;EACA,uBAAA;EACA,wBAAA;AAAJ;;AAIA;EACI,oCAAA;AADJ;;AAIA;EACI,qBAAA;EACA,uBAAA;EACA,sBAAA;EACA,gBAAA;AADJ;;AAIA;EACI,2BAAA;AADJ;;AAIA;EACI,4BAAA;AADJ;;AAIA;EACI,wBAAA;AADJ","sourcesContent":[".mat-intl-input {\n    background: #fff;\n    height: 43px;\n    display: block;\n    border: 1px solid #eee;\n    border-radius: 3px;\n    padding: 12px 0px 0px 0px;\n    margin-top: 4px;\n\n}\n\n.mat-intl-input > :host ::ng-deep .mat-focus-indicator {\n    height: 44px !important;\n    width: 100px  !important;\n    margin: 0px 0px 23px 0px;\n}\n\n\n:host ::ng-deep .is-invalid {\n    border: 1px solid #f96a74 !important ;\n}\n\n:host ::ng-deep .country-selector{\n    opacity: 1 !important;\n    height: 44px !important;\n    bottom: 2px !important;\n    margin-top: 22px;\n  }\n\n:host ::ng-deep .mat-mdc-menu-panel {\n    max-height: 34vh!important;\n}\n\n.mat-mdc-menu-panel {\n    max-height: 290px !important;\n}\n\n:host ::ng-deep .flag{\n    display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
