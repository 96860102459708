import { Injectable } from '@angular/core';
import {
  CreateLease,
  ICount,
  IUnitsResult,
  Lease,
  LeaseOptions,
  UpdateLease,
} from 'src/app/@fyxt/_shared/models/porperty-manager/leases';
import { CreateProvision, Provisions } from 'src/app/@fyxt/_shared/models/porperty-manager/provisions';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { Apollo, gql, QueryRef } from 'apollo-angular';
import { map, tap } from 'rxjs/operators';
import { ApolloQueryResult } from '@apollo/client/core';
import { Property } from '../../_shared/models/porperty-manager/properties';
import { Companies } from '../../_shared/models/porperty-manager/companies';
import {
  Category,
  ILeaseCategoriesResult,
  ILeaseCategory,
  IRuleOptions,
  IRuleOptionsAPIResponse,
  IRulesCount,
  IRulesCountAPIResponse,
  Rule,
  RuleCreationResult,
  RuleUpdateResult,
} from '../../_shared/models/porperty-manager/maintenance-terms-tab';
import { Contact, Contacts, ContactVariables, PrimaryContacts } from '../../_shared/models/porperty-manager/contacts';
import { DocumentInput, DocumentItem, DocumentOptions } from '../../_shared/models/porperty-manager/documents';
import {
  IInspectionJobData,
  IInspectionJobItem,
  InspectionScheduler,
  InspectionSchedulerInput,
  InspectionSchedulerOptions,
  InspectionSchedulerOutput,
  LeaseInspectionByLeaseId, LeaseInspectionInput,
  PrioritizationOptions,
  PrioritizationOutput, PropertiesForInspectionSchedulerOptions, PropertiesForInspectionSchedulerOutput,
} from '../../_shared/models/porperty-manager/inspection-scheduler';
import { T_INSPECTION } from '../../../Modules/_fyxt_modules/leases/pages/lease-inspection/lease-inspection.interface';
import {
  getFilePreviewUrl,
  getFileUrl,
} from './helpers'


type ApolloFetchResult<T> = Promise<ApolloQueryResult<T>>;


export interface ILeasesAPIResponse {
  leases?: Lease[];
  total?: number;
  size?: number;
  page?: number;
}


export interface IContactsAPIResponse {
  items?: Contacts[];
  total?: number;
  size?: number;
  page?: number;
  ids?: string[];
}


export interface IDocumentsAPIResponse {
  items?: DocumentItem[];
  total?: number;
  size?: number;
  page?: number;
  all_count?: number;
  credits?: number;
}


interface LeasesResult {
  leases: ILeasesAPIResponse;
  leasesTenant: ILeasesAPIResponse;
  createLease: Lease[];
  updateLease: Lease;
}


interface LeaseResult {
  lease: Lease;
}


interface PrimaryContactsResult {
  primaryContacts: PrimaryContacts[];
}


interface ContactsByCompanyResult {
  primaryContacts: Contacts[];
}


interface ContactsByLeaseResult {
  contactsByLease: IContactsAPIResponse;
}


interface PrimaryContactResult {
  contact: Contact;
}


interface PropertiesResult {
  properties: Property[];
  getPropertyByPm: Property[];
}


interface CompaniesResult {
  companies: Companies[];
}


interface ProvisionsResult {
  provisions: Provisions[];
  addProvision: Provisions;
}


interface DocumentResult {
  createDocumentLease: DocumentItem;
  documentsByLease: IDocumentsAPIResponse;
  leaseDocumentById: DocumentItem;
}

interface InspectionSchedulerResult {
  inspectionSchedulers: InspectionSchedulerOutput;
  getInspectionByLease: LeaseInspectionByLeaseId;
  getInspectionSchedulerById: InspectionScheduler;
  updateInspectionScheduler: InspectionScheduler;
  updateInspectionLease: LeaseInspectionByLeaseId;
  getWithPrioritization: PrioritizationOutput;
  getPropertiesForInspectionSchedulerId: PropertiesForInspectionSchedulerOutput;
}


interface CategoriesResult {
  categories: Category[];
}


interface RulesResult {
  rules: Rule[];
}


interface CountResult {
  leasesCount: ICount;
}


interface UnitsResult {
  unitsByProperty: IUnitsResult[];
}


interface ContactResult {
  contacts: Contacts[];
  assignContact: any
  removeContact: any
}


@Injectable({
  providedIn: 'root',
})
export class LeasesGraphqlService {
  private pLease$ = new BehaviorSubject<Lease | null>(null);
  public lease$ = this.pLease$.asObservable();

  private readonly LeasesQuery: QueryRef<LeasesResult, LeaseOptions> = this.appolo.watchQuery({
    query: gql`query($options: LeaseOptions) {
      leases(options: $options) {
        leases {
          id
          type
          tenant {
            id
            name
            active
          }
          property {
            id
            name
          }
          commencement_date
          expiration_date
          units {
            id
            name
            status
            tenant {
              name
              onboarded
            }
            address {
              address
            }
          }
          move_in_inspection
          move_out_inspection
          updated_at
          is_month_to_month
          external_code
        }
        total
        size
        page
      }
    }`,
    variables: {
      options: {
        sort: '',
        order: '',
        search: '',
        type: '',
        filters: {
          companies: [],
          properties: [],
        },
      },
    },
  });

  private readonly LeasesTenantQuery: QueryRef<LeasesResult, LeaseOptions> = this.appolo.watchQuery({
    query: gql`query($options: LeaseOptions) {
      leasesTenant(options: $options) {
        leases {
          id
          type
          tenant {
            id
            name
            active
          }
          property {
            id
            name
          }
          commencement_date
          expiration_date
          units {
            id
            name
            status
            tenant {
              name
              onboarded
            }
            address {
              address
            }
          }
          move_in_inspection
          move_out_inspection
          updated_at
          is_month_to_month
          external_code
        }
        total
        size
        page
      }
    }`,
    variables: {
      options: {
        sort: '',
        order: '',
        search: '',
        type: '',
        filters: {
          companies: [],
          properties: [],
        },
      },
    },
  });

  private readonly ContactByLeaseQuery: QueryRef<ContactsByLeaseResult, ContactVariables> = this.appolo.watchQuery({
    query: gql`query ($lease_id: Int! $options: ContactOptions) {
      contactsByLease(lease_id: $lease_id, options: $options) {
        items {
          id
          first_name
          last_name
          title
          primary_company
          phone
          email
        }
        total
        size
        page
        ids
      }
    }`,
    variables: {
      lease_id: 0,
      options: {
        sort: '',
        order: '',
        search: '',
        type: '',
      },
    },
  });

  private readonly PropertiesQuery: QueryRef<PropertiesResult, Property> = this.appolo.watchQuery({
    query: gql`query {
      properties {
        id
        name
      }
    }`,
  });

  private readonly PropertiesByPmQuery: QueryRef<PropertiesResult, Property> = this.appolo.watchQuery({
    query: gql`query {
      getPropertyByPm {
        id
        name
      }
    }`,
  });


  private readonly CompaniesQuery: QueryRef<CompaniesResult, Companies> = this.appolo.watchQuery({
    query: gql`query {
      companies {
        id
        name
      }
    }`,
  });

  private readonly ProvisionsQuery: QueryRef<ProvisionsResult, Provisions> = this.appolo.watchQuery({
    query: gql`query {
      provisions {
        id
        name
        clause
        assigned_document {
          id
          name
          url
          pages
        }
      }
    }`,
  });

  private readonly ContactsQuery: QueryRef<ContactResult, Contacts> = this.appolo.watchQuery({
    query: gql`query{
      contacts{
        id
        type
        first_name
        last_name
        email
        phone
        primary_company
        last_activity_at
        title
        active
      }
    }`,
  });

  constructor(private readonly appolo: Apollo) {
  };

  getLeases(options: LeaseOptions): Observable<ILeasesAPIResponse> {
    return from(this.LeasesQuery.refetch(options))
      .pipe(map(({ data }: ApolloQueryResult<LeasesResult>) => {
        const { leases: leasesData } = data;
        leasesData.leases.map((v) => ({
          ...v,
          expiration_date: v.expiration_date ? new Date(v?.expiration_date).toLocaleDateString('en-US') : null,
          commencement_date: v.commencement_date ? new Date(v.commencement_date).toLocaleDateString('en-US') : null,
        }));
        const { total, page, size } = leasesData;
        return {
          leases: leasesData.leases,
          total,
          size,
          page,
        };
      }));
  }

  getLeasesTenant(options: LeaseOptions): Observable<ILeasesAPIResponse> {
    return from(this.LeasesTenantQuery.refetch(options))
      .pipe(map(({ data }: ApolloQueryResult<LeasesResult>) => {
        const { leasesTenant: leasesData } = data;
        leasesData.leases.map((v) => ({
          ...v,
          expiration_date: v.expiration_date ? new Date(v?.expiration_date).toLocaleDateString('en-US') : null,
          commencement_date: v.commencement_date ? new Date(v.commencement_date).toLocaleDateString('en-US') : null,
        }));
        const { total, page, size } = leasesData;
        return {
          leases: leasesData.leases,
          total,
          size,
          page,
        };
      }));
  }

  getLease(id: number): Observable<Lease> {
    return from<Promise<ApolloQueryResult<LeaseResult>>>(this.appolo.watchQuery<LeaseResult, { id: number }>({
      query: gql`query ($id: Int!) {
        lease(id: $id)
        {
          id
          type
          lease_type
          tenant{
            id
            name
            type
            emails {
              id
              email
              type
              primary
            }
            phones {
              id
              phone
              country_code
              type
              primary
            }
            addresses {
              id
              address
              type
              primary
            }
            tags
            website
            created_at
          }
          property {
            id
            name
            type
            property_type
          }
          commencement_date
          expiration_date
          units {
            id
            name
            status
            tenant {
              name
              onboarded
            }
            address {
              address
            }
          }
          move_in_inspection
          move_out_inspection
          is_month_to_month
          external_code
          permission{
            can_create_job
            can_approve_job
          }
          activate_inspection
          inspection_scheduler_id
          contact_primary
          credits
        }
      }`,
      variables: {
        id: id,
      },
    }).refetch())
    .pipe(map((v) => v.data.lease))
    .pipe(tap(v => {
      this.pLease$.next(v);
    }));
  }

  createLease(lease: CreateLease): Observable<Lease[]> {
    return this.appolo.mutate({
      mutation: gql`mutation ($input: LeaseInput) {
        createLease(input: $input)
        {
          id
        }
      }`,
      variables: {
        'input': {
          'tenant': lease.company.id,
          'property': lease.property?.id,
          'units': lease.units,
          'inspection_scheduler_id': lease.inspection_scheduler_id,
          'move_in_date': lease.move_in_date,
          'move_out_date': lease.move_out_date,
          'is_month_to_month': lease.is_month_to_month,
          'type': lease.type,
          'commencement_date': lease.commencement_date,
          'expiration_date': lease.expiration_date,
          'external_code': lease.external_code,
          'permission': lease.permission,
        },
      },
    })
    .pipe(map(({ data }: ApolloQueryResult<LeasesResult>) => data.createLease));
  }

  updateLease(lease: UpdateLease): Observable<Lease> {
    return this.appolo.mutate({
      mutation: gql`mutation ($id: Int!, $input: LeaseInput) {
        updateLease(id: $id, input: $input) {
          id
          tenant{
            id
            name
            type
            emails{
              id
              email
              type
            }
            phones{
              id
              phone
              type
            }
            addresses{
              id
              address
              type
            }
          }
          property {
            id
            name
            type
          }
          commencement_date
          expiration_date
          is_month_to_month
          units {
            id
            name
            status
            tenant {
              name
              onboarded
            }
            address {
              address
            }
          }
          move_in_inspection
          move_out_inspection
          contact_primary
          external_code
          permission{
            can_create_job
            can_approve_job
          }
          lease_type
          is_month_to_month
          activate_inspection
          inspection_scheduler_id
        }
      }`,
      variables: {
        'input': {
          'tenant': lease.tenant,
          'property': lease.property,
          'commencement_date': lease.commencement_date,
          'expiration_date': lease.expiration_date,
          'is_month_to_month': lease.is_month_to_month,
          'units': lease.units,
          'type': lease.type,
          'contact_primary': lease.contact_primary,
          'lease_type': lease.lease_type,
          'activate_inspection': lease.activate_inspection,
          'inspection_scheduler_id': lease.inspection_scheduler_id,
          'move_in_date': lease.move_in_date,
          'move_out_date': lease.move_out_date,
          'external_code': lease.external_code,
          'permission': lease.permission,
        },
        'id': lease.id,
      },
    }).pipe(map(({ data }: ApolloQueryResult<LeasesResult>) => data.updateLease));
  }

  getCompanies(): Observable<Companies[]> {
    return from(this.CompaniesQuery.refetch())
      .pipe(map(({ data }: ApolloQueryResult<CompaniesResult>) => data.companies));
  }

  getProperties(): Observable<Property[]> {
    return from(this.PropertiesQuery.refetch())
      .pipe(map(({ data }: ApolloQueryResult<PropertiesResult>) => data.properties));
  }

  getPropertiesByPm(): Observable<Property[]> {
    return from(this.PropertiesByPmQuery.refetch())
      .pipe(map(({ data }: ApolloQueryResult<PropertiesResult>) => data.getPropertyByPm));
  }

  getContacts(): Observable<Contacts[]> {
    return from(this.ContactsQuery.refetch())
      .pipe(map(({ data }: ApolloQueryResult<ContactResult>) => data.contacts));
  }

  getCategories(lease_id: number): Observable<ILeaseCategory[]> {
    return from<Promise<ApolloQueryResult<ILeaseCategoriesResult>>>(this.appolo.watchQuery<ILeaseCategoriesResult, { id: number }>({
      query: gql`query($id: Int!) {
        leaseCategories(id: $id) {
          id
          category_name
          config
          is_modified
        }
      }`,
      variables: {
        id: lease_id,
      },
    }).refetch())
    .pipe(map(({ data }: ApolloQueryResult<ILeaseCategoriesResult>) => data.leaseCategories));
  }

  updateLeaseCategoryConfig(lease_id: number, category_id: string, config: string): Observable<boolean> {
    return this.appolo.mutate({
      mutation: gql`mutation($lease_id: Int!, $category_id: String!, $config: String!) {
        updateLeaseCategoryConfig(lease_id: $lease_id, category_id: $category_id, config: $config)
      }`,
      variables: { lease_id, category_id, config },
    })
    .pipe(map(({ data }: ApolloQueryResult<{ updateLeaseCategoryConfig: boolean }>) => data.updateLeaseCategoryConfig));
  }

  getRules(category_id: string, lease_id: number): Observable<Rule[]> {
    return from<Promise<ApolloQueryResult<RulesResult>>>(this.appolo.watchQuery<RulesResult, { category_id: string, lease_id: number }>({
      query: gql`query($category_id: String!, $lease_id: Int!) {
        rules(category_id: $category_id, lease_id: $lease_id) {
          id
          is_global
          leases_type
          lease_id
          rule_for
          rule_applies
          management_responsible
          tenant_responsible
          assigned_document {
            id
            name
            url
            pages
          }
          criteria {
            name
            count
            management_responsible_limit
            tenant_responsible_limit
          }
          categories {
            id
            name
          }
        }
      }`,
      variables: {
        category_id: category_id,
        lease_id: lease_id,
      },
    }).refetch())
    .pipe(map(({ data }: ApolloQueryResult<RulesResult>) => data.rules));
  }


  createRule(rule: Rule): Observable<Rule> {
    return this.appolo.mutate({
      mutation: gql`mutation ($input: RuleInput) {
        createRule(input: $input) {
          id
          leases_type
          lease_id
          rule_for
          rule_applies
          management_responsible
          tenant_responsible
          assigned_document {
            id
            name
            url
            pages
          }
          criteria {
            name
            count
            management_responsible_limit
            tenant_responsible_limit
          }
          categories {
            id
            name
          }
        }
      }`,
      variables: {
        input: {
          leases_type: rule.leases_type,
          category_id: rule.category_id,
          lease_id: rule.lease_id,
          categories: rule.categories,
          rule_for: rule.rule_for,
          rule_applies: rule.rule_applies,
          management_responsible: rule.management_responsible,
          tenant_responsible: rule.tenant_responsible,
          assigned_document: rule.assigned_document,
          criteria: rule.criteria,
        },
      },
    })
    .pipe(map(({ data }: ApolloQueryResult<RuleCreationResult>) => data.createRule));
  }

  updateRule(id: number, rule: Rule): Observable<Rule> {
    return this.appolo.mutate({
      mutation: gql`mutation ($id: Int!, $input: RuleInput) {
        updateRule(id: $id, input: $input) {
          id
          leases_type
          lease_id
          rule_for
          rule_applies
          management_responsible
          tenant_responsible
          assigned_document {
            id
            name
            url
            pages
          }
          criteria {
            name
            count
            management_responsible_limit
            tenant_responsible_limit
          }
          categories {
            id
            name
          }
        }
      }`,
      variables: {
        input: {
          leases_type: rule.leases_type,
          category_id: rule.category_id,
          lease_id: rule.lease_id,
          categories: rule.categories,
          rule_for: rule.rule_for,
          rule_applies: rule.rule_applies,
          management_responsible: rule.management_responsible,
          tenant_responsible: rule.tenant_responsible,
          assigned_document: rule.assigned_document,
          criteria: rule.criteria,
        },
        id,
      },
    })
    .pipe(map(({ data }: ApolloQueryResult<RuleUpdateResult>) => data.updateRule));
  }

  deleteRule(id: number): Observable<any> {
    return this.appolo.mutate({
      mutation: gql`mutation ($id: Int!) {
        deleteRule(id: $id)
      }`,
      variables: { id },
    });
  }

  getRuleOptions(): Observable<IRuleOptions> {
    return from<Promise<ApolloQueryResult<IRuleOptionsAPIResponse>>>(this.appolo.watchQuery<IRuleOptionsAPIResponse, any>({
      query: gql`query {
        ruleOptions {
          applies_options {
            id
            value
          }
          for_options {
            id
            value
          }
        }
      }`,
    }).refetch())
    .pipe(map(({ data }: ApolloQueryResult<IRuleOptionsAPIResponse>) => data.ruleOptions));

  }

  getRulesCount(lease_id: number): Observable<IRulesCount[]> {
    return from<Promise<ApolloQueryResult<IRulesCountAPIResponse>>>(this.appolo.watchQuery<IRulesCountAPIResponse, { lease_id: number }>({
      query: gql`query($lease_id: Int!) {
        rulesCount(lease_id: $lease_id) {
          category_id
          count
        }
      }`,
      variables: { lease_id },
    }).refetch())
    .pipe(map(({ data }: ApolloQueryResult<IRulesCountAPIResponse>) => data.rulesCount));
  }

  getCount(): Observable<ICount> {
    return from<Promise<ApolloQueryResult<CountResult>>>(this.appolo.watchQuery<CountResult, any>({
      query: gql`
        query {
          leasesCount {
            CREATED
            ENDED
            DRAFT
          }
        }
      `,
    }).refetch())
    .pipe(map(({ data }: ApolloQueryResult<CountResult>) => data.leasesCount));
  }

  getUnits(property_id: string, dates: { commencement_date: string, expiration_date: string, is_month_to_month: boolean }): Observable<IUnitsResult[]> {
    return from<Promise<ApolloQueryResult<UnitsResult>>>(this.appolo.watchQuery<UnitsResult, any>({
      query: gql`
        query($property_id: String!, $dates: UnitDateStatusInput) {
          unitsByProperty(property_id: $property_id, dates: $dates) {
            address
            units {
              id
              name
              status
              tenant {
                name
                onboarded
              }
            }
          }
        }
      `,
      variables: {
        property_id: property_id,
        dates: dates,
      },
    }).refetch())
    .pipe(map(({ data }: ApolloQueryResult<UnitsResult>) => data.unitsByProperty));
  }

  getContactsByCompany(company_id: string): Observable<any[]> {
    return from<Promise<ApolloQueryResult<ContactsByCompanyResult>>>(this.appolo.watchQuery<ContactsByCompanyResult, { company_id: string }>({
      query: gql`
        query ($company_id: String!){
          primaryContacts(company_id: $company_id) {
            id
            first_name
            last_name
            primary
            type
            primary_company
          }
        }`,
      variables: {
        company_id: company_id,
      },
    }).refetch())
    .pipe(map((v) => v.data.primaryContacts));
  }

  getContactsByLease(variables: ContactVariables): Observable<any> {
    return from(this.ContactByLeaseQuery.refetch(variables))
      .pipe(map(({ data }: ApolloQueryResult<ContactsByLeaseResult>) => {
        const { contactsByLease: contactsData } = data;
        const { total, page, size, ids } = contactsData;
        return {
          items: contactsData.items,
          total,
          size,
          page,
          ids,
        };
      }));
  }

  getContactById(id: string): Observable<Contact> {
    return from<Promise<ApolloQueryResult<PrimaryContactResult>>>(this.appolo.watchQuery<PrimaryContactResult, { id: string }>({
      query: gql`
        query($id: String!){
          contact(id: $id) {
            id
            first_name
            last_name
            title
            type
            primary_company
            active
            emails {
              id
              email
              type
              verified
            }
            phones {
              id
              type
              phone
            }
            last_activity_at
          }
        }
      `,
      variables: {
        id: id,
      },
    }).refetch())
    .pipe(map((v) => v.data.contact));
  }

  assignContact(lease_id: number, contact_id: string) {
    return this.appolo.mutate({
      mutation: gql`mutation($id: Int!, $contact_id: String!) {
        assignContact(id: $id, contact_id: $contact_id) {
          id
          contact_id
        }
      }`,
      variables: {
        id: lease_id,
        contact_id: contact_id,
      },
    }).pipe(map(({ data }: ApolloQueryResult<ContactResult>) => data.assignContact));
  }

  removeContact(lease_id: number, contact_id: string) {
    return this.appolo.mutate({
      mutation: gql`mutation($id: Int!, $contact_id: String!) {
        removeContact(id: $id, contact_id: $contact_id) {
          id
          contact_id
        }
      }`,
      variables: {
        id: lease_id,
        contact_id: contact_id,
      },
    }).pipe(map(({ data }: ApolloQueryResult<ContactResult>) => data.removeContact));
  }

  removeBulkContacts(lease_id: number, ids: string[]) {
    return this.appolo.mutate({
      mutation: gql`mutation($id: Int!, $contact_ids: [String]!) {
        removeBulkContacts(id: $id, contact_ids: $contact_ids)
      }`,
      variables: {
        id: lease_id,
        contact_ids: ids,
      },
    }).pipe(map(() => lease_id));
  }

  createDocumentLease(lease_id: number, document_id: string, title: string): Observable<DocumentItem> {
    return this.appolo.use('leases').mutate({
      mutation: gql`mutation($input: DocumentInput) {
        createDocumentLease(input: $input){
          id
          title
          status
          effective_date
          document {
            id
            title
            description
            size
            key
            url
            previewUrl
            tags
            source
            mimeType
            filename
            createdAt
            updatedAt
            createdById
            user {
              first_name
              last_name
            }
          }
        }
      }`,
      variables: {
        'input': {
          'lease_id': +lease_id,
          'document_id': document_id,
          'title': title,
        },
      },
    })
    .pipe(map(({ data }: ApolloQueryResult<DocumentResult>) => ({
      ...data.createDocumentLease,
      document: {
        ...data.createDocumentLease.document,
        url: getFileUrl(data.createDocumentLease.document),
        previewUrl: getFilePreviewUrl(data.createDocumentLease.document)
      }
    })));
  }

  getDocumentsByLeaseId(lease_id: number, options: DocumentOptions): Observable<IDocumentsAPIResponse> {
    return from<Promise<ApolloQueryResult<DocumentResult>>>(this.appolo.use('leases').watchQuery<DocumentResult, { lease_id: number, options: DocumentOptions }>({
      query: gql`query($lease_id: Int!, $options: DocumentOptions) {
        documentsByLease(lease_id: $lease_id, options: $options){
          items {
            id
            title
            status
            effective_date
            document {
              id
              title
              description
              size
              key
              url
              previewUrl
              tags
              source
              mimeType
              filename
              createdAt
              updatedAt
              createdById
              user {
                first_name
                last_name
              }
            }
          }
          total
          size
          page
          all_count
          credits
        }
      }`,
      variables: {
        lease_id: +lease_id,
        options: options,
      },
    }).refetch())
    .pipe(map(({ data }: ApolloQueryResult<DocumentResult>) => data.documentsByLease));

  }

  getLeaseDocumentById(lease_document_id: string): Observable<DocumentItem> {
    return from<Promise<ApolloQueryResult<DocumentResult>>>(this.appolo.use('leases').watchQuery<DocumentResult, { lease_document_id: string }>({
      query: gql`query($lease_document_id: String!) {
        leaseDocumentById(lease_document_id: $lease_document_id){
              id
              effective_date
              document {
                id
                title
                description
                size
                key
                url
                previewUrl
                tags
                source
                mimeType
                filename
                createdAt
                updatedAt
                user {
                  first_name
                  last_name
                }
                createdById
          }
            }
      }`,
      variables: {
        lease_document_id,
      },
    }).refetch())
    .pipe(map(({ data }: ApolloQueryResult<DocumentResult>) => data.leaseDocumentById));

  }

  updateDocument(document_id: string, input: DocumentInput) {
    return this.appolo.use('leases').mutate({
      mutation: gql`mutation($document_id: String!, $input: DocumentInput) {
        updateDocumentLease(input: $input, document_id: $document_id){
          id
          title
          status
          effective_date
          document {
            id
              title
              description
              size
              key
              url
              previewUrl
              tags
              source
              mimeType
              filename
              createdAt
              updatedAt
              createdById
              user {
                first_name
                last_name
              }
          }
        }
      }`,
      variables: {
        document_id: document_id,
        input: {
          title: input.title,
          effective_date: input.effective_date,
          status: input.status,
          description: input.description,
        },
      },
    });
  }

  deleteDocument(document_id: string): Observable<any> {
    return this.appolo.use('leases').mutate({
      mutation: gql`mutation($document_id: String!) {
        deleteDocumentLease(document_id: $document_id)
      }`,
      variables: {
        document_id: document_id,
      },
    });
  }

  bulkDocuments(document_ids: string[], action: string): Observable<any> {
    return this.appolo.use('leases').mutate({
      mutation: gql`mutation($document_ids: [String]!, $action: String!) {
        bulkDocuments(document_ids: $document_ids, action: $action)
      }`,
      variables: {
        document_ids,
        action,
      },
    });
  }

  bulkLeases(lease_ids: number[], action: string): Observable<any> {
    return this.appolo.mutate({
      mutation: gql`mutation($lease_ids: [Int]!, $action: String!) {
        bulkLeases(lease_ids: $lease_ids, action: $action)
      }`,
      variables: {
        lease_ids,
        action,
      },
    });
  }

  getInspectionSchedulers(options: InspectionSchedulerOptions): Observable<InspectionSchedulerOutput> {
    return from<Promise<ApolloQueryResult<InspectionSchedulerResult>>>(this.appolo.watchQuery<InspectionSchedulerResult, { options: InspectionSchedulerOptions }>({
      query: gql`query($options: InspectionSchedulerOptions) {
        inspectionSchedulers(options: $options){
          items {
            id
            name
            created_at
            created_by
          }
          total
          size
          page
        }
      }`,
      variables: {
        options: options,
      },
    }).refetch())
    .pipe(map(({ data }: ApolloQueryResult<InspectionSchedulerResult>) => data.inspectionSchedulers));
  }

  createInspectionScheduler(input: InspectionSchedulerInput): Observable<any> {
    return this.appolo.mutate({
      mutation: gql`mutation($input: InspectionSchedulerInput) {
        createInspectionScheduler(input: $input) {
          id
        }
      }`,
      variables: {
        input: {
          name: input.name,
          move_in_checklist_id: input.move_in_checklist_id,
          move_in_inspection_job: input.move_in_inspection_job,
          move_out_checklist_id: input.move_out_checklist_id,
          move_out_inspection_job: input.move_out_inspection_job,
          properties: input.properties,
          tenants: input.tenants,
          // status: input.status,
          // created_at: input.created_at,
          // created_by: input.created_by,

        },
      },
    })
    .pipe(map(({ data }: ApolloQueryResult<InspectionSchedulerResult>) => data.inspectionSchedulers));
  }

  updateInspectionScheduler(id: number, input: InspectionSchedulerInput): Observable<any> {
    return this.appolo.mutate({
      mutation: gql`mutation($id: Int!, $input: InspectionSchedulerInput) {
        updateInspectionScheduler(id: $id, input: $input) {
          id
          name
          move_in_checklist_id {
            id
            name
          }
          move_in_inspection_job
          move_out_checklist_id {
            id
            name
          }
          move_out_inspection_job
          properties {
            id
            name
            units {
              id
              name
            }
          }
          tenants {
            id
            name
          }
        }
      }`,
      variables: {
        id: id,
        input: {
          name: input.name,
          move_in_checklist_id: input.move_in_checklist_id,
          move_in_inspection_job: input.move_in_inspection_job,
          move_out_checklist_id: input.move_out_checklist_id,
          move_out_inspection_job: input.move_out_inspection_job,
          properties: input.properties,
          tenants: input.tenants,
          status: input.status,
        },
      },
    })
    .pipe(map(({ data }: ApolloQueryResult<InspectionSchedulerResult>) => data.updateInspectionScheduler));
  }

  updateInspectionLease(lease_id: number, input: LeaseInspectionInput): Observable<any> {
    return this.appolo.mutate({
      mutation: gql`mutation($lease_id: Int!, $input: LeaseInspectionInput) {
        updateInspectionLease(lease_id: $lease_id, input: $input) {
          inspection_scheduler_id
          inspection_scheduler_name
          move_in_inspection_job
          move_in_checklist_id {
            id
            name
          }
          move_out_inspection_job
          move_out_checklist_id {
            id
            name
          }
          move_in_date
          move_out_date}
      }`,
      variables: {
        lease_id: lease_id,
        input: {
          move_in_checklist_id: input.move_in_checklist_id,
          move_in_inspection_job: input.move_in_inspection_job,
          move_out_checklist_id: input.move_out_checklist_id,
          move_out_inspection_job: input.move_out_inspection_job,
          move_in_date: input.move_in_date,
          move_out_date: input.move_out_date,
        },
      },
    })
    .pipe(map(({ data }: ApolloQueryResult<InspectionSchedulerResult>) => data.updateInspectionLease));
  }

  getInspectionSchedulerById(id: number): Observable<InspectionScheduler> {
    return from<Promise<ApolloQueryResult<InspectionSchedulerResult>>>(this.appolo.watchQuery<InspectionSchedulerResult, { id: number }>({
      query: gql`query ($id: Int!) {
        getInspectionSchedulerById(id: $id)
        {
          id
          name
          move_in_checklist_id {
            id
            name
          }
          move_in_inspection_job
          move_out_checklist_id {
            id
            name
          }
          move_out_inspection_job
          properties {
            id
            name
            units {
              id
              name
            }
          }
          tenants {
            id
            name
          }
          status
          created_at
          created_by
          updated_at
          update_by
        }
      }`,
      variables: {
        id: id,
      },
    }).refetch()).pipe(map(({ data }: ApolloQueryResult<InspectionSchedulerResult>) => data.getInspectionSchedulerById));
  }

  getPropertiesForInspectionSchedulerId(scheduler_id: number, options: PropertiesForInspectionSchedulerOptions): Observable<PropertiesForInspectionSchedulerOutput> {
    return from<Promise<ApolloQueryResult<InspectionSchedulerResult>>>(this.appolo.watchQuery<InspectionSchedulerResult, { id: number, options: any }>({
      query: gql`query ($id: Int!, $options: PropertiesForInspectionSchedulerOptions) {
        getPropertiesForInspectionSchedulerId(id: $id, options: $options) {
          items {
            id
            name
            units{
              id
              name
            }
            all_units
          }
          total
          size
          page
        }
      }`,
      variables: {
        id: scheduler_id,
        options: options,
      },
    }).refetch()).pipe(map(({ data }: ApolloQueryResult<InspectionSchedulerResult>) => data.getPropertiesForInspectionSchedulerId));
  }

  getInspectionByLease(lease_id: number): Observable<LeaseInspectionByLeaseId> {
    return from<Promise<ApolloQueryResult<InspectionSchedulerResult>>>(this.appolo.watchQuery<InspectionSchedulerResult, { lease_id: number }>({
      query: gql`query($lease_id: Int!) {
        getInspectionByLease(lease_id: $lease_id) {
          inspection_scheduler_id
          inspection_scheduler_name
          move_in_inspection_job
          move_in_checklist_id {
            id
            name
          }
          move_out_inspection_job
          move_out_checklist_id {
            id
            name
          }
          move_in_date
          move_out_date
        }
      }`,
      variables: {
        lease_id: lease_id,
      },
    }).refetch()).pipe(map(({ data }: ApolloQueryResult<InspectionSchedulerResult>) => data.getInspectionByLease));
  }


  bulkInspectionScheduler(ids: number[], action: string): Observable<any> {
    return this.appolo.mutate({
      mutation: gql`mutation($ids: [Int]!, $action: String!) {
        multiInspectionScheduler(ids: $ids, action: $action)
      }`,
      variables: {
        ids,
        action,
      },
    });
  }

  getLeaseInspectionJobs(lease_id: number): Observable<IInspectionJobData[]> {
    return from<Promise<ApolloQueryResult<{ getLeaseInspectionJobs: IInspectionJobData[] }>>>(this.appolo.watchQuery<{ getLeaseInspectionJobs: IInspectionJobData[] }, { lease_id: number }>({
      query: gql`query ($lease_id: Int!) {
        getLeaseInspectionJobs(lease_id: $lease_id) {
          id
          job_id
          job {
            id
            category {
              id
              name
            }
            target_date
            property {
              id
              name
            }
            unit {
              id
              name
            }
            status
            stage {
              id
              name
            }
          }
        }
      }`,
      variables: {
        lease_id: lease_id,
      },
    }).refetch()).pipe(map(({ data }: ApolloQueryResult<{ getLeaseInspectionJobs: IInspectionJobData[] }>) => data.getLeaseInspectionJobs));
  }

  triggerInspection(lease_id: number, date: string, inspection_type: T_INSPECTION, checklist_id: string, need_subtract_date: boolean): Observable<IInspectionJobItem[]> {
    return this.appolo.mutate({
      mutation: gql`mutation($lease_id: Int!, $date: String!, $inspection_type: String!, $checklist_id: String!, $need_subtract_date: Boolean) {
        createInspectionJob(lease_id: $lease_id, date: $date, inspection_type: $inspection_type, checklist_id: $checklist_id, need_subtract_date: $need_subtract_date) {
          id
          category {
            id
            name
          }
          target_date
          property {
            id
            name
          }
          unit {
            id
            name
          }
          status
          stage {
            id
            name
          }
        }
      }`,
      variables: { lease_id: +lease_id, date, inspection_type, checklist_id, need_subtract_date },
    })
    .pipe(map(({ data }: ApolloQueryResult<{ createInspectionJob: IInspectionJobItem[] }>) => data.createInspectionJob));
  }


  getWithPrioritization(options: PrioritizationOptions): Observable<PrioritizationOutput> {
    return from<Promise<ApolloQueryResult<InspectionSchedulerResult>>>(this.appolo.watchQuery<InspectionSchedulerResult, { options: PrioritizationOptions }>({
      query: gql`query ($options: PrioritizationOptions) {
        getWithPrioritization(options: $options)
        {
          suggested {
            id
            name
          }
          all {
            id
            name
          }
        }
      }`,
      variables: {
        options: {
          tenant: options.tenant,
          units: options.units,
          property: options.property,
        },
      },
    }).refetch()).pipe(map(({ data }: ApolloQueryResult<InspectionSchedulerResult>) => data.getWithPrioritization));
  }

  /**
   * Get Rule Info by lease id
   * @param lease_id
   * @returns
   */
  getRulesByCategory(lease_id: number): Observable<Rule[]> {
    return from<Promise<ApolloQueryResult<RulesResult>>>(this.appolo.watchQuery<RulesResult, { lease_id: number }>({
      query: gql`query($lease_id: Int!) {
        getAllLeaseRules(lease_id: $lease_id) {
          category_id
          category_name
          config
          rules {
            id
            lease_id
            leases_type
            rule_for
            rule_applies
            management_responsible
            tenant_responsible
            assigned_document {
              id
              name
              url
              pages
            }
            criteria {
              name
              count
              management_responsible_limit
              tenant_responsible_limit
            }
          }
        }
      }`,
      variables: {
        lease_id: lease_id,
      },
    }).refetch())
    .pipe(map(({ data }: ApolloQueryResult<any>) => data ? (data.getAllLeaseRules) : []));
  }

}
