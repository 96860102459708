import {Component, Inject, OnInit} from '@angular/core';
import {environment} from "../../../../../../environments/environment";
import {DialogService} from "../../../../_services/dialog/dialog.service";
import {UtilityService} from "../../../../../services/utility.service";
import {JobsService} from "../../../../../services/v2/jobs.service";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import {AddViewComponent} from "../add-view/add-view.component";
import { HttpErrorResponse } from '@angular/common/http';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { CheckboxComponent } from '../../../_reusable_ui/_controls/checkbox/checkbox.component';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-checklist-associate-equipment',
    templateUrl: './checklist-associate-equipment.component.html',
    styleUrls: ['./checklist-associate-equipment.component.scss'],
    standalone: true,
    imports: [NgFor, CheckboxComponent, FormsModule, NgxIntlTelInputModule, MatTooltipModule, InfiniteScrollModule, MatDialogModule, ButtonComponent]
})
export class ChecklistAssociateEquipmentComponent implements OnInit {
  selectedCheckList: any;
  fromPlanner: boolean = false;
  constructor(public _dialogService: DialogService, public _utilService: UtilityService,
              public _jobService: JobsService, @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<ChecklistAssociateEquipmentComponent>,) {
    this.selectedCheckList = this.data?.data?.checkListDetails;
    if(this.data?.data?.fromPlanner){
      this.fromPlanner = this.data.data.fromPlanner;
    }
  }

  equipmentData = {
    data: [],
    currentPage: 1,
    perPage: 50
  };
  selectedEquipmentData = [];

  ngOnInit(): void {
    this.getEquipmentDetails();
  }

  getEquipmentDetails(): void {
    let selectedId = this._jobService.selectedJobInfo.equipment;
    if(this.fromPlanner){
      selectedId = this.data.data.id;
    }
    let requestURL = `${environment.equipURL}/jobs/equipment/?query={'id': ${JSON.stringify(selectedId)}}`;
    requestURL += '&search&columns=["id", "image", "name", "specific_location", "serial_number", "asset_tag"] & sort_column=name&sort_order=asc&page=' + this.equipmentData.currentPage + '&size=' + this.equipmentData.perPage;
    this._dialogService.doGET(requestURL).subscribe(
      {
        next: (response: any) => {
          response.items.map((data: any) => {
            return data.isChecked = false;
          });
          if (this.equipmentData.currentPage != undefined && this.equipmentData.currentPage > 1) {
            this.equipmentData.data = this.equipmentData.data.concat(response.items) || [];
          } else {
            this.equipmentData.data = response.items;
          }
          this.equipmentData.perPage = response.size;
          this.equipmentData.currentPage = response.page;
        },
        error: (error:HttpErrorResponse) => {
          this._utilService.showErrorMessage(error);
        },
        complete: () => {
        }
      }
    )
  }

  onScroll(event: any) {
    this.equipmentData.currentPage += 1;
    this.getEquipmentDetails();
  }

  checkedEquipmentData(data: any, event: any) {
    data.isChecked = event;
    this.selectedEquipmentData = this.equipmentData.data.filter((equipment: any) => {
      return equipment.isChecked == true;
    });
  }

  assignEquipment(): void {
    let payload = {};
    if(this.fromPlanner){
      payload = {
        checklist_id: this.selectedCheckList.checklist_id,
        equipment: this.selectedEquipmentData.map((equipment: any) => {
          return equipment.id;
        })
      };
    }else{
      payload = {
        job_checklist: this.selectedCheckList.id,
        equipment: this.selectedEquipmentData.map((equipment: any) => {
          return equipment.id;
        })
      };
    }
    
    let requestURL = '';
    if(this.fromPlanner){
      requestURL = environment.baseURL + `planner/${this.data.data.plannerID}/planner-checklist-equipment/`;
    }else{
      requestURL = environment.baseURL + `jobs/${this._jobService.selectedJobInfo.id}/job-checklist-equipment/`;
    }
    this._dialogService.doPATCH(requestURL, payload).subscribe(
      {
        next: (response: any) => {
          response.idAddEquipment = true;
          this.dialogRef.close(response);
          this._utilService.showSuccess('', 'Equipment Added Successfully.');
        },
        error: (error) => {
          this._utilService.showErrorMessage(error);
        },
        complete: () => {
        }
      }
    );
  }

   /** Track by Index  **/
   trackByIndex(index: number, item: any): number {
    return index;
  }
}
