import { Component, Inject, Input, OnInit } from '@angular/core';
import { Observable, Subject, from } from 'rxjs';
import {
  HttpBackend,
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpProgressEvent,
  HttpResponse,
} from '@angular/common/http';
import { ColumnSortType, TableColumnTypes } from 'src/app/@fyxt/_shared/enums/table/table-column-types.enum';
import { AppTableSource } from 'src/app/@fyxt/_shared/models/app-table-model';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { FilesService } from 'src/app/Modules/_fyxt_modules/companies/_services/files.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { concatMap, map, takeUntil, tap } from 'rxjs/operators';
import { ConfigService } from 'src/app/services/config.service';
import db from 'mime-db';
import moment from 'moment/moment';
import { UtilityService } from '../../../../../services/utility.service';
import { FileForm, FilesFormats, FileUploadTypes } from './tab-upload-files-popup.interface';
import { DocumentInfo } from "../../../models/porperty-manager/documents";
import { JobsService } from 'src/app/services/v2/jobs.service';
import { BaseService } from 'src/app/services/base.service';
import { DropdownSelectComponent } from '../../../_reusable_ui/_controls/dropdown-select/dropdown-select/dropdown-select.component';
import { TextareaComponent } from '../../../_reusable_ui/_controls/textarea/textarea.component';
import { TagsComponent } from '../../../_reusable_ui/_components/tags/tags.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { MatIconModule } from '@angular/material/icon';
import { ListComponent } from '../../../_reusable_ui/_components/list/list.component';
import { MatDividerModule } from '@angular/material/divider';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { TextboxComponent } from '../../../_reusable_ui/_controls/textbox/textbox.component';
import { UploadFilesComponent } from '../../../_reusable_ui/_components/upload-files-component/upload-files.component';
import { NgClass, NgIf, NgStyle, NgFor } from '@angular/common';

interface UpdateFileForm extends Record<keyof FileForm, FormControl> {
}

@Component({
    selector: 'app-tab-upload-files-popup',
    templateUrl: './tab-upload-files-popup.component.html',
    styleUrls: ['./tab-upload-files-popup.component.scss'],
    standalone: true,
    imports: [NgClass, UploadFilesComponent, NgIf, FormsModule, ReactiveFormsModule, TextboxComponent, NgxIntlTelInputModule, ButtonComponent, NgStyle, MatDividerModule, NgFor, ListComponent, MatIconModule, LoaderSmallComponent, NgxDocViewerModule, TagsComponent, TextareaComponent, DropdownSelectComponent]
})
export class TabUploadFilesPopupComponent implements OnInit {
  @Input() dataSource!: AppTableSource;
  inputData: any;
  isFileDetails: boolean;
  isLoader: boolean = false;
  base: string;
  tags: any[] = [];
  chipList: any[] = [];
  public updateFileForm!: FormGroup<UpdateFileForm>;
  protected readonly FilesFormats = FilesFormats;
  protected readonly FileUploadTypes = FileUploadTypes;
  isPublic: boolean = false;

  showVendorList = false;
  vendorList: any[] = [];
  isVendorLoading = false;
  selectedVendorList: any[] = [];

  constructor(
    public http: HttpClient,
    public _dialogService: DialogService,
    public utilService: UtilityService,
    public filesService: FilesService,
    private handler: HttpBackend,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService,
    private configService: ConfigService,
    public dialogRef: MatDialogRef<TabUploadFilesPopupComponent>,
    public _jobService: JobsService,
    private _utilityService:UtilityService,
    private _baseService:BaseService
  ) {
    this.base = environment.files.http;
    this.base = this.base.substring(0, this.base.length - 1);
    this.inputData = data;
    this.isPublic = data?.data?.public;

    this.showVendorList = data.data.entityType == 'JOB' ? true : false;

    if(this.showVendorList && !this.data.data?.isExternalVendor){
      this.getAllVendors('',true);
    }

    // this.http = new HttpClient(handler);
  }

  myGroup = new FormGroup({
    url: new FormControl(),
  });

  uploadedList = [];
  tempUploadedList = [];

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.dialogRef.close(this.tempUploadedList);
  }


  dummyData() {
    const tableHeaders = [
      {
        value: 'Image',
        sort: ColumnSortType.none,
        name: 'Name',
        type: TableColumnTypes.image,
        className: 'table-header',
      },
      {
        value: 'name',
        sort: ColumnSortType.none,
        name: '',
        type: TableColumnTypes.text,
        className: 'table-header',
      },
      {
        value: 'action',
        sort: ColumnSortType.none,
        name: '',
        type: TableColumnTypes.action,
        className: 'three-dot',
      },

    ];

    const Jpeg = '../../../../../assets/@fyxt/images/PartsDiagram.jpg';
    const Pdf = 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1667px-PDF_file_icon.svg.png';
    const link = 'Repair Manual';

    const tableRows: any[] = [
      { name: 'Repair Manual', imageLink: Pdf },
      { name: 'Exploded Parts Diagram', imageLink: Jpeg },
      { name: 'Purchase Invoice', imageLink: Pdf },
      { name: 'Statement of Work', imageLink: Pdf },
      { name: 'Warranty Terms', imageLink: Pdf },
    ];

    const source: AppTableSource = {
      headers: tableHeaders,
      rows: tableRows,
      length: tableRows.length,
    };
    this.dataSource = source;
  }


  //fake simulation of uploading file
  uploadFilesSimulator(file: File): Observable<HttpEvent<{ id: string }>> {
    const fakeProgress$ = new Subject<HttpEvent<{ id: string }>>();
    let progress = 0;

    const progressInterval = setInterval(() => {
      if (progress === 100) {
        const fakeHttpResponse = {
          type: HttpEventType.Response,
          body: { id: '123' },
        } as HttpResponse<{ id: string }>;

        fakeProgress$.next(fakeHttpResponse);
        clearInterval(progressInterval);

        return;
      } else {
        progress += 5;
      }

      const fakeProgressEvent = {
        type: HttpEventType.UploadProgress,
        loaded: progress,
        total: 100,
      };

      fakeProgress$.next(fakeProgressEvent as HttpProgressEvent);
    }, 70);

    return fakeProgress$.asObservable();
  }

  //common use of the download component
  getUploadFilesHandler() {
    return this.uploadFileHandler.bind(this);
  }
  uploadFileHandler(file: File) {
    const entityId = this.data.data.entityId;
    const entityType = this.data.data.entityType;
    const subDomain = this.filesService.subDomain;
    const isExternalVendor = this.data.data.isExternalVendor;
    const isDocManager = this.data.data.isDocManager;
    const isFileManager = this.data.data.isFileManager;
    const uploadDocumentHttpEvents$ = (() => {
      if (entityId != null) {

        let url = `${environment.files.http}files`;
        const formData: any = new FormData();
        //any other fields
        formData.append('entityId', entityId);
        formData.append('entityType', entityType);
        formData.append('file', file);
        if(isExternalVendor){
          url = url + '/public';
          formData.append('createdByType', 'VENDOR');
          formData.append('createdById', this.data.data.vendor.createdById);
          formData.append('tags[0]', this.data.data.vendor.tag);
          formData.append('path', this.data.data.vendor.path)
        }
        if (isDocManager) {
          url = `${environment.files.http}documents/upload`;
          formData.append('path', this.data.data.currentPath);
        }

        if (isFileManager) {
          url = `${environment.files.http}files`;
          formData.append('path', this.data.data.path);
        }
        //formData.append('title', file.name);

        return this.http.post<{ data: DocumentInfo }>(url, formData
          , {
            headers: {
              'x-tenant-id': subDomain,
            }, reportProgress: true,
            observe: 'events',
          },
        );

      } else {

        const url = `${environment.files.http}files/temp`;
        const formData: any = new FormData();
        formData.append('file', file);
        return this.http.post<{ data: DocumentInfo }>(url, formData
          , {
            headers: {
              'x-tenant-id': subDomain,
            }, reportProgress: true,
            observe: 'events',
          });

      }
    })();

    return uploadDocumentHttpEvents$.pipe(
      map((event) => {
        if (event.type === HttpEventType.Response) {
          return {
            ...event,
            body: isExternalVendor ? event.body : event.body.data,
          } as HttpResponse<DocumentInfo>;
        }

        return event as HttpEvent<DocumentInfo>;
      }),
      tap((event) => {
        const isSuccessEventStatus = 'status' in event && event.status === 201;
        if (event.type === HttpEventType.Response && isSuccessEventStatus) {
          this.fileUploadedEvent(event);
          if(this.showVendorList){
            // HANDLING THIS IN BE
            //this.assignFileToApprovedVendors(event.body,this.vendorList);
          }
        }
      }),
    );

  }

  deleteFile(file) {
    if (this.updateFileForm && this.updateFileForm.getRawValue()?.id === file.id) {
      this.isFileDetails = !this.isFileDetails;
      this.updateFileForm.reset();
    }
    for (let i = 0; i < this.uploadedList.length; i++) {
      if (file.key == this.uploadedList[i].key) {
        this.uploadedList.splice(i, 1);
      }
    }
    this.filesService.deleteFile(file.key).subscribe((data: any) => {
    });
  }


  fileUploadedEvent(event: any) {
    if (this.data.data.entityId != null) {
      event.body.type = 'FILE';
      if (event.body.previewUrl == null) {
        let fileType = db[event.body.mimeType];
        if (fileType != null && fileType.hasOwnProperty('extensions')) {
          if (fileType?.extensions.length > 0) {
            event.body.mimeType = fileType;
            event.body.image = this.filesService.defaultImage(fileType.extensions[0]);
          }
        }
      }
      this.uploadedList.push(event.body);
    } else if (this.data.data.entityId == null) {
      event.body.type = 'FILE';
      if (event.body.previewUrl == null) {
        let fileType = db[event.body.mimeType];
        if (fileType != null && fileType.hasOwnProperty('extensions')) {
          if (fileType?.extensions.length > 0) {
            event.body.mimeType = fileType;
            event.body.image = this.filesService.defaultImage(fileType.extensions[0]);
          }
        }
      }
      this.tempUploadedList.push(event.body);
    }
    if (this.inputData.data.entityType === 'JOB' && this._jobService.selectedJobInfo.id) {
      this.jobSeenUpdate();
    }
  }

  uploadFromUrl() {
    let urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    var regex = new RegExp(urlRegex);
    if (this.myGroup.value.url && this.myGroup.value.url.match(regex)) {
      if (this.data.data.entityId != null) {
        let payload = {
          'entityId': `${this.inputData.data.entityId}`,
          'entityType': this.inputData.data.entityType,
          'url': this.myGroup.value.url,
          'path': this.data.data.path
        };
        if (payload.url.substring(0, 4) != 'http') {
          payload.url = 'https://' + payload.url;
        }
        this.myGroup.patchValue({
          url: '',
        });
        this.configService.isLoader = true;
        this.filesService.uploadUrl(payload).pipe(map((x: any) => x.data)).subscribe((data: any) => {
          this.configService.isLoader = false;
          data.type = 'URL';
          this.uploadedList.push(data);
        }, (err: any) => {
          this.configService.isLoader = false;
        });
      } else if (this.data.data.entityId == null) {
        let payload = {
          'url': this.myGroup.value.url,
        };
        if (payload.url.substring(0, 4) != 'http') {
          payload.url = 'https://' + payload.url;
        }
        this.myGroup.patchValue({
          url: '',
        });
        this.configService.isLoader = true;
        this.filesService.uploadTempUrl(payload).pipe(map((x: any) => x.data)).subscribe((data: any) => {
          this.configService.isLoader = false;
          data.type = 'URL';
          this.tempUploadedList.push(data);
        }, (err: any) => {
          this.configService.isLoader = false;
        });
      }
    } else {
      if (this.myGroup.value.url)
        this.toastr.error('URL is not valid');
    }
  }


  deleteTempFile(file) {
    for (let i = 0; i < this.tempUploadedList.length; i++) {
      if (file.key == this.tempUploadedList[i].key) {
        this.tempUploadedList.splice(i, 1);
      }
    }
  }

  onDismiss(event?: any) {
    this.dialogRef.close(event);
  }

  handleFile(event: any, file: any) {
    if (this.isPublic) {
      return;
    }
    const fileId = this.updateFileForm?.getRawValue()?.id;
    if (!fileId || fileId === file.id) {
      this.isFileDetails = !this.isFileDetails;
    }

    if (this.isFileDetails) {
      this.isLoader = true;
      if (this.updateFileForm)
        this.updateFileForm.reset();
      this.filesService.getFile(file.id)
        .pipe(map((data: any) => data?.data?.file))
        .subscribe({
          next: (data) => {
            if(data?.tags && data?.tags.length > 0) {
              let newArray = data?.tags[0].split(',')
              this.chipList = newArray.filter(value => value !== '');
            }
            this.updateFileForm = new FormGroup({
              id: new FormControl(file.id),
              title: new FormControl(data.title ?? file.title),
              tags: new FormControl(this.chipList),
              description: new FormControl(data.description ?? file.description),
              file_name: new FormControl(file.filename),
              mime_type: new FormControl(file.mimeType),
              source: new FormControl(file.source),
              created_at: new FormControl(moment(file.createdAt).format('MM/DD/YYYY')),
              created_by: new FormControl(`${data?.user?.first_name} ${data?.user?.last_name}`),
              preview_url: new FormControl(this.base + file.previewUrl),
              url: new FormControl(file.source === 'DIRECT' ? this.base + file.url : file.url),
            });
            this.isLoader = false;

            this.getAllVendors('',true);
            this.getUserPermittedFiles(file.id);

          },
          error: (error) => {
            this.isLoader = false;
            this.utilService.showError('Get File Error', error.message);
          },
        });
    } else {
      this.updateFileForm.reset();
    }
  }

  changeTags(event: any): void {
    this.tags = [];

    event.forEach(tag => {
      this.tags.push(tag?.name || tag);
      this.updateFile();
    })
  }

  updateFile() {
    this.filesService.updateFile(
      this.updateFileForm.getRawValue()?.id,
      this.updateFileForm.getRawValue()?.title ?? '',
      this.updateFileForm.getRawValue()?.description ?? '',
      this.tags ?? [],
    ).subscribe({
      next: () => {
        this.utilService.showSuccess('', 'File Update Successfully');
      },
      error: (error) => {
        this.utilService.showError('Update File Error', error.message);
      },
    });
  }

  imageTypeRegex(type: string) {
    const imageMimeTypeRegex = /^image\/(jpeg|png|gif)$/i;
    return imageMimeTypeRegex.test(type);
  }

  jobSeenUpdate() {
    this.filesService.jobSeenUpdate(this._jobService.selectedJobInfo.id).subscribe((data) => { })
  }


  getAllVendors(searchInput: string, loading: boolean = false) {

    if(!this._jobService.selectedJobInfo.id){
      return null;
    }

    loading ? (this.isVendorLoading = true) : (this.isVendorLoading = false)
    let requestURL = environment.baseURL + `vendor-jobs/assigned-vendors/?page=1&limit=25&offset=0&job_id=${this._jobService.selectedJobInfo.id}`;
    if (searchInput !== '') {
      requestURL += `&search=${encodeURIComponent(searchInput)}`;
    }

    this._baseService.doGET(requestURL).subscribe({
      next: (res: any) => {
        this.isVendorLoading = false;

        this.vendorList = res?.results ?? [];
        this.filterListingData();

        this.vendorList.forEach(e => {
          e["name"] = e.company_name;
        });

      },
      error: (error) => {
        this.isVendorLoading = false
      },
      complete: () => {


      }
    })
  }

  onVendorChange(event) {
    this.addFilePermission(event[event.length - 1]);
  }


  addFilePermission(event) {
    const payload = {
      "fileId": this.updateFileForm.value.id,
      "userId": event.company_id ? event.company_id : event.id,
      "userType": "VENDOR",
      "permissionLevel": "READ",
      "createdByType": "USER"
    }

    this.filesService.addFilePermission(payload).subscribe((res) => {
      this.getUserPermittedFiles(this.updateFileForm.value.id,);
    }, (err: HttpErrorResponse) => {
      //this._utilityService.showError('', err?.error?.message)

    })
  }


  getUserPermittedFiles(id) {
    this.filesService.getUserPermittedFiles(id).subscribe((res: any) => {
      const data = res.data ?? [];
      data.map((r) => { r["name"] = r.first_name + r.last_name; return r })
      this.selectedVendorList = data;
      this.filterListingData();
    })
  }

  removeVendor(v) {
    const payload = {
      "fileId": this.updateFileForm.value.id,
      "userId": v.id
    }
    this.filesService.removeFilePermission(payload).subscribe((res) => {
      this.getUserPermittedFiles(this.updateFileForm.value.id);
      this.getAllVendors('', false);
    })
  }

  onRemove(e){
    this.removeVendor(e);
  }


  filterListingData(){
    this.vendorList = this.vendorList.filter(itemB => !this.selectedVendorList.some(itemA => itemA.id === itemB.company_id));
  }


  assignFileToApprovedVendors(file, vendorList) {

    vendorList.forEach(vendor => {

      if (vendor.approve_to_work == 'Approved') {

        const payload = {
          "fileId": file.id,
          "userId": vendor.company_id,
          "userType": "VENDOR",
          "permissionLevel": "READ",
          "createdByType": "USER"
        }

        this.filesService.addFilePermission(payload).subscribe((res) => {
          this.getUserPermittedFiles(this.updateFileForm.value.id,);
        }, (err: HttpErrorResponse) => { })
      }

    });

  }


   /** Track by Index  **/
   trackByIndex(index: number, item: any): number {
    return index;
  }
}
