import { Component, EventEmitter, forwardRef, Input, NgZone, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { ItemList } from '../itemType.class';
import { throttle } from 'lodash';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { NgIf } from '@angular/common';

@Component({
    selector: 'fyxt-table-list-dropdown',
    templateUrl: './table-list-dropdown.component.html',
    styleUrls: ['./table-list-dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => TableListDropdownComponent)
        }
    ],
    standalone: true,
    imports: [NgIf, NgSelectModule, FormsModule, NgxIntlTelInputModule]
})
export class TableListDropdownComponent implements OnInit,ControlValueAccessor {
  // @Input() label:boolean = false;
  @Input() items!:ItemList[];
  @Input() searchable:boolean = false;
  @Input() disable:boolean = false;
  @Input() placeHolder!:string;
  @Input() customClass: string = '';
  @Input() is_status:boolean = false;

  
  @Output() onValueChange = new EventEmitter<Event>();
  @ViewChildren("dropdown") dropdown: QueryList<NgSelectComponent>;
  @Input() input:any;

  private onScrollThrottled: () => void;

  constructor(
    private zone: NgZone
  ) {
    this.onScrollThrottled = throttle(this.onScroll, 200);
  }

  ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      window.addEventListener('scroll', this.onScrollThrottled, true);
    });
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.onScrollThrottled, true);
  }
  onChange = (data:any) => {}
  onTouch = (_:any) => {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: string) {
    this.input = value;
    this.onChange(value);
  }

   /**
  *Func triggered on input change
  */
  onchange(event:any){
    this.onChange(event);
    this.onValueChange.emit(event);
  }

  onScroll = (event: any):void => {
    const array = this.dropdown.toArray()
    let index = array.findIndex(x => x.isOpen === true);
    if (array && array[index]?.isOpen) {
      const isScrollingInScrollHost =(event.target.className as string).indexOf('ng-dropdown-panel-items')>-1;
      if (isScrollingInScrollHost) {
        return;
      }
      array[index]?.close();
    }
  }
}
