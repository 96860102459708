import { Injectable } from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {forkJoin} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ContactsDetailedViewService {
  _viewModule: string = "contact";

  constructor(private httpClient: HttpClient) { }

  getSelectedContactDetails(id: string) {
    return this.httpClient.get(`${environment.fastAPI_Actual_URL}/contacts/${id}/`);
  }

  getContactDetailedPageTabsCount(id: string) {
    return this.httpClient.get(`${environment.fastAPI_Actual_URL}/contacts/${id}/counts/`);
  }

  //Get Company Activities
  getContactsActivities(id: string) {
    return this.httpClient.get(`${environment.fastAPI_URL}/${this._viewModule}/${id}/activity/`);
  }

  getContactsNotes(id: string) {
    return this.httpClient.get(`${environment.fastAPI_URL}/${this._viewModule}/${id}/note/`);
  }

  changeEditColumnOrderView(id: string, selectedTabName: string, payload: any) {
    return this.httpClient.put(`${environment.fastAPI_URL}/${this._viewModule}/${id}/${selectedTabName}/edit_table/`, payload);
  }

  getPropertyTableDataRows(contactDetails: any,searchVal?: string, sortPayload?: any, paginationIndex?: any,perPage?:any, payload?: any) {
    const offset = (paginationIndex -1)*perPage
    let propertyTableRowsUrl = `${environment.LocalDev_URL_V2}properties/cc-assigned/` + '?page=' + paginationIndex + '&limit=' + perPage + '&offset=' + offset;

    if (searchVal) {
      propertyTableRowsUrl += `&search=${searchVal}`;
    }
    if (sortPayload?.active && sortPayload?.direction) {
      if (sortPayload.direction == 'asc') {
        propertyTableRowsUrl += `&ordering=${sortPayload.active}`;
      } else if (sortPayload.direction == 'desc') {
        propertyTableRowsUrl += `&ordering=-${sortPayload.active}`;
      }
    }
    return this.httpClient.post(propertyTableRowsUrl, payload);
  }

  getAssociatedJobTableDataRows(contactDetails: any, searchVal?: string, sortPayload?: any, paginationIndex?: any,perPage?:any) {
    const offset = (paginationIndex -1)*perPage
    let associatedJobTableRowsUrl =`${environment.baseURL}contact-jobs/`+ contactDetails.id +'/?limit=' + perPage + '&offset=' + offset ;
    if (searchVal) {
      associatedJobTableRowsUrl+= `&search=${searchVal}`;
    }
    if (sortPayload?.active && sortPayload?.direction) {
      if (sortPayload.direction == 'asc') {
        associatedJobTableRowsUrl += `&ordering=${sortPayload.active}`;
      } else if (sortPayload.direction == 'desc') {
        associatedJobTableRowsUrl+= `&ordering=-${sortPayload.active}`;
      }
    }
    if (paginationIndex > 0) {
      associatedJobTableRowsUrl += '&page=' + paginationIndex;
    }
    return this.httpClient.get(associatedJobTableRowsUrl);
  }

  getAssociatedCompanyTableDataRows(contactDetails: any, searchVal?: string, sortPayload?: any, paginationIndex?: any, perPage?: any) {
    let associatedCompanyTableRowsUrl = `${environment.fastAPI_Actual_URL}/contacts/` + contactDetails + `/companies/` + '?page=' + paginationIndex + '&size=' + perPage
    if (searchVal) {
      associatedCompanyTableRowsUrl += `&search=${searchVal}`;
    }
    if (sortPayload?.active && sortPayload?.direction) {
      associatedCompanyTableRowsUrl += `&sort=${sortPayload.active}&order=${sortPayload.direction}`;
    }
    return this.httpClient.get(associatedCompanyTableRowsUrl);
  }

  removeAssignCompany(contactDetails: any, payload: any) {
    let requestUrl = `${environment.fastAPI_Actual_URL}/contacts/` + contactDetails.id + `/bulk-action/`;
    return this.httpClient.patch(requestUrl, payload);
  }


  getFilesTableDataWithHeader(viewID: string) {
    let filesTableHeaders = this.httpClient.get(`${environment.fastAPI_URL}/${this._viewModule}/` + viewID + `/files/column/`);
    let filesTableRows = this.httpClient.get(`${environment.fastAPI_URL}/${this._viewModule}/` + viewID + `/files/`);
    return forkJoin([filesTableHeaders, filesTableRows]);
  }

  getFilesTableDataRows(viewID: string, searchVal?: string, sortPayload?: any) {
    let filesTableRowsUrl = `${environment.fastAPI_URL}/${this._viewModule}/` + viewID + `/files/`;
    if (searchVal) {
      filesTableRowsUrl += `?search=${searchVal}`;
    }
    if (sortPayload.active && sortPayload.direction) {
      filesTableRowsUrl += `?column=${sortPayload.active}&order=${sortPayload.direction}`;
    }
    return this.httpClient.get(filesTableRowsUrl);
  }
}
