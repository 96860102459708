import {
  Component,
  HostListener,
  OnInit,
  TemplateRef,
  ViewChild,
  Inject,
  ElementRef
} from '@angular/core';
import { SidebarService } from 'src/app/@fyxt/_services/_layout/sidebar.service';
import { LayoutService } from 'src/app/@fyxt/_services/_layout/layout.service';
import { FilesService } from 'src/app/Modules/_fyxt_modules/companies/_services/files.service';
import { FileSaverService } from 'ngx-filesaver';
import { ReplaceFileComponent } from '../../../_popup_views/replace-file/replace-file.component';
import { DialogService } from 'src/app/@fyxt/_services/dialog/dialog.service';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfigService } from 'src/app/services/config.service';
import { ActivatedRoute } from '@angular/router';
import db from 'mime-db';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileInformationSlideComponent } from '../../../../_forms/file-information-slide/file-information-slide.component';
import { LoaderSmallComponent } from '../../../../_reusable_ui/_components/loader-small/loader-small.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../../../../_reusable_ui/_components/button/button.component';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import {
  ALLOWED_AUDIO_TYPE,
  ALLOWED_DOCUMENT_TYPES,
  ALLOWED_IMAGE_TYPES,
  ALLOWED_VIDEO_TYPES,
  SHOW_LOADER_FOR
} from '../files.const';
@Component({
    selector: 'app-files-slider',
    templateUrl: './files-slider.component.html',
    styleUrls: ['./files-slider.component.scss'],
    standalone: true,
    imports: [NgClass, ButtonComponent, MatIconModule, NgIf, MatButtonModule, MatMenuModule, NgStyle, NgxDocViewerModule, LoaderSmallComponent, FileInformationSlideComponent]
})
export class FilesSliderComponent implements OnInit {
  imageTypes = ALLOWED_IMAGE_TYPES;
  showLoader = SHOW_LOADER_FOR;
  allowedVideoTypes = ALLOWED_VIDEO_TYPES;
  allowedAudioTypes = ALLOWED_AUDIO_TYPE;
  allowedDocumentTypes = ALLOWED_DOCUMENT_TYPES;

  @ViewChild('closebutton') closebutton;
  photos: any[] = [];
  timeStamp = Date.now();
  env = '';
  currentFile: any;
  imageLoading: boolean = false;
  showReplaceButton = true;

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'ArrowLeft') {
      this.prev();
    }
    if (event.key === 'ArrowRight') {
      this.next();
    }
  }

  currentnum = 0;
  currentImg = null;
  isFileDetails: boolean = false;

  @ViewChild('confirmArchiveContact')
  confirmArchiveContact!: TemplateRef<any>;

  @ViewChild('deleteDialogTemplate')
  deleteDialogTemplate!: TemplateRef<any>;
  isMobile: boolean = false;
  isPublic: boolean = false;
  isJobCost: boolean = false;

  actionButtonList = ['Download', 'Delete'];

  constructor(
    public sidebarService: SidebarService,
    public _filesService: FilesService,
    public _layoutService: LayoutService,
    private fileSaverService: FileSaverService,
    public _dialogService: DialogService,
    public dialogRef: MatDialogRef<FilesSliderComponent>,
    private configService: ConfigService,
    private _activeRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public matDialogData: any
  ) {
    this.env = environment.files.http;
    this.env = this.env.substring(0, this.env.length - 1);
    if (this._activeRoute.snapshot?.queryParams?.file) {
      let file_id = this._activeRoute.snapshot?.queryParams?.file;
      this.getParticularFile(file_id);
    }
    this.isMobile = matDialogData?.isMobile;
    this.isPublic = matDialogData?.isPublic;
    this.isJobCost = matDialogData?.isJobCost;
  }

  ngOnInit(): void {
    this.photos = this._filesService.sliderData.rows;

    this.photos.forEach((element, index) => {
      if (element.key == this._filesService.currentFileClicked) {
        this.currentFile = element;
        this.currentnum = index;
      }
      const fileType = db[element.mimeType];
      if (fileType != null && fileType.hasOwnProperty('extensions')) {
        if (fileType?.extensions.length > 0) {
          element.mimeType = fileType.extensions[0];
        }
      }

      const isSupported = this.isMimeTypeSupported(element.mimeType);
      element.isSupported = isSupported;
    });
    
    // this.currentFile = this.photos[0];
    this._filesService.currentFile.next(this.currentFile);
    this._filesService.currentViewingFile = this.currentFile;

    this.currentImg = this.photos[this.currentnum].image;

    if (this.showLoader.includes(this.currentFile.mimeType)) {
      this.imageLoading = true;
    }
    let pathname = window.location.pathname;
    if (pathname.includes('inbox')) {
      this.showReplaceButton = false;
    }

    if (this.isJobCost) {
      this.showReplaceButton = false;
      this._filesService.isTenantPortal = true;
      this.isMobile = false;
      this.isPublic = false;
    }
  }

  prev() {
    if (this.currentnum != 0) {
      this.timeStamp = Date.now();
      this.currentnum = this.currentnum - 1;
      this.currentImg = this.photos[this.currentnum].image;
      this.currentFile = this.photos[this.currentnum];
      this._filesService.currentViewingFile = this.currentFile;
      this._filesService.currentFile.next(this.currentFile);
      if (this.showLoader.includes(this.currentFile.mimeType)) {
        this.imageLoading = true;
      }
      this.changeVideo();
    }

    if(!this.photos[this.currentnum]?.isSupported) {
      this.imageLoading = false;
    }
  }

  onLoad() {
    this.imageLoading = false;
  }

  contentLoaded() {
    this.imageLoading = false;
  }

  deletePic() {
    this.configService.isLoader = true;
    let obj = this.photos[this.currentnum];
    this.photos.map((element, item) => {
      if (element.id == obj.id) {
        this._filesService.deleteFile(element.key).subscribe(
          (data: any) => {
            this.photos.splice(item, 1);
            this.dialogRef.close();
            this.configService.isLoader = false;
          },
          (err: any) => {
            this.configService.isLoader = false;
          }
        );
      }
    });
  }

  replacePic() {
    let popupData = {
      title: 'Replace',
      component: ReplaceFileComponent,
      containerClass: 'modal-md',
      data: {
        fileData: this.currentFile,
        entityId: this._filesService.selectedEntityId,
        entityType: this._filesService.selectedEntityType
      }
    };

    this._dialogService.openModal(popupData).subscribe((result) => {
      if (result?.event == 'file replaced') {
        //this.onclick.emit({event:'file replaced'});
        this.dialogRef.close();
      }
    });
  }

  downloadPic() {
    if (this.currentFile.source == 'DIRECT') {
      this.configService.isLoader = true;
      this._filesService.donwloadFile(this.currentFile.key).subscribe(
        (data) => {
          this.configService.isLoader = false;
          this.fileSaverService.save(data, this.currentFile.filename);
        },
        (err: any) => {
          this.configService.isLoader = false;
        }
      );
    } else if (this.currentFile.source == 'URL') {
      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = this.currentFile.image;
      tempLink.setAttribute('download', this.currentFile.filename);
      tempLink.setAttribute('target', '_blank');
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
    }
  }

  next() {
    if (this.photos.length - 1 != this.currentnum) {
      this.timeStamp = Date.now();
      this.currentnum = this.currentnum + 1;
      this.currentImg = this.photos[this.currentnum].image;
      this.currentFile = this.photos[this.currentnum];
      this._filesService.currentViewingFile = this.currentFile;
      this._filesService.currentFile.next(this.currentFile);
      if (this.showLoader.includes(this.currentFile.mimeType)) {
        this.imageLoading = true;
      }
      this.changeVideo();
    }

    if(!this.photos[this.currentnum]?.isSupported) {
      this.imageLoading = false;
    }
  }


  showInfo() {
    if (this._layoutService.collapasableClass == 'collapasable-model') {
      this._layoutService.collapasableClass = '';
    } else {
      this._layoutService.collapasableClass = 'collapasable-model';
    }

    this.sidebarService.toggleRightSidebar =
      !this.sidebarService.toggleRightSidebar;
    this.sidebarService.setFormConext('File Information');
  }

  onDismiss(event?: any) {
    this.dialogRef.close(event);
  }

  getParticularFile(id) {
    this.imageLoading = true;
    this._filesService.getPraticularFile(id).subscribe(
      (data: any) => {
        this.currentnum = 0;
        let files = [];
        let mimetype = db[data.data.file.mimeType];
        data.data.file.mimeType = mimetype.extensions[0];
        data.data.file.image = `${this.env}${data.data.file.previewUrl}`;
        files.push(data.data.file);
        this.imageLoading = false;
        this.photos = files;
        this.currentFile = this.photos[0];
        this._filesService.currentFile.next(this.currentFile);
        this._filesService.currentViewingFile = this.currentFile;
        this.currentImg = this.photos[this.currentnum].image;
      },
      (err: any) => {
        this.imageLoading = false;
      }
    );
  }

  openArchivePopup() {
    let popupData = {
      title: 'Delete Files',
      template: this.deleteDialogTemplate,
      confirmText: 'Delete',
      cancelText: 'cancel',
      buttons: true,
      data: ''
    };

    this._dialogService.confirmDialog(popupData).subscribe((result: any) => {
      if (result) {
        this.deletePic();
      }
    });
  }

  changeVideo() {
    if (this.allowedVideoTypes.includes(this.currentFile?.mimeType)) {
      this.imageLoading = true;
      setTimeout(() => {
        this.imageLoading = false;
      }, 2000);
    }
  }

  isMimeTypeSupported(mimeType: string): boolean {
    return this.allowedVideoTypes.includes(mimeType) || 
           this.allowedAudioTypes.includes(mimeType) || 
           this.allowedDocumentTypes.includes(mimeType) || 
           this.imageTypes.includes(mimeType);
  }
}
