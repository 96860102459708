import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'fyxt-thumbnail',
    templateUrl: './thumbnail.component.html',
    styleUrls: ['./thumbnail.component.scss'],
    standalone: true,
    imports: [NgClass]
})
export class ThumbnailComponent implements OnInit {

  @Input() size:string='sm';
  @Input() customClass: string = '';
  @Input() imageUrl: string = 'https://material.angular.io/assets/img/examples/shiba2.jpg';
  @Input() isDisplayHide: boolean = false;
  
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(this.isDisplayHide){
      this.customClass+=' d-none';
    }
  }

}
