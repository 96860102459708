import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';


/******************************************
Array | search:searchTerm
******************************************/
@Pipe({
    name: 'search',
    standalone: true
})
export class SearchPipe implements PipeTransform {

  transform(itemList: any, searchKeyword: string) {
    if (!itemList)
      return [];
    if (!searchKeyword)
      return itemList;


    let filteredList: any[] = [];
    if (itemList.length > 0) {
      searchKeyword = searchKeyword.toLowerCase();
      itemList.forEach((item: any) => {
        let propValueList:any = Object.values(item);
        for (let i = 0; i < propValueList.length; i++) {
          if (propValueList[i]) {
            if (propValueList[i].toString().toLowerCase().indexOf(searchKeyword) > -1) {
              filteredList.push(item);
              break;
            }
          }
        }
      });
    }
    return filteredList;
  }

}


/******************************************
Array | search:searchTerm
******************************************/

@Pipe({
    name: 'searchByKey',
    standalone: true
})
export class SearchByKeyPipe implements PipeTransform {

  transform(items: any[], columnName: string, searchTerm: string): any[] {
    if (!items || !columnName || !searchTerm) {
      return items;
    }

    searchTerm = searchTerm.toLowerCase();

    return items.filter(item => {
      const value = item[columnName];
      return value ? value.toString().toLowerCase().includes(searchTerm) : false;
    });
  }
}

/******************************************
Get Date Time ago ( date | dateAgo )
******************************************/

@Pipe({
    name: 'dateAgo',
    pure: true,
    standalone: true
})
export class DateAgoPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
        return 'Just now';
      const intervals = {
        'year': 31536000,
        'month': 2592000,
        'week': 604800,
        'day': 86400,
        'hour': 3600,
        'minute': 60,
        'second': 1
      };
      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0)
          if (counter === 1) {
            return counter + ' ' + i + ' ago'; // singular (1 day ago)
          } else {
            return counter + ' ' + i + 's ago'; // plural (2 days ago)
          }
      }
    }
    return value;
  }

}

/******************************************
Get Inbox Date Time ago ( date | dateInbox )
******************************************/

@Pipe({
  name: 'dateInbox',
  pure: true
})
export class DateInboxPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
        return 'Just now';
      const intervals = {
        'year': 31536000,
        'month': 2592000,
        'week': 604800,
        'day': 86400,
        'hour': 3600,
        'min': 60,
        'second': 1
      };
      let counter;
      for (const i in intervals) {
        if (seconds > 86399) {
          let getDate = new Date(value);
          return formatDate(getDate, 'd MMM', 'en-US');
        } else {
          counter = Math.floor(seconds / intervals[i]);
          if (counter > 0)
            if (counter === 1) {
              return counter + ' ' + i + ' ago'; // singular (1 day ago)
            } else {
              return counter + ' ' + i + 's ago'; // plural (2 days ago)
            }
        }
      }
    }
    return value;
  }

}

/******************************************
Get Sender Info ( SENDER | senderView )
******************************************/

@Pipe({
  name: 'senderView',
  pure: true
})
export class SenderViewPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      if (value.includes('Unknown')) {
        let fullName = value.split('[').filter(function (v) { return v.indexOf(']') > -1 }).map(function (value) {
          return value.split(']')
        });
        return fullName[0][0];
      }
    }
    return value;
  }

}




/********************
Order By Pipe
/********************/
@Pipe({
    name: 'orderBy',
    standalone: true
})
export class OrderByKey {

  transform(array, orderBy, asc = true) {

    if (!orderBy || orderBy.trim() == "") {
      return array;
    }

    //ascending
    if (asc) {
      return Array.from(array).sort((item1: any, item2: any) => {
        return this.orderByComparator(item1[orderBy], item2[orderBy]);
      });
    }
    else {
      //not asc
      return Array.from(array).sort((item1: any, item2: any) => {
        return this.orderByComparator(item2[orderBy], item1[orderBy]);
      });
    }

  }

  orderByComparator(a: any, b: any): number {

    if ((isNaN(parseFloat(a)) || !isFinite(a)) || (isNaN(parseFloat(b)) || !isFinite(b))) {
      //Isn't a number so lowercase the string to properly compare
      if (a.toLowerCase() < b.toLowerCase()) return -1;
      if (a.toLowerCase() > b.toLowerCase()) return 1;
    }
    else {
      //Parse strings as numbers to compare properly
      if (parseFloat(a) < parseFloat(b)) return -1;
      if (parseFloat(a) > parseFloat(b)) return 1;
    }

    return 0; //equal each other
  }

  
}




/********************
Filter By Pipe ->   *ngFor="let i of list | filter : 'key' : value"
/********************/
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(list: any[], key: string, value: string): any {
    return list.filter(i => i[key] === value);
  }

}

