import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {DialogService} from "../../../../_services/dialog/dialog.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {environment} from "../../../../../../environments/environment";
import {UtilityService} from "../../../../../services/utility.service";

@Component({
  selector: 'fyxt-delete-view',
  templateUrl: './delete-view.component.html',
  styleUrls: ['./delete-view.component.scss']
})
export class DeleteViewComponent implements OnInit {
  selectedView: any;
  isLoader: boolean;

  constructor(public _dialogService: DialogService, public dialogRef: MatDialogRef<DeleteViewComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, public _utilService: UtilityService,) {
    this.selectedView = this.data?.data?.selectedView;
  }

  ngOnInit(): void {
  }

  onCancel(): void {

  }

  deleteView(): void {
    this.isLoader = true;
    let requestURL = environment.LocalDev_URL_V2 + 'manage-dashboard/' + this.selectedView.id + '/remove_views/';
    this._dialogService.doDELETE(requestURL)
      .subscribe(
        {
          next: (response: any) => {

          },
          error: (error) => {

          },
          complete: () => {
            this.isLoader = false;
            this._utilService.showSuccess('', 'View Created Successfully');
            const payload = {
              viewDeleted: true
            };
            this.dialogRef.close(payload);
          }
        }
      );
  }

}
