import { data } from 'jquery';
import { BaseService } from './../../../../_services/core/base.service';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ResizedEvent, AngularResizeEventModule } from 'angular-resize-event-package';
import { environment } from 'src/environments/environment';
import { ColumnSortType, TableColumnTypes } from '../../../enums/table/table-column-types.enum';
import { TableService } from 'src/app/@fyxt/_services/table/table.service';
import { GraphqlEquipmentManagementService } from 'src/app/Modules/_fyxt_modules/equipment-tracking/_services/equipment-management/graphql-equipment-management.service';
import { AppTableSource } from '../../../models/app-table-model';
import { ActivatedRoute, Router } from '@angular/router';
import { JobsService } from 'src/app/services/v2/jobs.service';
import { UtilityService } from 'src/app/services/utility.service';
import { LayoutService } from 'src/app/@fyxt/_services/_layout/layout.service';
import { GraphQLError } from 'graphql';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { plannerService } from 'src/app/Modules/_fyxt_modules/service-planner/services/planner-service';
import { MatDividerModule } from '@angular/material/divider';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule } from '@angular/forms';
import { FilterAccordianComponent } from '../../../_reusable_ui/_controls/filter-accordian/filter-accordian.component';
import { FilterComponent } from '../../../_reusable_ui/_components/filter/filter.component';
import { TableComponent } from '../../../_reusable_ui/_components/table/table.component';
import { LoaderSmallComponent } from '../../../_reusable_ui/_components/loader-small/loader-small.component';
import { CardComponent } from '../../../_reusable_ui/_components/card/card.component';
import { FilterChipListComponent } from '../../../_reusable_ui/_components/filter-chip-list/filter-chip-list.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonComponent } from '../../../_reusable_ui/_components/button/button.component';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { SearchInputComponent } from '../../../_reusable_ui/_controls/search-input/search-input.component';

@Component({
    selector: 'app-assign-job-equipment',
    templateUrl: './assign-job-equipment.component.html',
    styleUrls: ['./assign-job-equipment.component.scss'],
    standalone: true,
    imports: [SearchInputComponent, NgIf, ButtonComponent, MatDialogModule, MatTooltipModule, MatIconModule, FilterChipListComponent, AngularResizeEventModule, NgClass, CardComponent, LoaderSmallComponent, TableComponent, FilterComponent, NgFor, FilterAccordianComponent, FormsModule, NgxIntlTelInputModule, MatDividerModule]
})
export class AssignJobEquipmentComponent implements OnInit {

  @Input() isLoader: boolean;
  // @Output() toggleFilter = new EventEmitter();
  @Output() onClear = new EventEmitter();
  @Output() onDeletedTag = new EventEmitter();
  @Input() filterChipList: any[] = [];
  @Output() divWidth: number;
  dataSource!: AppTableSource;
  public show: boolean = false;

  tableHeaders = [
    {
      value: 'checkbox',
      sort: ColumnSortType.none,
      name: '',
      type: TableColumnTypes.checkbox,
      className: 'table-checkbox'
    },
    {
      value: 'name',
      sort: ColumnSortType.none,
      name: 'Equipment',
      type: TableColumnTypes.textWithImage,
      className: 'table-header equipment_name',
      isEquipment_name: true
    },
    {
      value: 'equipment_type_name',
      sort: ColumnSortType.none,
      name: 'Equipment Type',
      type: TableColumnTypes.text,
      className: 'table-header',
      resizeColumn: true,
    },
    {
      value: 'category_name',
      sort: ColumnSortType.none,
      name: 'Category',
      type: TableColumnTypes.text,
      className: 'table-header',
      resizeColumn: true,
    },
    {
      value: 'specific_location',
      sort: ColumnSortType.none,
      name: 'Specific Location',
      type: TableColumnTypes.text,
      className: 'table-header',
      resizeColumn: true,
    },
    {
      value: 'manufacturer_name',
      sort: ColumnSortType.none,
      name: 'Manufacturer',
      type: TableColumnTypes.text,
      className: 'table-header',
      resizeColumn: true,
    },
    {
      value: 'model_name',
      sort: ColumnSortType.none,
      name: 'Model',
      type: TableColumnTypes.text,
      className: 'table-header',
      resizeColumn: true,
    },
    {
      value: 'serial_number',
      sort: ColumnSortType.none,
      name: 'Serial Number',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'asset_tag',
      sort: ColumnSortType.none,
      name: 'Asset Tag',
      type: TableColumnTypes.text,
      className: 'table-header'
    },
    {
      value: 'age',
      sort: ColumnSortType.none,
      name: 'Age',
      type: TableColumnTypes.text,
      className: 'table-header',
      resizeColumn: true,
    }
  ];

  filtersDataSource: any = {
    filterLists: [
      {
        label: 'Equipment Type',
        isExpended: false,
        selectAll: false,
        filterName: 'listFilterEquipmentsTypes',
        pageNumber: 1,
        total: 0,
        listItems: [],
      },
      {
        label: 'Category',
        isExpended: false,
        selectAll: false,
        filterName: 'listFyxtCategories',
        pageNumber: 1,
        total: 0,
        listItems: [],
      },
      {
        label: 'Manufacturer',
        isExpended: false,
        selectAll: false,
        filterName: 'listFilterManufacturers',
        pageNumber: 1,
        total: 0,
        listItems: [],
      },
      {
        label: 'Model',
        isExpended: false,
        selectAll: false,
        filterName: 'listFilterModels',
        pageNumber: 1,
        total: 0,
        listItems: [],
      }
    ],
  };

  chipList: any[] = [];

  showFilter: boolean = false;
  collapsableFilter: string = '';
  listEquipments: any[] = [];
  searchInput: string = "";
  pageSize: number = 25;
  sortColumn: string = null;
  moduleName = "dashboard";
  sortDirection: string = null;
  selectedViewTableRowsTotal: number;
  checkedEquipments: any[] = [];
  buttonName: string;
  selectedFiltersList: any[] = [];
  constructor(
    public _baseService: BaseService,
    public _graphql: GraphqlEquipmentManagementService,
    public tableService: TableService,
    public _jobService: JobsService,
    public _utilService: UtilityService,
    public plannerService: plannerService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    let tableCell = document.querySelector('#id');
    this.divWidth = tableCell.clientWidth - (2 * (tableCell.clientWidth * 8 / 100));
    this.getAllEquipments(this.searchInput, 1, this.pageSize)
    this.loadFilters();

  }

  loadFilters() {
    this._graphql.loadJobEquipmentFilters().subscribe({
      next: (res: any) => {
        this.filtersDataSource.filterLists[0].listItems = res.listFilterEquipmentsTypes?.items || [],
          this.filtersDataSource.filterLists[0].total = res.listFilterEquipmentsTypes?.total || 0,
          this.filtersDataSource.filterLists[2].listItems = res.listFilterManufacturers?.items || [],
          this.filtersDataSource.filterLists[2].total = res.listFilterManufacturers?.total || 0,
          this.filtersDataSource.filterLists[3].listItems = res.listFilterModels?.items || [],
          this.filtersDataSource.filterLists[3].total = res.listFilterModels?.total || 0
      }
    })

    this._graphql.loadCategoryFilters().subscribe({
      next: (res: any) => {
        this.filtersDataSource.filterLists[1].listItems = res || [],
          this.filtersDataSource.filterLists[1].total = res?.length || 0
      }
    })

  }

  onSearchResult(e: any, index: any, field: any) {
    if (field !== 'listFilterStatus' && field !== 'listFyxtCategories' && field !== 'listFyxtProperties') {
      this._graphql.loadFilterspageQueries(e.value, field).subscribe({
        next: (res: any) => {
          this.filtersDataSource.filterLists[index].listItems = field === 'listFilterAssetTags' ? (res?.[field].items.map((e: any) => { return { name: e.asset_tag, id: e.id }; }) || []) : res?.[field].items || [];
          this.filtersDataSource.filterLists[index].total = res?.[field].total;
        },
        error: (err: GraphQLError) => {
          this._utilService.showError('', err);
        },
      })
    }
  }



  showSelectedFilters() {
    let selectedFiltersList = this.filtersDataSource.filterLists.map((element: any) => element.listItems.filter((subElement: any) => subElement.selected === true))

    let selectedFiltersObj: any = [];
    selectedFiltersList.forEach((element: any) => {
      selectedFiltersObj.push(...element);
    });
    this.chipList = selectedFiltersObj;

    let selectedFiltersListIds = this.filtersDataSource.filterLists.map((element: any) => {
      return element.listItems
        .filter((subElement: any) => subElement.selected === true)
        .map((selectedItem: any) => selectedItem.id);
    });
    this.getAllEquipments(this.searchInput, 1, this.pageSize, this.sortColumn, this.sortDirection, selectedFiltersListIds)
  }

  clearAllSelectedFilters() {
    this.filtersDataSource.filterLists.forEach((element: any) => {
      element.listItems.filter((subElement: any) => subElement.selected = false);
      element.selectAll = false;
    });

    this.chipList = [];
    this.showSelectedFilters();
  }

  /**
  * Single TableRow Selection
  * @param UniqueId
  */
  onCheckRow(equipment: any) {
    let updatedcheckedEquipments: string[];
    const isequipmentChecked = this.checkedEquipments.some((chekcedEquipment) => chekcedEquipment.id === equipment.id);

    if (isequipmentChecked) {
      updatedcheckedEquipments = this.checkedEquipments.filter((chekcedEquipment) => chekcedEquipment.id !== equipment.id);
    } else {
      updatedcheckedEquipments = [...this.checkedEquipments, equipment];
    }

    this.checkedEquipments = updatedcheckedEquipments;
  }

  /**
 * All TableRow Selection
 * @param SelectValue
 */
  isAllSelected(isSelected: any) {
    if (isSelected) {
      this.checkedEquipments = this.listEquipments;
    } else {
      this.checkedEquipments = [];
    }
  };

  // ontoggleFilter() {
  //   this.toggleFilter.emit();
  // }

  AssignEquipments() {
    let requestURL: any;
    let payLoad: any;
    if (this.data.data.propertyId) {
      let checkedEquipments: string[] = this.checkedEquipments.map(e => e.id);
      let allEquipments: string[] = checkedEquipments.concat(this.data.data.equipments.map((str) => str.replace(/'/g, '')) || []);
      const id = this.router.url.split('/').pop();
      requestURL = environment.baseURL + `planner/${id}/`;
      payLoad = { "assigned_equipment": allEquipments }

    } else {
      let checkedEquipments: string[] = this.checkedEquipments.map(e => e.id);;
      let allEquipments: string[] = checkedEquipments.concat(this._jobService.selectedJobInfo?.equipment.map((str) => str.replace(/'/g, '')) || []);
      requestURL = environment.baseURL + `jobs/${this._jobService.selectedJobInfo?.id}/`;
      payLoad = { "equipment": allEquipments, "partial": true }
    }
    this._baseService.doPatch(requestURL, payLoad).subscribe({
      next: (res: any) => {
        this._utilService.showSuccess('', 'Equipment Assigned Successfully');
        if (!this.data.data.propertyId) {
          this._jobService.getSelectedJobInfo(this._jobService.selectedJobInfo?.id, false, 'Equipment');
        } else {
          this.plannerService.plannerEquipmentsUpdate.next(true);
        }

      },
      error: (error: any) => {
        this._utilService.showError('',error.error.error)
      }
    })
  }

  getAllEquipments(searchInput: string, pageNumber: number, pageSize: number, sortColumn?: any, sortDirection?: any, filters?: any) {
    this.isLoader = true;
    let requestURL: any;
    if (this.data.data.propertyId) {
      requestURL = environment.equipURL + `/jobs/equipment/?query={'id': [] , 'property':['${this.data.data.propertyId}'], 'property_name':[],'category':[${filters ? filters?.[1]?.map(e => `'${e}'`) : ''}],'manufacturer_id':[${filters ? filters?.[2]?.map(e => `'${e}'`) : ''}], 'model_id':[${filters ? filters?.[3]?.map(e => `'${e}'`) : ''}], 'equipment_type_id':[${filters ? filters?.[0]?.map(e => `'${e}'`) : ''}], 'equipment_type':[],'assigned_id': [${this.data.data.equipments.map(e => `'${e}'`)}]}&search=${searchInput}&columns=['id', 'image', 'name', 'equipment_type_name', 'category_name', 'specific_location', 'manufacturer_name', 'model_name', 'age', 'serial_number', 'asset_tag']&sort_column=${sortColumn ? sortColumn : 'name'}&sort_order=${sortDirection ? sortDirection : 'asc'}&page=${pageNumber}&size=${pageSize}`;
    } else {
      requestURL = environment.equipURL + `/jobs/equipment/?query={'id': [] , 'property':['${this._jobService.selectedJobInfo?.property?.id}'], 'property_name':[],'category':[${filters ? filters?.[1]?.map(e => `'${e}'`) : ''}],'manufacturer_id':[${filters ? filters?.[2]?.map(e => `'${e}'`) : ''}], 'model_id':[${filters ? filters?.[3]?.map(e => `'${e}'`) : ''}], 'equipment_type_id':[${filters ? filters?.[0]?.map(e => `'${e}'`) : ''}], 'equipment_type':[],'assigned_id': [${this._jobService.selectedJobInfo?.equipment ? this._jobService.selectedJobInfo?.equipment.map(e => e.startsWith("'") && e.endsWith("'") ? e : `'${e}'`).join(', ') : ''}]}&search=${searchInput}&columns=['id', 'image', 'name', 'equipment_type_name', 'category_name', 'specific_location', 'manufacturer_name', 'model_name', 'age', 'serial_number', 'asset_tag']&sort_column=${sortColumn ? sortColumn : 'name'}&sort_order=${sortDirection ? sortDirection : 'asc'}&page=${pageNumber}&size=${pageSize}`;
    }
    this._baseService.doGET(requestURL).subscribe({
      next: (res: any) => {
        this.listEquipments = res?.items || [];
        this.selectedViewTableRowsTotal = res.total;
      },
      complete: () => {
        if (sortColumn || sortDirection) {
          let sortedHeaders: any[] = this.tableHeaders.map(element => {
            (element.value === sortColumn) ? (element.sort = sortDirection) : (element.sort = ColumnSortType.none);

            return element;
          });
          this.listEquipments = this.listEquipments.map(obj => ({ ...obj, age: (obj.age !== null) ? this._graphql.getAge(obj.age) : obj.age }));
          this.dataSource = this.tableService.setTableData(this.moduleName, sortedHeaders, this.listEquipments, this.selectedViewTableRowsTotal, pageNumber - 1, pageSize);
        }
        else {
          this.listEquipments = this.listEquipments.map(obj => ({ ...obj, age: (obj.age !== null) ? this._graphql.getAge(obj.age) : obj.age }));
          this.dataSource = this.tableService.setTableData(this.moduleName, this.tableHeaders, this.listEquipments, this.selectedViewTableRowsTotal, pageNumber - 1, pageSize);
        }
        this.isLoader = false;
      }
    })
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
    if (this.showFilter) {
      this.collapsableFilter = 'collapasable-model-filter'
    } else {
      this.collapsableFilter = ''
    }
  }


  onSearch(inputValue: any) {
    this.searchInput = inputValue;
    if (inputValue.length >= 2 || inputValue.length === 0) {
      this.getAllEquipments(this.searchInput, 1, this.pageSize)
    }
    if (this.checkedEquipments.length > 0) {
      this.checkedEquipments = []
    }
  }

  onPageChange(event: any) {
    this.pageSize = event.pageSize;
    this.getAllEquipments(this.searchInput, event.pageIndex + 1, event.pageSize, this.sortColumn, this.sortDirection)
  }

  onSortChange(e: any) {
    this.sortColumn = e.active;
    this.sortDirection = e.direction;
    this.getAllEquipments(this.searchInput, 1, this.pageSize, e.active, e.direction);
    if (this.checkedEquipments.length > 0) {
      this.checkedEquipments = []
    }
  }

  /**
* On Selected Tags Change Event
* @param event
*/
  // deletedTag(filterChip: any): void {
  //   const deleteChipIndex = this.filterChipList.findIndex((chip) => {
  //     return chip.id === filterChip.id;
  //   });
  //   this.filterChipList.splice(deleteChipIndex, 1);
  //   this.onDeletedTag.emit(filterChip);
  // }
  deletedTag(filterChip: any): void {
    this.filtersDataSource.filterLists.map((ele: any, i: any) => {
      ele.listItems.map((element: any, i: any) => {
        if (element == filterChip) {
          element.selected = false;
        }
      })
    });
    this.showSelectedFilters();
  }

  /**
 * Clear Selected Filters
 */
  clearSelectedFilters() {
    this.filterChipList = [];
    this.onClear.emit(this.filterChipList);
  }

  onResized(event: ResizedEvent) {
    this.divWidth = event.newRect.width - (2 * (event.newRect.width * 8 / 100));
  }

  toggle() {
    this.show = !this.show;
  }

   /** Track by Index  **/
   trackByIndex(index: number, item: any): number {
    return index;
  }
}
